import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import boreholeFunctions from '../../../../store/borehole/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import appFunctionsService from '../../../../services/appFunctionsService';
import BoreholeStatusSelector from '../../../customComponents/selector/borehole/StatusSelector';
import BoreholeWaterLevelStatusSelector from '../../../customComponents/selector/borehole/OverallStatus';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import EditBoreholeTestIssues from './EditProblems';
import { IBoreholeTest } from '../../../../@types/model/borehole/boreholeTest';

interface IEditBoreholeTestInfoProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    boreholeTest ?: IBoreholeTest;
}

interface IEditBoreholeTestInfoState {
    open : boolean;
    status : string;
    equipped : boolean;
    waterStatus : string;
    dry : boolean;
    waterLevel : string;
    problems : Array<string>;
}

export default class EditBoreholeTestInfo extends React.Component<IEditBoreholeTestInfoProps, IEditBoreholeTestInfoState> {
    constructor(props : IEditBoreholeTestInfoProps) {
        super(props);
        this.state = {
            open: false,
            status: '',
            equipped: false,
            waterStatus: '',
            dry: false,
            waterLevel: '',
            problems: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeTestInfoProps, prevState : IEditBoreholeTestInfoState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                status: '',
                equipped: false,
                waterStatus: '',
                dry: false,
                waterLevel: '',
                problems: [],
            });
        }
        if (!prevState.open && this.state.open && this.props.boreholeTest) {
            this.setState({
                status: !this.props.boreholeTest.BoreholeOverallStatus ? '' : this.props.boreholeTest.BoreholeOverallStatus.toString(),
                equipped: !!this.props.boreholeTest.Equipped,
                waterStatus: !this.props.boreholeTest.BoreholeStatus ? '' : this.props.boreholeTest.BoreholeStatus.toString(),
                dry: !!this.props.boreholeTest.Dry,
                waterLevel: !this.props.boreholeTest.WaterLevel ? '' : this.props.boreholeTest.WaterLevel.toFixed(2),
                problems: !this.props.boreholeTest.BoreholeAttributes.length ? [] : this.props.boreholeTest.BoreholeAttributes.slice(),
            });
        }

    };

    private readonly onStatusChange = (status : string) => {
        this.setState({
            status,
        });
    };

    private readonly onWaterLevelStatusChange = (waterStatus : string) => {
        this.setState({
            waterStatus,
        });
    };

    private readonly onEquippedChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            equipped: checked,
        });
    };

    private readonly onDryChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            dry: checked,
            waterLevel: '',
        });
    };

    private readonly onWaterLevelChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !parseFloat(event.currentTarget.value)) return;
        this.setState({
            waterLevel: event.currentTarget.value,
        });
    };

    private readonly onProblemChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const problems = this.state.problems.slice();

        if (checked && !problems.includes(event.target.value)) {
            problems.push(event.target.value);
        } else if (!checked) {
            const index = problems.indexOf(event.target.value);
            problems.splice(index, 1);
        }

        this.setState({
            problems,
        });
    };

    private readonly save = async () => {
        const { status, waterStatus, dry, equipped, waterLevel, problems } = this.state;
        if (!status) return;
        if (!this.props.boreholeTest) return;

        const boreholeTest = {
            ...this.props.boreholeTest,
        };

        boreholeTest.BoreholeOverallStatus = status;
        boreholeTest.BoreholeStatus = waterStatus;
        boreholeTest.Dry = dry;
        boreholeTest.Equipped = equipped;
        boreholeTest.WaterLevel = !waterLevel ? 0.0 : parseFloat(waterLevel);
        boreholeTest.BoreholeAttributes = problems;

        const result = await boreholeFunctions.saveBoreholeTest(boreholeTest);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, boreholeTest } = this.props;
        const { open, status, waterStatus, dry, equipped, waterLevel, problems } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-info-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Borehole Test ({!boreholeTest ? '' : appFunctionsService.formatDateTimeToDateOnly(boreholeTest.CreatedOn)})
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill oya'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <BoreholeWaterLevelStatusSelector value={status} onChange={this.onStatusChange} />
                                <BoreholeStatusSelector label='Water Level Status' value={waterStatus} onChange={this.onWaterLevelStatusChange} />
                                <div className={'ais p5 mb10 pr20'}>
                                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={equipped} onChange={this.onEquippedChanged} />
                                            }
                                            label='Equipped?'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={dry} onChange={this.onDryChange} />
                                            }
                                            label='Dry?'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='waterLevel'
                                            label='Water Level'
                                            value={waterLevel}
                                            onChange={this.onWaterLevelChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!waterLevel && !Number(waterLevel)}
                                            disabled={dry}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            waterLevel && !Number(waterLevel) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                PROBLEMS
                            </Typography>
                            <div className={'fdc flx1 aifs'}>
                                <EditBoreholeTestIssues problems={problems} onChange={this.onProblemChange} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IFlowmeter } from '../../../@types/model/flowmeter/flowmeter';
import { IRootState } from '../../../@types/redux';
import { FLOWMETER_MAP_IMAGES } from '../../../appConstants';
import FlowmeterFunctions from '../../../store/flowmeter/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditLocationDialog from '../../customComponents/dialog/LocationEditDialog';
import CustomMapBox, { IMapMarker } from '../../customComponents/map/CustomMapBox';

interface IFlowmeterLocationInfoViewProps {
    flowmeter : IFlowmeter;

    isLoading : boolean;

    isFlowmeterAdmin : boolean;
}

interface IFlowmeterLocationInfoViewState {}

class FlowmeterLocationInfoViewComponent extends React.PureComponent<IFlowmeterLocationInfoViewProps, IFlowmeterLocationInfoViewState> {
    constructor(props : IFlowmeterLocationInfoViewProps) {
        super(props);
        this.state = {};
    }
    private readonly mapZoom = 9;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];

    public getData = (state : IFlowmeterLocationInfoViewState, props : IFlowmeterLocationInfoViewProps) => props.flowmeter;

    public readonly getMarker = createSelector(
        [this.getData],
        (flowmeter) => {
            return {
                coordinates: [flowmeter.geo?.longitude ?? 0, flowmeter.geo?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: flowmeter.code,
            } as IMapMarker;
        }
    );
    
    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number) => {
        const flowmeter = Object.assign({}, this.props.flowmeter);

        flowmeter.geo = geom;
        flowmeter.elevation = elevation;

        await FlowmeterFunctions.saveFlowmeter(flowmeter);
    };

    public readonly render = () => {
        const { isFlowmeterAdmin, flowmeter, isLoading } = this.props;
        const marker = this.getMarker(this.state, this.props);

        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc mxh400 hfill'}>
                    <div className={'fdr dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            LOCATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isFlowmeterAdmin &&
                                    <EditLocationDialog
                                        markerImage={FLOWMETER_MAP_IMAGES['1']}
                                        markerImageSize={1}
                                        elevation={flowmeter.elevation ?? 0}
                                        geom={flowmeter.geo}
                                        transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                        onSave={this.onLocationSave}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdc ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    LATITUDE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.geo?.latitude ?? '-'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    LONGITUDE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.geo?.longitude ?? '-'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    ELEVATION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.elevation ?? 0}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {
                        !isLoading &&
                        <div className={'flx1 ais'}>
                            <div className='hfill wfill '>
                                <CustomMapBox
                                    id='flowmeter_map'                                                                                  
                                    center={this.mapCenter}
                                    zoom={this.mapZoom}
                                    images={FLOWMETER_MAP_IMAGES}
                                    markers={[marker]}
                                    selectedMarker={flowmeter.code}
                                />
                            </div>
                        </div>
                    }
                </Card>
            </div>
        );   
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isFlowmeterAdmin: !!state.auth.session?.employee.IsFlowmeterAdmin,
        isLoading: state.flowmeter.loading,
    };
};

const FlowmeterLocationInfoView = connect(
    mapStateToProps,
)(FlowmeterLocationInfoViewComponent);

export default FlowmeterLocationInfoView;

import { IDepricatedBaseClass } from '../base';
import BoreholeHelper, { IBorehole } from './borehole';
import firebase from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';

export interface IBoreholeRuntimer {
    StartTime : firebase.firestore.Timestamp;
    EndTime : firebase.firestore.Timestamp;
    Duration : number;
}

export interface IBoreholeTest extends IDepricatedBaseClass<IBoreholeTest> {
    Borehole : firebase.firestore.DocumentReference<IBorehole>;
    Duration? : number;
    Yield? : number;
    IsFlowmeterReading? : boolean;
    FlowmeterImagePath? : string;
    WaterLevel? : number;
    Equipped : boolean;
    BoreholeAttributes : Array<string>;
    BoreholeStatus : firebase.firestore.DocumentReference | string;
    BoreholeOverallStatus : firebase.firestore.DocumentReference | string;
    Container? : firebase.firestore.DocumentReference | number | null;
    Geom? : firebase.firestore.GeoPoint;
    Sent : boolean;
    Runtimers : Array<IBoreholeRuntimer>;
    AdjustedYield? : number;
    Dry : boolean;
}

export default class BoreholeTestHelper {
    public static readonly COLLECTION_NAME = 'boreholetest';

    private static converter : firebase.firestore.FirestoreDataConverter<IBoreholeTest> = {
        fromFirestore: (snapshot) => {
            return BoreholeTestHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBoreholeTest) : firebase.firestore.DocumentData => {
            const { ref, ...firestoreObject } = data;
            return firestoreObject;
        },
    };

    public static fromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data() as IBoreholeTest | undefined;

        if (!data) {
            throw new Error(`Document does not exist! ${doc.id}`);
        }

        const result : IBoreholeTest = {
            ...data,
            ref: doc.ref.withConverter(BoreholeTestHelper.converter),
            Borehole: BoreholeHelper.doc(data.Borehole.id),
        };

        result.BoreholeStatus = result.BoreholeStatus instanceof firebase.firestore.DocumentReference ? result.BoreholeStatus.id : result.BoreholeStatus;
        result.BoreholeOverallStatus = result.BoreholeOverallStatus instanceof firebase.firestore.DocumentReference ? result.BoreholeOverallStatus.id : result.BoreholeOverallStatus;
        result.Container = result.Container instanceof firebase.firestore.DocumentReference ? parseInt(result.Container.id) : result.Container;

        return result;
    }

    public static update(boreholeTest : IBoreholeTest) {
        return boreholeTest.ref.set(boreholeTest, {
            merge: true,
        });
    }

    public static delete(id : string) {
        return firebaseApp
        .firestore()
        .collection(this.COLLECTION_NAME)
        .doc(id)
        .delete();
    }

    public static listen(boreholeCode : string) {
        return firebaseApp
        .firestore()
        .collection(this.COLLECTION_NAME)
        .where('Borehole', '==', BoreholeHelper.doc(boreholeCode))
        .withConverter(this.converter);
    }
}

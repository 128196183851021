import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { BOREHOLE_INSTALLATION_MOTOR_PHASES } from '../../../../appConstants';

interface IMotorPhaseSelectorProps {
    phase : string;

    onPhaseChange : (phase : string) => void;
}

interface IMotorPhaseSelectorState {}

export default class MotorPhaseSelector extends React.Component<IMotorPhaseSelectorProps, IMotorPhaseSelectorState> {
    constructor(props : IMotorPhaseSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onPhaseChange = (event : React.ChangeEvent<{ name ?: string | undefined; value : unknown }>) => {
        if (typeof event.target.value === 'string') {
            this.props.onPhaseChange(event.target.value);
        }
    };

    public readonly render = () => {
        const { phase } = this.props;

        return (
            <React.Fragment>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel shrink={!!phase} htmlFor='phase'>Motor Phase</InputLabel>
                        <Select
                            fullWidth
                            id='phase'
                            value={!phase ? '' : phase}
                            onChange={this.onPhaseChange}>
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            {
                                lodash.map(BOREHOLE_INSTALLATION_MOTOR_PHASES, n => (
                                    <MenuItem key={`${n}_phase`} value={`${n}`}>
                                        {n}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </React.Fragment>
        );
    };
}

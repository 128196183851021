import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IPumpingStationPumpMake } from '../../../../@types/model/masterData/pumpingStation/pumpMake';
import { connect } from 'react-redux';
import { IRootState } from '../../../../@types/redux';
import MasterDataPumpingStationPumpMakeFunctions from '../../../../store/masterData/pumpingStation/pumpMake/functions';

interface IPumpingStationPumpMakeSelectorProps {
    makeValue : string;
    modelValue : string;
    disabled ?: boolean;
    required ?: boolean;
    className ?: string;
    onMakeChange : (value : string) => void;
    onModelChange : (value : string) => void;

    isLoading : boolean;
    pumpMakes : Array<IPumpingStationPumpMake>;
}

interface IPumpingStationPumpMakeSelectorState { }

class PumpingStationPumpMakeSelectorComponent extends React.Component<IPumpingStationPumpMakeSelectorProps, IPumpingStationPumpMakeSelectorState> {
    constructor(props : IPumpingStationPumpMakeSelectorProps) {
        super(props);

        this.state = {};
    }

    public componentDidMount() {
        MasterDataPumpingStationPumpMakeFunctions.load();
    }

    private readonly onMakeChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onMakeChange(value?.value ?? '');
    };

    private readonly onModelChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onModelChange(value?.value ?? '');
    };

    private readonly getData = (state : IPumpingStationPumpMakeSelectorState, props : IPumpingStationPumpMakeSelectorProps) => props.pumpMakes;
    private readonly getMakeValue = (state : IPumpingStationPumpMakeSelectorState, props : IPumpingStationPumpMakeSelectorProps) => props.makeValue;
    private readonly getModelValue = (state : IPumpingStationPumpMakeSelectorState, props : IPumpingStationPumpMakeSelectorProps) => props.modelValue;
    private readonly getRequired = (state : IPumpingStationPumpMakeSelectorState, props : IPumpingStationPumpMakeSelectorProps) => props.required;

    private readonly getPumpMakes = createSelector([
        this.getData,
    ], (pumpModels) => pumpModels.filter(x => x.isActive).map(x => x.name).sort());

    private readonly getPumpModels = createSelector([
        this.getMakeValue,
        this.getData,
    ], (make, pumpModels) => {
        const pumpMake = pumpModels.find(x => x.name === make);

        return pumpMake?.types ?? [];
    });

    private readonly getPumpMakesForDropdown = createSelector([ 
        this.getRequired, this.getPumpMakes,
    ], (required, pumpMakes) => {
        
        const dropdownData = lodash.map(pumpMakes, (element) => ({ label: element, value: element }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getPumpModelsForDropdown = createSelector([ 
        this.getRequired, this.getPumpModels,
    ], (required, pumpModels) => {
        
        const dropdownData = lodash.map(pumpModels, (element) => ({ label: element, value: element }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedPumpMake = createSelector([ 
        this.getMakeValue, this.getRequired, this.getPumpMakes,
    ], (value, required, pumpMakes) => {
        if (value) {
            const pumpMake = pumpMakes.find(element => element === value);

            if (pumpMake) {
                return { label: pumpMake, value: pumpMake };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    private readonly getSelectedPumpModel = createSelector([ 
        this.getModelValue, this.getRequired, this.getPumpModels,
    ], (value, required, pumpModels) => {
        if (value) {
            const pumpModel = pumpModels.find(element => element === value);

            if (pumpModel) {
                return { label: pumpModel, value: pumpModel };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, className, disabled, isLoading } = this.props;

        const dropdownDataForMakes = this.getPumpMakesForDropdown(this.state, this.props);
        const dropdownDataForModels = this.getPumpModelsForDropdown(this.state, this.props);

        const makeValue = this.getSelectedPumpMake(this.state, this.props);
        const modelValue = this.getSelectedPumpModel(this.state, this.props);

        return (
            <>
                <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !makeValue}>
                    <Autocomplete 
                        disabled={isLoading}
                        id={'pumpMake'}
                        options={dropdownDataForMakes}
                        value={makeValue}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onMakeChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => <TextField error={required && !makeValue} {...params} fullWidth label={'Pump Make'} />} 
                    />   
                    {
                        required && !makeValue &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !modelValue}>
                    <Autocomplete 
                        disabled={isLoading}
                        id={'pumpModel'}
                        options={dropdownDataForModels}
                        value={modelValue}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onModelChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => <TextField error={required && !modelValue} {...params} fullWidth label={'Pump Model'} />} 
                    />
                    {
                        required && !modelValue &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
            </>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.masterData.pumpingStation.pumpMake.isLoading,
        pumpMakes: state.masterData.pumpingStation.pumpMake.pumpMakes,
    };
};

const PumpingStationPumpMakeSelector = connect(
    mapStateToProps,
)(PumpingStationPumpMakeSelectorComponent);

export default PumpingStationPumpMakeSelector;
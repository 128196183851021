import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { IBoreholeCalibration } from '../../../../@types/model/borehole/boreholeCalibration';

interface IBoreholeCalibrationEntryNeighbourTableViewProps {
    calibration : IBoreholeCalibration;
    isLoading : boolean;
}

interface IBoreholeCalibrationEntryNeighbourTableViewState {
}

export default class BoreholeCalibrationEntryNeighbourTableView extends React.Component<IBoreholeCalibrationEntryNeighbourTableViewProps, IBoreholeCalibrationEntryNeighbourTableViewState> {
    constructor(props : IBoreholeCalibrationEntryNeighbourTableViewProps) {
        super(props);
        this.state = {
        };
    }

    public readonly render = () => {
        const { calibration } = this.props;

        return (
            <div className='fdr mt15'>
                <Table className={'bcw'}>
                    <TableHead className='bcp'>
                        <TableRow>
                            <TableCell className='cw p12'>WB NUMBER</TableCell>
                            <TableCell className='cw p12'>TIME</TableCell>
                            <TableCell className='cw p12'>Ground Level (m)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            calibration.NeighbouringBoreholes.map((neighbour, i) => (

                                <TableRow key={`tr_${i}`}>
                                    <TableCell className='p12'>{neighbour.BoreholeCode}</TableCell>
                                    <TableCell className='p12'>{neighbour.Time}</TableCell>
                                    <TableCell className='p12'>{!neighbour.WaterLevel ? '-' : neighbour.WaterLevel.toFixed(2)}</TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </div>
        );
    };
}

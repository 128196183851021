import { getState, dispatch } from '..';
import firebase from 'firebase/app';
import { flowmeterSetList, flowmeterSetLoading, flowmeterSetSelected } from './actions';
import lodash from 'lodash';
import generalFunctions from '../general/functions';
import FlowmeterHelper, { EnumFlowmeterMonitor, EnumFlowmeterWaterUsage, IFlowmeter } from '../../@types/model/flowmeter/flowmeter';
import moment from 'moment';
import firebaseApp from '../../services/firebaseService';

export default class FlowmeterFunctions {
    private static listListener ?: () => void;

    public static readonly getList = async (onLoaded ?: () => void) => {
        if (FlowmeterFunctions.listListener) {
            if (onLoaded) onLoaded();
            return;
        }
        dispatch(flowmeterSetLoading(true));
        dispatch(flowmeterSetList([]));

        try {
            FlowmeterFunctions.listListener = FlowmeterHelper.collection().onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IFlowmeter>) => {
                const state = getState().flowmeter;

                const list = state.flowmeters.slice();
            
                const session = getState().auth.session;
                if (!session) return;

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();

                    if (
                        !session.employee.IsFlowmeterAdmin &&
                        item.division &&
                        session.employee.Areas.length &&
                        !session.employee.Areas.includes(item.division)) return;

                    const index = lodash.findIndex(list, n => n.code === item.code);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(flowmeterSetList(list));

                if (state.selectedFlowmeter) {
                    FlowmeterFunctions.setSelected(list.find(n => n.code === state.selectedFlowmeter?.code));
                }

                dispatch(flowmeterSetLoading(false));
                if (onLoaded) onLoaded(); 
            }, (error) => {
                generalFunctions.generalShowErrorSnackbar(error.message ? error.message : 'An error while loading pumping stations.');
            });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading pumping stations.');
            dispatch(flowmeterSetLoading(false));
        }
    };

    public static readonly saveFlowmeter = async (flowmeter : IFlowmeter) => {

        const session = getState().auth.session;
    
        if (!session) return;
    
        dispatch(flowmeterSetLoading(true));
        flowmeter.updatedBy = session.firebaseUser.uid;
        flowmeter.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        flowmeter.updatedByName = session.employee.Name;
        flowmeter.updatedOn = moment.utc().valueOf();
    
        try {
            await FlowmeterHelper.save(flowmeter);
    
            return true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving pumping station. ${ex}`);
        } finally {
            dispatch(flowmeterSetLoading(false));
        }
    
        return false;
    
    };

    public static readonly setSelected = (item ?: IFlowmeter) => {
        dispatch(flowmeterSetSelected(item));
    };

    public static readonly create = async (
        guid : string,
        name : string,
        division : string,
        managementArea : string,
        waterUsageType : EnumFlowmeterWaterUsage | null,
        monitorType : EnumFlowmeterMonitor | null,
        waterUsageTypeDescription : string | null,
        monitorTypeDescription : string | null,
        geom : firebase.firestore.GeoPoint | null,
        elevation : number | null,

    ) => {
        dispatch(flowmeterSetLoading(true));

        try {
            const snapshots = await FlowmeterHelper.collection().orderBy('createdOn', 'desc').limit(1).get();
            const doc = snapshots.docs.length === 0 ? null : snapshots.docs[0];

            await firebaseApp.firestore().runTransaction(async (db) => {

                const transDoc = !doc?.ref ? null : await db.get(doc.ref);
                const data = transDoc?.data() as IFlowmeter | null;

                const session = getState().auth.session;
            
                if (!session) return;

                const newCode = data ? (Number(data.code.replace(/^FMP/, '')) + 1).toString().padStart(4, '0') : '0001';

                const flowmeter : IFlowmeter = {
                    id: '',
                    guid: guid,
                    code: `FMP${newCode}`,
                    geo: geom,
                    elevation,
                    name,
                    accpacDesc: '',
                    division,
                    divisionNo: '',
                    managementArea,
                    connected: false,
                    cumulativeFlowMonth: null,
                    cumulativeFlowWeek: null,
                    currentMeter: null,
                    lastFlowWeek: null,
                    lastReading: null,
                    monitor: null,
                    createdBy: session.firebaseUser.uid,
                    createdByName: session.employee.Name,
                    createdByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    createdOn: moment.utc().valueOf(),
                    updatedOn: moment.utc().valueOf(),
                    isActive: true,
                    isSent: false,
                    isWeb: true,
                    lastReadingDate: null,
                    monitorType,
                    monitorTypeDescription,
                    waterUsageType,
                    waterUsageTypeDescription,
                };

                db.set(FlowmeterHelper.collection().doc(), flowmeter);
            });
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while creating flowmeter. ${ex}`);
            throw ex;
        } finally {
            dispatch(flowmeterSetLoading(false));
        }
    };

    public static readonly deactivate = async (flowmeter : IFlowmeter) => {
        const session = getState().auth.session;

        if (!session) return;

        dispatch(flowmeterSetLoading(true));

        flowmeter.updatedOn = moment.utc().valueOf();
        flowmeter.updatedBy = session.firebaseUser.uid;
        flowmeter.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        flowmeter.updatedByName = session.employee.Name;
        flowmeter.isActive = false;

        try {
            if (!session.employee.IsFlowmeterAdmin) {
                throw new Error('Admin rights required.');
            }

            await FlowmeterHelper.save(flowmeter);

            FlowmeterFunctions.setSelected();

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while deactivating the pumping station occurred.');
        } finally {
            dispatch(flowmeterSetLoading(false));
        }
    };

    public static readonly activate = async (flowmeter : IFlowmeter) => {
        const session = getState().auth.session;

        if (!session) return;

        dispatch(flowmeterSetLoading(true));
        
        flowmeter.updatedOn = moment.utc().valueOf();
        flowmeter.updatedBy = session.firebaseUser.uid;
        flowmeter.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        flowmeter.updatedByName = session.employee.Name;
        flowmeter.isActive = true;

        try {
            if (!session.employee.IsFlowmeterAdmin) {
                throw new Error('Admin rights required');
            }

            await FlowmeterHelper.save(flowmeter);
            
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while activating the pumping station occurred.');
        } finally {
            dispatch(flowmeterSetLoading(false));
        }
    };
}
import React from 'react';
import boreholeFunctions from '../../store/borehole/functions';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import { BOREHOLE_STATUS } from '../../appConstants';
import BoreholeInfo from './Info';
import { Transitions } from '../customComponents/animations/Transitions';
import navFunctions from '../../store/nav/functions';
import BoreholeNewButton from '../customComponents/button/BoreholeNewButton';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import appFunctionsService from '../../services/appFunctionsService';
import { IBorehole } from '../../@types/model/borehole/borehole';
import BoreholeFilterSelector from '../customComponents/selector/borehole/FilterSelector';
import { IUserSession } from '../../@types/employee';
import { CSVLinkProps } from '../../@types/csv';

interface IBoreholeListProps {
    boreholes : Array<IBorehole>;
    isLoadingBoreholes : boolean;
    session ?: IUserSession | null;
}

interface IBoreholeListState {
    sortList : Array<{ field : string; direction : 'asc' | 'desc' }>;
    name : string;
    division : string;
    code : string;
    managementArea : string;
}

class BoreholeList extends React.Component<IBoreholeListProps, IBoreholeListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : IBoreholeListProps) {
        super(props);

        this.state = {
            sortList: [],
            name: '',
            division: '',
            code: '',
            managementArea: '',
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        boreholeFunctions.getBoreholes();
    };

    private readonly onSortChanged = (sortList : Array<{ field : string; direction : 'asc' | 'desc' }>) => {
        this.setState({
            sortList,
        });
    };

    public readonly getData = (state : IBoreholeListState, props : IBoreholeListProps) => props.boreholes;
    public readonly getSortList = (state : IBoreholeListState) => state.sortList;
    private readonly getCode = (state : IBoreholeListState) => state.code;
    private readonly getName = (state : IBoreholeListState) => state.name;
    private readonly getDivision = (state : IBoreholeListState) => state.division;
    private readonly getManagementArea = (state : IBoreholeListState) => state.managementArea;

    public readonly getFilteredData = createSelector(
        [this.getData, this.getSortList, this.getCode, this.getName, this.getDivision, this.getManagementArea],
        (boreholes, sortList, code, name, division, managementArea) => {
            let sortedList : Array<IBorehole> = boreholes.slice()
                .filter(n => (n.IsActive === undefined || n.IsActive))
                .filter(n => n.Code.toLowerCase().includes(code.toLowerCase()))
                .filter(n => n.Name.toLowerCase().includes(name.toLowerCase()))
                .filter(n => (n.Division ?? '').toLowerCase().includes(division.toLowerCase()))
                .filter(n => (n.ManagementArea ?? '').toLowerCase().includes(managementArea.toLowerCase()));

            if (sortList.length === 0) {
                return sortedList.sort((a, b) => parseInt(b.Code.substring(2)) - parseInt(a.Code.substring(2)));
            }

            sortList.forEach((n) => {
                if (n.field === 'Code') {
                    sortedList = sortedList.sort((a, b) => parseInt(b.Code.substring(2)) - parseInt(a.Code.substring(2)) * (n.direction === 'desc' ? 1 : -1));
                } else {
                    sortedList = lodash.orderBy(sortedList, n.field, n.direction);
                }
            });

            return sortedList;
        },
    );

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (boreholes) => {
            return boreholes.map(n => ({
                Code: n.Code,
                Name: n.Name,
                Division: n.Division,
                ManagementArea: n.ManagementArea,
                Latitude: !n.Geom ? 0 : n.Geom.latitude,
                Longitude: !n.Geom ? 0 : n.Geom.longitude,
                Status: !n.Status ? '' : BOREHOLE_STATUS[n.Status.toString()],
                Equipped: n.Equipped ? 'YES' : 'NO',
                HoleDepth: n.HoleDepth,
                InstallationDepth: n.InstallationDepth,
                AdjustedYield: n.AdjustedYield,
                InstalledYield: n.InstalledYield,
                StaticWaterLevel: n.StaticWaterLevel,
                LatestRuntime: n.LatestRuntime,
                LatestPumpRateDay: n.LatestRuntime && n.InstalledYield ? (n.InstalledYield * n.LatestRuntime / 60) : 0,
                User: n.User,
                Comment: n.Comment,
                CreatedOn: appFunctionsService.formatDateTime(n.CreatedOn),
                UpdatedOn: appFunctionsService.formatDateTime(n.UpdatedOn),
            }));
        },
    );

    public readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const borehole = this.props.boreholes.slice().find(x => x.Code === event.currentTarget.value);

        boreholeFunctions.setBorehole(borehole);
    };

    public readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const borehole = this.props.boreholes.slice().find(x => x.Code === event.currentTarget.value);

        if (borehole) {
            navFunctions.navBoreholeMap(borehole.Code);
        }
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    public readonly renderEquipped = (row : IBorehole) => {
        if (row.Equipped) {
            return (
                <div className={'jcc aic'}>
                    <Icon className={'cp'}>
                        check_circle
                    </Icon>
                </div>

            );
        }
        return (
            <div className={'jcc aic'}>
                <Icon className={'cr'}>
                    cancel
                </Icon>
            </div>
        );
    };

    private readonly onFilterChange = (code : string | null, name : string | null, division : string | null, managementArea : string | null) => {
        this.setState({
            name: name ?? '',
            code: code ?? '',
            managementArea: managementArea ?? '',
            division: division ?? '',
        });
    };

    public readonly render = () => {
        const { isLoadingBoreholes, session } = this.props;
        const boreholes = this.getFilteredData(this.state, this.props);
        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.state, this.props);
        const { code, name, division, managementArea } = this.state;
        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0 pr15 pl15'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography className={'fs20 fw500 cdg'}>
                            BOREHOLES
                        </Typography>
                        <span className={'flx4'} />
                        <div className={'flx1'}>
                            <BoreholeFilterSelector
                                id='borehole_filter'
                                onChange={this.onFilterChange}
                                name={name}
                                code={code}
                                division={division}
                                managementArea={managementArea}
                                label='Filter By...'
                                disabled={isLoadingBoreholes}
                            />
                        </div>
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pr15'}>
                                <Typography className={'fs16 fw500 cp'}>
                                    Total:
                                </Typography>
                                <Typography className={'fs16 fw600 cp'}>
                                    {boreholes.length}
                                </Typography>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IBorehole>
                            id='boreholesTable'
                            data={boreholes}
                            isLoading={isLoadingBoreholes}
                            rowsPerPage={50}
                            externalSort
                            onSortChange={this.onSortChanged}
                            onExportCsvClick={!session?.employee.IsBoreholeExport ? undefined : this.onCSVClick}
                            columns={[{
                                header: '',
                                width: 100,
                                paddingRight: 4,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton value={row.Code} onClick={this.onInfoClick} className={'cp'}>
                                                    <Icon>
                                                        info_outlined
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton value={row.Code} onClick={this.onMapClick} disabled={!row.Geom} className={'cp'}>
                                                    <Icon>
                                                        map
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'Code',
                                width: 125,
                                enableSort: true,
                            }, {
                                header: 'Name',
                                field: 'Name',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Division',
                                field: 'Division',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Management Area',
                                field: 'ManagementArea',
                                width: 200,
                                enableSort: true,
                            }, {
                                header: 'Status',
                                field: 'Status',
                                width: 100,
                                enableSort: true,
                                renderCell: row => (<div>{row.Status ? BOREHOLE_STATUS[row.Status.toString()] : 'None'}</div>),
                            }, {
                                header: 'Equipped',
                                field: 'Equipped',
                                width: 150,
                                enableSort: true,
                                align: 'center',
                                renderCell: row => this.renderEquipped(row),
                            }, {
                                header: 'Hole Depth',
                                field: 'HoleDepth',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Inst. Depth',
                                field: 'InstallationDepth',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Inst. Depth Rec.',
                                field: 'InstallDepthRecommendation',
                                width: 200,
                                enableSort: true,
                            }, {
                                header: 'Adjusted Yield',
                                field: 'AdjustedYield',
                                width: 175,
                                enableSort: true,
                                renderCell: row => (<div>{row.AdjustedYield ? row.AdjustedYield : '0'} m³/hr</div>),
                            }, {
                                header: 'Installed Yield',
                                field: 'InstalledYield',
                                width: 175,
                                enableSort: true,
                                renderCell: row => (<div>{row.InstalledYield ? row.InstalledYield : '0'} m³/hr</div>),
                            }, {
                                header: 'Latest Yield',
                                field: 'LatestYield',
                                width: 175,
                                enableSort: true,
                                renderCell: row => (<div>{row.LatestYield ? row.LatestYield : '0'} m³/hr</div>),
                            }, {
                                header: 'S. Water Level',
                                field: 'StaticWaterLevel',
                                width: 175,
                                enableSort: true,
                            }, {
                                header: 'Runtime',
                                field: 'LatestRuntime',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'L. Pump Rate / Day',
                                field: 'LatestRuntime',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<div>{row.LatestRuntime && row.LatestYield ? row.LatestYield * row.LatestRuntime / 60 : '0'} m³/hr</div>),
                            }, {
                                header: 'P. Pump Rate / Day',
                                field: 'InstalledYield',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (<div>{row.LatestRuntime && row.InstalledYield ? row.InstalledYield * row.LatestRuntime / 60 : '0'} m³/hr</div>),
                            }, {
                                header: 'User',
                                field: 'User',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Comment',
                                field: 'Comment',
                                width: 250,
                                enableSort: true,
                            }]}
                        />
                    </Card>
                    <div className={'fdrr mt15 mr15'}>
                        {
                            session?.employee.IsBoreholeCreateAdmin &&
                            <BoreholeNewButton />
                        }
                    </div>
                    <BoreholeInfo fullScreen transition={Transitions.Up} />
                    {
                        session?.employee.IsBoreholeExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'Code',
                                    label: 'Code',
                                },
                                {
                                    key: 'Name',
                                    label: 'Name',
                                },
                                {
                                    key: 'Division',
                                    label: 'Division',
                                },
                                {
                                    key: 'ManagementArea',
                                    label: 'Management Area',
                                },
                                {
                                    key: 'Status',
                                    label: 'Status',
                                },
                                {
                                    key: 'Latitude',
                                    label: 'Latitude',
                                },
                                {
                                    key: 'Longitude',
                                    label: 'Longitude',
                                },
                                {
                                    key: 'Equipped',
                                    label: 'Equipped',
                                },
                                {
                                    key: 'HoleDepth',
                                    label: 'Hole Depth',
                                },
                                {
                                    key: 'InstallationDepth',
                                    label: 'Installation Depth',
                                },
                                {
                                    key: 'AdjustedYield',
                                    label: 'Adjusted Yield(m³/hr)',
                                },
                                {
                                    key: 'InstalledYield',
                                    label: 'Installed Yield(m³/hr)',
                                },
                                {
                                    key: 'StaticWaterLevel',
                                    label: 'Static Water Level(m)',
                                },
                                {
                                    key: 'LatestRuntime',
                                    label: 'Latest Runtime',
                                },
                                {
                                    key: 'LatestPumpRateDay',
                                    label: 'Latest Pump Rate / Day(m³/day)',
                                },
                                {
                                    key: 'User',
                                    label: 'User',
                                },
                                {
                                    key: 'Comment',
                                    label: 'Comment',
                                },
                                {
                                    key: 'CreatedOn',
                                    label: 'Created On',
                                },
                                {
                                    key: 'UpdatedOn',
                                    label: 'Updated On',
                                },
                            ]}
                            filename={`${csvName}_borehole.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        boreholes: state.borehole.boreholes,
        isLoadingBoreholes: state.borehole.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(BoreholeList);

import React from 'react';
import { RouteComponentProps } from 'react-router';
import TariffHelper, { TariffType, ITransformerTariff } from '../../../@types/model/transformer/tariffs';
import FirebaseHelper from '../../../services/helper/firebaseHelper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import TariffEditDialog from './dialog/TariffEditDialog';
import { createSelector } from 'reselect';
import generalFunctions from '../../../store/general/functions';
import TariffTable from '../../customComponents/tariffTable/TariffTable';

interface ITransformerTariffListProps extends RouteComponentProps<never, never, {
    type : TariffType;
}> {

}

interface ITransformerTariffListState {
    type ?: TariffType;
    isLoading : boolean;
    tariffs : Array<ITransformerTariff>;
}

export default class TransformerTariffList extends React.PureComponent<ITransformerTariffListProps, ITransformerTariffListState> {
    private listen ?: () => void;
    constructor(props : ITransformerTariffListProps) {
        super(props);
        this.state = {
            isLoading: false,
            tariffs: [],
        };
    }

    public readonly componentDidMount = () => {
        this.setType();
    };

    public readonly componentWillUnmount = () => {
        if (this.listen) this.listen();
    };

    public readonly componentDidUpdate = (prevProps : ITransformerTariffListProps, prevState : ITransformerTariffListState) => {
        if (prevProps.location.search !== this.props.location.search) this.setType();

        if (prevState.type !== this.state.type) this.loadList();
    };

    private readonly setType = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const type = urlParams.get('type') as TariffType;

        this.setState({
            type,
        });
    };

    private readonly loadList = () => {
        if (!this.state.type) return;
        if (this.listen) this.listen();

        this.setState({
            isLoading: true,
            tariffs: [],
        });

        this.listen = TariffHelper.collection()
            .where('type', '==', this.state.type)
            .orderBy('startDate', 'asc')
            .onSnapshot((snapshot) => {

                const tariffs = this.state.tariffs.slice();
                FirebaseHelper.firestoreSnapshotDocChanges(snapshot, tariffs);

                this.setState({
                    isLoading: false,
                    tariffs,
                });
            }, (err) => {
                generalFunctions.generalShowError(err.message, 'An error occured while loading tariffs.');
                this.setState({
                    isLoading: false,
                });
            });
    };

    public readonly getTariffs = (props : ITransformerTariffListProps, state : ITransformerTariffListState) => state.tariffs;

    public readonly getLastTariff = createSelector(
        [this.getTariffs],
        (tariffs) => {
            return tariffs.find(x => !x.endDate);
        },
    );

    public readonly render = () => {
        const { type, tariffs, isLoading } = this.state;
        const lastTariff = this.getLastTariff(this.props, this.state);
        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit' className='mr20'>
                            TRANSFORMER {type?.toLocaleUpperCase() ?? ''} TARIFFS
                        </Typography>
                        {
                            type &&
                            <TariffEditDialog isNew type={type} value={lastTariff?.ref.id} fullScreen />
                        }
                        <span className={'flx1'} />
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{tariffs.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        {
                            type &&
                            <TariffTable
                                type={type}
                                tariffs={tariffs}
                                isLoading={isLoading}
                            />
                        }
                    </Card>
                </div>
            </div>
        );
    };
}

import { firestore } from 'firebase/app';
import { Moment } from 'moment';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { YupSchema } from '../../../services/helper/yupHelper';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';
import DamHelper, { IDam } from './dam';
import * as Yup from 'yup';
import moment from 'moment';
import uuid from 'uuid';
import { EmployeeHelper } from '../../employee';

export interface IDamSafetyInspectionDetail {
    no : string;
    description : string;
    app : boolean;
    commenceDate : number | null;
    completionDate : number | null;
    estimateCost : number | null;
    priority : number | null;
}

export interface IDamSafetyInspectionComment {
    comment : string;
    createdBy : string;
    createdByName : string;
    createdByEmployee : string;
    createdOn : number;
}

export interface IDamSafetyInspection extends IBasicBaseClass {
    damRef : string; // Dam Id
    damCode : string;
    date : number;

    guid : string;
    documentName : string | null;
    documentURL : string | null;
    employeeNumber : string;
    employeeName : string;

    name : string;
    owner : string;
    inspectionNumber : number;

    details : Array<IDamSafetyInspectionDetail>;

    comments : Array<IDamSafetyInspectionComment>;

    isSent : boolean;
    isActive : boolean;
}

export interface IDamSafetyInspectionCommentFormValues {
    comment : string;
    createdBy : string;
    createdByName : string;
    createdByEmployee : string;
    createdOn : Moment;
    isNew : boolean;
}

export interface IDamSafetyInspectionDetailFormValues {
    no : string;
    description : string;
    app : boolean;
    commenceDate : Moment | null;
    completionDate : Moment | null;
    estimateCost : number | null;
    priority : number | null;
}

export interface IDamSafetyInspectionFormValues {
    guid : string;
    date : Moment;

    employeeNumber : string | null;
    employeeName : string | null;

    name : string;
    owner : string;
    inspectionNumber : number | null;

    details : Array<IDamSafetyInspectionDetailFormValues>;

    comments : Array<IDamSafetyInspectionCommentFormValues>;
}

type YupShape = Record<keyof IDamSafetyInspectionFormValues, YupSchema>;
type YupDetailShape = Record<keyof IDamSafetyInspectionDetailFormValues, YupSchema>;
type YupCommentShape = Record<keyof IDamSafetyInspectionCommentFormValues, YupSchema>;

export default class DamSafetyInspectionHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'dam_safety_inspection';

    private static converter : firebase.firestore.FirestoreDataConverter<IDamSafetyInspection> = {
        fromFirestore: (snapshot) => {
            return DamSafetyInspectionHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDamSafetyInspection) : firebase.firestore.DocumentData => {
            return DamSafetyInspectionHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDamSafetyInspection {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IDamSafetyInspection,
            ...result,
            damRef: (data['damRef'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            details: data['details'].map((x : any) => ({
                ...x,
                commenceDate: x.commenceDate?.toMillis() ?? null,
                completionDate: x.completionDate?.toMillis() ?? null,
            })),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            comments: data['comments'].map((x : any) => ({
                ...x,
                createdBy: (x['createdBy'] as firestore.DocumentReference).id,
                createdOn: x.createdOn.toMillis(),
            })),
        };
    }

    public static toFirestoreDocument(data : IDamSafetyInspection) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            damRef: DamHelper.doc(data.damRef),
            date: firestore.Timestamp.fromMillis(data.date),
            details: data.details.map((x) => ({
                ...x,
                commenceDate: !x.commenceDate ? null : firestore.Timestamp.fromMillis(x.commenceDate),
                completionDate: !x.completionDate ? null : firestore.Timestamp.fromMillis(x.completionDate),
            })),
            comments: data.comments.map((x) => ({
                ...x,
                createdBy: EmployeeHelper.doc(x.createdBy),
                createdOn: firestore.Timestamp.fromMillis(x.createdOn),
            })),
        };
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .withConverter(this.converter);
    }

    public static save(data : IDamSafetyInspection) {
        if (!data.id) {
            return DamSafetyInspectionHelper.doc().set(data, {
                merge: true,
            });
        }

        return DamSafetyInspectionHelper.doc(data.id).set(data, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().withConverter(DamSafetyInspectionHelper.converter).doc();
        }

        return this.collection().withConverter(DamSafetyInspectionHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return this.doc(id)
            .delete();
    }

    public static uploadFile(damCode : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `dam/${damCode}/dam_safety_inspection/${new Date().valueOf()}_${file.name}`, metadata);
    }
    public static initialFormValues(damSafetyInspection ?: IDamSafetyInspection, dam ?: IDam) : IDamSafetyInspectionFormValues {
        return {
            guid: damSafetyInspection?.guid ?? uuid.v4(),
            date: moment.utc(damSafetyInspection?.date).startOf('day'),
            employeeName: damSafetyInspection?.employeeName ?? '',
            employeeNumber: damSafetyInspection?.employeeNumber ?? '',
            owner: damSafetyInspection?.owner ?? dam?.ownerName ?? '',
            name: damSafetyInspection?.name ?? dam?.name ?? '',
            inspectionNumber: damSafetyInspection?.inspectionNumber ?? null,
            comments: damSafetyInspection?.comments.map(x => ({
                ...x,
                createdOn: moment.utc(x.createdOn),
                isNew: false,
            })) ?? [],
            details: damSafetyInspection?.details.map(x => ({
                ...x,
                commenceDate: !x.commenceDate ? null : moment.utc(x.commenceDate).startOf('day'),
                completionDate: !x.completionDate ? null : moment.utc(x.completionDate).startOf('day'),
            })) ?? [],
        };
    }

    public static formSchema = () => Yup.object<YupShape>({
        guid: Yup.string().required('Required'),
        date: Yup.date().nullable().moment().required('Required'),
        employeeName: Yup.string().required('Required'),
        employeeNumber: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        owner: Yup.string().required('Required'),
        inspectionNumber: Yup.number().required('Required').moreThan(0, 'Invalid'),
        comments: Yup.array(
            Yup.object<YupCommentShape>({
                isNew: Yup.bool().required('Required'),
                comment: Yup.string().required('Required'),
                createdBy: Yup.string().required('Required'),
                createdByEmployee: Yup.string().required('Required'),
                createdByName: Yup.string().required('Required'),
                createdOn: Yup.date().nullable().moment().required('Required'),
            })
        ),
        details: Yup.array<YupDetailShape>(
            Yup.object<YupDetailShape>({
                app: Yup.bool().required('Required'),
                commenceDate: Yup.date().nullable().moment(),
                completionDate: Yup.date().nullable().moment(),
                description: Yup.string().required('Required'),
                estimateCost: Yup.number().nullable().optional(),
                no: Yup.string().required('Required'),
                priority: Yup.number().nullable().required('Required'),
            })
        ),
    });
}
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import firebaseApp from '../../../services/firebaseService';
import { firestore } from 'firebase/app';
import DamHelper from './dam';

export interface IDamVNotchReadingFile {
    guid : string;
    firestoreLocation : string;
    downloadURL : string;
    thumbnailUrl : string | null;
    name : string;
    type : 4;
    uploadDate : number;
    contentType : string;
}

export interface IDamVNotchReading extends IBasicBaseClass {
  guid : string;
  damCode : string;
  dam : string; // Dam Id
  measuredHeight : number;
  measuredFlow : number;
  date : number;
  employee : string;
  employeeName : string;
  image : IDamVNotchReadingFile | null;
}

export default class DamVNotchReadingHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'dam_vnotch_reading';

    private static converter : firebase.firestore.FirestoreDataConverter<IDamVNotchReading> = {
        fromFirestore: (snapshot) => {
            return DamVNotchReadingHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDamVNotchReading) : firebase.firestore.DocumentData => {
            return DamVNotchReadingHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDamVNotchReading {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IDamVNotchReading,
            ...result,
            dam: (data['dam'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : IDamVNotchReading) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            dam: DamHelper.doc(data.dam),
            date: firestore.Timestamp.fromMillis(data.date),
        };
    }

    public static doc(id : string) {
        if (!id) {
            return firebaseApp.firestore().collection(DamVNotchReadingHelper.COLLECTION_NAME).withConverter(DamVNotchReadingHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(DamVNotchReadingHelper.COLLECTION_NAME).withConverter(DamVNotchReadingHelper.converter).doc(id);
    }

    public static listen(damCode ?: string) {
        return firebaseApp
            .firestore()
            .collection(DamVNotchReadingHelper.COLLECTION_NAME)
            .where('damCode', '==', damCode).withConverter(DamVNotchReadingHelper.converter);
    }

    public static delete (id : string) {
        return firebaseApp
            .firestore()
            .collection(DamVNotchReadingHelper.COLLECTION_NAME)
            .doc(id)
            .delete();
    }
}

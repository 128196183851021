import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import lodash from 'lodash';
import DamHelper, { EnumDamStatus } from '../../../../@types/model/dam/dam';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField } from '@material-ui/core';
import { createSelector } from 'reselect';

export interface IDamConstructionTypeDropDownOption {
    label : string;
    value : EnumDamStatus | null;
}

interface IDamStatusTypeSelectorProps {
    value : EnumDamStatus | null;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : EnumDamStatus | null) => void;
}

interface IDamStatusTypeSelectorState { }

class DamStatusTypeSelector extends React.Component<IDamStatusTypeSelectorProps, IDamStatusTypeSelectorState> {
    constructor(props : IDamStatusTypeSelectorProps) {
        super(props);

        this.state = {};
    }   

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? null);
    };

    private readonly getValue = (state : IDamStatusTypeSelectorState, props : IDamStatusTypeSelectorProps) => props.value;
    private readonly getRequired = (state : IDamStatusTypeSelectorState, props : IDamStatusTypeSelectorProps) => props.required;

    private readonly getStatuses = () => DamHelper.DamStatus;

    private readonly getStatusesForDropdown = createSelector([
        this.getRequired, this.getStatuses,
    ], (required, statuses) => {
        const dropdownData = lodash.map(statuses, (element, index) => ({ label: element, value: lodash.parseInt(index) } as IDamConstructionTypeDropDownOption));

        if (!required) {
            dropdownData.unshift({
                label: 'NONE',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getStatuses,
    ], (value, required, statuses) => {
        if (value != null) {
            const status = statuses[value];

            if (status) {
                return { label: status, value: value } as IDamConstructionTypeDropDownOption;
            }
        }
        return null;
    });

    public render = () => {

        const { required, className, disabled } = this.props;

        const dropdownData = this.getStatusesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'status'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Dam Status'} />}  
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };

}

export default DamStatusTypeSelector;
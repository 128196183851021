import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { ITransformerAttribute } from '../../../../@types/model/transformer/transformerAttribute';
import { IRootState } from '../../../../@types/redux';
import DataFunctions from '../../../../store/data/functions';
import TransformerMeterReadingProblem from '../../TransformerMeterReadingProblem';

interface ISPUMeterProblemSelectorProps {
    attributes : Array<ITransformerAttribute>;
    isLoading ?: boolean;

    values : Array<string>;
    onChange ?: (values : Array<string>) => void;
}

interface ISPUMeterProblemSelectorState {}

class SPUMeterProblemSelector extends React.PureComponent<ISPUMeterProblemSelectorProps, ISPUMeterProblemSelectorState> {
    constructor(props : ISPUMeterProblemSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onConditionClick = (value : string) => {
        const conditions = [ ...this.props.values ];
        const index = conditions.indexOf(value);

        if (index < 0) {
            conditions.push(value);
        } else {
            conditions.splice(index, 1);
        }
        
        if (this.props.onChange) this.props.onChange(conditions);
    };

    public readonly componentDidMount = () => {
        DataFunctions.getTransformerAttributes();
    };

    public readonly render = () => {
        const { isLoading, attributes, values } = this.props;
        return (
            <div className='fdr fww cw'>
                {
                    isLoading &&
                    <LinearProgress className='wfill' />
                }
                {
                    attributes
                        .filter(x => x.IsCondition)
                        .map((n, i) => (
                            <TransformerMeterReadingProblem
                                enabled={!isLoading}
                                checked={values.includes(n.Value)}
                                key={`${i}_problem`}
                                onClick={this.onConditionClick}
                                text={n.Value}
                            />
                        ))
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        attributes: state.data.transformerAttributes,
        isLoading: state.data.isLoadingTransformerAttributes,
    };
};

const TransformerSPUMeterProblemSelector = connect(
    mapStateToProps,
)(SPUMeterProblemSelector);

export default TransformerSPUMeterProblemSelector;

import React from 'react';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import MaterialTable from '../../customComponents/materialTable/Table';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import TransformerPrepaidReadingHelper, { ITransformerPrepaidReading } from '../../../@types/model/transformer/transformerPrepaidReading';
import generalFunctions from '../../../store/general/functions';
import lodash from 'lodash';
import appFunctionsService from '../../../services/appFunctionsService';
import Tooltip from '@material-ui/core/Tooltip';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../@types/employee';
import { Warning } from '@material-ui/icons';
import moment from 'moment';
import { Fab, Icon } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { CSVLinkProps } from '../../../@types/csv';
import DateRangePicker from '../../customComponents/input/DateRangePicker';
import EditTransformerPrepaidReadingEdit from './dialog/PrepaidMeterReadingEditDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditTransformerPrepaidReadingDialog from './dialog/PrepaidMeterReadingEditDialog';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import TransformerPrepaidReadingDeleteDialog from './dialog/PrepaidMeterReadingDeleteDialog';
import EmbedFileDialog from '../../customComponents/dialog/EmbededFileDialog';

interface ITransformerPrepaidMeterListProps {
    isTransformerAdmin : boolean;

    session ?: IUserSession | null;
}

interface ITransformerPrepaidMeterListState {
    transformerNumber ?: string;
    readings : Array<ITransformerPrepaidReading>;
    isLoading : boolean;
    dateFrom : moment.Moment | null;
    dateTo : moment.Moment | null;
    onlyErrors : boolean;

    isCarouselOpen : boolean;
    imageIndex : number;
    embedSrc ?: string;
}

class TransformerPrepaidMeterListClass extends React.Component<ITransformerPrepaidMeterListProps, ITransformerPrepaidMeterListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    private readingListener ?: () => void;

    constructor(props : ITransformerPrepaidMeterListProps) {
        super(props);

        this.state = {
            readings: [],
            isLoading: false,
            dateFrom: null,
            dateTo: null,
            onlyErrors: false,
            isCarouselOpen: false,
            imageIndex: 0,
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    private readonly loadData = async () => {
        if (this.readingListener) this.readingListener();

        this.setState({
            isLoading: true,
        });

        const session = this.props.session;

        if (!session) return;

        this.readingListener = TransformerPrepaidReadingHelper.listen().onSnapshot((snap) => {
            const readings = this.state.readings.slice();

            snap.docChanges().forEach((f) => {
                const item = f.doc.data();
                if (
                    !session.employee.IsTransformerAdmin &&
                    item.division &&
                    session.employee.Areas.length &&
                    (!session.employee.Areas.includes(item.division) && !session.employee.Areas.includes(item.subArea))) return;

                const index = lodash.findIndex(readings, n => n.id === item.id);

                switch (f.type) {
                    case 'added':
                        readings.push(item);
                        break;
                    case 'modified':
                        readings.splice(index, 1, item);
                        break;
                    case 'removed':
                        readings.splice(index, 1);
                        break;
                }
            });

            this.setState({
                readings,
                isLoading: false,
            });
        }, (err) => {
            generalFunctions.generalShowErrorSnackbar(err.message);
        });
    };
    
    private readonly getData = (props : ITransformerPrepaidMeterListProps, state : ITransformerPrepaidMeterListState) => state.readings;
    private readonly getTransformerNumber = (props : ITransformerPrepaidMeterListProps, state : ITransformerPrepaidMeterListState) => state.transformerNumber;
    private readonly getFromDate = (props : ITransformerPrepaidMeterListProps, state : ITransformerPrepaidMeterListState) => state.dateFrom;
    private readonly getToDate = (props : ITransformerPrepaidMeterListProps, state : ITransformerPrepaidMeterListState) => state.dateTo;
    private readonly getOnlyErrors = (props : ITransformerPrepaidMeterListProps, state : ITransformerPrepaidMeterListState) => state.onlyErrors;

    public readonly getFilteredData = createSelector(
        [
            this.getData,
            this.getTransformerNumber,
            this.getFromDate,
            this.getToDate,
            this.getOnlyErrors,
        ],
        (readings, transformerNumber, fromDate, toDate, onlyErrors) => {
            return readings
                .filter(n => !transformerNumber || n.transformerRef === transformerNumber)
                .filter(n => !fromDate || n.date >= fromDate.valueOf())
                .filter(n => !toDate || n.date <= toDate.valueOf())
                .filter(n => !onlyErrors || this.hasError(n))
                .sort((a, b) => b.date - a.date);
        },
    );

    public readonly getImages = createSelector(
        [this.getData],
        (readings) => {
            const images = readings
                .filter(x => !x.imageFileName?.includes('.pdf'))
                .filter(x => !!x.imageFileUrl)
                .map(n => n.imageFileUrl ?? '');

            return images;
        }
    );

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            transformerNumber: value?.EPNumber,
        });
    };

    private readonly hasError = (reading : ITransformerPrepaidReading) => reading.amount < 0;

    private readonly onDelete = async (reading : ITransformerPrepaidReading) => {
        const session = this.props.session;

        if (!session?.employee.IsTransformerAdmin) return;

        await TransformerPrepaidReadingHelper.delete(reading.id);
    };
    
    private readonly onDateSelectionChange = (start : moment.Moment | null, end : moment.Moment | null) => {
        this.setState({
            dateFrom: !start ? null : moment(start),
            dateTo: !end ? null : moment(end),
        });
    };

    private readonly onShowOnlyErrorClick = () => {
        this.setState({
            onlyErrors: !this.state.onlyErrors,
        });
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (readings) => {
            return readings.map(n => ({
                transformer: n.transformerRef,
                date: appFunctionsService.formatDateTime(n.date),
                division: n.division,
                group: n.group,
                amount: n.amount,
                createdByName: n.createdByName,
                createdByEmployee: n.createdByEmployee,
                createdOn: appFunctionsService.formatDateTime(n.createdOn),
                updatedByName: n.updatedByName,
                updatedByEmployee: n.updatedByEmployee,
                updatedOn: appFunctionsService.formatDateTime(n.updatedOn),
            }));
        },
    );

    private readonly onImageClick = (event : React.MouseEvent<HTMLImageElement>) => {
        const imageSrcs = this.getImages(this.props, this.state);

        const index = imageSrcs.indexOf(event.currentTarget.id);

        if (index >= 0) {
            this.setState({
                isCarouselOpen: true,
                imageIndex: index,
            });
        } else {
            this.setState({
                embedSrc: event.currentTarget.id,
            });
        }
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
            imageIndex: 0,
        });
    };

    private readonly onEmbedClose = () => {
        this.setState({
            embedSrc: '',
        });
    };

    public readonly render = () => {
        const {
            transformerNumber,
            isLoading,
            dateFrom,
            dateTo,
            onlyErrors,
            imageIndex,
            isCarouselOpen,
            embedSrc,
        } = this.state;

        const {
            isTransformerAdmin,
            session,
        } = this.props;

        const readings = this.getFilteredData(this.props, this.state);

        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.props, this.state);

        const imageSrcs = this.getImages(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <Toolbar>
                    <span className={'flx1'} />
                    <div style={{ paddingRight: '15px' }}>
                    </div>
                    <div className='pr20'>
                        <Tooltip title='Show only Errors'>
                            <Fab color={onlyErrors ? 'primary' : 'default'} size='small' onClick={this.onShowOnlyErrorClick}>
                                <Icon className='fs20'>
                                    warning
                                </Icon>
                            </Fab>
                        </Tooltip>
                    </div>
                    <div className={'aic p5 mb10 w350'}>
                        <DateRangePicker onChange={this.onDateSelectionChange} start={dateFrom} end={dateTo} />
                    </div>
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className='fdc flx1 w240'>
                            <TransformerDropdown
                                type='prepaid'
                                value={transformerNumber}
                                label='Select Transformer'
                                fullWidth
                                onChange={this.onEPNumberChange}
                            />
                        </div>
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<ITransformerPrepaidReading>
                        id='transformerReadingsTable'
                        data={readings}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        onExportCsvClick={this.onCSVClick}
                        columns={[{
                            header: '',
                            field: 'transformerRef',
                            width: 130,
                            renderCell: row => (
                                <div className=' fdr aic'>
                                    {
                                        (isTransformerAdmin || (row.createdBy === session?.firebaseUser.uid)) &&
                                        <EditTransformerPrepaidReadingDialog
                                            key={`${row.guid}_edit`} fullWidth
                                            maxWidth='md'
                                            reading={row}
                                            transition={Transitions.Down}
                                            session={session}
                                        />
                                    }
                                    {
                                        (isTransformerAdmin || (row.createdBy === session?.firebaseUser.uid)) &&
                                        <TransformerPrepaidReadingDeleteDialog
                                            key={`${row.guid}_delete`}
                                            fullWidth
                                            maxWidth='md'
                                            reading={row}
                                            transition={Transitions.Down}
                                            onDelete={this.onDelete}
                                        />

                                    }
                                    {
                                        !!row.isWeb &&
                                        <Icon className='cp ml10'>
                                            laptop
                                        </Icon>                                            
                                    }
                                    {
                                        !row.isWeb &&
                                        <Icon className='cp ml10'>
                                            smartphone
                                        </Icon>                                            
                                    }
                                    {
                                        this.hasError(row) &&
                                        <Tooltip title={'Transformer reading has a negative usage'}>
                                            <Warning className={'cy pl10'}/>
                                        </Tooltip>
                                    }
                                </div>
                            ),
                        }, {
                            header: 'Date',
                            field: 'date',
                            width: 125,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (<span>{appFunctionsService.formatDateTimeToDateOnly(row.date)}</span>),
                        }, {
                            header: 'EP Point',
                            field: 'transformerRef',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Area',
                            field: 'division',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Sub Area',
                            field: 'subArea',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Group',
                            field: 'group',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Electricity VAT',
                            field: 'electricityVat',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(!row.electricityVat ? 0 : row.electricityVat).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Electricity Amount',
                            field: 'electricityExlVat',
                            width: 210,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(!row.electricityExlVat ? 0 : row.electricityExlVat).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Electricity Units',
                            field: 'electricityUnits',
                            width: 210,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(!row.electricityUnits ? 0 : row.electricityUnits)} kWh
                                </div>
                            ),
                        }, {
                            header: 'Water VAT',
                            field: 'waterVat',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(!row.waterVat ? 0 : row.waterVat).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Water Amount',
                            field: 'waterExlVat',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(!row.waterExlVat ? 0 : row.waterExlVat).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'VAT Subtotal',
                            field: 'vatTotal',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(!row.vatTotal ? 0 : row.vatTotal).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Total Amount',
                            field: 'amount',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(!row.amount ? 0 : row.amount).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'File',
                            field: 'imageFileUrl',
                            width: 100,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (row.imageFileUrl ?
                                <Icon id={row.imageFileUrl} className={'cpd curp aic'} onClick={this.onImageClick}>
                                    check_circle
                                </Icon> :
                                <Icon className={'cr aic'}>
                                    close
                                </Icon>
                            ),
                        }, {
                            header: 'Created By',
                            field: 'createdByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.createdByEmployee}>
                                    <div>
                                        {
                                            row.createdByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }, {
                            header: 'Updated By',
                            field: 'updatedByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.updatedByEmployee}>
                                    <div>
                                        {
                                            row.updatedByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <EditTransformerPrepaidReadingEdit
                        aria-label='add'
                        maxWidth='md'
                        fullWidth
                        session={session}
                    />
                </div>
                <CSVLink
                    data={csvData}
                    headers={[
                        {
                            key: 'transformer',
                            label: 'EP Number',
                        },
                        {
                            key: 'date',
                            label: 'Date',
                        },
                        {
                            key: 'division',
                            label: 'Area',
                        },
                        {
                            key: 'Group',
                            label: 'group',
                        },
                        {
                            key: 'amount',
                            label: 'Amount',
                        },
                        {
                            key: 'createdByName',
                            label: 'CreatedByName',
                        },
                        {
                            key: 'createdByEmployee',
                            label: 'CreatedByEmployee',
                        },
                        {
                            key: 'createdOn',
                            label: 'CreatedOn',
                        },
                        {
                            key: 'updatedByName',
                            label: 'UpdatedByName',
                        },
                        {
                            key: 'updatedByEmployee',
                            label: 'UpdatedByEmployee',
                        },
                        {
                            key: 'updatedOn',
                            label: 'UpdatedOn',
                        },
                    ]}
                    filename={`${csvName}_prepaid_tansformers.csv`}
                    className='dn'
                    ref={this.csvLink}
                    target='_blank'/>
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
                <EmbedFileDialog src={embedSrc} onClose={this.onEmbedClose} fullWidth maxWidth='md' />
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isTransformerAdmin: !!state.auth.session?.employee.IsTransformerAdmin,
        session: state.auth.session,
    };
};

const TransformerPrepaidMeterList = connect(
    mapStateToProps,
)(TransformerPrepaidMeterListClass);

export default TransformerPrepaidMeterList;
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { Form } from 'informed';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import dataFunctions from '../../../../store/data/functions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';

interface IEmployeeRegisterDialogProps {
    open : boolean;
    onClose : () => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEmployeeRegisterDialogState {
    name : string;
    employeeNumber : string;
}

export default class EmployeeRegisterDialog extends React.Component<IEmployeeRegisterDialogProps, IEmployeeRegisterDialogState> {
    constructor(props : IEmployeeRegisterDialogProps) {
        super(props);
        this.state = {
            name: '',
            employeeNumber: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEmployeeRegisterDialogProps) => {
        if (prevProps.open && !this.props.open) {
            this.setState({
                name: '',
            });
        }
    };

    private readonly onSubmit = () => {
        this.onCreateClick();
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onEmployeeNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            employeeNumber: event.currentTarget.value,
        });
    };

    private readonly createEmployee = async () => {
        if (this.state.employeeNumber) {
            const result = await dataFunctions.registerEmployee(this.state.employeeNumber, this.state.name);

            if (result) {
                this.props.onClose();
            }
        }
    };

    private readonly onCreateClick = () => {
        this.createEmployee();
    };

    public readonly render = () => {
        const { open, onClose, transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { name, employeeNumber } = this.state;

        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={isLoading ? undefined : onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='confirm-dialog-title'
                aria-describedby='confirm-dialog-description'
            >
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Register Employee
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Add'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 hfill'>
                        <div className='fdc flx1 hfill'>
                            <div className={'aic mb10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='name'
                                        label='Name'
                                        value={name ? name : ''}
                                        onChange={this.onNameChange}
                                        margin='normal'
                                        className={'TextField'}
                                    />
                                </FormControl>
                            </div>
                            <div className={'aic mb10'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete='off'
                                        id='employeeNumber'
                                        label='Employee Number'
                                        value={employeeNumber ? employeeNumber : ''}
                                        required
                                        error={!employeeNumber}
                                        margin='normal'
                                        className={'TextField'}
                                        onChange={this.onEmployeeNumberChange}
                                    />
                                    {
                                        !employeeNumber &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>);
    };
}

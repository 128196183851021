import firebase from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';

export enum EnumPumpingStationFileType {
    ElectricalBox = 0,
}

export interface IPumpingStationFile {
    guid : string;
    firestoreLocation : string;
    downloadURL : string | null;
    thumbnailUrl : string | null;
    name : string;
    type : EnumPumpingStationFileType;
    uploadDate : number;
    contentType : string;
}

export interface IPumpingStationConnection {
    ref : firebase.firestore.DocumentReference;
    description : string;
    type : number;
}

export interface IPumpingStation extends IBasicBaseClass {
    code : string;
    name : string;
    guid : string;
    geo : firebase.firestore.GeoPoint | null;
    elevation : number | null;
    division : string | null;
    managementArea : string | null;
    divisionNo : string | null;
    accpacDesc : string | null;
    transformer : firebase.firestore.DocumentReference | null;
    connections : Array<IPumpingStationConnection>;
    isActive ?: boolean;

    electricalBoxFiles : Array<IPumpingStationFile>;

    isSent : boolean;
}

export default class PumpingStationHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station';

    public static readonly PURPOSES : Array<string> = ['Irrigation', 'Transfer', 'Irrigation & Transfer', 'Gravitation', 'Booster'];

    public static readonly MOTOR_PHASES : Array<string> = [
        'Single-Phase',
        'Three-Phase',
    ];
    
    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStation> = {
        fromFirestore: (snapshot) => {
            return PumpingStationHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStation) : firebase.firestore.DocumentData => {
            return PumpingStationHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStation {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStation,
            ...result,
            electricalBoxFiles: data?.electricalBoxFiles ?? [],
        };
    }

    public static toFirestoreDocument(data : IPumpingStation) {
        const result = super.toFirestore(data);
        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static save(pumpingStation : IPumpingStation) {
        return PumpingStationHelper.doc(pumpingStation.id).set(pumpingStation, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationHelper.COLLECTION_NAME).withConverter(PumpingStationHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationHelper.COLLECTION_NAME).withConverter(PumpingStationHelper.converter).doc(id);
    }

    public static listen() {
        return firebaseApp
            .firestore()
            .collection(PumpingStationHelper.COLLECTION_NAME)
            .withConverter(PumpingStationHelper.converter);
    }

    public static mapToCsv(stations : Array<IPumpingStation>) {
        return stations.map(station => ({
            code: station.code,
            name: station.name,
            division: station.division,
            managementArea: station.managementArea,
        }));
    }

    public static uploadFile(id : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `pumping_station/${id}/${new Date().valueOf()}_${file.name}`, metadata);
    }

    public static getQrCodeText(station : IPumpingStation) {
        return `${station.code}`;
    }
}
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';

interface IFileUploadButtonProps {
    accept ?: string;
    multiple ?: boolean;

    title : NonNullable<React.ReactNode>;
    onFileSelected ?: (file : File) => void;
}

interface IFileUploadButtonState {}

export default class FileUploadButton extends React.PureComponent<IFileUploadButtonProps, IFileUploadButtonState> {
    private readonly inputRef : React.RefObject<HTMLInputElement>;
    constructor(props : IFileUploadButtonProps) {
        super(props);
        this.state = {};

        this.inputRef = React.createRef<HTMLInputElement>();

    }


    public readonly onClick = () => {
        this.inputRef.current?.click();
    };

    private readonly onFileChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files?.length) return;
        if (!this.props.onFileSelected) return;

        const file = event.currentTarget.files[0];

        this.props.onFileSelected(file);
    };
    
    public readonly render = () => {
        const { title, accept, multiple } = this.props;

        return (
            <Tooltip title={title}>
                <div>
                    <input
                        ref={this.inputRef}
                        accept={accept}
                        multiple={multiple}
                        type='file'
                        autoComplete='off'
                        tabIndex={-1}
                        className='dn'
                        onChange={this.onFileChange}
                    />
                    <IconButton onClick={this.onClick}>
                        <Icon>upload</Icon>
                    </IconButton>
                </div>
            </Tooltip>
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import transformerFunctions from '../../../store/transformer/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { ITransformerLpuReading } from '../../../@types/model/transformer/transformerLpuReading';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import StandardFab from '../../customComponents/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';

interface IEditTransformerLpuReadingEditProps {
    reading ?: ITransformerLpuReading;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    open ?: boolean;
    onClose ?: (open : boolean) => void;

    disabled ?: boolean;

    transformerRef ?: string;
}

interface IEditTransformerLpuReadingEditState {
    open : boolean;
    date : moment.Moment | null;
    peak : number;
    standard : number;
    offPeak : number;
    max : number;
    dateOfMax : moment.Moment | null;
    timeOfMax : moment.Moment | null;
    comment : string;

    area : string;
    group : string;

    transformerRef ?: string;
    isLoading : boolean;
}

export default class EditTransformerLpuReadingEdit extends React.Component<IEditTransformerLpuReadingEditProps, IEditTransformerLpuReadingEditState> {
    constructor(props : IEditTransformerLpuReadingEditProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
            date: null,
            peak: 0,
            standard: 0,
            offPeak: 0,
            max: 0,
            dateOfMax: null,
            timeOfMax: null,
            comment: '',
            area: '',
            group: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTransformerLpuReadingEditProps, prevState : IEditTransformerLpuReadingEditState) => {
        if (prevProps.open !== this.props.open) {
            this.setState({
                open: !!this.props.open,
            });
        }

        if (!this.props.reading && prevState.open && !this.state.open) {
            this.setState({
                date: null,
                peak: 0,
                standard: 0,
                offPeak: 0,
                max: 0,
                dateOfMax: null,
                timeOfMax: null,
                comment: '',
                transformerRef: '',
                area: '',
                group: '',
            });
        }

        if (this.props.reading && !prevState.open && this.state.open) {
            this.setState({
                date: !this.props.reading.date ? moment().utc().startOf('day') : moment.utc(this.props.reading.date),
                peak: !this.props.reading.peakReading ? 0 : this.props.reading.peakReading,
                standard: !this.props.reading.standardReading ? 0 : this.props.reading.standardReading,
                offPeak: !this.props.reading.offPeakReading ? 0 : this.props.reading.offPeakReading,
                max: !this.props.reading.maxDemand ? 0 : this.props.reading.maxDemand,
                dateOfMax: !this.props.reading.dateOfMaxDemand ? moment().utc().startOf('day') : moment.utc(this.props.reading.dateOfMaxDemand),
                timeOfMax: !this.props.reading.timeOfMaxDemand ? moment().utc() : moment.utc(this.props.reading.timeOfMaxDemand, 'HH:mm'),
                comment: !this.props.reading.comment ? '' : this.props.reading.comment,
                transformerRef: !this.props.reading.transformerRef ? '' : this.props.reading.transformerRef,
                area: !this.props.reading.division ? '' : this.props.reading.division,
                group: !this.props.reading.group ? '' : this.props.reading.group,
            });
        }
    };

    private readonly onPeakChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            peak: Number(event.currentTarget.value),
        });
    };

    private readonly onStandardChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            standard: Number(event.currentTarget.value),
        });
    };

    private readonly onOffPeakChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            offPeak: Number(event.currentTarget.value),
        });
    };
    
    private readonly onMaxChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            max: Number(event.currentTarget.value),
        });
    };
    
    private readonly onCommentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            comment: event.currentTarget.value,
        });
    };

    private readonly onDateOfMaxChange = (date : moment.Moment | null) => {
        this.setState({
            dateOfMax: date?.asUTC() ?? null,
        });
    };

    private readonly onTimeOfMaxChange = (date : moment.Moment | null) => {
        this.setState({
            timeOfMax: date?.asUTC() ?? null,
        });
    };

    private readonly onDateChange = (date : moment.Moment | null) => {
        this.setState({
            date: date?.asUTC() ?? null,
        });
    };

    private readonly save = async () => {
        const { peak, offPeak, standard, max, dateOfMax, timeOfMax, comment, date,
            transformerRef, group, area } = this.state;
    
        const reading = Object.assign({}, this.props.reading);

        reading.peakReading = peak;
        reading.standardReading = standard;
        reading.offPeakReading = offPeak;
        reading.maxDemand = max;
        reading.dateOfMaxDemand = dateOfMax?.startOf('day').valueOf() ?? 0;
        reading.timeOfMaxDemand = timeOfMax?.asUTC().format('HH:mm') ?? '';
        reading.date = date?.startOf('day').valueOf() ?? 0;
        reading.comment = comment;
        reading.division = area;
        reading.group = group;

        if (transformerRef) reading.transformerRef = transformerRef;

        if (!reading.transformerRef) return;

        this.setState({
            isLoading: true,
        });

        const result = await transformerFunctions.saveLpuReading(reading);

        this.setState({
            isLoading: false,
        });

        if (result) {
            this.onClose();
        }

    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        }, () => {
            if (this.props.onClose) this.props.onClose(this.state.open);
        });
    };

    private readonly onClick = () => {
        this.setState({
            open: true,
        }, () => {
            if (this.props.onClose) this.props.onClose(this.state.open);
        });
    };

    private readonly onTransformerChange = (value ?: ITransformer) => {
        this.setState({
            transformerRef: value?.EPNumber,
            area: value?.Division ?? '',
            group: value?.EPGroup ?? '',
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, onClose, disabled,
            reading } = this.props;
        const { peak, offPeak, standard, max, dateOfMax, timeOfMax, comment, date, open, transformerRef,
            isLoading } = this.state;
        return (
            <>
                {
                    !onClose &&
                    reading &&
                    <Tooltip title='Info'>
                        <div>
                            <IconButton color='primary' onClick={this.onClick}
                                aria-label='Info'>
                                {
                                    !disabled ? (
                                        <Icon>edit</Icon>
                                    ) : (
                                        <InfoOutlinedIcon />
                                    )
                                }
                            </IconButton>
                        </div>
                    </Tooltip>
                }
                {
                    !onClose &&
                    !reading &&
                    <Tooltip title='Add'>
                        <div>
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onClick}>
                                <AddIcon />
                            </StandardFab>
                        </div>
                    </Tooltip>
                }
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-division-info-title'
                    aria-describedby='transformer-division-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {reading ? 'Edit' : 'Create'} LPU Reading
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Add'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                {
                                    !this.props.transformerRef &&
                                    <div className={'flx1 ais p5 mb10 mt16 pr20'}>
                                        <TransformerDropdown
                                            value={transformerRef}
                                            label='Select Transformer'
                                            fullWidth
                                            required
                                            onChange={this.onTransformerChange}
                                            disabled={disabled}
                                        />
                                    </div>
                                }
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='date'
                                            label='Date Reading'
                                            value={date}
                                            format={DATEPICKER_FORMAT_DEFAULT}
                                            onChange={this.onDateChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='peak'
                                            label='Peak Reading'
                                            value={peak}
                                            onChange={this.onPeakChange}
                                            type={'number'}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='standard'
                                            label='Standard Reading'
                                            value={standard}
                                            onChange={this.onStandardChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='offPeak'
                                            label='Off Peak Reading'
                                            value={offPeak}
                                            onChange={this.onOffPeakChange}
                                            type={'number'}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='max'
                                            label='Maximum Demand'
                                            value={max}
                                            onChange={this.onMaxChange}
                                            type={'number'}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='dateOfMax'
                                            label='Date Of Maximum Demand'
                                            format={DATEPICKER_FORMAT_DEFAULT}
                                            value={dateOfMax}
                                            onChange={this.onDateOfMaxChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <KeyboardTimePicker
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='timeOfMax'
                                            label='Time of Maximum Demand'
                                            value={timeOfMax}
                                            ampm={false}
                                            onChange={this.onTimeOfMaxChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='comment'
                                            label='Comment'
                                            value={comment}
                                            onChange={this.onCommentChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || disabled} type='submit' variant='contained' color='primary'>
                                    OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                    CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </>);
    };
}

import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { createSelector } from 'reselect';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Form } from 'informed';
import riverFunctions from '../../../store/river/functions';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IRiver } from '../../../@types/model/river/river';

interface IRiverNewButtonProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    rivers : Array<IRiver>;
    isLoading : boolean;
}

interface IRiverNewButtonState {
    open : boolean;

    name : string;
}

class RiverNewButton extends React.Component<IRiverNewButtonProps, IRiverNewButtonState> {
    constructor(props : IRiverNewButtonProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
        };
    }

    public readonly getData = (state : IRiverNewButtonState, props : IRiverNewButtonProps) => props.rivers;
    public readonly getCode = createSelector(
        [this.getData],
        (rivers) => {
            if (rivers.length === 0) return '';
            const latest = rivers.slice().sort((a, b) => parseInt(b.WRNumber.substr(2)) - parseInt(a.WRNumber.substr(2)))[0];
            const maxNumber = parseInt(latest.WRNumber.substr(2)) + 1;

            return `WR${maxNumber.toString().padStart(3, '0')}`;
        },
    );

    private readonly onCloseClick = () => {
        if (this.props.isLoading) return;
        this.setState({
            open: false,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onAddClick = () => {
        this.setState({
            open: true,
            name: '',
        });
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly save = async () => {
        const { name } = this.state;
        const code = this.getCode(this.state, this.props);
        if (!name) return;

        const result = await riverFunctions.createRiver(name, code);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    public render = () => {
        const { transition, fullWidth, maxWidth, fullScreen, isLoading } = this.props;
        const { open, name } = this.state;

        const code = this.getCode(this.state, this.props);
        return (<div>
            <Tooltip title='Create'>
                <div>
                    <IconButton onClick={this.onAddClick}>
                        <Icon>add</Icon>
                    </IconButton>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                aria-labelledby='new-river-dialog-title'
                aria-describedby='new-river-dialog-description'>
                <AppBar className='flx1 fdr posr p10 aic' position='static'>
                    <Typography variant='h5' color='inherit'>
                        NEW RIVER - {code}
                    </Typography>
                    <span className='flx1' />
                    <Tooltip title='Close'>
                        <div>
                            <IconButton color='inherit' onClick={this.onCloseClick}>
                                <Icon>close</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                </AppBar>
                <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdr jcc aic flx1'>
                        <div className={'flx1 ais p5 mb10 pr20'}>
                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    autoComplete='off'
                                    id='name'
                                    label='Name'
                                    value={name}
                                    onChange={this.onNameChange}
                                    margin='normal'
                                    className={'TextField'}
                                    required
                                    error={!name}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' type='submit' color='primary' disabled={isLoading}>
                            CREATE
                        </Button>
                        <Button variant='outlined' onClick={this.onCloseClick} color='primary' disabled={isLoading}>
                            CLOSE
                        </Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        rivers: state.river.rivers,
        isLoading: state.river.loading,
    };
};

export default connect(
    mapStateToProps,
)(RiverNewButton);

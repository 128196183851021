import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import PumpingStationHelper from './pumpingStation';
import { IPumpingStationGroup } from './pumpingStationGroup';

export interface IPumpingStationAudit extends IBasicBaseClass {
    guid : string;
    pumpRef : string;
    pumpCode : string;
    isActive : boolean;

    pumpingGroups : Array<IPumpingStationGroup>;
}

export default class PumpingStationAuditHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station_audit';

    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStationAudit> = {
        fromFirestore: (snapshot) => {
            return PumpingStationAuditHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStationAudit) : firebase.firestore.DocumentData => {
            return PumpingStationAuditHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStationAudit {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStationAudit,
            ...result,
            pumpRef: data.pumpRef.id,
        };
    }

    public static toFirestoreDocument(data : IPumpingStationAudit) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            pumpRef: PumpingStationHelper.doc(data.pumpRef),
        };
    }

    public static save(pumpingStation : IPumpingStationAudit) {
        return PumpingStationAuditHelper.doc(pumpingStation.id).set(pumpingStation, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationAuditHelper.COLLECTION_NAME).withConverter(PumpingStationAuditHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationAuditHelper.COLLECTION_NAME).withConverter(PumpingStationAuditHelper.converter).doc(id);
    }
    
    public static listen(pumpingStationCode ?: string) {
        return firebaseApp
            .firestore()
            .collection(PumpingStationAuditHelper.COLLECTION_NAME)
            .where('pumpCode', '==', pumpingStationCode)
            .withConverter(PumpingStationAuditHelper.converter);
    }

    public static mapToCsv(stations : Array<IPumpingStationAudit>) {
        return stations.map(() => ({
        }));
    }
}
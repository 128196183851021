import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationMotorEfficiency } from '../../../../@types/model/masterData/pumpingStation/motorEfficiency';

type MasterDataPumpingStationMotorEfficiencyActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationMotorEfficiencyState {
    motorEfficiencies : Array<IPumpingStationMotorEfficiency>;
    isLoading : boolean;
}
const initialState = {
    motorEfficiencies: [],
    isLoading: false,
};

export default function motorEfficiencyReducer(state : IMasterDataPumpingStationMotorEfficiencyState = initialState, action : MasterDataPumpingStationMotorEfficiencyActionType) : IMasterDataPumpingStationMotorEfficiencyState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, motorEfficiencies: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

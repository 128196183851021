import firebaseApp from '../../../services/firebaseService';

export interface ITransformerAttribute {
    Id : string;
    Value : string;
    IsCondition : boolean;
}

export default class TransformerAttributeHelper {
    public static readonly COLLECTION_NAME = 'electricalpointattribute';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerAttribute> = {
        fromFirestore: (snapshot) => {
            return TransformerAttributeHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: () : firebase.firestore.DocumentData => {
            throw new Error('Not Implemented');
        },
    };

    private static fromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data() as ITransformerAttribute;

        if (!doc.exists) {
            throw new Error(`Document does not exist! ${doc.id}`);
        }

        const result : ITransformerAttribute = {
            ...data,
            Id: doc.id,
        };

        return result;
    }

    public static listen() {
        return firebaseApp
            .firestore()
            .collection(TransformerAttributeHelper.COLLECTION_NAME)
            .withConverter(TransformerAttributeHelper.converter);
    }
}
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { EnumDamStatus, EnumDamConstructionType, IDam } from '../../../@types/model/dam/dam';
import DamFunctions from '../../../store/dam/functions';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import DamConstructionTypeSelector from '../../customComponents/selector/dam/DamConstructionTypeSelector';
import DamStatusSelector from '../../customComponents/selector/dam/DamStatusSelector';

interface IEditDamSummaryProps {
    dam : IDam;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditDamSummaryState {
    open : boolean;
    catchment : number;
    category : string | null;
    river : string;
    WARMSNo : string;
    owner : string;
    designer : string;
    contractor : string;
    constructionEndDate : Moment | null;
    registeredCapacity : number;
    regDate : Moment | null;
    parentFarm : string;
    farmPortion : string;
    titleDeed : string;
    hasMeasurementStick : boolean;
    damName : string;
    damConstructionType : number | null;
    status : number | null;
}

export default class EditDamSummary extends React.Component<IEditDamSummaryProps, IEditDamSummaryState> {
    constructor(props : IEditDamSummaryProps) {
        super(props);
        this.state = {
            open: false,
            catchment: 0,
            category: '',
            river: '',
            WARMSNo: '',
            owner: '',
            designer: '',
            contractor: '',
            registeredCapacity: 0,
            parentFarm: '',
            farmPortion: '',
            titleDeed: '',
            constructionEndDate: null,
            regDate: null,
            hasMeasurementStick: false,
            damName: '',
            damConstructionType: null,
            status: null,
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditDamSummaryProps, prevState : IEditDamSummaryState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                catchment: 0,
                category: '',
                river: '',
                WARMSNo: '',
                owner: '',
                designer: '',
                contractor: '',
                registeredCapacity: 0,
                parentFarm: '',
                farmPortion: '',
                titleDeed: '',
                constructionEndDate: null,
                regDate: null,
                hasMeasurementStick: false,
                damName: '',
                damConstructionType: null,
                status: null,
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                catchment: !this.props.dam.catchmentArea ? 0 : this.props.dam.catchmentArea,
                category: !this.props.dam.riskCategory ? '' : this.props.dam.riskCategory,
                river: !this.props.dam.riverOrWaterCourse ? '' : this.props.dam.riverOrWaterCourse,
                WARMSNo: !this.props.dam.warmsNo ? '' : this.props.dam.warmsNo,
                owner: !this.props.dam.ownerName ? '' : this.props.dam.ownerName,
                designer: !this.props.dam.designer ? '' : this.props.dam.designer,
                contractor: !this.props.dam.contractor ? '' : this.props.dam.contractor,
                constructionEndDate: !this.props.dam.constructionEndDate ? null : moment.utc(this.props.dam.constructionEndDate),
                registeredCapacity: !this.props.dam.registeredCapacity ? 0 : this.props.dam.registeredCapacity,
                regDate: !this.props.dam.registrationDate ? null : moment.utc(this.props.dam.registrationDate),
                parentFarm: !this.props.dam.parentFarm ? '' : this.props.dam.parentFarm,
                farmPortion: !this.props.dam.farmPortion ? '' : this.props.dam.farmPortion,
                titleDeed: !this.props.dam.titleDeed ? '' : this.props.dam.titleDeed,
                hasMeasurementStick: this.props.dam.measurementStick ?? false,
                damName: this.props.dam.name,
                damConstructionType: this.props.dam.constructionType != null ? this.props.dam.constructionType : null,
                status: this.props.dam.status != null ? this.props.dam.status : null,
            });
        }
    };

    private readonly save = async () => {
        const { catchment, category, river,
            WARMSNo, owner, designer, contractor, constructionEndDate, registeredCapacity,
            regDate, parentFarm, farmPortion,
            titleDeed, hasMeasurementStick, damName, status, damConstructionType } = this.state;

        const dam = {
            ...this.props.dam,
        };

        dam.catchmentArea = catchment;
        dam.riskCategory = category ?? null;
        dam.riverOrWaterCourse = river;
        dam.warmsNo = WARMSNo;
        dam.ownerName = owner;
        dam.designer = designer;
        dam.contractor = contractor;
        dam.constructionEndDate = !constructionEndDate ? null : constructionEndDate.valueOf();
        dam.registeredCapacity = registeredCapacity;
        dam.registrationDate = !regDate ? null : regDate.valueOf();
        dam.parentFarm = parentFarm;
        dam.farmPortion = farmPortion;
        dam.titleDeed = titleDeed;
        dam.measurementStick = hasMeasurementStick;
        dam.name = damName;
        dam.constructionType = damConstructionType != null ? damConstructionType : null;
        dam.status = status != null ? status : null;

        const result = await DamFunctions.saveDam(dam);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onCatchmentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            catchment: parseInt(event.target.value),
        });
    };

    private readonly onCategoryChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            category: event.target.value,
        });
    };

    private readonly onRiverChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            river: event.target.value,
        });
    };

    private readonly onWARMSNoChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            WARMSNo: event.target.value,
        });
    };

    private readonly onOwnerChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            owner: event.target.value,
        });
    };

    private readonly onDesignerChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            designer: event.target.value,
        });
    };

    private readonly onContractorChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            contractor: event.target.value,
        });
    };

    private readonly onConstructionEndDateChange = (momentDate : moment.Moment | null) => {
        this.setState({
            constructionEndDate: momentDate,

        });
    };

    private readonly onRegisteredCapacityChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            registeredCapacity: parseInt(event.target.value),
        });
    };

    private readonly onRegDateChange = (momentDate : moment.Moment | null) => {
        this.setState({
            regDate: momentDate,
        });
    };

    private readonly onParentFarmChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            parentFarm: event.target.value,
        });
    };

    private readonly onFarmPortionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            farmPortion: event.target.value,
        });
    };

    private readonly onTitleDeedChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            titleDeed: !event.target.value ? '' : event.target.value,
        });
    };

    private readonly onMeasurementStickChange = () => {
        this.setState({
            hasMeasurementStick: !this.state.hasMeasurementStick,
        });
    };

    private readonly onDamNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            damName: event.target.value,
        });
    };

    private readonly onDamConstructionTypeChange = (value : EnumDamConstructionType | null) => {
        this.setState({
            damConstructionType: value,
        });
    };

    private readonly onDamStatusChange = (value : EnumDamStatus | null) => {
        this.setState({
            status: value,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const {
            open,
            catchment,
            category,
            river,
            WARMSNo,
            owner,
            designer,
            contractor,
            constructionEndDate,
            registeredCapacity,
            regDate,
            parentFarm,
            farmPortion,
            titleDeed,
            hasMeasurementStick,
            damName,
            status,
            damConstructionType,
        } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='dam-info-info-title'
                    aria-describedby='dam-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Dam Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='catchment'
                                            label='Quatenary Catchment Area'
                                            value={catchment}
                                            onChange={this.onCatchmentChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='category'
                                            label='Risk Category'
                                            value={category}
                                            onChange={this.onCategoryChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='river'
                                            label='River or WaterCourse'
                                            value={river}
                                            onChange={this.onRiverChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='WARMSNo'
                                            label='Warms Number'
                                            value={WARMSNo}
                                            onChange={this.onWARMSNoChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='owner'
                                            label='Owners Name'
                                            value={owner}
                                            onChange={this.onOwnerChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='designer'
                                            label='Designer'
                                            value={designer}
                                            onChange={this.onDesignerChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='contractor'
                                            label='Contractor'
                                            value={contractor}
                                            onChange={this.onContractorChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <DatePicker value={constructionEndDate} onChange={this.onConstructionEndDateChange} format='YYYY-MM-DD' label='Construction End Date'
                                            id='constructionEndDate'
                                            className={'TextField'}
                                            margin={'normal'}
                                        />
                                    </FormControl>
                                </div>
                            </div>                    
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='registeredCapacity'
                                            label='Registered Capacity'
                                            value={registeredCapacity}
                                            onChange={this.onRegisteredCapacityChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <DatePicker value={regDate} onChange={this.onRegDateChange} format='YYYY' label='Registration Date'
                                            id='regDate'
                                            className={'TextField'}
                                            margin={'normal'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='parentFarm'
                                            label='Rarent Farm'
                                            value={parentFarm}
                                            onChange={this.onParentFarmChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='farmPortion'
                                            label='Farm Portion'
                                            value={farmPortion}
                                            onChange={this.onFarmPortionChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='titleDeed'
                                            label='Title Deed'
                                            value={titleDeed}
                                            onChange={this.onTitleDeedChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 fdr hfill mb10 pr20 mt16 ais jcc pt30'}>
                                    <Typography className={'pr10 aic jcc'}>
                                        Measurement Plate
                                    </Typography>
                                    <IconButton className={`${hasMeasurementStick ? 'bcp' : 'bcr'}`} onClick={this.onMeasurementStickChange}>
                                        {
                                            hasMeasurementStick &&
                                            <Icon className={' bcp cw fs20'}>
                                                    check
                                            </Icon>
                                        }
                                        {
                                            !hasMeasurementStick &&
                                            <Icon className={'bcr cw'}>
                                                close
                                            </Icon>
                                        }

                                    </IconButton >
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='name'
                                            label='Dam Name'
                                            value={damName}
                                            onChange={this.onDamNameChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                    <DamConstructionTypeSelector 
                                        value={damConstructionType} 
                                        onChange={this.onDamConstructionTypeChange}                                        
                                    />
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                    <DamStatusSelector 
                                        value={status} 
                                        onChange={this.onDamStatusChange}                                        
                                    />
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                </div>
                                <div className={'flx1 fdr hfill mb10 pr20 mt16 ais jcc pt30'}>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

import { IDepricatedBaseClass } from '../base';
import firebaseApp from '../../../services/firebaseService';
import TransformerHelper, { ITransformer } from './transformer';
import firebase from 'firebase/app';
import { EmployeeHelper, IUserSession } from '../../employee';

export interface ITransformerReading extends IDepricatedBaseClass<ITransformerReading> {
    Geom : firebase.firestore.GeoPoint;
    Electricalpoint : firebase.firestore.DocumentReference;
    GUID : string;
    TransformerMeterNumberReadings : Array<ITransformerMeterReading>;
    Conditions : Array<string>;
    Division ?: string;
    SubArea ?: string;
    Group ?: string;
    Date ?: firebase.firestore.Timestamp;
    isWeb ?: boolean;
}

export interface ITransformerMeterReading {
    ItemNumber : number;
    MeterNumber : string;
    ImageFileName : string | null;
    ImageFileUrl : string | null;
    RecordingFileName : string;
    NumberOfDigits : number;
    Reading : number;
    Usage : number;
    Division ?: string;
    Group ?: string;
    Rollover ?: boolean;

    Approved ?: boolean | null;
    ApprovedOn ?: firebase.firestore.Timestamp | null;
    ApprovedBy ?: firebase.firestore.DocumentReference | null;
    ApprovedByName ?: string | null;
    ApprovedByEmployee ?: string | null;
}

export interface IMeterReadingsView extends ITransformerMeterReading {
    Guid : string;
    EPNumber : string;
    Conditions : Array<string>;
    Date : number;
    CreatedBy : string;
    UpdatedBy : string;
    CreatedByName : string;
    UpdatedByName : string;
    CreatedByEmployee ?: string;
    UpdatedByEmployee ?: string;
    Rollover ?: boolean;
    isWeb ?: boolean;

    AccountNumber ?: string | null;
    SubArea ?: string;
    PoleNumber ?: string;

    Approved ?: boolean | null;
    ApprovedOn ?: firebase.firestore.Timestamp | null;
    ApprovedBy ?: firebase.firestore.DocumentReference | null;
    ApprovedByName ?: string | null;
    ApprovedByEmployee ?: string | null;

    Error ?: string;
}

export default class TransformerReadingHelper {
    public static readonly COLLECTION_NAME = 'electricpointreading';

    private static readonly converter : firebase.firestore.FirestoreDataConverter<ITransformerReading> = {
        fromFirestore: (snapshot) => {
            return TransformerReadingHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerReading) : firebase.firestore.DocumentData => {
            const { ref: _ref, ...rest } = data;
            return rest;
        },
    };

    public static fromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data() as ITransformerReading;

        const result : ITransformerReading = { ...data, ref: doc.ref.withConverter(TransformerReadingHelper.converter) };
        return result;
    }

    public static newDoc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(TransformerReadingHelper.COLLECTION_NAME).withConverter(TransformerReadingHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(TransformerReadingHelper.COLLECTION_NAME).withConverter(TransformerReadingHelper.converter).doc(id);
    }

    public static listen(ref ?: firebase.firestore.DocumentReference<ITransformer>) {
        if (!ref) {
            return firebaseApp
                .firestore()
                .collection(TransformerReadingHelper.COLLECTION_NAME)
                .orderBy('Date', 'desc')
                .withConverter(TransformerReadingHelper.converter);
        }

        return firebaseApp
            .firestore()
            .collection(TransformerReadingHelper.COLLECTION_NAME)
            .where('Electricalpoint', '==', ref)
            .withConverter(TransformerReadingHelper.converter);
    }

    public static get(epNumber : string, from : number, to ?: number) {
        let query = firebaseApp
            .firestore()
            .collection(TransformerReadingHelper.COLLECTION_NAME)
            .withConverter(TransformerReadingHelper.converter)
            .where('Electricalpoint', '==', TransformerHelper.newDoc(epNumber))
            .where('Date', '>=', firebase.firestore.Timestamp.fromMillis(from));

        if (to) {
            query = query.where('Date', '<=', firebase.firestore.Timestamp.fromMillis(to));
        }

        return query;
    }

    public static create(reading : ITransformerReading) {
        return reading.ref.set(reading);
    }

    public static update(reading : ITransformerReading) {
        return reading.ref.update({
            IsSent: false,
            TransformerMeterNumberReadings: reading.TransformerMeterNumberReadings,
            Conditions: reading.Conditions,
            Date: reading.Date,
            UpdatedBy: reading.UpdatedBy,
            UpdatedByName: reading.UpdatedByName,
            UpdatedByEmployee: reading.UpdatedByEmployee,
            UpdatedOn: reading.UpdatedOn,
        });
    }

    public static batchReadingSave(
        readings : Array<ITransformerReading>,
        session : IUserSession,
    ) {
        const batch = firebaseApp.firestore().batch();

        readings.forEach((reading) => {
            batch.update(
                reading.ref,
                {
                    IsSent: false,
                    TransformerMeterNumberReadings: reading.TransformerMeterNumberReadings,
                    UpdatedBy: EmployeeHelper.doc(session.firebaseUser.uid),
                    UpdatedByName: session.employee.Name,
                    UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                    UpdatedOn: reading.UpdatedOn,
                },
            );
        });

        return batch.commit();
    }

    public static delete(reading : ITransformerReading) {
        return reading.ref.delete();
    }
}

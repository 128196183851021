import { FormControl, Icon, InputAdornment } from '@material-ui/core';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { Field, FieldProps } from 'formik';
import { Moment } from 'moment';
import React from 'react';

interface IDateFormFieldProps extends Omit<DatePickerProps, 'name' | 'value' | 'error' | 'variant' | 'onChange'> {
    name : string;
}

interface IDateFormFieldState {}

export default class DateFormField extends React.PureComponent<IDateFormFieldProps, IDateFormFieldState> {
    constructor(props : IDateFormFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { id, name, ...rest } = this.props;
        return (
            <Field
                id={id}
                name={name}
            >
                {
                    (params : FieldProps<Moment>) => (
                        <FormControl fullWidth={rest.fullWidth} required={rest.required}>
                            <DatePicker
                                {...rest}
                                onChange={(date) => params.form.setFieldValue(params.field.name, date)}
                                value={params.field.value}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Icon>date_range</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    )
                }
            </Field>
        );
    };
}

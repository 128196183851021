import { IconButton, Tooltip } from '@material-ui/core';
import { FieldArrayRenderProps } from 'formik';
import React from 'react';
import StadiumOutlinedTextField from '../../textField/StadiumOutlinedTextField';
import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import { IUserSession } from '../../../../@types/employee';
import moment from 'moment';
import { connect } from 'react-redux';
import { IRootState } from '../../../../@types/redux';

interface IDamSafetyInspectionCommentFormFieldProps extends FieldArrayRenderProps {
    session ?: IUserSession | null;
}

interface IDamSafetyInspectionCommentFormFieldState {
    newComment : string;
}

class DamSafetyInspectionCommentFormFieldComponent extends React.PureComponent<IDamSafetyInspectionCommentFormFieldProps, IDamSafetyInspectionCommentFormFieldState> {
    constructor(props : IDamSafetyInspectionCommentFormFieldProps) {
        super(props);
        this.state = {
            newComment: '',
        };
    }

    public readonly insert = (comment : string) => {
        const { insert, session } = this.props;

        if (!session) return;
        
        insert(-1, {
            comment,
            createdBy: session.firebaseUser.uid,
            createdByEmployee: session.employee.EmployeeNumber ?? '',
            createdByName: session.firebaseUser.displayName ?? '',
            createdOn: moment.utc(),
            isNew: true,
        });

        this.setState({
            newComment: '',
        });
    };

    public readonly onCommentChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            newComment: event.currentTarget.value,
        });
    };

    public readonly onAddClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const { newComment } = this.state;
        if (!newComment) return;

        this.insert(newComment);
    };

    private readonly onKeyDown = (event : React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') return;

        event.preventDefault();
        event.stopPropagation();

        const { newComment } = this.state;
        if (!newComment) return;

        this.insert(newComment);
    };

    public readonly render = () => {
        const { newComment } = this.state;
        return (
            <div className='fdr'>
                <StadiumOutlinedTextField
                    className='flx1'
                    label='Start typing here…'
                    value={newComment}
                    onChange={this.onCommentChange}
                    onKeyDown={this.onKeyDown}
                />
                <div className='ml15'>
                    <Tooltip title='Add'>
                        <div>
                            <IconButton color='primary' onClick={this.onAddClick}>
                                <AddCircleIcon className='fs32' />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const DamSafetyInspectionCommentFormField = connect(
    mapStateToProps,
)(DamSafetyInspectionCommentFormFieldComponent);

export default DamSafetyInspectionCommentFormField;

import { Card, Typography } from '@material-ui/core';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import React from 'react';
import { createSelector } from 'reselect';
import { IBoreholeCalibrationFormValues } from '../../../../@types/model/borehole/boreholeCalibration';
import TextFormField from '../TextFormField';

interface IBoreholeCalibrationAchieveStepEntryFormFieldProps extends Omit<FieldArrayRenderProps, 'form'> {
    form : FormikProps<IBoreholeCalibrationFormValues>;
}

export default class BoreholeCalibrationAchieveStepEntryFormField extends React.PureComponent<IBoreholeCalibrationAchieveStepEntryFormFieldProps> {
    constructor(props : IBoreholeCalibrationAchieveStepEntryFormFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly getValues = (props : IBoreholeCalibrationAchieveStepEntryFormFieldProps) => props.form.values;
    public readonly getName = (props : IBoreholeCalibrationAchieveStepEntryFormFieldProps) => props.name;
    public readonly getStage = createSelector(
        [this.getName],
        (name) => {
            return Number(name.split('.')[1]);
        },
    );

    public readonly getValue = createSelector(
        [this.getValues, this.getStage],
        (values) => {
            return values.achieved;
        },
    );

    public readonly render = () => {
        const { name } = this.props;
        const value = this.getValue(this.props);
        return (
            <div className='fdc mt12'>
                <Card className='fdc flx1'>
                    <div className='fdr bcw h50'>
                        <div className={'fdc flx1 jcc aic bcp h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                TARGET %
                            </Typography>
                        </div>
                        <span className='w3' />
                        <div className={'fdc flx1 jcc aic dvbg h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                % of DRAWDOWN (m)
                            </Typography>
                        </div>
                        <span className='w3' />
                        <div className={'fdc flx1 jcc aic dvblg h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                TARGET GROUNDWATER LEVEL
                            </Typography>
                        </div>
                    </div>
                    {
                        Object.keys(value).map((stage, index) => (
                            <div key={stage} className={`fdr ais jcc ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                <div className={'fdr flx1 aic jcc pb16 pt16 pr30 pl30'}>
                                    <Typography className={'fs20 fwb'}>
                                        Step {Number(stage) + 1}&nbsp;(
                                        {stage === '0' && '10%'}
                                        {stage === '1' && '40%'}
                                        {stage === '2' && '70%'}
                                        {stage === '3' && '90%'})
                                    </Typography>
                                </div>
                                <span className='w3 bcw' />
                                <div className={'fdr flx1 ais jcc pb16 pt16 pr30 pl30'}>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={`${name}.${index}.percentageOfDrawdown`}
                                        name={`${name}.${index}.percentageOfDrawdown`}
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                                <span className='w3 bcw' />
                                <div className={'fdr flx1 ais jcc pb16 pt16 pr30 pl30'}>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={`${name}.${index}.groundLevel`}
                                        name={`${name}.${index}.groundLevel`}
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                            </div>
                        ))
                    }
                </Card>
            </div>
        );
    };
}

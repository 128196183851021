import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { FieldArray } from 'formik';
import React from 'react';
import { IDamSafetyInspectionDetailFormValues } from '../../../../../@types/model/dam/damSafetyInspection';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import DamSafetyInspectionDetailFormField from '../../../../customComponents/form/damSafetyInspection/DamSafetyInspectionDetailFormField';

interface IDamSafetyInspectionEditDialogDetailsTabProps {
    isLoading : boolean;

    onNextClick ?: () => void;
    onBackClick ?: () => void;

    details : Array<IDamSafetyInspectionDetailFormValues>;
}

interface IDamSafetyInspectionEditDialogDetailsTabState {}

export default class DamSafetyInspectionEditDialogDetailsTab extends React.PureComponent<IDamSafetyInspectionEditDialogDetailsTabProps, IDamSafetyInspectionEditDialogDetailsTabState> {
    constructor(props : IDamSafetyInspectionEditDialogDetailsTabProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { onNextClick, onBackClick, details } = this.props;
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <div className='fdr jcfs mt15'>
                    <AppBar position='static' className='dvblg' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography variant='h6' className='flx1 cw'>
                                PDF DOCUMENT - DETAILS
                            </Typography>
                            <span className={'flx1'} />
                            <FieldArray
                                name='details'
                                render={(props) => <DamSafetyInspectionDetailFormField {...props} />}
                            />
                        </Toolbar>
                    </AppBar>
                </div>
                <div className='fdc jcfs bcw p15'>
                    <div className={'fdr aic jcc pb15 pl5 pr5'}>
                        <div className='fdc w50'>
                            <Typography className='fs13 cpd fwm'>No.</Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className='fs13 cpd fwm'>DESCRIPTION</Typography>
                        </div>
                        <div className='fdc w50'>
                            <Typography className='fs13 cpd fwm'>APP</Typography>
                        </div>
                        <div className='fdc w50'>
                            <Typography className='fs13 cpd fwm'>#</Typography>
                        </div>
                        <div className='fdc flx1'>
                            <Typography className='fs13 cpd fwm'>COMMENCE & COMPLETION DATES</Typography>
                        </div>
                        <div className='fdc w100'>
                            <Typography className='fs13 cpd fwm'>ESTIMATE COST</Typography>
                        </div>
                    </div>
                    {
                        details.map((n, index) => (
                            <div key={n.no} className={`fdr aifs jcc mnh65 pt15 pl5 pr5 pb10 ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                <div className='fdc w50'>
                                    <Typography className='fs15 cpd fwb'>{n.no}</Typography>
                                </div>
                                <div className='fdc flx1'>
                                    <Typography className='fs15 cpd fwb'>{n.description}</Typography>
                                </div>
                                <div className='fdc w50'>
                                    <Typography className='fs15 cpd fwb'>{n.app ? 'YES' : 'NO'}</Typography>
                                </div>
                                <div className='fdc w50'>
                                    <Typography className='fs15 cpd fwb'>{n.priority}</Typography>
                                </div>
                                <div className='fdc flx1'>
                                    {
                                        n.commenceDate &&
                                        <div className='fdr'>
                                            <Typography className='fs15 cpd fwb'>C - {n.commenceDate.format('DD/MM/YYYY')}</Typography>
                                        </div>

                                    }
                                    {
                                        n.completionDate &&
                                        <div className='fdr'>
                                            <Typography className='fs15 cpd fwb'>Co - {n.completionDate.format('DD/MM/YYYY')}</Typography>
                                        </div>

                                    }
                                </div>
                                <div className='fdc w100'>
                                    <Typography className='fs15 cpd fwb'>{!n.estimateCost ? '-' : `R${n.estimateCost.toSAFormat(2)}`}</Typography>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='fdr jcfe mt15'>
                    <div className='mr15'>
                        <OutlinedStadiumButton onClick={onBackClick}>
                            Back
                        </OutlinedStadiumButton>
                    </div>
                    <div>
                        <StadiumButton onClick={onNextClick}>
                            Next
                        </StadiumButton>
                    </div>
                </div>
            </div>
        );
    };
}

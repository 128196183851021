import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../../@types/employee';
import GeneralFunctions from '../../../../store/general/functions';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { CircularProgress } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import FormikForm from '../../../customComponents/form/FormikForm';
import DamAerialHelper, { IDamAerial, IDamAerialFormValues } from '../../../../@types/model/dam/damAerialPhotos';
import { IDam } from '../../../../@types/model/dam/dam';
import firebaseApp from '../../../../services/firebaseService';
import { EnumFileUploadQueueType } from '../../../../@types/model/files/enum';
import TextFormField from '../../../customComponents/form/TextFormField';
import firebase from 'firebase/app';
import { Field, FieldProps } from 'formik';
import { Moment } from 'moment';
import FormTabDateSelector from '../../../customComponents/form/FormTabDateSelector';
import ImageEmbedView from '../../../customComponents/embed/ImageEmbedView';

interface IEditDamAerialProps {
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;


    damAerial ?: IDamAerial;

    session ?: IUserSession | null;
    dam ?: IDam;
}

interface IEditDamAerialState {
    isLoading : boolean;
    open : boolean;

    file : File | null;

    tabIndex : number;

    initialValues : IDamAerialFormValues | null;
}

class EditDamAerialComponent extends React.Component<IEditDamAerialProps, IEditDamAerialState> {
    constructor(props : IEditDamAerialProps) {
        super(props);
        this.state = {
            isLoading: false,
            open: false,
            file: null,
            tabIndex: 0,
            initialValues: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditDamAerialProps, prevState : IEditDamAerialState) => {
        if (!prevState.open && this.state.open) {
            const { session } = this.props;
            this.setState({
                isLoading: false,
                file: null,
                tabIndex: 0,
                initialValues: {
                    ...DamAerialHelper.initialFormValues(this.props.damAerial),
                    employeeName: session?.employee.Name ?? null,
                    employeeNumber: session?.employee.EmployeeNumber ?? null,
                },
            });
        }

    };

    private readonly save = async (formValues : IDamAerialFormValues) => {
        const { damAerial } = this.props;
        const { 
            file,
        } = this.state;

        const ref = DamAerialHelper.doc(damAerial?.id);

        try {
            this.setState({
                isLoading: true,
            });
            
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { session, dam } = this.props;
        
                if (!session) throw new Error('Session Required');
                if (!dam) throw new Error('Dam Required');
                if (!file && !damAerial) throw new Error('Photo Required');
    
                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);
    
                const data = doc.data();
    
                transaction.set(ref, {
                    ...formValues,
                    guid: data?.guid ?? formValues.guid,
                    createdBy: data?.createdBy ?? session.firebaseUser.uid,
                    createdByName: data?.createdByName ?? session.employee.Name,
                    createdByEmployee: data?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
                    createdOn: data?.createdOn ?? firebase.firestore.Timestamp.now().toMillis(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                    isSent: false,
                    isActive: data?.isActive ?? true,
                    isWeb: data?.isWeb ?? true,

                    damRef: dam.id,
                    damCode: dam.code,
                    
                    documentName: file?.name ?? data?.documentName ?? null,
                    documentURL: !file ? (data?.documentURL ?? null) : '',
                    documentThumbnailURL: !file ? (data?.documentThumbnailURL ?? null) : '',

                    employeeName: formValues.employeeName ?? '',
                    employeeNumber: formValues.employeeNumber ?? '',

                    date: formValues.date.valueOf(),
                    photographer: formValues.photographer,
                }, {
                    merge: true,
                });
    
                if (file) {
                    await DamAerialHelper.uploadFile(
                        data?.damCode ?? dam.code,
                        file,
                        {
                            collection: DamAerialHelper.COLLECTION_NAME,
                            fieldName: 'documentURL',
                            fileType: EnumFileUploadQueueType.DamAerial.toString(),
                            refGuid: formValues.guid,
                            thumbnailFieldName: 'documentThumbnailURL',
                        }
                    );
                }
            });

            this.setState({
                open: false,
            });
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving registration.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
        
    };

    private readonly onSubmit = (formValues : IDamAerialFormValues) => {
        this.save(formValues);
    };

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.dam) return;
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onFileDrop = (acceptedFiles : Array<File>) => {
        if (!acceptedFiles.length) return;

        this.setState({
            file: new File([acceptedFiles[0].slice()], `${acceptedFiles[0].name}`, {
                type: acceptedFiles[0].type,
            }),
        });
    };

    public readonly render = () => {
        const { transition, damAerial } = this.props;
        const { open, isLoading, file, initialValues } = this.state;
        return (
            <React.Fragment>
                <Tooltip title={damAerial ? 'Edit' : 'Upload'}>
                    <div>
                        {
                            !!damAerial &&
                            <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Edit' disabled={isLoading}>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                        {
                            !damAerial &&
                            <StadiumButton style={{
                                height: 42,
                                borderRadius: 40,
                            }} className='jcc aic p0' color='primary' onClick={this.onClick} aria-label='Upload Video'>
                                <Icon className='cp ml10'>upload</Icon>
                                <Typography className='fw600 fs16 cp ml20 mr10'>DOCUMENT UPLOADS</Typography>
                            </StadiumButton>
                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    fullScreen
                    fullWidth
                    aria-labelledby='dam-registration-edit-dialog-title'
                    aria-describedby='dam-registration-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            {
                                !!damAerial &&
                                <Typography variant='h5' color='inherit'>
                                    Edit Dam Aerial Photo - {AppFunctionsService.formatDateTimeToDatePicker(damAerial.date)}
                                </Typography>
                            }
                            {
                                !damAerial &&
                                <Typography variant='h5' color='inherit'>
                                    Add Dam Aerial Photo
                                </Typography>
                            }
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    {
                        initialValues &&
                        <FormikForm
                            onSubmit={this.onSubmit}
                            className='fdc hfill bcg0'
                            validateOnMount
                            initialValues={initialValues}
                            validationSchema={DamAerialHelper.formSchema()}
                            enableReinitialize
                        >
                            {
                                (props) => (
                                    <>
                                        <DialogContent className='fdc hfill p0'>
                                            {
                                                isLoading &&
                                                <div className='fdc flx1 aic jcc'>
                                                    <CircularProgress />
                                                </div>
                                            }
                                            {
                                                !isLoading &&
                                                <div className='fdr flx1 mb15'>
                                                    <div className='fdc w804 mr10 ml10'>
                                                        <div className={'jcfs aic wfill dvbg h50'}>
                                                            <Typography className={'ml15 fs14 fw500 cw '}>
                                                                PHOTO
                                                            </Typography>
                                                            <span className='flx1' />
                                                        </div>
                                                        <div className='fdc flx1 wfill'>
                                                            <Dropzone onDrop={this.onFileDrop} accept={'image/*'}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className='fdc flx1'>
                                                                        <div className='fdc flx1' {...getRootProps()}>
                                                                            <input disabled={isLoading} multiple={false} {...getInputProps()} />
                                                                            
                                                                            {
                                                                                !file &&
                                                                                <div className='fdr flx1 jcc aic'>
                                                                                    <Typography className='cg0' variant='body2'>
                                                                                        DRAG &amp; DROP FILE
                                                                                    </Typography>
                                                                                </div>
                                                                            }
                                                                            
                                                                            {
                                                                                file &&
                                                                                <ImageEmbedView width='100%' file={file} />
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                    <div className='fdc flx1 ml10 mr10'>
                                                        <div className={'jcfs aic wfill dvbg h50'}>
                                                            <Typography className={'ml15 fs14 fw500 cw '}>
                                                                PHOTO DETAILS
                                                            </Typography>
                                                            <span className='flx1' />
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdc flx1'>
                                                                <Field 
                                                                    id='date'
                                                                    name='date'
                                                                >
                                                                    {
                                                                        (params : FieldProps<Moment>) => <FormTabDateSelector {...params} />
                                                                    }
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'employeeName'}
                                                                    name={'employeeName'}
                                                                    fullWidth
                                                                    required
                                                                    autoComplete='off'
                                                                    label={'DATA RECORDER\u2019S PERSONEL NAME'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'employeeNumber'}
                                                                    name={'employeeNumber'}
                                                                    fullWidth
                                                                    required
                                                                    autoComplete='off'
                                                                    label={'DATA RECORDER\u2019S PERSONEL NUMBER'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1'>
                                                                <TextFormField
                                                                    id={'photographer'}
                                                                    name={'photographer'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'NAME OF PHOTOGRAPHER'}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr jcfe mt15 mb15'>
                                                            <div>
                                                                <StadiumButton disabled={!props.isValid} onClick={props.submitForm}>
                                                                    Save
                                                                </StadiumButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </DialogContent>
                                    </>
                                )
                            }
                        </FormikForm>
                    }
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const EditDamAerial = connect(
    mapStateToProps,
)(EditDamAerialComponent);

export default EditDamAerial;
import { FieldArray } from 'formik';
import React from 'react';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import BoreholeCalibrationNeighbouringFormField from '../../../../customComponents/form/boreholeCalibration/BoreholeCalibrationNeighbouringSelector';

interface IBoreholeCalibrationEditDialogNeighbouringTabProps {
    isLoading : boolean;

    onNextClick ?: () => void;
    onBackClick ?: () => void;
}

interface IBoreholeCalibrationEditDialogNeighbouringTabState {}

export default class BoreholeCalibrationEditDialogNeighbouringTab extends React.PureComponent<IBoreholeCalibrationEditDialogNeighbouringTabProps, IBoreholeCalibrationEditDialogNeighbouringTabState> {
    constructor(props : IBoreholeCalibrationEditDialogNeighbouringTabProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { onNextClick, onBackClick } = this.props;
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <FieldArray
                    name='neighbouringBoreholes'
                    render={(props) => <BoreholeCalibrationNeighbouringFormField {...props} />}
                />
                <div className='fdr jcfe mt15'>
                    <div className='mr15'>
                        <OutlinedStadiumButton onClick={onBackClick}>
                            Back
                        </OutlinedStadiumButton>
                    </div>
                    <div>
                        <StadiumButton onClick={onNextClick}>
                            Next
                        </StadiumButton>
                    </div>
                </div>
            </div>
        );
    };
}

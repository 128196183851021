import { enqueueSnackbar, removeSnackbar } from '../general/actions';
import { dispatch } from '../../store';
import { AxiosResponse } from 'axios';
import { FirebaseError } from 'firebase';

export default class GeneralFunctions {
    public static generalShowError(ex : unknown, defaultMessage ?: string) {
        if (typeof ex === 'string' && ex) {
            return GeneralFunctions.generalShowErrorSnackbar(ex);
        }

        if (typeof ex === 'object' && ex instanceof Error) {
            return GeneralFunctions.generalShowErrorSnackbar(ex.message);
        }

        if (ex && typeof ex === 'object' && 'request' in ex) {
            const response = ex as AxiosResponse;

            if (response.config.responseType === 'blob') {
                (response.data as Blob).text().then((text) => {
                    GeneralFunctions.generalShowErrorSnackbar(text);
                }).catch(() => {
                    GeneralFunctions.generalShowErrorSnackbar(defaultMessage ?? '');
                });
                return;
            }

            return GeneralFunctions.generalShowErrorSnackbar(response.data ?? defaultMessage);
        }

        if (ex && typeof ex === 'object' && 'message' in ex) {
            const response = ex as Error | FirebaseError;
            return GeneralFunctions.generalShowErrorSnackbar(response.message);
        }

        return GeneralFunctions.generalShowErrorSnackbar(defaultMessage ?? '');
    }

    public static generalShowErrorSnackbar(message : string) {
        dispatch(enqueueSnackbar({
            message,
            options: {
                variant: 'error',
            },
        }));
    }
    
    public static generalShowSuccessSnackbar(message : string) {
        dispatch(enqueueSnackbar({
            message,
            options: {
                variant: 'success',
            },
        }));
    }
    
    public static generalShowWarningSnackbar(message : string) {
        dispatch(enqueueSnackbar({
            message,
            options: {
                variant: 'warning',
            },
        }));
    }
    
    public static generalShowInfoSnackbar(message : string) {
        dispatch(enqueueSnackbar({
            message,
            options: {
                variant: 'info',
            },
        }));
    }
    
    public static generalRemoveSnackbar(key : number) {
        dispatch(removeSnackbar(key));
    }

}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IDamInspection } from '../../../../@types/model/dam/damInspection';
import DamHelper from '../../../../@types/model/dam/dam';
import DamInspectionLocationView from './LocationView';
import DamInspectionPhotoView from './PhotoView';

interface IDamInspectionCrestViewProps {
    inspection : IDamInspection;

    onImageClick ?: (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IDamInspectionCrestViewState {}

export default class DamInspectionCrestView extends React.PureComponent<IDamInspectionCrestViewProps, IDamInspectionCrestViewState> {
    constructor(props : IDamInspectionCrestViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { inspection, onImageClick } = this.props;
        
        return (
            <div className='fdr aifs jcc pt28 pb28'>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            SURFACE PROTECTION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        <Typography className={'fs14 fw500 cg3 aic jcc'}>
                            {
                                inspection.crestSurfaceProtection == null ? '-' : DamHelper.DamWallSurfaceProtection[inspection.crestSurfaceProtection]
                            }
                        </Typography>
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            SETTLEMENT
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.crestSettlement &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.crestSettlement &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.crestSettlement}
                                </Typography>
                                {
                                    inspection.crestSettlementPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'crestSettlementPhotoUrl'}
                                            photoThumbUrl={inspection.crestSettlementPhotoThumbUrl}
                                            photoFileName={inspection.crestSettlementPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.crestSettlementLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='crestSettlementLocation'
                                            lat={inspection.crestSettlementLocation[0]}
                                            lng={inspection.crestSettlementLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            DISTURBANCE
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.crestDisturbance &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.crestDisturbance &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.crestDisturbance}
                                </Typography>
                                {
                                    inspection.crestDisturbancePhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'crestDisturbancePhotoUrl'}
                                            photoThumbUrl={inspection.crestDisturbancePhotoThumbUrl}
                                            photoFileName={inspection.crestDisturbancePhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            EROSION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.crestErosion &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.crestErosion &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.crestErosion}
                                </Typography>
                                {
                                    inspection.crestErosionPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'crestErosionPhotoUrl'}
                                            photoThumbUrl={inspection.crestErosionPhotoThumbUrl}
                                            photoFileName={inspection.crestErosionPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.crestErosionLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='crestErosionLocation'
                                            lat={inspection.crestErosionLocation[0]}
                                            lng={inspection.crestErosionLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            UNWANTED VEGETATION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.crestUnwantedVegetation &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.crestUnwantedVegetation &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.crestUnwantedVegetation}
                                </Typography>
                                {
                                    inspection.crestUnwantedVegetationPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'crestUnwantedVegetationPhotoUrl'}
                                            photoThumbUrl={inspection.crestUnwantedVegetationPhotoThumbUrl}
                                            photoFileName={inspection.crestUnwantedVegetationPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            CRACKS
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.crestCracks &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.crestCracks &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.crestCracks}
                                </Typography>
                                {
                                    inspection.crestCracksPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'crestCracksPhotoUrl'}
                                            photoThumbUrl={inspection.crestCracksPhotoThumbUrl}
                                            photoFileName={inspection.crestCracksPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.crestCracksLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='crestCracksLocation'
                                            lat={inspection.crestCracksLocation[0]}
                                            lng={inspection.crestCracksLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                {
                    !!inspection.crestOther &&
                    <Card className={'bcg0 fdc mrnlc15'}>
                        <div className={'jcc aic wfill bcpl h50'}>
                            <Typography className={'fs14 fw500 cw '}>
                                OTHER
                            </Typography>
                        </div>
                        <div className='fdc p15 ais jcc w200 flx1'>
                            {
                                !inspection.crestOther &&
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    NO
                                </Typography>
                            }
                            {
                                !!inspection.crestOther &&
                                <>
                                    <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                        {inspection.crestOther}
                                    </Typography>
                                    {
                                        inspection.crestOtherPhotoThumbUrl &&
                                        <div className='fdc mt12'>
                                            <DamInspectionPhotoView
                                                id={'crestOthermentPhotoUrl'}
                                                photoThumbUrl={inspection.crestOtherPhotoThumbUrl}
                                                photoFileName={inspection.crestOtherPhotoFileName ?? ''}
                                                onImageClick={onImageClick}
                                            />
                                        </div>
                                    }
                                    {
                                        !!inspection.crestOtherLocation &&
                                        <div className='fdc mt12'>
                                            <DamInspectionLocationView
                                                id='crestCracksLocation'
                                                lat={inspection.crestOtherLocation[0]}
                                                lng={inspection.crestOtherLocation[1]}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </Card>
                }
            </div>
        );
    };
}


import { Route, RouteComponentProps, RouteProps } from 'react-router';
import React from 'react';
import { IAuthState } from '../../@types/redux';
import authFunctions from '../../store/auth/functions';
import NoAccess from './NoAccess';

interface IPrivateRouteProps extends RouteProps {
    auth : IAuthState;
}

export default class PrivateRoute extends React.Component<IPrivateRouteProps> {
    public render () {
        const { component, auth, ...rest } = this.props;
        if (!component) {
            return;
        }
        /* tslint:disable-next-line:variable-name */
        const Component = component;
        const render = (props : RouteComponentProps<Record<string, never>>) : React.ReactNode => {
            if (auth.session) {
                if (!authFunctions.hasRight(props.match.path, props.location.search)) {
                    return <NoAccess />;
                }

                return <Component {...props} />;
            }
        };

        return (<Route {...rest} render={render} />);
    }
}

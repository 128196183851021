import moment, { Moment } from 'moment';
import React from 'react';
import { IMunicipalityInvoice, MunicipalityInvoiceHelper } from '../../@types/model/transformer/municipalityInvoice';
import GeneralFunctions from '../../store/general/functions';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { Card, FormControl, IconButton, InputAdornment, TextField, Toolbar, Tooltip } from '@material-ui/core';
import TransformerGroupDropdown from '../customComponents/selector/transformer/GroupSelector';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DropdownButton from '../customComponents/button/DropdownButton';
import MaterialTable from '../customComponents/materialTable/Table';
import { Add, Close, Delete, Edit } from '@material-ui/icons';
import { ITransformerGroup } from '../../@types/model/transformer/transformerGroup';
import AppFunctionsService from '../../services/appFunctionsService';
import StandardFab from '../customComponents/button/StandardFab';
import NavFunctions from '../../store/nav/functions';
import TransformerDropdown from '../customComponents/selector/transformer/TransformerSelector';
import { ITransformer } from '../../@types/model/transformer/transformer';
import TransformerInvoiceJournalDialog from '../transformers/capturing/dialog/JournalDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';

interface IMunicipalityInvoiceListProps {}

interface IMunicipalityInvoiceListState {
    group : string | null;
    monthDate : Moment | null;
    accountNumber : string | null;
    epNumber : string | null;

    isLoading : boolean;

    invoices : Array<IMunicipalityInvoice>;

    selectedInvoices : Array<IMunicipalityInvoice>;
    selectedDelete ?: IMunicipalityInvoice | null;
}

class MunicipalityInvoiceListComponent extends React.PureComponent<IMunicipalityInvoiceListProps, IMunicipalityInvoiceListState> {
    private readonly maxDate = moment.utc().startOf('month').add(1, 'day');
    private listener : null | (() => void);
    
    constructor(props : IMunicipalityInvoiceListProps) {
        super(props);
        this.state = {
            group: null,
            isLoading: false,
            monthDate: null,
            accountNumber: null,
            epNumber: null,
            invoices: [],
            selectedInvoices: [],
        };

        this.listener = null;
    }

    public componentDidMount() : void {
        this.refresh();
    }

    private readonly refresh = () => {
        if (this.listener) this.listener();
        this.setState({
            isLoading: true,
            invoices: [],
            selectedInvoices: [],
        });

        this.listener = MunicipalityInvoiceHelper.collection()
            .orderBy('monthDate', 'desc')
            .onSnapshot((snapshot) => {
                const invoices = this.state.invoices.slice();

                snapshot.docChanges().forEach((f) => {
                    const data = f.doc.data();
    
                    switch (f.type) {
                        case 'added':
                            invoices.splice(f.newIndex, 0, data);
                            break;
                        case 'modified':
                            invoices.splice(f.oldIndex, 1);
                            invoices.splice(f.newIndex, 0, data);
                            break;
                        case 'removed':
                            invoices.splice(f.oldIndex, 1);
                            break;
                    }
                });

                this.setState({
                    invoices,
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Invoices');
            });
    };

    private readonly onGroupChange = (value ?: ITransformerGroup | undefined) => {
        this.setState({
            group: value?.name ?? null,
        });
    };

    private readonly onMonthDateChanged = (momentDate : moment.Moment | null) => {
        this.setState({
            monthDate: !momentDate?.isValid() ? null : moment.utc(momentDate.valueOf()).startOf('month'),
            selectedInvoices: [],
        });
    };

    private readonly onClearDateClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        this.setState({
            monthDate: null,
            selectedInvoices: [],
        });
    };

    private readonly getInvoices = (props : IMunicipalityInvoiceListProps, state : IMunicipalityInvoiceListState) => state.invoices;
    private readonly getGroup = (props : IMunicipalityInvoiceListProps, state : IMunicipalityInvoiceListState) => state.group;
    private readonly getMonthDate = (props : IMunicipalityInvoiceListProps, state : IMunicipalityInvoiceListState) => state.monthDate;
    private readonly getEpNumber = (props : IMunicipalityInvoiceListProps, state : IMunicipalityInvoiceListState) => state.epNumber;
    private readonly getAccountNumber = (props : IMunicipalityInvoiceListProps, state : IMunicipalityInvoiceListState) => state.accountNumber;
    public readonly getList = createSelector(
        [
            this.getInvoices,
            this.getGroup,
            this.getMonthDate,
            this.getEpNumber,
            this.getAccountNumber,
        ],
        (
            invoices,
            group,
            monthDate,
            epNumber,
            accountNumber,
        ) => {
            return lodash
                .chain(invoices)
                .filter(n => !accountNumber || n.accountNumber.toLowerCase().includes(accountNumber.toLowerCase()))
                .filter(n => !epNumber || n.transformerRef.toLowerCase().includes(epNumber.toLowerCase()))
                .filter(x => !group || x.transformer.EPGroup === group)
                .filter(x => !monthDate || x.monthDate === monthDate.valueOf())
                .uniq()
                .value();
        },
    );

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const line = this.state.invoices.find(x => x.id === event.currentTarget.value);

        const search : Array<string> = [];

        if (line) {
            search.push(`id=${line.id}`);
            search.push(`month=${line.monthDate}`);
            search.push(`group=${line.group}`);
        }

        NavFunctions.navPath('/transformer/capturing/municipality/invoice/capture', search.join('&'));
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            selectedDelete: this.state.invoices.find(x => x.id === event.currentTarget.value),
        });
    };

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            epNumber: value?.EPNumber ?? null,
        });
    };

    private readonly onAccountNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            accountNumber: event.currentTarget.value,
            selectedInvoices: [],
        });
    };

    private readonly onJournalDialogClose = () => {
        this.setState({
            selectedInvoices: [],
        });
    };

    private readonly onSelectionChange = (selectedRows : Array<IMunicipalityInvoice>) => {
        this.setState({
            selectedInvoices: selectedRows,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.state.selectedDelete) {
            MunicipalityInvoiceHelper.delete({
                ...this.state.selectedDelete,
            });
        }
        this.setState({
            selectedDelete: null,
        });
    };

    public readonly render = () => {
        const {
            group,
            isLoading,
            monthDate,
            accountNumber,
            epNumber,
            selectedInvoices,
            selectedDelete,
        } = this.state;

        const invoices = this.getList(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <Toolbar className='aifs'>
                    <div className='flx1 ais mb10'>
                        {
                            !!selectedInvoices.length &&
                            <TransformerInvoiceJournalDialog
                                onClose={this.onJournalDialogClose}
                                invoices={selectedInvoices}
                                fullScreen
                                transition={Transitions.Down}
                                type={'municipality'}
                            />
                        }
                    </div>
                    <span className={'flx1'} />
                    <div className={'flx1 ais mb10 pl10 pr10'}>
                        <FormControl fullWidth>
                            <KeyboardDatePicker
                                value={monthDate}
                                views={['month']}
                                maxDate={this.maxDate}
                                onChange={this.onMonthDateChanged}
                                format='MMM YYYY'
                                label='Select Account Month...'
                                autoComplete='off'
                                id='monthDate'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {
                                                monthDate &&
                                                <IconButton size='small' onClick={this.onClearDateClick}>
                                                    <Close />
                                                </IconButton>
                                            }
                                            <DropdownButton />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className={'flx1 ais mb10 pl10 pr10'}>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='accountNumber'
                                label='Account No'
                                value={accountNumber ?? ''}
                                onChange={this.onAccountNumberChange}
                            />
                        </FormControl>
                    </div>
                    <div className={'flx1 ais mb10 pl10 pr10'}>
                        <FormControl fullWidth>
                            <TransformerDropdown
                                value={epNumber}
                                label='Waypoint No'
                                fullWidth
                                onChange={this.onEPNumberChange}
                                type={'municipality'}
                            />
                        </FormControl>
                    </div>
                    <div className={'flx1 ais mb10 pl10 pr10'}>
                        <TransformerGroupDropdown
                            fullWidth
                            value={group}
                            onChange={this.onGroupChange}
                            type={'Municipality'}
                            disabled={isLoading}
                        />
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<IMunicipalityInvoice>
                        id='IMunicipalityInvoiceTable'
                        data={invoices}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        selectable
                        selectedRows={selectedInvoices}
                        onSelectionChanged={this.onSelectionChange}
                        isSelectable={(row) => 
                            !!group &&
                            !row.paid &&
                            !row.journaled &&
                            row.group === group &&
                            !!row.transformer.Company &&
                            !!row.transformer.BankAccount
                        }
                        columns={[{
                            header: '',
                            field: 'monthDate',
                            width: 85,
                            renderCell: row => (
                                <div className='aic'>
                                    <Tooltip title='Edit'>
                                        <IconButton value={row.id} onClick={this.onEditClick}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    {
                                        !row.paid &&
                                        !row.journaled &&
                                        <Tooltip title='Delete'>
                                            <IconButton value={row.id} onClick={this.onDeleteClick}>
                                                <Delete className='cr' />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    
                                </div>
                            ),
                        }, {
                            header: 'Month',
                            field: 'monthDate',
                            width: 75,
                            paddingLeft: 15,
                            renderCell: row => (
                                <div className='aic'>
                                    {AppFunctionsService.formatDateTimeToMonthYearOnly(row.monthDate)}
                                </div>
                            ),
                        }, {
                            header: 'Way Point',
                            field: 'transformerRef',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Group',
                            field: 'transformer.EPGroup',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Pole No',
                            field: 'transformer.PoleNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Account No',
                            field: 'transformer.AccountNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Total Elec. Usage',
                            field: 'electricity.totalConsumption',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {row.electricity?.totalConsumption.toLocaleString() ?? '-'}
                                </div>
                            ),
                        }, {
                            header: 'Total Water Usage',
                            field: 'water.totalConsumption',
                            width: 185,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {row.water?.totalConsumption.toLocaleString() ?? '-'}
                                </div>
                            ),
                        }, {
                            header: 'Total Other Cost',
                            field: 'other.totalOtherCosts',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {
                                        !!row.other &&
                                        (row.other.totalOtherCosts + row.other.propertyRatesTotal).toLocaleCurrency()
                                    }
                                    {
                                        !row.other &&
                                        '-'
                                    }
                                </div>
                            ),
                        }, {
                            header: 'Total Charge',
                            field: 'totalCharge',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(row.totalIncVat).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Total Billed',
                            field: 'rebillTotal',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {!row.rebillTotal ? '-' : (row.rebillTotal).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Reason',
                            field: 'reasonForRebill',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Journaled',
                            field: 'journaled',
                            width: 145,
                            renderCell: row => (
                                <div className='fdc jcc'>
                                    <Tooltip title={!row.journaled ? '' : `${row.journaledByName} - ${row.journaledByEmployeeNumber}`}>
                                        <CheckCircleIcon className={!row.journaled ? 'cg2' : 'cp'} />
                                    </Tooltip>
                                </div>
                            ),
                        }, {
                            header: 'Paid',
                            field: 'paid',
                            width: 145,
                            renderCell: row => (
                                <div className='fdc jcc'>
                                    <Tooltip title={!row.paid ? '' : `${row.paidByName} - ${row.paidByEmployeeNumber}`}>
                                        <CheckCircleIcon className={!row.paid ? 'cg2' : 'cp'} />
                                    </Tooltip>
                                </div>
                            ),
                        }, {
                            header: 'Created By',
                            field: 'createdByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.createdByEmployee}>
                                    <div>
                                        {
                                            row.createdByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }, {
                            header: 'Updated By',
                            field: 'updatedByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.updatedByEmployee}>
                                    <div>
                                        {
                                            row.updatedByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }]}
                    />
                </Card>
                <ConfirmDialog
                    open={!!selectedDelete}
                    title={'Confirm Delete?'}
                    message={!selectedDelete ? '' : `Delete Invoice for ${selectedDelete.transformerRef} of ${selectedDelete.totalIncVat.toLocaleCurrency()}, this cannot be undone?`}
                    onClose={this.onDelete}
                    transition={Transitions.Up}
                />
                <div className='fdr jcfe'>
                    <StandardFab aria-label='add' disabled={isLoading} onClick={this.onEditClick}>
                        <Add />
                    </StandardFab>
                </div>
            </div>
        );
    };
}

    
const MunicipalityInvoiceList = MunicipalityInvoiceListComponent;

export default MunicipalityInvoiceList;
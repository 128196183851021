import { dispatch, getState } from '../../..';
import PumpingStationVoluteCasingMakeHelper, { IPumpingStationVoluteCasingMake } from '../../../../@types/model/masterData/pumpingStation/voluteCasingMake';
import GeneralFunctions from '../../../general/functions';
import MasterDataPumpingStationVoluteCasingMakeActions from './actions';

export default class MasterDataPumpingStationVoluteCasingMakeFunctions {
    private static listener : undefined | (() => void);


    public static readonly load = async () => {
        if (MasterDataPumpingStationVoluteCasingMakeFunctions.listener) return;
        
        dispatch(MasterDataPumpingStationVoluteCasingMakeActions.setLoading(true));
        dispatch(MasterDataPumpingStationVoluteCasingMakeActions.set([]));

        try {
            MasterDataPumpingStationVoluteCasingMakeFunctions.listener = PumpingStationVoluteCasingMakeHelper
                .collection()
                .onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IPumpingStationVoluteCasingMake>) => {
                    const state = getState().masterData.pumpingStation.voluteCasingMake;

                    const list = state.voluteCasingMakes.slice();
                
                    const session = getState().auth.session;
                    if (!session) return;

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const item = f.doc.data();
                        const index = list.findIndex(n => n.id === item.id);


                        switch (f.type) {
                            case 'added':
                                list.push(item);
                                break;
                            case 'modified':
                                list.splice(index, 1, item);
                                break;
                            case 'removed':
                                list.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(MasterDataPumpingStationVoluteCasingMakeActions.set(list));
                    dispatch(MasterDataPumpingStationVoluteCasingMakeActions.setLoading(false));
                }, (ex) => {
                    GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
                    dispatch(MasterDataPumpingStationVoluteCasingMakeActions.setLoading(false));
                }, () => {
                    dispatch(MasterDataPumpingStationVoluteCasingMakeActions.setLoading(false));
                    MasterDataPumpingStationVoluteCasingMakeFunctions.listener = undefined;
                });

        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
            dispatch(MasterDataPumpingStationVoluteCasingMakeActions.setLoading(false));
        }
    };
}
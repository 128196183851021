import * as React from 'react';
import moment, { Moment } from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';
import { Icon, IconButton } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import OutsideClicker from '../outsideClicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dateRangePicker = require('react-date-range');

interface IDateRangePickerProps {
    start : Moment | null;
    end : Moment | null;
    onChange : (start : Moment | null, end : Moment | null) => void;
}

interface IDateRangePickerState {
    showPicker : boolean;
}

interface IDateRange {
    selection : {
        startDate : Date | null; 
        endDate : Date | null;
    };
}

export default class DateRangePicker extends React.Component<IDateRangePickerProps, IDateRangePickerState> {

    constructor (props : IDateRangePickerProps) {
        super(props);

        this.state = {
            showPicker: false,
        };
    }

    private readonly selectedToDateChange = (date : MaterialUiPickersDate) => {
        this.props.onChange(this.props.start, date);
    };

    private readonly selectedFromDateChange = (date : MaterialUiPickersDate) => {
        this.props.onChange(date, this.props.end);
    }; 

    private readonly handleDateRangeClick = () => {
        this.setState({
            showPicker: !this.state.showPicker,
        });
    };

    private readonly handleOutsideDateRangeClick = () => {
        this.setState({
            showPicker: false,
        });
    };

    private readonly handleDateRangeChange = (ranges : IDateRange) => {
        const start = !ranges.selection.startDate ? null : moment({ y: ranges.selection.startDate.getFullYear(), M: ranges.selection.startDate.getMonth(), d: ranges.selection.startDate.getDate() });
        const end = !ranges.selection.endDate ? null : moment({ y: ranges.selection.endDate.getFullYear(), M: ranges.selection.endDate.getMonth(), d: ranges.selection.endDate.getDate()});
   
        this.props.onChange(start, end);
    };

    public render() {

        const selectionRange = {
            startDate: this.props.start?.toDate(),
            endDate: this.props.end?.toDate(),
            key: 'selection',
        };

        return (

            <div className={'fdr flx1 posr posb0'}>
                <div className={'pr20'}>
                    <DatePicker 
                        value={this.props.start}
                        format={DATEPICKER_FORMAT_DEFAULT}
                        label={'Start Date'}
                        className={'TextField'}
                        margin={'normal'}
                        onChange={this.selectedFromDateChange}
                        fullWidth
                    />
                </div>
                <div>
                    <DatePicker 
                        value={this.props.end}
                        format={DATEPICKER_FORMAT_DEFAULT}
                        label={'End Date'}
                        className={'TextField'}
                        margin={'normal'}
                        onChange={this.selectedToDateChange}
                        fullWidth
                    />
                </div>
                <div className={'pr20 aife'}>
                    <IconButton onClick={this.handleDateRangeClick} >
                        <Icon>date_range</Icon>
                    </IconButton>
                </div>
                {
                    this.state.showPicker && (
                        <OutsideClicker 
                            className={'posa zi2 posr0 post70 bw1 bocg3'}
                            onClickedOutside={this.handleOutsideDateRangeClick}
                        >
                            <dateRangePicker.DateRangePicker 
                                ranges={[selectionRange]}
                                onChange={this.handleDateRangeChange}
                            />
                        </OutsideClicker>
                    )
                }
            </div>
        );

    }
}


import '../../style/transformer/MunicipalityInvoice.scss';
import { CircularProgress, FormControl, IconButton, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import TransformerGroupDropdown from '../customComponents/selector/transformer/GroupSelector';
import { ITransformerGroup } from '../../@types/model/transformer/transformerGroup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { AddCircle, Close } from '@material-ui/icons';
import DropdownButton from '../customComponents/button/DropdownButton';
import { IMunicipalityInvoice, MunicipalityInvoiceHelper, waterBlocksTypes } from '../../@types/model/transformer/municipalityInvoice';
import { firestore } from 'firebase/app';
import GeneralFunctions from '../../store/general/functions';
import MunicipalityInvoiceEditDialog from './dialog/Edit';
import StadiumButton from '../customComponents/button/StadiumButton';
import { IUserSession } from '../../@types/employee';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import { Transitions } from '../customComponents/animations/Transitions';
import AppFunctionsService from '../../services/appFunctionsService';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';

interface IMunicipalityInvoiceCaptureProps extends RouteComponentProps {
    session ?: IUserSession | null;
}

interface IMunicipalityInvoiceCaptureState {
    group : string | null;

    monthDate : Moment | null;

    isLoading : boolean;

    invoices : Array<IMunicipalityInvoice>;

    selectedInvoice : IMunicipalityInvoice | null;
}

class MunicipalityInvoiceCaptureComponent extends React.PureComponent<IMunicipalityInvoiceCaptureProps, IMunicipalityInvoiceCaptureState> {
    private readonly maxDate = moment.utc().startOf('month').add(1, 'day');
    private listener : null | (() => void);
    constructor(props : IMunicipalityInvoiceCaptureProps) {
        super(props);
        this.state = {
            group: null,
            isLoading: false,
            monthDate: null,
            invoices: [],
            selectedInvoice: null,
        };

        this.listener = null;
    }

    public componentDidMount() : void {
        const urlParams = new URLSearchParams(this.props.location.search);
        
        const monthDate = Number(urlParams.get('month'));
        const group = urlParams.get('group');

        if (monthDate && group) {
            this.setState({
                monthDate: moment.utc(monthDate),
                group,
            }, this.refresh);
        }
    }

    public componentDidUpdate(prevProps : Readonly<IMunicipalityInvoiceCaptureProps>, prevState : Readonly<IMunicipalityInvoiceCaptureState>) : void {
        const refresh = prevState.group !== this.state.group
            || prevState.monthDate?.valueOf() !== this.state.monthDate?.valueOf();
        
        if (refresh) {
            this.refresh();
        }
    }

    private readonly refresh = () => {
        if (this.listener) this.listener();

        const {
            group,
            monthDate,
        } = this.state;

        if (!monthDate) return;
        if (!group) return;

        this.setState({
            isLoading: true,
            invoices: [],
        });

        this.listener = MunicipalityInvoiceHelper.collection()
            .where('group', '==', group)
            .where('monthDate', '==', firestore.Timestamp.fromMillis(monthDate.valueOf()))
            .onSnapshot((snapshot) => {
                const invoices = this.state.invoices.slice();

                snapshot.docChanges().forEach((f) => {
                    const data = f.doc.data();
    
                    switch (f.type) {
                        case 'added':
                            invoices.splice(f.newIndex, 0, data);
                            break;
                        case 'modified':
                            invoices.splice(f.oldIndex, 1);
                            invoices.splice(f.newIndex, 0, data);
                            break;
                        case 'removed':
                            invoices.splice(f.oldIndex, 1);
                            break;
                    }
                });

                this.setState({
                    invoices,
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Invoices');
            });
    };

    private readonly onGroupChange = (value ?: ITransformerGroup | undefined) => {
        this.setState({
            group: value?.name ?? null,
        });
    };

    private readonly onMonthDateChanged = (momentDate : moment.Moment | null) => {
        this.setState({
            monthDate: !momentDate?.isValid() ? null : moment.utc(momentDate.valueOf()).startOf('month'),
        });
    };

    private readonly onClearDateClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        this.setState({
            monthDate: null,
        });
    };

    private readonly onAddClick = () => {
        const { session } = this.props;

        if (!session) return;
        const selectedInvoice : IMunicipalityInvoice = {
            id: '',
            createdBy: session.firebaseUser.uid,
            createdByEmployee: session.employee.EmployeeNumber ?? '',
            createdByName: session.firebaseUser.displayName ?? '',
            createdOn: moment.utc().valueOf(),
            updatedBy: session.firebaseUser.uid,
            updatedByName: session.firebaseUser.displayName ?? '',
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedOn: moment.utc().valueOf(),
        } as IMunicipalityInvoice;
        
        this.setState({
            selectedInvoice,
        });
    };

    private readonly onEditClose = () => {
        this.setState({
            selectedInvoice: null,
        });
    };

    private readonly onRowClick = (event : React.MouseEvent<HTMLTableRowElement>) => {
        const invoice = this.state.invoices.find(x => x.id === event.currentTarget.id);

        this.setState({
            selectedInvoice: invoice ?? null,
        });
    };

    private readonly getData = (props : IMunicipalityInvoiceCaptureProps, state : IMunicipalityInvoiceCaptureState) => state.invoices;
    private readonly getSearch = (props : IMunicipalityInvoiceCaptureProps) => props.location.search as string;

    public readonly getInvoices = createSelector(
        [this.getData],
        (invoices) => {
            return lodash
                .chain(invoices)
                .sortBy(x => x.accountNumber)
                .value();
        },
    );

    public readonly getAccountNumbers = createSelector(
        [this.getInvoices],
        (invoices) => {
            return lodash
                .chain(invoices)
                .map(x => x.accountNumber)
                .uniq()
                .value();
        },
    );

    public readonly getId = createSelector(
        [this.getSearch],
        (search) => {
            const urlParams = new URLSearchParams(search);
        
            return urlParams.get('id');
        },
    );

    public readonly render = () => {
        const {
            group,
            isLoading,
            monthDate,
            selectedInvoice,
        } = this.state;

        const invoices = this.getInvoices(this.props, this.state);
        const accountNumbers = this.getAccountNumbers(this.props, this.state);
        const id = this.getId(this.props);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'fdc mh0 mw0'}>
                    <Toolbar disableGutters>
                        <div className='fdr flx1 jcfe'>
                            <span className='flx3' />
                            <div className={'flx1 ais mb10 pl10 pr10'}>
                                <TransformerGroupDropdown
                                    fullWidth
                                    value={group}
                                    onChange={this.onGroupChange}
                                    type={'Municipality'}
                                    disabled={isLoading || !!id}
                                    required
                                />
                            </div>
                            <div className={'flx1 ais mb10 pl10 pr10'}>
                                <FormControl fullWidth>
                                    <KeyboardDatePicker
                                        disabled={isLoading || !!id}
                                        value={monthDate}
                                        views={['month']}
                                        maxDate={this.maxDate}
                                        required
                                        error={!monthDate}
                                        onChange={this.onMonthDateChanged}
                                        format='MMM YYYY'
                                        label='Select Account Month...'
                                        autoComplete='off'
                                        id='monthDate'
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    {
                                                        monthDate &&
                                                        <IconButton size='small' onClick={this.onClearDateClick}>
                                                            <Close />
                                                        </IconButton>
                                                    }
                                                    <DropdownButton />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </div>
                            <div className={'flx1 aic mb10 pr10'}>
                                <StadiumButton
                                    className='flx1 jcfs aic pl5 bcpd'
                                    color='secondary'
                                    onClick={this.onAddClick}
                                    aria-label='Edit Invoice'
                                    disabled={!monthDate || isLoading}
                                >
                                    <AddCircle className='cw' />
                                    <Typography className='ml15 cw fwm fs14'>ADD MUNICIPAL READING</Typography>
                                </StadiumButton>
                            </div>
                        </div>
                    </Toolbar>
                </div>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc mh0 mw0'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    <div className='fdc hfill mh0 mw0'>
                        <div className='oyo'>
                            <Table className='municipality-invoice-table'>
                                <TableHead>
                                    <TableRow className='header-row'>
                                        <TableCell width={200} colSpan={2}>
                                        </TableCell>
                                        <TableCell className='electricity' width={1600} align='center' colSpan={14}>
                                            ELECTRICITY
                                        </TableCell>
                                        <TableCell className='water' width={1900} align='center' colSpan={7 + (waterBlocksTypes.length * 2)}>
                                            WATER
                                        </TableCell>
                                        <TableCell className='other' width={700} align='center' colSpan={9}>
                                            OTHER
                                        </TableCell>
                                        <TableCell align='center' width={700} colSpan={5}>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className='sub-header-row'>
                                        <TableCell align='center' className='accountNumber'>
                                            Account Number
                                        </TableCell>
                                        <TableCell align='center' className='dueDate'>
                                            Due Date
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s1'>
                                            Basic Interest
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s1'>
                                            Metered Interest
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s1'>
                                            Fixed Cost/Basic Charge
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s1'>
                                            Demand Cost
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s2'>
                                            Days
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s2'>
                                            Off Peak Consumption KWH
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s2'>
                                            Standard Consumption KWH
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s2'>
                                            Peak Consumption KWH
                                        </TableCell>
                                        <TableCell align='center' className='electricity-consumption-kwh'>
                                            CONSUMPTION KWH
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s3'>
                                            Off Peak Cost
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s3'>
                                            STD Cost
                                        </TableCell>
                                        <TableCell align='center' className='electricity-s3'>
                                            Peak Cost
                                        </TableCell>
                                        <TableCell align='center' className='electricity-usage-zar'>
                                            USAGE ZAR
                                        </TableCell>
                                        <TableCell align='center' className='electricity-total-cost'>
                                            ELECTRICITY TOTAL COST
                                        </TableCell>
                                        <TableCell align='center' className='water-s1'>
                                            Water Availability Cost
                                        </TableCell>
                                        <TableCell align='center' className='water-s1'>
                                            Water Days (Optional)
                                        </TableCell>
                                        <TableCell align='center' className='water-s1'>
                                            Basic Interest
                                        </TableCell>
                                        <TableCell align='center' className='water-s1'>
                                            Metered Interest
                                        </TableCell>
                                        {
                                            waterBlocksTypes.map((block) => (
                                                <React.Fragment key={`water_block_${block}`}>
                                                    <TableCell align='center' className='water-s1'>
                                                        Water Consumption BLOCK {block}
                                                    </TableCell>
                                                    <TableCell align='center' className='water-s1'>
                                                        Water Cost BLOCK {block}
                                                    </TableCell>
                                                </React.Fragment>
                                            ))
                                        }
                                        <TableCell align='center' className='water-total-consumption'>
                                            TOTAL WATER CONSUMPTION (KL)
                                        </TableCell>
                                        <TableCell align='center' className='water-total-consumption-cost'>
                                            TOTAL WATER CONSUMPTION COST (ZAR)
                                        </TableCell>
                                        <TableCell align='center' className='water-total-cost'>
                                            TOTAL WATER COST (ZAR)
                                        </TableCell>
                                        <TableCell align='center' className='property-s1'>
                                            Property Rates
                                        </TableCell>
                                        <TableCell align='center' className='property-s1'>
                                            Property Rates REBATE
                                        </TableCell>
                                        <TableCell align='center' className='property-s1'>
                                            Property Rates INTEREST
                                        </TableCell>
                                        <TableCell align='center' className='property-s1'>
                                            Yearly Property TAX
                                        </TableCell>
                                        <TableCell align='center' className='property-total'>
                                            PROPERTY RATES TOTAL
                                        </TableCell>
                                        <TableCell align='center' className='property-s2'>
                                            Waste Disposal REFUSE
                                        </TableCell>
                                        <TableCell align='center' className='property-s2'>
                                            Waste Disposal INTEREST
                                        </TableCell>
                                        <TableCell align='center' className='property-s2'>
                                            Sanitation SEWERAGE
                                        </TableCell>
                                        <TableCell align='center' className='property-s2'>
                                            Sanitation INTEREST
                                        </TableCell>
                                        <TableCell align='center' className='total-invoice-excl-vat'>
                                            TOTAL INVOICE COST VAT EXCL
                                        </TableCell>
                                        <TableCell align='center' className='total-invoice-vat'>
                                            TOTAL VAT
                                        </TableCell>
                                        <TableCell align='center' className='total-invoice-incl-vat'>
                                            TOTAL AMOUNT DUE VAT INCL
                                        </TableCell>
                                        <TableCell align='center' className='total-invoice-credit'>
                                            CREDIT
                                        </TableCell>
                                        <TableCell align='center' className='total-invoice-rebill'>
                                            REBILL
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        invoices.map(invoice => (
                                            <TableRow
                                                key={invoice.id}
                                                className={`row ${invoice.id === id ? 'selected' : ''}`}
                                                id={invoice.id}
                                                onClick={this.onRowClick}
                                            >
                                                <TableCell align='center' className='no-hover accountNumber'>
                                                    {
                                                        invoice.accountNumber
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover dueDate'>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnly(invoice.dueDate)
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.electricity?.basicInterest.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.electricity?.meteredInterest.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.electricity?.fixedBasicCharge.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.electricity?.demandCost.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.electricity?.days ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        !invoice.electricity?.offPeakConsumptionKwh ? '-' :
                                                            invoice.electricity.offPeakConsumptionKwh.toLocaleString(
                                                                'en-ZA', {
                                                                    minimumFractionDigits: 1,
                                                                }
                                                            )
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        !invoice.electricity?.standardConsumptionKwh ? '-' :
                                                            invoice.electricity.standardConsumptionKwh.toLocaleString(
                                                                'en-ZA', {
                                                                    minimumFractionDigits: 1,
                                                                }
                                                            )
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        !invoice.electricity?.peakConsumptionKwh ? '-' :
                                                            invoice.electricity.peakConsumptionKwh.toLocaleString(
                                                                'en-ZA', {
                                                                    minimumFractionDigits: 1,
                                                                }
                                                            )
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover electricity-consumption'>
                                                    {
                                                        invoice.electricity?.totalConsumption.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        !invoice.electricity?.offPeakCostReading ? '-' :
                                                            invoice.electricity.offPeakCostReading.toLocaleString(
                                                                'en-ZA', {
                                                                    minimumFractionDigits: 1,
                                                                }
                                                            )
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.electricity?.standardCostReading.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        !invoice.electricity?.peakCostReading ? '-' :
                                                            invoice.electricity.peakCostReading.toLocaleString(
                                                                'en-ZA', {
                                                                    minimumFractionDigits: 1,
                                                                }
                                                            )
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover electricity-consumption'>
                                                    {
                                                        invoice.electricity?.usageZar.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover electricity-total'>
                                                    {
                                                        invoice.electricity?.total.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.water?.waterAvailabilityCost.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        !invoice.water?.days ? '-' :
                                                            invoice.water.days.toLocaleString(
                                                                'en-ZA', {
                                                                    minimumFractionDigits: 1,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            )
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.water?.basicInterest.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.water?.meteredInterest.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                {
                                                    waterBlocksTypes.map((block) => (
                                                        <React.Fragment key={`water_block_value_${block}`}>
                                                            <TableCell align='center'>
                                                                {
                                                                    invoice.water?.blocks[block].consumption.toLocaleString(
                                                                        'en-ZA', {
                                                                            minimumFractionDigits: 1,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ) ?? '-'
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {
                                                                    invoice.water?.blocks[block].cost.toLocaleString(
                                                                        'en-ZA', {
                                                                            minimumFractionDigits: 1,
                                                                            maximumFractionDigits: 2,
                                                                        }
                                                                    ) ?? '-'
                                                                }
                                                            </TableCell>
                                                        </React.Fragment>
                                                    ))
                                                }
                                                <TableCell align='center' className='no-hover water-total-consumption'>
                                                    {
                                                        invoice.water?.totalConsumption.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover water-total-consumption-cost'>
                                                    {
                                                        invoice.water?.totalConsumptionCost.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover water-total-cost'>
                                                    {
                                                        invoice.water?.total.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.other?.propertyRates.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.other?.propertyRatesRebate.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.other?.propertyInterest?.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.other?.yearlyPropertyTax.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover property-rates-total'>
                                                    {
                                                        invoice.other?.propertyRatesTotal.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.other?.wasteDisposal.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.other?.wasteDisposalInterest?.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.other?.sanitationSewerage.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {
                                                        invoice.other?.sanitationSewerageInterest?.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        ) ?? '-'
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover invoice-total-excl'>
                                                    {
                                                        invoice.totalExVat.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover invoice-total-vat'>
                                                    {
                                                        invoice.totalVat.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover invoice-total-incl'>
                                                    {
                                                        invoice.totalIncVat.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover invoice-total-credit'>
                                                    {
                                                        invoice.credit.toLocaleString(
                                                            'en-ZA', {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell align='center' className='no-hover invoice-total-rebill'>
                                                    <Tooltip title={!invoice.reasonForRebill ? '' : invoice.reasonForRebill}>
                                                        <span>
                                                            {
                                                                invoice.rebillTotal.toLocaleString(
                                                                    'en-ZA', {
                                                                        minimumFractionDigits: 1,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                )
                                                            }
                                                        </span>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                }
                <MunicipalityInvoiceEditDialog
                    value={selectedInvoice}
                    transition={Transitions.Up}
                    isLoading={isLoading}
                    onClose={this.onEditClose}
                    group={group}
                    accountNumbers={accountNumbers}
                    monthDate={monthDate}
                />
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

    
const MunicipalityInvoiceCapture = connect(
    mapStateToProps,
)(withRouter(MunicipalityInvoiceCaptureComponent));

export default MunicipalityInvoiceCapture;
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import DamFilterPopper from '../popper/DamFilterPopper';

interface IDamFilterButtonProps {
    id ?: string;
    open : boolean;

    onClick ?: () => void;

    code : string | null;
    name : string | null;
    type : string | null;
    division : string | null;
    managementArea : string | null;
    inspectionDue : boolean | null;

    onChange ?: (
        code : string | null,
        name : string | null,
        type : string | null,
        division : string | null,
        managementArea : string | null,
        inspectionDue : boolean | null,
    ) => void;

    disabled ?: boolean;
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
}

interface IDamFilterButtonState {
}

export default class DamFilterButton extends React.PureComponent<IDamFilterButtonProps, IDamFilterButtonState> {
    private readonly filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : IDamFilterButtonProps) {
        super(props);
        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    public readonly render = () => {
        const { id, onChange, code, name, type, division,
            managementArea, disabled, onClick, color,
            inspectionDue } = this.props;
        return (
            <> 
                <Tooltip title='Filter'>
                    <div className={''}>
                        <IconButton
                            ref={this.filterRef}
                            aria-label='toggle filter menu'
                            onClick={onClick}
                            disabled={disabled}
                            color={color}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <DamFilterPopper
                    id={id}
                    anchorEl={this.filterRef.current}
                    open={this.props.open}
                    onCancelClick={onClick}
                    onChange={onChange}
                    code={code}
                    name={name}
                    type={type}
                    division={division}
                    managementArea={managementArea}
                    inspectionDue={inspectionDue}
                />
            </>
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddIcon from '@material-ui/icons/Add';
import TransformerPrepaidReadingHelper, { ITransformerPrepaidReading } from '../../../../@types/model/transformer/transformerPrepaidReading';
import GeneralFunctions from '../../../../store/general/functions';
import { IUserSession } from '../../../../@types/employee';
import { firestore } from 'firebase/app';
import { ITransformer } from '../../../../@types/model/transformer/transformer';
import StandardFab from '../../../customComponents/button/StandardFab';
import TransformerDropdown from '../../../customComponents/selector/transformer/TransformerSelector';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../../appConstants';
import { FormHelperText, InputAdornment } from '@material-ui/core';
import uuid from 'uuid';
import firebaseApp from '../../../../services/firebaseService';
import { EnumFileUploadQueueType } from '../../../../@types/model/files/enum';
import Dropzone from 'react-dropzone';
import ImageEmbedView from '../../../customComponents/embed/ImageEmbedView';
import FileEmbedView from '../../../customComponents/embed/FileEmbedView';

interface IEditTransformerPrepaidReadingDialogProps {
    reading ?: ITransformerPrepaidReading;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    disabled ?: boolean;

    session ?: IUserSession | null;
}

interface IEditTransformerPrepaidReadingDialogState {
    open : boolean;
    date : moment.Moment | null;
    electricityAmount : number;
    electricityUnitAmount : number;
    waterAmount : number;
    comment : string;

    division : string;
    subArea : string;
    group : string;

    transformerRef ?: string;
    isLoading : boolean;

    imageFile : File | null;
}

export default class EditTransformerPrepaidReadingDialog extends React.Component<IEditTransformerPrepaidReadingDialogProps, IEditTransformerPrepaidReadingDialogState> {
    constructor(props : IEditTransformerPrepaidReadingDialogProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
            date: null,
            electricityAmount: 0,
            electricityUnitAmount: 0,
            waterAmount: 0,
            comment: '',
            division: '',
            subArea: '',
            group: '',
            imageFile: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTransformerPrepaidReadingDialogProps, prevState : IEditTransformerPrepaidReadingDialogState) => {
        if (!this.props.reading && prevState.open && !this.state.open) {
            this.setState({
                date: null,
                electricityAmount: 0,
                electricityUnitAmount: 0,
                waterAmount: 0,
                comment: '',
                transformerRef: '',
                division: '',
                subArea: '',
                group: '',
                imageFile: null,
            });
        }

        if (this.props.reading && !prevState.open && this.state.open) {
            this.setState({
                date: !this.props.reading.date ? moment().utc().startOf('day') : moment.utc(this.props.reading.date),
                electricityAmount: !this.props.reading.electricityTotal ? 0 : this.props.reading.electricityTotal,
                electricityUnitAmount: !this.props.reading.electricityUnits ? 0 : this.props.reading.electricityUnits,
                waterAmount: !this.props.reading.waterTotal ? 0 : this.props.reading.waterTotal,
                comment: !this.props.reading.comment ? '' : this.props.reading.comment,
                transformerRef: !this.props.reading.transformerRef ? '' : this.props.reading.transformerRef,
                division: !this.props.reading.division ? '' : this.props.reading.division,
                subArea: !this.props.reading.subArea ? '' : this.props.reading.subArea,
                group: !this.props.reading.group ? '' : this.props.reading.group,
            });
        }
    };

    private readonly onElectricityAmountChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            electricityAmount: Number(event.currentTarget.value),
        });
    };

    private readonly onElectricityUnitAmountChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            electricityUnitAmount: Number(event.currentTarget.value),
        });
    };

    private readonly onWaterAmountChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            waterAmount: Number(event.currentTarget.value),
        });
    };
    
    private readonly onCommentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            comment: event.currentTarget.value,
        });
    };

    private readonly onDateChange = (date : moment.Moment | null) => {
        this.setState({
            date: date?.asUTC() ?? null,
        });
    };

    private readonly save = async () => {
        const { session, reading } = this.props;

        if (!session) return;

        this.setState({
            isLoading: true,
        });

        const ref = TransformerPrepaidReadingHelper.doc(reading?.id);

        try {
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const {
                    electricityAmount,
                    electricityUnitAmount,
                    waterAmount,
                    comment,
                    date,
                    transformerRef,
                    group,
                    division,
                    subArea,
                    imageFile,
                } = this.state;
        
                if (!transformerRef) throw new Error('Ref Required');

                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);
                const data = doc.data();

                const guid = reading?.guid ?? uuid.v4();
                TransformerPrepaidReadingHelper.save(transaction, {
                    ...data,
                    guid,
                    electricityTotal: electricityAmount,
                    electricityUnits: electricityUnitAmount,
                    waterTotal: waterAmount,
                    transformerRef,
                    comment,
                    date: date?.valueOf() ?? 0,
                    group,
                    division: division,
                    subArea: subArea,
                    imageFileName: data?.imageFileName ?? imageFile?.name ?? '',
                    createdBy: data?.createdBy ?? session.firebaseUser.uid,
                    createdByName: data?.createdByName ?? session.employee.Name,
                    createdByEmployee: data?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
                    createdOn: data?.createdOn ?? firestore.Timestamp.now().toMillis(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: firestore.Timestamp.now().toMillis(),
                    isActive: data?.isActive ?? true,
                    isWeb: data?.isWeb ?? true,
                } as ITransformerPrepaidReading);
    
                if (imageFile) {
                    await TransformerPrepaidReadingHelper.uploadFile(
                        transformerRef,
                        imageFile,
                        {
                            collection: TransformerPrepaidReadingHelper.COLLECTION_NAME,
                            fieldName: 'imageFileUrl',
                            fileType: EnumFileUploadQueueType.TransformerPrepaidReading.toString(),
                            refGuid: guid,
                            thumbnailFieldName: 'imageFileThumbnailUrl',
                        }
                    );
                }
            });

            this.setState({
                open: false,
            });
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar(`An error while saving reading. ${ex}`);
        } finally {
            this.setState({
                isLoading: false,
            });
        }

    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onTransformerChange = (value ?: ITransformer) => {
        this.setState({
            transformerRef: value?.EPNumber,
            division: value?.Division ?? '',
            subArea: value?.SubArea ?? '',
            group: value?.EPGroup ?? '',
        });
    };

    private readonly onFileDrop = (acceptedFiles : Array<File>) => {
        if (!acceptedFiles.length) return;

        this.setState({
            imageFile: new File([acceptedFiles[0].slice()], `${acceptedFiles[0].name}`, {
                type: acceptedFiles[0].type,
            }),
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            disabled,
            reading,
        } = this.props;
        
        const {
            electricityAmount,
            electricityUnitAmount,
            waterAmount,
            comment,
            date,
            open,
            transformerRef,
            isLoading,
            imageFile,
        } = this.state;

        return (
            <>
                {
                    reading &&
                    <Tooltip title='Info'>
                        <div>
                            <IconButton color='primary' onClick={this.onClick}
                                aria-label='Info'>
                                {
                                    !disabled ? (
                                        <Icon>edit</Icon>
                                    ) : (
                                        <InfoOutlinedIcon />
                                    )
                                }
                            </IconButton>
                        </div>
                    </Tooltip>
                }
                {
                    !reading &&
                    <Tooltip title='Add'>
                        <div>
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onClick}>
                                <AddIcon />
                            </StandardFab>
                        </div>
                    </Tooltip>
                }
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-division-info-title'
                    aria-describedby='transformer-division-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {reading ? 'Edit' : 'Create'} Prepaid Reading
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Add'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs mt26'}>
                                <div className={'flx1 ais pr20'}>
                                    <TransformerDropdown
                                        value={transformerRef}
                                        label='Select Transformer'
                                        fullWidth
                                        required
                                        onChange={this.onTransformerChange}
                                        disabled={disabled}
                                        type='prepaid'
                                        autoFocus
                                    />
                                </div>
                                <div className={'flx1 ais pr10'}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='date'
                                            label='Date'
                                            value={date}
                                            format={DATEPICKER_FORMAT_DEFAULT}
                                            onChange={this.onDateChange}
                                            required
                                            error={!date}
                                            helperText={DATEPICKER_FORMAT_DEFAULT}
                                        />
                                        {
                                            !date &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais pl10'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='electricityAmount'
                                            label='Electricity Amount'
                                            value={!electricityAmount ? '' : electricityAmount}
                                            onChange={this.onElectricityAmountChange}
                                            type={'number'}
                                            required
                                            error={!electricityAmount}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='end'>
                                                        R
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !electricityAmount &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs mt26'}>
                                <div className={'flx1 ais pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='electricityUnitAmount'
                                            label='Electricity Units'
                                            value={!electricityUnitAmount ? '' : electricityUnitAmount}
                                            onChange={this.onElectricityUnitAmountChange}
                                            type={'number'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        kWh
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais pr10'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='waterAmount'
                                            label='Water Amount'
                                            value={!waterAmount ? '' : waterAmount}
                                            onChange={this.onWaterAmountChange}
                                            type={'number'}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='end'>
                                                        R
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais pl10'}>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs mt26'}>
                                <div className={'flx1 ais'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={disabled}
                                            autoComplete='off'
                                            id='comment'
                                            label='Comment'
                                            value={comment}
                                            onChange={this.onCommentChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            {
                                !this.props.reading?.imageFileUrl &&
                                <div className='fdr h400 mt26'>
                                    <Dropzone onDrop={this.onFileDrop} accept={'image/*,application/pdf'}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section className='fdc flx1'>
                                                <div className='fdc flx1' {...getRootProps()}>
                                                    <input disabled={isLoading} multiple={false} {...getInputProps()} />
                                                    
                                                    {
                                                        !imageFile &&
                                                        <div className='fdr flx1 jcc aic'>
                                                            <Typography className='cg0' variant='body2'>
                                                                DRAG &amp; DROP FILE
                                                            </Typography>
                                                        </div>
                                                    }
                                                    
                                                    {
                                                        imageFile?.type.includes('image') &&
                                                        <ImageEmbedView width='100%' file={imageFile} />
                                                    }
                                                    
                                                    {
                                                        imageFile?.type.includes('pdf') &&
                                                        <FileEmbedView file={imageFile} />
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || disabled} type='submit' variant='contained' color='primary'>
                                    OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary'>
                                    CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </>);
    };
}

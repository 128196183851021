import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { IRootState } from '../../../@types/redux';
import { IChemicalFillingPoint } from '../../../@types/model/fillingPoint/chemical';
import { IUserSession } from '../../../@types/employee';
import FillingPointChemicalFunctions from '../../../store/fillingPoint/chemical/functions';
import { createSelector } from 'reselect';
import PrimaryTabs from '../../customComponents/tab/PrimaryTabs';
import PrimaryTab from '../../customComponents/tab/PrimaryTab';
import TabViews from '../../customComponents/tab/TabViews';
import ChemicalFillingPointDivisionView from '../chemical/view/ChemicalFillingPointDivisionView';
import ChemicalFillingPointLocationInfoView from '../chemical/view/ChemicalFillingPointLocationView';
import ChemicalFillingPointInfoView from '../chemical/view/ChemicalFillingPointInfoView';
import ChemicalFillingPointInspectionView from '../chemical/view/ChemicalFillingPointInspectionView';

interface IChemicalFillingPointInfoDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    points : Array<IChemicalFillingPoint>;
    selectedGuid ?: string;
    isLoading : boolean;

    session ?: IUserSession | null;
}

interface IChemicalFillingPointInfoDialogState {
    tabIndex : number;
}

class ChemicalFillingPointInfoDialogComponent extends React.PureComponent<IChemicalFillingPointInfoDialogProps, IChemicalFillingPointInfoDialogState> {
    constructor(props : IChemicalFillingPointInfoDialogProps) {
        super(props);

        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onClose = () => {
        FillingPointChemicalFunctions.setSelected();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly getData = (props : IChemicalFillingPointInfoDialogProps) => props.points;
    private readonly getGuid = (props : IChemicalFillingPointInfoDialogProps) => props.selectedGuid;

    public readonly getStation = createSelector(
        [
            this.getData,
            this.getGuid,
        ],
        (
            points,
            guid,
        ) => {
            if (!guid) return undefined;
            return points.find(x => x.guid === guid);
        },
    );

    public readonly render = () => {
        const { fullWidth, transition, maxWidth, fullScreen, session, isLoading } = this.props;
        const { tabIndex } = this.state;
        
        const point = this.getStation(this.props);
        return (
            <Dialog
                open={!!point}
                onClose={this.onClose}
                aria-labelledby='point-info-dialog-title'
                aria-describedby='point-info-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}>
                <AppBar className='posr' position='static' elevation={0}>
                    <Toolbar variant='dense'>
                        {
                            point &&
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {point.code} - {point.name}
                            </Typography>
                        }
                        <span className={'flx1'} />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close' disabled={isLoading}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <PrimaryTabs
                        value={tabIndex}
                        onChange={this.onTabChange}
                        aria-label='Info Tabs'
                        variant='scrollable'
                    >
                        <PrimaryTab label='SUMMARY' />
                        <PrimaryTab label='LOCATION' />
                        <PrimaryTab label='DIVISION INFO' />
                        <PrimaryTab label='INSPECTIONS' />
                    </PrimaryTabs>
                </AppBar>
                <div className={'fdc flx1 hfill'}>
                    {
                        point &&
                        session &&
                        <TabViews index={tabIndex}>
                            <ChemicalFillingPointInfoView point={point} />
                            <ChemicalFillingPointLocationInfoView point={point} />
                            <ChemicalFillingPointDivisionView point={point} />
                            <ChemicalFillingPointInspectionView point={point} />
                        </TabViews>
                    }
                </div>
            </Dialog>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        points: state.fillingPoints.chemical.points,
        selectedGuid: state.fillingPoints.chemical.selectedGuid,
        isLoading: state.fillingPoints.chemical.isLoading,
        session: state.auth.session,
    };
};

const ChemicalFillingPointInfoDialog = connect(
    mapStateToProps,
)(ChemicalFillingPointInfoDialogComponent);

export default ChemicalFillingPointInfoDialog;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { BOREHOLE_OVERALL_STATUS, BOREHOLE_STATUS } from '../../../appConstants';
import lodash from 'lodash';
import appFunctionsService from '../../../services/appFunctionsService';
import { createSelector } from 'reselect';
import EditBoreholeTestInfo from '../dialog/test/EditInfo';
import { Transitions } from '../../customComponents/animations/Transitions';
import { IUserSession } from '../../../@types/employee';
import EditBoreholeTestDrum from '../dialog/test/EditDrumTest';
import EditBoreholeTestRunTimers from '../dialog/test/EditRunTimer';
import { IBoreholeTest } from '../../../@types/model/borehole/boreholeTest';
import { Card } from '@material-ui/core';
import FirebaseImageButton from '../../customComponents/button/FirebaseImageButton';

interface IBoreholeTestItemViewProps {
    boreholeTest : IBoreholeTest;
    session ?: IUserSession | null;
    isLoading : boolean;
}

interface IBoreholeTestItemViewState {}

export default class BoreholeTestItemView extends React.Component<IBoreholeTestItemViewProps, IBoreholeTestItemViewState> {
    constructor(props : IBoreholeTestItemViewProps) {
        super(props);
        this.state = {};
    }

    public readonly getData = (state : IBoreholeTestItemViewState, props : IBoreholeTestItemViewProps) => props.boreholeTest;
    public readonly getPumpTime = createSelector(
        [this.getData],
        (boreholeTest) => {
            return lodash.sumBy(boreholeTest.Runtimers, t => t.Duration) / 60;
        },
    );

    public readonly render = () => {
        const { boreholeTest, session, isLoading } = this.props;
        const pumpTime = this.getPumpTime(this.state, this.props);

        return (
            <div className='fdc flx1'>
                <div className='fdc'>
                    <Card className='fdc flx1'>
                        <div className='fdr aic pl20 pr20 h50 dvbg'>
                            <Typography component={'span'} className={'fdr aic flx1 fs14 fwb cw'}>
                                BOREHOLE TEST INFO
                                <span className='flx1' />
                                {
                                    session?.employee.IsBoreholeAdmin &&
                                    <EditBoreholeTestInfo boreholeTest={boreholeTest} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                                }
                            </Typography>
                        </div>
                        <div className={'fdr pl20 aic h50 bcg0'}>
                            <div className='flx1'>
                                <Typography className={'fs14 fw600'}>
                                    STATUS
                                </Typography>
                            </div>
                            <div className='flx2'>
                                <Typography className={'fs14 fw400'}>
                                    {boreholeTest.BoreholeOverallStatus ? BOREHOLE_OVERALL_STATUS[boreholeTest.BoreholeOverallStatus.toString()] : 'None'}
                                </Typography>
                            </div>
                            <div className='flx1'>
                                <Typography className={'fs14 fw600'}>
                                    DRY
                                </Typography>
                            </div>
                            <div className='flx2'>
                                <Typography className={'fs14 fw400'}>
                                    {
                                        boreholeTest.Dry ? 'YES' : 'NO'
                                    }
                                </Typography>
                            </div>
                            <div className='flx1'>
                                <Typography className={'fs14 fw600'}>
                                    WATER LEVEL STATUS
                                </Typography>
                            </div>
                            <div className='flx2'>
                                <Typography className={'fs14 fw400'}>
                                    {boreholeTest.BoreholeStatus ? BOREHOLE_STATUS[boreholeTest.BoreholeStatus.toString()] : 'None'}
                                </Typography>
                            </div>
                        </div>
                        <div className={'fdr pl20 aic h50'}>
                            <div className='flx1'>
                                <Typography className={'fs14 fw600'}>
                                    EQUIPPED
                                </Typography>
                            </div>
                            <div className='flx2'>
                                <Typography className={'fs14 fw400'}>
                                    {
                                        boreholeTest.Equipped ? 'YES' : 'NO'
                                    }
                                </Typography>
                            </div>
                            <div className='flx1'>
                                <Typography className={'fs14 fw600'}>
                                    WATER LEVEL
                                </Typography>
                            </div>
                            <div className='flx2'>
                                <Typography className={'fs14 fw400'}>
                                    {boreholeTest.WaterLevel ? `${boreholeTest.WaterLevel} m` : 'None'}
                                </Typography>
                            </div>
                            <div className='flx1'>
                                <Typography className={'fs14 fw600'}>
                                    PROBLEMS
                                </Typography>
                            </div>
                            <div className='flx2'>
                                <Typography className={'fs14 fw400'}>
                                    {
                                        boreholeTest.BoreholeAttributes.length === 0 ? 'None' : boreholeTest.BoreholeAttributes.join(',\n')
                                    }
                                </Typography>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className='fdr mt10'>
                    <div className='fdc flx1 mr15'>
                        {
                            boreholeTest.Equipped &&
                            <Card className='fdc flx1'>
                                <div className='fdr aic pl20 pr20 h50 dvbg'>
                                    <Typography component={'span'} className={'fdr aic flx1 fs14 fwb cw'}>
                                        TIMERS
                                        <span className='flx1' />
                                        {
                                            session?.employee.IsBoreholeAdmin &&
                                            <EditBoreholeTestRunTimers boreholeTest={boreholeTest} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                                        }
                                    </Typography>
                                </div>
                                {
                                    boreholeTest.Runtimers.map((t, i) => (
                                        <div key={`timer_${i}`} className={`fdr pl20 aic h50 ${(i + 1) % 2 === 0 ? '' : 'bcg0'}`}>
                                            <div className='flx1'>
                                                <Typography className={'fs14 fw600'}>
                                                    START
                                                </Typography>
                                            </div>
                                            <div className='flx2'>
                                                <Typography className={'fs14 fw400'}>
                                                    {appFunctionsService.formatDateTimeToTimeOnly(t.StartTime)}
                                                </Typography>
                                            </div>
                                            <div className='flx1'>
                                                <Typography className={'fs14 fw600'}>
                                                    END
                                                </Typography>
                                            </div>
                                            <div className='flx2'>
                                                <Typography className={'fs14 fw400'}>
                                                    {appFunctionsService.formatDateTimeToTimeOnly(t.EndTime)}
                                                </Typography>
                                            </div>
                                            <div className='flx1'>
                                                <Typography className={'fs14 fw600'}>
                                                    DURATION
                                                </Typography>
                                            </div>
                                            <div className='flx2'>
                                                <Typography className={'fs14 fw400'}>
                                                    {(t.Duration / 60).toFixed(0)} h
                                                </Typography>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className={`fdr pl20 aic h50 ${boreholeTest.Runtimers.length % 2 !== 0 ? '' : 'bcg0'}`}>
                                    <div className='flx1'>
                                    </div>
                                    <div className='flx2'>
                                    </div>
                                    <div className='flx1'>
                                    </div>
                                    <div className='flx2'>
                                    </div>
                                    <div className='flx1'>
                                        <Typography className={'fs14 fw600'}>
                                            TOTAL
                                        </Typography>
                                    </div>
                                    <div className='flx2'>
                                        <Typography className={'fs14 fw400'}>
                                            {pumpTime.toFixed(2)} h
                                        </Typography>
                                    </div>
                                </div>
                            </Card>
                        }
                    </div>
                    <div className='fdc flx1 ml15'>
                        {
                            boreholeTest.Equipped &&
                            <Card className='fdc flx1'>
                                <div className='fdr aic pl20 pr20 h50 dvbg'>
                                    <Typography component={'span'} className={'fdr aic flx1 fs14 fwb cw'}>
                                        DRUM TEST
                                        <span className='flx1' />
                                        {
                                            session?.employee.IsBoreholeAdmin &&
                                            <EditBoreholeTestDrum boreholeTest={boreholeTest} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                                        }
                                    </Typography>
                                </div>
                                <div className={'fdr pl20 aic h50 bcg0'}>
                                    <div className='flx1'>
                                        <Typography className={'fs14 fw600'}>
                                            CONTAINER
                                        </Typography>
                                    </div>
                                    <div className='flx2'>
                                        <Typography className={'fs14 fw400'}>
                                            {!boreholeTest.Container ? 'None' : `${boreholeTest.Container} L`}
                                        </Typography>
                                    </div>
                                    <div className='flx1'>
                                        <Typography className={'fs14 fw600'}>
                                            DURATION
                                        </Typography>
                                    </div>
                                    <div className='flx2'>
                                        <Typography className={'fs14 fw400'}>
                                            {boreholeTest.Duration ? boreholeTest.Duration : 'None'}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={'fdr pl20 aic h50'}>
                                    <div className='flx1'>
                                        <Typography className={'fs14 fw600'}>
                                            YIELD
                                        </Typography>
                                    </div>
                                    <div className='flx2'>
                                        <Typography className={'fs14 fw400'}>
                                            {boreholeTest.Yield ? boreholeTest.Yield : '0'} m³/hr
                                        </Typography>
                                    </div>
                                    <div className='flx1'>
                                        <Typography className={'fs14 fw600'}>
                                            PUMP RATE
                                        </Typography>
                                    </div>
                                    <div className='flx2'>
                                        <Typography className={'fs14 fw400'}>
                                            {boreholeTest.Yield ? (boreholeTest.Yield * pumpTime).toFixed(2) : '0'} m³/day
                                        </Typography>
                                    </div>
                                </div>
                                {
                                    boreholeTest.FlowmeterImagePath &&
                                    <div className={'fdr pl20 aic h50 bcg0'}>
                                        <div className='flx1'>
                                            <Typography className={'fs14 fw600'}>
                                                FLOW METER IMAGE
                                            </Typography>
                                        </div>
                                        <div className='flx2'>
                                            <Typography className={'fs14 fw400'}>
                                                <FirebaseImageButton
                                                    fileName={boreholeTest.FlowmeterImagePath.substring(boreholeTest.FlowmeterImagePath.lastIndexOf('/'))}
                                                    path={`${boreholeTest.FlowmeterImagePath.substring(0, boreholeTest.FlowmeterImagePath.lastIndexOf('/'))}`}
                                                />
                                            </Typography>
                                        </div>
                                        <div className='flx1'>
                                            <Typography className={'fs14 fw600'}>
                                            </Typography>
                                        </div>
                                        <div className='flx2'>
                                            <Typography className={'fs14 fw400'}>
                                            </Typography>
                                        </div>
                                    </div>
                                }
                            </Card>
                        }
                    </div>
                </div>
            </div>
        );
    };
}

import { Card, FormControl, Typography } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { Moment } from 'moment';
import React from 'react';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import BoreholeCalibrationDateSelector from '../../../../customComponents/form/boreholeCalibration/BoreholeCalibrationDateSelector';
import TextFormField from '../../../../customComponents/form/TextFormField';

interface IBoreholeCalibrationEditDialogLogisticsTabProps {
    isLoading : boolean;

    onNextClick ?: () => void;
    onCancelClick ?: () => void;
}

interface IBoreholeCalibrationEditDialogLogisticsTabState {}

export default class BoreholeCalibrationEditDialogLogisticsTab extends React.PureComponent<IBoreholeCalibrationEditDialogLogisticsTabProps, IBoreholeCalibrationEditDialogLogisticsTabState> {
    constructor(props : IBoreholeCalibrationEditDialogLogisticsTabProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { onNextClick, onCancelClick } = this.props;
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <Field 
                    id='date'
                    name='date'
                >
                    {
                        (params : FieldProps<Moment>) => <BoreholeCalibrationDateSelector {...params} />
                    }
                </Field>
                <div className='fdr ais jcfs mt12'>
                    <Card className='fdc flx1'>
                        <div className={'jcc aic wfill dvblg h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                BOREHOLE LOGISTICS
                            </Typography>
                        </div>
                        <div className={'fdc pb20 pt20 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                DATA RECORDER’S PERSONEL NUMBER
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <TextFormField
                                    inputProps={{
                                        className: 'cp fwb',
                                        style: {
                                            textAlign: 'center',
                                        },
                                    }}
                                    id={'employeeNumber'}
                                    name={'employeeNumber'}
                                    fullWidth
                                    required
                                />
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 bcg2 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                DATA RECORDER’S PERSONEL NAME
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <TextFormField
                                    inputProps={{
                                        className: 'cp fwb',
                                        style: {
                                            textAlign: 'center',
                                        },
                                    }}
                                    id={'employeeName'}
                                    name={'employeeName'}
                                    fullWidth
                                    required
                                />
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                INSTALLED YIELD (m<sup>3</sup>/h)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='installedYield'
                                        name='installedYield'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                CASTING DIAMETER (mm)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='casingDiameter'
                                        name='casingDiameter'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 bcg2 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                CEMENT BLOCK HEIGHT ABOVE SOIL (m)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='concreteBlockHeight'
                                        name='concreteBlockHeight'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 bcg2 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                CASTING HEIGHT ABOVE CEMENT BLOCK (m)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='casingHeight'
                                        name='casingHeight'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                BOREHOLE DEPTH (m)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='holeDepth'
                                        name='holeDepth'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 bcg2 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                STATIC WATER LEVEL BEFORE PIEZOMETER TUBE INSTALLATION (m)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='beforePiezoLevel'
                                        name='beforePiezoLevel'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                PIEZOMETER TUBE HEIGHT ABOVE CASING RIM (m)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='piezoHeight'
                                        name='piezoHeight'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 bcg2 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                STATIC WATER LEVEL AFTER PIEZOMETER TUBE INSTALLATION (m)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='afterPiezoLevel'
                                        name='afterPiezoLevel'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 bclg jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                AVAILABLE DRAWDOWN
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='availableDrawdown'
                                        name='availableDrawdown'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className={'fdc pb20 pt20 jcc ais'}>
                            <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                TESTING PUMP INSTALLED DEPTH (m)
                            </Typography>
                            <div className='fdr mr30 ml30'>
                                <FormControl fullWidth required>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='pumpDepth'
                                        name='pumpDepth'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </Card>
                    <span className='w30' />
                    <div className='fdc flx1 ais'>
                        <Card className='fdc'>
                            <div className={'jcc aic wfill dvbg h50'}>
                                <Typography className={'fs16 fw400 cw'}>
                                    COORDINATES
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    LATITUDE
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='latitude'
                                        name='latitude'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                            </div>
                            <div className={'fdc pb20 pt20 bcg2 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    LONGITUDE
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='longitude'
                                        name='longitude'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                            </div>
                            <div className={'fdc pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    ELEVATION
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <FormControl fullWidth required>
                                        <TextFormField
                                            inputProps={{
                                                className: 'cp fwb',
                                                style: {
                                                    textAlign: 'center',
                                                },
                                            }}
                                            id='elevation'
                                            name='elevation'
                                            fullWidth
                                            required
                                            type='number'
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </Card>
                        <span className='flx1' />
                        <div className='fdr jcfe'>
                            <div className='mr15'>
                                <OutlinedStadiumButton onClick={onCancelClick}>
                                    Cancel
                                </OutlinedStadiumButton>
                            </div>
                            <div>
                                <StadiumButton onClick={onNextClick}>
                                    Next
                                </StadiumButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationFilterSize } from '../../../../@types/model/masterData/pumpingStation/filterSize';

type MasterDataPumpingStationFilterSizeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationFilterSizeState {
    filterSizes : Array<IPumpingStationFilterSize>;
    isLoading : boolean;
}
const initialState = {
    filterSizes: [],
    isLoading: false,
};

export default function filterSizeReducer(state : IMasterDataPumpingStationFilterSizeState = initialState, action : MasterDataPumpingStationFilterSizeActionType) : IMasterDataPumpingStationFilterSizeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, filterSizes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

import React, { ChangeEvent } from 'react';
import firebase from 'firebase/app';
import lodash from 'lodash';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { IRootState, IAuthState, RootAction, DispatchCall } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { enqueueSnackbar } from '../../../../store/general/actions';
import { bindActionCreators, Dispatch } from 'redux';
import PumpHelper, { IPump } from '../../../../@types/model/borehole/pump';
import MasterDataPumpFunctions from '../../../../store/masterData/pump/functions';

interface IPumpListProps {
    auth : IAuthState;
    generalShowErrorSnackbar : DispatchCall<string>;

    pumps : Array<IPump>;
    isLoading : boolean;
}

interface IPumpListState {
    searchText : string;

    selectedId : string | null;
    newTypeText : string;
}

class PumpList extends React.PureComponent<IPumpListProps, IPumpListState> {
    constructor(props : IPumpListProps) {
        super(props);
        this.state = {
            searchText: '',
            newTypeText: '',
            selectedId: null,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    };

    private readonly load = () => {
        MasterDataPumpFunctions.load();
    };

    private readonly onPumpClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!event.currentTarget.id) return;
        this.setState({
            selectedId: event.currentTarget.id,
        });
    };

    private readonly onSearchChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            searchText: event.currentTarget.value,
        });
    };

    private readonly onNewTypeChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            newTypeText: event.currentTarget.value,
        });
    };

    private readonly onClearClick = () => {
        this.setState({
            searchText: '',
        });
    };

    private readonly onAddClick = () => {
        if (!this.state.searchText) return;

        if (lodash.some(this.props.pumps, n => n.name.toLocaleLowerCase() === this.state.searchText.toLocaleLowerCase())) {
            this.props.generalShowErrorSnackbar(`${this.state.searchText} already added.`);
            return;
        }

        const pump : IPump = {
            id: '',
            name: this.state.searchText,
            types: [],
            createdBy: this.props.auth.session?.firebaseUser.uid ?? '',
            createdByEmployee: this.props.auth.session?.employee.EmployeeNumber ?? '',
            createdByName: this.props.auth.session?.employee.Name ?? '',
            createdOn: firebase.firestore.Timestamp.now().toMillis(),
            updatedBy: this.props.auth.session?.firebaseUser.uid ?? '',
            updatedByEmployee: this.props.auth.session?.employee.EmployeeNumber ?? '',
            updatedByName: this.props.auth.session?.employee.Name ?? '',
            updatedOn: firebase.firestore.Timestamp.now().toMillis(),
            isActive: true,
        };

        PumpHelper.save(pump);
    };

    private readonly onAddTypeClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        if (!this.state.newTypeText) return;

        const selected = this.getSelected(this.props, this.state);

        if (!selected) return;

        if (lodash.some(selected.types, n => n.toLocaleLowerCase() === this.state.newTypeText.toLocaleLowerCase())) {
            this.props.generalShowErrorSnackbar(`${this.state.newTypeText} already added.`);
            return;
        }

        const types = selected.types.slice();
        types.push(this.state.newTypeText);

        PumpHelper.save({
            ...selected,
            types,
        });

        this.setState({
            newTypeText: '',
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        const selected = this.getSelected(this.props, this.state);

        if (!selected) return;

        PumpHelper.save({
            ...selected,
            isActive: false,
        });
    };

    private readonly onDeleteTypeClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const selected = this.getSelected(this.props, this.state);

        if (!selected) return;

        const types = selected.types.slice();
        const index = types.indexOf(event.currentTarget.value);
        types.splice(index, 1);

        PumpHelper.save({
            ...selected,
            types,
        });

        this.setState({
            newTypeText: '',
        });
    };

    private readonly getPumps = (props : IPumpListProps) => props.pumps;
    private readonly getSearchText = (props : IPumpListProps, state : IPumpListState) => state.searchText;
    private readonly getSelectedId = (props : IPumpListProps, state : IPumpListState) => state.selectedId;

    private readonly getFilteredPumps = createSelector(
        [this.getPumps, this.getSearchText],
        (pumps, searchText) => {
            return pumps.filter(n => n.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        },
    );

    private readonly getPumpsTotal = createSelector(
        [this.getPumps],
        (pumps) => {
            return pumps.length;
        },
    );

    private readonly getSelected = createSelector(
        [this.getPumps, this.getSelectedId],
        (pumps, selectedId) => {
            if (!selectedId) return null;
            return pumps.find(x => x.id === selectedId);
        },
    );

    private readonly onDragEnd = (dropResult : DropResult) => {
        const selected = this.getSelected(this.props, this.state);

        if (!selected) return;
        if (!dropResult.destination) return;
        
        const types = selected.types.slice();
        types.splice(dropResult.source.index, 1);
        types.splice(dropResult.destination.index, 0, dropResult.draggableId);

        PumpHelper.save({
            ...selected,
            types,
        });
    };

    public readonly render = () => {
        const { searchText, newTypeText } = this.state;
        const { isLoading } = this.props;
        const pumps = this.getFilteredPumps(this.props, this.state);
        const pumpsTotal = this.getPumpsTotal(this.props);
        const selected = this.getSelected(this.props, this.state);
        
        return (
            <div className={'fdc ais flx1 p10'}>
                <div className='fdr flx1 hfill'>
                    <div className='fdc flx1 hfill'>
                        <Paper className='fdc flx1 hfill m10'>
                            <Toolbar className={'fdr aic'}>
                                <TextField
                                    value={searchText}
                                    onChange={this.onSearchChanged}
                                    placeholder='Search'
                                    margin='dense'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <Tooltip title='Clear'>
                                                <IconButton onClick={this.onClearClick}>
                                                    <Icon className='cr'>close</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                                <Tooltip title='Add'>
                                    <div>
                                        <IconButton color='primary' onClick={this.onAddClick} aria-label='Add'>
                                            <Icon>add</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Toolbar>
                            <Divider />
                            <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                                Total {pumpsTotal}
                            </Typography>
                            <Divider />
                            <List className={'fdc flx1 oya'}>
                                <div className='mnh4'>
                                    { isLoading && <LinearProgress />}
                                </div>
                                {
                                    pumps.map(n => (
                                        <ListItem button key={n.id} id={n.id} onClick={this.onPumpClick} selected={selected?.id === n.id} >
                                            <ListItemText
                                                primary={n.name}
                                            />
                                            <ListItemSecondaryAction>
                                                <Tooltip title='Disable'>
                                                    <IconButton className='cr' value={n.name} onClick={this.onDeleteClick} aria-label='Delete'>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Paper>
                    </div>
                    <div className='fdc flx4 hfill'>
                        {
                            !!selected &&
                            <Paper className='fdc flx1 hfill m10'>
                                <Toolbar className={'fdr aic'}>
                                    <Typography variant='h5' color='inherit'>
                                        {selected.name}
                                    </Typography>
                                    <span className='flx1' />
                                    <TextField
                                        value={newTypeText}
                                        onChange={this.onNewTypeChanged}
                                        placeholder='New Type'
                                        margin='dense'
                                        InputProps={{
                                            endAdornment: (
                                                <Tooltip title='Add'>
                                                    <IconButton color='primary' onClick={this.onAddTypeClick}>
                                                        <Icon>add</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                    <span className='flx1' />
                                </Toolbar>
                                <Divider />
                                <div className='fdc flx1 hfill'>
                                    <List className='fdc flx1 oya'>
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId={selected.id}>
                                                {
                                                    (provided) => (
                                                        <div {...provided.droppableProps}
                                                            ref={provided.innerRef}>
                                                            {
                                                                selected.types.map((type, index) => (
                                                                    <Draggable key={type} draggableId={type} index={index}>
                                                                        {
                                                                            (dragProvided) => (
                                                                                <ListItem button id={type}
                                                                                    ref={dragProvided.innerRef}
                                                                                    {...dragProvided.draggableProps}
                                                                                    {...dragProvided.dragHandleProps}>
                                                                                    <ListItemText
                                                                                        primary={type}
                                                                                    />
                                                                                    {
                                                                                        <ListItemSecondaryAction>
                                                                                            <IconButton className='cr'
                                                                                                value={type} onClick={this.onDeleteTypeClick} aria-label='Delete'>
                                                                                                <Icon>delete</Icon>
                                                                                            </IconButton>
                                                                                        </ListItemSecondaryAction>
                                                                                    }
                                                                                </ListItem>)
                                                                        }
                                                                    </Draggable>
                                                                ))
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                            </Droppable>
                                        </DragDropContext>
                                    </List>
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        pumps: state.masterData.pump.pumps,
        isLoading: state.masterData.pump.isLoading,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    generalShowErrorSnackbar: (message : string) => dispatch(enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    })),
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PumpList);

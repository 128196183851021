import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import BoreholeCalibrationHelper, { BoreholeCalibrationStagesType, IBoreholeCalibration, IBoreholeCalibrationDrawdownAchieved, IBoreholeCalibrationFormValues, IBoreholeCalibrationStage } from '../../../../@types/model/borehole/boreholeCalibration';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { EmployeeHelper, IUserSession } from '../../../../@types/employee';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import firebaseApp from '../../../../services/firebaseService';
import GeneralFunctions from '../../../../store/general/functions';
import { EnumFileUploadQueueType } from '../../../../@types/model/files/enum';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { CircularProgress, Tab, Tabs } from '@material-ui/core';
import TabViews from '../../../customComponents/tab/TabViews';
import Dropzone from 'react-dropzone';
import FileEmbedView from '../../../customComponents/embed/FileEmbedView';
import FormikForm from '../../../customComponents/form/FormikForm';
import BoreholeCalibrationEditDialogLogisticsTab from './tabs/LogisticsTab';
import BoreholeCalibrationEditDialogNeighbouringTab from './tabs/NeighbouringTab';
import BoreholeCalibrationEditDialogStepsTab from './tabs/StepsTab';
import firebase from 'firebase/app';

interface IEditBoreholeCalibrationProps {
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;


    boreholeCalibration ?: IBoreholeCalibration;

    session ?: IUserSession | null;
    borehole ?: IBorehole;
}

interface IEditBoreholeCalibrationState {
    isLoading : boolean;
    open : boolean;

    file : File | null;

    tabIndex : number;

    initialValues : IBoreholeCalibrationFormValues | null;
}

class EditBoreholeCalibrationComponent extends React.Component<IEditBoreholeCalibrationProps, IEditBoreholeCalibrationState> {
    constructor(props : IEditBoreholeCalibrationProps) {
        super(props);
        this.state = {
            isLoading: false,
            open: false,
            file: null,
            tabIndex: 0,
            initialValues: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeCalibrationProps, prevState : IEditBoreholeCalibrationState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                isLoading: false,
                file: null,
                tabIndex: 0,
                initialValues: BoreholeCalibrationHelper.initialFormValues(this.props.boreholeCalibration),
            });
        }

    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly save = async (formValues : IBoreholeCalibrationFormValues) => {
        const { boreholeCalibration } = this.props;
        const { 
            file,
        } = this.state;

        const ref = BoreholeCalibrationHelper.doc(boreholeCalibration?.ref.id);

        try {
            this.setState({
                isLoading: true,
            });

            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { session, borehole } = this.props;
        
                if (!session) return;
                if (!borehole) return;
                if (!file && !boreholeCalibration) return;
    
                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);
    
                const data = doc.data();
    
                const boreholeCalibrationStages : Record<number, IBoreholeCalibrationStage> = {};

                Object.keys(formValues.stages).forEach((key) => {
                    const stage = Number(key) as BoreholeCalibrationStagesType;
                    boreholeCalibrationStages[stage] = {
                        CanComplete: true,
                        IsCompleted: !formValues.stages[stage].stageEntries.some(x => !x.abstractionRate),
                        IsActive: true,
                        StageEntries: formValues.stages[stage].stageEntries.map(x => ({
                            IsActive: !!x.abstractionRate || !!x.groundLevel,
                            AbstractionRate: x.abstractionRate ?? 0,
                            GroundLevel: x.groundLevel ?? 0,
                            Time: x.time,
                        })),
                    };
                });

                const achieved : Record<number, IBoreholeCalibrationDrawdownAchieved> = {};

                Object.keys(formValues.achieved).forEach((key) => {
                    const stage = Number(key) as BoreholeCalibrationStagesType;
                    const currentAchieved = formValues.achieved[stage];
                    achieved[stage] = {
                        PercentageOfDrawdown: currentAchieved.percentageOfDrawdown ?? 0,
                        GroundLevel: currentAchieved.groundLevel ?? 0,
                    };
                });

                transaction.set(ref, {
                    AfterPiezoLevel: formValues.afterPiezoLevel ?? data?.AfterPiezoLevel ?? 0,
                    AvailableDrawdown: formValues.availableDrawdown ?? data?.AvailableDrawdown ?? 0,
                    BeforePiezoLevel: formValues.beforePiezoLevel ?? data?.BeforePiezoLevel ?? 0,
                    Borehole: data?.Borehole ?? borehole.ref,
                    CasingDiameter: formValues.casingDiameter ?? data?.CasingDiameter ?? 0,
                    CasingHeight: formValues.casingHeight ?? data?.CasingHeight ?? 0,
                    ConcreteBlockHeight: formValues.concreteBlockHeight ?? data?.ConcreteBlockHeight,
                    Date: firebase.firestore.Timestamp.fromMillis(formValues.date.valueOf()),
                    DocumentName: file?.name ?? data?.DocumentName ?? null,
                    DocumentURL: !file ? (data?.DocumentURL ?? null) : '',
                    Elevation: formValues.elevation ?? data?.Elevation ?? 0,
                    Geo: new firebase.firestore.GeoPoint(
                        formValues.latitude ?? data?.Geo?.latitude ?? 0,
                        formValues.longitude ?? data?.Geo?.longitude ?? 0,
                    ),
                    EmployeeName: formValues.employeeName ?? data?.EmployeeName ?? null,
                    EmployeeNumber: formValues.employeeNumber ?? data?.EmployeeNumber ?? null,
                    GUID: data?.GUID ?? formValues.guid,
                    HoleDepth: formValues.holeDepth ?? data?.HoleDepth ?? 0,
                    InstalledYield: formValues.installedYield ?? data?.InstalledYield ?? 0,
                    Note: formValues.note,
                    PiezoHeight: formValues.piezoHeight ?? data?.PiezoHeight ?? 0,
                    PumpDepth: formValues.pumpDepth ?? data?.PumpDepth ?? 0,
                    NeighbouringBoreholes: formValues.neighbouringBoreholes.map((neighbour) => ({
                        BoreholeCode: neighbour.boreholeCode,
                        Time: neighbour.time.format('HH:mm'),
                        WaterLevel: neighbour.waterLevel,
                    })),
                    RecoveryEntries: formValues.recoveryEntries.map((entry) => ({
                        AbstractionRate: 0,
                        GroundLevel: entry.groundLevel ?? 0,
                        IsActive: !!entry.groundLevel,
                        Time: entry.time,
                    })),
                    BoreholeCalibrationStages: boreholeCalibrationStages,
                    Achieved: achieved,
                    TestRig: data?.TestRig ?? null,
                    TestRigNr: data?.TestRigNr ?? null,
                    CreatedBy: data?.CreatedBy ?? EmployeeHelper.doc(session.firebaseUser.uid),
                    CreatedByName: data?.CreatedByName ?? session.employee.Name,
                    CreatedByEmployee: data?.CreatedByEmployee ?? session.employee.EmployeeNumber ?? '',
                    CreatedOn: data?.CreatedOn ?? firebase.firestore.Timestamp.now(),
                    UpdatedBy: EmployeeHelper.doc(session.firebaseUser.uid),
                    UpdatedByName: session.employee.Name,
                    UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                    UpdatedOn: firebase.firestore.Timestamp.now(),
                    IsSent: false,
                    IsActive: data?.IsActive ?? true,
                    IsWeb: data?.IsWeb ?? true,
                }, {
                    merge: true,
                });
    
                if (file) {
                    await BoreholeCalibrationHelper.uploadFile(
                        data?.Borehole.id ?? borehole.Code,
                        file,
                        {
                            collection: 'borehole_calibration',
                            fieldName: 'DocumentURL',
                            fileType: EnumFileUploadQueueType.BoreholeCalibration.toString(),
                            refGuid: formValues.guid,
                            thumbnailFieldName: '',
                        }
                    );
                }
            });

            this.setState({
                open: false,
            });
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving calibration.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
        
    };

    private readonly onSubmit = (formValues : IBoreholeCalibrationFormValues) => {
        this.save(formValues);
    };

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.borehole) return;
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onFileDrop = (acceptedFiles : Array<File>) => {
        if (!acceptedFiles.length) return;

        this.setState({
            file: new File([acceptedFiles[0].slice()], `${acceptedFiles[0].name}`, {
                type: acceptedFiles[0].type,
            }),
        });
    };

    private readonly onNextClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onBackClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
        });
    };
    

    public readonly render = () => {
        const {
            transition,
            boreholeCalibration,
        } = this.props;

        const {
            open,
            isLoading,
            tabIndex,
            file,
            initialValues,
        } = this.state;
        return (
            <React.Fragment>
                <Tooltip title={boreholeCalibration ? 'Edit' : 'Upload'}>
                    <div>
                        {
                            !!boreholeCalibration &&
                            <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Edit' disabled={isLoading}>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                        {
                            !boreholeCalibration &&
                            <StadiumButton style={{
                                height: 42,
                                borderRadius: 40,
                            }} className='jcc aic p0' color='primary' onClick={this.onClick} aria-label='Upload Video'>
                                <Icon className='cp ml10'>upload</Icon>
                                <Typography className='fw600 fs16 cp ml20 mr10'>DOCUMENT UPLOADS</Typography>
                            </StadiumButton>
                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    fullScreen
                    fullWidth
                    aria-labelledby='borehole-calibration-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            {
                                !!boreholeCalibration &&
                                <Typography variant='h5' color='inherit'>
                                    Edit Calibration - {AppFunctionsService.formatDateTimeToDatePicker(boreholeCalibration.Date ?? boreholeCalibration.CreatedOn)}
                                </Typography>
                            }
                            {
                                !boreholeCalibration &&
                                <Typography variant='h5' color='inherit'>
                                    Add Calibration
                                </Typography>
                            }
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    {
                        initialValues &&
                        <FormikForm
                            onSubmit={this.onSubmit}
                            className='fdc hfill bcg0'
                            validateOnMount
                            initialValues={initialValues}
                            validationSchema={BoreholeCalibrationHelper.formSchema()}
                            enableReinitialize
                        >
                            {
                                (props) => (
                                    <>
                                        <DialogContent className='fdc hfill p0'>
                                            {
                                                isLoading &&
                                                <div className='fdc flx1 aic jcc'>
                                                    <CircularProgress />
                                                </div>
                                            }
                                            {
                                                !isLoading &&
                                                <div className='fdr'>
                                                    <div className='fdc w804'>
                                                        <div className={'jcfs aic wfill dvbg h50'}>
                                                            <Typography className={'ml15 fs14 fw500 cw '}>
                                                                PDF DOCUMENT
                                                            </Typography>
                                                            <span className='flx1' />
                                                        </div>
                                                        <div className='fdc h1132 wfill'>
                                                            <Dropzone onDrop={this.onFileDrop} accept={'application/pdf,image/*'}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className='fdc flx1'>
                                                                        <div className='fdc flx1' {...getRootProps()}>
                                                                            <input disabled={isLoading} multiple={false} {...getInputProps()} />
                                                                            
                                                                            {
                                                                                !file &&
                                                                                <div className='fdr flx1 jcc aic'>
                                                                                    <Typography className='cg0' variant='body2'>
                                                                                        DRAG &amp; DROP FILE
                                                                                    </Typography>
                                                                                </div>
                                                                            }
                                                                            
                                                                            {
                                                                                file &&
                                                                                <FileEmbedView file={file} />
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                    <div className='fdc flx1'>
                                                        <Tabs
                                                            value={tabIndex}
                                                            onChange={this.onTabChange}
                                                            aria-label='Info Tabs'
                                                            className={'h50'}
                                                            variant={'fullWidth'}
                                                            indicatorColor={'primary'}
                                                        >
                                                            <Tab label='BOREHOLE LOGISTICS' value={0} className={'cdg'} fullWidth/>
                                                            <Tab label='NEIGHBOURHOOD BOREHOLES' value={1} className={'cdg'} fullWidth/>
                                                            <Tab label='CALIBRATION TEST' value={2} className={'cdg'} fullWidth/>
                                                        </Tabs>
                                                        <div className={'fdc flx1 hfill mb15'}>
                                                            <TabViews index={tabIndex}>
                                                                <BoreholeCalibrationEditDialogLogisticsTab
                                                                    isLoading={isLoading}
                                                                    onNextClick={this.onNextClick}
                                                                    onCancelClick={this.onClose}
                                                                />
                                                                <BoreholeCalibrationEditDialogNeighbouringTab
                                                                    isLoading={isLoading}
                                                                    onNextClick={this.onNextClick}
                                                                    onBackClick={this.onBackClick}
                                                                />
                                                                <BoreholeCalibrationEditDialogStepsTab
                                                                    isLoading={isLoading}
                                                                    onSubmitClick={props.submitForm}
                                                                    onBackClick={this.onBackClick}
                                                                    isValid={props.isValid}
                                                                />
                                                            </TabViews>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </DialogContent>
                                    </>
                                )
                            }
                        </FormikForm>
                    }
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        borehole: state.borehole.borehole,
    };
};

const EditBoreholeCalibration = connect(
    mapStateToProps,
)(EditBoreholeCalibrationComponent);

export default EditBoreholeCalibration;
import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import Dialog from '@material-ui/core/Dialog';
import weatherFunctions from '../../store/weather/functions';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../customComponents/tab/TabPanel';
import { IUserSession } from '../../@types/employee';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import Tooltip from '@material-ui/core/Tooltip';
import WeatherStationDetailView from './view/DetailView';
import WeatherStationAuditView from './view/AuditView';
import { IWeatherStation } from '../../@types/model/weather/weatherStation';

interface IWeatherInfoProps {
    onClose ?: () => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    weather ?: IWeatherStation;
    isLoadingWeathers : boolean;

    session ?: IUserSession | null;
}

interface IWeatherInfoState {
    tabIndex : number;
    showDelete : boolean;
}

class WeatherInfo extends React.Component<IWeatherInfoProps, IWeatherInfoState> {
    constructor(props : IWeatherInfoProps) {
        super(props);

        this.state = {
            tabIndex: 0,
            showDelete: false,
        };
    }

    private readonly onClose = () => {
        weatherFunctions.setSelected();
    };

    private readonly onTabChange = (event : React.ChangeEvent<Record<string, unknown>>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly onDeleteClick = () => {
        this.setState({
            showDelete: true,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.props.weather) {
            weatherFunctions.deactivate(this.props.weather.WSNumber);
        }
        this.setState({
            showDelete: false,
        });
    };

    public readonly render = () => {
        const { weather, fullWidth, transition, maxWidth, fullScreen, session, isLoadingWeathers } = this.props;
        const { tabIndex, showDelete } = this.state;
        return (
            <Dialog
                open={!!weather}
                onClose={this.onClose}
                aria-labelledby='weather-info-dialog-title'
                aria-describedby='weather-info-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}>
                <AppBar className='posr' position='static'>
                    <Toolbar variant='dense'>
                        {
                            weather &&
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {weather.WSNumber} - {weather.WSName}
                            </Typography>
                        }
                        <span className={'flx1'} />
                        {
                            !!session?.employee.IsWeatherStationAdmin &&
                            <Tooltip title='Delete'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoadingWeathers}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        }
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <Tabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                        <Tab label='Details' value={0} />
                        <Tab label='Audits' value={1} />
                    </Tabs>
                </AppBar>
                <div className={'fdc flx1 hfill oys'}>
                    <TabPanel value={tabIndex} index={0}>
                        <div>
                            {
                                weather && session &&
                                <WeatherStationDetailView session={session} weatherStation={weather} isLoading={isLoadingWeathers} />
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <div>
                            {
                                weather && session &&
                                <WeatherStationAuditView session={session}/>
                            }
                        </div>
                    </TabPanel>
                </div>
                <ConfirmDialog open={showDelete} title={!weather ? '' : `Confirm Deactivating ${weather.WSNumber}`}
                    message={!weather ? '' : `Deactivate Weather ${weather.WSNumber}?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
            </Dialog>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        weather: state.weather.weatherStation,
        isLoadingWeathers: state.weather.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(WeatherInfo);

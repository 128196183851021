import React, { useEffect, useMemo, useState } from 'react';
import CustomMapBox, { IMapMarker } from '../customComponents/map/CustomMapBox';
import { AppBar, Dialog, Drawer, Icon, IconButton, Paper, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Transitions } from '../customComponents/animations/Transitions';

interface IBaseDataPoint {
  name ?: string;
  code ?: string;
  wrNumber ?: string;
  geo ?: firebase.firestore.GeoPoint | null;
}

interface IMapDialogProps<T> {
  isOpen : boolean;
  onDismiss : () => void;
  title : string;
  dataPoints : Array<T>
  selectedDataPoint : T | null;
  mapImages : Record<string, string>;
  identifier : string
}

const mapZoom = 8;

const MapDialog = <T extends IBaseDataPoint,>(props : IMapDialogProps<T>) : React.ReactElement => {
    const [mapCenter, setMapCenter] = useState<[number, number]>([30.1148622, -23.5520414]);
    const [selectedDataPoint, setSelectedDataPoint] = useState<T | undefined>(undefined);

    useEffect(() => {
        if (props.selectedDataPoint?.geo) {
            setMapCenter([props.selectedDataPoint?.geo?.latitude, props.selectedDataPoint?.geo?.longitude])
        }
    }, [])
  
    const handleSelectedFlowmeter = () => {
      const dataPointId = props.selectedDataPoint?.code;

      if (!dataPointId) return;

      onMarkerClick(dataPointId);
    };
  
    const selectMarker = (data: T) => {
      setSelectedDataPoint(data);
    };
  
    const onMarkerClick = (id: string) => {
      const data = props.dataPoints.slice().find(n => n.code === id);
      if (data) {
        selectMarker(data);
      }
    };
  
    const onCloseDrawer = () => {
      setSelectedDataPoint(undefined);
    };
    
    const filteredData = useMemo(() => {
        return props.dataPoints.filter(n => n.geo);

    }, [props.dataPoints]);
  
    const markers = useMemo(() => {
        return filteredData.map(n => ({
          coordinates: [n.geo?.longitude ?? 0, n.geo?.latitude ?? 0],
          iconSize: 0.35,
          icon: '1',
          id: n.code,
        } as IMapMarker));
        
      }, [filteredData]);
  
    return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transitions.Up}
            transitionDuration={500}
            onClose={props.onDismiss}
            fullScreen
            aria-labelledby='rain-meter-edit-dialog-title'
            aria-describedby='rain-meter-edit-description'>
            <AppBar className='fdr posr aic jcc' position='static'>
                <Toolbar className={'fdr flx1 aic jcc'}>
                    <Typography variant='h5' color='inherit'>
                        { Map }
                    </Typography>
                    <span className='flx1' />
                    <Tooltip title='Close'>
                        <div>
                            <IconButton color='inherit' onClick={props.onDismiss} aria-label='Close'>
                                <Icon>close</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Paper className={'flx1 wfill hfill fdr oxh'}>
                {
                    props.dataPoints.length > 0 &&
                    <CustomMapBox
                    id='flowmeter_map'
                    enableAreas
                    geoLocationControl
                    navigationControl
                    showScale
                    layerControls
                    center={mapCenter}
                    zoom={mapZoom}
                    images={props.mapImages}
                    onMarkerClick={onMarkerClick}
                    markers={markers}
                    selectedMarker={selectedDataPoint?.code}
                    />
                }
                </Paper>
            </div>
            <Drawer open={!!selectedDataPoint} anchor='right' variant='persistent'>
                <div className={'w300 flx1 fdc'}>
                <AppBar className='posr' position='static'>
                    <Toolbar variant='dense'>
                    <div className='h60' />
                    <Typography color='inherit' variant='h6' className={'flx1 ml10'} noWrap={true}>
                        {!!selectedDataPoint && selectedDataPoint.code}
                    </Typography>
                    <IconButton color={'inherit'} onClick={onCloseDrawer}>
                        <Icon color={'inherit'}>close</Icon>
                    </IconButton>
                    </Toolbar>
                </AppBar>
                {
                    selectedDataPoint &&
                    <div className='fdc flx1'>
                    <div className='fdr flx1'>
                        <div className={'fdc flx1'}>
                        <Typography className={'fdr bcDarkBlue fw700 cw p10'} variant='h6'>
                            SUMMARY
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                            Code
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                            {selectedDataPoint.code}
                            </Typography>
                        </div>
                        </div>
                    </div>
                    </div>
                }
                </div>
            </Drawer>
            </div>
      </Dialog>
    );
}

export default MapDialog;

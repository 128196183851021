import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import FlowmeterHelper from './flowmeter';

export interface IFlowmeterReading extends IBasicBaseClass {
  guid : string;
  flowmeterCode : string;
  flowmeterRef : string;
  date : number;
  
  reading : number;
  dialMeter : string;
  revs : number;
  milliSeconds : number;
  flow : number;
  photoUrl : string;
  thumbnailUrl : string;
}



export default class FlowmeterReadingHelper extends BasicBaseHelper {
  public static readonly COLLECTION_NAME = 'flowmeter_reading';

  private static converter : firebase.firestore.FirestoreDataConverter<IFlowmeterReading> = {
      fromFirestore: (snapshot) => {
          return FlowmeterReadingHelper.fromFirestoreDocument(snapshot);
      },
      toFirestore: (data : IFlowmeterReading) : firebase.firestore.DocumentData => {
          return FlowmeterReadingHelper.toFirestoreDocument(data);
      },
  };

  public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IFlowmeterReading {
      const result = super.fromFirestore(snapshot);
      const data = snapshot.data();

      if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
      return {
          ...data as IFlowmeterReading,
          ...result,
      };
  }

  public static toFirestoreDocument(data : IFlowmeterReading) {
      const result = super.toFirestore(data);
      const { id:_id, ...rest } = data;

      return {
          ...rest,
          ...result,
      };
  }

  public static doc(id ?: string) {
      if (!id) {
          return firebaseApp.firestore().collection(FlowmeterReadingHelper.COLLECTION_NAME).withConverter(FlowmeterReadingHelper.converter).doc();
      }

      return firebaseApp.firestore().collection(FlowmeterReadingHelper.COLLECTION_NAME).withConverter(FlowmeterReadingHelper.converter).doc(id);
  }

  public static save(flowmeter : IFlowmeterReading) {
      return FlowmeterReadingHelper.doc(flowmeter.id).set(flowmeter, {
          merge: true,
      });
  }
  
  public static collection(flowmeterId : string) {
      return firebaseApp
          .firestore()
          .collection(FlowmeterReadingHelper.COLLECTION_NAME)
          .where('flowmeterRef', '==', FlowmeterHelper.doc(flowmeterId))
          .withConverter(FlowmeterReadingHelper.converter);
  }
}
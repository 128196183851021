import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { motion } from 'framer-motion';

interface ISideMenuItemProps {
    icon : React.ReactNode;
    children ?: React.ReactNode;
    title : string;
}

interface ISideMenuItemState {
    open : boolean;
}

export default class SideMenuItem extends React.PureComponent<ISideMenuItemProps, ISideMenuItemState> {

    constructor(props : ISideMenuItemProps) {
        super(props);

        this.state = {
            open: false,
        };

    }

    private readonly handleClick = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    public render() {
        return (
            <List>
                <ListItem button onClick={this.handleClick} key={this.props.title}>
                    <ListItemIcon>
                        {this.props.icon}
                    </ListItemIcon>
                    <ListItemText primary={this.props.title} title={this.props.title} />
                    <motion.div animate={{ transform: this.state.open ? 'rotate(180deg)' : 'rotate(0deg)' }} className='mh0'>
                        <ExpandMore />
                    </motion.div>
                </ListItem>
                {!!this.state.open && <Divider />}
                <Collapse in={this.state.open} timeout='auto' unmountOnExit>
                    <div className='pl5'>
                        <List disablePadding>
                            {this.props.children}
                        </List>
                    </div>
                </Collapse>
            </List>
        );
    }
}

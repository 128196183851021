import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IStyledExpansionPanelProps {
    heading : string;
    key : string;

    actions ?: (expanded : boolean) => JSX.Element;
    children ?: React.ReactNode;
}

interface IStyledExpansionPanelState {
    expanded : boolean;
}

export class StyledExpansionPanel extends React.PureComponent<IStyledExpansionPanelProps, IStyledExpansionPanelState> {
    constructor(props : IStyledExpansionPanelProps) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    private readonly onChange = () => {
        this.setState({
            expanded: !this.state.expanded,
        });
    };

    public readonly render = () => {
        const { children, heading, actions } = this.props;
        const { expanded } = this.state;
        return (
            <Accordion onChange={this.onChange} className={'wfill mt16'}>
                <AccordionSummary expandIcon={<ExpandMoreIcon className={expanded ? 'cw' : 'cp'}/>} className={expanded ? 'dbclg' : 'bcw'}>
                    <div className={'jcsb wfill'}>
                        <Typography className={expanded ? 'cw' : 'cb'}>{heading}</Typography>
                        {
                            !!actions &&
                            actions(expanded)
                        }
                    </div>
                </AccordionSummary>
                <AccordionDetails className={'fdc flx1'}>
                    {children}
                </AccordionDetails>
            </Accordion>
        );
    };
}
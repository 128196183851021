import React from 'react';
import { IPermission, IEmployeeRole } from '../../../@types/employee';
import TreeItem from '@material-ui/lab/TreeItem';
import CheckTreeItem from './CheckTreeItem';

interface IRoleTreeItemProps {
    permissions : IPermission;
    parentNode : string;
    role : IEmployeeRole;
    onPermissionChange : (permissions : IPermission) => void;
}

interface IRoleTreeItemState {}

export default class RoleTreeItem extends React.PureComponent<IRoleTreeItemProps, IRoleTreeItemState> {
    constructor(props : IRoleTreeItemProps) {
        super(props);
        this.state = {};
    }

    private readonly assignRoles = (role : IEmployeeRole, permission : IPermission, value : boolean) => {
        if (role.flag) {
            permission[role.flag] = value;
        }

        if (role.children) {
            role.children.forEach(x => this.assignRoles(x, permission, value));
        }
    };

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const permissions = { ...this.props.permissions };

        this.assignRoles(this.props.role, permissions, checked);

        if (this.props.role.parentFlag && !!checked) {
            permissions[this.props.role.parentFlag] = checked;
        }

        this.props.onPermissionChange(permissions);
    };

    private readonly hasSomeRights = (role : IEmployeeRole) : boolean => {
        const { permissions } = this.props;

        if (!role.flag) return false;

        if (!role.children) {
            return !!permissions[role.flag];
        }

        return !permissions[role.flag] && role.children.some(n => this.hasSomeRights(n));
    };

    public readonly render = () => {
        const { parentNode, role, permissions } = this.props;
        return (
            <TreeItem
                nodeId={`${parentNode}_${role.label}`}
                label={
                    <CheckTreeItem labelText={role.label} indeterminate={this.hasSomeRights(role)} checked={!!role.flag && !!permissions[role.flag]} onChange={this.onChange} />
                }>
                {
                    !!role.children &&
                    role.children.map(child => (
                        <RoleTreeItem
                            key={`${parentNode}_${child.label}`}
                            parentNode={`${parentNode}_${role.label}`}
                            permissions={permissions}
                            role={child}
                            onPermissionChange={this.props.onPermissionChange}
                        />
                    ))
                }
            </TreeItem>
        );
    };
}

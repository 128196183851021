import React from 'react';
import { IMottechIO } from '../../../@types/mottech';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import MottechIOSelector from '../selector/mottech/IOSelector';

interface IMottechIOInputProps {
    value : IMottechIO;
    input ?: boolean;

    onChange : (value : IMottechIO) => void;
    onDelete : (itemNumber : number) => void;
}

interface IMottechIOInputState {}

export default class MottechIOInput extends React.Component<IMottechIOInputProps, IMottechIOInputState> {
    constructor(props : IMottechIOInputProps) {
        super(props);
        this.state = {};
    }

    private readonly onNameChange = (name : string) => {
        const value = Object.assign({}, this.props.value);

        value.Name = name;

        this.props.onChange(value);
    };

    private readonly onAdditionalChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = Object.assign({}, this.props.value);

        value.Additional = event.currentTarget.value;

        this.props.onChange(value);
    };

    private onDelete = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.onDelete(parseInt(event.currentTarget.value));
    };

    public readonly render = () => {
        const { value, input } = this.props;

        return (
            <div className={'fdr flx1 ais'}>
                <div className={'flx1 aic p5 mb10 pr20'}>
                    <Typography variant='subtitle1' className={'flx1 pl10 pr10'}>
                        {value.InputNumber}
                    </Typography>
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <MottechIOSelector value={value.Name} input={input} onChange={this.onNameChange} />
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete='off'
                            id={`${value.Additional}_add`}
                            label='Additional'
                            value={!value.Additional ? '' : value.Additional}
                            onChange={this.onAdditionalChange}
                            margin='normal'
                            className={'TextField'}
                            required={value.Name === 'Other'}
                            error={value.Name === 'Other' && !value.Additional}
                        />
                        {
                            value.Name === 'Other' && !value.Additional &&
                        <FormHelperText error>Required</FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className={'flx1 aic jcc p5 mb10 pr20'}>
                    <Tooltip title='Delete'>
                        <div>
                            <IconButton value={value.InputNumber} onClick={this.onDelete}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

import React from 'react';
import BoreholeCalibrationHelper, { IBoreholeCalibration } from '../../../../@types/model/borehole/boreholeCalibration';
import BoreholeCalibrationEntryLogisticsCardView from './CalibrationEntryLogistcsCardView';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { createSelector } from 'reselect';

interface IBoreholeCalibrationEntryLogisticsViewProps {
    borehole : IBorehole;
    calibration : IBoreholeCalibration;
}

interface IBoreholeCalibrationEntryLogisticsViewState {
    
}

export default class BoreholeCalibrationEntryLogisticsView extends React.Component<IBoreholeCalibrationEntryLogisticsViewProps, IBoreholeCalibrationEntryLogisticsViewState> {
    constructor(props : IBoreholeCalibrationEntryLogisticsViewProps) {
        super(props);
        this.state = {};
    }

    public readonly getData = (props : IBoreholeCalibrationEntryLogisticsViewProps) => props.calibration;
    public readonly getMaximumAchieved = createSelector(
        [this.getData],
        BoreholeCalibrationHelper.getMaximumAchieved,
    );

    public readonly render = () => {
        const { calibration, borehole } = this.props;
        const {
            recoveryPercentage,
        } = this.getMaximumAchieved(this.props);
        return (
            <div className='fdc p5'>
                <div className='fdr aic jcc'>
                    <BoreholeCalibrationEntryLogisticsCardView
                        title={'DIVISION'}
                        value={borehole.Division ?? '-'}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        title={'BOREHOLE NAME'}
                        value={borehole.Name}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        title={'WB NUMBER'}
                        value={borehole.Code}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        title={'DATE'}
                        value={AppFunctionsService.formatDateTimeToDateOnly(calibration.Date?.toMillis() ?? calibration.CreatedOn.toMillis())}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        title={'LATITUDE'}
                        value={calibration.Geo?.latitude.toFixed(9) ?? borehole.Geom?.latitude.toFixed(9) ?? '-'}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        title={'LONGITUDE'}
                        value={calibration.Geo?.longitude.toFixed(9) ?? borehole.Geom?.longitude.toFixed(9) ?? '-'}
                    />
                </div>
                <div className='fdr aic jcc mt8'>
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={50}
                        title={'DATA RECORDER PERSONNEL NUMBER'}
                        value={calibration.EmployeeNumber ?? calibration.CreatedByEmployee}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={50}
                        title={'DATA RECORDER PERSONNEL NAME'}
                        value={calibration.EmployeeName ?? calibration.CreatedByName}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={50}
                        title={<span>HISTORICAL INSTALLED/TEST YIELD IN DATABASE (m<sup>3</sup>/h)</span>}
                        value={calibration.InstalledYield.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={50}
                        title={'CASTING DIAMETER (m)'}
                        value={calibration.CasingDiameter.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={50}
                        title={'CASTING BLOCK HEIGHT ABOVE CEMENT BLOCK (m)'}
                        value={calibration.CasingHeight.toFixed(2)}
                    />
                </div>
                <div className='fdr aic jcc mt8'>
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={65}
                        title={'BOREHOLE DEPTH (m)'}
                        value={calibration.HoleDepth.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={65}
                        title={'STATIC GROUNDWATER LEVEL PRIOR TO PIEZOMETER TUBE INSTALLATION (m)'}
                        value={calibration.BeforePiezoLevel.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={65}
                        title={'PIEZOMETER TUBE HEIGHT ABOVE CASTING RIM (m)'}
                        value={calibration.PiezoHeight?.toFixed(2) ?? '-'}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={65}
                        title={'STATIC GROUNDWATER LEVEL AFTER TO PIEZOMETER TUBE INSTALLATION (m)'}
                        value={calibration.AfterPiezoLevel.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        titleHeight={65}
                        title={'TESTING PUMP INSTALLATION DEPTH (m)'}
                        value={calibration.PumpDepth.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        alt
                        titleHeight={65}
                        title={'AVAILIBLE DRAWDOWN'}
                        value={(calibration.HoleDepth - calibration.AfterPiezoLevel).toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeCalibrationEntryLogisticsCardView
                        alt
                        titleHeight={65}
                        title={'RECOVERY ACHIEVED'}
                        value={`${recoveryPercentage.toFixed(2)} %`}
                    />
                </div>
            </div>
        );
    };
}

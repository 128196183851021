import firebaseApp from '../services/firebaseService';
import { IBase } from './model';
import { BasicBaseHelper } from './model/base';

export interface IWeatherStationAudit extends IBase {
    Id : string;
    GUID : string;
    WeatherStation : firebase.firestore.DocumentReference;
    Geom : firebase.firestore.GeoPoint;
    Elevation : number;
    StationImageURL : string;
    WSType : string;
    ObstructionDistance : number;
    Surroundings : Array<string>;
    Fenced : boolean;
    AnemometerInstallationIssues : Array<string>;
    AnemometerDirectionIssues : Array<string>;
    AnemometerSpeedSensorIssues : Array<string>;
    RainGuageFunnelIssues : Array<string>;
    RainGuageBucketIssues : Array<string>;
    LightMeterIssues : Array<string>;
    SolarDiskIssues : Array<string>;
    TempHumMeterIssues : Array<string>;
    ControlBoxInstallationIssues : Array<string>;
    ControlBoxSunPanelIssues : Array<string>;
    ControlBoxCoverIssues : Array<string>;
    ControlBoxAntennaIssues : Array<string>;
    ControlBoxCableIssues : Array<string>;
    ControlBoxBatteryIssues : Array<string>;
    IsSent : boolean;
}

export default class WeatherStationAuditHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'weather_station_audit';

    public static delete (id : string) {
        return firebaseApp
            .firestore()
            .collection(WeatherStationAuditHelper.COLLECTION_NAME)
            .doc(id)
            .delete();
    }
}
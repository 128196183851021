import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IFlowmeter } from '../../../@types/model/flowmeter/flowmeter';
import { IRootState } from '../../../@types/redux';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditFlowmeterDivisionInfo from '../dialog/EditDivisionInfo';

interface IFlowmeterDivisionInfoViewProps {
    flowmeter : IFlowmeter;

    isLoading : boolean;

    isFlowmeterAdmin : boolean;
}

interface IFlowmeterDivisionInfoViewState {}

class FlowmeterDivisionInfoViewComponent extends React.PureComponent<IFlowmeterDivisionInfoViewProps, IFlowmeterDivisionInfoViewState> {
    constructor(props : IFlowmeterDivisionInfoViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { isFlowmeterAdmin, flowmeter, isLoading } = this.props;

        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            DIVISION INFORMATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isFlowmeterAdmin &&
                                    <EditFlowmeterDivisionInfo
                                        flowmeter={flowmeter}
                                        isLoading={isLoading}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdc flx1 ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DIVISION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.division ? flowmeter.division : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    MANAGMENT AREA
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.managementArea ? flowmeter.managementArea : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DIVISION NUMBER
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.divisionNo ? flowmeter.divisionNo : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    ACCPAC DESCRIPTION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.accpacDesc ? flowmeter.accpacDesc : 'None'}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isFlowmeterAdmin: !!state.auth.session?.employee.IsFlowmeterAdmin,
        isLoading: state.flowmeter.loading,
    };
};

const FlowmeterDivisionInfoView = connect(
    mapStateToProps,
)(FlowmeterDivisionInfoViewComponent);

export default FlowmeterDivisionInfoView;

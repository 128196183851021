import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const StadiumButton = withStyles(() => ({
    root: {
        color: '#0b4323',
        backgroundColor: '#fd0',
        '&:hover': {
            backgroundColor: '#dbbe00',
        },
        font: '500 14px Roboto',
        height: 34,
        minWidth: 99,
        borderRadius: 17,
    },
}))(Button);

export default StadiumButton;

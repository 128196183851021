import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IDamInspection } from '../../../../@types/model/dam/damInspection';
import DamInspectionLocationView from './LocationView';
import DamInspectionPhotoView from './PhotoView';

interface IDamInspectionDownstreamSlopeViewProps {
    inspection : IDamInspection;

    onImageClick ?: (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IDamInspectionDownstreamSlopeViewState {}

export default class DamInspectionDownstreamSlopeView extends React.PureComponent<IDamInspectionDownstreamSlopeViewProps, IDamInspectionDownstreamSlopeViewState> {
    constructor(props : IDamInspectionDownstreamSlopeViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { inspection, onImageClick } = this.props;
        
        return (
            <div className='fdr aifs jcc pt28 pb28'>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            BULGING
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamSlopeBulging &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.downstreamSlopeBulging &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.downstreamSlopeBulging}
                                </Typography>
                                {
                                    inspection.downstreamSlopeBulgingPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'downstreamSlopeBulgingPhotoUrl'}
                                            photoThumbUrl={inspection.downstreamSlopeBulgingPhotoThumbUrl}
                                            photoFileName={inspection.downstreamSlopeBulgingPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamSlopeBulgingLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamSlopeBulgingLocation'
                                            lat={inspection.downstreamSlopeBulgingLocation[0]}
                                            lng={inspection.downstreamSlopeBulgingLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            SETTLEMENT
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamSlopeSettlement &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.downstreamSlopeSettlement &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.downstreamSlopeSettlement}
                                </Typography>
                                {
                                    inspection.downstreamSlopeSettlementPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'downstreamSlopeSettlementPhotoUrl'}
                                            photoThumbUrl={inspection.downstreamSlopeSettlementPhotoThumbUrl}
                                            photoFileName={inspection.downstreamSlopeSettlementPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamSlopeSettlementLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamSlopeSettlementLocation'
                                            lat={inspection.downstreamSlopeSettlementLocation[0]}
                                            lng={inspection.downstreamSlopeSettlementLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            DISTURBANCE
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamSlopeDisturbance &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.downstreamSlopeDisturbance &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.downstreamSlopeDisturbance}
                                </Typography>
                                {
                                    inspection.downstreamSlopeDisturbancePhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'downstreamSlopeDisturbancePhotoUrl'}
                                            photoThumbUrl={inspection.downstreamSlopeDisturbancePhotoThumbUrl}
                                            photoFileName={inspection.downstreamSlopeDisturbancePhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamSlopeDisturbanceLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamSlopeDisturbanceLocation'
                                            lat={inspection.downstreamSlopeDisturbanceLocation[0]}
                                            lng={inspection.downstreamSlopeDisturbanceLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            EROSION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamSlopeErosion &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.downstreamSlopeErosion &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.downstreamSlopeErosion}
                                </Typography>
                                {
                                    inspection.downstreamSlopeErosionPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'downstreamSlopeErosionPhotoUrl'}
                                            photoThumbUrl={inspection.downstreamSlopeErosionPhotoThumbUrl}
                                            photoFileName={inspection.downstreamSlopeErosionPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamSlopeErosionLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamSlopeErosionLocation'
                                            lat={inspection.downstreamSlopeErosionLocation[0]}
                                            lng={inspection.downstreamSlopeErosionLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            SEEPAGE
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamWallSeepage &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            inspection.downstreamWallSeepage != null &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {
                                        `${inspection.downstreamWallSeepage}, ${inspection.downstreamWallSeepageType}, ${inspection.downstreamWallSeepageColour}`
                                    }
                                </Typography>
                                {
                                    inspection.downstreamWallSeepagePhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'ddownstreamWallSeepagePhotoUrl'}
                                            photoThumbUrl={inspection.downstreamWallSeepagePhotoThumbUrl}
                                            photoFileName={inspection.downstreamWallSeepagePhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamWallSeepageLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamWallSeepageLocation'
                                            lat={inspection.downstreamWallSeepageLocation[0]}
                                            lng={inspection.downstreamWallSeepageLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            UNWANTED VEGETATION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamSlopeUnwantedVegetation &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.downstreamSlopeUnwantedVegetation &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.downstreamSlopeUnwantedVegetation}
                                </Typography>
                                {
                                    inspection.downstreamSlopeUnwantedVegetationPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'downstreamSlopeUnwantedVegetationPhotoUrl'}
                                            photoThumbUrl={inspection.downstreamSlopeUnwantedVegetationPhotoThumbUrl}
                                            photoFileName={inspection.downstreamSlopeUnwantedVegetationPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamSlopeUnwantedVegetationLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamSlopeUnwantedVegetationLocation'
                                            lat={inspection.downstreamSlopeUnwantedVegetationLocation[0]}
                                            lng={inspection.downstreamSlopeUnwantedVegetationLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
            </div>
        );
    };
}

import { push, replace } from 'connected-react-router';
import { dispatch } from '../../store';

export default class NavFunctions {
    public static navHome() {
        dispatch(push('/'));
    }
    
    public static navPath(pathname : string, search ?: string) {
        dispatch(push({
            pathname,
            search,
        }));
    }
    
    public static navReplacePath(pathname : string, search ?: string) {
        dispatch(replace({
            pathname,
            search,
        }));
    }
    
    public static navBoreholeMap(code : string) {
        dispatch(push('/borehole/map?Code=' + code));
    }
    
    public static navTransformerMap(code : string) {
        dispatch(push('/transformer/map?Code=' + code));
    }
    
    public static navWeatherMap(code : string) {
        dispatch(push('/weather/map?Code=' + code));
    }
    
    public static navMottechMap(code : string) {
        dispatch(push('/mottech/map?Code=' + code));
    }
    
    public static navRiverMap(code : string) {
        dispatch(push('/river/map?Code=' + code));
    }
    
    public static navDamMap(code : string) {
        dispatch(push('/dam/map?Code=' + code));
    }
    
    public static navPumpingStationMap(code : string) {
        dispatch(push('/pumpingStation/map?Code=' + code));
    }
    
    public static navFlowmeterMap(code : string) {
        dispatch(push('/flowmeter/map?Code=' + code));
    }
    
    public static navChemicalFillingPointMap(code : string) {
        dispatch(push('/chemical_filling_point/map?Code=' + code));
    }
}


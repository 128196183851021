import AppFunctionsService from '../../services/appFunctionsService';
import { IGeoServerGeoJsonDepartment } from './masterData/geoserver/block';

export class Area {
    public id : string;
    public name : string;
    public division : string;
    public subDivisionName : string;

    constructor(department : IGeoServerGeoJsonDepartment) {
        this.id = department.dptGuid;
        this.name = AppFunctionsService.toTitleCase(department.dptName);
        this.division = AppFunctionsService.toTitleCase(department.dptDivisionName);
        this.subDivisionName = AppFunctionsService.toTitleCase(department.dptSubDivisionName);
    }
}

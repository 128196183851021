import React from 'react';

interface IOutsideClickerProps {
    children : React.ReactNode;
    className ?: string;
    onClickedOutside ?: () => void;
}

export default class OutsideClicker extends React.Component<React.PropsWithChildren<IOutsideClickerProps>> {
    private readonly wrapperRef : React.RefObject<HTMLDivElement>;
    constructor(props : IOutsideClickerProps) {
        super(props);
        this.wrapperRef = React.createRef<HTMLDivElement>();
    }

    public readonly onClick = (e : MouseEvent) => {
        if (!this.props.onClickedOutside) return;
        if (e.target instanceof HTMLLIElement) {
            return;
        } else if (this.wrapperRef.current && !this.wrapperRef.current.contains(e.target as Node)) {
            this.props.onClickedOutside();
        }
    };

    public componentDidMount() {
        document.addEventListener('mousedown', e => this.onClick(e));
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', e => this.onClick(e));
    }

    public render() {
        return <div ref={this.wrapperRef} className={this.props.className ?? ''}>{this.props.children}</div>;
    }
}

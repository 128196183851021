import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IMottech } from '../../../../@types/mottech';

interface IMottechCodeSelectorProps {
    mottechs : Array<IMottech>;
    isLoading : boolean;

    value : string;

    onChange : (value : string) => void;

    required ?: boolean;
}

interface IMottechCodeSelectorState {}

class MottechCodeSelector extends React.Component<IMottechCodeSelectorProps, IMottechCodeSelectorState> {
    constructor(props : IMottechCodeSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly getMottechs = (props : IMottechCodeSelectorProps) => props.mottechs;

    public readonly getMottechCodes = createSelector(
        [this.getMottechs],
        (mottechs) => {
            return mottechs.map((e : IMottech) => {
                return e.Code;
            });
        },
    );

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: string | undefined | null) => {
        this.props.onChange(value ?? '');
    };

    public readonly render = () => {
        const { value, required, isLoading} = this.props;

        const codes = this.getMottechCodes(this.props);

        return (
            <FormControl fullWidth={true} required={false} disabled={isLoading}>
                <Autocomplete
                    id={'code'}
                    options={codes}
                    value={value}
                    getOptionSelected={(option, val) => option === val}
                    getOptionLabel={option => option}
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={true} label={'Mottech Code'} />}
                />
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        mottechs: state.mottech.mottechs,
        isLoading: state.mottech.loading,
    };
};

export default connect(
    mapStateToProps,
)(MottechCodeSelector);

import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import PumpingStationInspectionAuditHelper from '../../../../@types/model/pumpingStation/pumpingStationInspectionAudit';

interface IPumpingStationInspectionAuditDriveSwitchGearSelectorProps {
    value : string;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : string) => void;
}

interface IPumpingStationInspectionAuditDriveSwitchGearSelectorState { }

class PumpingStationInspectionAuditDriveSwitchGearSelectorComponent extends React.PureComponent<IPumpingStationInspectionAuditDriveSwitchGearSelectorProps, IPumpingStationInspectionAuditDriveSwitchGearSelectorState> {
    constructor(props : IPumpingStationInspectionAuditDriveSwitchGearSelectorProps) {
        super(props);

        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? '');
    };

    private readonly getValue = (state : IPumpingStationInspectionAuditDriveSwitchGearSelectorState, props : IPumpingStationInspectionAuditDriveSwitchGearSelectorProps) => props.value;
    private readonly getRequired = (state : IPumpingStationInspectionAuditDriveSwitchGearSelectorState, props : IPumpingStationInspectionAuditDriveSwitchGearSelectorProps) => props.required;


    private readonly getInspectionAuditDriveSwitchGearsForDropdown = createSelector([ 
        this.getRequired,
    ], (required,) => {
        
        const dropdownData = lodash.map(PumpingStationInspectionAuditHelper.switchGears, (element) => ({ label: `${element}`, value: element.toString() }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([ 
        this.getValue,
    ], (value) => {
        if (value) {
            const result = PumpingStationInspectionAuditHelper.switchGears.find(element => element === value);

            if (result) {
                return { label: result, value: result };
            } else {
                return { label: value, value };
            }
        }

        return null;
    });

    public readonly render = () => {
        const { required, className, disabled } = this.props;

        const dropdownData = this.getInspectionAuditDriveSwitchGearsForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'flowmeterMake'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Switch Gear'} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

const PumpingStationInspectionAuditDriveSwitchGearSelector = PumpingStationInspectionAuditDriveSwitchGearSelectorComponent;

export default PumpingStationInspectionAuditDriveSwitchGearSelector;
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { ITransformer } from '../../../../@types/model/transformer/transformer';
import StandardFab from '../../../customComponents/button/StandardFab';
import AddIcon from '@material-ui/icons/Add';
import TransformerDropdown from '../../../customComponents/selector/transformer/TransformerSelector';
import BankDropdown from '../../../customComponents/selector/BankSelector';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { BankAccountType } from '../../../../@types/model/bankAccount';
import BankAccountTypeDropdown from '../../../customComponents/selector/BankAccountTypeSelector';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import TransformerFunctions from '../../../../store/transformer/functions';

interface ITransformerAccountEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    transformer ?: ITransformer;
    excludeTransformers ?: Array<ITransformer>;

    isLoading : boolean;
}

interface ITransformerAccountEditDialogState {
    open : boolean;

    epNumber ?: string;
    bank ?: string;
    accountNumber : string;
    accountType ?: BankAccountType;
    branch : string;
    beneficiaryDescription : string;
    recipientReference : string;
    ownReference : string;
}

export default class TransformerAccountEditDialog extends React.PureComponent<ITransformerAccountEditDialogProps, ITransformerAccountEditDialogState> {
    constructor(props : ITransformerAccountEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            accountNumber: '',
            branch: '',
            beneficiaryDescription: '',
            recipientReference: '',
            ownReference: '',
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });

        if (this.props.transformer?.BankAccount) {
            this.setState({
                accountNumber: this.props.transformer.BankAccount.accountNumber,
                branch: this.props.transformer.BankAccount.branch,
                beneficiaryDescription: this.props.transformer.BankAccount.beneficiaryDescription,
                recipientReference: this.props.transformer.BankAccount.recipientReference,
                ownReference: this.props.transformer.BankAccount.ownReference,
                accountType: this.props.transformer.BankAccount.accountType,
                bank: this.props.transformer.BankAccount.bank,
                epNumber: this.props.transformer.EPNumber,
            });
        }
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
            accountNumber: '',
            branch: '',
            beneficiaryDescription: '',
            recipientReference: '',
            ownReference: '',
            accountType: undefined,
            bank: undefined,
            epNumber: undefined,
        });
    };

    private readonly save = async (epNumber : string, bank : string, accountNumber : string, accountType : BankAccountType, branch : string,
        beneficiaryDescription : string, recipientReference : string, ownReference : string) => {
        const result = await TransformerFunctions.updateBankDetails(epNumber, bank, accountNumber, accountType, branch,
            beneficiaryDescription, recipientReference, ownReference);

        if (result) this.onClose();
    };

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            epNumber: value?.EPNumber,
        });
    };

    private readonly onBankChange = (value ?: string) => {
        this.setState({
            bank: value,
        });
    };

    private readonly onAccountNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            accountNumber: event.currentTarget.value,
        });
    };

    private readonly onAccountTypeChange = (value ?: BankAccountType) => {
        this.setState({
            accountType: value,
        });
    };

    private readonly onBranchCodeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            branch: event.currentTarget.value,
        });
    };

    private readonly onBeneficiaryDescriptionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            beneficiaryDescription: event.currentTarget.value,
        });
    };

    private readonly onRecipientReferenceChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            recipientReference: event.currentTarget.value,
        });
    };

    private readonly onOwnReferenceChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            ownReference: event.currentTarget.value,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { epNumber, bank, accountNumber, accountType,
            branch, beneficiaryDescription,
            ownReference, recipientReference } = this.state;

        if (!epNumber || !bank || !accountNumber || !accountType || !branch) return;
        if (!beneficiaryDescription || !ownReference || !recipientReference) return;

        this.save(epNumber, bank, accountNumber, accountType, branch,
            beneficiaryDescription, recipientReference, ownReference);
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            transformer,
            isLoading,
            excludeTransformers,
        } = this.props;

        const { 
            open,
            epNumber,
            bank,
            accountNumber,
            accountType,
            branch,
            beneficiaryDescription,
            ownReference,
            recipientReference,
        } = this.state;
        
        return (
            <div>
                <Tooltip title={!transformer ? 'Add' : 'Edit'}>
                    <div>
                        {
                            !transformer &&
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onClick}>
                                <AddIcon />
                            </StandardFab>

                        }
                        {
                            transformer &&
                            <IconButton color='primary' onClick={this.onClick}
                                aria-label='Edit' disabled={isLoading }>
                                <Icon>edit</Icon>
                            </IconButton>

                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Edit-Transformer-Bank-dialog-title'
                    aria-describedby='Edit-Transformer-Bank-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!transformer ? 'Create' : 'Edit'} Account
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 ais hfill'>
                            <div className='fdc flx1 ais jcc'>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 pr5'>
                                        <TransformerDropdown
                                            value={epNumber}
                                            label='Waypoint No'
                                            fullWidth
                                            exclusionList={excludeTransformers}
                                            onChange={this.onEPNumberChange}
                                            required
                                        />
                                    </div>
                                    <div className='fdc flx1 pl5'>
                                        <BankDropdown
                                            value={bank}
                                            fullWidth
                                            onChange={this.onBankChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 aifs pr5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='accountNumber'
                                                label='Account Number'
                                                value={accountNumber}
                                                onChange={this.onAccountNumberChange}
                                                margin='normal'
                                                required
                                                error={!accountNumber}
                                                className={'TextField'}
                                            />
                                            {
                                                !accountNumber &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aifs pl5 mt16'>
                                        <BankAccountTypeDropdown
                                            value={accountType}
                                            fullWidth
                                            onChange={this.onAccountTypeChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 aifs pr5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='branchCode'
                                                label='Branch Code'
                                                value={branch}
                                                onChange={this.onBranchCodeChange}
                                                margin='normal'
                                                required
                                                error={!branch}
                                                className={'TextField'}
                                            />
                                            {
                                                !branch &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aifs pl5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='beneficiaryDescription'
                                                label='Beneficiary Description'
                                                value={beneficiaryDescription}
                                                onChange={this.onBeneficiaryDescriptionChange}
                                                margin='normal'
                                                required
                                                error={!beneficiaryDescription}
                                                className={'TextField'}
                                            />
                                            {
                                                !beneficiaryDescription &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 aifs pr5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='recipientReference'
                                                label='Recipient Reference'
                                                value={recipientReference}
                                                onChange={this.onRecipientReferenceChange}
                                                margin='normal'
                                                required
                                                error={!recipientReference}
                                                className={'TextField'}
                                            />
                                            {
                                                !recipientReference &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aifs pl5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='ownReference'
                                                label='Own Reference'
                                                value={ownReference}
                                                onChange={this.onOwnReferenceChange}
                                                margin='normal'
                                                required
                                                error={!ownReference}
                                                className={'TextField'}
                                            />
                                            {
                                                !ownReference &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <OutlinedStadiumButton disabled={isLoading} className='mr16' variant='text' type='reset' onClick={this.onClose}>
                                CANCEL
                            </OutlinedStadiumButton>
                            <StadiumButton disabled={isLoading} type='submit' >
                                SAVE
                            </StadiumButton>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    };
}

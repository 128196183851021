import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import React, { ChangeEvent } from 'react';
import Fab from '@material-ui/core/Fab';
import RiverCodeSelector from '../selector/river/CodeSelector';
import OutsideClicker from '../outsideClicker';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DivisionSelector from '../selector/DivisionSelector';

interface IRiverPopperProps {
    id ?: string;
    open : boolean;
    anchorEl : HTMLButtonElement | null;
    onChange ?: (name : string | null, code : string | null, division : string | null) => void;
    onCancelClick ?: () => void;
    name : string | null;
    code : string | null;
    division : string | null;
}

interface IRiverFilterPopperState {
    name : string | null;
    code : string | null;
    division : string | null;
}

export default class RiverFilterPopper extends React.PureComponent<IRiverPopperProps, IRiverFilterPopperState> {
    constructor(props : IRiverPopperProps) {
        super(props);
        this.state = {
            name: '',
            code: '',
            division: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IRiverPopperProps) => {
        if (!prevProps.open && this.props.open) {
            const { name, code, division } = this.props;
            this.setState({
                name: name,
                code: code ?? '',
                division: division ?? '',
            });
        }
    };

    private readonly onCodeChange = (value : string) => {
        this.setState({
            code: value,
        });
    };

    private readonly onDivisionChange = (value : string) => {
        this.setState({
            division: value,
        });
    };

    private readonly onNameChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            name: event.target.value,
        });
    };

    private readonly onSubmit = (event ?: React.FormEvent<HTMLFormElement>) => {
        
        if (event) {
            event.preventDefault();
        }

        if (!this.props.onChange) return;
        const { name, code, division } = this.state;

        this.props.onChange(name, code, division);
    };

    public readonly render = () => {
        const { open, anchorEl, id, onCancelClick } = this.props;
        const { name, code, division } = this.state;

        return (
            <Popper
                id={`${id}_transformer_filter_menu`} open={open} anchorEl={anchorEl} transition
                style={{
                    zIndex: 5,
                }}
                placement='bottom'>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div style={{
                            width: 362,
                            background: '#FFF',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: -5,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}>
                            {
                                open &&
                                <OutsideClicker onClickedOutside={onCancelClick}>
                                    <form onSubmit={this.onSubmit} className='fdc ais'>
                                        <div className='fdr'>
                                            <div className='flx1 pr5 jcc'>
                                                <RiverCodeSelector value={code ?? ''} onChange={this.onCodeChange} />
                                            </div>
                                            <div className='fdr flx1 aifs pl5 jcc'>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='name'
                                                        label='Name'
                                                        value={name}
                                                        onChange={this.onNameChange}
                                                        margin={'none'}
                                                        className={'pb7'}
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr'>
                                            <div className={'fdr flx1 aifs'}>
                                                <DivisionSelector division={division ?? ''} onDivisionChanged={this.onDivisionChange}/>
                                            </div>    
                                        </div>
                                        <div className='fdrr aic pb16'>
                                            <span className='flx1' />
                                            <Fab variant='extended' color='secondary' className='fab cancel' size='small' onClick={onCancelClick} type='button'>
                                                CANCEL
                                            </Fab>
                                            <Fab variant='extended' color='secondary' className='fab save' size='small' type='submit'>
                                                APPLY
                                            </Fab>
                                        </div>
                                    </form>
                                </OutsideClicker>
                            }
                        </div>
                    </Fade>
                )}
            </Popper>
        );
    };
}

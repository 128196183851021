import React from 'react';

interface ITransformerMeterReadingProblemProps {
    text : string;

    enabled ?: boolean;
    checked ?: boolean;

    onClick ?: (value : string) => void;
}

interface ITransformerMeterReadingProblemState {}

export default class TransformerMeterReadingProblem extends React.PureComponent<ITransformerMeterReadingProblemProps, ITransformerMeterReadingProblemState> {
    constructor(props : ITransformerMeterReadingProblemProps) {
        super(props);
        this.state = {};
    }

    private readonly onClick = () => {
        if (this.props.onClick) this.props.onClick(this.props.text);
    };

    public readonly render = () => {
        const { enabled, checked } = this.props;
        return (
            <div className='aic jcc' onClick={!enabled ? undefined : this.onClick} style={{
                marginRight: 15,
                marginBottom: 15,
                color: checked || !enabled ? '#FFFF' : '#188944',
                cursor: enabled ? 'pointer' : '',
                width: 174,
                height: 31,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: '#188944',
                background: enabled ? `${checked ? '#188944' : ''} 0% 0% no-repeat padding-box` : '#188944 0% 0% no-repeat padding-box',
                borderRadius: 16,
                font: 'normal 12px/15px Roboto',
            }}>
                {this.props.text}
            </div>
        );
    };
}

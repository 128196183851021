import { IRiverState, RiverAction } from '../../@types/redux';
import RiverActions from './actions';
import { getType } from 'typesafe-actions';

const initialState : IRiverState = {
    rivers: [],
    loading: false,
    monitorTests: [],
    loadingMonitorTests: false,
};

export default function generalReducer(state : IRiverState = initialState, action : RiverAction) : IRiverState {
    switch (action.type) {
        case getType(RiverActions.riverSetList):
            return { ...state, rivers: action.payload };
        case getType(RiverActions.riverSetSelected):
            return { ...state, river: action.payload };
        case getType(RiverActions.riverSetLoading):
            return { ...state, loading: action.payload };
        case getType(RiverActions.riverMonitorTestSetList):
            return { ...state, monitorTests: action.payload };
        case getType(RiverActions.riverMonitorTestSetLoading):
            return { ...state, loadingMonitorTests: action.payload };
        default:
            return state;
    }
}

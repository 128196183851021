import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Icon, IconButton, Typography, TextField, Toolbar, Tooltip } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import RiverFlowTypeSelector from '../../customComponents/selector/river/RiverFlowTypeSelector';
import riverFunctions from '../../../store/river/functions';
import RiverMonitorHelper, { IRiverMonitorTest } from '../../../@types/model/river/riverMonitorTest';
import FileDropZone from '../../customComponents/FileDropZone';

interface IEditRiverReadingDialogProps {
    riverMonitorTest : IRiverMonitorTest;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    fullWidth ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IEditRiverReadingDialogState {
    open : boolean;
    flow : number;
    flowType : string;
    image ?: File;
}

export default class EditRiverReadingDialog extends React.Component<IEditRiverReadingDialogProps, IEditRiverReadingDialogState> {
    constructor(props : IEditRiverReadingDialogProps) {
        super(props);
        this.state = {
            open: false,
            flow: this.props.riverMonitorTest.Flow ?? 0,
            flowType: this.props.riverMonitorTest.FlowType,
        };
    }

    private readonly onFlowTypeChange = (value : string) => {
        this.setState({ flowType: value});
    };

    private readonly onFlowChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ flow: parseFloat(event.target.value) });
    };

    private readonly onEditClick = () => {
        this.setState({ open: true });
    };

    private readonly onSubmit = async (event : React.FormEvent) => {
        event.preventDefault();
        const { flow, flowType, image } = this.state;

        if ((flow < 0) || !flowType) {
            GeneralFunctions.generalShowErrorSnackbar('You have left out some information.');
            return;
        }

        const riverMonitorTest = { ...this.props.riverMonitorTest };

        riverMonitorTest.Flow = flow;
        riverMonitorTest.FlowType = flowType;

        if (image) {
            riverMonitorTest.ImageName = null;
            try {
                await riverFunctions.uploadSelectedRiverMonitorImage(riverMonitorTest, image, () => {
                    // TODO: Show Progress
                });
                GeneralFunctions.generalShowSuccessSnackbar('Image uploaded successfuly');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Image upload failed');

                return;
            }
        }

        try {
            await RiverMonitorHelper.update(riverMonitorTest);
            this.onClose();
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar(`An error while saving monitor test. ${ex}`);
            throw ex;
        }
        
    };

    private readonly onClose = () => {
        this.setState({ open: false });
    };

    private readonly onFileSelected = (image : File) => {
        this.setState({
            image,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullScreen, fullWidth, isLoading, riverMonitorTest } = this.props;
        const { flow, flowType, open } = this.state;

        return (
            <>
                <Tooltip title={'edit'}>
                    <IconButton
                        color={'inherit'}
                        size={'small'}
                        onClick={this.onEditClick}
                        aria-label={'Edit'}
                        disabled={isLoading}
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledBy={'river-info-title'}
                    arai-describedBy={'river-info-description'}
                >
                    <AppBar position={'static'}>
                        <Toolbar className={'fdr aic jcsb'}>
                            <Typography variant={'h6'} >
                                {`Edit Monitor Test - ${AppFunctionsService.formatDateTimeToDateOnly(riverMonitorTest.CreatedOn)}`}
                            </Typography>
                            <Tooltip title={'Close'}>
                                <IconButton color={'inherit'} disabled={isLoading} onClick={this.onClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                        
                    </AppBar>
                    <form
                        autoComplete='off'
                        onSubmit={this.onSubmit}
                        className={'fdc flx1 hfill oya'}
                    >
                        <DialogContent className={'fdc hfill'}>
                            <div className='fdr'>
                                <FormControl fullWidth>
                                    <TextField 
                                        type={'number'}
                                        autoComplete={'off'}
                                        id={'flow'}
                                        label={'Flow'}
                                        value={flow}
                                        margin={'normal'}
                                        className={'TextField'}
                                        error={(flow < 0)}
                                        onChange={this.onFlowChange}
                                    />
                                    {
                                        (flow < 0) && 
                                            <FormHelperText error>{'Can\'t be less than zero'}</FormHelperText>
                                    }
                                </FormControl>
                                <RiverFlowTypeSelector 
                                    disabled={isLoading}
                                    className={'ml20'}
                                    value={flowType}
                                    required
                                    onChange={this.onFlowTypeChange}
                                />
                            </div>
                            <div>
                                <FileDropZone
                                    fileName={!riverMonitorTest.ImageName ? '' : riverMonitorTest.ImageName}
                                    path={!riverMonitorTest.ImageName ? '' : `river/${riverMonitorTest.River.id}`}
                                    onFileSelected={this.onFileSelected}
                                    url={riverMonitorTest.ImageUrl}
                                    thumbnailUrl={riverMonitorTest.ImageThumbnailUrl}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button type={'submit'} disabled={isLoading} variant={'contained'} color={'primary'}>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant={'outlined'} onClick={this.onClose} color={'primary'} autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </>
        );
    }; 
}
import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IDamInspection } from '../../../../@types/model/dam/damInspection';
import DamInspectionLocationView from './LocationView';
import DamInspectionPhotoView from './PhotoView';

interface IDamInspectionSpillwayViewProps {
    inspection : IDamInspection;

    onImageClick ?: (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IDamInspectionSpillwayViewState {}

export default class DamInspectionSpillwayView extends React.PureComponent<IDamInspectionSpillwayViewProps, IDamInspectionSpillwayViewState> {
    constructor(props : IDamInspectionSpillwayViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { inspection, onImageClick } = this.props;
        
        return (
            <div className='fdr aifs jcc pt28 pb28'>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            DISTURBANCE
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.spillwayDisturbance &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.spillwayDisturbance &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.spillwayDisturbance}
                                </Typography>
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            EROSION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.spillwayErosion &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.spillwayErosion &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.spillwayErosion}
                                </Typography>
                                {
                                    inspection.spillwayErosionPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'spillwayErosionPhotoUrl'}
                                            photoThumbUrl={inspection.spillwayErosionPhotoThumbUrl}
                                            photoFileName={inspection.spillwayErosionPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.spillwayErosionLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='spillwayErosionLocation'
                                            lat={inspection.spillwayErosionLocation[0]}
                                            lng={inspection.spillwayErosionLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            UNWANTED VEGETATION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.spillwayUnwantedVegetation &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.spillwayUnwantedVegetation &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.spillwayUnwantedVegetation}
                                </Typography>
                                {
                                    inspection.spillwayUnwantedVegetationPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'spillwayUnwantedVegetationPhotoUrl'}
                                            photoThumbUrl={inspection.spillwayUnwantedVegetationPhotoThumbUrl}
                                            photoFileName={inspection.spillwayUnwantedVegetationPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.spillwayUnwantedVegetationLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='spillwayUnwantedVegetationLocation'
                                            lat={inspection.spillwayUnwantedVegetationLocation[0]}
                                            lng={inspection.spillwayUnwantedVegetationLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
            </div>
        );
    };
}

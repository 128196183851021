import React from 'react';

interface IFileEmbedViewProps {
    file : File;
}

interface IFileEmbedViewState {
    src : string;
}

export default class FileEmbedView extends React.PureComponent<IFileEmbedViewProps, IFileEmbedViewState> {
    constructor(props : IFileEmbedViewProps) {
        super(props);
        this.state = {
            src: '',
        };
    }

    public componentDidMount() : void {
        this.load();
    }

    public componentDidUpdate(prevProps : Readonly<IFileEmbedViewProps>) : void {
        if (this.props.file.size !== prevProps.file.size) {
            this.load();
        }
    }

    private readonly load = () => {
        this.setState({
            src: URL.createObjectURL(this.props.file),
        });
    };

    public readonly render = () => {
        const { src } = this.state;
        return (<embed height={'100%'} src={`${src}#toolbar=0&navpanes=0&scrollbar=0`} />);
    };
}

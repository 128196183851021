import lodash from 'lodash';
import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { createSelector } from 'reselect';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { BoreholeConstantRateTimesType, IBoreholeConstantRateRecoveryTimeEntry, IBoreholeConstantRateTimeEntry } from '../../../../@types/model/borehole/boreholeConstantRateTest';

interface IBoreholeConstantRateEntryGraphViewProps {
    entries : Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntry | IBoreholeConstantRateRecoveryTimeEntry>;
}

interface IBoreholeConstantRateEntryGraphViewState {
}

export default class BoreholeConstantRateEntryGraphView extends React.Component<IBoreholeConstantRateEntryGraphViewProps, IBoreholeConstantRateEntryGraphViewState> {
    constructor(props : IBoreholeConstantRateEntryGraphViewProps) {
        super(props);
        this.state = {
        };
    }

    public readonly getData = (props : IBoreholeConstantRateEntryGraphViewProps) => props.entries;
    public readonly getAbstractionGraphValues = createSelector(
        [this.getData],
        (entries) => {
            if (!('rate' in entries[1])) return [];
            
            const returnValue : Array<{
                ['Abstraction Rate'] : number;
                time : number;
            }> = [];

            lodash.forEach(entries, (entry, time) => {

                if ('rate' in entry && entry.rate) {
                    returnValue.push({
                        time: Number(time),
                        ['Abstraction Rate']: entry.rate,
                    });
                }
            });
            
            return returnValue;
        },
    );

    public readonly getGroundLevelGraphValues = createSelector(
        [this.getData],
        (entries) => {
            const returnValue : Array<{
                time : number;
                ['Ground Water Level'] : number;
            }> = [];

            lodash.forEach(entries, (entry, time) => {
                if (entry.waterLevel) {
                    returnValue.push({
                        time: Number(time),
                        ['Ground Water Level']: entry.waterLevel,
                    });
                }
            });
            
            return returnValue;
        },
    );

    public readonly render = () => {
        const groundLevelGraphValues = this.getGroundLevelGraphValues(this.props);
        const abstractionGraphValues = this.getAbstractionGraphValues(this.props);

        return (
            <div className='fdc'>
                <Card className={'fdr bcg0 mt15'}>
                    {
                        abstractionGraphValues.length !== 0 && (
                            <div className={'flx1 fdc p10 wfill'}>
                                <ResponsiveContainer aspect={5} width={'99%'}>
                                    <LineChart className={''} data={abstractionGraphValues}>
                                        <XAxis dataKey='time'/>
                                        <YAxis domain={[0, 'dataMax + 5']}/>
                                        <CartesianGrid />
                                        <Tooltip
                                            itemStyle={{
                                                color: 'black',
                                            }}
                                            labelStyle={{ 
                                                color: 'black',
                                            }}
                                            labelFormatter={(label) => `${label} min`}
                                            formatter={(value) => `${value}m³/h`}
                                        />
                                        <Line strokeWidth={3} type='monotone' dataKey='Abstraction Rate' stroke='#2294FC' />
                                    </LineChart>
                                </ResponsiveContainer>
                                <Typography className={'fs14 fwb aic jcc cg3'} color='inherit' variant='subtitle1'>
                                    Time (min)
                                </Typography>
                            </div>
                        )
                    }
                    
                </Card>
                <Card className={'fdr bcDarkBlue mt15'}>
                    {
                        groundLevelGraphValues.length !== 0 &&
                        (
                            <div className={'flx1 fdc jcc p10 wfill'}>
                                <ResponsiveContainer aspect={5} width={'99%'}>
                                    <LineChart className={''} data={groundLevelGraphValues}>
                                        <XAxis tick={{
                                            fill: 'white',
                                        }} dataKey='time'/>
                                        <YAxis tick={{
                                            fill: 'white',
                                        }} domain={[0, 'dataMax + 5']} reversed/>
                                        <CartesianGrid />
                                        <Tooltip
                                            itemStyle={{
                                                color: 'black',
                                            }}
                                            labelStyle={{
                                                color: 'black',
                                            }}
                                            labelFormatter={(label) => `${label} min`}
                                            formatter={(value) => `${value}m`}
                                        />
                                        <Line strokeWidth={3} type='monotone' dataKey='Ground Water Level' stroke='white' />
                                    </LineChart>
                                </ResponsiveContainer>
                                <Typography className={'fs14 fwb pb10 aic jcc cw'} color='inherit' variant='subtitle1'>
                                    Time (min)
                                </Typography>
                            </div>
                        )
                    }
                </Card>
            </div>
        );
    };
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';

interface IDamInspectionPhotoViewProps {
    id : string;
    photoThumbUrl : string;
    photoFileName : string;
    
    onImageClick ?: (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IDamInspectionPhotoViewState {}

export default class DamInspectionPhotoView extends React.PureComponent<IDamInspectionPhotoViewProps, IDamInspectionPhotoViewState> {
    constructor(props : IDamInspectionPhotoViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const {
            id,
            photoThumbUrl,
            photoFileName,
            onImageClick,
        } = this.props;

        return (
            <>
                <Typography className={'fs11 fwr cpd aic jcc'}>
                    PHOTO
                </Typography>
                <div className='fdr mt8'>
                    <Card className='flx1 h150 curp'>
                        <img
                            id={id}
                            width={'100%'}
                            src={photoThumbUrl}
                            alt={photoFileName}
                            onClick={onImageClick}
                        />
                    </Card>
                </div>
            </>
        );
    };
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IDamInspection } from '../../../../@types/model/dam/damInspection';
import DamInspectionPhotoView from './PhotoView';

interface IDamInspectionPublicSafetyViewProps {
    inspection : IDamInspection;

    onImageClick ?: (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IDamInspectionPublicSafetyViewState {}

export default class DamInspectionPublicSafetyView extends React.PureComponent<IDamInspectionPublicSafetyViewProps, IDamInspectionPublicSafetyViewState> {
    constructor(props : IDamInspectionPublicSafetyViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { inspection, onImageClick } = this.props;
        
        return (
            <div className='fdr aifs jcc pt28 pb28'>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            ACCESS TO SPILLWAY
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.publicSafetyAccessToSpillway &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.publicSafetyAccessToSpillway &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    YES
                                </Typography>
                                {
                                    inspection.publicSafetyAccessToSpillwayFenced &&
                                    <div className='fdc mt12'>
                                        <Card elevation={0} className='fdc bcw'>
                                            <Typography className={'fs14 fw500 cp aic jcc mt12'}>
                                                Fenced?
                                            </Typography>
                                            <Typography className={'fs14 fw700 cp aic jcc mt8'}>
                                                YES
                                            </Typography>
                                        </Card>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            ACCESS TO DAM WALL
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.publicSafetyAccessToWall &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.publicSafetyAccessToWall &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    YES
                                </Typography>
                                {
                                    inspection.publicSafetyAccessToWallFenced &&
                                    <div className='fdc mt12'>
                                        <Card elevation={0} className='fdc bcw'>
                                            <Typography className={'fs14 fw500 cp aic jcc mt12'}>
                                                Fenced?
                                            </Typography>
                                            <Typography className={'fs14 fw700 cp aic jcc mt8'}>
                                                YES
                                            </Typography>
                                        </Card>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                {
                    inspection.publicSafetyOther &&
                    <Card className={'bcg0 fdc mrnlc15'}>
                        <div className={'jcc aic wfill bcpl h50'}>
                            <Typography className={'fs14 fw500 cw '}>
                                OTHER
                            </Typography>
                        </div>
                        <div className='fdc p15 ais jcc w200 flx1'>
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                {inspection.publicSafetyOther}
                            </Typography>
                            {
                                inspection.publicSafetyOtherPhotoThumbUrl &&
                                <div className='fdc mt12'>
                                    <DamInspectionPhotoView
                                        id={'outletWorksValvesPhotoUrl'}
                                        photoThumbUrl={inspection.publicSafetyOtherPhotoThumbUrl}
                                        photoFileName={inspection.publicSafetyOtherPhotoFileName ?? ''}
                                        onImageClick={onImageClick}
                                    />
                                </div>
                            }
                        </div>
                    </Card>
                }
            </div>
        );
    };
}

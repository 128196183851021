import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditTransformerMeters from '../dialog/EditMeters';
import TransformerLatestMeterView from './LatestMeterView';

interface ITransformerMetersViewProps {
    transformer : ITransformer;
    isLoading : boolean;
    session : IUserSession;
}

interface ITransformerMetersViewState {}

export default class TransformerMetersView extends React.PureComponent<ITransformerMetersViewProps, ITransformerMetersViewState> {
    constructor(props : ITransformerMetersViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { transformer, isLoading, session } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg0'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                METERS
                            </Typography>
                            <span className='flx1' />
                            {
                                session.employee.IsTransformerAdmin &&
                                <EditTransformerMeters transformer={transformer} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc'>
                        {
                            transformer.TransformerMeterNumbers?.map(n => (
                                <TransformerLatestMeterView key={n.Number} meter={n} />
                            ))
                        }
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    };
}

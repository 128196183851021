import { firestore } from 'firebase/app';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import TransformerHelper, { ITransformer } from './transformer';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { YupSchema } from '../../../services/helper/yupHelper';
import * as Yup from 'yup';
import { Moment } from 'moment';
import { FormikProps } from 'formik';
import { EmployeeHelper } from '../../employee';
import moment from 'moment';
import { DATE_INPUT_FORMAT_DEFAULT } from '../../../appConstants';
import TransformerJournalHelper from './journal';
import { TransformerInvoiceHelper } from './transformerInvoice';
import Decimal from 'decimal.js';

export interface ISPSInvoice extends IBasicBaseClass {
    group : string;
    monthDate : number;
    dueDate : number;

    offPeakGenerationKwh : number;
    standardGenerationKwh : number;
    peakGenerationKwh : number;
    rates : number;

    transformerRef : string;
    transformer : ITransformer;

    totalGenerationKwh : number;
    totalExVat : number;
    vat : number;
    totalVat : number;
    totalIncVat : number;
    credit : number;
    rebillTotal : number;

    reasonForRebill : string | null;

    reverted : boolean;
    relatedId : string | null;
    related : ISPSInvoice | null;
    
    paid : boolean;

    paymentDueDate : string | null;
    // Path to user
    paidBy : string | null;
    paidByName : string | null;
    paidByEmployeeNumber : string | null;
    paidOn : number | null;

    journaled : boolean;

    journal : string | null;
    journalNr : string | null;

    // Path to user
    journaledBy : string | null;
    journaledByName : string | null;
    journaledByEmployeeNumber : string | null;
    journaledOn : number | null;
}


export interface ISPSInvoiceFormValues extends Omit<ISPSInvoice,
keyof IBasicBaseClass
| 'transformer'
| 'offPeakGenerationKwh'
| 'standardGenerationKwh'
| 'peakGenerationKwh'
| 'rates'
| 'totalExVat'
| 'vat'
| 'totalVat'
| 'totalIncVat'
| 'credit'
| 'rebillTotal'
| 'monthDate'
| 'dueDate'
| 'reverted'
| 'paid'
| 'paymentDueDate'
| 'paidBy'
| 'paidByName'
| 'paidByEmployeeNumber'
| 'paidOn'
| 'journaled'
| 'journal'
| 'journalNr'
| 'journaledBy'
| 'journaledByName'
| 'journaledByEmployeeNumber'
| 'journaledOn'
| 'relatedId'
| 'related'> {
    monthDate : Moment;
    dueDate : Moment;

    offPeakGenerationKwh : number | null;
    standardGenerationKwh : number | null;
    peakGenerationKwh : number | null;
    rates : number | null;

    transformer : ITransformer | null;

    totalExVat : number | null;
    vat : number | null;
    totalVat : number | null;
    totalIncVat : number | null;
    credit : number | null;
    rebillTotal : number | null;
}

type YupShape = Record<keyof ISPSInvoiceFormValues, YupSchema>;

export class SPSInvoiceHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'sps_invoices';

    private static converter : firebase.firestore.FirestoreDataConverter<ISPSInvoice> = {
        fromFirestore: (snapshot) => {
            return SPSInvoiceHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ISPSInvoice) : firebase.firestore.DocumentData => {
            return SPSInvoiceHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ISPSInvoice {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as ISPSInvoice,
            ...result,
            monthDate: (data['monthDate'] as firestore.Timestamp).toMillis(),
            dueDate: !data['dueDate'] ? 0 : (data['dueDate'] as firestore.Timestamp).toMillis(),
            transformerRef: (data['transformerRef'] as firestore.DocumentReference).id,
            transformer: TransformerHelper.fromDocument(data.transformer, data.transformerRef),
            paid: !!data.paid,
            paidBy: data.paidBy?.path ?? null,
            paidByName: data.paidByName ?? null,
            paidByEmployeeNumber: data.paidByEmployeeNumber ?? null,
            paidOn: data.paidOn?.toMillis() ?? null,
            paymentDueDate: !data.paymentDueDate ? null : (moment.utc(data.paymentDueDate?.toMillis()).format('YYYY/MM/DD')),
            journal: !data.journal ? null : (data.journal as firestore.DocumentReference).id,
            journaled: !!data.journaled,
            journalNr: data.journalNr ?? null,
            journaledBy: data.journaledBy?.path ?? null,
            journaledByName: data.journaledByName ?? null,
            journaledByEmployeeNumber: data.journaledByEmployeeNumber ?? null,
            journaledOn: data.journaledOn?.toMillis() ?? null,
            relatedId: !data.relatedId ? null : (data.relatedId as firestore.DocumentReference).path,
            related: data.related ?? null,
            reverted: !!data.reverted,
        };
    }

    public static toFirestoreDocument(data : ISPSInvoice) {
        const result = super.toFirestore(data);
        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
            monthDate: firestore.Timestamp.fromMillis(data.monthDate),
            dueDate: firestore.Timestamp.fromMillis(data.dueDate),
            transformerRef: TransformerHelper.doc(data.transformerRef),
            transformer: TransformerHelper.toFirestore(data.transformer, true),
            paid: !!data.paid,
            paidBy: !data.paidBy ? null : EmployeeHelper.path(data.paidBy),
            paidByName: data.paidByName ?? null,
            paidByEmployeeNumber: data.paidByEmployeeNumber ?? null,
            paidOn: !data.paidOn ? null : firestore.Timestamp.fromMillis(data.paidOn),
            paymentDueDate: !data.paymentDueDate ? null : firestore.Timestamp.fromMillis(moment.utc(data.paymentDueDate, DATE_INPUT_FORMAT_DEFAULT, true).valueOf()),
            journal: !data.journal ? null : TransformerJournalHelper.doc(data.journal),
            journaled: !!data.journaled,
            journalNr: data.journalNr ?? null,
            journaledBy: !data.journaledBy ? null : EmployeeHelper.path(data.journaledBy),
            journaledByName: data.journaledByName ?? null,
            journaledByEmployeeNumber: data.journaledByEmployeeNumber ?? null,
            journaledOn: !data.journaledOn ? null : firestore.Timestamp.fromMillis(data.journaledOn),
            related: !data.related ? null : data.related,
            relatedId: !data.related ? null : TransformerInvoiceHelper.doc(data.related.id),
            reverted: !!data.reverted,
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(SPSInvoiceHelper.COLLECTION_NAME).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().doc();
        }
        return this.collection().doc(id);
    }

    public static formSchema = () => Yup.object<YupShape>({
        monthDate: Yup.date().moment().required('Required'),
        dueDate: Yup.date().moment().required('Required'),
        group: Yup.string().required('Required'),
        totalExVat: Yup.number().nullable().required('Required'),
        vat: Yup.number().nullable().required('Required'),
        offPeakGenerationKwh: Yup.number().nullable().optional(),
        peakGenerationKwh: Yup.number().nullable().optional(),
        standardGenerationKwh: Yup.number().nullable().required('Required'),
        totalGenerationKwh: Yup.number().nullable().required('Required'),
        rates: Yup.number().nullable().required('Required'),
        totalVat: Yup.number().nullable().required('Required'),
        totalIncVat: Yup.number().nullable().required('Required'),
        credit: Yup.number().nullable().optional(),
        rebillTotal: Yup.number().nullable().optional(),
        reasonForRebill: Yup.string().when('rebillTotal', {
            is: (rebillTotal : number | null) => rebillTotal && rebillTotal > 0,
            then: (schema) => schema.required('Required'),
            otherwise: (schema) => schema.optional(),
        }),
        transformer: Yup.object().nullable().required('Required'),
        transformerRef: Yup.string().required('Required'),
    });

    public static calculateTotals = (form : FormikProps<ISPSInvoiceFormValues>) => {

        const totalGenerationKwh = new Decimal(form.values.peakGenerationKwh ?? 0)
            .add(form.values.offPeakGenerationKwh ?? 0)
            .add(form.values.standardGenerationKwh ?? 0);

        let totalExVat = new Decimal(form.values.rates ?? 0)
            .mul(totalGenerationKwh)
            .minus(form.values.credit ?? 0);

        if (totalExVat.lessThan(0)) {
            totalExVat = new Decimal(0);
        }
        
        const vat = new Decimal(form.values.vat ?? FirebaseService.getVat());

        const totalVat = vat
            .mul(totalExVat);

        const totalIncVat = totalExVat
            .add(totalVat);

        form.setFieldValue('totalGenerationKwh', totalGenerationKwh.toNumber());
        form.setFieldValue('totalExVat', totalExVat.toNumber());
        form.setFieldValue('totalVat', totalVat.toNumber());
        form.setFieldValue('totalIncVat', totalIncVat.toNumber());
    };
}
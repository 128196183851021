import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IPumpingStationMotorMake } from '../../../../@types/model/masterData/pumpingStation/motorMake';
import MasterDataPumpingStationMotorMakeFunctions from '../../../../store/masterData/pumpingStation/motorMake/functions';

interface IPumpingStationMotorMakeSelectorProps {
    value : string;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : string) => void;

    isLoading : boolean;
    motorMakes : Array<IPumpingStationMotorMake>;
}

interface IPumpingStationMotorMakeSelectorState { }

class PumpingStationMotorMakeSelectorComponent extends React.Component<IPumpingStationMotorMakeSelectorProps, IPumpingStationMotorMakeSelectorState> {
    constructor(props : IPumpingStationMotorMakeSelectorProps) {
        super(props);

        this.state = {};
    }

    public componentDidMount() {
        MasterDataPumpingStationMotorMakeFunctions.load();
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? '');
    };

    private readonly getData = (state : IPumpingStationMotorMakeSelectorState, props : IPumpingStationMotorMakeSelectorProps) => props.motorMakes;
    private readonly getValue = (state : IPumpingStationMotorMakeSelectorState, props : IPumpingStationMotorMakeSelectorProps) => props.value;
    private readonly getRequired = (state : IPumpingStationMotorMakeSelectorState, props : IPumpingStationMotorMakeSelectorProps) => props.required;

    private readonly getMotorMakes = createSelector([
        this.getData,
    ], (motorMakes) => motorMakes.filter(x => x.isActive).map(x => x.name).sort());

    private readonly getMotorMakesForDropdown = createSelector([ 
        this.getRequired, this.getMotorMakes,
    ], (required, motorMakes) => {
        
        const dropdownData = lodash.map(motorMakes, (element) => ({ label: element, value: element }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([ 
        this.getValue, this.getRequired, this.getMotorMakes,
    ], (value, required, motorMakes) => {
        if (value) {
            const motorMake = motorMakes.find(element => element === value);

            if (motorMake) {
                return { label: motorMake, value: motorMake };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, className, disabled } = this.props;

        const dropdownData = this.getMotorMakesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'motorMake'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Motor Make'} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return { 
        isLoading: state.masterData.pumpingStation.motorMake.isLoading,
        motorMakes: state.masterData.pumpingStation.motorMake.motorMakes,
    };
};

const PumpingStationMotorMakeSelector = connect(
    mapStateToProps,
)(PumpingStationMotorMakeSelectorComponent);

export default PumpingStationMotorMakeSelector;
import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IBoreholeCalibrationStageEntry } from '../../../@types/model/borehole/boreholeCalibration';

interface IBoreholeStageInputProps {
    recovery ?: boolean;
    index : number;
    value : IBoreholeCalibrationStageEntry;
    onChange : (value : IBoreholeCalibrationStageEntry, index : number) => void;
}

interface IBoreholeStageInputState {}

export default class BoreholeStageInput extends React.Component<IBoreholeStageInputProps, IBoreholeStageInputState> {
    constructor(props : IBoreholeStageInputProps) {
        super(props);
        this.state = {};
    }

    private readonly onAbstractionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        const entry = {
            ...this.props.value,
        };
        entry.AbstractionRate = Number(event.currentTarget.value);
        entry.IsActive = !!entry.AbstractionRate && !!entry.GroundLevel;
        this.props.onChange(entry, this.props.index);
    };

    private readonly onWaterLevelChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        const entry = {
            ...this.props.value,
        };
        entry.GroundLevel = Number(event.currentTarget.value);
        entry.IsActive = !!entry.AbstractionRate && !!entry.GroundLevel;
        this.props.onChange(entry, this.props.index);
    };

    public readonly render = () => {
        const { value, index, recovery } = this.props;

        return (
            <div className={'fdr flx1 aifs'}>
                <div className={'flx1 aic pb10 pl10 pr10 p5 mb10 pr20'}>
                    <Typography variant='subtitle1'>
                        {value.Time}
                    </Typography>
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl fullWidth>
                        <TextField
                            type='number'
                            autoComplete='off'
                            id={`waterLevel${index}`}
                            label='Ground Level'
                            value={value.GroundLevel}
                            onChange={this.onWaterLevelChange}
                            margin='normal'
                            className={'TextField'}
                            error={!!value.GroundLevel && !Number(value.GroundLevel)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {
                            !!value.AbstractionRate && !Number(value.AbstractionRate) &&
                            <FormHelperText error>Invalid</FormHelperText>
                        }
                    </FormControl>
                </div>
                {
                    !recovery &&
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id={`abstractionRate${index}`}
                                label='Abstraction Rate'
                                value={value.AbstractionRate}
                                onChange={this.onAbstractionChange}
                                margin='normal'
                                className={'TextField'}
                                error={!!value.AbstractionRate && !Number(value.AbstractionRate)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            m<sup>3</sup>/hr
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                !!value.AbstractionRate && !Number(value.AbstractionRate) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                }
            </div>
        );
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { BOREHOLE_OVERALL_STATUS } from '../../../../appConstants';

interface IBoreholeWaterLevelStatusSelectorProps {
    value : string;

    onChange : (value : string) => void;
}

interface IBoreholeWaterLevelStatusSelectorState {}

export default class BoreholeWaterLevelStatusSelector extends React.Component<IBoreholeWaterLevelStatusSelectorProps, IBoreholeWaterLevelStatusSelectorState> {
    constructor(props : IBoreholeWaterLevelStatusSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<{
        name ?: string;
        value : unknown;
    }>) => {
        if (typeof event.target.value !== 'string') return;
        
        this.props.onChange(event.target.value);
    };

    public readonly render = () => {
        const { value } = this.props;

        return (
            <React.Fragment>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel shrink={!!value} htmlFor='value'>Borehole Status</InputLabel>
                        <Select
                            fullWidth
                            id='value'
                            value={!value ? '' : value}
                            onChange={this.onChange}>
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            {
                                lodash.map(BOREHOLE_OVERALL_STATUS, (n, i) => (
                                    <MenuItem key={`${n}_status`} value={`${i}`}>
                                        {n}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </React.Fragment>
        );
    };
}

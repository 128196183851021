import React, { ChangeEvent } from 'react';
import dataFunctions from '../../../store/data/functions';
import { IEmployee } from '../../../@types/employee';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { createSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import EmployeeView from './View';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import Tooltip from '@material-ui/core/Tooltip';
import EmployeeRegisterDialog from './dialog/Create';

interface IEmployeeListProps {
    employee ?: IEmployee;
    employees : Array<IEmployee>;
    isLoading : boolean;
}

interface IEmployeeListState {
    searchText : string;
    deleteEmployee ?: IEmployee;
    showAdd : boolean;
}

class EmployeeList extends React.Component<IEmployeeListProps, IEmployeeListState> {
    constructor(props : IEmployeeListProps) {
        super(props);
        this.state = {
            searchText: '',
            showAdd: false,
        };
    }

    public readonly componentDidMount = () => {
        dataFunctions.getEmployees();
    };

    private readonly onEmployeeClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const id = event.currentTarget.id;
        const employee = this.props.employees.slice().find(n => n.Id === id);
        dataFunctions.setEmployee(employee);
    };

    private readonly onSearchChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            searchText: event.currentTarget.value,
        });
    };

    private readonly getData = (props : IEmployeeListProps) => props.employees;
    private readonly getSearch = (props : IEmployeeListProps, state : IEmployeeListState) => state.searchText;

    private readonly getFilteredData = createSelector(
        [this.getData, this.getSearch],
        (employees, searchText) => {
            return employees.slice().filter(n => (n.Email?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) ||
            (n.EmployeeNumber?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) ||
            (n.Name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));
        },
    );

    private readonly getMobileTotal = createSelector(
        [this.getData],
        (employees) => {
            return employees.slice().filter(n => !n.Email).length;
        },
    );

    private readonly getWebTotal = createSelector(
        [this.getData],
        (employees) => {
            return employees.slice().filter(n => n.Email).length;
        },
    );

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const deleteEmployee = this.props.employees.slice().find(n => n.Id === event.currentTarget.value);

        this.setState({
            deleteEmployee,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result) {
            const id = !this.state.deleteEmployee ? '' : this.state.deleteEmployee.Id;
            dataFunctions.deactivateEmployee(id);
        }
        this.setState({
            deleteEmployee: undefined,
        });
    };

    private readonly onAddClick = () => {
        this.setState({
            showAdd: true,
        });
    };

    private readonly onCreateClose = () => {
        this.setState({
            showAdd: false,
        });
    };

    public readonly render = () => {
        const { isLoading, employee } = this.props;
        const { deleteEmployee, showAdd } = this.state;

        const employees = this.getFilteredData(this.props, this.state);
        const mobileTotal = this.getMobileTotal(this.props);
        const webTotal = this.getWebTotal(this.props);

        return (
            <div className='fdr flx1 hfill'>
                <div className='fdc flx1 hfill'>
                    <Paper className='fdc flx1 hfill m10'>
                        <Toolbar className={'fdr aic jcc ais'}>
                            <TextField
                                fullWidth
                                onChange={this.onSearchChanged}
                                placeholder='Search'
                                margin='dense'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Icon>search</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Tooltip title='Add'>
                                <div>
                                    <IconButton color='primary' onClick={this.onAddClick} aria-label='Add'>
                                        <Icon>add</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                        <Divider />
                        <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                            {'Mobile: ' + mobileTotal}
                            <div className={'flx1'} />
                            {'Web: ' + webTotal}
                        </Typography>
                        <Divider />
                        <List className={'fdc flx1 oys oxh'}>
                            <div className='mnh4'>
                                { isLoading && <LinearProgress />}
                            </div>
                            {
                                employees.map(n => (
                                    <ListItem button key={n.Id} id={n.Id} onClick={this.onEmployeeClick} selected={employee && n.Id === employee.Id}>
                                        <ListItemText
                                            className={n.IsActive ? '' : 'cr'}
                                            primary={n.Name}
                                            secondary={n.Email ? n.Email : n.EmployeeNumber}
                                        />
                                        {
                                            n.IsActive &&
                                            <ListItemSecondaryAction>
                                                <IconButton className='cr' value={n.Id} onClick={this.onDeleteClick} aria-label='Delete'>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                ))
                            }
                        </List>
                        <Divider />
                        <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                            <div className={'flx1'} />
                            {'Total: ' + employees.length}
                        </Typography>
                    </Paper>
                </div>
                <div className='fdc flx4 hfill'>
                    {
                        !!employee &&
                        <EmployeeView employee={employee} isLoading={isLoading} />
                    }
                </div>
                <ConfirmDialog open={!!deleteEmployee} title={!deleteEmployee ? '' : `Confirm Deactivating ${deleteEmployee.Name}`}
                    message={!deleteEmployee ? '' : `Deactivate Employee ${deleteEmployee.Name}?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
                <EmployeeRegisterDialog open={showAdd} transition={Transitions.Up} onClose={this.onCreateClose} maxWidth='md' isLoading={isLoading}/>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        employees: state.data.employees,
        employee: state.data.employee,
        isLoading: state.data.loadingEmployees,
    };
};

export default connect(
    mapStateToProps,
)(EmployeeList);

import { firestore } from 'firebase/app';
import lodash from 'lodash';
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import TransformerJournalHelper, { ITransformerJournal } from '../../../../@types/model/transformer/journal';
import Typography from '@material-ui/core/Typography';
import { Button, DialogActions, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { createSelector } from 'reselect';
import { CURRENCY_FORMAT } from '../../../../appConstants';
import GeneralFunctions from '../../../../store/general/functions';
import firebaseApp from '../../../../services/firebaseService';
import { EmployeeHelper, IUserSession } from '../../../../@types/employee';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';

interface ITransformerJournalExportDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value : Array<ITransformerJournal>;

    onClose ?: (clear ?: boolean) => void;

    session ?: IUserSession | null;
}

interface ITransformerJournalExportDialogState {
    open : boolean;
    isLoading : boolean;
}

class TransformerJournalExportDialogComponent extends React.PureComponent<ITransformerJournalExportDialogProps, ITransformerJournalExportDialogState> {
    constructor(props : ITransformerJournalExportDialogProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
            isLoading: false,
        });
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
        });
    };

    public readonly getValue = (state : ITransformerJournalExportDialogState, props : ITransformerJournalExportDialogProps) => props.value;
    public readonly getDetails = createSelector(
        [this.getValue],
        (journals) : [Record<string, Record<string, number | null>>, Array<string>] => {
            const grouped : Record<string, Record<string, number | null>> = {};
            const acctIds : Array<string> = [];

            journals.sort((a, b) => a.journalNumber.localeCompare(b.journalNumber)).forEach(journal => {
                grouped[journal.journalNumber] = {};

                journal.lines.forEach(line => {
                    if (!acctIds.includes(line.accpacAccountNumber)) {
                        acctIds.push(line.accpacAccountNumber);
                    }

                    let amount = grouped[journal.journalNumber][line.accpacAccountNumber] ?? 0;
                    amount += line.amount;
                    grouped[journal.journalNumber][line.accpacAccountNumber] = amount;
                });
            });

            return [grouped, acctIds];
        },
    );
    public readonly getTotals = createSelector(
        [this.getDetails],
        (details) : Array<number> => {
            const [grouped, acctIds] = details;
            const totals : Array<number> = [];

            acctIds.forEach((acctId, i) => {
                totals[i] = 0;

                Object.values(grouped).forEach(x => {
                    totals[i] += (x[acctId] ?? 0);
                });
            });

            return totals;
        },
    );

    public readonly getCSV = () => {
        const [grouped, acctIds] = this.getDetails(this.state, this.props);
        const totals = this.getTotals(this.state, this.props);

        try {
            const csvData : Array<Array<string>> = [];
            
            // Add header
            const headerRow : Array<string> = [];
            csvData.push(headerRow);
            headerRow.push('');
            acctIds.forEach(acctId => headerRow.push(acctId));

            // Add Journals
            lodash.forEach(grouped, (line, journalNr) => {
                const dataRow : Array<string> = [];
                csvData.push(dataRow);
                dataRow.push(journalNr);
                acctIds.forEach(acctId => dataRow.push(line[acctId]?.toFixed(2) ?? ''));
            });

            // Add Totals
            const totalRow : Array<string> = [];
            csvData.push(totalRow);
            totalRow.push('Grand Total');
            totals.forEach(value => totalRow.push(value.toFixed(2)));

            let csv = '';
            
            csvData.forEach(n => {
                csv += n.join(',');
                csv += '\n';
            });
    
    
            const url = window.URL.createObjectURL(new Blob([csv]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${new Date().valueOf()}_journal_export.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error generating file.');
        }

        
    };

    public readonly export = async () => {
        try {
            const documentRefs = this.props.value.map(x => TransformerJournalHelper.doc(x.id));

            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { session } = this.props;
        
                if (!session) return;
    
                const docs = await Promise.all(documentRefs.map(x => transaction.get(x)));
    
                this.getCSV();
    
                docs.forEach(x => {
                    transaction.update(x.ref, {
                        exported: true,
                        exportedBy: EmployeeHelper.doc(session.firebaseUser.uid),
                        exportedByName: session.employee.Name,
                        exportedByEmployeeNumber: session.employee.EmployeeNumber ?? '',
                        exportedOn: firestore.Timestamp.now(),
                    });
                });
            });
    
            this.onClose();

            if (this.props.onClose) {
                this.props.onClose(true);
            }
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error exporting journal.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    public readonly onOkClick = () => {
        this.setState({
            isLoading: true,
        });
        this.export();
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value } = this.props;
        const { open, isLoading } = this.state;

        const [grouped, acctIds] = this.getDetails(this.state, this.props);
        const totals = this.getTotals(this.state, this.props);
        return (
            <React.Fragment>
                <Tooltip title='Info'>
                    <div>
                        <Button disabled={!value.length || isLoading} variant='contained' color='primary' onClick={this.onClick}>
                            <Icon style={{ marginRight: 10 }}><img src={`${ASSET_BASE}/assets/images/icons/submitted.svg`} /></Icon>
                            EXPORT
                        </Button>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={isLoading ? undefined : this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Info-dialog-title'
                    aria-describedby='Info-description'>
                    <AppBar className='posr' position='static' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography variant='h5' color='inherit'>
                                Export Selected Journals
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill bcg0'>
                        <Paper className='oyo'>
                            <Table className='journal-table'>
                                <TableHead>
                                    <TableRow className='header-row'>
                                        <TableCell className=''>
                                        </TableCell>
                                        {
                                            acctIds.map((n, i) => (
                                                <TableCell key={`split_${i}`} align='right' className=''>
                                                    {n}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        lodash.map(grouped, (line, journalNr) => (
                                            <TableRow key={journalNr} className='data-row'>
                                                <TableCell align='left' className=''>
                                                    {journalNr}
                                                </TableCell>
                                                {
                                                    acctIds.map((a, i) => (
                                                        <TableCell align='right' key={`${a}_${i}`} className=''>
                                                            {line[a] ? CURRENCY_FORMAT.format(line[a] ?? 0) : ''}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        ))
                                    }
                                    <TableRow>
                                        <TableCell className='fw700'>
                                            Grand Total
                                        </TableCell>
                                        {
                                            totals.map((a, i) => (
                                                <TableCell align='right' key={`total_${i}`} className='fw700'>
                                                    {CURRENCY_FORMAT.format(a)}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </DialogContent>
                    <DialogActions className='bcg0'>
                        <Button disabled={isLoading} variant='contained' color='primary' onClick={this.onOkClick}>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const TransformerJournalExportDialog = connect(
    mapStateToProps,
)(TransformerJournalExportDialogComponent);

export default TransformerJournalExportDialog;
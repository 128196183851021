import React from 'react';
import Lightbox from 'react-images';

interface ICarouselImageViewerProps {
    isOpen : boolean;
    imageSrcs : Array<string>;
    onClose : () => void;
    index : number;
}

interface ICarouselImageViewerState {
    index : number;
}

export default class CarouselImageViewer extends React.Component<ICarouselImageViewerProps, ICarouselImageViewerState> {
    constructor(props : ICarouselImageViewerProps) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    public readonly componentDidUpdate = (prevProps : ICarouselImageViewerProps) => {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.setState({
                index: this.props.index,
            });
        }
    };

    public readonly onClickNext = () => {
        this.setState({
            index: this.state.index + 1,
        });
    };

    public readonly onClickPrev = () => {
        this.setState({
            index: this.state.index - 1,
        });
    };

    public readonly onClickThumbnail = (index : number) => {
        this.setState({
            index,
        });
    };

    public readonly render = () => {
        const { isOpen, imageSrcs, onClose } = this.props;
        const { index } = this.state;
        return (
            <Lightbox
                images={imageSrcs.map((n) => {
                    return {
                        src: n,
                    };
                })}
                onClickPrev={this.onClickPrev}
                onClickNext={this.onClickNext}
                currentImage={index}
                isOpen={isOpen}
                showThumbnails={true}
                onClose={onClose}
                onClickThumbnail={this.onClickThumbnail}
            />
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import PumpingStationInspectionAuditHelper, { IPumpingStationInspectionAudit } from '../../../../@types/model/pumpingStation/pumpingStationInspectionAudit';
import { IPumpingStationInspectionGroupFilterBank } from '../../../../@types/model/pumpingStation/pumpingStationInspectionGroup';
import { IUserSession } from '../../../../@types/employee';
import { FormControlLabel, Switch } from '@material-ui/core';
import moment from 'moment';
import GeneralFunctions from '../../../../store/general/functions';
import StarRating from '../../../customComponents/rating/StarRating';

interface IEditPumpingStationInspectionFilterBankDialogProps {
    audit : IPumpingStationInspectionAudit;
    reference : string;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
    
    session : IUserSession;
}

interface IEditPumpingStationInspectionFilterBankDialogState {
    open : boolean;
    filterBank : IPumpingStationInspectionGroupFilterBank;
}

export default class EditPumpingStationInspectionFilterBankDialog extends React.Component<IEditPumpingStationInspectionFilterBankDialogProps, IEditPumpingStationInspectionFilterBankDialogState> {
    constructor(props : IEditPumpingStationInspectionFilterBankDialogProps) {
        super(props);
        this.state = {
            open: false,
            filterBank: {
                serviceRequired: false,
                backFlushTimerWorking: false,
                backwashWorking: false,
                commandFilter20: 0,
                differentialSwitchWorking: false,
                filterRingsCondition: 0,
                waterMeterVerification: false,
            },
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditPumpingStationInspectionFilterBankDialogProps, prevState : IEditPumpingStationInspectionFilterBankDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                filterBank: {
                    serviceRequired: false,
                    backFlushTimerWorking: false,
                    backwashWorking: false,
                    commandFilter20: 0,
                    differentialSwitchWorking: false,
                    filterRingsCondition: 0,
                    waterMeterVerification: false,
                },
            });
        }

        if (!prevState.open && this.state.open) {
            const group = this.props.audit.inspectionGroups.find(x => x.reference === this.props.reference);

            if (!group) return;

            this.setState({
                filterBank: {
                    ...group.filterBank,
                },
            });
        }
    };

    private readonly save = async () => {
        const { session } = this.props;

        const audit = {
            ...this.props.audit,
            inspectionGroups: [
                ...this.props.audit.inspectionGroups,
            ],
        };

        const index = audit.inspectionGroups.findIndex(x => x.reference === this.props.reference);
        audit.inspectionGroups[index] = {
            ...audit.inspectionGroups[index],
            filterBank: {
                ...this.state.filterBank,
            },
        };

        audit.updatedBy = session.firebaseUser.uid;
        audit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        audit.updatedByName = session.employee.Name;
        audit.updatedOn = moment.utc().valueOf();
        
        this.onClose();

        try {
            await PumpingStationInspectionAuditHelper.save(audit);


            GeneralFunctions.generalShowSuccessSnackbar('Saved changes!');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Something went wrong while saving the your changes.');
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    };
    private readonly onBackFlushTimerWorkingChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            filterBank: {
                ...this.state.filterBank,
                backFlushTimerWorking: checked,
            },
        });
    };

    private readonly onBackwashWorkingChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            filterBank: {
                ...this.state.filterBank,
                backwashWorking: checked,
            },
        });
    };

    private readonly onWaterMeterVerificationChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            filterBank: {
                ...this.state.filterBank,
                waterMeterVerification: checked,
            },
        });
    };

    private readonly onDifferentialSwitchWorkingChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            filterBank: {
                ...this.state.filterBank,
                differentialSwitchWorking: checked,
            },
        });
    };

    private readonly onServiceRequiredChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            filterBank: {
                ...this.state.filterBank,
                serviceRequired: checked,
            },
        });
    };

    private readonly onCommandFilter20Change = (commandFilter20 : number) => {
        this.setState({
            filterBank: {
                ...this.state.filterBank,
                commandFilter20,
            },
        });
    };

    private readonly onFilterRingsConditionChange = (filterRingsCondition : number) => {
        this.setState({
            filterBank: {
                ...this.state.filterBank,
                filterRingsCondition,
            },
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open } = this.state;
        const { serviceRequired, commandFilter20, filterRingsCondition, backFlushTimerWorking, backwashWorking,
            differentialSwitchWorking, waterMeterVerification } = this.state.filterBank;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton className='cdg' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='pumpingStation-info-info-title'
                    aria-describedby='pumpingStation-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Pumping Station Inspection Audit FilterBank Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aic jcc'}>
                                <div className={'fdr flx1 mr15'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={backwashWorking}
                                                color='primary'
                                                checked={backwashWorking}
                                                onChange={this.onBackwashWorkingChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Backwash of Filter Working?'}
                                    />
                                </div>
                                <div className={'fdc jcc mr15'}>
                                    <Typography className={'fs12 fw500 mb5 dcgrey'}>
                                        Condition of Filter Rings
                                    </Typography>
                                    <div className='fdc aifs jcfs'>
                                        <StarRating rating={filterRingsCondition} size={5} onClick={this.onFilterRingsConditionChange} />
                                    </div>
                                </div>
                                <div className={'fdr flx1'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={differentialSwitchWorking}
                                                color='primary'
                                                checked={differentialSwitchWorking}
                                                onChange={this.onDifferentialSwitchWorkingChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Pressure Differential Switching Working?'}
                                    />
                                </div>
                            </div>
                            <div className={'fdr flx1 aic jcc'}>
                                <div className={'fdr flx1 mr15'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={backFlushTimerWorking}
                                                color='primary'
                                                checked={backFlushTimerWorking}
                                                onChange={this.onBackFlushTimerWorkingChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Back Flush Timer Working?'}
                                    />
                                </div>
                                <div className={'fdc jcc mr15'}>
                                    <Typography className={'fs12 fw500 mb5 dcgrey'}>
                                        20mm Command Filter
                                    </Typography>
                                    <div className='fdc aifs jcfs'>
                                        <StarRating rating={commandFilter20} size={5} onClick={this.onCommandFilter20Change} />
                                    </div>
                                </div>
                                <div className={'fdr flx1'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={waterMeterVerification}
                                                color='primary'
                                                checked={waterMeterVerification}
                                                onChange={this.onWaterMeterVerificationChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={<span>Water meter verification ({'<10m'}<sup>3</sup>&nbsp;deviation)</span>}
                                    />
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs jcc'}>
                                <div className={'fdr flx1 mr15'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={serviceRequired}
                                                color='primary'
                                                checked={serviceRequired}
                                                onChange={this.onServiceRequiredChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Service Require?'}
                                    />
                                </div>
                                <div className={'fdr flx1 mr15'}>
                                </div>
                                <div className={'fdr flx1'}>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MottechConnectedInput from './ConnectedInput';
import Icon from '@material-ui/core/Icon';

interface IMottechRepeaterInputProps {
    id : string;
    index : number;
    value : string;
    required ?: boolean;
    onChange : (value : string, index : number) => void;
    onDelete : (index : number) => void;
}

interface IMottechRepeaterInputState {
}

export default class MottechRepeaterInput extends React.Component<IMottechRepeaterInputProps, IMottechRepeaterInputState> {
    constructor(props : IMottechRepeaterInputProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onChange = (value : string) => {
        this.props.onChange(value, this.props.index);
    };

    private readonly onDelete = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.onDelete(parseInt(event.currentTarget.value));
    };

    public readonly render = () => {
        const { value, id, index } = this.props;

        return (
            <div className={'fdr flx1 ais'}>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <MottechConnectedInput id={id} label='Piccolo/PIU Id'
                        value={value} onChange={this.onChange} required />
                </div>
                <div className={'flx1 aic jcc p5 mb10 pr20'}>
                    <Tooltip title='Delete'>
                        <div>
                            <IconButton value={index} onClick={this.onDelete}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

import { createAction } from 'typesafe-actions';
import { IRiver } from '../../@types/model/river/river';
import { IRiverMonitorTest } from '../../@types/model/river/riverMonitorTest';

export default class RiverActions {
    public static readonly riverSetList = createAction('RIVER_SET_LIST', resolve => (rivers : Array<IRiver>) => resolve(rivers));
    public static readonly riverSetSelected = createAction('RIVER_SET_SELECTED', resolve => (river ?: IRiver) => resolve(river));
    public static readonly riverSetLoading = createAction('RIVER_SET_LOADING', resolve => (loading : boolean) => resolve(loading));

    public static readonly riverMonitorTestSetList = createAction('RIVER_MONITOR_TEST_SET_LIST', resolve => (rivers : Array<IRiverMonitorTest>) => resolve(rivers));
    public static readonly riverMonitorTestSetLoading = createAction('RIVER_MONITOR_TEST_SET_LOADING', resolve => (loading : boolean) => resolve(loading));
}

import React from 'react';
import { ITransformerMeter, TransformerMeterUoMType } from '../../../@types/model/transformer/transformer';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TransformerMeterUoMSelector from '../selector/transformer/MeterUoMSelector';

interface IMeterNumberInputProps {
    value : ITransformerMeter;
    onChange : (value : ITransformerMeter) => void;
    onDelete : (itemNumber : number) => void;
}

interface IMeterNumberInputState {}

export default class MeterNumberInput extends React.Component<IMeterNumberInputProps, IMeterNumberInputState> {
    constructor(props : IMeterNumberInputProps) {
        super(props);
        this.state = {};
    }

    private readonly onDelete = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.onDelete(parseInt(event.currentTarget.value));
    };

    private readonly onNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = {
            ...this.props.value,
        };
        value.Number = !event.currentTarget.value ? '' : event.currentTarget.value.toLocaleUpperCase();

        this.props.onChange(value);
    };

    private readonly onNumberDigitsChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (typeof event.target.value !== 'number') return;

        const value = {
            ...this.props.value,
        };
        value.NumberOfDigits = !event.target.value ? 0 : event.target.value;

        this.props.onChange(value);
    };

    private readonly onUoMChange = (uom : TransformerMeterUoMType | null) => {
        const value = {
            ...this.props.value,
            UnitOfMeasure: uom,
        };

        this.props.onChange(value);
    };

    public readonly render = () => {
        const { value } = this.props;

        return (
            <div className={'fdr flx1 ais'}>
                <div className={'flx1 aic jcc p5 mb10 pr20'}>
                    <Typography variant='subtitle1'>
                        {value.ItemNumber}
                    </Typography>
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl fullWidth>
                        <TextField
                            required
                            autoComplete='off'
                            id={`${value.ItemNumber}_meter`}
                            label='Meter Number'
                            value={!value.Number ? '' : value.Number}
                            onChange={this.onNumberChange}
                            margin='normal'
                            className={'TextField'}
                            error={!value.Number}
                        />
                        {
                            !value.Number &&
                            <FormHelperText error>Required</FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl required error={!value.NumberOfDigits} margin='normal' fullWidth>
                        <InputLabel shrink={!!value.NumberOfDigits} htmlFor={`${value.ItemNumber}_noDig`}>Number of Digits</InputLabel>
                        <Select
                            required
                            fullWidth
                            id={`${value.ItemNumber}_noDig`}
                            value={!value.NumberOfDigits ? '' : value.NumberOfDigits}
                            onChange={this.onNumberDigitsChange}
                            error={!value.NumberOfDigits}>
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            {
                                [4, 5, 6, 7, 8].map(n => (
                                    <MenuItem key={`${n}_digit`} value={n}>
                                        {n}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        {
                            !value.NumberOfDigits &&
                            <FormHelperText error>Required</FormHelperText>
                        }
                    </FormControl>
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <TransformerMeterUoMSelector
                        value={value.UnitOfMeasure}
                        onChange={this.onUoMChange}
                        required
                    />
                </div>
                <div className={'flx1 aic jcc p5 mb10 pr20'}>
                    <Tooltip title='Delete'>
                        <div>
                            <IconButton value={value.ItemNumber} onClick={this.onDelete}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

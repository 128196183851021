import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import weatherStationFunctions from '../../../store/weather/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import WeatherStationTypeSelector from '../../customComponents/selector/weather/WeatherStationTypeSelector';
import DivisionSelector from '../../customComponents/selector/DivisionSelector';
import { WEATHER_STATION_REG_EXP_YEAR } from '../../../appConstants';
import { IWeatherStation } from '../../../@types/model/weather/weatherStation';

interface IEditWeatherStationInfoProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    weatherStation ?: IWeatherStation;
}

interface IEditWeatherStationInfoState {
    open : boolean;
    name : string;
    wsType : string;
    division : string;
    installationDate : string;
    serialNumber : string;
    station : string;
}

export default class EditWeatherStationInfo extends React.Component<IEditWeatherStationInfoProps, IEditWeatherStationInfoState> {
    constructor(props : IEditWeatherStationInfoProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            wsType: '',
            division: '',
            installationDate: '',
            serialNumber: '',
            station: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditWeatherStationInfoProps, prevState : IEditWeatherStationInfoState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: '',
                wsType: '',
                division: '',
                installationDate: '',
                serialNumber: '',
                station: '',
            });
        }
        if (!prevState.open && this.state.open && this.props.weatherStation) {
            this.setState({
                name: this.props.weatherStation.WSName,
                wsType: !this.props.weatherStation.WSType || this.props.weatherStation.WSType === 'No Data' ? '' : this.props.weatherStation.WSType,
                division: !this.props.weatherStation.Division || this.props.weatherStation.Division === 'No Data' ? '' : this.props.weatherStation.Division,
                installationDate: !this.props.weatherStation.InstallationDate || this.props.weatherStation.InstallationDate === 'No Data' ? '' : this.props.weatherStation.InstallationDate,
                serialNumber: !this.props.weatherStation.SerialNumber || this.props.weatherStation.SerialNumber === 'No Data' ? '' : this.props.weatherStation.SerialNumber,
                station: this.props.weatherStation.Station ?? '',
            });
        }

    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onStationChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            station: event.currentTarget.value,
        });
    };

    private readonly onDivisionChange = (division : string) => {
        this.setState({
            division,
        });
    };

    private readonly onWsTypeChange = (wsType : string) => {
        this.setState({
            wsType,
        });
    };

    private readonly onInstallationDateChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            installationDate: event.currentTarget.value,
        });
    };

    private readonly onSerialNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            serialNumber: event.currentTarget.value,
        });
    };

    private readonly save = async () => {
        const { name, wsType, division, installationDate, serialNumber,
            station } = this.state;
        if (!name) return;
        if (installationDate && !WEATHER_STATION_REG_EXP_YEAR.test(installationDate)) return;

        const weatherStation = Object.assign({}, this.props.weatherStation);

        weatherStation.WSName = name;
        weatherStation.WSType = wsType;
        weatherStation.Division = division;
        weatherStation.InstallationDate = installationDate;
        weatherStation.SerialNumber = serialNumber;
        weatherStation.Station = station;

        const result = await weatherStationFunctions.saveWeatherStation(weatherStation);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, name, wsType, division, installationDate, serialNumber,
            station } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='weatherStation-info-edit-dialog-title'
                    aria-describedby='weatherStation-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Weather Station Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='name'
                                            label='Name'
                                            value={name}
                                            onChange={this.onNameChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!name}
                                        />
                                        {
                                            !name &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <WeatherStationTypeSelector value={wsType} onChange={this.onWsTypeChange} />
                                <DivisionSelector division={division} onDivisionChanged={this.onDivisionChange} />
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='installationDate'
                                            label='Installation Date'
                                            value={installationDate}
                                            onChange={this.onInstallationDateChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!installationDate && !WEATHER_STATION_REG_EXP_YEAR.test(installationDate)}
                                            placeholder='YYYY' />
                                        {
                                            !!installationDate && !WEATHER_STATION_REG_EXP_YEAR.test(installationDate) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='serialNumber'
                                            label='Serial Number'
                                            value={serialNumber}
                                            onChange={this.onSerialNumberChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='station'
                                            label='Station'
                                            value={station}
                                            onChange={this.onStationChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

import {  LinearProgress, } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import PumpingStationInspectionAuditHelper, { IPumpingStationInspectionAudit } from '../../../@types/model/pumpingStation/pumpingStationInspectionAudit';
import PumpingStationExpandableInspectionAudit from './PumpingStationExandableInspectionAudit';

interface IPumpingStationInspectionAuditProps {
    pumpingStation : IPumpingStation;
    isLoading : boolean;
    session : IUserSession;
}

interface IPumpingStationInspectionAuditState {
    audits : Array<IPumpingStationInspectionAudit>;
    isLoadingAudits : boolean;
}

class PumpingStationInspectionAudit extends React.PureComponent<IPumpingStationInspectionAuditProps, IPumpingStationInspectionAuditState> {
    
    private listener ?: () => void;
    
    constructor(props : IPumpingStationInspectionAuditProps) {
        super(props);
        this.state = {
            audits: [],
            isLoadingAudits: true,
        };
    }

    public componentDidMount() {
        
        this.listener = PumpingStationInspectionAuditHelper.listen(this.props.pumpingStation.code)
            .where('isActive', '==', true)
            .onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IPumpingStationInspectionAudit>) => {
               
                const audits = this.state.audits.slice();

                snapshot.docChanges().forEach((change : firebase.firestore.DocumentChange<IPumpingStationInspectionAudit>) => {

                    const data = change.doc.data();
                    const index = audits.findIndex(audit => audit.id === data.id);

                    switch(change.type) {
                   
                    case 'added':
                        if (index > -1) audits.splice(index, 1, data);
                        else audits.push(data);
                        break;
                   
                    case 'modified': 
                        audits.splice(index, 1, data);
                        break;
                   
                    case 'removed': 
                        audits.splice(index, 1);
                        break;
                    }

                });
                
                this.setState({ audits, isLoadingAudits: false, });
            });

    }




    public componentWillUnmount() {
        if (this.listener) this.listener();
    }

    public render = () => {
        
        const { audits, isLoadingAudits } = this.state;
        const { isLoading, session } = this.props;

        return (
            <>
                {
                    (isLoading || isLoadingAudits) && (
                        <div className='mnh4'>
                            <LinearProgress />
                        </div>
                    )
                }
                <div className='bcg0 p20 pl30 pr30 hfill oys' style={{ boxSizing: 'border-box'}}>
                    {
                        !audits.length &&
                        !(isLoading || isLoadingAudits) &&
                        <h2>There are no audits.</h2>
                    }
                    {
                        !!audits.length &&
                        !(isLoading || isLoadingAudits) &&
                        audits.map((audit) => (
                            <PumpingStationExpandableInspectionAudit session={session} pumpingStationInspectionAudit={audit} key={audit.id}/>
                        ))
                    }
                </div>
            </>
        );
    }
}

export default PumpingStationInspectionAudit;

import firebaseApp from '../../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../../base';

export interface IPumpingStationFlowmeterSize extends IBasicBaseClass {
    size : number;

    isActive : boolean;
}

export default class PumpingStationFlowmeterSizeHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station_flowmeter_size';

    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStationFlowmeterSize> = {
        fromFirestore: (snapshot) => {
            return PumpingStationFlowmeterSizeHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStationFlowmeterSize) : firebase.firestore.DocumentData => {
            return PumpingStationFlowmeterSizeHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStationFlowmeterSize {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStationFlowmeterSize,
            ...result,
        };
    }

    public static toFirestoreDocument(data : IPumpingStationFlowmeterSize) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationFlowmeterSizeHelper.COLLECTION_NAME).withConverter(PumpingStationFlowmeterSizeHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationFlowmeterSizeHelper.COLLECTION_NAME).withConverter(PumpingStationFlowmeterSizeHelper.converter).doc(id);
    }

    public static save(company : IPumpingStationFlowmeterSize) {
        return PumpingStationFlowmeterSizeHelper.doc(company.id).set(company, {
            merge: true,
        });
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(PumpingStationFlowmeterSizeHelper.COLLECTION_NAME)
            .withConverter(PumpingStationFlowmeterSizeHelper.converter);
    }
}
import { Card, Icon, IconButton, TextField, Typography } from '@material-ui/core';
import { Field, FieldArrayRenderProps, FieldProps, FormikProps } from 'formik';
import lodash from 'lodash';
import React from 'react';
import { createSelector } from 'reselect';
import BoreholeConstantRateTestHelper, { BoreholeConstantRateNeighbouringStage, IBoreholeConstantRateTestFormValues } from '../../../../@types/model/borehole/boreholeConstantRateTest';
import BoreholeFormField from '../borehole/BoreholeAutocompleteFormField';
import TextFormField from '../TextFormField';
import TimeFormField from '../TimeFormField';

interface IBoreholeConstantRateNeighbouringFormFieldProps extends Omit<FieldArrayRenderProps, 'form'> {
    form : FormikProps<IBoreholeConstantRateTestFormValues>;
}

export default class BoreholeConstantRateNeighbouringFormField extends React.PureComponent<IBoreholeConstantRateNeighbouringFormFieldProps> {
    constructor(props : IBoreholeConstantRateNeighbouringFormFieldProps) {
        super(props);
        this.state = {};
    }

    public getValues = (props : IBoreholeConstantRateNeighbouringFormFieldProps) => props.form.values.neighbouringBoreholes;
    public getList = createSelector(
        [this.getValues],
        (values) => {
            return values;
        },
    );

    public readonly onAddClick = () => {
        this.props.push(BoreholeConstantRateTestHelper.initialNeighbourFormValues());
    };

    public readonly onBoreholeChange = (value : string, fieldName : string) => {
        const index = fieldName.split('.')[1];
        BoreholeConstantRateNeighbouringStage.forEach(step => {
            this.props.form.setFieldValue(`neighbouringBoreholes.${index}.${step}.borehole`, value);
        });
    };

    public readonly render = () => {
        const neighbouringBoreholes = this.getList(this.props);
        return (
            <div className='fdc'>
                <Card className='fdr aic jcc p15 bcdlg3'>
                    <Typography className='flx1 aic jcc fs18 fwb cw'>
                        IDENTIFICATION AND GROUNDWATER LEVEL OF NEIGHBOURING BOREHOLES
                    </Typography>
                    <div className='fdc jcc aic posr'>
                        <div className='posa'>
                            <IconButton className='cw mr30' onClick={this.onAddClick}>
                                <Icon>
                                    add
                                </Icon>
                            </IconButton>
                        </div>
                    </div>
                </Card>
                <div className='fdc'>
                    {
                        neighbouringBoreholes.map((n, i) => (
                            <Card key={i} className='fdc flx1 mt12'>
                                <div className='fdr bcw h50'>
                                    <div className={'fdc flx1 jcc aic bcp h50'}>
                                        <Typography className={'fs14 fw400 cw'}>
                                            TEST NUMBER
                                        </Typography>
                                    </div>
                                    <span className='w3' />
                                    <div className={'fdc flx1 jcc aic dvbg h50'}>
                                        <Typography className={'fs14 fw400 cw'}>
                                            WB NUMBER
                                        </Typography>
                                    </div>
                                    <span className='w3' />
                                    <div className={'fdc flx1 jcc aic dvblg h50'}>
                                        <Typography className={'fs14 fw400 cw'}>
                                            TIME
                                        </Typography>
                                    </div>
                                    <span className='w3' />
                                    <div className={'fdc flx1 jcc aic bcdlg1 h50'}>
                                        <Typography className={'fs14 fw400 cw'}>
                                            GROUNDWATER LEVEL (m)
                                        </Typography>
                                    </div>
                                </div>
                                {
                                    lodash.map(n, (x, step) => (
                                        <Field key={step}
                                            name={`neighbouringBoreholes.${i}`}>
                                            {
                                                (props : FieldProps) => (
                                                    <div className={`fdr ais jcc ${Number(step) % 2 === 1 ? 'bcg2' : ''}`}>
                                                        <div className={'fdc flx1 aic jcc pb16 pt16 pr30 pl30'}>
                                                            <Typography className={'fs20 fwb'}>
                                                                {
                                                                    step
                                                                }
                                                            </Typography>
                                                            {
                                                                step === '6' &&
                                                                <Typography className={'fs12 cp mt5 fwb'}>
                                                                    (Prior to switching off pump)
                                                                </Typography>
                                                            }
                                                        </div>
                                                        <span className='w3 bcw' />
                                                        <div className={'fdr flx1 aife jcc pb16 pt16 pr30 pl30'}>
                                                            <BoreholeFormField
                                                                name={`${props.field.name}.${step}.borehole`}
                                                                label={null}
                                                                optional
                                                                onChange={this.onBoreholeChange}
                                                            />
                                                        </div>
                                                        <span className='w3 bcw' />
                                                        <div className={'fdr flx1 aife jcc pb16 pt16 pr30 pl30'}>
                                                            <TimeFormField
                                                                name={`${props.field.name}.${step}.time`}
                                                                fullWidth
                                                                TextFieldComponent={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            className: params.inputProps + ' cp fwb',
                                                                            style: {
                                                                                ...params.inputProps?.style,
                                                                                textAlign: 'center',
                                                                            },
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <span className='w3 bcw' />
                                                        <div className={'fdr flx1 aife jcc pb16 pt16 pr30 pl30'}>
                                                            <TextFormField
                                                                inputProps={{
                                                                    className: 'cp fwb',
                                                                    style: {
                                                                        textAlign: 'center',
                                                                    },
                                                                }}
                                                                id={`${props.field.name}.${step}.waterLevel`}
                                                                name={`${props.field.name}.${step}.waterLevel`}
                                                                fullWidth
                                                                type='number'
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </Field>
                                    ))
                                }
                            </Card>
                        ))
                    }
                </div>
            </div>
        );
    };
}

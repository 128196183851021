import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IPumpingStationFilterType } from '../../../../@types/model/masterData/pumpingStation/filterType';
import MasterDataPumpingStationFilterTypeFunctions from '../../../../store/masterData/pumpingStation/filterType/functions';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';

interface IPumpingStationFilterTypeSelectorProps {
    value : string | null;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;

    onChange : (value : string | null) => void;

    isLoading : boolean;
    filterTypes : Array<IPumpingStationFilterType>;
}

interface IPumpingStationFilterTypeSelectorState { }

class PumpingStationFilterTypeSelectorComponent extends React.PureComponent<IPumpingStationFilterTypeSelectorProps, IPumpingStationFilterTypeSelectorState> {
    constructor(props : IPumpingStationFilterTypeSelectorProps) {
        super(props);

        this.state = {};
    }

    public componentDidMount() {
        MasterDataPumpingStationFilterTypeFunctions.load();
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? null);
    };

    private readonly getData = (state : IPumpingStationFilterTypeSelectorState, props : IPumpingStationFilterTypeSelectorProps) => props.filterTypes;
    private readonly getValue = (state : IPumpingStationFilterTypeSelectorState, props : IPumpingStationFilterTypeSelectorProps) => props.value;
    private readonly getRequired = (state : IPumpingStationFilterTypeSelectorState, props : IPumpingStationFilterTypeSelectorProps) => props.required;


    private readonly getFilterTypes = createSelector([
        this.getData,
    ], (filterTypes) => filterTypes.filter(x => x.isActive).map(x => x.description).sort((a, b) => a.localeCompare(b)));

    private readonly getFilterTypesForDropdown = createSelector([ 
        this.getRequired, this.getFilterTypes, 
    ], (required, filterTypes) => {
        
        const dropdownData = lodash.map(filterTypes, (element) => ({ label: `${element}`, value: element.toString() }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([ 
        this.getValue, this.getRequired, this.getFilterTypes,
    ], (value, required, filterTypes) => {
        if (value) {
            const filterType = filterTypes.find(element => element === value);

            if (filterType) {
                return { label: filterType, value: filterType };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, className, disabled } = this.props;

        const dropdownData = this.getFilterTypesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'filterType'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'FIlter Type'} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return { 
        isLoading: state.masterData.pumpingStation.filterType.isLoading,
        filterTypes: state.masterData.pumpingStation.filterType.filterTypes,
    };
};

const PumpingStationFilterTypeSelector = connect(
    mapStateToProps,
)(PumpingStationFilterTypeSelectorComponent);

export default PumpingStationFilterTypeSelector;
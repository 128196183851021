import { View, Image, Text } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import style from '../../../style/pdfStyleSheet';

interface ITransformerSPUMeterReportHeaderPdfProps {
    session ?: IUserSession | null;
    area : string;
    employee : string;
}

interface ITransformerSPUMeterReportHeaderPdfState {}

export default class TransformerSPUMeterReportHeaderPdf extends React.PureComponent<ITransformerSPUMeterReportHeaderPdfProps, ITransformerSPUMeterReportHeaderPdfState> {
    constructor(props : ITransformerSPUMeterReportHeaderPdfProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { session, area, employee } = this.props;
        
        return (
            <View fixed style={[style.fdr, style.ais, style.h58, style.page]}>
                <Image source={'assets/images/services.png'} style={[style.h56]} />
                <View style={[style.h58, style.w2, style.bcg3, style.ml18, style.mr18]}>
                </View>
                <View style={[style.fdr, style.aic, style.h58]}>
                    <View style={[style.fdc, style.jcc]}>
                        <Text style={[style.fs20, style.cg3, style.fweb]}>METER READINGS</Text>
                        <View style={[style.fdr, style.flx1, style.mt4]}>
                            <Text style={[style.fs10, style.fwm, style.cg3]}>AREA: </Text>
                            <Text style={[style.fs10, style.fwb, style.cg3]}>{area}</Text>
                        </View>
                    </View>
                </View>
                <View style={[style.fdr, style.flx1, style.aic, style.h58, style.ml18]}>
                    <View style={[style.fdc, style.flx1, style.jcc]}>
                        <View style={[style.fdr, style.jcc]}>
                            <View style={[style.fdc, style.w60]}>
                                <Text style={[style.fs6, style.cg3, style.fwb]}>READING DATE:</Text>
                            </View>
                            <View style={[style.fdr, style.flx1, style.ml9, style.bbw1, style.bocg3]}>
                                <Text style={[style.fs6, style.cg3, style.fwb]}></Text>
                            </View>
                            <View style={[style.fdr, style.ml24, style.w60]}>
                                <Text style={[style.fs6, style.cg3, style.fwb]}>DATE: </Text>
                                <Text style={[style.fs6, style.cg3, style.fwm]}>{moment().format('YYYY/MM/DD')}</Text>
                            </View>
                        </View>
                        <View style={[style.fdr, style.jcc, style.mt16]}>
                            <View style={[style.fdc, style.w60]}>
                                <Text style={[style.fs6, style.cg3, style.fwb]}>READER NAME & SURNAME</Text>
                            </View>
                            <View style={[style.fdr, style.flx1, style.ml9, style.bbw1, style.bocg3]}>
                                <Text style={[style.fs6, style.cg3, style.fweb]}>{[' - -', '- - -', '- -'].includes(employee) ? '' : employee}</Text>
                            </View>
                            <View style={[style.fdr, style.fww, style.aife, style.ml24, style.w60]}>
                                <Text style={[style.fs6, style.cg3, style.fwb]}>CREATED BY: </Text>
                                <Text style={[style.fs6, style.cg3, style.fwm]}>{session?.employee.Name ?? ''}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    };
}
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import React from 'react';
import uuid from 'uuid';
import generalFunctions from '../../../store/general/functions';

interface IMultiFileUploadProgressProps {
    files : Array<File>;

    onFileUpload : (file : File, guid : string) => firebase.storage.UploadTask | null;

    onDone ?: () => void;
}

interface IMultiFileUploadProgressState {
    fileInfo : Array<{
        progress : number;
        guid : string;
        name : string;
    }>;

    error ?: string;
}

export default class MultiFileUploadProgress extends React.PureComponent<IMultiFileUploadProgressProps, IMultiFileUploadProgressState> {
    constructor(props : IMultiFileUploadProgressProps) {
        super(props);
        this.state = {
            fileInfo: [],
        };
    }

    public readonly componentDidMount = () => {
        const fileInfo = new Array<{
            progress : number;
            guid : string;
            name : string;
        }>(this.props.files.length);

        this.props.files.forEach((file, i) => {
            fileInfo[i] = {
                progress: 0,
                guid: uuid.v4(),
                name: file.name,
            };
        });

        this.setState({
            fileInfo,
        }, this.uploadFiles);
    };

    private readonly uploadFiles = async () => {
        const { files, onFileUpload, onDone } = this.props;
        const { fileInfo } = this.state;
        for (let i = 0; i < files.length; i++) {
            const task = onFileUpload(files[i], fileInfo[i].guid);

            if (task) {
                const unsub = task.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                    const currentFileInfo = this.state.fileInfo.slice();
    
                    const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
    
                    currentFileInfo.splice(i, 1, {
                        ...fileInfo[i],
                        progress,
                    });
    
                    this.setState({
                        fileInfo: currentFileInfo,
                    });
                }, (e) => {
                    generalFunctions.generalShowErrorSnackbar(e.message);

                    this.setState({
                        error: e.message,
                    });
                });
                await task;
                unsub();
            }
        }

        if (onDone) onDone();
    };

    public readonly render = () => {
        const { fileInfo } = this.state;
        return (
            <div className='fdc flx1 ais'>
                {
                    fileInfo.map(info => (
                        <div className='fdc ais mt10' key={info.name}>
                            <Typography>{info.name}</Typography>
                            <LinearProgress variant='determinate' value={info.progress} />
                        </div>
                    ))
                }
            </div>
        );
    };
}

import { withStyles, Fab } from '@material-ui/core';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { FabTypeMap } from '@material-ui/core/Fab';

type StandardFabType = ExtendButtonBase<FabTypeMap> & { href : string };

const StandardFab = withStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: theme.palette.primary.dark,
        backgroundColor: '#fd0',
        '&:hover': {
            backgroundColor: '#fd0',
        },
    },
}))(Fab);

export default StandardFab as StandardFabType;

import * as actions from './actions';
import { getType } from 'typesafe-actions';
import { DamAction, IDamState } from '../../@types/redux';

const initialState = {
    dams: [],
    loading: false,
    readings: [],
    loadingReadings: false,
};

export default function generalReducer(state : IDamState = initialState, action : DamAction) : IDamState {
    switch (action.type) {
        case getType(actions.damSetList):
            return { ...state, dams: action.payload };
        case getType(actions.damSetSelected):
            return { ...state, selectedDam: action.payload };
        case getType(actions.damSetLoading):
            return { ...state, loading: action.payload };
        case getType(actions.damSetReadings):
            return { ...state, readings: action.payload };
        case getType(actions.damSetReadingLoading):
            return { ...state, loadingReadings: action.payload };
        default:
            return state;
    }
}

import { Fab } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import TransformerLpuReadingHelper, { ITransformerLpuReading } from '../../../@types/model/transformer/transformerLpuReading';
import { IRootState } from '../../../@types/redux';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import DateRangePicker from '../../customComponents/input/DateRangePicker';
import MaterialTable from '../../customComponents/materialTable/Table';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import EditTransformerLpuReadingEdit from '../dialog/EditLpuReading';
import TransformerLPUReadingDeleteDialog from './dialog/LPUMeterReadingDeleteDialog';

interface ITransformerLPUMeterListProps {
    isTransformerAdmin : boolean;

    session ?: IUserSession | null;
}

interface ITransformerLPUMeterListState {
    transformerNumber ?: string;
    readings : Array<ITransformerLpuReading>;
    isLoading : boolean;
    dateFrom : moment.Moment | null;
    dateTo : moment.Moment | null;
    errorOnly : boolean;
}

class TransformerLPUMeterListClass extends React.PureComponent<ITransformerLPUMeterListProps, ITransformerLPUMeterListState> {
    private readingListener ?: () => void;
    constructor(props : ITransformerLPUMeterListProps) {
        super(props);
        this.state = {
            readings: [],
            isLoading: false,
            dateFrom: null,
            dateTo: null,
            errorOnly: false,
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    private readonly loadData = async () => {
        if (this.readingListener) this.readingListener();

        this.setState({
            isLoading: true,
        });

        const session = this.props.session;

        if (!session) return;

        this.readingListener = TransformerLpuReadingHelper.listen().limit(1000).onSnapshot((snap) => {
            const readings = this.state.readings.slice();

            snap.docChanges().forEach((f) => {
                const item = f.doc.data();
                if (
                    !session.employee.IsTransformerAdmin &&
                    item.division &&
                    session.employee.Areas.length &&
                    !session.employee.Areas.includes(item.division)) return;

                const index = lodash.findIndex(readings, n => n.id === item.id);

                switch (f.type) {
                    case 'added':
                        readings.push(item);
                        break;
                    case 'modified':
                        readings.splice(index, 1, item);
                        break;
                    case 'removed':
                        readings.splice(index, 1);
                        break;
                }
            });

            this.setState({
                readings,
                isLoading: false,
            });
        }, (err) => {
            GeneralFunctions.generalShowErrorSnackbar(err.message);
        });
    };

    private readonly getTransformerNumber = (props : ITransformerLPUMeterListProps, state : ITransformerLPUMeterListState) => state.transformerNumber;
    private readonly getDateFrom = (props : ITransformerLPUMeterListProps, state : ITransformerLPUMeterListState) => state.dateFrom;
    private readonly getDateTo = (props : ITransformerLPUMeterListProps, state : ITransformerLPUMeterListState) => state.dateTo;
    private readonly getData = (props : ITransformerLPUMeterListProps, state : ITransformerLPUMeterListState) => state.readings;
    private readonly getErrorOnly = (props : ITransformerLPUMeterListProps, state : ITransformerLPUMeterListState) => state.errorOnly;

    public readonly getFilteredData = createSelector(
        [
            this.getTransformerNumber,
            this.getDateFrom,
            this.getDateTo,
            this.getData,
            this.getErrorOnly,
        ],
        (transformerNumber, dateFrom, dateTo, readings, errorOnly) => {
            return lodash
                .chain(readings)
                .filter(n => !errorOnly || !!n.isDuplicate || !!n.hasPeakUsageError || !!n.hasOffPeakUsageError || !!n.hasStandardUsageError)
                .filter(n => !transformerNumber || n.transformerRef.includes(transformerNumber))
                .filter(n => !dateFrom || n.date >= dateFrom.valueOf())
                .filter(n => !dateTo || n.date <= dateTo.valueOf())
                .sort((a, b) => b.date - a.date)
                .value();
        },
    );

    private readonly onDelete = async (reading : ITransformerLpuReading) => {
        const session = this.props.session;

        if (!session?.employee.IsTransformerAdmin) return;

        await TransformerLpuReadingHelper.delete(reading.id);
    };

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            transformerNumber: value?.EPNumber,
        });
    };

    private readonly onDateSelectionChange = (start : moment.Moment | null, end : moment.Moment | null) => {
        this.setState({
            dateFrom: !start ? null : moment(start),
            dateTo: !end ? null : moment(end),
        });
    };

    private readonly onShowOnlyErrorClick = () => {
        this.setState({
            errorOnly: !this.state.errorOnly,
        });
    };

    public readonly render = () => {
        const { isLoading, transformerNumber, dateFrom, dateTo, errorOnly } = this.state;
        const { isTransformerAdmin, session } = this.props;
        const readings = this.getFilteredData(this.props, this.state);
        
        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <Toolbar>
                    <span className={'flx1'} />
                    <div className='pr20'>
                        <Tooltip title='Show only Errors'>
                            <Fab color={errorOnly ? 'primary' : 'default'} size='small' onClick={this.onShowOnlyErrorClick}>
                                <Icon className='fs20'>
                                    warning
                                </Icon>
                            </Fab>
                        </Tooltip>
                    </div>
                    <div className={'aic p5 mb10'}>
                        <DateRangePicker start={dateFrom} end={dateTo} onChange={this.onDateSelectionChange} />
                    </div>
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className='fdc flx1 w240'>
                            <TransformerDropdown 
                                value={transformerNumber}
                                label={'Select Transformer'}
                                fullWidth
                                onChange={this.onEPNumberChange}
                            />
                        </div>
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<ITransformerLpuReading>
                        id='transformerReadingsTable'
                        data={readings}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        columns={[{
                            header: '',
                            field: 'EPNumber',
                            width: 250,
                            renderCell: row => (
                                <div className=' fdr aic'>
                                    <EditTransformerLpuReadingEdit
                                        key={`${row.id}_edit`} fullWidth
                                        maxWidth='md'
                                        reading={row}
                                        transition={Transitions.Down}
                                        disabled={!isTransformerAdmin && (row.createdBy !== session?.firebaseUser.uid)}
                                        transformerRef={row.transformerRef}
                                    />
                                    {
                                        (isTransformerAdmin || (row.createdBy === session?.firebaseUser.uid)) &&
                                        <TransformerLPUReadingDeleteDialog key={`${row.id}_delete`} fullWidth
                                            maxWidth='md' reading={row} transition={Transitions.Down}
                                            onDelete={this.onDelete}
                                        />
                                    }
                                    {
                                        !!row.isWeb &&
                                        <Icon className='cp ml10'>
                                            laptop
                                        </Icon>                                            
                                    }
                                    {
                                        !row.isWeb &&
                                        <Icon className='cp ml10'>
                                            smartphone
                                        </Icon>                                            
                                    }
                                    {
                                        row.isDuplicate &&
                                        <Tooltip title='Duplicate'>
                                            <Icon className='cy ml10'>
                                                warning
                                            </Icon>    
                                        </Tooltip>                                        
                                    }
                                    {
                                        row.hasPeakUsageError &&
                                        <Tooltip title='Peak Usage Incorrect'>
                                            <Icon className='cy ml10'>
                                                warning
                                            </Icon>    
                                        </Tooltip>                                        
                                    }
                                    {
                                        row.hasOffPeakUsageError &&
                                        <Tooltip title='Off Peak Usage Incorrect'>
                                            <Icon className='cy ml10'>
                                                warning
                                            </Icon>    
                                        </Tooltip>                                        
                                    }
                                    {
                                        row.hasStandardUsageError &&
                                        <Tooltip title='Standard Usage Incorrect'>
                                            <Icon className='cy ml10'>
                                                warning
                                            </Icon>    
                                        </Tooltip>                                        
                                    }
                                </div>
                            ),
                        }, {
                            header: 'Date',
                            field: 'date',
                            width: 150,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (<span>{AppFunctionsService.formatDateTimeToDateOnly(row.date)}</span>),
                        }, {
                            header: 'EP Point',
                            field: 'transformerRef',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Area',
                            field: 'division',
                            width: 160,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Group',
                            field: 'group',
                            width: 160,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Peak Reading',
                            field: 'peakReading',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <b>
                                    {row.peakReading}
                                </b>
                            ),
                        }, {
                            header: 'Prev. Peak Reading',
                            field: 'prevPeakReading',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Peak Usage',
                            field: 'peakUsage',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Standard Reading',
                            field: 'standardReading',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <b>
                                    {row.standardReading}
                                </b>
                            ),
                        }, {
                            header: 'Prev. Standard Reading',
                            field: 'prevStandardReading',
                            width: 210,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Standard Usage',
                            field: 'standardUsage',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Off Peak Reading',
                            field: 'offPeakReading',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <b>
                                    {row.offPeakReading}
                                </b>
                            ),
                        }, {
                            header: 'Prev. Off Peak Reading',
                            field: 'prevOffPeakReading',
                            width: 210,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Off Peak Usage',
                            field: 'offPeakUsage',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Total Usage',
                            field: 'maxDemand',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <span>
                                    {row.peakUsage + row.offPeakUsage + row.standardUsage}
                                </span>
                            ),
                        }, {
                            header: 'Max Demand',
                            field: 'maxDemand',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Max Demand Date',
                            field: 'dateOfMaxDemand',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (<span>{AppFunctionsService.formatDateTimeToDateOnly(row.dateOfMaxDemand)}</span>),
                        }, {
                            header: 'Max Demand Time',
                            field: 'timeOfMaxDemand',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Captured By',
                            field: 'createdByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.createdByEmployee}>
                                    <div>
                                        {
                                            row.createdByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }, {
                            header: 'Captured On',
                            field: 'createdOn',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (<span>{AppFunctionsService.formatDateTimeToDateOnly(row.createdOn)}</span>),
                        }]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <EditTransformerLpuReadingEdit
                        fullWidth
                        maxWidth='md'
                        transition={Transitions.Down}
                    />
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isTransformerAdmin: !!state.auth.session?.employee.IsTransformerAdmin,
        session: state.auth.session,
    };
};

const TransformerLPUMeterList = connect(
    mapStateToProps,
)(TransformerLPUMeterListClass);

export default TransformerLPUMeterList;

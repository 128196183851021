import { FormControl, FormHelperText, InputAdornment } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import { DatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import { Field, FieldProps } from 'formik';
import moment from 'moment';
import { Moment } from 'moment';
import React from 'react';

interface IKeyboardDateFormFieldProps extends Omit<DatePickerProps, 'name' | 'value' | 'error' | 'variant' | 'onChange'> {
    name : string;
}

class KeyboardDateFormField extends React.PureComponent<IKeyboardDateFormFieldProps> {
    constructor(props : IKeyboardDateFormFieldProps) {
        super(props);
    }

    private readonly onChange = (date : Moment | null, params : FieldProps<Moment | null>) => {
        const {
            views,
        } = this.props;

        if (views?.length === 1 && views.includes('month')) {
            params.form.setFieldValue(params.field.name, !date ? null : moment.utc(date.asUTC()).startOf('month'));
        } else if (views?.length === 1 && views.includes('year')) {
            params.form.setFieldValue(params.field.name, !date ? null : moment.utc(date.asUTC()).startOf('year'));
        } else {
            params.form.setFieldValue(params.field.name, date?.asUTC() ?? null);
        }
    };

    public readonly render = () => {
        const { ...rest } = this.props;
        return (
            <Field
                name={rest.name}
            >
                {
                    (params : FieldProps<Moment | null>) => (
                        <FormControl
                            fullWidth={rest.fullWidth}
                            required={rest.required}
                            error={!!params.meta.error}
                        >
                            <KeyboardDatePicker
                                {...rest}
                                error={!!params.meta.error}
                                onChange={(date) => this.onChange(date, params)}
                                value={params.field.value}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <DateRange />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <FormHelperText error>{params.meta.error ?? ' '}</FormHelperText>
                        </FormControl>
                    )
                }
            </Field>
        );
    };
}

export default KeyboardDateFormField;
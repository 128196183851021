import { Button, Card, Tooltip, Typography } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import { CSVLinkProps } from '../../../../@types/csv';
import { BankAccountType } from '../../../../@types/model/bankAccount';
import { ITransformerJournal } from '../../../../@types/model/transformer/journal';
import { ACCOUNT_TYPE, CURRENCY_FORMAT } from '../../../../appConstants';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { FirebaseService } from '../../../../services/firebaseService';
import GeneralFunctions from '../../../../store/general/functions';
import MaterialTable from '../../../customComponents/materialTable/Table';
import lodash from 'lodash';

interface ITransformerJournalPayment {
    recipientName : string;
    recipientAccount : string;
    recipientAccountType : BankAccountType;
    branchCode : string;
    amount : number;
    ownReference : string;
    recipientReference : string;
}

interface ITransformerJournalPaymentViewProps {
    value : ITransformerJournal;
}

interface ITransformerJournalPaymentViewState {
    date : Moment;

    isLoadingPayment : boolean;
}

export default class TransformerJournalPaymentView extends React.PureComponent<ITransformerJournalPaymentViewProps, ITransformerJournalPaymentViewState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : ITransformerJournalPaymentViewProps) {
        super(props);
        this.state = {
            date: moment.utc().startOf('day'),
            isLoadingPayment: false,
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly getValue = (state : ITransformerJournalPaymentViewState, props : ITransformerJournalPaymentViewProps) => props.value;
    public readonly getPaymentDetails = createSelector(
        [this.getValue],
        (journal) : Array<ITransformerJournalPayment> => {
            const consolidated = journal.invoices.find(x => x.transformer.Consolidated);

            if (consolidated) {
                return [{
                    amount: lodash.sumBy(journal.invoices, (n) => 'totalBilled' in n ? n.totalBilled : (n.rebillTotal ? n.rebillTotal : n.totalIncVat)),
                    branchCode: consolidated.transformer.BankAccount?.branch ?? '',
                    ownReference: journal.journalNumber,
                    recipientAccount: consolidated.transformer.BankAccount?.accountNumber ?? '',
                    recipientAccountType: consolidated.transformer.BankAccount?.accountType ?? 'Other',
                    recipientName: consolidated.transformer.EPGroup ?? '',
                    recipientReference: consolidated.transformer.BankAccount?.recipientReference ?? '',
                }];
            }

            return journal.invoices.map(n => ({
                amount: 'totalBilled' in n ? n.totalBilled : (n.rebillTotal ? n.rebillTotal : n.totalIncVat),
                branchCode: n.transformer.BankAccount?.branch ?? '',
                ownReference: journal.journalNumber,
                recipientAccount: n.transformer.BankAccount?.accountNumber ?? '',
                recipientAccountType: n.transformer.BankAccount?.accountType ?? 'Other',
                recipientName: n.transformer.EPGroup ?? '',
                recipientReference: n.transformer.BankAccount?.recipientReference ?? '',
            }));
        },
    );

    public readonly onCSVClick = () => {
        const { date } = this.state;
        const { value } = this.props;

        this.setState({
            isLoadingPayment: true,
        });

        try {

        
            const csvData : Array<[
                string,
                string,
                string,
                string,
                string,
                string,
                string,
            ]> = [];
    
            csvData.push([
                'BInSol - U ver 1.00',
                '',
                '',
                '',
                '',
                '',
                '',
            ]);
    
            csvData.push([
                AppFunctionsService.formatDateTimeToDateOnly(date),
                '',
                '',
                '',
                '',
                '',
                '',
            ]);
    
            csvData.push([
                FirebaseService.getBankAccount(),
                '',
                '',
                '',
                '',
                '',
                '',
            ]);
    
            csvData.push([
                'RECIPIENT NAME',
                'RECIPIENT ACCOUNT',
                'RECIPIENT ACCOUNT TYPE',
                'BRANCHCODE',
                'AMOUNT',
                'OWN REFERENCE',
                'RECIPIENT REFERENCE',
            ]);
    
            const data = this.getPaymentDetails(this.state, this.props);
    
            data.forEach(n => {
                csvData.push([
                    n.recipientName,
                    n.recipientAccount,
                    ACCOUNT_TYPE[n.recipientAccountType],
                    n.branchCode,
                    n.amount.toFixed(2),
                    n.ownReference,
                    n.recipientReference,
                ]);
            });
    
            let csv = '';
            
            csvData.forEach(n => {
                csv += n.join(',');
                csv += '\n';
            });
    
    
            const url = window.URL.createObjectURL(new Blob([csv]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${value.journalNumber}_payment.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error generating file.');
        } finally {
            this.setState({
                isLoadingPayment: true,
            });
        }
    };

    public readonly render = () => {
        const { date, isLoadingPayment } = this.state;
        const data = this.getPaymentDetails(this.state, this.props);
        return (
            <div className='fdc flx1'>
                <div className='fdr'>
                    <div className='fdc flx1'>
                        <Typography>BInSol - U ver 1.00</Typography>
                        <Typography>{AppFunctionsService.formatDateTimeToDateOnly(date)}</Typography>
                    </div>
                    <div className='fdr'>
                        <Tooltip title='Download Payment File'>
                            <div>
                                <Button variant='contained' color='primary' onClick={this.onCSVClick} disabled={isLoadingPayment}>
                                    Download
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <Card className={'flx1 fdc mt15'}>
                    <MaterialTable<ITransformerJournalPayment>
                        id='transformerJournalPaymentTable'
                        data={data}
                        rowsPerPage={50}
                        externalSort
                        columns={[{
                            header: 'RECIPIENT NAME',
                            field: 'recipientName',
                            width: 145,
                        }, {
                            header: 'RECIPIENT ACCOUNT',
                            field: 'recipientAccount',
                            width: 145,
                        }, {
                            header: 'RECIPIENT ACCOUNT TYPE',
                            field: 'recipientAccountType',
                            width: 145,
                        }, {
                            header: 'BRANCHCODE',
                            field: 'branchCode',
                            width: 145,
                        }, {
                            header: 'AMOUNT',
                            field: 'amount',
                            width: 145,
                            renderCell: row => (
                                <div className=''>
                                    {CURRENCY_FORMAT.format(row.amount)}
                                </div>
                            ),
                        }, {
                            header: 'OWN REFERENCE',
                            field: 'ownReference',
                            width: 145,
                        }, {
                            header: 'RECIPIENT REFERENCE',
                            field: 'recipientReference',
                            width: 145,
                        }]}
                    />
                </Card>
            </div>
        );
    };
}

import { useState } from 'react';
import { IRainMeter } from '../../@types/model/rainMeter/rainMeter/rainMeter';
import RainMeterModelHelper from '../../@types/model/rainMeter/rainMeter/rainMeterModelHelper';
import GeneralFunctions from '../../store/general/functions';
import { getState } from '../../store';
import useFirestoreCollection from '../../@types/hooks/firestore/useFirestoreCollection';
import React from 'react';
import { Card, Icon, IconButton, Tooltip } from '@material-ui/core';
import MaterialTable from '../customComponents/materialTable/Table';
import MapIcon from '@material-ui/icons/Map';
import EditIcon from '@material-ui/icons/Edit';
import RainMeterEditDialog from './components/RainMeterEditDialog';
import RainMeterMeasurementDialog from './components/rainMeterMeasurement/RainMeterMeasurementDialog';
import MapDialog from '../customComponentV2/MapDialog';
import { CHEMICAL_FILLING_POINT_MAP_IMAGES, RIVER_MAP_IMAGES } from '../../appConstants';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';

const RainMeterManager = () : React.ReactElement => {
    const currentUser = getState().auth.session;
    const [selectedRainMeter, setSelectedRainMeter] = useState<IRainMeter | null>(null);
    const [rainMeterToDelete, setRainMeterToDelete] = useState<IRainMeter | null>(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
    const [isDetailDialogOpen, setIsDetailDialogOpen] = useState<boolean>(false);
    const [isMapDialogOpen, setIsMapDialogOpen] = useState<boolean>(false);

    const { data: rainMeters, isLoading : isLoadingRainMeters } = useFirestoreCollection<IRainMeter>(RainMeterModelHelper.collection())

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const handleOpenModal = () : void => {
        setIsEditDialogOpen(true);
    };

    const handleOnDismiss = () : void => {
        setSelectedRainMeter(null);
        setIsEditDialogOpen(false);
        setIsDetailDialogOpen(false);
    };

    const editRainMeter = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) : void => {
        const row = rainMeters.slice().find(x => x.guid === event.currentTarget.value);

        if (!row) return;

        setSelectedRainMeter(row);
        setIsEditDialogOpen(true);
    };

    const deleteRainMeter = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) : void => {
        const row = rainMeters.slice().find(x => x.guid === event.currentTarget.value);

        if (!row) return;

        setRainMeterToDelete(row);
    }

    const handleRainMeterDelete = async () : Promise<void> => {
        if (!currentUser) return;

        if (rainMeterToDelete) {            
            const RainMeter = RainMeterModelHelper.createInactiveSave(currentUser, rainMeterToDelete);
    
            try {
                await RainMeterModelHelper.save(RainMeter);
    
            } catch (e) {
                GeneralFunctions.generalShowErrorSnackbar('An error occurred while inactivating Rain Meter.');
            }
        }

        setRainMeterToDelete(null);
    }

    const rainMeterMeasurementDetail = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) : void => {
        const row = rainMeters.slice().find(x => x.guid === event.currentTarget.value);

        if (!row) return;

        setIsDetailDialogOpen(true);
        setSelectedRainMeter(row);
    }

    const handleOpenMapDialog = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) : void => {
        const row = rainMeters.slice().find(x => x.code === event.currentTarget.value);

        if (!row) return;

        setSelectedRainMeter(row);
        setIsMapDialogOpen(true);
    };

    const handleCloseMapDialog = () : void => {
        setSelectedRainMeter(null);
        setIsMapDialogOpen(false);
    };


    /*================================================================================================================
     *                                                      Memos
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/
    const isDeleteDisabled = (guid : string) : boolean => {
        const row = rainMeters.slice().find(x => x.guid === guid);

        if (!row || !row.isActive) return true;

        return false;
    }

    return (
        <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
            <Card className={'flx1 fdc'}>
                <MaterialTable<IRainMeter>
                    id='RainMetersTable'
                    data={rainMeters}
                    isLoading={isLoadingRainMeters}
                    rowsPerPage={100}
                    columns={[
                    {
                        header: '',
                        field: '',
                        width: 100,
                        enableSort: true,
                        enableFilter: true,
                        paddingRight: 4,
                        fixed: true,
                        renderCell: row => (
                            <div className='aic'>
                                <Tooltip title='Info'>
                                    <div>
                                        <IconButton color='primary' value={row.guid} onClick={rainMeterMeasurementDetail}>
                                            <Icon>
                                                list
                                            </Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip title='Map'>
                                    <div>
                                        <IconButton color='primary' value={row.code} onClick={handleOpenMapDialog} disabled={!row.geo}>
                                            <MapIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip title='Edit'>
                                    <div>
                                        <IconButton color='primary' value={row.guid} onClick={editRainMeter} disabled={!row.geo}>
                                            <EditIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip title='Delete'>
                                    <div>
                                        <IconButton color='primary' disabled={isDeleteDisabled(row.guid)} value={row.guid} onClick={deleteRainMeter}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        ),
                    }, 
                    {
                        header: 'Code',
                        field: 'code',
                        width: 140,
                        enableSort: true,
                    }, 
                    {
                        header: 'Created By',
                        field: 'createdByName',
                        width: 140,
                        enableSort: true,
                    },   
                    {
                        header: 'Updated By',
                        field: 'updatedByName',
                        width: 140,
                        enableSort: true,
                    },  
                    {
                        header: 'Is Active',
                        field: 'isActive',
                        width: 215,
                        enableSort: true,
                        renderCell: n => !n.isActive ? (<Icon className='cr'>close</Icon>) : (<Icon className='cdg'>check</Icon>),
                    }]}
                />
            </Card>
            <div className={'fdrr mt15 mr15'}>
                {
                    currentUser?.employee.IsRainMeterAdmin &&
                    <Tooltip title='Create'>
                        <div>
                            <IconButton onClick={handleOpenModal} className={'bcy'}>
                                <Icon className={'cp'}>
                                    add
                                </Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                }
            </div>
            {
                isDetailDialogOpen &&
                <RainMeterMeasurementDialog
                    isOpen={isDetailDialogOpen}
                    currentUser={currentUser}
                    selectedRainMeter={selectedRainMeter}
                    onDismiss={handleOnDismiss}
                /> 
            } 
            {
                isEditDialogOpen && 
                <RainMeterEditDialog 
                    isOpen={isEditDialogOpen} 
                    currentUser={currentUser}
                    selectedRainMeter={selectedRainMeter} 
                    onDismiss={handleOnDismiss} 
                />
            }
            {
                <MapDialog<IRainMeter> 
                    title={'Rain Meters'}
                    isOpen={isMapDialogOpen}
                    onDismiss={handleCloseMapDialog}
                    dataPoints={rainMeters}
                    selectedDataPoint={selectedRainMeter ?? null}
                    mapImages={RIVER_MAP_IMAGES}
                    identifier={'code'}
                />
            }
            {
                !!rainMeterToDelete &&
                <ConfirmDialog 
                    open={!!rainMeterToDelete}
                    title={'Delete Rain Meter'}
                    message={'Are you sure you want to inactivate the selected entry?'}
                    onAccept={handleRainMeterDelete}
                    onClose={() => setRainMeterToDelete(null)}
                />
            }
        </div>
    );
}

export default RainMeterManager;
import { Field, FieldProps } from 'formik';
import React from 'react';
import TransformerTransformerDropdown, { ITransformerDropdownProps } from '../../selector/transformer/TransformerSelector';
import { ITransformer } from '../../../../@types/model/transformer/transformer';

interface ITransformerAutocompleteFormFieldProps extends Omit<ITransformerDropdownProps, 'name' | 'value' | 'error' | 'variant' | 'onChange' | 'transformers' | 'isLoading'> {
    name : string;

    onChange ?: (transformer : ITransformer | null, params : FieldProps<string | null>) => void;
}

class TransformerAutocompleteFormField extends React.PureComponent<ITransformerAutocompleteFormFieldProps> {
    constructor(props : ITransformerAutocompleteFormFieldProps) {
        super(props);
    }

    private readonly onChange = (transformer : ITransformer | null, params : FieldProps<string | null>) => {
        params.form.setFieldValue(params.field.name, transformer?.EPNumber ?? null);

        setTimeout(() => {
            if (!this.props.onChange) return;
            this.props.onChange(transformer, params);
        });
        
    };

    public readonly render = () => {
        const { ...rest } = this.props;
        return (
            <Field
                name={rest.name}
            >
                {
                    (params : FieldProps<string | null>) => (
                        
                        <TransformerTransformerDropdown
                            {...rest}
                            onChange={(value) => this.onChange(value ?? null, params)}
                            value={params.field.value ?? ''}
                            required
                        />
                    )
                }
            </Field>
        );
    };
}

export default TransformerAutocompleteFormField;
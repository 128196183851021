import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { firestore } from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import TransformerHelper, { ITransformer } from './transformer';
import firebase from 'firebase/app';

export interface ITransformerLpuReading extends IBasicBaseClass {
    transformerRef : string; // Transformer Id
    date : number;
    peakReading : number;
    standardReading : number;
    offPeakReading : number;
    maxDemand : number;
    dateOfMaxDemand : number;
    timeOfMaxDemand : string;
    comment : string;
    division : string;
    group : string;
    isDuplicate ?: boolean;
    hasPeakUsageError ?: boolean;
    hasOffPeakUsageError ?: boolean;
    hasStandardUsageError ?: boolean;
    peakUsage : number;
    offPeakUsage : number;
    standardUsage : number;
    prevPeakReading : number;
    prevStandardReading : number;
    prevOffPeakReading : number;
}

export default class TransformerLpuReadingHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_lpu_reading';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerLpuReading> = {
        fromFirestore: (snapshot) => {
            return TransformerLpuReadingHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerLpuReading) : firebase.firestore.DocumentData => {
            return TransformerLpuReadingHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerLpuReading {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as ITransformerLpuReading,
            ...result,
            date: (data['date'] as firestore.Timestamp).toMillis(),
            dateOfMaxDemand: (data['dateOfMaxDemand'] as firestore.Timestamp).toMillis(),
            transformerRef: (data['transformerRef'] as firestore.DocumentReference).id,
            offPeakReading: data['offPeakReading'] ?? data['pffPeakReading'],
            peakUsage: data['peakUsage'] ?? 0,
            offPeakUsage: data['offPeakUsage'] ?? 0,
            standardUsage: data['standardUsage'] ?? 0,
            prevPeakReading: data['prevPeakReading'] ?? 0,
            prevStandardReading: data['prevStandardReading'] ?? 0,
            prevOffPeakReading: data['prevOffPeakReading'] ?? 0,
        };
    }

    public static toFirestoreDocument(data : ITransformerLpuReading) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            date: firestore.Timestamp.fromMillis(data.date),
            dateOfMaxDemand: firestore.Timestamp.fromMillis(data.dateOfMaxDemand),
            transformerRef: TransformerHelper.doc(data.transformerRef),
        };
    }
    
    public static listen(ref ?: firebase.firestore.DocumentReference<ITransformer>) {
        if (!ref) {
            return firebaseApp
                .firestore()
                .collection(TransformerLpuReadingHelper.COLLECTION_NAME)
                .withConverter(TransformerLpuReadingHelper.converter);
        }

        return firebaseApp
            .firestore()
            .collection(TransformerLpuReadingHelper.COLLECTION_NAME)
            .where('transformerRef', '==', ref)
            .withConverter(TransformerLpuReadingHelper.converter);
    }

    public static save(reading : ITransformerLpuReading) {
        return TransformerLpuReadingHelper.doc(reading.id).set(reading, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(TransformerLpuReadingHelper.COLLECTION_NAME).withConverter(TransformerLpuReadingHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(TransformerLpuReadingHelper.COLLECTION_NAME).withConverter(TransformerLpuReadingHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return firebaseApp
            .firestore()
            .collection(TransformerLpuReadingHelper.COLLECTION_NAME)
            .doc(id)
            .delete();
    }
}
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import lodash from 'lodash';
import DamHelper, { EnumDamWallSurfaceProtection } from '../../../../@types/model/dam/dam';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField } from '@material-ui/core';
import { createSelector } from 'reselect';

export interface IDamWallProtectionDropDownOption {
    label : string;
    value : EnumDamWallSurfaceProtection | null;
}

interface IDamWallProtectionSelectorProps {
    value : EnumDamWallSurfaceProtection | null;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : EnumDamWallSurfaceProtection | null) => void;
}

interface IDamWallProtectionSelectorState { }

class DamWallProtectionSelector extends React.Component<IDamWallProtectionSelectorProps, IDamWallProtectionSelectorState> {
    constructor(props : IDamWallProtectionSelectorProps) {
        super(props);

        this.state = {};
    }   

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? null);
    };

    private readonly getValue = (state : IDamWallProtectionSelectorState, props : IDamWallProtectionSelectorProps) => props.value;
    private readonly getRequired = (state : IDamWallProtectionSelectorState, props : IDamWallProtectionSelectorProps) => props.required;

    private readonly getWallProtections = () => DamHelper.DamWallSurfaceProtection;

    private readonly getWallProtectionsForDropdown = createSelector([
        this.getRequired, this.getWallProtections,
    ], (required, wallProtections) => {
        const dropdownData = lodash.map(wallProtections, (element, index) => ({ label: element, value: lodash.parseInt(index) } as IDamWallProtectionDropDownOption));

        if (!required) {
            dropdownData.unshift({
                label: 'NONE',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getWallProtections,
    ], (value, required, wallProtections) => {
        if (value != null) {
            const wallProtection = wallProtections[value];

            if (wallProtection) {
                return { label: wallProtection, value: value } as IDamWallProtectionDropDownOption;
            }
        }
        return null;
    });

    public readonly render = () => {

        const { required, className, disabled } = this.props;

        const dropdownData = this.getWallProtectionsForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'wallProtection'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Wall Surface Protections'} />}  
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };

}

export default DamWallProtectionSelector;
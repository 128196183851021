import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import TransformerFunctions from '../../../store/transformer/functions';
import { createSelector } from 'reselect';
import { Card, CircularProgress, Fab, FormHelperText, Icon, InputAdornment, Toolbar, Tooltip, Typography } from '@material-ui/core';
import TransformerBudgetHelper, { BudgetType, ILPUBudget, IMunicipalityBudget, ISPUBudget, ITransformerBudget } from '../../../@types/model/transformer/budget';
import FileDropdown from '../../customComponents/FileDropdown';
import Papa from 'papaparse';
import GeneralFunctions from '../../../store/general/functions';
import MaterialTable from '../../customComponents/materialTable/Table';
import { DatePicker } from '@material-ui/pickers';
import { Moment } from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import lodash from 'lodash';
import moment from 'moment';
import { HIGH_SEASON_MONTHS } from '../../../appConstants';
import { IUserSession } from '../../../@types/employee';

interface IParsedCsv {
    Waypoint : string;
    'System Month' : string;
    'SPU 2023_24 Budget' : number | null;
    '2023_24 kWh LPU STD Budget' : number | null;
    '2023_24 kWh LPU Peak Budget' : number | null;
    '2023_24 kWh LPU Off Budget' : number | null;
}

interface IBudgetCsv {
    epNumber : string;
    month : string;

    type : BudgetType;
    std : number | null;
    peak : number | null;
    offpeak : number | null;
}

interface ITransformerBudgetCsvProps {
    transformers : Array<ITransformer>;
    isLoadingTransformers : boolean;

    session ?: IUserSession | null;
}

interface ITransformerBudgetCsvState {
    isLoading : boolean;
    csvFile ?: File;

    csvData : Array<IBudgetCsv>;
    date : Moment | null;
}

class TransformerBudgetCsvComponent extends React.PureComponent<ITransformerBudgetCsvProps, ITransformerBudgetCsvState> {    
    constructor(props : ITransformerBudgetCsvProps) {
        super(props);
        this.state = {
            isLoading: false,
            csvData: [],
            date: null,
        };
    }

    public readonly componentDidMount = () => {
        TransformerFunctions.getList();
    };

    private readonly getIsLoadingTransformers = (props : ITransformerBudgetCsvProps) => props.isLoadingTransformers;
    private readonly getIsLoadingState = (props : ITransformerBudgetCsvProps, state : ITransformerBudgetCsvState) => state.isLoading;
    public readonly getIsLoading = createSelector(
        [this.getIsLoadingTransformers, this.getIsLoadingState],
        (isLoadingTransformers, isLoading) => isLoadingTransformers || isLoading,
    );

    private readonly parseCompleted = (result ?: Papa.ParseResult<IParsedCsv>) => {
        if (!result) {
            this.setState({
                csvData: [],
            });
            return;
        }

        if (result.errors.length) {
            GeneralFunctions.generalShowErrorSnackbar(result.errors.map(x => x.message).join(','));
            return;
        }
        
        const data = result.data.slice();
        

        this.setState({
            csvData: data.map((n) => ({
                epNumber: n.Waypoint,
                month: n['System Month'],
                type: n['SPU 2023_24 Budget'] ? 'spu' : 'lpu',
                offpeak: !n['2023_24 kWh LPU Off Budget'] ? null : Number(n['2023_24 kWh LPU Off Budget']),
                peak: !n['2023_24 kWh LPU Peak Budget'] ? null : Number(n['2023_24 kWh LPU Peak Budget']),
                std: n['SPU 2023_24 Budget'] ? Number(n['SPU 2023_24 Budget']) : Number(n['2023_24 kWh LPU STD Budget']),
            })),
        });
    };

    private readonly parseCsv = (csvFile ?: File) => {
        if (csvFile?.type !== 'text/csv') {
            this.setState({
                csvData: [],
            });
            return;
        }

        Papa.parse<IParsedCsv>(csvFile, {
            header: true,
            complete: this.parseCompleted,
        });
    };

    private readonly onCsvChange = (csvFile ?: File) => {
        this.setState({
            csvFile,
        });

        this.parseCsv(csvFile);
    };

    private readonly onDateChange = (date : MaterialUiPickersDate) => {
        this.setState({
            date: date?.asUTC().startOf('year') ?? null,
        });
    };

    private readonly createBudgets = async () => {
        try {
            const {
                csvData,
                date,
            } = this.state;
            const {
                transformers,
                session,
            } = this.props;

            if (!csvData.length) return;
            if (!date) return;
            if (!transformers.length) return;
            if (!session) return;
    
            const resultList : Array<ITransformerBudget> = [];
    
            const companyAreaGroups = lodash
                .chain(transformers)
                .filter(x => !!x.Company && !!x.Division && !!x.MeterType)
                .groupBy(x => `${x.Company?.id}_${x.Division}_${x.MeterType}`)
                .value();
    
            lodash.forEach(companyAreaGroups, (values) => {
                const area = values[0].Division;
                const company = values[0].Company;
                const type = values[0].MeterType;
                const startDate = moment(date).month('July');
                const year = moment.utc(startDate).add(1, 'year').subtract(1, 'day').year();
                const endDate = moment.utc(startDate).add(1, 'year').subtract(1, 'day').valueOf();

                const result : ITransformerBudget = {
                    id: `${type}_${company?.id}_${area}_${year}`,
                    isActive: true,
                    createdBy: session.firebaseUser.uid,
                    createdByName: session.firebaseUser.displayName ?? '',
                    createdByEmployee: session.employee.EmployeeNumber ?? '',
                    createdOn: moment.utc().valueOf(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.firebaseUser.displayName ?? '',
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: moment.utc().valueOf(),
                    isWeb: true,
                    type: type?.toLocaleLowerCase() === 'spu' ? 'spu' : 'lpu',
                    area,
                    companyId: company?.id ?? '',
                    companyName: company?.name ?? '',
                    prevBudget: null,
                    year,
                    startDate: startDate.valueOf(),
                    endDate: endDate.valueOf(),
                    values: [],
                };

                values.forEach((transformer) => {
                    const budgetValues = csvData.filter(x => x.epNumber === transformer.EPNumber);

                    if (!budgetValues.length) return;
                    
                    budgetValues.forEach((budget) => {

                        const month = moment.utc(startDate).month(budget.month);
    
                        if (month.month() <= 5) {
                            month.add('y', 1);
                        }

                        if (budget.type === 'spu') {
                            const budgetResult : ISPUBudget = {
                                budget: budget.std ?? 0,
                                comment: 'CSV Import',
                                days: month.daysInMonth(),
                                month: month.valueOf(),
                                highSeason: HIGH_SEASON_MONTHS.includes(month.month() + 1),
                                transformer: transformer,
                                transformerRef: transformer.ref.id,
                            };
                            result.values.push(budgetResult);
                        }

                        if (budget.type === 'lpu') {
                            const budgetResult : ILPUBudget = {
                                standardBudget: budget.std ?? 0,
                                offpeakBudget: budget.offpeak ?? 0,
                                peakBudget: budget.peak ?? 0,
                                totalBudget: null,
                                comment: 'CSV Import',
                                days: month.daysInMonth(),
                                month: month.valueOf(),
                                highSeason: HIGH_SEASON_MONTHS.includes(month.month() + 1),
                                transformer: transformer,
                                transformerRef: transformer.ref.id,
                            };

                            if (!budgetResult.totalBudget) {
                                budgetResult.totalBudget = 0;
                            }

                            budgetResult.totalBudget += budgetResult.standardBudget ?? 0;
                            budgetResult.totalBudget += budgetResult.offpeakBudget ?? 0;
                            budgetResult.totalBudget += budgetResult.peakBudget ?? 0;

                            result.values.push(budgetResult);
                        }

                        if (budget.type === 'municipality') {
                            const budgetResult : IMunicipalityBudget = {
                                budget: budget.std ?? 0,
                                comment: 'CSV Import',
                                days: month.daysInMonth(),
                                month: month.valueOf(),
                                highSeason: HIGH_SEASON_MONTHS.includes(month.month() + 1),
                                transformer: transformer,
                                transformerRef: transformer.ref.id,
                            };
                            result.values.push(budgetResult);
                        }
                    });
                });

                resultList.push(result);
            });
            
            await TransformerBudgetHelper.batchSave(resultList);

            this.setState({
                csvData: [],
                date: null,
                csvFile: undefined,
            });
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error creating budgets');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly onCreateClick = () => {
        this.setState({
            isLoading: true,
        });

        this.createBudgets();
    };

    public readonly render = () => {
        const {
            csvFile,
            csvData,
            date,
        } = this.state;
        const isLoading = this.getIsLoading(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    <div className={'flx1 fdc mh0 mw0'}>
                        <Toolbar>
                            <Typography variant='h5' color='inherit'>
                                SELECT A BUDGET CSV
                            </Typography>
                            <div className='fdc flx1 aifs jcfs ml15 mr15'>
                                <FileDropdown
                                    label='Select csv'
                                    accept={'.csv'}
                                    fullWidth
                                    value={csvFile}
                                    required
                                    onChange={this.onCsvChange}
                                />
                            </div>
                            <div className='fdc flx1 aifs jcfs mr15'>
                                <DatePicker
                                    label={'Year'}
                                    onChange={this.onDateChange}
                                    value={date}
                                    format={'YYYY'}
                                    views={['year']}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Icon>date_range</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={!date}
                                    required
                                />
                                {
                                    !date &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </div>
                            <div>
                                <Tooltip title={'Create'}>
                                    <div>
                                        <Fab
                                            size='small'
                                            color='secondary'
                                            className='bcy'
                                            variant='extended'
                                            aria-label='Create'
                                            disabled={!date || !csvData.length}
                                            onClick={this.onCreateClick}
                                        >
                                            <span className='pl10 pr10'>Create</span>
                                        </Fab>
                                    </div>
                                </Tooltip>
                            </div>
                            <span className={'flx4'} />
                        </Toolbar>
                        <Card className={'flx1 fdc'}>
                            <MaterialTable<IBudgetCsv>
                                id='csvData'
                                data={csvData}
                                isLoading={isLoading}
                                rowsPerPage={100}
                                columns={[{
                                    header: 'Waypoint',
                                    field: 'epNumber',
                                    width: 150,
                                    enableSort: false,
                                    enableFilter: false,
                                }, {
                                    header: 'System Month',
                                    field: 'month',
                                    width: 145,
                                    enableSort: false,
                                    enableFilter: false,
                                }, {
                                    header: 'Type',
                                    field: 'type',
                                    width: 175,
                                    enableSort: false,
                                    enableFilter: false,
                                }, {
                                    header: 'STD',
                                    field: 'std',
                                    width: 175,
                                    enableSort: false,
                                    enableFilter: false,
                                }, {
                                    header: 'PEAK',
                                    field: 'peak',
                                    width: 175,
                                    enableSort: false,
                                    enableFilter: false,
                                }, {
                                    header: 'OFF PEAK',
                                    field: 'offpeak',
                                    width: 175,
                                    enableSort: false,
                                    enableFilter: false,
                                }]}
                            />
                        </Card>
                    </div>
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoadingTransformers: state.transformer.loading,
        session: state.auth.session,
    };
};

const TransformerBudgetCsv = connect(
    mapStateToProps,
)(TransformerBudgetCsvComponent);

export default TransformerBudgetCsv;

import lodash from 'lodash';
import { dispatch, getState } from '../..';
import ChemicalFillingPointHelper, { IChemicalFillingPoint } from '../../../@types/model/fillingPoint/chemical';
import FillingPointChemicalActions from './actions';
import GeneralFunctions from '../../general/functions';
import moment from 'moment';

export default class FillingPointChemicalFunctions {
    private static listListener ?: () => void;
   
    public static readonly load = async (onLoaded ?: () => void) => {
        if (FillingPointChemicalFunctions.listListener) {
            if (onLoaded) onLoaded();
            return;
        }
        
        dispatch(FillingPointChemicalActions.setLoading(true));
        dispatch(FillingPointChemicalActions.setList([]));

        try {
            FillingPointChemicalFunctions.listListener = ChemicalFillingPointHelper
                .collection()
                .onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IChemicalFillingPoint>) => {
                    const list = getState().fillingPoints.chemical.points.slice();
                
                    const session = getState().auth.session;
                    if (!session) return;

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const item = f.doc.data();

                        if (
                            !session.employee.IsFillingPointAdmin &&
                            item.division &&
                            session.employee.Areas.length &&
                            !session.employee.Areas.includes(item.division) &&
                            !session.employee.Areas.includes(item.managementArea ?? '')) return;

                        const index = lodash.findIndex(list, n => n.id === item.id);


                        switch (f.type) {
                            case 'added':
                                list.push(item);
                                break;
                            case 'modified':
                                list.splice(index, 1, item);
                                break;
                            case 'removed':
                                list.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(FillingPointChemicalActions.setList(list));
                    dispatch(FillingPointChemicalActions.setLoading(false));

                    if (onLoaded) onLoaded(); 
                }, (error) => {
                    GeneralFunctions.generalShowError(error, 'An error while loading stations.');
                    dispatch(FillingPointChemicalActions.setLoading(false));
                });

        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'An error while loading stations.');
            dispatch(FillingPointChemicalActions.setLoading(false));
        }
    };

    public static readonly setSelected = (item ?: IChemicalFillingPoint) => {
        dispatch(FillingPointChemicalActions.setSelected(item?.guid));
    };

    public static readonly create = async (uuid : string, name : string, code : string) => {
        dispatch(FillingPointChemicalActions.setLoading(true));

        const session = getState().auth.session;

        if (!session) return;
        const result : IChemicalFillingPoint = {
            id: code,
            createdBy: session.firebaseUser.uid,
            createdByEmployee: session.employee.EmployeeNumber ?? '',
            createdByName: session.employee.Name,
            createdOn: moment.utc().valueOf(),
            updatedBy: session.firebaseUser.uid,
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedByName: session.employee.Name,
            updatedOn: moment.utc().valueOf(),
            elevation: null,
            isWeb: true,
            guid: uuid,
            code,
            name,
            division: null,
            managementArea: null,
            divisionNo: null,
            accpacDesc: null,
            geo: null,
            isActive: true,
            isSent: false,
        };

        try {
            await ChemicalFillingPointHelper.save(result);
            FillingPointChemicalFunctions.setSelected(result);
        } catch (ex) {
            GeneralFunctions.generalShowErrorSnackbar(`An error while creating station. ${ex}`);
            throw ex;
        } finally {
            dispatch(FillingPointChemicalActions.setLoading(false));
        }
    };

    /** Save's Chemical Filling Station.
     * Sets dam Loading on dam State. Sets IsSent, UpdatedOn and UpdatedBy.
     * Returns true if success false if failure.
     */
    public static readonly save = async (point : IChemicalFillingPoint) => {

        const session = getState().auth.session;

        if (!session) return;

        dispatch(FillingPointChemicalActions.setLoading(true));
        point.updatedBy = session.firebaseUser.uid;
        point.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        point.updatedByName = session.employee.Name;
        point.updatedOn = moment.utc().valueOf();

        try {
            await ChemicalFillingPointHelper.save(point);
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'An error while saving point.');
            throw ex;
        } finally {
            dispatch(FillingPointChemicalActions.setLoading(false));
        }

    };
}
import React from 'react';
import appFunctionsService from '../../../services/appFunctionsService';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IUserSession } from '../../../@types/employee';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import Typography from '@material-ui/core/Typography';
import BoreholeSensorAuditHelper, { IBoreholeSensorAudit } from '../../../@types/model/borehole/boreholeSensorAudit';
import generalFunctions from '../../../store/general/functions';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import BoreholeSensorAuditEntryView from './SensorAuditEntryView';

interface IBoreholeSensorAuditViewProps {
    boreholeCode : string;
    session ?: IUserSession | null;
}

interface IBoreholeSensorAuditViewState {
    audits : Array<IBoreholeSensorAudit>;
    selectedAudit ?: IBoreholeSensorAudit;
    isLoading : boolean;
}

class BoreholeSensorAuditView extends React.PureComponent<IBoreholeSensorAuditViewProps, IBoreholeSensorAuditViewState> {
    private listen ?: () => void;
    constructor(props : IBoreholeSensorAuditViewProps) {
        super(props);
        this.state = {
            audits: [],
            isLoading: true,
        };
    }

    public readonly componentDidMount = () => {
        try {
            this.listen = BoreholeSensorAuditHelper.listen(this.props.boreholeCode).onSnapshot((snapshot) => {
                this.setLoading(true);
                const audits = this.state.audits.slice();

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const audit = f.doc.data();

                    const index = audits.findIndex(n => n.Id === audit.Id);

                    switch (f.type) {
                        case 'added':
                            audits.push(audit);
                            break;
                        case 'modified':
                            audits.splice(index, 1, audit);
                            break;
                        case 'removed':
                            audits.splice(index, 1);
                            break;
                    }
                });
                this.setState({
                    audits,
                });
                this.setLoading(false);
            });
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading borehole sensor audits.');
            this.setLoading(false);
        }
    };

    public readonly componentWillUnmount = () => {
        if (this.listen) this.listen();
    };

    private readonly setLoading = (isLoading : boolean) => {
        this.setState({
            isLoading,
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selectedAudit = this.state.audits.slice().find(n => n.Id === event.currentTarget.value);
        this.setState({
            selectedAudit,
        });
    };

    private readonly onDelete = async (result : boolean) => {
        if (result && this.state.selectedAudit) {
            try {
                this.setLoading(true);
                await BoreholeSensorAuditHelper.delete(this.state.selectedAudit.Id);
            } catch (ex) {
                generalFunctions.generalShowErrorSnackbar('An error while loading borehole sensor audits.');
            } finally {
                this.setLoading(false);
            }
        }
        this.setState({
            selectedAudit: undefined,
        });
    };

    public readonly render = () => {
        const { session } = this.props;
        const { audits, selectedAudit, isLoading } = this.state;
        return (
            <div className={'fdc flx1 m10'}>
                { isLoading && <LinearProgress className={'wfill mt5'} />}
                {
                    !isLoading && audits.length === 0 &&
                    <div>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Sensor Audit Data Exists!
                        </Typography>
                    </div>
                }
                {
                    audits.map(n => (
                        <ExpansionPanel key={n.Id} className={'wfill'}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <div className='fdr jcc aic flx1'>
                                    { appFunctionsService.formatDateTimeToDateOnly(n.CreatedOn) }
                                    <span className='flx1' />
                                    {
                                        session && session.employee.IsBoreholeAdmin &&
                                        <Tooltip title='Delete'>
                                            <div>
                                                <IconButton size='small' value={n.Id} color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoading}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={'fdc flx1'}>
                                <BoreholeSensorAuditEntryView audit={n} />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))
                }
                <ConfirmDialog open={!!selectedAudit} title={!selectedAudit ? '' : `Confirm Delete ${appFunctionsService.formatDateTimeToDateOnly(selectedAudit.CreatedOn)}`}
                    message={!selectedAudit ? '' : `Delete Audit on ${appFunctionsService.formatDateTimeToDateOnly(selectedAudit.CreatedOn)}, this cannot be undone?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(BoreholeSensorAuditView);

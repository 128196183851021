import React, { useState } from 'react';
import { IRainMeterMeasurement } from '../../../../@types/model/rainMeter/rainMeterMeasurement/rainMeterMeasurement';
import useFirestoreCollection, { FirestoreQueryFilter } from '../../../../@types/hooks/firestore/useFirestoreCollection';
import RainMeterMeasurementModelHelper from '../../../../@types/model/rainMeter/rainMeterMeasurement/rainMeterMeasurementModelHelper';
import { getState } from '../../../../store';
import GeneralFunctions from '../../../../store/general/functions';
import MaterialTable from '../../../customComponents/materialTable/Table';
import { AppBar, Card, Dialog, DialogContent, Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RainMeterMeasurementEditDialog from './RainMeterMeasurementEditDialog';
import { IUserSession } from '../../../../@types/employee';
import { IRainMeter } from '../../../../@types/model/rainMeter/rainMeter/rainMeter';
import { Transitions } from '../../../customComponents/animations/Transitions';
import ConfirmDialog from '../../../customComponents/dialog/ConfirmDialog';
import RainMeterMeasurementChart from './RainMeterMeasurementChart';

interface IRainMeterMeasurementDialogProps {
    currentUser ?: IUserSession | null;
    selectedRainMeter ?: IRainMeter | null;
    isOpen : boolean;
    onDismiss : () => void;
}

const RainMeterMeasurementDialog = (props : IRainMeterMeasurementDialogProps) : React.ReactElement => {
    const currentUser = getState().auth.session;
    const [selectedRainMeterMeasurement, setSelectedRainMeterMeasurement] = useState<IRainMeterMeasurement | null>(null);
    const [rainMeterMeasurementToDelete, setRainMeterMeasurementToDelete] = useState<IRainMeterMeasurement | null>(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);

    const queryFilter : FirestoreQueryFilter = {
        property: 'rainMeterRef',
        operator: '==',
        value: props.selectedRainMeter?.ref ?? 0
    }

    const { 
        data: rainMeterMeasurements, 
        isLoading : isLoadingRainMeterMeasurements 
    } = useFirestoreCollection<IRainMeterMeasurement>(RainMeterMeasurementModelHelper.collection(), [queryFilter]);

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const handleOpenModal = () : void => {
        setIsEditDialogOpen(true);
    };

    const handleOnDismiss = () : void => {
        setSelectedRainMeterMeasurement(null);
        setIsEditDialogOpen(false);
    };

    const editRainMeterMeasurement = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) : void => {
        const row = rainMeterMeasurements.slice().find(x => x.guid === event.currentTarget.value);

        if (!row) return;

        setSelectedRainMeterMeasurement(row);
        setIsEditDialogOpen(true);
    };

    const deleteRainMeterMeasurement = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) : void => {
        const row = rainMeterMeasurements.slice().find(x => x.guid === event.currentTarget.value);

        if (!row) return;

        setRainMeterMeasurementToDelete(row);
    }

    const handleRainMeterMeasurementDelete = async () : Promise<void> => {
        if (!currentUser) return;

        if (rainMeterMeasurementToDelete) {            
            const RainMeterMeasurement = RainMeterMeasurementModelHelper.createInactiveSave(currentUser, rainMeterMeasurementToDelete);
    
            try {
                await RainMeterMeasurementModelHelper.save(RainMeterMeasurement);
    
            } catch (e) {
                GeneralFunctions.generalShowErrorSnackbar('An error occurred while inactivating Rain Meter.');
            }
        }

        setRainMeterMeasurementToDelete(null);
    }

    const isDeleteDisabled = (guid : string) : boolean => {
        const row = rainMeterMeasurements.slice().find(x => x.guid === guid);

        if (!row || !row.isActive) return true;

        return false;
    }

    /*================================================================================================================
     *                                                      Memos
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <>
            <Dialog
                className={'bcg0'}
                open={props.isOpen}
                onClose={props.onDismiss}
                TransitionComponent={Transitions.Up}
                transitionDuration={500}
                fullScreen={true}
                aria-labelledby='rain-meter-edit-dialog-title'
                aria-describedby='rain-meter-edit-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {`Rain Meter - ${props.selectedRainMeter?.code}`}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={props.onDismiss} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className={'bcg0'}>
                    <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                        <Card className={'flx1 fdc'}>
                            <MaterialTable<IRainMeterMeasurement>
                                id='RainMeterMeasurementsTable'
                                data={rainMeterMeasurements}
                                isLoading={isLoadingRainMeterMeasurements}
                                rowsPerPage={100}
                                columns={[
                                {
                                    header: '',
                                    field: '',
                                    width: 100,
                                    enableSort: true,
                                    enableFilter: true,
                                    paddingRight: 4,
                                    fixed: true,
                                    renderCell: row => (
                                        <div className='aic'>
                                            <Tooltip title='Edit'>
                                                <div>
                                                    <IconButton color='primary' value={row.guid} onClick={editRainMeterMeasurement}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                            <Tooltip title='Delete'>
                                                <div>
                                                    <IconButton color='primary' disabled={isDeleteDisabled(row.guid)} value={row.guid} onClick={deleteRainMeterMeasurement}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    ),
                                }, 
                                {
                                    header: 'Measurement (mm)',
                                    field: 'value',
                                    width: 140,
                                    enableSort: true,
                                }, 
                                {
                                    header: 'Captured On',
                                    field: 'capturedDate',
                                    width: 140,
                                    enableSort: true,
                                    renderCell: n => n.capturedDate ? `${n.capturedDate.toDate().toLocaleDateString()}` : '-'
                                }, 
                                {
                                    header: 'Created By',
                                    field: 'createdByName',
                                    width: 140,
                                    enableSort: true,
                                },   
                                {
                                    header: 'Updated By',
                                    field: 'updatedByName',
                                    width: 140,
                                    enableSort: true,
                                },  
                                {
                                    header: 'Is Active',
                                    field: 'isActive',
                                    width: 215,
                                    enableSort: true,
                                    renderCell: n => !n.isActive ? (<Icon className='cr'>close</Icon>) : (<Icon className='cdg'>check</Icon>),
                                }]}
                            />
                        </Card>
                        <div className={'fdrr mt15 mr15'}>
                            <Tooltip title='Create'>
                                <div>
                                    <IconButton onClick={handleOpenModal} className={'bcy'}>
                                        <Icon className={'cp'}>
                                            add
                                        </Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                        <div className={'jcc aic'}>
                            <RainMeterMeasurementChart 
                                data={rainMeterMeasurements}
                                height={500}
                                width={1000}
                            />
                        </div>
                        {
                            isEditDialogOpen && 
                            <RainMeterMeasurementEditDialog 
                                isOpen={isEditDialogOpen} 
                                currentUser={currentUser}
                                selectedRainMeter={props.selectedRainMeter}
                                selectedRainMeterMeasurement={selectedRainMeterMeasurement} 
                                onDismiss={handleOnDismiss} 
                            />
                        }
                        {
                            !!rainMeterMeasurementToDelete &&
                            <ConfirmDialog 
                                open={!!rainMeterMeasurementToDelete}
                                title={'Delete Rain Meter Measurement'}
                                message={'Are you sure you want to inactivate the selected entry?'}
                                onAccept={handleRainMeterMeasurementDelete}
                                onClose={() => setRainMeterMeasurementToDelete(null)}
                            />
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default RainMeterMeasurementDialog;
import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ITransformer, TransformerMeterType } from '../../../../@types/model/transformer/transformer';
import TransformerFunctions from '../../../../store/transformer/functions';
import { IRootState } from '../../../../@types/redux';
import OutlinedTextField from '../../textField/OutlinedTextField';

export interface ITransformerAccountDropdownProps {
    id ?: string;
    value ?: string;

    onChange : (transformer : ITransformer | null) => void;

    required ?: boolean;
    disabled ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    transformers : Array<ITransformer>;
    isLoading : boolean;

    label ?: string;

    type ?: TransformerMeterType | Array<TransformerMeterType>;
    group ?: string | null;
    exclude ?: Array<string> | null;

    variant ?: 'outlined';
}

interface ITransformerAccountDropdownState {
}

class TransformerAccountDropdownComponent extends React.Component<ITransformerAccountDropdownProps, ITransformerAccountDropdownState> {
    constructor(props : ITransformerAccountDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        TransformerFunctions.getList();
    };

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string | null;
        value : string | null;
    } | null | undefined) => {
        this.props.onChange(this.props.transformers.find(x => x.AccountNumber === value?.value) ?? null);
    };

    private readonly getData = (state : ITransformerAccountDropdownState, props : ITransformerAccountDropdownProps) => props.transformers;
    private readonly getValue = (state : ITransformerAccountDropdownState, props : ITransformerAccountDropdownProps) => props.value;
    private readonly getRequired = (state : ITransformerAccountDropdownState, props : ITransformerAccountDropdownProps) => props.required;
    private readonly getType = (state : ITransformerAccountDropdownState, props : ITransformerAccountDropdownProps) => props.type;
    private readonly getGroup = (state : ITransformerAccountDropdownState, props : ITransformerAccountDropdownProps) => props.group;
    private readonly getExclude = (state : ITransformerAccountDropdownState, props : ITransformerAccountDropdownProps) => props.exclude;

    private readonly getAccounts = createSelector([
        this.getData,
        this.getType,
        this.getGroup,
        this.getExclude,
        this.getValue,
    ], (
        transformers,
        type,
        group,
        exclude,
        value,
    ) => {
        return lodash
            .chain(transformers)
            .filter(n => !!n.IsActive)
            .filter(n => !type || (typeof type === 'string' && n.MeterType?.toLocaleLowerCase() === type) || (!!n.MeterType && type.includes(n.MeterType.toLocaleLowerCase() as TransformerMeterType)))
            .filter(n => !group || n.EPGroup === group)
            .filter(n => !!n.AccountNumber)
            .filter(n => !exclude || value === n.AccountNumber || !exclude.includes(n.AccountNumber ?? ''))
            .sortBy(n => n.AccountNumber)
            .value();
    });

    private readonly getTransformerAccountDropdown = createSelector([
        this.getRequired,
        this.getAccounts,
    ], (required, accounts) => {
        const drop = lodash.map(accounts, (n) => ({
            label: `${n.AccountNumber} - ${n.EPNumber} - ${n.MeterType}`,
            value: n.AccountNumber,
        }));

        if (!required) {
            drop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return drop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getAccounts,
    ], (value, required, accounts) => {
        if (value) {
            const account = accounts.find(x => x.AccountNumber === value);

            if (account) {
                return {
                    label: `${account.AccountNumber} - ${account.EPNumber}`,
                    value: account.AccountNumber,
                };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, fullWidth, isLoading, id, label = 'Account Number',
            disabled, variant } = this.props;

        const transformersDrop = this.getTransformerAccountDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        const Component = variant === 'outlined' ? OutlinedTextField : TextField;
        
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    disabled={disabled}
                    options={transformersDrop}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <Component
                        error={required && !value} {...params} fullWidth={fullWidth}
                        disabled={disabled} label={label} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoading: state.transformer.loading,
    };
};

const TransformerAccountDropdown = connect(
    mapStateToProps,
)(TransformerAccountDropdownComponent);

export default TransformerAccountDropdown;
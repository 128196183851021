import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import FlowmeterHelper from './flowmeter';

export interface IFlowmeterInstallationAudit extends IBasicBaseClass {
  guid : string;
  flowmeterCode : string;
  flowmeterRef : string;
  date : number;

  currentMeter : string;

  make : string;

  size : number;

  dialReadings : Array<string>;

  reedSwitch : boolean;

  pulse : string;

  serialNumber : string;
  serialNumberPhotoUrl : string;
  serialNumberThumbnailUrl : string;

  connected : boolean;
  monitor : string;
}

export default class FlowmeterInstallationAuditHelper extends BasicBaseHelper {
  public static readonly COLLECTION_NAME = 'flowmeter_installation_audit';

  private static converter : firebase.firestore.FirestoreDataConverter<IFlowmeterInstallationAudit> = {
      fromFirestore: (snapshot) => {
          return FlowmeterInstallationAuditHelper.fromFirestoreDocument(snapshot);
      },
      toFirestore: (data : IFlowmeterInstallationAudit) : firebase.firestore.DocumentData => {
          return FlowmeterInstallationAuditHelper.toFirestoreDocument(data);
      },
  };

  public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IFlowmeterInstallationAudit {
      const result = super.fromFirestore(snapshot);
      const data = snapshot.data();

      if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
      return {
          ...data as IFlowmeterInstallationAudit,
          ...result,
      };
  }

  public static toFirestoreDocument(data : IFlowmeterInstallationAudit) {
      const result = super.toFirestore(data);
      const { id:_id, ...rest } = data;

      return {
          ...rest,
          ...result,
      };
  }

  public static doc(id ?: string) {
      if (!id) {
          return firebaseApp.firestore().collection(FlowmeterInstallationAuditHelper.COLLECTION_NAME).withConverter(FlowmeterInstallationAuditHelper.converter).doc();
      }

      return firebaseApp.firestore().collection(FlowmeterInstallationAuditHelper.COLLECTION_NAME).withConverter(FlowmeterInstallationAuditHelper.converter).doc(id);
  }

  public static save(flowmeter : IFlowmeterInstallationAudit) {
      return FlowmeterInstallationAuditHelper.doc(flowmeter.id).set(flowmeter, {
          merge: true,
      });
  }
  
  public static collection(flowmeterId : string) {
      return firebaseApp
          .firestore()
          .collection(FlowmeterInstallationAuditHelper.COLLECTION_NAME)
          .where('flowmeterRef', '==', FlowmeterHelper.doc(flowmeterId))
          .withConverter(FlowmeterInstallationAuditHelper.converter);
  }
}
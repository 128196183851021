import React from 'react';
import { ISPSInvoice, ISPSInvoiceFormValues, SPSInvoiceHelper } from '../../../@types/model/transformer/spsInvoice';
import { AppBar, CircularProgress, Dialog, DialogContent, Divider, FormControl, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close } from '@material-ui/icons';
import FormikForm from '../../customComponents/form/FormikForm';
import KeyboardDateFormField from '../../customComponents/form/KeyboardDateFormField';
import moment, { Moment } from 'moment';
import { FastField, FieldProps } from 'formik';
import OutlinedStadiumButton from '../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../customComponents/button/StadiumButton';
import StadiumDiv from '../../customComponents/div/Stadium';
import WhiteTextField from '../../customComponents/textField/WhiteTextField';
import GeneralFunctions from '../../../store/general/functions';
import firebaseApp from '../../../services/firebaseService';
import TransformerAutocompleteFormField from '../../customComponents/form/transformer/TransformerAutocompleteFormField';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import SPSInvoiceCalculatedFields from '../form/CalculatedFields';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../../appConstants';

interface ISPSInvoiceEditDialogProps {
    value : ISPSInvoice | null;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    monthDate ?: Moment | null;

    isLoading : boolean;

    onClose ?: () => void;
}

interface ISPSInvoiceEditDialogState {
    open : boolean;

    initialValues : ISPSInvoiceFormValues | null;
}

export default class SPSInvoiceEditDialog extends React.PureComponent<ISPSInvoiceEditDialogProps, ISPSInvoiceEditDialogState> {
    private readonly maxDate = moment.utc().startOf('month');
    constructor(props : ISPSInvoiceEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            initialValues: null,
        };
    }

    public componentDidUpdate(prevProps : Readonly<ISPSInvoiceEditDialogProps>) : void {
        if (!prevProps.value && this.props.value) {
            this.setState({
                open: true,
            });

            this.setInitialValue(this.props.value);
        }
        
        if (prevProps.value && !this.props.value) {
            this.setState({
                open: false,
            });
        }
    }

    private readonly setInitialValue = (value : ISPSInvoice) => {
        this.setState({
            initialValues: {
                ...value,
                monthDate: !value.monthDate ? (this.props.monthDate ?? null) : moment.utc(value.monthDate),
                group: !value.group ? null : value.group,
                credit: !value.credit ? null : value.credit,
                rebillTotal: !value.rebillTotal ? null : value.rebillTotal,
                dueDate: !value.dueDate ? null : moment.utc(value.dueDate),
            } as ISPSInvoiceFormValues,
        });
    };

    private readonly onSubmit = async (formValues : ISPSInvoiceFormValues) => {
        try {

            const ref = SPSInvoiceHelper.doc(this.props.value?.id);

            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const doc = await transaction.get(ref);
                
                if (!this.props.value) return;
                if (!formValues.transformer) return;

                transaction.set(doc.ref, {
                    ...this.props.value,
                    group: formValues.group,
                    credit: formValues.credit ?? 0,
                    monthDate: formValues.monthDate.valueOf(),
                    dueDate: formValues.dueDate.valueOf(),
                    reasonForRebill: formValues.reasonForRebill ?? null,
                    rebillTotal: formValues.rebillTotal ?? 0,
                    totalExVat: formValues.totalExVat ?? 0,
                    vat: formValues.vat ?? 0,
                    totalVat: formValues.totalVat ?? 0,
                    totalIncVat: formValues.totalIncVat ?? 0,
                    transformer: formValues.transformer,
                    transformerRef: formValues.transformerRef,
                    offPeakGenerationKwh: formValues.offPeakGenerationKwh,
                    peakGenerationKwh: formValues.peakGenerationKwh,
                    standardGenerationKwh: formValues.standardGenerationKwh,
                    rates: formValues.rates,
                    totalGenerationKwh: formValues.totalGenerationKwh,
                });
            });
            if (!this.props.onClose) return;
            this.props.onClose();
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving invoice.');
        }
    };
    
    private readonly onTransformerChange = (transformer : ITransformer | null, params : FieldProps<string | null, ISPSInvoiceFormValues>) => {
        params.form.setFieldValue('transformer', transformer ?? null);
        params.form.setFieldValue('group', transformer?.EPGroup ?? null);
        params.form.setFieldValue('accountNumber', transformer?.AccountNumber ?? null);
        params.form.setFieldValue('transformerRef', transformer?.ref.id ?? null);

        setTimeout(() => {
            SPSInvoiceHelper.calculateTotals(params.form);
        });
    };

    public readonly render = () => {
        const {
            transition,
            isLoading,
            onClose,
            value,
        } = this.props;

        const {
            open,
            initialValues,
        } = this.state;

        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                maxWidth={'md'}
                fullWidth
                aria-labelledby='sps-invoice-dialog-title'
                aria-describedby='sps-invoice-description'
            
            >
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {!value?.id ? 'ADD' : 'EDIT'} SPS READING
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={onClose} aria-label='Close'>
                                    <Close />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                {
                    initialValues &&
                    <FormikForm
                        onSubmit={this.onSubmit}
                        className='fdc hfill'
                        validateOnMount
                        initialValues={initialValues}
                        validationSchema={SPSInvoiceHelper.formSchema()}
                        enableReinitialize
                    >
                        {
                            (props) => (
                                <>
                                    <DialogContent className='p15'>
                                        {
                                            isLoading &&
                                            <div className='fdc flx1 aic jcc'>
                                                <CircularProgress />
                                            </div>
                                        }
                                        {
                                            !isLoading &&
                                            <div className='fdc'>
                                                <div className='fdr'>
                                                    <div className={'flx1 aifs mr10'}>
                                                        <TransformerAutocompleteFormField
                                                            fullWidth
                                                            disabled={isLoading}
                                                            id='transformerRef'
                                                            name={'transformerRef'}
                                                            type={'sps'}
                                                            onChange={this.onTransformerChange}
                                                        />
                                                    </div>
                                                    <div className={'flx1 aifs ml10 mr10'}>
                                                        <KeyboardDateFormField
                                                            fullWidth
                                                            disabled={!!this.props.monthDate}
                                                            views={['month']}
                                                            maxDate={this.maxDate}
                                                            format='MMM YYYY'
                                                            label='Select Account Month...'
                                                            id='monthDate'
                                                            name={'monthDate'}
                                                            required
                                                        />
                                                    </div>
                                                    <div className={'flx1 aifs ml10'}>
                                                        <KeyboardDateFormField
                                                            fullWidth
                                                            disabled={isLoading}
                                                            format={DATE_FORMAT_DEFAULT_NO_TIME}
                                                            label='Due Date'
                                                            id='dueDate'
                                                            name={'dueDate'}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='fdc mt10'>
                                                    <div className='fdr aic h37 pr25'>
                                                        <Typography className='cpd fwm fs14'>GENERATED ENERGY READINGS:</Typography>
                                                    </div>
                                                    <SPSInvoiceCalculatedFields formik={props} />
                                                    <Divider className='mt15 mb15' />
                                                    <div className='fdr aic'>
                                                        <div className='fdr flx1 mr10'>
                                                            <Typography component={'span'} className='flx2 aic jcfe cpd fwm fs14'>TOTAL ELECTRICITY GENERATED:</Typography>
                                                            <StadiumDiv className='fdr flx1 bcg1 cpd ml15'>
                                                                <div className='fdr aic jcc'>
                                                                    <Typography component={'span'} className='fdr jcfs flx1 fs16 fwm pr'>
                                                                        {
                                                                            props.values.totalGenerationKwh.toLocaleString()
                                                                        }
                                                                    </Typography>
                                                                    <Typography component={'span'} className='cg3 fs16 fwm ml15'>Kwh</Typography>
                                                                </div>
                                                            </StadiumDiv>
                                                        </div>
                                                        <div className='fdr flx1 ml10'>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='fdc mt10'>
                                                    <div className='fdc bcpd p20'>
                                                        <Typography className='cw fs22 fwm'>INVOICE SUMMARY</Typography>
                                                        <Divider className='mt15 mb15 bcw' />
                                                        <div className='fdr'>
                                                            <div className='fdc mr15'>
                                                                <div className='fdr aic'>
                                                                    <div className='w250'>
                                                                        <Typography className='cw fs16 fwm'>TOTAL AMOUNT DUE:</Typography>
                                                                    </div>
                                                                    <div className='w200'>
                                                                        <StadiumDiv className='bcw cp'>
                                                                            <Typography component={'span'} className='fdr fs16 fwm cp'>
                                                                                {
                                                                                    props.values.totalExVat?.toLocaleCurrency()
                                                                                }
                                                                            </Typography>
                                                                        </StadiumDiv>
                                                                    </div>
                                                                </div>
                                                                <div className='fdr aic mt15'>
                                                                    <div className='w250'>
                                                                        <Typography className='cw fs16 fwm'>TOTAL VAT:</Typography>
                                                                    </div>
                                                                    <div className='w200'>
                                                                        <StadiumDiv className='bcw cp'>
                                                                            <Typography component={'span'} className='fdr fs16 fwm cp'>
                                                                                {
                                                                                    props.values.totalVat?.toLocaleCurrency()
                                                                                }
                                                                            </Typography>
                                                                        </StadiumDiv>
                                                                    </div>
                                                                </div>
                                                                <div className='fdr aic mt15'>
                                                                    <div className='w250'>
                                                                        <Typography className='cw fs16 fwm'>TOTAL INVOICE COST:</Typography>
                                                                    </div>
                                                                    <div className='w200'>
                                                                        <StadiumDiv className='bcw cp'>
                                                                            <Typography component={'span'} className='fdr fs16 fwm cp'>
                                                                                {
                                                                                    ((props.values.totalIncVat ?? 0)).toLocaleCurrency()
                                                                                }
                                                                            </Typography>
                                                                        </StadiumDiv>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='fdc flx1'>
                                                                <div className='fdr flx1 aife mb15'>
                                                                    <div className=''>
                                                                        <FastField
                                                                            name={'credit'}
                                                                        >
                                                                            {
                                                                                (params : FieldProps<number | null>) => (
                                                                                    <FormControl fullWidth required>
                                                                                        <WhiteTextField
                                                                                            {...params.field}
                                                                                            value={params.field.value ?? ''}
                                                                                            error={!!params.meta.error}
                                                                                            variant='standard'
                                                                                            label='Credit (incl VAT)'
                                                                                            fullWidth
                                                                                            type='number'
                                                                                            onChange={(event) => {
                                                                                                if (!event.currentTarget.value) return;
                                                                                                params.field.onChange(event);
                                                                                                setTimeout(() => {
                                                                                                    SPSInvoiceHelper.calculateTotals(params.form);
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </FormControl>
                                                                                )
                                                                            }
                                                                        </FastField>
                                                                    </div>
                                                                </div>
                                                                <div className='fdr flx1 aife'>
                                                                    <div className='mr15'>
                                                                        <FastField
                                                                            name={'rebillTotal'}
                                                                        >
                                                                            {
                                                                                (params : FieldProps<number | null>) => (
                                                                                    <FormControl fullWidth required>
                                                                                        <WhiteTextField
                                                                                            {...params.field}
                                                                                            value={params.field.value ?? ''}
                                                                                            error={!!params.meta.error}
                                                                                            variant='standard'
                                                                                            label='Rebill'
                                                                                            fullWidth
                                                                                            type='number'
                                                                                            onChange={params.field.onChange}
                                                                                        />
                                                                                    </FormControl>
                                                                                )
                                                                            }
                                                                        </FastField>
                                                                    </div>
                                                                    <div className='flx1'>
                                                                        <FastField
                                                                            name={'reasonForRebill'}
                                                                        >
                                                                            {
                                                                                (params : FieldProps<string | null>) => (
                                                                                    <FormControl fullWidth required>
                                                                                        <WhiteTextField
                                                                                            {...params.field}
                                                                                            value={params.field.value ?? ''}
                                                                                            error={!!params.meta.error}
                                                                                            variant='standard'
                                                                                            label='Reason for Rebill'
                                                                                            fullWidth
                                                                                            onChange={params.field.onChange}
                                                                                        />
                                                                                    </FormControl>
                                                                                )
                                                                            }
                                                                        </FastField>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </DialogContent>
                                    <div className='fdr flx1 jcfe aife p20'>
                                        <OutlinedStadiumButton className='mr16' variant='text' type='button' onClick={onClose} disabled={props.isSubmitting}>
                                            CANCEL
                                        </OutlinedStadiumButton>
                                        <StadiumButton type='submit' disabled={!props.isValid || !props.dirty || props.isSubmitting}>
                                            CONFIRM
                                        </StadiumButton>
                                    </div>
                                </>
                            )
                        }
                    </FormikForm>
                }
            </Dialog>
        );
    };
}

import { Button } from '@material-ui/core';
import React from 'react';

interface IToggleStadiumButtonProps {
    isSelected : boolean;
    onClick : () => void;
    disabled ?: boolean;
    toggledIcon ?: string;
    untoggledIcon ?: string;
}

interface IToggleStadiumButtonState {

}

export default class ToggleStadiumButton extends React.PureComponent<IToggleStadiumButtonProps, IToggleStadiumButtonState> {
    constructor(props : IToggleStadiumButtonProps) {
        super(props);
        this.state = {

        };
    }

    public readonly getStartIcon = () => {
        const { toggledIcon, untoggledIcon, isSelected } = this.props;
        if (isSelected) {
            return <img className='br20 m1 w20 h20 p5 toggleStadiumButtonIconUnselected' src={untoggledIcon}/>;
        } else {
            return <img className='br20 m1 w20 h20 p5 toggleStadiumButtonIconSelected' src={toggledIcon}/>;
        }
    };

    public render () {
        const { isSelected, onClick, disabled } = this.props;
        const startIcon = this.getStartIcon();
        return <Button startIcon={startIcon} onClick={onClick} disabled={disabled} className={`${isSelected ? 'toggleStadiumButtonSelected ' : 'toggleStadiumButtonUnselected '} br20`}>
            {this.props.children}
        </Button>;
    }
}

import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { LANDRATES } from '../../../../appConstants';
import AppFunctionsService from '../../../../services/appFunctionsService';

interface ILandrateDropdownProps {
    id ?: string;
    value : number | null;

    onChange ?: (value : number | null) => void;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    label ?: string;
    clearable ?: boolean;
}

interface ILandrateDropdownState {
}

export default class LandrateDropdown extends React.Component<ILandrateDropdownProps, ILandrateDropdownState> {
    constructor(props : ILandrateDropdownProps) {
        super(props);

        this.state = {
        };
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null) => {
        if (this.props.onChange) this.props.onChange(value?.value ?? null);
    };

    private readonly getValue = (state : ILandrateDropdownState, props : ILandrateDropdownProps) => props.value;
    private readonly getRequired = (state : ILandrateDropdownState, props : ILandrateDropdownProps) => props.required;
    private readonly getClearable = (state : ILandrateDropdownState, props : ILandrateDropdownProps) => props.clearable;

    private readonly getLandrateDropdown = createSelector([
        this.getRequired,
        this.getClearable,
    ], (required, clearable) => {
        const drop : Array<{
            label : string;
            value : number | null;
        }> = lodash.map(LANDRATES, n => ({
            label: AppFunctionsService.getLandrateLabel(n),
            value: n,
        }));

        if (!required && !clearable) {
            drop.unshift({
                label: 'ALL',
                value: null,
            });
        }

        return drop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getClearable,
    ], (value, required, clearable) => {
        if (value) {
            const rate = LANDRATES.find(x => x === value);

            if (rate) {
                return { label: AppFunctionsService.getLandrateLabel(rate), value: rate };
            } else {
                return { label: AppFunctionsService.getLandrateLabel(value), value };
            }
        }

        if (!value && !required && !clearable) {
            return {
                label: 'ALL',
                value: null,
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, fullWidth, id, label = 'Landrate', clearable } = this.props;

        const dropdown = this.getLandrateDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.label === val.label}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required && !clearable}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label={label} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                </div>
            </FormControl>
        );
    };
}

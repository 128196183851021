import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { firestore } from 'firebase/app';
import DamHelper from './dam';

export interface IDamComment extends IBasicBaseClass {
    damId : string; // Dam Id

    comment : string;

    type : 'comment' | 'website' | 'inspection' | 'safety inspection report' | string;

    date : number;
}

export default class DamCommentHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'dam_comment';

    private static converter : firebase.firestore.FirestoreDataConverter<IDamComment> = {
        fromFirestore: (snapshot) => {
            return DamCommentHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDamComment) : firebase.firestore.DocumentData => {
            return DamCommentHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDamComment {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IDamComment,
            ...result,
            damId: (data['damRef'] as firestore.DocumentReference).id, // We convert the ref to an id
            date: (data['date'] as firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : IDamComment) {
        const result = super.toFirestore(data);
        const { id:_id, damId: _damId, ...rest } = data;

        return {
            ...rest,
            ...result,
            damRef: DamHelper.doc(data.damId), // We convert the id back to a ref here
            date: firestore.Timestamp.fromMillis(data.date),
        };
    }
    
    public static collection(damId : string) {
        return DamHelper.doc(damId)
            .collection(DamCommentHelper.COLLECTION_NAME)
            .withConverter(DamCommentHelper.converter);
    }

    public static save(data : IDamComment) {
        if (!data.id) {
            return DamCommentHelper.doc(data.damId).set(data, {
                merge: true,
            });
        }

        return DamCommentHelper.doc(data.damId, data.id).set(data, {
            merge: true,
        });
    }

    public static doc(damId : string, id ?: string) {
        if (!id) {
            return this.collection(damId).withConverter(DamCommentHelper.converter).doc();
        }

        return this.collection(damId).withConverter(DamCommentHelper.converter).doc(id);
    }

    public static delete(damId : string, id : string) {
        return this.doc(damId, id)
            .delete();
    }
}
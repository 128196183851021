import { AccordionDetails, AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import PumpingStationInspectionAuditHelper, { IPumpingStationInspectionAudit } from '../../../@types/model/pumpingStation/pumpingStationInspectionAudit';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';
import '../../../style/pumpingstation/expandableAudit.scss';
import { IUserSession } from '../../../@types/employee';
import GeneralFunctions from '../../../store/general/functions';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import StarRating from '../../customComponents/rating/StarRating';
import EditPumpingStationInspectionDriveDialog from '../dialog/inspection/InspectionAuditDriveEditDialog';
import EditPumpingStationInspectionMotorDialog from '../dialog/inspection/InspectionAuditMotorEditDialog';
import EditPumpingStationInspectionPumpDialog from '../dialog/inspection/InspectionAuditPumpEditDialog';
import EditPumpingStationInspectionFilterBankDialog from '../dialog/inspection/InspectionAuditFilterBankEditDialog';

const WidePanelDetials = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
    }
})(AccordionDetails);

interface IPumpingStationExpandableInspectionAuditProps {
    pumpingStationInspectionAudit : IPumpingStationInspectionAudit;
    session : IUserSession;
}

interface IPumpingStationExpandableInspectionAuditState {
    showConfirm : boolean;
}

class PumpingStationExpandableInspectionAudit extends React.Component<IPumpingStationExpandableInspectionAuditProps, IPumpingStationExpandableInspectionAuditState> {

    constructor(props : IPumpingStationExpandableInspectionAuditProps) {
        super(props);
        this.state = {
            showConfirm: false,
        };
    }

    private onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({ showConfirm: true });
    }

    private onConfirmDeleteClose = (result : boolean) => {
        this.setState({ showConfirm: false });

        this.deleteAudit(result);

    }

    private deleteAudit = async (result : boolean) => {

        if (!result) {
            return;
        }

        const { session } = this.props;

        const pumpingStationInspectionAudit = { 
            ...this.props.pumpingStationInspectionAudit,
            inspectionGroups : [...this.props.pumpingStationInspectionAudit.inspectionGroups]
        }; 

        pumpingStationInspectionAudit.updatedBy = session.firebaseUser.uid;
        pumpingStationInspectionAudit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStationInspectionAudit.updatedByName = session.employee.Name;
        pumpingStationInspectionAudit.updatedOn = moment().utc().valueOf();
        pumpingStationInspectionAudit.isActive = false;
 
        try {
            await PumpingStationInspectionAuditHelper.save(pumpingStationInspectionAudit);
            GeneralFunctions.generalShowSuccessSnackbar('Audit has been deleted!');
        } catch {
            GeneralFunctions.generalShowErrorSnackbar('Something went wrong while deleting the audit.');
        }


    }

    public render() {
        const { showConfirm } = this.state;
        const { pumpingStationInspectionAudit, session } = this.props;

        return (
            <>
                {
                    pumpingStationInspectionAudit.inspectionGroups.map((inspectionGroup) => (
                        <SeparatedAccordion key={inspectionGroup.reference}>
                            <PrimaryAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <div className={'fdr flx1 aic'}>
                                    <Typography className={'flx1 fs19 fw500'}> 
                                        PG {inspectionGroup.number} - {moment.utc(pumpingStationInspectionAudit.createdOn).format('DD/MM/YYYY')}
                                    </Typography>
                                    {
                                        session.employee.IsPumpingStationAdmin &&
                                        <IconButton onClick={this.onDeleteClick} size='small'>
                                            <DeleteIcon color='primary'/>
                                        </IconButton>
                                    }
                                </div>
                            </PrimaryAccordionSummary>
                            <WidePanelDetials>
                                <div className='fdc ais'>
                                    <AppBar className='bclg' position='static' elevation={1}>
                                        <Toolbar variant='dense'>
                                            <div className={'fdr jcsb aic wfill'}>
                                                <Typography variant='h6' className='flx1 cp fw600'>
                                                    Drive
                                                </Typography>
                                            </div>
                                            <span className='flx1' />
                                            {
                                                session.employee.IsPumpingStationAdmin &&
                                                <EditPumpingStationInspectionDriveDialog
                                                    session={session}
                                                    reference={inspectionGroup.reference} 
                                                    audit={pumpingStationInspectionAudit} 
                                                    maxWidth={'md'} 
                                                    fullWidth={true} 
                                                />
                                            }
                                        </Toolbar>
                                    </AppBar>
                                    <div className='fdr aifs pl30 pr30 pt16 pb16'>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Switchgear
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.drive.switchGear}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Loose Connection Test (temp)
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.drive.looseConnectionTestTemp.toFixed(1)}&#8451;
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Hot Connection Test
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.drive.hotConnectionTest ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Pressure Sensor Working
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.drive.pressureSensorWorking ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Visual Damage
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.drive.visualDamage ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Service Required
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.drive.serviceRequired ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Comment
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {
                                                    !inspectionGroup.drive.visualDamageComment &&
                                                    '-'
                                                }
                                                {
                                                    !!inspectionGroup.drive.visualDamageComment &&
                                                    inspectionGroup.drive.visualDamageComment
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <AppBar className='bclg' position='static' elevation={1}>
                                        <Toolbar variant='dense'>
                                            <div className={'fdr jcsb aic wfill'}>
                                                <Typography variant='h6' className='flx1 cp fw600'>
                                                    Motor
                                                </Typography>
                                            </div>
                                            <span className='flx1' />
                                            {
                                                session.employee.IsPumpingStationAdmin &&
                                                <EditPumpingStationInspectionMotorDialog
                                                    session={session}
                                                    reference={inspectionGroup.reference} 
                                                    audit={pumpingStationInspectionAudit} 
                                                    maxWidth={'md'} 
                                                    fullWidth={true} 
                                                />
                                            }
                                        </Toolbar>
                                    </AppBar>
                                    <div className='fdr aifs pl30 pr30 pt16 pb16'>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Bearings Motor Drive-end X
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.motor.bearingMotorDriveX.toFixed(2)} m/s<sup>2</sup>
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Bearings Motor Drive-end Y
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.motor.bearingMotorDriveY.toFixed(2)} m/s<sup>2</sup>
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Bearings Motor Non Drive - end X
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.motor.bearingMotorNonDriveX.toFixed(2)} m/s<sup>2</sup>
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Axial Direction (Z)
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.motor.axialDirectionZ.toFixed(2)}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Visual Damage
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.motor.visualDamage ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Service Required
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.motor.serviceRequired ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Comment
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {
                                                    !inspectionGroup.motor.visualDamageComment &&
                                                    '-'
                                                }
                                                {
                                                    !!inspectionGroup.motor.visualDamageComment &&
                                                    inspectionGroup.motor.visualDamageComment
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <AppBar className='bclg' position='static' elevation={1}>
                                        <Toolbar variant='dense'>
                                            <div className={'fdr jcsb aic wfill'}>
                                                <Typography variant='h6' className='flx1 cp fw600'>
                                                    Pump
                                                </Typography>
                                            </div>
                                            {
                                                session.employee.IsPumpingStationAdmin &&
                                                <EditPumpingStationInspectionPumpDialog
                                                    session={session}
                                                    reference={inspectionGroup.reference} 
                                                    audit={pumpingStationInspectionAudit} 
                                                    maxWidth={'md'} 
                                                    fullWidth={true} 
                                                />
                                            }
                                        </Toolbar>
                                    </AppBar>
                                    <div className='fdr aifs pl30 pr30 pt16 pb16'>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Bearings Motor Drive-end
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                X - {inspectionGroup.pump.bearingMotorDriveX.toFixed(2)} m/s<sup>2</sup>
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                Y - {inspectionGroup.pump.bearingMotorDriveY.toFixed(2)} m/s<sup>2</sup>
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Bearings Motor Non Drive - end X
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                X - {inspectionGroup.pump.bearingMotorNonDriveX.toFixed(2)} m/s<sup>2</sup>
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                Y - {inspectionGroup.pump.bearingMotorNonDriveY.toFixed(2)} m/s<sup>2</sup>
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Coupling Condition
                                            </Typography>
                                            <StarRating rating={inspectionGroup.pump.rating} size={5} />
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Pump cut off pressure reading
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                Theoretical - {inspectionGroup.pump.pumpCutOffPressureTheoretical.toFixed(2)}
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                Actual - {inspectionGroup.pump.pumpCutOffPressureActual.toFixed(2)}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Visual Damage
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.pump.visualDamage ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Service Required
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.pump.serviceRequired ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Comment
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {
                                                    !inspectionGroup.pump.visualDamageComment &&
                                                    '-'
                                                }
                                                {
                                                    !!inspectionGroup.pump.visualDamageComment &&
                                                    inspectionGroup.pump.visualDamageComment
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <AppBar className='bclg' position='static' elevation={1}>
                                        <Toolbar variant='dense'>
                                            <div className={'fdr jcsb aic wfill'}>
                                                <Typography variant='h6' className='flx1 cp fw600'>
                                                    Filterbank
                                                </Typography>
                                            </div>
                                            {
                                                session.employee.IsPumpingStationAdmin &&
                                                <EditPumpingStationInspectionFilterBankDialog
                                                    session={session}
                                                    reference={inspectionGroup.reference} 
                                                    audit={pumpingStationInspectionAudit} 
                                                    maxWidth={'md'} 
                                                    fullWidth={true} 
                                                />
                                            }
                                        </Toolbar>
                                    </AppBar>
                                    <div className='fdr aifs pl30 pr30 pt16 pb16'>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Backwash of Filter Working?
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.filterBank.backwashWorking ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Condition of Filter Rings
                                            </Typography>
                                            <StarRating rating={inspectionGroup.filterBank.filterRingsCondition} size={5} />
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Pressure Differential Switching Working?
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.filterBank.differentialSwitchWorking ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Back Flush Timer Working?
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.filterBank.backwashWorking ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                20mm Command Filter
                                            </Typography>
                                            <StarRating rating={inspectionGroup.filterBank.commandFilter20} size={5} />
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Water meter verification ({'<10m'}<sup>3</sup>&nbsp;deviation)
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.filterBank.waterMeterVerification ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                        <div className='fdc aifs jcfs flx1'>
                                            <Typography className={'flx1 fs12 fw500 mb5 dcgrey'}>
                                                Service Required
                                            </Typography>
                                            <Typography className={'flx1 fs126 fw400'}>
                                                {inspectionGroup.filterBank.serviceRequired ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </WidePanelDetials>
                        </SeparatedAccordion>
                    ))
                }
                {
                    session.employee.IsPumpingStationAdmin &&
                    <ConfirmDialog 
                        open={showConfirm}
                        title={'Delete Pumping Station Inspection Audit'}
                        message={`Are you sure that you want to delete the autid on the ${moment.utc(pumpingStationInspectionAudit.createdOn).format('DD/MM/YYYY')}`}
                        onClose={this.onConfirmDeleteClose}
                        transition={Transitions.Up}
                    />
                }
            </>
            
        );
    }
}

export default PumpingStationExpandableInspectionAudit;
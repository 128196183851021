import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import { createSelector } from 'reselect';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import TransformerFilterButton from '../../button/TransformerFilterButton';
import { TransformerMeterTypeUpperCase } from '../../../../@types/model/transformer/transformer';

interface ITransformerFilterSelectorProps {
    id ?: string;
    fullWidth ?: boolean;
    required ?: boolean;

    label : string;
    disabled ?: boolean;

    epNumber : string | null;
    epGroup : string | null;
    epArea : string | null;
    type : string | null;
    meterType : TransformerMeterTypeUpperCase | null;

    onChange ?: (epNumber : string | null, epGroup : string | null, epArea : string | null, type : string | null, meterType : TransformerMeterTypeUpperCase | null) => void;
}

interface ITransformerFilterSelectorState {
    open : boolean;
}

export default class TransformerFilterSelector extends React.PureComponent<ITransformerFilterSelectorProps, ITransformerFilterSelectorState> {
    constructor(props : ITransformerFilterSelectorProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private readonly onFilterIconButtonClick = () => {
        this.toggleOpen(!this.state.open);
    };

    private readonly onFilterFocusClick = () => {
        this.toggleOpen(true);
    };

    private readonly onClearClick = () => {
        this.onChange(null, null, null, null, null);
    };

    public readonly onChange = (epNumber : string | null, epGroup : string | null, epArea : string | null, type : string | null, meterType : TransformerMeterTypeUpperCase | null) => {
        this.setState({
            open: false,
        });

        if (!this.props.onChange) return;
        this.props.onChange(epNumber, epGroup, epArea, type, meterType);

    };

    private readonly toggleOpen = (open : boolean) => {
        this.setState({
            open,
        });
    };

    private readonly getEpNumber = (props : ITransformerFilterSelectorProps) => props.epNumber;
    private readonly getEpGroup = (props : ITransformerFilterSelectorProps) => props.epGroup;
    private readonly getEpArea = (props : ITransformerFilterSelectorProps) => props.epArea;
    private readonly getType = (props : ITransformerFilterSelectorProps) => props.type;
    private readonly getMeterType = (props : ITransformerFilterSelectorProps) => props.meterType;
    private readonly getLabel = createSelector(
        [this.getEpNumber, this.getEpGroup, this.getEpArea, this.getType, this.getMeterType],
        (epNumber, epGroup, epArea, type, meterType) => {
            const result = [];

            if (epNumber) result.push(epNumber);
            if (epGroup) result.push(epGroup);
            if (epArea) result.push(epArea);
            if (type) result.push(type);
            if (meterType) result.push(meterType);

            return result.join(' - ');
        },
    );

    public readonly render = () => {
        const { required, fullWidth, label, id, epNumber, epGroup, epArea, type, meterType, disabled } = this.props;
        const { open } = this.state;

        const value = this.getLabel(this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !epNumber} required={required}>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <Input
                    onFocus={this.onFilterFocusClick}
                    onChange={undefined}
                    value={value}
                    readOnly
                    endAdornment={
                        <InputAdornment position='end'>
                            {
                                (epNumber || epGroup || epArea || type || meterType) &&
                                <IconButton
                                    aria-label='clear filter'
                                    onClick={this.onClearClick}
                                >
                                    <CloseIcon className='cr' />
                                </IconButton>
                            }
                            <TransformerFilterButton
                                color='primary'
                                id='Transformers_filter'
                                open={open}
                                onClick={this.onFilterIconButtonClick}
                                onChange={this.onChange}
                                epNumber={epNumber}
                                epArea={epArea}
                                epGroup={epGroup}
                                type={type}
                                meterType={meterType}
                                disabled={disabled}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
        );
    };
}

import React from 'react';
import weatherFunctions from '../../store/weather/functions';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { IAuthState, IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { Transitions } from '../customComponents/animations/Transitions';
import WeatherInfo from './Info';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import { withRouter, RouteComponentProps } from 'react-router';
import CustomMapBox, { IMapMarker } from '../customComponents/map/CustomMapBox';
import { WEATHER_MAP_IMAGES } from '../../appConstants';
import { IWeatherStation } from '../../@types/model/weather/weatherStation';

interface IWeatherMapProps extends RouteComponentProps<{
    Code : string;
}, Record<string, unknown>, Record<string, unknown>> {
    auth : IAuthState;
    weathers : Array<IWeatherStation>;
    isLoadingWeathers : boolean;
}

interface IWeatherMapState {
    selectedWeather ?: IWeatherStation;
}

class WeatherMap extends React.Component<IWeatherMapProps, IWeatherMapState> {
    private readonly mapZoom = 8;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];

    constructor(props : IWeatherMapProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        weatherFunctions.getList(this.setSelectedWeather);
    };

    private setSelectedWeather : undefined | (() => void) = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const weatherId = urlParams.get('Code') as string;
        this.onMarkerClick(weatherId);

        this.setSelectedWeather = undefined;
    };

    private readonly selectMarker = (weather : IWeatherStation) => {
        this.setState({
            selectedWeather: weather,
        });
    };

    private readonly onMarkerClick = (code : string) => {
        const weather = this.props.weathers.slice().find(n => n.WSNumber === code);

        if (weather) {        
            this.selectMarker(weather);
        }
    };

    private readonly onMoreInfo = () => {
        weatherFunctions.setSelected(Object.assign({}, this.state.selectedWeather));
    };

    private readonly onCloseDrawer = () => {
        this.setState({
            selectedWeather: undefined,
        });
    };

    public readonly getData = (state : IWeatherMapState, props : IWeatherMapProps) => props.weathers;
    public readonly getFilteredData = createSelector(
        [this.getData],
        (weathers) => {
            return weathers.slice().filter(n => n.Geom && (n.IsActive === undefined || n.IsActive));
        },
    );

    public readonly getMarkers = createSelector(
        [this.getFilteredData],
        (weathers) => {
            return weathers.map(n => ({
                coordinates: [n.Geom?.longitude ?? 0, n.Geom?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: n.WSNumber,
            } as IMapMarker));
        }
    );

    public readonly render = () => {
        const { isLoadingWeathers } = this.props;
        const { selectedWeather } = this.state;
        const weathers = this.getFilteredData(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (<div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Toolbar>
                    <Typography variant='h5' color='inherit'>
                        WEATHER STATIONS
                    </Typography>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className={'fdr pt5'}>
                            <span style={{ paddingRight: '15px' }}><b>Total: </b>{weathers.length}</span>
                        </div>
                    </div>
                </Toolbar>
                <div className='mnh4'>
                    { isLoadingWeathers && <LinearProgress />}
                </div>
                <Paper className={'flx1 fdr oxh'}>
                    {
                        weathers.length > 0 &&
                        <CustomMapBox
                            id='weather_map'
                            enableAreas
                            geoLocationControl
                            navigationControl
                            showScale
                            layerControls
                            center={this.mapCenter}
                            zoom={this.mapZoom}
                            images={WEATHER_MAP_IMAGES}
                            onMarkerClick={this.onMarkerClick}
                            markers={markers}
                            selectedMarker={selectedWeather?.WSNumber}
                        />
                    }
                </Paper>
            </div>
            <Drawer open={!!selectedWeather} anchor='right' variant='persistent'>
                <div className={'w300 flx1 fdc'}>
                    <AppBar className='posr' position='static'>
                        <Toolbar variant='dense'>
                            <div className='h60' />
                            <IconButton color={'inherit'} onClick={this.onMoreInfo}><Icon color={'inherit'}>more_vert</Icon></IconButton>
                            <Typography color='inherit' variant='h6' className={'flx1 ml10'} noWrap={true}>
                                {!!selectedWeather && selectedWeather.WSNumber}
                            </Typography>
                            <IconButton color={'inherit'} onClick={this.onCloseDrawer}><Icon color={'inherit'}>close</Icon></IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        selectedWeather &&
                        <div className='fdc flx1'>
                            <div className='fdr flx1'>
                                <div className={'fdc flx1'}>
                                    <Typography className={'fdr bcDarkBlue fw700 cw p10'} variant='h6'>
                                        SUMMARY
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            WS NUMBER
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            NAME
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedWeather.WSNumber}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedWeather.WSName}
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            DIVISION
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            TYPE
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedWeather.Division}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedWeather.WSType}
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            INS. DATE
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            SERIAL NUM.
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedWeather.InstallationDate}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedWeather.SerialNumber}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Drawer>
            <WeatherInfo fullScreen transition={Transitions.Up} />
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        weathers: state.weather.weatherStations,
        weather: state.weather.weatherStation,
        isLoadingWeathers: state.weather.loading,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(WeatherMap));

import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { CircularProgress, Tab, Tabs } from '@material-ui/core';
import TabViews from '../../customComponents/tab/TabViews';
import EditBoreholePumpInfo from '../edit/EditPump';
import EditBoreholeMotorSizeInfo from '../edit/EditMotor';
import EditBoreholeRisingMainInfo from '../edit/EditRisingMain';
import EditBoreholeCableInfo from '../edit/EditCable';
import EditBoreholeStarterInfo from '../edit/EditStarter';
import BoreholeFunctions from '../../../store/borehole/functions';
import EditBoreholeInstallationInfo from '../edit/EditInstallation';

interface IEditBoreholeInstallationInfoDialogProps {
    borehole : IBorehole;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditBoreholeInstallationInfoDialogState {
    open : boolean;
    borehole ?: IBorehole;

    tab : number;
}

export default class EditBoreholeInstallationInfoDialog extends React.Component<IEditBoreholeInstallationInfoDialogProps, IEditBoreholeInstallationInfoDialogState> {
    constructor(props : IEditBoreholeInstallationInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            tab: 0,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeInstallationInfoDialogProps, prevState : IEditBoreholeInstallationInfoDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                borehole: undefined,
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                borehole: {
                    ...this.props.borehole,
                },
            });
        }

    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tab: value,
        });
    };


    private readonly save = async () => {
        const { borehole } = this.state;
        if (!borehole) return;
        
        await BoreholeFunctions.saveBorehole(borehole);
        this.setState({
            open: false,
        });
    };

    private readonly onSubmit = (event : React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onChange = (borehole : IBorehole) => {
        this.setState({
            borehole: {
                ...borehole,
            },
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, tab, borehole } = this.state;
        return (
            <>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-ins-edit-dialog-title'
                    aria-describedby='borehole-ins-edit-description'
                >
                    <AppBar className='posr' position='static'>
                        <Toolbar variant='dense'>
                            <Typography variant='h5' color='inherit'>
                                Edit Borehole Installation Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                        <Tabs value={tab} onChange={this.onTabChange} aria-label='Edit Tabs'>
                            <Tab label='BOREHOLE INSTALLATION' value={0} />
                            <Tab label='PUMP' value={1} />
                            <Tab label='MOTOR' value={2} />
                            <Tab label='RISING MAIN' value={3} />
                            <Tab label='CABLE' value={4} />
                            <Tab label='STARTER' value={5} />
                        </Tabs>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc hfill mnh350'>
                            {
                                borehole &&
                                !isLoading &&
                                <TabViews index={tab}>
                                    <EditBoreholeInstallationInfo borehole={borehole} onChange={this.onChange} />
                                    <EditBoreholePumpInfo borehole={borehole} onChange={this.onChange} />
                                    <EditBoreholeMotorSizeInfo borehole={borehole} onChange={this.onChange} />
                                    <EditBoreholeRisingMainInfo borehole={borehole} onChange={this.onChange} />
                                    <EditBoreholeCableInfo borehole={borehole} onChange={this.onChange} />
                                    <EditBoreholeStarterInfo borehole={borehole} onChange={this.onChange} />
                                </TabViews>
                            }
                            {
                                isLoading &&
                                <div className='fdc flx1 aic jcc'>
                                    <CircularProgress />
                                </div>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </>);
    };
}

import React from 'react';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import navFunctions from '../../store/nav/functions';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import appFunctionsService from '../../services/appFunctionsService';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MapIcon from '@material-ui/icons/Map';
import DamHelper, { IDam } from '../../@types/model/dam/dam';
import DamFunctions from '../../store/dam/functions';
import DamNewButton from './button/NewButton';
import { Icon } from '@material-ui/core';
import { Transitions } from '../customComponents/animations/Transitions';
import DamInfo from './Info';
import DamFilterSelector from '../customComponents/selector/dam/FilterSelector';
import { CSVLinkProps } from '../../@types/csv';
import { IUserSession } from '../../@types/employee';

interface IDamListProps {
    dams : Array<IDam>;
    isLoadingDams : boolean;
    session ?: IUserSession | null;
}

interface IDamListState {
    name : string;
    type : string;
    code : string;
    division : string;
    managementArea : string;
    inspectionDue : boolean | null;
}

class DamList extends React.Component<IDamListProps, IDamListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : IDamListProps) {
        super(props);

        this.state = {
            name: '',
            type: '',
            code: '',
            division: '',
            managementArea: '',
            inspectionDue: null,
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        DamFunctions.getList();
    };

    private readonly getData = (props : IDamListProps) => props.dams;
    private readonly getCode = (props : IDamListProps, state : IDamListState) => state.code;
    private readonly getName = (props : IDamListProps, state : IDamListState) => state.name;
    private readonly getType = (props : IDamListProps, state : IDamListState) => state.type;
    private readonly getDivision = (props : IDamListProps, state : IDamListState) => state.division;
    private readonly getManagementArea = (props : IDamListProps, state : IDamListState) => state.managementArea;
    private readonly getInspectionDue = (props : IDamListProps, state : IDamListState) => state.inspectionDue;

    public readonly getFilteredData = createSelector(
        [
            this.getData,
            this.getCode,
            this.getName,
            this.getType,
            this.getDivision,
            this.getManagementArea,
            this.getInspectionDue,
        ],
        (
            dams,
            code,
            name,
            type,
            division,
            managementArea,
            inspectionDue,
        ) => {

            const currentYear = moment.utc().year();

            return lodash
                .chain(dams)
                .filter(n => inspectionDue === null || !!inspectionDue || (!!n.nextInspectionDate && moment.utc(n.nextInspectionDate).year() > currentYear))
                .filter(n => inspectionDue === null || !inspectionDue || !!n.nextInspectionDate && moment.utc(n.nextInspectionDate).year() <= currentYear)
                .filter(n => n.code.toLowerCase().includes(code.toLowerCase()))
                .filter(n => n.name.toLowerCase().includes(name.toLowerCase()))
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                .filter(n => ((DamHelper.DamType[(n.damType)] ?? '').toLocaleLowerCase()).includes(type.toLowerCase()))
                .filter(n => (n.division ?? '').toLowerCase().includes(division.toLowerCase()))
                .filter(n => (n.managementArea ?? '').toLowerCase().includes(managementArea.toLowerCase()))
                .value();
        },
    );

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (dams) => {
            return DamHelper.mapToCsv(dams);
        },
    );

    public readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const dam = this.props.dams.slice().find(x => x.code === event.currentTarget.value);

        DamFunctions.setSelected(dam);
    };

    public readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const dam = this.props.dams.slice().find(x => x.code === event.currentTarget.value);

        if (dam) {
            navFunctions.navDamMap(dam.code);
        }
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    private readonly onFilterChange = (
        code : string | null,
        name : string | null,
        type : string | null,
        division : string | null,
        managementArea : string | null,
        inspectionDue : boolean | null,
    ) => {
        this.setState({
            name: name ?? '',
            code: code ?? '',
            type: type ?? '',
            division: division ?? '',
            managementArea: managementArea ?? '',
            inspectionDue,
        });
    };

    public readonly render = () => {
        const { isLoadingDams, session } = this.props;
        const { code, name, type, division, managementArea, inspectionDue } = this.state;
        const dams = this.getFilteredData(this.props, this.state);
        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            DAMS
                        </Typography>
                        <span className={'flx4'} />
                        <div className={'flx1'}>
                            <DamFilterSelector
                                id='dam_filter'
                                onChange={this.onFilterChange}
                                name={name}
                                code={code}
                                type={type}
                                division={division}
                                managementArea={managementArea}
                                inspectionDue={inspectionDue}
                                label='Filter By...'
                                disabled={isLoadingDams}
                            />    
                        </div>
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr '}>
                                <span className='pr15'><b>Total: </b>{dams.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IDam>
                            id='DamTable'
                            data={dams}
                            isLoading={isLoadingDams}
                            rowsPerPage={100}
                            onExportCsvClick={!session?.employee.IsDamExport ? undefined : this.onCSVClick}
                            columns={[{
                                header: '',
                                width: 100,
                                paddingRight: 4,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton color='primary' value={row.code} onClick={this.onInfoClick}>
                                                    <InfoOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton color='primary' value={row.code} onClick={this.onMapClick} disabled={!row.geo}>
                                                    <MapIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                paddingRight: 4,
                            },
                            {
                                header: 'Name',
                                field: 'name',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                renderCell: n => <span>{DamHelper.DamType[n.damType] }</span>,
                                header: 'Dam Type',
                                field: 'damType',
                                width: 140,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                renderCell: n =>  
                                    <span className={'jcc'}>
                                        {n.measurementStick ? <Icon className={'cp bct'}>check_circle</Icon> : ''}
                                    </span>,
                                header: 'Measurement Stick',
                                field: 'measurementStick',
                                width: 250,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                renderCell: n =>  
                                    <span className={'jcc'}>
                                        {n.surveyed ? <Icon className={'cp bct'}>check_circle</Icon> : ''}
                                    </span>,
                                header: 'Surveyed',
                                field: 'surveyed',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Last Inspected',
                                field: 'lastInspectionDate',
                                width: 150,
                                enableSort: true,
                                renderCell: n => <span>{(!n.lastInspectionDate ? '' : appFunctionsService.formatDateTimeToDateOnly(n.lastInspectionDate))}</span>,
                            },
                            {
                                header: 'Next Inspection',
                                field: 'nextInspectionDate',
                                width: 150,
                                enableSort: true,
                                renderCell: n => <span>{(!n.nextInspectionDate ? '' : appFunctionsService.formatDateTimeToDateOnly(n.nextInspectionDate))}</span>,
                            },
                            {
                                header: 'Last Quarterly Inspection',
                                field: 'lastDSIDate',
                                width: 150,
                                enableSort: true,
                                renderCell: n => <span>{!n.lastDSIDate ? '' : appFunctionsService.formatDateTimeToDateOnly(n.lastDSIDate)}</span>,
                            },
                            {
                                header: 'Survey Method',
                                field: 'surveyType',
                                width: 180,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                renderCell: n => <span>{n.constructionType === null ? '' : DamHelper.DamConstructionType[n.constructionType] }</span>,
                                header: 'Construction Type',
                                field: 'constructionType',
                                width: 140,
                                enableSort: true,
                            },
                            {
                                header: 'Catagory',
                                field: 'riskCategory',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Comments',
                                field: 'comment',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'DSO No',
                                field: 'warmsNo',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'WARMS No',
                                field: 'warmsNo',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Q Drain Ar',
                                field: 'quaternaryDrainageArea',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'W Man Are',
                                field: 'waterManagementArea',
                                width: 150,
                                enableSort: true,
                                align: 'right',
                                enableFilter: true,
                            },
                            {
                                header: 'Town Near',
                                field: 'nearestTown',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Dist Town',
                                field: 'distanceFromTown',
                                width: 150,
                                enableSort: true,
                                align: 'right',
                                enableFilter: true,
                            },
                            {
                                header: 'Farm House',
                                field: 'parentFarm',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Farm Ptn',
                                field: 'farmPortion',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'D Munic',
                                field: 'municipalDistrict',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'P Code',
                                field: 'provinceCode',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Region Code',
                                field: 'regionCode',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'R watercou',
                                field: 'riverOrWaterCourse',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                renderCell: n => <span>{n.wallType == null ? '' : DamHelper.DamWallType[n.wallType]}</span>,
                                header: 'Wall Type',
                                field: 'wallType',
                                width: 150,
                                enableSort: true,
                            },
                            {
                                header: 'Wall Height',
                                field: 'wallHeight',
                                width: 150,
                                enableSort: true,
                                align: 'right',
                                enableFilter: true,
                            },
                            {
                                header: 'Crest Lgth',
                                field: 'crestLength',
                                width: 150,
                                enableSort: true,
                                align: 'right',
                                enableFilter: true,
                            },
                            {
                                header: 'Cap1000m',
                                field: 'capacity',
                                width: 150,
                                enableSort: true,
                                align: 'right',
                                enableFilter: true,
                            },
                            {
                                header: 'S are Ha',
                                field: 'surfaceArea',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                align: 'right',
                            },
                            {
                                header: 'Catch Km²',
                                field: 'catchmentArea',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                align: 'right',
                            },
                            {
                                renderCell: n => <span>{n.purpose == null ? '' : n.purpose}</span>,
                                header: 'Purpose',
                                field: 'purpose',
                                width: 550,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Owner',
                                field: 'ownerName',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Designer',
                                field: 'designer',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Contractor',
                                field: 'contractor',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                            },
                            {
                                header: 'Reg Date',
                                field: 'registrationDate',
                                width: 150,
                                enableSort: true,
                                renderCell: n => <span>{!n.registrationDate ? '' : appFunctionsService.formatDateTimeToDateOnly(n.registrationDate)}</span>,
                            },
                            {
                                renderCell: n => <span>{n.size == null ? '' : DamHelper.DamSize[n.size]}</span>,
                                header: 'Size',
                                field: 'size',
                                width: 150,
                                enableSort: true,
                            },
                            {
                                renderCell: n => <span>{n.hazardPotential == null ? '' : DamHelper.HazardPotential[n.hazardPotential]}</span>,
                                header: 'Haz pot',
                                field: 'hazardPotential',
                                width: 150,
                                enableSort: true,
                            },
                            {
                                header: 'Class Date',
                                field: 'classificationDate',
                                width: 150,
                                enableSort: true,
                                renderCell: n => <span>{!n.classificationDate ? '' : appFunctionsService.formatDateTimeToDateOnly(n.classificationDate)}</span>,
                            },
                            {
                                header: 'DSI No',
                                field: 'lastDSINumber',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                align: 'right',
                            },
                            {
                                header: 'Pers Full',
                                field: 'percentageFull',
                                width: 150,
                                enableSort: true,
                                align: 'right',
                                renderCell: n => <span>{!n.percentageFull ? '' : n.percentageFull}</span>,
                            }]}
                        />
                    </Card>
                    {
                        session?.employee.IsDamAdmin &&
                        <DamNewButton />
                    }
                    <DamInfo fullScreen transition={Transitions.Up} />
                    {
                        session?.employee.IsDamExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'code',
                                    label: 'Code',
                                },
                                {
                                    key: 'name',
                                    label: 'Name',
                                },
                                {
                                    key: 'type',
                                    label: 'Type',
                                },
                                {
                                    key: 'measurementStick',
                                    label: 'Measurement Stick',
                                },
                                {
                                    key: 'surveyed',
                                    label: 'Surveyed',
                                },
                                {
                                    key: 'surveyType',
                                    label: 'Survey Type',
                                },
                                {
                                    key: 'riskCategory',
                                    label: 'Risk Category',
                                },
                                {
                                    key: 'quaternaryDrainageArea',
                                    label: 'Quaternary Drainage Area',
                                },
                                {
                                    key: 'waterManagementArea',
                                    label: 'Water Management Area',
                                },
                                {
                                    key: 'nearestTown',
                                    label: 'Nearest Town',
                                },
                                {
                                    key: 'distanceFromTown',
                                    label: 'Distance From Town',
                                },
                                {
                                    key: 'parentFarm',
                                    label: 'Parent Farm',
                                },
                                {
                                    key: 'farmPortion',
                                    label: 'Farm Portion',
                                },
                                {
                                    key: 'municipalDistrict',
                                    label: 'Municipal District',
                                },
                                {
                                    key: 'provinceCode',
                                    label: 'Province Code',
                                },
                                {
                                    key: 'regionCode',
                                    label: 'Region Code',
                                },
                                {
                                    key: 'riverOrWaterCourse',
                                    label: 'River Or Water Course',
                                },
                                {
                                    key: 'wallType',
                                    label: 'Wall Type',
                                },
                                {
                                    key: 'wallHeight',
                                    label: 'Wall Height',
                                },
                                {
                                    key: 'crestLength',
                                    label: 'Crest Length',
                                },
                                {
                                    key: 'capacity',
                                    label: 'Capacity',
                                },
                                {
                                    key: 'surfaceArea',
                                    label: 'Surface Area',
                                },
                                {
                                    key: 'catchmentArea',
                                    label: 'Catchment Area',
                                },
                                {
                                    key: 'ownerName',
                                    label: 'Owner Name',
                                },
                                {
                                    key: 'Purpose',
                                    label: 'purpose',
                                },
                                {
                                    key: 'designer',
                                    label: 'Designer',
                                },
                                {
                                    key: 'contractor',
                                    label: 'Contractor',
                                },
                                {
                                    key: 'registrationDate',
                                    label: 'Registration Date',
                                },
                                {
                                    key: 'hazardPotential',
                                    label: 'Hazard Potential',
                                },
                                {
                                    key: 'classificationDate',
                                    label: 'Classification Date',
                                },
                                {
                                    key: 'lastDSIDate',
                                    label: 'Last DSI Date',
                                },
                                {
                                    key: 'lastDSINumber',
                                    label: 'Last DSI Number',
                                },
                                {
                                    key: 'percentageFull',
                                    label: 'Percentage Full',
                                },
                                {
                                    key: 'completionDateRaised',
                                    label: 'Completion Date Raised',
                                },
                                {
                                    key: 'constructionEndDate',
                                    label: 'Construction End Date',
                                },
                                {
                                    key: 'damFeed',
                                    label: 'DamFeed',
                                },
                                {
                                    key: 'division',
                                    label: 'Division',
                                },
                                {
                                    key: 'latitude',
                                    label: 'Latitude',
                                },
                                {
                                    key: 'longitude',
                                    label: 'Longitude',
                                },
                                {
                                    key: 'managementArea',
                                    label: 'Management Area',
                                },
                                {
                                    key: 'divisionDescriptionAccpac',
                                    label: 'Accpac Description',
                                },
                                {
                                    key: 'divisionNumber',
                                    label: 'Division Number',
                                },
                                {
                                    key: 'sector',
                                    label: 'Sector',
                                },
                                {
                                    key: 'spillwayType',
                                    label: 'Spillway Type',
                                },
                                {
                                    key: 'targetDate',
                                    label: 'Target Date',
                                },
                                {
                                    key: 'warmsId',
                                    label: 'Warms Id',
                                },
                                {
                                    key: 'createdOn',
                                    label: 'Created On',
                                },
                                {
                                    key: 'updatedOn',
                                    label: 'Updated On',
                                },
                                
                            ]}
                            filename={`${csvName}_Dams.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        dams: state.dam.dams,
        isLoadingDams: state.dam.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(DamList);
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IMeterReadingsView } from '../../../../@types/model/transformer/transformerReading';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FirebaseStorageImage from '../../../customComponents/image/FirebaseStorageImage';
import CarouselImageViewer from '../../../customComponents/image/CarouselViewer';
import TransformerMeterReadingProblem from '../../../customComponents/TransformerMeterReadingProblem';
import Divider from '@material-ui/core/Divider';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import DialogActions from '@material-ui/core/DialogActions';
import DataFunctions from '../../../../store/data/functions';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { ITransformerAttribute } from '../../../../@types/model/transformer/transformerAttribute';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../../../appConstants';
import UsageToggleButton from '../../../customComponents/button/UsageToggleButton';
import FileDropZone from '../../../customComponents/FileDropZone';
import FileUploadButton from '../../../customComponents/button/FileUploadButton';
import MultiFileUploadProgress from '../../../customComponents/file/MultiFileUploadProgress';
import TransformerFunctions from '../../../../store/transformer/functions';

interface ITransformerSPUReadingInfoDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    reading ?: IMeterReadingsView;
    enabled ?: boolean;

    onSave ?: (reading : IMeterReadingsView) => void;

    attributes : Array<ITransformerAttribute>;
}

interface ITransformerSPUReadingInfoDialogState {
    open : boolean;
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;

    meterReading : string;
    usage : number;
    conditions : Array<string>;
    other : string;
    date : moment.Moment;
    rollover : boolean;

    image : File | null;
    isUploading : boolean;
}

class TransformerSPUReadingInfoDialogClass extends React.PureComponent<ITransformerSPUReadingInfoDialogProps, ITransformerSPUReadingInfoDialogState> {
    constructor(props : ITransformerSPUReadingInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            imageSrcs: [],
            isCarouselOpen: false,
            meterReading: '',
            usage: 0,
            conditions: [],
            other: '',
            date: moment.utc().startOf('day'),
            rollover: false,
            image: null,
            isUploading: false,
        };
    }

    public componentDidUpdate(
        prevProps : Readonly<ITransformerSPUReadingInfoDialogProps>,
        prevState : Readonly<ITransformerSPUReadingInfoDialogState>,
    ) {
        if (prevProps.attributes !== this.props.attributes) {
            this.setOther();
        }

        if (prevState.conditions !== this.state.conditions) {
            this.setOther();
        }
    }

    public setOther() {
        const otherList = this.state.conditions.filter(x => this.props.attributes.findIndex(e => e.Value === x) === - 1); 

        if (otherList.length) {
            this.setState({ 
                other: otherList[0],
                conditions: this.state.conditions.filter(x => x !== otherList[0]),
            });
        }
    }

    public readonly onClick = () => {
        this.setState({
            isCarouselOpen: false,
            open: true,
            meterReading: this.props.reading?.Reading.toString() ?? '',
            usage: this.props.reading?.Usage ?? 0,
            conditions: [ ...(this.props.reading?.Conditions ?? []) ],
            date: this.props.reading ? moment.utc(this.props.reading.Date).startOf('day') : moment.utc().startOf('day'),
            rollover: !!this.props.reading?.Rollover,
            image: null,
            isUploading: false,
        });
        if (this.props.enabled) DataFunctions.getTransformerAttributes();
    };

    public readonly onClose = () => {
        this.setState({
            isCarouselOpen: false,
            open: false,
        });
    };

    private readonly onImageUrlLoaded = (url : string) => {
        this.setState({
            imageSrcs: [url],
        });
    };

    private readonly onThumbClick = () => {
        this.setState({
            isCarouselOpen: true,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    private readonly onDateChange = (date : moment.Moment | null) => {
        if (!date) return;
        this.setState({
            date: date.startOf('day').asUTC(),
        });
    };

    private readonly onOtherChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ other: event.target.value });
    };

    private readonly onMeterReadingChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.enabled) return;
        if (event.currentTarget.value.length > (this.props.reading?.NumberOfDigits ?? 0)) return;

        const lastReading = (this.props.reading?.Reading ?? 0) - (this.props.reading?.Usage ?? 0);
        this.setState({
            meterReading: event.currentTarget.value,
            usage: Number(event.currentTarget.value) - lastReading,
        });
    };

    private readonly onConditionClick = (value : string) => {
        if (!this.props.enabled) return;

        const conditions = [ ...this.state.conditions ];
        const index = conditions.indexOf(value);

        if (index < 0) {
            conditions.push(value);
        } else {
            conditions.splice(index, 1);
        }

        this.setState({
            conditions,
        });
    };

    private readonly onRolloverToggle = () => {
        if (!this.props.enabled) return;
        this.setState({
            rollover: !this.state.rollover,
        });
    };

    private readonly onSaveClick = async () => {
        if (!this.props.reading) return;
        if (!this.props.onSave) return;
        if (!this.props.enabled) return;
        if (!this.state.meterReading) return;

        const newConditions = this.state.conditions.slice();

        if (this.state.other) {
            newConditions.push(this.state.other);
        }

        this.props.onSave({
            ...this.props.reading,
            Reading: Number(this.state.meterReading),
            Usage: this.state.usage,
            Conditions: newConditions,
            Date: this.state.date.startOf('day').valueOf(),
            Rollover: this.state.rollover,
            ImageFileName: this.state.image?.name ?? this.props.reading.ImageFileName ?? null,
        });
    
        this.setState({
            isUploading: true,
        });
    };

    private readonly onFileChange = (file : File) => {
        if (!this.props.reading) return;
        const { Guid } = this.props.reading;
        this.setState({
            image: new File([file.slice()], `${Guid}-${file.name}`, {
                type: file.type,
            }),
        });
    };

    private readonly onFileUpload = (file : File) => {
        const { reading } = this.props;
        if (!reading) return null;
        
        return TransformerFunctions.uploadReadingFile(reading.EPNumber, file, reading.Guid, reading.ItemNumber);
    };

    private onDone = () => {
        this.onClose();
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen,
            reading, enabled, attributes } = this.props;
        const { open, imageSrcs, image, isCarouselOpen, meterReading,
            usage, conditions, rollover, isUploading, other } = this.state;
        return (
            <div>
                <Tooltip title='Info'>
                    <div>
                        <IconButton color='primary' onClick={this.onClick}
                            aria-label='Info'>
                            {
                                enabled ? (
                                    <Icon>edit</Icon>
                                ) : (
                                    <InfoOutlinedIcon />
                                )
                            }
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    onClose={this.onClose}
                    aria-labelledby='SPU-Meter-Reading-Info-dialog-title'
                    aria-describedby='SPU-Meter-Reading-Info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                VIEW READING | {reading?.EPNumber} | {reading?.MeterNumber}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='bcg0'>
                        {
                            !!reading &&
                            !isUploading &&
                            open &&
                            <div className='fdc flx1 ais jcc'>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 aifs pr5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='meter'
                                                label='Meter'
                                                value={reading.MeterNumber}
                                                margin='normal'
                                                required
                                                disabled
                                                className={'TextField'}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aifs pl5'>
                                        <FormControl fullWidth required error={meterReading.length > reading.NumberOfDigits}>
                                            <TextField
                                                autoComplete='off'
                                                id='meterReading'
                                                type='number'
                                                label='Meter Reading'
                                                value={meterReading}
                                                disabled={!enabled}
                                                onChange={this.onMeterReadingChange}
                                                margin='normal'
                                                helperText={`${meterReading.length}/${reading.NumberOfDigits}`}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            kWh
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 aifs pr5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='lastReading'
                                                label='Last Reading'
                                                value={reading.Reading - reading.Usage}
                                                disabled
                                                margin='normal'
                                                required
                                                className={'TextField'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            kWh
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aifs pl5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='usage'
                                                label='Usage'
                                                value={usage}
                                                margin='normal'
                                                disabled
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            kWh
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='fdr ais mt15 mb15'>
                                    <div className='fdc flx1 aifs pr5'>
                                        <FormControl fullWidth required>
                                            <KeyboardDatePicker
                                                value={this.state.date}
                                                label='Reading Date'
                                                type='spu'
                                                format={DATE_FORMAT_DEFAULT_NO_TIME}
                                                fullWidth
                                                onChange={this.onDateChange}
                                                required
                                                disabled={!enabled}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aic jcc pl5'>
                                        <UsageToggleButton
                                            text='Rollover?'
                                            value='Rollover?'
                                            selected={rollover}
                                            onClick={this.onRolloverToggle}
                                        />
                                    </div>
                                </div>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 ais pr5'>
                                        <div className='fdr mb10'>
                                            <Typography className='fs16 lh37 cg3 flx1'>
                                                Uploaded Image
                                            </Typography>
                                            {
                                                enabled &&
                                                <FileUploadButton title={'Upload Image'} accept='image/*' onFileSelected={this.onFileChange} />
                                            }
                                        </div>
                                        {
                                            !image &&
                                            (reading.ImageFileName || !enabled) &&
                                            <div className='h138 bw1 bocg3 aic jcc mximg'>
                                                {
                                                    reading.ImageFileName &&
                                                    <FirebaseStorageImage
                                                        excludeThumbnail
                                                        onClick={this.onThumbClick}
                                                        onLoaded={this.onImageUrlLoaded}
                                                        fileName={reading.ImageFileName}
                                                        path={`electricalpoint/${reading.EPNumber}/readings`}
                                                        url={reading.ImageFileUrl}
                                                    />
                                                }
                                            </div>
                                        }
                                        {
                                            enabled &&
                                            !reading.ImageFileName &&
                                            <FileDropZone onFileSelected={this.onFileChange} />
                                        }
                                        {
                                            image &&
                                            <div className='h138 bw1 bocg3 aic jcc mximg'>
                                                <img className={'mxhi'} src={URL.createObjectURL(image)} onClick={this.onClick} />
                                            </div>
                                        }
                                        
                                    </div>
                                    <div className='fdc flx1 ais pl5'>
                                    </div>
                                </div>
                                <div className='fdr ais mt15'>
                                    <div className='fdc flx1 ais pr5'>
                                        <div className='fdc mb10'>
                                            <Typography className='fs16 lh37 cg3'>
                                                Problems
                                            </Typography>
                                        </div>
                                        <div className='fdr fww cw'>
                                            {
                                                !enabled &&
                                                conditions.map((n, i) => (
                                                    <TransformerMeterReadingProblem key={`${i}_${reading.EPNumber}_problem`} text={n} />
                                                ))
                                            }
                                            {
                                                enabled &&
                                                attributes
                                                    .filter(x => x.IsCondition)
                                                    .map((n, i) => (
                                                        <TransformerMeterReadingProblem
                                                            enabled
                                                            checked={conditions.includes(n.Value)}
                                                            key={`${i}_${reading.EPNumber}_problem`}
                                                            onClick={this.onConditionClick}
                                                            text={n.Value}
                                                        />
                                                    ))
                                            }
                                        </div>
                                        {
                                            enabled && 
                                            <div className={'fdr pb10'}>
                                                <FormControl className={'flx1'}>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='other'
                                                        label='Other'
                                                        value={other}
                                                        margin='dense'
                                                        className={'TextField'}
                                                        onChange={this.onOtherChange}
                                                    />
                                                </FormControl>
                                                <div className={'flx1'}/>
                                            </div>
                                        }
                                        <Divider />
                                    </div>
                                </div>
                                {
                                    imageSrcs.length > 0 &&
                                    <CarouselImageViewer index={0} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                                }
                            </div>
                        }
                        
                        {
                            isUploading &&
                            <MultiFileUploadProgress files={image ? [image] : []} onFileUpload={this.onFileUpload} onDone={this.onDone} />
                        }
                    </DialogContent>
                    {
                        enabled &&
                        <DialogActions className='bcg0'>
                            <StadiumButton variant='contained' onClick={this.onSaveClick}>
                                SAVE
                            </StadiumButton>
                        </DialogActions>
                    }
                </Dialog>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        attributes: state.data.transformerAttributes,
    };
};

const TransformerSPUReadingInfoDialog = connect(
    mapStateToProps,
)(TransformerSPUReadingInfoDialogClass);

export default TransformerSPUReadingInfoDialog;

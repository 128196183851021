import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import appFunctionsService from '../../../services/appFunctionsService';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import BoreholeAuditEntryView from './AuditEntryView';
import { IUserSession } from '../../../@types/employee';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import boreholeFunctions from '../../../store/borehole/functions';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import Typography from '@material-ui/core/Typography';
import { IBoreholeInstallationAudit } from '../../../@types/model/borehole/boreholeInstallationAudit';

interface IBoreholeInsAuditViewProps {
    boreholeAudits : Array<IBoreholeInstallationAudit>;
    isLoading : boolean;
    session ?: IUserSession | null;
}

interface IBoreholeInsAuditViewState {
    selectedAudit ?: IBoreholeInstallationAudit;
}

class BoreholeInsAuditView extends React.Component<IBoreholeInsAuditViewProps, IBoreholeInsAuditViewState> {
    constructor(props : IBoreholeInsAuditViewProps) {
        super(props);
        this.state = {};
    }

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selectedAudit = this.props.boreholeAudits.slice().find(n => n.ref.id === event.currentTarget.value);
        this.setState({
            selectedAudit,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.state.selectedAudit) {
            boreholeFunctions.deleteBoreholeAudit(this.state.selectedAudit.ref.id);
        }
        this.setState({
            selectedAudit: undefined,
        });
    };

    public readonly render = () => {
        const { boreholeAudits, isLoading, session } = this.props;
        const { selectedAudit } = this.state;
        return (
            <div className={'fdc flx1 m10'}>
                { isLoading && <LinearProgress className={'wfill mt5'} />}
                {
                    !isLoading && boreholeAudits.length === 0 &&
                    <div>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Installation Audit Data Exists!
                        </Typography>
                    </div>
                }
                {
                    boreholeAudits.map(n => (
                        <ExpansionPanel key={n.ref.id} className={'wfill'}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <div className='fdr jcc aic flx1'>
                                    { appFunctionsService.formatDateTimeToDateOnly(n.CreatedOn) }
                                    <span className='flx1' />
                                    {
                                        session?.employee.IsBoreholeAdmin &&
                                        <Tooltip title='Delete'>
                                            <div>
                                                <IconButton size='small' value={n.ref.id} color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoading}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={'fdc flx1'}>
                                <BoreholeAuditEntryView installationAudit={n} />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))
                }
                <ConfirmDialog open={!!selectedAudit} title={!selectedAudit ? '' : `Confirm Delete ${appFunctionsService.formatDateTimeToDateOnly(selectedAudit.CreatedOn)}`}
                    message={!selectedAudit ? '' : `Delete Audit on ${appFunctionsService.formatDateTimeToDateOnly(selectedAudit.CreatedOn)}, this cannot be undone?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        boreholeAudits: state.borehole.boreholeAudits,
        isLoading: state.borehole.loadingAudits,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(BoreholeInsAuditView);

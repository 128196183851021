import React from 'react';
import { IEmployee, IPermission } from '../../../@types/employee';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../customComponents/tab/TabPanel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DataFunctions from '../../../store/data/functions';
import FormHelperText from '@material-ui/core/FormHelperText';
import appFunctionsService from '../../../services/appFunctionsService';
import EmployeeRightsView from './view/RightsView';
import EmployeeLinkDialog from './dialog/Link';
import { Transitions } from '../../customComponents/animations/Transitions';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import EmployeeAreasView from './view/AreasView';
import EmployeeDamConstructionTypesView from './view/DamTypeView';

interface IEmployeeViewProps {
    employee ?: IEmployee;
    isLoading : boolean;
}

interface IEmployeeViewState {
    currentTab : number;
    permission : IPermission;
    name : string;
    employeeNumber : string;
    email : string;

    showLink : boolean;
    showUnLink : boolean;

    areas : Array<string>;
    damConstructionType : Array<number>;
}

export default class EmployeeView extends React.PureComponent<IEmployeeViewProps, IEmployeeViewState> {
    constructor(props : IEmployeeViewProps) {
        super(props);
        this.state = {
            currentTab: 0,
            permission: {},
            name: '',
            employeeNumber: '',
            email: '',
            showLink: false,
            showUnLink: false,
            areas: [],
            damConstructionType: [],
        };
    }

    public readonly componentDidMount = () => {
        const { employee } = this.props;
        if (employee) this.setData(employee);
    };

    public readonly componentDidUpdate = (prevProps : IEmployeeViewProps) => {
        const { employee } = this.props;

        if (employee && employee !== prevProps.employee) {
            this.setData(employee);
        }
    };

    private readonly setData = (employee : IEmployee) => {
        this.setState({
            name: employee.Name,
            employeeNumber: employee.EmployeeNumber ? employee.EmployeeNumber : '',
            email: employee.Email ? employee.Email : '',
            areas: employee.Areas,
            damConstructionType: employee.DamConstructionTypes,
            permission: {
                IsBoreholeAdmin: employee.IsBoreholeAdmin,
                IsBoreholeExport: employee.IsBoreholeExport,
                IsBoreholeCreateAdmin: employee.IsBoreholeCreateAdmin,
                IsBoreholeTester: employee.IsBoreholeTester,
                IsBoreholeUser: employee.IsBoreholeUser,
                IsBoreholeYieldAdjuster: employee.IsBoreholeYieldAdjuster,
                IsBoreholeFlowMeter: employee.IsBoreholeFlowMeter,
                IsEmployeeAdmin: employee.IsEmployeeAdmin,
                IsMottechAdmin: employee.IsMottechAdmin,
                IsMottechTester: employee.IsMottechTester,
                IsMottechUser: employee.IsMottechUser,
                IsRiverAdmin: employee.IsRiverAdmin,
                IsRiverTester: employee.IsRiverTester,
                IsRiverUser: employee.IsRiverUser,
                IsTransformerAdmin: employee.IsTransformerAdmin,
                IsTransformerLpuTester: employee.IsTransformerLpuTester,
                IsTransformerSpuTester: employee.IsTransformerSpuTester,
                IsTransformerLpuTariff: employee.IsTransformerLpuTariff,
                IsTransformerSpuTariff: employee.IsTransformerSpuTariff,
                IsTransformerUser: employee.IsTransformerUser,
                IsWeatherStationAdmin: employee.IsWeatherStationAdmin,
                IsWeatherStationTester: employee.IsWeatherStationTester,
                IsWeatherStationUser: employee.IsWeatherStationUser,
                IsMasterDataAdmin: !!employee.IsMasterDataAdmin,
                IsDivisionAdmin: !!employee.IsDivisionAdmin,
                IsBoreholeMasterDataAdmin: !!employee.IsBoreholeMasterDataAdmin,
                IsTransformerBudgetAdmin: !!employee.IsTransformerBudgetAdmin,
                IsTransformerTariffAdmin: !!employee.IsTransformerTariffAdmin,
                IsTransformerMasterDataAdmin: !!employee.IsTransformerMasterDataAdmin,
                IsDamAdmin: !!employee.IsDamAdmin,
                IsDamTester: !!employee.IsDamTester,
                IsDamRegReportUser: !!employee.IsDamRegReportUser,
                IsDamSafetyReportUser: !!employee.IsDamSafetyReportUser,
                IsDamUser: !!employee.IsDamUser,
                IsPumpingStationAdmin: !!employee.IsPumpingStationAdmin,
                IsPumpingStationCreateAdmin: !!employee.IsPumpingStationCreateAdmin,
                IsPumpingStationTester: !!employee.IsPumpingStationTester,
                IsPumpingStationUser: !!employee.IsPumpingStationUser,
                IsTransformerExport: employee.IsTransformerExport ?? false,
                IsWeatherExport: employee.IsWeatherExport ?? false,
                IsRiverExport: employee.IsRiverExport ?? false,
                IsMottechExport: employee.IsMottechExport ?? false,
                IsDamExport: employee.IsDamExport ?? false,
                IsPumpingStationExport: employee.IsPumpingStationExport ?? false,
                IsCompanyAdmin: employee.IsCompanyAdmin ?? false,
                IsTransformerMunicipalityTester: employee.IsTransformerMunicipalityTester ?? false,
                IsTransformerPrepaidTester: employee.IsTransformerPrepaidTester ?? false,
                IsMunicipalityInvoice: employee.IsMunicipalityInvoice ?? false,
                IsSPSInvoice: employee.IsSPSInvoice ?? false,
                IsFlowmeterAdmin: employee.IsFlowmeterAdmin ?? false,
                IsFlowmeterCreateAdmin: employee.IsFlowmeterCreateAdmin ?? false,
                IsFlowmeterExport: employee.IsFlowmeterExport ?? false,
                IsFlowmeterTester: employee.IsFlowmeterTester ?? false,
                IsFlowmeterUser: employee.IsFlowmeterUser ?? false,
                IsFillingPointAdmin: employee.IsFillingPointAdmin ?? false,
                IsFillingPointUser: employee.IsFillingPointUser ?? false,
                IsFillingPointTester: employee.IsFillingPointTester ?? false,
                IsRainMeterUser: employee.IsRainMeterUser ?? false,
                IsRainMeterMeasurementUser: employee.IsRainMeterMeasurementUser ?? false,
                IsRainMeterAdmin: employee.IsRainMeterAdmin ?? false,
            },
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const { name, areas, damConstructionType, permission } = this.state;
        DataFunctions.saveEmployee(name, areas, damConstructionType, permission);
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            currentTab: value,
        });
    };

    private readonly onLinkClick = () => {
        this.setState({
            showLink: true,
        });
    };

    private readonly onUnLinkClick = () => {
        this.setState({
            showUnLink: true,
        });
    };

    private readonly onPermissionsChange = (permissions : IPermission) => {
        this.setState({
            permission: { ...permissions },
        });
    };

    private readonly onLinkClose = () => {
        this.setState({
            showLink: false,
        });
    };

    private readonly onUnlink = (result : boolean) => {
        if (result && this.props.employee?.Email) {
            DataFunctions.unlinkWithEmployeeNumber(this.props.employee.Email);
        }
        this.setState({
            showUnLink: false,
        });
    };

    private readonly onAreasChange = (areas : Array<string>) => {
        this.setState({
            areas: [...areas.sort()],
        });
    };

    private readonly onDamConstructionTypesChange = (damConstructionType : Array<number>) => {
        this.setState({
            damConstructionType: [...damConstructionType.sort()],
        });
    };

    public readonly render = () => {
        const { employee, isLoading } = this.props;
        const { currentTab, name, email, employeeNumber, permission, showLink, showUnLink,
            areas, damConstructionType } = this.state;
        return (
            <div className='fdc flx1 m10 hfill'>
                <Paper className='fdc flx1 p10 hfill'>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 p10 hfill'>
                        <Toolbar color='secondary'>
                            <div className='fdr flx1'>
                                <Typography variant='h5' color='inherit'>
                                    Employee {employee?.Name}
                                </Typography>
                                <span className='flx1' />
                                {
                                    !!employee && employee.Email && !employee.EmployeeNumber &&
                                    <Tooltip title='Link'>
                                        <div>
                                            <IconButton color='primary' type='button' onClick={this.onLinkClick} aria-label='Link'>
                                                <LinkIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                }
                                {
                                    !!employee && employee.Email && !!employee.EmployeeNumber &&
                                    <Tooltip title='UnLink'>
                                        <div>
                                            <IconButton color='primary' type='button' onClick={this.onUnLinkClick} aria-label='UnLink'>
                                                <LinkOffIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                }
                                <Tooltip title='Save'>
                                    <div>
                                        <IconButton color='primary' disabled={!employee || isLoading} type='submit' aria-label='Edit'>
                                            <Icon>save</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                        </Toolbar>
                        <Divider />
                        <AppBar position='static'>
                            <Tabs value={currentTab} onChange={this.onTabChange} aria-label='user tabs'>
                                <Tab label='Info' />
                                <Tab label='Roles' />
                                <Tab label='Areas' />
                                <Tab label='Dam Construction Types' />
                            </Tabs>
                        </AppBar>
                        <div className='fdc flx1'>
                            <TabPanel value={currentTab} index={0}>
                                <div className='fdc flx1 oya'>
                                    <div className='aic p5 mb10'>
                                        <div className={'aic flx1 p5 mb10 pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='name'
                                                    label='Name'
                                                    value={name ? name : ''}
                                                    required
                                                    error={!name}
                                                    onChange={this.onNameChange}
                                                    margin='normal'
                                                    className={'TextField'}
                                                />
                                                {
                                                    !name &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='fdr'>
                                        {
                                            employeeNumber &&
                                            <div className={'aic flx1 p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='employeeNumber'
                                                        label='Employee Number'
                                                        value={employeeNumber ? employeeNumber : ''}
                                                        required
                                                        error={!employeeNumber}
                                                        margin='normal'
                                                        className={'TextField'}
                                                        disabled
                                                    />
                                                    {
                                                        !employeeNumber &&
                                                        <FormHelperText error>Required</FormHelperText>
                                                    }
                                                </FormControl>
                                            </div>
                                        }
                                        {
                                            email &&
                                            <div className={'aic flx1 p5 mb10 pr20'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='email'
                                                        label='Email Address'
                                                        value={email ? email : ''}
                                                        required
                                                        error={!email}
                                                        margin='normal'
                                                        className={'TextField'}
                                                        disabled
                                                    />
                                                    {
                                                        !email &&
                                                        <FormHelperText error>Required</FormHelperText>
                                                    }
                                                </FormControl>
                                            </div>
                                        }
                                    </div>
                                    <div className='fdr'>
                                        <div className={'flx1 aic p5 mb10 pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='CreatedOn'
                                                    label='Created On'
                                                    value={employee?.CreatedOn ? appFunctionsService.formatDateTime(employee.CreatedOn) : ''}
                                                    margin='normal'
                                                    className={'TextField'}
                                                    disabled
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 aic p5 mb10 pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='LastSignIn'
                                                    label='Last Sign In'
                                                    value={employee?.LastSignIn ? appFunctionsService.formatDateTime(employee.LastSignIn) : ''}
                                                    margin='normal'
                                                    className={'TextField'}
                                                    disabled
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={currentTab} index={1}>
                                <div className='fdc flx1 oya'>
                                    <EmployeeRightsView permissions={permission} onChange={this.onPermissionsChange} />
                                </div>
                            </TabPanel>
                            <TabPanel value={currentTab} index={2}>
                                <div className='fdc flx1 oya'>
                                    <EmployeeAreasView value={areas} onChange={this.onAreasChange} />
                                </div>
                            </TabPanel>
                            <TabPanel value={currentTab} index={3}>
                                <div className='fdc flx1 oya'>
                                    <EmployeeDamConstructionTypesView value={damConstructionType} onChange={this.onDamConstructionTypesChange} />
                                </div>
                            </TabPanel>
                        </div>
                    </form>
                    {
                        employee &&
                        <EmployeeLinkDialog employee={employee} open={showLink} transition={Transitions.Up} onClose={this.onLinkClose} maxWidth='sm' isLoading={isLoading}/>
                    }
                    <ConfirmDialog open={!!showUnLink} title='Unlink Employee'
                        message={!employee ? '' : `Unlink Employee from Employee Number ${employee.EmployeeNumber}?`}
                        onClose={this.onUnlink} transition={Transitions.Up}/>
                </Paper>
            </div>
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { IDam, IDamVolume } from '../../../@types/model/dam/dam';
import DamFunctions from '../../../store/dam/functions';
import MaterialTable from '../../customComponents/materialTable/Table';
import IndexTextInput from '../../customComponents/input/IndexTextInput';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@material-ui/icons';

interface FormValue extends Omit<IDamVolume, 'number'> { number : number; id : string }

interface IEditDamPlateDialogProps {
    dam : IDam;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditDamPlateDialogState {
    open : boolean;
    volumes : Array<FormValue>;
}

export default class EditDamPlateDialog extends React.Component<IEditDamPlateDialogProps, IEditDamPlateDialogState> {
    constructor(props : IEditDamPlateDialogProps) {
        super(props);
        this.state = {
            open: false,
            volumes: [],
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditDamPlateDialogProps, prevState : IEditDamPlateDialogState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                volumes: this.props.dam.damVolumes.sort((a, b) => a.number === null || b.number === null ? (b.plate - a.plate) : (a.number - b.number)).map((x, i) => ({
                    ...x,
                    percentage: Number((x.percentage * 100).toFixed(2)),
                    number: x.number ?? (i + 1),
                    id: i.toString(),
                })),
            });
        }
    };

    private readonly save = async () => {

        const dam = {
            ...this.props.dam,
        };

        dam.damVolumes = this.state.volumes.map(n => ({
            belowSpillway: n.belowSpillway,
            elevation: n.elevation,
            number: n.number,
            percentage: Number((n.percentage / 100).toFixed(2)),
            plate: n.plate,
            volume: n.volume,
        }));

        const result = await DamFunctions.saveDam(dam);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly addRow = () => {
        const newVolumes = this.state.volumes.slice();

        const newVolume = {
            id: this.state.volumes.length.toString(),
            number: this.state.volumes.length + 1,
            plate: 0,
            elevation: 0,
            volume: 0,
            percentage: 0,
            belowSpillway: null,
        } as FormValue;

        newVolumes.push(newVolume);
        this.setState({
            volumes: newVolumes,
        });
    };

    private readonly removeRow = (event : React.MouseEvent<HTMLButtonElement>) => {
        const newVolumes = this.state.volumes.slice();
        newVolumes.splice(Number(event.currentTarget.value) - 1, 1);
        this.setState({
            volumes: newVolumes,
        });
    };

    private readonly onMoveUpClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const newVolumes = this.state.volumes.map(x => ({
            ...Object.assign({}, x),
        }));

        const index = Number(event.currentTarget.value) - 1;

        const current = newVolumes[index];
        const previous = newVolumes[index - 1];

        current.number--;
        previous.number++;
        
        newVolumes.splice(index - 1, 2, current, previous);

        this.setState({
            volumes: newVolumes,
        });
    };

    private readonly onMoveDownClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const newVolumes = this.state.volumes.map(x => ({
            ...Object.assign({}, x),
        }));

        const index = Number(event.currentTarget.value) - 1;

        const current = newVolumes[index];
        const next = newVolumes[index + 1];

        current.number++;
        next.number--;
        
        newVolumes.splice(index, 2, next, current);

        this.setState({
            volumes: newVolumes,
        });
    };
    
    private readonly updateVolumes = (index : number, newRow : FormValue) => {
        const newVolumes = this.state.volumes.slice();

        newVolumes.splice(index, 1, newRow);

        this.setState({
            volumes: newVolumes,
        });
    };

    private readonly onBelowSpillwayChange = (index : number, value : string) => {
        if (Number(value) < 0) return;
        
        this.updateVolumes(index, {
            ...this.state.volumes[index],
            belowSpillway: Number(value),
        });
    };
    
    private readonly onPlateChange = (index : number, value : string) => {
        if (Number(value) < 0) return;
        
        this.updateVolumes(index, {
            ...this.state.volumes[index],
            plate: Number(value),
        });
    };

    private readonly onElevationChange = (index : number, value : string) => {
        if (Number(value) < 0) return;
        
        this.updateVolumes(index, {
            ...this.state.volumes[index],
            elevation: Number(value),
        });
    };

    private readonly onVolumeChange = (index : number, value : string) => {
        if (Number(value) < 0) return;
        
        this.updateVolumes(index, {
            ...this.state.volumes[index],
            volume: Number(value),
        });
    };

    private readonly onPercentageChange = (index : number, value : string) => {
        if (Number(value) < 0) return;
        
        this.updateVolumes(index, {
            ...this.state.volumes[index],
            percentage: Number(value),
        });
    };

    public readonly render = () => {
        const { transition, isLoading } = this.props;
        const { open, volumes } = this.state;

        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton className={'cp ml20'} onClick={this.onEditClick}>
                            <Icon>
                                edit
                            </Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={'lg'}
                    fullScreen={false}
                    fullWidth={true}
                    aria-labelledby='dam-info-info-title'
                    aria-describedby='dam-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Dam Plate Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdc flx1 '}>
                                <MaterialTable<FormValue>
                                    id='plateTable'
                                    data={volumes}
                                    isLoading={this.props.isLoading}
                                    rowsPerPage={10}
                                    columns={[{
                                        header: '',
                                        field: '',
                                        width: 50,
                                        enableSort: true,
                                        enableFilter: true,
                                        paddingRight: 4,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IconButton component='button' value={row.number} onClick={this.removeRow}>
                                                    <Icon className={'cr'}>
                                                        delete
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        ),
                                    }, {
                                        header: 'Order',
                                        field: 'number',
                                        width: 150,
                                        enableSort: true,
                                        enableFilter: true,
                                        paddingRight: 4,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IconButton color='primary' disabled={row.number === 1} value={row.number} onClick={this.onMoveUpClick}>
                                                    <ArrowUpwardRounded />
                                                </IconButton>
                                                <span>{row.number}</span>
                                                <IconButton color='primary' disabled={row.number === volumes.length} value={row.number} onClick={this.onMoveDownClick}>
                                                    <ArrowDownwardRounded />
                                                </IconButton>
                                            </div>
                                        ),
                                    }, {
                                        header: 'Plate',
                                        field: 'plate',
                                        width: 150,
                                        enableSort: true,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IndexTextInput 
                                                    id={'plate'} 
                                                    value={row.plate.toString()} 
                                                    onChange={this.onPlateChange} 
                                                    index={row.number - 1}                                                
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        header: 'Elevation',
                                        field: 'elevation',
                                        width: 150,
                                        enableSort: true,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IndexTextInput 
                                                    id={'elevation'} 
                                                    value={row.elevation.toString()} 
                                                    onChange={this.onElevationChange} 
                                                    index={row.number - 1}                                                
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        header: 'Volume',
                                        field: 'volume',
                                        width: 150,
                                        enableSort: true,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IndexTextInput 
                                                    id={'volume'} 
                                                    value={row.volume.toString()} 
                                                    onChange={this.onVolumeChange} 
                                                    index={row.number - 1}                                                
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        header: 'Percentage',
                                        field: 'percentage',
                                        width: 150,
                                        enableSort: true,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IndexTextInput 
                                                    id={'percentage'} 
                                                    value={row.percentage.toString()} 
                                                    onChange={this.onPercentageChange} 
                                                    index={row.number - 1}                                                
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        header: 'Below Slipway (m)',
                                        field: 'belowSpillway',
                                        width: 150,
                                        enableSort: true,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IndexTextInput 
                                                    id={'belowSpillway'} 
                                                    value={row.belowSpillway?.toString() ?? ''} 
                                                    onChange={this.onBelowSpillwayChange} 
                                                    index={row.number - 1}                                                
                                                />
                                            </div>
                                        ),
                                    },
                                    ]}
                                />
                                <div className={'jcfe wfill pt20 '}>
                                    <IconButton className={'bcy'} onClick={this.addRow}>
                                        <Icon className={'cp'}>
                                            add
                                        </Icon>
                                    </IconButton>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { connect } from 'react-redux';
import BoreholeCalibrationHelper, { IBoreholeCalibration } from '../../../@types/model/borehole/boreholeCalibration';
import AppFunctionsService from '../../../services/appFunctionsService';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { Card, CircularProgress, Icon, IconButton } from '@material-ui/core';
import GeneralFunctions from '../../../store/general/functions';
import EditBoreholeCalibration from '../dialog/calibration/EditDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import BoreholeCalibrationEntryView from './calibrationView/CalibrationEntryView';
import BoreholeCalibrationEntryLogisticsView from './calibrationView/CalibrationEntryLogisticsView';
import BoreholeBoreholeCalibrationEntryNeighbourLogView from './calibrationView/CalibrationEntryNeighbourLogView';

interface IBoreholeCalibrationPDFViewProps {
    borehole : IBorehole;

    session : IUserSession;
}

interface IBoreholeCalibrationPDFViewState {
    selectedCalibration ?: IBoreholeCalibration;

    boreholeCalibrations : Array<IBoreholeCalibration>;
    isLoading : boolean;
}

class BoreholeCalibrationPDFViewComponent extends React.Component<IBoreholeCalibrationPDFViewProps, IBoreholeCalibrationPDFViewState> {
    private listener ?: () => void;
    constructor(props : IBoreholeCalibrationPDFViewProps) {
        super(props);
        this.state = {
            isLoading: false,
            boreholeCalibrations: [],
        };
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public componentDidMount() : void {
        this.load();
    }

    public readonly load = () => {
        if (this.listener) this.listener();

        this.setState({
            boreholeCalibrations: [],
            isLoading: true,
        });

        this.listener = BoreholeCalibrationHelper.collection()
            .where('Borehole', '==', this.props.borehole.ref)
            .orderBy('Date', 'desc').onSnapshot((snapshot) => {
                if (!this.listener) return;
                this.setState({
                    boreholeCalibrations: snapshot.docs.map(x => x.data()).filter(x => !!x.DocumentName),
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Calibrations');
            }, () => {
                this.listener = undefined;
            });
    };

    private readonly onViewClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const calibration = this.state.boreholeCalibrations.find(x => x.ref.id === event.currentTarget.value);

        if (!calibration?.DocumentURL) return;
        window.open(calibration.DocumentURL, '_blank');
    };

    public readonly render = () => {
        const { boreholeCalibrations, isLoading } = this.state;
        const { session, borehole } = this.props;
        return (
            <div className={'fdc flx1 m10'}>
                <div className='fdc aife jcfe'>
                    <EditBoreholeCalibration
                        transition={Transitions.Down}
                    />
                </div>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    boreholeCalibrations.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Test Data Exists!
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!boreholeCalibrations.length &&
                    <div className='fdc flx1 ais jcfs oya oxh mt12'>
                        {
                            boreholeCalibrations.map(boreholeCalibration => (
                                <SeparatedAccordion key={boreholeCalibration.ref.id}>
                                    <PrimaryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className='fdr jcc aic flx1'>
                                            {
                                                AppFunctionsService.formatDateTimeToDateOnly(boreholeCalibration.Date ?? boreholeCalibration.CreatedOn) 
                                            }
                                            <span className='flx1' />
                                            <div className='mr15'>
                                                <IconButton
                                                    color='inherit'
                                                    value={boreholeCalibration.ref.id}
                                                    onClick={this.onViewClick}
                                                    size='small'
                                                >
                                                    <Icon>visibility</Icon>
                                                </IconButton>
                                            </div>
                                            {
                                                session.employee.IsBoreholeAdmin &&
                                                <div>
                                                    <EditBoreholeCalibration
                                                        transition={Transitions.Down}
                                                        boreholeCalibration={boreholeCalibration}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </PrimaryAccordionSummary>
                                    <AccordionDetails className={'fdc flx1'}>
                                        <div className='fdc p5'>
                                            <Card className='fdr aic jcc p15 bcp'>
                                                <Typography className='flx1 aic jcc fs18 fwb cw'>
                                                    BOREHOLE LOGISTICS
                                                </Typography>
                                            </Card>
                                        </div>
                                        <BoreholeCalibrationEntryLogisticsView
                                            calibration={boreholeCalibration}
                                            borehole={borehole}
                                        /> 
                                        {
                                            !!boreholeCalibration.NeighbouringBoreholes.length &&
                                            <div className='fdc p5'>
                                                <BoreholeBoreholeCalibrationEntryNeighbourLogView 
                                                    calibration={boreholeCalibration}
                                                    isLoading={isLoading}
                                                />
                                            </div>
                                        }
                                        <div className='fdc p5'>
                                            <Card className='fdr aic jcc p15 bcp'>
                                                <Typography className='flx1 aic jcc fs18 fwb cw'>
                                                    CALIBRATION TEST
                                                </Typography>
                                            </Card>
                                        </div>
                                        <BoreholeCalibrationEntryView
                                            calibration={boreholeCalibration}
                                            session={session}
                                            isLoading={isLoading}
                                        />                                        
                                    </AccordionDetails>
                                </SeparatedAccordion>
                            ))
                        }
                    </div>
                }
            </div>
        );
    };
}

const mapStateToProps = () => {
    return {};
};

const BoreholeCalibrationPDFView = connect(
    mapStateToProps,
)(BoreholeCalibrationPDFViewComponent);

export default BoreholeCalibrationPDFView;
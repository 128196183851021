import React from 'react';
import weatherFunctions from '../../store/weather/functions';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import WeatherInfo from './Info';
import { Transitions } from '../customComponents/animations/Transitions';
import navFunctions from '../../store/nav/functions';
import WeatherStationNewButton from './button/NewButton';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import WeatherStationHelper, { IWeatherStation } from '../../@types/model/weather/weatherStation';
import { IUserSession } from '../../@types/employee';
import { CSVLinkProps } from '../../@types/csv';

interface IWeatherListProps {
    weatherStations : Array<IWeatherStation>;
    isLoadingWeathers : boolean;
    session ?: IUserSession | null;
}

interface IWeatherListState {
    sortList : Array<{ field : string; direction : 'asc' | 'desc' }>;
}

class WeatherList extends React.Component<IWeatherListProps, IWeatherListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : IWeatherListProps) {
        super(props);

        this.state = {
            sortList: [],
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        weatherFunctions.getList();
    };

    private readonly onSortChanged = (sortList : Array<{ field : string; direction : 'asc' | 'desc' }>) => {
        this.setState({
            sortList,
        });
    };

    public readonly getData = (state : IWeatherListState, props : IWeatherListProps) => props.weatherStations;
    public readonly getSortList = (state : IWeatherListState) => state.sortList;

    public readonly getFilteredData = createSelector(
        [this.getData, this.getSortList],
        (weathers, sortList) => {
            let sortedList = weathers.slice().filter(n => (n.IsActive === undefined || n.IsActive));

            if (sortList.length === 0) {
                return sortedList.sort((a, b) => parseInt(a.WSNumber.substring(2)) - parseInt(b.WSNumber.substring(2)));
            }

            sortList.forEach((n) => {
                if (n.field === 'WSNumber') {
                    sortedList = sortedList.sort((a, b) => parseInt(b.WSNumber.substring(2)) - parseInt(a.WSNumber.substring(2)) * (n.direction === 'desc' ? -1 : 1));
                } else {
                    sortedList = lodash.orderBy(sortedList, n.field, n.direction);
                }
            });

            return sortedList;
        },
    );

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (stations) => {
            return WeatherStationHelper.mapToCsv(stations);
        },
    );

    public readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const weather = this.props.weatherStations.slice().find(x => x.WSNumber === event.currentTarget.value);

        weatherFunctions.setSelected(weather);
    };

    public readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const weather = this.props.weatherStations.slice().find(x => x.WSNumber === event.currentTarget.value);

        if (weather) {
            navFunctions.navWeatherMap(weather.WSNumber);
        }
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    public readonly render = () => {
        const { isLoadingWeathers, session } = this.props;
        const weathers = this.getFilteredData(this.state, this.props);
        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.state, this.props);

        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            WEATHER STATIONS
                        </Typography>
                        {
                            session?.employee.IsWeatherStationAdmin &&
                            <WeatherStationNewButton />
                        }
                        <span className={'flx1'} />
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{weathers.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IWeatherStation>
                            id='weathersTable'
                            data={weathers}
                            isLoading={isLoadingWeathers}
                            rowsPerPage={50}
                            externalSort
                            onSortChange={this.onSortChanged}
                            onExportCsvClick={!session?.employee.IsWeatherExport ? undefined : this.onCSVClick}
                            columns={[{
                                header: '',
                                width: 100,
                                paddingRight: 4,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton value={row.WSNumber} onClick={this.onInfoClick}>
                                                    <Icon>
                                                        list
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton value={row.WSNumber} onClick={this.onMapClick} disabled={!row.Geom}>
                                                    <Icon>
                                                        map
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Division',
                                field: 'Division',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'WS Number',
                                field: 'WSNumber',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Station',
                                field: 'Station',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Name',
                                field: 'WSName',
                                width: 125,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Type',
                                field: 'WSType',
                                width: 125,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Installation Date',
                                field: 'InstallationDate',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Serial Number',
                                field: 'SerialNumber',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Obstruction Distance',
                                field: 'ObstructionDistance',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Fenced',
                                field: 'Fenced',
                                width: 125,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => (<Icon>{n.Fenced ? 'done' : 'close' }</Icon>),
                            }]}
                        />
                    </Card>
                    <WeatherInfo fullScreen transition={Transitions.Up} />
                    {
                        session?.employee.IsWeatherExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'WSNumber',
                                    label: 'WS Number',
                                },
                                {
                                    key: 'WSName',
                                    label: 'WS Name',
                                },
                                {
                                    key: 'Division',
                                    label: 'Division',
                                },
                                {
                                    key: 'WSType',
                                    label: 'WS Type',
                                },
                                {
                                    key: 'Latitude',
                                    label: 'Latitude',
                                },
                                {
                                    key: 'Longitude',
                                    label: 'Longitude',
                                },
                                {
                                    key: 'InstallationDate',
                                    label: 'Installation Date',
                                },
                                {
                                    key: 'SerialNumber',
                                    label: 'Serial Number',
                                },
                                {
                                    key: 'ObstructionDistance',
                                    label: 'Obstruction Distance',
                                },
                                {
                                    key: 'Fenced',
                                    label: 'Fenced',
                                },
                                {
                                    key: 'CreatedOn',
                                    label: 'Created On',
                                },
                                {
                                    key: 'UpdatedOn',
                                    label: 'Updated On',
                                },
                            ]}
                            filename={`${csvName}_weather_station.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        weatherStations: state.weather.weatherStations,
        isLoadingWeathers: state.weather.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(WeatherList);

import React from 'react';
import mottechFunctions from '../../store/mottech/functions';
import { IMottech } from '../../@types/mottech';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { IAuthState, IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createSelector } from 'reselect';
import randomColor from 'randomcolor';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { Transitions } from '../customComponents/animations/Transitions';
import MottechInfo from './Info';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import { withRouter, RouteComponentProps } from 'react-router';
import appFunctionsService from '../../services/appFunctionsService';
import CustomMapBox, { IMapLine, IMapMarker } from '../customComponents/map/CustomMapBox';
import { MOTTECH_MAP_IMAGES } from '../../appConstants';

interface IMottechMapProps extends RouteComponentProps<{
    Code : string;
}, Record<string, unknown>, Record<string, unknown>> {
    auth : IAuthState;
    mottechs : Array<IMottech>;
    isLoadingMottechs : boolean;
}

interface IMottechMapState {
    selectedMottech ?: IMottech;
}

class MottechMap extends React.Component<IMottechMapProps, IMottechMapState> {
    private readonly mapZoom = 8;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];

    constructor(props : IMottechMapProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        mottechFunctions.getList(this.setSelectedMottech);
    };

    private setSelectedMottech : undefined | (() => void) = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const mottechId = urlParams.get('Code') as string;
        this.onMarkerClick(mottechId);

        this.setSelectedMottech = undefined;
    };
    
    private readonly selectMarker = (mottech : IMottech) => {
        this.setState({
            selectedMottech: mottech,
        });
        
    };

    private readonly onMoreInfo = () => {
        mottechFunctions.setSelected(Object.assign({}, this.state.selectedMottech));
    };

    private readonly onCloseDrawer = () => {
        this.setState({
            selectedMottech: undefined,
        });
    };

    private readonly onMarkerClick = (code : string) => {
        const mottech = this.props.mottechs.slice().find(n => n.Code === code);

        if (mottech) {        
            this.selectMarker(mottech);
        }
    };

    public readonly getData = (state : IMottechMapState, props : IMottechMapProps) => props.mottechs;
    public readonly getFilteredData = createSelector(
        [this.getData],
        (mottechs) => {
            return mottechs.slice().filter(n => n.Geom && (n.IsActive === undefined || n.IsActive));
        },
    );
    public readonly getPolyLines = createSelector(
        [this.getData],
        (mottechs) => {
            const polylines : Array<IMapLine> = [];

            mottechs.filter(n => (n.IsActive === undefined || n.IsActive) && !!n.ConnectedTo && !!n.Geom).forEach((n) => {
                const connectedMottech = mottechs.find(x => x.PiccoloId === n.ConnectedTo);

                if (connectedMottech?.Geom && n.Geom) {
                    polylines.push({
                        positions: [[n.Geom.longitude, n.Geom.latitude], [connectedMottech.Geom.longitude, connectedMottech.Geom.latitude]],
                        color: randomColor({ seed: `${n.Code}_${connectedMottech.Code}` }),
                        key: `${connectedMottech.Code}_connected_${n.Code}`,
                    });
                }
            });

            return polylines;
        },
    );

    public readonly getMarkers = createSelector(
        [this.getFilteredData],
        (mottechs) => {
            return mottechs.map(n => ({
                coordinates: [n.Geom?.longitude ?? 0, n.Geom?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: n.Code,
            } as IMapMarker));
        }
    );

    public readonly render = () => {
        const { isLoadingMottechs } = this.props;
        const { selectedMottech } = this.state;
        const mottechs = this.getFilteredData(this.state, this.props);
        const polylines = this.getPolyLines(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (<div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Toolbar>
                    <Typography variant='h5' color='inherit'>
                        MOTTECHS
                    </Typography>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className={'fdr pt5'}>
                            <span style={{ paddingRight: '15px' }}><b>Total: </b>{mottechs.length}</span>
                        </div>
                    </div>
                </Toolbar>
                <div className='mnh4'>
                    { isLoadingMottechs && <LinearProgress />}
                </div>
                <Paper className={'flx1 fdr oxh'}>
                    {
                        mottechs.length > 0 &&
                        <CustomMapBox
                            id='mottech'
                            enableAreas
                            geoLocationControl
                            navigationControl
                            showScale
                            layerControls
                            center={this.mapCenter}
                            zoom={this.mapZoom}
                            images={MOTTECH_MAP_IMAGES}
                            onMarkerClick={this.onMarkerClick}
                            markers={markers}
                            lines={polylines}
                            selectedMarker={selectedMottech?.Code}
                        />
                    }
                </Paper>
            </div>
            <Drawer open={!!selectedMottech} anchor='right' variant='persistent'>
                <div className={'w300 flx1 fdc'}>
                    <AppBar className='posr' position='static'>
                        <Toolbar variant='dense'>
                            <div className='h60' />
                            <IconButton color={'inherit'} onClick={this.onMoreInfo}><Icon color={'inherit'}>more_vert</Icon></IconButton>
                            <Typography color='inherit' variant='h6' className={'flx1 ml10'} noWrap={true}>
                                {!!selectedMottech && selectedMottech.Code}
                            </Typography>
                            <IconButton color={'inherit'} onClick={this.onCloseDrawer}><Icon color={'inherit'}>close</Icon></IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        selectedMottech &&
                        <div className='fdc flx1'>
                            <div className='fdr flx1'>
                                <div className={'fdc flx1'}>
                                    <Typography className={'fdr bcDarkBlue fw700 cw p10'} variant='h6'>
                                        SUMMARY
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            CODE
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            NAME
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedMottech.Code}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedMottech.Name}
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            TYPE
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            INSTALLATION DATE
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedMottech.Type}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {!selectedMottech.InstallationDate ? '' : appFunctionsService.formatDateTimeToDateOnly(selectedMottech.InstallationDate)}
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            PICCOLO/PIU ID
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            SERIAL NUMBER
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedMottech.PiccoloId}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedMottech.SerialNumber}
                                        </Typography>
                                    </div>
                                    <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                        DIVISION INFO
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            DIVISION
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            MANAGEMENT AREA
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedMottech.Division}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedMottech.ManagementArea}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Drawer>
            <MottechInfo fullScreen transition={Transitions.Up} />
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        mottechs: state.mottech.mottechs,
        mottech: state.mottech.mottech,
        isLoadingMottechs: state.mottech.loading,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(MottechMap));

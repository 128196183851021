import { firestore } from 'firebase/app';
import moment from 'moment';
import { DATE_INPUT_FORMAT_DEFAULT } from '../../../appConstants';
import firebaseApp from '../../../services/firebaseService';
import { EmployeeHelper } from '../../employee';
import { IAuthState } from '../../redux';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { ISPULine, ILPULine, TariffType } from './tariffs';
import TransformerHelper, { ITransformer } from './transformer';
import lodash from 'lodash';
import TransformerJournalHelper from './journal';
import Decimal from 'decimal.js';

export interface ITransformerInvoice<T = ISPULine | ILPULine> extends IBasicBaseClass {
    type : TariffType;

    /// Begining of month for invoice
    monthDate : number;

    epNumber : string;

    /// Path to ep.
    epNumberRef : string;
    
    transformer : ITransformer;

    poleNumber : string;
    accountNumber : string;
    totalUsage : number;
    days : number;

    demandReading : number;
    reactiveEnergy : number;
    utilizedCapacity : number;

    credit : number; // Credit
    totalCharge : number; // Calculated Total - vat
    vatPercentage : number;
    vatCharge : number;
    totalDue : number; // Calculated Total + vat
    totalBilled : number; // Actual Total + vat
    totalInterest : number; // Total Interest Charged, no VAT

    paid : boolean;

    paymentDueDate : string | null;
    // Path to user
    paidBy : string | null;
    paidByName : string | null;
    paidByEmployeeNumber : string | null;
    paidOn : number | null;

    journaled : boolean;

    journal : string | null;
    journalNr : string | null;

    // Path to user
    journaledBy : string | null;
    journaledByName : string | null;
    journaledByEmployeeNumber : string | null;
    journaledOn : number | null;

    group : string;

    adminCharge : number;
    serviceCharge : number;
    standardConnectionCharge : number;

    lines : Array<T>;

    reverted : boolean;
    relatedId : string | null;
    related : ITransformerInvoice | null;
}

export class TransformerInvoiceHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_invoice';

    private static converter : <T = ILPULine | ISPULine>() => firebase.firestore.FirestoreDataConverter<ITransformerInvoice<T>> = <T = ILPULine | ISPULine>() => ({
        fromFirestore: (snapshot) => {
            return TransformerInvoiceHelper.fromFirestoreDocument<T>(snapshot);
        },
        toFirestore: (data : ITransformerInvoice<T>) : firebase.firestore.DocumentData => {
            return TransformerInvoiceHelper.toFirestoreDocument(data);
        },
    });
    
    public static fromFirestoreDocument<T = ILPULine | ISPULine>(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerInvoice<T> {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...result,
            demandReading: data.demandReading ?? 0,
            adminCharge: data.adminCharge ?? 0,
            serviceCharge: data.serviceCharge ?? 0,
            standardConnectionCharge: data.standardConnectionCharge ?? 0,
            reactiveEnergy: data.reactiveEnergy ?? 0,
            utilizedCapacity: data.utilizedCapacity ?? 0,
            type: data.type,
            monthDate: data.monthDate.toMillis(),
            epNumber: data.epNumber,
            epNumberRef: data.epNumberRef.path,
            poleNumber: data.poleNumber,
            accountNumber: data.accountNumber,
            totalUsage: data.totalUsage,
            totalInterest: data.totalInterest ?? 0,
            days: data.days,
            totalCharge: data.totalCharge,
            vatPercentage: data.vatPercentage,
            vatCharge: data.vatCharge,
            totalDue: data.totalDue,
            totalBilled: data.totalBilled ?? 0,
            paid: !!data.paid,
            paidBy: data.paidBy?.path ?? null,
            paidByName: data.paidByName ?? null,
            paidByEmployeeNumber: data.paidByEmployeeNumber ?? null,
            paidOn: data.paidOn?.toMillis() ?? null,
            paymentDueDate: !data.paymentDueDate ? null : (moment.utc(data.paymentDueDate?.toMillis()).format('YYYY/MM/DD')),
            group: data.group,
            lines: data.lines,
            journal: !data.journal ? null : (data.journal as firestore.DocumentReference).id,
            journaled: !!data.journaled,
            journalNr: data.journalNr ?? null,
            journaledBy: data.journaledBy?.path ?? null,
            journaledByName: data.journaledByName ?? null,
            journaledByEmployeeNumber: data.journaledByEmployeeNumber ?? null,
            journaledOn: data.journaledOn?.toMillis() ?? null,
            relatedId: !data.relatedId ? null : (data.relatedId as firestore.DocumentReference).path,
            related: data.related ?? null,
            reverted: !!data.reverted,
            transformer: data.transformer,
            credit: data.credit ?? 0,
        };
    }

    public static toFirestoreDocument<T = ILPULine | ISPULine>(data : ITransformerInvoice<T>) {
        const result = super.toFirestore(data);
        return {
            ...result,
            type: data.type,
            monthDate: firestore.Timestamp.fromMillis(data.monthDate),
            epNumber: data.epNumber,
            epNumberRef: TransformerHelper.path(data.epNumberRef),
            poleNumber: data.poleNumber,
            accountNumber: data.accountNumber,
            totalUsage: data.totalUsage,
            days: data.days,
            totalInterest: data.totalInterest,
            totalCharge: data.totalCharge,
            vatPercentage: data.vatPercentage,
            vatCharge: data.vatCharge,
            totalDue: data.totalDue,
            paid: !!data.paid,
            paidBy: !data.paidBy ? null : EmployeeHelper.path(data.paidBy),
            paidByName: data.paidByName ?? null,
            paidByEmployeeNumber: data.paidByEmployeeNumber ?? null,
            paidOn: !data.paidOn ? null : firestore.Timestamp.fromMillis(data.paidOn),
            paymentDueDate: !data.paymentDueDate ? null : firestore.Timestamp.fromMillis(moment.utc(data.paymentDueDate, DATE_INPUT_FORMAT_DEFAULT, true).valueOf()),
            group: data.group,
            lines: data.lines,
            journal: !data.journal ? null : TransformerJournalHelper.doc(data.journal),
            journaled: !!data.journaled,
            journalNr: data.journalNr ?? null,
            journaledBy: !data.journaledBy ? null : EmployeeHelper.path(data.journaledBy),
            journaledByName: data.journaledByName ?? null,
            journaledByEmployeeNumber: data.journaledByEmployeeNumber ?? null,
            journaledOn: !data.journaledOn ? null : firestore.Timestamp.fromMillis(data.journaledOn),
            related: !data.related ? null : data.related,
            relatedId: !data.related ? null : TransformerInvoiceHelper.doc(data.related.id),
            reverted: !!data.reverted,
            transformer: data.transformer,
            totalBilled: data.totalBilled,
            demandReading: data.demandReading,
            adminCharge: data.adminCharge,
            serviceCharge: data.serviceCharge,
            reactiveEnergy: data.reactiveEnergy,
            utilizedCapacity: data.utilizedCapacity,
        };
    }

    public static collection<T = ILPULine | ISPULine>() {
        return firebaseApp.firestore().collection(TransformerInvoiceHelper.COLLECTION_NAME).withConverter(this.converter<T>());
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().doc();
        }
        return this.collection().doc(id);
    }

    public static saveMany(invoices : Array<ITransformerInvoice>) {
        return firebaseApp.firestore().runTransaction(async (db) => {
            invoices.forEach(invoice => db.set(TransformerInvoiceHelper.doc(invoice.id), invoice));
        });
    }

    private static reverse(invoice : ITransformerInvoice, auth : IAuthState) {
        return {
            ...invoice,
            totalCharge: invoice.totalCharge * -1,
            vatCharge: invoice.vatCharge * -1,
            totalDue: invoice.totalDue * -1,
            paid: false,
            paidBy: null,
            paidByName: null,
            paidByEmployeeNumber: null,
            paidOn: null,
            paymentDueDate: null,
            journaled: false,
            journalNr: null,
            journaledBy: null,
            journaledByName: null,
            journaledByEmployeeNumber: null,
            journaledOn: null,
            related: invoice,
            relatedId: invoice.id,
            createdBy: auth.session?.firebaseUser.uid ?? '',
            createdByEmployee: auth.session?.employee.EmployeeNumber ?? '',
            createdByName: auth.session?.employee.Name ?? '',
            createdOn: moment.utc().valueOf(),
            updatedBy: auth.session?.firebaseUser.uid ?? '',
            updatedByEmployee: auth.session?.employee.EmployeeNumber ?? '',
            updatedByName: auth.session?.employee.Name ?? '',
            updatedOn: moment.utc().valueOf(),
            reverted: false,
        };
    }

    public static reverseMany(db : firestore.Transaction, invoices : Array<ITransformerInvoice>, auth : IAuthState) {
        invoices.forEach(invoice => db.set(TransformerInvoiceHelper.doc(), TransformerInvoiceHelper.reverse(invoice, auth)));
        invoices.forEach(invoice => db.update(TransformerInvoiceHelper.doc(invoice.id), {
            updatedBy: EmployeeHelper.doc(auth.session?.firebaseUser.uid ?? ''),
            updatedByEmployee: auth.session?.employee.EmployeeNumber ?? '',
            updatedByName: auth.session?.employee.Name ?? '',
            updatedOn: firestore.Timestamp.fromMillis(moment.utc().valueOf()),
            reverted: true,
        }));
    }

    public static calculateLPUTotal(invoice : ITransformerInvoice<ILPULine>) {
        const result = { 
            ...invoice,
            lines: [
                ...invoice.lines,
            ],
        };

        result.days = result.lines.reduce((a, b) => a + b.days, 0);

        let totalInterest = 0;
        result.lines.forEach((line) => {
            line.ancillaryServiceCharge = parseFloat((line.networkDemandUnits * line.tariffValues.ancillaryServiceCharge / 100).toFixed(2));
            line.total = TransformerInvoiceHelper.calculateLPULineTotal(result.days, line);
            totalInterest += line.interestCharge;
        });
        
        let total = (result.adminCharge * result.days) + result.serviceCharge + result.standardConnectionCharge;

        result.lines.forEach((line) => {
            total += (line.total);

            if (result.lines.length > 1) {
                total += (result.utilizedCapacity * line.tariffValues.netwrokCapacity / 31 * line.days);
            } else {
                total += (result.utilizedCapacity * line.tariffValues.netwrokCapacity);
            }
        });

        const totalVat = total * result.vatPercentage;

        result.totalCharge = parseFloat(total.toFixed(2));
        result.vatCharge = parseFloat(totalVat.toFixed(2));
        result.totalDue = parseFloat((total + totalVat + totalInterest).toFixed(2));
        result.totalBilled = parseFloat((total + totalVat).toFixed(2));
        result.totalInterest = totalInterest;
        result.totalUsage = lodash.sumBy(result.lines, x => x.networkDemandUnits);

        return result;
    }

    public static calculateSPUTotal(invoice : ITransformerInvoice<ISPULine>) {
        const result = { 
            ...invoice,
            lines: [
                ...invoice.lines,
            ],
        };

        result.days = result.lines.reduce((a, b) => a + b.days, 0);

        result.lines.forEach((line) => {
            line.total = TransformerInvoiceHelper.calculateSPULineTotal(line);
        });
        
        const adminCharge = new Decimal(result.adminCharge).mul(result.days);
        let total = adminCharge.add(result.serviceCharge).add(result.credit);

        result.lines.forEach((line) => {
            total = total.add(line.total);
        });

        const totalVat = total.mul(result.vatPercentage);

        const totalDue = totalVat.add(total);

        result.totalCharge = total.toDecimalPlaces(2).toNumber();
        result.vatCharge = totalVat.toDecimalPlaces(2).toNumber();
        result.totalDue = totalDue.toDecimalPlaces(2).toNumber();
        result.totalBilled = totalDue.toDecimalPlaces(2).toNumber();
        result.totalUsage = lodash.sumBy(result.lines, x => x.totalUsage);

        return result;
    }

    private static calculateLPULineTotal(totalDays : number, line : ILPULine) {
        if (totalDays > 31) {
            return (
                (line.networkDemandUnits * line.tariffValues.networkDemandCharge / 100) +
                (line.ancillaryServiceCharge) +
                (line.unitsSTDLow * line.tariffValues.lowSTD / 100) +
                (line.unitsOffPeakLow * line.tariffValues.lowOffPeak / 100) +
                (line.unitsPeakLow * line.tariffValues.lowPeak / 100) +
                (line.unitsSTDHigh * line.tariffValues.highSTD / 100) +
                (line.unitsOffPeakHigh * line.tariffValues.highOffPeak / 100) +
                (line.unitsPeakHigh * line.tariffValues.highPeak / 100) +
                (line.excessNetworkAccessCharge * line.tariffValues.netwrokCapacity) +
                (line.excessReactiveEnergy * line.tariffValues.reactiveEnergyCharge / 100)
            );

        }

        return (
            (line.networkDemandUnits * line.tariffValues.networkDemandCharge / 100) +
            (line.ancillaryServiceCharge) +
            (line.unitsSTDLow * line.tariffValues.lowSTD / 100) +
            (line.unitsOffPeakLow * line.tariffValues.lowOffPeak / 100) +
            (line.unitsPeakLow * line.tariffValues.lowPeak / 100) +
            (line.unitsSTDHigh * line.tariffValues.highSTD / 100) +
            (line.unitsOffPeakHigh * line.tariffValues.highOffPeak / 100) +
            (line.unitsPeakHigh * line.tariffValues.highPeak / 100) +
            (line.excessNetworkAccessCharge * line.tariffValues.netwrokCapacity) +
            (line.excessReactiveEnergy * line.tariffValues.reactiveEnergyCharge / 100)
        );
    }

    public static calculateSPULineTotal(line : ISPULine) {
        const serviceCharge = new Decimal(line.tariffValues.serviceCharge).mul(line.days);
        const networkCapacity = new Decimal(line.tariffValues.networkCapacity).mul(line.days);
        const networkDemand = new Decimal(line.tariffValues.networkDemand).div(100).mul(line.totalUsage);
        const ancillaryService = new Decimal(line.tariffValues.ancillaryService).div(100).mul(line.totalUsage);
        const energyCharge = new Decimal(line.tariffValues.energyCharge).div(100).mul(line.totalUsage);

        return serviceCharge
            .add(networkCapacity)
            .add(networkDemand)
            .add(ancillaryService)
            .add(energyCharge)
            .toNumber();
    }
}

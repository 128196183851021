import { AppBar, Toolbar, Typography, Paper } from '@material-ui/core';
import React from 'react';
import { createSelector } from 'reselect';
import { IUserSession } from '../../../@types/employee';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import { PUMPING_STATION_MAP_IMAGES } from '../../../appConstants';
import CustomMapBox, { IMapMarker } from '../../customComponents/map/CustomMapBox';
import EditLocationDialog from '../../customComponents/dialog/LocationEditDialog';
import PumpingStationFunctions from '../../../store/pumpingStation/functions';
import { Transitions } from '../../customComponents/animations/Transitions';


interface IPumpingStationLocationProps {
    pumpingStation : IPumpingStation;
    isLoading : boolean;
    session : IUserSession;
}

interface IPumpingStationLocationState {
}

export default class PumpingStationLocation extends React.PureComponent<IPumpingStationLocationProps, IPumpingStationLocationState> {
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];
    private readonly mapZoom = 8;

    constructor(props : IPumpingStationLocationProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number) => {
        const pumpingStation = Object.assign({}, this.props.pumpingStation);

        pumpingStation.geo = geom;
        pumpingStation.elevation = elevation;

        const result = await PumpingStationFunctions.savePumpingStation(pumpingStation);

        if (!result) throw new Error('');
    }

    public getData = (props : IPumpingStationLocationProps) => props.pumpingStation;

    public readonly getMarker = createSelector(
        [this.getData],
        (pumpingStation) => {
            return [({
                coordinates: [pumpingStation.geo?.longitude ?? 0, pumpingStation.geo?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: pumpingStation.code,
            } as IMapMarker)];
        }
    );

    public render = () => {
        const { pumpingStation, isLoading, session } = this.props;
        const marker = this.getMarker(this.props);

        return (
            <div className=' flx1 mh0 mw0 bcg0'>
                <div className='fdr flx1 mh0 mw0 bcg0 m50'>
                    { 
                        !isLoading &&
                        <Paper className={'flx2 ais'}>
                            <div className='hfill wfill '>
                                {
                                    <CustomMapBox
                                        id='pumpingStation_map'
                                        showScale
                                        center={this.mapCenter}
                                        zoom={this.mapZoom}
                                        images={PUMPING_STATION_MAP_IMAGES}
                                        markers={marker}
                                        selectedMarker={pumpingStation.code}
                                    />
                                }
                            </div>
                        </Paper>
                    }
                    <div className={'flx1 fdc ml20 bcg0'}>
                        <div>
                            <AppBar className='bcdlg0' position='static' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' color='inherit' className='flx1 '>
                                        LOCATION
                                    </Typography>
                                    <span className={'flx1 ais'} />
                                    {
                                        session.employee.IsPumpingStationAdmin &&
                                        <EditLocationDialog
                                            markerImage={PUMPING_STATION_MAP_IMAGES['1']}
                                            markerImageSize = {0.35}
                                            elevation={pumpingStation.elevation ?? 0}
                                            geom={pumpingStation.geo}
                                            transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                            onSave={this.onLocationSave}
                                        />
                                    }
                                    
                                </Toolbar>
                            </AppBar>
                        </div>
                        <Paper className={'mt20'}>
                            <AppBar className='bcdlg1' position='static' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' color='inherit' className='flx1 '>
                                    LATITUDE
                                    </Typography>
                                    <span className={'flx1'} />
                                </Toolbar>
                            </AppBar>
                            <div className={'flx1 ais'}>
                                <Typography className={'flx1 fs28 fwm p20'}>
                                    {pumpingStation.geo?.latitude}
                                </Typography>
                            </div>
                        </Paper>
                        <Paper className={'mt20'}>
                            <AppBar className='bcdlg2' position='static' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' color='inherit' className='flx1'>
                                    LONGITUDE
                                    </Typography>
                                    <span className={'flx1'} />
                                </Toolbar>
                            </AppBar>
                            <div className={'ais '}>
                                <Typography className={'flx1 fs28 fwm p20'}>
                                    {pumpingStation.geo?.longitude}
                                </Typography>
                            </div>
                        </Paper>
                        <Paper className={'mt20'}>
                            <AppBar className='posr bcdlg3' position='static' elevation={0}>
                                <Toolbar variant='dense' >
                                    <Typography variant='h6'  className='flx1'>
                                        ELEVATION
                                    </Typography>
                                    <span className={'flx1'} />
                                </Toolbar>
                            </AppBar>
                            <div className={'flx1 ais'}>
                                <Typography className={'flx1 fs28 fwm p20'}>
                                    {pumpingStation.elevation ?? '0.00'} m
                                </Typography>
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        );
    }
}

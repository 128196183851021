import { FormControl, TextField, TextFieldProps } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import React from 'react';

interface ITextFormFieldProps extends Omit<TextFieldProps, 'name' | 'value' | 'error' | 'variant'> {
    name : string;
}

interface ITextFormFieldState {}

export default class TextFormField extends React.PureComponent<ITextFormFieldProps, ITextFormFieldState> {
    constructor(props : ITextFormFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { id, name, onChange, ...rest } = this.props;
        return (
            <Field
                id={id}
                name={name}
            >
                {
                    (params : FieldProps<unknown>) => (
                        <FormControl fullWidth={rest.fullWidth} required={rest.required}>
                            <TextField
                                {...rest}
                                {...params.field}
                                value={params.field.value ?? ''}
                                error={!!params.meta.error}
                                variant='standard'
                                onChange={onChange ? onChange : params.field.onChange}
                            />
                        </FormControl>
                    )
                }
            </Field>
        );
    };
}

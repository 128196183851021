import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import boreholeFunctions from '../../../../store/borehole/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import appFunctionsService from '../../../../services/appFunctionsService';
import BoreholeStageInput from '../../../customComponents/input/BoreholeStageEntryInput';
import { BoreholeCalibrationStagesType, IBoreholeCalibration, IBoreholeCalibrationStageEntry } from '../../../../@types/model/borehole/boreholeCalibration';

interface IEditBoreholeCalibrationStageProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    boreholeCalibration : IBoreholeCalibration;
    stage : BoreholeCalibrationStagesType | 5;
}

interface IEditBoreholeCalibrationStageState {
    open : boolean;
    entries : Array<IBoreholeCalibrationStageEntry>;
}

export default class EditBoreholeCalibrationStage extends React.Component<IEditBoreholeCalibrationStageProps, IEditBoreholeCalibrationStageState> {
    constructor(props : IEditBoreholeCalibrationStageProps) {
        super(props);
        this.state = {
            open: false,
            entries: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeCalibrationStageProps, prevState : IEditBoreholeCalibrationStageState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                entries: [],
            });
        }
        if (!prevState.open && this.state.open) {
            const { boreholeCalibration, stage } = this.props;

            if (stage === 5) {
                this.setState({
                    entries: boreholeCalibration.RecoveryEntries.map(x => ({...x})),
                });
            } else {
                this.setState({
                    entries: boreholeCalibration.BoreholeCalibrationStages[stage].StageEntries.map(x => ({...x})),
                });
            }
        }
    };

    private readonly save = async () => {
        const { entries } = this.state;
        const { stage } = this.props;

        const boreholeCalibration = {
            ...this.props.boreholeCalibration,
        };

        if (stage !== 5) {
            boreholeCalibration.BoreholeCalibrationStages[stage].StageEntries = entries.map(x => ({...x}));
        } else {
            boreholeCalibration.RecoveryEntries = entries.map(x => ({...x}));
        }

        
        const result = await boreholeFunctions.saveBoreholeCalibration(boreholeCalibration);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onEntryChange = (runTimer : IBoreholeCalibrationStageEntry, index : number) => {
        const entries = this.state.entries.slice();
        entries.splice(index, 1, runTimer);
        this.setState({
            entries,
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, boreholeCalibration, stage } = this.props;
        const { open, entries } = this.state;
        return (
            <div>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-info-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {
                                    stage < 5 &&
                                    `Edit Borehole Calibration Stage ${stage} `
                                }
                                {
                                    stage === 5 &&
                                    'Edit Borehole Calibration Recovery Stage '
                                }
                                ({appFunctionsService.formatDateTimeToDateOnly(boreholeCalibration.CreatedOn)})
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill oya'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdc flx1 m10'}>
                                <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    ENTRIES
                                    <span className='flx1' />
                                </Typography>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        MIN
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        WATER LEVEL
                                    </Typography>
                                    {
                                        stage < 5 &&
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            ABSTRACTION RATE
                                        </Typography>
                                    }
                                </div>
                                {
                                    entries.map((t, i) => (
                                        <BoreholeStageInput
                                            key={`${i}_stage`}
                                            recovery={stage === 5}
                                            index={i}
                                            value={t}
                                            onChange={this.onEntryChange}
                                        />
                                    ))
                                }
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </div>);
    };
}

import React from 'react';
import Dropzone from 'react-dropzone';
import FirebaseStorageImage from './image/FirebaseStorageImage';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

interface IFileDropZoneProps {
    fileName ?: string;
    path ?: string;

    onFileSelected : (file : File) => void;

    url ?: string | null;
    thumbnailUrl ?: string | null;
}

interface IFileDropZoneState {
    selectedFile ?: string;
}

export default class FileDropZone extends React.Component<IFileDropZoneProps, IFileDropZoneState> {
    constructor(props : IFileDropZoneProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onFileDrop = (acceptedFiles : Array<File>) => {
        this.setState({
            selectedFile: URL.createObjectURL(acceptedFiles[0]),
        });

        this.props.onFileSelected(acceptedFiles[0]);
    };

    public readonly render = () => {
        const { path, fileName, url, thumbnailUrl } = this.props;
        const { selectedFile } = this.state;
        return (
            <Dropzone onDrop={this.onFileDrop} accept='image/*'>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Card className={'fdc flx1 h250'}>
                                {
                                    selectedFile &&
                                    <CardMedia className='flx1' image={selectedFile}>
                                    </CardMedia>
                                }
                                {
                                    !selectedFile && fileName && path &&
                                    <CardMedia className='flx1' >
                                        <FirebaseStorageImage
                                            fileName={fileName}
                                            path={path}
                                            url={url}
                                            thumbnailUrl={thumbnailUrl}
                                        />
                                    </CardMedia>
                                }
                            </Card>
                            <div>
                                <Typography variant='body2'>
                                    Drag &apos;n&apos; drop some files here, or click to select files
                                </Typography>
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>
        );
    };
}

import { useState, useEffect } from 'react';
import * as lodash from 'lodash';
import { firestore } from 'firebase';

export interface FirestoreQueryFilter {
    property : string;
    operator : '==' | '!=' | '<' | '>' | '<=' | '>=';
    value : string | number | firestore.DocumentReference;
}

const useFirestoreCollection = <T extends { ref ?: firestore.DocumentReference }>(
    collection : firestore.CollectionReference<T>,
    filters ?: Array<FirestoreQueryFilter>
) => {
    const [data, setData] = useState<Array<T>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsLoading(true);
        let collectionQuery : firestore.Query<T> = collection;

        if (filters && filters.length > 0) {
            lodash.forEach(filters, x => {
                collectionQuery = collectionQuery.where(x.property, x.operator, x.value);
            });
        }

        collectionQuery.onSnapshot(
            (snapshot) => {
                setData((prevData) => {
                    const newData = [...prevData];

                    snapshot.docChanges().forEach((change) => {
                        const entry : T = { 
                            ...change.doc.data(),
                            ref: change.doc.ref,
                        };

                        const index = newData.findIndex(doc => doc.ref?.id === change.doc.ref.id);

                        switch (change.type) {
                            case 'added':
                                newData.push(entry);
                                break;
                            case 'modified':
                                if (index !== -1) {
                                    newData[index] = entry;
                                }
                                break;
                            case 'removed':
                                if (index !== -1) {
                                    newData.splice(index, 1);
                                }
                                break;
                            default:
                                break;
                        }
                    });

                    return newData;
                });

                setIsLoading(false);
            },
            (error) => {
                setData([]);
                setIsLoading(false);
            }
        );
    }, []);

    return { data, isLoading };
};

export default useFirestoreCollection;

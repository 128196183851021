import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import TransformerFunctions from '../../../store/transformer/functions';

interface ITransformerLpuReadingDeleteProps {
    readingId : string;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    onClose : () => void;
    open : boolean;
    isLoading : boolean;
}

interface ITransformerLpuReadingDeleteState {
}

export default class TransformerLpuReadingDelete extends React.Component<ITransformerLpuReadingDeleteProps, ITransformerLpuReadingDeleteState> {
    constructor(props : ITransformerLpuReadingDeleteProps) {
        super(props);
    }

    private readonly delete = async () => {
        await TransformerFunctions.deleteLpuReading(this.props.readingId);
        this.props.onClose();
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;
        this.props.onClose();
    };

    public readonly render = () => {
        const { open, transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;

        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='transformer-division-info-title'
                aria-describedby='transformer-division-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                                Delete LPU Reading
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Add'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 hfill'>
                    <div className={'flx1 jcc aic'}>
                        <Typography className={'cp fs15 fw500'}>
                                    Are you sure you want to delete this LPU Reading?
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={this.delete} type='submit' variant='contained' color='primary'>
                                OK
                    </Button>
                    <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                    </Button>
                </DialogActions>
            </Dialog>);
    };
}

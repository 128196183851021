import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import lodash from 'lodash';
import { TRANSFORMER_BREAKERS } from '../../../../appConstants';
import MenuItem from '@material-ui/core/MenuItem';

interface IPhaseSizeSelectorProps {
    phaseSize ?: number | null;

    onPhaseSizeChange : (phaseSize : number | null) => void;
}

interface IPhaseSizeSelectorState {
}

export default class PhaseSizeSelector extends React.Component<IPhaseSizeSelectorProps, IPhaseSizeSelectorState> {
    constructor(props : IPhaseSizeSelectorProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onPhaseSizeChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (event.target.value) {
            this.props.onPhaseSizeChange(Number(event.target.value));
        } else {
            this.props.onPhaseSizeChange(null);
        }
    };

    public readonly render = () => {
        const { phaseSize } = this.props;

        return (
            <FormControl fullWidth>
                <InputLabel shrink={!!phaseSize} htmlFor='phaseSize'>Phase Size</InputLabel>
                <Select
                    fullWidth
                    id='phaseSize'
                    value={!phaseSize ? '' : phaseSize}
                    onChange={this.onPhaseSizeChange}>
                    <MenuItem value=''>
                        <em>None</em>
                    </MenuItem>
                    {
                        lodash.map(TRANSFORMER_BREAKERS, (n, i) => (
                            <MenuItem key={`${i}_PhaseSize`} value={i}>
                                {i}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    };
}

import { firestore } from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import { EmployeeHelper } from '../../employee';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { TariffType } from './tariffs';
import { ITransformerInvoice, TransformerInvoiceHelper } from './transformerInvoice';
import lodash from 'lodash';
import moment from 'moment';
import { IAuthState } from '../../redux';
import { DEPT_PREFIX } from '../../../appConstants';
import Decimal from 'decimal.js';
import { IMunicipalityInvoice, MunicipalityInvoiceHelper } from './municipalityInvoice';
import { ISPSInvoice, SPSInvoiceHelper } from './spsInvoice';

export type TransformerJournalType = TariffType | 'municipality' | 'sps';
export interface ITransformerJournal extends IBasicBaseClass {
    journalNumber : string;
    type : TransformerJournalType;
    
    invoices : Array<ITransformerInvoice | IMunicipalityInvoice | ISPSInvoice>;

    totalAccount : number;
    totalVat : number;
    totalInterest : number;

    /**
     * Table lines, { group: { split/vat/account: value }}
     */
    lines : Array<ITransformerJournalSplit>;

    exported : boolean | null;
    // Path to user
    exportedBy : string | null;
    exportedByName : string | null;
    exportedByEmployeeNumber : string | null;
    exportedOn : number | null;
}

export interface ITransformerJournalSplit {
    accountNumber : string;
    amount : number;
    date : string;
    accpacAccountNumber : string;
    description : string;
    subArea : string;
    group : string;
    area : string;
    waypoint : string;
}

export default class TransformerJournalHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_journal';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerJournal> = {
        fromFirestore: (snapshot) => {
            return TransformerJournalHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerJournal) : firebase.firestore.DocumentData => {
            return TransformerJournalHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerJournal {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...result,
            type: data.type,
            lines: data.lines,
            invoices: data.invoices,
            journalNumber: data.journalNumber,
            totalAccount: data.totalAccount,
            totalVat: data.totalVat,
            totalInterest: data.totalInterest,
            exported: !!data.exported,
            exportedBy: data.exportedBy?.path ?? null,
            exportedByName: data.exportedByName ?? null,
            exportedByEmployeeNumber: data.exportedByEmployeeNumber ?? null,
            exportedOn: data.exportedOn?.toMillis() ?? null,
        };
    }

    public static toFirestoreDocument(data : ITransformerJournal) {
        const result = super.toFirestore(data);
        return {
            ...result,
            type: data.type,
            lines: data.lines,
            invoices: data.invoices,
            journalNumber: data.journalNumber,
            totalAccount: data.totalAccount,
            totalVat: data.totalVat,
            exported: !!data.exported,
            exportedBy: !data.exportedBy ? null : EmployeeHelper.path(data.exportedBy),
            exportedByName: data.exportedByName ?? null,
            exportedByEmployeeNumber: data.exportedByEmployeeNumber ?? null,
            exportedOn: !data.exportedOn ? null : firestore.Timestamp.fromMillis(data.exportedOn),
        };
    }

    public static collection() {
        return firebaseApp.firestore().collection(TransformerJournalHelper.COLLECTION_NAME).withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().doc();
        }
        return this.collection().doc(id);
    }

    public static create(journal : ITransformerJournal) {
        return firebaseApp.firestore().runTransaction(async (db) => {
            db.set(this.doc(), journal);

            journal.invoices.forEach((oldInvoice) => {
                const invoice = lodash.cloneDeep(oldInvoice); // We use deepclone here because of nested objects.

                switch (journal.type) {
                    case 'municipality':
                        db.set(MunicipalityInvoiceHelper.doc(invoice.id), {
                            ...invoice,
                            paid: true,
                            paidBy: EmployeeHelper.doc(journal.createdBy).path,
                            paidByName: journal.createdByName,
                            paidByEmployeeNumber: journal.createdByEmployee,
                            paidOn: journal.createdOn,
                            journaled: true,
                            journaledBy: EmployeeHelper.doc(journal.createdBy).path,
                            journaledByName: journal.createdByName,
                            journaledByEmployeeNumber: journal.createdByEmployee,
                            journaledOn: journal.createdOn,
                        } as IMunicipalityInvoice, {
                            merge: true,
                        });
                        break;
                    case 'sps':
                        db.set(SPSInvoiceHelper.doc(invoice.id), {
                            ...invoice,
                            paid: true,
                            paidBy: EmployeeHelper.doc(journal.createdBy).path,
                            paidByName: journal.createdByName,
                            paidByEmployeeNumber: journal.createdByEmployee,
                            paidOn: journal.createdOn,
                            journaled: true,
                            journaledBy: EmployeeHelper.doc(journal.createdBy).path,
                            journaledByName: journal.createdByName,
                            journaledByEmployeeNumber: journal.createdByEmployee,
                            journaledOn: journal.createdOn,
                        } as ISPSInvoice, {
                            merge: true,
                        });
                        break;
                    default:
                        db.set(TransformerInvoiceHelper.doc(invoice.id), {
                            ...invoice,
                            paid: true,
                            paidBy: EmployeeHelper.doc(journal.createdBy).path,
                            paidByName: journal.createdByName,
                            paidByEmployeeNumber: journal.createdByEmployee,
                            paidOn: journal.createdOn,
                            journaled: true,
                            journaledBy: EmployeeHelper.doc(journal.createdBy).path,
                            journaledByName: journal.createdByName,
                            journaledByEmployeeNumber: journal.createdByEmployee,
                            journaledOn: journal.createdOn,
                        } as ITransformerInvoice, {
                            merge: true,
                        });
                }
                
            });
        });
    }

    public static reverseInvoices(data : ITransformerJournal, invoices : Array<ITransformerInvoice>, auth : IAuthState) {
        const journal = {
            ...lodash.cloneDeep(data),
            updatedBy: auth.session?.firebaseUser.uid ?? '',
            updatedByEmployee: auth.session?.employee.EmployeeNumber ?? '',
            updatedByName: auth.session?.employee.Name ?? '',
            updatedOn: moment.utc().valueOf(),
        };

        journal.invoices.forEach(n => {
            if (invoices.some(x => x.id === n.id)) {
                n.reverted = true;
            }
        });

        return firebaseApp.firestore().runTransaction(async (db) => {
            db.set(TransformerJournalHelper.doc(journal.id), journal);
            TransformerInvoiceHelper.reverseMany(db, invoices, auth);
        });
    }

    public static readonly getSplitLines = (invoices : Array<ITransformerInvoice | IMunicipalityInvoice | ISPSInvoice>) => {
        const lines : Array<ITransformerJournalSplit> = [];
        const Dec = Decimal.clone({ precision: 9, rounding: 2 });
        invoices.forEach((invoice) => {
            const electricityAccountNumber = invoice.transformer.Company?.electricityAccountNumber ?? 'ELECTRICITY';
            const vatAccountNumber = invoice.transformer.Company?.vatAccountNumber ?? 'VAT';
            const interestAccountNumber = invoice.transformer.Company?.interestAccountNumber ?? 'INTEREST';

            const splits = invoice.transformer.UsageSplits ?? [];
            
            const vatPercentage = new Dec('vatPercentage' in invoice ? invoice.vatPercentage : invoice.vat);
            const vatValue = vatPercentage.mul(100);

            // Step 1 - Get total
            const interestAccount = new Dec('totalInterest' in invoice ? invoice.totalInterest : 0);
            const total = new Dec('totalBilled' in invoice ? invoice.totalBilled : (invoice.rebillTotal ? invoice.rebillTotal : invoice.totalIncVat));
            
            // Lets add all Fixed Amounts first
            splits.filter(x => x.FixedAmount).forEach(split => {
                let splitResult = 0;
                let amount = total.minus(interestAccount);
    
                if (!split.IncludeVat) {
                    const r = amount.mul(vatPercentage);
                    const b = vatPercentage.plus(1);
                    amount = amount.minus(r.div(b));
                }
    
                splitResult = lodash.min([split.FixedAmount, (amount.toNumber())]) ?? 0;

                lines.push({
                    accountNumber: invoice.transformer.AccountNumber ?? '',
                    accpacAccountNumber: `${DEPT_PREFIX}${split.Dept}`,
                    amount: splitResult,
                    area: invoice.transformer.Division,
                    date: 'dueDate' in invoice ? (moment.utc(invoice.dueDate).format('YYYY/MM/DD')) : (invoice.paymentDueDate ?? ''),
                    description: '',
                    group: invoice.transformer.EPGroup ?? '',
                    subArea: invoice.transformer.SubArea,
                    waypoint: invoice.transformer.EPNumber,
                });
            });

            // Step 2 - Get VAT
            const totalBilled = new Dec('totalBilled' in invoice ? invoice.totalBilled : (invoice.rebillTotal ? invoice.rebillTotal : invoice.totalIncVat));
            const vat = totalBilled.minus(interestAccount).mul(vatValue.div(vatValue.plus(100)));

            // Step 3 - Get VAT Exclusive Total
            const excludeVat = totalBilled.minus(vat);

            // Step 4 - Get Total Fixed, Not needed

            const vatExclusiveFixed = splits.filter(x => x.FixedAmount && !x.IncludeVat).map(x => x.FixedAmount ?? 0).reduce((a, b) => a.plus(new Dec(b)), new Decimal(0));

            // Step 5 - Get total Fixed unclaimable vat without vat
            const step5 = vatExclusiveFixed.mul(100).div(vatValue.plus(100));
            const step5Vat = vatExclusiveFixed.mul(vatValue).div(vatValue.plus(100));

            // Step 6 - Get total Fixed vat
            const step6 = splits.filter(x => x.FixedAmount && !!x.IncludeVat).map(x => x.FixedAmount ?? 0).reduce((a, b) => a.plus(new Dec(b)), new Dec(0));
            
            // Step 7
            const step7 = excludeVat.minus(step5).minus(step6);

            // Step 8
            splits.filter(x => x.Percentage && x.IncludeVat).forEach(split => {
                const splitResult = step7.mul(new Dec(split.Percentage ?? 0).div(100));

                lines.push({
                    accountNumber: invoice.transformer.AccountNumber ?? '',
                    accpacAccountNumber: `${DEPT_PREFIX}${split.Dept}`,
                    amount: splitResult.toNumber(),
                    area: invoice.transformer.Division,
                    date: 'dueDate' in invoice ? (moment.utc(invoice.dueDate).format('YYYY/MM/DD')) : (invoice.paymentDueDate ?? ''),
                    description: '',
                    group: invoice.transformer.EPGroup ?? '',
                    subArea: invoice.transformer.SubArea,
                    waypoint: invoice.transformer.EPNumber,
                });
            });

            // Step 9
            let step9 = new Dec(0);
            splits.filter(x => x.Percentage && !x.IncludeVat).forEach(split => {
                let splitResult = step7.mul(new Dec(split.Percentage ?? 0).div(100));

                step9 = step9.plus(splitResult);
                splitResult = splitResult.plus(splitResult.mul(vatPercentage));
                lines.push({
                    accountNumber: invoice.transformer.AccountNumber ?? '',
                    accpacAccountNumber: `${DEPT_PREFIX}${split.Dept}`,
                    amount: splitResult.toNumber(),
                    area: invoice.transformer.Division,
                    date: 'dueDate' in invoice ? (moment.utc(invoice.dueDate).format('YYYY/MM/DD')) : (invoice.paymentDueDate ?? ''),
                    description: '',
                    group: invoice.transformer.EPGroup ?? '',
                    subArea: invoice.transformer.SubArea,
                    waypoint: invoice.transformer.EPNumber,
                });
            });

            // Step 10
            const step10 = step9.mul(vatPercentage);
            const step10NoClaim = step5Vat.plus(step10);

            // Step 11
            const vatAccount = vat.minus(step10NoClaim);
            
            // VAT
            lines.push({
                accountNumber: invoice.transformer.AccountNumber ?? '',
                accpacAccountNumber: vatAccountNumber,
                amount: vatAccount.toNumber(),
                area: invoice.transformer.Division,
                date: 'dueDate' in invoice ? (moment.utc(invoice.dueDate).format('YYYY/MM/DD')) : (invoice.paymentDueDate ?? ''),
                description: 'VAT',
                group: invoice.transformer.EPGroup ?? '',
                subArea: invoice.transformer.SubArea,
                waypoint: invoice.transformer.EPNumber,
            });

            // Interest
            if (!interestAccount.isZero()) {
                lines.push({
                    accountNumber: invoice.transformer.AccountNumber ?? '',
                    accpacAccountNumber: interestAccountNumber,
                    amount: interestAccount.toNumber(),
                    area: invoice.transformer.Division,
                    date: 'dueDate' in invoice ? (moment.utc(invoice.dueDate).format('YYYY/MM/DD')) : (invoice.paymentDueDate ?? ''),
                    description: 'INTEREST',
                    group: invoice.transformer.EPGroup ?? '',
                    subArea: invoice.transformer.SubArea,
                    waypoint: invoice.transformer.EPNumber,
                });
            }

            // ESKOM
            lines.push({
                accountNumber: invoice.transformer.AccountNumber ?? '',
                accpacAccountNumber: electricityAccountNumber,
                amount: -total,
                area: invoice.transformer.Division,
                date: 'dueDate' in invoice ? (moment.utc(invoice.dueDate).format('YYYY/MM/DD')) : (invoice.paymentDueDate ?? ''),
                description: 'ELECTRICITY',
                group: invoice.transformer.EPGroup ?? '',
                subArea: invoice.transformer.SubArea,
                waypoint: invoice.transformer.EPNumber,
            });
        });

        return lodash
            .chain(lines)
            .groupBy(x => `${x.accpacAccountNumber}_${x.accountNumber}`)
            .values()
            .map(n => ({
                accountNumber: n[0].accountNumber,
                accpacAccountNumber: n[0].accpacAccountNumber,
                amount: lodash.sumBy(n, x => x.amount),
                area: n[0].area,
                date: n[0].date,
                description: n[0].description,
                group: n[0].group,
                subArea: n[0].subArea,
                waypoint: n[0].waypoint,
            }))
            .value();
    };
}
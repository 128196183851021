import firebase, { firestore } from 'firebase/app';
import appFunctionsService from '../../../services/appFunctionsService';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import TransformerHelper from '../transformer/transformer';
import { IFileMetaData } from '../files/fileMetaData';

export interface IDamFile {
    guid : string;
    firestoreLocation : string;
    downloadURL : string | null;
    thumbnailUrl : string | null;
    name : string;
    type : EnumDamFileType;
    uploadDate : number;
    contentType : string;
}

export enum EnumDamType {
    Dam,
    Reservoir,
}

export enum EnumDamConstructionType {
    Gatdam,
    Gronddam,
    BelyndeGronddam,
    Sementdam,
}

export enum EnumDamHazardPotential {
    Significant,
    Low,
}

export enum EnumDamWallType {
    Earthfill,
    Arch,
    Gravity,
    SideChannel,
    ConcreteSpilway,
    RubelMasenary,
}

export enum EnumDamSize {
    Small,
    Medium,
    Large,
}

export enum EnumDamWallSurfaceProtection {
    Gravel,
    Soil,
    Grass,
}
  
export enum EnumDamSlopeSurfaceProtection {
    RipRap,
    Liner,
    Grass,
    Other,
}


export enum EnumDamStatus {
    Registered,
    NotRegistered,
}

export interface IDamVolume {
    plate : number;
    elevation : number;
    volume : number;
    percentage : number;
    belowSpillway : number | null;
    number : number | null;
}

export enum EnumDamFileType {
    Registration = 0,
    SafetyReport = 1,
    WeeklyReport = 2,
    WeeklyReadingMeasure = 3,
    VNotchReadingMeasure = 4,
    ElectricalBox = 5,
}

export interface IDamLatestReadings {
    thisWeek : IDamReading | null;
    lastWeek : IDamReading | null;
    lastLastWeek : IDamReading | null;
}

export interface IDamReading {
    date : firebase.firestore.Timestamp;
    capacity : number;
    volume : number;
}

export interface IDamVNotch {
    height : number;
    flow : number;
}

export interface IDam extends IBasicBaseClass {
    capacity : number | null;
    catchmentArea : number | null;
    constructionType : EnumDamConstructionType | null;
    classificationDate : number | null;
    code : string;
    comment : string | null;
    completionDate : number | null;
    completionDateRaised : number | null;
    constructionEndDate : number | null;
    contractor : string | null;
    crestLength : number | null;
    damFeed : string | null;
    damType : EnumDamType;
    designer : string | null;
    distanceFromTown : number | null;
    division : string | null;
    farmPortion : string | null;
    geo : firebase.firestore.GeoPoint | null;
    hazardPotential : EnumDamHazardPotential | null;
    lastDSIDate : number | null;
    lastDSINumber : number | null;
    managementArea : string | null;
    divisionDescriptionAccpac : string | null;
    divisionNumber : number | null;
    measurementStick : boolean | null;
    municipalDistrict : string | null;
    name : string;
    nearestTown : string | null;
    ownerName : string | null;
    parentFarm : string | null;
    provinceCode : string | null;
    purpose : string | null;
    quaternaryDrainageArea : string | null;
    regionCode : string | null;
    registeredCapacity : number | null;
    registrationDate : number | null;
    riskCategory : string | null;
    riverOrWaterCourse : string | null;
    sector : string | null;
    size : EnumDamSize | null;
    spillwayType : string | null;
    status : EnumDamStatus | null;
    surfaceArea : number | null;
    surveyed : boolean | null;
    surveyType : string | null;
    targetDate : number | null;
    titleDeed : string | null;
    wallHeight : number | null;
    wallType : EnumDamWallType | null;
    wallProtectionSurface : EnumDamWallSurfaceProtection | null;
    warmsId : string | null;
    warmsNo : string | null;
    waterManagementArea : string | null;

    slopeSurfaceProtection : EnumDamSlopeSurfaceProtection | null;
    slopeSurfaceProtectionOther : string | null;

    percentageFull : number | null;
    elevation : number | null;
    damVolumes : Array<IDamVolume>;
    damVNotch : Array<IDamVNotch> | null;

    damLatestReadings : IDamLatestReadings | null;

    lastInspectionDate : number | null;
    nextInspectionDate : number | null;

    transformerRef : string | null;
    transformerNumber : string | null;

    electricalBoxFiles : Array<IDamFile>;

    isSent : boolean;
}

export interface ReadingGraphData {
    date : firebase.firestore.Timestamp;
    capacity : number;
    label : string;
}

export default class DamHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'dam';

    public static DamType : Record<EnumDamType, string> = {
        [EnumDamType.Dam]: 'Dam',
        [EnumDamType.Reservoir]: 'Reservoir',
    };

    public static DamConstructionType : Record<EnumDamConstructionType, string> = {
        [EnumDamConstructionType.Gatdam]: 'Gatdam',
        [EnumDamConstructionType.Gronddam]: 'Gronddam',
        [EnumDamConstructionType.BelyndeGronddam]: 'Belynde Gronddam',
        [EnumDamConstructionType.Sementdam]: 'Sementdam',
    };

    public static HazardPotential : Record<EnumDamHazardPotential, string> = {
        [EnumDamHazardPotential.Significant]: 'Significant',
        [EnumDamHazardPotential.Low]: 'Low',
    };

    public static DamStatus : Record<EnumDamStatus, string> = {
        [EnumDamStatus.Registered]: 'Registered',
        [EnumDamStatus.NotRegistered]: 'Not Registered',
    };

    public static DamWallType : Record<EnumDamWallType, string> = {
        [EnumDamWallType.Arch]: 'Arch',
        [EnumDamWallType.Earthfill]: 'Earthfill',
        [EnumDamWallType.Gravity]: 'Gravity',
        [EnumDamWallType.SideChannel]: 'Side Channel',
        [EnumDamWallType.RubelMasenary]: 'Rubel Masenary',
        [EnumDamWallType.ConcreteSpilway]: 'Concrete Spilway',
    };

    public static DamWallSurfaceProtection : Record<EnumDamWallSurfaceProtection, string> = {
        [EnumDamWallSurfaceProtection.Grass]: 'Grass',
        [EnumDamWallSurfaceProtection.Gravel]: 'Gravel',
        [EnumDamWallSurfaceProtection.Soil]: 'Soil',
    };

    public static DamSlopeSurfaceProtection : Record<EnumDamSlopeSurfaceProtection, string> = {
        [EnumDamSlopeSurfaceProtection.Grass]: 'Grass',
        [EnumDamSlopeSurfaceProtection.Liner]: 'Liner',
        [EnumDamSlopeSurfaceProtection.Other]: 'Other',
        [EnumDamSlopeSurfaceProtection.RipRap]: 'Rip-Rap',
    };

    public static DamSize : Record<EnumDamSize, string> = {
        [EnumDamSize.Large]: 'Large',
        [EnumDamSize.Medium]: 'Medium',
        [EnumDamSize.Small]: 'Small',
    };

    private static converter : firebase.firestore.FirestoreDataConverter<IDam> = {
        fromFirestore: (snapshot) => {
            return DamHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDam) : firebase.firestore.DocumentData => {
            return DamHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDam {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        if (!data) throw new Error(`Document does not exist! ${snapshot.id}`);

        return {
            ...data,
            ...result,
            constructionType: 'constructionType' in data ? data.constructionType : null,
            classificationDate: data.classificationDate?.toMillis() ?? null,
            completionDate: data.completionDate?.toMillis() ?? null,
            completionDateRaised: data.completionDateRaised?.toMillis() ?? null,
            constructionEndDate: data.constructionEndDate?.toMillis() ?? null,
            lastDSIDate: data.lastDSIDate?.toMillis() ?? null,
            registrationDate: data.registrationDate?.toMillis() ?? null,
            targetDate: data.targetDate?.toMillis() ?? null,
            lastInspectionDate: data.lastInspectionDate?.toMillis() ?? null,
            nextInspectionDate: data.nextInspectionDate?.toMillis() ?? null,
            wallProtectionSurface: data.wallProtectionSurface ?? null,
            transformerRef: !data.transformerRef ? null : (data.transformerRef as firebase.firestore.DocumentReference).id,
            electricalBoxFiles: !data.electricalBoxFiles ? [] : data.electricalBoxFiles,
        } as IDam;
    }

    public static toFirestoreDocument(data : IDam) {
        const result = super.toFirestore(data);
        const {
            id: _id,
            electricalBoxFiles: _,
            ...rest
        } = data;

        return {
            ...rest,
            ...result,
            classificationDate: data.classificationDate ? firestore.Timestamp.fromMillis(data.classificationDate) : null,
            completionDate: data.completionDate ? firestore.Timestamp.fromMillis(data.completionDate) : null,
            completionDateRaised: data.completionDateRaised ? firestore.Timestamp.fromMillis(data.completionDateRaised) : null,
            constructionEndDate: data.constructionEndDate ? firestore.Timestamp.fromMillis(data.constructionEndDate) : null,
            lastDSIDate: data.lastDSIDate ? firestore.Timestamp.fromMillis(data.lastDSIDate) : null,
            registrationDate: data.registrationDate ? firestore.Timestamp.fromMillis(data.registrationDate) : null,
            targetDate: data.targetDate ? firestore.Timestamp.fromMillis(data.targetDate) : null,
            lastInspectionDate: data.lastInspectionDate ? firestore.Timestamp.fromMillis(data.lastInspectionDate) : null,
            nextInspectionDate: data.nextInspectionDate ? firestore.Timestamp.fromMillis(data.nextInspectionDate) : null,
            transformerRef: data.transformerRef ? TransformerHelper.doc(data.transformerRef) : null,
        };
    }

    public static save(dam : IDam) {
        return DamHelper.doc(dam.id).set(dam, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(DamHelper.COLLECTION_NAME).withConverter(DamHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(DamHelper.COLLECTION_NAME).withConverter(DamHelper.converter).doc(id);
    }
    
    public static listen() {
        return firebaseApp
            .firestore()
            .collection(DamHelper.COLLECTION_NAME)
            .withConverter(DamHelper.converter);
    }

    public static mapToCsv(dams : Array<IDam>) {
        return dams.map(n => ({
            code: n.code,
            name: n.name,
            type: n.damType,
            constructionType: n.constructionType,
            measurementStick: n.measurementStick,
            surveyed: n.surveyed,
            surveyType: n.surveyType,
            riskCategory: n.riskCategory,
            comments: n.comment,
            quaternaryDrainageArea: n.quaternaryDrainageArea,
            waterManagementArea: n.waterManagementArea,
            nearestTown: n.nearestTown,
            distanceFromTown: n.distanceFromTown,
            parentFarm: n.parentFarm,
            farmPortion: n.farmPortion,
            municipalDistrict: n.municipalDistrict,
            provinceCode: n.provinceCode,
            regionCode: n.regionCode,
            riverOrWaterCourse: n.riverOrWaterCourse,
            wallType: n.wallType,
            wallProtectionSurface: n.wallProtectionSurface,
            wallHeight: n.wallHeight,
            crestLength: n.crestLength,
            capacity: n.capacity,
            surfaceArea: n.surfaceArea,
            catchmentArea: n.catchmentArea,
            ownerName: n.ownerName,
            designer: n.designer,
            contractor: n.contractor,
            registrationDate: appFunctionsService.formatDateTime(n.registrationDate ?? ''),
            size: n.size,
            hazardPotential: n.hazardPotential,
            classificationDate: appFunctionsService.formatDateTime(n.classificationDate ?? ''),
            lastDSIDate: appFunctionsService.formatDateTime(n.lastDSIDate ?? ''),
            lastDSINumber: n.lastDSINumber,
            percentageFull: n.percentageFull,
            elevation: n.elevation,
            completionDateRaised: appFunctionsService.formatDateTime(n.completionDate ?? ''),
            constructionEndDate: appFunctionsService.formatDateTime(n.constructionEndDate ?? ''),
            damFeed: n.damFeed,
            division: n.division,
            latitude: !n.geo ? 0 : n.geo.latitude,
            longitude: !n.geo ? 0 : n.geo.longitude,
            managementArea: n.managementArea,
            divisionDescriptionAccpac: n.divisionDescriptionAccpac,
            divisionNumber: n.divisionNumber,
            sector: n.sector,
            spillwayType: n.spillwayType,
            status: n.status,
            targetDate: n.targetDate,
            titleDeed: n.titleDeed,
            warmsId: n.warmsId,
            purpose: n.purpose,
            createdOn: appFunctionsService.formatDateTime(n.createdOn),
            updatedOn: appFunctionsService.formatDateTime(n.updatedOn),
        }));
    }

    public static getReadingChartData(dam : IDam) {
        const readingSet : Array<ReadingGraphData> = [];
        if (!dam.damLatestReadings) return readingSet;
        
        if (dam.damLatestReadings.thisWeek) readingSet.push({
            'date': dam.damLatestReadings.thisWeek.date,
            'capacity': dam.damLatestReadings.thisWeek.capacity * 100,
            'label': 'This Week',
        });
        
        if (dam.damLatestReadings.lastWeek) readingSet.push({
            'date': dam.damLatestReadings.lastWeek.date,
            'capacity': dam.damLatestReadings.lastWeek.capacity * 100,
            'label': 'Last Week',
        });
        
        if (dam.damLatestReadings.lastLastWeek) readingSet.push({
            'date': dam.damLatestReadings.lastLastWeek.date,
            'capacity': dam.damLatestReadings.lastLastWeek.capacity * 100,
            'label': '2nd Last Week',
        });

        return readingSet.sort((a, b) => a.date.toMillis() - b.date.toMillis());
    }

    public static uploadFile(damCode : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `dam/${damCode}/${new Date().valueOf()}_${file.name}`, metadata);
    }
}
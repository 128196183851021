import React from 'react';
import BoreholeConstantRateEntryLoggerCardView from './ConstantRateEntryLogistcsCardView';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { IBoreholeConstantRateTest } from '../../../../@types/model/borehole/boreholeConstantRateTest';

interface IBoreholeConstantRateEntryLoggerViewProps {
    constantRateTest : IBoreholeConstantRateTest;
}

interface IBoreholeConstantRateEntryLoggerViewState {
    
}

export default class BoreholeConstantRateEntryLoggerView extends React.Component<IBoreholeConstantRateEntryLoggerViewProps, IBoreholeConstantRateEntryLoggerViewState> {
    constructor(props : IBoreholeConstantRateEntryLoggerViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { constantRateTest } = this.props;

        return (
            <div className='fdc aifs p5'>
                <div className='fdr aic jcc'>
                    <BoreholeConstantRateEntryLoggerCardView
                        title={'LEVEL LOGGER DEPTH (m)'}
                        value={constantRateTest.levelLoggerDepth?.toFixed(2) ?? ''}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLoggerCardView
                        title={'LEVEL LOGGER IN'}
                        value={!constantRateTest.levelLoggerDate ?
                            '' :
                            `${constantRateTest.levelLoggerTime} | ${AppFunctionsService.formatDateTimeToDateOnly(constantRateTest.levelLoggerDate)}`
                        }
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLoggerCardView
                        title={'PUMP SWITCHED ON'}
                        value={!constantRateTest.levelLoggerPumpOnDate ?
                            '' :
                            `${constantRateTest.levelLoggerPumpOnTime} | ${AppFunctionsService.formatDateTimeToDateOnly(constantRateTest.levelLoggerPumpOnDate)}`
                        }
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLoggerCardView
                        title={'PUMP SWITCHED OFF'}
                        value={!constantRateTest.levelLoggerPumpOffDate ?
                            '' :
                            `${constantRateTest.levelLoggerPumpOffTime} | ${AppFunctionsService.formatDateTimeToDateOnly(constantRateTest.levelLoggerPumpOffDate)}`
                        }
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLoggerCardView
                        title={'LEVEL LOGGER OUT'}
                        value={!constantRateTest.levelLoggerOutDate ?
                            '' :
                            `${constantRateTest.levelLoggerOutTime ?? '-'} | ${AppFunctionsService.formatDateTimeToDateOnly(constantRateTest.levelLoggerOutDate)}`
                        }
                    />
                </div>
            </div>
        );
    };
}

import { IGeneralState, GeneralAction } from '../../@types/redux';
import * as generalActions from './actions';
import { getType } from 'typesafe-actions';

const initialState = {
    company: '',
    notifications: [],
};

export default function generalReducer(state : IGeneralState = initialState, action : GeneralAction) : IGeneralState {
    switch (action.type) {
        case getType(generalActions.enqueueSnackbar):
            return { ...state, notifications: [
                ...state.notifications,
                {
                    ...action.payload,
                },
            ] };
        case getType(generalActions.removeSnackbar):
            return { ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.payload,
                ) };
        default:
            return state;
    }
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import { IRootState } from '../../../../@types/redux';
import { Transitions } from '../../../customComponents/animations/Transitions';
import EditBoreholeDivisionInfo from '../../dialog/EditDivisionInfo';

interface IBoreholeDivisionInfoViewProps {
    borehole : IBorehole;

    isLoading : boolean;

    isBoreholeAdmin : boolean;
}

interface IBoreholeDivisionInfoViewState {}

class BoreholeDivisionInfoViewComponent extends React.PureComponent<IBoreholeDivisionInfoViewProps, IBoreholeDivisionInfoViewState> {
    constructor(props : IBoreholeDivisionInfoViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { isBoreholeAdmin, borehole, isLoading } = this.props;

        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            DIVISION INFORMATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isBoreholeAdmin &&
                                    <EditBoreholeDivisionInfo
                                        borehole={borehole}
                                        isLoading={isLoading}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdc flx1 ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DIVISION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.Division ? borehole.Division : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    MANAGMENT AREA
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.ManagementArea ? borehole.ManagementArea : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DIVISION NUMBER
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.DivisionNumber ? borehole.DivisionNumber : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    ACCPAC DESCRIPTION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.DivisionDescriptionAccpac ? borehole.DivisionDescriptionAccpac : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    USER
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.User ? borehole.User : 'None'}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
        isLoading: state.borehole.loading,
    };
};

const BoreholeDivisionInfoView = connect(
    mapStateToProps,
)(BoreholeDivisionInfoViewComponent);

export default BoreholeDivisionInfoView;

import React from 'react';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { IUserSession } from '../../../@types/employee';
import TransformerGuaranteeHelper, { ITransformerGuarantee } from '../../../@types/model/transformer/transformerGuarantee';
import GeneralFunctions from '../../../store/general/functions';
import { AccordionDetails, CircularProgress, IconButton, Tooltip, Typography } from '@material-ui/core';
import AppFunctionsService from '../../../services/appFunctionsService';
import { Add, Delete, Edit, ExpandMore } from '@material-ui/icons';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import StandardFab from '../../customComponents/button/StandardFab';
import moment from 'moment';
import EditTransformerGuaranteeDialog from './Edit';

interface ITransformerGuaranteeListViewProps {
    transformer : ITransformer;
    session ?: IUserSession | null;
}

interface ITransformerGuaranteeListViewState {
    isLoading : boolean;

    guarantees : Array<ITransformerGuarantee>;
    expanded : Array<string>;

    selected : ITransformerGuarantee | null;
    selectedDelete : ITransformerGuarantee | null;
}

export default class TransformerGuaranteeListView extends React.PureComponent<ITransformerGuaranteeListViewProps, ITransformerGuaranteeListViewState> {
    private listener ?: () => void;
    constructor(props : ITransformerGuaranteeListViewProps) {
        super(props);
        this.state = {
            isLoading: false,
            guarantees: [],
            expanded: [],
            selected: null,
            selectedDelete: null,
        };
    }

    public componentDidMount() : void {
        this.load();
    }

    public componentWillUnmount() : void {
        if (this.listener) {
            this.listener();
        }
    }

    private readonly onAddClick = () => {
        const {
            session,
            transformer,
        } = this.props;

        if (!session) return;
        this.setState({
            selected: {
                id: '',
                isWeb: true,
                createdBy: session.firebaseUser.uid,
                createdByEmployee: session.employee.EmployeeNumber ?? '',
                createdByName: session.firebaseUser.displayName ?? '',
                createdOn: moment.utc().valueOf(),
                updatedBy: session.firebaseUser.uid,
                updatedByEmployee: session.employee.EmployeeNumber ?? '',
                updatedByName: session.firebaseUser.displayName ?? '',
                updatedOn: moment.utc().valueOf(),
                transformerRef: transformer.ref.id,
            } as ITransformerGuarantee,
        });
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const selected = this.state.guarantees.slice().find(n => n.id === event.currentTarget.value) ?? null;
        this.setState({
            selected,
        });
    };

    private readonly onEditClose = () => {
        this.setState({
            selected: null,
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const selectedDelete = this.state.guarantees.slice().find(n => n.id === event.currentTarget.value) ?? null;
        this.setState({
            selectedDelete,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.state.selectedDelete) {
            TransformerGuaranteeHelper.delete({
                ...this.state.selectedDelete,
            });
        }
        this.setState({
            selectedDelete: null,
        });
    };

    private readonly onExpandClick = (event : React.MouseEvent<HTMLDivElement>) => {
        const expanded = this.state.expanded.slice();
        const index = expanded.indexOf(event.currentTarget.id);

        if (index > -1) {
            expanded.splice(index, 1);
        } else {
            expanded.push(event.currentTarget.id);
        }

        this.setState({
            expanded,
        });
    };

    private readonly load = () => {
        if (this.listener) {
            this.listener();
        }

        const { transformer } = this.props;

        this.setState({
            isLoading: true,
        });

        this.listener = TransformerGuaranteeHelper.collection(transformer.ref).onSnapshot((snapshot) => {
            const guarantees = this.state.guarantees.slice();
            snapshot.docChanges().forEach((f) => {
                const data = f.doc.data();
                const index = guarantees.findIndex(n => n.id === data.id);

                switch (f.type) {
                    case 'added':
                        guarantees.push(data);
                        break;
                    case 'modified':
                        guarantees.splice(index, 1, data);
                        break;
                    case 'removed':
                        guarantees.splice(index, 1);
                        break;
                }
            });

            this.setState({
                isLoading: false,
                guarantees: guarantees.sort((a, b) => b.date - a.date),
            });
        }, (error) => {
            this.setState({
                isLoading: false,
            });

            GeneralFunctions.generalShowError(error, 'Error loading guarantees');
        });
    };

    public readonly render = () => {
        const {
            isLoading,
            guarantees,
            expanded,
            selectedDelete,
            selected,
        } = this.state;
        const {
            session,
            transformer,
        } = this.props;

        return (
            <div className='fdc flx1 p20 bcg0'>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !guarantees.length &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography>No Data</Typography>
                    </div>
                }
                {
                    guarantees.map(n => (
                        <SeparatedAccordion
                            key={n.id}
                            expanded={expanded.includes(n.id)}
                        >
                            <PrimaryAccordionSummary
                                id={n.id}
                                expandIcon={<ExpandMore />} 
                                onClick={this.onExpandClick}
                            >
                                <div className='fdr jcc aic flx1'>
                                    {
                                        !expanded.includes(n.id) &&
                                        AppFunctionsService.formatDateTimeToDateOnly(n.date)
                                    }
                                    {
                                        expanded.includes(n.id) &&
                                        <>
                                            <div className='fdc mr50'>
                                                <Typography className='fs12'>Date</Typography>
                                                <Typography className='fs16 fwm mt5'>{AppFunctionsService.formatDateTimeToDateOnly(n.date)}</Typography>
                                            </div>
                                            <div className='fdc mr50'>
                                                <Typography className='fs12'>Bank</Typography>
                                                <Typography className='fs16 fwm mt5'>{n.bank}</Typography>
                                            </div>
                                            <div className='fdc'>
                                                <Typography className='fs12'>Guarantee Amount</Typography>
                                                <Typography className='fs16 fwm mt5'>{n.guaranteeAmount?.toLocaleCurrency() ?? '-'}</Typography>
                                            </div>
                                        </>
                                    }
                                    <span className='flx1' />
                                    {
                                        session?.employee.IsTransformerAdmin &&
                                        <Tooltip title='Edit'>
                                            <div>
                                                <IconButton size='small' value={n.id} color='inherit' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                                                    <Edit />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    }
                                    {
                                        session?.employee.IsTransformerAdmin &&
                                        <Tooltip title='Delete'>
                                            <div>
                                                <IconButton size='small' value={n.id} color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoading}>
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            </PrimaryAccordionSummary>
                            <AccordionDetails className={'fdc'}>
                                <div className='fdr'>
                                    <div className={'bcg0 fdc flx1 aic jcc mr15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            DATE
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {AppFunctionsService.formatDateTimeToDateOnly(n.date)}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc ml15 mr15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            CASH
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.cash?.toLocaleCurrency() ?? '-'}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc ml15 mr15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            INTEREST
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.interest?.toLocaleCurrency() ?? '-'}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc ml15 mr15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            NON CASH DEPOSIT
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.nonCashDeposit?.toLocaleCurrency() ?? '-'}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc ml15 mr15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            BANK NAME
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.bank}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc ml15 mr15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            GUARANTEE NUMBER
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.guaranteeNumber}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc ml15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            GUARANTEE AMOUNT
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.guaranteeAmount?.toLocaleCurrency() ?? '-'}
                                        </Typography>
                                    </div>
                                </div>
                                <div className='fdr mt15'>
                                    <div className={'bcg0 fdc flx1 aic jcc mr15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            BLANKET
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.blanket}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc ml15 mr15 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            ATTENTION
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.attention}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx5 aic jcc ml15 pl109 pr109'}>
                                        <Typography className={'fs11 cdg'}>
                                            COMMENT
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {n.comment}
                                        </Typography>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </SeparatedAccordion>
                    ))
                }
                <ConfirmDialog
                    open={!!selectedDelete}
                    title={!selectedDelete ? '' : `Confirm Delete ${AppFunctionsService.formatDateTimeToDateOnly(selectedDelete.date)}`}
                    message={!selectedDelete ? '' : `Delete Guarantee on ${AppFunctionsService.formatDateTimeToDateOnly(selectedDelete.date)}, this cannot be undone?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>

                {
                    !isLoading &&
                    session?.employee.IsTransformerAdmin &&
                    <div className='fdr aife jcfe'>
                        <Tooltip title='Add'>
                            <div>
                                <StandardFab onClick={this.onAddClick}>
                                    <Add />
                                </StandardFab>
                            </div>
                        </Tooltip>
                    </div>
                }
                {
                    session &&
                    <EditTransformerGuaranteeDialog
                        maxWidth='md'
                        value={selected}
                        onClose={this.onEditClose}
                        fullWidth
                        session={session}
                        transformer={transformer}
                    />
                }

            </div>
        );
    };
}

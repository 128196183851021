import React from 'react';
import '../../../style/transformerTariffs.scss';
import Paper from '@material-ui/core/Paper';
import firebaseApp from '../../../services/firebaseService';
import generalFunctions from '../../../store/general/functions';
import TariffHelper, { ILPUTariff } from '../../../@types/model/transformer/tariffs';
import { Formik, FieldArray } from 'formik';
import NumberHelper from '../../../services/helper/numberHelper';
import { connect } from 'react-redux';
import { IRootState, IAuthState } from '../../../@types/redux';
import StadiumButton from '../../customComponents/button/StadiumButton';
import OutlinedStadiumButton from '../../customComponents/button/OutlinedStadiumButton';
import AbsoluteLinearProgress from '../../customComponents/progress/AbsoluteLinearProgress';
import DateMillisInput from '../../customComponents/form/DateMillisInput';
import FirebaseHelper from '../../../services/helper/firebaseHelper';

interface ILPUEditProps {
    auth : IAuthState;

    lpu : ILPUTariff;
    vat : number;

    onSaved ?: () => void;

    enabled ?: boolean;
    disabledStartDate ?: boolean;
}

interface ILPUEditState {
}

class LPUEdit extends React.PureComponent<ILPUEditProps, ILPUEditState> {
    constructor(props : ILPUEditProps) {
        super(props);
        this.state = {
        };
    }

    private readonly save = async (values : ILPUTariff) => {
        if (!this.props.auth.session || !this.props.enabled) return;
        const lpu = { ...values };

        try {
            lpu.vat = this.props.vat;

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!lpu.ref) {
                lpu.ref = TariffHelper.lpuDoc();
                lpu.createdBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session.firebaseUser.uid);
                lpu.createdByEmployee = this.props.auth.session.employee.EmployeeNumber ?? '';
                lpu.createdByName = this.props.auth.session.employee.Name;
                lpu.createdOn = FirebaseHelper.timestampNow();
            }

            lpu.updatedBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session.firebaseUser.uid);
            lpu.updatedByEmployee = this.props.auth.session.employee.EmployeeNumber ?? '';
            lpu.updatedByName = this.props.auth.session.employee.Name;
            lpu.updatedOn = FirebaseHelper.timestampNow();

            await TariffHelper.saveLPU(lpu);
            if (this.props.onSaved) this.props.onSaved();
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error saving LPU Tariff');
            throw ex;
        }

    };

    public readonly onReset = () => {
        if (this.props.onSaved) this.props.onSaved();
    };

    public readonly render = () => {
        const { lpu, vat, enabled, disabledStartDate } = this.props;
        return (
            <div className='fdc flx1 ais pr20 pl20 pt10 pb20 transformer-tariffs lpu oya'>
                <Formik
                    enableReinitialize
                    initialValues={lpu}
                    onSubmit={this.save}
                    onReset={this.onReset}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        isSubmitting,
                    }) => (
                        <form id='lpu-form' className='' onSubmit={handleSubmit} onReset={handleReset}>
                            <div className='fdr pb20'>
                                <DateMillisInput name='startDate' label='Start Date' className='' disabled={isSubmitting || !enabled || disabledStartDate} />
                                {
                                    !!lpu.endDate &&
                                    <DateMillisInput name='endDate' label='End Date' className='ml20' disabled />
                                }
                            </div>
                            <Paper className='fdc ais mb10' elevation={3}>
                                <div className='fdr ais'>
                                    <div className='fdc ais flx1'>
                                        <div className='fdr flx2 jcc aic'>
                                            <div className='fdr flx1 aic jcc vat-header'>
                                                CURRENT VAT
                                            </div>
                                            {
                                                !!vat &&
                                                <div className='fdr flx1 aic jcc vat-value ml10 mr10'>
                                                    <span className='p10'>{vat * 100} %</span>
                                                </div>
                                            }
                                            {
                                                !vat &&
                                                <div className='fdr flx1 aic jcc vat-value error ml10 mr10'>
                                                    <span className='p10'>ERROR</span>
                                                </div>
                                            }
                                        </div>
                                        <div className='fdr flx1 mnh65'>
                                            <div className='fdr flx1 aic jcc transmission-zone'>
                                                Transmission zone
                                            </div>
                                            <div className='fdr flx1 aic jcc voltage'>
                                                Voltage
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fdc ais flx4'>
                                        <div className='fdc flx1 ais jcc average'>
                                            Active Energy Charge [c/kWh]
                                        </div>
                                        <div className='fdr flx1 ais jcc'>
                                            <div className='fdr flx1 aic jcc high-header'>
                                                <span>High Demand Season</span>&nbsp;[Jun - Aug]
                                            </div>
                                            <div className='fdr flx1 aic jcc low-header'>
                                                <span>Low Demand Season </span>&nbsp;[Sep - May]
                                            </div>
                                        </div>
                                        <div className='fdr flx1 ais mnh65 jcc'>
                                            <div className='fdr flx1 ais'>
                                                <div className='fdc flx1 ais pt10 high peak'>
                                                    <span className='fdr flx1 jcc'>
                                                        Peak
                                                    </span>
                                                    <div className='fdc aife jcfe vat'>
                                                        VAT incl
                                                    </div>
                                                </div>
                                                <div className='fdc flx1 ais pt10 high standard'>
                                                    <span className='fdr flx1 jcc'>
                                                        Standard
                                                    </span>
                                                    <div className='fdc aife jcfe vat'>
                                                        VAT incl
                                                    </div>
                                                </div>
                                                <div className='fdc flx1 ais pt10 high off-peak'>
                                                    <span className='fdr flx1 jcc'>
                                                        Off Peak
                                                    </span>
                                                    <div className='fdc aife jcfe vat'>
                                                        VAT incl
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='fdr flx1 ais'>
                                                <div className='fdc flx1 ais jcc pt10 low peak'>
                                                    <span className='flx1 aic jcc'>
                                                        Peak
                                                    </span>
                                                    <div className='fdc aife jcfe vat'>
                                                        VAT incl
                                                    </div>
                                                </div>
                                                <div className='fdc flx1 ais jcc pt10 low standard'>
                                                    <span className='flx1 aic jcc'>
                                                        Standard
                                                    </span>
                                                    <div className='fdc aife jcfe vat'>
                                                        VAT incl
                                                    </div>
                                                </div>
                                                <div className='fdc flx1 ais jcc pt10 low off-peak'>
                                                    <span className='flx1 aic jcc'>
                                                        Off Peak
                                                    </span>
                                                    <div className='fdc aife jcfe vat'>
                                                        VAT incl
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fdc ais flx1 ais jcc network-capacity'>
                                        <span className='flx2 aic jcc'>
                                            Network Capacity Charges [R/kVA/m]
                                        </span>
                                        <div className='flx1 aife jcfe vat'>
                                            VAT incl
                                        </div>
                                    </div>
                                </div>
                                {
                                    isSubmitting &&
                                    <div className='fdc ais wfill submitting'>
                                        <AbsoluteLinearProgress />
                                    </div>
                                }
                                <div className='fdc ais flx2 pb5'>
                                    <FieldArray
                                        name='zones'
                                        render={
                                            () => (
                                                <div className='fdc ais'>
                                                    {
                                                        values.zones.map((zone, index) => (
                                                            <div className='fdr tariff-row mnh65' key={`zone_${zone.lower}_${zone.upper}`}>
                                                                <div className='fdc flx1 '>
                                                                    <div className='fdr flx1'>
                                                                        <div className='fdc flx1 aic jcc zone-cell'>
                                                                            {
                                                                                !!zone.lower &&
                                                                                <span>&gt; {zone.lower} KM{!zone.upper ? '' : ' &'}</span>
                                                                            }
                                                                            {
                                                                                !!zone.upper &&
                                                                                <span>&le; {zone.upper} KM</span>
                                                                            }
                                                                        </div>
                                                                        <div className='fdc flx1 aife jcc voltage-cell'>
                                                                            <span className='pb10 pr10'>&lt; 500V</span>
                                                                            <span className='pr10'>&ge; 500V &amp; &le; 22KV</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='fdr flx4 data-columns'>
                                                                    <div className='fdc flx1 aic jcc'>
                                                                        <div className='fdr pb10'>
                                                                            <input value={zone.highDemand.peak[0]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.highDemand.peak[0]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.highDemand.peak[0], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                        <div className='fdr'>
                                                                            <input value={zone.highDemand.peak[1]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.highDemand.peak[1]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.highDemand.peak[1], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdc flx1 aic jcc'>
                                                                        <div className='fdr pb10'>
                                                                            <input value={zone.highDemand.standard[0]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.highDemand.standard[0]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.highDemand.standard[0], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                        <div className='fdr'>
                                                                            <input value={zone.highDemand.standard[1]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.highDemand.standard[1]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.highDemand.standard[1], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdc flx1 aic jcc'>
                                                                        <div className='fdr pb10'>
                                                                            <input value={zone.highDemand.offPeak[0]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.highDemand.offPeak[0]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.highDemand.offPeak[0], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                        <div className='fdr'>
                                                                            <input value={zone.highDemand.offPeak[1]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.highDemand.offPeak[1]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.highDemand.offPeak[1], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdc flx1 aic jcc'>
                                                                        <div className='fdr pb10'>
                                                                            <input value={zone.lowDemand.peak[0]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.lowDemand.peak[0]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.lowDemand.peak[0], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                        <div className='fdr'>
                                                                            <input value={zone.lowDemand.peak[1]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.lowDemand.peak[1]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.lowDemand.peak[1], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdc flx1 aic jcc'>
                                                                        <div className='fdr pb10'>
                                                                            <input value={zone.lowDemand.standard[0]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.lowDemand.standard[0]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.lowDemand.standard[0], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                        <div className='fdr'>
                                                                            <input value={zone.lowDemand.standard[1]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.lowDemand.standard[1]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.lowDemand.standard[1], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdc flx1 aic jcc'>
                                                                        <div className='fdr pb10'>
                                                                            <input value={zone.lowDemand.offPeak[0]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.lowDemand.offPeak[0]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.lowDemand.offPeak[0], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                        <div className='fdr'>
                                                                            <input value={zone.lowDemand.offPeak[1]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.lowDemand.offPeak[1]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(zone.lowDemand.offPeak[1], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='fdr flx1 capacity-column'>
                                                                    <div className='fdc flx1 ais jcc'>
                                                                        <div className='fdr pb10'>
                                                                            <span className='currency'>R</span>
                                                                            <input value={zone.netwrokCapacity[0]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.netwrokCapacity[0]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                R{NumberHelper.addPercentage(zone.netwrokCapacity[0], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                        <div className='fdr'>
                                                                            <span className='currency'>R</span>
                                                                            <input value={zone.netwrokCapacity[1]}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`zones.${index}.netwrokCapacity[1]`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                R{NumberHelper.addPercentage(zone.netwrokCapacity[1], vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    />
                                </div>
                            </Paper>
                            <div className='fdr ais'>
                                <Paper className='fdc flx1 ais mr10' elevation={3}>
                                    <div className='fdr ais'>
                                        <div className='fdc ais flx1 categories'>
                                            <span className='fdr flx1 jcc aic tac'>
                                                Customer Categories
                                            </span>
                                        </div>
                                        <div className='fdc ais flx1 service-charge'>
                                            <span className='fdr flx1 jcc aic tac pl20 pr20'>
                                                Service Charge [R/account/day]
                                            </span>
                                            <div className='fdc aife jcfe vat'>
                                                VAT incl
                                            </div>
                                        </div>
                                        <div className='fdc ais flx1 admin-charge'>
                                            <span className='fdr flx1 jcc aic tac pl20 pr20'>
                                                Administration Charge [R/POD/day]
                                            </span>
                                            <div className='fdc aife jcfe vat'>
                                                VAT incl
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fdc ais'>
                                        {
                                            isSubmitting &&
                                            <div className='fdc ais wfill submitting'>
                                                <AbsoluteLinearProgress />
                                            </div>
                                        }
                                        <FieldArray
                                            name='categories'
                                            render={
                                                () => (
                                                    <div className='fdc ais'>
                                                        {
                                                            values.categories.map((category, index) => (
                                                                <div className='fdr tariff-row' key={`caregory_${category.lower}_${category.upper}`}>
                                                                    <div className='fdc aic jcc flx1 zone-cell mnh48'>
                                                                        {
                                                                            category.lower > 0 &&
                                                                            <span>&gt; {category.lower > 999 ? `${category.lower / 1000} MVA` : `${category.lower} kVA`} {!category.upper ? '' : ' &'}</span>
                                                                        }
                                                                        {
                                                                            category.upper > 0 &&
                                                                            <span>&le; {category.upper > 999 ? `${category.upper / 1000} MVA` : `${category.upper} kVA`}</span>
                                                                        }
                                                                        {
                                                                            category.lower < 0 &&
                                                                            <span>Key Customers</span>
                                                                        }
                                                                    </div>
                                                                    <div className='fdc ais flx1 data-columns'>
                                                                        <div className='fdr flx1 aic'>
                                                                            <span className='currency'>R</span>
                                                                            <input value={category.serviceCharge}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`categories.${index}.serviceCharge`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                R{NumberHelper.addPercentage(category.serviceCharge, vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='fdc ais flx1 data-columns'>
                                                                        <div className='fdr flx1 aic'>
                                                                            <span className='currency aic'>R</span>
                                                                            <input value={category.administrationCharge}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`categories.${index}.administrationCharge`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat aic'>
                                                                                R{NumberHelper.addPercentage(category.administrationCharge, vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                        />
                                    </div>
                                </Paper>
                                <div className='fdc flx1 ais ml10'>
                                    <Paper className='fdc ais mb5' elevation={3}>
                                        <div className='fdr ais'>
                                            <div className='fdc ais flx1 categories'>
                                                <span className='fdr flx1 jcc aic tac'>
                                                    Voltage
                                                </span>
                                            </div>
                                            <div className='fdc ais flx1 service-charge'>
                                                <span className='fdr flx1 jcc aic tac pl20 pr20'>
                                                    Ancillary Service Charge [c/kWh]
                                                </span>
                                                <div className='fdc aife jcfe vat'>
                                                    VAT incl
                                                </div>
                                            </div>
                                            <div className='fdc ais flx1 admin-charge'>
                                                <span className='fdr flx1 jcc aic tac pl20 pr20'>
                                                    Network Demand charge [c/kWh] in all time-of-use periods
                                                </span>
                                                <div className='fdc aife jcfe vat'>
                                                    VAT incl
                                                </div>
                                            </div>
                                        </div>
                                        <div className='fdc ais'>
                                            {
                                                isSubmitting &&
                                                <div className='fdc ais wfill submitting'>
                                                    <AbsoluteLinearProgress />
                                                </div>
                                            }
                                            <FieldArray
                                                name='voltages'
                                                render={
                                                    () => (
                                                        <div className='fdc ais'>
                                                            {
                                                                values.voltages.map((voltage, index) => (
                                                                    <div className='fdr tariff-row' key={`voltage_${voltage.lower}_${voltage.upper}`}>
                                                                        <div className='fdc aic jcc flx1 zone-cell mnh48'>
                                                                            {
                                                                                voltage.lower > 0 &&
                                                                                <span>&ge; {voltage.lower > 999 ? `${voltage.lower / 1000} kV` : `${voltage.lower} V`} {!voltage.upper ? '' : ' &'}</span>
                                                                            }
                                                                            {
                                                                                voltage.upper > 0 &&
                                                                                <span>&lt; {voltage.upper > 999 ? `${voltage.upper / 1000} kV` : `${voltage.upper} V`}</span>
                                                                            }
                                                                        </div>
                                                                        <div className='fdc ais flx1 data-columns'>
                                                                            <div className='fdr flx1 aic'>
                                                                                <input value={voltage.ancillaryServiceCharge}
                                                                                    disabled={isSubmitting || !enabled}
                                                                                    autoComplete='off'
                                                                                    name={`voltages.${index}.ancillaryServiceCharge`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    type='number'
                                                                                    min='0'
                                                                                    step='any' />
                                                                                <span className='flx1' />
                                                                                <span className='vat'>
                                                                                    {NumberHelper.addPercentage(voltage.ancillaryServiceCharge, vat).toFixed(2)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='fdc ais flx1 data-columns'>
                                                                            <div className='fdr flx1 aic'>
                                                                                <input value={voltage.networkDemandCharge}
                                                                                    disabled={isSubmitting || !enabled}
                                                                                    autoComplete='off'
                                                                                    name={`voltages.${index}.networkDemandCharge`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    type='number'
                                                                                    min='0'
                                                                                    step='any' />
                                                                                <span className='flx1' />
                                                                                <span className='vat aic'>
                                                                                    {NumberHelper.addPercentage(voltage.networkDemandCharge, vat).toFixed(2)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    )
                                                }
                                            />
                                        </div>
                                    </Paper>
                                    <Paper className='fdc flx1 ais mt5' elevation={3}>
                                        <div className='fdc ais reactive-energy-charge'>
                                            <span className='fdr flx1 jcc aic tac'>
                                                Reactive Energy Charge [c/kVArh]
                                            </span>
                                        </div>
                                        <div className='fdr ais'>
                                            <div className='fdr flx1 ais jcc reactive-energy-high-season'>
                                                <span className='fdr flx1 aic pl10'>
                                                    High Season
                                                </span>
                                                <div className='fdc aife jcc vat'>
                                                    VAT incl
                                                </div>
                                            </div>
                                            <div className='fdr flx1 ais jcc reactive-energy-low-season'>
                                                <span className='fdr flx1 aic pl10'>
                                                    Low Season
                                                </span>
                                                <div className='fdc aife jcc vat'>
                                                    VAT incl
                                                </div>
                                            </div>
                                        </div>
                                        <div className='fdr tariff-row ais flx1'>
                                            <div className='fdr flx1 aic'>
                                                <input value={values.reactiveEnergyCharge.high}
                                                    disabled={isSubmitting || !enabled}
                                                    autoComplete='off'
                                                    name={'reactiveEnergyCharge.high'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type='number'
                                                    min='0'
                                                    step='any' />
                                                <span className='flx1' />
                                                <span className='vat'>
                                                    {NumberHelper.addPercentage(values.reactiveEnergyCharge.high, vat).toFixed(2)}
                                                </span>
                                            </div>
                                            <div className='fdr flx1 aic' style={{
                                                borderLeft: 'solid 1px #A0A0A0',
                                            }}>
                                                <input value={values.reactiveEnergyCharge.low}
                                                    disabled={isSubmitting || !enabled}
                                                    autoComplete='off'
                                                    name={'reactiveEnergyCharge.low'}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type='number'
                                                    min='0'
                                                    step='any' />
                                                <span className='flx1' />
                                                <span className='vat'>
                                                    {NumberHelper.addPercentage(values.reactiveEnergyCharge.low, vat).toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                    </Paper>
                                </div>
                            </div>
                            <div className='fdr flx1 jcfe aife mt15'>
                                <OutlinedStadiumButton className='mr16' variant='text' type='reset' disabled={isSubmitting || !enabled}>
                                        CANCEL
                                </OutlinedStadiumButton>
                                <StadiumButton type='submit' disabled={isSubmitting || !enabled}>
                                        SAVE
                                </StadiumButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(LPUEdit);

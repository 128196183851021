import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import PumpSelector from '../../customComponents/selector/borehole/PumpSelector';

interface IEditBoreholePumpInfoProps {
    borehole : IBorehole;

    onChange : (borehole : IBorehole) => void;
}

interface IEditBoreholePumpInfoState {
}

export default class EditBoreholePumpInfo extends React.Component<IEditBoreholePumpInfoProps, IEditBoreholePumpInfoState> {
    constructor(props : IEditBoreholePumpInfoProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onSerialChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange({
            ...this.props.borehole,
            PumpSerial: event.currentTarget.value,
        });
    };

    private readonly onPumpChange = (pump : string) => {
        this.props.onChange({
            ...this.props.borehole,
            Pump: pump,
        });
    };

    private readonly onPumpTypeChange = (pumpType : string) => {
        this.props.onChange({
            ...this.props.borehole,
            PumpType: pumpType,
        });
    };

    public readonly render = () => {
        const { borehole } = this.props;
        return (
            <div className={'fdr flx1 aifs'}>
                <PumpSelector pump={borehole.Pump ?? ''} pumpType={borehole.PumpType ?? ''} onPumpChange={this.onPumpChange} onPumpTypeChange={this.onPumpTypeChange} />
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete='off'
                            id='serial'
                            label='Pump Serial'
                            value={borehole.PumpSerial ?? ''}
                            onChange={this.onSerialChange}
                            margin='normal'
                            className={'TextField'}
                        />
                    </FormControl>
                </div>
            </div>
        );
    };
}

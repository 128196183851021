import lodash from 'lodash';
import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { createSelector } from 'reselect';
import { IBoreholeCalibration } from '../../../../@types/model/borehole/boreholeCalibration';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

interface IBoreholeCalibrationEntryGraphViewProps {
    calibration : IBoreholeCalibration;
    isLoading : boolean;
}

interface IBoreholeCalibrationEntryGraphViewState {
}

export default class BoreholeCalibrationEntryGraphView extends React.Component<IBoreholeCalibrationEntryGraphViewProps, IBoreholeCalibrationEntryGraphViewState> {
    constructor(props : IBoreholeCalibrationEntryGraphViewProps) {
        super(props);
        this.state = {
        };
    }

    public readonly getData = (props : IBoreholeCalibrationEntryGraphViewProps) => props.calibration;
    public readonly getAbstractionGraphValues = createSelector(
        [this.getData],
        (calibrationTest) => {
            const returnValue : Array<{
                'Abstraction Rate' : number;
                CurrentTime : number;
            }> = [];

            let maxTime = 0;
            lodash.forEach(calibrationTest.BoreholeCalibrationStages, (x) => {
                x.StageEntries.forEach((entry, entryIndex) => {
                    if (entry.AbstractionRate && entry.Time && entry.IsActive) {
                        returnValue.push({
                            'Abstraction Rate': entry.AbstractionRate,
                            CurrentTime: maxTime + entry.Time,
                        });
                    }
                    if ((entryIndex === x.StageEntries.length - 1) && entry.IsActive) {
                        maxTime += entry.Time;
                    }
                });
            });
            return returnValue;
        },
    );

    public readonly getGroundLevelGraphValues = createSelector(
        [this.getData],
        (calibrationTest) => {
            const returnValue : Array<{
                CurrentTime : number;
                'Ground Water Level' : number;
            }> = [];
            let maxTime = 0;
            lodash.forEach(calibrationTest.BoreholeCalibrationStages, (x) => {
                x.StageEntries.forEach((entry, entryIndex) => {
                    if (entry.GroundLevel && entry.Time && entry.IsActive) {
                        returnValue.push({
                            'Ground Water Level': entry.GroundLevel,
                            CurrentTime: maxTime + entry.Time,
                        });
                    }
                    if ((entryIndex === x.StageEntries.length - 1) && entry.IsActive) {
                        maxTime += entry.Time;
                    }
                });
            });

            calibrationTest.RecoveryEntries.forEach((entry, entryIndex) => {
                if (entry.GroundLevel && entry.Time && entry.IsActive) {
                    returnValue.push({
                        'Ground Water Level': entry.GroundLevel,
                        CurrentTime: maxTime + entry.Time,
                    });
                }
                if ((entryIndex === calibrationTest.RecoveryEntries.length - 1) && entry.IsActive) {
                    maxTime += entry.Time;
                }
            });
            return returnValue;
        },
    );

    public readonly render = () => {
        const groundLevelGraphValues = this.getGroundLevelGraphValues(this.props);
        const abstractionGraphValues = this.getAbstractionGraphValues(this.props);

        return (
            <div className='fdc'>
                <Card className={'fdr bcg0 mt15'}>
                    {
                        abstractionGraphValues.length !== 0 && (
                            <div className={'flx1 fdc p10 wfill'}>
                                <ResponsiveContainer aspect={5} width={'99%'}>
                                    <LineChart className={''} data={abstractionGraphValues}>
                                        <XAxis dataKey='CurrentTime'/>
                                        <YAxis domain={[0, 'dataMax + 5']}/>
                                        <CartesianGrid />
                                        <Tooltip
                                            itemStyle={{
                                                color: 'black',
                                            }}
                                            labelStyle={{ 
                                                color: 'black',
                                            }}
                                            labelFormatter={(label) => `${label} min`}
                                            formatter={(value) => `${value}m³/h`}
                                        />
                                        <Line strokeWidth={3} type='monotone' dataKey='Abstraction Rate' stroke='#2294FC' />
                                    </LineChart>
                                </ResponsiveContainer>
                                <Typography className={'fs14 fwb aic jcc cg3'} color='inherit' variant='subtitle1'>
                                    Time (min)
                                </Typography>
                            </div>
                        )
                    }
                    
                </Card>
                <Card className={'fdr bcDarkBlue mt15'}>
                    {
                        groundLevelGraphValues.length !== 0 &&
                        (
                            <div className={'flx1 fdc jcc p10 wfill'}>
                                <ResponsiveContainer aspect={5} width={'99%'}>
                                    <LineChart className={''} data={groundLevelGraphValues}>
                                        <XAxis tick={{
                                            fill: 'white',
                                        }} dataKey='CurrentTime'/>
                                        <YAxis tick={{
                                            fill: 'white',
                                        }} domain={[0, 'dataMax + 5']} reversed/>
                                        <CartesianGrid />
                                        <Tooltip
                                            itemStyle={{
                                                color: 'black',
                                            }}
                                            labelStyle={{ color: 'black' }}
                                            labelFormatter={(label) => `${label} min`}
                                            formatter={(value) => `${value}m`}
                                        />
                                        <Line strokeWidth={3} type='monotone' dataKey='Ground Water Level' stroke='white' />
                                    </LineChart>
                                </ResponsiveContainer>
                                <Typography className={'fs14 fwb pb10 aic jcc cw'} color='inherit' variant='subtitle1'>
                                    Time (min)
                                </Typography>
                            </div>
                        )
                    }
                </Card>
            </div>
        );
    };
}


import { createAction } from 'typesafe-actions';
import { IMottech, IMottechAudit, IMottechInstallationAudit } from '../../@types/mottech';

export const mottechSetList = createAction('MOTTECH_SET_LIST', resolve => (mottechs : Array<IMottech>) => resolve(mottechs));
export const mottechSetSelected = createAction('MOTTECH_SET_SELECTED', resolve => (mottech ?: IMottech) => resolve(mottech));
export const mottechSetLoading = createAction('MOTTECH_SET_LOADING', resolve => (loading : boolean) => resolve(loading));
export const mottechSetAudits = createAction('MOTTECH_SET_AUDITS', resolve => (audits : Array<IMottechAudit>) => resolve(audits));
export const mottechSetAuditLoading = createAction('MOTTECH_SET_LOADING_AUDITS', resolve => (loading : boolean) => resolve(loading));
export const mottechSetInsAudits = createAction('MOTTECH_INS_SET_AUDITS', resolve => (audits : Array<IMottechInstallationAudit>) => resolve(audits));
export const mottechSetInsAuditLoading = createAction('MOTTECH_INS_SET_LOADING_AUDITS', resolve => (loading : boolean) => resolve(loading));

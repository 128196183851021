import React, { useEffect, useState } from 'react';
import { AppBar, Dialog, DialogContent, Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Form } from 'informed';
import { IUserSession } from '../../../@types/employee';
import { IRainMeter } from '../../../@types/model/rainMeter/rainMeter/rainMeter';
import { IRainMeterFormValues } from '../../../@types/model/rainMeter/rainMeter/rainMeterFormValues';
import RainMeterModelHelper from '../../../@types/model/rainMeter/rainMeter/rainMeterModelHelper';
import GeneralFunctions from '../../../store/general/functions';
import RainMeterEditForm from './RainMeterForm';
import { Transitions } from '../../customComponents/animations/Transitions';

interface IRainMeterEditDialogProps {
    currentUser ?: IUserSession | null;
    selectedRainMeter ?: IRainMeter | null;
    isOpen : boolean;
    onDismiss : () => void;
}

const RainMeterEditDialog = (props : IRainMeterEditDialogProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entrySaved, setEntrySaved] = useState<boolean>(false);

    /*================================================================================================================
     *                                                      Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (entrySaved) {
            setEntrySaved(false);
            props.onDismiss();   
        } 

    }, [entrySaved]);

    /*================================================================================================================
    *                                                  Handler Methods
    * ==============================================================================================================*/

    const onSubmit = async (formValues : IRainMeterFormValues) : Promise<void> => {
        setIsLoading(true);

        if (!props.currentUser) {
            setIsLoading(false);
            return;
        }
        
        const rainMeter = RainMeterModelHelper.createSave(props.currentUser, formValues, props.selectedRainMeter);

        try {
            await RainMeterModelHelper.save(rainMeter);
            setIsLoading(false);
            setEntrySaved(true);
            GeneralFunctions.generalShowSuccessSnackbar('Rain Meter updated successfully.');

        } catch (e) {
            // console.log(e);
            // GeneralFunctions.generalShowErrorSnackbar('An error occurred while saving Rain Meter.');
            setIsLoading(false);
        }
    };

    const getInitialFormValues = () : IRainMeterFormValues => {
        return RainMeterModelHelper.createFormValues(props.selectedRainMeter);
    };

    /*================================================================================================================
    *                                                  Memos
    * ==============================================================================================================*/

    /*================================================================================================================
    *                                                  Render
    * ==============================================================================================================*/

    return (
        <>
            <Dialog
                open={props.isOpen}
                TransitionComponent={Transitions.Up}
                transitionDuration={500}
                onClose={props.onDismiss}
                maxWidth={'sm'}
                aria-labelledby='rain-meter-edit-dialog-title'
                aria-describedby='rain-meter-edit-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            { props.selectedRainMeter ? 'Edit Rain Meter' : 'Add Rain Meter'}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={props.onDismiss} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Form autoComplete='off' onSubmit={onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc flx1 jcfs hfill'>
                        <RainMeterEditForm 
                            initialValues={getInitialFormValues()} 
                            onSubmit={onSubmit} 
                            onCancel={props.onDismiss} 
                        />
                    </DialogContent>
                </Form>
            </Dialog>
        </>
    );
}

export default RainMeterEditDialog;
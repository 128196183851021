import { dispatch, getState } from '../../..';
import PumpingStationFlowmeterPulseHelper, { IPumpingStationFlowmeterPulse } from '../../../../@types/model/masterData/pumpingStation/flowmeterPulse';
import GeneralFunctions from '../../../general/functions';
import MasterDataPumpingStationFlowmeterPulseActions from './actions';

export default class MasterDataPumpingStationFlowmeterPulseFunctions {
    private static listener : undefined | (() => void);

    public static readonly load = async () => {
        if (MasterDataPumpingStationFlowmeterPulseFunctions.listener) return;
        
        dispatch(MasterDataPumpingStationFlowmeterPulseActions.setLoading(true));
        dispatch(MasterDataPumpingStationFlowmeterPulseActions.set([]));

        try {
            MasterDataPumpingStationFlowmeterPulseFunctions.listener = PumpingStationFlowmeterPulseHelper
                .collection()
                .onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IPumpingStationFlowmeterPulse>) => {
                    const state = getState().masterData.pumpingStation.flowmeterPulse;

                    const list = state.flowmeterPulses.slice();
                
                    const session = getState().auth.session;
                    if (!session) return;

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const item = f.doc.data();
                        const index = list.findIndex(n => n.id === item.id);


                        switch (f.type) {
                            case 'added':
                                list.push(item);
                                break;
                            case 'modified':
                                list.splice(index, 1, item);
                                break;
                            case 'removed':
                                list.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(MasterDataPumpingStationFlowmeterPulseActions.set(list));
                    dispatch(MasterDataPumpingStationFlowmeterPulseActions.setLoading(false));
                }, (ex) => {
                    GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
                    dispatch(MasterDataPumpingStationFlowmeterPulseActions.setLoading(false));
                }, () => {
                    dispatch(MasterDataPumpingStationFlowmeterPulseActions.setLoading(false));
                    MasterDataPumpingStationFlowmeterPulseFunctions.listener = undefined;
                });

        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
            dispatch(MasterDataPumpingStationFlowmeterPulseActions.setLoading(false));
        }
    };
}
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import lodash from 'lodash';
import { TRANSFORMER_RATINGS } from '../../../../appConstants';
import { Autocomplete } from '@material-ui/lab';
import { createSelector } from 'reselect';
import { TextField } from '@material-ui/core';

interface ITransformerRatingSelectorProps {
    required ?: boolean;

    fullWidth ?: boolean;

    value : number | null;

    onChange : (circuit : number | null) => void;
}

interface ITransformerRatingSelectorState {
}

export default class TransformerRatingSelector extends React.Component<ITransformerRatingSelectorProps, ITransformerRatingSelectorState> {
    constructor(props : ITransformerRatingSelectorProps) {
        super(props);
        this.state = {
        };
    }

    private readonly getValue = (state : ITransformerRatingSelectorState, props : ITransformerRatingSelectorProps) => props.value;

    private readonly getOptions = () => {
        const options = lodash
            .chain(TRANSFORMER_RATINGS)
            .flatMap(x => x)
            .uniq()
            .map(n => ({
                label: `${n} kVA`,
                value: n,
            }))
            .sortBy(x => x.value)
            .value();

        return options;
    };

    private readonly getSelectedValue = createSelector([
        this.getValue,
        this.getOptions,
    ], (
        value,
        options,
    ) => {
        if (value) {
            const circuit = options.find(x => x.value === value);

            if (circuit) {
                return {
                    label: `${circuit.value} kVA`,
                    value: circuit.value,
                };
            } else {
                return {
                    label: `${value} kVA`,
                    value: value,
                };
            }
        }

        return null;
    });

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : number | string | null | {
        label : string;
        value : number;
    }) => {
        event.preventDefault();
        event.stopPropagation();
        if (typeof value === 'string') {
            this.props.onChange(Number(value));
        } else if (typeof value === 'number') {
            this.props.onChange(value);
        } else {
            this.props.onChange(value?.value ?? null);
        }
    };

    public readonly render = () => {
        const { required, fullWidth } = this.props;
        const options = this.getOptions();

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl fullWidth={fullWidth}>
                <Autocomplete
                    freeSolo
                    options={options}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={(option : {
                        label : string;
                        value : number;
                    } | null) => option?.label ?? `${option}`}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    fullWidth={fullWidth}
                    renderInput={params => <TextField error={required && !value} {...params} label='Transformer Rating' />}
                />
            
            </FormControl>
        );
    };
}

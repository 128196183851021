import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IRiver } from '../../../../@types/model/river/river';

interface IRiverCodeSelectorProps {
    rivers : Array<IRiver>;
    isLoading : boolean;

    value : string;

    onChange : (value : string) => void;

    required ?: boolean;
}

interface IRiverCodeSelectorState {}

class RiverCodeSelector extends React.Component<IRiverCodeSelectorProps, IRiverCodeSelectorState> {
    constructor(props : IRiverCodeSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly getRivers = (props : IRiverCodeSelectorProps) => props.rivers;

    public readonly getRiverCodes = createSelector(
        [this.getRivers],
        (rivers) => {
            return rivers.map((e : IRiver) => {
                return e.WRNumber;
            });
        },
    );

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: string | undefined | null) => {
        this.props.onChange(value ?? '');
    };
    public readonly render = () => {
        const { value, required, isLoading} = this.props;

        const codes = this.getRiverCodes(this.props);

        return (
            <FormControl fullWidth={true} required={false} disabled={isLoading}>
                <Autocomplete
                    id={'code'}
                    options={codes}
                    value={value}
                    getOptionSelected={(option, val) => option === val}
                    getOptionLabel={option => option }
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={true} label={'River Code'} />}
                />
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        rivers: state.river.rivers,
        isLoading: state.river.loading,
    };
};

export default connect(
    mapStateToProps,
)(RiverCodeSelector);

import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MultiFileDropZone from '../../customComponents/file/MultiFileDropZone';
import MultiFileUploadProgress from '../../customComponents/file/MultiFileUploadProgress';
import BoreholeFunctions from '../../../store/borehole/functions';
import StadiumButton from '../../customComponents/button/StadiumButton';

interface IUploadBoreholeVideosProps {
    borehole : IBorehole;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IUploadBoreholeVideosState {
    open : boolean;
    
    videos : Array<File>;

    isUploadingLoading : boolean;
}

export default class UploadBoreholeVideos extends React.Component<IUploadBoreholeVideosProps, IUploadBoreholeVideosState> {
    constructor(props : IUploadBoreholeVideosProps) {
        super(props);
        this.state = {
            open: false,
            videos: [],
            isUploadingLoading: false,
        };
    }

    public readonly componentDidUpdate = (prevProps : IUploadBoreholeVideosProps, prevState : IUploadBoreholeVideosState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                videos: [],
                isUploadingLoading: false,
            });
        }
    };

    private readonly onClick = () => {
        this.setState({
            open: true,
        });
    };

    public readonly onClose = () => {
        if (this.state.isUploadingLoading) return;
        this.close();
    };

    private readonly onFileSelected = (acceptedFiles : Array<File>) => {
        const videos = this.state.videos.slice().concat(acceptedFiles);

        this.setState({
            videos,
        });
    };

    private readonly onFileRemove = (name : string) => {
        const videos = this.state.videos.slice();
        const fileIndex = videos.findIndex(x => x.name === name);

        videos.splice(fileIndex, 1);

        this.setState({
            videos,
        });
    };

    private readonly onFileUpload = (file : File) => {
        return BoreholeFunctions.uploadSelectedBoreholeVideo(file);
    };

    private readonly close = () => {

        this.setState({
            open: false,
            isUploadingLoading: false,
            videos: [],
        });
    };

    private readonly onDone = () => {
        this.close();
    };

    private readonly onUploadClick = () => {
        this.setState({
            isUploadingLoading: true,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, isUploadingLoading, videos } = this.state;
        return (
            <>
                <Tooltip title='Upload Video'>
                    <div>
                        <StadiumButton style={{
                            height: 42,
                            borderRadius: 40,
                        }} className='jcc aic p0' color='primary' onClick={this.onClick} aria-label='Upload Video'>
                            <Icon className='cp ml10'>upload</Icon>
                            <Typography className='fw600 fs16 cp ml20 mr10'>VIDEO UPLOADS</Typography>
                        </StadiumButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-video-edit-dialog-title'
                    aria-describedby='borehole-video-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Upload Borehole Videos
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading || isUploadingLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 mnh350'>
                        {
                            !isUploadingLoading &&
                            <MultiFileDropZone
                                files={videos}
                                onFileRemoveClick={this.onFileRemove}
                                onFileSelected={this.onFileSelected}
                                accept='video/mp4'
                            />
                        }
                        {
                            isUploadingLoading &&
                            <MultiFileUploadProgress
                                files={videos}
                                onFileUpload={this.onFileUpload}
                                onDone={this.onDone}
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading || isUploadingLoading} onClick={this.onUploadClick} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading || isUploadingLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };
}

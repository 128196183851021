import { StyleSheet } from '@react-pdf/renderer';
import { themeVariables } from '../material';

const s = StyleSheet.create({
    body: {
        fontFamily: 'Montserrat',
        overflow: 'hidden',
        marginTop: 12,
        paddingBottom: 47,
    },

    page: {
        marginLeft: 12,
        marginRight: 12,
    },

    /* Flex */
    flx1: { flexGrow: 1, display: 'flex' },
    flx2: { flexGrow: 2, display: 'flex' },
    flx3: { flexGrow: 3 },
    flx4: { flexGrow: 4 },
    flx5: { flexGrow: 5 },
    flx6: { flexGrow: 6 },
    flx7: { flexGrow: 7 },
    flx8: { flexGrow: 8 },
    flx9: { flexGrow: 9 },
    flx10: { flexGrow: 10 },
    flx11: { flexGrow: 11 },
    flx12: { flexGrow: 12 },

    /* Flex Direction */
    fdr: { flexDirection: 'row', display: 'flex' },
    fdc: { flexDirection: 'column', display: 'flex' },

    /* Flex Wrap */
    fww: { flexWrap: 'wrap' },
    fwnw: { flexWrap: 'nowrap' },

    /* Justify */
    jcfs: { justifyContent: 'flex-start' },
    jcfe: { justifyContent: 'flex-end' },
    jcc: { justifyContent: 'center' },
    jcsa: { justifyContent: 'space-around' },
    jcsb: { justifyContent: 'space-between' },

    /* Align */
    aic: { alignItems: 'center' },
    aifs: { alignItems: 'flex-start' },
    aife: { alignItems: 'flex-end' },
    ais: { alignItems: 'stretch' },
    asc: { alignSelf: 'center' },
    ass: { alignSelf: 'stretch' },
    asfs: { alignSelf: 'flex-start' },
    asfe: { alignSelf: 'flex-end' },

    /* Position */
    posa: { position: 'absolute' },
    posr: { position: 'relative' },
    posl0: { left: 0 },
    posr0: { right: 0 },
    post0: { top: 0 },
    posb0: { bottom: 0 },

    /* Height */
    hfill: { height: '100%' },
    h1: { height: 1 },
    h5: { height: 5 },
    h10: { height: 10 },
    h15: { height: 15 },
    h20: { height: 20 },
    h25: { height: 25 },
    h28: { height: 28 },
    h29: { height: 29 },
    h30: { height: 30 },
    h35: { height: 35 },
    h40: { height: 40 },
    h42: { height: 42 },
    h45: { height: 45 },
    h46: { height: 46 },
    h50: { height: 50 },
    h55: { height: 55 },
    h56: { height: 56 },
    h58: { height: 58 },
    h60: { height: 60 },
    h70: { height: 70 },
    h80: { height: 80 },
    h90: { height: 90 },
    h95: { height: 95 },
    h100: { height: 100 },
    h110: { height: 110 },
    h120: { height: 120 },
    h130: { height: 130 },
    h150: { height: 150 },
    h170: { height: 170 },
    h200: { height: 200 },
    h250: { height: 250 },
    h300: { height: 300 },
    h500: { height: 500 },

    /* Width */
    wfill: { width: '100%' },
    w50p: { width: '50%' },
    w2: { width: 2 },
    w5: { width: 5 },
    w10: { width: 10 },
    w15: { width: 15 },
    w20: { width: 20 },
    w25: { width: 25 },
    w30: { width: 30 },
    w35: { width: 35 },
    w40: { width: 40 },
    w42: { width: 42 },
    w50: { width: 50 },
    w60: { width: 60 },
    w70: { width: 70 },
    w80: { width: 80 },
    w90: { width: 90 },
    w100: { width: 100 },
    w110: { width: 110 },
    w120: { width: 120 },
    w130: { width: 130 },
    w150: { width: 150 },
    w170: { width: 170 },
    w200: { width: 200 },
    w240: { width: 240 },
    w245: { width: 245 },
    w246: { width: 246 },
    w250: { width: 250 },
    w300: { width: 300 },
    w350: { width: 350 },

    /* Margin */
    m0: { margin: 0 },
    m40: { margin: 40 },

    /* Margin Left */
    ml0: { marginLeft: 0 },
    ml9: { marginLeft: 9 },
    ml12: { marginLeft: 12 },
    ml18: { marginLeft: 18 },
    ml24: { marginLeft: 24 },

    /* Margin Top */
    mt0: { marginTop: 0 },
    mt2: { marginTop: 2 },
    mt4: { marginTop: 4 },
    mt8: { marginTop: 8 },
    mt10: { marginTop: 10 },
    mt14: { marginTop: 14 },
    mt15: { marginTop: 15 },
    mt16: { marginTop: 16 },

    /* Margin Right */
    mr0: { marginRight: 0 },
    mr12: { marginRight: 12 },
    mr15: { marginRight: 15 },
    mr18: { marginRight: 18 },

    /* Margin Bottom */
    mb0: { marginBottom: 0 },
    mb5: { marginBottom: 5 },
    mb10: { marginBottom: 10 },
    mb15: { marginBottom: 15 },
    mb20: { marginBottom: 20 },

    /* Margin Vertical */
    mver0: { marginBottom: 0 },
    mver3: { marginBottom: 3 },
    mver40: { marginTop: 40, marginBottom: 40 },

    /* Margin Horizontal */
    mhor0: { marginBottom: 0 },
    mhor3: { marginBottom: 3 },
    mhor30: { marginLeft: 30, marginRight: 30 },

    /* Padding */
    p0: { padding: 0 },
    p10: { padding: 10 },

    /* Padding Left */
    pl0: { paddingLeft: 0 },
    pl5: { paddingLeft: 5 },
    pl6: { paddingLeft: 6 },
    pl9: { paddingLeft: 9 },
    pl12: { paddingLeft: 12 },
    pl40: { paddingLeft: 40 },

    /* Padding Top */
    pt0: { paddingTop: 0 },

    /* Padding Right */
    pr0: { paddingRight: 0 },
    pr3: { paddingRight: 3 },
    pr5: { paddingRight: 5 },
    pr6: { paddingRight: 6 },
    pr10: { paddingRight: 10 },
    pr12: { paddingRight: 12 },

    /* Padding Bottom */
    pb0: { paddingBottom: 0 },
    pb5: { paddingBottom: 5 },

    /* FontSize */
    fs5: { fontSize: 5 },
    fs6: { fontSize: 6 },
    fs8: { fontSize: 8 },
    fs9: { fontSize: 9 },
    fs10: { fontSize: 10 },
    fs12: { fontSize: 12 },
    fs13: { fontSize: 13 },
    fs14: { fontSize: 14 },
    fs15: { fontSize: 15 },
    fs16: { fontSize: 16 },
    fs17: { fontSize: 17 },
    fs18: { fontSize: 18 },
    fs19: { fontSize: 19 },
    fs20: { fontSize: 20 },
    fs22: { fontSize: 22 },
    fs23: { fontSize: 23 },
    fs24: { fontSize: 24 },
    fs25: { fontSize: 25 },
    fs28: { fontSize: 28 },
    fs40: { fontSize: 40 },

    /* Font Weight */
    fwm: { fontWeight: 500 },
    fwb: { fontWeight: 700 },
    fweb: { fontWeight: 800 },

    /* Font Style */
    fsi: { fontStyle: 'italic' },

    /* Line Height */
    lh37: { lineHeight: 37 },

    /* Letter Spacing */
    ls065: { letterSpacing: 0.65 },

    /* Text Alignment */
    tac: { textAlign: 'center' },
    tal: { textAlign: 'left' },
    tar: { textAlign: 'right' },

    /* Border Width */
    bw0: { borderWidth: 0 },
    blw0: { borderLeftWidth: 0 },
    btw0: { borderTopWidth: 0 },
    brw0: { borderRightWidth: 0 },
    bbw0: { borderBottomWidth: 0 },
    bw1: { borderWidth: 1 },
    blw1: { borderLeftWidth: 1 },
    btw1: { borderTopWidth: 1 },
    brw1: { borderRightWidth: 1 },
    bbw1: { borderBottomWidth: 1 },

    /* Border Radius */
    br21: { borderRadius: 21 },

    /* Border Color */
    bocg1: { borderColor: themeVariables.colors.grey1 },
    bocg2: { borderColor: '#DDDFDE' },
    bocg3: { borderColor: '#595B5A' },

    /* Color */
    cw: { color: '#FFF' },
    cpl: { color: themeVariables.colors.material.primary.light },
    cpm: { color: themeVariables.colors.material.primary.main },
    cg3: { color: '#595B5A' },
    cdg: { color: '#424242' },

    /* Background Colour */
    bcp: { backgroundColor: themeVariables.colors.material.primary.main },
    bcw: { backgroundColor: themeVariables.colors.white },
    bcg1: { backgroundColor: '#EFEEEE' },
    bcg3: { backgroundColor: '#595B5A' },

    susBox: { boxShadow: '0px 3px 6px #00000029' } as any,
});

export default s;

import { Card, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import BoreholeInstallationAuditHelper, { IBoreholeInstallationAudit } from '../../../../@types/model/borehole/boreholeInstallationAudit';
import { IRootState } from '../../../../@types/redux';
import AppFunctionsService from '../../../../services/appFunctionsService';
import GeneralFunctions from '../../../../store/general/functions';
import { Transitions } from '../../../customComponents/animations/Transitions';
import CarouselImageViewer from '../../../customComponents/image/CarouselViewer';
import FirebaseStorageImage from '../../../customComponents/image/FirebaseStorageImage';
import EditBoreholeImages from '../../dialog/EditImages';
import { IBoreholeTest } from '../../../../@types/model/borehole/boreholeTest';
import { createSelector } from 'reselect';

interface IBoreholeImagesViewProps {
    borehole : IBorehole;
    boreholeTests : Array<IBoreholeTest>;

    isBoreholeAdmin : boolean;
}

interface IBoreholeImagesViewState {
    audit : IBoreholeInstallationAudit | null;
    isLoading : boolean;

    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

class BoreholeImagesViewComponent extends React.PureComponent<IBoreholeImagesViewProps, IBoreholeImagesViewState> {
    private stop ?: () => void;
    constructor(props : IBoreholeImagesViewProps) {
        super(props);
        this.state = {
            audit: null,
            isLoading: false,
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    public readonly componentWillUnmount = () => {
        if (!this.stop) return;

        this.stop();
    };

    public readonly componentDidMount = () => {
        const { borehole } = this.props;

        try {
            this.setLoading(true);
            this.stop = BoreholeInstallationAuditHelper
                .listen(borehole.Code)
                .orderBy('AuditDate', 'desc')
                .limit(1)
                .onSnapshot((snapshot) => {
                    this.setLoading(false);

                    this.setState({
                        audit: snapshot.empty ? null : snapshot.docs[0].data(),
                    });
                }, (ex) => {
                    GeneralFunctions.generalShowError(ex, 'An error while loading borehole audit.');
                });
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'An error while loading borehole audit.');
            this.setLoading(false);
        }
    };

    private readonly setLoading = (isLoading : boolean) => {
        this.setState({
            isLoading,
            imageSrcs: isLoading ? [] : this.state.imageSrcs,
        });
    };

    private readonly onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly getBoreholeTests = (state : IBoreholeImagesViewState, props : IBoreholeImagesViewProps) => props.boreholeTests;
    public readonly getBoreholeTest = createSelector(
        [this.getBoreholeTests],
        (boreholeTests) => {
            return boreholeTests.slice()
                .sort((a, b) => b.CreatedOn.toMillis() - a.UpdatedOn.toMillis())
                .find(x => !!x.FlowmeterImagePath);
        }
    );

    public readonly render = () => {
        const { isLoading, audit, imageSrcs, imageIndex, isCarouselOpen } = this.state;
        const { borehole, isBoreholeAdmin } = this.props;

        const boreholeTest = this.getBoreholeTest(this.state, this.props);
        return (
            <div className={'fdc flx1 mt15'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    <div className={'flx1 fdr aifs jcc'}>
                        <Card className={'bcw mt30 fdc'}>
                            <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                                <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                    INSTALLATION AUDIT {!audit ? '- No Audits' : AppFunctionsService.formatDateTimeToDateOnly(audit.AuditDate)}
                                </Typography>
                                <div className='posr posr aic jcc'>
                                    <div className='posa posr0'>
                                        {
                                            isBoreholeAdmin &&
                                            <EditBoreholeImages
                                                borehole={borehole}
                                                isLoading={isLoading}
                                                fullWidth
                                                maxWidth='md'
                                                transition={Transitions.Down}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'fdr ml17 mr17 mb13'}>
                                <Card className={'bcg0 fdc mr30'}>
                                    <div className={'jcc aic wfill bcp h50'}>
                                        <Typography className={'fs14 fw500 cw '}>
                                            BOREHOLE SITE IMAGE
                                        </Typography>
                                    </div>
                                    <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                        {
                                            (borehole.BoreholeImageFileName || borehole.BoreholeImageUrl) &&
                                            <div className='fdc jcc aic flx1'>
                                                <FirebaseStorageImage
                                                    fileName={borehole.BoreholeImageFileName ?? ''}
                                                    path={`borehole/${borehole.Code}`}
                                                    onClick={this.onThumbClick}
                                                    onLoaded={this.onImageUrlLoaded}
                                                    url={borehole.BoreholeImageUrl}
                                                    thumbnailUrl={borehole.BoreholeImageThumbnailUrl}
                                                />
                                            </div>
                                        }
                                        {
                                            !borehole.BoreholeImageFileName &&
                                            !borehole.BoreholeImageUrl &&
                                            <div className='fdc jcc aic flx1 cpd'>
                                                No Image
                                            </div>
                                        }
                                    </div>
                                </Card>
                                <Card className={'bcg0 fdc mr30'}>
                                    <div className={'jcc aic wfill bcp h50'}>
                                        <Typography className={'fs14 fw500 cw '}>
                                            INSIDE ELECTRICAL BOX IMAGE
                                        </Typography>
                                    </div>
                                    <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                        {
                                            (borehole.ElectricalBoxImageFileName || borehole.ElectricalBoxImageUrl) &&
                                            <div className='fdc jcc aic flx1'>
                                                <FirebaseStorageImage
                                                    fileName={borehole.ElectricalBoxImageFileName ?? ''}
                                                    path={`borehole/${borehole.Code}`}
                                                    onClick={this.onThumbClick}
                                                    onLoaded={this.onImageUrlLoaded}
                                                    url={borehole.ElectricalBoxImageUrl}
                                                    thumbnailUrl={borehole.ElectricalBoxImageThumbnailUrl}
                                                />
                                            </div>
                                        }
                                        {
                                            !borehole.ElectricalBoxImageFileName &&
                                            !borehole.ElectricalBoxImageUrl &&
                                            <div className='fdc jcc aic flx1 cpd'>
                                                No Image
                                            </div>
                                        }
                                    </div>
                                </Card>
                                <Card className={'bcg0 fdc mr30'}>
                                    <div className={'jcc aic wfill bcp h50'}>
                                        <Typography className={'fs14 fw500 cw '}>
                                            PUMP
                                        </Typography>
                                    </div>
                                    <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                        {
                                            (borehole.PumpImageFileName || borehole.PumpImageUrl) &&
                                            <div className='fdc jcc aic flx1'>
                                                <FirebaseStorageImage
                                                    fileName={borehole.PumpImageFileName ?? ''}
                                                    path={`borehole/${borehole.Code}`}
                                                    onClick={this.onThumbClick}
                                                    onLoaded={this.onImageUrlLoaded}
                                                    url={borehole.PumpImageUrl}
                                                    thumbnailUrl={borehole.PumpImageThumbnailUrl}
                                                />
                                            </div>
                                        }
                                        {
                                            !borehole.PumpImageFileName &&
                                            !borehole.PumpImageUrl &&
                                            <div className='fdc jcc aic flx1 cpd'>
                                                No Image
                                            </div>
                                        }
                                    </div>
                                </Card>
                                <Card className={'bcg0 fdc'}>
                                    <div className={'jcc aic wfill bcp h50'}>
                                        <Typography className={'fs14 fw500 cw '}>
                                            MOTOR
                                        </Typography>
                                    </div>
                                    <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                        {
                                            (borehole.MotorImageFileName || borehole.MotorImageUrl) &&
                                            <div className='fdc jcc aic flx1'>
                                                <FirebaseStorageImage
                                                    fileName={borehole.MotorImageFileName ?? ''}
                                                    path={`borehole/${borehole.Code}`}
                                                    onClick={this.onThumbClick}
                                                    onLoaded={this.onImageUrlLoaded}
                                                    url={borehole.MotorImageUrl}
                                                    thumbnailUrl={borehole.MotorImageThumbnailUrl}
                                                />
                                            </div>
                                        }
                                        {
                                            !borehole.MotorImageFileName &&
                                            !borehole.MotorImageUrl &&
                                            <div className='fdc jcc aic flx1 cpd'>
                                                No Image
                                            </div>
                                        }
                                    </div>
                                </Card>
                            </div>
                        </Card>
                        {
                            boreholeTest &&
                            <Card className={'bcw ml15 mt30 fdc'}>
                                <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                                    <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                        FLOWMETER READING
                                    </Typography>
                                </div>
                                <div className={'fdr ml17 mr17 mb13'}>
                                    <Card className={'bcg0 fdc'}>
                                        <div className={'jcc aic wfill bcp h50'}>
                                            <Typography className={'fs14 fw500 cw '}>
                                                FLOWMETER
                                            </Typography>
                                        </div>
                                        <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                            <div className='fdc jcc aic flx1'>
                                                <FirebaseStorageImage
                                                    fileName={boreholeTest.FlowmeterImagePath?.substring(boreholeTest.FlowmeterImagePath.lastIndexOf('/')) ?? ''}
                                                    path={boreholeTest.FlowmeterImagePath?.substring(0, boreholeTest.FlowmeterImagePath.lastIndexOf('/')) ?? ''}
                                                    onClick={this.onThumbClick}
                                                    onLoaded={this.onImageUrlLoaded}
                                                    excludeThumbnail
                                                />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </Card>
                        }
                    </div>
                }
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
        boreholeTests: state.borehole.boreholeTests,
    };
};

const BoreholeImagesView = connect(
    mapStateToProps,
)(BoreholeImagesViewComponent);

export default BoreholeImagesView;

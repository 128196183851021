import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationMotorMake } from '../../../../@types/model/masterData/pumpingStation/motorMake';

type MasterDataPumpingStationMotorMakeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationMotorMakeState {
    motorMakes : Array<IPumpingStationMotorMake>;
    isLoading : boolean;
}
const initialState = {
    motorMakes: [],
    isLoading: false,
};

export default function motorMakeReducer(state : IMasterDataPumpingStationMotorMakeState = initialState, action : MasterDataPumpingStationMotorMakeActionType) : IMasterDataPumpingStationMotorMakeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, motorMakes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { BankAccountType, BANK_ACCOUNT_TYPES } from '../../../@types/model/bankAccount';

interface IBankAccountTypeDropdownProps {
    id ?: string;
    value ?: BankAccountType;

    onChange : (value ?: BankAccountType) => void;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    label ?: string;
}

interface IBankAccountTypeDropdownState {
}

export default class BankAccountTypeDropdown extends React.Component<IBankAccountTypeDropdownProps, IBankAccountTypeDropdownState> {
    constructor(props : IBankAccountTypeDropdownProps) {
        super(props);

        this.state = {
        };
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {
        if (typeof value === 'string') {
            this.props.onChange(value);
        } else {
            this.props.onChange(value?.value as BankAccountType);
        }
    };

    private readonly getValue = (state : IBankAccountTypeDropdownState, props : IBankAccountTypeDropdownProps) => props.value;
    private readonly getRequired = (state : IBankAccountTypeDropdownState, props : IBankAccountTypeDropdownProps) => props.required;

    private readonly getBankAccountTypeDropdown = createSelector([
        this.getRequired,
    ], (required) => {
        const drop = lodash.map(BANK_ACCOUNT_TYPES, (n) => ({ label: n, value: n }));

        if (!required) {
            drop.unshift({
                label: 'ALL' as BankAccountType,
                value: '' as BankAccountType,
            });
        }

        return drop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired,
    ], (value, required) => {
        if (value) {
            const bank = BANK_ACCOUNT_TYPES.find(x => x === value);

            if (bank) {
                return { label: bank, value: bank };
            } else {
                return { label: value, value };
            }
        }

        if (!required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, fullWidth, id, label = 'Account Type' } = this.props;

        const dropdown = this.getBankAccountTypeDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    options={dropdown}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label={label} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                </div>
            </FormControl>
        );
    };
}

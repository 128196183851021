import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import BoreholeFilterPopper from '../popper/BoreholeFilterPopper';

interface IBoreholeFilterButtonProps {
    id ?: string;
    open : boolean;

    onClick ?: () => void;

    code : string | null;
    name : string | null;
    division : string | null;
    managementArea : string | null;
    onChange ?: (code : string | null, name : string | null, division : string | null, managementArea : string | null) => void;

    disabled ?: boolean;
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
}

interface IBoreholeFilterButtonState {}

export default class BoreholeFilterButton extends React.PureComponent<IBoreholeFilterButtonProps, IBoreholeFilterButtonState> {
    private filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : IBoreholeFilterButtonProps) {
        super(props);
        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    public readonly render = () => {
        const { id, onChange, code, name, division,
            managementArea, disabled, color, onClick } = this.props;
        return (
            <>
                <Tooltip title='Filter'>
                    <div>
                        <IconButton
                            ref={this.filterRef}
                            aria-label='toggle filter menu'
                            onClick={onClick}
                            disabled={disabled}
                            color={color}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <BoreholeFilterPopper
                    id={id}
                    anchorEl={this.filterRef.current}
                    open={this.props.open}
                    onCancelClick={onClick}
                    onChange={onChange}
                    code={code}
                    name={name}
                    managementArea={managementArea}
                    division={division}
                />
            </>
        );
    };
}

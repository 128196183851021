import { TableCell, TableRow } from '@material-ui/core';
import { FieldArrayRenderProps } from 'formik';
import React from 'react';
import { createSelector } from 'reselect';
import { ISPUBudgetValueFormValue } from '../../../../@types/model/transformer/budget';
import { ITransformerCompany } from '../../../../@types/model/transformer/transformer';
import AppFunctionsService from '../../../../services/appFunctionsService';
import TextFormFastField from '../../../customComponents/form/TextFormFastField';

interface ISPUBudgetFieldArrayProps extends FieldArrayRenderProps {
    hideDetail ?: boolean;

    company : ITransformerCompany | null;
    area : string | null;
    transformer : string | null;
    accountNumber : string | null;
    period : number | null;

    currentPage : number;
    rowsPerPage : number;
}

interface ISPUBudgetFieldArrayState {}

export default class SPUBudgetFieldArray extends React.PureComponent<ISPUBudgetFieldArrayProps, ISPUBudgetFieldArrayState> {
    constructor(props : ISPUBudgetFieldArrayProps) {
        super(props);
        this.state = {};
    }

    public readonly getForm = (props : ISPUBudgetFieldArrayProps) => props.form;
    public readonly getCurrentPage = (props : ISPUBudgetFieldArrayProps) => props.currentPage;
    public readonly getRowsPerPage = (props : ISPUBudgetFieldArrayProps) => props.rowsPerPage;
    public readonly getCompany = (props : ISPUBudgetFieldArrayProps) => props.company;
    public readonly getArea = (props : ISPUBudgetFieldArrayProps) => props.area;
    public readonly getTransformer = (props : ISPUBudgetFieldArrayProps) => props.transformer;
    public readonly getAccountNumber = (props : ISPUBudgetFieldArrayProps) => props.accountNumber;
    public readonly getPeriod = (props : ISPUBudgetFieldArrayProps) => props.period;

    public readonly getValues = createSelector(
        [this.getForm],
        (form) => {
            return form.values.values as Array<ISPUBudgetValueFormValue>;
        },
    );

    public readonly getFilteredValues = createSelector(
        [
            this.getValues,
            this.getCurrentPage,
            this.getRowsPerPage,
            this.getCompany,
            this.getArea,
            this.getTransformer,
            this.getAccountNumber,
            this.getPeriod,
        ],
        (
            values,
            currentPage,
            rowsPerPage,
            company,
            area,
            transformer,
            accountNumber,
            period,
        ) => {
            return values
                .filter(x => !company || x.transformer.Company?.id === company.id)
                .filter(x => !area || x.transformer.Division === area)
                .filter(x => !transformer || x.transformer.EPNumber === transformer)
                .filter(x => !accountNumber || x.transformer.AccountNumber === accountNumber)
                .filter(x => !period || x.month === period)
                .slice((currentPage * rowsPerPage), currentPage * rowsPerPage + rowsPerPage);
        },
    );

    public readonly render = () => {
        const { hideDetail } = this.props;

        const values = this.getFilteredValues(this.props);
        return values
            .map((n) => (
                <TableRow key={`values_${n.index}`} className={'data-row'}>
                    <TableCell className='fixed' width={80}>
                        {
                            AppFunctionsService.formatDateTimeToMonthYearOnly(n.month)
                        }
                    </TableCell>
                    <TableCell className='fixed' width={100}>
                        {
                            n.transformer.Company?.name ?? ''
                        }
                    </TableCell>
                    <TableCell className='fixed' width={150}>
                        {
                            n.transformer.Division
                        }
                    </TableCell>
                    <TableCell className='fixed' width={100}>
                        {
                            n.transformer.EPNumber
                        }
                    </TableCell>
                    {
                        !hideDetail &&
                        <>
                            <TableCell className='fixed' width={350}>
                                {
                                    n.transformer.SubArea
                                }
                            </TableCell>
                            <TableCell className='fixed' width={200}>
                                {
                                    n.transformer.AccountNumber
                                }
                            </TableCell>
                        </>
                    }
                    <TableCell className='fixed lwb new'>
                        <TextFormFastField
                            inputProps={{
                                className: 'cw fwb',
                                style: {
                                    textAlign: 'center',
                                },
                            }}
                            id={`values.${n.index}.budget`}
                            name={`values.${n.index}.budget`}
                            fullWidth
                            required
                            type={'number'}
                            color='white'
                        />
                    </TableCell>
                    <TableCell className='fixed lwb comment'>
                        <TextFormFastField
                            inputProps={{
                                className: 'cw fwb',
                            }}
                            id={`values.${n.index}.comment`}
                            name={`values.${n.index}.comment`}
                            fullWidth
                            color='white'
                        />
                    </TableCell>
                </TableRow>
            ));
    };
}

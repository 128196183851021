import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from '../../../services/firebaseService';
import generalFunctions from '../../../store/general/functions';
import CarouselImageViewer from '../image/CarouselViewer';

interface IFirebaseImageButtonProps {
    path : string;
    fileName : string;
}

interface IFirebaseImageButtonState {
    open : boolean;
    isLoading : boolean;

    downloadUrl : string;
}

export default class FirebaseImageButton extends React.Component<IFirebaseImageButtonProps, IFirebaseImageButtonState> {
    constructor(props : IFirebaseImageButtonProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
            downloadUrl: '',
        };
    }

    private readonly onImageClick = () => {
        const { downloadUrl } = this.state;

        if (downloadUrl) {
            this.setState({
                open: true,
            });
        } else {
            this.setState({
                isLoading: true,
            });
            this.loadImage();
        }
    };

    private readonly loadImage = async () => {

        try {
            const fullPath = `${this.props.path}/${this.props.fileName}`;
            const downloadUrl = await firebase.storage().ref(fullPath).getDownloadURL();

            this.setState({
                isLoading: false,
                downloadUrl,
                open: true,
            });
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error loading image.');
        }
    };

    private readonly onCarouselClose = () => {
        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { open, isLoading, downloadUrl } = this.state;
        return (<div>
            <Tooltip title='Display Image'>
                <div>
                    {
                        isLoading &&
                        <CircularProgress />
                    }
                    {
                        !isLoading &&
                        <IconButton onClick={this.onImageClick}>
                            <Icon>image</Icon>
                        </IconButton>
                    }
                </div>
            </Tooltip>
            {
                downloadUrl && open &&
                <CarouselImageViewer index={0} isOpen={open} imageSrcs={[downloadUrl]} onClose={this.onCarouselClose} />
            }
        </div>);
    };
}

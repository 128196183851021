import { Card, Icon, IconButton, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FieldProps } from 'formik';
import moment from 'moment';
import { Moment } from 'moment';
import React from 'react';
import AppFunctionsService from '../../../services/appFunctionsService';

interface IFormTabDateSelectorProps<T> extends FieldProps<Moment, T> {
}

export default class FormTabDateSelector<T> extends React.PureComponent<IFormTabDateSelectorProps<T>> {
    constructor(props : IFormTabDateSelectorProps<T>) {
        super(props);
        this.state = {};
    }

    private readonly onDateChange = (date : moment.Moment | null) => {
        if (!date) return;

        this.props.form.setFieldValue(this.props.field.name, moment(date).startOf('day').asUTC());
    };

    public readonly render = () => {
        const { field } = this.props;
        return (
            <Card className='fdr aic jcc p15 bclb'>
                <Typography className='flx1 aic jcc fs18 fwb cw'>
                    DATE: {`${AppFunctionsService.formatDateTimeToDateOnly(field.value)}`}
                </Typography>
                <div className='fdc jcc aic posr'>
                    <div className='posa'>
                        <DatePicker
                            {...field}
                            onChange={this.onDateChange}
                            TextFieldComponent={(textParams) => (
                                <IconButton className='cw mr30' onClick={textParams.onClick as React.MouseEventHandler}>
                                    <Icon>
                                        date_range
                                    </Icon>
                                </IconButton>
                            )}
                        />
                    </div>
                </div>
            </Card>
        );
    };
}

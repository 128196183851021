import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter, Redirect } from 'react-router';
import { IAuthState, IRootState } from '../../@types/redux';
import PrivateRoute from './PrivateRoute';
import BoreholeList from '../borehole/List';
import BoreholeMap from '../borehole/Map';
import EmployeeList from '../rights/employee/List';
import WeatherList from '../weather/List';
import WeatherMap from '../weather/Map';
import DamList from '../dam/List';
import DamMap from '../dam/Map';
import TransformerList from '../transformers/List';
import TransformerMap from '../transformers/Map';
import RiverList from '../river/List';
import RiverMap from '../river/Map';
import MottechList from '../mottech/List';
import MottechMap from '../mottech/Map';
import DivisionList from '../masterData/division/List';
import { createSelector } from 'reselect';
import NoAccess from './NoAccess';
import PumpList from '../masterData/borehole/pump/List';
import BoreholeMotorSizeList from '../masterData/borehole/motorSizes/List';
import BoreholeMotorList from '../masterData/borehole/motor/List';
import BoreholeIssueList from '../masterData/borehole/issue/List';
import TransformerTariffList from '../transformers/tariff/List';
import TransformerAccountList from '../transformers/account/List';
import RuntimesOverride from '../borehole/RuntimesOverride';
import TransformerSPUMeterList from '../transformers/capturing/SPUMeterList';
import TransformerCaptureList from '../transformers/capturing/List';
import TransformerSPUCapture from '../transformers/capturing/SPUCapture';
import TransformerLPUCapture from '../transformers/capturing/LPUCapture';
import TransformerGroupList from '../masterData/transformer/group/List';
import TransformerJournalList from '../transformers/journal/List';
import TransformerUsageList from '../masterData/transformer/usage/List';
import PumpingStationList from '../pumpingStation/List';
import PumpingStationMap from '../pumpingStation/Map';
import TransformerLPUMeterList from '../transformers/capturing/LPUMeterList';
import CompanyList from '../masterData/company/List';
import ErrorScreen from '../customComponents/ErrorScreen';
import ErrorBoundary from '../customComponents/ErrorBoundary';
import TransformerMunicipalityMeterList from '../transformers/capturing/MunicipalityMeterList';
import PumpingStationPumpMakeList from '../masterData/pumpingStation/pumpMake/List';
import PumpingStationPumpTypeList from '../masterData/pumpingStation/pumpType/List';
import PumpingStationMotorMakeList from '../masterData/pumpingStation/motorMake/List';
import PumpingStationMotorSizeList from '../masterData/pumpingStation/motorSize/List';
import PumpingStationMotorPoleList from '../masterData/pumpingStation/motorPole/List';
import PumpingStationMotorEfficiencyList from '../masterData/pumpingStation/motorEfficiency/List';
import PumpingStationMotorTypeList from '../masterData/pumpingStation/motorType/List';
import PumpingStationMotorMountingList from '../masterData/pumpingStation/motorMounting/List';
import PumpingStationFilterTypeList from '../masterData/pumpingStation/filterType/List';
import PumpingStationFilterSizeList from '../masterData/pumpingStation/filterSize/List';
import PumpingStationFlowmeterMakeList from '../masterData/pumpingStation/flowmeterMake/List';
import PumpingStationFlowmeterPulseList from '../masterData/pumpingStation/flowmeterPulse/List';
import PumpingStationFlowmeterSizeList from '../masterData/pumpingStation/flowmeterSize/List';
import PumpingStationVoluteCasingMakeList from '../masterData/pumpingStation/voluteCasingMake/List';
import FlowmeterList from '../flowmeter/List';
import FlowmeterMap from '../flowmeter/Map';
import TransformerBudgetList from '../transformers/budget/List';
import TransformerPrepaidMeterList from '../transformers/capturing/PrepaidMeterList';
import TransformerBudgetCsv from '../transformers/budget/Csv';
import MunicipalityInvoiceList from '../municipality/List';
import MunicipalityInvoiceCapture from '../municipality/Capture';
import SPSInvoiceList from '../sps/List';
import ChemicalFillingPointList from '../fillingPoint/chemical/List';
import ChemicalFillingPointMap from '../fillingPoint/chemical/Map';
import RainMeterManager from '../rainMeter/RainMeterManager';

interface IRoutesProps extends RouteComponentProps {
    auth : IAuthState;
}

class Routes extends React.Component<IRoutesProps> {
    constructor(props : IRoutesProps) {
        super(props);
    }

    public readonly getAuth = (props : IRoutesProps) => props.auth;

    public readonly getDefaultRoute = createSelector(
        [this.getAuth],
        (auth) => {
            if (auth.session?.employee.IsBoreholeUser) return '/borehole/list';
            if (auth.session?.employee.IsDamUser) return '/dam/list';
            if (auth.session?.employee.IsTransformerUser) return '/transformer/list';
            if (auth.session?.employee.IsWeatherStationUser) return '/weather/list';
            if (auth.session?.employee.IsMottechUser) return '/mottech/list';
            if (auth.session?.employee.IsRiverUser) return '/river/list';
            if (auth.session?.employee.IsPumpingStationUser) return '/pumpingStation/list';

            return '/no-access';
        },
    );

    public render() {
        const { auth } = this.props;

        return (
            <ErrorBoundary
                auth={auth}
            >
                <Switch>
                    <Route path={'/'} exact render={() => <Redirect from={'/'} to={{
                        pathname: this.getDefaultRoute(this.props),
                    }} />} />
                    <Route path={'/no-access'} exact component={NoAccess} />
                    <Route path={'/error'} exact component={ErrorScreen} />
                    <PrivateRoute auth={auth} path='/borehole/list' exact component={BoreholeList} />
                    <PrivateRoute auth={auth} path='/borehole/map' exact component={BoreholeMap} />
                    <PrivateRoute auth={auth} path='/dam/list' exact component={DamList} />
                    <PrivateRoute auth={auth} path='/dam/map' exact component={DamMap} />
                    <PrivateRoute auth={auth} path='/pumpingStation/list' exact component={PumpingStationList} />
                    <PrivateRoute auth={auth} path='/pumpingStation/map' exact component={PumpingStationMap} />
                    <PrivateRoute auth={auth} path='/weather/list' exact component={WeatherList} />
                    <PrivateRoute auth={auth} path='/weather/map' exact component={WeatherMap} />
                    <PrivateRoute auth={auth} path='/transformer/list' exact component={TransformerList} />
                    <PrivateRoute auth={auth} path='/transformer/map' exact component={TransformerMap} />
                    <PrivateRoute auth={auth} path='/transformer/tariffs' exact component={TransformerTariffList} />
                    <PrivateRoute auth={auth} path='/transformer/budget' exact component={TransformerBudgetList} />
                    <PrivateRoute auth={auth} path='/transformer/budget/csv' exact component={TransformerBudgetCsv} />
                    <PrivateRoute auth={auth} path='/transformer/accounts' exact component={TransformerAccountList} />
                    <PrivateRoute auth={auth} path='/transformer/capturing' exact component={TransformerCaptureList} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/spu' exact component={TransformerSPUCapture} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/lpu' exact component={TransformerLPUCapture} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/spu/meter' exact component={TransformerSPUMeterList} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/lpu/meter' exact component={TransformerLPUMeterList} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/municipality/meter' exact component={TransformerMunicipalityMeterList} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/municipality/invoice' exact component={MunicipalityInvoiceList} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/municipality/invoice/capture' exact component={MunicipalityInvoiceCapture} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/sps/invoice' exact component={SPSInvoiceList} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/sps/invoice/capture' exact component={SPSInvoiceList} />
                    <PrivateRoute auth={auth} path='/transformer/capturing/prepaid/meter' exact component={TransformerPrepaidMeterList} />
                    <PrivateRoute auth={auth} path='/transformer/journals' exact component={TransformerJournalList} />
                    <PrivateRoute auth={auth} path='/masterData/transformer/group' exact component={TransformerGroupList} />
                    <PrivateRoute auth={auth} path='/masterData/transformer/usage' exact component={TransformerUsageList} />
                    <PrivateRoute auth={auth} path='/river/list' exact component={RiverList} />
                    <PrivateRoute auth={auth} path='/river/map' exact component={RiverMap} />
                    <PrivateRoute auth={auth} path='/mottech/list' exact component={MottechList} />
                    <PrivateRoute auth={auth} path='/mottech/map' exact component={MottechMap} />
                    <PrivateRoute auth={auth} path='/employee' exact component={EmployeeList} />
                    <PrivateRoute auth={auth} path='/division' exact component={DivisionList} />
                    <PrivateRoute auth={auth} path='/masterData/pumps' exact component={PumpList} />
                    <PrivateRoute auth={auth} path='/masterData/motorSizes' exact component={BoreholeMotorSizeList} />
                    <PrivateRoute auth={auth} path='/masterData/motors' exact component={BoreholeMotorList} />
                    <PrivateRoute auth={auth} path='/masterData/boreholeIssues' exact component={BoreholeIssueList} />
                    <PrivateRoute auth={auth} path='/borehole/runtimesOverride' exact component={RuntimesOverride} />
                    <PrivateRoute auth={auth} path='/masterData/company' exact component={CompanyList} />

                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/voluteCasingMake' exact component={PumpingStationVoluteCasingMakeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/pumpMake' exact component={PumpingStationPumpMakeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/pumpType' exact component={PumpingStationPumpTypeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/motorEfficiency' exact component={PumpingStationMotorEfficiencyList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/motorMake' exact component={PumpingStationMotorMakeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/motorPole' exact component={PumpingStationMotorPoleList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/motorSize' exact component={PumpingStationMotorSizeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/motorType' exact component={PumpingStationMotorTypeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/motorMounting' exact component={PumpingStationMotorMountingList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/filterType' exact component={PumpingStationFilterTypeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/filterSize' exact component={PumpingStationFilterSizeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/flowmeterSize' exact component={PumpingStationFlowmeterSizeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/flowmeterMake' exact component={PumpingStationFlowmeterMakeList} />
                    <PrivateRoute auth={auth} path='/masterData/pumpingStation/flowmeterPulse' exact component={PumpingStationFlowmeterPulseList} />

                    <PrivateRoute auth={auth} path='/flowmeter/list' exact component={FlowmeterList} />
                    <PrivateRoute auth={auth} path='/flowmeter/map' exact component={FlowmeterMap} />

                    <PrivateRoute auth={auth} path='/chemical_filling_point/list' exact component={ChemicalFillingPointList} />
                    <PrivateRoute auth={auth} path='/chemical_filling_point/map' exact component={ChemicalFillingPointMap} />
                    <PrivateRoute auth={auth} path='/rainMeter/rainMeterManager' exact component={RainMeterManager} />
                </Switch>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

/* With router is important because it ensures the component updates with location changes, therefore allowing redirects */
export default withRouter(connect(
    mapStateToProps,
)(Routes));

import { motion } from 'framer-motion';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';

interface IUsageToggleButtonProps {
    text : string;
    value : string;

    selected ?: boolean;

    onClick ?: (value : string) => void;
}

interface IUsageToggleButtonState {

}

export default class UsageToggleButton extends React.PureComponent<IUsageToggleButtonProps, IUsageToggleButtonState> {
    constructor(props : IUsageToggleButtonProps) {
        super(props);
        this.state = {};
    }

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        if (this.props.onClick) this.props.onClick(this.props.value);
    };

    public readonly render = () => {
        const { text, selected } = this.props;
        return (
            <motion.button style={{
                outline: 'none',
                border: 'none',
                cursor: 'pointer',
            }}
            initial={{
                color: '#A7A7A7',
                backgroundColor: '#E0E0E0',
            }}
            animate={{
                color: !selected ? '#A7A7A7' : '#FFFFFF',
                backgroundColor: !selected ? '#E0E0E0' : '#0D703F',
            }}
            className='fdr aic fwm fs12 h31 br16 ' type='button' onClick={this.onClick}>
                <div className='aic jcc h23 w23 br16 mr7'style={{
                    color: !selected ? '#E0E0E0' : '#0D703F',
                    backgroundColor: !selected ? '#A7A7A7' : '#FFFFFF',
                }}>
                    <CheckIcon fontSize='small' />
                </div>
                <span className='mr7'>
                    {
                        text
                    }
                </span>
            </motion.button>
        );
    };
}

import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationMotorSize } from '../../../../@types/model/masterData/pumpingStation/motorSize';

type MasterDataPumpingStationMotorSizeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationMotorSizeState {
    motorSizes : Array<IPumpingStationMotorSize>;
    isLoading : boolean;
}
const initialState = {
    motorSizes: [],
    isLoading: false,
};

export default function motorSizeReducer(state : IMasterDataPumpingStationMotorSizeState = initialState, action : MasterDataPumpingStationMotorSizeActionType) : IMasterDataPumpingStationMotorSizeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, motorSizes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IDam } from '../../../@types/model/dam/dam';
import DamFunctions from '../../../store/dam/functions';
import { Fab } from '@material-ui/core';

interface IDamNewButtonProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    dams : Array<(IDam)>;
    isLoading : boolean;
}

interface IDamNewButtonState {
    open : boolean;

    name : string;
    code : string;
}

class DamNewButton extends React.Component<IDamNewButtonProps, IDamNewButtonState> {

    constructor(props : IDamNewButtonProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            code: '',
        };
    }
    
    private readonly onAddClick = () => {
        const latestDam = this.props.dams.slice().sort((a, b) => parseInt(b.code.substr(2)) - parseInt(a.code.substr(2)))[0];
        const maxNumber = parseInt(latestDam.code.substr(2)) + 1;
    
        this.setState({
            open: true,
            name: '',
            code: `WD${maxNumber.toString().padStart(3, '0')}`,
        });
    };


    private readonly onCloseClick = () => {
        if (this.props.isLoading) return;
        this.setState({
            open: false,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onCodeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value.length < 2 || event.currentTarget.value.substr(0, 2) !== 'WD') return;
        this.setState({
            code: event.currentTarget.value,
        });
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly save = async () => {
        const { name, code } = this.state;
        if (!name) return;

        if (code.substring(0, 2) !== 'WD') return;
        if (!Number(code.substring(2))) return;

        const result = await DamFunctions.createDam(name, code);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    public readonly render = () => {
        const { transition, fullWidth, maxWidth, fullScreen, isLoading } = this.props;
        const { open, name, code } = this.state;

        return (<div className={'jcfe mt10 mb30 mr20'}>
            <Tooltip title='Create'>
                <div className={' '}>
                    <Fab aria-label='Add' onClick={this.onAddClick} className='fab add cp bcy '>
                        <Icon>add</Icon>
                    </Fab>
                </div>
            </Tooltip>
            <Dialog
                open={open}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                aria-labelledby='new-dam-dialog-title'
                aria-describedby='new-dam-dialog-description'>
                <AppBar className='flx1 fdr posr p10 aic' position='static'>
                    <Typography variant='h5' color='inherit'>
                        NEW DAM - {code}
                    </Typography>
                    <span className='flx1' />
                    <Tooltip title='Close'>
                        <div>
                            <IconButton color='inherit' onClick={this.onCloseClick}>
                                <Icon>close</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill ais'>
                    <div style={{ height: 5 }}>
                        {
                            isLoading &&
                            <LinearProgress />
                        }
                    </div>
                    <DialogContent className='fdr jcfs aifs flx1'>
                        <div className={'flx1 ais p5 mb10 pr20'}>
                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    autoComplete='off'
                                    id='code'
                                    label='Code'
                                    value={code}
                                    onChange={this.onCodeChange}
                                    margin='normal'
                                    className={'TextField'}
                                    required
                                    error={!code || code.length < 2 || !parseInt(code.substr(2))}
                                />
                                {
                                    !code &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                                {
                                    (code.length < 2 || !parseInt(code.substr(2))) &&
                                    <FormHelperText error>Invalid</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={'flx1 ais p5 mb10 pr20'}>
                            <FormControl fullWidth>
                                <TextField
                                    autoFocus
                                    autoComplete='off'
                                    id='name'
                                    label='Name'
                                    value={name}
                                    onChange={this.onNameChange}
                                    margin='normal'
                                    className={'TextField'}
                                    required
                                    error={!name}
                                />
                                {
                                    !name &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' type='submit' color='primary' disabled={isLoading}>
                            CREATE
                        </Button>
                        <Button variant='outlined' onClick={this.onCloseClick} color='primary' disabled={isLoading}>
                            CLOSE
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        dams: state.dam.dams,
        isLoading: state.dam.loading,
    };
};

export default connect(
    mapStateToProps,
)(DamNewButton);
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { IUserSession } from '../../employee';
import { IDepricatedBaseClass } from '../base';
import firebase from 'firebase/app';
import { IGroundWaterZone } from '../../model';
import { IFileMetaData } from '../files/fileMetaData';

export interface IBorehole extends IDepricatedBaseClass<IBorehole> {
    Name : string;
    Code : string;
    Geom ?: firebase.firestore.GeoPoint;
    Elevation : number;
    Status ?: string;
    Division : string | null;
    DivisionDescriptionAccpac : string | null;
    DivisionNumber : number | null;
    EPNumber : string | null;
    ManagementArea : string | null;
    CasingDiameter : number | null;
    HoleDepth : number | null;
    InstallationDepth : number | null;
    PerfomanceTest : string | null;

    /// MAXIMUM ABSTRACTION RATE (m3/hr)
    PerfomanceYield ?: number | null;

    /// RECOMMENDED ABSTRACTION RATE (m3/hr)
    DesignedYield : number | null;

    InstalledYield : number | null;
    AdjustedYield : number | null;
    ConcreteBlockHeight : number | null;
    PiezometerTubeHeight : number | null;
    DesignDate ?: firebase.firestore.Timestamp | null;
    MotorSize : number | null;
    MotorType : string | null;
    MotorPhase : string | null;
    MotorDiameter : number | null;
    MotorSerial : string | null;
    BoreholeImageFileName : string | null;
    BoreholeImageUrl : string | null;
    BoreholeImageThumbnailUrl : string | null;
    MotorImageFileName : string | null;
    MotorImageUrl : string | null;
    MotorImageThumbnailUrl : string | null;
    ElectricalBoxImageFileName : string | null;
    ElectricalBoxImageUrl : string | null;
    ElectricalBoxImageThumbnailUrl : string | null;
    PipeLength : number | null;
    CableCores : number | null;
    CableSize : number | null;
    CableLength : number | null;
    Pump : string | null;
    PumpType : string | null;
    PumpSerial : string | null;
    PumpImageFileName : string | null;
    PumpImageUrl : string | null;
    PumpImageThumbnailUrl : string | null;
    TestValve ?: number | null;
    Starter : boolean | null;
    StarterType : string | null;
    CasingHeight : number | null;
    RisingMain : string | null;
    RisingMainType : string | null;
    IsActive ?: boolean;
    uPVCColumn : string | null;
    uPVCColumnType : string | null;
    LevelCheck : boolean;
    Comment : string;
    IsSent : boolean;
    User : string | null;
    // RECOMMENDED INSTALLED DEPTH (mbdl)
    InstallDepthRecommendation ?: number | null;
    StaticWaterLevel ?: number | null;
    DynamicWaterLevelLimit ?: number | null;
    CriticalWaterLevel ?: number | null;

    // RECOMMENDED MAXIMUM ABSTRACTION (m3/day)
    MaximumDailyAbstraction ?: number | null;
    EmergencyMaxYield ?: number | null;
    Equipped ?: boolean;
    LatestYield ?: number;
    /**
     * Total Runtime in hours of the last Borehole installation.
     */
    LatestRuntime ?: number;
    /**
     * Total Runtime Override in hours of the last Borehole installation.
     */
    RuntimeOverride ?: number;
    GroundwaterZone : null | IGroundWaterZone;
    PumpingCycle ?: number | null;
}

export default class BoreholeHelper {
    public static readonly COLLECTION_NAME = 'borehole';

    private static converter : firebase.firestore.FirestoreDataConverter<IBorehole> = {
        fromFirestore: (snapshot) => {
            return BoreholeHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBorehole) : firebase.firestore.DocumentData => {
            const { ref: _ref, ...firestoreObject } = data;
            return firestoreObject;
        },
    };

    public static fromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data() as IBorehole | undefined;

        if (!data) {
            throw new Error(`Document does not exist! ${doc.id}`);
        }

        const result : IBorehole = { ...data, ref: doc.ref.withConverter(BoreholeHelper.converter), IsActive: !!(typeof data.IsActive === 'undefined' || data.IsActive) };

        if (result.Status && typeof result.Status === 'object') {
            const status = result.Status as firebase.firestore.DocumentReference;
            result.Status = status.id;
        }

        if (result.Pump === 'SVM') {
            result.Pump = 'Franklin';
        }

        if (typeof result.DivisionNumber === 'string') {
            result.DivisionNumber = parseInt(result.DivisionNumber);
        }

        return result;
    }

    public static doc(id : string) {
        return firebaseApp.firestore().collection(this.COLLECTION_NAME).withConverter(this.converter).doc(id);
    }

    public static async create(borehole : IBorehole) {
        const doc = await borehole.ref.get();

        if (doc.exists) {
            throw new Error(`${borehole.EPNumber} already exists.`);
        }

        return borehole.ref.set(borehole);
    }

    public static update(borehole : IBorehole) {
        return borehole.ref.set(borehole, {
            merge: true,
        });
    }

    public static batchUpdate(boreholes : Array<IBorehole>) {
        const batch = firebaseApp.firestore().batch();

        boreholes.forEach((borehole) => {
            batch.set(borehole.ref, borehole, { merge: true });
        });

        return batch.commit();
    }

    public static activate(id : string, session : IUserSession) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                UpdatedByName: session.employee.Name,
                IsActive: true,
                IsSent: false,
            } as IBorehole);
    }

    public static delete(id : string, session : IUserSession) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                UpdatedByName: session.employee.Name,
                IsActive: false,
                IsSent: false,
            } as IBorehole);
    }

    public static listen() {
        return firebaseApp.firestore().collection(this.COLLECTION_NAME).withConverter(this.converter);
    }

    public static uploadImage(borehole : IBorehole, file : File, metadata : IFileMetaData, progressCallback : (progress : number) => void, errorCallback ?: (e : Error) => void) {
        const unsub = FirebaseService.fileUpload(file, `borehole/${borehole.Code}/${new Date().valueOf()}_${file.name}`, metadata)
            .on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                if (progress >= 100) {
                    unsub();
                }

                progressCallback(progress);
            }, errorCallback);
    }

    public static getQrCodeText(borehole : IBorehole) {
        return `${borehole.Code}`;
    }
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import TransformerUsageHelper, { ITransformerUsage } from '../../../../@types/model/transformer/transformerUsage';
import StandardFab from '../../../customComponents/button/StandardFab';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { IAuthState } from '../../../../@types/redux';
import moment from 'moment';
import generalFunctions from '../../../../store/general/functions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface ITransformerUsageEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    usage ?: ITransformerUsage;

    isLoading : boolean;
    auth : IAuthState;

    defaultCode ?: string;
}

interface ITransformerUsageEditDialogState {
    open : boolean;

    code : string;
    name : string;
    isActive : boolean;
}

export default class TransformerUsageEditDialog extends React.PureComponent<ITransformerUsageEditDialogProps, ITransformerUsageEditDialogState> {
    constructor(props : ITransformerUsageEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            code: '',
            name: '',
            isActive: true,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });

        if (this.props.usage) {
            this.setState({
                code: this.props.usage.code,
                name: this.props.usage.name,
                isActive: this.props.usage.isActive,
            });
        } else if (this.props.defaultCode) {
            this.setState({
                code: this.props.defaultCode,
                isActive: true,
            });
        }
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
            code: '',
            name: '',
        });
    };

    private readonly save = async (code : string, name : string, isActive : boolean) => {
        const usage = this.props.usage ?? {
            createdBy: this.props.auth.session?.firebaseUser.uid ?? '',
            createdByEmployee: this.props.auth.session?.employee.EmployeeNumber ?? '',
            createdByName: this.props.auth.session?.employee.Name ?? '',
            createdOn: moment.utc().valueOf(),
        } as ITransformerUsage;

        usage.code = code;
        usage.name = name;
        usage.isActive = isActive;

        usage.updatedBy = this.props.auth.session?.firebaseUser.uid ?? '';
        usage.updatedByEmployee = this.props.auth.session?.employee.EmployeeNumber ?? '';
        usage.updatedByName = this.props.auth.session?.employee.Name ?? '';
        usage.updatedOn = moment.utc().valueOf();

        try {
            await TransformerUsageHelper.save(usage);
            this.onClose();
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error Saving Usage');
        }
    };

    private readonly onCodeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            code: event.currentTarget.value,
        });
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { code, name, isActive } = this.state;

        if (!code || !name) return;

        this.save(code, name, isActive);
    };

    private readonly onIsActiveChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isActive: checked,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, usage, isLoading } = this.props;
        const { open, name, code, isActive } = this.state;
        return (
            <div>
                <Tooltip title={!usage ? 'Add' : 'Edit'}>
                    <div>
                        {
                            !usage &&
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onClick}>
                                <AddIcon />
                            </StandardFab>

                        }
                        {
                            usage &&
                            <IconButton color='primary' onClick={this.onClick}
                                aria-label='Edit' disabled={isLoading }>
                                <Icon>edit</Icon>
                            </IconButton>

                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Edit-Transformer-Bank-dialog-title'
                    aria-describedby='Edit-Transformer-Bank-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!usage ? 'Create' : 'Edit'} Usage
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 ais hfill'>
                            <div className='fdc flx1 ais jcc'>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 aifs pr5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='code'
                                                label='Code'
                                                value={code}
                                                onChange={this.onCodeChange}
                                                margin='normal'
                                                required
                                                error={!code}
                                                className={'TextField'}
                                            />
                                            {
                                                !code &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aifs pl5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='name'
                                                label='Name'
                                                value={name}
                                                onChange={this.onNameChange}
                                                margin='normal'
                                                required
                                                error={!name}
                                                className={'TextField'}
                                            />
                                            {
                                                !name &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 ais pr5'>
                                        <FormControl margin='normal'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color='primary' checked={isActive} onChange={this.onIsActiveChanged} />
                                                }
                                                label='IsActive?'
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aifs pl5'>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <OutlinedStadiumButton disabled={isLoading} className='mr16' variant='text' type='reset' onClick={this.onClose}>
                                CANCEL
                            </OutlinedStadiumButton>
                            <StadiumButton variant='contained' disabled={isLoading} type='submit' >
                                SAVE
                            </StadiumButton>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    };
}

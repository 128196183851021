import firebaseApp from '../../../services/firebaseService';
import { IDepricatedBaseClass } from '../base';
import { IUserSession } from '../../employee';
import firebase from 'firebase/app';
import IBankAccount from '../bankAccount';
import { ICompany } from '../masterData/company';

export const TransformerMeterTypes : ['spu', 'lpu', 'municipality', 'prepaid', 'sps'] = ['spu', 'lpu', 'municipality', 'prepaid', 'sps'];
export const TransformerMeterTypesUpperCase : ['SPU', 'LPU', 'Municipality', 'Prepaid', 'SPS'] = ['SPU', 'LPU', 'Municipality', 'Prepaid', 'SPS'];
export type TransformerMeterType = typeof TransformerMeterTypes[number];
export type TransformerMeterTypeUpperCase = typeof TransformerMeterTypesUpperCase[number];

export const TransformerMeterUoM : ['kWh', 'kVa'] = ['kWh', 'kVa'];
export type TransformerMeterUoMType = typeof TransformerMeterUoM[number];

export interface ITransformerCompany extends Omit<ICompany,
'createdBy' |
'updatedBy' |
'createdByName' |
'updatedByName' |
'createdByEmployee' |
'updatedByEmployee' |
'createdOn' |
'updatedOn' |
'isWeb'> {

}

// TODO: Rework to IBasicBaseClass to remove non serializable variables
export interface ITransformer extends IDepricatedBaseClass<ITransformer> {
    Usage ?: firebase.firestore.FieldValue | Array<string>;
    GUID : string;
    EPNumber : string;
    EPGroup : string | null;
    Consolidated : boolean | null;
    Geom ?: firebase.firestore.GeoPoint;
    Division : string;
    SubArea : string;
    CNC : string;
    Line : string;
    LineVoltage : [number, number] | null;
    ManualReading : boolean;
    MeterType : TransformerMeterTypeUpperCase | '' | null;
    EPType : string | null;
    PhaseSize : number | null;
    PoleNumber : string | null;
    PoleFileName : string | null;
    PoleFileThumbnailUrl : string | null;
    PoleFileUrl : string | null;
    CircuitFileName : string | null;
    CircuitFileThumbnailUrl : string | null;
    CircuitFileUrl : string | null;
    CircuitBreaker : number | null;
    TransformerFileName : string | null;
    TransformerFileThumbnailUrl : string | null;
    TransformerFileUrl : string | null;
    TransformerNumber : string | null;
    TransformerRating : number | null;
    YellowNumber : string | null;
    YellowNumberFileName : string | null;
    YellowNumberFileThumbnailUrl : string | null;
    YellowNumberFileUrl : string | null;
    UsageSplits ?: Array<ITransformerSplit>;
    TransformerMeterNumbers ?: Array<ITransformerMeter>;
    AccountNumber : string | null;
    RoadNumber : string | null;
    IsActive ?: boolean;
    IsSent : boolean;
    TransformerReaderEmployeeName : string | null;
    TransformerReaderEmployeeNr : string | null;

    BankAccount ?: IBankAccount | null;
    TransmissionZone : [number, number] | null;
    Landrate : number | null;

    Company ?: ITransformerCompany | null;

    NotifiedMaxDemand : number | null;
    PremiseId : string | null;

    SecurityGuarantee : number | null;
    SecurityDeposit : number | null;
    CurrentResident : string | null;

    LinkedEp : string | null;
}

export interface ITransformerMeter {
    ItemNumber : number;
    Number : string;
    NumberOfDigits : number;
    LastReading : number | null;
    LatestMeterUsage : number | null;
    UnitOfMeasure : TransformerMeterUoMType | null;
}

export interface ITransformerSplit {
    Usage : string;
    Dept : string;
    DeptName : string;
    Percentage : number | null;
    FixedAmount : number | null;
    IncludeVat : boolean;
    FleetNumber ?: string | null;
}

export default class TransformerHelper {
    public static readonly COLLECTION_NAME = 'electricalpoint';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformer> = {
        fromFirestore: (snapshot) => {
            return TransformerHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformer) : firebase.firestore.DocumentData => {
            return TransformerHelper.toFirestore(data);
        },
    };

    public static fromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data() as ITransformer;

        if (!doc.exists) {
            throw new Error(`Document does not exist! ${doc.id}`);
        }

        return TransformerHelper.fromDocument(data, doc.ref.withConverter(TransformerHelper.converter));
    }

    public static fromDocument(data : ITransformer, ref : firebase.firestore.DocumentReference<ITransformer>) {
        const result : ITransformer = {
            ...data,
            ref: ref,
            TransmissionZone: !data.TransmissionZone ? null : data.TransmissionZone,
            Landrate: !data.Landrate ? null : data.Landrate,
            IsActive: !!(typeof data.IsActive === 'undefined' || data.IsActive),
            TransformerMeterNumbers: data.TransformerMeterNumbers ?? [],
            NotifiedMaxDemand: data.NotifiedMaxDemand ?? null,
            LineVoltage: typeof data.LineVoltage === 'number' ? null : (data.LineVoltage ?? null),
            Company: typeof data.Company !== 'object' ? null : data.Company,
        };

        return result;
    }

    public static toFirestore(data : ITransformer, ignoreUsage ?: boolean) {
        const { ref: _ref, ...firestoreObject } = data;

        if (!ignoreUsage && 'Usage' in firestoreObject && firestoreObject.UsageSplits) {
            firestoreObject.Usage = firebase.firestore.FieldValue.delete();
        }

        return firestoreObject;
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(TransformerHelper.COLLECTION_NAME).withConverter(TransformerHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(TransformerHelper.COLLECTION_NAME).withConverter(TransformerHelper.converter).doc(id);
    }

    public static path(path : string) {
        return firebaseApp.firestore().doc(path).withConverter(TransformerHelper.converter);
    }

    public static newDoc(id ?: string) {
        return firebaseApp.firestore().collection(TransformerHelper.COLLECTION_NAME).withConverter(TransformerHelper.converter).doc(id);
    }

    public static async create(transformer : ITransformer) {
        const doc = await transformer.ref.get();

        if (doc.exists) {
            throw new Error(`${transformer.EPNumber} already exists.`);
        }

        return transformer.ref.set(transformer);
    }

    public static update(transformer : ITransformer) {
        return transformer.ref.withConverter(TransformerHelper.converter).set(transformer, {
            merge: true,
        });
    }

    public static activate(id : string, session : IUserSession) {
        return firebaseApp
            .firestore()
            .collection(TransformerHelper.COLLECTION_NAME)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                UpdatedByName: session.employee.Name,
                IsActive: true,
                IsSent: false,
            } as ITransformer);
    }

    public static delete(id : string, session : IUserSession) {
        return firebaseApp
            .firestore()
            .collection(TransformerHelper.COLLECTION_NAME)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                UpdatedByName: session.employee.Name,
                IsActive: false,
                IsSent: false,
            } as ITransformer);
    }

    public static listen() {
        return firebaseApp.firestore().collection(TransformerHelper.COLLECTION_NAME).withConverter(TransformerHelper.converter);
    }

    public static async loadImage(transformer : ITransformer, fileName : string) {
        const fullPath = `electricalpoint/${transformer.EPNumber}/${fileName}`;
        const thumbPath = `electricalpoint/${transformer.EPNumber}/thumb_${fileName}`;

        const promises = [firebase.storage().ref(fullPath).getDownloadURL()];
        promises.push(firebase.storage().ref(thumbPath).getDownloadURL());

        return await Promise.all(promises);
    }
}

import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import PumpingStationHelper from '../../../../@types/model/pumpingStation/pumpingStation';

interface IPumpingStationPurposeSelectorProps {
    value : string;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : string) => void;
}

interface IPumpingStationPurposeSelectorState { }

class PumpingStationPurposeSelector extends React.Component<IPumpingStationPurposeSelectorProps, IPumpingStationPurposeSelectorState> {
    constructor(props : IPumpingStationPurposeSelectorProps) {
        super(props);

        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? '');
    };

    private readonly getValue = (state : IPumpingStationPurposeSelectorState, props : IPumpingStationPurposeSelectorProps) => props.value;
    private readonly getRequired = (state : IPumpingStationPurposeSelectorState, props : IPumpingStationPurposeSelectorProps) => props.required;

    private readonly getPurposes = () => PumpingStationHelper.PURPOSES;

    private readonly getPurposesForDropdown = createSelector([ 
        this.getRequired, this.getPurposes,
    ], (required, purposes) => {
        
        const dropdownData = lodash.map(purposes, (element) => ({ label: element, value: element }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([ 
        this.getValue, this.getRequired, this.getPurposes,
    ], (value, required, purposes) => {
        if (value) {
            const purpose = purposes.find(element => element === value);

            if (purpose) {
                return { label: purpose, value: purpose };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, className, disabled } = this.props;

        const dropdownData = this.getPurposesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'purpose'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Purpose'} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

export default PumpingStationPurposeSelector;
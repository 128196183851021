import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import { IRootState } from '../../../../@types/redux';
import { BOREHOLE_STATUS } from '../../../../appConstants';
import appFunctionsService from '../../../../services/appFunctionsService';
import { Transitions } from '../../../customComponents/animations/Transitions';
import EditBoreholeInfo from '../../dialog/EditInfo';
import BoreholeDownloadQrCodeButton from '../../dialog/DownloadQrCodes';

interface IBoreholeInfoViewProps {
    borehole : IBorehole;

    isLoading : boolean;

    isBoreholeAdmin : boolean;
}

interface IBoreholeInfoViewState {}

class BoreholeInfoViewComponent extends React.PureComponent<IBoreholeInfoViewProps, IBoreholeInfoViewState> {
    constructor(props : IBoreholeInfoViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { isBoreholeAdmin, borehole, isLoading } = this.props;

        return (
            <div className='fdc flx1'>
                <div className={'flx1 fdr aifs jcc mt15'}>
                    <Card className={'bcw mt30 fdc'}>
                        <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                            <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                BOREHOLE INFORMATION
                            </Typography>
                            <div className='posr posr aic jcc'>
                                <div className='posa posr0'>
                                    {
                                        isBoreholeAdmin &&
                                        <EditBoreholeInfo
                                            borehole={borehole}
                                            isLoading={isLoading}
                                            fullWidth
                                            maxWidth='md'
                                            transition={Transitions.Down}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'fdc flx1 ml17 mr17 mb13'}>
                            <div className={'flx1 mt9 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        BOREHOLE CODE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {borehole.Code}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        BOREHOLE NAME
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {borehole.Name}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        BOREHOLE TEST
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {borehole.PerfomanceTest ? borehole.PerfomanceTest : 'None'}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        EQUIPPED
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {
                                            borehole.Equipped ? 'YES' : 'NO'
                                        }
                                    </Typography>
                                </div>
                            </div>
                            <div className={'flx1 mt9 fdr'}>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        STATUS
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {BOREHOLE_STATUS[borehole.Status ?? '1']}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        DESIGNED DATE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {borehole.DesignDate ? appFunctionsService.formatDateTimeToDateOnly(borehole.DesignDate) : 'None'}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        LEVEL CHECK
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {borehole.LevelCheck ? 'YES' : 'NO'}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc flx1 aic jcc w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        GROUNDWATER ZONE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {borehole.GroundwaterZone ? borehole.GroundwaterZone.Name : 'None'}
                                    </Typography>
                                </div>
                            </div>
                            <div className={'flx1 mt9 fdr'}>
                                <div className={'bcg0 fdc flx1 aifs jcfs mr8 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        COMMENT
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {borehole.Comment}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className='fdr aic mb15 mr15'>
                    <span className='flx1' />
                    <BoreholeDownloadQrCodeButton value={borehole} />
                </div>

            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
        isLoading: state.borehole.loading,
    };
};

const BoreholeInfoView = connect(
    mapStateToProps,
)(BoreholeInfoViewComponent);

export default BoreholeInfoView;

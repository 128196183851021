import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import RiverFilterPopper from '../popper/RiverFilterPopper';

interface IRiverFilterButtonProps {
    id ?: string;
    open : boolean;

    onClick ?: () => void;

    name : string | null;
    code : string | null;
    division : string | null;
    onChange ?: (name : string | null, code : string | null, division : string | null) => void;

    disabled ?: boolean;
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
}

interface IRiverFilterButtonState {
}

export default class RiverFilterButton extends React.PureComponent<IRiverFilterButtonProps, IRiverFilterButtonState> {
    private filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : IRiverFilterButtonProps) {
        super(props);
        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    public readonly render = () => {
        const { id, onChange, name, code, division, disabled, onClick, open, color } = this.props;
        return (
            <>
                <Tooltip title='Filter'>
                    <div>
                        <IconButton
                            ref={this.filterRef}
                            aria-label='toggle filter menu'
                            onClick={onClick}
                            disabled={disabled}
                            color={color}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <RiverFilterPopper
                    id={id}
                    anchorEl={this.filterRef.current}
                    open={open}
                    onCancelClick={onClick}
                    onChange={onChange}
                    name={name}
                    code={code}
                    division={division}
                />
            </>
        );
    };
}

import { firestore } from 'firebase/app';
import { Moment } from 'moment';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';
import BoreholeHelper from './borehole';
import * as Yup from 'yup';
import { YupSchema } from '../../../services/helper/yupHelper';
import moment from 'moment';
import uuid from 'uuid';

export const BoreholeConstantRateNeighbouringStage : [1,2,3,4,5,6] = [1,2,3,4,5,6];
export type BoreholeConstantRateNeighbouringStageType = typeof BoreholeConstantRateNeighbouringStage[number];

export const BoreholeConstantRateDrumStage : [1,2,3,4] = [1,2,3,4];
export type BoreholeConstantRateDrumStageType = typeof BoreholeConstantRateDrumStage[number];

export const BoreholeConstantRateTime : [
    1,2,3,4,5,7,9,12,15,20,25,30,40,50,60,70,80,90,120,150,180,210,
    240,300,360,420,480,540,600,660,720,780,840,900,960,1020,1080,
    1140,1200,1260,1320,1380,1440,1500
] = [
    1,2,3,4,5,7,9,12,15,20,25,30,40,50,60,70,80,90,120,150,180,210,
    240,300,360,420,480,540,600,660,720,780,840,900,960,1020,1080,
    1140,1200,1260,1320,1380,1440,1500
];
export type BoreholeConstantRateTimesType = typeof BoreholeConstantRateTime[number];

export interface IBoreholeConstantRateTest extends IBasicBaseClass {
    boreholeRef : string; // Borehole Id
    date : number;

    guid : string;
    documentName : string | null;
    documentURL : string | null;
    employeeNumber : string;
    employeeName : string;

    geo : [number, number];
    elevation : number;

    pumpDepth : number;
    installedPump : number;
    dewateringYield : number | null;

    staticWaterLevel : number;

    recommendedRate : number;
    nextBorehole : string | null;

    levelLoggerDepth : number | null;
    levelLoggerTime : string | null;
    levelLoggerDate : number | null;

    levelLoggerPumpOnTime : string | null;
    levelLoggerPumpOnDate : number | null;

    levelLoggerPumpOffTime : string | null;
    levelLoggerPumpOffDate : number | null;

    levelLoggerOutTime : string | null;
    levelLoggerOutDate : number | null;

    neighbouringBoreholes : Array<Record<BoreholeConstantRateNeighbouringStageType, IBoreholeConstantRateTestNeighbour>>;

    drumEntries : Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateDrumEntry>;
    entries : Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntry>;
    recoveryEntries : Record<BoreholeConstantRateTimesType, IBoreholeConstantRateRecoveryTimeEntry>;

    comment : string;

    isSent : boolean;
    isActive : boolean;
}

export interface IBoreholeConstantRateTestNeighbour {
    borehole : string;
    time : string | null;
    waterLevel : number | null;
}

export interface IBoreholeConstantRateTimeEntry {
    time : number;
    waterLevel : number | null;
    rate : number | null;
}

export interface IBoreholeConstantRateRecoveryTimeEntry extends Omit<IBoreholeConstantRateTimeEntry, 'rate'> {
}

export interface IBoreholeConstantRateDrumEntry {
    time : string | null;
    fillTime : number| null;
    rate : number| null;
    capacity : number | null;
}

export interface IBoreholeConstantRateTestFormValues {
    guid : string;
    date : Moment;

    latitude : number | null;
    longitude : number | null;
    elevation : number | null;

    employeeNumber : string | null;
    employeeName : string | null;

    pumpDepth : number | null;
    staticWaterLevel : number | null;
    installedPump : number | null;
    recommendedRate : number | null;
    dewateringYield : number | null;
    nextBorehole : string | null;

    levelLoggerDepth : number | null;
    levelLoggerTime : Moment | null;
    levelLoggerDate : Moment | null;
    levelLoggerPumpOnTime : Moment | null;
    levelLoggerPumpOnDate : Moment | null;
    levelLoggerPumpOffTime : Moment | null;
    levelLoggerPumpOffDate : Moment | null;
    levelLoggerOutTime : Moment | null;
    levelLoggerOutDate : Moment | null;

    neighbouringBoreholes : Array<Record<BoreholeConstantRateNeighbouringStageType, IBoreholeConstantRateTestNeighbourFormValues>>;

    drumEntries : Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateTestDrumFormValues>;

    entries : Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntryFormValues>;
    recoveryEntries : Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeRecoveryEntryFormValues>;

    comment : string;
}

export interface IBoreholeConstantRateTestNeighbourFormValues {
    borehole : string | null;
    time : Moment | null;
    waterLevel : number | null;
}

export interface IBoreholeConstantRateTestDrumFormValues {
    time : Moment | null;
    fillTime : number | null;
    rate : number | null;
    capacity : number | null;
}

export interface IBoreholeConstantRateTimeEntryFormValues {
    time : number;
    waterLevel : number | null;
    rate : number | null;
}

export interface IBoreholeConstantRateTimeRecoveryEntryFormValues extends Omit<IBoreholeConstantRateTimeEntryFormValues, 'rate'> {
}

type YupShape = Record<keyof IBoreholeConstantRateTestFormValues, YupSchema>;
type YupNeighbourRecordShape = Record<keyof Record<BoreholeConstantRateNeighbouringStageType, IBoreholeConstantRateTestNeighbourFormValues>, YupSchema>;
type YupNeighbourShape = Record<keyof IBoreholeConstantRateTestNeighbourFormValues, YupSchema>;

type YupDrumRecordShape = Record<keyof Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateTestDrumFormValues>, YupSchema>;
type YupDrumShape = Record<keyof IBoreholeConstantRateTestDrumFormValues, YupSchema>;

type YupEntryRecordShape = Record<keyof Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntryFormValues>, YupSchema>;
type YupEntryShape = Record<keyof IBoreholeConstantRateTimeEntryFormValues, YupSchema>;

type YupRecoveryEntryRecordShape = Record<keyof Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeRecoveryEntryFormValues>, YupSchema>;
type YupRecoveryEntryShape = Record<keyof IBoreholeConstantRateTimeRecoveryEntryFormValues, YupSchema>;

// Have to use a function here as moment() is only added after the application mounts.
const yupNeighbourSchema = () => Yup.object<YupNeighbourShape>().shape({
    borehole: Yup.string().nullable().when(['time'], {
        is: (time : Date | null) => !!time,
        then: (schema) => schema.required('Required'),
    }).when(['waterLevel'], {
        is: (waterLevel : number | null) => !!waterLevel,
        then: (schema) => schema.required('Required'),
    }),
    time: Yup.date().nullable().moment().when(['waterLevel'], {
        is: (waterLevel : number | null) => !!waterLevel,
        then: (schema) => schema.required('Required'),
    }),
    waterLevel: Yup.number().nullable().when(['time'], {
        is: (time : Date | null) => !!time,
        then: (schema) => schema.required('Required'),
    }),
}, [
    ['time', 'waterLevel'],
    ['borehole', 'waterLevel'],
    ['borehole', 'time'],
]);

const yupDrumSchema = () => Yup.object().shape<YupDrumShape>({
    rate: Yup.number().nullable(),
    time: Yup.date().nullable().moment(),
    fillTime: Yup.number().nullable(),
    capacity: Yup.number().nullable(),
});

const yupEntrySchema = Yup.object().shape<YupEntryShape>({
    rate: Yup.number().nullable().when(['waterLevel'], {
        is: (waterLevel : number | null) => !!waterLevel,
        then: (schema) => schema.required('Required'),
    }),
    time: Yup.number().nullable(),
    waterLevel: Yup.number().nullable().when(['rate'], {
        is: (rate : number | null) => !!rate,
        then: (schema) => schema.required('Required'),
    }),
}, [
    ['rate','waterLevel']
]);

const yupRecoveryEntrySchema = Yup.object().shape<YupRecoveryEntryShape>({
    time: Yup.number().nullable(),
    waterLevel: Yup.number().nullable(),
});

export default class BoreholeConstantRateTestHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'borehole_constant_rate';

    private static converter : firebase.firestore.FirestoreDataConverter<IBoreholeConstantRateTest> = {
        fromFirestore: (snapshot) => {
            return BoreholeConstantRateTestHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBoreholeConstantRateTest) : firebase.firestore.DocumentData => {
            return BoreholeConstantRateTestHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IBoreholeConstantRateTest {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IBoreholeConstantRateTest,
            ...result,
            boreholeRef: (data['boreholeRef'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
            geo: [(data['geo'] as firestore.GeoPoint).latitude, (data['geo'] as firestore.GeoPoint).longitude],
            levelLoggerDate: !data['levelLoggerDate'] ? null : (data['levelLoggerDate'] as firestore.Timestamp).toMillis(),
            levelLoggerPumpOnDate: !data['levelLoggerPumpOnDate'] ? null : (data['levelLoggerPumpOnDate'] as firestore.Timestamp).toMillis(),
            levelLoggerPumpOffDate: !data['levelLoggerPumpOffDate'] ? null : (data['levelLoggerPumpOffDate'] as firestore.Timestamp).toMillis(),
            levelLoggerOutDate: !data['levelLoggerOutDate'] ? null : (data['levelLoggerOutDate'] as firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : IBoreholeConstantRateTest) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            boreholeRef: BoreholeHelper.doc(data.boreholeRef),
            date: firestore.Timestamp.fromMillis(data.date),
            geo: new firestore.GeoPoint(data.geo[0], data.geo[1]),
            levelLoggerDate: !data.levelLoggerDate ? null : firestore.Timestamp.fromMillis(data.levelLoggerDate),
            levelLoggerPumpOnDate: !data.levelLoggerPumpOnDate ? null : firestore.Timestamp.fromMillis(data.levelLoggerPumpOnDate),
            levelLoggerPumpOffDate: !data.levelLoggerPumpOffDate ? null : firestore.Timestamp.fromMillis(data.levelLoggerPumpOffDate),
            levelLoggerOutDate: !data.levelLoggerOutDate ? null : firestore.Timestamp.fromMillis(data.levelLoggerOutDate),
        };
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .withConverter(this.converter);
    }

    public static save(data : IBoreholeConstantRateTest) {
        if (!data.id) {
            return BoreholeConstantRateTestHelper.doc().set(data, {
                merge: true,
            });
        }

        return BoreholeConstantRateTestHelper.doc(data.id).set(data, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().withConverter(BoreholeConstantRateTestHelper.converter).doc();
        }

        return this.collection().withConverter(BoreholeConstantRateTestHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return this.doc(id)
            .delete();
    }

    public static uploadFile(boreholeCode : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `borehole/${boreholeCode}/borehole_constant_rate/${new Date().valueOf()}_${file.name}`, metadata);
    }

    public static initialNeighbourFormValues(value ?: Record<BoreholeConstantRateNeighbouringStageType, IBoreholeConstantRateTestNeighbour>)  : Record<BoreholeConstantRateNeighbouringStageType, IBoreholeConstantRateTestNeighbourFormValues> {
        return BoreholeConstantRateNeighbouringStage.reduce((current, stage) => {

            current[stage] = {
                borehole: !value ? null : value[stage].borehole,
                time: !value || !value[stage].time ? null : moment.utc(value[stage].time, 'HH:mm'),
                waterLevel: !value ? null : value[stage].waterLevel,
            };

            return current;
        }, {} as Record<BoreholeConstantRateNeighbouringStageType, IBoreholeConstantRateTestNeighbourFormValues>);
    }

    public static initialDrumFormValues(value ?: Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateDrumEntry>)  : Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateTestDrumFormValues> {
        return BoreholeConstantRateDrumStage.reduce((current, stage) => {

            current[stage] = {
                time: !value || !value[stage].time ? null : moment.utc(value[stage].time, 'HH:mm'),
                rate: !value ? null : value[stage].rate,
                fillTime: !value ? null : value[stage].fillTime,
                capacity: !value ? null : value[stage].capacity,
            };

            return current;
        }, {} as Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateTestDrumFormValues>);
    }

    public static initialEntriesFormValues(value ?: Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntry>)  : Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntryFormValues> {
        return BoreholeConstantRateTime.reduce((current, time) => {

            current[time] = {
                time,
                rate: !value ? null : value[time].rate,
                waterLevel: !value ? null : value[time].waterLevel,
            };

            return current;
        }, {} as Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntryFormValues>);
    }

    public static initialRecoveryEntriesFormValues(value ?: Record<BoreholeConstantRateTimesType, IBoreholeConstantRateRecoveryTimeEntry>)  : Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeRecoveryEntryFormValues> {
        return BoreholeConstantRateTime.reduce((current, time) => {

            current[time] = {
                time,
                waterLevel: !value ? null : value[time].waterLevel,
            };

            return current;
        }, {} as Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeRecoveryEntryFormValues>);
    }

    public static initialFormValues(boreholeConstantRate ?: IBoreholeConstantRateTest) : IBoreholeConstantRateTestFormValues {
        return {
            guid: boreholeConstantRate?.guid ?? uuid.v4(),
            date: moment.utc(boreholeConstantRate?.date).startOf('day'),
            latitude: boreholeConstantRate?.geo[0] ?? null,
            longitude: boreholeConstantRate?.geo[1] ?? null,
            elevation: boreholeConstantRate?.elevation ?? null,
            employeeName: boreholeConstantRate?.employeeName ?? '',
            employeeNumber: boreholeConstantRate?.employeeNumber ?? '',
            comment: boreholeConstantRate?.comment ?? '',
            pumpDepth: boreholeConstantRate?.pumpDepth ?? null,
            staticWaterLevel: boreholeConstantRate?.staticWaterLevel ?? null,
            installedPump: boreholeConstantRate?.installedPump ?? null,
            recommendedRate: boreholeConstantRate?.recommendedRate ?? null,
            dewateringYield: !boreholeConstantRate?.dewateringYield ? null : boreholeConstantRate.dewateringYield,
            nextBorehole: boreholeConstantRate?.nextBorehole ?? null,
            levelLoggerDate: !boreholeConstantRate?.levelLoggerDate ? null : moment.utc(boreholeConstantRate.levelLoggerDate).startOf('day'),
            levelLoggerPumpOnDate: !boreholeConstantRate?.levelLoggerPumpOnDate ? null : moment.utc(boreholeConstantRate.levelLoggerPumpOnDate).startOf('day'),
            levelLoggerPumpOffDate: !boreholeConstantRate?.levelLoggerPumpOffDate ? null : moment.utc(boreholeConstantRate.levelLoggerPumpOffDate).startOf('day'),
            levelLoggerOutDate: !boreholeConstantRate?.levelLoggerOutDate ? null : moment.utc(boreholeConstantRate.levelLoggerOutDate).startOf('day'),
            levelLoggerDepth: boreholeConstantRate?.levelLoggerDepth ?? null,
            levelLoggerOutTime: !boreholeConstantRate?.levelLoggerOutTime ? null : moment.utc(boreholeConstantRate.levelLoggerOutTime, 'HH:mm'),
            levelLoggerPumpOffTime: !boreholeConstantRate?.levelLoggerPumpOffTime ? null : moment.utc(boreholeConstantRate.levelLoggerPumpOffTime, 'HH:mm'),
            levelLoggerPumpOnTime: !boreholeConstantRate?.levelLoggerPumpOnTime ? null : moment.utc(boreholeConstantRate.levelLoggerPumpOnTime, 'HH:mm'),
            levelLoggerTime: !boreholeConstantRate?.levelLoggerTime ? null : moment.utc(boreholeConstantRate.levelLoggerTime, 'HH:mm'),
            neighbouringBoreholes: boreholeConstantRate?.neighbouringBoreholes.map(this.initialNeighbourFormValues) ?? [],
            drumEntries: this.initialDrumFormValues(boreholeConstantRate?.drumEntries),
            entries: this.initialEntriesFormValues(boreholeConstantRate?.entries),
            recoveryEntries: this.initialRecoveryEntriesFormValues(boreholeConstantRate?.recoveryEntries),
        };
    }

    public static formSchema = () => Yup.object<YupShape>({
        guid: Yup.string().required('Required'),
        date: Yup.date().nullable().moment().required('Required'),
        latitude: Yup.number().nullable().required('Required'),
        longitude: Yup.number().nullable().required('Required'),
        elevation: Yup.number().nullable(),
        employeeName: Yup.string().required('Required'),
        employeeNumber: Yup.string().required('Required'),
        comment: Yup.string(),
        pumpDepth: Yup.number().nullable().required('Required'),
        staticWaterLevel: Yup.number().nullable().required('Required'),
        installedPump: Yup.number().nullable().required('Required'),
        recommendedRate: Yup.number().nullable().required('Required'),
        dewateringYield: Yup.number().nullable(),
        nextBorehole: Yup.string().nullable(),
        levelLoggerOutTime: Yup.date().nullable().moment(),
        levelLoggerPumpOffTime: Yup.date().nullable().moment(),
        levelLoggerPumpOnTime: Yup.date().nullable().moment(),
        levelLoggerTime: Yup.date().nullable().moment(),
        levelLoggerDate: Yup.date().nullable().moment(),
        levelLoggerPumpOnDate: Yup.date().nullable().moment(),
        levelLoggerPumpOffDate: Yup.date().nullable().moment(),
        levelLoggerOutDate: Yup.date().nullable().moment(),
        levelLoggerDepth: Yup.number().nullable(),
        neighbouringBoreholes: Yup.array(
            Yup.object<YupNeighbourRecordShape>().shape(
                BoreholeConstantRateNeighbouringStage.reduce((current, stage) => {
                    current[stage] = yupNeighbourSchema();

                    return current;
                }, {} as YupNeighbourRecordShape)),
        ),
        drumEntries: Yup.object<YupDrumRecordShape>(
            BoreholeConstantRateDrumStage.reduce((current, stage) => {
                current[stage] = yupDrumSchema();

                return current;
            }, {} as YupDrumRecordShape)
        ),
        entries: Yup.object<YupEntryShape>().shape(
            BoreholeConstantRateTime.reduce((current, time) => {
                current[time] = yupEntrySchema.clone();

                return current;
            }, {} as YupEntryRecordShape)),
        recoveryEntries: Yup.object<YupRecoveryEntryRecordShape>().shape(
            BoreholeConstantRateTime.reduce((current, time) => {
                current[time] =  yupRecoveryEntrySchema.clone();

                return current;
            }, {} as YupRecoveryEntryRecordShape)),
    });
}
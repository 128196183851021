import { ITransformerState, TransformerAction } from '../../@types/redux';
import * as actions from './actions';
import { getType } from 'typesafe-actions';

const initialState = {
    transformers: [],
    loading: false,
    readings: [],
    loadingReadings: false,
    lpuReadings: [],
    loadingLpuReadings: false,
};

export default function generalReducer(state : ITransformerState = initialState, action : TransformerAction) : ITransformerState {
    switch (action.type) {
        case getType(actions.transformerSetList):
            return { ...state, transformers: action.payload };
        case getType(actions.transformerSetSelected):
            return { ...state, transformer: action.payload };
        case getType(actions.transformerSetLoading):
            return { ...state, loading: action.payload };
        case getType(actions.transformerSetReadings):
            return { ...state, readings: action.payload };
        case getType(actions.transformerSetReadingLoading):
            return { ...state, loadingReadings: action.payload };
        case getType(actions.transformerSetLpuReadings):
            return { ...state, lpuReadings: action.payload };
        case getType(actions.transformerSetLpuReadingLoading):
            return { ...state, loadingLpuReadings: action.payload };
        default:
            return state;
    }
}

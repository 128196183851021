import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import boreholeFunctions from '../../../store/borehole/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FileDropZone from '../../customComponents/FileDropZone';
import GeneralFunctions from '../../../store/general/functions';
import { CircularProgress } from '@material-ui/core';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import TransformerCustomMap from '../../customComponents/map/TransformerMap';
import { createSelector } from 'reselect';
import { BOREHOLE_MAP_IMAGES, BOREHOLE_STATUS, DEFAULT_MAP_CENTER } from '../../../appConstants';
import { IMapMarker } from '../../customComponents/map/CustomMapBox';

interface IEditBoreholeEPProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    borehole : IBorehole;
}

interface IEditBoreholeEPState {
    open : boolean;
    epNumber : string | null;

    image ?: File;
    progress : number;
}

export default class EditBoreholeEP extends React.Component<IEditBoreholeEPProps, IEditBoreholeEPState> {
    constructor(props : IEditBoreholeEPProps) {
        super(props);
        this.state = {
            open: false,
            epNumber: '',
            progress: 0,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeEPProps, prevState : IEditBoreholeEPState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                epNumber: this.props.borehole.EPNumber ?? null,
            });
        }

    };

    public readonly getBoreholeCode = (state : IEditBoreholeEPState, props : IEditBoreholeEPProps) => props.borehole.Code;
    public readonly getBoreholeGeom = (state : IEditBoreholeEPState, props : IEditBoreholeEPProps) => props.borehole.Geom;
    public readonly getBoreholeStatus = (state : IEditBoreholeEPState, props : IEditBoreholeEPProps) => props.borehole.Status;

    public readonly getMapCenter = createSelector(
        [this.getBoreholeGeom],
        (geom) => {
            return !geom ? DEFAULT_MAP_CENTER : [geom.longitude, geom.latitude] as [number, number];
        }
    );
    
    public readonly getMarkers = createSelector(
        [
            this.getBoreholeCode,
            this.getMapCenter,
            this.getBoreholeStatus,
        ],
        (
            code,
            center,
            status,
        ) => {
            return [{
                coordinates: center,
                iconSize: 1,
                icon: BOREHOLE_STATUS[status ?? '1'],
                id: code,
            } as IMapMarker];
        }
    );

    private readonly onTransformer = (value ?: ITransformer) => {
        this.setState({
            epNumber: value?.EPNumber ?? null,
        });
    };

    private readonly onMarkerClick = (value ?: string) => {
        this.setState({
            epNumber: value ?? null,
        });
    };

    private readonly save = async () => {
        const { epNumber, image } = this.state;

        const borehole = {
            ...this.props.borehole,
        };

        borehole.EPNumber = epNumber;

        try {
            await boreholeFunctions.saveBorehole(borehole);

            if (image) {
                await boreholeFunctions.uploadSelectedBoreholeEPImage(image, (progress : number) => {
                    this.setState({
                        progress,
                    });
                });
                GeneralFunctions.generalShowSuccessSnackbar('Borehole EP image uploaded successfuly');
            }

            this.onClose();
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Borehole EP image upload failed');
            this.setState({
                progress: 0,
            });
        }
        
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onFileSelected = (image : File) => {
        this.setState({
            image,
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            isLoading,
            borehole,
        } = this.props;

        const {
            open,
            epNumber,
            progress,
        } = this.state;

        const mapCenter = this.getMapCenter(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-info-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Borehole EP Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 jcfs hfill'>
                            <div className={'fdr flx1 ais'}>
                                <div className={'fdc flx1 ais mr15'}>
                                    <TransformerDropdown value={epNumber} fullWidth onChange={this.onTransformer} />
                                    <div className='fdc flx1 ais'>
                                        <TransformerCustomMap
                                            selected={epNumber}
                                            mapCenter={mapCenter}
                                            onMarkerClick={this.onMarkerClick}
                                            extraMarkers={markers}
                                            extraImages={BOREHOLE_MAP_IMAGES}
                                        />
                                    </div>
                                </div>
                                <div className={'flx1 ais ml15'}>
                                    {
                                        !progress &&
                                        <FileDropZone
                                            url={borehole.ElectricalBoxImageUrl}
                                            thumbnailUrl={borehole.ElectricalBoxImageThumbnailUrl}
                                            fileName={borehole.ElectricalBoxImageFileName ?? ''}
                                            path={`borehole/${borehole.Code}`}
                                            onFileSelected={this.onFileSelected}
                                        />
                                    }
                                    {
                                        !!progress &&
                                        <CircularProgress variant='determinate' value={progress} />
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

import { firestore } from 'firebase/app';
import { EmployeeHelper } from '../employee';

/**
 * Depricated!
 */
export interface IBaseClass {
    ref : firebase.firestore.DocumentReference;

    createdBy : firebase.firestore.DocumentReference;
    updatedBy : firebase.firestore.DocumentReference;
    createdByName : string;
    updatedByName : string;
    createdByEmployee : string;
    updatedByEmployee : string;
    createdOn : firebase.firestore.Timestamp;
    updatedOn : firebase.firestore.Timestamp;
}

/**
 * Depricated!
 */
export class BaseClass {
    public ref : firebase.firestore.DocumentReference;

    public createdBy : firebase.firestore.DocumentReference;
    public updatedBy : firebase.firestore.DocumentReference;
    public createdByName : string;
    public updatedByName : string;
    public createdByEmployee : string;
    public updatedByEmployee : string;
    public createdOn : firebase.firestore.Timestamp;
    public updatedOn : firebase.firestore.Timestamp;

    constructor(snapshot ?: firebase.firestore.DocumentSnapshot | firebase.firestore.QueryDocumentSnapshot) {
        if (snapshot) {
            this.ref = snapshot.ref;
        }

        const data = snapshot?.data();
        this.createdBy = data?.createdBy ?? null;
        this.updatedBy = data?.updatedBy ?? null;
        this.createdByName = data?.createdByName ?? null;
        this.updatedByName = data?.updatedByName ?? null;
        this.createdByEmployee = data?.createdByEmployee ?? null;
        this.updatedByEmployee = data?.updatedByEmployee ?? null;
        this.createdOn = data?.createdOn ?? null;
        this.updatedOn = data?.updatedOn ?? null;
    }

    protected toObject() {
        return {
            createdBy: this.createdBy ?? null,
            updatedBy: this.updatedBy ?? null,
            createdByName: this.createdByName ?? null,
            updatedByName: this.updatedByName ?? null,
            createdByEmployee: this.createdByEmployee ?? null,
            updatedByEmployee: this.updatedByEmployee ?? null,
            createdOn: this.createdOn ?? null,
            updatedOn: this.updatedOn ?? null,
        };
    }
}

/**
 * Depricated!
 */
export interface IDepricatedBaseClass<T> {
    GUID : string;
    ref : firebase.firestore.DocumentReference<T>;

    CreatedBy : firebase.firestore.DocumentReference;
    UpdatedBy : firebase.firestore.DocumentReference;
    CreatedByName : string;
    UpdatedByName : string;
    CreatedByEmployee : string;
    UpdatedByEmployee : string;
    CreatedOn : firebase.firestore.Timestamp;
    UpdatedOn : firebase.firestore.Timestamp;
}

/**
 * Depricated!
 */
export class BaseHelper {
    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IBaseClass | null {
        const data = snapshot.data();

        if (!data) return null;

        return {
            ref : snapshot.ref,
            createdBy : (data['createdBy'] as firestore.DocumentReference),
            updatedBy : (data['updatedBy'] as firestore.DocumentReference),
            createdByName : data['createdByName'],
            updatedByName : data['updatedByName'],
            createdByEmployee : data['createdByEmployee'],
            updatedByEmployee : data['updatedByEmployee'],
            createdOn : (data['createdOn'] as firestore.Timestamp),
            updatedOn : (data['updatedOn'] as firestore.Timestamp),
        };
    }

    protected static toFirestore(data : IBaseClass) {
        return {
            createdBy : data.createdBy,
            updatedBy : data.updatedBy,
            createdByName : data.createdByName,
            updatedByName : data.updatedByName,
            createdByEmployee : data.createdByEmployee,
            updatedByEmployee : data.updatedByEmployee,
            createdOn : data.createdOn,
            updatedOn : data.updatedOn,
        };
    }
}

export interface IBasicBaseClass {
    id : string;

    createdBy : string;
    updatedBy : string;
    createdByName : string;
    updatedByName : string;
    createdByEmployee : string;
    updatedByEmployee : string;
    createdOn : number;
    updatedOn : number;

    isWeb ?: boolean;
}

export class BasicBaseHelper {
    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : IBasicBaseClass | null {
        const data = snapshot.data();

        if (!data) return null;

        return {
            id : snapshot.id,
            createdBy : (data['createdBy'] as firestore.DocumentReference).id,
            updatedBy : (data['updatedBy'] as firestore.DocumentReference).id,
            createdByName : data['createdByName'],
            updatedByName : data['updatedByName'],
            createdByEmployee : data['createdByEmployee'],
            updatedByEmployee : data['updatedByEmployee'],
            createdOn : (data['createdOn'] as firestore.Timestamp).toMillis(),
            updatedOn : (data['updatedOn'] as firestore.Timestamp).toMillis(),
            isWeb : data['isWeb'] ?? false,
        };
    }

    protected static toFirestore(data : IBasicBaseClass) {
        return {
            createdBy : EmployeeHelper.doc(data.createdBy),
            updatedBy : EmployeeHelper.doc(data.updatedBy),
            createdByName : data.createdByName,
            updatedByName : data.updatedByName,
            createdByEmployee : data.createdByEmployee,
            updatedByEmployee : data.updatedByEmployee,
            createdOn : firestore.Timestamp.fromMillis(data.createdOn),
            updatedOn : firestore.Timestamp.fromMillis(data.updatedOn),
            isWeb : data.isWeb ?? true,
            isSent: false,
        };
    }
}

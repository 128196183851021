import Typography from '@material-ui/core/Typography';
import React from 'react';
import { IBoreholeSensorAudit } from '../../../@types/model/borehole/boreholeSensorAudit';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';

interface IBoreholeSensorAuditEntryViewProps {
    audit : IBoreholeSensorAudit;
}

interface IBoreholeSensorAuditEntryViewState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class BoreholeSensorAuditEntryView extends React.Component<IBoreholeSensorAuditEntryViewProps, IBoreholeSensorAuditEntryViewState> {
    constructor(props : IBoreholeSensorAuditEntryViewProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private readonly onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { audit } = this.props;
        const { imageSrcs, imageIndex, isCarouselOpen } = this.state;
        return (
            <div className='fdc'>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            LEVEL SENSOR
                            <span className='flx1' />
                        </Typography>
                        {
                            !audit.HasLevelSensor &&
                            <div>
                                <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                    NONE
                                </Typography>
                            </div>
                        }
                        {
                            audit.HasLevelSensor &&
                            <div className={'fdc'}>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        TYPE
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        BAR
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        OUTPUT
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.LevelSensorType}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.LevelSensorBar}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.LevelSensorOut}
                                    </Typography>
                                </div>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        SUPPLY
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        SERIAL NR.
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        CABLE LENGTH
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.LevelSensorSupply}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.LevelSensorSerialNr}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {
                                            audit.LevelSensorCableLength &&
                                            `${audit.LevelSensorCableLength.toFixed(2)}`
                                        }
                                    </Typography>
                                </div>
                                {
                                    audit.LevelSensorFileName &&
                                    <div className='p10 jcc'>
                                        <FirebaseStorageImage
                                            fileName={audit.LevelSensorFileName}
                                            path={`borehole/${audit.BoreholeCode}/sensorAudit`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            FLOW METER
                            <span className='flx1' />
                        </Typography>
                        {
                            !audit.HasFlowMeter &&
                            <div>
                                <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                    NONE
                                </Typography>
                            </div>
                        }
                        {
                            audit.HasFlowMeter &&
                            <div className={'fdc'}>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        MODEL
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        TYPE
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        SERIAL NR.
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.FlowMeterModel}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.FlowMeterType}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.FlowMeterSerialNr}
                                    </Typography>
                                </div>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        PIPE DIAMETER
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        EV
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        OUTPUT
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {
                                            audit.LevelSensorCableLength &&
                                            `${audit.FlowMeterPipeDiameter} mm`
                                        }
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.FlowMeterEV}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {audit.FlowMeterOutput}
                                    </Typography>
                                </div>
                                {
                                    audit.FlowMeterFileName &&
                                    <div className='p10 jcc'>
                                        <FirebaseStorageImage
                                            fileName={audit.FlowMeterFileName}
                                            path={`borehole/${audit.BoreholeCode}/sensorAudit`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                {
                    audit.HasLevelSensor &&
                    <div className='fdc'>

                    </div>
                }
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

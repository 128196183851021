import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';

export interface ITransformerUsage extends IBasicBaseClass {
    code : string;
    name : string;

    isActive : boolean;
}

export default class TransformerUsageHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_usage';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerUsage> = {
        fromFirestore: (snapshot) => {
            return TransformerUsageHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerUsage) : firebase.firestore.DocumentData => {
            return TransformerUsageHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerUsage {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data() as ITransformerUsage;

        if (!snapshot.exists || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data,
            ...result,
        };
    }

    public static toFirestoreDocument(data : ITransformerUsage) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static save(data : ITransformerUsage) {
        return TransformerUsageHelper.doc(data.id).set(data, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(TransformerUsageHelper.COLLECTION_NAME).withConverter(TransformerUsageHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(TransformerUsageHelper.COLLECTION_NAME).withConverter(TransformerUsageHelper.converter).doc(id);
    }

    public static listen() {
        return firebaseApp
            .firestore()
            .collection(TransformerUsageHelper.COLLECTION_NAME)
            .withConverter(TransformerUsageHelper.converter);
    }
}
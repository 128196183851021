import { combineReducers, compose, legacy_createStore as createStore, applyMiddleware } from 'redux';
import { createHashHistory, History } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import general from './general/reducer';
import auth from './auth/reducer';
import borehole from './borehole/reducer';
import data from './data/reducer';
import transformer from './transformer/reducer';
import weather from './weather/reducer';
import river from './river/reducer';
import mottech from './mottech/reducer';
import dam from './dam/reducer';
import pumpingStation from './pumpingStation/reducer';
import { masterDataReducer } from './masterData';
import flowmeterReducer from './flowmeter/reducer';
import { fillingPointReducer } from './fillingPoint';

export const hashHistory = createHashHistory();
const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createRootReducer = (history : History<unknown>) => combineReducers({
    router: connectRouter(history),
    data,
    general,
    auth,
    borehole,
    transformer,
    weather,
    river,
    mottech,
    dam,
    pumpingStation,
    masterData: masterDataReducer,
    flowmeter: flowmeterReducer,
    fillingPoints: fillingPointReducer,
});

export const rootReducer = createRootReducer(hashHistory);

const configureStore = () => {
    return createStore(
        rootReducer,
        composeEnhancer(
            applyMiddleware(
                thunk,
                routerMiddleware(hashHistory),
            ),
        ),
    );
};

const store = configureStore();

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;

import React from 'react';
import BoreholeCalibrationHelper, { IBoreholeCalibration } from '../../../../@types/model/borehole/boreholeCalibration';
import { IUserSession } from '../../../../@types/employee';
import BoreholeBoreholeCalibrationEntryLogView from './CalibrationEntryLogView';
import { Card, Typography } from '@material-ui/core';
import { createSelector } from 'reselect';
import { VictoryPie } from 'victory';

interface IBoreholeCalibrationEntryViewProps {
    calibration : IBoreholeCalibration;
    session : IUserSession;
    isLoading : boolean;
}

interface IBoreholeCalibrationEntryViewState {
    
}

export default class BoreholeCalibrationEntryView extends React.Component<IBoreholeCalibrationEntryViewProps, IBoreholeCalibrationEntryViewState> {
    constructor(props : IBoreholeCalibrationEntryViewProps) {
        super(props);
        this.state = {};
    }

    public readonly getData = (props : IBoreholeCalibrationEntryViewProps) => props.calibration;
    public readonly getMaximumAchieved = createSelector(
        [this.getData],
        BoreholeCalibrationHelper.getMaximumAchieved,
    );

    public render = () => {
        const { calibration, isLoading, session } = this.props;
        const {
            drawDownPercentage,
            recoveryPercentage,
            maximumRate,
            totalPumpingTime1,
            totalPumpingTime2,
            totalPumpingTime3,
            totalPumpingTime4,
            totalPumpingTime5,
            totalRecoveryTime,
            totalTime,
        } = this.getMaximumAchieved(this.props);
        return (
            <div className='fdc p5'>
                <div className='fdr'>
                    <Card className='fdc flx1'>
                        <div className='fdr h50'>
                            <div className={'fdc flx1 jcc aic dvbg'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    HOLE DEPTH
                                </Typography>
                            </div>
                            <div className={'fdc flx1 jcc aic dvbg'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    TEST PUMP LEVEL
                                </Typography>
                            </div>
                            <div className={'fdc flx1 jcc aic dvbg'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    BEFORE PIEZO LEVEL
                                </Typography>
                            </div>
                            <div className={'fdc flx1 jcc aic dvbg'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    AVAILABLE DRAWDOWN
                                </Typography>
                            </div>
                        </div>
                        <div className={'fdr flx1 ais jcc'}>
                            <div className={'fdr flx1 aic jcc pb16 pt16'}>
                                {
                                    `${calibration.HoleDepth.toFixed(2)} m`
                                }
                            </div>
                            <div className={'fdr flx1 aic jcc pb16 pt16'}>
                                {
                                    `${calibration.BeforePiezoLevel.toFixed(2)} m`
                                }
                            </div>
                            <div className={'fdr flx1 aic jcc pb16 pt16'}>
                                {
                                    `${calibration.PumpDepth.toFixed(2)} m`
                                }
                            </div>
                            <div className={'fdr flx1 aic jcc pb16 pt16'}>
                                {
                                    `${calibration.AvailableDrawdown.toFixed(2)} m`
                                }
                            </div>
                        </div>
                    </Card>
                    <div className='mw160 ml15'>
                        <Card className='fdc bcp'>
                            <div className={'fdc jcc aic h50'}>
                                <Typography className={'fs10 fw500 cw'}>
                                    DRAWDOWN ACHIEVED
                                </Typography>
                            </div>
                            <div className={'fdr ais jcc'}>
                                <div className={'fdr aic jcc pb16 pt16 pl5 pr5'}>
                                    <Typography className={'fs30 fw500 cw'}>
                                        {
                                            `${drawDownPercentage.toFixed(2)} %`
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className='mw160 ml15'>
                        <Card className='fdc bcp'>
                            <div className={'fdc jcc aic h50'}>
                                <Typography className={'fs10 fw500 cw'}>
                                    RECOVERY
                                </Typography>
                                <Typography className={'fs8 fw500 cw'}>
                                    (After {totalRecoveryTime} min)
                                </Typography>
                            </div>
                            <div className={'fdr ais jcc'}>
                                <div className={'fdr aic jcc pb16 pt16 pl5 pr5'}>
                                    <Typography className={'fs30 fw500 cw'}>
                                        {
                                            `${recoveryPercentage.toFixed(2)} %`
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <Card className='fdc w250 ml15'>
                        <div className='fdr bcw h50'>
                            <div className={'fdc jcc aifs flx1 dvbg pl15'}>
                                <Typography className={'fs10 fw500 cw'}>
                                    COMMENTS
                                </Typography>
                            </div>
                        </div>
                        <div className={'fdr ais jcc'}>
                            <div className={'fdr flx1 aifs jcfs pb16 pt16 pl15'}>
                                {
                                    calibration.Note
                                }
                            </div>
                        </div>
                    </Card>
                </div>
                <div className='fdr mt12'>
                    <div className='fdc w172 mr15'>
                        <Card className='fdc bcLightBlue'>
                            <div className={'fdc jcc aic h50'}>
                                <Typography className={'fs10 fw500 cw'}>
                                    MAXIMUM RATE
                                </Typography>
                            </div>
                            <div className={'posr flx1 aic jcc'}>
                                <VictoryPie
                                    standalone={true}
                                    radius={158}
                                    padding={0}
                                    data={[{
                                        x: 1,
                                        y: !calibration.InstalledYield ? 0 : (maximumRate / calibration.InstalledYield),
                                    },
                                    {
                                        x: 1,
                                        y: !calibration.InstalledYield ? 1 : (1 - (maximumRate / calibration.InstalledYield)),
                                    }]}
                                    colorScale={['#FFFF', '#2294FC']}
                                    innerRadius={150} labelRadius={0}
                                    style={{ labels: { fontSize: 0, fill: 'transparent' } }}
                                />
                                <Typography className={'posa cw'} variant='subtitle1'>
                                    {
                                        `${maximumRate.toFixed(2)}`
                                    }
                                    m<sup>3</sup>/hr
                                </Typography>
                            </div>
                        </Card>
                        <Card className={'fdc mt12'}>
                            <Typography className={'fdc aic jcc fs13 cw fw500 bcDarkBlue pt8 pb8'}>
                                TOTAL PUMPING TIME
                            </Typography>
                            <div className='fdr pl10 pt10 pb10'>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    1
                                </Typography>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    -
                                </Typography>
                                <Typography className={'fs12 fwb cpd'}>
                                    {
                                        totalPumpingTime1 ?
                                            `${totalPumpingTime1} min` :
                                            'X'
                                    }
                                </Typography>
                            </div>
                            <div className='fdr pl10 pt10 pb10 bcg0'>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    2
                                </Typography>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    -
                                </Typography>
                                <Typography className={'fs12 fwb cpd'}>
                                    {
                                        totalPumpingTime2 ?
                                            `${totalPumpingTime2} min` :
                                            'X'
                                    }
                                </Typography>
                            </div>
                            <div className='fdr pl10 pt10 pb10'>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    3
                                </Typography>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    -
                                </Typography>
                                <Typography className={'fs12 fwb cpd'}>
                                    {
                                        totalPumpingTime3 ?
                                            `${totalPumpingTime3} min` :
                                            'X'
                                    }
                                </Typography>
                            </div>
                            <div className='fdr pl10 pt10 pb10 bcg0'>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    4
                                </Typography>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    -
                                </Typography>
                                <Typography className={'fs12 fwb cpd'}>
                                    {
                                        totalPumpingTime4 ?
                                            `${totalPumpingTime4} min` :
                                            'X'
                                    }
                                </Typography>
                            </div>
                            <div className='fdr pl10 pt10 pb10'>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    5
                                </Typography>
                                <Typography className={'mr15 fs12 fw400 cpd'}>
                                    -
                                </Typography>
                                <Typography className={'fs12 fwb cpd'}>
                                    {
                                        totalPumpingTime5 ?
                                            `${totalPumpingTime5} min` :
                                            'X'
                                    }
                                </Typography>
                            </div>
                            <Typography className={'fdc aic jcc fs13 cw fw500 bcDarkBlue pt8 pb8'}>
                                TOTAL RECOVER TIME
                            </Typography>
                            <div className='fdr aic jcc pl10 pt10 pb10'>
                                <Typography className={'fs12 fwb cpd'}>
                                    {totalRecoveryTime} min
                                </Typography>
                            </div>
                            <Typography className={'fdc aic jcc fs13 cw fw500 bcDarkBlue pt8 pb8'}>
                                TOTAL
                            </Typography>
                            <div className='fdr aic jcc pl10 pt10 pb10'>
                                <Typography className={'fs12 fwb cpd'}>
                                    {
                                        totalTime
                                    } min
                                </Typography>
                            </div>
                        </Card>
                    </div>
                    <div className='fdc flx1'>
                        <BoreholeBoreholeCalibrationEntryLogView
                            calibration={calibration}
                            isLoading={isLoading}
                            session={session}
                        />
                    </div>
                </div>
            </div>
        );
    };
}

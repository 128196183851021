import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import firebaseApp from '../../../../services/firebaseService';
import BoreholeMotor from '../../../../@types/model/borehole/motor';
import { createSelector } from 'reselect';

interface IMotorTypeSelectorProps {
    motorType : string;

    onMotorTypeChange : (motorType : string) => void;
}

interface IMotorTypeSelectorState {
    motors : Array<BoreholeMotor>;
    isLoading : boolean;
}

export default class MotorTypeSelector extends React.Component<IMotorTypeSelectorProps, IMotorTypeSelectorState> {
    private listener ?: () => void;
    constructor(props : IMotorTypeSelectorProps) {
        super(props);
        this.state = {
            motors: [],
            isLoading: true,
        };
    }

    private readonly onMotorTypeChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (typeof event.target.value !== 'string') return;
        
        this.props.onMotorTypeChange(event.target.value);
    };

    public readonly componentDidMount = () => {
        this.listen();
    };

    public readonly componentWillUnmount = () => {
        if (!this.listener) return;
        this.listener();
    };

    private readonly listen = () => {
        this.listener = firebaseApp.firestore().collection(BoreholeMotor.COLLECTION).onSnapshot((snapshot) => {
            // Shallow clone is fine here as we do not modify objects in array.
            const motors = this.state.motors.slice();

            snapshot.docChanges().forEach((f) => {
                const motor = new BoreholeMotor(f.doc);
                const index = lodash.findIndex(motors, n => n.ref.id === f.doc.ref.id);

                switch (f.type) {
                    case 'added':
                        motors.push(motor);
                        break;
                    case 'modified':
                        motors.splice(index, 1, motor);
                        break;
                    case 'removed':
                        motors.splice(index, 1);
                        break;
                }
            });

            this.setState({
                motors,
                isLoading: false,
            });
        }, () => {
            this.setState({
                motors: [],
                isLoading: false,
            });
        });
    };

    private readonly getMotors = (props : IMotorTypeSelectorProps, state : IMotorTypeSelectorState) => state.motors;
    private readonly getValue = (props : IMotorTypeSelectorProps) => props.motorType;
    private readonly getAllMotors = createSelector(
        [this.getMotors, this.getValue],
        (motors, value) => {
            const result = motors.slice().map(n => n.name).sort();

            // Some motors are Unidentified.
            result.push('Unidentified');
            if (value && !result.includes(value)) result.push(value);

            return result;
        },
    );
    public render = () => {
        const { motorType } = this.props;

        const motors = this.getAllMotors(this.props, this.state);

        return (
            <React.Fragment>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel shrink={!!motorType} htmlFor='motorType'>Motor Type</InputLabel>
                        <Select
                            fullWidth
                            id='motorType'
                            value={!motorType ? '' : motorType}
                            onChange={this.onMotorTypeChange}>
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            {
                                lodash.map(motors, n => (
                                    <MenuItem key={`${n}_motorType`} value={`${n}`}>
                                        {n}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </React.Fragment>
        );
    };
}

import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import TransformerFunctions from '../../../store/transformer/functions';
import { ITransformer, TransformerMeterTypeUpperCase } from '../../../@types/model/transformer/transformer';
import MaterialTable from '../../customComponents/materialTable/Table';
import { IRootState } from '../../../@types/redux';
import TransformerAccountEditDialog from './dialog/Edit';
import { Transitions } from '../../customComponents/animations/Transitions';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import { Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import MeterTypeSelector from '../../customComponents/selector/transformer/MeterTypeSelector';

interface ITransformerAccountListProps {
    transformers : Array<ITransformer>;
    isLoading : boolean;
}

interface ITransformerAccountListState {
    transformerNumber ?: string;
    meterType ?: TransformerMeterTypeUpperCase | '';
}

class TransformerAccountList extends React.Component<ITransformerAccountListProps, ITransformerAccountListState> {
    constructor(props : ITransformerAccountListProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        TransformerFunctions.getList();
    };

    private readonly getData = (props : ITransformerAccountListProps) => props.transformers;
    private readonly getTransformerNumber = (props : ITransformerAccountListProps, state : ITransformerAccountListState) => state.transformerNumber;
    private readonly getMeterType = (props : ITransformerAccountListProps, state : ITransformerAccountListState) => state.meterType;

    public readonly getFilteredData = createSelector(
        [
            this.getData,
            this.getTransformerNumber,
            this.getMeterType,
        ],
        (
            transformers,
            transformerNumber,
            meterType,
        ) => {
            return transformers
                .filter(n => !transformerNumber || n.EPNumber === transformerNumber)
                .filter(n => !meterType || n.MeterType === meterType)
                .filter(n => n.IsActive)
                .filter(n => !!n.BankAccount);
        },
    );

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            transformerNumber: value?.EPNumber,
        });
    };

    private onMeterTypeChange = (meterType : TransformerMeterTypeUpperCase | '') => {
        this.setState({
            meterType,
        });
    };

    public readonly render = () => {
        const { isLoading } = this.props;
        const { transformerNumber, meterType } = this.state;
        const transformers = this.getFilteredData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <Toolbar>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className='fdc flx1 pr5 w240'>
                            <MeterTypeSelector value={meterType} onChange={this.onMeterTypeChange} />
                        </div>
                        <div className='fdc flx1 pr5 w240'>
                            <TransformerDropdown
                                value={transformerNumber}
                                label='Waypoint No'
                                fullWidth
                                onChange={this.onEPNumberChange}
                            />
                        </div>
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<ITransformer>
                        id='transformerAccountsTable'
                        data={transformers}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        columns={[{
                            header: 'Way Point',
                            field: 'EPNumber',
                            width: 150,
                            enableSort: true,
                            enableFilter: true,
                            paddingRight: 4,
                            fixed: true,
                            renderCell: row => (
                                <div className='aic'>
                                    <TransformerAccountEditDialog isLoading={isLoading} fullWidth
                                        maxWidth='md' transformer={row}
                                        excludeTransformers={transformers} transition={Transitions.Down}
                                    />
                                    <span>{row.EPNumber}</span>
                                </div>
                            ),
                        }, {
                            header: 'Type',
                            field: 'MeterType',
                            width: 100,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Bank',
                            field: 'BankAccount.bank',
                            width: 155,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Branch Code',
                            field: 'BankAccount.branch',
                            width: 155,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Account No',
                            field: 'BankAccount.accountNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Account Type',
                            field: 'BankAccount.accountType',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Beneficiary Descript.',
                            field: 'BankAccount.beneficiaryDescription',
                            width: 195,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Recipient Reference',
                            field: 'BankAccount.recipientReference',
                            width: 195,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Own Reference',
                            field: 'BankAccount.ownReference',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Created By',
                            field: 'CreatedByEmployee',
                            renderCell: row => (
                                <Tooltip title={row.CreatedByEmployee}>
                                    <div>
                                        {
                                            row.CreatedByName
                                        }
                                    </div>
                                </Tooltip>
                            ),                            
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Created On',
                            field: 'CreatedOn',
                            renderCell: (row) => <Typography className={'fs12'}> {moment.utc(row.CreatedOn.toDate()).format('DD/MM/YYYY')}</Typography>,
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                        }]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <TransformerAccountEditDialog isLoading={isLoading} fullWidth maxWidth='md' excludeTransformers={transformers} transition={Transitions.Down} />
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoading: state.transformer.loading,
    };
};

export default connect(
    mapStateToProps,
)(TransformerAccountList);

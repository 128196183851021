import { FieldProps } from 'formik';
import React from 'react';
import { MunicipalityInvoiceHelper } from '../../../@types/model/transformer/municipalityInvoice';
import WhiteTextField from '../../customComponents/textField/WhiteTextField';

interface IMunicipilityInvoiceCreditFormFieldProps extends FieldProps<number | null> {}

interface IMunicipilityInvoiceCreditFormFieldState {}

export default class MunicipilityInvoiceCreditFormField extends React.PureComponent<IMunicipilityInvoiceCreditFormFieldProps, IMunicipilityInvoiceCreditFormFieldState> {
    constructor(props : IMunicipilityInvoiceCreditFormFieldProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.field.onChange(event);

        setTimeout(() => {
            MunicipalityInvoiceHelper.calculateTotals(this.props.form);
        });
    };

    public readonly render = () => {
        const {
            field,
        } = this.props;
        return (
            <WhiteTextField
                {...field}
                value={field.value ?? ''}
                variant='standard'
                label='Credit (Excl VAT)'
                fullWidth
                type='number'
                onChange={this.onChange}
            />
        );
    };
}

import React from 'react';
import { IDam } from '../../../@types/model/dam/dam';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import damFunctions from '../../../store/dam/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DivisionSelector from '../../customComponents/selector/DivisionSelector';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

interface IEditDamDivisionInfoProps {
    dam : IDam;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditDamDivisionInfoState {
    open : boolean;
    division : string;
    manArea : string;
    divisionNumber : string;
    accPacDesc : string;
}

export default class EditDamDivisionInfo extends React.Component<IEditDamDivisionInfoProps, IEditDamDivisionInfoState> {
    constructor(props : IEditDamDivisionInfoProps) {
        super(props);
        this.state = {
            open: false,
            division: '',
            manArea: '',
            divisionNumber: '',
            accPacDesc: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditDamDivisionInfoProps, prevState : IEditDamDivisionInfoState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                division: '',
                manArea: '',
                divisionNumber: '',
                accPacDesc: '',
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                division: !this.props.dam.division ? '' : this.props.dam.division,
                manArea: !this.props.dam.managementArea ? '' : this.props.dam.managementArea,
                divisionNumber: !this.props.dam.divisionNumber ? '' : this.props.dam.divisionNumber.toFixed(),
                accPacDesc: !this.props.dam.divisionDescriptionAccpac ? '' : this.props.dam.divisionDescriptionAccpac,
            });
        }
    };

    private readonly onManAreaChanged = (manArea : string) => {
        this.setState({
            manArea,
        });
    };

    private readonly onDivisionChanged = (division : string) => {
        this.setState({
            division,
        });
    };

    private readonly onDivisionNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            divisionNumber: event.currentTarget.value,
        });
    };

    private readonly onAccPacDescChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            accPacDesc: event.currentTarget.value,
        });
    };

    private readonly save = async () => {
        const { division, manArea, divisionNumber, accPacDesc } = this.state;

        const dam = {
            ...this.props.dam,
        };

        dam.division = division;
        dam.managementArea = manArea;
        dam.divisionNumber = !divisionNumber ? null : parseInt(divisionNumber);
        dam.divisionDescriptionAccpac = accPacDesc;

        await damFunctions.saveDam(dam);
        this.onClose();
    };

    private readonly onSubmit = (event : React.FormEvent) => {
        event.preventDefault();
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, division, manArea, divisionNumber, accPacDesc } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='dam-division-info-title'
                    aria-describedby='dam-division-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Dam Division Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <DivisionSelector division={division} manArea={manArea} onDivisionChanged={this.onDivisionChanged} onManAreaChanged={this.onManAreaChanged} />
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='accPacDesc'
                                            label='AccPac Description'
                                            value={accPacDesc}
                                            onChange={this.onAccPacDescChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='divisionNumber'
                                            label='Division Number'
                                            type='number'
                                            value={divisionNumber}
                                            onChange={this.onDivisionNumberChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                        {
                                            divisionNumber && !Number(divisionNumber) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>);
    };
}

import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { DIVISION_BOUNDRIES } from '../../../../../appConstants';
import { createSelector } from 'reselect';

interface IMapboxSubDivisionLayerProps {
    polygonId : string;
    strokeId : string;
    labelId : string;

    polygonBeforeId ?: string;
    strokeBeforeId ?: string;
    labelBeforeId ?: string;

    minZoom ?: number;
    maxZoom ?: number;

    divisionGuid ?: string | null;
    subDivisionGuid ?: string | null;

    labelsVisible ?: boolean;
    visible ?: boolean;
}

interface IMapboxSubDivisionLayerState {}

export default class MapboxSubDivisionLayer extends React.PureComponent<IMapboxSubDivisionLayerProps, IMapboxSubDivisionLayerState> {
    constructor(props : IMapboxSubDivisionLayerProps) {
        super(props);
        this.state = {};
    }

    private readonly getDivisionGuid = (props : IMapboxSubDivisionLayerProps) => props.divisionGuid;
    private readonly getSubDivisionGuid = (props : IMapboxSubDivisionLayerProps) => props.subDivisionGuid;

    private readonly getFilter = createSelector(
        [
            this.getDivisionGuid,
            this.getSubDivisionGuid,
        ],
        (
            divisionGuid,
            subDivisionGuid,
        ) => {
            const result : Array<unknown> = [];

            if (divisionGuid) {
                result.push(['==', ['literal', divisionGuid], ['get', 'subDivisionGuid']]);
            }

            if (subDivisionGuid) {
                result.push(['==', ['literal', subDivisionGuid], ['get', 'subGuid']]);
            }
            
            return [
                'all',
                ...result,
            ];
        },
    );

    public readonly render = () => {
        const {
            minZoom = 0,
            maxZoom = 20,
            labelId,
            strokeId,
            polygonId,
            labelBeforeId,
            polygonBeforeId,
            strokeBeforeId,
            visible,
            labelsVisible,
        } = this.props;

        const filter = this.getFilter(this.props);

        return (
            <Source
                id='polygon_sub_division_source'
                type='vector'
                tiles={[
                    `${GEO_SERVER_URL}/geoserver/${GEO_SERVER_WORKSPACE}/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=farm_sub_divisions&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
                ]}
                minzoom={minZoom}
                maxzoom={maxZoom}
                bounds={DIVISION_BOUNDRIES}
            >
                <Layer {...{
                    id: polygonId,
                    'source-layer': 'farm_sub_divisions',
                    minzoom: minZoom,
                    maxzoom: maxZoom,
                    type: 'fill',
                    'layout': {
                        visibility: visible ? 'visible' : 'none',
                    },
                    'paint': {
                        'fill-color': ['get', 'subColor'],
                        'fill-opacity': 0.2,
                    },
                    'road-label-simple': true,
                    filter,
                    beforeId: polygonBeforeId,
                }} />
                <Layer {...{
                    id: strokeId,
                    'source-layer': 'farm_sub_divisions',
                    'type': 'line',
                    minzoom: minZoom,
                    maxzoom: maxZoom,
                    'layout': {
                        visibility: visible ? 'visible' : 'none',
                    },
                    'paint': {
                        'line-color': ['get', 'subColor'],
                        'line-width': 3,
                    },
                    filter,
                    beforeId: strokeBeforeId,
                }}/>
                <Layer {...{
                    id: labelId,
                    'source-layer': 'farm_sub_divisions',
                    type: 'symbol',
                    minzoom: minZoom,
                    maxzoom: maxZoom,
                    layout: {
                        'text-field': [
                            'format',
                            [
                                'get',
                                'subName',
                            ],
                            {
                                'font-scale': 0.8,
                            },
                        ],
                        'text-justify': 'center',
                        'text-allow-overlap': true,
                        'text-ignore-placement': true,
                        visibility: visible && labelsVisible ? 'visible' : 'none',
                    },
                    paint: {
                        'text-opacity': 1,
                        'text-color': '#FFFFFF',
                        'text-halo-color': '#000000',
                        'text-halo-width': 2,
                        'text-halo-blur': 1,
                    },
                    filter,
                    beforeId: labelBeforeId,
                }}
                />
            </Source>
        );
    };
}

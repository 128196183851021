import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import TariffHelper, { TariffType, ISPUTariff, ILPUTariff } from '../../../../@types/model/transformer/tariffs';
import Typography from '@material-ui/core/Typography';
import LPUEdit from '../LPUEdit';
import SPUEdit from '../SPUEdit';
import { FirebaseService } from '../../../../services/firebaseService';
import { TRANSMISSION_ZONES, LPU_VOLTAGES, LPU_CATEGORIES } from '../../../../appConstants';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import generalFunctions from '../../../../store/general/functions';
import Fab from '@material-ui/core/Fab';
import { createSelector } from 'reselect';

interface ITariffEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    
    type : TariffType;

    isNew ?: boolean;
    enableEdit ?: boolean;

    /**
     * Document Id of tariff
     */
    value ?: string;
}

interface ITariffEditDialogState {
    open : boolean;
    isLoading : boolean;

    tariff ?: ILPUTariff | ISPUTariff;
    vat : number;
}

export default class TariffEditDialog extends React.PureComponent<ITariffEditDialogProps, ITariffEditDialogState> {
    constructor(props : ITariffEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
            vat: 0,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
            isLoading: true,
        });

        this.loadData();
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
            isLoading: false,
        });
    };

    private readonly loadData = () => {
        const { type } = this.props;

        const vat = FirebaseService.getVat();

        this.setState({
            vat,
        });

        if (type === 'lpu') this.loadLPU();

        if (type === 'spu') this.loadSPU();
    };

    /**
     * Loads the LPU tariff.
     * Creates a new one base on last tariff LPU tariff, and
     * sets start date to today
     */
    private readonly loadLPU = async () => {
        const { value, isNew } = this.props;

        try {
            const lpuSnapshot = isNew || !value ? (null) : (await TariffHelper.lpuDoc(value).get());

            const lpu = lpuSnapshot?.data();

            if (lpu) {
                this.setTariff(lpu);
                return;
            }

            this.setTariff({
                startDate: moment.utc().startOf('day').valueOf(),
                type: 'lpu',
                zones: TRANSMISSION_ZONES.map(n => ({
                    lower: n[0],
                    upper: n[1],
                    highDemand: {
                        offPeak: [0, 0],
                        peak: [0, 0],
                        standard: [0, 0],
                    },
                    lowDemand: {
                        offPeak: [0, 0],
                        peak: [0, 0],
                        standard: [0, 0],
                    },
                    highDemandMonths: [6, 7, 8],
                    lowDemandMonths: [1, 2, 3, 4, 5, 9, 10, 11, 12],
                    netwrokCapacity: [0, 0],
                })),
                voltages: LPU_VOLTAGES.map(n => ({
                    lower: n[0],
                    upper: n[1],
                    ancillaryServiceCharge: 0,
                    networkDemandCharge: 0,
                })),
                categories: LPU_CATEGORIES.map(n => ({
                    administrationCharge: 0,
                    lower: n[0],
                    upper: n[1],
                    serviceCharge: 0,
                })),
                reactiveEnergyCharge: {
                    high: 0,
                    low: 0,
                },
            } as ILPUTariff);
            

        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    /**
     * Loads the SPU tariff.
     * Creates a new one base on last tariff SPU tariff, and
     * sets start date to today
     */
    private readonly loadSPU = async () => {
        const { value, isNew } = this.props;

        try {
            const spuSnapshot = isNew || !value ? (null) : (await TariffHelper.spuDoc(value).get());

            const spu = spuSnapshot?.data();

            if (spu) {
                this.setTariff(spu);
                return;
            }

            this.setTariff({
                startDate: moment.utc().startOf('day').valueOf(),
                type: 'spu',
                landrates: {
                    1: {
                        energyCharge: 0,
                        ancillaryService: 0,
                        networkCapacity: 0,
                        networkDemand: 0,
                        serviceCharge: 0,
                    },
                    2: {
                        energyCharge: 0,
                        ancillaryService: 0,
                        networkCapacity: 0,
                        networkDemand: 0,
                        serviceCharge: 0,
                    },
                    3: {
                        energyCharge: 0,
                        ancillaryService: 0,
                        networkCapacity: 0,
                        networkDemand: 0,
                        serviceCharge: 0,
                    },
                    4: {
                        energyCharge: 0,
                        ancillaryService: 0,
                        networkCapacity: 0,
                        networkDemand: 0,
                    },
                    DX: {
                        serviceCharge: 0,
                    },
                },
            } as ISPUTariff);


        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly setTariff = (tariff : ILPUTariff | ISPUTariff) => {
        this.setState({
            tariff,
        });
    };

    private readonly onSaved = () => {
        this.setState({
            open: false,
        });
    };

    public readonly getIsNew = (props : ITariffEditDialogProps) => props.isNew;
    public readonly getEditEnable = (props : ITariffEditDialogProps) => props.enableEdit;
    public readonly getTooltip = createSelector(
        [this.getIsNew, this.getEditEnable],
        (isNew, enableEdit) => {
            if (isNew) return 'Add';
            if (enableEdit) return 'Edit';

            return 'View';
        },
    );

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isNew, type, enableEdit } = this.props;
        const { open, tariff, vat, isLoading } = this.state;

        const tooltip = this.getTooltip(this.props);
        return (
            <React.Fragment>
                <Tooltip title={tooltip}>
                    <div>
                        {
                            isNew &&
                            <Fab size='small' color='secondary' className='bcy' aria-label='Add' onClick={this.onClick} >
                                <Icon>add</Icon>
                            </Fab>

                        }
                        {
                            !isNew &&
                            <Fab size='small' color='secondary' className='bcy' variant='extended' aria-label='View' onClick={this.onClick} >
                                <span className='pl10 pr10'>{tooltip}</span>
                            </Fab>

                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Tariff-Edit-dialog-title'
                    aria-describedby='Tariff-Edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {isNew ? 'Create' : 'View'} {type.toLocaleUpperCase()} Tariffs
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill'>
                        {
                            isLoading &&
                            <div className='fdc flx1 aic jcc'>
                                <CircularProgress />
                            </div>
                        }
                        {
                            !isLoading &&
                            type === 'lpu' &&
                            !!tariff &&
                            <LPUEdit lpu={tariff as ILPUTariff} vat={vat} onSaved={this.onSaved} enabled={isNew || !tariff.endDate} disabledStartDate={enableEdit} />
                        }
                        {
                            !isLoading &&
                            type === 'spu' &&
                            !!tariff &&
                            <SPUEdit spu={tariff as ISPUTariff} vat={vat} onSaved={this.onSaved} enabled={isNew || !tariff.endDate} disabledStartDate={enableEdit} />
                        }
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    };
}

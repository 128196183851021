import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { IRainMeter } from './rainMeter';
import firebaseApp from '../../../../services/firebaseService';
import { IRainMeterFormValues } from './rainMeterFormValues';
import { IUserSession } from '../../../employee';

export default class RainMeterModelHelper {
    public static readonly  COLLECTION_NAME = 'rain_meter';

    /*================================================================================================================
     *                                                  Converters
     * ==============================================================================================================*/

    private static converter : firebase.firestore.FirestoreDataConverter<IRainMeter> = {

        fromFirestore: (snapshot) => {
            return RainMeterModelHelper.fromFirestoreDocument(snapshot);
        },

        toFirestore: (data : IRainMeter) : firebase.firestore.DocumentData => {
            return RainMeterModelHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot | firebase.firestore.QueryDocumentSnapshot) : IRainMeter {
        const data = snapshot.data() as IRainMeter;
        if (!snapshot.exists) throw new Error(`Document does not exist! ${snapshot.id}`);
        
        return {
            ...data,
            ref: snapshot.ref,
        };
    }

    public static toFirestoreDocument(data : IRainMeter) {
        return RainMeterModelHelper.convertToObject(data);
    }

    public static convertToObject(model : IRainMeter) {
        const obj = {
            ...model,
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { ref, ...rest } = obj;
        return rest;
    }

    /*================================================================================================================
     *                                                Handler Methods
     * ==============================================================================================================*/

    public static collection() {
        return firebaseApp
            .firestore()
            .collection(RainMeterModelHelper.COLLECTION_NAME)
            .withConverter(RainMeterModelHelper.converter);
    }

    public static async save(entry : IRainMeter) : Promise<void> {
        if (entry.ref) {
            RainMeterModelHelper
                .collection()
                .doc(entry.ref.id)
                .set(entry, { merge: true });

        } else {
            RainMeterModelHelper
                .collection()
                .add(entry);
        }
    }

    /*================================================================================================================
     *                                                  Form Values
     * ==============================================================================================================*/

    public static createFormValues = (existing ?: IRainMeter | null) : IRainMeterFormValues => {
        if (existing) {
            return {
                guid: existing.guid,
                code: existing.code,
                latitude: existing?.geo?.latitude ?? null,
                longitude: existing?.geo?.longitude ?? null,
                isActive: existing.isActive,
            };
        } else {
            return {
                guid: uuidv4(),
                code: '',
                latitude: null,
                longitude: null,
                isActive: true,
            };
        }
    };

    public static createInactiveSave = (user : IUserSession, entry : IRainMeter) : IRainMeter => {        
        return {
            ...entry,
            updatedByEmployee: user.employee.EmployeeNumber ?? '',
            updatedByName: user.employee.Name,
            updatedBy: firebaseApp
                .firestore()
                .collection('employee')
                .doc(user.firebaseUser.uid),

            updatedOn: firebase.firestore.Timestamp.now(),
            isActive: false,
        }
    };

    public static createSave = (user : IUserSession, formValues : IRainMeterFormValues, existingEntry ?: IRainMeter | null) : IRainMeter => {       
        console.log('form values', formValues);
        const isLatitudeDefined = typeof formValues.latitude === 'number';
        const isLongitudeDefined = typeof formValues.longitude === 'number';

        if (existingEntry) {
            const geom = isLatitudeDefined && isLongitudeDefined
                ? new firebase.firestore.GeoPoint(formValues.latitude as number, formValues.longitude as number) 
                : null;

            return {
                ...existingEntry,
                code: formValues.code,
                geo: geom,
                updatedByEmployee: user.employee.EmployeeNumber ?? '',
                updatedByName: user.employee.Name,
                updatedBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                updatedOn: firebase.firestore.Timestamp.now(),
                isActive: formValues.isActive
            };

        } else {
            return {
                guid: formValues.guid,
                code: formValues.code,
                geo: (formValues.latitude !== null && formValues.longitude !== null) 
                    ? new firebase.firestore.GeoPoint(formValues.latitude as number, formValues.longitude as number) 
                    : null,

                createdByEmployee: user.employee.EmployeeNumber ?? '',
                createdByName: user.employee.Name,
                createdOn: firebase.firestore.Timestamp.now(),
                createdBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                updatedByEmployee: user.employee.EmployeeNumber ?? '',
                updatedByName: user.employee.Name,
                updatedBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                updatedOn: firebase.firestore.Timestamp.now(),
                isActive: true,
            };
        }
    };

    /*================================================================================================================
     *                                                  Schemas
     * ==============================================================================================================*/

    public static formSchema = () : Yup.ObjectSchema<IRainMeterFormValues> => Yup.object({
        guid: Yup.string().required('Required'), 
        code: Yup.string().trim().min(1, 'Must have at least 1 character').required('Required'),
        latitude: Yup.number().nullable().defined(),
        longitude: Yup.number().nullable().defined(),
        isActive: Yup.boolean().required('Required'), 
    });
}

import { createTheme } from '@material-ui/core/styles';

export const themeVariables = {
    colors: {
        white: '#FFFFFF',
        grey0: '#eaeaea',
        grey1: '#B4B4B4',
        grey3: '#424242',
        orange: '#FF7F00',
        yellow: '#fddb00',
        lightGreen: '#D9EAD3',
        blue: '#1B4586',
        dam: {
            locationGreen0: '#138544',
            locationGreen1: '#A5D380',
            locationGreen2: '#89C35A',
            locationGreen3: '#69AF32',
            blue: '#128186',
            darkBlue: '#272163',
            lightBlue: '#5EB2B6',
            orange: '#FD9926',
            lightGreen: '#90C663',
            semiDarkGreen: '#138544',
            notchLightBlue: '#50ADB1',
            notchBlue: '#08767B',
            notchDarkBlue: '#272163',
            notchLightGreen: '#8FCB5C',
            notchGreen: '#579523',
            notchDarkgreen: '#124B0F',
        },
        material: {
            primary: {
                main: '#0d703f',
                contrastText: '#fff',
                dark: '#0b4323',
                light: '#098442',
            },
            secondary: {
                main: '#fff',
                contrastText: '#0d703f',
            },
        },
        table: {
            capturing: {
                spu: {
                    waypoint: '#2C9FA2',
                    pole: '#61B2B5',
                    account: '#91C1C3',
                    totalUsage: '#92C46D',
                    waypointEven: '#2A989C',
                    poleEven: '#5DAAAD',
                    accountEven: '#8CB8BA',
                    totalUsageEven: '#82C44C',
                    oldRate: '#C5E369',
                    newRate: '#D2E788',
                    evenRow: '#F4F4F4',
                },
                lpu: {
                    waypoint: '#2C9FA2',
                    pole: '#61B2B5',
                    account: '#91C1C3',
                    waypointEven: '#2A989C',
                    poleEven: '#5DAAAD',
                    accountEven: '#8CB8BA',
                    oldRate: '#C5E369',
                    newRate: '#D2E788',
                    evenRow: '#F4F4F4',
                    invoiceTotal: '#92C46D',
                    invoiceTotalEven: '#82C44C',
                }
            }
        }
    },
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#0d703f',
            contrastText: '#fff',
            dark: '#0b4323',
            light: '#098442',
        },
        secondary: {
            main: '#fff',
            contrastText: '#0d703f',
        },
    },
});

export default theme;

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import Dialog from '@material-ui/core/Dialog';
import boreholeFunctions from '../../store/borehole/functions';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BoreholeSummary from './view/SummaryView';
import { IUserSession } from '../../@types/employee';
import BoreholeDetail from './view/DetailView';
import BoreholeTestView from './view/TestView';
import BoreholeInsAuditView from './view/AuditView';
import BoreholeCalibrationView from './view/CalibrationView';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import Tooltip from '@material-ui/core/Tooltip';
import { IBorehole } from '../../@types/model/borehole/borehole';
import BoreholeSensorAuditView from './view/SensorAuditView';
import TabViews from '../customComponents/tab/TabViews';
import BoreholeCommentsView from './view/CommentView';
import BoreholeAgesRecommendationView from './view/AgesRecommendationView';
import BoreholeCalibrationPDFView from './view/CalibrationPDFView';
import BoreholeConstantRatePDFView from './view/ConstantRatePDFView';

interface IBoreholeInfoProps {
    onClose ?: () => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    borehole ?: IBorehole;
    isLoadingBoreholes : boolean;

    session ?: IUserSession | null;
}

interface IBoreholeInfoState {
    tabIndex : number;
    showDelete : boolean;
}

class BoreholeInfo extends React.Component<IBoreholeInfoProps, IBoreholeInfoState> {
    constructor(props : IBoreholeInfoProps) {
        super(props);

        this.state = {
            tabIndex: 0,
            showDelete: false,
        };
    }

    private readonly onClose = () => {
        boreholeFunctions.setBorehole();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly onDeleteClick = () => {
        this.setState({
            showDelete: true,
        });
    };

    private onDelete = (result : boolean) => {
        if (result && this.props.borehole) {
            boreholeFunctions.deactivateBorehole(this.props.borehole.Code);
        }
        this.setState({
            showDelete: false,
        });
    };

    public readonly render = () => {
        const { borehole, fullWidth, transition, maxWidth, fullScreen, session, isLoadingBoreholes } = this.props;
        const { tabIndex, showDelete } = this.state;
        return (
            <Dialog
                open={!!borehole}
                onClose={this.onClose}
                aria-labelledby='borehole-info-dialog-title'
                aria-describedby='borehole-info-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}>
                <AppBar className='posr' position='static'>
                    <Toolbar variant='dense'>
                        {
                            borehole &&
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {borehole.Code} - {borehole.Name}
                            </Typography>
                        }
                        <span className={'flx1'} />
                        {
                            session?.employee.IsBoreholeAdmin &&
                            <Tooltip title='Delete'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoadingBoreholes}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        }
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <Tabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                        <Tab label='Summary' value={0} />
                        <Tab label='AGES RECOMMENDATIONS' value={1} />
                        <Tab label='Details' value={2} />
                        <Tab label='Borehole Tests' value={3} />
                        <Tab label='Sensor Audits' value={4} />
                        <Tab label='Installation Audits' value={5} />
                        <Tab label='Calibration Test' value={6} />
                        <Tab label='Calibration PDF' value={7} />
                        <Tab label='Constant Rate PDF' value={8} />
                        <Tab label='Comment History' value={9} />
                    </Tabs>
                </AppBar>
                <div className={'fdc flx1 hfill bcg0'}>
                    {
                        borehole &&
                        session &&
                        <TabViews index={tabIndex}>
                            <BoreholeSummary borehole={borehole} />
                            <BoreholeAgesRecommendationView borehole={borehole} session={session} />
                            <BoreholeDetail borehole={borehole} />
                            <BoreholeTestView />
                            <BoreholeSensorAuditView boreholeCode={borehole.Code} />
                            <BoreholeInsAuditView />
                            <BoreholeCalibrationView borehole={borehole} session={session} />
                            <BoreholeCalibrationPDFView borehole={borehole} session={session} />
                            <BoreholeConstantRatePDFView borehole={borehole} session={session} />
                            <BoreholeCommentsView borehole={borehole} session={session} />
                        </TabViews>
                    }
                </div>
                <ConfirmDialog open={showDelete} title={!borehole ? '' : `Confirm Deactivating ${borehole.Code} - ${borehole.Name}`}
                    message={!borehole ? '' : `Deactivate Borehole ${borehole.Code} - ${borehole.Name}?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
            </Dialog>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        borehole: state.borehole.borehole,
        isLoadingBoreholes: state.borehole.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(BoreholeInfo);

import firebase from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import ChemicalFillingPointHelper from './chemical';

export interface IChemicalFillingPointInspection extends IBasicBaseClass {
    photoUrl ?: string | null;
    thumbnailUrl ?: string | null;

    observationPhotoUrl ?: string | null;
    observationThumbnailUrl ?: string | null;

    guid : string;
    pointCode : string;
    pointRef : string;
    date : number;

    observation : string;
    observationComment : string;

    isActive : boolean;
    isSent : boolean;
}

export default class ChemicalFillingPointInspectionHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'chemical_filling_point_inspection';

    private static converter : firebase.firestore.FirestoreDataConverter<IChemicalFillingPointInspection> = {
        fromFirestore: (snapshot) => {
            return ChemicalFillingPointInspectionHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IChemicalFillingPointInspection) : firebase.firestore.DocumentData => {
            return ChemicalFillingPointInspectionHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IChemicalFillingPointInspection {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        if (!data) throw new Error(`Document does not exist! ${snapshot.id}`);

        return {
            ...data as IChemicalFillingPointInspection,
            ...result,
            pointRef: data.pointRef.id,
            date: (data.date as firebase.firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : IChemicalFillingPointInspection) {
        const result = super.toFirestore(data);
        const {
            id: _id,
            ...rest
        } = data;

        return {
            ...rest,
            ...result,
            pointRef: ChemicalFillingPointHelper.doc(data.pointRef),
            date: firebase.firestore.Timestamp.fromMillis(data.date),
        };
    }

    public static collection() {
        return firebaseApp
            .firestore()
            .collection(ChemicalFillingPointInspectionHelper.COLLECTION_NAME)
            .withConverter(ChemicalFillingPointInspectionHelper.converter);
    }


    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(ChemicalFillingPointInspectionHelper.COLLECTION_NAME).withConverter(ChemicalFillingPointInspectionHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(ChemicalFillingPointInspectionHelper.COLLECTION_NAME).withConverter(ChemicalFillingPointInspectionHelper.converter).doc(id);
    }

    public static save(data : IChemicalFillingPointInspection) {
        return ChemicalFillingPointInspectionHelper.doc(data.id).set(data, {
            merge: true,
        });
    }
}
import { IDataState, DataAction } from '../../@types/redux';
import DataActions from './actions';
import { getType } from 'typesafe-actions';

const initialState : IDataState = {
    employees: [],
    loadingEmployees: false,
    divisions: [],
    loadingDivisions: false,
    groundwaterZones: [],
    loadingGroundwaterZones: false,
    isLoadingAreas: false,
    areas: [],
    isLoadingDepartments: false,
    departments: [],
    transformerGroups: [],
    isLoadingTransformerGroups: false,
    transformerUsages: [],
    isLoadingTransformerUsages: false,
    transformerAttributes: [],
    isLoadingTransformerAttributes: false,
    companies: [],
    isLoadingCompanies: false,
};

export default function generalReducer(state : IDataState = initialState, action : DataAction) : IDataState {
    switch (action.type) {
        case getType(DataActions.dataSetEmployees):
            return { ...state, employees: action.payload };
        case getType(DataActions.dataSetEmployee):
            return { ...state, employee: action.payload };
        case getType(DataActions.dataSetLoadingEmployee):
            return { ...state, loadingEmployees: action.payload };
        case getType(DataActions.dataSetDivisions):
            return { ...state, divisions: action.payload };
        case getType(DataActions.dataSetLoadingDivisions):
            return { ...state, loadingDivisions: action.payload };
        case getType(DataActions.dataSetGroundwaterZones):
            return { ...state, groundwaterZones: action.payload };
        case getType(DataActions.dataSetLoadingGroundwaterZones):
            return { ...state, loadingGroundwaterZones: action.payload };
        case getType(DataActions.setAreas):
            return { ...state, areas: action.payload };
        case getType(DataActions.setLoadingAreas):
            return { ...state, isLoadingAreas: action.payload };
        case getType(DataActions.setLoadingDepartments):
            return { ...state, isLoadingDepartments: action.payload };
        case getType(DataActions.setDepartments):
            return { ...state, departments: action.payload };
        case getType(DataActions.setLoadingTransformerGroups):
            return { ...state, isLoadingTransformerGroups: action.payload };
        case getType(DataActions.setTransformerGroups):
            return { ...state, transformerGroups: action.payload };
        case getType(DataActions.setLoadingTransformerUsages):
            return { ...state, isLoadingTransformerUsages: action.payload };
        case getType(DataActions.setTransformerUsages):
            return { ...state, transformerUsages: action.payload };
        case getType(DataActions.setLoadingTransformerAttributes):
            return { ...state, isLoadingTransformerAttributes: action.payload };
        case getType(DataActions.setTransformerAttributes):
            return { ...state, transformerAttributes: action.payload };
        case getType(DataActions.setLoadingCompany):
            return { ...state, isLoadingCompanies: action.payload };
        case getType(DataActions.setCompanies):
            return { ...state, companies: action.payload };
        default:
            return state;
    }
}

import React from 'react';

interface IImageEmbedViewProps {
    file : File;

    width ?: string;
}

interface IImageEmbedViewState {
    src : string;
}

export default class ImageEmbedView extends React.PureComponent<IImageEmbedViewProps, IImageEmbedViewState> {
    constructor(props : IImageEmbedViewProps) {
        super(props);
        this.state = {
            src: '',
        };
    }

    public componentDidMount() : void {
        this.load();
    }

    public componentDidUpdate(prevProps : Readonly<IImageEmbedViewProps>) : void {
        if (this.props.file.size !== prevProps.file.size) {
            this.load();
        }
    }

    private readonly load = () => {
        this.setState({
            src: URL.createObjectURL(this.props.file),
        });
    };

    public readonly render = () => {
        const { src } = this.state;
        return (<img height={'100%'} src={`${src}`} />);
    };
}

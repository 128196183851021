import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import Typography from '@material-ui/core/Typography';

interface IRouteBreadCrumbsProps extends RouteComponentProps<Record<string, string | undefined>, Record<string, unknown>, Record<string, unknown>> {

}

interface IRouteBreadCrumbsState {}

class RouteBreadCrumbs extends React.PureComponent<IRouteBreadCrumbsProps, IRouteBreadCrumbsState> {
    constructor(props : IRouteBreadCrumbsProps) {
        super(props);
        this.state = {};
    }

    private readonly getLocation = (props : IRouteBreadCrumbsProps) => props.location;
    private readonly getPath = createSelector(
        [this.getLocation],
        (location) => {
            let title = '';
            if (location.pathname.startsWith('/borehole')) {
                title = 'Borehole';
                if (location.pathname.includes('/runtimesOverride')) title += ' > Runtimes Override';
            }

            if (location.pathname.startsWith('/weather')) title = 'Weather Station';
            if (location.pathname.startsWith('/transformer')) {
                title = 'Transformer';

                if (location.pathname.includes('/tariffs')) {
                    title += ' > Tariffs';

                    if (location.search.includes('lpu')) title += ' > LPU';
                    if (location.search.includes('spu')) title += ' > SPU';
                }

                if (location.pathname.includes('/budget')) {
                    title += ' > Budgets';

                    if (location.search.includes('lpu')) title += ' > LPU';
                    if (location.search.includes('spu')) title += ' > SPU';
                    if (location.search.includes('municipality')) title += ' > Municipality';
                }
                if (location.pathname.includes('/accounts')) title += ' > Accounts';
                if (location.pathname.includes('/journals')) title += ' > Journals';

                if (location.pathname.includes('/capturing')) {
                    title += ' > Capturing';

                    if (location.pathname.includes('spu')) title += ' > SPU';
                    if (location.pathname.includes('lpu')) title += ' > LPU';
                    if (location.pathname.includes('municipality')) title += ' > Municipality';
                    if (location.search.includes('spu')) title += ' > SPU';
                    if (location.search.includes('lpu')) title += ' > LPU';
                    if (location.search.includes('municipality')) title += ' > Municipality';
                    if (location.pathname.includes('/meter')) title += ' Meter';
                    if (location.pathname.includes('/invoice')) title += ' Invoice';
                }
            }

            if (location.pathname.startsWith('/mottech')) title = 'Mottech';
            if (location.pathname.startsWith('/river')) title = 'River';
            if (location.pathname.startsWith('/dam')) title = 'Dam';
            if (location.pathname.startsWith('/pumpingStation')) title = 'Pumping Station';
            if (location.pathname.startsWith('/flowmeter')) title = 'Flowmeter';
            if (location.pathname.startsWith('/chemical_filling_point')) title = 'Chemical Filling Station';

            if (location.pathname.includes('/list')) title += 's';
            if (location.pathname.includes('/map')) title += 's map';

            if (location.pathname.startsWith('/masterData')) {
                title = 'Master Data > ';
                if (location.pathname.includes('/pumps')) title += 'Pumps';
                if (location.pathname.includes('/motors')) title += 'Motors';
                if (location.pathname.includes('/motorSizes')) title += 'Motor Sizes';
                if (location.pathname.includes('/boreholeIssues')) title += 'Borehole Issues';
                if (location.pathname.includes('/transformer/group')) title += 'Transformer Groups';
                if (location.pathname.includes('/company')) title += 'Company';

                if (location.pathname.includes('/pumpingStation')) {
                    title += 'Pumping Station > ';
                    if (location.pathname.includes('/pumpMake')) title += 'Pump Makes';
                    if (location.pathname.includes('/pumpType')) title += 'Pump Types';
                    if (location.pathname.includes('/motorEfficiency')) title += 'Motor Efficiency';
                    if (location.pathname.includes('/motorMake')) title += 'Motor Makes';
                    if (location.pathname.includes('/motorPole')) title += 'Motor Poles';
                    if (location.pathname.includes('/motorMounting')) title += 'Motor Mountings';
                    if (location.pathname.includes('/motorPole')) title += 'Motor Poles';
                    if (location.pathname.includes('/motorSize')) title += 'Motor Sizes';
                    if (location.pathname.includes('/motorType')) title += 'Motor Types';
                    if (location.pathname.includes('/flowmeterSize')) title += 'Flowmeter Sizes';
                    if (location.pathname.includes('/flowmeterMake')) title += 'Flowmeter Makes';
                    if (location.pathname.includes('/flowmeterPulse')) title += 'Flowmeter Pulses';
                    if (location.pathname.includes('/filterSize')) title += 'Filter Sizes';
                    if (location.pathname.includes('/filterType')) title += 'Filter Types';
                }
            }

            if (location.pathname.startsWith('/employee')) title = 'Employees';

            return title;
        },
    );

    public readonly render = () => {
        const path = this.getPath(this.props);
        return (
            <Typography className='cy fs14 mt5'>
                {path}
            </Typography>
        );
    };
}

export default withRouter(RouteBreadCrumbs);

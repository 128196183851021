import { getState, dispatch } from '..';
import firebase from 'firebase/app';
import { FirebaseService } from '../../services/firebaseService';
import { weatherSetList, weatherSetLoading, weatherSetSelected, weatherSetAudits, weatherSetAuditLoading } from './actions';
import lodash from 'lodash';
import generalFunctions from '../general/functions';
import uuid from 'uuid';
import WeatherStationHelper, { IWeatherStation } from '../../@types/model/weather/weatherStation';
import { IWeatherStationAudit } from '../../@types/weatherStation';

const AUDIT_COLLECTION = 'weather_station_audit';

let listListener : undefined | (() => void);
let auditListener : undefined | (() => void);

const getList = async (onLoaded ?: () => void) => {
    if (listListener) {
        if (onLoaded) onLoaded();
        return;
    }

    dispatch(weatherSetLoading(true));
    dispatch(weatherSetList([]));

    try {
        listListener = WeatherStationHelper.listen().onSnapshot((snapshot) => {
            const state = getState().weather;

            const list = state.weatherStations.slice();
            const session = getState().auth.session;
            if (!session) return;

            // "added" | "removed" | "modified"
            snapshot
                .docChanges()
                .forEach((f) => {
                    const item = f.doc.data();

                    if (
                        !session.employee.IsWeatherStationAdmin &&
                        item.Division &&
                        session.employee.Areas.length &&
                        !session.employee.Areas.includes(item.Division)) return;

                    const index = lodash.findIndex(list, n => n.WSNumber === item.WSNumber);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

            dispatch(weatherSetList(list));

            if (state.weatherStation) {
                const id = state.weatherStation.WSNumber;
                setSelected(list.find(n => n.WSNumber === id), false);
            }

            dispatch(weatherSetLoading(false));
            if (onLoaded) onLoaded(); 
        });

    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar('An error while loading weather stations.');
        dispatch(weatherSetLoading(false));
    }
};

const setSelected = (item ?: IWeatherStation, loadChildren = true) => {
    dispatch(weatherSetSelected(item));

    if (loadChildren || !item) {
        getAudits(item);
    }
};

const deactivate = async (id : string) => {
    const auth = getState().auth;

    if (!auth.session || !auth.session.employee.IsWeatherStationAdmin) return;

    dispatch(weatherSetLoading(true));

    try {
        await WeatherStationHelper
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebase.firestore().collection('employee').doc(auth.session.firebaseUser.uid),
                IsActive: false,
                IsSent: false,
            });

        setSelected();
    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar('An error while deactivating weather.');
    }

    dispatch(weatherSetLoading(false));
};

const getAudits = (weather ?: IWeatherStation) => {
    if (auditListener) auditListener();

    dispatch(weatherSetAudits([]));

    if (!weather) return;

    dispatch(weatherSetAuditLoading(true));
    try {
        auditListener = firebase.firestore().collection(AUDIT_COLLECTION)
            .where('WeatherStation', '==', WeatherStationHelper.doc(weather.WSNumber))
            .orderBy('CreatedOn', 'desc').onSnapshot((snapshot : firebase.firestore.QuerySnapshot) => {
                const state = getState().weather;

                const list = state.audits.slice();

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data() as IWeatherStationAudit;

                    const index = lodash.findIndex(list, n => n.Id === item.Id);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(weatherSetAudits(list));

                dispatch(weatherSetAuditLoading(false));
            });

    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar('An error while loading weather station audits.');
        dispatch(weatherSetAuditLoading(false));
    }
};

const createWeatherStation = async (name : string, code : string) => {
    dispatch(weatherSetLoading(true));

    const session = getState().auth.session;

    if (!session) return;
    const weatherStation : IWeatherStation = {
        ref: WeatherStationHelper.doc(),
        GUID: uuid.v4(),
        WSNumber: code,
        WSName: name,
        Geom: new firebase.firestore.GeoPoint(-1, -1),
        Elevation: 0,
        WSType: '',
        Division: null,
        InstallationDate: null,
        SerialNumber: null,
        Surroundings: [],
        StationImageURL: null,
        ObstructionDistance: 0,
        Fenced: false,
        IsSent: false,
        CreatedOn: firebase.firestore.Timestamp.now(),
        CreatedBy: firebase.firestore().collection('employee').doc(session.firebaseUser.uid),
        CreatedByName: session.employee.Name,
        CreatedByEmployee: session.employee.EmployeeNumber ?? '',
        UpdatedOn: firebase.firestore.Timestamp.now(),
        UpdatedBy: firebase.firestore().collection('employee').doc(session.firebaseUser.uid),
        UpdatedByName: session.employee.Name,
        UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
        IsActive: true,
    };

    let result = false;
    try {
        const docSnap = await WeatherStationHelper
            .doc(weatherStation.WSNumber).get();

        if (docSnap.exists) {
            throw new Error(`${code} already exists.`);
        }

        await WeatherStationHelper
            .doc(weatherStation.WSNumber)
            .set(weatherStation);
        result = true;
    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar(`An error while creating borehole. ${ex}`);
    }
    dispatch(weatherSetLoading(false));

    if (result) setSelected(weatherStation);
    return result;
};

/** Save's weather station.
 * Sets Weather Station Loading on Transformer State. Sets IsSent, UpdatedOn and UpdatedBy.
 * Returns true if success false if failure.
 */
const saveWeatherStation = async (weatherStation : IWeatherStation) => {

    const session = getState().auth.session;

    if (!session) return;

    dispatch(weatherSetLoading(true));

    weatherStation.IsSent = false;
    weatherStation.UpdatedOn = firebase.firestore.Timestamp.now();
    weatherStation.UpdatedBy = firebase.firestore().collection('employee').doc(session.firebaseUser.uid);

    let result = false;
    try {
        await WeatherStationHelper
            .doc(weatherStation.WSNumber)
            .update(weatherStation);
        result = true;
    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar(`An error while saving weather station. ${ex}`);
    }
    dispatch(weatherSetLoading(false));
    return result;

};

/**
 * Uploads file to Firebase Selected Weather Station's storage location.
 * Firebase function generateThumbnail updates appropriate field
 * once thumbnail is generated.
 * @param file File to upload
 * @param progressCallback callback for updating upload progress
 * @param errorCallback callback on error
 */
const uploadSelectedWeatherStationImage = async (file : File, progressCallback : (progress : number) => void, errorCallback ?: (e : Error) => void) => {
    const weatherState = getState().weather;

    if (!weatherState.weatherStation) throw new Error('No selected weather station');

    const unsub = FirebaseService.uploadFileMetaData(weatherState.weatherStation.WSNumber, 'StationImageURL', WeatherStationHelper.COLLECTION_NAME, file, `weather_station/${weatherState.weatherStation.WSNumber}`)
        .on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

            if (progress >= 100) {
                unsub();
            }

            progressCallback(progress);
        }, errorCallback);
};

const weatherFunctions = {
    getList,
    setSelected,
    deactivate,
    createWeatherStation,
    saveWeatherStation,
    uploadSelectedWeatherStationImage,
};

export default weatherFunctions;

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import TransformerJournalHelper, { ITransformerJournal } from '../../../@types/model/transformer/journal';
import { CURRENCY_FORMAT } from '../../../appConstants';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import MaterialTable from '../../customComponents/materialTable/Table';
import TransformerJournalExportDialog from './dialog/Export';
import TransformerInfoDialog from './dialog/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { createSelector } from 'reselect';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Fab, InputAdornment } from '@material-ui/core';
import DropdownButton from '../../customComponents/button/DropdownButton';

interface ITransformerJournalListProps {}

interface ITransformerJournalListState {
    isLoading : boolean;
    journals : Array<ITransformerJournal>;

    selected : Array<ITransformerJournal>;

    startDate : moment.Moment | null;
    endDate : moment.Moment | null;
}

export default class TransformerJournalList extends React.PureComponent<ITransformerJournalListProps, ITransformerJournalListState> {

    private listener ?: () => void;
    constructor(props : ITransformerJournalListProps) {
        super(props);

        const currentDate = moment.utc().endOf('day');
        this.state = {
            isLoading: false,
            journals: [],
            selected: [],
            endDate: currentDate,
            startDate: moment.utc(currentDate).startOf('month').subtract(1, 'month'),
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly componentWillUnmount = () => {
        if (this.listener) this.listener();
    };

    private readonly loadData = () => {
        if (this.state.isLoading) return;
        if (this.listener) this.listener();

        this.setState({
            isLoading: true,
        });

        this.listener = TransformerJournalHelper.collection()
            .where('createdOn', '>=', this.state.startDate?.toDate())
            .where('createdOn', '<=', this.state.endDate?.toDate())
            .orderBy('createdOn')
            .onSnapshot((snapshot) => {
                // Shallow clone is fine here as we do not modify objects in array.
                const journals = this.state.journals.slice();

                snapshot.docChanges().forEach((f) => {
                    switch (f.type) {
                        case 'added':
                            journals.splice(f.newIndex, 0, f.doc.data());
                            break;
                        case 'modified':
                            journals.splice(f.oldIndex, 1);
                            journals.splice(f.newIndex, 0, f.doc.data());
                            break;
                        case 'removed': {
                            journals.splice(f.oldIndex, 1);
                        }
                            break;
                    }
                });

                this.setState({
                    journals,
                    isLoading: false,
                });
            }, (error) => {
                this.setState({
                    journals: [],
                    isLoading: false,
                });

                GeneralFunctions.generalShowError(error, 'Error loading Journals');
            });
    };

    public readonly onSelectionChange = (selected : Array<ITransformerJournal>) => {
        this.setState({
            selected,
        });
    };

    public readonly onJournalExportDialogClose = (clear ?: boolean) => {
        if (clear) {
            this.setState({
                selected: [],
            });
        }
    };
    
    public readonly getJournals = (state : ITransformerJournalListState) => state.journals;
    public readonly getSortedJournals = createSelector(
        [this.getJournals],
        (journals) : Array<ITransformerJournal> => {
            return journals
                .sort((a, b) => Number(b.journalNumber.substring(3)) - Number(a.journalNumber.substring(3)));
        },
    );

    private readonly onStartDateChanged = (momentDate : moment.Moment | null) => {
        this.setState({
            startDate: !momentDate?.isValid() ? null : moment.utc(momentDate.valueOf()).startOf('month'),
        });
    };

    private readonly onEndDateChanged = (momentDate : moment.Moment | null) => {
        this.setState({
            endDate: !momentDate?.isValid() ? null : moment.utc(momentDate.valueOf()).endOf('month'),
        });
    };

    public readonly render = () => {
        const {
            isLoading,
            selected,
            startDate,
            endDate,
        } = this.state;

        const journals = this.getSortedJournals(this.state);
        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar className='ais' disableGutters>
                        {
                            !!selected.length &&
                            <TransformerJournalExportDialog
                                onClose={this.onJournalExportDialogClose}
                                value={selected}
                                fullScreen
                                transition={Transitions.Down}
                            />
                        }
                        <span className='flx2' />
                        <div className={'flx1 ais mb10 pl10 pr10'}>
                            <KeyboardDatePicker
                                value={startDate}
                                fullWidth
                                views={['month']}
                                onChange={this.onStartDateChanged}
                                format='MMM YYYY'
                                label='Select Start Month...'
                                autoComplete='off'
                                id='startDate'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <DropdownButton />
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={isLoading}
                            />
                        </div>
                        <div className={'flx1 ais mb10 pl10 pr10'}>
                            <KeyboardDatePicker
                                value={endDate}
                                fullWidth
                                views={['month']}
                                onChange={this.onEndDateChanged}
                                format='MMM YYYY'
                                label='Select End Month...'
                                autoComplete='off'
                                id='endDate'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <DropdownButton />
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={isLoading}
                            />
                        </div>
                        <div className={'aic jcc mb10 pl10'}>
                            <Fab
                                disabled={isLoading}
                                variant='extended'
                                color='secondary'
                                className='fab save fw600'
                                size='small'
                                onClick={this.loadData}
                            >
                                APPLY
                            </Fab>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<ITransformerJournal>
                            id='transformerInvoicesTable'
                            data={journals}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            selectable
                            selectedRows={selected}
                            isSelectable={row => !row.exported}
                            onSelectionChanged={this.onSelectionChange}
                            columns={[{
                                header: '',
                                field: 'id',
                                paddingLeft: 15,
                                width: 10,
                                renderCell: row => (
                                    <div className='fdr aic jcc'>
                                        <TransformerInfoDialog value={row} fullWidth fullScreen transition={Transitions.Up} />
                                    </div>
                                ),
                            }, {
                                header: 'Date',
                                field: 'createdOn',
                                width: 75,
                                paddingLeft: 15,
                                renderCell: row => AppFunctionsService.formatDateTimeToDateOnly(row.createdOn),

                            }, {
                                header: 'Number',
                                field: 'journalNumber',
                                width: 75,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Type',
                                field: 'type',
                                width: 75,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => row.type.toLocaleUpperCase(),
                            }, {
                                header: 'Group',
                                field: 'lines.0.group',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Total VAT',
                                field: 'totalVat',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <div className=''>
                                        {CURRENCY_FORMAT.format(Math.abs(row.totalVat))}
                                    </div>
                                ),
                            }, {
                                header: 'Total',
                                field: 'totalAccount',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <div className=''>
                                        {CURRENCY_FORMAT.format(row.totalAccount)}
                                    </div>
                                ),
                            }, {
                                header: 'Exported',
                                field: 'createdByName',
                                width: 145,
                                renderCell: row => (
                                    <div className='fdc jcc'>
                                        <Tooltip title={!row.exported ? '' : `${row.exportedByName} - ${row.exportedByEmployeeNumber}`}>
                                            <CheckCircleIcon className={!row.exported ? 'cg2' : 'cp'} />
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 145,
                                renderCell: row => (
                                    <Tooltip title={row.createdByEmployee}>
                                        <div>
                                            {
                                                row.createdByName
                                            }
                                        </div>
                                    </Tooltip>
                                ),
                            }]}
                        />
                    </Card>
                </div>
            </div>
        );
    };
}

import { getState, dispatch } from '..';
import firebase from 'firebase/app';
import { damSetList, damSetLoading, damSetReadingLoading, damSetReadings, damSetSelected } from './actions';
import lodash from 'lodash';
import generalFunctions from '../general/functions';
import DamHelper, { EnumDamType, IDam } from '../../@types/model/dam/dam';
import moment from 'moment';
import DamWeeklyReadingHelper, { IDamWeeklyReading } from '../../@types/model/dam/weeklyReading';

export default class DamFunctions {
    private static listListener ?: () => void;
    private static readingListener ?: undefined | (() => void);

    public static readonly getList = async (onLoaded ?: () => void) => {
        if (DamFunctions.listListener) {
            if (onLoaded) onLoaded();
            return;
        }
        dispatch(damSetLoading(true));
        dispatch(damSetList([]));

        try {
            DamFunctions.listListener = DamHelper.listen().onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IDam>) => {
                const state = getState().dam;

                const list = state.dams.slice();
            
                const session = getState().auth.session;
                if (!session) return;

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();

                    if (
                        !session.employee.IsDamAdmin &&
                        item.division &&
                        session.employee.Areas.length &&
                        !session.employee.Areas.includes(item.division) &&
                        !session.employee.Areas.includes(item.managementArea ?? '')) return;

                    if (
                        !session.employee.IsDamAdmin &&
                        item.constructionType !== null &&
                        session.employee.DamConstructionTypes.length &&
                        !session.employee.DamConstructionTypes.includes(item.constructionType)) return;

                    const index = lodash.findIndex(list, n => n.code === item.code);


                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(damSetList(list));

                if (state.selectedDam) {
                    DamFunctions.setSelected(list.find(n => n.code === state.selectedDam?.code), false);
                }

                dispatch(damSetLoading(false));
                if (onLoaded) onLoaded(); 
            });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading dams.');
            dispatch(damSetLoading(false));
        }
    };

    public static readonly setSelected = (item ?: IDam, loadChildren = true) => {
        dispatch(damSetSelected(item));
        if (loadChildren || !item) {
            DamFunctions.getReadings(item);
        }
    };

    public static readonly createDam = async (name : string, code : string) => {
        dispatch(damSetLoading(true));

        const session = getState().auth.session;

        if (!session) return;
        const dam : IDam = {
            id: code,
            createdBy: session.firebaseUser.uid,
            createdByEmployee: session.employee.EmployeeNumber ?? '',
            createdByName: session.employee.Name,
            createdOn: moment.utc().valueOf(),
            updatedBy: session.firebaseUser.uid,
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedByName: session.employee.Name,
            updatedOn: moment.utc().valueOf(),
            capacity: null,
            catchmentArea: null,
            classificationDate: null,
            code: code,
            completionDate: null,
            completionDateRaised: null,
            constructionEndDate: null,
            contractor: null,
            crestLength: null,
            damFeed: null,
            damType: EnumDamType.Dam,
            designer: null,
            distanceFromTown: null,
            division: null,
            farmPortion: null,
            geo: null,
            hazardPotential: null,
            lastDSIDate: null,
            lastDSINumber: null,
            managementArea: null,
            measurementStick: false,
            municipalDistrict: null,
            name: name,
            nearestTown: null,
            ownerName: null,
            parentFarm: null,
            provinceCode: null,
            purpose: null,
            quaternaryDrainageArea: null,
            regionCode: null,
            registeredCapacity: null,
            registrationDate: null,
            riskCategory: null,
            sector: null,
            size: null,
            spillwayType: null,
            surfaceArea: null,
            surveyed: null,
            surveyType: null,
            targetDate: null,
            titleDeed: null,
            wallHeight: null,
            wallType: null,
            warmsId: null,
            warmsNo: null,
            waterManagementArea: null,
            damLatestReadings: null,
            damVolumes: [],
            damVNotch: null,
            comment: null,
            elevation: null,
            percentageFull: null,
            riverOrWaterCourse: null,
            constructionType: null,
            status: null,
            isWeb: true,
            lastInspectionDate: null,
            nextInspectionDate: null,
            divisionDescriptionAccpac: null,
            divisionNumber: null,
            wallProtectionSurface: null,
            slopeSurfaceProtection: null,
            slopeSurfaceProtectionOther: null,
            electricalBoxFiles: [],
            isSent: false,
            transformerNumber: null,
            transformerRef: null,
        };

        let result = false;
        try {
            await DamHelper.save(dam);
            DamFunctions.setSelected(dam);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while creating dam. ${ex}`);
        } finally {
            dispatch(damSetLoading(false));
        }
        return result;
    };

    /** Save's dam.
     * Sets dam Loading on dam State. Sets IsSent, UpdatedOn and UpdatedBy.
     * Returns true if success false if failure.
     */
    public static readonly saveDam = async (dam : IDam) => {

        const session = getState().auth.session;

        if (!session) return;

        dispatch(damSetLoading(true));
        dam.updatedBy = session.firebaseUser.uid;
        dam.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        dam.updatedByName = session.employee.Name;
        dam.updatedOn = moment.utc().valueOf();

        try {
            await DamHelper.save(dam);

            return true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving dam. ${ex}`);
        } finally {
            dispatch(damSetLoading(false));
        }

        return false;

    };

    public static readonly getReadings = (dam ?: IDam) => {
        if (DamFunctions.readingListener) DamFunctions.readingListener();

        dispatch(damSetReadings([]));

        if (!dam) return;

        dispatch(damSetReadingLoading(true));
        try {
            DamFunctions.readingListener = DamWeeklyReadingHelper.listen(dam.code).orderBy('createdOn', 'desc').onSnapshot((snapshot) => {
                const state = getState().dam;

                const list = state.readings.slice();
                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();

                    const index = lodash.findIndex(list, n => n.id === item.id);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(damSetReadings(list));

                dispatch(damSetReadingLoading(false));
            });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading dam readings.');
            dispatch(damSetReadingLoading(false));
        }
    };

    /** Save's dam weekly reading.
     * Sets dam Loading on dam State. Sets UpdatedOn and UpdatedBy.
     * Returns true if success false if failure.
     */
    public static readonly saveWeeklyReading = async (reading : IDamWeeklyReading) => {

        const session = getState().auth.session;

        if (!session) return;

        dispatch(damSetLoading(true));
        reading.updatedBy = session.firebaseUser.uid;
        reading.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        reading.updatedByName = session.employee.Name;
        reading.updatedOn = moment.utc().valueOf();

        try {
            await DamWeeklyReadingHelper.save(reading);

            return true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving dam weekly reading. ${ex}`);
        } finally {
            dispatch(damSetLoading(false));
        }

        return false;
    };
}
import AppFunctionsService from '../../../services/appFunctionsService';
import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';

export interface IChemicalFillingPoint extends IBasicBaseClass {
    guid : string;
    code : string;
    name : string;

    division : string | null;
    managementArea : string | null;
    divisionNo : string | null;
    accpacDesc : string | null;

    elevation : number | null;
    geo : firebase.firestore.GeoPoint | null;
    
    photoUrl ?: string | null;
    thumbnailUrl ?: string | null;

    isActive : boolean;
    isSent : boolean;
}

export default class ChemicalFillingPointHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'chemical_filling_point';

    private static converter : firebase.firestore.FirestoreDataConverter<IChemicalFillingPoint> = {
        fromFirestore: (snapshot) => {
            return ChemicalFillingPointHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IChemicalFillingPoint) : firebase.firestore.DocumentData => {
            return ChemicalFillingPointHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IChemicalFillingPoint {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        if (!data) throw new Error(`Document does not exist! ${snapshot.id}`);

        return {
            ...data as IChemicalFillingPoint,
            ...result,
        };
    }

    public static toFirestoreDocument(data : IChemicalFillingPoint) {
        const result = super.toFirestore(data);
        const {
            id: _id,
            ...rest
        } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp
            .firestore()
            .collection(ChemicalFillingPointHelper.COLLECTION_NAME)
            .withConverter(ChemicalFillingPointHelper.converter);
    }

    public static mapToCsv(data : Array<IChemicalFillingPoint>) {
        return data.map(n => ({
            code: n.code,
            name: n.name,
            division: n.division,
            latitude: !n.geo ? 0 : n.geo.latitude,
            longitude: !n.geo ? 0 : n.geo.longitude,
            managementArea: n.managementArea,
            createdOn: AppFunctionsService.formatDateTime(n.createdOn),
            updatedOn: AppFunctionsService.formatDateTime(n.updatedOn),
        }));
    }


    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(ChemicalFillingPointHelper.COLLECTION_NAME).withConverter(ChemicalFillingPointHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(ChemicalFillingPointHelper.COLLECTION_NAME).withConverter(ChemicalFillingPointHelper.converter).doc(id);
    }

    public static save(data : IChemicalFillingPoint) {
        return ChemicalFillingPointHelper.doc(data.id).set(data, {
            merge: true,
        });
    }
}
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React from 'react';

interface IFormikFormProps<T> {
    children : ((props : FormikProps<T>) => React.ReactNode) | React.ReactNode;

    initialValues : T;

    onSubmit : (values : T, bag : FormikHelpers<T>) => void;
    validationSchema ?: unknown;

    enableReinitialize ?: boolean;
    className ?: string;

    validateOnMount ?: boolean;
}

interface IFormikFormState {}

export default class FormikForm<T extends FormikValues> extends React.PureComponent<IFormikFormProps<T>, IFormikFormState> {
    constructor(props : IFormikFormProps<T>) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const {
            className,
            children,
            enableReinitialize,
            initialValues,
            onSubmit,
            validationSchema,
            validateOnMount,
        } = this.props;

        return (
            <Formik
                enableReinitialize={enableReinitialize}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnMount={validateOnMount}
            >
                {
                    formProps => (
                        <Form className={className}>
                            {
                                children &&
                                typeof children === 'function' &&
                                children(formProps)
                            }
                            {
                                children &&
                                typeof children !== 'function' &&
                                children
                            }
                        </Form>
                    )
                }
            </Formik>
        );
    };
}

import { IBaseClass, BaseHelper } from '../base';
import firebaseApp from '../../../services/firebaseService';
import { HIGH_SEASON_MONTHS, LINE_VOLTAGES, LPU_CATEGORIES, LPU_VOLTAGES } from '../../../appConstants';
import { firestore } from 'firebase/app';
import { ITransformer } from './transformer';
import moment from 'moment';

export type TariffType = 'lpu' | 'spu';

export interface ITransformerTariff extends IBaseClass {
    ref : firebase.firestore.DocumentReference<ITransformerTariff>;
    type : TariffType;
    startDate : number;
    endDate : number | null;

    vat : number;
}

export interface ILPUTariff extends ITransformerTariff {
    ref : firebase.firestore.DocumentReference<ILPUTariff>;
    zones : Array<ITransmissionZone>;
    categories : Array<ILPUCategory>;
    voltages : Array<ILPUVoltage>;
    reactiveEnergyCharge : {
        high : number;
        low : number;
    };
}

export interface ITransmissionZone {
    lower : number;
    upper : number;
    highDemand : IDemand;
    lowDemand : IDemand;
    highDemandMonths : Array<number>;
    lowDemandMonths : Array<number>;
    netwrokCapacity : [number, number];
}

export interface ILPUCategory {
    lower : number;
    upper : number;
    serviceCharge : number;
    administrationCharge : number;
}

export interface ILPUVoltage {
    lower : number;
    upper : number;
    ancillaryServiceCharge : number;
    networkDemandCharge : number;
}

export interface IDemand {
    peak : [number, number];
    standard : [number, number];
    offPeak : [number, number];
}

export interface ILandrate {
    energyCharge ?: number;
    ancillaryService ?: number;
    networkDemand ?: number;
    networkCapacity ?: number;
    serviceCharge ?: number;
}

export interface ISPUTariff extends ITransformerTariff {
    ref : firebase.firestore.DocumentReference<ISPUTariff>;
    landrates : {
        [key : string] : ILandrate;
        1 : ILandrate;
        2 : ILandrate;
        3 : ILandrate;
        4 : ILandrate;
        DX : ILandrate;
    };
}

export interface ILPULine {    
    days : number;
    
    tariff : ILPUTariff;
    tariffValues : ILPUTariffValues;

    networkDemandUnits : number;
    ancillaryServiceCharge : number;
    unitsSTDLow : number;
    unitsOffPeakLow : number;
    unitsSTDHigh : number;
    unitsOffPeakHigh : number;
    unitsPeakHigh : number;
    unitsPeakLow : number;
    excessNetworkAccessCharge : number;
    excessReactiveEnergy : number;
    interestCharge : number;

    total : number;
}

export interface ISPULine {
    transformer : ITransformer;
    
    days : number;
    tariff : ISPUTariff;
    tariffValues : ISPUTariffValues;
    totalUsage : number;
    calculatedUsage : number;

    total : number;
}

export interface ILPUTariffValues {
    netwrokCapacity : number;
    networkDemandCharge : number;
    ancillaryServiceCharge : number;
    lowSTD : number;
    lowOffPeak : number;
    lowPeak : number;
    highSTD : number;
    highOffPeak : number;
    highPeak : number;
    reactiveEnergyCharge : number;
}

export interface ISPUTariffValues {
    serviceCharge : number;
    networkCapacity : number;
    networkDemand : number;
    ancillaryService : number;
    energyCharge : number;
}

export default class TariffHelper extends BaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_tariffs';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerTariff> = {
        fromFirestore: (snapshot) => {
            return TariffHelper.fromFirestore(snapshot);
        },
        toFirestore: () : firebase.firestore.DocumentData => {
            throw new Error('Cannot save Tariff');
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerTariff {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) throw new Error('Tariff not found!');

        return {
            ...result,
            ref: result.ref.withConverter(this.converter),
            startDate: data.startDate.toMillis(),
            endDate: data.endDate?.toMillis() ?? null,
            type: data.type,
            vat: data.vat ?? 0.15,
        };
    }

    private static lpuConverter : firebase.firestore.FirestoreDataConverter<ILPUTariff> = {
        fromFirestore: (snapshot) => {
            return TariffHelper.lpuFromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ILPUTariff) : firebase.firestore.DocumentData => {
            const { ref: _ref, ...rest } = data;

            const firestoreObject = {
                ...rest,
                startDate: firestore.Timestamp.fromMillis(data.startDate),
                endDate: !data.endDate ? null : firestore.Timestamp.fromMillis(data.endDate),
            };
            firestoreObject.type = 'lpu';
            return firestoreObject;
        },
    };

    private static spuConverter : firebase.firestore.FirestoreDataConverter<ISPUTariff> = {
        fromFirestore: (snapshot) => {
            return TariffHelper.spuFromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ISPUTariff) : firebase.firestore.DocumentData => {
            const { ref: _ref, ...rest } = data;
            const firestoreObject = {
                ...rest,
                startDate: firestore.Timestamp.fromMillis(data.startDate),
                endDate: !data.endDate ? null : firestore.Timestamp.fromMillis(data.endDate),
            };

            firestoreObject.type = 'spu';
            return firestoreObject;
        },
    };

    public static lpuFromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
        const data = doc.data();

        if (!data) throw new Error('LPU Document not found!');

        const result : ILPUTariff = {
            ref: doc.ref.withConverter(this.lpuConverter),
            createdBy: data.createdBy,
            updatedBy: data.updatedBy,
            createdByName: data.createdByName,
            updatedByName: data.updatedByName,
            createdByEmployee: data.createdByEmployee,
            updatedByEmployee: data.updatedByEmployee,
            createdOn: data.createdOn,
            updatedOn: data.updatedOn,
            zones: data.zones.sort((a : any, b : any) => a.lower - b.lower),
            categories: data.categories,
            voltages: data.voltages,
            reactiveEnergyCharge: data.reactiveEnergyCharge,
            startDate: data.startDate?.toMillis() ?? 0,
            endDate: data.endDate?.toMillis() ?? null,
            type: 'lpu',
            vat: data.vat,
        };

        if (!data.categories) {
            result.categories = LPU_CATEGORIES.map(n => ({
                administrationCharge: 0,
                lower: n[0],
                upper: n[1],
                serviceCharge: 0,
            }));
        }

        if (!data.voltages) {
            result.voltages = LPU_VOLTAGES.map(n => ({
                lower: n[0],
                upper: n[1],
                ancillaryServiceCharge: 0,
                networkDemandCharge: 0,
            }));
        }

        if (!data.reactiveEnergyCharge) {
            result.reactiveEnergyCharge = {
                high: 0,
                low: 0,
            };
        }

        return result;
    }

    public static spuFromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data();

        if (!data) throw new Error('SPU Document not found!');

        const result : ISPUTariff = {
            ref: doc.ref.withConverter(this.spuConverter),
            createdBy: data.createdBy,
            updatedBy: data.updatedBy,
            createdByName: data.createdByName,
            updatedByName: data.updatedByName,
            createdByEmployee: data.createdByEmployee,
            updatedByEmployee: data.updatedByEmployee,
            createdOn: data.createdOn,
            updatedOn: data.updatedOn,
            landrates: data.landrates,
            startDate: data.startDate?.toMillis() ?? 0,
            endDate: data.endDate?.toMillis() ?? null,
            type: 'spu',
            vat: data.vat,
        };

        return result;
    }

    public static lpuDoc(id ?: string) {
        if (!id) {
            return this.collection().doc().withConverter(this.lpuConverter);
        }
        return this.collection().doc(id).withConverter(this.lpuConverter);
    }

    public static spuDoc(id ?: string) {
        if (!id) {
            return this.collection().doc().withConverter(this.spuConverter);
        }
        return this.collection().withConverter(this.spuConverter).doc(id);
    }

    public static collection() {
        return firebaseApp.firestore().collection(TariffHelper.COLLECTION_NAME).withConverter(this.converter);
    }

    public static spuCollection() {
        return firebaseApp.firestore().collection(TariffHelper.COLLECTION_NAME).where('type', '==', 'spu').withConverter(this.spuConverter);
    }

    public static lpuCollection() {
        return firebaseApp.firestore().collection(TariffHelper.COLLECTION_NAME).where('type', '==', 'lpu').withConverter(this.lpuConverter);
    }

    public static async saveLPU(tariff : ILPUTariff) {
        const lastTariffSnap = await this.collection().where('type', '==', 'lpu').where('endDate', '==', null).withConverter(this.lpuConverter).limit(1).get();

        await firebaseApp.firestore().runTransaction(async (transaction) => {

            /**
             * Have to get docs first.
             */
            const doc = await transaction.get(tariff.ref);
            const lastDocSnap = !lastTariffSnap.docs.length ? null : (await transaction.get(lastTariffSnap.docs[0].ref));

            transaction.set(doc.ref, tariff);

            /**
             * Set Last Tariff's end date to the new/updated tariff's start date.
             * 
             * Ignore if last tariff is this tariff.
             */
            const lastData = lastDocSnap?.data();
            if (lastData && lastDocSnap && lastDocSnap.ref.id !== doc.ref.id) {
                lastData.endDate = tariff.startDate;
                lastData.updatedBy = tariff.updatedBy;
                lastData.updatedByEmployee = tariff.updatedByEmployee;
                lastData.updatedByName = tariff.updatedByName;
                lastData.updatedOn = tariff.updatedOn;

                transaction.set(lastDocSnap.ref, lastData);
            }
        });
    }

    public static async saveSPU(tariff : ISPUTariff) {
        const lastTariffSnap = await this.collection().where('type', '==', 'spu').where('endDate', '==', null).withConverter(this.spuConverter).limit(1).get();

        await firebaseApp.firestore().runTransaction(async (transaction) => {

            /**
             * Have to get docs first.
             */
            const doc = await transaction.get(tariff.ref);
            const lastDocSnap = !lastTariffSnap.docs.length ? null : (await transaction.get(lastTariffSnap.docs[0].ref));

            transaction.set(doc.ref, tariff);

            /**
             * Set Last Tariff's end date to the new/updated tariff's start date.
             * Ignore if last tariff is this tariff.
             */
            const lastData = lastDocSnap?.data();
            if (lastData && lastDocSnap && lastDocSnap.ref.id !== doc.ref.id) {
                lastData.endDate = tariff.startDate;
                lastData.updatedBy = tariff.updatedBy;
                lastData.updatedByEmployee = tariff.updatedByEmployee;
                lastData.updatedByName = tariff.updatedByName;
                lastData.updatedOn = tariff.updatedOn;

                transaction.set(lastDocSnap.ref, lastData);
            }
        });
    }

    public static getSPUTariffValues(tariff : ISPUTariff, transformer : ITransformer) : ISPUTariffValues {
        let landrate : ILandrate | undefined;

        if (transformer.Landrate) {
            landrate = tariff.landrates[transformer.Landrate];
        }

        if (!landrate) {
            switch (transformer.PhaseSize) {
                case 1:
                    if (transformer.TransformerRating === 5) landrate = tariff.landrates.DX;
                    if (transformer.TransformerRating === 16) landrate = tariff.landrates[1];
                    break;
                case 2:
                    if (transformer.TransformerRating === 32) landrate = tariff.landrates[1];
                    if (transformer.TransformerRating === 64) landrate = tariff.landrates[2];
                    if (transformer.TransformerRating === 100) landrate = tariff.landrates[3];
                    break;
                case 3:
                    if (transformer.TransformerRating === 25) landrate = tariff.landrates[1];
                    if (transformer.TransformerRating === 50) landrate = tariff.landrates[2];
                    if (transformer.TransformerRating === 100) landrate = tariff.landrates[3];
                    break;
            }
        }
        
        return {
            serviceCharge: landrate?.serviceCharge ?? 0,
            networkCapacity: landrate?.networkCapacity ?? 0,
            networkDemand: landrate?.networkDemand ?? 0,
            ancillaryService: landrate?.ancillaryService ?? 0,
            energyCharge: landrate?.energyCharge ?? 0,
        };
    }

    public static getLPUTariffValues(tariff : ILPUTariff, transformer : ITransformer, date : moment.Moment) : ILPUTariffValues {
        const zone = tariff.zones.find(x => 
            transformer.TransmissionZone &&
            x.lower === transformer.TransmissionZone[0] &&
            x.upper === transformer.TransmissionZone[1]);
        
        const lineVoltageRange = transformer.LineVoltage ?? LINE_VOLTAGES[0];
        const voltage = tariff.voltages.find(x => lineVoltageRange[0] === x.lower && lineVoltageRange[1] === x.upper);
        
        const index = (voltage?.upper ?? 500) <= 500 ? 0 : 1;
        const lowSTD = zone?.lowDemand.standard[index];
        const lowOffPeak = zone?.lowDemand.offPeak[index];
        const lowPeak = zone?.lowDemand.peak[index];
        
        const highSTD = zone?.highDemand.standard[index];
        const highOffPeak = zone?.highDemand.offPeak[index];
        const highPeak = zone?.highDemand.peak[index];

        const highSeason = HIGH_SEASON_MONTHS.includes(date.month() + 1);
        const reactiveEnergyCharge = highSeason ? tariff.reactiveEnergyCharge.high : tariff.reactiveEnergyCharge.low;

        return {
            netwrokCapacity: (lineVoltageRange[0] === LINE_VOLTAGES[0][0] && lineVoltageRange[1] === LINE_VOLTAGES[0][1] ? zone?.netwrokCapacity[0] : zone?.netwrokCapacity[1]) ?? 0,
            networkDemandCharge: voltage?.networkDemandCharge ?? 0,
            ancillaryServiceCharge: voltage?.ancillaryServiceCharge ?? 0,
            lowSTD: lowSTD ?? 0,
            lowOffPeak: lowOffPeak ?? 0,
            lowPeak: lowPeak ?? 0,
            highSTD: highSTD ?? 0,
            highOffPeak: highOffPeak ?? 0,
            highPeak: highPeak ?? 0,
            reactiveEnergyCharge: reactiveEnergyCharge,
        };
    }
}

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createSelector } from 'reselect';
import { IDamAerial } from '../../../@types/model/dam/damAerialPhotos';
import AppFunctionsService from '../../../services/appFunctionsService';

interface IDamAerialPhotoViewProps {
    damAerial : IDamAerial;

    onClick ?: (value : IDamAerial) => void;
}

interface IDamAerialPhotoViewState {
    showDate : boolean;
}

export default class DamAerialPhotoView extends React.PureComponent<IDamAerialPhotoViewProps, IDamAerialPhotoViewState> {
    constructor(props : IDamAerialPhotoViewProps) {
        super(props);
        this.state = {
            showDate: false,
        };
    }

    public readonly onClick = () => {
        if (!this.props.onClick) return;

        this.props.onClick(this.props.damAerial);
    };

    private readonly onMouseEnter = () => {
        this.setState({
            showDate: true,
        });
    };

    private readonly onMouseLeave = () => {
        this.setState({
            showDate: false,
        });
    };

    public readonly getAerialPhoto = (props : IDamAerialPhotoViewProps) => props.damAerial;
    public readonly getUrl = createSelector(
        [this.getAerialPhoto],
        (damAerial) => {
            if (damAerial.documentThumbnailURL) {
                return damAerial.documentThumbnailURL;
            }

            if (damAerial.documentURL) {
                return damAerial.documentURL;
            }
            return '';
        }
    );

    public readonly render = () => {
        const { damAerial } = this.props;
        const { showDate } = this.state;

        const url = this.getUrl(this.props);
        return (
            <div>
                <div className='curp'
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onClick={this.onClick}
                >
                    <Paper className='h225 w290 ml38'>
                        {
                            showDate &&
                            <div className='posr'>
                                <div className='h225 w290 fdc posa file-card-backdrop'>
                                </div>
                                <div className='h225 w290 fdc posa'>
                                    <div className='fdc flx1 jcc aic'>
                                        <Typography className='fs19 fw700 cw jcc aic'>
                                            {AppFunctionsService.formatDateTimeToFileDate(damAerial.date)}
                                        </Typography>
                                        <Typography className='fs19 fw700 cw jcc aic mt15'>
                                            {damAerial.photographer}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        }
                        <img height='225' width='290' src={url} />
                    </Paper>
                </div>
            </div>
        );
    };
}

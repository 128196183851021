import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import React, { ChangeEvent } from 'react';
import Fab from '@material-ui/core/Fab';
import OutsideClicker from '../outsideClicker';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FlowmeterCodeSelector from '../selector/flowmeter/FlowmeterCodeSelector';
import { IFlowmeter } from '../../../@types/model/flowmeter/flowmeter';

interface IFlowmeterPopperProps {
    id ?: string;
    open : boolean;
    anchorEl : HTMLButtonElement | null;
    onChange ?: (name : string | null, code : string | null, division : string | null, manArea : string | null) => void;
    onCancelClick ?: () => void;
    code : string | null;
    name : string | null;
    division : string | null;
    manArea : string | null;
}

interface IFlowmeterFilterPopperState {
    code : string;
    name : string;
    division : string;
    manArea : string;
}

export default class FlowmeterFilterPopper extends React.PureComponent<IFlowmeterPopperProps, IFlowmeterFilterPopperState> {
    constructor(props : IFlowmeterPopperProps) {
        super(props);
        this.state = {
            code: '',
            name: '',
            division: '',
            manArea: '',
        };
    }

    public componentDidUpdate = (prevProps : IFlowmeterPopperProps) => {
        if (!prevProps.open && this.props.open) {
            const {code, name, division, manArea} = this.props;
            this.setState({
                code: code ?? '',
                name: name ?? '',
                division: division ?? '',
                manArea: manArea ?? '',
            });
        }
    };

    private readonly codeChange = (flowmeter ?: IFlowmeter) => {
        this.setState({
            code: flowmeter?.code ?? '',
        });
    };

    private readonly nameChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            name: event.target.value,
        });
    };

    private readonly divisionChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            division: event.target.value,
        });
    };

    private readonly manAreaChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            manArea: event.target.value,
        });
    };


    private readonly onSubmit = (event ?: React.FormEvent<HTMLFormElement>) => {
        
        if (event) {
            event.preventDefault();
        }

        if (!this.props.onChange) return;
        const { code, name, division, manArea} = this.state;

        this.props.onChange(name, code, division, manArea);
    };

    public readonly render = () => {
        const { open, anchorEl, id, onCancelClick } = this.props;
        const { code, name, division, manArea } = this.state;

        return (
            <Popper
                id={`${id}_flowmeter_filter_menu`} open={open} anchorEl={anchorEl} transition
                style={{
                    zIndex: 5,
                }}
                placement='bottom'>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div style={{
                            width: 450,
                            background: '#FFF',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: -5,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}>
                            {
                                open &&
                                <OutsideClicker onClickedOutside={onCancelClick}>
                                    <form onSubmit={this.onSubmit} className='fdc ais'>
                                        <div className='fdr aic jcc'>
                                            <div className='flx1 aifs jcc mt14 pr25'>
                                                <FlowmeterCodeSelector fullWidth value={code} onChange={this.codeChange} />
                                            </div>
                                            <div className={'flx1 aifs pr25'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='name'
                                                        label='Name'
                                                        value={name}
                                                        onChange={this.nameChange}
                                                        margin='normal'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr'>
                                            <div className={'flx1 aifs pr25'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='division'
                                                        label='Division'
                                                        value={division}
                                                        onChange={this.divisionChange}
                                                        margin='normal'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={'flx1 aifs pr25'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='manArea'
                                                        label='Management Area'
                                                        value={manArea}
                                                        onChange={this.manAreaChange}
                                                        margin='normal'
                                                    />
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className='fdr aic pb16'>
                                            <span className='flx1' />
                                            <Fab variant='extended' color='secondary' className='fab cancel' size='small' onClick={onCancelClick} type='button'>
                                                CANCEL
                                            </Fab>
                                            <Fab variant='extended' color='secondary' className='fab save fw600' size='small' type='submit'>
                                                APPLY
                                            </Fab>
                                        </div>
                                    </form>
                                </OutsideClicker>
                            }
                        </div>
                    </Fade>
                )}
            </Popper>
        );
    };
}

import { Checkbox } from '@material-ui/core';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

export type CustomChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

interface ICustomLabelledCheckBoxProps{
    name : string;
    label : string;
    control : Control<any, any>;
    defaultValue : boolean;
    className ?: string;
    disabled ?: boolean;
}

const CustomLabelledCheckBox = (props : ICustomLabelledCheckBoxProps) => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValue}
            render={(x) => (
                <div className='fdr aic'>
                    <Checkbox
                        name={x.field.name}
                        onChange={(e : CustomChangeEvent, checked : boolean) : void => {
                            x.field.onChange(e, checked);
                        }}
                        className={props.className}
                        checked={!!x.field.value}
                        disabled={props.disabled}
                    />
                    <span className={props.disabled ? 'cg3' : ''}>{props.label}</span>
                </div>
            )}
        />
    );
};

export default CustomLabelledCheckBox;
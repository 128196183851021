import React from 'react';
import '../../../../style/transformer/JournalDialog.scss';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { ITransformerInvoice } from '../../../../@types/model/transformer/transformerInvoice';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TransformerJournalHelper, { ITransformerJournal, TransformerJournalType } from '../../../../@types/model/transformer/journal';
import { IAuthState, IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import moment from 'moment';
import generalFunctions from '../../../../store/general/functions';
import TransformerTariffSplitTable from '../../../customComponents/tariffTable/SplitTable';
import lodash from 'lodash';
import { Card } from '@material-ui/core';
import { IMunicipalityInvoice } from '../../../../@types/model/transformer/municipalityInvoice';
import { ISPSInvoice } from '../../../../@types/model/transformer/spsInvoice';

interface ITransformerInvoiceJournalDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    invoices : Array<ITransformerInvoice | IMunicipalityInvoice | ISPSInvoice>;
    type : TransformerJournalType;

    auth : IAuthState;

    onClose ?: () => void;
}

interface ITransformerInvoiceJournalDialogState {
    open : boolean;
    isLoading : boolean;
}

class TransformerInvoiceJournalDialogComponent extends React.PureComponent<ITransformerInvoiceJournalDialogProps, ITransformerInvoiceJournalDialogState> {
    constructor(props : ITransformerInvoiceJournalDialogProps) {
        super(props);
        this.state = {
            isLoading: false,
            open: false,
        };
    }

    private readonly onClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        this.setState({
            open: false,
        });
    };

    private readonly onConfirmClick = async () => {
        this.setState({
            isLoading: true,
        });

        const { auth, type, invoices } = this.props;

        if (!auth.session) return;

        try {
            const lines = TransformerJournalHelper.getSplitLines(invoices);

            const journal : ITransformerJournal = {
                id: '',
                createdBy: auth.session.firebaseUser.uid,
                createdByEmployee: auth.session.employee.EmployeeNumber ?? '',
                createdByName: auth.session.employee.Name,
                createdOn: moment.utc().valueOf(),
                updatedBy: auth.session.firebaseUser.uid,
                updatedByEmployee: auth.session.employee.EmployeeNumber ?? '',
                updatedByName: auth.session.employee.Name,
                updatedOn: moment.utc().valueOf(),
                type,
                invoices,
                journalNumber: '',
                totalAccount: lodash.chain(lines).filter(x => x.description === 'ELECTRICITY').sumBy(x => x.amount).value() * -1,
                totalVat: lodash.chain(lines).filter(x => x.description === 'VAT').sumBy(x => x.amount).value(),
                totalInterest: lodash.chain(lines).filter(x => x.description === 'INTEREST').sumBy(x => x.amount).value(),
                lines,
                exported: false,
                exportedBy: null,
                exportedByEmployeeNumber: null,
                exportedByName: null,
                exportedOn: null,
                isWeb: true,
            };

            await TransformerJournalHelper.create(journal);

            generalFunctions.generalShowSuccessSnackbar('Success');
            this.setState({
                open: false,
            });

            if (this.props.onClose) this.props.onClose();
        } catch (ex) {
            generalFunctions.generalShowError(ex);
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, invoices } = this.props;
        const { open, isLoading } = this.state;

        return (
            <div>
                <Tooltip title='Journal'>
                    <div>
                        <Button disabled={!invoices.length} variant='contained' color='primary' onClick={this.onClick}>
                            <Icon style={{ marginRight: 10 }}><img src={`${ASSET_BASE}/assets/images/icons/submitted.svg`} /></Icon>
                            JOURNAL
                        </Button>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Transformer-Journal-dialog-title'
                    aria-describedby='Transformer-Journal-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                JOURNAL
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc hfill bcg0'>
                        {
                            isLoading &&
                            <div className='fdc flx1 aic jcc'>
                                <CircularProgress />
                            </div>
                        }
                        {
                            !isLoading &&
                            !invoices.some(x => !x.transformer.Company) &&
                            <Card className={'flx1 fdc mt15'}>
                                <TransformerTariffSplitTable invoices={invoices} />
                            </Card>
                        }
                        {
                            !isLoading &&
                            invoices.some(x => !x.transformer.Company) &&
                            <div className={'flx1 fdc mt15'}>
                                <Typography>{invoices.filter(x => !x.transformer.Company).map(x => x.transformer.EPNumber).join(', ')} have missing company data!</Typography>
                            </div>
                        }
                    </DialogContent>
                    <DialogActions className='bcg0'>
                        <OutlinedStadiumButton disabled={isLoading} className='mr16' variant='text' type='reset' onClick={this.onClose}>
                            CANCEL
                        </OutlinedStadiumButton>
                        <StadiumButton variant='contained' disabled={isLoading || invoices.some(x => !x.transformer.Company)} onClick={this.onConfirmClick}>
                            CONFIRM
                        </StadiumButton>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

const TransformerInvoiceJournalDialog = connect(
    mapStateToProps,
)(TransformerInvoiceJournalDialogComponent);

export default TransformerInvoiceJournalDialog;

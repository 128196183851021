import { dispatch, getState } from '../..';
import PumpHelper, { IPump } from '../../../@types/model/borehole/pump';
import GeneralFunctions from '../../general/functions';
import MasterDataPumpActions from './actions';

export default class MasterDataPumpFunctions {
    private static listener : undefined | (() => void);


    public static readonly load = async () => {
        if (MasterDataPumpFunctions.listener) return;
        
        dispatch(MasterDataPumpActions.setLoading(true));
        dispatch(MasterDataPumpActions.set([]));

        try {
            MasterDataPumpFunctions.listener = PumpHelper.collection().onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IPump>) => {
                const state = getState().masterData.pump;

                const list = state.pumps.slice();
            
                const session = getState().auth.session;
                if (!session) return;

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();
                    const index = list.findIndex(n => n.id === item.id);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(MasterDataPumpActions.set(list));
                dispatch(MasterDataPumpActions.setLoading(false));
            }, (ex) => {
                GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
                dispatch(MasterDataPumpActions.setLoading(false));
            }, () => {
                dispatch(MasterDataPumpActions.setLoading(false));
                MasterDataPumpFunctions.listener = undefined;
            });

        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
            dispatch(MasterDataPumpActions.setLoading(false));
        }
    };
}
import React from 'react';
import firebase from '../../../services/firebaseService';
import generalFunctions from '../../../store/general/functions';

interface IFirebaseStorageImageProps {
    excludeThumbnail ?: boolean;
    fileName : string;
    path : string;
    onLoaded ?: (url : string, prevUrl ?: string) => void;
    onClick ?: (url : string) => void;

    url ?: string | null;
    thumbnailUrl ?: string | null;
}

interface IFirebaseStorageImageState {
    isLoading : boolean;
    src : string;
    thumbSrc : string;
}

export default class FirebaseStorageImage extends React.Component<IFirebaseStorageImageProps, IFirebaseStorageImageState> {
    constructor(props : IFirebaseStorageImageProps) {
        super(props);
        this.state = {
            isLoading: true,
            src: props.url ?? '',
            thumbSrc: props.url ?? '',
        };
    }

    public readonly componentDidMount = () => {
        this.loadImage();
    };

    public readonly componentDidUpdate = (prevProps : IFirebaseStorageImageProps) => {
        if (prevProps.fileName !== this.props.fileName) {
            this.loadImage();
        }
    };

    private readonly loadImage = async () => {
        try {
            const prev = this.state.src;
            const { path, fileName, excludeThumbnail, url } = this.props;

            if (url) {

                if (this.props.onLoaded) {
                    this.props.onLoaded(url, prev);
                }

                return;
            }
            const fullPath = `${path}/${fileName}`;
            const thumbPath = `${path}/thumb_${fileName}`;
            const promises = [firebase.storage().ref(fullPath).getDownloadURL()];

            if (!this.props.excludeThumbnail) promises.push(firebase.storage().ref(thumbPath).getDownloadURL());
            const result = await Promise.all(promises);

            this.setState({
                src: result[0],
                thumbSrc: !excludeThumbnail ? result[1] : '',
            });

            if (this.props.onLoaded) {
                this.props.onLoaded(result[0], prev);
            }
        } catch (ex) {
            if (typeof ex === 'object' && ex && 'code' in ex && ex.code === 'storage/object-not-found') return;
            generalFunctions.generalShowError(ex, 'Error loading image');
        } finally {

            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly onClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.state.src);
        }
    };

    public readonly render = () => {
        const { thumbSrc, src } = this.state;
        const { onClick } = this.props;
        return (
            <div className={`ImageArea fdr jcc aic ${!onClick ? '' : 'curp'}`}>
                <img className={'mxhi'} src={!thumbSrc ? src : thumbSrc} onClick={this.onClick} />
            </div>
        );
    };
}

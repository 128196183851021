import { IBaseClass } from '../../@types/model/base';
import lodash from 'lodash';
import { firestore } from 'firebase/app';

export default class FirebaseHelper {
    /**
     * Mutates list by removing, updating or adding item based on snapshot doc changes.
     * @param snapshot Snapshot on which document changes occured
     * @param list List of items to change. This gets mutated!!!!!
     */
    public static firestoreSnapshotDocChanges(snapshot : firebase.firestore.QuerySnapshot<IBaseClass>, list : Array<IBaseClass>) {
        snapshot.docChanges().forEach((docChange) => {
            const index = lodash.findIndex(list, n => n.ref.id === docChange.doc.ref.id);
            const data = docChange.doc.data();

            switch (docChange.type) {
                case 'added':
                    if (index > -1) list.splice(index, 1, data);
                    else list.push(data);
                    break;
                case 'modified':
                    list.splice(index, 1, data);
                    break;
                case 'removed':
                    list.splice(index, 1);
                    break;
            }
        });
    }

    /**
     * Converts unix milliseconds to firestore timestamp.
     * @param millis Unix milliseconds
     */
    public static timestampFromMillis(millis : number) {
        return firestore.Timestamp.fromMillis(millis);
    }

    /**
     * Returns current Timestamp in unix milliseconds
     */
    public static timestampMillisNow() {
        return firestore.Timestamp.now().toMillis();
    }

    /**
     * Return current Timestamp.
     */
    public static timestampNow() {
        return firestore.Timestamp.now();
    }
}

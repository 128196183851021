import lodash from 'lodash';
import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { createSelector } from 'reselect';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import randomColor from 'randomcolor';
import { IBoreholeConstantRateTest } from '../../../../@types/model/borehole/boreholeConstantRateTest';

interface IBoreholeConstantRateEntryNeighbourGraphViewProps {
    constantRateTest : IBoreholeConstantRateTest;
}

interface IBoreholeConstantRateEntryNeighbourGraphViewState {
}

export default class BoreholeConstantRateEntryNeighbourGraphView extends React.Component<IBoreholeConstantRateEntryNeighbourGraphViewProps, IBoreholeConstantRateEntryNeighbourGraphViewState> {
    constructor(props : IBoreholeConstantRateEntryNeighbourGraphViewProps) {
        super(props);
        this.state = {
        };
    }

    public readonly getNeighbouringBoreholes = (props : IBoreholeConstantRateEntryNeighbourGraphViewProps) => props.constantRateTest.neighbouringBoreholes;
    public readonly getData = createSelector(
        [this.getNeighbouringBoreholes],
        (neighbouringBoreholes) => {
            
            return lodash.chain(neighbouringBoreholes)
                .flatMap(x => Object.values(x))
                .filter(x => !!x.time)
                .map(n => ({
                    time: moment.utc(n.time, 'HH:mm').valueOf(),
                    [n.borehole]: n.waterLevel,
                }))
                .value();
        },
    );
    public readonly getBoreholes = createSelector(
        [this.getNeighbouringBoreholes],
        (neighbouringBoreholes) => {
            
            return lodash.chain(neighbouringBoreholes)
                .flatMap(x => Object.values(x))
                .filter(x => !!x.time)
                .groupBy(x => x.borehole)
                .map((n, key) => key)
                .value();
        },
    );

    private readonly getRandomColor = (code : string) => {
        return randomColor({
            seed: code,
            luminosity: 'dark',
        });
    };

    public readonly render = () => {
        const data = this.getData(this.props);
        const boreholes = this.getBoreholes(this.props);

        return (
            <div className='fdc'>
                <Card className={'fdr mt15 bcg0'}>
                    <div className={'flx1 fdc jcc p10 wfill'}>
                        <ResponsiveContainer aspect={5} width={'99%'}>
                            <LineChart className={''} data={data}>
                                <XAxis
                                    domain={['auto', 'auto']}
                                    dataKey='time'
                                    tickFormatter={unixTime => moment(unixTime).format('HH:mm')}
                                    type='number'
                                />
                                <YAxis reversed/>
                                <CartesianGrid />
                                <Legend />
                                <Tooltip
                                    labelFormatter={unixTime => moment(unixTime).format('HH:mm')} 
                                />
                                {
                                    boreholes.map((key) => (
                                        <Line key={key} strokeWidth={3} dataKey={key} type='monotone' stroke={this.getRandomColor(key)} />
                                    ))
                                }
                            </LineChart>
                        </ResponsiveContainer>
                        <Typography className={'fs14 fwb pb10 aic jcc cg3'} color='inherit' variant='subtitle1'>
                            Time (HH:mm)
                        </Typography>
                    </div>
                </Card>
            </div>
        );
    };
}

import React from 'react';
import { createSelector } from 'reselect';
import RiverHelper from '../../../../@types/model/river/river';
import lodash from 'lodash';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

interface IRiverFlowTypeSelectorProps {
    value : string;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : string) => void;
}

interface IRiverFlowTypeSelectorState { }

class RiverFlowTypeSelector extends React.Component<IRiverFlowTypeSelectorProps, IRiverFlowTypeSelectorState> {
    constructor(props : IRiverFlowTypeSelectorProps) {
        super(props);

        this.state = {};
    }   

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? '');
    };

    private readonly getValue = (state : IRiverFlowTypeSelectorState, props : IRiverFlowTypeSelectorProps) => props.value;
    private readonly getRequired = (state : IRiverFlowTypeSelectorState, props : IRiverFlowTypeSelectorProps) => props.required;

    private readonly getFlowTypes = () => RiverHelper.FLOW_TYPES;

    private readonly getFlowTypesForDropdown = createSelector([
        this.getRequired, this.getFlowTypes,
    ], (required, flowTypes) => {
        const dropdownData = lodash.map(flowTypes, (element) => ({ label: element, value: element }));

        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getFlowTypes,
    ], (value, required, flowTypes) => {
        if (value) {
            const flowType = flowTypes.find(element => element === value);

            if (flowType) {
                return { label: flowType, value: flowType };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {

        const { required, className, disabled } = this.props;

        const dropdownData = this.getFlowTypesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'flowType'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option === val}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Flow Types'} />}  
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };

}

export default RiverFlowTypeSelector;
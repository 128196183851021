import React from 'react';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import transformerFunctions from '../../../store/transformer/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FileDropZone from '../../customComponents/FileDropZone';
import LinearProgress from '@material-ui/core/LinearProgress';
import GeneralFunctions from '../../../store/general/functions';

interface IEditTransformerImagesProps {
    transformer ?: ITransformer;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditTransformerImagesState {
    open : boolean;
    transformerImage ?: File;
    poleImage ?: File;
    circuitImage ?: File;

    transformerImageProgress : number;
    poleImageProgress : number;
    curcuitImageProgress : number;

    isUploadingLoading : boolean;
}

class EditTransformerImages extends React.Component<IEditTransformerImagesProps, IEditTransformerImagesState> {
    constructor(props : IEditTransformerImagesProps) {
        super(props);
        this.state = {
            open: false,
            transformerImageProgress: 0,
            poleImageProgress: 0,
            curcuitImageProgress: 0,
            isUploadingLoading: false,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTransformerImagesProps, prevState : IEditTransformerImagesState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                poleImage: undefined,
                transformerImage: undefined,
                circuitImage: undefined,
                transformerImageProgress: 0,
                poleImageProgress: 0,
                curcuitImageProgress: 0,
                isUploadingLoading: false,
            });
        }
    };

    private readonly save = async () => {
        const { poleImage, transformerImage, circuitImage } = this.state;

        if (!this.props.transformer) return;

        this.setState({
            isUploadingLoading: true,
        });

        if (poleImage) {
            try {
                const polePromise = new Promise((res, rej) => {
                    transformerFunctions.uploadSelectedTransformerPoleImage(poleImage, (progress : number) => {
                        if (progress >= 100) {
                            res(undefined);
                        }
                        this.setState({
                            poleImageProgress: progress,
                        });
                    }, (e) => {
                        rej(e);
                    });
                });

                await polePromise;

                GeneralFunctions.generalShowSuccessSnackbar('Pole Image uploaded.');

            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Pole Image upload error.');
            }
        }

        if (transformerImage) {
            try {
                const transformerPromise = new Promise((res, rej) => {
                    transformerFunctions.uploadSelectedTransformerImage(transformerImage, (progress : number) => {
                        if (progress >= 100) {
                            res(undefined);
                        }
                        this.setState({
                            transformerImageProgress: progress,
                        });
                    }, (e) => {
                        rej(e);
                    });
                });

                await transformerPromise;
                GeneralFunctions.generalShowSuccessSnackbar('Transformer Image uploaded.');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Transformer Image upload error.');
            }
        }

        if (circuitImage) {
            try {
                const circuitPromise = new Promise((res, rej) => {
                    transformerFunctions.uploadSelectedCircuitImage(circuitImage, (progress : number) => {
                        if (progress >= 100) {
                            res(undefined);
                        }
                        this.setState({
                            curcuitImageProgress: progress,
                        });
                    }, (e) => {
                        rej(e);
                    });
                });

                await circuitPromise;

                GeneralFunctions.generalShowSuccessSnackbar('Circuit Image uploaded.');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Circuit Image upload error.');
            }
        }

        this.setState({
            isUploadingLoading: false,
        });

        this.onClose();
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading || this.state.isUploadingLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onPoleFileSelected = (poleImage : File) => {
        this.setState({
            poleImage,
        });
    };

    private readonly onTransformerFileSelected = (transformerImage : File) => {
        this.setState({
            transformerImage,
        });
    };

    private onCircuitFileSelected = (circuitImage : File) => {
        this.setState({
            circuitImage,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, transformer } = this.props;
        const { open, isUploadingLoading, transformerImageProgress, poleImageProgress, curcuitImageProgress } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading || isUploadingLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-image-edit-dialog-title'
                    aria-describedby='transformer-image-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Transformer Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading || isUploadingLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdr flx1 hfill'>
                            <div className={'fdc flx1 ais jcc m5'}>
                                <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    POLE
                                </Typography>
                                <FileDropZone fileName={!transformer?.PoleFileName ? '' : transformer.PoleFileName}
                                    path={!transformer?.PoleFileName ? '' : `electricalpoint/${transformer.EPNumber}`}
                                    onFileSelected={this.onPoleFileSelected} />
                                <div className='wfill'>
                                    {
                                        !!poleImageProgress &&
                                        <LinearProgress variant='determinate' value={poleImageProgress} />
                                    }
                                </div>
                            </div>
                            <div className={'fdc flx1 ais jcc m5'}>
                                <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    TRANSFORMER
                                </Typography>
                                <FileDropZone fileName={!transformer?.TransformerFileName ? '' : transformer.TransformerFileName}
                                    path={!transformer?.TransformerFileName ? '' : `electricalpoint/${transformer.EPNumber}`}
                                    onFileSelected={this.onTransformerFileSelected} />
                                <div className='wfill mnh4'>
                                    {
                                        !!transformerImageProgress &&
                                        <LinearProgress variant='determinate' value={transformerImageProgress} />
                                    }
                                </div>
                            </div>
                            <div className={'fdc flx1 ais jcc m5'}>
                                <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    CIRCUIT
                                </Typography>
                                <FileDropZone fileName={!transformer?.CircuitFileName ? '' : transformer.CircuitFileName}
                                    path={!transformer?.CircuitFileName ? '' : `electricalpoint/${transformer.EPNumber}`}
                                    onFileSelected={this.onCircuitFileSelected} />
                                <div className='wfill'>
                                    {
                                        !!curcuitImageProgress &&
                                        <LinearProgress variant='determinate' value={curcuitImageProgress} />
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || isUploadingLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading || isUploadingLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}


export default EditTransformerImages;

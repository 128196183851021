import React from 'react';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

interface IMultiFileDropZoneProps {
    files : Array<File>;
    disabled ?: boolean;

    accept : string;

    onFileSelected ?: (files : Array<File>) => void;
    onFileRemoveClick ?: (name : string) => void;
}

interface IMultiFileDropZoneState {}

export default class MultiFileDropZone extends React.Component<IMultiFileDropZoneProps, IMultiFileDropZoneState> {
    constructor(props : IMultiFileDropZoneProps) {
        super(props);
        this.state = {

        };
    }

    private readonly onFileDrop = (acceptedFiles : Array<File>) => {
        if (this.props.onFileSelected) {
            this.props.onFileSelected(acceptedFiles);
        }
    };

    private readonly onFileRemoveClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        if (this.props.onFileRemoveClick) {
            this.props.onFileRemoveClick(event.currentTarget.value);
        }
    };

    public readonly render = () => {
        const { disabled, accept, files } = this.props;
        return (
            <div className='fdr flx1'>
                <Dropzone onDrop={this.onFileDrop} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <section className='fdc flx1'>
                            {
                                !disabled &&
                                <div className='fdc flx1 p20 bw1d' {...getRootProps()}>
                                    <input disabled={disabled} multiple={false} {...getInputProps()} />
                                    {
                                        !!files.length &&
                                        <div className='fdr flx1 fww pb15'>
                                            {
                                                files.map(n => (
                                                    <div key={n.name} className='fdr aic pr15'>
                                                        <InsertDriveFileIcon className='cp pr5' />
                                                        <Typography className='mw200' variant='body2'>
                                                            {n.name}
                                                        </Typography>
                                                        <IconButton size='small' value={n.name} onClick={this.onFileRemoveClick}>
                                                            <DeleteIcon className='cr' />
                                                        </IconButton>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    }
                                    <div className='fdr flx1 jcc aic'>
                                        <Typography className='cg0' variant='body2'>
                                            DRAG &amp; DROP FILES
                                        </Typography>
                                    </div>
                                </div>
                            }
                        </section>
                    )}
                </Dropzone>
            </div>
        );
    };
}

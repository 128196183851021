import { getState, dispatch } from '..';
import firebase from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../services/firebaseService';
import { mottechSetList, mottechSetLoading, mottechSetSelected, mottechSetAudits, mottechSetAuditLoading, mottechSetInsAuditLoading, mottechSetInsAudits } from './actions';
import lodash from 'lodash';
import generalFunctions from '../general/functions';
import { IMottech, IMottechAudit, IMottechInstallationAudit } from '../../@types/mottech';

const COLLECTION = 'mottech';
const AUDIT_COLLECTION = 'mottech_audit';
const INS_AUDIT_COLLECTION = 'mottech_installation_audit';

let listListener : undefined | (() => void);
let auditListener : undefined | (() => void);
let insAuditListener : undefined | (() => void);

const getList = async (onLoaded ?: () => void) => {
    if (listListener) {
        if (onLoaded) onLoaded();
        return;
    }
    dispatch(mottechSetLoading(true));
    dispatch(mottechSetList([]));

    try {
        listListener = firebaseApp.firestore().collection(COLLECTION).onSnapshot((snapshot : firebase.firestore.QuerySnapshot) => {
            const state = getState().mottech;

            const list = state.mottechs.slice();
            
            const session = getState().auth.session;
            if (!session) return;

            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                const item = f.doc.data() as IMottech;

                if (
                    !session.employee.IsMottechAdmin &&
                    item.Division &&
                    session.employee.Areas.length &&
                    !session.employee.Areas.includes(item.Division)) return;

                const index = lodash.findIndex(list, n => n.Code === item.Code);

                switch (f.type) {
                    case 'added':
                        list.push(item);
                        break;
                    case 'modified':
                        list.splice(index, 1, item);
                        break;
                    case 'removed':
                        list.splice(index, 1);
                        break;
                }
            });

            dispatch(mottechSetList(list));

            if (state.mottech) {
                const id = state.mottech.Code;
                setSelected(list.find(n => n.Code === id), false);
            }

            dispatch(mottechSetLoading(false));
            if (onLoaded) onLoaded(); 
        });

    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar('An error while loading mottechs.');
        dispatch(mottechSetLoading(false));
    }
};

const setSelected = (item ?: IMottech, loadChildren = true) => {
    dispatch(mottechSetSelected(item));

    if (loadChildren || !item) {
        getAudits(item);
        getInsAudits(item);
    }
};

const deactivate = async (id : string) => {
    const auth = getState().auth;

    if (!auth.session || !auth.session.employee.IsMottechAdmin) return;

    dispatch(mottechSetLoading(true));

    try {
        await firebase
            .firestore()
            .collection(COLLECTION)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(auth.session.firebaseUser.uid),
                IsActive: false,
                IsSent: false,
            });

        setSelected();
    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar('An error while deactivating mottech.');
    }

    dispatch(mottechSetLoading(false));
};

const getAudits = (mottech ?: IMottech) => {
    if (auditListener) auditListener();

    dispatch(mottechSetAudits([]));

    if (!mottech) return;

    dispatch(mottechSetAuditLoading(true));
    try {
        auditListener = firebaseApp.firestore().collection(AUDIT_COLLECTION)
            .where('Mottech', '==', firebaseApp.firestore().collection(COLLECTION).doc(mottech.Code))
            .orderBy('CreatedOn', 'desc').onSnapshot((snapshot : firebase.firestore.QuerySnapshot) => {
                const state = getState().mottech;

                const list = state.audits.slice();

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data() as IMottechAudit;

                    const index = lodash.findIndex(list, n => n.Id === item.Id);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(mottechSetAudits(list));

                dispatch(mottechSetAuditLoading(false));
            });

    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar('An error while loading mottech audits.');
        dispatch(mottechSetAuditLoading(false));
    }
};

const getInsAudits = (mottech ?: IMottech) => {
    if (insAuditListener) insAuditListener();

    dispatch(mottechSetAudits([]));

    if (!mottech) return;

    dispatch(mottechSetInsAuditLoading(true));
    try {
        insAuditListener = firebaseApp.firestore().collection(INS_AUDIT_COLLECTION)
            .where('Mottech', '==', firebaseApp.firestore().collection(COLLECTION).doc(mottech.Code))
            .orderBy('CreatedOn', 'desc').onSnapshot((snapshot : firebase.firestore.QuerySnapshot) => {
                const state = getState().mottech;

                const list = state.insAudits.slice();

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data() as IMottechInstallationAudit;

                    const index = lodash.findIndex(list, n => n.Id === item.Id);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(mottechSetInsAudits(list));

                dispatch(mottechSetInsAuditLoading(false));
            });

    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar('An error while loading mottech installation audits.');
        dispatch(mottechSetInsAuditLoading(false));
    }
};

/** Save's mottech.
 * Sets Mottech Loading on Mottech State. Sets IsSent, UpdatedOn and UpdatedBy.
 * Returns true if success, false if failure.
 */
const saveMottech = async (mottech : IMottech) => {

    const session = getState().auth.session;

    if (!session) return;
    dispatch(mottechSetLoading(true));

    const id = mottech.Code;
    mottech.IsSent = false;
    mottech.UpdatedOn = firebase.firestore.Timestamp.now();
    mottech.UpdatedBy = firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid);

    let result = false;
    try {
        await firebase
            .firestore()
            .collection(COLLECTION)
            .doc(id)
            .update(mottech);
        result = true;
    } catch (ex) {
        generalFunctions.generalShowErrorSnackbar(`An error while saving mottech. ${ex}`);
    }
    dispatch(mottechSetLoading(false));
    return result;

};

/**
 * Uploads file to Firebase Selected Mottech's storage location.
 * Firebase function generateThumbnail updates appropriate field
 * once thumbnail is generated.
 * @param file File to upload
 * @param progressCallback callback for updating upload progress
 * @param errorCallback callback on error
 */
const uploadSelectedMottechImage = async (file : File, progressCallback : (progress : number) => void, errorCallback ?: (e : Error) => void) => {
    const mottechState = getState().mottech;

    if (!mottechState.mottech) throw new Error('No selected mottech');

    const unsub = FirebaseService.uploadFileMetaData(mottechState.mottech.Code, 'ImageName', COLLECTION, file, `mottech/${mottechState.mottech.Code}`)
        .on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

            if (progress >= 100) {
                unsub();
            }

            progressCallback(progress);
        }, errorCallback);
};

const mottechFunctions = {
    getList,
    setSelected,
    deactivate,
    saveMottech,
    uploadSelectedMottechImage,
};

export default mottechFunctions;

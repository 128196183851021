import React, { ChangeEvent } from 'react';
import { IRootState, IAuthState, RootAction } from '../../../../@types/redux';
import BoreholeMotorSize from '../../../../@types/model/borehole/motorSize';
import firebaseApp from '../../../../services/firebaseService';
import firebase from 'firebase/app';
import lodash from 'lodash';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Dispatch, bindActionCreators } from 'redux';
import GeneralFunctions from '../../../../store/general/functions';

interface IBoreholeMotorSizeListProps {
    auth : IAuthState;
}

interface IBoreholeMotorSizeListState {
    isLoading : boolean;
    searchText : string;
    motors : Array<BoreholeMotorSize>;

    selected ?: BoreholeMotorSize;
    newSizeText : string;
}

class BoreholeMotorSizeList extends React.PureComponent<IBoreholeMotorSizeListProps, IBoreholeMotorSizeListState> {
    private listener ?: () => void;
    constructor(props : IBoreholeMotorSizeListProps) {
        super(props);
        this.state = {
            isLoading: true,
            searchText: '',
            newSizeText: '',
            motors: [],
        };
    }

    public readonly componentDidMount = () => {
        this.listen();
    };

    public readonly componentWillUnmount = () => {
        if (!this.listener) return;
        this.listener();
    };

    private readonly listen = () => {
        this.listener = firebaseApp.firestore().collection(BoreholeMotorSize.COLLECTION).onSnapshot((snapshot) => {
            const motors = this.state.motors.slice();

            snapshot.docChanges().forEach((f) => {
                const motor = new BoreholeMotorSize(f.doc);
                const index = lodash.findIndex(motors, n => n.ref.id === f.doc.ref.id);

                switch (f.type) {
                    case 'added':
                        motors.push(motor);
                        break;
                    case 'modified':
                        motors.splice(index, 1, motor);
                        break;
                    case 'removed':
                        motors.splice(index, 1);
                        break;
                }
            });

            this.setState({
                motors: lodash.sortBy(motors, p => p.diameter),
                isLoading: false,
            });
        }, (error) => {
            GeneralFunctions.generalShowError(error, 'Error loading motors.');
            this.setState({
                motors: [],
                isLoading: false,
            });
        });
    };

    private readonly onMotorClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selected = this.state.motors.slice().find(n => event.currentTarget.id === n.ref.id);

        if (!selected) return;
        this.setState({
            selected: lodash.cloneDeep(selected), // Using deep clone as this object has functions.
        });
    };

    private readonly onSearchChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            searchText: event.currentTarget.value,
        });
    };

    private readonly onNewSizeChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            newSizeText: event.currentTarget.value,
        });
    };

    private readonly onClearClick = () => {
        this.setState({
            searchText: '',
        });
    };

    private readonly onAddClick = () => {
        if (!this.state.searchText || !parseFloat(this.state.searchText)) return;

        const diameter = parseFloat(this.state.searchText);
        if (lodash.some(this.state.motors, n => n.diameter === diameter)) {
            GeneralFunctions.generalShowErrorSnackbar(`${diameter} inches motor already added.`);
            return;
        }

        const motor = new BoreholeMotorSize();

        motor.ref = firebaseApp.firestore().collection(BoreholeMotorSize.COLLECTION).doc();
        motor.diameter = parseFloat(this.state.searchText);
        motor.sizes = [];

        motor.createdBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session!.firebaseUser.uid);
        motor.createdByEmployee = this.props.auth.session!.employee.EmployeeNumber ?? '';
        motor.createdByName = this.props.auth.session?.employee.Name ?? '';
        motor.createdOn = firebase.firestore.Timestamp.now();
        motor.updatedBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session!.firebaseUser.uid);
        motor.updatedByEmployee = this.props.auth.session!.employee.EmployeeNumber ?? '';
        motor.updatedByName = this.props.auth.session?.employee.Name ?? '';
        motor.updatedOn = firebase.firestore.Timestamp.now();
        motor.save();
    };

    private readonly onAddSizeClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        if (!this.state.newSizeText || !parseFloat(this.state.newSizeText)) return;

        const selected = lodash.cloneDeep(this.state.selected); // Using deep clone as this object has functions.

        if (!selected) return;
        const size = parseFloat(this.state.newSizeText);
        if (lodash.some(selected.sizes, n => n === size)) {
            GeneralFunctions.generalShowErrorSnackbar(`${size} already added.`);
            return;
        }
        const sizes = selected.sizes.slice();
        sizes.push(parseFloat(this.state.newSizeText));

        selected.sizes = sizes.sort();

        this.setState({
            selected,
            newSizeText: '',
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
    };

    private readonly onDeleteSizeClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const selected = lodash.cloneDeep(this.state.selected); // Using deep clone as this object has functions.

        if (!selected) return;

        const sizes = selected.sizes.slice();
        const index = sizes.indexOf(parseFloat(event.currentTarget.value));
        sizes.splice(index, 1);
        selected.sizes = sizes;

        this.setState({
            selected,
            newSizeText: '',
        });

    };

    private readonly onSaveClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        this.save();
    };

    private readonly save = async () => {
        const selected = lodash.cloneDeep(this.state.selected);

        if (!selected) return;

        try {
            selected.updatedBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session!.firebaseUser.uid);
            selected.updatedByEmployee = this.props.auth.session!.employee.EmployeeNumber ?? '';
            selected.updatedByName = this.props.auth.session?.employee.Name ?? '';
            selected.updatedOn = firebase.firestore.Timestamp.now();

            this.setState({
                isLoading: true,
            });
            await selected.save();
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving motor.');
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly getMotors = (props : IBoreholeMotorSizeListProps, state : IBoreholeMotorSizeListState) => state.motors;
    private readonly getSearchText = (props : IBoreholeMotorSizeListProps, state : IBoreholeMotorSizeListState) => state.searchText;

    private readonly getFilteredMotors = createSelector(
        [this.getMotors, this.getSearchText],
        (motors, searchText) => {
            return motors.filter(n => n.diameter.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        },
    );

    private readonly getMotorsTotal = createSelector(
        [this.getMotors],
        (motors) => {
            return motors.length;
        },
    );

    public readonly render = () => {
        const { searchText, isLoading, selected, newSizeText } = this.state;
        const motors = this.getFilteredMotors(this.props, this.state);
        const motorsTotal = this.getMotorsTotal(this.props, this.state);

        return (
            <div className={'fdc ais flx1 p10'}>
                <div className='fdr flx1 hfill'>
                    <div className='fdc flx1 hfill'>
                        <Paper className='fdc flx1 hfill m10'>
                            <Toolbar className={'fdr aic'}>
                                <TextField
                                    value={searchText}
                                    onChange={this.onSearchChanged}
                                    placeholder='Search'
                                    margin='dense'
                                    type='number'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <Tooltip title='Clear'>
                                                <IconButton onClick={this.onClearClick}>
                                                    <Icon className='cr'>close</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                                <Tooltip title='Add'>
                                    <div>
                                        <IconButton color='primary' onClick={this.onAddClick} aria-label='Add'>
                                            <Icon>add</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Toolbar>
                            <Divider />
                            <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                                Total {motorsTotal}
                            </Typography>
                            <Divider />
                            <List className={'fdc flx1 oya'}>
                                <div className='mnh4'>
                                    { isLoading && <LinearProgress />}
                                </div>
                                {
                                    motors.map(n => (
                                        <ListItem button key={n.ref.id} id={n.ref.id} onClick={this.onMotorClick} selected={selected?.ref.id === n.ref.id} >
                                            <ListItemText
                                                primary={`${n.diameter} inches`}
                                            />
                                            <ListItemSecondaryAction>
                                                <Tooltip title='Disable'>
                                                    <IconButton className='cr' value={n.diameter} onClick={this.onDeleteClick} aria-label='Delete'>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Paper>
                    </div>
                    <div className='fdc flx4 hfill'>
                        {
                            !!selected &&
                            <Paper className='fdc flx1 hfill m10'>
                                <Toolbar className={'fdr aic'}>
                                    <Typography variant='h5' color='inherit'>
                                        {selected.diameter} inches Motor
                                    </Typography>
                                    <span className='flx1' />
                                    <TextField
                                        value={newSizeText}
                                        onChange={this.onNewSizeChanged}
                                        placeholder='New Size'
                                        margin='dense'
                                        type='number'
                                        InputProps={{
                                            endAdornment: (
                                                <Tooltip title='Add'>
                                                    <IconButton color='primary' onClick={this.onAddSizeClick}>
                                                        <Icon>add</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                    <span className='flx1' />
                                    <Tooltip title='Save'>
                                        <div>
                                            <IconButton color='primary' onClick={this.onSaveClick} aria-label='Save'>
                                                <Icon>save</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </Toolbar>
                                <Divider />
                                <div className='fdc flx1 hfill'>
                                    <List className='fdc flx1 oya'>
                                        {
                                            selected.sizes.map((size) => (
                                                <ListItem button id={`${size}`} key={size}>
                                                    <ListItemText
                                                        primary={`${size} kW`}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton className='cr'
                                                            value={size} onClick={this.onDeleteSizeClick} aria-label='Delete'>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BoreholeMotorSizeList);

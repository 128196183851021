import { getState, dispatch } from '..';
import firebase from 'firebase/app';
import { pumpingStationSetList, pumpingStationSetLoading, pumpingStationSetSelected } from './actions';
import lodash from 'lodash';
import generalFunctions from '../general/functions';
import PumpingStationHelper, { IPumpingStation } from '../../@types/model/pumpingStation/pumpingStation';
import moment from 'moment';
import { FirebaseService } from '../../services/firebaseService';

export default class PumpingStationFunctions {
    private static listListener ?: () => void;

    public static readonly getList = async (onLoaded ?: () => void) => {
        if (PumpingStationFunctions.listListener) {
            if (onLoaded) onLoaded();
            return;
        }
        dispatch(pumpingStationSetLoading(true));
        dispatch(pumpingStationSetList([]));

        try {
            PumpingStationFunctions.listListener = PumpingStationHelper.listen().onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IPumpingStation>) => {
                const state = getState().pumpingStation;

                const list = state.pumpingStations.slice();
            
                const session = getState().auth.session;
                if (!session) return;

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();

                    if (
                        !session.employee.IsPumpingStationAdmin &&
                        item.division &&
                        session.employee.Areas.length &&
                        !session.employee.Areas.includes(item.division)) return;

                    const index = lodash.findIndex(list, n => n.code === item.code);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(pumpingStationSetList(list));

                if (state.selectedPumpingStation) {
                    PumpingStationFunctions.setSelected(list.find(n => n.code === state.selectedPumpingStation?.code));
                }

                dispatch(pumpingStationSetLoading(false));
                if (onLoaded) onLoaded(); 
            }, (error) => {
                generalFunctions.generalShowErrorSnackbar(error.message ? error.message : 'An error while loading pumping stations.');
            });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading pumping stations.');
            dispatch(pumpingStationSetLoading(false));
        }
    };

    public static readonly savePumpingStation = async (pumpingStation : IPumpingStation) => {

        const session = getState().auth.session;
    
        if (!session) return;
    
        dispatch(pumpingStationSetLoading(true));
        pumpingStation.updatedBy = session.firebaseUser.uid;
        pumpingStation.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStation.updatedByName = session.employee.Name;
        pumpingStation.updatedOn = moment.utc().valueOf();
    
        try {
            await PumpingStationHelper.save(pumpingStation);
    
            return true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving pumping station. ${ex}`);
        } finally {
            dispatch(pumpingStationSetLoading(false));
        }
    
        return false;
    
    };

    public static readonly setSelected = (item ?: IPumpingStation) => {
        dispatch(pumpingStationSetSelected(item));
    };

    public static readonly create = async (name : string) => {
        dispatch(pumpingStationSetLoading(true));

        let result = false;
        try {
            await FirebaseService.createPumpingStation(name);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while creating pumping station. ${ex}`);
        } finally {
            dispatch(pumpingStationSetLoading(false));
        }
        return result;
    };

    public static readonly deactivate = async (pumpingStation : IPumpingStation) => {
        const session = getState().auth.session;

        if (!session) return;

        dispatch(pumpingStationSetLoading(true));

        pumpingStation.updatedOn = moment.utc().valueOf();
        pumpingStation.updatedBy = session.firebaseUser.uid;
        pumpingStation.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStation.updatedByName = session.employee.Name;
        pumpingStation.isActive = false;

        try {
            if (!session.employee.IsPumpingStationAdmin) {
                throw new Error('Admin rights required.');
            }

            await PumpingStationHelper.save(pumpingStation);

            PumpingStationFunctions.setSelected();

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while deactivating the pumping station occurred.');
        } finally {
            dispatch(pumpingStationSetLoading(false));
        }
    };

    public static readonly activate = async (pumpingStation : IPumpingStation) => {
        const session = getState().auth.session;

        if (!session) return;

        dispatch(pumpingStationSetLoading(true));
        
        pumpingStation.updatedOn = moment.utc().valueOf();
        pumpingStation.updatedBy = session.firebaseUser.uid;
        pumpingStation.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStation.updatedByName = session.employee.Name;
        pumpingStation.isActive = true;

        try {
            if (!session.employee.IsPumpingStationAdmin) {
                throw new Error('Admin rights required');
            }

            await PumpingStationHelper.save(pumpingStation);
            
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while activating the pumping station occurred.');
        } finally {
            dispatch(pumpingStationSetLoading(false));
        }
    };
}
import { dispatch, getState } from '../../..';
import PumpingStationFlowmeterMakeHelper, { IPumpingStationFlowmeterMake } from '../../../../@types/model/masterData/pumpingStation/flowMeterMake';
import GeneralFunctions from '../../../general/functions';
import MasterDataPumpingStationFlowmeterMakeActions from './actions';

export default class MasterDataPumpingStationFlowmeterMakeFunctions {
    private static listener : undefined | (() => void);


    public static readonly load = async () => {
        if (MasterDataPumpingStationFlowmeterMakeFunctions.listener) return;
        
        dispatch(MasterDataPumpingStationFlowmeterMakeActions.setLoading(true));
        dispatch(MasterDataPumpingStationFlowmeterMakeActions.set([]));

        try {
            MasterDataPumpingStationFlowmeterMakeFunctions.listener = PumpingStationFlowmeterMakeHelper
                .collection()
                .onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IPumpingStationFlowmeterMake>) => {
                    const state = getState().masterData.pumpingStation.flowmeterMake;

                    const list = state.flowmeterMakes.slice();
                
                    const session = getState().auth.session;
                    if (!session) return;

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const item = f.doc.data();
                        const index = list.findIndex(n => n.id === item.id);


                        switch (f.type) {
                            case 'added':
                                list.push(item);
                                break;
                            case 'modified':
                                list.splice(index, 1, item);
                                break;
                            case 'removed':
                                list.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(MasterDataPumpingStationFlowmeterMakeActions.set(list));
                    dispatch(MasterDataPumpingStationFlowmeterMakeActions.setLoading(false));
                }, (ex) => {
                    GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
                    dispatch(MasterDataPumpingStationFlowmeterMakeActions.setLoading(false));
                }, () => {
                    dispatch(MasterDataPumpingStationFlowmeterMakeActions.setLoading(false));
                    MasterDataPumpingStationFlowmeterMakeFunctions.listener = undefined;
                });

        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
            dispatch(MasterDataPumpingStationFlowmeterMakeActions.setLoading(false));
        }
    };
}
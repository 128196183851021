import { Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../../@types/employee';
import { BoreholeCalibrationFinalStageTimes, BoreholeCalibrationStages, BoreholeCalibrationTimes, IBoreholeCalibration } from '../../../../@types/model/borehole/boreholeCalibration';
import { Transitions } from '../../../customComponents/animations/Transitions';
import EditBoreholeCalibrationStage from '../../dialog/calibration/EditStage';

interface IBoreholeCalibrationEntryTableViewProps {
    calibration : IBoreholeCalibration;
    isLoading : boolean;

    session : IUserSession;
}

interface IBoreholeCalibrationEntryTableViewState {
}

export default class BoreholeCalibrationEntryTableView extends React.Component<IBoreholeCalibrationEntryTableViewProps, IBoreholeCalibrationEntryTableViewState> {
    constructor(props : IBoreholeCalibrationEntryTableViewProps) {
        super(props);
        this.state = {
        };
    }

    public readonly render = () => {
        const { session, calibration, isLoading } = this.props;

        return (
            <div className='fdr mt15'>
                {
                    BoreholeCalibrationStages.map((stage) => (
                        <Card key={`p_${stage}`} className={'fdc bcDarkBlue mr10 pb10'}>
                            <Typography className={'fdr p10 fw500 cw tac'} color='inherit' variant='subtitle1'>
                                <span className='flx1 aic jcc'>
                                    {
                                        stage + 1
                                    }
                                </span>
                                <span className='fdc aic jcc posr'>
                                    <div className='fdc posa mr10'>
                                        {
                                            session.employee.IsBoreholeAdmin &&
                                            <EditBoreholeCalibrationStage stage={stage} boreholeCalibration={calibration}
                                                transition={Transitions.Up} maxWidth={'md'} fullWidth isLoading={isLoading}/>
                                        }
                                    </div>
                                </span>
                            </Typography>
                            <Table className={'bcw'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='p12'>Time</TableCell>
                                        <TableCell className='p12'>Ground Level (m){/* crappy alignment fix*/ }<sup></sup></TableCell>
                                        <TableCell className='p12'>Abstraction Rate (m<sup>3</sup>/h)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        stage !== 4 &&
                                        BoreholeCalibrationTimes.map((time) => {
                                            const entry = calibration.BoreholeCalibrationStages[stage].StageEntries.find(x => x.Time === time);
                                            
                                            return (
                                                <TableRow key={`tr_${time}`}>
                                                    <TableCell className='p12'>{time}</TableCell>
                                                    <TableCell className='p12'>{!entry?.GroundLevel ? '-' : entry.GroundLevel.toFixed(2)}</TableCell>
                                                    <TableCell className='p12'>{!entry?.AbstractionRate ? '-' : entry.AbstractionRate.toFixed(2)}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                    {
                                        stage === 4 &&
                                        BoreholeCalibrationFinalStageTimes.map((time) => {
                                            const entry = calibration.BoreholeCalibrationStages[stage].StageEntries.find(x => x.Time === time);
                                            
                                            return (
                                                <TableRow key={`tr_${time}`}>
                                                    <TableCell className='p12'>{time}</TableCell>
                                                    <TableCell className='p12'>{!entry?.GroundLevel ? '-' : entry.GroundLevel.toFixed(2)}</TableCell>
                                                    <TableCell className='p12'>{!entry?.AbstractionRate ? '-' : entry.AbstractionRate.toFixed(2)}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Card>
                    ))
                }
                <Card key={`p_${5}`} className={'fdc bcGreen pb10'}>
                    <Typography className={'fdr p10 fw500 cw tac'} color='inherit' variant='subtitle1'>
                        <span className='flx1 aic jcc'>
                            Recovery
                        </span>
                        <span className='fdc aic jcc posr'>
                            <div className='fdc posa mr10'>
                                {
                                    session.employee.IsBoreholeAdmin &&
                                    <EditBoreholeCalibrationStage stage={5} boreholeCalibration={calibration}
                                        transition={Transitions.Up} maxWidth={'md'} fullWidth isLoading={isLoading}/>
                                }
                            </div>
                        </span>
                    </Typography>
                    <Table className={'bcw'}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='p12'>Time</TableCell>
                                <TableCell className='p12'>Ground Level (m){/* crappy alignment fix*/ }<sup></sup></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                BoreholeCalibrationTimes.map((time) => {
                                    const entry = calibration.RecoveryEntries.find(x => x.Time === time);
                                    
                                    return (
                                        <TableRow key={`tr_${time}`}>
                                            <TableCell className='p12'>{time}</TableCell>
                                            <TableCell className='p12'>{!entry?.GroundLevel ? '-' : entry.GroundLevel.toFixed(2)}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </Card>
            </div>
        );
    };
}

import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import boreholeFunctions from '../../../store/borehole/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FileDropZone from '../../customComponents/FileDropZone';
import LinearProgress from '@material-ui/core/LinearProgress';
import GeneralFunctions from '../../../store/general/functions';
import { Card } from '@material-ui/core';

interface IEditBoreholeImagesProps {
    borehole : IBorehole;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditBoreholeImagesState {
    open : boolean;
    boreholeImage ?: File;
    epImage ?: File;
    pumpImage ?: File;
    motorImage ?: File;

    boreholeImageProgress : number;
    epImageProgress : number;
    pumpImageProgress : number;
    motorImageProgress : number;

    isUploadingLoading : boolean;
}

export default class EditBoreholeImages extends React.Component<IEditBoreholeImagesProps, IEditBoreholeImagesState> {
    constructor(props : IEditBoreholeImagesProps) {
        super(props);
        this.state = {
            open: false,
            boreholeImageProgress: 0,
            epImageProgress: 0,
            pumpImageProgress: 0,
            motorImageProgress: 0,
            isUploadingLoading: false,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeImagesProps, prevState : IEditBoreholeImagesState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                epImage: undefined,
                boreholeImage: undefined,
                boreholeImageProgress: 0,
                epImageProgress: 0,
                pumpImageProgress: 0,
                motorImageProgress: 0,
                isUploadingLoading: false,
            });
        }
    };

    private readonly save = async () => {
        const { epImage, boreholeImage, motorImage, pumpImage } = this.state;

        this.setState({
            isUploadingLoading: true,
        });

        if (boreholeImage) {
            try {
                await boreholeFunctions.uploadSelectedBoreholeBoreholeImage(boreholeImage, (progress : number) => {
                    this.setState({
                        boreholeImageProgress: progress,
                    });
                });
                GeneralFunctions.generalShowSuccessSnackbar('Borehole image uploaded successfuly');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Borehole image upload failed');
            }
        }

        if (epImage) {
            try {
                await boreholeFunctions.uploadSelectedBoreholeEPImage(epImage, (progress : number) => {
                    this.setState({
                        epImageProgress: progress,
                    });
                });
                GeneralFunctions.generalShowSuccessSnackbar('Borehole image uploaded successfuly');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Borehole image upload failed');
            }
        }

        if (pumpImage) {
            try {
                await boreholeFunctions.uploadSelectedBoreholePumpImage(pumpImage, (progress : number) => {
                    this.setState({
                        epImageProgress: progress,
                    });
                });
                GeneralFunctions.generalShowSuccessSnackbar('Borehole image uploaded successfuly');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Borehole image upload failed');
            }
        }

        if (motorImage) {
            try {
                await boreholeFunctions.uploadSelectedBoreholeMotorImage(motorImage, (progress : number) => {
                    this.setState({
                        epImageProgress: progress,
                    });
                });
                GeneralFunctions.generalShowSuccessSnackbar('Borehole image uploaded successfuly');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Borehole image upload failed');
            }
        }

        this.setState({
            isUploadingLoading: false,
        });

        this.onClose();
    };

    private onSubmit = () => {
        this.save();
    };

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private onClose = () => {
        if (this.props.isLoading || this.state.isUploadingLoading) return;

        this.setState({
            open: false,
        });
    };

    private onEPFileSelected = (epImage : File) => {
        this.setState({
            epImage,
        });
    };

    private onBoreholeFileSelected = (boreholeImage : File) => {
        this.setState({
            boreholeImage,
        });
    };

    private onMotorFileSelected = (motorImage : File) => {
        this.setState({
            motorImage,
        });
    };

    private onPumpFileSelected = (pumpImage : File) => {
        this.setState({
            pumpImage,
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, borehole } = this.props;
        const { open, isUploadingLoading, boreholeImageProgress, epImageProgress, pumpImageProgress,
            motorImageProgress } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading || isUploadingLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-image-edit-dialog-title'
                    aria-describedby='borehole-image-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Borehole Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading || isUploadingLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdr flx1 hfill'>
                            <div className={'fdr ml17 mr17 mb13'}>
                                <Card className={'bcg0 fdc mr30'}>
                                    <div className={'jcc aic wfill bcp h50'}>
                                        <Typography className={'fs14 fw500 cw '}>
                                            BOREHOLE SITE IMAGE
                                        </Typography>
                                    </div>
                                    <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                        <FileDropZone
                                            fileName={!borehole.BoreholeImageFileName ? '' : borehole.BoreholeImageFileName}
                                            path={!borehole.BoreholeImageFileName ? '' : `borehole/${borehole.Code}`}
                                            onFileSelected={this.onBoreholeFileSelected}
                                            url={borehole.BoreholeImageUrl}
                                            thumbnailUrl={borehole.BoreholeImageThumbnailUrl}
                                        />
                                        <div className='wfill mnh4'>
                                            {
                                                !!boreholeImageProgress &&
                                                <LinearProgress variant='determinate' value={boreholeImageProgress} />
                                            }
                                        </div>
                                    </div>
                                </Card>
                                <Card className={'bcg0 fdc mr30'}>
                                    <div className={'jcc aic wfill bcp h50'}>
                                        <Typography className={'fs14 fw500 cw '}>
                                            INSIDE ELECTRICAL BOX IMAGE
                                        </Typography>
                                    </div>
                                    <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                        <FileDropZone
                                            fileName={!borehole.ElectricalBoxImageFileName ? '' : borehole.ElectricalBoxImageFileName}
                                            path={!borehole.ElectricalBoxImageFileName ? '' : `borehole/${borehole.Code}`}
                                            onFileSelected={this.onEPFileSelected}
                                            url={borehole.ElectricalBoxImageUrl}
                                            thumbnailUrl={borehole.ElectricalBoxImageThumbnailUrl}
                                        />
                                        <div className='wfill'>
                                            {
                                                !!epImageProgress &&
                                                <LinearProgress variant='determinate' value={epImageProgress} />
                                            }
                                        </div>
                                    </div>
                                </Card>
                                <Card className={'bcg0 fdc mr30'}>
                                    <div className={'jcc aic wfill bcp h50'}>
                                        <Typography className={'fs14 fw500 cw '}>
                                            PUMP
                                        </Typography>
                                    </div>
                                    <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                        <FileDropZone
                                            fileName={!borehole.PumpImageFileName ? '' : borehole.PumpImageFileName}
                                            path={!borehole.PumpImageFileName ? '' : `borehole/${borehole.Code}`}
                                            onFileSelected={this.onPumpFileSelected}
                                            url={borehole.PumpImageUrl}
                                            thumbnailUrl={borehole.PumpImageThumbnailUrl}
                                        />
                                        <div className='wfill'>
                                            {
                                                !!pumpImageProgress &&
                                                <LinearProgress variant='determinate' value={pumpImageProgress} />
                                            }
                                        </div>
                                    </div>
                                </Card>
                                <Card className={'bcg0 fdc'}>
                                    <div className={'jcc aic wfill bcp h50'}>
                                        <Typography className={'fs14 fw500 cw '}>
                                            MOTOR
                                        </Typography>
                                    </div>
                                    <div className='fdc p15 aic jcc w200 mnh250 flx1'>
                                        <FileDropZone
                                            fileName={!borehole.MotorImageFileName ? '' : borehole.MotorImageFileName}
                                            path={!borehole.MotorImageFileName ? '' : `borehole/${borehole.Code}`}
                                            onFileSelected={this.onMotorFileSelected}
                                            url={borehole.MotorImageUrl}
                                            thumbnailUrl={borehole.MotorImageThumbnailUrl}
                                        />
                                        <div className='wfill'>
                                            {
                                                !!motorImageProgress &&
                                                <LinearProgress variant='determinate' value={motorImageProgress} />
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || isUploadingLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading || isUploadingLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

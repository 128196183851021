import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import PumpingStationAuditHelper, { IPumpingStationAudit } from '../../../@types/model/pumpingStation/pumpingStationAudit';
import PumpingStationExpandableAudit from './PumpingStationExandableAudit';

interface IPumpingStationAuditProps {
    pumpingStation : IPumpingStation;
    isLoading : boolean;
    session : IUserSession;
}

interface IPumpingStationAuditState {
    audits : Array<IPumpingStationAudit>;
    isLoadingAudits : boolean;
}

class PumpingStationAudit extends React.PureComponent<IPumpingStationAuditProps, IPumpingStationAuditState> {
    
    private listener ?: () => void;
    
    constructor(props : IPumpingStationAuditProps) {
        super(props);
        this.state = {
            audits: [],
            isLoadingAudits: true,
        };
    }

    public componentDidMount() {
        
        this.listener = PumpingStationAuditHelper.listen(this.props.pumpingStation.code)
            .where('isActive', '==', true)
            .onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IPumpingStationAudit>) => {
               
                const audits = this.state.audits.slice();

                snapshot.docChanges().forEach((change : firebase.firestore.DocumentChange<IPumpingStationAudit>) => {

                    const index = audits.findIndex(audit => audit.id === change.doc.ref.id);
                    const data = change.doc.data();

                    switch (change.type) {
                   
                        case 'added':
                            if (index > -1) audits.splice(index, 1, data);
                            else audits.push(data);
                            break;
                   
                        case 'modified': 
                            audits.splice(index, 1, data);
                            break;
                   
                        case 'removed': 
                            audits.splice(index, 1);
                            break;
                    }

                });
                
                this.setState({ audits, isLoadingAudits: false });
            });

    }




    public componentWillUnmount() {
        if (this.listener) this.listener();
    }

    public render = () => {
        
        const { audits, isLoadingAudits } = this.state;
        const { isLoading, session } = this.props;

        return (
            <>
                {
                    (isLoading || isLoadingAudits) && (
                        <div className='mnh4'>
                            <LinearProgress />
                        </div>
                    )
                }
                <div className='bcg0 p20 pl30 pr30 hfill oys' style={{ boxSizing: 'border-box'}}>
                    {
                        !audits.length &&
                        !(isLoading || isLoadingAudits) &&
                        <h2>There are no audits.</h2>
                    }
                    {
                        !!audits.length &&
                        !(isLoading || isLoadingAudits) &&
                        audits.map((audit : IPumpingStationAudit) => (
                            <PumpingStationExpandableAudit session={session} pumpingStationAudit={audit} key={audit.id}/>
                        ))
                    }
                </div>
            </>
        );
    };
}

export default PumpingStationAudit;

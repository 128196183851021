import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import React, { ChangeEvent } from 'react';
import Fab from '@material-ui/core/Fab';
import OutsideClicker from '../outsideClicker';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DivisionSelector from '../selector/DivisionSelector';
import BoreholeCodeSelector from '../selector/borehole/BoreholeCodeSelector';

interface IBoreholePopperProps {
    id ?: string;
    open : boolean;
    anchorEl : HTMLButtonElement | null;
    onChange ?: (code : string | null, name : string | null, division : string | null, managementArea : string | null) => void;
    onCancelClick ?: () => void;
    code : string | null;
    name : string | null;
    division : string | null;
    managementArea : string | null;
}

interface IBoreholeFilterPopperState {
    code : string | null;
    name : string | null;
    division : string | null;
    managementArea : string | null;
}

export default class BoreholeFilterPopper extends React.PureComponent<IBoreholePopperProps, IBoreholeFilterPopperState> {
    constructor(props : IBoreholePopperProps) {
        super(props);
        this.state = {
            code: '',
            name: '',
            division: '',
            managementArea: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IBoreholePopperProps) => {
        if (!prevProps.open && this.props.open) {
            const {code, name, division, managementArea} = this.props;
            this.setState({
                code: code,
                name: name ?? '',
                division: division ?? '',
                managementArea: managementArea,
            });
        }
    };

    private readonly onCodeChange = (value ?: string) => {
        this.setState({
            code: value ?? '',
        });
    };

    private readonly onNameChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            name: event.target.value,
        });
    };

    private readonly onDivisionChanged = (value : string) => {
        this.setState({
            division: value,
        });
    };

    private readonly onManAreaChanged = (value : string) => {
        this.setState({
            managementArea: value,
        });
    };

    private readonly onSubmit = (event ?: React.FormEvent<HTMLFormElement>) => {
        
        if (event) {
            event.preventDefault();
        }

        if (!this.props.onChange) return;
        const { code, name, division, managementArea } = this.state;

        this.props.onChange(code, name, division, managementArea);
    };

    public readonly render = () => {
        const { open, anchorEl, id, onCancelClick } = this.props;
        const { code, name, division, managementArea } = this.state;

        return (
            <Popper
                id={`${id}_transformer_filter_menu`} open={open} anchorEl={anchorEl} transition
                style={{
                    zIndex: 5,
                }}
                placement='bottom'>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div style={{
                            width: 362,
                            background: '#FFF',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: -5,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}>
                            {
                                open &&
                                <OutsideClicker onClickedOutside={onCancelClick}>
                                    <form onSubmit={this.onSubmit} className='fdc ais'>
                                        <div className='fdr'>
                                            <div className={'fdr flx1 aifs'}>
                                                <DivisionSelector division={division ?? ''} manArea={managementArea ?? ''} onDivisionChanged={this.onDivisionChanged} onManAreaChanged={this.onManAreaChanged} />
                                            </div>                                       
                                        </div>
                                        <div className='fdr aic'>
                                            <div className='flx1 aifs jcc pt5 ais mb10 pr25'>
                                                <BoreholeCodeSelector value={code ?? ''} onChange={this.onCodeChange} />
                                            </div>
                                            <div className='fdr flx1 aifs pl5 jcc'>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='name'
                                                        label='Name'
                                                        value={name}
                                                        onChange={this.onNameChange}
                                                        margin={'none'}
                                                        className={'pb7'}
                                                    />
                                                </FormControl>
                                            </div>

                                        </div>
                                        <div className='fdr aic pb16'>
                                            <span className='flx1' />
                                            <Fab variant='extended' color='secondary' className='fab cancel' size='small' onClick={onCancelClick} type='button'>
                                                CANCEL
                                            </Fab>
                                            <Fab variant='extended' color='secondary' className='fab save fw600' size='small' type='submit'>
                                                APPLY
                                            </Fab>
                                        </div>
                                    </form>
                                </OutsideClicker>
                            }
                        </div>
                    </Fade>
                )}
            </Popper>
        );
    };
}

import React from 'react';
import { IAuthState, IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import PumpingStationFunctions from '../../store/pumpingStation/functions';
import CustomMapBox, { IMapMarker } from '../customComponents/map/CustomMapBox';
import { AppBar, Drawer, Icon, IconButton, LinearProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import { Transitions } from '../customComponents/animations/Transitions';
import { createSelector } from 'reselect';
import { IPumpingStation } from '../../@types/model/pumpingStation/pumpingStation';
import { PUMPING_STATION_MAP_IMAGES } from '../../appConstants';
import PumpingStationInfo from './Info';

interface IPumpingStationMapProps extends RouteComponentProps<{
    Code : string;
}, Record<string, unknown>, Record<string, unknown>> {
    auth : IAuthState;
    pumpingStations : Array<IPumpingStation>;
    isLoadingPumpingStations : boolean;
}

interface IPumpingStationMapState {
    selectedPumpingStation ? : IPumpingStation;
}

class PumpingStationMap extends React.Component<IPumpingStationMapProps, IPumpingStationMapState> {
    private readonly mapZoom = 8;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];

    constructor(props : IPumpingStationMapProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount = () => {
        PumpingStationFunctions.getList(this.setSelectedPumpingStation);
    }

    private setSelectedPumpingStation : undefined | (() => void) = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const pumpingStationId = urlParams.get('Code') as string;
        this.onMarkerClick(pumpingStationId);

        this.setSelectedPumpingStation = undefined;
    }

    private selectMarker = (pumpingStation : IPumpingStation) => {
        this.setState({
            selectedPumpingStation: pumpingStation,
        });
    }
    
    private onMarkerClick = (id : string) => {
        const pumpingStation = this.props.pumpingStations.slice().find(n => n.code === id);

        if (pumpingStation) {        
            this.selectMarker(pumpingStation);
        }
    }

    private onMoreInfo = () => {
        PumpingStationFunctions.setSelected(Object.assign({}, this.state.selectedPumpingStation));
    }

    private onCloseDrawer = () => {
        this.setState({
            selectedPumpingStation: undefined,
        });
    }

    public getData = (state : IPumpingStationMapState, props : IPumpingStationMapProps) => props.pumpingStations;

    public getFilteredData = createSelector(
        [this.getData],
        (pumpingStations) => {
            return pumpingStations.filter(n => n.geo);
        },
    );

    public readonly getMarkers = createSelector(
        [this.getFilteredData],
        (pumpingStations) => {
            return pumpingStations.map(n => ({
                coordinates: [n.geo?.longitude ?? 0, n.geo?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: n.code,
            } as IMapMarker));
        }
    );

    public render = () => {
        const { isLoadingPumpingStations } = this.props;
        const { selectedPumpingStation } = this.state;
        const pumpingStations = this.getFilteredData(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (<div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Toolbar>
                    <Typography variant='h5' color='inherit'>
                        Pumping Stations
                    </Typography>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className={'fdr pt5'}>
                            <span style={{ paddingRight: '15px' }}><b>Total: </b>{pumpingStations.length}</span>
                        </div>
                    </div>
                </Toolbar>
                <div className='mnh4'>
                    { isLoadingPumpingStations && <LinearProgress />}
                </div>
                <Paper className={'flx1 fdr oxh'}>
                    {
                                            
                        pumpingStations.length > 0 &&
                        <CustomMapBox
                            id='pumpingStation_map'
                            enableAreas
                            geoLocationControl
                            navigationControl
                            showScale
                            layerControls
                            center={this.mapCenter}
                            zoom={this.mapZoom}
                            images={PUMPING_STATION_MAP_IMAGES}
                            onMarkerClick={this.onMarkerClick}
                            markers={markers}
                            selectedMarker={selectedPumpingStation?.code}
                        />
                    }
                </Paper>
            </div>
            <Drawer open={!!selectedPumpingStation} anchor='right' variant='persistent'>
                <div className={'w300 flx1 fdc'}>
                    <AppBar className='posr' position='static'>
                        <Toolbar variant='dense'>
                            <div className='h60' />
                            <IconButton color={'inherit'} onClick={this.onMoreInfo}><Icon color={'inherit'}>more_vert</Icon></IconButton>
                            <Typography color='inherit' variant='h6' className={'flx1 ml10'} noWrap={true}>
                                {!!selectedPumpingStation && selectedPumpingStation.code}
                            </Typography>
                            <IconButton color={'inherit'} onClick={this.onCloseDrawer}><Icon color={'inherit'}>close</Icon></IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        selectedPumpingStation &&
                        <div className='fdc flx1'>
                            <div className='fdr flx1'>
                                <div className={'fdc flx1'}>
                                    <Typography className={'fdr bcDarkBlue fw700 cw p10'} variant='h6'>
                                        SUMMARY
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            Code
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            PumpingStation Name
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedPumpingStation.code}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedPumpingStation.name}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <PumpingStationInfo fullScreen transition={Transitions.Up} />
            </Drawer>
        </div>);
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        pumpingStations: state.pumpingStation.pumpingStations,
        pumpingStation: state.pumpingStation.selectedPumpingStation,
        isLoadingPumpingStations: state.pumpingStation.loading,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(PumpingStationMap));

import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationMotorMounting } from '../../../../@types/model/masterData/pumpingStation/motorMounting';

type MasterDataPumpingStationMotorMountingActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationMotorMountingState {
    motorMountings : Array<IPumpingStationMotorMounting>;
    isLoading : boolean;
}
const initialState = {
    motorMountings: [],
    isLoading: false,
};

export default function motorMountingReducer(state : IMasterDataPumpingStationMotorMountingState = initialState, action : MasterDataPumpingStationMotorMountingActionType) : IMasterDataPumpingStationMotorMountingState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, motorMountings: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

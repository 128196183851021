export enum EnumFileUploadQueueType {
    Borehole,
    BoreholeInsAudit,
    BoreholeSensorAudit,
    BoreholeTest,
    Dam,
    Mottech,
    MottechAudit,
    MottechInsAudit,
    PumpingStation,
    PumpingStationAudit,
    River,
    RiverMonitor,
    Transformer,
    TransformerLpuReading,
    TransformerSpuReading,
    WeatherStation,
    WeatherStationAudit,
    DamVNotch,
    DamWeekly,
    TransformerMunicipalityReading,
    BoreholeVideo,
    BoreholeAgesRecommendation,
    BoreholeCalibration,
    BoreholeConstantRateTest,
    FlowmeterAudit,
    FlowmeterReading,
    DamSafety,
    DamRegistration,
    DamAerial,
    DamInspection,
    TransformerPrepaidReading,
}
import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../../@types/employee';
import { IBoreholeCalibration } from '../../../../@types/model/borehole/boreholeCalibration';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import BoreholeCalibrationEntryGraphView from './CalibrationEntryGraphView';
import BoreholeCalibrationEntryTableView from './CalibrationEntryTableView';

interface IBoreholeBoreholeCalibrationEntryLogViewProps {
    calibration : IBoreholeCalibration;
    isLoading : boolean;

    session : IUserSession;
}

interface IBoreholeBoreholeCalibrationEntryLogViewState {
    viewAsTable : boolean;
}

export default class BoreholeBoreholeCalibrationEntryLogView extends React.Component<IBoreholeBoreholeCalibrationEntryLogViewProps, IBoreholeBoreholeCalibrationEntryLogViewState> {
    constructor(props : IBoreholeBoreholeCalibrationEntryLogViewProps) {
        super(props);
        this.state = {
            viewAsTable: false,
        };
    }

    private readonly onViewAsClick = () => {
        this.setState({
            viewAsTable: !this.state.viewAsTable,
        });
    };

    public readonly render = () => {
        const { calibration, isLoading, session } = this.props;
        const { viewAsTable } = this.state;
        return (
            <div className='fdc'>
                <Card className='fdr aic jcfs p15 bcpd'>
                    <Typography className='aic jcc fs14 fw500 cw'>
                        LOG {!viewAsTable ? 'GRAPH' : 'TABLE'}
                    </Typography>
                    <span className='flx1' />
                    <OutlinedStadiumButton
                        variant={'outlined'}
                        onClick={this.onViewAsClick}
                        className={'cw bocw'}
                    >
                        VIEW AS {viewAsTable ? 'GRAPH' : 'TABLE'}
                    </OutlinedStadiumButton>
                </Card>
                {
                    !viewAsTable &&
                    <BoreholeCalibrationEntryGraphView
                        calibration={calibration}
                        isLoading={isLoading}
                    />
                }
                {
                    viewAsTable &&
                    <BoreholeCalibrationEntryTableView
                        calibration={calibration}
                        isLoading={isLoading}
                        session={session}
                    />
                }
            </div>
        );
    };
}

import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IPumpingStationMotorSize } from '../../../../@types/model/masterData/pumpingStation/motorSize';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import MasterDataPumpingStationMotorSizeFunctions from '../../../../store/masterData/pumpingStation/motorSize/functions';

interface IPumpingStationMotorSizeSelectorProps {
    value : number | null;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : number | null) => void;

    isLoading : boolean;
    motorSizes : Array<IPumpingStationMotorSize>;
}

interface IPumpingStationMotorSizeSelectorState { }

class PumpingStationMotorSizeSelectorComponent extends React.Component<IPumpingStationMotorSizeSelectorProps, IPumpingStationMotorSizeSelectorState> {
    constructor(props : IPumpingStationMotorSizeSelectorProps) {
        super(props);

        this.state = {};
    }

    public componentDidMount() {
        MasterDataPumpingStationMotorSizeFunctions.load();
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value ? parseFloat(value.value) : null);
    };

    private readonly getData = (state : IPumpingStationMotorSizeSelectorState, props : IPumpingStationMotorSizeSelectorProps) => props.motorSizes;
    private readonly getValue = (state : IPumpingStationMotorSizeSelectorState, props : IPumpingStationMotorSizeSelectorProps) => props.value;
    private readonly getRequired = (state : IPumpingStationMotorSizeSelectorState, props : IPumpingStationMotorSizeSelectorProps) => props.required;


    private readonly getMotorSizes = createSelector([
        this.getData,
    ], (motorSizes) => motorSizes.filter(x => x.isActive).map(x => x.size).sort((a, b) => a - b));

    private readonly getMotorSizesForDropdown = createSelector([ 
        this.getRequired, this.getMotorSizes, 
    ], (required, motorSizes) => {
        
        const dropdownData = lodash.map(motorSizes, (element) => ({ label: `${element}`, value: element.toString() }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([ 
        this.getValue, this.getRequired, this.getMotorSizes, 
    ], (value, required, motorSizes) => {
        if (value) {
            const motorSize = motorSizes.find(element => element === value);

            if (motorSize) {
                return { label: `${motorSize}`, value: motorSize.toString() };
            } else {
                return { label: `${value}`, value: value.toString() };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, className, disabled } = this.props;

        const dropdownData = this.getMotorSizesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'motorSize'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => `${option.label} kW`}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Motor Size'} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>  
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return { 
        isLoading: state.masterData.pumpingStation.motorSize.isLoading,
        motorSizes: state.masterData.pumpingStation.motorSize.motorSizes,
    };
};

const PumpingStationMotorSizeSelector = connect(
    mapStateToProps,
)(PumpingStationMotorSizeSelectorComponent);

export default PumpingStationMotorSizeSelector;
import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IRiverMonitorTest } from '../../../@types/model/river/riverMonitorTest';
import { CircularProgress, Typography } from '@material-ui/core';
import { createSelector } from 'reselect';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import AppFunctionsService from '../../../services/appFunctionsService';

interface IRiverMonitorTestYieldsGraphProps {
    monitorTests : Array<IRiverMonitorTest>;
    isLoading : boolean;
}

interface IRiverMonitorTestYieldsGraphState {}

class RiverMonitorTestYieldsGraphComponent extends React.Component<IRiverMonitorTestYieldsGraphProps, IRiverMonitorTestYieldsGraphState> {
    constructor(props : IRiverMonitorTestYieldsGraphProps) {
        super(props);
        this.state = {};
    }

    public readonly getMonitorTests = (props : IRiverMonitorTestYieldsGraphProps) => props.monitorTests;

    public readonly getData = createSelector(
        [this.getMonitorTests],
        (monitorTests) => {
            return monitorTests
                .filter(x => !!x.Flow)
                .map(x => ({
                    Yield: x.Flow,
                    Date: x.Date.toMillis(),
                }))
                .sort((a, b) => a.Date - b.Date);
        }
    );

    public readonly render = () => {
        const { isLoading } = this.props;
        const data = this.getData(this.props);

        return (
            <div className={'fdc flx1 aic jcc p5'}>
                { 
                    isLoading &&
                    <div className={'fdc flx1 aic jcc'}>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !data.length &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Data
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!data.length &&
                    <ResponsiveContainer width='100%' height='90%'>
                        <LineChart 
                            margin={{
                                top: 0,
                                right: 10,
                                bottom: 0,
                                left: 5,
                            }}
                            data={data}>                               
                            <XAxis
                                dataKey='Date'
                                allowDecimals={false}
                                interval={'preserveStart'}
                                type='category'
                                tickFormatter={(x) => `${AppFunctionsService.formatDateTimeToDateOnly(x)}`}
                            />
                            <YAxis
                                padding={{
                                    top: 5,
                                    bottom: 0,
                                }}
                            />
                            <CartesianGrid stroke='#eee'/>
                            <Line
                                type='monotone'
                                dataKey='Yield'
                                strokeWidth={3}
                            />
                            <Tooltip
                                formatter={(x) => `${x} m³/h`}
                                labelFormatter={(x) => `${AppFunctionsService.formatDateTimeToDateOnly(x)}`}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        monitorTests: state.river.monitorTests,
        isLoading: state.river.loadingMonitorTests,
    };
};

const RiverMonitorTestYieldsGraph = connect(
    mapStateToProps,
)(RiverMonitorTestYieldsGraphComponent);

export default RiverMonitorTestYieldsGraph;

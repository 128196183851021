import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import appFunctionsService from '../../../services/appFunctionsService';
import Typography from '@material-ui/core/Typography';
import { IUserSession } from '../../../@types/employee';
import RiverMonitorTestEntryView from './TestEntryView';
import { IRiverMonitorTest } from '../../../@types/model/river/riverMonitorTest';
import { CircularProgress } from '@material-ui/core';
import { StyledExpansionPanel } from '../../customComponents/StyledExpansionPanel';

interface IRiverMonitorTestViewProps {
    tests : Array<IRiverMonitorTest>;
    isLoading : boolean;
    session ?: IUserSession | null;
}

interface IRiverMonitorTestViewState {}

class RiverMonitorTestView extends React.Component<IRiverMonitorTestViewProps, IRiverMonitorTestViewState> {
    constructor(props : IRiverMonitorTestViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { tests, isLoading, session } = this.props;
        return (
            <div className={'fdc flx1 m10'}>
                { 
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    tests.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Data
                        </Typography>
                    </div>
                }
                {
                    tests.map(n => (
                        <StyledExpansionPanel
                            key={n.ref.id}
                            heading={appFunctionsService.formatDateTimeToDateOnly(n.Date)}
                        >
                            <RiverMonitorTestEntryView test={n} isLoading={isLoading} session={session} />
                        </StyledExpansionPanel>
                    ))
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        tests: state.river.monitorTests,
        isLoading: state.river.loadingMonitorTests,
    };
};

export default connect(
    mapStateToProps,
)(RiverMonitorTestView);

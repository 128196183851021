import React from 'react';
import { IUserSession } from '../../../@types/employee';
import DeleteIcon from '@material-ui/icons/Delete';
import { Transitions } from '../../customComponents/animations/Transitions';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import AppFunctionsService from '../../../services/appFunctionsService';
import riverFunctions from '../../../store/river/functions';
import { IRiverMonitorTest } from '../../../@types/model/river/riverMonitorTest';
import EditRiverReadingDialog from '../dialog/EditReadingDialog';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';

interface IRiverMonitorTestEntryViewProps {
    test : IRiverMonitorTest;
    session ?: IUserSession | null;
    isLoading : boolean;
}

interface IRiverMonitorTestEntryViewState {
    showConfirm : boolean;

    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class RiverMonitorTestEntryView extends React.Component<IRiverMonitorTestEntryViewProps, IRiverMonitorTestEntryViewState> {
    constructor(props : IRiverMonitorTestEntryViewProps) {
        super(props);
        this.state = {
            showConfirm: false,
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private readonly onDeleteClick = () => {
        this.setState({ showConfirm: true });
    };
    
    private readonly onCloseConfirm = () => {
        this.setState({ showConfirm: false });
    };
    
    private deleteTest = async (confirmation : boolean) => {
        if (!confirmation) {
            this.onCloseConfirm();
            return;
        }

        await riverFunctions.deactivateRiverMonitorTest(this.props.test.ref.id);

        this.onCloseConfirm();
    };

    private onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public render = () => {
        const { test, session, isLoading } = this.props;
        const { showConfirm, imageSrcs, isCarouselOpen, imageIndex } = this.state;

        return (
            <div className='fdc'>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 bw1 bocg3'}>
                        <AppBar position='static' className='dvblg' elevation={0}>
                            <Toolbar variant='dense'>
                                <Typography variant='h6' className='flx1 cw'>
                                    TEST INFO
                                </Typography>
                                <span className='flx1' />
                                {
                                    session?.employee.IsRiverAdmin &&
                                    <EditRiverReadingDialog
                                        isLoading={isLoading}
                                        transition={Transitions.Up}
                                        maxWidth={'sm'}
                                        fullWidth
                                        riverMonitorTest={test}
                                    />
                                }
                            </Toolbar>
                        </AppBar>
                        <AppBar position='static' className='bcg0' elevation={0}>
                            <Toolbar variant='dense'>
                                <Typography className='w240 cg3 fwm fs16'>
                                    FLOW
                                </Typography>
                                <Typography className='flx1 cg3 fwr fs16'>
                                    {
                                        !test.Flow ? '-' : <span>{test.Flow} m<sup>3</sup>/h</span>
                                    }
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <AppBar position='static' className='bcw' elevation={0}>
                            <Toolbar variant='dense'>
                                <Typography className='w240 cg3 fwm fs16'>
                                    TYPE
                                </Typography>
                                <Typography className='flx1 cg3 fwr fs16'>
                                    {
                                        test.FlowType
                                    }
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <AppBar position='static' className='bcg0' elevation={0}>
                            <Toolbar variant='dense'>
                                <Typography className='w240 cg3 fwm fs16'>
                                    METHOD USED
                                </Typography>
                                <Typography className='flx1 cg3 fwr fs16'>
                                    {
                                        test.IsFlowmeterReading &&
                                        'Flowmeter'
                                    }
                                    {
                                        test.IsVnotchReading &&
                                        'V-Notch'
                                    }
                                    {
                                        !test.IsVnotchReading &&
                                        !test.IsFlowmeterReading &&
                                        test.YieldTested &&
                                        test.Container &&
                                        'Drum Test'
                                    }
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <span className='mr20' />
                    <div className={'fdc flx1 bw1 bocg3'}>
                        <AppBar position='static' className='dvblg' elevation={0}>
                            <Toolbar variant='dense'>
                                <Typography variant='h6' className='flx1 cw'>
                                    TEST INFO
                                </Typography>
                                <span className='flx1' />
                                {
                                    session?.employee.IsRiverAdmin &&
                                    <EditRiverReadingDialog
                                        isLoading={isLoading}
                                        transition={Transitions.Up}
                                        maxWidth={'sm'}
                                        fullWidth
                                        riverMonitorTest={test}
                                    />
                                }
                            </Toolbar>
                        </AppBar>
                        <div className='fdc aic jcc p15'>
                            <FirebaseStorageImage
                                fileName={test.ImageName ?? ''}
                                path={`river/${test.River.id}`}
                                onClick={this.onThumbClick}
                                onLoaded={this.onImageUrlLoaded}
                                url={test.ImageUrl}
                                thumbnailUrl={test.ImageThumbnailUrl}
                            />
                        </div>
                    </div>
                </div>
                <div className='fdr jcfe'>
                    <IconButton onClick={this.onDeleteClick}>
                        <DeleteIcon style={{ fontSize: 30 }} color={'primary'} /> 
                    </IconButton>
                </div>
                <ConfirmDialog
                    open={showConfirm}
                    title={'Delete River Monitor Test'}
                    message={`Are you sure that you want to delete the river monitor test captured on the following date: ${AppFunctionsService.formatDateTimeToDateOnly(test.Date)}`}
                    transition={Transitions.Up}
                    onClose={this.deleteTest}
                />
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

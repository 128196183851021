import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { BOREHOLE_CONTAINERS } from '../../../../appConstants';

interface IBoreholeContainerSelectorProps {
    value ?: number;

    onChange : (value : number) => void;
}

interface IBoreholeContainerSelectorState {}

export default class BoreholeContainerSelector extends React.Component<IBoreholeContainerSelectorProps, IBoreholeContainerSelectorState> {
    constructor(props : IBoreholeContainerSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onBoreholeContainerChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        this.props.onChange(Number(event.target.value));
    };

    public readonly render = () => {
        const { value } = this.props;

        return (
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel htmlFor='value'>Container</InputLabel>
                    <Select
                        fullWidth
                        id='value'
                        value={!value ? '0' : value}
                        onChange={this.onBoreholeContainerChange}>
                        <MenuItem value='0'>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(BOREHOLE_CONTAINERS, n => (
                                <MenuItem key={`${n}_container`} value={`${n}`}>
                                    {n}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        );
    };
}

import { Card, Typography } from '@material-ui/core';
import { FieldProps } from 'formik';
import React from 'react';
import { BoreholeConstantRateTime, BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntryFormValues, IBoreholeConstantRateTimeRecoveryEntryFormValues } from '../../../../@types/model/borehole/boreholeConstantRateTest';
import TextFormField from '../TextFormField';

interface IBoreholeConstantRateEntryFormFieldProps extends FieldProps<Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntryFormValues | IBoreholeConstantRateTimeRecoveryEntryFormValues>> {
    recovery ?: boolean;
}

export default class BoreholeConstantRateEntryFormField extends React.PureComponent<IBoreholeConstantRateEntryFormFieldProps> {
    constructor(props : IBoreholeConstantRateEntryFormFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { field, recovery } = this.props;
        return (
            <div className='fdr mt12'>
                <Card className='fdc flx1'>
                    <div className='fdr bcw h50'>
                        <div className={'fdc flx1 jcc aic bcp h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                TIME
                            </Typography>
                        </div>
                        <span className='w3' />
                        <div className={'fdc flx1 jcc aic dvbg h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                GROUNDWATER LEVEL (m)
                            </Typography>
                        </div>
                        {
                            !recovery &&
                            <>
                                <span className='w3' />
                                <div className={'fdc flx1 jcc aic dvblg h50'}>
                                    <Typography className={'fs14 fw400 cw'}>
                                        ABSTRACTION RATE (m<sup>3</sup>/h)
                                    </Typography>
                                </div>
                            </>
                        }
                    </div>
                    {
                        BoreholeConstantRateTime.map((time, index) => (
                            <div key={index} className={`fdr ais jcc ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                <div className={'fdr flx1 aic jcc pb16 pt16 pr30 pl30'}>
                                    <Typography className={'fs20 fwb'}>
                                        {
                                            time
                                        }
                                    </Typography>
                                </div>
                                <span className='w3 bcw' />
                                <div className={'fdr flx1 ais jcc pb16 pt16 pr30 pl30'}>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={`${field.name}.${time}.waterLevel`}
                                        name={`${field.name}.${time}.waterLevel`}
                                        fullWidth
                                        type='number'
                                    />
                                </div>
                                {
                                    !recovery &&
                                    <>
                                        <span className='w3 bcw' />
                                        <div className={'fdr flx1 ais jcc pb16 pt16 pr30 pl30'}>
                                            <TextFormField
                                                inputProps={{
                                                    className: 'cp fwb',
                                                    style: {
                                                        textAlign: 'center',
                                                    },
                                                }}
                                                id={`${field.name}.${time}.rate`}
                                                name={`${field.name}.${time}.rate`}
                                                fullWidth
                                                required
                                                type='number'
                                            />
                                        </div>
                                    </>
                                }
                                
                            </div>
                        ))
                    }
                </Card>
            </div>
        );
    };
}

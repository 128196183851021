import React from 'react';
import { IMottech } from '../../../@types/mottech';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import moment, { Moment } from 'moment';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import mottechFunctions from '../../../store/mottech/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import MottechTypeSelector from '../../customComponents/selector/mottech/TypeSelector';
import MottechConnectedInput from '../../customComponents/input/mottech/ConnectedInput';

interface IEditMottechInfoProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    mottech ?: IMottech;
}

interface IEditMottechInfoState {
    open : boolean;
    name : string;
    piccoloId : string;
    serialNumber : string;
    insDate ?: Moment;
    mType : string;
    connectedTo : string;
}

export default class EditMottechInfo extends React.Component<IEditMottechInfoProps, IEditMottechInfoState> {
    constructor(props : IEditMottechInfoProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            piccoloId: '',
            serialNumber: '',
            mType: '',
            connectedTo: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditMottechInfoProps, prevState : IEditMottechInfoState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: '',
                piccoloId: '',
                serialNumber: '',
                mType: '',
                connectedTo: '',
            });
        }

        if (!prevState.open && this.state.open && this.props.mottech) {
            this.setState({
                name: this.props.mottech.Name,
                piccoloId: !this.props.mottech.PiccoloId ? '' : this.props.mottech.PiccoloId.toString(),
                insDate: !this.props.mottech.InstallationDate ? undefined : moment(this.props.mottech.InstallationDate.toMillis()),
                serialNumber: !this.props.mottech.SerialNumber ? '' : this.props.mottech.SerialNumber,
                mType: !this.props.mottech.Type ? '' : this.props.mottech.Type,
                connectedTo: !this.props.mottech.ConnectedTo ? '' : this.props.mottech.ConnectedTo,
            });
        }

    };

    private onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private onPiccoloIdChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            piccoloId: event.currentTarget.value,
        });
    };

    private onConnectedToChange = (connectedTo : string) => {
        this.setState({
            connectedTo,
        });
    };

    private onSerialNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            serialNumber: event.currentTarget.value,
        });
    };

    private onInsDateChanged = (momentDate : moment.Moment | null) => {
        if (momentDate) return;
        
        this.setState({
            insDate: moment(momentDate),
        });
    };

    private onMTypeChanged = (mType : string) => {
        this.setState({
            mType,
        });
    };

    private save = async () => {
        const { name, piccoloId, serialNumber, insDate, mType, connectedTo } = this.state;
        if (!this.props.mottech) return;
        if (!name) return;
        if (insDate && !insDate.isValid()) return;

        const mottech = {
            ...this.props.mottech,
        };

        mottech.Name = name;
        mottech.PiccoloId = piccoloId;
        mottech.SerialNumber = serialNumber;
        mottech.InstallationDate = !insDate ? null : firebase.firestore.Timestamp.fromMillis(insDate.valueOf());
        mottech.Type = mType;
        mottech.ConnectedTo = connectedTo;

        const result = await mottechFunctions.saveMottech(mottech);

        if (result) {
            this.onClose();
        }
    };

    private onSubmit = () => {
        this.save();
    };

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, name, piccoloId, serialNumber, insDate, mType, connectedTo } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='mottech-info-edit-dialog-title'
                    aria-describedby='mottech-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Mottech Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='name'
                                            label='Name'
                                            value={name}
                                            onChange={this.onNameChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!name}
                                        />
                                        {
                                            !name &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <MottechTypeSelector value={mType} onChange={this.onMTypeChanged} />
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='piccoloId'
                                            label='Piccolo/PIU Id'
                                            value={piccoloId ? piccoloId : ''}
                                            onChange={this.onPiccoloIdChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='serialNumber'
                                            label='Serial Number'
                                            value={serialNumber}
                                            onChange={this.onSerialNumberChange}
                                            margin='normal'
                                            className={'TextField'}
                                            multiline
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <DatePicker value={insDate} onChange={this.onInsDateChanged} format='YYYY-MM-DD' label='Installation Date'
                                            id='insDate'
                                            className={'TextField'}
                                            margin={'normal'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <MottechConnectedInput id='connectedTo' label='Connected To'
                                        value={connectedTo} onChange={this.onConnectedToChange}/>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

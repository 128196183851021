import React from 'react';
import { IMottech } from '../../../@types/mottech';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import mottechFunctions from '../../../store/mottech/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DivisionSelector from '../../customComponents/selector/DivisionSelector';

interface IEditMottechDivisionInfoProps {
    mottech : IMottech;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditMottechDivisionInfoState {
    open : boolean;
    division : string;
    manArea : string;
}

export default class EditMottechDivisionInfo extends React.Component<IEditMottechDivisionInfoProps, IEditMottechDivisionInfoState> {
    constructor(props : IEditMottechDivisionInfoProps) {
        super(props);
        this.state = {
            open: false,
            division: '',
            manArea: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditMottechDivisionInfoProps, prevState : IEditMottechDivisionInfoState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                division: '',
                manArea: '',
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                division: !this.props.mottech.Division || this.props.mottech.Division === 'No Data' ? '' : this.props.mottech.Division,
                manArea: !this.props.mottech.ManagementArea ? '' : this.props.mottech.ManagementArea,
            });
        }
    };

    private readonly onManAreaChanged = (manArea : string) => {
        this.setState({
            manArea,
        });
    };

    private readonly onDivisionChanged = (division : string) => {
        this.setState({
            division,
        });
    };

    private readonly save = async () => {
        const { division, manArea } = this.state;

        const mottech = {
            ...this.props.mottech,
        };

        mottech.Division = division;
        mottech.ManagementArea = manArea;

        const result = await mottechFunctions.saveMottech(mottech);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, division, manArea } = this.state;
        
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='mottech-division-info-title'
                    aria-describedby='mottech-division-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Mottech Division Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <DivisionSelector division={division} manArea={manArea} onDivisionChanged={this.onDivisionChanged} onManAreaChanged={this.onManAreaChanged} />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

import { Backdrop, Icon } from '@material-ui/core';
import React from 'react';
import StandardFab from '../button/StandardFab';

interface IVideoFileViewProps {
    url : string;
    thumbnailUrl : string;
}

interface IVideoFileViewState {
    open : boolean;
}

export default class VideoFileView extends React.PureComponent<IVideoFileViewProps, IVideoFileViewState> {
    constructor(props : IVideoFileViewProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });
    };

    public readonly onClose = (event : React.MouseEvent) => {
        event.stopPropagation();
        this.close();
    };

    private readonly close = () => {

        this.setState({
            open: false,
        });
    };

    public render = () => {
        const { url, thumbnailUrl } = this.props;
        const { open } = this.state;
        return (
            <div className='curp hfill'
                onClick={this.onClick}
            >
                <img height={'100%'} src={thumbnailUrl} />
                <Backdrop style={{
                    zIndex: 50000,
                    color: '#fff',
                }} open={open} onClick={this.onClose}>
                    <div className='fdc hfill wfill ais'>
                        <div className='fdr'>
                            <span className='flx1' />
                            <div className='posr'>
                                <StandardFab onClick={this.onClose} style={{
                                    top: 15,
                                }}>
                                    <Icon>close</Icon>
                                </StandardFab>
                            </div>
                        </div>
                        <div className='fdc flx1'>
                            {
                                open &&
                                <embed height='100%' width='100%' src={url} />
                            }
                        </div>

                    </div>
                </Backdrop>

            </div>
        );
    };
}

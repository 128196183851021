import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import Dialog from '@material-ui/core/Dialog';
import riverFunctions from '../../store/river/functions';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { IUserSession } from '../../@types/employee';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import Tooltip from '@material-ui/core/Tooltip';
import RiverDetailView from './view/DetailView';
import RiverMonitorTestView from './view/TestView';
import TabViews from '../customComponents/tab/TabViews';
import { IRiver } from '../../@types/model/river/river';
import RiverDivisionInfoView from './view/DivisionInfoView';

interface IRiverInfoProps {
    onClose ?: () => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    river ?: IRiver;
    isLoading : boolean;

    session ?: IUserSession | null;
}

interface IRiverInfoState {
    tabIndex : number;
    showDelete : boolean;
}

class RiverInfo extends React.Component<IRiverInfoProps, IRiverInfoState> {
    constructor(props : IRiverInfoProps) {
        super(props);

        this.state = {
            tabIndex: 0,
            showDelete: false,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IRiverInfoProps>) : void {
        if (this.props.river && prevProps.river?.ref.id !== this.props.river.ref.id) {
            riverFunctions.getMonitorTestList();
        }

        if (!this.props.river && prevProps.river?.ref.id) {
            riverFunctions.getMonitorTestList();
        }
    }

    private readonly onClose = () => {
        riverFunctions.setSelected();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly onDeleteClick = () => {
        this.setState({
            showDelete: true,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.props.river) {
            riverFunctions.deactivate(this.props.river.WRNumber);
        }
        this.setState({
            showDelete: false,
        });
    };

    public readonly render = () => {
        const { river, fullWidth, transition, maxWidth, fullScreen, session, isLoading } = this.props;
        const { tabIndex, showDelete } = this.state;
        return (
            <Dialog
                open={!!river}
                onClose={this.onClose}
                aria-labelledby='river-info-dialog-title'
                aria-describedby='river-info-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}>
                <AppBar className='posr' position='static'>
                    <Toolbar variant='dense'>
                        {
                            river &&
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {river.WRNumber} - {river.RiverName}
                            </Typography>
                        }
                        <span className={'flx1'} />
                        {
                            session?.employee.IsRiverAdmin &&
                            <Tooltip title='Delete'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoading}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        }
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <Tabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                        <Tab label='SUMMARY' value={0} />
                        <Tab label='DIVISION INFO' value={1} />
                        <Tab label='RIVER READINGS' value={2} />
                    </Tabs>
                </AppBar>
                <div className={'fdc flx1 hfill bcg0'}>
                    {
                        river &&
                        session &&
                        <TabViews index={tabIndex}>
                            <RiverDetailView river={river} session={session} />
                            <RiverDivisionInfoView river={river} />
                            <RiverMonitorTestView session={session} />
                        </TabViews>
                    }
                </div>
                <ConfirmDialog open={showDelete} title={!river ? '' : `Confirm Deactivating ${river.WRNumber}`}
                    message={!river ? '' : `Deactivate River ${river.WRNumber}?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
            </Dialog>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        river: state.river.river,
        isLoading: state.river.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(RiverInfo);

import { Field, FieldProps } from 'formik';
import React from 'react';
import { EnumDamHazardPotential } from '../../../../@types/model/dam/dam';
import DamHazardPotentialSelector, { IDamHazardPotentialSelectorProps } from '../../selector/dam/DamHazardPotentialSelector';

type DamHazardPotentialFormFieldPropsType = FieldProps<EnumDamHazardPotential | null> & Omit<IDamHazardPotentialSelectorProps, 'value' | 'onChange'>;

interface IDamHazardPotentialFormFieldProps extends DamHazardPotentialFormFieldPropsType {
    name : string;
}

class DamHazardPotentialFormFieldComponent extends React.PureComponent<IDamHazardPotentialFormFieldProps> {
    constructor(props : IDamHazardPotentialFormFieldProps) {
        super(props);
    }

    private readonly onDamHazardPotentialChange = (value : EnumDamHazardPotential | null) => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, value);
    };

    public readonly render = () => {
        const { field, form: _form, meta: _meta, name: _name, ...rest } = this.props;
        return (
            <DamHazardPotentialSelector
                {...rest}
                value={field.value} 
                onChange={this.onDamHazardPotentialChange}                                        
            />
        );
    };
}

export default class DamHazardPotentialFormField extends React.PureComponent<{name : string; label : string}> {
    constructor(props : {name : string; label : string}) {
        super(props);
    }

    public render = () => {
        const { name, label } = this.props;
        return (
            <Field
                name={name}
                label={label}
                component={DamHazardPotentialFormFieldComponent}                                  
            >
            </Field>
        );
    };
}

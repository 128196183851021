import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import appFunctionsService from '../../../services/appFunctionsService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { IUserSession } from '../../../@types/employee';
import TransformerReadingEntryView from './ReadingEntryView';
import { ITransformerReading } from '../../../@types/model/transformer/transformerReading';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

interface ITransformerReadingViewProps {
    transformerReadings : Array<ITransformerReading>;
    isLoading : boolean;
    session ?: IUserSession | null;
}

interface ITransformerReadingViewState {}

class TransformerReadingView extends React.Component<ITransformerReadingViewProps, ITransformerReadingViewState> {
    constructor(props : ITransformerReadingViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { transformerReadings, isLoading } = this.props;
        return (
            <div className='hfill fdc aifs pt20 pb20 pl20 pr20 bcg0 oya'>
                {
                    isLoading &&
                    <LinearProgress className={'wfill mt5'} />
                }
                {
                    !isLoading && transformerReadings.length === 0 &&
                    <div>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Reading Data Exists!
                        </Typography>
                    </div>
                }
                {
                    transformerReadings.map(n => (
                        <Accordion key={n.ref.id} className={'wfill'}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {
                                    appFunctionsService.formatDateTimeToDateOnly(n.CreatedOn)
                                }
                            </AccordionSummary>
                            <AccordionDetails className={'fdc flx1'}>
                                <TransformerReadingEntryView reading={n} />
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformerReadings: state.transformer.readings,
        isLoading: state.transformer.loadingReadings,
    };
};

export default connect(
    mapStateToProps,
)(TransformerReadingView);

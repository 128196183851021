import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import TransformerHelper, { ITransformerCompany, TransformerMeterTypeUpperCase } from './transformer';

export interface ITransformerGroup extends IBasicBaseClass {
    code : string;
    name : string;
    type : TransformerMeterTypeUpperCase | '';
    company : ITransformerCompany | null;
    paymentEnabled : boolean | null;

    // Transformer Ref
    mainAccount : string | null;

    isActive : boolean;
}

export class TransformerGroupHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_group';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerGroup> = {
        fromFirestore: (snapshot) => {
            return TransformerGroupHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerGroup) : firebase.firestore.DocumentData => {
            return TransformerGroupHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerGroup {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...result,
            name: data.name,
            code: data.code,
            type: data.type,
            company: data.company ?? null,
            paymentEnabled: data.paymentEnabled ?? true,
            mainAccount: (data.mainAccount?.id) ?? null,
            isActive: (data.isActive) ?? true,
        };
    }

    public static toFirestoreDocument(data : ITransformerGroup) {
        const result = super.toFirestore(data);

        return {
            ...result,
            name: data.name,
            code: data.code,
            type: data.type,
            company: data.company ?? null,
            paymentEnabled: data.paymentEnabled,
            mainAccount: !data.mainAccount ? null : TransformerHelper.doc(data.mainAccount),
            isActive: data.isActive,
        };
    }

    public static save(group : ITransformerGroup) {

        return firebaseApp.firestore().runTransaction(async (db) => {
            const doc = await db.get(TransformerGroupHelper.doc(group.id));
            const data = doc.data();

            if (group.mainAccount) {
                const transformerDoc = await db.get(TransformerHelper.doc(group.mainAccount));

                db.set(transformerDoc.ref, {
                    EPGroup: group.name,
                    Company: group.company,
                    Consolidated: true,
                    IsSent: false,
                }, {
                    merge: true,
                });
            } else if (data?.mainAccount) {
                const transformerDoc = await db.get(TransformerHelper.doc(data.mainAccount));

                db.set(transformerDoc.ref, {
                    EPGroup: null,
                    Company: null,
                    Consolidated: false,
                    IsSent: false,
                }, {
                    merge: true,
                });
            }

            db.set(doc.ref, group, {
                merge: true,
            });
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(TransformerGroupHelper.COLLECTION_NAME).withConverter(TransformerGroupHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(TransformerGroupHelper.COLLECTION_NAME).withConverter(TransformerGroupHelper.converter).doc(id);
    }

    public static listen() {
        return firebaseApp
            .firestore()
            .collection(TransformerGroupHelper.COLLECTION_NAME)
            .withConverter(TransformerGroupHelper.converter);
    }
}

import React from 'react';
import { ITransformerGroup } from '../../../../@types/model/transformer/transformerGroup';
import { connect } from 'react-redux';
import { IAuthState, IRootState } from '../../../../@types/redux';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../../../customComponents/materialTable/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TransformerGroupEditDialog from './Edit';
import { Transitions } from '../../../customComponents/animations/Transitions';
import DataFunctions from '../../../../store/data/functions';
import { Icon } from '@material-ui/core';

interface ITransformerGroupListProps {
    auth : IAuthState;
    groups : Array<ITransformerGroup>;
    isLoading : boolean;
}

interface ITransformerGroupListState {
}

class TransformerGroupList extends React.PureComponent<ITransformerGroupListProps, ITransformerGroupListState> {
    constructor(props : ITransformerGroupListProps) {
        super(props);
        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        DataFunctions.getTransformerGroups();
    };

    public readonly render = () => {
        const { auth, isLoading, groups } = this.props;
        
        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<ITransformerGroup>
                            id='transformerGroupsTable'
                            data={groups}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            externalSort
                            columns={[{
                                header: '',
                                field: 'id',
                                width: 50,
                                renderCell: row => (
                                    <div className='aic'>
                                        <TransformerGroupEditDialog auth={auth} group={row} isLoading={isLoading} fullWidth maxWidth='sm' transition={Transitions.Down} />
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Type',
                                field: 'type',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Company',
                                field: 'company.code',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Payment Enabled',
                                field: 'paymentEnabled',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.paymentEnabled ? (<Icon className='cr aic'>close</Icon>) : (<Icon className='cdg aic'>check</Icon>),
                            }, {
                                header: 'Consolidated Group',
                                field: 'mainAccount',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.mainAccount ? (<Icon className='cr aic'>close</Icon>) : (<Icon className='cdg aic'>check</Icon>),
                            }, {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.isActive ? (<Icon className='cr'>close</Icon>) : (<Icon className='cdg'>check</Icon>),
                            }, {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={row.createdByEmployee}>
                                        <div>
                                            {
                                                row.createdByName
                                            }
                                        </div>
                                    </Tooltip>
                                ),
                            }, {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={row.updatedByEmployee}>
                                        <div>
                                            {
                                                row.updatedByName
                                            }
                                        </div>
                                    </Tooltip>
                                ),
                            }]}
                        />
                    </Card>
                    <div className='fdr jcfe'>
                        <TransformerGroupEditDialog auth={auth} isLoading={isLoading} fullWidth maxWidth='sm' transition={Transitions.Down} />
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        isLoading: state.data.isLoadingTransformerGroups,
        groups: state.data.transformerGroups,
    };
};

export default connect(
    mapStateToProps,
)(TransformerGroupList);

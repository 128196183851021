import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_NO_TIME, DATE_FORMAT_DATE_PICKER, DATEPICKER_FORMAT_DEFAULT, TIME_FORMAT_DEFAULT, DATE_FORMAT_MONTH_YEAR, DATE_FILE_UPLOAD_FORMAT, DATE_FORMAT_MMM_DD_HH_MM } from '../appConstants';
import lodash from 'lodash';
import moment, { Moment } from 'moment/moment';
import firebase from 'firebase/app';

export default class AppFunctionsService {
    public static readonly setArrayElement = <T>(arr : null | Array<T>, index : number, value : T) : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];
        if (index === -1) return AppFunctionsService.addArrayElement(arr, value);

        return Object.assign([...arr], { [index]: value });
    }

    public static readonly addArrayElement = <T>(arr : null | Array<T>, value : T, position : 'start' | 'end' = 'start') : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];

        if (position === 'start') {
            return [value, ...arr];
        } else if (position === 'end') {
            return [...arr, value];
        }
        return arr;
    }

    public static readonly removeArrayElement = <T>(arr : null | Array<T>, index : number) : Array<T> => {
        if (arr === undefined) return [];
        if (arr === null) return [];
        if (index === -1) return arr;

        return [...arr.slice(0, index), ...arr.slice(index + 1)];
    }

    public static readonly getIndexOfArrayElement = <T>(arr : null | Array<T> | any, item : any, field : string) => {
        if (arr === undefined) return -1;
        if (arr === null) return -1;
        if (item === undefined || item === null) return -1;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i][field] === item[field]) {
                return i;
            }
        }
        return -1;
    }

    public static readonly sortArray = <T>(arr : null | Array<T> | any, direction : 'asc' | 'desc', field : string) => {
        if (arr === undefined) return -1;
        if (arr === null) return -1;

        arr.sort((a : any, b : any) => {
            if (typeof(a[field]) === 'string') {
                const field1 = !a[field] ? '' : a[field];
                const field2 = !b[field] ? '' : b[field];

                if (direction === 'desc') {
                    return field1.localeCompare(field2);
                } else {
                    return field2.localeCompare(field1);
                }
            } else {
                const field1 = !a[field] ? 0 : a[field];
                const field2 = !b[field] ? 0 : b[field];

                if (direction === 'desc') {
                    return field1 - field2;
                } else {
                    return field2 - field1;
                }
            }
        });

        return arr;
    }

    public static readonly addObjectAttribute = <T>(obj : {[key : string] : T}, key : string, value : T) : {[key : string] : T} => {
        const tempObj : { [key : string] : T } = {};
        tempObj[key] = value;
        return Object.assign({}, obj, tempObj);
    }

    public static readonly removeObjectAttribute = (obj : {[key : string] : any}, key : string) : {[key : string] : any} => {
        return lodash.omit(obj, key);
    }

    public static readonly isEmptyObject = (obj : {[key : string] : any}) : boolean => {
        return !Object.keys(obj).length;
    }

    public static formatDateTime(dateTime : string | number | firebase.firestore.Timestamp | Moment) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment(dateTime.toMillis()).format(DATE_FORMAT_DEFAULT);
        }

        return moment.utc(dateTime).local().format(DATE_FORMAT_DEFAULT);
    }

    public static formatToDatePicker(dateTime : string | firebase.firestore.Timestamp) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment(dateTime.toMillis()).format(DATEPICKER_FORMAT_DEFAULT);
        }
        return moment(dateTime, DATE_FORMAT_DEFAULT_NO_TIME).format(DATEPICKER_FORMAT_DEFAULT);
    }

    public static formatFromDatePicker(dateTime : string) : string {
        return moment(dateTime, DATEPICKER_FORMAT_DEFAULT).format(DATE_FORMAT_DEFAULT_NO_TIME);
    }

    public static formatDateTimeToDateOnly(dateTime : string | number | firebase.firestore.Timestamp | Moment) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment(dateTime.toMillis()).format(DATE_FORMAT_DEFAULT_NO_TIME);
        }

        return moment(dateTime).format(DATE_FORMAT_DEFAULT_NO_TIME);
    }

    public static formatDateTimeToTimeOnly(dateTime : string | number | firebase.firestore.Timestamp | Moment) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment(dateTime.toMillis()).format(TIME_FORMAT_DEFAULT);
        }

        return moment(dateTime).format(TIME_FORMAT_DEFAULT);
    }

    public static formatDateTimeToMonthYearOnly(dateTime : string | number | firebase.firestore.Timestamp | Moment) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).format(DATE_FORMAT_MONTH_YEAR);
        }

        return moment(dateTime).format(DATE_FORMAT_MONTH_YEAR);
    }

    public static formatDateTimeToDatePicker(dateTime : string | number | firebase.firestore.Timestamp | moment.Moment) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).format(DATE_FORMAT_DATE_PICKER);
        }

        return moment(dateTime).format(DATE_FORMAT_DATE_PICKER);
    }

    public static formatDateTimeToMMMDDHHmm(dateTime : string | number | firebase.firestore.Timestamp | moment.Moment) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).format(DATE_FORMAT_MMM_DD_HH_MM);
        }

        return moment.utc(dateTime).local().format(DATE_FORMAT_MMM_DD_HH_MM);
    }
    
    public static formatDateTimeToFileDate(dateTime : string | number | firebase.firestore.Timestamp) : string {
        if (dateTime instanceof firebase.firestore.Timestamp) {
            return moment.utc(dateTime.toMillis()).format(DATE_FILE_UPLOAD_FORMAT);
        }

        return moment(dateTime).format(DATE_FILE_UPLOAD_FORMAT);
    }

    public static dateDifferenceMillis(start : number, end : number) : moment.Duration {
        const startDate = moment(start);
        const endDate = moment(end);

        return moment.duration(endDate.diff(startDate));
    }

    public static formatSurnameAndInitials(surname : string, name : string) : string {
        const lastname = surname ? AppFunctionsService.toTitleCase(surname) : '';
        const names = name ? name : '';
        const initials = names.split(' ').map(value => value.charAt(0));
        return lastname + ' ' + initials.toString().replace(/,/g, '');
    }

    public static toTitleCase(value : string) : string {
        const words = value.split(' ');
        if (!words.length) return '';

        return words
            .filter(word => !!word)
            .map(word => word[0].toUpperCase() + word.toLowerCase().slice(1))
            .toString()
            .replace(/,/g, ' ');
    }

    /* removes PR from EmployeeNumber */
    public static readonly trimEmployeeNumber = (employeeNumber : string) : string => {
        if (employeeNumber.includes('PR')) {
            return employeeNumber.replace('PR', '');
        }
        return employeeNumber;
    }

    /* adds PR and leading zeros to EmployeeNumber */
    public static readonly expandEmployeeNumber = (employeeNumber : string) : string => {
        let expandedEmployeeNumber : string = employeeNumber;
        /* First Ensure that the string does not aleady contain 'PR' */
        if (employeeNumber.includes('PR')) {
            expandedEmployeeNumber = AppFunctionsService.trimEmployeeNumber(expandedEmployeeNumber);
        }
        /* add PR and return */
        return 'PR' + expandedEmployeeNumber;
    }

    public static readonly wait = (seconds : number) => {
        return new Promise((res) => {
            setTimeout(() => {
                res(null);
            }, seconds * 1000);
        });
    }

    public static readonly parseParams = (params : any) => {
        const keys = Object.keys(params);
        let options = '';

        keys.forEach((key) => {
            const isParamTypeObject = typeof params[key] === 'object';
            const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

            if (!isParamTypeObject) {
                options += `${key}=${params[key]}&`;
            }

            if (isParamTypeObject && isParamTypeArray) {
                params[key].forEach((element : any) => {
                    options += `${key}=${element}&`;
                });
            }
        });

        return options ? options.slice(0, -1) : options;
    }

    public static readonly getNestedObject = (nestedObj : { [key : string] : any }, pathArr : Array<string>) => {
        return pathArr.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    }

    public static readonly getField = <T>(row : T, field : string) => {
        return AppFunctionsService.getNestedObject(row, field.split('.'));
    }

    public static readonly removeUndefined = (obj : any) => {
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object') {
                AppFunctionsService.removeUndefined(obj[key]);
            }

            if (typeof obj[key] === 'undefined') {
                delete obj[key];
            }
        });
    }

    public static readonly getTransmissionZoneLabel = (zone : [number, number]) => {
        let label = '';

        if (zone[0]) {
            label += `> ${zone[0]} KM${!zone[1] ? '' : ' & '}`;
        }

        if (zone[1]) {
            label += `≤ ${zone[1]} KM`;
        }

        return label;
    }

    public static readonly getLineVoltageRangeLabel = (range : [number, number]) => {
        let label = '';

        if (range[0]) {
            label += `≥ ${range[0]} V${!range[1] ? '' : ' & '}`;
        }

        if (range[1]) {
            label += `≤ ${range[1] / 1000} kV`;
        }

        return label;
    }

    public static readonly getLandrateLabel = (rate : number) => {
        return `Landrate ${!rate ? 'DX' : rate}`;
    }
}

import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';
import { createSelector } from 'reselect';
import CloseIcon from '@material-ui/icons/Close';
import Input from '@material-ui/core/Input';
import ChemicalFillingPointFilterButton from '../../button/ChemicalFillingStationFilterButton';

interface IChemicalFillingPointFilterSelectorProps {
    id ?: string;
    fullWidth ?: boolean;
    required ?: boolean;

    label : string;
    disabled ?: boolean;

    code : string | null;
    name : string | null;
    division : string | null;
    managementArea : string | null;

    onChange ?: (
        code : string | null,
        name : string | null,
        division : string | null,
        managementArea : string | null,
    ) => void;
}

interface IChemicalFillingPointFilterSelectorState {
    open : boolean;
}

export default class ChemicalFillingPointFilterSelector extends React.PureComponent<IChemicalFillingPointFilterSelectorProps, IChemicalFillingPointFilterSelectorState> {
    constructor(props : IChemicalFillingPointFilterSelectorProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    private readonly onFilterIconButtonClick = () => {
        this.toggleOpen(!this.state.open);
    };

    private readonly onFilterFocusClick = () => {
        this.toggleOpen(true);
    };

    private readonly onClearClick = () => {
        this.onChange(null, null, null, null,);
    };

    public readonly onChange = (
        code : string | null,
        name : string | null,
        division : string | null,
        managementArea : string | null,
    ) => {
        this.setState({
            open: false,
        });

        if (!this.props.onChange) return;
        this.props.onChange(code, name, division, managementArea);

    };

    private readonly toggleOpen = (open : boolean) => {
        this.setState({
            open,
        });
    };

    private readonly getCode = (props : IChemicalFillingPointFilterSelectorProps) => props.code;
    private readonly getName = (props : IChemicalFillingPointFilterSelectorProps) => props.name;
    private readonly getDivision = (props : IChemicalFillingPointFilterSelectorProps) => props.division;
    private readonly getManagementArea = (props : IChemicalFillingPointFilterSelectorProps) => props.managementArea;

    private readonly getLabel = createSelector(
        [
            this.getCode,
            this.getName,
            this.getDivision,
            this.getManagementArea,
        ],
        (code, name, division, managementArea) => {
            const result = [];

            if (code) result.push(code);
            if (name) result.push(name);
            if (division) result.push(division);
            if (managementArea) result.push(managementArea);

            return result.join(' - ');
        },
    );

    public readonly render = () => {
        const {
            required,
            fullWidth,
            label,
            id,
            code,
            name,
            division,
            managementArea,
            disabled,
        } = this.props;
        const { open } = this.state;

        const value = this.getLabel(this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !code} required={required}>
                <InputLabel htmlFor={id}>{label}</InputLabel>
                <Input
                    onFocus={this.onFilterFocusClick}
                    onChange={undefined}
                    value={value}
                    readOnly
                    endAdornment={
                        <InputAdornment position='end'>
                            {
                                (code || name || division || managementArea) &&
                                <IconButton
                                    aria-label='clear filter'
                                    onClick={this.onClearClick}
                                >
                                    <CloseIcon className='cr' />
                                </IconButton>
                            }
                            <ChemicalFillingPointFilterButton
                                color='primary'
                                id='ChemicalFillingPoints_filter'
                                open={open}
                                onClick={this.onFilterIconButtonClick}
                                onChange={this.onChange}
                                name={name}
                                code={code}
                                division={division}
                                managementArea={managementArea}
                                disabled={disabled}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
        );
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import firebaseApp from '../../../../services/firebaseService';
import BoreholeMotorSize from '../../../../@types/model/borehole/motorSize';

interface IMotorSizeSelectorProps {
    diameter : number;
    size : number;

    onDiameterChange : (diameter : number) => void;
    onSizeChange : (size : number) => void;
}

interface IMotorSizeSelectorState {
    isLoading : boolean;
    motors : Array<BoreholeMotorSize>;
}

export default class MotorSizeSelector extends React.Component<IMotorSizeSelectorProps, IMotorSizeSelectorState> {
    private listener ?: () => void;
    constructor(props : IMotorSizeSelectorProps) {
        super(props);
        this.state = {
            isLoading: true,
            motors: [],
        };
    }

    public readonly componentDidMount = () => {
        this.listen();
    };

    public readonly componentDidUpdate = (prevProps : Readonly<IMotorSizeSelectorProps>) => {
        if (prevProps.diameter !== this.props.diameter) {
            this.props.onSizeChange(0);
        }
    };

    public readonly componentWillUnmount = () => {
        if (!this.listener) return;
        this.listener();
    };

    private readonly listen = () => {
        this.listener = firebaseApp.firestore().collection(BoreholeMotorSize.COLLECTION).onSnapshot((snapshot) => {
            // Use deep clone here as there are arrays in object.
            const motors = lodash.cloneDeep(this.state.motors);

            snapshot.docChanges().forEach((f) => {
                const motor = new BoreholeMotorSize(f.doc);
                const index = lodash.findIndex(motors, n => n.ref.id === f.doc.ref.id);

                switch (f.type) {
                    case 'added':
                        motors.push(motor);
                        break;
                    case 'modified':
                        motors.splice(index, 1, motor);
                        break;
                    case 'removed':
                        motors.splice(index, 1);
                        break;
                }
            });

            this.setState({
                motors,
                isLoading: false,
            });
        });
    };

    private readonly onDiameterChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        this.props.onDiameterChange(Number(event.target.value));
    };

    private readonly onSizeChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        this.props.onSizeChange(Number(event.target.value));
    };

    public readonly getData = (state : IMotorSizeSelectorState, props : IMotorSizeSelectorProps) => props.diameter;
    public readonly getMotors = (state : IMotorSizeSelectorState) => state.motors;
    public readonly getSize = (state : IMotorSizeSelectorState, props : IMotorSizeSelectorProps) => props.size;

    public readonly getAllMotors = createSelector(
        [this.getData, this.getMotors],
        (diameter, motors) => {
            const result : Record<string, Array<number> | null> = {};
            motors.forEach((n) => {
                result[n.diameter] = n.sizes.slice();
            });

            if (diameter && !result[diameter]) {
                result[diameter] = [];
            }

            return result;
        },
    );

    public readonly getSizes = createSelector(
        [this.getData, this.getAllMotors, this.getSize],
        (diameter, motors, size) => {
            const currentSizes = motors[diameter];
            if (!currentSizes) return [];
            const sizes = [...currentSizes];

            if (size && !sizes.includes(size)) {
                sizes.push(size);
            }

            return sizes;
        },
    );

    public readonly render = () => {
        const { diameter, size } = this.props;

        const motors = this.getAllMotors(this.state, this.props);
        const types = this.getSizes(this.state, this.props);

        return (
            <>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel shrink={!!diameter} htmlFor='diameter'>Motor Diameter</InputLabel>
                        <Select
                            fullWidth
                            id='diameter'
                            value={!diameter ? '' : diameter}
                            onChange={this.onDiameterChange}>
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            {
                                lodash.map(motors, (n, i) => (
                                    <MenuItem key={`${i}_diameter`} value={`${i}`}>
                                        {i} inches
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel shrink={!!size} htmlFor='size'>Motor Size</InputLabel>
                        <Select
                            fullWidth
                            id='size'
                            value={!size ? '' : size}
                            onChange={this.onSizeChange}>
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            {
                                lodash.map(types, (n, i) => (
                                    <MenuItem key={`${i}_size`} value={`${n}`}>
                                        {n} kW
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </>
        );
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import MasterDataPumpFunctions from '../../../../store/masterData/pump/functions';
import { IPump } from '../../../../@types/model/borehole/pump';

interface IPumpSelectorProps {
    pump : string;
    pumpType : string;

    onPumpChange : (pump : string) => void;
    onPumpTypeChange : (pumpType : string) => void;

    isLoading : boolean;
    pumps : Array<IPump | null>;
}

interface IPumpSelectorState {
}

class PumpSelectorComponent extends React.Component<IPumpSelectorProps, IPumpSelectorState> {
    constructor(props : IPumpSelectorProps) {
        super(props);
        this.state = {
            isLoading: true,
            pumps: [],
        };
    }

    public readonly componentDidMount = () => {
        MasterDataPumpFunctions.load();
    };

    public readonly componentDidUpdate = (prevProps : Readonly<IPumpSelectorProps>) => {
        if (prevProps.pump !== this.props.pump) {
            this.props.onPumpTypeChange('');
        }
    };

    private readonly onPumpChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        this.props.onPumpChange(event.target.value as string);
    };

    private readonly onPumpTypeChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        this.props.onPumpTypeChange(event.target.value as string);
    };

    public readonly getData = (state : IPumpSelectorState, props : IPumpSelectorProps) => props.pump;
    public readonly getPumps = (state : IPumpSelectorState, props : IPumpSelectorProps) => props.pumps;
    public readonly getPumpType = (state : IPumpSelectorState, props : IPumpSelectorProps) => props.pumpType;

    public readonly getAllPumps = createSelector(
        [this.getData, this.getPumps],
        (pumpString, pumpList) => {
            const pumps : Record<string, Array<string> | null> = {};
            pumpList.forEach((n) => {
                if (!n) return;
                pumps[n.name] = n.types.slice();
            });

            // Some pumps are Unidentified.
            pumps['Unidentified'] = [];
            if (pumpString && !pumps[pumpString]) {
                pumps[pumpString] = [];
            }

            return pumps;
        },
    );

    public readonly getTypes = createSelector(
        [this.getData, this.getAllPumps, this.getPumpType],
        (pumpString, pumps, type) => {
            const currentTypes = pumps[pumpString];
            if (!currentTypes) return [];
            const types = [...currentTypes];

            if (type && !types.includes(type)) {
                types.push(type);
            }

            return types;
        },
    );

    public readonly render = () => {
        const { pump, pumpType, isLoading } = this.props;

        const pumps = this.getAllPumps(this.state, this.props);
        const types = this.getTypes(this.state, this.props);

        return (
            <div className={'fdc flx1 ais mb10 p5'}>
                <div className={'fdr flx1 ais'}>
                    <div className={'flx1 ais pr20'}>
                        <FormControl margin='normal' fullWidth>
                            <InputLabel shrink={!!pump} htmlFor='pump'>Pump</InputLabel>
                            <Select
                                fullWidth
                                id='pump'
                                value={!pump ? '' : pump}
                                onChange={this.onPumpChange}>
                                <MenuItem value=''>
                                    <em>None</em>
                                </MenuItem>
                                {
                                    lodash.map(pumps, (value, key) => (
                                        <MenuItem key={`${key}_pump`} value={`${key}`}>
                                            {key}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className={'flx1 ais'}>
                        <FormControl margin='normal' fullWidth>
                            <InputLabel shrink={!!pumpType} htmlFor='pumpType'>Pump Type</InputLabel>
                            <Select
                                fullWidth
                                id='pumpType'
                                value={!pumpType ? '' : pumpType}
                                onChange={this.onPumpTypeChange}>
                                <MenuItem value=''>
                                    <em>None</em>
                                </MenuItem>
                                {
                                    lodash.map(types, (n, i) => (
                                        <MenuItem key={`${i}_pumpType`} value={`${n}`}>
                                            {n}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {
                    isLoading &&
                    <LinearProgress />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        pumps: state.masterData.pump.pumps,
        isLoading: state.borehole.loading,
    };
};

const PumpSelector = connect(
    mapStateToProps,
)(PumpSelectorComponent);

export default PumpSelector;

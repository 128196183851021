import { IChemicalFillingPoint } from '../../../@types/model/fillingPoint/chemical';
import FillingPointChemicalActions from './actions';
import { ActionType, getType } from 'typesafe-actions';

export interface IFillingPointChemicalState {
    points : Array<IChemicalFillingPoint>;
    isLoading : boolean;
    selectedGuid ?: string;
}

const initialState = {
    points: [],
    isLoading: false,
};

export default function chemicalFillingPointReducer(state : IFillingPointChemicalState = initialState, action : ActionType<typeof FillingPointChemicalActions>) : IFillingPointChemicalState {
    switch (action.type) {
        case getType(FillingPointChemicalActions.setList):
            return { ...state, points: action.payload };
        case getType(FillingPointChemicalActions.setLoading):
            return { ...state, isLoading: action.payload };
        case getType(FillingPointChemicalActions.setSelected):
            return { ...state, selectedGuid: action.payload };
        default:
            return state;
    }
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import defaultTheme from '../../../material';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';
import { IBoreholeInstallationAudit } from '../../../@types/model/borehole/boreholeInstallationAudit';

interface IBoreholeAuditEntryViewProps {
    installationAudit : IBoreholeInstallationAudit;
}

interface IBoreholeAuditEntryViewState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class BoreholeAuditEntryView extends React.Component<IBoreholeAuditEntryViewProps, IBoreholeAuditEntryViewState> {
    constructor(props : IBoreholeAuditEntryViewProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private readonly onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { installationAudit } = this.props;
        const { imageSrcs, imageIndex, isCarouselOpen } = this.state;
        return (<div className='fdc'>
            {
                installationAudit.Unequipped &&
                <div className={'p20 flx1 fdc'}>
                    <Typography className={'bbw1 mb10'} color='inherit' variant='h5' style={{ borderColor: defaultTheme.palette.primary.main }}>
                        Summary
                    </Typography>
                    <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                        <div className='DisplayField'>
                            Unequipped
                        </div>
                    </Typography>
                </div>
            }
            {
                installationAudit.Disconnected &&
                <div className={'p20 flx1 fdc'}>
                    <Typography className={'bbw1 mb10'} color='inherit' variant='h5' style={{ borderColor: defaultTheme.palette.primary.main }}>
                        Summary
                    </Typography>
                    <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                        <div className='DisplayField'>
                            Disconnected
                        </div>
                    </Typography>
                </div>
            }
            {
                !installationAudit.Unequipped && !installationAudit.Disconnected &&
                <div className='fdc'>
                    <div className={'p20 flx1 fdc'}> {/* Summary Section */}
                        <Typography className={'bbw1 mb10'} color='inherit' variant='h5' style={{ borderColor: defaultTheme.palette.primary.main }}>
                            Summary
                        </Typography>
                        <Typography color='inherit' variant='subtitle1'><br /></Typography>
                        <div className={'fdr mt10'}>
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Concrete Block Height
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Piezometer Tube Height
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Test Valve
                            </Typography>
                        </div>
                        <div className={'fdr mb10'}>
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayField'>
                                    {installationAudit.ConcreteBlockHeight ? installationAudit.ConcreteBlockHeight + ' mm' : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.PiezometerTubeHeight ? installationAudit.PiezometerTubeHeight + ' mm' : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.TestValve ? installationAudit.TestValve + ' mm' : 'None'}
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr mt10'}>
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Casing Diameter
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Casing Height
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Starter
                            </Typography>
                        </div>
                        <div className={'fdr mb10'}>
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayField'>
                                    {installationAudit.CasingInnerDiameter ? installationAudit.CasingInnerDiameter + ' mm' : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.CasingHeight ? installationAudit.CasingHeight + ' cm' : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.Starter ? installationAudit.Starter : 'None'}
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr mt10'}>
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Starter Type
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Pipe Length
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Cable Cores
                            </Typography>
                        </div>
                        <div className={'fdr mb10'}>
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.StarterType ? installationAudit.StarterType : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.PipeLength ? installationAudit.PipeLength + ' m' : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.CableCores ? installationAudit.CableCores : 'None'}
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr mt10'}>
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Cable Size
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Cable Length
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Pump
                            </Typography>
                        </div>
                        <div className={'fdr mb10'}>
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayField'>
                                    {installationAudit.CableSize ? <div>{installationAudit.CableSize + 'mm'}<sup>2</sup></div> : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.CableLength ? installationAudit.CableLength + ' m' : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.Pump ? installationAudit.Pump : 'None'}
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr mt10'}>
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Pump Type
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Pump Serial
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Motor Size
                            </Typography>
                        </div>
                        <div className={'fdr mb10'}>
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayField'>
                                    {installationAudit.PumpType ? installationAudit.PumpType : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.PumpSerial ? installationAudit.PumpSerial : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.MotorSize ? installationAudit.MotorSize + ' kW' : 'None'}
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr mt10'}>
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Motor Type
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Motor Phase
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Motor Diameter
                            </Typography>
                        </div>
                        <div className={'fdr mb10'}>
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayField'>
                                    {installationAudit.MotorType ? installationAudit.MotorType : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.MotorPhase ? installationAudit.MotorPhase : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.MotorDiameter ? installationAudit.MotorDiameter + '"' : 'None'}
                                </div>
                            </Typography>
                        </div>
                        <div className={'fdr mt10'}>
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Motor Serial
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Rising Main
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                Rising Main Type
                            </Typography>
                        </div>
                        <div className={'fdr mb10'}>
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayField'>
                                    {installationAudit.MotorSerial ? installationAudit.MotorSerial : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayFieldBottom'>
                                    {installationAudit.RisingMain ? installationAudit.RisingMain : 'None'}
                                </div>
                            </Typography>
                            <span className={'w10'} />
                            <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                <div className='DisplayField'>
                                    {installationAudit.RisingMainType ? installationAudit.RisingMainType : 'None'}
                                </div>
                            </Typography>
                        </div>
                        { !!installationAudit.RisingMain && installationAudit.RisingMain === 'uPVC Column' &&
                            <div className={'fdc'}>
                                <div className={'fdr mt10'}>
                                    <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                        uPVC Column
                                    </Typography>
                                    <span className={'w10'} />
                                    <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                        uPVC Column Type
                                    </Typography>
                                    <span className={'w10'} />
                                    <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                        uPVC Column Value
                                    </Typography>
                                </div>
                                <div className={'fdr mb10'}>
                                    <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                        <div className='DisplayField'>
                                            {installationAudit.uPVCColumn ? installationAudit.uPVCColumn : 'None'}
                                        </div>
                                    </Typography>
                                    <span className={'w10'} />
                                    <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                        <div className='DisplayFieldBottom'>
                                            {installationAudit.uPVCColumnType ? installationAudit.uPVCColumnType : 'None'}
                                        </div>
                                    </Typography>
                                    <span className={'w10'} />
                                    <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                        <div className='DisplayFieldBottom'>
                                            {installationAudit.uPVCColumnValue ? installationAudit.uPVCColumnValue : 'None'}
                                        </div>
                                    </Typography>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={'fdr aic jcc mt40 flx1'}>
                        <div className={'p20 flx1 fdc'}>
                            <div className={'fdr mt10'}>
                                <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                    Hole Depth
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                    Installation Depth
                                </Typography>
                            </div>
                            <div className={'fdr mb10'}>
                                <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                    <div className='DisplayField'>
                                        {installationAudit.HoleDepth ? installationAudit.HoleDepth + ' m' : 'None'}
                                    </div>
                                </Typography>
                                <span className={'w10'} />
                                <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                    <div className='DisplayFieldBottom'>
                                        {installationAudit.InstalledDepth ? installationAudit.InstalledDepth + ' m' : 'None'}
                                    </div>
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className={'fdr aic jcc mt40 flx1'}>
                        <div className={'p20 flx1 fdc'}>
                            <div className={'fdr mt10'}>
                                <Typography className={'flx1 bbw1'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                    Installed Yield
                                </Typography>
                            </div>
                            <div className={'fdr mb10'}>
                                <Typography className={'flx1'} color='inherit' variant='subtitle1'>
                                    <div className='DisplayField'>
                                        {installationAudit.InstallYield ? <div>{installationAudit.InstallYield} m<sup>3</sup></div> : 'None'}
                                    </div>
                                </Typography>
                            </div>
                            <div className='p10 fdr flx1'>
                                <div className='p10 fdc flx1'>
                                    <Typography className={'bbw1 jcc'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                        Pump
                                    </Typography>
                                    { installationAudit.PumpImageFileName ? (
                                        <div className='p10 jcc'>
                                            <FirebaseStorageImage fileName={installationAudit.PumpImageFileName} path={`borehole/${installationAudit.Borehole.id}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                        </div>
                                    ) : (
                                        <div className='flx1 fdc aic jcc p30'>No Image</div>
                                    )}
                                </div>
                                <div className='p10 fdc flx1'>
                                    <Typography className={'bbw1 jcc'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                        Motor
                                    </Typography>
                                    { installationAudit.MotorImageFileName ? (
                                        <div className='p10 jcc'>
                                            <FirebaseStorageImage fileName={installationAudit.MotorImageFileName} path={`borehole/${installationAudit.Borehole.id}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                        </div>
                                    ) : (
                                        <div className='flx1 fdc aic jcc p30'>No Image</div>
                                    )}
                                </div>
                                <div className='p10 fdc flx1'>
                                    <Typography className={'bbw1 jcc'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                        Inside Electrical Box Image
                                    </Typography>
                                    { installationAudit.ElectricalBoxImageFileName ? (
                                        <div className='p10 jcc'>
                                            <FirebaseStorageImage fileName={installationAudit.ElectricalBoxImageFileName} path={`borehole/${installationAudit.Borehole.id}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                        </div>
                                    ) : (
                                        <div className='flx1 fdc aic jcc p30'>No Image</div>
                                    )}
                                </div>
                                <div className='p10 fdc flx1'>
                                    <Typography className={'bbw1 jcc'} color='inherit' variant='h6' style={{ borderColor: defaultTheme.palette.primary.main }}>
                                        Borehole Site Image
                                    </Typography>
                                    { installationAudit.BoreholeImageFileName ? (
                                        <div className='p10 jcc'>
                                            <FirebaseStorageImage fileName={installationAudit.BoreholeImageFileName} path={`borehole/${installationAudit.Borehole.id}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                        </div>
                                    ) : (
                                        <div className='flx1 fdc aic jcc p30'>No Image</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        imageSrcs.length > 0 &&
                        <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                    }
                </div>
            }
        </div>);
    };
}

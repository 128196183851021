import React from 'react';
import { IMottech } from '../../../@types/mottech';
import { IUserSession } from '../../../@types/employee';
import Typography from '@material-ui/core/Typography';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import Icon from '@material-ui/core/Icon';
import appFunctionsService from '../../../services/appFunctionsService';
import MottechConnectedView from './ConnectedView';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditMottechDivisionInfo from '../dialog/EditDivision';
import EditMottechInfo from '../dialog/EditInfo';
import EditMottechIO from '../dialog/EditConnections';
import EditMottechRepeater from '../dialog/EditRepeaters';
import EditMottechImages from '../dialog/EditImages';
import EditLocationDialog from '../../customComponents/dialog/LocationEditDialog';
import mottechFunctions from '../../../store/mottech/functions';
import { MOTTECH_MAP_IMAGES } from '../../../appConstants';

interface IMottechDetailViewProps {
    mottech : IMottech;
    isLoading : boolean;
    session : IUserSession;
}

interface IMottechDetailViewState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class MottechDetailView extends React.Component<IMottechDetailViewProps, IMottechDetailViewState> {
    constructor(props : IMottechDetailViewProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private readonly onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number, accuracy ?: number) => {
        const mottech = Object.assign({}, this.props.mottech);

        mottech.Geom = geom;
        mottech.Elevation = elevation;
        mottech.Accuracy = accuracy ?? 0.0;

        const result = await mottechFunctions.saveMottech(mottech);
        
        if (!result) throw new Error('');
    };

    public readonly render = () => {
        const { mottech, isLoading, session } = this.props;
        const { imageSrcs, isCarouselOpen, imageIndex } = this.state;
        return (
            <div className='fdc flx1'>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            MOTTECH INFO
                            <span className='flx1' />
                            {
                                session.employee.IsMottechAdmin &&
                                <EditMottechInfo mottech={mottech} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                CODE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                NAME
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                TYPE
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.Code}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.Name}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.Type}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                PICCOLO/PIU ID
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                SERIAL NUMBER
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                INSTALLATION DATE
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.PiccoloId}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.SerialNumber}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!mottech.InstallationDate ? '' : appFunctionsService.formatDateTimeToDateOnly(mottech.InstallationDate)}
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            DIVISION INFO
                            <span className='flx1' />
                            {
                                session.employee.IsMottechAdmin &&
                                <EditMottechDivisionInfo mottech={mottech} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                DIVISION
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                MANAGEMENT AREA
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.Division}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.ManagementArea}
                            </Typography>
                        </div>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            LOCATION
                            <span className='flx1' />
                            {
                                session.employee.IsMottechAdmin &&
                                <EditLocationDialog
                                    markerImage = {MOTTECH_MAP_IMAGES['1']}
                                    markerImageSize = {0.35}
                                    elevation={mottech.Elevation}
                                    geom={mottech.Geom}
                                    transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                    accuracy={!mottech.Accuracy ? 0 : mottech.Accuracy}
                                    onSave={this.onLocationSave}
                                />
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                LATITUDE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                LONGITUDE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                ELEVATION
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                ACCURACY
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.Geom ? mottech.Geom.latitude : 'None'}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.Geom ? mottech.Geom.longitude : 'None'}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.Geom && mottech.Elevation ? mottech.Elevation.toFixed(2) : 'None'}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {mottech.Geom && mottech.Accuracy ? mottech.Accuracy.toFixed(2) : 'None'}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            INPUTS
                            <span className='flx1' />
                            {
                                session.employee.IsMottechAdmin &&
                                <EditMottechIO input mottech={mottech} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                NUMBER
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                TYPE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                OTHER
                            </Typography>
                        </div>
                        {
                            mottech.Inputs.map(n => (
                                <div key={`${n.InputNumber}_in`} className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {n.InputNumber}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {n.Name}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {n.Additional}
                                    </Typography>
                                </div>
                            ))
                        }
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            OUTPUTS
                            <span className='flx1' />
                            {
                                session.employee.IsMottechAdmin &&
                                <EditMottechIO mottech={mottech} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                NUMBER
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                TYPE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                OTHER
                            </Typography>
                        </div>
                        {
                            mottech.Outputs.map(n => (
                                <div key={`${n.InputNumber}_out`} className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {n.InputNumber}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {n.Name}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {n.Additional}
                                    </Typography>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            REPEATERS
                            <span className='flx1' />
                            {
                                session.employee.IsMottechAdmin &&
                                <EditMottechRepeater mottech={mottech} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        {
                            !mottech.Repeater &&
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                    <Icon>close</Icon>
                                </Typography>
                            </div>
                        }
                        {
                            !!mottech.Repeaters?.length &&
                            mottech.Repeaters.map(n => (
                                <MottechConnectedView key={`${n}_rep`} mottechCode={n} />
                            ))
                        }
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            CONNECTED TO
                            <span className='flx1' />
                            {
                                session.employee.IsMottechAdmin &&
                                <EditMottechInfo mottech={mottech} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        {
                            mottech.ConnectedTo &&
                            <MottechConnectedView mottechCode={mottech.ConnectedTo} />
                        }
                        {
                            !mottech.ConnectedTo &&
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                <Icon>close</Icon>
                            </Typography>
                        }
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            IMAGES
                            <span className='flx1' />
                            {
                                session.employee.IsMottechAdmin &&
                                <EditMottechImages mottech={mottech} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            {
                                mottech.ImageName &&
                                <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                    Mottech ({mottech.Code})
                                </Typography>
                            }
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            {
                                mottech.ImageName &&
                                <div className='flx1 p10 jcc fdc'>
                                    <FirebaseStorageImage fileName={mottech.ImageName} path={`mottech/${mottech.Code}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                </div>
                            }
                            {
                                imageSrcs.length > 0 &&
                                <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                            }
                        </div>
                    </div>
                </div>
            </div>);
    };
}

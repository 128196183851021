import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { ICompany } from '../../../@types/model/masterData/company';
import { IRootState } from '../../../@types/redux';
import DataFunctions from '../../../store/data/functions';
import lodash from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import OutlinedTextField from '../textField/OutlinedTextField';

interface ICompanySelectorProps {
    companies : Array<ICompany>;
    isLoading : boolean;

    disabled ?: boolean;
    required ?: boolean;
    value ?: string | null;
    onChange ?: (value ?: ICompany | null) => void;

    variant ?: 'outlined';
}

interface ICompanySelectorState {}

class CompanySelectorComponent extends React.PureComponent<ICompanySelectorProps, ICompanySelectorState> {
    constructor(props : ICompanySelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        DataFunctions.getCompanies();
    };

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: {
        label : string;
        value : string;
    } | null) => {
        const { companies, onChange } = this.props;

        if (!onChange) return;

        onChange(companies.find(x => x.id === value?.value));
    };

    private readonly getData = (state : ICompanySelectorState, props : ICompanySelectorProps) => props.companies;
    private readonly getValue = (state : ICompanySelectorState, props : ICompanySelectorProps) => props.value;
    private readonly getRequired = (state : ICompanySelectorState, props : ICompanySelectorProps) => props.required;

    private readonly getDropdown = createSelector([ 
        this.getRequired, this.getData,
    ], (required, companies) => {
        
        const dropdownData = lodash
            .map(companies, (element) => ({
                label: `${element.code} - ${element.name}`,
                value: element.id,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([ 
        this.getValue, this.getRequired, this.getData,
    ], (value, required, companies) => {
        if (companies.length && value) {
            const company = companies.find(element => element.id === value);

            if (company) {
                return { label: `${company.code} - ${company.name}`, value: company.id };
            } else {
                return null;
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, disabled, variant } = this.props;

        const dropdownData = this.getDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        const Component = variant === 'outlined' ? OutlinedTextField : TextField;
        
        return (
            <Autocomplete
                options={dropdownData}
                value={!dropdownData.length ? null : value}
                getOptionSelected={(option, val) => option.value === val.value}
                getOptionLabel={option => option.label}
                onChange={this.onChange}
                disableClearable={required}
                openOnFocus
                disabled={disabled}
                fullWidth
                renderInput={params => <Component error={required && !value} {...params} fullWidth label={'Company'} />}
            />
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        companies: state.data.companies,
        isLoading: state.data.isLoadingCompanies,
    };
};

const CompanySelector = connect(
    mapStateToProps,
)(CompanySelectorComponent);

export default CompanySelector;
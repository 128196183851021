import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import RiverFunctions from '../../../store/river/functions';
import MaterialTable from '../../customComponents/materialTable/Table';
import IndexTextInput from '../../customComponents/input/IndexTextInput';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@material-ui/icons';
import { IRiver, IRiverVNotch } from '../../../@types/model/river/river';

interface FormValue extends IRiverVNotch {
    number : number;
}

interface IEditRiverVNotchDialogProps {
    river : IRiver;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading ?: boolean;
}

interface IEditRiverVNotchDialogState {
    open : boolean;
    values : Array<FormValue>;
}

export default class EditRiverVNotchDialog extends React.Component<IEditRiverVNotchDialogProps, IEditRiverVNotchDialogState> {
    constructor(props : IEditRiverVNotchDialogProps) {
        super(props);
        this.state = {
            open: false,
            values: [],
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditRiverVNotchDialogProps, prevState : IEditRiverVNotchDialogState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                values: this.props.river.Vnotch
                    .sort((a, b) => a.height - b.height)
                    .map((x, i) => ({
                        ...x,
                        number: i,
                    })),
            });
        }
    };

    private readonly save = async () => {
        const river = {
            ...this.props.river,
            Vnotch: [
                ...this.props.river.Vnotch,
            ],
        };

        river.Vnotch = this.state.values.map(n => ({
            flow: n.flow,
            height: n.height,
        }));

        await RiverFunctions.saveRiver(river);
        this.setState({
            open: false,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };
    private readonly addRow = () => {
        const newValues = this.state.values.slice();

        const newValue = {
            number: this.state.values.length,
            flow: 0,
            height: 0,
        } as FormValue;

        newValues.push(newValue);
        this.setState({
            values: newValues,
        });
    };

    private readonly removeRow = (event : React.MouseEvent<HTMLButtonElement>) => {
        const newValues = this.state.values.slice();
        newValues.splice(Number(event.currentTarget.value) - 1, 1);
        this.setState({
            values: newValues,
        });
    };

    private readonly onMoveUpClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const newValues = this.state.values.map(x => ({
            ...x,
        }));

        const index = Number(event.currentTarget.value) - 1;

        const current = newValues[index];
        const previous = newValues[index - 1];

        current.number--;
        previous.number++;
        
        newValues.splice(index - 1, 2, current, previous);

        this.setState({
            values: newValues,
        });
    };

    private readonly onMoveDownClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const newValues = this.state.values.map(x => ({
            ...x,
        }));

        const index = Number(event.currentTarget.value) - 1;

        const current = newValues[index];
        const next = newValues[index + 1];

        current.number++;
        next.number--;
        
        newValues.splice(index, 2, next, current);

        this.setState({
            values: newValues,
        });
    };
    
    private readonly updateVolumes = (index : number, newRow : FormValue) => {
        const newValues = this.state.values.slice();

        newValues.splice(index, 1, newRow);

        this.setState({
            values: newValues,
        });
    };

    private readonly onHeightChange = (index : number, value : string) => {
        if (Number(value) < 0) return;
        
        this.updateVolumes(index, {
            ...this.state.values[index],
            height: Number(value),
        });
    };
    
    private readonly onFlowChange = (index : number, value : string) => {
        if (Number(value) < 0) return;
        
        this.updateVolumes(index, {
            ...this.state.values[index],
            flow: Number(value),
        });
    };

    public readonly render = () => {
        const { transition, isLoading } = this.props;
        const { open, values } = this.state;

        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton className={'cp ml20'} onClick={this.onEditClick}>
                            <Icon>
                                edit
                            </Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={'lg'}
                    fullScreen={false}
                    fullWidth={true}
                    aria-labelledby='river-info-info-title'
                    aria-describedby='river-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit River V-Notch Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdc flx1 '}>
                                <MaterialTable<FormValue>
                                    id='vnotchTable'
                                    data={values}
                                    isLoading={this.props.isLoading}
                                    rowsPerPage={10}
                                    columns={[{
                                        header: '',
                                        field: '',
                                        width: 50,
                                        enableSort: true,
                                        enableFilter: true,
                                        paddingRight: 4,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IconButton component='button' value={row.number} onClick={this.removeRow}>
                                                    <Icon className={'cr'}>
                                                        delete
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        ),
                                    }, {
                                        header: 'Order',
                                        field: 'number',
                                        width: 150,
                                        enableSort: true,
                                        enableFilter: true,
                                        paddingRight: 4,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IconButton color='primary' disabled={row.number === 0} value={row.number} onClick={this.onMoveUpClick}>
                                                    <ArrowUpwardRounded />
                                                </IconButton>
                                                <span>{row.number + 1}</span>
                                                <IconButton color='primary' disabled={row.number === values.length - 1} value={row.number} onClick={this.onMoveDownClick}>
                                                    <ArrowDownwardRounded />
                                                </IconButton>
                                            </div>
                                        ),
                                    }, {
                                        header: 'CM',
                                        field: 'height',
                                        width: 150,
                                        enableSort: true,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IndexTextInput 
                                                    id={'height'} 
                                                    value={row.height.toString()} 
                                                    onChange={this.onHeightChange} 
                                                    index={row.number}
                                                />
                                            </div>
                                        ),
                                    },
                                    {
                                        header: 'Flow',
                                        field: 'flow',
                                        width: 150,
                                        enableSort: true,
                                        renderCell: row => (
                                            <div className='aic'>
                                                <IndexTextInput 
                                                    id={'flow'} 
                                                    value={row.flow.toString()} 
                                                    onChange={this.onFlowChange}
                                                    index={row.number}
                                                />
                                            </div>
                                        ),
                                    },
                                    ]}
                                />
                                <div className={'jcfe wfill pt20 '}>
                                    <IconButton className={'bcy'} onClick={this.addRow}>
                                        <Icon className={'cp'}>
                                            add
                                        </Icon>
                                    </IconButton>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}
import { Accordion, withStyles } from '@material-ui/core';

const SeparatedAccordion = withStyles({
    root: {
        marginBottom: 16,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        '&& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 58,
            height: 58,
        },
        '&:before': {
            display: 'none',
        },
    },
})(Accordion);


export default SeparatedAccordion;
import firebaseApp from '../../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../../base';

export interface IPumpingStationPumpMake extends IBasicBaseClass {
    name : string;
    types : Array<string>;

    isActive : boolean;
}

export default class PumpingStationPumpMakeHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station_pump_make';

    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStationPumpMake> = {
        fromFirestore: (snapshot) => {
            return PumpingStationPumpMakeHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStationPumpMake) : firebase.firestore.DocumentData => {
            return PumpingStationPumpMakeHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStationPumpMake {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStationPumpMake,
            ...result,
        };
    }

    public static toFirestoreDocument(data : IPumpingStationPumpMake) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationPumpMakeHelper.COLLECTION_NAME).withConverter(PumpingStationPumpMakeHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationPumpMakeHelper.COLLECTION_NAME).withConverter(PumpingStationPumpMakeHelper.converter).doc(id);
    }

    public static save(company : IPumpingStationPumpMake) {
        return PumpingStationPumpMakeHelper.doc(company.id).set(company, {
            merge: true,
        });
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(PumpingStationPumpMakeHelper.COLLECTION_NAME)
            .withConverter(PumpingStationPumpMakeHelper.converter);
    }
}
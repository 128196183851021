import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

// tslint:disable-next-line: variable-name
const AbsoluteLinearProgress = withStyles(() => ({
    root: {
        position: 'absolute',
        width: 'inherit',
    },
}))(LinearProgress);

export default AbsoluteLinearProgress;

import { FieldArray, FieldProps } from 'formik';
import React from 'react';
import { IMunicipalityElectricityInvoiceFormValues, MunicipalityInvoiceHelper } from '../../../@types/model/transformer/municipalityInvoice';
import { themeVariables } from '../../../material';
import { Divider, Switch, Typography } from '@material-ui/core';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import TextFormFastField from '../../customComponents/form/TextFormFastField';
import StadiumDiv from '../../customComponents/div/Stadium';

interface IMunicipilityInvoiceElectricityFormProps extends FieldProps<IMunicipalityElectricityInvoiceFormValues | null> {}

interface IMunicipilityInvoiceElectricityFormState {}

export default class MunicipilityInvoiceElectricityForm extends React.PureComponent<IMunicipilityInvoiceElectricityFormProps, IMunicipilityInvoiceElectricityFormState> {
    constructor(props : IMunicipilityInvoiceElectricityFormProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.field.onChange(event);

        setTimeout(() => {
            MunicipalityInvoiceHelper.calculateTotals(this.props.form);
        });
    };

    private readonly onDivClick = (event : React.SyntheticEvent) => {
        this.onElectricityChange(event as React.ChangeEvent<HTMLInputElement>, !this.props.field.value);
    };

    private readonly onElectricityChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const transformer = this.props.form.getFieldProps<ITransformer | null>('transformer');
        if (!transformer.value) return;

        let electricity : IMunicipalityElectricityInvoiceFormValues | null = null;
        if (checked) {
            electricity = {
                basicInterest: null,
                meteredInterest: null,
                fixedBasicCharge: null,
                demandCost: null,
                days: null,
                meters: transformer.value.TransformerMeterNumbers?.map(n => ({
                    meterNumber: n.Number,
                    unitOfMeasure: n.UnitOfMeasure ?? '-',
                    offPeakConsumptionKwh: null,
                    offPeakCostReading: null,
                    peakConsumptionKwh: null,
                    peakCostReading: null,
                    standardConsumptionKwh: null,
                    standardCostReading: null,
                })) ?? [],
                usageZar: 0,
                totalConsumption: 0,
                total: 0,
            };
        }

        this.props.form.setFieldValue('electricity', electricity);

        setTimeout(() => {
            MunicipalityInvoiceHelper.calculateTotals(this.props.form);
        });
    };

    public readonly render = () => {
        const {
            field,
            form,
        } = this.props;
        
        return (
            <div className='fdc'>
                <div className='fdr'>
                    <div
                        className='fdr flx1 aic h50 pl25 pr25 curp'
                        onClick={this.onDivClick}
                        style={{
                            boxShadow: '0px 3px 6px #00000029',
                            backgroundColor: field.value ?
                                '#0D703F' :
                                themeVariables.colors.table.capturing.spu.totalUsage,
                        }}
                    >
                        <Typography className='cw fwm fs20'>ELECTRICITY</Typography>
                        <span className='flx1' />
                        <Switch
                            color={!field.value ? 'primary' : 'secondary'}
                            checked={!!field.value}
                            onChange={this.onElectricityChange}
                        />
                    </div>
                </div>
                {
                    field.value &&
                    <div className='fdc mt10'>
                        <div className='fdr'>
                            <div className='flx1 mr10 aifs'>
                                <TextFormFastField
                                    name={'electricity.fixedBasicCharge'}
                                    label='Fixed Cost'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                    disabled={form.isSubmitting}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'electricity.days'}
                                    label='Days'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                    disabled={form.isSubmitting}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'electricity.demandCost'}
                                    label='Energy Cost'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                    disabled={form.isSubmitting}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'electricity.basicInterest'}
                                    label='Basic Interest'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                    disabled={form.isSubmitting}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'electricity.meteredInterest'}
                                    label='Metered Interest'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                    disabled={form.isSubmitting}
                                />
                            </div>
                        </div>
                        <div className='fdr aic h37 bclg pl25 pr25'>
                            <Typography className='cpd fwm fs14'>READINGS:</Typography>
                        </div>
                        <div className='mt15'>
                            <FieldArray
                                name='electricity.meters'
                            >
                                {
                                    () => (
                                        <div className='fdc'>
                                            {
                                                field.value?.meters.map((meter, i) => (
                                                    <div key={i} className='fdr mb15'>
                                                        <div className='fdc aifs jcc mr10'>
                                                            <div className='fdc flx1 aic jcfe mr10 pb15'>
                                                                <TextFormFastField
                                                                    name={`electricity.meters.${i}.meterNumber`}
                                                                    label='Meter'
                                                                    disabled
                                                                    fullWidth
                                                                    onChange={this.onChange}
                                                                    onBlur={form.handleBlur}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdc aifs jcc mr10'>
                                                            (
                                                            {
                                                                meter.unitOfMeasure
                                                            }
                                                            )
                                                        </div>
                                                        <div className='fdc flx1 aifs jcc ml10 mr10'>
                                                            <div className='fdr flx1 aic jcc p15 '>
                                                                <div className='flx1 mr10'>
                                                                    <TextFormFastField
                                                                        name={`electricity.meters.${i}.standardConsumptionKwh`}
                                                                        label='Usage'
                                                                        fullWidth
                                                                        type='number'
                                                                        onChange={this.onChange}
                                                                        onBlur={form.handleBlur}
                                                                    />
                                                                </div>
                                                                <div className='flx1 ml10'>
                                                                    <TextFormFastField
                                                                        name={`electricity.meters.${i}.standardCostReading`}
                                                                        label='Cost'
                                                                        fullWidth
                                                                        type='number'
                                                                        onChange={this.onChange}
                                                                        onBlur={form.handleBlur}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </FieldArray>
                        </div>
                        <Divider className='mt15 mb15' />
                        <div className='fdr aic'>
                            <div className='fdr flx1 mr10'>
                                <Typography component={'span'} className='flx2 aic jcfe cpd fwm fs14'>TOTAL ELECTRICITY USAGE:</Typography>
                                <StadiumDiv className='fdr flx1 bcg1 cpd ml15'>
                                    <div className='fdr aic jcc'>
                                        <Typography component={'span'} className='fdr jcfs flx1 fs16 fwm pr'>
                                            {
                                                field.value.totalConsumption.toLocaleString()
                                            }
                                        </Typography>
                                        <Typography component={'span'} className='cg3 fs16 fwm ml15'>Kwh</Typography>
                                    </div>
                                </StadiumDiv>
                            </div>
                            <div className='fdr flx1 ml10'>
                                <Typography component={'span'} className='flx2 aic jcfe cpd fwm fs14'>TOTAL ELECTRICITY COST:</Typography>
                                <StadiumDiv className='fdr flx1 bcg1 cpd ml15'>
                                    <div className='fdr aic jcc'>
                                        <Typography component={'span'} className='fdr jcfs flx1 fs16 fwm pr'>
                                            {
                                                !!field.value.total &&
                                                field.value.total.toLocaleCurrency()
                                            }
                                            {
                                                !field.value.total &&
                                                Number(0).toLocaleCurrency()
                                            }
                                        </Typography>
                                    </div>
                                </StadiumDiv>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import React, { ChangeEvent } from 'react';
import Fab from '@material-ui/core/Fab';
import TransformerGroupDropdown from '../../customComponents/selector/transformer/GroupSelector';
import { ITransformerGroup } from '../../../@types/model/transformer/transformerGroup';
import MeterTypeSelector from '../selector/transformer/MeterTypeSelector';
import OutsideClicker from '../outsideClicker';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import TransformerDropdown from '../selector/transformer/TransformerSelector';
import { ITransformer, TransformerMeterTypeUpperCase } from '../../../@types/model/transformer/transformer';

interface ITransformerPopperProps {
    id ?: string;
    open : boolean;
    anchorEl : HTMLButtonElement | null;
    onChange ?: (epNumbers : string | null, epGroup : string | null, epArea : string | null, type : string | null, meterType : TransformerMeterTypeUpperCase | null) => void;
    onCancelClick ?: () => void;
    epNumber : string | null;
    epGroup : string | null;
    epArea : string | null;
    type : string | null;
    meterType : TransformerMeterTypeUpperCase | null;
}

interface ITransformerFilterPopperState {
    epNumber : string;
    epGroup ?: string;
    epArea : string | null;
    type : string | null;
    meterType : TransformerMeterTypeUpperCase | null;
}

export default class TransformerFilterPopper extends React.PureComponent<ITransformerPopperProps, ITransformerFilterPopperState> {
    constructor(props : ITransformerPopperProps) {
        super(props);
        this.state = {
            epNumber: '',
            epGroup: '',
            epArea: '',
            type: '',
            meterType: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : ITransformerPopperProps) => {
        if (!prevProps.open && this.props.open) {
            const {epNumber, epArea, epGroup, type, meterType} = this.props;
            this.setState({
                epArea: epArea,
                epNumber: epNumber ?? '',
                epGroup: epGroup ?? '',
                type: type,
                meterType: meterType ?? null,
            });
        }
    };

    private readonly onEPGroupChange = (value ?: ITransformerGroup) => {
        this.setState({
            epGroup: value?.name,
        });
    };

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            epNumber: value?.EPNumber ?? '',
        });
    };

    private readonly onEpAreaChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            epArea: event.target.value,
        });
    };

    private readonly typeChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            type: event.target.value,
        });
    };

    private readonly onMeterTypeChange = (meterType : TransformerMeterTypeUpperCase | '') => {
        this.setState({
            meterType: !meterType ? null : meterType,
        });
    };

    private readonly onSubmit = (event ?: React.FormEvent<HTMLFormElement>) => {
        
        if (event) {
            event.preventDefault();
        }

        if (!this.props.onChange) return;
        const { epGroup, epNumber, epArea, type, meterType } = this.state;

        this.props.onChange(epNumber, epGroup ?? '', epArea, type, meterType);
    };

    public render = () => {
        const { open, anchorEl, id, onCancelClick } = this.props;
        const { epGroup, epNumber, epArea, type, meterType } = this.state;

        return (
            <Popper
                id={`${id}_transformer_filter_menu`} open={open} anchorEl={anchorEl} transition
                style={{
                    zIndex: 5,
                }}
                placement='bottom'>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div style={{
                            width: 362,
                            background: '#FFF',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: -5,
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 15,
                        }}>
                            {
                                open &&
                                <OutsideClicker onClickedOutside={onCancelClick}>
                                    <form onSubmit={this.onSubmit} className='fdc ais'>
                                        <div className='fdr'>
                                            <div className='flx1 pr5 jcc'>
                                                <TransformerDropdown value={epNumber} fullWidth onChange={this.onEPNumberChange} />
                                            </div>
                                            <div className='flx1 pr5 jcc'>
                                                <TransformerGroupDropdown fullWidth value={epGroup} onChange={this.onEPGroupChange} type={'ALL'} />
                                            </div>
                                        </div>
                                        <div className='fdr'>
                                            <div className={'flx1 aifs pr5'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='Area'
                                                        label='Area'
                                                        value={epArea}
                                                        onChange={this.onEpAreaChange}
                                                        margin='normal'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className='fdr flx1 aifs pl5'>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='Type'
                                                        label='Type'
                                                        value={type}
                                                        onChange={this.typeChange}
                                                        margin='normal'
                                                    />
                                                </FormControl>
                                            </div>

                                        </div>
                                        <div className='fdr'>
                                            <div className='fdr flx1 aifs pr5'>
                                                <MeterTypeSelector value={meterType} onChange={this.onMeterTypeChange} />
                                            </div>
                                        </div>
                                        <div className='fdrr aic pb16'>
                                            <span className='flx1' />
                                            <Fab variant='extended' color='secondary' className='fab cancel' size='small' onClick={onCancelClick} type='button'>
                                                CANCEL
                                            </Fab>
                                            <Fab variant='extended' color='secondary' className='fab save' size='small' type='submit'>
                                                APPLY
                                            </Fab>
                                        </div>
                                    </form>
                                </OutsideClicker>
                            }
                        </div>
                    </Fade>
                )}
            </Popper>
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import { createSelector } from 'reselect';
import TransformerBudgetHelper, { BudgetType, ILPUBudget, ILPUBudgetFormValues, IMunicipalityBudget, IMunicipalityBudgetFormValues, ISPUBudget, ISPUBudgetFormValues, ITransformerBudget } from '../../../../@types/model/transformer/budget';
import { CircularProgress, FormControlLabel, FormHelperText, InputAdornment, Switch } from '@material-ui/core';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { ITransformer, ITransformerCompany } from '../../../../@types/model/transformer/transformer';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import TransformerFunctions from '../../../../store/transformer/functions';
import SPUBudgetForm from '../form/SPUForm';
import { Moment } from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IUserSession } from '../../../../@types/employee';
import moment from 'moment';
import GeneralFunctions from '../../../../store/general/functions';
import MunicipalityBudgetForm from '../form/MunicipalityForm';
import LPUBudgetForm from '../form/LPUForm';
import CompanySelector from '../../../customComponents/selector/CompanySelector';
import { ICompany } from '../../../../@types/model/masterData/company';
import TransformerAreaDropdown from '../../../customComponents/selector/transformer/AreaSelector';
import TransformerDropdown from '../../../customComponents/selector/transformer/TransformerSelector';
import TransformerAccountDropdown from '../../../customComponents/selector/transformer/TransformerAccountSelector';
import PeriodDropdown from '../../../customComponents/selector/PeriodSelector';
import OutlinedTextField from '../../../customComponents/textField/OutlinedTextField';

interface IBudgetEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    
    type : BudgetType;

    value ?: ITransformerBudget;

    isLoadingTransformers : boolean;
    transformers : Array<ITransformer>;
    
    session ?: IUserSession | null;

    budgets : Array<ITransformerBudget>;
    
    companies : Array<ICompany>;
}

interface IBudgetEditDialogState {
    open : boolean;
    isLoading : boolean;

    budget ?: ITransformerBudget;

    hideDetail : boolean;
    date : Moment | null;
    company : ITransformerCompany | null;
    area : string | null;
    transformer : string | null;
    accountNumber : string | null;
    period : number | null;
}

class BudgetEditDialog extends React.PureComponent<IBudgetEditDialogProps, IBudgetEditDialogState> {
    constructor(props : IBudgetEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
            hideDetail: false,
            date: null,
            company: null,
            area: null,
            transformer: null,
            accountNumber: null,
            period: null,
        };
    }
    
    public readonly componentDidMount = () => {
        TransformerFunctions.getList();
    };

    public readonly onClick = () => {
        this.setState({
            open: true,
            date: this.props.value?.startDate ? moment.utc(this.props.value.startDate) : null,
            area: this.props.value?.area ?? null,
            company: this.props.companies.find(x => x.id === this.props.value?.companyId) ?? null,
        });
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
            isLoading: false,
        });
    };

    public readonly getValue = (props : IBudgetEditDialogProps) => props.value;
    public readonly getType = (props : IBudgetEditDialogProps) => props.type;
    public readonly getIsLoadingTransformers = (props : IBudgetEditDialogProps) => props.isLoadingTransformers;
    public readonly getIsLoadingProp = (props : IBudgetEditDialogProps, state : IBudgetEditDialogState) => state.isLoading;

    public readonly getTooltip = createSelector(
        [this.getValue],
        (value) => {
            if (!value) return 'Add';

            return 'Edit';
        },
    );

    public readonly getTitle = createSelector(
        [this.getType, this.getTooltip],
        (type, tooltip) => {
            let title = type.toLocaleUpperCase();
            if (type === 'municipality') {
                title = AppFunctionsService.toTitleCase(type);
            }

            return `${tooltip == 'Add' ? 'Create' : 'View'} ${title} Budget`;
        },
    );
    
    public readonly getIsLoading = createSelector(
        [
            this.getIsLoadingTransformers,
            this.getIsLoadingProp,
        ],
        (isLoadingTransformers, isLoading) => {
            return isLoadingTransformers || isLoading;
        },
    );

    public readonly onHideDetailChange = (event : React.ChangeEvent, checked : boolean) => {
        this.setState({
            hideDetail: checked,
        });
    };

    private readonly onDateChange = (date : MaterialUiPickersDate) => {
        this.setState({
            date: date?.asUTC().startOf('month') ?? null,
        });
    };

    private readonly onCompanyChange = (company ?: ICompany | null) => {
        this.setState({
            company: !company ? null : {
                code: company.code,
                electricityAccountNumber: company.electricityAccountNumber,
                id: company.id,
                interestAccountNumber: company.interestAccountNumber,
                name: company.name,
                vatAccountNumber: company.vatAccountNumber,
                paymentEnabled: company.paymentEnabled,
            },
        });
    };

    private readonly onSPUSubmit = async (values : ISPUBudgetFormValues) => {
        const { session, value, budgets } = this.props;
        if (!session) return;

        const isDuplicate = budgets.some(x =>
            x.id !== values.id &&
            x.area === values.area &&
            x.companyId === values.companyId &&
            x.startDate === values.startDate
        );
        if (isDuplicate) {
            GeneralFunctions.generalShowErrorSnackbar('Trying to save duplicate');
            return;
        }

        const save : ITransformerBudget<ISPUBudget> = {
            id: values.id,
            isActive: value?.isActive ?? true,
            createdBy: value?.createdBy ?? session.firebaseUser.uid,
            createdByName: value?.createdByName ?? session.firebaseUser.displayName ?? '',
            createdByEmployee: value?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
            createdOn: value?.createdOn ?? value?.createdOn ?? moment.utc().valueOf(),
            updatedBy: session.firebaseUser.uid,
            updatedByName: session.firebaseUser.displayName ?? '',
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedOn: moment.utc().valueOf(),
            isWeb: true,
            endDate: values.endDate,
            startDate: values.startDate,
            type: 'spu',
            year: values.year,
            area: values.area,
            companyId: values.companyId,
            companyName: values.companyName,
            values: values.values.map(x => {
                const { index: _index, ...rest } = x;
                return {
                    ...rest,
                    budget: !x.budget ? null : Number(x.budget),
                };
            }),
            prevBudget: null,
        };

        try {
            await TransformerBudgetHelper.save(save);

            GeneralFunctions.generalShowSuccessSnackbar('Saved');

            if (!save.id) {
                this.onClose();
            }
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Client saving Error');
        }
    };

    private readonly onLPUSubmit = async (values : ILPUBudgetFormValues) => {
        const { session, value, budgets } = this.props;
        if (!session) return;

        const isDuplicate = budgets.some(x =>
            x.id !== values.id &&
            x.area === values.area &&
            x.companyId === values.companyId &&
            x.startDate === values.startDate
        );
        if (isDuplicate) {
            GeneralFunctions.generalShowErrorSnackbar('Trying to save duplicate');
            return;
        }

        const save : ITransformerBudget<ILPUBudget> = {
            id: values.id,
            isActive: value?.isActive ?? true,
            createdBy: value?.createdBy ?? session.firebaseUser.uid,
            createdByName: value?.createdByName ?? session.firebaseUser.displayName ?? '',
            createdByEmployee: value?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
            createdOn: value?.createdOn ?? value?.createdOn ?? moment.utc().valueOf(),
            updatedBy: session.firebaseUser.uid,
            updatedByName: session.firebaseUser.displayName ?? '',
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedOn: moment.utc().valueOf(),
            isWeb: true,
            endDate: values.endDate,
            startDate: values.startDate,
            type: 'lpu',
            year: values.year,
            area: values.area,
            companyId: values.companyId,
            companyName: values.companyName,
            values: values.values.map(x => {
                const { index: _index, ...rest } = x;
                return ({
                    ...rest,
                    offpeakBudget: !x.offpeakBudget ? null : Number(x.offpeakBudget),
                    peakBudget: !x.peakBudget ? null : Number(x.peakBudget),
                    standardBudget: !x.standardBudget ? null : Number(x.standardBudget),
                    totalBudget: (x.standardBudget ?? 0) + (x.peakBudget ?? 0) + (x.offpeakBudget ?? 0),
                });
            }),
            prevBudget: null,
        };

        try {
            await TransformerBudgetHelper.save(save);

            GeneralFunctions.generalShowSuccessSnackbar('Saved');

            if (!save.id) {
                this.onClose();
            }
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Client saving Error');
        }
    };

    private readonly onMunicipalitySubmit = async (values : IMunicipalityBudgetFormValues) => {
        const { session, value, budgets } = this.props;
        if (!session) return;

        const isDuplicate = budgets.some(x =>
            x.id !== values.id &&
            x.area === values.area &&
            x.companyId === values.companyId &&
            x.startDate === values.startDate
        );
        if (isDuplicate) {
            GeneralFunctions.generalShowErrorSnackbar('Trying to save duplicate');
            return;
        }

        const save : ITransformerBudget<IMunicipalityBudget> = {
            id: values.id,
            isActive: value?.isActive ?? true,
            createdBy: value?.createdBy ?? session.firebaseUser.uid,
            createdByName: value?.createdByName ?? session.firebaseUser.displayName ?? '',
            createdByEmployee: value?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
            createdOn: value?.createdOn ?? value?.createdOn ?? moment.utc().valueOf(),
            updatedBy: session.firebaseUser.uid,
            updatedByName: session.firebaseUser.displayName ?? '',
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedOn: moment.utc().valueOf(),
            isWeb: true,
            endDate: values.endDate,
            startDate: values.startDate,
            type: 'municipality',
            year: values.year,
            area: values.area,
            companyId: values.companyId,
            companyName: values.companyName,
            values: values.values.map(x => {
                const { index: _index, ...rest } = x;
                return {
                    ...rest,
                    budget: !x.budget ? null : Number(x.budget),
                };
            }),
            prevBudget: null,
        };

        try {
            await TransformerBudgetHelper.save(save);

            GeneralFunctions.generalShowSuccessSnackbar('Saved');

            if (!save.id) {
                this.onClose();
            }
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Client saving Error');
        }
    };

    private readonly onAreaChange = (area ?: string | null) => {
        this.setState({
            area: area ?? null,
        });
    };

    private readonly onTransformerChange = (value ?: ITransformer) => {
        this.setState({
            transformer: value?.EPNumber ?? '',
        });
    };

    private readonly onAccountNumberChange = (value ?: ITransformer | null) => {
        this.setState({
            accountNumber: value?.AccountNumber ?? null,
        });
    };

    private readonly onPeriodChange = (value : number | null) => {
        this.setState({
            period: value ?? null,
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            type,
            transformers,
            value,
        } = this.props;
        const {
            open,
            hideDetail,
            date,
            company,
            area,
            transformer,
            accountNumber,
            period,
        } = this.state;

        const tooltip = this.getTooltip(this.props);
        const title = this.getTitle(this.props);
        const isLoading = this.getIsLoading(this.props, this.state);

        return (
            <React.Fragment>
                <Tooltip title={tooltip}>
                    <div>
                        {
                            tooltip == 'Add' &&
                            <Fab size='small' color='secondary' className='bcy' aria-label='Add' onClick={this.onClick} >
                                <Icon>add</Icon>
                            </Fab>

                        }
                        {
                            tooltip != 'Add' &&
                            <Fab size='small' color='secondary' className='bcy' variant='extended' aria-label='View' onClick={this.onClick} >
                                <span className='pl10 pr10'>{tooltip}</span>
                            </Fab>

                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Budget-Edit-dialog-title'
                    aria-describedby='Budget-Edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {
                                    title
                                }
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill bcg0'>
                        <Typography color='inherit' className='mr20 fs25 fwm cpd'>
                            {type.toLocaleUpperCase()} BUDGETED UNITS
                        </Typography>
                        {
                            isLoading &&
                            <div className='fdc flx1 aic jcc'>
                                <CircularProgress />
                            </div>
                        }
                        {
                            !isLoading &&
                            <div className='fdr aifs jcfs mb15'>
                                <div className='fdc flx1 aifs jcfs mr15 mb8'>
                                    <DatePicker
                                        label={'Start Date'}
                                        onChange={this.onDateChange}
                                        value={date}
                                        disabled={!!value}
                                        format={'MMM YYYY'}
                                        views={['month', 'year']}
                                        fullWidth
                                        TextFieldComponent={OutlinedTextField}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Icon>date_range</Icon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!date}
                                        required
                                    />
                                    {
                                        !date &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </div>
                                <div className='fdc flx1 aifs jcfs mr15 mb8'>
                                    <CompanySelector
                                        value={company?.id}
                                        onChange={this.onCompanyChange}
                                        disabled={!!value || isLoading}
                                        variant='outlined'
                                        required
                                    />
                                    {
                                        !company &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                </div>
                                <div className='fdr flx1 aifs jcfs mr15'>
                                    <TransformerAreaDropdown
                                        label='Area'
                                        type={type}
                                        fullWidth
                                        value={area}
                                        onChange={this.onAreaChange}
                                        disabled={!!value || isLoading}
                                        variant='outlined'
                                        required
                                    />
                                </div>
                                <div className='fdr flx1 aifs jcfs mr15'>
                                    <TransformerDropdown
                                        value={transformer ?? ''}
                                        fullWidth
                                        onChange={this.onTransformerChange}
                                        type={type}
                                        variant='outlined'
                                    />
                                </div>
                                <div className='fdr flx1 aifs jcfs mr15'>
                                    <TransformerAccountDropdown
                                        value={accountNumber ?? ''}
                                        fullWidth
                                        onChange={this.onAccountNumberChange}
                                        type={type}
                                        variant='outlined'
                                    />
                                </div>
                                <div className='fdr flx1 aifs jcfs mr15 mb8'>
                                    <PeriodDropdown
                                        value={period}
                                        fullWidth
                                        onChange={this.onPeriodChange}
                                        startMonth={date?.valueOf() ?? 0}
                                        disabled={!date}
                                        variant='outlined'
                                    />
                                </div>
                                <div className='fdr aic jcc mt12'>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={hideDetail}
                                                color='primary'
                                                checked={hideDetail}
                                                onChange={this.onHideDetailChange}
                                            />
                                        }
                                        labelPlacement='end'
                                        label={'Hide Detail'}
                                    />
                                </div>
                                
                            </div>
                        }
                        {
                            !isLoading &&
                            type === 'spu' &&
                            !!date &&
                            !!area &&
                            !!company &&
                            <div className='fdc flx1'>
                                {
                                    <SPUBudgetForm
                                        hideDetail={hideDetail}
                                        startDate={date.valueOf()}
                                        transformers={transformers}
                                        initialValue={value}
                                        onSubmit={this.onSPUSubmit}
                                        accountNumber={accountNumber}
                                        area={area}
                                        company={company}
                                        period={period}
                                        transformer={transformer}
                                    />
                                }
                            </div>
                        }
                        {
                            !isLoading &&
                            type === 'lpu' &&
                            !!date &&
                            !!area &&
                            !!company &&
                            <div className='fdc flx1'>
                                {
                                    <LPUBudgetForm
                                        hideDetail={hideDetail}
                                        startDate={date.valueOf()}
                                        transformers={transformers}
                                        initialValue={value}
                                        onSubmit={this.onLPUSubmit}
                                        accountNumber={accountNumber}
                                        area={area}
                                        company={company}
                                        period={period}
                                        transformer={transformer}
                                    />
                                }
                            </div>
                        }
                        {
                            !isLoading &&
                            type === 'municipality' &&
                            !!date &&
                            !!area &&
                            !!company &&
                            <div className='fdc flx1'>
                                {
                                    <MunicipalityBudgetForm
                                        hideDetail={hideDetail}
                                        startDate={date.valueOf()}
                                        transformers={transformers}
                                        initialValue={value}
                                        onSubmit={this.onMunicipalitySubmit}
                                        accountNumber={accountNumber}
                                        area={area}
                                        company={company}
                                        period={period}
                                        transformer={transformer}
                                    />
                                }
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoadingTransformers: state.transformer.loading,
        session: state.auth.session,
        companies: state.data.companies,
    };
};

export default connect(
    mapStateToProps,
)(BudgetEditDialog);
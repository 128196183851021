import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ITransformerMeter } from '../../../@types/model/transformer/transformer';

interface ITransformerLatestMeterViewProps {
    meter : ITransformerMeter;
}

interface ITransformerLatestMeterViewState {}

export default class TransformerLatestMeterView extends React.Component<ITransformerLatestMeterViewProps, ITransformerLatestMeterViewState> {
    constructor(props : ITransformerLatestMeterViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { meter } = this.props;
        return (
            <React.Fragment>
                <div className={'fdr pl10 pr10 pt10 mb10'}>
                    <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                        NUMBER (M{meter.ItemNumber})
                    </Typography>
                    <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                        NUMBER OF DIGITS (M{meter.ItemNumber})
                    </Typography>
                    <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                        MEASUREMENT UNIT (M{meter.ItemNumber})
                    </Typography>
                    <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                        LAST READING (M{meter.ItemNumber})
                    </Typography>
                    <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                        LATEST USAGE (M{meter.ItemNumber})
                    </Typography>
                </div>
                <div className={'fdr mb10 pl10 pr10'}>
                    <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                        {meter.Number}
                    </Typography>
                    <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                        {meter.NumberOfDigits}
                    </Typography>
                    <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                        {meter.UnitOfMeasure ?? '-'}
                    </Typography>
                    <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                        {meter.LastReading}
                    </Typography>
                    <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                        {meter.LatestMeterUsage}
                    </Typography>
                </div>
            </React.Fragment>
        );
    };
}

import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationFlowmeterPulse } from '../../../../@types/model/masterData/pumpingStation/flowmeterPulse';

type MasterDataPumpingStationFlowmeterPulseActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationFlowmeterPulseState {
    flowmeterPulses : Array<IPumpingStationFlowmeterPulse>;
    isLoading : boolean;
}
const initialState = {
    flowmeterPulses: [],
    isLoading: false,
};

export default function flowmeterPulseReducer(state : IMasterDataPumpingStationFlowmeterPulseState = initialState, action : MasterDataPumpingStationFlowmeterPulseActionType) : IMasterDataPumpingStationFlowmeterPulseState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, flowmeterPulses: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

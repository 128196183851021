import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { IErrorInformation } from '../../@types/model/errorInformation';

interface IErrorScreenProps {
    errorInformation ?: IErrorInformation;
}

const ErrorScreen = (props : IErrorScreenProps) : React.ReactElement => { 
    return (
        <div className={'flx1 fdr bcGreen'}>
            {
                <div className={'flx1 fdc aic jcc'}>
                    <img src={`${ASSET_BASE}/assets/images/error.png`} className={'posr'} />
                    <Typography className={'fs50 fwb cw'}>{props.errorInformation?.message ?? ''}</Typography>
                </div>
            }
        </div>
    );
};

export default ErrorScreen;

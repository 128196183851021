import { Card, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import BoreholeSensorAuditHelper, { IBoreholeSensorAudit } from '../../../../@types/model/borehole/boreholeSensorAudit';
import { IRootState } from '../../../../@types/redux';
import AppFunctionsService from '../../../../services/appFunctionsService';
import GeneralFunctions from '../../../../store/general/functions';
import CarouselImageViewer from '../../../customComponents/image/CarouselViewer';
import FirebaseStorageImage from '../../../customComponents/image/FirebaseStorageImage';

interface IBoreholeSensorInstallationViewProps {
    borehole : IBorehole;

    isLoading : boolean;

    isBoreholeAdmin : boolean;
}

interface IBoreholeSensorInstallationViewState {
    audit : IBoreholeSensorAudit | null;
    isLoading : boolean;

    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

class BoreholeSensorInstallationViewComponent extends React.PureComponent<IBoreholeSensorInstallationViewProps, IBoreholeSensorInstallationViewState> {
    private stop ?: () => void;
    constructor(props : IBoreholeSensorInstallationViewProps) {
        super(props);
        this.state = {
            audit: null,
            isLoading: false,
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    public readonly componentWillUnmount = () => {
        if (!this.stop) return;

        this.stop();
    };

    public readonly componentDidMount = () => {
        const { borehole } = this.props;

        try {
            this.setLoading(true);
            this.stop = BoreholeSensorAuditHelper
                .listen(borehole.Code)
                .orderBy('CreatedOn', 'desc')
                .limit(1)
                .onSnapshot((snapshot) => {
                    this.setLoading(false);

                    this.setState({
                        audit: snapshot.empty ? null : snapshot.docs[0].data(),
                    });
                }, (ex) => {
                    GeneralFunctions.generalShowError(ex, 'An error while loading borehole sensor.');
                });
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'An error while loading borehole sensor.');
            this.setLoading(false);
        }
    };

    private readonly setLoading = (isLoading : boolean) => {
        this.setState({
            isLoading,
        });
    };

    private readonly onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { isLoading, audit, imageSrcs, imageIndex, isCarouselOpen } = this.state;
        return (
            <div className={'fdc flx1 mt15'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    audit &&
                    <div className={'flx1 fdr aifs jcc'}>
                        <Card className={'bcw mt30 fdc'}>
                            <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                                <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                    SENSOR AUDIT {AppFunctionsService.formatDateTimeToDateOnly(audit.CreatedOn)}
                                </Typography>
                            </div>
                            <div className={'fdr ml17 mr17 mb13'}>
                                {
                                    audit.HasLevelSensor &&
                                    <div className={'mt9 fdr'}>
                                        <Card className={'bcg0 fdc'}>
                                            <div className={'jcc aic wfill bcp h50'}>
                                                <Typography className={'fs14 fw500 cw '}>
                                                    LEVEL SENSOR
                                                </Typography>
                                            </div>
                                            <div className='fdr'>
                                                <div className={'fdc w200'}>
                                                    <div className={'fdc jcc aic p10'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            TYPE
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.LevelSensorType}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10 bcw'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            BAR
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.LevelSensorBar}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            OUTPUT
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.LevelSensorOut}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10 bcw'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            SUPPLY
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.LevelSensorSupply}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            SERIAL NUMBER
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.LevelSensorSerialNr}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10 bcw'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            CABLE LENGTH
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {
                                                                audit.LevelSensorCableLength &&
                                                                `${audit.LevelSensorCableLength.toFixed(2)}`
                                                            }
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className={'fdc'}>
                                                    {
                                                        audit.FlowMeterFileName &&
                                                        <div className='fdc p15 aic jcc mw200 flx1'>
                                                            <div className='fdc jcc aic flx1'>
                                                                <FirebaseStorageImage
                                                                    fileName={audit.LevelSensorFileName}
                                                                    path={`borehole/${audit.BoreholeCode}/sensorAudit`}
                                                                    onClick={this.onThumbClick}
                                                                    onLoaded={this.onImageUrlLoaded}
                                                                />
                                                            </div>
                                                            <Typography className={'fs13 fw700 cp'}>
                                                                SERIAL NUMBER PHOTO
                                                            </Typography>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                }
                                {
                                    audit.HasLevelSensor &&
                                    audit.HasFlowMeter &&
                                    <div className={'mt9 fdr mr30'}>
                                    </div>
                                }
                                {
                                    audit.HasFlowMeter &&
                                    <div className={'mt9 fdr'}>
                                        <Card className={'bcg0 fdc'}>
                                            <div className={'jcc aic wfill bcp h50'}>
                                                <Typography className={'fs14 fw500 cw '}>
                                                    FLOW METER SENSOR
                                                </Typography>
                                            </div>
                                            <div className='fdr'>
                                                <div className={'fdc w200'}>
                                                    <div className={'fdc jcc aic p10'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            MODEL
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.FlowMeterModel}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10 bcw'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            TYPE
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.FlowMeterType}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            SERIAL NUMBER
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.FlowMeterSerialNr}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10 bcw'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            PIPE DIAMETER
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {
                                                                audit.LevelSensorCableLength &&
                                                                `${audit.FlowMeterPipeDiameter} mm`
                                                            }
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            EV
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.FlowMeterEV}
                                                        </Typography>
                                                    </div>
                                                    <div className={'fdc jcc aic p10 bcw'}>
                                                        <Typography className={'fs11 fw500 cp'}>
                                                            OUTPUT
                                                        </Typography>
                                                        <Typography className={'fs13 fw700 cb'}>
                                                            {audit.FlowMeterOutput}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className={'fdc'}>
                                                    {
                                                        audit.FlowMeterFileName &&
                                                        <div className='fdc p15 aic jcc mw200 flx1'>
                                                            <div className='fdc jcc aic flx1'>
                                                                <FirebaseStorageImage
                                                                    fileName={audit.FlowMeterFileName}
                                                                    path={`borehole/${audit.BoreholeCode}/sensorAudit`}
                                                                    onClick={this.onThumbClick}
                                                                    onLoaded={this.onImageUrlLoaded}
                                                                />
                                                            </div>
                                                            <Typography className={'fs13 fw700 cp'}>
                                                                SERIAL NUMBER PHOTO
                                                            </Typography>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                }
                            </div>
                        </Card>
                    </div>
                }
                {
                    !isLoading &&
                    !audit &&
                    <div className='fdc flx1 aic jcc'>
                        No Data
                    </div>
                }
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
        isLoading: state.borehole.loading,
    };
};

const BoreholeSensorInstallationView = connect(
    mapStateToProps,
)(BoreholeSensorInstallationViewComponent);

export default BoreholeSensorInstallationView;

import { combineReducers } from 'redux';
import filterSizeReducer from './filterSize/reducer';
import filterTypeReducer from './filterType/reducer';
import flowmeterMakeReducer from './flowmeterMake/reducer';
import flowmeterPulseReducer from './flowmeterPulse/reducer';
import flowmeterSizeReducer from './flowmeterSize/reducer';
import motorEfficiencyReducer from './motorEfficiency/reducer';
import motorMakeReducer from './motorMake/reducer';
import motorMountingReducer from './motorMounting/reducer';
import motorPoleReducer from './motorPole/reducer';
import motorSizeReducer from './motorSize/reducer';
import motorTypeReducer from './motorType/reducer';
import pumpMakeReducer from './pumpMake/reducer';
import pumpTypeReducer from './pumpType/reducer';
import voluteCasingMakeReducer from './voluteCasingMake/reducer';

export const masterDataPumpingStationReducer = combineReducers({
    pumpMake: pumpMakeReducer,
    pumpType: pumpTypeReducer,
    motorMake: motorMakeReducer,
    motorSize: motorSizeReducer,
    motorPole: motorPoleReducer,
    motorEfficiency: motorEfficiencyReducer,
    motorType: motorTypeReducer,
    motorMounting: motorMountingReducer,
    flowmeterSize: flowmeterSizeReducer,
    filterSize: filterSizeReducer,
    filterType: filterTypeReducer,
    flowmeterMake: flowmeterMakeReducer,
    flowmeterPulse: flowmeterPulseReducer,
    voluteCasingMake: voluteCasingMakeReducer,
});
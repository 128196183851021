import React from 'react';
import Typography from '@material-ui/core/Typography';
import FirebaseAudioButton from '../../customComponents/button/FirebaseAudioButton';
import FirebaseImageButton from '../../customComponents/button/FirebaseImageButton';
import { ITransformerReading } from '../../../@types/model/transformer/transformerReading';

interface ITransformerReadingEntryViewProps {
    reading : ITransformerReading;
}

interface ITransformerReadingEntryViewState {}

export default class TransformerReadingEntryView extends React.Component<ITransformerReadingEntryViewProps, ITransformerReadingEntryViewState> {
    constructor(props : ITransformerReadingEntryViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { reading } = this.props;
        return (
            <div className='fdc'>
                {
                    !!reading.TransformerMeterNumberReadings.length &&
                    <div className='fdr flx1'>
                        <div className={'fdc flx1 m10'}>
                            <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                READINGS
                            </Typography>
                            {
                                reading.TransformerMeterNumberReadings.map(n => (
                                    <div key={`${n.ItemNumber}_${n.MeterNumber}_reading`} className={'fdc'}>
                                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                                NUMBER (M{n.ItemNumber})
                                            </Typography>
                                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                                NUMBER OF DIGITS (M{n.ItemNumber})
                                            </Typography>
                                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                                READING (M{n.ItemNumber})
                                            </Typography>
                                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                                USAGE (M{n.ItemNumber})
                                            </Typography>
                                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                            </Typography>
                                        </div>
                                        <div className={'fdr mb10 pl10 pr10'}>
                                            <Typography className={'flx1 pl10 pr10 mnh48 jcc'} variant='subtitle1'>
                                                {n.MeterNumber}
                                            </Typography>
                                            <Typography className={'flx1 pl10 pr10 mnh48 jcc'} variant='subtitle1'>
                                                {n.NumberOfDigits}
                                            </Typography>
                                            <Typography className={'flx1 pl10 pr10 mnh48 jcc'} variant='subtitle1'>
                                                {n.Reading}
                                            </Typography>
                                            <Typography className={'flx1 pl10 pr10 mnh48 jcc'} variant='subtitle1'>
                                                {n.Usage}
                                            </Typography>
                                            <div className={'flx1 pl10 pr10 mnh48 jcc'}>
                                                {
                                                    n.RecordingFileName &&
                                                    <FirebaseAudioButton fileName={n.RecordingFileName} path={`electricalpoint/${reading.Electricalpoint.id}/readings`} />
                                                }
                                                {
                                                    n.ImageFileName &&
                                                    <FirebaseImageButton fileName={n.ImageFileName} path={`electricalpoint/${reading.Electricalpoint.id}/readings`} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    !!reading.Conditions.length &&
                    <div className='fdr flx1'>
                        <div className={'fdc flx1 m10'}>
                            <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                PROBLEMS
                            </Typography>
                            <div className={'fdr pl10 pr10 pt10 mb10'}>
                                {reading.Conditions.join(', ')}
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

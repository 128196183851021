import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { TRANSFORMER_MAP_IMAGES } from '../../../appConstants';
import TransformerFunctions from '../../../store/transformer/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditLocationDialog from '../../customComponents/dialog/LocationEditDialog';

interface ILocationInfoViewProps {
    transformer : ITransformer;
    isLoading : boolean;
    session : IUserSession;
}

interface ILocationInfoViewState {}

export default class LocationInfoView extends React.PureComponent<ILocationInfoViewProps, ILocationInfoViewState> {
    constructor(props : ILocationInfoViewProps) {
        super(props);
        this.state = {};
    }

    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint) => {
        const transformer = Object.assign({}, this.props.transformer);

        transformer.Geom = geom;

        const result = await TransformerFunctions.saveTransformer(transformer);
        
        if (!result) throw new Error('');
    };

    public readonly render = () => {
        const { transformer, isLoading, session } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg0'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                LOCATION INFO
                            </Typography>
                            <span className='flx1' />
                            {
                                session.employee.IsTransformerAdmin &&
                                <EditLocationDialog
                                    markerImage = {TRANSFORMER_MAP_IMAGES['1']}
                                    markerImageSize = {0.35}
                                    elevation={0}
                                    geom={transformer.Geom}
                                    transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                    onSave={this.onLocationSave}
                                />
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc'>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                LATITUDE
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                LONGITUDE
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.Geom ? transformer.Geom.latitude : '-'}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {transformer.Geom ? transformer.Geom.longitude : '-'}
                            </Typography>
                        </div>
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ITransformerGroup, TransformerGroupHelper } from '../../../../@types/model/transformer/transformerGroup';
import StandardFab from '../../../customComponents/button/StandardFab';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { IAuthState } from '../../../../@types/redux';
import moment from 'moment';
import generalFunctions from '../../../../store/general/functions';
import MeterTypeSelector from '../../../customComponents/selector/transformer/MeterTypeSelector';
import { ITransformer, ITransformerCompany, TransformerMeterTypeUpperCase } from '../../../../@types/model/transformer/transformer';
import CompanySelector from '../../../customComponents/selector/CompanySelector';
import { ICompany } from '../../../../@types/model/masterData/company';
import { FormControlLabel, Switch } from '@material-ui/core';
import TransformerDropdown from '../../../customComponents/selector/transformer/TransformerSelector';
import { dispatch } from '../../../../store';
import DataActions from '../../../../store/data/actions';

interface ITransformerGroupEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    group ?: ITransformerGroup;

    isLoading : boolean;
    auth : IAuthState;
}

interface ITransformerGroupEditDialogState {
    open : boolean;

    code : string;
    name : string;
    meterType : TransformerMeterTypeUpperCase | '';
    company : ITransformerCompany | null;
    paymentEnabled : boolean;
    mainAccount : string | null;
    isActive : boolean;
}

export default class TransformerGroupEditDialog extends React.PureComponent<ITransformerGroupEditDialogProps, ITransformerGroupEditDialogState> {
    constructor(props : ITransformerGroupEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            code: '',
            name: '',
            meterType: '',
            company: null,
            paymentEnabled: true,
            mainAccount: null,
            isActive: true,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });

        if (this.props.group) {
            this.setState({
                code: this.props.group.code,
                name: this.props.group.name,
                meterType: this.props.group.type,
                company: this.props.group.company,
                paymentEnabled: this.props.group.paymentEnabled ?? true,
                mainAccount: this.props.group.mainAccount ?? null,
                isActive: this.props.group.isActive,
            });
        }
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
            code: '',
            name: '',
            meterType: '',
            company: null,
            paymentEnabled: true,
            mainAccount: '',
            isActive: true,
        });
    };

    private readonly save = async (
        code : string,
        name : string,
        type : TransformerMeterTypeUpperCase | '',
        company : ITransformerCompany | null,
        paymentEnabled : boolean,
        mainAccount : string | null,
        isActive : boolean,
    ) => {
        dispatch(DataActions.setLoadingTransformerGroups(true));
        const group = this.props.group ? {
            ...this.props.group,
        } : {
            createdBy: this.props.auth.session?.firebaseUser.uid ?? '',
            createdByEmployee: this.props.auth.session?.employee.EmployeeNumber ?? '',
            createdByName: this.props.auth.session?.employee.Name ?? '',
            createdOn: moment.utc().valueOf(),
        } as ITransformerGroup;

        group.code = code;
        group.name = name;
        group.type = type;
        group.company = company;
        group.paymentEnabled = paymentEnabled;
        group.mainAccount = mainAccount;

        group.updatedBy = this.props.auth.session?.firebaseUser.uid ?? '';
        group.updatedByEmployee = this.props.auth.session?.employee.EmployeeNumber ?? '';
        group.updatedByName = this.props.auth.session?.employee.Name ?? '';
        group.updatedOn = moment.utc().valueOf();
        group.isActive = isActive;

        try {
            await TransformerGroupHelper.save(group);
            this.onClose();
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error Saving Group');
        }
        dispatch(DataActions.setLoadingTransformerGroups(false));
    };

    private readonly onCodeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            code: event.currentTarget.value,
        });
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const {
            code,
            name,
            meterType,
            company,
            paymentEnabled,
            mainAccount,
            isActive,
        } = this.state;

        if (!code || !name || !meterType || !company) return;

        this.save(
            code,
            name,
            meterType,
            company,
            paymentEnabled,
            mainAccount,
            isActive,
        );
    };

    private readonly onMeterTypeChange = (meterType : TransformerMeterTypeUpperCase | '') => {
        this.setState({
            meterType,
        });
    };

    private onCompanyChange = (company ?: ICompany | null) => {
        this.setState({
            company: !company ? null : {
                code: company.code,
                electricityAccountNumber: company.electricityAccountNumber,
                id: company.id,
                interestAccountNumber: company.interestAccountNumber,
                name: company.name,
                vatAccountNumber: company.vatAccountNumber,
                paymentEnabled: company.paymentEnabled,
            },
        });
    };

    private readonly onPaymentEnableChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            paymentEnabled: checked,
        });
    };

    private readonly onMainAccountChange = (value ?: ITransformer) => {
        this.setState({
            mainAccount: value?.EPNumber ?? null,
        });
    };

    private readonly onIsActiveChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            isActive: checked,
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            group,
            isLoading,
        } = this.props;

        const {
            open,
            name,
            code,
            meterType,
            company,
            paymentEnabled,
            mainAccount,
            isActive,
        } = this.state;
        return (
            <div>
                <Tooltip title={!group ? 'Add' : 'Edit'}>
                    <div>
                        {
                            !group &&
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onClick}>
                                <AddIcon />
                            </StandardFab>

                        }
                        {
                            group &&
                            <IconButton color='primary' onClick={this.onClick}
                                aria-label='Edit' disabled={isLoading }>
                                <Icon>edit</Icon>
                            </IconButton>

                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Edit-Transformer-Bank-dialog-title'
                    aria-describedby='Edit-Transformer-Bank-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!group ? 'Create' : 'Edit'} Group
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 ais hfill'>
                            <div className='fdc flx1 ais jcc'>
                                <div className='fdr ais'>
                                    <div className='fdc flx1 aifs pr5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='code'
                                                label='Code'
                                                value={code}
                                                onChange={this.onCodeChange}
                                                required
                                                error={!code}
                                            />
                                            {
                                                !code &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 aifs pl5'>
                                        <FormControl fullWidth required>
                                            <TextField
                                                autoComplete='off'
                                                id='name'
                                                label='Name'
                                                value={name}
                                                onChange={this.onNameChange}
                                                required
                                                error={!name}
                                            />
                                            {
                                                !name &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='fdr aifs mt15'>
                                    <div className='fdc flx1 ais pr5'>
                                        <MeterTypeSelector value={meterType} onChange={this.onMeterTypeChange} required />
                                    </div>
                                    <div className='fdc flx1 ais pl5'>
                                        <FormControl fullWidth>
                                            <CompanySelector
                                                value={company?.id}
                                                required
                                                onChange={this.onCompanyChange}
                                                disabled={isLoading}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='fdr jcc mt15'>
                                    <div className='fdc flx1 ais jcc pr5'>
                                        <FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        value={paymentEnabled}
                                                        color='primary'
                                                        checked={paymentEnabled}
                                                        onChange={this.onPaymentEnableChange}
                                                    />
                                                }
                                                label={'Include in Payment?'}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 ais pl5'>
                                        <TransformerDropdown
                                            value={mainAccount ?? ''}
                                            fullWidth
                                            onChange={this.onMainAccountChange}
                                            type={meterType.toLocaleLowerCase()}
                                            label='Main Account'
                                        />
                                        <FormHelperText>This will change the selected transformer&apos;s group.</FormHelperText>
                                    </div>
                                </div>
                                <div className='fdr jcc'>
                                    <div className='fdc flx1 ais jcc pr5'>
                                        <FormControl>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        value={isActive}
                                                        color='primary'
                                                        checked={isActive}
                                                        onChange={this.onIsActiveChange}
                                                    />
                                                }
                                                label={'Is Active?'}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='fdc flx1 ais pl5'>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <OutlinedStadiumButton disabled={isLoading} className='mr16' variant='text' type='reset' onClick={this.onClose}>
                                CANCEL
                            </OutlinedStadiumButton>
                            <StadiumButton variant='contained' disabled={isLoading} type='submit' >
                                SAVE
                            </StadiumButton>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    };
}

import { Card, Icon, Toolbar, Tooltip } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { ICompany } from '../../../@types/model/masterData/company';
import { IRootState } from '../../../@types/redux';
import DataFunctions from '../../../store/data/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import MaterialTable from '../../customComponents/materialTable/Table';
import CompanyEditDialog from './Edit';

interface ICompanyListProps {
    companies : Array<ICompany>;
    isLoading : boolean;
}

interface ICompanyListState {}

class CompanyListComponent extends React.PureComponent<ICompanyListProps, ICompanyListState> {
    constructor(props : ICompanyListProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        DataFunctions.getCompanies();
    };

    public readonly render = () => {
        const { isLoading, companies } = this.props;
        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'fdc mh0 mw0'}>
                    <Toolbar disableGutters>
                    </Toolbar>
                </div>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<ICompany>
                        id='companiesTable'
                        data={companies}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        columns={[{
                            header: '',
                            field: 'id',
                            width: 50,
                            renderCell: row => (
                                <div className='aic'>
                                    <CompanyEditDialog company={row} fullWidth maxWidth='sm' transition={Transitions.Down} />
                                </div>
                            ),
                        }, {
                            header: 'Code',
                            field: 'code',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Name',
                            field: 'name',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'VAT Account',
                            field: 'vatAccountNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Electricity Account',
                            field: 'electricityAccountNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Payment Enabled',
                            field: 'paymentEnabled',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: n => !n.paymentEnabled ? (<Icon className='cr aic'>close</Icon>) : (<Icon className='cdg aic'>check</Icon>),
                        }, {
                            header: 'Created By',
                            field: 'createdByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.createdByEmployee}>
                                    <div>
                                        {
                                            row.createdByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }, {
                            header: 'Updated By',
                            field: 'updatedByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.updatedByEmployee}>
                                    <div>
                                        {
                                            row.updatedByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <CompanyEditDialog fullWidth maxWidth='sm' transition={Transitions.Down} />
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.data.isLoadingCompanies,
        companies: state.data.companies,
    };
};

const CompanyList = connect(
    mapStateToProps,
)(CompanyListComponent);

export default CompanyList;

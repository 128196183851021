import { firestore } from 'firebase/app';
import { Moment } from 'moment';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';
import DamHelper, { EnumDamHazardPotential, EnumDamSize, EnumDamWallType, IDam } from './dam';
import * as Yup from 'yup';
import { YupSchema } from '../../../services/helper/yupHelper';
import uuid from 'uuid';
import moment from 'moment';

export interface IDamRegistration extends IBasicBaseClass {
    damRef : string; // Dam Id
    damCode : string;
    date : number;

    guid : string;
    documentName : string | null;
    documentURL : string | null;
    employeeNumber : string;
    employeeName : string;

    nameOfDam : string | null;
    damNumber : string | null;
    warmsDamId : string | null;
    waterManagementArea : string | null;
    quaternaryDrainageArea : string | null;
    latitude : number | null;
    longitude : number | null;
    nearestTown : string | null;
    distanceFromTown : number | null;
    nameOfFarm : string | null;
    district : string | null;
    province : string | null;
    provinceOffice : string | null;
    completionDate : number | null;
    completionDateRaised : number | null;
    riverOrWaterCourse : string | null;
    wallType : EnumDamWallType | null;
    wallHeight : number | null;
    crestLength : number | null;
    spillwayType : string | null;
    capacity : number | null;
    surfaceArea : number | null;
    catchmentArea : number | null;
    purpose : string | null;
    owner : string | null;
    designer : string | null;
    contractor : string | null;
    registrationDate : number | null;
    size : EnumDamSize | null;
    hazardPotential : EnumDamHazardPotential | null;
    category : number | null;
    classificationDate : number | null;
    sector : string | null;
    lastDSEDate : number | null;
    lastDSENumber : number | null;
    nextDSEDate : number | null;

    isSent : boolean;
    isActive : boolean;
}

export interface IDamRegistrationFormValues {
    guid : string;
    date : Moment;

    employeeNumber : string | null;
    employeeName : string | null;



    nameOfDam : string | null;
    damNumber : string | null;
    warmsDamId : string | null;
    waterManagementArea : string | null;
    quaternaryDrainageArea : string | null;
    latitude : number | null;
    longitude : number | null;
    nearestTown : string | null;
    distanceFromTown : number | null;
    nameOfFarm : string | null;
    district : string | null;
    province : string | null;
    provinceOffice : string | null;
    completionDate : Moment | null;
    completionDateRaised : Moment | null;
    riverOrWaterCourse : string | null;
    wallType : EnumDamWallType | null;
    wallHeight : number | null;
    crestLength : number | null;
    spillwayType : string | null;
    capacity : number | null;
    surfaceArea : number | null;
    catchmentArea : number | null;
    purpose : string | null;
    owner : string | null;
    designer : string | null;
    contractor : string | null;
    registrationDate : Moment | null;
    size : EnumDamSize | null;
    hazardPotential : EnumDamHazardPotential | null;
    category : number | null;
    classificationDate : Moment | null;
    sector : string | null;
    lastDSEDate : Moment | null;
    lastDSENumber : number | null;
    nextDSEDate : Moment | null;
}

type YupShape = Record<keyof IDamRegistrationFormValues, YupSchema>;

export default class DamRegistrationHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'dam_registration';

    private static converter : firebase.firestore.FirestoreDataConverter<IDamRegistration> = {
        fromFirestore: (snapshot) => {
            return DamRegistrationHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDamRegistration) : firebase.firestore.DocumentData => {
            return DamRegistrationHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDamRegistration {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IDamRegistration,
            ...result,
            damRef: (data['damRef'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
            completionDate: (data['completionDate'] as firestore.Timestamp | null)?.toMillis() ?? null,
            completionDateRaised: (data['completionDateRaised'] as firestore.Timestamp | null)?.toMillis() ?? null,
            registrationDate: (data['registrationDate'] as firestore.Timestamp | null)?.toMillis() ?? null,
            classificationDate: (data['classificationDate'] as firestore.Timestamp | null)?.toMillis() ?? null,
            lastDSEDate: (data['lastDSEDate'] as firestore.Timestamp | null)?.toMillis() ?? null,
            nextDSEDate: (data['nextDSEDate'] as firestore.Timestamp | null)?.toMillis() ?? null,
        };
    }

    public static toFirestoreDocument(data : IDamRegistration) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            damRef: DamHelper.doc(data.damRef),
            date: firestore.Timestamp.fromMillis(data.date),
            completionDate: !data.completionDate ? null : firestore.Timestamp.fromMillis(data.completionDate),
            completionDateRaised: !data.completionDateRaised ? null : firestore.Timestamp.fromMillis(data.completionDateRaised),
            registrationDate: !data.registrationDate ? null : firestore.Timestamp.fromMillis(data.registrationDate),
            classificationDate: !data.classificationDate ? null : firestore.Timestamp.fromMillis(data.classificationDate),
            lastDSEDate: !data.lastDSEDate ? null : firestore.Timestamp.fromMillis(data.lastDSEDate),
            nextDSEDate: !data.nextDSEDate ? null : firestore.Timestamp.fromMillis(data.nextDSEDate),
        };
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .withConverter(this.converter);
    }

    public static save(data : IDamRegistration) {
        if (!data.id) {
            return DamRegistrationHelper.doc().set(data, {
                merge: true,
            });
        }

        return DamRegistrationHelper.doc(data.id).set(data, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().withConverter(DamRegistrationHelper.converter).doc();
        }

        return this.collection().withConverter(DamRegistrationHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return this.doc(id)
            .delete();
    }

    public static uploadFile(damCode : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `dam/${damCode}/dam_registration/${new Date().valueOf()}_${file.name}`, metadata);
    }

    public static initialFormValues(damRegistration ?: IDamRegistration, dam ?: IDam) : IDamRegistrationFormValues {
        return {
            guid: damRegistration?.guid ?? uuid.v4(),
            date: moment.utc(damRegistration?.date).startOf('day'),
            employeeName: damRegistration?.employeeName ?? '',
            employeeNumber: damRegistration?.employeeNumber ?? '',
            nameOfDam: dam?.name ?? '',
            capacity: damRegistration?.capacity ?? dam?.capacity ?? null,
            catchmentArea: damRegistration?.catchmentArea ?? dam?.catchmentArea ?? null,
            classificationDate: damRegistration?.classificationDate?.toMoment() ?? dam?.classificationDate?.toMoment() ?? null,
            category: damRegistration?.category ?? null,
            completionDate: damRegistration?.completionDate?.toMoment() ?? dam?.completionDate?.toMoment() ?? null,
            completionDateRaised: damRegistration?.completionDateRaised?.toMoment() ?? dam?.completionDateRaised?.toMoment() ?? null,
            contractor: damRegistration?.contractor ?? dam?.contractor ?? null,
            crestLength: damRegistration?.crestLength ?? dam?.crestLength ?? null,
            damNumber: damRegistration?.damNumber ?? null,
            designer: damRegistration?.designer ?? dam?.designer ?? null,
            distanceFromTown: damRegistration?.distanceFromTown ?? dam?.distanceFromTown ?? null,
            district: damRegistration?.district ?? null,
            hazardPotential: damRegistration?.hazardPotential ?? dam?.hazardPotential ?? null,
            lastDSEDate: damRegistration?.lastDSEDate?.toMoment() ?? null,
            lastDSENumber: damRegistration?.lastDSENumber ?? null,
            latitude: damRegistration?.latitude ?? dam?.geo?.latitude ?? null,
            longitude: damRegistration?.longitude ?? dam?.geo?.longitude ?? null,
            nameOfFarm: damRegistration?.nameOfFarm ?? null,
            nearestTown: damRegistration?.nearestTown ?? dam?.nearestTown ?? null,
            nextDSEDate: damRegistration?.nextDSEDate?.toMoment() ?? null,
            owner: damRegistration?.owner ?? dam?.ownerName ?? null,
            province: damRegistration?.province ?? null,
            provinceOffice: damRegistration?.provinceOffice ?? null,
            purpose: damRegistration?.purpose ?? dam?.purpose ?? null,
            quaternaryDrainageArea: damRegistration?.quaternaryDrainageArea ?? dam?.quaternaryDrainageArea ?? null,
            registrationDate: damRegistration?.registrationDate?.toMoment() ?? dam?.registrationDate?.toMoment() ?? null,
            riverOrWaterCourse: damRegistration?.riverOrWaterCourse ?? dam?.riverOrWaterCourse ?? null,
            sector: damRegistration?.sector ?? dam?.sector ?? null,
            size: damRegistration?.size ?? dam?.size ?? null,
            spillwayType: damRegistration?.spillwayType ?? dam?.spillwayType ?? null,
            surfaceArea: damRegistration?.surfaceArea ?? dam?.surfaceArea ?? null,
            wallHeight: damRegistration?.wallHeight ?? dam?.wallHeight ?? null,
            wallType: damRegistration?.wallType ?? dam?.wallType ?? null,
            warmsDamId: damRegistration?.warmsDamId ?? dam?.warmsId ?? null,
            waterManagementArea: damRegistration?.waterManagementArea ?? dam?.waterManagementArea ?? null,
        };
    }

    public static formSchema = () => Yup.object<YupShape>({
        guid: Yup.string().required('Required'),
        date: Yup.date().nullable().moment().required('Required'),
        employeeName: Yup.string().required('Required'),
        employeeNumber: Yup.string().required('Required'),
        nameOfDam: Yup.string().required('Required'),
        capacity: Yup.number().nullable().optional(),
        catchmentArea: Yup.number().nullable().optional(),
        classificationDate: Yup.date().nullable().moment().optional(),
        category: Yup.number().nullable().optional(),
        completionDate: Yup.date().moment().nullable().optional(),
        completionDateRaised: Yup.date().nullable().moment().optional(),
        contractor: Yup.string().nullable().optional(),
        crestLength: Yup.number().nullable().optional(),
        damNumber: Yup.string().nullable().optional(),
        designer: Yup.string().nullable().optional(),
        distanceFromTown: Yup.number().nullable().optional(),
        district: Yup.string().nullable().optional(),
        hazardPotential: Yup.number().nullable().optional().moreThan(-1, 'Invalid'),
        lastDSEDate: Yup.date().nullable().moment().optional(),
        lastDSENumber: Yup.number().nullable().optional(),
        latitude: Yup.number().nullable().optional(),
        longitude: Yup.number().nullable().optional(),
        nameOfFarm: Yup.string().nullable().optional(),
        nearestTown: Yup.string().nullable().optional(),
        nextDSEDate: Yup.date().nullable().moment().optional(),
        owner: Yup.string().nullable().optional(),
        province: Yup.string().nullable().optional(),
        provinceOffice: Yup.string().nullable().optional(),
        purpose: Yup.string().nullable().optional(),
        quaternaryDrainageArea: Yup.string().nullable().optional(),
        registrationDate: Yup.date().nullable().moment().optional(),
        riverOrWaterCourse: Yup.string().nullable().optional(),
        sector: Yup.string().nullable().optional(),
        size: Yup.number().nullable().optional().moreThan(-1, 'Invalid'),
        spillwayType: Yup.string().nullable().optional(),
        surfaceArea: Yup.number().nullable().optional(),
        wallHeight: Yup.number().nullable().optional(),
        wallType: Yup.number().nullable().optional().moreThan(-1, 'Invalid'),
        warmsDamId: Yup.string().nullable().optional(),
        waterManagementArea: Yup.string().nullable().optional(),
        
    });
}
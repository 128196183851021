import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';

export interface IDamWeeklyReading extends IBasicBaseClass {
    guid : string;
    employee : string | null;
    employeeName : string | null;
    damCode : string | null;
    dam : string | null;
    currentCapacity : number | null;
    currentVolume : number | null;
    hasMeasurementStick : boolean | null;
    measurement : number | null;
    date : firebase.firestore.Timestamp;
    imageMeasurestick : IDamImageFile | null;
    imageDam : IDamImageFile | null;
}

export interface IDamImageFile {
    guid : string;
    firestoreLocation : string;
    downloadURL : string;
    thumbnailUrl : string | null;
    name : string;
    uploadDate : number;
    contentType : string;
}

export default class DamWeeklyReadingHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'dam_weekly_reading';

    private static converter : firebase.firestore.FirestoreDataConverter<IDamWeeklyReading> = {
        fromFirestore: (snapshot) => {
            return DamWeeklyReadingHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDamWeeklyReading) : firebase.firestore.DocumentData => {
            return DamWeeklyReadingHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDamWeeklyReading {
        const result = super.fromFirestore(snapshot);
        if (!snapshot.exists || !result) throw new Error(`Document does not exist! ${snapshot.id}`);
        const data = snapshot.data() as IDamWeeklyReading;
        return {
            ...data,
            ...result,
        };
    }

    public static toFirestoreDocument(data : IDamWeeklyReading) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id : string) {
        if (!id) {
            return firebaseApp.firestore().collection(DamWeeklyReadingHelper.COLLECTION_NAME).withConverter(DamWeeklyReadingHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(DamWeeklyReadingHelper.COLLECTION_NAME).withConverter(DamWeeklyReadingHelper.converter).doc(id);
    }

    public static listen(code ?: string) {
        return firebaseApp
            .firestore()
            .collection(DamWeeklyReadingHelper.COLLECTION_NAME)
            .where('damCode', '==', code).withConverter(DamWeeklyReadingHelper.converter);
    }

    public static delete (id : string) {
        return firebaseApp
            .firestore()
            .collection(DamWeeklyReadingHelper.COLLECTION_NAME)
            .doc(id)
            .delete();
    }

    public static save(reading : IDamWeeklyReading) {
        return DamWeeklyReadingHelper.doc(reading.id).set(reading, {
            merge: true,
        });
    }
}
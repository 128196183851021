import { IMottechState, MottechAction } from '../../@types/redux';
import * as actions from './actions';
import { getType } from 'typesafe-actions';

const initialState = {
    mottechs: [],
    loading: false,
    audits: [],
    loadingAudits: false,
    insAudits: [],
    loadingInsAudits: false,
};

export default function generalReducer(state : IMottechState = initialState, action : MottechAction) : IMottechState {
    switch (action.type) {
        case getType(actions.mottechSetList):
            return { ...state, mottechs: action.payload };
        case getType(actions.mottechSetSelected):
            return { ...state, mottech: action.payload };
        case getType(actions.mottechSetLoading):
            return { ...state, loading: action.payload };
        case getType(actions.mottechSetAudits):
            return { ...state, audits: action.payload };
        case getType(actions.mottechSetAuditLoading):
            return { ...state, loadingAudits: action.payload };
        case getType(actions.mottechSetInsAudits):
            return { ...state, insAudits: action.payload };
        case getType(actions.mottechSetInsAuditLoading):
            return { ...state, loadingInsAudits: action.payload };
        default:
            return state;
    }
}

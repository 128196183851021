import { View, Text } from '@react-pdf/renderer';
import React from 'react';
import style from '../../../style/pdfStyleSheet';

interface ITransformerSPUMeterReportTableHeaderPdfProps {}

interface ITransformerSPUMeterReportTableHeaderPdfState {}

export default class TransformerSPUMeterReportTableHeaderPdf
    extends React.PureComponent<ITransformerSPUMeterReportTableHeaderPdfProps, ITransformerSPUMeterReportTableHeaderPdfState> {
    constructor(props : ITransformerSPUMeterReportTableHeaderPdfProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        return (
            <View fixed style={[style.fdr, style.ais, style.h28, style.bcg1, style.mt14, style.page, style.bbw0]}>
                <View style={[style.fdc, style.pl9, style.fww, style.aifs, style.jcc, style.h28, style.w50]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>EP</Text>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>NUMBER</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.aifs, style.jcc, style.h28, style.w80, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>DESCRIPTION</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.aifs, style.jcc, style.h28, style.w90, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>POLE</Text>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>NUMBER</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.fww, style.aifs, style.jcc, style.h28, style.w50, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>METER</Text>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>TYPE</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.fww, style.aifs, style.jcc, style.h28, style.w50, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>KVA</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.fww, style.aifs, style.jcc, style.h28, style.w90, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>METER</Text>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>NUMBER</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.fww, style.aifs, style.jcc, style.h28, style.w50, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>NUMBER</Text>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>OF DIGITS</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.fww, style.aifs, style.jcc, style.h28, style.w50, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>LAST</Text>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>READING</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.fww, style.aifs, style.jcc, style.w150, style.h28, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>CURRENT READING</Text>
                </View>
                <View style={[style.fdc, style.pl9, style.fww, style.aifs, style.jcc, style.flx1, style.h28, style.blw1, style.bbw0, style.bocg2]}>
                    <Text style={[style.fs6, style.fwb, style.cg3]}>COMMENTS</Text>
                </View>
            </View>
        );
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IBorehole } from '../../../../@types/model/borehole/borehole';

export interface IBoreholeCodeSelectorProps {
    boreholes : Array<IBorehole>;
    isLoading : boolean;

    value : string;

    onChange : (value : string) => void;

    required ?: boolean;

    label ?: string | null;
    optional ?: boolean;
    error ?: string;
}

interface IBoreholeCodeSelectorState {}

class BoreholeCodeSelector extends React.Component<IBoreholeCodeSelectorProps, IBoreholeCodeSelectorState> {
    constructor(props : IBoreholeCodeSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly getBoreholes = (props : IBoreholeCodeSelectorProps) => props.boreholes;
    public readonly getRequired = (props : IBoreholeCodeSelectorProps) => props.required;
    public readonly getOptional = (props : IBoreholeCodeSelectorProps) => props.optional;
    private readonly getValue = (props : IBoreholeCodeSelectorProps) => props.value;

    public readonly getBoreholeCodes = createSelector(
        [this.getBoreholes],
        (boreholes) => {
            return boreholes.map((e : IBorehole) => ({
                label: e.Code,
                value: e.Code,
            }));
        },
    );
    private readonly getFilterDropdown = createSelector([ 
        this.getRequired,
        this.getBoreholeCodes,
        this.getOptional,
    ], (required, boreholes, optional) => {
        
        const dropdownData = boreholes.slice();
        
        if (!required && !optional) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: {
        label : string;
        value : string;
    } | null) => {
        this.props.onChange(value?.value ?? '');
    };

    private getSelectedValue = createSelector([ 
        this.getValue,
        this.getRequired,
        this.getFilterDropdown,
        this.getOptional,
    ], (value, required, dropdownData, optional) => {
        if (value) {
            const data = dropdownData.find(element => element.value === value);

            if (data) {
                return { label: data.label, value: data.value };
            } else {
                return { label: value, value: value };
            }
        }

        if (!value && !required && !optional) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { required, isLoading, label = 'Borehole Code', error } = this.props;

        const codes = this.getFilterDropdown(this.props);

        const value = this.getSelectedValue(this.props);


        return (
            <FormControl fullWidth required={required} disabled={isLoading}>
                <Autocomplete
                    options={codes}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    renderInput={params =>
                        <TextField
                            error={(required && !value) || !!error}
                            {...params}
                            fullWidth={true}
                            label={label}
                            inputProps={label ? params.inputProps : {
                                ...params.inputProps,
                                className: 'cp fwb',
                            }}
                        />
                    }
                />
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        boreholes: state.borehole.boreholes,
        isLoading: state.borehole.loading,
    };
};

export default connect(
    mapStateToProps,
)(BoreholeCodeSelector);

import { IAuthState, AuthAction } from '../../@types/redux';
import { getType } from 'typesafe-actions';
import AuthActions from './actions';

const initialAuthState = {
    isLoggedIn: false,
    session: undefined,
    isLoggingIn: false,
};

export default function authReducer(state : IAuthState = initialAuthState, action : AuthAction) : IAuthState {
    switch (action.type) {
        case getType(AuthActions.authSetSession):
            return { ...state, session: action.payload };
        case getType(AuthActions.authSetLoggedIn):
            return { ...state, isLoggedIn: action.payload };
        case getType(AuthActions.authSetLoggingIn):
            return { ...state, isLoggingIn: action.payload };
        default:
            return state;
    }
}

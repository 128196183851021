import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import ChemicalFillingPointFilterPopper from '../popper/ChemicalFillingStationFilterPopper';

interface IChemicalFillingPointFilterButtonProps {
    id ?: string;
    open : boolean;

    onClick ?: () => void;

    code : string | null;
    name : string | null;
    division : string | null;
    managementArea : string | null;

    onChange ?: (
        code : string | null,
        name : string | null,
        division : string | null,
        managementArea : string | null,
    ) => void;

    disabled ?: boolean;
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
}

interface IChemicalFillingPointFilterButtonState {
}

export default class ChemicalFillingPointFilterButton extends React.PureComponent<IChemicalFillingPointFilterButtonProps, IChemicalFillingPointFilterButtonState> {
    private readonly filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : IChemicalFillingPointFilterButtonProps) {
        super(props);
        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    public readonly render = () => {
        const {
            id,
            onChange,
            code,
            name,
            division,
            managementArea,
            disabled,
            onClick,
            color,
        } = this.props;
        return (
            <> 
                <Tooltip title='Filter'>
                    <div className={''}>
                        <IconButton
                            ref={this.filterRef}
                            aria-label='toggle filter menu'
                            onClick={onClick}
                            disabled={disabled}
                            color={color}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <ChemicalFillingPointFilterPopper
                    id={id}
                    anchorEl={this.filterRef.current}
                    open={this.props.open}
                    onCancelClick={onClick}
                    onChange={onChange}
                    code={code}
                    name={name}
                    division={division}
                    managementArea={managementArea}
                />
            </>
        );
    };
}

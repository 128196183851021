import { firestore } from 'firebase/app';
import { Moment } from 'moment';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { YupSchema } from '../../../services/helper/yupHelper';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';
import DamHelper from './dam';
import * as Yup from 'yup';
import moment from 'moment';
import uuid from 'uuid';

export interface IDamAerial extends IBasicBaseClass {
    damRef : string; // Dam Id
    damCode : string;
    date : number;

    guid : string;
    documentName : string | null;
    documentURL : string | null;
    documentThumbnailURL : string | null;
    employeeNumber : string;
    employeeName : string;

    photographer : string;

    isSent : boolean;
    isActive : boolean;
}

export interface IDamAerialFormValues {
    guid : string;
    date : Moment;

    employeeNumber : string | null;
    employeeName : string | null;

    photographer : string;
}

type YupShape = Record<keyof IDamAerialFormValues, YupSchema>;

export default class DamAerialHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'dam_aerial_photo';

    private static converter : firebase.firestore.FirestoreDataConverter<IDamAerial> = {
        fromFirestore: (snapshot) => {
            return DamAerialHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDamAerial) : firebase.firestore.DocumentData => {
            return DamAerialHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDamAerial {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IDamAerial,
            ...result,
            damRef: (data['damRef'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : IDamAerial) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            damRef: DamHelper.doc(data.damRef),
            date: firestore.Timestamp.fromMillis(data.date),
        };
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .withConverter(this.converter);
    }

    public static save(data : IDamAerial) {
        if (!data.id) {
            return DamAerialHelper.doc().set(data, {
                merge: true,
            });
        }

        return DamAerialHelper.doc(data.id).set(data, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().withConverter(DamAerialHelper.converter).doc();
        }

        return this.collection().withConverter(DamAerialHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return this.doc(id)
            .delete();
    }

    public static uploadFile(damCode : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `dam/${damCode}/dam_aerial/${new Date().valueOf()}_${file.name}`, metadata);
    }

    public static initialFormValues(damAerial ?: IDamAerial) : IDamAerialFormValues {
        return {
            guid: damAerial?.guid ?? uuid.v4(),
            date: moment.utc(damAerial?.date).startOf('day'),
            employeeName: damAerial?.employeeName ?? '',
            employeeNumber: damAerial?.employeeNumber ?? '',
            photographer: damAerial?.photographer ?? '',
        };
    }

    public static formSchema = () => Yup.object<YupShape>({
        guid: Yup.string().required('Required'),
        date: Yup.date().nullable().moment().required('Required'),
        employeeName: Yup.string().required('Required'),
        employeeNumber: Yup.string().required('Required'),
        photographer: Yup.string().required('Required'),
    });
}
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import weatherStationFunctions from '../../../store/weather/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import { WEATHER_STATION_SURROUNDINGS } from '../../../appConstants';
import { IWeatherStation } from '../../../@types/model/weather/weatherStation';

interface IEditWeatherStationAreaProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    weatherStation ?: IWeatherStation;
}

interface IEditWeatherStationAreaState {
    open : boolean;
    obstructionDistance : string;
    surroundings : Array<string>;
    fenced : boolean;
}

export default class EditWeatherStationArea extends React.Component<IEditWeatherStationAreaProps, IEditWeatherStationAreaState> {
    constructor(props : IEditWeatherStationAreaProps) {
        super(props);
        this.state = {
            open: false,
            fenced: false,
            obstructionDistance: '',
            surroundings: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditWeatherStationAreaProps, prevState : IEditWeatherStationAreaState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                fenced: false,
                obstructionDistance: '',
                surroundings: [],
            });
        }
        if (!prevState.open && this.state.open && this.props.weatherStation) {
            this.setState({
                fenced: !!this.props.weatherStation.Fenced,
                obstructionDistance: !this.props.weatherStation.ObstructionDistance ? '' : this.props.weatherStation.ObstructionDistance.toFixed(2),
                surroundings: this.props.weatherStation.Surroundings,
            });
        }

    };

    private readonly onObstructionDistanceChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !parseFloat(event.currentTarget.value)) return;
        this.setState({
            obstructionDistance: event.currentTarget.value,
        });
    };

    private readonly onFencedChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            fenced: checked,
        });
    };

    private readonly save = async () => {
        const { fenced, obstructionDistance, surroundings } = this.state;

        const weatherStation = Object.assign({}, this.props.weatherStation);

        weatherStation.Fenced = fenced;
        weatherStation.ObstructionDistance = !obstructionDistance ? null : parseFloat(obstructionDistance);
        weatherStation.Surroundings = surroundings;
        
        const result = await weatherStationFunctions.saveWeatherStation(weatherStation);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSurroundingsChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const surroundings = this.state.surroundings.slice();

        if (checked && !surroundings.includes(event.target.value)) {
            surroundings.push(event.target.value);
        } else if (!checked) {
            const index = surroundings.indexOf(event.target.value);
            surroundings.splice(index, 1);
        }

        this.setState({
            surroundings,
        });
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, fenced, obstructionDistance, surroundings } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='weatherStation-area-edit-dialog-title'
                    aria-describedby='weatherStation-area-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Weather Station Area
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='obstructionDistance'
                                            label='Obstruction Distance'
                                            value={obstructionDistance}
                                            onChange={this.onObstructionDistanceChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!obstructionDistance && !Number(obstructionDistance)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m
                                                    </InputAdornment>
                                                ),
                                            }} />
                                        {
                                            !!obstructionDistance && !Number(obstructionDistance) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={fenced} onChange={this.onFencedChanged} />
                                            }
                                            label='Fenced?'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                            </div>
                            <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                PROBLEMS
                            </Typography>
                            <div className={'fdc flx1 aifs'}>
                                <div className={'gc3'}>
                                    {
                                        WEATHER_STATION_SURROUNDINGS.map(n => (
                                            <div key={n} className={'flx1 ais p5 mb10 pr20'}>
                                                <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox value={n} color='primary' checked={surroundings.includes(n)} onChange={this.onSurroundingsChange} />
                                                        }
                                                        label={n}
                                                    />
                                                </FormControl>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

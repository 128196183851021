import React from 'react';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import transformerFunctions from '../../../store/transformer/functions';
import DialogContent from '@material-ui/core/DialogContent';
import LineVoltageRangeDropdown from '../../customComponents/selector/transformer/LineVoltageRangeSelector';
import { FormControl, TextField } from '@material-ui/core';

interface IEditTransformerLineProps {
    transformer : ITransformer;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditTransformerLineState {
    open : boolean;
    line : string;
    lineVoltage : [number, number] | null;
}

export default class EditTransformerLine extends React.Component<IEditTransformerLineProps, IEditTransformerLineState> {
    constructor(props : IEditTransformerLineProps) {
        super(props);
        this.state = {
            open: false,
            line: '',
            lineVoltage: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTransformerLineProps, prevState : IEditTransformerLineState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                line: '',
                lineVoltage: null,
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                line: !this.props.transformer.Line ? '' : this.props.transformer.Line,
                lineVoltage: this.props.transformer.LineVoltage,
            });
        }
    };

    private readonly save = async () => {
        const { line, lineVoltage } = this.state;

        const transformer = Object.assign({}, this.props.transformer);

        transformer.Line = line;
        transformer.LineVoltage = lineVoltage;

        const result = await transformerFunctions.saveTransformer(transformer);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onLineChanged = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            line: event.target.value,
        });
    };

    private readonly onLineVoltageChange = (value : [number, number] | null) => {
        this.setState({
            lineVoltage: value,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, line, lineVoltage } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-line-info-title'
                    aria-describedby='transformer-line-info-description'
                >
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Transformer Line Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'fdr flx1 aifs'}>
                                    <div className='fdr flx1 aifs'>
                                        <div className={'flx1 ais p5 mb10 pr20'}>
                                            <FormControl margin='normal' fullWidth>
                                                <TextField
                                                    required
                                                    label= 'Feeder Line'
                                                    disabled={isLoading}
                                                    fullWidth
                                                    id='line'
                                                    value={!line ? '' : line}
                                                    onChange={this.onLineChanged}
                                                    className={'TextField'}
                                                >
                                                </TextField>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                    <LineVoltageRangeDropdown clearable value={lineVoltage} fullWidth onChange={this.onLineVoltageChange} />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>
        );
    };
}

import { IBoreholeState, BoreholeAction } from '../../@types/redux';
import * as boreholeActions from './actions';
import { getType } from 'typesafe-actions';

const initialState = {
    boreholes: [],
    loading: false,
    loadingTests: false,
    boreholeTests: [],
    loadingAudits: false,
    boreholeAudits: [],
};

export default function generalReducer(state : IBoreholeState = initialState, action : BoreholeAction) : IBoreholeState {
    switch (action.type) {
        case getType(boreholeActions.boreholeSetBoreholes):
            return { ...state, boreholes: action.payload };
        case getType(boreholeActions.boreholeSetBorehole):
            return { ...state, borehole: action.payload };
        case getType(boreholeActions.boreholeSetLoading):
            return { ...state, loading: action.payload };
        case getType(boreholeActions.boreholeSetBoreholeTests):
            return { ...state, boreholeTests: action.payload };
        case getType(boreholeActions.boreholeSetTestLoading):
            return { ...state, loadingTests: action.payload };
        case getType(boreholeActions.boreholeSetBoreholeAudits):
            return { ...state, boreholeAudits: action.payload };
        case getType(boreholeActions.boreholeSetAuditLoading):
            return { ...state, loadingAudits: action.payload };
        default:
            return state;
    }
}


import { createAction } from 'typesafe-actions';
import { IBorehole } from '../../@types/model/borehole/borehole';
import { IBoreholeInstallationAudit } from '../../@types/model/borehole/boreholeInstallationAudit';
import { IBoreholeTest } from '../../@types/model/borehole/boreholeTest';

export const boreholeSetBoreholes = createAction('BOREHOLE_SET_BOREHOLES', resolve => (boreholes : Array<IBorehole>) => resolve(boreholes));
export const boreholeSetBorehole = createAction('BOREHOLE_SET_BOREHOLE', resolve => (borehole ?: IBorehole) => resolve(borehole));
export const boreholeSetLoading = createAction('BOREHOLE_SET_LOADING', resolve => (loading : boolean) => resolve(loading));
export const boreholeSetBoreholeTests = createAction('BOREHOLE_SET_BOREHOLE_TESTS', resolve => (boreholeTest ?: Array<IBoreholeTest>) => resolve(boreholeTest));
export const boreholeSetTestLoading = createAction('BOREHOLE_SET_TEST_LOADING', resolve => (loading : boolean) => resolve(loading));
export const boreholeSetBoreholeAudits = createAction('BOREHOLE_SET_BOREHOLE_AUDITS', resolve => (audits ?: Array<IBoreholeInstallationAudit>) => resolve(audits));
export const boreholeSetAuditLoading = createAction('BOREHOLE_SET_AUDIT_LOADING', resolve => (loading : boolean) => resolve(loading));

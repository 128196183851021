import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CableCoresSelector from '../../customComponents/selector/CableCoresSelector';
import CableSizeSelector from '../../customComponents/selector/CableSizeSelector';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IBorehole } from '../../../@types/model/borehole/borehole';

interface IEditBoreholeCableInfoProps {
    borehole : IBorehole;

    onChange : (borehole : IBorehole) => void;
}

interface IEditBoreholeCableInfoState {
}

export default class EditBoreholeCableInfo extends React.Component<IEditBoreholeCableInfoProps, IEditBoreholeCableInfoState> {
    constructor(props : IEditBoreholeCableInfoProps) {
        super(props);
        this.state = {
        };
    }

    private onCableCoresChange = (cableCores : number) => {
        this.props.onChange({
            ...this.props.borehole,
            CableCores: cableCores,
        });
    };

    private onCableSizeChange = (cableSize : number) => {
        this.props.onChange({
            ...this.props.borehole,
            CableSize: cableSize,
        });
    };

    private onCableLengthChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.props.onChange({
            ...this.props.borehole,
            CableLength: Number(event.currentTarget.value),
        });
    };

    public render = () => {
        const { borehole } = this.props;
        return (
            <div className={'fdr flx1 aifs'}>
                <CableCoresSelector value={borehole.CableCores ?? 0} onChange={this.onCableCoresChange} />
                <CableSizeSelector value={borehole.CableSize ?? 0} onChange={this.onCableSizeChange} />
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl fullWidth>
                        <TextField
                            type='number'
                            autoComplete='off'
                            id='recommendedYield'
                            label='Cable Length'
                            value={borehole.CableLength ?? 0}
                            onChange={this.onCableLengthChange}
                            margin='normal'
                            className={'TextField'}
                            error={!!borehole.CableLength && !Number(borehole.CableLength)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {
                            borehole.CableLength && !Number(borehole.CableLength) &&
                            <FormHelperText error>Invalid</FormHelperText>
                        }
                    </FormControl>
                </div>
            </div>
        );
    };
}

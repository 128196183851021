import React from 'react';
import Typography from '@material-ui/core/Typography';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IMottech } from '../../../@types/mottech';

interface IMottechConnectedViewProps {
    mottechs : Array<IMottech>;
    mottechCode : string;
}

interface IMottechConnectedViewState {}

class MottechConnectedView extends React.Component<IMottechConnectedViewProps, IMottechConnectedViewState> {
    constructor(props : IMottechConnectedViewProps) {
        super(props);
        this.state = {};
    }
    public getData = (state : IMottechConnectedViewState, props : IMottechConnectedViewProps) => props.mottechs;
    public getCode = (state : IMottechConnectedViewState, props : IMottechConnectedViewProps) => props.mottechCode;
    public getMottech = createSelector(
        [this.getData, this.getCode],
        (mottechs, code) => {
            return mottechs.find(x => x.PiccoloId === code);
        },
    );

    public render = () => {
        const mottech = this.getMottech(this.state, this.props);

        if (!mottech) {
            return (<div className={'fdr pl10 pr10 pt10 mb10'}>
                <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                    <span>Unkown Mottech Piccolo/PIU Id ({this.props.mottechCode})</span>
                </Typography>
            </div>);
        }

        return (<div className={'fdc flx1'}>
            <div className={'fdr pl10 pr10 pt10 mb10'}>
                <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                    PICCOLO/PIU ID
                </Typography>
                <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                    MOTTECH
                </Typography>
            </div>
            <div className={'fdr mb10 pl10 pr10'}>
                <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                    {mottech.PiccoloId}
                </Typography>
                <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                    {mottech.Code}
                </Typography>
            </div>
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        mottechs: state.mottech.mottechs,
    };
};

export default connect(
    mapStateToProps,
)(MottechConnectedView);

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { MOTTECH_TYPES } from '../../../../appConstants';

interface IMottechTypeSelectorProps {
    value : string;

    onChange : (value : string) => void;
}

interface IMottechTypeSelectorState {}

export default class MottechTypeSelector extends React.Component<IMottechTypeSelectorProps, IMottechTypeSelectorState> {
    constructor(props : IMottechTypeSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onMottechTypeChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (typeof event.target.value !== 'string') return;
        this.props.onChange(event.target.value);
    };

    public readonly render = () => {
        const { value } = this.props;

        return (
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!value} htmlFor='mottechType'>Mottech Type</InputLabel>
                    <Select
                        fullWidth
                        id='mottechType'
                        value={!value ? '' : value}
                        onChange={this.onMottechTypeChange}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(MOTTECH_TYPES, (n, k) => (
                                <MenuItem key={`${k}_mottechType`} value={`${k}`}>
                                    {k}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        );
    };
}

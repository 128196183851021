import * as actions from './actions';
import { getType } from 'typesafe-actions';
import { IPumpingStationState, PumpingStationAction } from '../../@types/redux';

const initialState = {
    pumpingStations: [],
    selectedPumpingStation: null,
    loading: false,
};

export default function generalReducer(state : IPumpingStationState = initialState, action : PumpingStationAction) : IPumpingStationState {
    switch (action.type) {
        case getType(actions.pumpingStationSetList):
            return { ...state, pumpingStations: action.payload };
        case getType(actions.pumpingStationSetSelected):
            return { ...state, selectedPumpingStation: action.payload };
        case getType(actions.pumpingStationSetLoading):
            return { ...state, loading: action.payload };
        default:
            return state;
    }
}

import * as React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { hashHistory } from './store';
import App from './App';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import theme from './material';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue';
import defaultTheme from './material';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './@types/extensions';

if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log('Production?', process.env.NODE_ENV);
}

interface IAppRootProps {}

class AppRoot extends React.Component<IAppRootProps> {
    protected store : any;

    constructor(props : IAppRootProps) {
        super(props);
        this.store = store;
    }

    public render() {
        return (
            <MuiThemeProvider theme={defaultTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Provider store={this.store}>
                        <ConnectedRouter history={hashHistory}>
                            <SnackbarProvider maxSnack={4}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                classes={{
                                    variantSuccess: theme.palette.primary.main,
                                    variantError: red[600],
                                    variantWarning: orange[600],
                                    variantInfo: blue[600],
                                }}
                            >
                                <App />
                            </SnackbarProvider>
                        </ConnectedRouter>
                    </Provider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        );
    }
}

const rootDomElement = document.getElementById('root');

if (rootDomElement) {
    ReactDOM.render(<AppRoot />, rootDomElement);
}

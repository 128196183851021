import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import PumpingStationInspectionAuditHelper, { IPumpingStationInspectionAudit } from '../../../../@types/model/pumpingStation/pumpingStationInspectionAudit';
import { IPumpingStationInspectionGroupDrive } from '../../../../@types/model/pumpingStation/pumpingStationInspectionGroup';
import { IUserSession } from '../../../../@types/employee';
import PumpingStationInspectionAuditDriveSwitchGearSelector from '../../../customComponents/selector/pumpingStation/PumpingStationInspectionAuditDriveSwitchGearSelector';
import { FormControl, FormControlLabel, InputAdornment, Switch, TextField } from '@material-ui/core';
import moment from 'moment';
import GeneralFunctions from '../../../../store/general/functions';

interface IEditPumpingStationInspectionDriveDialogProps {
    audit : IPumpingStationInspectionAudit;
    reference : string;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
    
    session : IUserSession;
}

interface IEditPumpingStationInspectionDriveDialogState {
    open : boolean;
    drive : IPumpingStationInspectionGroupDrive;
}

export default class EditPumpingStationInspectionDriveDialog extends React.Component<IEditPumpingStationInspectionDriveDialogProps, IEditPumpingStationInspectionDriveDialogState> {
    constructor(props : IEditPumpingStationInspectionDriveDialogProps) {
        super(props);
        this.state = {
            open: false,
            drive: {
                hotConnectionTest: false,
                looseConnectionTestTemp: 0,
                pressureSensorWorking: false,
                serviceRequired: false,
                switchGear: '',
                visualDamage: false,
                visualDamageComment: '',
            },
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditPumpingStationInspectionDriveDialogProps, prevState : IEditPumpingStationInspectionDriveDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                drive: {
                    hotConnectionTest: false,
                    looseConnectionTestTemp: 0,
                    pressureSensorWorking: false,
                    serviceRequired: false,
                    switchGear: '',
                    visualDamage: false,
                    visualDamageComment: '',
                },
            });
        }

        if (!prevState.open && this.state.open) {
            const group = this.props.audit.inspectionGroups.find(x => x.reference === this.props.reference);

            if (!group) return;

            this.setState({
                drive: {
                    ...group.drive,
                },
            });
        }
    };

    private readonly save = async () => {
                
        const { session } = this.props;

        const { switchGear, looseConnectionTestTemp, visualDamage, visualDamageComment } = this.state.drive;

        if (!switchGear) return;
        if (!looseConnectionTestTemp) return;
        if (visualDamage && !visualDamageComment) return;

        const audit = {
            ...this.props.audit,
            inspectionGroups: [
                ...this.props.audit.inspectionGroups,
            ],
        };

        const index = audit.inspectionGroups.findIndex(x => x.reference === this.props.reference);
        audit.inspectionGroups[index] = {
            ...audit.inspectionGroups[index],
            drive: {
                ...this.state.drive,
            },
        };

        audit.updatedBy = session.firebaseUser.uid;
        audit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        audit.updatedByName = session.employee.Name;
        audit.updatedOn = moment.utc().valueOf();
        
        this.onClose();

        try {
            await PumpingStationInspectionAuditHelper.save(audit);


            GeneralFunctions.generalShowSuccessSnackbar('Saved changes!');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Something went wrong while saving the your changes.');
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onSwitchGearChange = (value : string) => {
        this.setState({
            drive: {
                ...this.state.drive,
                switchGear: value,
            },
        });
    };

    private readonly onLooseConnectionTestTempChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isNaN(Number(event.currentTarget.value))) return;
        this.setState({
            drive: {
                ...this.state.drive,
                looseConnectionTestTemp: Number(event.currentTarget.value),
            },
        });
    };

    private readonly onHotConnectionTestChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            drive: {
                ...this.state.drive,
                hotConnectionTest: checked,
            },
        });
    };

    private readonly onPressureSensorWorkingChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            drive: {
                ...this.state.drive,
                pressureSensorWorking: checked,
            },
        });
    };

    private readonly onServiceRequiredChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            drive: {
                ...this.state.drive,
                serviceRequired: checked,
            },
        });
    };

    private readonly onVisualDamageChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            drive: {
                ...this.state.drive,
                visualDamage: checked,
                visualDamageComment: '',
            },
        });
    };

    private readonly onVisualDamageCommentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            drive: {
                ...this.state.drive,
                visualDamageComment: event.currentTarget.value,
            },
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open } = this.state;
        const { switchGear, looseConnectionTestTemp, hotConnectionTest,
            pressureSensorWorking, serviceRequired, visualDamage, visualDamageComment } = this.state.drive;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton className='cdg' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='pumpingStation-info-info-title'
                    aria-describedby='pumpingStation-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Pumping Station Inspection Audit Drive Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs jcc'}>
                                <div className={'fdr flx1 mr15'}>
                                    <PumpingStationInspectionAuditDriveSwitchGearSelector 
                                        value={switchGear}
                                        disabled={isLoading}
                                        required
                                        onChange={this.onSwitchGearChange}
                                    />
                                </div>
                                <div className={'fdr flx1 mr15'}>
                                    <FormControl fullWidth={true} >
                                        <TextField 
                                            type={'number'}
                                            autoComplete={'off'}
                                            id={'looseConnectionTestTemp'}
                                            label={'Loose Connection Test (temp)'}
                                            value={looseConnectionTestTemp}
                                            margin={'normal'}
                                            error={!looseConnectionTestTemp}
                                            onChange={this.onLooseConnectionTestTempChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m/s<sup>2</sup>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'fdr flx1'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={hotConnectionTest}
                                                color='primary'
                                                checked={hotConnectionTest}
                                                onChange={this.onHotConnectionTestChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Hot Connection Test?'}
                                    />
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs jcc'}>
                                <div className={'fdr flx1 mr15'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={pressureSensorWorking}
                                                color='primary'
                                                checked={pressureSensorWorking}
                                                onChange={this.onPressureSensorWorkingChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Pressure Sensor Working?'}
                                    />
                                </div>
                                <div className={'fdr flx1 mr15'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={serviceRequired}
                                                color='primary'
                                                checked={serviceRequired}
                                                onChange={this.onServiceRequiredChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Service Require?'}
                                    />
                                </div>
                                <div className={'fdr flx1'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={visualDamage}
                                                color='primary'
                                                checked={visualDamage}
                                                onChange={this.onVisualDamageChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Visual Damage?'}
                                    />
                                </div>
                            </div>
                            {
                                visualDamage &&
                                <div className={'fdr flx1 aifs jcc'}>
                                    <div className={'fdr flx1'}>
                                        <FormControl fullWidth={true} >
                                            <TextField 
                                                type={'text'}
                                                autoComplete={'off'}
                                                id={'visualDamageComment'}
                                                label={'Comment'}
                                                value={visualDamageComment}
                                                margin={'normal'}
                                                error={!visualDamageComment}
                                                onChange={this.onVisualDamageCommentChange}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import React from 'react';
import TransformerBudgetHelper, { ISPUBudget, ISPUBudgetFormValues, ITransformerBudget } from '../../../../@types/model/transformer/budget';
import { ITransformer, ITransformerCompany } from '../../../../@types/model/transformer/transformer';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import BudgetTablePaginationField from '../field/BudgetTablePagination';
import SPUBudgetFieldArray from '../field/SPUField';

interface ISPUBudgetFormProps {
    startDate : number;
    hideDetail ?: boolean;

    transformers : Array<ITransformer>;

    initialValue ?: ITransformerBudget | null;

    onSubmit ?: (value : ISPUBudgetFormValues) => void;
    
    company : ITransformerCompany;
    area : string;
    transformer : string | null;
    accountNumber : string | null;
    period : number | null;
}

interface ISPUBudgetFormState {
    initialValue : ISPUBudgetFormValues | null;

    currentPage : number;
    rowsPerPage : number;
    rowsPerPageOptions : Array<number>;
}

export default class SPUBudgetForm extends React.PureComponent<ISPUBudgetFormProps, ISPUBudgetFormState> {
    constructor(props : ISPUBudgetFormProps) {
        super(props);
        this.state = {
            initialValue: null,
            currentPage: 0,
            rowsPerPage: 50,
            rowsPerPageOptions: [10, 20, 30, 50],
        };
    }

    public readonly componentDidMount = () => {
        this.setInit();
    };

    public readonly componentDidUpdate = (prevProps : ISPUBudgetFormProps) => {
        if (prevProps.startDate !== this.props.startDate) {
            this.setInit();
        }

        if (prevProps.company.id !== this.props.company.id) {
            this.setInit();
        }

        if (prevProps.area !== this.props.area) {
            this.setInit();
        }

        if (prevProps.transformer !== this.props.transformer) {
            this.onPageChange(null, 0);
        }

        if (prevProps.accountNumber !== this.props.accountNumber) {
            this.onPageChange(null, 0);
        }

        if (prevProps.period !== this.props.period) {
            this.onPageChange(null, 0);
        }
    };

    public readonly setInit = () => {
        const { startDate, company, area, transformers, initialValue } = this.props;

        this.setState({
            initialValue: TransformerBudgetHelper.initSPUForm(
                startDate,
                transformers,
                company,
                area,
                initialValue as ITransformerBudget<ISPUBudget>,
            ), 
        });
    };

    public readonly onSubmit = (values : ISPUBudgetFormValues) => {
        if (!this.props.onSubmit) return;

        return this.props.onSubmit(values);
    };

    private readonly onPageChange = (event : React.MouseEvent<HTMLButtonElement> | null, page : number) => {
        this.setState({ currentPage: page });
    };

    private readonly onRowsPerPageChange = (event ?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!event) return;
        this.setState({ rowsPerPage: Number(event.target.value) });
    };

    public readonly render = () => {
        const {
            initialValue,
            currentPage,
            rowsPerPage,
            rowsPerPageOptions,
        } = this.state;

        const {
            hideDetail,
            accountNumber,
            area,
            company,
            period,
            transformer,
        } = this.props;

        return (
            <div className='fdc flx1'>
                {
                    !!initialValue &&
                    <Formik
                        initialValues={initialValue}
                        validationSchema={TransformerBudgetHelper.formSPUSchema()}
                        onSubmit={this.onSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize
                    >
                        {
                            (formik) => (
                                <div className='fdc flx1'>
                                    {
                                        !formik.isSubmitting &&
                                        <div className='fdc flx1 budget bs oys'>
                                            <Table stickyHeader>
                                                <TableHead className='budget-table-header'>
                                                    <TableRow>
                                                        <TableCell component='th' scope='row' align='left' width={80}>
                                                            Period
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' width={100}>
                                                            Company
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' width={150}>
                                                            Area
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' width={100}>
                                                            EP Nr
                                                        </TableCell>
                                                        {
                                                            !hideDetail &&
                                                            <>
                                                                <TableCell component='th' scope='row' align='left' width={350}>
                                                                    Sub Area
                                                                </TableCell>
                                                                <TableCell component='th' scope='row' align='left' width={200}>
                                                                    Acc Nr
                                                                </TableCell>
                                                            </>
                                                        }
                                                        <TableCell className='lwb bcpd' component='th' scope='row' align='left'>
                                                            NEW BUDGETED
                                                        </TableCell>
                                                        <TableCell className='lwb bcp' component='th' scope='row' align='left'>
                                                            COMMENT
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className='budget-table-body'>
                                                    <FieldArray name='values'>
                                                        {
                                                            (array) => (
                                                                <SPUBudgetFieldArray
                                                                    {...array}
                                                                    accountNumber={accountNumber}
                                                                    area={area}
                                                                    company={company}
                                                                    period={period}
                                                                    currentPage={currentPage}
                                                                    rowsPerPage={rowsPerPage}
                                                                    transformer={transformer}
                                                                    hideDetail={hideDetail}
                                                                />
                                                            )
                                                        }
                                                    </FieldArray>
                                                </TableBody>
                                            </Table>
                                        </div>
                                    }
                                    {
                                        formik.isSubmitting &&
                                        <div className='fdc flx1 aic jcc'>
                                            <CircularProgress />
                                        </div>
                                    }
                                    <div className={'fdr aic'}>
                                        <span className='flx1'></span>
                                        <Field>
                                            {
                                                (params : FieldProps<unknown>) => (
                                                    <BudgetTablePaginationField
                                                        {...params}
                                                        accountNumber={accountNumber}
                                                        area={area}
                                                        company={company}
                                                        period={period}
                                                        currentPage={currentPage}
                                                        rowsPerPage={rowsPerPage}
                                                        transformer={transformer}
                                                        rowsPerPageOptions={rowsPerPageOptions}
                                                        onPageChange={this.onPageChange}
                                                        onRowsPerPageChange={this.onRowsPerPageChange}
                                                    />
                                                )
                                            }
                                        </Field>
                                    </div>
                                    <div className='fdr aife jcfe mt15'>
                                        <StadiumButton
                                            style={{
                                                height: 42,
                                                borderRadius: 40,
                                            }}
                                            className='jcc aic p0'
                                            color='primary'
                                            type='button'
                                            onClick={formik.submitForm}
                                            aria-label='Submit'
                                            disabled={formik.isSubmitting || !formik.isValid}
                                        >
                                            <Typography className='fw600 fs16 cp ml20 mr20'>SAVE</Typography>
                                        </StadiumButton>
                                    </div>
                                </div>
                            )
                        }
                    </Formik>

                }
            </div>
        );
    };
}

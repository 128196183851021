import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

interface IFileDropdownProps {
    label : string;
    value ?: File;

    onChange ?: (value ?: File) => void;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;
    disabled ?: boolean;

    accept ?: string;
}

interface IFileDropdownState {}

export default class FileDropdown extends React.PureComponent<IFileDropdownProps, IFileDropdownState> {
    private inputRef : HTMLInputElement | null = null;
    constructor(props : IFileDropdownProps) {
        super(props);
        this.state = {};
    }

    private readonly onRef = (inputRef : HTMLInputElement | null) => {
        this.inputRef = inputRef;
    };

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files?.length && this.props.onChange) {
            this.props.onChange(event.currentTarget.files[0]);
        }

        if (this.inputRef) {
            this.inputRef.value = '';
        }
    };

    private readonly onClick = () => {
        this.inputRef?.click();
    };

    public readonly render = () => {
        const { required, fullWidth, disabled, label, value, accept } = this.props;

        return (
            <FormControl disabled={disabled} fullWidth={fullWidth} error={!disabled && required && !value} required={required}>
                <input accept={accept} disabled={disabled} ref={this.onRef} className='dn' type='file' id={`name_${label}_file_upload`} onChange={this.onChange} />
                <InputLabel htmlFor={`${label}_file_select`}>{label}</InputLabel>
                <Select
                    disabled={disabled}
                    required={required}
                    open={false}
                    onOpen={this.onClick}
                    value={!value ? '' : value.name}
                    inputProps={{
                        name: `name_${label}_file_select`,
                        id: `id_${label}_file_select`,
                    }}
                >
                    {
                        !!value &&
                        <MenuItem value={value.name}>{value.name}</MenuItem>
                    }
                </Select>
                {
                    !disabled && required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

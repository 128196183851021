import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import lodash from 'lodash';
import DamHelper, { EnumDamConstructionType } from '../../../../@types/model/dam/dam';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField } from '@material-ui/core';
import { createSelector } from 'reselect';

export interface IDamConstructionTypeDropDownOption {
    label : string;
    value : EnumDamConstructionType | null;
}

interface IDamConstructionTypeSelectorProps {
    value : EnumDamConstructionType | null;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : EnumDamConstructionType | null) => void;
}

interface IDamConstructionTypeSelectorState { }

class DamConstructionTypeSelector extends React.Component<IDamConstructionTypeSelectorProps, IDamConstructionTypeSelectorState> {
    constructor(props : IDamConstructionTypeSelectorProps) {
        super(props);

        this.state = {};
    }   

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? null);
    };

    private readonly getValue = (state : IDamConstructionTypeSelectorState, props : IDamConstructionTypeSelectorProps) => props.value;
    private readonly getRequired = (state : IDamConstructionTypeSelectorState, props : IDamConstructionTypeSelectorProps) => props.required;

    private readonly getConstructionTypes = () => DamHelper.DamConstructionType;

    private readonly getConstructionTypesForDropdown = createSelector([
        this.getRequired, this.getConstructionTypes,
    ], (required, constructionTypes) => {
        const dropdownData = lodash.map(constructionTypes, (element, index) => ({ label: element, value: lodash.parseInt(index) } as IDamConstructionTypeDropDownOption));

        if (!required) {
            dropdownData.unshift({
                label: 'NONE',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getConstructionTypes,
    ], (value, required, constructionTypes) => {
        if (value != null) {
            const constructionType = constructionTypes[value];

            if (constructionType) {
                return { label: constructionType, value: value } as IDamConstructionTypeDropDownOption;
            }
        }
        return null;
    });

    public readonly render = () => {
        const { required, className, disabled } = this.props;

        const dropdownData = this.getConstructionTypesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'constructionType'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Construction Types'} />}  
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };

}

export default DamConstructionTypeSelector;
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IChemicalFillingPoint } from '../../../../@types/model/fillingPoint/chemical';

interface IChemicalFillingPointCodeSelectorProps {
    points : Array<IChemicalFillingPoint>;
    isLoading : boolean;

    value : string;

    onChange : (value : string) => void;

    required ?: boolean;
}

interface IChemicalFillingPointCodeSelectorState {}

class ChemicalFillingPointCodeSelectorComponent extends React.Component<IChemicalFillingPointCodeSelectorProps, IChemicalFillingPointCodeSelectorState> {
    constructor(props : IChemicalFillingPointCodeSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly getChemicalFillingPoints = (props : IChemicalFillingPointCodeSelectorProps) => props.points;

    public readonly getChemicalFillingPointCodes = createSelector(
        [this.getChemicalFillingPoints],
        (points) => {
            return points.map((e : IChemicalFillingPoint) => {
                return e.code;
            });
        },
    );

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: string | undefined | null) => {
        this.props.onChange(value ?? '');
    };

    public readonly render = () => {
        const { value, required, isLoading} = this.props;

        const codes = this.getChemicalFillingPointCodes(this.props);

        return (
            <FormControl fullWidth={true} required={false} disabled={isLoading}>
                <Autocomplete
                    id={'code'}
                    options={codes}
                    value={value}
                    getOptionSelected={(option, val) => option === val}
                    getOptionLabel={option => option}
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={true} label={'Filling Station Code'} />}
                />
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        points: state.fillingPoints.chemical.points,
        isLoading: state.fillingPoints.chemical.isLoading,
    };
};

const ChemicalFillingPointCodeSelector = connect(
    mapStateToProps,
)(ChemicalFillingPointCodeSelectorComponent);

export default ChemicalFillingPointCodeSelector;

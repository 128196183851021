import * as React from 'react';
import { Button, Card, Fade, FormControl, Icon, IconButton, InputAdornment, Popper, TextField, Tooltip, Typography, withTheme } from '@material-ui/core';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import OutsideClicker from './outsideClicker';
import appFunctionsService from '../../services/appFunctionsService';
import { DatePicker } from '@material-ui/pickers';
import DropdownButton from './button/DropdownButton';
import { createSelector } from 'reselect';
import { ThemedComponentProps } from '@material-ui/core/styles/withTheme';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';


interface IDateRangeProps {
    onChange ?: (start : moment.Moment | null, end : moment.Moment | null) => void;
    start : moment.Moment | null;
    end : moment.Moment | null;
}

interface IDateRangeState {
    showPicker : boolean;
    startDate : moment.Moment | null;
    endDate : moment.Moment | null;

    type : 'to' | 'from' | string;
}

class DateRangeComponent extends React.Component<IDateRangeProps & ThemedComponentProps, IDateRangeState> {
    private readonly ref : React.RefObject<HTMLDivElement>;
    constructor(props : IDateRangeProps) {
        super(props);

        this.state = {
            showPicker: false,
            startDate: null, 
            endDate: null,
            type: 'to',
        };

        this.ref = React.createRef();
    }

    private readonly onRangeClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const { type, showPicker } = this.state;
        const { start, end } = this.props;
        this.setState({
            showPicker: type !== event.currentTarget.value || !showPicker,
            startDate: start,
            endDate: end,
            type: event.currentTarget.value,
        });
    };

    private readonly onCancelClick = () => {
        this.setState({
            showPicker: false,
        });
    };

    private readonly onClearClick = () => {
        this.setState({
            showPicker: false,
        });

        if (!this.props.onChange) return;
        this.props.onChange(null, null);
    };

    private readonly onSaveClick = () => {
        this.setState({
            showPicker: false,
        });

        if (!this.props.onChange) return;
        this.props.onChange(this.state.startDate, this.state.endDate);
    };

    private onMonthClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const month = Number(event.currentTarget.value);

        const { startDate, endDate, type } = this.state;

        if (type === 'from') {
            this.setState({
                startDate: moment(startDate ?? moment()).set('month', month).startOf('month'),
            });
        }

        if (type === 'to') {
            this.setState({
                endDate: moment(endDate ?? moment()).set('month', month).endOf('month'),
            });
        }
    };

    private onYearClick = (newYear : MaterialUiPickersDate) => {
        const year = newYear?.year() ?? moment().year();

        const { startDate, endDate, type } = this.state;

        if (type === 'from') {
            this.setState({
                startDate: moment(startDate ?? moment()).set('year', year),
            });
        }

        if (type === 'to') {
            this.setState({
                endDate: moment(endDate ?? moment()).set('year', year),
            });
        }
    };

    private readonly onTypeClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            type: event.currentTarget.id,
        });
    };

    public readonly getStartDate = (props : IDateRangeProps, state : IDateRangeState) => state.startDate;
    public readonly getEndDate = (props : IDateRangeProps, state : IDateRangeState) => state.endDate;
    public readonly getType = (props : IDateRangeProps, state : IDateRangeState) => state.type;

    private readonly getYearValue = createSelector(
        [this.getStartDate, this.getEndDate, this.getType],
        (startDate, endDate, type) => {
            if (type === 'from') {
                return !startDate ? moment().startOf('year') : moment(startDate);
            }

            if (type === 'to') {
                return !endDate ? moment().endOf('year') : moment(endDate);
            }

            return moment().startOf('year');
        },
    );

    private readonly getMonthValue = createSelector(
        [this.getStartDate, this.getEndDate, this.getType],
        (startDate, endDate, type) => {
            if (type === 'from') {
                return !startDate ? moment().startOf('month') : moment(startDate);
            }

            if (type === 'to') {
                return !endDate ? moment().startOf('month') : moment(endDate);
            }

            return moment().startOf('month');
        },
    );

    private readonly getMaxDate = createSelector(
        [this.getStartDate, this.getEndDate, this.getType],
        (startDate, endDate, type) => {
            if (type === 'from') {
                return moment();
            }

            if (type === 'to') {
                return moment(startDate ?? moment());
            }

            return moment();
        },
    );

    public render() {
        const { startDate, endDate, showPicker, type } = this.state;
        const { start, end, theme } = this.props;
        
        const yearValue = this.getYearValue(this.props, this.state);
        const monthValue = this.getMonthValue(this.props, this.state);
        const maxDate = this.getMaxDate(this.props, this.state);
        return (
            <div className={'fdr flx1'}>
                <div className={'fdr flx1 pr20'}>
                    <FormControl fullWidth>
                        <TextField
                            ref={this.ref}
                            type='text'
                            autoComplete='off'
                            id='fromDate'
                            label='Select a from date'
                            value={!start ? '' : appFunctionsService.formatDateTimeToDatePicker(start)}
                            margin='normal'
                            className='fs11 cp'
                            InputProps={{
                                className: 'fs15 cp',
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title='Select Range'>
                                            <div>
                                                <IconButton value='from' onClick={this.onRangeClick}>
                                                    <Icon className={'cp'}>
                                                        date_range
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </div>
                <div className={'fdr flx1 pr20'}>
                    <FormControl fullWidth>
                        <TextField
                            type='text'
                            autoComplete='off'
                            id='toDate'
                            label='Select a to date'
                            value={!end ? '' : appFunctionsService.formatDateTimeToDatePicker(end)}
                            margin='normal'
                            className='fs11 cp'
                            InputProps={{
                                className: 'fs15 cp',
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Tooltip title='Select Range'>
                                            <div>
                                                <IconButton value='to' onClick={this.onRangeClick}>
                                                    <Icon className={'cp'}>
                                                        date_range
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </div>
                <div className={'posr aic'}>
                    <Popper 
                        open={showPicker}
                        anchorEl={this.ref.current}
                        transition
                        placement='bottom'
                        style={{
                            zIndex: theme?.zIndex.modal,
                        }}
                    >
                        {
                            ({ TransitionProps }) => (
                                <Fade {...TransitionProps} >
                                    <OutsideClicker>
                                        <Card>
                                            <div className={'m20'}>
                                                <div className={'fdr'}>
                                                    <div className={'fdc'}>
                                                        <Typography className={'fs11 cp'}>
                                                            Select a from date
                                                        </Typography>
                                                        <Button 
                                                            id={'from'}
                                                            variant={type === 'from' ? 'contained' : 'outlined'}
                                                            color='primary'
                                                            className={'cp w250 h60'}
                                                            onClick={this.onTypeClick}
                                                        >
                                                            <Typography className={type === 'from' ? 'fs18 cw pl80 ' : 'fw500 fs18 cp pl80 '}>
                                                                {
                                                                    startDate &&
                                                                    appFunctionsService.formatDateTimeToDatePicker(startDate)
                                                                }
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                    <div className={'fdc ml20'}>
                                                        <Typography className={'fs11 cp'}>
                                                            Select a to date
                                                        </Typography>
                                                        <Button
                                                            id={'to'}
                                                            variant={type === 'to' ? 'contained' : 'outlined'}
                                                            color='primary' className={'cp w250 h60'}
                                                            onClick={this.onTypeClick}>
                                                            <Typography className={type === 'to' ? 'fs18 cw pl80 ' : 'fw500 fs18 cp pl80'}>
                                                                {
                                                                    endDate &&
                                                                    appFunctionsService.formatDateTimeToDatePicker(endDate)
                                                                }
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className={'fdr flx1 aifs'}>
                                                    <div className={'flx1 aifs p5 mb10 pr20 cp'}>
                                                        <DatePicker 
                                                            value={yearValue}
                                                            views={['year']}
                                                            maxDate={moment(maxDate).endOf('year')}
                                                            onChange={this.onYearClick} format='YYYY' label='Select Year'
                                                            id='yearDate'
                                                            className={'cp'}
                                                            margin='normal'
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <DropdownButton />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                                    
                                                    </div>
                                                </div>
                                                <Typography className={'cp fs11'}>
                                                    Select a month
                                                </Typography>
                                                <div className={'pt16 pb16'}
                                                    style={{
                                                        display: 'grid',
                                                        gridTemplateColumns: '100px 100px 100px 100px 100px',
                                                        gridTemplateRows: '35px 35px 35px',
                                                        gridRowGap: 7,
                                                        gridColumnGap: 15,
                                                    }}>
                                                    {
                                                        moment
                                                            .months()
                                                            .map((month, i) => (
                                                                <Button
                                                                    value={i}
                                                                    onClick={this.onMonthClick}
                                                                    key={`week_${month}`}
                                                                    variant={monthValue.month() === i ? 'contained' : 'outlined'}
                                                                    color='primary'
                                                                    className={'br15 p15'}
                                                                    disabled={maxDate.month() < i}
                                                                >
                                                                    {month}
                                                                </Button>
                                                            ))
                                                    }
                                                </div>
                                                <div className={'fdr jcfe'}>
                                                    <Button onClick={this.onCancelClick}className={'cw br20 mr16'}>
                                                        <Typography className={'cp fs14 fw500'}>
                                                            Cancel
                                                        </Typography>
                                                    </Button>
                                                    <Button onClick={this.onClearClick}className={'cw br20 mr16'}>
                                                        <Typography className={'cp fs14 fw500'}>
                                                            Clear
                                                        </Typography>
                                                    </Button>
                                                    <Button onClick={this.onSaveClick} className={'bcy br20 '}>
                                                        <Typography className={'cp fs14 fw500'}>
                                                            Saved
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Card>
                                    </OutsideClicker>
                                </Fade>
                            )
                        }                        
                    </Popper>
                </div>
            </div>
        );
    }
}

const DateRange = withTheme(DateRangeComponent);

export default DateRange;

import React, { ChangeEvent } from 'react';
import { IAuthState, IRootState, RootAction } from '../../../../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BoreholeIssue from '../../../../@types/model/borehole/issue';
import firebaseApp from '../../../../services/firebaseService';
import firebase from 'firebase/app';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import GeneralFunctions from '../../../../store/general/functions';

interface IBoreholeIssueListProps {
    auth : IAuthState;
}

interface IBoreholeIssueListState {
    isLoading : boolean;
    searchText : string;
    issues : Array<BoreholeIssue>;

    selected ?: BoreholeIssue;

}

class BoreholeIssueList extends React.PureComponent<IBoreholeIssueListProps, IBoreholeIssueListState> {
    private listener ?: () => void;
    constructor(props : IBoreholeIssueListProps) {
        super(props);
        this.state = {
            isLoading: true,
            searchText: '',
            issues: [],
        };
    }

    public readonly componentDidMount = () => {
        this.listen();
    };

    public readonly componentWillUnmount = () => {
        if (this.listener) {
            this.listener();
        }
    };

    private readonly listen = () => {
        this.listener = firebaseApp.firestore().collection(BoreholeIssue.COLLECTION).onSnapshot((snapshot) => {
            // Shallow clone is fine here as we do not modify objects in array.
            const issues = this.state.issues.slice();

            snapshot.docChanges().forEach((f) => {
                const issue = new BoreholeIssue(f.doc);
                const index = lodash.findIndex(issues, n => n.ref.id === f.doc.ref.id);

                switch (f.type) {
                    case 'added':
                        issues.push(issue);
                        break;
                    case 'modified':
                        issues.splice(index, 1, issue);
                        break;
                    case 'removed':
                        issues.splice(index, 1);
                        break;
                }
            });

            this.setState({
                issues: lodash.sortBy(issues, p => p.name),
                isLoading: false,
            });
        }, (error) => {
            GeneralFunctions.generalShowError(error);
            this.setState({
                issues: [],
                isLoading: false,
            });
        });
    };

    private readonly onMotorClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const selected = this.state.issues.slice().find(n => event.currentTarget.id === n.ref.id);

        if (!selected) return;
        this.setState({
            selected: lodash.cloneDeep(selected), // Using deep clone as this object has functions.
        });
    };

    private readonly onSearchChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            searchText: event.currentTarget.value,
        });
    };

    private readonly onNameChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if (!this.state.selected) return;
        const selected = lodash.cloneDeep(this.state.selected);
        selected.name = event.currentTarget.value;
        this.setState({
            selected,
        });
    };

    private readonly onClearClick = () => {
        this.setState({
            searchText: '',
        });
    };

    private readonly onAddClick = () => {
        if (!this.state.searchText) return;

        if (lodash.some(this.state.issues, n => n.name.toLocaleLowerCase() === this.state.searchText.toLocaleLowerCase())) {
            GeneralFunctions.generalShowErrorSnackbar(`${this.state.searchText} already added.`);
            return;
        }

        const issue = new BoreholeIssue();

        issue.ref = firebaseApp.firestore().collection(BoreholeIssue.COLLECTION).doc();
        issue.name = this.state.searchText;

        issue.createdBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session?.firebaseUser.uid);
        issue.createdByEmployee = this.props.auth.session?.employee.EmployeeNumber ?? '';
        issue.createdByName = this.props.auth.session?.employee.Name ?? '';
        issue.createdOn = firebase.firestore.Timestamp.now();
        issue.updatedBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session?.firebaseUser.uid);
        issue.updatedByEmployee = this.props.auth.session?.employee.EmployeeNumber ?? '';
        issue.updatedByName = this.props.auth.session?.employee.Name ?? '';
        issue.updatedOn = firebase.firestore.Timestamp.now();
        issue.save();

        this.setState({
            isLoading: true,
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        this.delete(event.currentTarget.value);
    };

    private readonly onSaveClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        this.save();
    };

    private readonly save = async () => {
        const selected = lodash.cloneDeep(this.state.selected);

        if (!selected) return;
        if (!selected.name) return;
        if (!this.props.auth.session) return;

        try {
            selected.updatedBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session.firebaseUser.uid);
            selected.updatedByEmployee = this.props.auth.session.employee.EmployeeNumber ?? '';
            selected.updatedByName = this.props.auth.session.employee.Name;
            selected.updatedOn = firebase.firestore.Timestamp.now();

            this.setState({
                isLoading: true,
            });
            await selected.save();
        } catch (ex) {
            GeneralFunctions.generalShowError(ex);
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly delete = async (id : string) => {
        const selected = this.state.issues.slice().find(n => id === n.ref.id);

        if (!selected) return;

        try {
            await selected.delete();
        } catch (ex) {
            GeneralFunctions.generalShowError(ex);
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly getIssues = (props : IBoreholeIssueListProps, state : IBoreholeIssueListState) => state.issues;
    private readonly getSearchText = (props : IBoreholeIssueListProps, state : IBoreholeIssueListState) => state.searchText;

    private readonly getFilteredIssues = createSelector(
        [this.getIssues, this.getSearchText],
        (issues, searchText) => {
            return issues.filter(n => n.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        },
    );

    private readonly getIssuesTotal = createSelector(
        [this.getIssues],
        (issues) => {
            return issues.length;
        },
    );

    public readonly render = () => {
        const { searchText, isLoading, selected } = this.state;
        const issues = this.getFilteredIssues(this.props, this.state);
        const issuesTotal = this.getIssuesTotal(this.props, this.state);
        return (
            <div className={'fdc ais flx1 p10'}>
                <div className='fdr flx1 hfill'>
                    <div className='fdc flx1 hfill'>
                        <Paper className='fdc flx1 hfill m10'>
                            <Toolbar className={'fdr aic'}>
                                <TextField
                                    value={searchText}
                                    onChange={this.onSearchChanged}
                                    placeholder='Search'
                                    margin='dense'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <Tooltip title='Clear'>
                                                <IconButton onClick={this.onClearClick}>
                                                    <Icon className='cr'>close</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                                <Tooltip title='Add'>
                                    <div>
                                        <IconButton color='primary' onClick={this.onAddClick} aria-label='Add'>
                                            <Icon>add</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Toolbar>
                            <Divider />
                            <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                                Total {issuesTotal}
                            </Typography>
                            <Divider />
                            <List className={'fdc flx1 oya'}>
                                <div className='mnh4'>
                                    { isLoading && <LinearProgress />}
                                </div>
                                {
                                    issues.map(n => (
                                        <ListItem button key={n.ref.id} id={n.ref.id} onClick={this.onMotorClick} selected={selected?.ref.id === n.ref.id} >
                                            <ListItemText
                                                primary={`${n.name}`}
                                            />
                                            <ListItemSecondaryAction>
                                                <Tooltip title='Disable'>
                                                    <IconButton className='cr' value={n.ref.id} onClick={this.onDeleteClick} aria-label='Delete'>
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Paper>
                    </div>
                    <div className='fdc flx4 hfill'>
                        {
                            !!selected &&
                            <Paper className='fdc flx1 hfill m10'>
                                <Toolbar className={'fdr aic'}>
                                    <Typography variant='h5' color='inherit'>
                                    Issue - {selected.name}
                                    </Typography>
                                    <span className='flx1' />
                                    <Tooltip title='Save'>
                                        <div>
                                            <IconButton color='primary' onClick={this.onSaveClick} aria-label='Save'>
                                                <Icon>save</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </Toolbar>
                                <Divider />
                                <div className='fdc flx1 hfill'>
                                    <div className='fdc flx1 oya'>
                                        <div className='aic p5 mb10 pr20'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='name'
                                                    label='Name'
                                                    value={selected.name ? selected.name : ''}
                                                    required
                                                    error={!selected.name}
                                                    onChange={this.onNameChange}
                                                    margin='normal'
                                                    className={'TextField'}
                                                />
                                                {
                                                    !selected.name &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BoreholeIssueList);

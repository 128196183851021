import { AppBar, Divider, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { FieldArray } from 'formik';
import React from 'react';
import { IDamSafetyInspectionCommentFormValues } from '../../../../../@types/model/dam/damSafetyInspection';
import AppFunctionsService from '../../../../../services/appFunctionsService';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import DamSafetyInspectionCommentFormField from '../../../../customComponents/form/damSafetyInspection/DamSafetyInspectionCommentFormField';

interface IDamSafetyInspectionEditDialogCommentsTabProps {
    isLoading : boolean;

    onSaveClick ?: () => void;
    onBackClick ?: () => void;

    comments : Array<IDamSafetyInspectionCommentFormValues>;

    isValid ?: boolean;
}

interface IDamSafetyInspectionEditDialogCommentsTabState {
}

export default class DamSafetyInspectionEditDialogCommentsTab extends React.PureComponent<IDamSafetyInspectionEditDialogCommentsTabProps, IDamSafetyInspectionEditDialogCommentsTabState> {
    constructor(props : IDamSafetyInspectionEditDialogCommentsTabProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { onSaveClick, onBackClick, comments, isValid } = this.props;
        
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <div className='fdr jcfs mt15'>
                    <AppBar position='static' className='' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography variant='h6' className='flx1 cw'>
                                COMMENTS
                            </Typography>
                            <span className={'flx1'} />
                        </Toolbar>
                    </AppBar>
                </div>
                <div className='fdc jcfs bcw p15'>
                    <FieldArray
                        name='comments'
                        render={(props) => (
                            <DamSafetyInspectionCommentFormField {...props} />
                        )}
                    />
                    
                </div>
                <div className='fdc jcfs bcw p15'>
                    <div className='fdc'>
                        <Typography className='fs16 fwb cpd'>History</Typography>
                    </div>
                    <Divider className='mt15 bbw2' />
                </div>
                <div className='fdc jcfs bcw p15'>
                    {
                        comments.map((x, i) => (
                            <div key={i} className='fdc'>
                                <div className='fdr aic bcg0 pl10 mb10'>
                                    <div className='fdc flx1'>
                                        <Typography className='fw700 cdg fs18 mt5 mb5'>
                                            {
                                                x.createdByName
                                            }
                                        </Typography>
                                    </div>
                                    <div className='fdc jcc aic mr10'>
                                        <Typography className='cpd fs12'>
                                            {
                                                AppFunctionsService.formatDateTime(x.createdOn)
                                            }
                                        </Typography>
                                    </div>
                                    {
                                        x.isNew &&
                                        <div className='fdc jcc aic mr10'>
                                            <FieldArray
                                                name='comments'
                                                render={(props) => (
                                                    <Tooltip title='Remove'>
                                                        <div>
                                                            <IconButton
                                                                onClick={() => {
                                                                    props.remove(i);
                                                                }}
                                                            >
                                                                <DeleteIcon className='cr' />
                                                            </IconButton>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className='fdc ml10 mr10 mb10'>
                                    {
                                        /** This is ugly but needed for displaying new lines... */
                                        x.comment.split('\n').map(n => (
                                            <Typography key={n} className='fs15 cg3' component='p'>
                                                {
                                                    n
                                                }
                                            </Typography>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className='fdr jcfe mt15'>
                    <div className='mr15'>
                        <OutlinedStadiumButton onClick={onBackClick}>
                            Back
                        </OutlinedStadiumButton>
                    </div>
                    <div>
                        <StadiumButton disabled={!isValid} onClick={onSaveClick}>
                            Save
                        </StadiumButton>
                    </div>
                </div>
            </div>
        );
    };
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import DamHelper, { EnumDamSlopeSurfaceProtection, IDam } from '../../../@types/model/dam/dam';
import { IRootState } from '../../../@types/redux';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditDamMasterData from '../dialog/EditMasterData';

interface IDamMasterDataViewProps {
    dam : IDam;

    isLoading : boolean;

    isDamAdmin : boolean;
}

interface IDamMasterDataViewState {}

class DamMasterDataViewComponent extends React.PureComponent<IDamMasterDataViewProps, IDamMasterDataViewState> {
    constructor(props : IDamMasterDataViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { isDamAdmin, dam, isLoading } = this.props;

        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            MASTER DATA
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isDamAdmin &&
                                    <EditDamMasterData
                                        dam={dam}
                                        isLoading={isLoading}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdc flx1 ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DIVISION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.division ? dam.division : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    MANAGMENT AREA
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.managementArea ? dam.managementArea : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DSI NUMBER
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.lastDSINumber ? dam.lastDSINumber : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    WARMS NUMBER
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.warmsNo ? dam.warmsNo : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DAM NAME
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.name}
                                </Typography>
                            </div>
                        </div>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    WALL TYPE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.wallType !== null ? DamHelper.DamWallType[dam.wallType] : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DAM WALL SURFACE PROTECTION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.wallProtectionSurface !== null ? DamHelper.DamWallSurfaceProtection[dam.wallProtectionSurface] : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    SLOPE PROTECTION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {
                                        !dam.slopeSurfaceProtection &&
                                        'None'
                                    }
                                    {
                                        !!dam.slopeSurfaceProtection &&
                                        dam.slopeSurfaceProtection !== EnumDamSlopeSurfaceProtection.Other &&
                                        DamHelper.DamSlopeSurfaceProtection[dam.slopeSurfaceProtection]
                                    }
                                    {
                                        dam.slopeSurfaceProtection === EnumDamSlopeSurfaceProtection.Other &&
                                        dam.slopeSurfaceProtectionOther
                                    }
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    SPILLWAY TYPE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.spillwayType ? dam.spillwayType : 'None'}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isDamAdmin: !!state.auth.session?.employee.IsDamAdmin,
        isLoading: state.dam.loading,
    };
};

const DamMasterDataView = connect(
    mapStateToProps,
)(DamMasterDataViewComponent);

export default DamMasterDataView;

import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import appFunctionsService from '../../../services/appFunctionsService';
import lodash from 'lodash';
import { createSelector } from 'reselect';
import { IBoreholeTest } from '../../../@types/model/borehole/boreholeTest';

interface IBoreholeYieldGraphProps {
    boreholeTests : Array<IBoreholeTest>;
    isLoading : boolean;
}

interface IBoreholeYieldGraphState {}

class BoreholeYieldGraph extends React.Component<IBoreholeYieldGraphProps, IBoreholeYieldGraphState> {
    constructor(props : IBoreholeYieldGraphProps) {
        super(props);
        this.state = {};
    }

    public readonly getBoreholeTests = (state : IBoreholeYieldGraphState, props : IBoreholeYieldGraphProps) => props.boreholeTests;
    public readonly getMax = createSelector(
        [this.getBoreholeTests],
        (boreholeTests) => {
            const max = lodash.maxBy(boreholeTests, n => n.Yield);

            return max?.Yield ? (max.Yield + 5) : 0;
        },
    );
    
    public readonly getData = createSelector(
        [this.getBoreholeTests],
        (boreholeTests) => {
            return boreholeTests
                .filter(n => typeof n.Yield !== 'undefined')
                .map((n) => ({
                    name: appFunctionsService.formatDateTimeToDateOnly(n.CreatedOn),
                    value: n.Yield ?? 0,
                }));
        },
    );

    public readonly getPoints = createSelector(
        [this.getBoreholeTests],
        (boreholeTests) => {
            return boreholeTests
                .filter(n => typeof n.Yield !== 'undefined')
                .map((n) => ({
                    value: n.Yield ?? 0,
                    x: n.Yield ?? 0,
                    y: n.Yield ?? 0,
                }));
        },
    );

    public readonly render = () => {
        const { isLoading, boreholeTests } = this.props;

        const max = this.getMax(this.state, this.props);
        const data = this.getData(this.state, this.props);
        const points = this.getPoints(this.state, this.props);

        return (
            <div className='wfill'>
                {
                    isLoading &&
                    <LinearProgress />
                }
                {
                    !isLoading && boreholeTests.length === 0 &&
                    <div className='fdr flx1 jcc aic'>
                        <Typography>
                            None
                        </Typography>
                    </div>
                }
                {
                    !isLoading && boreholeTests.length > 0 &&
                    <ResponsiveContainer width={'99%'} aspect={3}>
                        <LineChart data={data}>
                            <XAxis dataKey='name' reversed/>
                            <YAxis domain={[0, max]} />
                            <CartesianGrid />
                            <Tooltip allowEscapeViewBox={{
                                x: false,
                                y: false,
                            }} />
                            <Line points={points} type='monotone' dataKey='value' stroke='#2294FC' />
                        </LineChart>
                    </ResponsiveContainer>
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        boreholeTests: state.borehole.boreholeTests,
        isLoading: state.borehole.loadingTests,
    };
};

export default connect(
    mapStateToProps,
)(BoreholeYieldGraph);

import { AppBar, Card, Divider, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { CartesianGrid, Line, LineChart, XAxis, YAxis, Label, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { createSelector } from 'reselect';
import { IUserSession } from '../../../@types/employee';
import DamHelper, { IDam } from '../../../@types/model/dam/dam';
import { IDamWeeklyReading } from '../../../@types/model/dam/weeklyReading';
import { IRootState } from '../../../@types/redux';
import appFunctionsService from '../../../services/appFunctionsService';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditDamSummary from '../dialog/EditSummaryDialog';
import lodash from 'lodash';
import EditDamPlateDialog from '../dialog/EditPlateDialog';


interface IDamSummaryProps {
    dam : IDam;
    isLoading : boolean;
    session : IUserSession;
    weeklyReadings : Array<IDamWeeklyReading>;
}

interface IDamSummaryState {
}

class DamSummary extends React.PureComponent<IDamSummaryProps, IDamSummaryState> {
    constructor(props : IDamSummaryProps) {
        super(props);
        this.state = {
        };
    }

    public getDamVolumesData = (props : IDamSummaryProps) => props.dam.damVolumes;

    public readonly getVolumeData = createSelector(
        [this.getDamVolumesData],
        (damVolumes) => {
            return damVolumes.map(n => ({'plate': n.plate, 'volume': n.volume})).reverse();
        }
    );

    public getPlateReadings = (props : IDamSummaryProps) => props.weeklyReadings;
    public getDam = (props : IDamSummaryProps) => props.dam;

    public readonly getPlateReading = createSelector(
        [this.getPlateReadings],
        (readings) => {
            return lodash.chain(readings).sortBy(a => a.date).last().value() as IDamWeeklyReading | undefined;
        }
    );

    public readonly getReadingData = createSelector(
        [this.getDam],
        DamHelper.getReadingChartData,
    );

    public readonly tickFormater = (tick : number) => { return tick.toLocaleString(); };
    
    public render = () => {
        const { dam, session, isLoading} = this.props;
        const volumeData = this.getVolumeData(this.props);
        const weeklyReadings = this.getReadingData(this.props);
        const plateReading = this.getPlateReading(this.props);
        return (
            <div className='fdr flx1 bcg0 wfill hfill ais jcsb'>
                <div className={'fdc flx2 m50'}>
                    <div className={''}>
                        <Card>
                            <AppBar className='posr dbcslg' position='static' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='flx1 cw'>
                                        Dam Info
                                    </Typography>
                                    <span className={'flx1'} />
                                    {
                                        session.employee.IsDamAdmin &&
                                        <EditDamSummary dam={dam} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                                    }
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr ais p20 flx1'}>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Quatenary Catchment
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.catchmentArea ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Risk Category
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.riskCategory ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        River
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.riverOrWaterCourse ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        WARMS Number
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.warmsNo ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Owner
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.ownerName ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Designer
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.designer ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Contractor
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.contractor ?? ''}
                                    </span>
                                </div>
                            </div>
                            <Divider>
                            </Divider>
                            <div className={'fdr ais p20 flx1'}>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Construction End Date
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.constructionEndDate ? appFunctionsService.formatDateTimeToDateOnly(dam.constructionEndDate) : ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Registered Capacity
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.registeredCapacity ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Reg Date
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.registrationDate ? appFunctionsService.formatDateTimeToDateOnly(dam.registrationDate) : ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Parent Farm
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.parentFarm ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Farm Portion
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.farmPortion ?? ''}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Title Deed
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.titleDeed ?? ''}
                                    </span>
                                </div>
                                <div className={'pr20 flx1'}>
                                </div>
                            </div>
                            <Divider />
                            <div className={'fdr ais p20 flx1'}>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Dam Name
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.name}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Dam Type
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.constructionType != null ? DamHelper.DamConstructionType[(dam.constructionType)] : '-'}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Dam Code
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.code}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Construction Type
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.constructionType === null ? '-' : DamHelper.DamConstructionType[dam.constructionType]}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'dam-summary-Heading '}>
                                        Dam Status
                                    </Typography>
                                    <span className={'dam-summary-detail '}>
                                        {dam.status != null ? DamHelper.DamStatus[(dam.status)] : '-'}
                                    </span>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={'fdr flx1 ais wfill pt50'}>
                        <Card className={'jcc aic fdc flx1 pr20'}>
                            { 
                                weeklyReadings.length == 0 &&
                                <Typography>
                                    No Data
                                </Typography>
                            }
                            {   
                                weeklyReadings.length > 0 &&
                                <ResponsiveContainer width='100%' height='90%'>
                                    <AreaChart 
                                        margin={{ top: 0, right: 20, bottom: 30, left: 35 }}
                                        data={weeklyReadings}>      
                                        <Area type='monotone' dataKey='capacity' stroke='#82ca9d' fillOpacity={0.3} fill='#82ca9d' />
                                        <Area type='monotone' dataKey='capacity' stroke='#128186' fillOpacity={0.3} fill='#5EB2B6' />
                                        <XAxis dataKey='label'>
                                        </XAxis>
                                        <YAxis>
                                        </YAxis>
                                        <CartesianGrid stroke='#eee'/>
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        </Card>
                        {
                            weeklyReadings.length > 0 &&
                            <div className={'fdc pl20 flx1 ais'}>
                                <AppBar className='posr dbcsdg' position='static' elevation={0}>
                                    <Toolbar variant='dense'>
                                        <Typography variant='h6' className='flx1 cw'>
                                            SUMMARY
                                        </Typography>
                                        <span className={'flx1'} />
                                    </Toolbar>
                                </AppBar>
                                <div className={'fdr flx1 mt20'}>
                                    <Card className={'flx1 mr20 dbclb jcc tac fdc'}>
                                        <Typography className={'cw fs16'}>
                                            Level This Week
                                        </Typography>
                                        <Typography className={'cw fs41 fw500'}>
                                            {(dam.damLatestReadings?.thisWeek?.capacity ?? 0) * 100}%
                                        </Typography>
                                    </Card>
                                    <Card className={'flx1 dbcb jcc tac fdc'}>
                                        <Typography className={'cw fs16'}>
                                            Levels Last Week
                                        </Typography>
                                        <Typography className={'cw fs41 fw500'}>
                                            {(dam.damLatestReadings?.lastWeek?.capacity ?? 0) * 100}%
                                        </Typography>
                                    </Card>
                                </div>
                                <div className={'fdr flx1 mt20'}>
                                    <Card className={'flx1 mr20 dbcdb fdc jcc tac'}>
                                        <Typography className={'cw fs16'}>
                                            Level Last Year
                                        </Typography>
                                        <Typography className={'cw fs41 fw500'}>
                                            {Number(((dam.damLatestReadings?.lastLastWeek?.capacity ?? 0) * 100).toFixed(2))}%
                                        </Typography>
                                    </Card>
                                    <Card className={'flx1 dbclg jcc tac fdc'}>
                                        <Typography className={'cw fs16'}>
                                            Capacity
                                        </Typography>
                                        <Typography className={'cw fs41 fw500'}>
                                            {dam.damLatestReadings?.thisWeek?.volume ?? 0} m<sup>3</sup>
                                        </Typography>
                                    </Card>
                                </div>
                            </div>
                        }
                    </div>
                </div> 
                <div className={'fdc flx1 mr50 mt50 mb50'}>
                    {
                        !volumeData.length && dam.measurementStick &&
                        <Card className={'jcc aic fdc wfill h50 mb50'}>
                            {
                                session.employee.IsDamAdmin &&
                                <div className={'fdr jcc aic'}>
                                    <Typography className={'fs15 cp aic jcc'}>
                                        Add Volume Data
                                    </Typography>
                                    <EditDamPlateDialog dam={this.props.dam} isLoading={this.props.isLoading} />
                                </div>
                            }
                        </Card>
                    }
                    {
                        !!volumeData.length &&
                        <Card className={'jcc aic fdc flx2'}>
                            <div className={'fl1 aic fdr'}>
                                <Typography className={''}>
                                    {dam.name}
                                </Typography>
                                {
                                    session.employee.IsDamAdmin &&
                                    <EditDamPlateDialog dam={this.props.dam} isLoading={this.props.isLoading} />
                                }
                            </div>
                            <ResponsiveContainer width='100%' height='90%'>
                                <LineChart 
                                    margin={{ top: 0, right: 20, bottom: 30, left: 50 }}
                                    data={volumeData}>                               
                                    <XAxis dataKey='plate' allowDecimals={false} interval={'preserveStart'} minTickGap={125}>
                                        <Label value='LEVEL ON PLATE(M)' offset={0} position='bottom'/>
                                    </XAxis>
                                    <YAxis tickFormatter={this.tickFormater}>
                                        <Label content={
                                            <text x={20} y={0} dx={0} dy={'40%'} fontSize={14} className={'rotate'} textAnchor='middle'>Volume m3</text>
                                        }/>
                                    </YAxis>
                                    <CartesianGrid stroke='#eee'/>
                                    <Line type='monotone' dataKey='volume' stroke='#FD9926' dot={false} strokeWidth={3}/>
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    }
                    <div className={`fdr flx1 ${volumeData.length ? 'mt16' : ''} mb16`}>
                        <Card className={'flx1 mr10 fdc jcc tac'}>
                            <Typography className={'dco fs23 fw300'}>
                                Plate
                            </Typography>
                            <Typography className={'dco fs45 fw500'}>
                                {plateReading?.measurement ?? '0'}m
                            </Typography>
                        </Card>
                        <Card className={'flx1 ml10 fdc jcc tac'}>
                            <Typography className={'dco fs23 fw300'}>
                                Percentage
                            </Typography>
                            <Typography className={'dco fs45 fw500'}>
                                {plateReading?.currentCapacity ?? '0'}%
                            </Typography>
                        </Card>
                    </div>
                    <Card className={'flx1 mt16 dbco fdc jcc tac'}>
                        <Typography className={'cw fs20 fw300'}>
                            Volume
                        </Typography>
                        <Typography className={'cw fs45 fw500'}>
                            {plateReading?.currentVolume ?? '0'}m<sup>3</sup>
                        </Typography>
                    </Card>
                    <span className={'flx1'}>
                    </span>
                </div>
                
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        weeklyReadings: state.dam.readings,
    };
};

export default connect(
    mapStateToProps,
)(DamSummary);
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import PumpingStationFilterPopper from '../popper/PumpingStationFilterPopper';

interface IPumpingStationFilterButtonProps {
    id ?: string;
    open : boolean;

    onClick ?: () => void;

    code : string | null;
    name : string | null;
    division : string | null;
    manArea : string | null;

    onChange ?: (name : string | null, code : string | null, division : string | null, manArea : string | null) => void;

    disabled ?: boolean;
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
}

interface IPumpingStationFilterButtonState {
}

export default class PumpingStationFilterButton extends React.PureComponent<IPumpingStationFilterButtonProps, IPumpingStationFilterButtonState> {
    private filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : IPumpingStationFilterButtonProps) {
        super(props);
        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    public readonly render = () => {
        const {
            id,
            onChange,
            code,
            name,
            division,
            manArea,
            disabled,
            onClick,
            color,
        } = this.props;
        return (
            <> 
                <Tooltip title='Filter'>
                    <div className={''}>
                        <IconButton
                            ref={this.filterRef}
                            aria-label='toggle filter menu'
                            onClick={onClick}
                            disabled={disabled}
                            color={color}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <PumpingStationFilterPopper
                    id={id}
                    anchorEl={this.filterRef.current}
                    open={this.props.open}
                    onCancelClick={onClick}
                    onChange={onChange}
                    code={code}
                    name={name}
                    division={division}
                    manArea={manArea}
                />
            </>
        );
    };
}

import React from 'react';
import { SwitchBaseProps } from '@material-ui/core/internal/SwitchBase';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import firebaseApp from '../../../../services/firebaseService';
import BoreholeIssue from '../../../../@types/model/borehole/issue';
import lodash from 'lodash';

interface IEditBoreholeTestIssuesProps {
    problems : Array<string>;
    onChange ?: SwitchBaseProps['onChange'];
}

interface IEditBoreholeTestIssuesState {
    issues : Array<BoreholeIssue>;
}

export default class EditBoreholeTestIssues extends React.PureComponent<IEditBoreholeTestIssuesProps, IEditBoreholeTestIssuesState> {
    private listener ?: () => void;
    constructor(props : IEditBoreholeTestIssuesProps) {
        super(props);
        this.state = {
            issues: [],
        };
    }

    public readonly componentDidMount = () => {
        this.listen();
    };

    public readonly componentWillUnmount = () => {
        if (this.listener) this.listener();
    };

    private readonly listen = () => {
        this.listener = firebaseApp.firestore().collection(BoreholeIssue.COLLECTION).onSnapshot((snapshot) => {
            // Shallow clone is fine here as we do not modify objects in array.
            const issues = this.state.issues.slice();

            snapshot.docChanges().forEach((f) => {
                const issue = new BoreholeIssue(f.doc);
                const index = lodash.findIndex(issues, n => n.ref.id === f.doc.ref.id);

                switch (f.type) {
                    case 'added':
                        issues.push(issue);
                        break;
                    case 'modified':
                        issues.splice(index, 1, issue);
                        break;
                    case 'removed':
                        issues.splice(index, 1);
                        break;
                }
            });

            this.setState({
                issues,
            });
        }, () => {
            this.setState({
                issues: [],
            });
        });
    };

    private readonly getIssues = (props : IEditBoreholeTestIssuesProps, state : IEditBoreholeTestIssuesState) => state.issues;
    private readonly getProblems = (props : IEditBoreholeTestIssuesProps) => props.problems;
    private readonly getAllIssues = createSelector(
        [this.getIssues, this.getProblems],
        (issues, problems) => {
            const result = issues.slice().map(n => n.name);

            problems.forEach((n) => {
                if (!result.includes(n)) result.push(n);
            });

            return result.sort();
        },
    );

    public readonly render = () => {
        const { problems } = this.props;
        const issues = this.getAllIssues(this.props, this.state);
        return (
            <div className={'gc3'}>
                {
                    issues.map(n => (
                        <div key={n} className={'flx1 ais p5 mb10 pr20'}>
                            <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox value={n} color='primary' checked={problems.includes(n)} onChange={this.props.onChange} />
                                    }
                                    label={n}
                                />
                            </FormControl>
                        </div>
                    ))
                }
            </div>
        );
    };
}

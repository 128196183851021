import React from 'react';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { createSelector } from 'reselect';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import firebase from 'firebase/app';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { IBoreholeRuntimer } from '../../../@types/model/borehole/boreholeTest';

interface IRunTimerInputProps {
    index : number;
    value : IBoreholeRuntimer;
    onChange : (value : IBoreholeRuntimer, index : number) => void;
    onDelete : (index : number) => void;
}

interface IRunTimerInputState {}

export default class RunTimerInput extends React.Component<IRunTimerInputProps, IRunTimerInputState> {
    constructor(props : IRunTimerInputProps) {
        super(props);
        this.state = {};
    }

    private readonly calculateDuration = (value : IBoreholeRuntimer) => {
        const dif = (value.EndTime.toMillis() - value.StartTime.toMillis()) / (1000 * 60);

        if (dif === 0) {
            return 24 * 60;
        }

        return dif;
    };

    private readonly onStartChanged = (date : moment.Moment | null) => {
        if (!date) return;

        const timer = {
            ...this.props.value,
        };

        const endTimeMoment = moment(timer.EndTime.toMillis());
        const startTime = moment().startOf('day').hours(date.hours()).minutes(date.minutes());
        const endTime = moment().startOf('day').hours(endTimeMoment.hours()).minutes(endTimeMoment.minutes());

        if (endTime.isBefore(startTime)) {
            startTime.subtract(1, 'day');
        }

        timer.StartTime = firebase.firestore.Timestamp.fromMillis(startTime.valueOf());
        timer.EndTime = firebase.firestore.Timestamp.fromMillis(endTime.valueOf());
        timer.Duration = this.calculateDuration(timer);

        this.props.onChange(timer, this.props.index);
    };

    private readonly onEndChanged = (date : moment.Moment | null) => {
        if (!date) return;

        const timer = {
            ...this.props.value,
        };
        const startTimeMoment = moment(timer.StartTime.toMillis());
        const startTime = moment().startOf('day').hours(startTimeMoment.hours()).minutes(startTimeMoment.minutes());
        const endTime = moment().startOf('day').hours(date.hours()).minutes(date.minutes());

        if (endTime.isBefore(startTime)) {
            startTime.subtract(1, 'day');
        }

        timer.StartTime = firebase.firestore.Timestamp.fromMillis(startTime.valueOf());
        timer.EndTime = firebase.firestore.Timestamp.fromMillis(endTime.valueOf());
        timer.Duration = this.calculateDuration(timer);

        this.props.onChange(timer, this.props.index);
    };

    public readonly getData = (state : IRunTimerInputState, props : IRunTimerInputProps) => props.value;
    public readonly getStartTime = createSelector(
        [this.getData],
        (value) => {
            return moment(value.StartTime.toMillis());
        },
    );
    public readonly getEndTime = createSelector(
        [this.getData],
        (value) => {
            return moment(value.EndTime.toMillis());
        },
    );

    private readonly onDelete = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.props.onDelete(parseInt(event.currentTarget.value));
    };

    public readonly render = () => {
        const { value, index } = this.props;

        const start = this.getStartTime(this.state, this.props);
        const end = this.getEndTime(this.state, this.props);

        return (
            <div className={'fdr flx1 aifs'}>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <TimePicker value={start} ampm={false} onChange={this.onStartChanged} />
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <TimePicker value={end} ampm={false} onChange={this.onEndChanged} />
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <Typography variant='subtitle1'>
                        {value.Duration / 60} h
                    </Typography>
                </div>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <Tooltip title='Delete'>
                        <div>
                            <IconButton value={index} onClick={this.onDelete}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import boreholeFunctions from '../../../../store/borehole/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import appFunctionsService from '../../../../services/appFunctionsService';
import BoreholeContainerSelector from '../../../customComponents/selector/borehole/ContainerSelector';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IBoreholeTest } from '../../../../@types/model/borehole/boreholeTest';

interface IEditBoreholeTestDrumProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    boreholeTest ?: IBoreholeTest;
}

interface IEditBoreholeTestDrumState {
    open : boolean;
    container : number;
    duration : string;
    yieldFlow : string;
}

export default class EditBoreholeTestDrum extends React.Component<IEditBoreholeTestDrumProps, IEditBoreholeTestDrumState> {
    constructor(props : IEditBoreholeTestDrumProps) {
        super(props);
        this.state = {
            open: false,
            container: 0,
            duration: '',
            yieldFlow: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeTestDrumProps, prevState : IEditBoreholeTestDrumState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                container: 0,
                duration: '',
                yieldFlow: '',
            });
        }
        if (!prevState.open && this.state.open && this.props.boreholeTest) {
            this.setState({
                container: !this.props.boreholeTest.Container ? 0 : parseInt(this.props.boreholeTest.Container.toString()),
                duration: !this.props.boreholeTest.Duration ? '' : this.props.boreholeTest.Duration.toFixed(3),
                yieldFlow: !this.props.boreholeTest.Yield ? '' : this.props.boreholeTest.Yield.toFixed(2),
            });
        }

    };

    private readonly onContainerChange = (container : number) => {
        this.setState({
            container,
        });
    };

    private readonly onDurationChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !parseFloat(event.currentTarget.value)) return;
        this.setState({
            duration: event.currentTarget.value,
        });
    };

    private readonly onYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !parseFloat(event.currentTarget.value)) return;
        this.setState({
            yieldFlow: event.currentTarget.value,
        });
    };

    private readonly save = async () => {
        const { container, duration, yieldFlow } = this.state;

        const boreholeTest = Object.assign({}, this.props.boreholeTest);

        boreholeTest.Container = !container ? null : container;
        boreholeTest.Duration = !duration ? 0.0 : Number(duration);
        boreholeTest.Yield = !yieldFlow ? 0.0 : Number(yieldFlow);

        const result = await boreholeFunctions.saveBoreholeTest(boreholeTest);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, boreholeTest } = this.props;
        const { open, container, duration, yieldFlow } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-info-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Borehole Test ({!boreholeTest ? '' : appFunctionsService.formatDateTimeToDateOnly(boreholeTest.CreatedOn)})
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill oya'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <BoreholeContainerSelector value={container} onChange={this.onContainerChange} />
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='duration'
                                            label='Duration'
                                            value={duration}
                                            onChange={this.onDurationChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!duration && !Number(duration)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        Sec.
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            duration && !Number(duration) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='yieldFlow'
                                            label='Yield'
                                            value={yieldFlow}
                                            onChange={this.onYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!yieldFlow && !Number(yieldFlow)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/hr
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            yieldFlow && !Number(yieldFlow) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { BOREHOLE_STATUS } from '../../../../appConstants';

interface IBoreholeStatusSelectorProps {
    label : string;
    value : string;

    onChange : (value : string) => void;
}

interface IBoreholeStatusSelectorState {}

export default class BoreholeStatusSelector extends React.Component<IBoreholeStatusSelectorProps, IBoreholeStatusSelectorState> {
    constructor(props : IBoreholeStatusSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onBoreholeStatusChange = (event : React.ChangeEvent<{ name ?: string | undefined; value : unknown }>) => {
        if (typeof event.target.value !== 'string') return;
        this.props.onChange(event.target.value);
    };

    public render = () => {
        const { value, label } = this.props;

        return (
            <React.Fragment>
                <div className={'flx1 ais p5 mb10 pr20'}>
                    <FormControl margin='normal' fullWidth>
                        <InputLabel shrink={!!value} htmlFor='value'>{label}</InputLabel>
                        <Select
                            fullWidth
                            id='value'
                            value={!value ? '' : value}
                            onChange={this.onBoreholeStatusChange}>
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            {
                                lodash.map(BOREHOLE_STATUS, (n, i) => (
                                    <MenuItem key={`${n}_status`} value={`${i}`}>
                                        {n}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            </React.Fragment>
        );
    };
}

import { Button, Card, Typography } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntryFormValues, IBoreholeConstantRateTimeRecoveryEntryFormValues } from '../../../../../@types/model/borehole/boreholeConstantRateTest';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import BoreholeConstantRateEntryFormField from '../../../../customComponents/form/boreholeConstantRate/BoreholeConstantRateEntryFormField';
import TextFormField from '../../../../customComponents/form/TextFormField';

interface IBoreholeConstantRateEditDialogTestTabProps {
    isLoading : boolean;
    isValid ?: boolean;

    onSubmitClick ?: () => void;
    onBackClick ?: () => void;
}

interface IBoreholeConstantRateEditDialogTestTabState {
    currentStep : 0 | 1 | 2;
}

export default class BoreholeConstantRateEditDialogTestTab extends React.PureComponent<IBoreholeConstantRateEditDialogTestTabProps, IBoreholeConstantRateEditDialogTestTabState> {
    constructor(props : IBoreholeConstantRateEditDialogTestTabProps) {
        super(props);
        this.state = {
            currentStep: 0,
        };
    }

    private readonly onStepClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            currentStep: Number(event.currentTarget.value) as 0 | 1 | 2,
        });
    };

    public readonly render = () => {
        const { currentStep } = this.state;
        const { onSubmitClick, onBackClick, isValid, isLoading } = this.props;
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <Card className='fdr aic jcc p15 dbco'>
                    <Typography className='flx1 aic jcc fs18 fwb cw'>
                        CALIBRATION TEST
                    </Typography>
                </Card>
                <div className='fdr jcc mt15'>
                    <div className={'mr15'}>
                        <Button
                            value={0}
                            variant='contained'
                            onClick={this.onStepClick}
                            color={currentStep === 0 ? 'primary' : 'secondary'}
                        >
                            CONSTANT TEST
                        </Button>
                    </div>
                    <div className={'mr15'}>
                        <Button
                            value={1}
                            variant='contained'
                            onClick={this.onStepClick}
                            color={currentStep === 1 ? 'primary' : 'secondary'}
                        >
                            RECOVERY
                        </Button>
                    </div>
                    <div className={'mr15'}>
                        <Button
                            value={2}
                            variant='contained'
                            onClick={this.onStepClick}
                            color={currentStep === 2 ? 'primary' : 'secondary'}
                        >
                            NOTES
                        </Button>
                    </div>
                </div>
                {
                    currentStep === 0 &&
                    <div className='fdc'>
                        <Field 
                            name={'entries'}
                        >
                            {
                                (params : FieldProps<Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntryFormValues>>) => (
                                    <BoreholeConstantRateEntryFormField
                                        {...params}
                                    />
                                )
                            }
                        </Field>
                    </div>
                }
                {
                    currentStep === 1 &&
                    <div className='fdc'>
                        <Field 
                            name={'recoveryEntries'}
                        >
                            {
                                (params : FieldProps<Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeRecoveryEntryFormValues>>) => (
                                    <BoreholeConstantRateEntryFormField
                                        {...params}
                                        recovery
                                    />
                                )
                            }
                        </Field>
                    </div>
                }
                {
                    currentStep === 2 &&
                    <div className='fdc mt12'>
                        <TextFormField
                            autoFocus
                            id={'comment'}
                            name={'comment'}
                            fullWidth
                            required
                            multiline
                            disabled={isLoading}
                            inputProps={{
                                className: 'cp fwb',
                            }}
                        />
                    </div>
                }
                {
                    currentStep === 2 &&
                    <div className='fdr jcfe mt15'>
                        <div className='mr15'>
                            <OutlinedStadiumButton disabled={isLoading} onClick={onBackClick}>
                                Back
                            </OutlinedStadiumButton>
                        </div>
                        <div>
                            <StadiumButton disabled={!isValid || isLoading} onClick={onSubmitClick}>
                                Submit
                            </StadiumButton>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

import React from 'react';
import { IAuthState, IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import FlowmeterFunctions from '../../store/flowmeter/functions';
import CustomMapBox, { IMapMarker } from '../customComponents/map/CustomMapBox';
import { AppBar, Drawer, Icon, IconButton, LinearProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import { Transitions } from '../customComponents/animations/Transitions';
import { createSelector } from 'reselect';
import { IFlowmeter } from '../../@types/model/flowmeter/flowmeter';
import { FLOWMETER_MAP_IMAGES } from '../../appConstants';
import FlowmeterInfo from './Info';

interface IFlowmeterMapProps extends RouteComponentProps<{
    Code : string;
}, Record<string, unknown>, Record<string, unknown>> {
    auth : IAuthState;
    flowmeters : Array<IFlowmeter>;
    isLoadingFlowmeters : boolean;
}

interface IFlowmeterMapState {
    selectedFlowmeter ?: IFlowmeter;
}

class FlowmeterMapComponent extends React.Component<IFlowmeterMapProps, IFlowmeterMapState> {
    private readonly mapZoom = 8;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];

    constructor(props : IFlowmeterMapProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        FlowmeterFunctions.getList(this.setSelectedFlowmeter);
    };

    private setSelectedFlowmeter : undefined | (() => void) = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const flowmeterId = urlParams.get('Code') as string;
        this.onMarkerClick(flowmeterId);

        this.setSelectedFlowmeter = undefined;
    };

    private readonly selectMarker = (flowmeter : IFlowmeter) => {
        this.setState({
            selectedFlowmeter: flowmeter,
        });
    };
    
    private readonly onMarkerClick = (id : string) => {
        const flowmeter = this.props.flowmeters.slice().find(n => n.code === id);

        if (flowmeter) {        
            this.selectMarker(flowmeter);
        }
    };

    private readonly onMoreInfo = () => {
        FlowmeterFunctions.setSelected(Object.assign({}, this.state.selectedFlowmeter));
    };

    private readonly onCloseDrawer = () => {
        this.setState({
            selectedFlowmeter: undefined,
        });
    };

    public readonly getData = (state : IFlowmeterMapState, props : IFlowmeterMapProps) => props.flowmeters;

    public readonly getFilteredData = createSelector(
        [this.getData],
        (flowmeters) => {
            return flowmeters.filter(n => n.geo);
        },
    );

    public readonly getMarkers = createSelector(
        [this.getFilteredData],
        (flowmeters) => {
            return flowmeters.map(n => ({
                coordinates: [n.geo?.longitude ?? 0, n.geo?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: n.code,
            } as IMapMarker));
        }
    );

    public readonly render = () => {
        const { isLoadingFlowmeters } = this.props;
        const { selectedFlowmeter } = this.state;
        const flowmeters = this.getFilteredData(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (<div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Toolbar>
                    <Typography variant='h5' color='inherit'>
                        Flowmeters
                    </Typography>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className={'fdr pt5'}>
                            <span style={{ paddingRight: '15px' }}><b>Total: </b>{flowmeters.length}</span>
                        </div>
                    </div>
                </Toolbar>
                <div className='mnh4'>
                    { isLoadingFlowmeters && <LinearProgress />}
                </div>
                <Paper className={'flx1 fdr oxh'}>
                    {
                                            
                        flowmeters.length > 0 &&
                        <CustomMapBox
                            id='flowmeter_map'
                            enableAreas
                            geoLocationControl
                            navigationControl
                            showScale
                            layerControls
                            center={this.mapCenter}
                            zoom={this.mapZoom}
                            images={FLOWMETER_MAP_IMAGES}
                            onMarkerClick={this.onMarkerClick}
                            markers={markers}
                            selectedMarker={selectedFlowmeter?.code}
                        />
                    }
                </Paper>
            </div>
            <Drawer open={!!selectedFlowmeter} anchor='right' variant='persistent'>
                <div className={'w300 flx1 fdc'}>
                    <AppBar className='posr' position='static'>
                        <Toolbar variant='dense'>
                            <div className='h60' />
                            <IconButton color={'inherit'} onClick={this.onMoreInfo}><Icon color={'inherit'}>more_vert</Icon></IconButton>
                            <Typography color='inherit' variant='h6' className={'flx1 ml10'} noWrap={true}>
                                {!!selectedFlowmeter && selectedFlowmeter.code}
                            </Typography>
                            <IconButton color={'inherit'} onClick={this.onCloseDrawer}><Icon color={'inherit'}>close</Icon></IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        selectedFlowmeter &&
                        <div className='fdc flx1'>
                            <div className='fdr flx1'>
                                <div className={'fdc flx1'}>
                                    <Typography className={'fdr bcDarkBlue fw700 cw p10'} variant='h6'>
                                        SUMMARY
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            Code
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            Flowmeter Name
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedFlowmeter.code}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedFlowmeter.name}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <FlowmeterInfo fullScreen transition={Transitions.Up} />
            </Drawer>
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        flowmeters: state.flowmeter.flowmeters,
        flowmeter: state.flowmeter.selectedFlowmeter,
        isLoadingFlowmeters: state.flowmeter.loading,
        auth: state.auth,
    };
};

const FlowmeterMap = connect(
    mapStateToProps,
)(withRouter(FlowmeterMapComponent));

export default FlowmeterMap;

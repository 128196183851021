import firebase, { firestore } from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';
import BoreholeHelper from './borehole';

export interface IBoreholeVideo extends IBasicBaseClass {
    // Id
    borehole : string;
    boreholeCode : string;
    url : string;
    thumbnailUrl : string;
    fileName : string;
}



export default class BoreholeVideoHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'borehole_video';

    private static converter : firebase.firestore.FirestoreDataConverter<IBoreholeVideo> = {
        fromFirestore: (snapshot) => {
            return BoreholeVideoHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBoreholeVideo) : firebase.firestore.DocumentData => {
            return BoreholeVideoHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IBoreholeVideo {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data,
            ...result as IBoreholeVideo,
            borehole: (data['borehole'] as firestore.DocumentReference).id,
        };
    }

    public static toFirestoreDocument(data : IBoreholeVideo) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            borehole: firebaseApp
                .firestore()
                .collection(BoreholeHelper.COLLECTION_NAME)
                .doc(rest.borehole),
        };
    }

    public static doc(boreholeId : string, id ?: string) {
        if (!id) {
            return BoreholeVideoHelper.collection(boreholeId).withConverter(BoreholeVideoHelper.converter).doc();
        }

        return BoreholeVideoHelper.collection(boreholeId).withConverter(BoreholeVideoHelper.converter).doc(id);
    }
    
    public static collection(boreholeId : string) {
        return firebaseApp
            .firestore()
            .collection(BoreholeHelper.COLLECTION_NAME)
            .doc(boreholeId)
            .collection(BoreholeVideoHelper.COLLECTION_NAME)
            .withConverter(BoreholeVideoHelper.converter);
    }

    public static uploadVideo(boreholeCode : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `borehole/${boreholeCode}/video/${new Date().valueOf()}_${file.name}`, metadata);
    }
}
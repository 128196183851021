import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { createSelector } from 'reselect';

interface IMapControlMenuProps {
    position : 'left-top' | 'right-top' | 'left-bottom' | 'right-bottom';

    geoLocationControl ?: boolean;
    showScale ?: boolean;
    navigationControl ?: boolean;

    children ?: React.ReactNode | Array<React.ReactNode>;
}

interface IMapControlMenuState {
    open : boolean;
}

export default class MapControlMenu extends React.PureComponent<IMapControlMenuProps, IMapControlMenuState> {
    private readonly anchorElement : React.RefObject<HTMLButtonElement>;
    constructor(props : IMapControlMenuProps) {
        super(props);
        this.state = {
            open: false,
        };

        this.anchorElement = React.createRef();
    }

    private readonly onClick = () => {
        this.setState({
            open: !this.state.open,
        });
    };

    private readonly onClickAway = () => {
        this.setState({
            open: false,
        });
    };

    private readonly getPosition = (props : IMapControlMenuProps) => props.position;
    private readonly getGeoLocationControl = (props : IMapControlMenuProps) => props.geoLocationControl;
    private readonly getShowScale = (props : IMapControlMenuProps) => props.showScale;
    private readonly getNavigationControl = (props : IMapControlMenuProps) => props.navigationControl;

    public readonly getTop = createSelector(
        [this.getPosition, this.getGeoLocationControl, this.getNavigationControl],
        (position, geoLocationControl, navigationControl) => {
            if (position.includes('bottom')) return undefined;

            let result = 0;

            if (position === 'left-top') return result;

            if (navigationControl) result += 100;
            if (geoLocationControl) result += 35;

            return result;
        },
    );

    public readonly getBottom = createSelector(
        [this.getPosition, this.getShowScale],
        (position, showScale) => {
            if (position.includes('top')) return undefined;

            let result = 0;

            if (showScale) {
                if (position === 'left-bottom') result += 72;
                if (position === 'right-bottom') result += 20;
            }

            return result;
        },
    );

    public readonly render = () => {
        const { children, position } = this.props;
        const { open } = this.state;

        const top = this.getTop(this.props);
        return (
            <div 
                className={`map-controls ${position}`}
                style={{
                    top,
                }}
            >
                <IconButton ref={this.anchorElement} size='small' onClick={this.onClick} aria-controls='map-layer-controls' aria-haspopup='true'>
                    <MoreVertIcon className='map-controls-icon' fontSize='small' />
                </IconButton>
                <Popper open={open} anchorEl={this.anchorElement.current} transition disablePortal>
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: 'left bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.onClickAway}>
                                    <div className='fdc' style={{
                                        minWidth: 200,
                                        marginTop: 5,
                                        marginRight: 10,
                                    }}>
                                        {
                                            children
                                        }
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    };
}

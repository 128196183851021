export type BankAccountType = 'Current' | 'Saving' | 'Recurring Deposit' | 'Fixed Deposit' | 'Other';

export const BANK_ACCOUNT_TYPES : Array<BankAccountType> = ['Current', 'Saving', 'Recurring Deposit', 'Fixed Deposit', 'Other'];

export const BANKS : Array<string> = [
    'ABSA', 'African Bank', 'Bidvest Bank', 'Capitec Bank', 'Discovery Limited',
    'FNB', 'First Rand Bank', 'Grindrod Bank Limited', 'Imperial Bank',
    'Investec Bank', 'Nedbank', 'Sasfin Bank', 'Imperial Bank',
    'Standard Bank', 'Ubank Limited', 'TymeBank',
];

export interface IBankAccount {
    bank : string;
    branch : string;
    accountNumber : string;
    accountType : BankAccountType;
    beneficiaryDescription : string;
    recipientReference : string;
    ownReference : string;
}

export default IBankAccount;

import React from 'react';
import { ITransformer, ITransformerMeter } from '../../../@types/model/transformer/transformer';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import transformerFunctions from '../../../store/transformer/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MeterNumberInput from '../../customComponents/input/TransformerMeterInput';

interface IEditTransformerMetersProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    transformer ?: ITransformer;
}

interface IEditTransformerMetersState {
    open : boolean;
    meters : Array<ITransformerMeter>;
}

export default class EditTransformerMeters extends React.Component<IEditTransformerMetersProps, IEditTransformerMetersState> {
    constructor(props : IEditTransformerMetersProps) {
        super(props);
        this.state = {
            open: false,
            meters: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTransformerMetersProps, prevState : IEditTransformerMetersState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                meters: [],
            });
        }
        if (!prevState.open && this.state.open && this.props.transformer) {
            this.setState({
                meters: this.props.transformer.TransformerMeterNumbers ?? [],
            });
        }

    };

    private readonly onMeterChange = (runTimer : ITransformerMeter) => {
        const meters = this.state.meters.slice();
        meters.splice(runTimer.ItemNumber - 1, 1, runTimer);
        this.setState({
            meters,
        });
    };

    private readonly onMeterDelete = (itemNumber : number) => {
        const meters = this.state.meters.slice();
        meters.splice(itemNumber - 1, 1);

        meters.forEach((n, i) => {
            n.ItemNumber = i + 1;
        });

        this.setState({
            meters,
        });
    };

    private readonly onAddClick = () => {
        const meters = this.state.meters.slice();

        meters.push({
            ItemNumber: meters.length + 1,
            LastReading: null,
            LatestMeterUsage: null,
            Number: '',
            NumberOfDigits: 0,
            UnitOfMeasure: null,
        });

        this.setState({
            meters,
        });
    };

    private readonly save = async () => {
        const { meters } = this.state;

        if (meters.some(n => !n.Number || !n.NumberOfDigits)) return;

        const transformer = {
            ...this.props.transformer,
        } as ITransformer;

        transformer.TransformerMeterNumbers = meters;

        const result = await transformerFunctions.saveTransformer(transformer);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, transformer } = this.props;
        const { open, meters } = this.state;

        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-meter-edit-dialog-title'
                    aria-describedby='transformer-meter-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Transformer ({!transformer ? '' : transformer.EPNumber})
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill oya'>
                            <div className={'fdc flx1 m10'}>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        METER NUMBER
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        NUMBER
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        MEASUREMENT UNIT
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        NO. DIGITS
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                    </Typography>
                                </div>
                                {
                                    meters.map(m => (
                                        <MeterNumberInput key={`${m.ItemNumber}_meter`} value={m} onChange={this.onMeterChange} onDelete={this.onMeterDelete} />
                                    ))
                                }
                                <div className='fdr'>
                                    <span className='flx1' />
                                    <Tooltip title='Add'>
                                        <div>
                                            <IconButton color='primary' onClick={this.onAddClick} aria-label='Add'>
                                                <Icon>add</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>
        );
    };
}

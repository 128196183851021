import { FieldProps } from 'formik';
import React from 'react';
import { MunicipalityInvoiceHelper } from '../../../@types/model/transformer/municipalityInvoice';
import WhiteTextField from '../../customComponents/textField/WhiteTextField';

interface IMunicipilityInvoiceVatFormFieldProps extends FieldProps<number | null> {}

interface IMunicipilityInvoiceVatFormFieldState {}

export default class MunicipilityInvoiceVatFormField extends React.PureComponent<IMunicipilityInvoiceVatFormFieldProps, IMunicipilityInvoiceVatFormFieldState> {
    constructor(props : IMunicipilityInvoiceVatFormFieldProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.form.handleChange(event);
        this.props.form.setFieldTouched(this.props.field.name, true);
        setTimeout(() => {
            MunicipalityInvoiceHelper.calculateTotals(this.props.form);
        });
    };

    public readonly render = () => {
        const {
            field,
        } = this.props;
        return (
            <WhiteTextField
                {...field}
                value={field.value ?? ''}
                variant='standard'
                label='VAT Rebill'
                fullWidth
                type='number'
                onChange={this.onChange}
            />
        );
    };
}

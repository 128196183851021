import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { IDam, EnumDamWallType, EnumDamWallSurfaceProtection, EnumDamSlopeSurfaceProtection } from '../../../@types/model/dam/dam';
import DamFunctions from '../../../store/dam/functions';
import DamWallTypeSelector from '../../customComponents/selector/dam/DamWallTypeSelector';
import DamWallProtectionSelector from '../../customComponents/selector/dam/DamWallProtectionSelector';
import DamSlopeProtectionSelector from '../../customComponents/selector/dam/DamSlopeProtectionSelector';

interface IEditDamMasterDataProps {
    dam : IDam;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditDamMasterDataState {
    open : boolean;
    WARMSNo : string;
    dsiNumber : number;
    damName : string;
    damWallType : number | null;
    damWallProtection : number | null;
    damSlopeProtection : number | null;
    damSlopeProtectionOther : string;
    spillwayType : string;
}

export default class EditDamMasterData extends React.Component<IEditDamMasterDataProps, IEditDamMasterDataState> {
    constructor(props : IEditDamMasterDataProps) {
        super(props);
        this.state = {
            open: false,
            WARMSNo: '',
            dsiNumber: 0,
            damName: '',
            damWallType: null,
            damWallProtection: null,
            damSlopeProtection: null,
            damSlopeProtectionOther: '',
            spillwayType: '',
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditDamMasterDataProps, prevState : IEditDamMasterDataState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                WARMSNo: '',
                dsiNumber: 0,
                damName: '',
                damWallType: null,
                damWallProtection: null,
                damSlopeProtection: null,
                damSlopeProtectionOther: '',
                spillwayType: '',
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                WARMSNo: !this.props.dam.warmsNo ? '' : this.props.dam.warmsNo,
                dsiNumber: !this.props.dam.lastDSINumber ? 0 : this.props.dam.lastDSINumber,
                damName: this.props.dam.name,
                damWallType: this.props.dam.wallType != null ? this.props.dam.wallType : null,
                damWallProtection: this.props.dam.wallProtectionSurface != null ? this.props.dam.wallProtectionSurface : null,
                damSlopeProtection: this.props.dam.slopeSurfaceProtection != null ? this.props.dam.slopeSurfaceProtection : null,
                damSlopeProtectionOther: this.props.dam.slopeSurfaceProtectionOther ?? '',
                spillwayType: !this.props.dam.spillwayType ? '' : this.props.dam.spillwayType,
            });
        }
    };

    private readonly save = async () => {
        const { 
            WARMSNo,
            dsiNumber,
            damName,
            damWallType,
            damWallProtection,
            spillwayType,
            damSlopeProtection,
            damSlopeProtectionOther,
        } = this.state;

        const dam = {
            ...this.props.dam,
        };

        dam.lastDSINumber = dsiNumber;
        dam.warmsNo = WARMSNo;
        dam.name = damName;
        dam.wallType = damWallType != null ? damWallType : null;
        dam.wallProtectionSurface = damWallProtection != null ? damWallProtection : null;
        dam.slopeSurfaceProtection = damSlopeProtection != null ? damSlopeProtection : null;
        dam.slopeSurfaceProtectionOther = damSlopeProtectionOther;
        dam.titleDeed = spillwayType;

        const result = await DamFunctions.saveDam(dam);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onDSINumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            dsiNumber: Number(event.target.value),
        });
    };

    private readonly onWARMSNoChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            WARMSNo: event.target.value,
        });
    };

    private readonly onDamNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            damName: event.target.value,
        });
    };

    private readonly onDamWallTypeChange = (value : EnumDamWallType | null) => {
        this.setState({
            damWallType: value,
        });
    };

    private readonly onDamWallProtectionChange = (value : EnumDamWallSurfaceProtection | null) => {
        this.setState({
            damWallProtection: value,
        });
    };

    private readonly onSpillwayTypeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            spillwayType: event.target.value,
        });
    };

    private readonly onDamSlopeProtectionChange = (value : EnumDamSlopeSurfaceProtection | null) => {
        this.setState({
            damSlopeProtection: value,
        });
    };

    private readonly onSlopeProtectionOtherChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            damSlopeProtectionOther: event.target.value,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { 
            open,
            dsiNumber,
            WARMSNo,
            damName,
            damWallType,
            damWallProtection,
            spillwayType,
            damSlopeProtection,
            damSlopeProtectionOther,
        } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='dam-info-info-title'
                    aria-describedby='dam-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Dam Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='dsiNumber'
                                            label='DSI NUMBER'
                                            value={dsiNumber}
                                            onChange={this.onDSINumberChange}
                                            margin='normal'
                                            className={'TextField'}
                                            type='number'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='WARMSNo'
                                            label='Warms Number'
                                            value={WARMSNo}
                                            onChange={this.onWARMSNoChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='name'
                                            label='Name'
                                            value={damName}
                                            onChange={this.onDamNameChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <DamWallTypeSelector 
                                        value={damWallType} 
                                        onChange={this.onDamWallTypeChange}                                        
                                    />
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <DamWallProtectionSelector 
                                        value={damWallProtection} 
                                        onChange={this.onDamWallProtectionChange}                                        
                                    />
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='spillwayType'
                                            label='Spillway Type'
                                            value={spillwayType}
                                            onChange={this.onSpillwayTypeChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <DamSlopeProtectionSelector 
                                        value={damSlopeProtection} 
                                        onChange={this.onDamSlopeProtectionChange}                                        
                                    />
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    {
                                        damSlopeProtection === EnumDamSlopeSurfaceProtection.Other &&

                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete='off'
                                                id='damSlopeProtectionOther'
                                                label='Slope Protection Other'
                                                value={damSlopeProtectionOther}
                                                onChange={this.onSlopeProtectionOtherChange}
                                                margin='normal'
                                                className={'TextField'}
                                            />
                                        </FormControl>
                                    }
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

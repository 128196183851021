import { AppBar, Dialog, DialogActions, Button, DialogContent, Icon, IconButton, Toolbar, Tooltip, Typography, FormControl, TextField } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Form } from 'informed';
import moment from 'moment';
import React from 'react';
import PumpingStationAuditHelper, { IPumpingStationAudit } from '../../../@types/model/pumpingStation/pumpingStationAudit';
import { IPumpingStationGroup } from '../../../@types/model/pumpingStation/pumpingStationGroup';
import GeneralFunctions from '../../../store/general/functions';
import PumpingStationMotorMakeSelector from '../../customComponents/selector/pumpingStation/PumpingStationMotorMakeSelector';
import PumpingStationMotorSizeSelector from '../../customComponents/selector/pumpingStation/PumpingStationMotorSizeSelector';
import PumpingStationMotorTypeSelector from '../../customComponents/selector/pumpingStation/PumpingStationMotorTypeSelector';
import PumpingStationMotorPoleSelector from '../../customComponents/selector/pumpingStation/PumpingStationMotorPoleSelector';
import PumpingStationMotorPhaseSelector from '../../customComponents/selector/pumpingStation/PumpingStationMotorPhaseSelector';
import PumpingStationMotorEfficiencySelector from '../../customComponents/selector/pumpingStation/PumpingStationMotorEfficiencySelector';
import PumpingStationMotorMountingSelector from '../../customComponents/selector/pumpingStation/PumpingStationMotorMountingSelector';
import { IUserSession } from '../../../@types/employee';

interface IEditPumpingStationMotorProps {
    session : IUserSession;
    pumpingStationAudit : IPumpingStationAudit;
    pumpingGroup : IPumpingStationGroup;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    fullWidth ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IEditPumpingStationMotorState {
    open : boolean;
    make : string;
    type : string;
    size : number | null;
    pole : string;
    phase : string;
    efficiency : string;
    mounting : string;
    serialNumber : string;
    motorImage : string;
}

export default class EditPumpingStationMotor extends React.Component<IEditPumpingStationMotorProps, IEditPumpingStationMotorState> {
    constructor(props : IEditPumpingStationMotorProps) {
        super(props);
        this.state = {
            open: false,
            make: this.props.pumpingGroup.motorMake,
            type: this.props.pumpingGroup.motorType,
            size: this.props.pumpingGroup.motorSize,
            pole: this.props.pumpingGroup.motorPoles,
            phase: this.props.pumpingGroup.motorPhase,
            efficiency: this.props.pumpingGroup.motorEfficiency,
            mounting: this.props.pumpingGroup.motorMountType,
            serialNumber: this.props.pumpingGroup.motorSerial,
            motorImage: this.props.pumpingGroup.motorPhotoThumbnailUrl,
        };
    }

    private readonly onMakeChange = (value : string) => {
        this.setState({ make: value });
    };

    private readonly onSizeChange = (value : number | null) => {
        this.setState({ size: value });
    };

    private readonly onPoleChange = (value : string) => {
        this.setState({ pole: value });
    };

    private readonly onPhaseChange = (value : string) => {
        this.setState({ phase: value });
    };

    private readonly onEfficiencyChange = (value : string) => {
        this.setState({ efficiency: value });
    };

    private readonly onTypeChange = (value : string) => {
        this.setState({ type: value });
    };

    private readonly onMountingChange = (value : string) => {
        this.setState({ mounting: value });
    };

    private readonly onSerialChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ serialNumber: event.target.value });
    };

    private readonly onEditClick = () => {
        this.setState({ open: true });
    };

    private readonly onSubmit = async () => {
        
        const { session } = this.props;

        const { make, size, pole, phase, efficiency, type, mounting, serialNumber } = this.state;

        if (!make || !size || !pole || !phase || !efficiency || !type || !mounting || !serialNumber) {
            GeneralFunctions.generalShowErrorSnackbar('You have left out some information.'); 
            return;
        } 

        const pumpingStationAudit = { ...this.props.pumpingStationAudit, pumpingGroups: [ ...this.props.pumpingStationAudit.pumpingGroups ]};
        
        const pumpingGroupIndex = pumpingStationAudit.pumpingGroups.findIndex((element) => element.reference == this.props.pumpingGroup.reference);

        pumpingStationAudit.updatedBy = session.firebaseUser.uid;
        pumpingStationAudit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStationAudit.updatedByName = session.employee.Name;
        pumpingStationAudit.updatedOn = moment.utc().valueOf();

        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].motorMake = make;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].motorSize = size;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].motorPoles = pole;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].motorPhase = phase;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].motorEfficiency = efficiency;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].motorType = type;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].motorMountType = mounting;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].motorSerial = serialNumber;
        
        try {
            await PumpingStationAuditHelper.save(pumpingStationAudit);

            this.onClose();

            GeneralFunctions.generalShowSuccessSnackbar('Saved changes!');
        } catch {
            GeneralFunctions.generalShowErrorSnackbar('Something went wrong while saving the your changes.');
        }

    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({ open: false });
    };

    public render = () => {

        const { transition, maxWidth, fullScreen, fullWidth, isLoading, pumpingStationAudit, pumpingGroup } = this.props;
        const { open, make, size, pole, phase, efficiency, type, mounting, serialNumber} = this.state;

        return (
            <>
                <Tooltip title={'Edit'}>
                    <IconButton 
                        color={'primary'} 
                        size={'small'} 
                        onClick={this.onEditClick} 
                        aria-label={'Edit'} 
                        disabled={isLoading}
                    >
                        <Icon>edit</Icon>
                    </IconButton> 
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby={'pumpingStation-info-info-title'}
                    aria-describedby={'pumpingStation-info-info-description'}
                >
                    <AppBar position='static' >
                        <Toolbar className={'fdr aic jcsb'}>
                            <Typography variant={'h5'} color={'inherit'}>
                                {`Edit ${pumpingStationAudit.pumpCode} - ${moment.utc(pumpingStationAudit.createdOn).format('DD-MM-YYYY')}: PG ${pumpingGroup.number}`}
                            </Typography>
                            <Tooltip title={'Close'}>
                                <IconButton color={'inherit'} disabled={isLoading} onClick={this.onClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete={'off'} onSubmit={this.onSubmit} className={'fdc flx1 hfill oya'}>
                        <DialogContent className={'fdc hfill'}>
                            <div className={'fdr flx1'}>
                                <PumpingStationMotorMakeSelector 
                                    value={make}
                                    disabled={isLoading}
                                    required={true}
                                    onChange={this.onMakeChange}
                                />
                                <PumpingStationMotorTypeSelector 
                                    value={type}
                                    disabled={isLoading}
                                    required={true}
                                    className={'ml20'}
                                    onChange={this.onTypeChange}
                                />
                            </div>

                            <div className={'fdr flx1'}>
                                <PumpingStationMotorSizeSelector 
                                    value={size}
                                    disabled={isLoading}
                                    required={true}
                                    onChange={this.onSizeChange}
                                />
                                <PumpingStationMotorPoleSelector 
                                    value={pole}
                                    disabled={isLoading}
                                    required={true}
                                    className={'ml20'}
                                    onChange={this.onPoleChange}
                                />
                            </div>

                            <div className={'fdr flx1'}>
                                <PumpingStationMotorPhaseSelector 
                                    value={phase}
                                    disabled={isLoading}
                                    required={true}
                                    onChange={this.onPhaseChange}
                                />
                                <PumpingStationMotorEfficiencySelector 
                                    value={efficiency}
                                    disabled={isLoading}
                                    required={true}
                                    className={'ml20'}
                                    onChange={this.onEfficiencyChange}
                                />
                            </div>

                            <div className='fdr flx1'>
                                <PumpingStationMotorMountingSelector 
                                    value={mounting}
                                    disabled={isLoading}
                                    required={true}
                                    onChange={this.onMountingChange}
                                />
                                <FormControl fullWidth={true} className={'ml20'}>
                                    <TextField 
                                        type={'text'}
                                        autoComplete={'off'}
                                        id={'serialNumber'}
                                        label={'Serial Number'}
                                        value={serialNumber}
                                        margin={'normal'}
                                        className={'TextField'}
                                        error={!serialNumber}
                                        onChange={this.onSerialChange}
                                    />
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button type={'submit'} disabled={isLoading} variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </>
        );
    };
}
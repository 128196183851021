import { Typography, Card } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import { IRootState } from '../../../@types/redux';
import { Transitions } from '../../customComponents/animations/Transitions';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import EditPumpingStationEP from '../dialog/EditEP';
import { IUserSession } from '../../../@types/employee';
import { createSelector } from 'reselect';

interface IPumpingStationTransformerProps {
    pumpingStation : IPumpingStation;

    isLoading : boolean;

    isPumpingStationAdmin : boolean;
    session ?: IUserSession | null;
}

interface IPumpingStationTransformerState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

class PumpingStationTransformerComponent extends React.PureComponent<IPumpingStationTransformerProps, IPumpingStationTransformerState> {
    constructor(props : IPumpingStationTransformerProps) {
        super(props);
        this.state = {
            imageSrcs : [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    public getImages = (props : IPumpingStationTransformerProps) => props.pumpingStation.electricalBoxFiles;
    public readonly getPumpingStationImage = createSelector(
        [this.getImages],
        (images) => {
            return images.length ? images[images.length - 1] : null;
        }
    );

    public readonly getImageUrls = createSelector(
        [this.getPumpingStationImage],
        (pumpingStationImage) => {
            const result : Array<string> = [
                pumpingStationImage?.downloadURL ?? '',
            ];

            return result;
        }
    );

    private onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    }

    private onThumbClick = (event : React.MouseEvent<HTMLImageElement>) => {
        this.setState({
            isCarouselOpen: true,
            imageIndex: Number(event.currentTarget.id),
        });
    }

    public render = () => {
        const { imageIndex, isCarouselOpen } = this.state;
        const { pumpingStation, isPumpingStationAdmin, session } = this.props;

        const image = this.getPumpingStationImage(this.props);
        const imageSrcs = this.getImageUrls(this.props);
        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            ELECTRICAL POINT
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isPumpingStationAdmin &&
                                    <EditPumpingStationEP
                                        pumpingStation={pumpingStation}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                        session={session}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdr ml17 mr17 mb13'}>
                        <div className={'fdc mb13'}>
                            <Typography className={'fs11 bbw1 bocGrey fw500 cp pl15 pr15 pb15 aic jcc'}>
                                TRANSFORMER
                            </Typography>
                            <div className={'bcg0 fdc aic jcfs w200 p10 mt10'}>
                                <Typography className={'fs11 cdg'}>
                                    EP POINT
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {pumpingStation.transformer?.id ?? '-'}
                                </Typography>
                            </div>
                        </div>
                        <div className='brw1 ml17 mr17 bocg3'>
                        </div>
                        <div className={'fdc mb13'}>
                            <Typography className={'fs11 bbw1 bocGrey fw500 cp pl15 pr15 pb15 aic jcc'}>
                                ELECTRICAL BOX
                            </Typography>
                            <div className={'fdc flx1 aic jcc p10 mt10'}>
                                <div className='w200'>
                                    {
                                        !!image &&
                                        <div className='fdc jcc aic flx1'>
                                            <img
                                                id={'0'}
                                                className='curp'
                                                width={200}
                                                src={image.thumbnailUrl ?? image.downloadURL ?? ''}
                                                onClick={this.onThumbClick}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        session : state.auth.session,
        isPumpingStationAdmin : !!state.auth.session?.employee.IsPumpingStationAdmin,
        isLoading: state.pumpingStation.loading,
    };
};

const PumpingStationTransformerView = connect(
    mapStateToProps,
)(PumpingStationTransformerComponent);

export default PumpingStationTransformerView;


import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import { IMottech } from '../../../../@types/mottech';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';

interface IMottechConnectedInputProps {
    id : string;
    label : string;
    value : string;
    required ?: boolean;
    onChange : (value : string) => void;

    mottechs : Array<IMottech>;
}

interface IMottechConnectedInputState {
}

class MottechConnectedInput extends React.Component<IMottechConnectedInputProps, IMottechConnectedInputState> {
    constructor(props : IMottechConnectedInputProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange(event.currentTarget.value);
    };

    public readonly getValue = (state : IMottechConnectedInputState, props : IMottechConnectedInputProps) => props.value;
    public readonly getData = (state : IMottechConnectedInputState, props : IMottechConnectedInputProps) => props.mottechs;

    public readonly getKnownMottech = createSelector(
        [this.getValue, this.getData],
        (value, mottechs) => {

            return !!mottechs.find(n => n.PiccoloId === value);
        },
    );

    public readonly render = () => {
        const { value, label, id, required } = this.props;

        const knownMottech = this.getKnownMottech(this.state, this.props);

        return (
            <FormControl fullWidth>
                <TextField
                    autoComplete='off'
                    id={id}
                    label={label}
                    value={value}
                    onChange={this.onChange}
                    margin='normal'
                    className={'TextField'}
                    required={required}
                    error={!!required && !value}
                />
                {
                    value && !knownMottech &&
                <FormHelperText>Unknown Mottech</FormHelperText>
                }
                {
                    !!required && !value &&
                <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        mottechs: state.mottech.mottechs,
    };
};

export default connect(
    mapStateToProps,
)(MottechConnectedInput);

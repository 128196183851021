import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import boreholeFunctions from '../../../store/borehole/functions';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

interface IEditBoreholeYieldProps {
    borehole : IBorehole;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditBoreholeYieldState {
    open : boolean;
    recommendedYield : string;
    installedYield : string;
    adjustedYield : string;
    hisMaxYieldYield : string;
    emergancyYield : string;
    maxAbstractYield : string;
    installDepthRecommendation : string;
    staticWaterLevel : string;
    dynamicWaterLevel : string;
    criticalWaterLevel : string;
    pumpCycle : string;
}

export default class EditBoreholeYield extends React.Component<IEditBoreholeYieldProps, IEditBoreholeYieldState> {
    constructor(props : IEditBoreholeYieldProps) {
        super(props);
        this.state = {
            open: false,
            recommendedYield: '',
            installedYield: '',
            adjustedYield: '',
            hisMaxYieldYield: '',
            emergancyYield: '',
            maxAbstractYield: '',
            installDepthRecommendation: '',
            staticWaterLevel: '',
            dynamicWaterLevel: '',
            criticalWaterLevel: '',
            pumpCycle: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeYieldProps, prevState : IEditBoreholeYieldState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                recommendedYield: !this.props.borehole.DesignedYield ? '' : this.props.borehole.DesignedYield.toFixed(2),
                installedYield: !this.props.borehole.InstalledYield ? '' : this.props.borehole.InstalledYield.toFixed(2),
                adjustedYield: !this.props.borehole.AdjustedYield ? '' : this.props.borehole.AdjustedYield.toFixed(2),
                hisMaxYieldYield: !this.props.borehole.PerfomanceYield ? '' : this.props.borehole.PerfomanceYield.toFixed(2),
                emergancyYield: !this.props.borehole.EmergencyMaxYield ? '' : this.props.borehole.EmergencyMaxYield.toFixed(2),
                maxAbstractYield: !this.props.borehole.MaximumDailyAbstraction ? '' : this.props.borehole.MaximumDailyAbstraction.toFixed(2),
                installDepthRecommendation: !this.props.borehole.InstallDepthRecommendation ? '' : this.props.borehole.InstallDepthRecommendation.toFixed(2),
                staticWaterLevel: !this.props.borehole.StaticWaterLevel ? '' : this.props.borehole.StaticWaterLevel.toFixed(2),
                dynamicWaterLevel: !this.props.borehole.DynamicWaterLevelLimit ? '' : this.props.borehole.DynamicWaterLevelLimit.toFixed(2),
                criticalWaterLevel: !this.props.borehole.CriticalWaterLevel ? '' : this.props.borehole.CriticalWaterLevel.toFixed(2),
                pumpCycle: !this.props.borehole.PumpingCycle ? '' : this.props.borehole.PumpingCycle.toFixed(2),
            });
        }
    };

    private readonly save = async () => {
        const { recommendedYield, installedYield, adjustedYield,
            hisMaxYieldYield, emergancyYield, maxAbstractYield,
            installDepthRecommendation, staticWaterLevel, dynamicWaterLevel,
            criticalWaterLevel, pumpCycle } = this.state;

        const borehole = Object.assign({}, this.props.borehole);

        borehole.DesignedYield = !recommendedYield || !Number(recommendedYield) ? null : Number(recommendedYield);
        borehole.InstalledYield = !installedYield || !Number(installedYield) ? null : Number(installedYield);
        borehole.AdjustedYield = !adjustedYield || !Number(adjustedYield) ? null : Number(adjustedYield);
        borehole.PerfomanceYield = !hisMaxYieldYield || !Number(hisMaxYieldYield) ? null : Number(hisMaxYieldYield);
        borehole.EmergencyMaxYield = !emergancyYield || !Number(emergancyYield) ? null : Number(emergancyYield);
        borehole.MaximumDailyAbstraction = !maxAbstractYield || !Number(maxAbstractYield) ? null : Number(maxAbstractYield);
        borehole.InstallDepthRecommendation = !installDepthRecommendation || !Number(installDepthRecommendation) ? null : Number(installDepthRecommendation);
        borehole.StaticWaterLevel = !staticWaterLevel || !Number(staticWaterLevel) ? null : Number(staticWaterLevel);
        borehole.DynamicWaterLevelLimit = !dynamicWaterLevel || !Number(dynamicWaterLevel) ? null : Number(dynamicWaterLevel);
        borehole.CriticalWaterLevel = !criticalWaterLevel || !Number(criticalWaterLevel) ? null : Number(criticalWaterLevel);
        borehole.PumpingCycle = !pumpCycle || !Number(pumpCycle) ? null : Number(pumpCycle);

        await boreholeFunctions.saveBorehole(borehole);

        this.setState({
            open: false,
        });
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onRecommenedYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            recommendedYield: event.currentTarget.value,
        });
    };

    private readonly onInstalledYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            installedYield: event.currentTarget.value,
        });
    };

    private readonly onAdjustedYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            adjustedYield: event.currentTarget.value,
        });
    };

    private readonly onHisMaxYieldYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            hisMaxYieldYield: event.currentTarget.value,
        });
    };

    private readonly onEmergancyYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            emergancyYield: event.currentTarget.value,
        });
    };

    private readonly onMaxAbstractYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            maxAbstractYield: event.currentTarget.value,
        });
    };

    private readonly onInstallDepthRecommendationChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            installDepthRecommendation: event.currentTarget.value,
        });
    };

    private readonly onStaticWaterLevelChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            staticWaterLevel: event.currentTarget.value,
        });
    };

    private readonly onDynamicWaterLevelChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            dynamicWaterLevel: event.currentTarget.value,
        });
    };

    private readonly onCriticalWaterLevelChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            criticalWaterLevel: event.currentTarget.value,
        });
    };

    private readonly onPumpCycleChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            pumpCycle: event.currentTarget.value,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, recommendedYield, installedYield, adjustedYield,
            hisMaxYieldYield, emergancyYield, maxAbstractYield, installDepthRecommendation,
            staticWaterLevel, dynamicWaterLevel, criticalWaterLevel, pumpCycle } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-yield-info-title'
                    aria-describedby='borehole-yield-info-description'
                >
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Borehole Yield Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='recommendedYield'
                                            label='Rec. Abstraction Rate'
                                            value={recommendedYield}
                                            onChange={this.onRecommenedYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!recommendedYield && !Number(recommendedYield)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/hr
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            recommendedYield && !Number(recommendedYield) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='installedYield'
                                            label='Installed Yield'
                                            value={installedYield}
                                            onChange={this.onInstalledYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!installedYield && !Number(installedYield)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/hr
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            installedYield && !Number(installedYield) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='adjustedYield'
                                            label='Adjusted Yield'
                                            value={adjustedYield}
                                            onChange={this.onAdjustedYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!adjustedYield && !Number(adjustedYield)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/hr
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            adjustedYield && !Number(adjustedYield) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='hisMaxYieldYield'
                                            label='Rec. Max. Abtraction Rate'
                                            value={hisMaxYieldYield}
                                            onChange={this.onHisMaxYieldYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!hisMaxYieldYield && !Number(hisMaxYieldYield)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/hr
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            hisMaxYieldYield && !Number(hisMaxYieldYield) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='emergancyYield'
                                            label='Emergancy Max. Yield'
                                            value={emergancyYield}
                                            onChange={this.onEmergancyYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!emergancyYield && !Number(emergancyYield)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/hr
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            emergancyYield && !Number(emergancyYield) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='maxAbstractYield'
                                            label='Rec. Max. Abtraction Rate'
                                            value={maxAbstractYield}
                                            onChange={this.onMaxAbstractYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!maxAbstractYield && !Number(maxAbstractYield)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/day
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            maxAbstractYield && !Number(maxAbstractYield) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='staticWaterLevel'
                                            label='Static Water Level'
                                            value={staticWaterLevel}
                                            onChange={this.onStaticWaterLevelChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!staticWaterLevel && !Number(staticWaterLevel)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            staticWaterLevel && !Number(staticWaterLevel) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='dynamicWaterLevel'
                                            label='Dynamic Water Level Limit'
                                            value={dynamicWaterLevel}
                                            onChange={this.onDynamicWaterLevelChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!dynamicWaterLevel && !Number(dynamicWaterLevel)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            dynamicWaterLevel && !Number(dynamicWaterLevel) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='criticalWaterLevel'
                                            label='Critical Water Level Limit'
                                            value={criticalWaterLevel}
                                            onChange={this.onCriticalWaterLevelChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!criticalWaterLevel && !Number(criticalWaterLevel)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            criticalWaterLevel && !Number(criticalWaterLevel) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='installDepth'
                                            label='Install Depth Recommendation'
                                            value={installDepthRecommendation}
                                            onChange={this.onInstallDepthRecommendationChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!installDepthRecommendation && !Number(installDepthRecommendation)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            installDepthRecommendation && !Number(installDepthRecommendation) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            autoComplete='off'
                                            id='pumpCycle'
                                            label='Pumping Cycle'
                                            value={pumpCycle}
                                            onChange={this.onPumpCycleChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!!pumpCycle && !Number(pumpCycle)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        hr/day
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            pumpCycle && !Number(pumpCycle) &&
                                            <FormHelperText error>Invalid</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>
        );
    };
}

import { createAction } from 'typesafe-actions';
import { IEmployee } from '../../@types/employee';
import { IGroundWaterZone } from '../../@types/model';
import { IDivision } from '../../@types/model/masterData/division';
import { Area } from '../../@types/model/area';
import IDepartment from '../../@types/model/department';
import { ICompany } from '../../@types/model/masterData/company';
import { ITransformerAttribute } from '../../@types/model/transformer/transformerAttribute';
import { ITransformerGroup } from '../../@types/model/transformer/transformerGroup';
import { ITransformerUsage } from '../../@types/model/transformer/transformerUsage';

export default class DataActions {
    public static readonly dataSetEmployees = createAction('DATA_SET_EMPLOYEES', resolve => (list : Array<IEmployee>) => resolve(list));
    public static readonly dataSetEmployee = createAction('DATA_SET_EMPLOYEE', resolve => (data ?: IEmployee) => resolve(data));
    public static readonly dataSetLoadingEmployee = createAction('DATA_SET_LOADING_EMPLOYEE', resolve => (data : boolean) => resolve(data));
    public static readonly dataSetDivisions = createAction('DATA_SET_DIVISIONS', resolve => (list : Array<IDivision>) => resolve(list));
    public static readonly dataSetLoadingDivisions = createAction('DATA_SET_LOADING_DIVISION', resolve => (data : boolean) => resolve(data));
    public static readonly dataSetGroundwaterZones = createAction('DATA_SET_GROUNDWATERZONES', resolve => (list : Array<IGroundWaterZone>) => resolve(list));
    public static readonly dataSetLoadingGroundwaterZones = createAction('DATA_SET_LOADING_GROUNDWATERZONES', resolve => (data : boolean) => resolve(data));
    public static readonly setLoadingAreas = createAction('DATA_SET_LOADING_AREAS', resolve => (loading : boolean) => resolve(loading));
    public static readonly setAreas = createAction('DATA_SET_AREAS', resolve => (list : Array<Area>) => resolve(list));
    public static readonly setLoadingDepartments = createAction('DATA_SET_LOADING_DEPARTMENTS', resolve => (loading : boolean) => resolve(loading));
    public static readonly setDepartments = createAction('DATA_SET_DEPARTMENTS', resolve => (list : Array<IDepartment>) => resolve(list));
    public static readonly setLoadingTransformerGroups = createAction('DATA_SET_LOADING_TRANS_GROUPS', resolve => (loading : boolean) => resolve(loading));
    public static readonly setTransformerGroups = createAction('DATA_SET_TRANS_GROUPS', resolve => (list : Array<ITransformerGroup>) => resolve(list));
    public static readonly setLoadingTransformerUsages = createAction('DATA_SET_LOADING_TRANS_USAGE', resolve => (loading : boolean) => resolve(loading));
    public static readonly setTransformerUsages = createAction('DATA_SET_TRANS_USAGE', resolve => (list : Array<ITransformerUsage>) => resolve(list));
    public static readonly setLoadingTransformerAttributes = createAction('DATA_SET_LOADING_TRANS_ATT', resolve => (loading : boolean) => resolve(loading));
    public static readonly setTransformerAttributes = createAction('DATA_SET_TRANS_ATT', resolve => (list : Array<ITransformerAttribute>) => resolve(list));
    public static readonly setLoadingCompany = createAction('DATA_SET_LOADING_COMPANY', resolve => (loading : boolean) => resolve(loading));
    public static readonly setCompanies = createAction('DATA_SET_COMPANIES', resolve => (list : Array<ICompany>) => resolve(list));
}

import firebase from 'firebase/app';
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import TransformerReadingHelper, { IMeterReadingsView, ITransformerReading } from '../../../../@types/model/transformer/transformerReading';
import { Button, CircularProgress, DialogActions, Typography } from '@material-ui/core';
import MaterialTable from '../../../customComponents/materialTable/Table';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { Warning } from '@material-ui/icons';
import ConfirmDialog from '../../../customComponents/dialog/ConfirmDialog';
import { EmployeeHelper, IUserSession } from '../../../../@types/employee';
import lodash from 'lodash';
import { createSelector } from 'reselect';

interface ISPUApproveDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    open : boolean;
    disapprove ?: boolean;

    onClose : (result : boolean) => void;

    values : Array<IMeterReadingsView>;
    title : string;
    
    readings : Array<ITransformerReading>;

    session : IUserSession;
}

interface ISPUApproveDialogState {
    isLoading : boolean;
    showConfirm : boolean;
}

export default class SPUApproveDialog extends React.PureComponent<ISPUApproveDialogProps, ISPUApproveDialogState> {
    constructor(props : ISPUApproveDialogProps) {
        super(props);
        this.state = {
            isLoading: false,
            showConfirm: false,
        };
    }

    private onClose(result : boolean) {
        if (this.state.isLoading) return;
        this.props.onClose(result);
    }

    private readonly onDialogClose = () => {
        if (this.state.isLoading) return;
        this.onClose(false);
    };

    private readonly onCloseClick = () => {
        if (this.state.isLoading) return;
        this.onClose(false);
    };

    private readonly save = async () => {
        this.setState({
            isLoading: true,
        });

        const session = this.props.session;

        const disapprove = !!this.props.disapprove;
        const meterReadings = this.getFilteredData(this.props);
        const guids = lodash.uniq(meterReadings.map(x => x.Guid));
        const readings = this.props.readings.filter(x => guids.includes(x.GUID));

        meterReadings.forEach((meterReading) => {
            const readingIndex = readings.findIndex(x => x.GUID === meterReading.Guid);
            if (readingIndex < 0) return;

            const reading = readings[readingIndex];
            const meterIndex = reading.TransformerMeterNumberReadings.findIndex(x => x.ItemNumber === meterReading.ItemNumber);
            if (meterIndex < 0) return;


            const update = {
                ...reading,
                TransformerMeterNumberReadings: reading.TransformerMeterNumberReadings.map(z => ({
                    ...z,
                })),
            };

            update.TransformerMeterNumberReadings[meterIndex].Approved = disapprove ? false : true;
            update.TransformerMeterNumberReadings[meterIndex].ApprovedBy = disapprove ? null : EmployeeHelper.doc(session.firebaseUser.uid);
            update.TransformerMeterNumberReadings[meterIndex].ApprovedByName = disapprove ? null : session.firebaseUser.displayName ?? '';
            update.TransformerMeterNumberReadings[meterIndex].ApprovedByEmployee = disapprove ? null : session.employee.EmployeeNumber ?? '';
            update.TransformerMeterNumberReadings[meterIndex].ApprovedOn = disapprove ? null : firebase.firestore.Timestamp.now();

            readings.splice(readingIndex, 1, update);
        });

        await TransformerReadingHelper.batchReadingSave(readings, session);

        this.setState({
            isLoading: false,
        });
        this.props.onClose(true);
    };

    public readonly onConfirm = (result : boolean) => {
        this.setState({
            showConfirm: false,
        });
        if (!result) {
            return;
        }
        this.save();
    };

    private readonly onOkClick = () => {
        if (this.state.isLoading) return;

        if (this.props.disapprove) {
            this.save();
        } else {
            if (this.props.values.some(n => !!n.Error)) {
                this.setState({
                    showConfirm: true,
                });
            } else {
                this.save();
            }
        }
    };

    private readonly getValues = (props : ISPUApproveDialogProps) => props.values;
    private readonly getDisapprove = (props : ISPUApproveDialogProps) => props.disapprove;

    public readonly getFilteredData = createSelector(
        [
            this.getValues,
            this.getDisapprove,
        ],
        (values, disapprove) => {
            return values.filter(x => !disapprove ? !x.Approved : x.Approved);
        },
    );
    
    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            open,
            title,
        } = this.props;
        const {
            isLoading,
            showConfirm,
        } = this.state;

        const values = this.getFilteredData(this.props);
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onDialogClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='tooltip-dialog-title'
                    aria-describedby='tooltip-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {
                                    title
                                }
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onCloseClick} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <div className='fdc'>
                            <MaterialTable<IMeterReadingsView>
                                id='valuesGrid'
                                data={values}
                                isLoading={isLoading}
                                rowsPerPage={100}
                                columns={[{
                                    header: '',
                                    field: 'EPNumber',
                                    width: 75,
                                    renderCell: row => (
                                        <div className=' fdr aic'>
                                            {
                                                !!row.Error &&
                                                <Tooltip title={row.Error}>
                                                    <Warning className={'cy pl10'}/>
                                                </Tooltip>
                                            }
                                        </div>
                                    ),
                                }, {
                                    header: 'Date',
                                    field: 'Date',
                                    width: 175,
                                    enableSort: true,
                                    enableFilter: true,
                                    renderCell: row => (<span>{AppFunctionsService.formatDateTimeToDateOnly(row.Date)}</span>),
                                }, {
                                    header: 'EP Point',
                                    field: 'EPNumber',
                                    width: 145,
                                    enableSort: true,
                                    enableFilter: true,
                                }, {
                                    header: 'Area',
                                    field: 'Division',
                                    width: 145,
                                    enableSort: true,
                                    enableFilter: true,
                                }, {
                                    header: 'Group',
                                    field: 'Group',
                                    width: 145,
                                    enableSort: true,
                                    enableFilter: true,
                                }, {
                                    header: 'Meter',
                                    field: 'MeterNumber',
                                    width: 145,
                                    enableSort: true,
                                    enableFilter: true,
                                }, {
                                    header: 'Meter Reading (kWh)',
                                    field: 'Reading',
                                    width: 200,
                                    enableSort: true,
                                    enableFilter: true,
                                }, {
                                    header: 'Last Reading (kWh)',
                                    field: 'Usage',
                                    width: 200,
                                    enableSort: true,
                                    enableFilter: true,
                                    renderCell: row => (
                                        <span>
                                            {
                                                row.Rollover ?
                                                    row.Reading - row.Usage + Number(new Array(row.NumberOfDigits).fill('9').join('')) :
                                                    row.Reading - row.Usage
                                            }
                                        </span>),
                                }, {
                                    header: 'Usage',
                                    field: 'Usage',
                                    width: 145,
                                    enableSort: true,
                                    enableFilter: true,
                                }]}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onCloseClick} disabled={isLoading} color='primary'>
                            Cancel
                        </Button>
                        <Button type='button' disabled={isLoading} onClick={this.onOkClick}>
                            {
                                !isLoading &&
                                <Icon>check</Icon>
                            }
                            {
                                !!isLoading &&
                                <CircularProgress size={24}></CircularProgress>
                            }
                            <span className='pl10'>SUBMIT</span>
                        </Button>
                    </DialogActions>
                    {
                        showConfirm &&
                        <ConfirmDialog
                            open
                            onClose={this.onConfirm}
                            message={'Some readings have errors, are you sure you want to approve them?'}
                            title={'Confirm'} />
                    }
                </Dialog>
            </React.Fragment>
        );
    };
}

import React from 'react';
import { IMottechAudit } from '../../../@types/mottech';
import Typography from '@material-ui/core/Typography';
import FirebaseImageButton from '../../customComponents/button/FirebaseImageButton';

interface IMottechAuditEntryViewProps {
    audit : IMottechAudit;
}

interface IMottechAuditEntryViewState {}

export default class MottechAuditEntryView extends React.Component<IMottechAuditEntryViewProps, IMottechAuditEntryViewState> {
    constructor(props : IMottechAuditEntryViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { audit } = this.props;
        return (
            <div className='fdc'>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            ANTENNA ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.AntennaIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.AntennaIssues.length &&
                                    <span>{audit.AntennaIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            BATTERY ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.BatteryIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.BatteryIssues.length &&
                                    <span>{audit.BatteryIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            ENCLOSURE ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.EnclosureIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.EnclosureIssues.length &&
                                    <span>{audit.EnclosureIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            FUSE ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.FusesIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.TransformerIssues.length &&
                                    <span>{audit.FusesIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            PIU ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.PIUIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.PIUIssues.length &&
                                    <span>{audit.PIUIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            POWER SUPPLY ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.PowerSupplyIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.PowerSupplyIssues.length &&
                                    <span>{audit.PowerSupplyIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            SOLAR PANEL ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.SolarPanelIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.SolarPanelIssues.length &&
                                    <span>{audit.SolarPanelIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            SOLENOID ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.SolenoidIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.SolenoidIssues.length &&
                                    <span>{audit.SolenoidIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            SURGE PROTECTOR ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.SurgeProtectorIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.SurgeProtectorIssues.length &&
                                    <span>{audit.TransformerIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            TRANSFORMER ISSUES
                        </Typography>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    (!audit.TransformerIssues.length) &&
                                    <span>None</span>
                                }
                                {
                                    !!audit.TransformerIssues.length &&
                                    <span>{audit.TransformerIssues.join(', ')}</span>
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            IMAGES
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            {
                                audit.ImageName &&
                                <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                    Mottech ({audit.Mottech.id})
                                </Typography>
                            }
                        </div>
                        <div className={'fdr mb10 pl10 pr10 jcc'}>
                            <Typography className={'flx1 pl10 pr10 mnh48 jcc'} variant='subtitle1'>
                                {
                                    audit.ImageName &&
                                    <FirebaseImageButton fileName={audit.ImageName} path={`mottech/${audit.Mottech.id}`} />
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

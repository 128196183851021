import React from 'react';
import mottechFunctions from '../../store/mottech/functions';
import { IMottech } from '../../@types/mottech';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import MottechInfo from './Info';
import { Transitions } from '../customComponents/animations/Transitions';
import navFunctions from '../../store/nav/functions';
import appFunctionsService from '../../services/appFunctionsService';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import MottechFilterSelector from '../customComponents/selector/mottech/FilterSelector';
import { IUserSession } from '../../@types/employee';
import { CSVLinkProps } from '../../@types/csv';

interface IMottechListProps {
    mottechs : Array<IMottech>;
    isLoadingMottechs : boolean;
    session ?: IUserSession | null;
}

interface IMottechListState {
    sortList : Array<{ field : string; direction : 'asc' | 'desc' }>;
    name : string;
    division : string;
    code : string;
    managementArea : string;
}

class MottechList extends React.Component<IMottechListProps, IMottechListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : IMottechListProps) {
        super(props);

        this.state = {
            sortList: [],
            name: '',
            division: '',
            code: '',
            managementArea: '',
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        mottechFunctions.getList();
    };

    private readonly onSortChanged = (sortList : Array<{ field : string; direction : 'asc' | 'desc' }>) => {
        this.setState({
            sortList,
        });
    };

    public readonly getData = (state : IMottechListState, props : IMottechListProps) => props.mottechs;
    public readonly getSortList = (state : IMottechListState) => state.sortList;
    public readonly getName = (state : IMottechListState) => state.name;
    public readonly getCode = (state : IMottechListState) => state.code;
    public readonly getDivision = (state : IMottechListState) => state.division;
    public readonly getManagementArea = (state : IMottechListState) => state.managementArea;

    public readonly getFilteredData = createSelector(
        [this.getData, this.getSortList, this.getName, this.getCode, this.getDivision, this.getManagementArea],
        (mottechs, sortList, name, code, division, managementArea) => {
            let sortedList = mottechs
                .filter(n => (n.IsActive === undefined || n.IsActive))
                .filter(n => n.Code.toLowerCase().includes(code.toLowerCase()))
                .filter(n => (n.Division ?? '').toLowerCase().includes(division.toLowerCase()))
                .filter(n => (n.ManagementArea ?? '').toLowerCase().includes(managementArea.toLowerCase()))
                .filter(n => n.Name.toLowerCase().includes(name.toLowerCase()));

            if (sortList.length === 0) {
                return sortedList.sort((a, b) => parseInt(b.Code.substring(2)) - parseInt(a.Code.substring(2)));
            }

            sortList.forEach((n) => {
                if (n.field === 'Code') {
                    sortedList = sortedList.sort((a, b) => parseInt(b.Code.substring(2)) - parseInt(a.Code.substring(2)) * (n.direction === 'desc' ? 1 : -1));
                } else {
                    sortedList = lodash.orderBy(sortedList, n.field, n.direction);
                }
            });

            return sortedList;
        },
    );

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (boreholes) => {
            return boreholes.map(n => ({
                Code: n.Code,
                Name: n.Name,
                Division: n.Division,
                ManagementArea: n.ManagementArea,
                PiccoloId: n.PiccoloId,
                SerialNumber: n.SerialNumber,
                ConnectedTo: n.ConnectedTo,
                Type: n.Type,
                InstallationDate: !n.InstallationDate ? '' : appFunctionsService.formatDateTime(n.InstallationDate),
                Repeater: n.Repeater ? 'YES' : 'NO',
                Repeaters: n.Repeater && n.Repeaters?.length ? n.Repeaters.join(', ') : '',
                CreatedOn: appFunctionsService.formatDateTime(n.CreatedOn),
                UpdatedOn: appFunctionsService.formatDateTime(n.UpdatedOn),
                Latitude: !n.Geom ? 0 : n.Geom.latitude,
                Longitude: !n.Geom ? 0 : n.Geom.longitude,
            }));
        },
    );

    public readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const mottech = this.props.mottechs.slice().find(x => x.Code === event.currentTarget.value);

        mottechFunctions.setSelected(mottech);
    };

    public readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const mottech = this.props.mottechs.slice().find(x => x.Code === event.currentTarget.value);

        if (mottech) {
            navFunctions.navMottechMap(mottech.Code);
        }
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    private readonly onFilterChange = (code : string | null, name : string | null, division : string | null, managementArea : string | null) => {
        this.setState({
            name: name ?? '',
            code: code ?? '',
            division: division ?? '',
            managementArea: managementArea ?? '',
        });
    };

    public readonly render = () => {
        const { isLoadingMottechs, session } = this.props;
        const { name, code, division, managementArea } = this.state;
        const mottechs = this.getFilteredData(this.state, this.props);

        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.state, this.props);

        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                        MOTTECH
                        </Typography>
                        <span className={'flx4'} />
                        <div className='flx1'>
                            <MottechFilterSelector
                                id='mottech'
                                onChange={this.onFilterChange}
                                code={code}
                                name={name}
                                division={division}
                                managementArea={managementArea}
                                label='Filter By...'
                                disabled={isLoadingMottechs}
                            />
                        </div>
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{mottechs.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IMottech>
                            id='mottechsTable'
                            data={mottechs}
                            isLoading={isLoadingMottechs}
                            rowsPerPage={50}
                            externalSort
                            onSortChange={this.onSortChanged}
                            onExportCsvClick={!session?.employee.IsMottechExport ? undefined : this.onCSVClick}
                            columns={[{
                                header: '',
                                width: 100,
                                paddingRight: 4,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton value={row.Code} onClick={this.onInfoClick}>
                                                    <Icon>
                                                    list
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton value={row.Code} onClick={this.onMapClick} disabled={!row.Geom}>
                                                    <Icon>
                                                    map
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Division',
                                field: 'Division',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Management Area',
                                field: 'ManagementArea',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Code',
                                field: 'Code',
                                width: 125,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Name',
                                field: 'Name',
                                width: 125,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Piccolo Id',
                                field: 'PiccoloId',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Serial Number',
                                field: 'SerialNumber',
                                enableSort: true,
                                enableFilter: true,
                                width: 175,
                            }, {
                                header: 'Connected To',
                                field: 'ConnectedTo',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Type',
                                field: 'Type',
                                width: 125,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Installation Date',
                                field: 'InstallationDate',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => (!n.InstallationDate ? '' : <span>{appFunctionsService.formatDateTimeToDateOnly(n.InstallationDate)}</span>),
                            }, {
                                header: 'Repeater',
                                field: 'Repeater',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => (<Icon>{n.Repeater ? 'done' : 'close' }</Icon>),
                            }]}
                        />
                    </Card>
                    <MottechInfo fullScreen transition={Transitions.Up} />
                    {
                        session?.employee.IsMottechExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'Code',
                                    label: 'Code',
                                },
                                {
                                    key: 'Name',
                                    label: 'Name',
                                },
                                {
                                    key: 'Division',
                                    label: 'Division',
                                },
                                {
                                    key: 'ManagementArea',
                                    label: 'Management Area',
                                },
                                {
                                    key: 'Latitude',
                                    label: 'Latitude',
                                },
                                {
                                    key: 'Longitude',
                                    label: 'Longitude',
                                },
                                {
                                    key: 'PiccoloId',
                                    label: 'Piccolo Id',
                                },
                                {
                                    key: 'SerialNumber',
                                    label: 'Serial Number',
                                },
                                {
                                    key: 'ConnectedTo',
                                    label: 'Connected To',
                                },
                                {
                                    key: 'Type',
                                    label: 'Type',
                                },
                                {
                                    key: 'InstallationDate',
                                    label: 'Installation Date',
                                },
                                {
                                    key: 'Repeater',
                                    label: 'Repeater',
                                },
                                {
                                    key: 'Repeaters',
                                    label: 'Repeaters',
                                },
                                {
                                    key: 'CreatedOn',
                                    label: 'Created On',
                                },
                                {
                                    key: 'UpdatedOn',
                                    label: 'Updated On',
                                },
                            ]}
                            filename={`${csvName}_mottech.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        mottechs: state.mottech.mottechs,
        isLoadingMottechs: state.mottech.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(MottechList);

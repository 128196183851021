import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPump } from '../../../@types/model/borehole/pump';

type MasterDataPumpActionType = ActionType<typeof actions>;

export interface IMasterDataPumpState {
    pumps : Array<IPump>;
    isLoading : boolean;
}
const initialState = {
    pumps: [],
    isLoading: false,
};

export default function pumpReducer(state : IMasterDataPumpState = initialState, action : MasterDataPumpActionType) : IMasterDataPumpState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, pumps: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IDam } from '../../../@types/model/dam/dam';
import { IDamWeeklyReading } from '../../../@types/model/dam/weeklyReading';
import { IRootState } from '../../../@types/redux';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';

interface IDamImagesViewProps {
    dam : IDam;

    isDamAdmin : boolean;
    readings : Array<IDamWeeklyReading>;
}

interface IDamImagesViewState {
    isCarouselOpen : boolean;
    imageIndex : number;
}

class DamImagesViewComponent extends React.PureComponent<IDamImagesViewProps, IDamImagesViewState> {
    constructor(props : IDamImagesViewProps) {
        super(props);
        this.state = {
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    public readonly getReadings = (props : IDamImagesViewProps) => props.readings;
    public readonly getDamImage = createSelector(
        [this.getReadings],
        (readings) => {
            const images = readings
                .sort((a, b) => b.date.toMillis() - a.date.toMillis())
                .filter(x => !!x.imageDam)
                .map(n => n.imageDam);

            return images.length ? images[0] : null;
        }
    );

    public readonly getMeasureImage = createSelector(
        [this.getReadings],
        (readings) => {
            const images = readings
                .sort((a, b) => b.date.toMillis() - a.date.toMillis())
                .filter(x => !!x.imageMeasurestick)
                .map(n => n.imageMeasurestick);

            return images.length ? images[0] : null;
        }
    );

    public readonly getImageUrls = createSelector(
        [this.getDamImage, this.getMeasureImage],
        (damImage, measureImage) => {
            const result : Array<string> = [
                damImage?.downloadURL ?? '',
                measureImage?.downloadURL ?? '',
            ];

            return result;
        }
    );

    private readonly onThumbClick = (event : React.MouseEvent<HTMLImageElement>) => {
        this.setState({
            isCarouselOpen: true,
            imageIndex: Number(event.currentTarget.id),
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { imageIndex, isCarouselOpen } = this.state;
        
        const damImage = this.getDamImage(this.props);
        const measureImage = this.getMeasureImage(this.props);
        const imageSrcs = this.getImageUrls(this.props);

        return (
            <div className={'fdc flx1 mt15'}>
                <div className={'flx1 fdr aifs jcc'}>
                    <Card className={'bcg0 fdc mr30'}>
                        <div className={'jcc aic wfill bcp h50'}>
                            <Typography className={'fs14 fw500 cw '}>
                                OVERALL DAM IMAGE
                            </Typography>
                        </div>
                        <div className='fdc p15 aic jcc w400 mnh250 flx1'>
                            {
                                !!damImage &&
                                <div className='fdc jcc aic flx1'>
                                    <img
                                        id={'0'}
                                        className='curp'
                                        height='225'
                                        src={damImage.thumbnailUrl ?? ''}
                                        onClick={this.onThumbClick}
                                    />
                                </div>
                            }
                            {
                                !damImage?.downloadURL &&
                                <div className='fdc jcc aic flx1 cpd'>
                                    No Image
                                </div>
                            }
                        </div>
                    </Card>
                    <Card className={'bcg0 fdc mr30'}>
                        <div className={'jcc aic wfill bcp h50'}>
                            <Typography className={'fs14 fw500 cw '}>
                                MEASUREMENT STICK IMAGES
                            </Typography>
                        </div>
                        <div className='fdc p15 aic jcc w400 mnh250 flx1'>
                            {
                                !!measureImage &&
                                <div className='fdc jcc aic flx1'>
                                    <img
                                        id={'1'}
                                        className='curp'
                                        height='225'
                                        src={measureImage.thumbnailUrl ?? ''}
                                        onClick={this.onThumbClick}
                                    />
                                </div>
                            }
                            {
                                !measureImage?.downloadURL &&
                                <div className='fdc jcc aic flx1 cpd'>
                                    No Image
                                </div>
                            }
                        </div>
                    </Card>
                </div>
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isDamAdmin: !!state.auth.session?.employee.IsDamAdmin,
        readings: state.dam.readings,
    };
};

const DamImagesView = connect(
    mapStateToProps,
)(DamImagesViewComponent);

export default DamImagesView;

import { Typography, Card, AppBar, Toolbar } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../../@types/redux';
import { IChemicalFillingPoint } from '../../../../@types/model/fillingPoint/chemical';
import EditLocationDialog from '../../../customComponents/dialog/LocationEditDialog';
import { CHEMICAL_FILLING_POINT_MAP_IMAGES } from '../../../../appConstants';
import { Transitions } from '../../../customComponents/animations/Transitions';
import FillingPointChemicalFunctions from '../../../../store/fillingPoint/chemical/functions';
import EditChemicalFillingPoint from '../../dialog/EditInfoDialog';

interface IChemicalFillingPointInfoViewProps {
    point : IChemicalFillingPoint;

    isLoading : boolean;

    isFillingPointAdmin : boolean;
}

interface IChemicalFillingPointInfoViewState {
}

class ChemicalFillingPointInfoViewComponent extends React.PureComponent<IChemicalFillingPointInfoViewProps, IChemicalFillingPointInfoViewState> {
    constructor(props : IChemicalFillingPointInfoViewProps) {
        super(props);
        this.state = {
        };
    }
    
    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number) => {
        const point = {
            ...this.props.point,
        };

        point.geo = geom;
        point.elevation = elevation;

        await FillingPointChemicalFunctions.save(point);
    };

    public readonly render = () => {
        const { point, isFillingPointAdmin, isLoading } = this.props;

        return (
            <div className='fdr flx1 bcg0 wfill hfill'>
                <div className={'fdc mt50 ml50 mr25'}>
                    <div className={''}>
                        <Card>
                            <AppBar className='posr' position='static' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='cw'>
                                        CHEMICAL FILLING POINT INFO
                                    </Typography>
                                    <span className={'flx1'} />
                                    {
                                        isFillingPointAdmin &&
                                        <EditChemicalFillingPoint
                                            point={point}
                                            isLoading={isLoading}
                                            fullWidth
                                            maxWidth='md'
                                            transition={Transitions.Down}
                                        />
                                    }
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr ais p20 flx1'}>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'fs12 fwr'}>
                                        Code
                                    </Typography>
                                    <span className={'fs16 fwm'}>
                                        {point.code}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'fs12 fwr'}>
                                        Name
                                    </Typography>
                                    <span className={'fs16 fwm'}>
                                        {point.name}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'fs12 fwr'}>
                                        Division
                                    </Typography>
                                    <span className={'fs16 fwm'}>
                                        {point.division ?? ''}
                                    </span>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className={'pt50'}>
                        <Card>
                            <AppBar className='posr dvblg' position='static' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='flx1 cw'>
                                        LOCATION
                                    </Typography>
                                    <span className={'flx1'} />
                                    {
                                        isFillingPointAdmin &&
                                        <EditLocationDialog
                                            markerImage={CHEMICAL_FILLING_POINT_MAP_IMAGES['1']}
                                            markerImageSize = {0.35}
                                            elevation={point.elevation ?? 0}
                                            geom={point.geo}
                                            transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                            onSave={this.onLocationSave}
                                        />
                                    }
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr ais p20 flx1'}>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'fs12 fwr'}>
                                        Latitude
                                    </Typography>
                                    <span className={'fs16 fwm'}>
                                        {point.geo?.latitude.toFixed(12) ?? '-'}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'fs12 fwr'}>
                                        Logitude
                                    </Typography>
                                    <span className={'fs16 fwm'}>
                                        {point.geo?.longitude.toFixed(12) ?? '-'}
                                    </span>
                                </div>
                                <div className={'fdc pr20 flx1'}>
                                    <Typography className={'fs12 fwr'}>
                                        Elevation
                                    </Typography>
                                    <span className={'fs16 fwm'}>
                                        {point.elevation ?? '-'}
                                    </span>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className={'fdc mt50 ml25'}>
                    <div className={''}>
                        <Card>
                            <AppBar className='posr' position='static' elevation={0}>
                                <Toolbar variant='dense'>
                                    <Typography variant='h6' className='cw'>
                                        IMAGE
                                    </Typography>
                                    <span className={'flx1'} />
                                </Toolbar>
                            </AppBar>
                            <div className={'fdr aic jcc p20 flx1 w600'}>
                                {
                                    !point.photoUrl &&
                                    <div className='fdc flx1 aic jcc'>
                                        <Typography variant='h6'>
                                            NO IMAGE
                                        </Typography>
                                    </div>
                                }
                                {
                                    point.photoUrl &&
                                    <div className='fdc aic jcc h600'>
                                        <img src={point.photoUrl} height={'100%'} width={'100%'} />
                                    </div>
                                }
                            </div>
                        </Card>
                    </div>
                    
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isFillingPointAdmin: !!state.auth.session?.employee.IsFillingPointAdmin,
        isLoading: state.fillingPoints.chemical.isLoading,
    };
};

const ChemicalFillingPointInfoView = connect(
    mapStateToProps,
)(ChemicalFillingPointInfoViewComponent);

export default ChemicalFillingPointInfoView;


import firebaseApp from '../../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../../base';

export interface IPumpingStationFilterSize extends IBasicBaseClass {
    size : number;

    isActive : boolean;
}

export default class PumpingStationFilterSizeHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station_filter_size';

    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStationFilterSize> = {
        fromFirestore: (snapshot) => {
            return PumpingStationFilterSizeHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStationFilterSize) : firebase.firestore.DocumentData => {
            return PumpingStationFilterSizeHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStationFilterSize {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStationFilterSize,
            ...result,
        };
    }

    public static toFirestoreDocument(data : IPumpingStationFilterSize) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationFilterSizeHelper.COLLECTION_NAME).withConverter(PumpingStationFilterSizeHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationFilterSizeHelper.COLLECTION_NAME).withConverter(PumpingStationFilterSizeHelper.converter).doc(id);
    }

    public static save(company : IPumpingStationFilterSize) {
        return PumpingStationFilterSizeHelper.doc(company.id).set(company, {
            merge: true,
        });
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(PumpingStationFilterSizeHelper.COLLECTION_NAME)
            .withConverter(PumpingStationFilterSizeHelper.converter);
    }
}
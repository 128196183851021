import React from 'react';
import transformerFunctions from '../../store/transformer/functions';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { IAuthState, IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { Transitions } from '../customComponents/animations/Transitions';
import TransformerInfo from './Info';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import { withRouter, RouteComponentProps } from 'react-router';
import { ITransformer } from '../../@types/model/transformer/transformer';
import CustomMapBox, { IMapMarker } from '../customComponents/map/CustomMapBox';
import { TRANSFORMER_MAP_IMAGES } from '../../appConstants';
import TransformerMapSideView from './MapSideView';

interface ITransformerMapProps extends RouteComponentProps<{
    EPNumber : string;
}, Record<string, unknown>, Record<string, unknown>> {
    auth : IAuthState;
    transformers : Array<ITransformer>;
    isLoadingTransformers : boolean;
}

interface ITransformerMapState {
    selectedTransformer ?: ITransformer;
}

class TransformerMap extends React.Component<ITransformerMapProps, ITransformerMapState> {

    private mapZoom = 8;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];
    constructor(props : ITransformerMapProps) {
        super(props);
        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        transformerFunctions.getList(this.setSelectedTransformer);
    };

    private setSelectedTransformer : undefined | (() => void) = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const transformerId = urlParams.get('Code') as string;
        this.onMarkerClick(transformerId);

        this.setSelectedTransformer = undefined;
    };

    private readonly selectMarker = (transformer : ITransformer) => {
        this.setState({
            selectedTransformer: transformer,
        });
    };
    
    private readonly onMarkerClick = (id : string) => {
        const transformer = this.props.transformers.slice().find(n => n.EPNumber === id);

        if (transformer) {        
            this.selectMarker(transformer);
        }
    };
    private onMoreInfo = () => {
        transformerFunctions.setSelected(Object.assign({}, this.state.selectedTransformer));
    };

    private readonly onCloseDrawer = () => {
        this.setState({
            selectedTransformer: undefined,
        });
    };

    public readonly getData = (state : ITransformerMapState, props : ITransformerMapProps) => props.transformers;
    public readonly getFilteredData = createSelector(
        [this.getData],
        (transformers) => {
            return transformers.slice().filter(n => n.Geom && (n.IsActive === undefined || n.IsActive));
        },
    );

    public readonly getMarkers = createSelector(
        [this.getFilteredData],
        (transformers) => {
            return transformers.map(n => ({
                coordinates: [n.Geom?.longitude ?? 0, n.Geom?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: n.EPNumber,
            } as IMapMarker));
        }
    );

    public readonly render = () => {
        const { isLoadingTransformers } = this.props;
        const { selectedTransformer } = this.state;
        const transformers = this.getFilteredData(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (<div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Toolbar>
                    <Typography variant='h5' color='inherit' className={'fw500'}>
                        TRANSFORMERS
                    </Typography>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className={'fdr pt5'}>
                            <span style={{ paddingRight: '15px' }}><b>Total: </b>{transformers.length}</span>
                        </div>
                    </div>
                </Toolbar>
                <div className='mnh4'>
                    { isLoadingTransformers && <LinearProgress />}
                </div>
                <Paper className={'flx1 fdr oxh'}>
                    {
                                            
                        transformers.length > 0 &&
                    <CustomMapBox
                        id='transformer_map'
                        enableAreas
                        geoLocationControl
                        navigationControl
                        showScale
                        layerControls
                        center={this.mapCenter}
                        zoom={this.mapZoom}
                        images={TRANSFORMER_MAP_IMAGES}
                        onMarkerClick={this.onMarkerClick}
                        markers={markers}
                        selectedMarker={selectedTransformer?.EPNumber}
                    />
                    }
                </Paper>
            </div>
            <Drawer open={!!selectedTransformer} anchor='right' variant='persistent'>
                <div className={'w500 flx1 fdc'}>
                    <AppBar className='posr bcpd' position='static'>
                        <Toolbar variant='dense' className={'pl15 pr15'}>
                            <div className='h60' />
                            <IconButton color={'inherit'} size={'small'} onClick={this.onMoreInfo}><Icon fontSize={'large'} color={'inherit'}>info_outline</Icon></IconButton>
                            <Typography color='inherit' variant='h5' className={'flx1 ml10'} noWrap={true}>
                                {!!selectedTransformer && selectedTransformer.EPNumber}
                            </Typography>
                            <IconButton color={'inherit'} size={'small'} onClick={this.onCloseDrawer}><Icon fontSize={'large'} color={'inherit'}>close</Icon></IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        selectedTransformer && 
                        <TransformerMapSideView transformer={selectedTransformer} />
                    }
                </div>
            </Drawer>
            <TransformerInfo fullScreen transition={Transitions.Up} />
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        transformer: state.transformer.transformer,
        isLoadingTransformers: state.transformer.loading,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(TransformerMap));

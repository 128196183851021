import React, { ChangeEvent } from 'react';
import { IAuthState, IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import { createSelector } from 'reselect';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import GeneralFunctions from '../../../../store/general/functions';
import PumpingStationPumpTypeHelper, { IPumpingStationPumpType } from '../../../../@types/model/masterData/pumpingStation/pumpType';
import MasterDataPumpingStationPumpTypeFunctions from '../../../../store/masterData/pumpingStation/pumpType/functions';

interface IPumpingStationPumpTypeListProps {
    auth : IAuthState;

    isLoading : boolean;
    pumpTypes : Array<IPumpingStationPumpType>;
}

interface IPumpingStationPumpTypeListState {
    searchText : string;

    selected : IPumpingStationPumpType | null;

}

class PumpingStationPumpTypeListComponent extends React.PureComponent<IPumpingStationPumpTypeListProps, IPumpingStationPumpTypeListState> {
    constructor(props : IPumpingStationPumpTypeListProps) {
        super(props);
        this.state = {
            searchText: '',
            selected: null,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    };

    private readonly load = () => {
        MasterDataPumpingStationPumpTypeFunctions.load();
    };

    private readonly onTypeClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({
            selected: this.props.pumpTypes.find(n => n.id === event.currentTarget.id) ?? null,
        });
    };

    private readonly onSearchChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            searchText: event.currentTarget.value,
        });
    };

    private readonly onNameChange = (event : ChangeEvent<HTMLInputElement>) => {
        const { selected } = this.state;

        if (!selected) return;

        this.setState({
            selected: {
                ...selected,
                name: event.currentTarget.value,
            },
            
        });
    };

    private readonly onClearClick = () => {
        this.setState({
            searchText: '',
        });
    };

    private readonly onAddClick = () => {
        if (!this.state.searchText) return;

        if (this.props.pumpTypes.some(n => n.name.toLocaleLowerCase() === this.state.searchText.toLocaleLowerCase())) {
            GeneralFunctions.generalShowErrorSnackbar(`${this.state.searchText} already added.`);
            return;
        }

        const pump : IPumpingStationPumpType = {
            id: '',
            name: this.state.searchText,
            createdBy: this.props.auth.session?.firebaseUser.uid ?? '',
            createdByEmployee: this.props.auth.session?.employee.EmployeeNumber ?? '',
            createdByName: this.props.auth.session?.employee.Name ?? '',
            createdOn: firebase.firestore.Timestamp.now().toMillis(),
            updatedBy: this.props.auth.session?.firebaseUser.uid ?? '',
            updatedByEmployee: this.props.auth.session?.employee.EmployeeNumber ?? '',
            updatedByName: this.props.auth.session?.employee.Name ?? '',
            updatedOn: firebase.firestore.Timestamp.now().toMillis(),
            isActive: true,
        };

        PumpingStationPumpTypeHelper.save(pump);

        this.setState({
            searchText: '',            
        });
    };

    private readonly onSaveClick = async (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        const { selected } = this.state;

        if (!selected) return;
        if (!selected.name) return;

        if (this.props.pumpTypes.some(n => n.id !== selected.id && n.name.toLocaleLowerCase() === this.state.searchText.toLocaleLowerCase())) {
            GeneralFunctions.generalShowErrorSnackbar(`${this.state.searchText} already added.`);
            return;
        }

        PumpingStationPumpTypeHelper.save({
            ...selected,
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        const selected = this.props.pumpTypes.find(x => x.id === event.currentTarget.value);
        if (!selected) return;

        PumpingStationPumpTypeHelper.save({
            ...selected,
            isActive: !selected.isActive,
        });
    };

    private readonly getPumpTypes = (state : IPumpingStationPumpTypeListState, props : IPumpingStationPumpTypeListProps) => props.pumpTypes;
    private readonly getSearchText = (state : IPumpingStationPumpTypeListState) => state.searchText;

    private readonly getFilteredTypes = createSelector(
        [this.getPumpTypes, this.getSearchText],
        (pumpTypes, searchText) => {
            return pumpTypes
                .filter(n => n.name.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        },
    );

    private readonly getTotal = createSelector(
        [this.getPumpTypes],
        (pumpTypes) => {
            return pumpTypes.length;
        },
    );

    public readonly render = () => {
        const { searchText, selected } = this.state;
        const { isLoading } = this.props;

        const pumpTypes = this.getFilteredTypes(this.state, this.props);
        const total = this.getTotal(this.state, this.props);

        return (
            <div className={'fdc ais flx1 p10'}>
                <div className='fdr flx1 hfill'>
                    <div className='fdc flx1 hfill'>
                        <Paper className='fdc flx1 hfill m10'>
                            <Toolbar className={'fdr aic'}>
                                <TextField
                                    value={searchText}
                                    onChange={this.onSearchChanged}
                                    placeholder='Search'
                                    margin='dense'
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <div>
                                                {
                                                    searchText &&
                                                    <Tooltip title='Clear'>
                                                        <IconButton size='small' onClick={this.onClearClick}>
                                                            <Icon className='cr'>close</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </div>
                                        ),
                                    }}
                                />
                                <Tooltip title='Add'>
                                    <div>
                                        <IconButton color='primary' onClick={this.onAddClick} aria-label='Add'>
                                            <Icon>add</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Toolbar>
                            <Divider />
                            <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                                Total {total}
                            </Typography>
                            <Divider />
                            <List className={'fdc flx1 oya'}>
                                <div className='mnh4'>
                                    { isLoading && <LinearProgress />}
                                </div>
                                {
                                    pumpTypes.map(n => (
                                        <ListItem button key={n.id} id={n.id} onClick={this.onTypeClick} selected={selected?.id === n.id} >
                                            <ListItemText
                                                className={n.isActive ? '' : 'cr'}
                                                primary={`${n.name}`}
                                            />
                                            {
                                                (n.isActive) &&
                                                <ListItemSecondaryAction>
                                                    <Tooltip title='Disable'>
                                                        <IconButton className='cr' value={n.id} onClick={this.onDeleteClick} aria-label='Delete'>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            }
                                            {
                                                (!n.isActive) &&
                                                <ListItemSecondaryAction>
                                                    <Tooltip title='Enable'>
                                                        <IconButton color='primary' value={n.id} onClick={this.onDeleteClick} aria-label='Delete'>
                                                            <Icon>check</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            }
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Paper>
                    </div>
                    <div className='fdc flx4 hfill'>
                        {
                            !!selected &&
                            <Paper className='fdc flx1 hfill m10'>
                                <Toolbar className={'fdr aic'}>
                                    <Typography variant='h5' color='inherit'>
                                        Pump Type - {selected.name}
                                    </Typography>
                                    <span className='flx1' />
                                    <Tooltip title='Save'>
                                        <div>
                                            <IconButton color='primary' onClick={this.onSaveClick} aria-label='Save'>
                                                <Icon>save</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </Toolbar>
                                <Divider />
                                <div className='fdc flx1 hfill'>
                                    <div className='fdc flx1 oya'>
                                        <div className='aic p5 mb10 pr20'>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='name'
                                                    label='Name'
                                                    value={selected.name ? selected.name : ''}
                                                    required
                                                    error={!selected.name}
                                                    onChange={this.onNameChange}
                                                    margin='normal'
                                                    className={'TextField'}
                                                />
                                                {
                                                    !selected.name &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        isLoading: state.masterData.pumpingStation.pumpType.isLoading,
        pumpTypes: state.masterData.pumpingStation.pumpType.pumpTypes,
    };
};

const PumpingStationPumpTypeList = connect(
    mapStateToProps,
)(PumpingStationPumpTypeListComponent);

export default PumpingStationPumpTypeList;

import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditTransformerImages from '../dialog/EditImages';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';

interface ITransformerImagesViewProps {
    transformer : ITransformer;
    isLoading : boolean;
    session : IUserSession;
}

interface ITransformerImagesViewState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class TransformerImagesView extends React.PureComponent<ITransformerImagesViewProps, ITransformerImagesViewState> {
    constructor(props : ITransformerImagesViewProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { transformer, isLoading, session } = this.props;
        const { imageSrcs, isCarouselOpen, imageIndex } = this.state;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg0'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                IMAGES
                            </Typography>
                            <span className='flx1' />
                            {
                                session.employee.IsTransformerAdmin &&
                                <EditTransformerImages transformer={transformer} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc oya'>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                POLE ({transformer.PoleNumber})
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                TRANSFORMER { !transformer.TransformerNumber ? '' : `(${transformer.TransformerNumber})`}
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <div className={'flx1'}>
                                {
                                    transformer.PoleFileName &&
                                    <div className='flx1 p10 jcc fdc'>
                                        <FirebaseStorageImage fileName={transformer.PoleFileName} path={`electricalpoint/${transformer.EPNumber}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                    </div>
                                }
                            </div>
                            <div className={'flx1'}>
                                {
                                    transformer.TransformerFileName &&
                                    <div className='flx1 p10 jcc fdc'>
                                        <FirebaseStorageImage fileName={transformer.TransformerFileName} path={`electricalpoint/${transformer.EPNumber}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                CIRCUIT { !transformer.CircuitBreaker ? '' : `(${transformer.CircuitBreaker} A)`}
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                YELLOW NUMBER { !transformer.YellowNumber ? '' : `(${transformer.YellowNumber})`}
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {
                                    transformer.CircuitFileName &&
                                    <div className='flx1 p10 jcc fdc'>
                                        <FirebaseStorageImage fileName={transformer.CircuitFileName} path={`electricalpoint/${transformer.EPNumber}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                    </div>
                                }
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {
                                    transformer.YellowNumberFileName &&
                                    <div className='flx1 p10 jcc fdc'>
                                        <FirebaseStorageImage fileName={transformer.YellowNumberFileName} path={`electricalpoint/${transformer.EPNumber}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                    </div>
                                }
                            </Typography>
                        </div>
                        {
                            imageSrcs.length > 0 &&
                            <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                        }
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    };
}

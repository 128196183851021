import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import riverFunctions from '../../../store/river/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { IRiver } from '../../../@types/model/river/river';
import DivisionSelector from '../../customComponents/selector/DivisionSelector';

interface IEditRiverInfoProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    river ?: IRiver;
}

interface IEditRiverInfoState {
    open : boolean;
    name : string;
    division : string;
}

export default class EditRiverInfo extends React.Component<IEditRiverInfoProps, IEditRiverInfoState> {
    constructor(props : IEditRiverInfoProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            division: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditRiverInfoProps, prevState : IEditRiverInfoState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: '',
                division: '',
            });
        }
        if (!prevState.open && this.state.open && this.props.river) {
            this.setState({
                name: this.props.river.RiverName,
                division: !this.props.river.Division || this.props.river.Division === 'No Data' ? '' : this.props.river.Division,
            });
        }

    };

    private readonly onDivisionChanged = (division : string) => {
        this.setState({
            division,
        });
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly save = async () => {
        const { name, division } = this.state;
        if (!name) return;

        const river = Object.assign({}, this.props.river);

        river.RiverName = name;
        river.Division = division;

        await riverFunctions.saveRiver(river);
        this.onClose();
    };

    private readonly onSubmit = (event : React.FormEvent) => {
        event.preventDefault();
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen } = this.props;
        const { open, name, division } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit'>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='river-info-edit-dialog-title'
                    aria-describedby='river-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit River Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='name'
                                            label='Name'
                                            value={name}
                                            onChange={this.onNameChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!name}
                                        />
                                        {
                                            !name &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'fdr flx1 aifs'}>
                                    <DivisionSelector division={division} onDivisionChanged={this.onDivisionChanged} />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>);
    };
}

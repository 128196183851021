import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import appFunctionsService from '../../../services/appFunctionsService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import BoreholeTestItemView from './TestEntryView';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import boreholeFunctions from '../../../store/borehole/functions';
import { IUserSession } from '../../../@types/employee';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import { IBoreholeTest } from '../../../@types/model/borehole/boreholeTest';
import { CircularProgress } from '@material-ui/core';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';

interface IBoreholeTestViewProps {
    boreholeTests : Array<IBoreholeTest>;
    isLoading : boolean;
    session ?: IUserSession | null;
}

interface IBoreholeTestViewState {
    selectedTest ?: IBoreholeTest;
}

class BoreholeTestView extends React.Component<IBoreholeTestViewProps, IBoreholeTestViewState> {
    constructor(props : IBoreholeTestViewProps) {
        super(props);
        this.state = {};
    }

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selectedTest = this.props.boreholeTests.slice().find(n => n.ref.id === event.currentTarget.value);
        this.setState({
            selectedTest,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.state.selectedTest) {
            boreholeFunctions.deleteBoreholeTest(this.state.selectedTest.ref.id);
        }
        this.setState({
            selectedTest: undefined,
        });
    };

    public readonly render = () => {
        const { boreholeTests, isLoading, session } = this.props;
        const { selectedTest } = this.state;
        return (
            <div className={'fdc flx1 m10'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    boreholeTests.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Test Data Exists!
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!boreholeTests.length &&
                    <div className='fdc flx1 ais jcfs oya oxh mt12'>
                        {
                            boreholeTests.map(n => (
                                <SeparatedAccordion key={n.ref.id}>
                                    <PrimaryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className='fdr jcc aic flx1'>
                                            {
                                                appFunctionsService.formatDateTimeToDateOnly(n.CreatedOn) 
                                            }
                                            <span className='flx1' />
                                            {
                                                session?.employee.IsBoreholeAdmin &&
                                                <Tooltip title='Delete'>
                                                    <div>
                                                        <IconButton size='small' value={n.ref.id} color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoading}>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            }
                                        </div>
                                    </PrimaryAccordionSummary>
                                    <AccordionDetails className={'fdc flx1'}>
                                        <BoreholeTestItemView session={session} boreholeTest={n} isLoading={isLoading} />
                                    </AccordionDetails>
                                </SeparatedAccordion>
                            ))
                        }
                    </div>
                    
                }
                <ConfirmDialog open={!!selectedTest} title={!selectedTest ? '' : `Confirm Delete ${appFunctionsService.formatDateTimeToDateOnly(selectedTest.CreatedOn)}`}
                    message={!selectedTest ? '' : `Delete Test on ${appFunctionsService.formatDateTimeToDateOnly(selectedTest.CreatedOn)}, this cannot be undone?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        boreholeTests: state.borehole.boreholeTests,
        isLoading: state.borehole.loadingTests,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(BoreholeTestView);

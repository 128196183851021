import React from 'react';
import DataFunctions from '../../../store/data/functions';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { Area } from '../../../@types/model/area';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

interface IDivisionAreaDropdownProps {
    id ?: string;
    label ?: string;
    value ?: string;

    onChange : (value ?: string) => void;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    areas : Array<Area>;
    isLoading : boolean;

    exclusionList ?: Array<string>;
}

interface IDivisionAreaDropdownState {
}

class DivisionAreaDropdown extends React.Component<IDivisionAreaDropdownProps, IDivisionAreaDropdownState> {
    constructor(props : IDivisionAreaDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        DataFunctions.getAreas();
    };

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | string | null) => {
        event.stopPropagation();
        event.preventDefault();
        if (typeof value === 'string') {
            this.props.onChange(value);
        } else {
            this.props.onChange(value?.value);
        }
    };

    private readonly getData = (state : IDivisionAreaDropdownState, props : IDivisionAreaDropdownProps) => props.areas;
    private readonly getExclusionList = (state : IDivisionAreaDropdownState, props : IDivisionAreaDropdownProps) => props.exclusionList;
    private readonly getValue = (state : IDivisionAreaDropdownState, props : IDivisionAreaDropdownProps) => props.value;
    private readonly getRequired = (state : IDivisionAreaDropdownState, props : IDivisionAreaDropdownProps) => props.required;

    private readonly getDivisions = createSelector([
        this.getData, this.getExclusionList,
    ], (divisions, exclusionList) => {
        return lodash
            .chain(divisions)
            .uniqBy(n => n.name)
            .filter(n => !exclusionList || !exclusionList.includes(n.name))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(n => n.name)
            .value();
    });

    private readonly getDivisionAreaDropdown = createSelector([
        this.getRequired,
        this.getDivisions,
    ], (required, divisions) => {
        const divisionsDrop = lodash.map(divisions, (n) => ({ label: n, value: n }));

        if (!required) {
            divisionsDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return divisionsDrop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getDivisions,
    ], (value, required, divisions) => {
        if (value) {
            const division = divisions.find(x => x === value);

            if (division) {
                return { label: division, value: division };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, fullWidth, isLoading, id, label = 'Divisions' } = this.props;

        const divisions = this.getDivisionAreaDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <React.Fragment>
                <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                    <Autocomplete
                        freeSolo
                        id={id}
                        options={divisions}
                        value={value}
                        getOptionSelected={(option, val) => option.value === val.value}
                        getOptionLabel={option => option.label}
                        onChange={this.onChange}
                        disableClearable={required}
                        openOnFocus
                        renderInput={params => <TextField error={required && !value} 
                            required={required} {...params} fullWidth={fullWidth} label={label} />}
                    />
                    {
                        required && !value &&
                            <FormHelperText error>Required</FormHelperText>
                    }
                </FormControl>
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                    <LinearProgress />
                    }
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        areas: state.data.areas,
        isLoading: state.data.isLoadingAreas,
    };
};

export default connect(
    mapStateToProps,
)(DivisionAreaDropdown);

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import weatherFunctions from '../../../store/weather/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import FileDropZone from '../../customComponents/FileDropZone';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IWeatherStation } from '../../../@types/model/weather/weatherStation';
import GeneralFunctions from '../../../store/general/functions';

interface IEditWeatherStationImagesProps {
    weatherStation ?: IWeatherStation;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditWeatherStationImagesState {
    open : boolean;
    weatherStationImage ?: File;

    weatherStationImageProgress : number;
    isUploadingLoading : boolean;
}

class EditWeatherStationImages extends React.Component<IEditWeatherStationImagesProps, IEditWeatherStationImagesState> {
    constructor(props : IEditWeatherStationImagesProps) {
        super(props);
        this.state = {
            open: false,
            weatherStationImageProgress: 0,
            isUploadingLoading: false,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditWeatherStationImagesProps, prevState : IEditWeatherStationImagesState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                weatherStationImage: undefined,
                weatherStationImageProgress: 0,
                isUploadingLoading: false,
            });
        }
    };

    private readonly save = async () => {
        const { weatherStationImage } = this.state;

        this.setState({
            isUploadingLoading: true,
        });

        if (weatherStationImage) {
            try {
                const weatherStationPromise = new Promise((res, rej) => {
                    weatherFunctions.uploadSelectedWeatherStationImage(weatherStationImage, (progress : number) => {
                        if (progress >= 100) {
                            res(undefined);
                        }
                        this.setState({
                            weatherStationImageProgress: progress,
                        });
                    }, (e) => {
                        rej(e);
                    });
                });

                await weatherStationPromise;
                GeneralFunctions.generalShowSuccessSnackbar('Weather Station Image uploaded.');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'Weather Station Image upload error.');
            }
        }

        this.setState({
            isUploadingLoading: false,
        });

        this.onClose();
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading || this.state.isUploadingLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onWeatherStationFileSelected = (weatherStationImage : File) => {
        this.setState({
            weatherStationImage,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, weatherStation } = this.props;
        const { open, isUploadingLoading, weatherStationImageProgress } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading || isUploadingLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='weatherStation-image-edit-dialog-title'
                    aria-describedby='weatherStation-image-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit WeatherStation Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading || isUploadingLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdr flx1 hfill'>
                            <div className={'fdc flx1 ais jcc m5'}>
                                <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    WEATHER STATION
                                </Typography>
                                <FileDropZone fileName={!weatherStation?.StationImageURL ? '' : weatherStation.StationImageURL}
                                    path={!weatherStation?.StationImageURL ? '' : `weather_station/${weatherStation.WSNumber}`}
                                    onFileSelected={this.onWeatherStationFileSelected} />
                                <div className='wfill mnh4'>
                                    {
                                        !!weatherStationImageProgress &&
                                        <LinearProgress variant='determinate' value={weatherStationImageProgress} />
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || isUploadingLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading || isUploadingLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        weatherStation: state.weather.weatherStation,
    };
};

export default connect(
    mapStateToProps,
)(EditWeatherStationImages);

import '../../../style/boxmap/CustomMapBox.scss';
import React from 'react';
import { connect } from 'react-redux';
import MapBox, { MapBoxInitialState } from '../mapBox/mapbox';
import { GeolocateControl, Layer, LngLatLike, MapMouseEvent, MapRef, MapboxGeoJSONFeature, NavigationControl, ScaleControl, Source } from 'react-map-gl';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM } from '../../../appConstants';
import MapboxDepartmentLayer from '../mapBox/layer/masterData/DepartmentLayer';
import MapboxSubDivisionLayer from '../mapBox/layer/masterData/SubDivisionLayer';
import MapboxDivisionLayer from '../mapBox/layer/masterData/DivisionLayer';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import randomColor from 'randomcolor';
import { EventData } from 'mapbox-gl';
import MapControlMenu from './mapbox/MapControlMenu';
import MapControlCheckbox from './mapbox/MapControlCheckbox';

const mapStyles = {
    'Streets': 'mapbox://styles/mapbox/streets-v12',
    'Satellite Streets': 'mapbox://styles/mapbox/satellite-streets-v11',
};

export type MapStylesType = keyof typeof mapStyles;

export interface IMapMarker {
    coordinates : LngLatLike;
    icon : string;
    iconSize : number;
    id : string;
}

export interface IMapLine {
    positions : Array <LngLatLike>;
    color : string;
    key : string;
}

interface ICustomMapBoxProps {
    id : string;

    markers ?: Array<IMapMarker>;
    lines ?: Array<IMapLine>;

    enableAreas ?: boolean;
    geoLocationControl ?: boolean;
    showScale ?: boolean;
    navigationControl ?: boolean;
    layerControls ?: boolean;

    mapStyle ?: MapStylesType;
    onMarkerClick ?: (id : string) => void;
    selectedMarker ?: string | null;

    images ?: Record<string, string>;

    onMap ?: (mapRef : MapRef) => void;
}

interface ICustomMapBoxState {
    initialViewState : MapBoxInitialState | null;

    imagesLoaded : boolean;

    showAreas : boolean;
    showAreaNames : boolean;
}

class CustomMapBox extends React.Component<ICustomMapBoxProps, ICustomMapBoxState> {
    private mapRef : MapRef | null = null;

    private readonly subDivisionZoom = 12;
    private readonly departmentZoom = 14;

    private readonly markerZoom = 14;

    private readonly markersLayer = 'markers_layer';
    private readonly markersSource = `${this.markersLayer}-source`;

    private readonly markerLayer = 'marker_layer';
    private readonly markerSource = `${this.markerLayer}-source`;

    constructor(props : ICustomMapBoxProps) {
        super(props);
        this.state = {
            initialViewState: null,
            imagesLoaded: false,
            showAreas: true,
            showAreaNames: true,
        };
    }

    public readonly componentDidMount = () => {
        this.setState({
            initialViewState: {
                latitude: DEFAULT_MAP_CENTER[1],
                longitude: DEFAULT_MAP_CENTER[0],
                zoom: DEFAULT_MAP_ZOOM,
            },
        });
    };

    public componentWillUnmount() : void {
        this.mapRef?.off('click', this.markersLayer, this.onMarkerClick);
        this.mapRef?.off('mouseenter', this.markersLayer, this.onMarkerMouseOver);
        this.mapRef?.off('mouseleave', this.markersLayer, this.onMarkerMouseOver);
    }

    private readonly flyTo = (center : mapboxgl.LngLatLike, zoom = 17) => {
        if (!this.mapRef) return;

        this.mapRef.flyTo({
            zoom,
            center,
            essential: true, 
        });
    };

    private readonly loadImages = (images : Record<string, string>) => {
        if (!this.mapRef) return Promise.resolve(undefined);
        
        const promises = lodash.map(images, (url, id) => {
            return new Promise<undefined>((res, rej) => {
                if (!this.mapRef) {
                    rej();
                    return;
                }

                if (this.mapRef.hasImage(id)) {
                    res(undefined);
                    return;
                }

                this.mapRef.loadImage(url, (error, result) => {
                    if (error) rej();
                    
                    if (this.mapRef && result) {
                        this.mapRef.addImage(id, result);
                        res(undefined);
                        return;
                    }

                    rej();
                });
            });
        });

        return Promise.all(promises);
    };

    private readonly onMarkerClick = (ev : MapMouseEvent & { 
        features ?: Array<MapboxGeoJSONFeature> | undefined;
    } & EventData) => {
        if (!ev.features) return;

        const properties = ev.features[0].properties;

        if (!properties) return;

        this.flyTo([properties.lng, properties.lat], this.markerZoom);

        if (this.props.onMarkerClick) {
            this.props.onMarkerClick(properties.name);
        }
    };

    private readonly onMarkerMouseOver = (ev : MapMouseEvent) => {
        if (ev.type === 'mouseenter') {
            ev.target.getCanvas().style.cursor = 'pointer';
        }
        if (ev.type === 'mouseleave') {
            ev.target.getCanvas().style.cursor = '';
        }
    };

    private readonly setMap = async () => {
        if (!this.mapRef) return;

        const {
            images,
            onMarkerClick,
            selectedMarker,
        } = this.props;
        
        await this.loadImages(images ?? {
            '1': `${ASSET_BASE}/assets/images/markers/green_location.png`,
        });

        this.setState({
            imagesLoaded: true,
        });

        this.mapRef.on('click', this.markersLayer, this.onMarkerClick);

        if (onMarkerClick) {
            this.mapRef.on('mouseenter', this.markersLayer, this.onMarkerMouseOver);
            this.mapRef.on('mouseleave', this.markersLayer, this.onMarkerMouseOver);
        }

        if (selectedMarker) {
            const markers = this.getPropMarkers(this.props);
            const marker = markers.find(x => x.id === selectedMarker);

            if (marker) {
                this.flyTo(marker.coordinates, this.markerZoom);
            }
        }

        if (this.props.onMap) this.props.onMap(this.mapRef);
    };

    private readonly setMapRef = (mapRef : MapRef) => {
        this.mapRef = mapRef;

        this.setMap();
    };

    private readonly getPropMarkers = (props : ICustomMapBoxProps) => props.markers ?? [];
    private readonly getImagesLoaded = (props : ICustomMapBoxProps, state : ICustomMapBoxState) => state.imagesLoaded;
    private readonly getPropSelectedMarker = (props : ICustomMapBoxProps) => props.selectedMarker;
    private readonly getMarkersGeoJson = createSelector(
        [
            this.getPropMarkers,
            this.getImagesLoaded,
        ],
        (
            markers,
            imagesLoaded,
        ) => {
            const geojson : GeoJSON.FeatureCollection<GeoJSON.Point> = {
                type: 'FeatureCollection',
                features: [],
            };

            if (!imagesLoaded) return geojson;

            geojson.features = markers.map((marker) => ({
                id: marker.id,
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: marker.coordinates as Array<number>,
                },
                properties: {
                    'name': marker.id,
                    'image': marker.icon,
                    'size': marker.iconSize,
                    'lat': (marker.coordinates as Array<number>)[1],
                    'lng': (marker.coordinates as Array<number>)[0],
                },
            }));
            
            return geojson;
        }
    );

    private readonly getSelectedMarkerGeoJson = createSelector(
        [
            this.getPropMarkers,
            this.getPropSelectedMarker,
        ],
        (
            markers,
            selectedMarker,
        ) => {
            if (!selectedMarker) return null;

            const marker = markers.find(x => x.id === selectedMarker);

            if (!marker) return null;

            const geojson : GeoJSON.Feature<GeoJSON.Point> = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: marker.coordinates as Array<number>,
                },
                properties: {
                    color: randomColor({seed: marker.id}),
                },
            };
            
            return geojson;
        }
    );

    private readonly onManagementAreasChange = (checked : boolean) => {
        this.setState({
            showAreas: checked,
        });
    };

    private readonly onManagementAreasNameChange = (checked : boolean) => {
        this.setState({
            showAreaNames: checked,
        });
    };

    public readonly render = () => {
        const {
            id,
            mapStyle,
            geoLocationControl,
            enableAreas,
            navigationControl,
            showScale,
            layerControls,
        } = this.props;
        const {
            initialViewState,
            showAreaNames,
            showAreas,
        } = this.state;

        if (!initialViewState) return null;

        const markersGeoJson = this.getMarkersGeoJson(this.props, this.state);
        const selectedMarkerGeoJson = this.getSelectedMarkerGeoJson(this.props);

        return (
            <MapBox
                id={id}
                mapRef={this.setMapRef}
                reuseMaps
                mapboxAccessToken={process.env.MapboxAccessToken}
                initialViewState={initialViewState}
                mapStyle={mapStyle ?? 'mapbox://styles/mapbox/satellite-streets-v11'}
                projection={{
                    name: 'globe',
                }}

            >
                {
                    layerControls &&
                    <MapControlMenu
                        geoLocationControl={geoLocationControl}
                        showScale={showScale}
                        navigationControl={navigationControl}
                        position='right-top'
                    >
                        {
                            enableAreas &&
                            <MapControlCheckbox
                                value={showAreas}
                                label='Management Areas' 
                                onChange={this.onManagementAreasChange}
                            />
                        }
                        {
                            enableAreas &&
                            <MapControlCheckbox
                                value={showAreaNames}
                                label='Management Area Names' 
                                onChange={this.onManagementAreasNameChange}
                            />
                        }
                    </MapControlMenu>
                }
                {
                    geoLocationControl &&
                    <GeolocateControl
                        positionOptions={{
                            enableHighAccuracy: true,
                        }}
                        trackUserLocation
                        showUserHeading
                    />
                }
                {
                    navigationControl &&
                    <NavigationControl />
                }
                {
                    showScale &&
                    <ScaleControl />
                }
                {
                    enableAreas &&
                    <>
                        <MapboxDepartmentLayer
                            polygonId='polygon_department_layer'
                            labelId='label_department_layer'
                            strokeId='stroke_department_layer'
                            minZoom={this.departmentZoom}
                            labelsVisible={showAreaNames}
                            visible={showAreas}
                        />
                        <MapboxSubDivisionLayer
                            polygonId='polygon_sub_division_layer'
                            labelId='label_sub_division_layer'
                            strokeId='stroke_sub_division_layer'
                            maxZoom={this.departmentZoom}
                            minZoom={this.subDivisionZoom}
                            labelsVisible={showAreaNames}
                            visible={showAreas}
                        />
                        <MapboxDivisionLayer
                            polygonId='polygon_division_layer'
                            labelId='label_division_layer'
                            strokeId='stroke_division_layer'
                            maxZoom={this.subDivisionZoom}
                            labelsVisible={showAreaNames}
                            visible={showAreas}
                        />
                    </>
                }
                <Source
                    type='geojson'
                    data={markersGeoJson}
                    id={this.markersSource}
                >
                    <Layer
                        id={this.markersLayer}
                        type='symbol'
                        {
                            ...{
                                paint: {
                                },
                                layout: {
                                    'icon-image': ['get', 'image'],
                                    'icon-size': ['get', 'size'],
                                    'icon-anchor': 'center',
                                    'icon-allow-overlap': true,
                                },
                            }
                        }
                    >
                    </Layer>
                </Source>
                {
                    selectedMarkerGeoJson &&
                    <Source
                        type='geojson'
                        data={selectedMarkerGeoJson}
                        id={this.markerSource}
                    >
                        <Layer
                            id={this.markerLayer}
                            beforeId={this.markersLayer}
                            type='circle'
                            {
                                ...{
                                    paint: {
                                        'circle-color': ['get', 'color'],
                                        'circle-opacity': 0.8,
                                        'circle-radius': 28,
                                    },
                                    layout: {
                                    },
                                }
                            }
                        >
                        </Layer>
                    </Source>
                }
            </MapBox>
        );
    };
}

const mapStateToProps = () => {
    return {
    };
};

export default connect(
    mapStateToProps,
)(CustomMapBox);

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { TRANSFORMER_METER_TYPE } from '../../../../appConstants';
import { TransformerMeterTypeUpperCase } from '../../../../@types/model/transformer/transformer';

interface IMeterTypeSelectorProps {
    value ?: TransformerMeterTypeUpperCase | '' | null;

    onChange : (value : TransformerMeterTypeUpperCase | '') => void;

    required ?: boolean;
}

interface IMeterTypeSelectorState {}

export default class MeterTypeSelector extends React.Component<IMeterTypeSelectorProps, IMeterTypeSelectorState> {
    constructor(props : IMeterTypeSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onMeterTypeChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (typeof event.target.value === 'string') {
            this.props.onChange(event.target.value as TransformerMeterTypeUpperCase | '');
        } 
    };

    public readonly render = () => {
        const { value, required } = this.props;

        return (
            <FormControl fullWidth>
                <InputLabel shrink={!!value} required={required} htmlFor='meterType'>Meter Type</InputLabel>
                <Select
                    fullWidth
                    id='meterType'
                    value={!value ? '' : value}
                    required={required}
                    error={required && !value}
                    onChange={this.onMeterTypeChange}>
                    <MenuItem value=''>
                        <em>None</em>
                    </MenuItem>
                    {
                        lodash.map(TRANSFORMER_METER_TYPE, n => (
                            <MenuItem key={`${n}_meterType`} value={`${n}`}>
                                {n}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    };
}

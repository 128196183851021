import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../../@types/redux';
import { ITransformer, TransformerMeterType } from '../../../../@types/model/transformer/transformer';
import TransformerFunctions from '../../../../store/transformer/functions';
import OutlinedTextField from '../../textField/OutlinedTextField';

interface ITransformerAreaDropdownProps {
    id ?: string;
    value ?: string | null;

    onChange : (value ?: string | null) => void;

    required ?: boolean;
    disabled ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    transformers : Array<ITransformer>;
    isLoading : boolean;

    label ?: string;

    type ?: TransformerMeterType;
    className ?: string;
    color ?: 'primary' | 'secondary';

    variant ?: 'outlined';
}

interface ITransformerAreaDropdownState {
}

class TransformerAreaDropdownComponent extends React.Component<ITransformerAreaDropdownProps, ITransformerAreaDropdownState> {
    constructor(props : ITransformerAreaDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        TransformerFunctions.getList();
    };

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value);
    };

    private readonly getData = (state : ITransformerAreaDropdownState, props : ITransformerAreaDropdownProps) => props.transformers;
    private readonly getValue = (state : ITransformerAreaDropdownState, props : ITransformerAreaDropdownProps) => props.value;
    private readonly getRequired = (state : ITransformerAreaDropdownState, props : ITransformerAreaDropdownProps) => props.required;
    private readonly getType = (state : ITransformerAreaDropdownState, props : ITransformerAreaDropdownProps) => props.type;

    private readonly getTransformerAreas = createSelector([
        this.getData, this.getValue, this.getType,
    ], (transformers, value, type) => {
        return lodash
            .chain(transformers)
            .filter(n => !type || n.MeterType?.toLocaleLowerCase() === type)
            .map(n => n.Division)
            .filter(n => !!n)
            .uniq()
            .sort()
            .value();
    });

    private readonly getTransformerAreaDropdown = createSelector([
        this.getRequired,
        this.getTransformerAreas,
    ], (required, transformerAreas) => {
        const transformerAreasDrop : Array<{
            label : string;
            value : string;
        }> = lodash.map(transformerAreas, (n) => ({ label: n, value: n }));

        if (!required) {
            transformerAreasDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return transformerAreasDrop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getTransformerAreas,
    ], (value, required, transformerAreas) => {
        if (value) {
            const transformerArea = transformerAreas.find(x => x === value);

            if (transformerArea) {
                return { label: transformerArea, value: transformerArea };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, fullWidth, isLoading, id, label = '',
            disabled, className, color, variant } = this.props;

        const transformerAreasDrop = this.getTransformerAreaDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        const Component = variant === 'outlined' ? OutlinedTextField : TextField;

        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    disabled={disabled}
                    options={transformerAreasDrop}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    color={color}
                    className={className}
                    renderInput={params => <Component
                        error={required && !value} {...params} fullWidth={fullWidth}
                        disabled={disabled} label={label}
                        color={color}
                        className={className}
                        InputProps={{
                            ...params.InputProps,
                            className,
                        }}
                    />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoading: state.transformer.loading,
    };
};

const TransformerAreaDropdown = connect(
    mapStateToProps,
)(TransformerAreaDropdownComponent);

export default TransformerAreaDropdown;
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import AppFunctionsService from '../../../services/appFunctionsService';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditTransformerDetail from '../dialog/EditDetail';

interface ITransformerSummaryViewProps {
    transformer : ITransformer;
    isLoading : boolean;
    session : IUserSession;
}

interface ITransformerSummaryViewState {}

export default class TransformerSummaryView extends React.PureComponent<ITransformerSummaryViewProps, ITransformerSummaryViewState> {
    constructor(props : ITransformerSummaryViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { transformer, isLoading, session } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg0'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                SUMMARY
                            </Typography>
                            <span className='flx1' />
                            {
                                session.employee.IsTransformerAdmin &&
                                <EditTransformerDetail transformer={transformer} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc'>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                EP NO
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                EP TYPE
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.EPNumber}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.EPType}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                GROUP
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                READER NO AND NAME
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.EPGroup ?? '-'}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {(transformer.TransformerReaderEmployeeNr) ? transformer.TransformerReaderEmployeeNr + ': ' + transformer.TransformerReaderEmployeeName : '-'}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                TRANSFORMER RATING
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                METER
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.TransformerRating ? `${transformer.TransformerRating} kVA` : '-'}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.MeterType ?? '-'}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                PHASE SIZE
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                POLE NUMBER
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.PhaseSize ?? '-'}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.PoleNumber ?? '-'}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                CIRCUIT BREAKER
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                MANUAL READING
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.CircuitBreaker ? `${transformer.CircuitBreaker} A` : '-'}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.ManualReading ? 'YES' : 'NO'}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                {
                                    transformer.MeterType === 'LPU' &&
                                    'TRANSMISSION ZONE'
                                }
                                {
                                    transformer.MeterType === 'SPU' &&
                                    'LANDRATE'
                                }
                                {
                                    transformer.MeterType === 'Prepaid' &&
                                    'CURRENT RESIDENT'
                                }
                                {
                                    transformer.MeterType === 'SPS' &&
                                    'LINKED ESKOM WAYPOINT'
                                }
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                ACCOUNT NUMBER
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>

                                {
                                    transformer.MeterType === 'LPU' &&
                                    (transformer.TransmissionZone ? AppFunctionsService.getTransmissionZoneLabel(transformer.TransmissionZone) : '-')
                                }
                                {
                                    transformer.MeterType === 'SPU' &&
                                    (transformer.Landrate ? AppFunctionsService.getLandrateLabel(transformer.Landrate) : '-')
                                }
                                {
                                    transformer.MeterType === 'Prepaid' &&
                                    (transformer.CurrentResident ? transformer.CurrentResident : '-')
                                }
                                {
                                    transformer.MeterType === 'SPS' &&
                                    (transformer.LinkedEp ? transformer.LinkedEp : '-')
                                }
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.AccountNumber ? transformer.AccountNumber : '-'}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                NOTIFIED MAX DEMAND
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                PREMISE ID
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {!transformer.NotifiedMaxDemand ? '-' : `${transformer.NotifiedMaxDemand} kVA`}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {!transformer.PremiseId ? '-' : `${transformer.PremiseId}`}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                SECURITY GUARANTEE
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                SECURITY DEPOSIT
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {!transformer.SecurityGuarantee ? '-' : `R${transformer.SecurityGuarantee}`}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {!transformer.SecurityDeposit ? '-' : `R${transformer.SecurityDeposit}`}
                            </Typography>
                        </div>
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    };
}

import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IFlowmeter } from '../../../../@types/model/flowmeter/flowmeter';
import FlowmeterFunctions from '../../../../store/flowmeter/functions';
import { IRootState } from '../../../../@types/redux';

interface IFlowmeterDropdownProps {
    id ?: string;
    value ?: string;

    onChange : (value ?: IFlowmeter) => void;

    required ?: boolean;
    disabled ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    flowmeters : Array<IFlowmeter>;
    isLoading : boolean;

    exclusionList ?: Array<IFlowmeter>;

    label ?: string;
}

interface IFlowmeterDropdownState {
}

class FlowmeterDropdownComponent extends React.Component<IFlowmeterDropdownProps, IFlowmeterDropdownState> {
    constructor(props : IFlowmeterDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        FlowmeterFunctions.getList();
    };

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        if (typeof value === 'string') {
            const flowmeter = this.props.flowmeters.find(x => x.code === value);
            this.props.onChange(flowmeter);
        } else {
            const flowmeter = this.props.flowmeters.find(x => x.code === value?.value);
            this.props.onChange(flowmeter);
        }
    };

    private readonly getData = (state : IFlowmeterDropdownState, props : IFlowmeterDropdownProps) => props.flowmeters;
    private readonly getExclusionList = (state : IFlowmeterDropdownState, props : IFlowmeterDropdownProps) => props.exclusionList;
    private readonly getValue = (state : IFlowmeterDropdownState, props : IFlowmeterDropdownProps) => props.value;
    private readonly getRequired = (state : IFlowmeterDropdownState, props : IFlowmeterDropdownProps) => props.required;

    private getFlowmeters = createSelector([
        this.getData, this.getExclusionList, this.getValue,
    ], (flowmeters, exclusionList, value) => {
        return lodash
            .chain(flowmeters)
            .filter(n => !exclusionList || n.code === value || !exclusionList.some(x => n.code === x.code))
            .map(n => n.code)
            .sort()
            .value();
    });

    private getFlowmeterDropdown = createSelector([
        this.getRequired,
        this.getFlowmeters,
    ], (required, flowmeters) => {
        const flowmetersDrop = lodash.map(flowmeters, (n) => ({ label: n, value: n }));

        if (!required) {
            flowmetersDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return flowmetersDrop;
    });

    private getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getFlowmeters,
    ], (value, required, flowmeters) => {
        if (value) {
            const flowmeter = flowmeters.find(x => x === value);

            if (flowmeter) {
                return { label: flowmeter, value: flowmeter };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public render = () => {
        const { required, fullWidth, isLoading, id, label = 'Flowmeter',
            disabled } = this.props;

        const flowmetersDrop = this.getFlowmeterDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    disabled={disabled}
                    options={flowmetersDrop}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField
                        error={required && !value} {...params} fullWidth={fullWidth}
                        disabled={disabled} label={label} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        flowmeters: state.flowmeter.flowmeters,
        isLoading: state.flowmeter.loading,
    };
};

const FlowmeterDropdown = connect(
    mapStateToProps,
)(FlowmeterDropdownComponent);

export default FlowmeterDropdown;
import React from 'react';
import { Field, FieldProps } from 'formik';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import moment from 'moment';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import { DATEPICKER_FORMAT_DEFAULT } from '../../../appConstants';

interface IDateMillisInputProps extends Omit<KeyboardDatePickerProps, 'name' | 'value' | 'onChange' | 'format'> {
    name : string;
    value ?: number;
    onChange ?: (value : moment.Moment) => void;
    formControlProps ?: FormControlProps;
}

interface IDateMillisInputState {}

export default class DateMillisInput extends React.PureComponent<IDateMillisInputProps, IDateMillisInputState> {
    constructor(props : IDateMillisInputProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        this.setValue(this.props.value);
    };

    public readonly componentDidUpdate = (prevProps : IDateMillisInputProps) => {
        if (prevProps.value !== this.props.value) {
            this.setValue(this.props.value);
        }
    };

    private readonly setValue = (value ?: number) => {
        this.setState({
            value: !value ? undefined : moment.utc(value),
        });
    };

    private readonly onChange = (date : moment.Moment | null, fieldProps : FieldProps<number>) => {
        fieldProps.form.setFieldValue(fieldProps.field.name, date?.asUTC().valueOf(), true);
    };

    public readonly renderInput = (fieldProps : FieldProps) => {
        const {
            formControlProps,
            onChange: _onChange,
            value: _value,
            ...other
        } = this.props;
        const date = moment.utc(fieldProps.field.value);
        return (
            <FormControl {...formControlProps}>
                <KeyboardDatePicker {...other} format={DATEPICKER_FORMAT_DEFAULT} value={date} onChange={d => this.onChange(d, fieldProps)} />
            </FormControl>
        );
    };

    public readonly render = () => {
        return <Field name={this.props.name}>{this.renderInput}</Field>;
    };
}

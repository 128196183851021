import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { BOREHOLE_INSTALLATION_CABLE_CORES } from '../../../appConstants';

interface ICableCoresSelectorProps {
    value : number;

    onChange : (value : number) => void;
}

interface ICableCoresSelectorState {}

export default class CableCoresSelector extends React.Component<ICableCoresSelectorProps, ICableCoresSelectorState> {
    constructor(props : ICableCoresSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        this.props.onChange(Number(event.target.value));
    };

    public readonly render = () => {
        const { value } = this.props;

        return (<React.Fragment>
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!value} htmlFor='cableCores'>Cable Cores</InputLabel>
                    <Select
                        fullWidth
                        id='cableCores'
                        value={!value ? '' : value}
                        onChange={this.onChange}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(BOREHOLE_INSTALLATION_CABLE_CORES, n => (
                                <MenuItem key={`${n}_cableCores`} value={n}>
                                    {n}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        </React.Fragment>);
    };
}

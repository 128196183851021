import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IBoreholeConstantRateTest } from '../../../../@types/model/borehole/boreholeConstantRateTest';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import BoreholeConstantRateEntryNeighbourGraphView from './ConstantRateEntryNeighbourGraphView';
import BoreholeConstantRateEntryNeighbourTableView from './ConstantRateEntryNeighbourTableView';

interface IBoreholeConstantRateEntryNeighbourLogViewProps {
    constantRateTest : IBoreholeConstantRateTest;
}

interface IBoreholeConstantRateEntryNeighbourLogViewState {
    viewAsTable : boolean;
}

export default class BoreholeConstantRateEntryNeighbourLogView extends React.Component<IBoreholeConstantRateEntryNeighbourLogViewProps, IBoreholeConstantRateEntryNeighbourLogViewState> {
    constructor(props : IBoreholeConstantRateEntryNeighbourLogViewProps) {
        super(props);
        this.state = {
            viewAsTable: false,
        };
    }

    private readonly onViewAsClick = () => {
        this.setState({
            viewAsTable: !this.state.viewAsTable,
        });
    };

    public readonly render = () => {
        const { constantRateTest } = this.props;
        const { viewAsTable } = this.state;
        return (
            <div className='fdc'>
                <Card className='fdr aic jcc p15 bcp'>
                    <Typography className='flx1 aic jcc fs18 fwb cw'>
                        NEIGHOURING BOREHOLES
                    </Typography>
                    <div className='posr'>
                        <div className='posa posr0 postn17 w150'>
                            <OutlinedStadiumButton
                                variant={'outlined'}
                                onClick={this.onViewAsClick}
                                className={'cw bocw'}
                            >
                                VIEW AS {viewAsTable ? 'GRAPH' : 'TABLE'}
                            </OutlinedStadiumButton>
                        </div>
                    </div>
                </Card>
                <div className='fdc mt16 pl20 pr20'>
                    <Card className='fdr aic jcfs p15 dvbg'>
                        <Typography className='aic jcc fs14 fw500 cw'>
                            LOG {!viewAsTable ? 'GRAPH' : 'TABLE'}
                        </Typography>
                    </Card>
                    {
                        !viewAsTable &&
                        <BoreholeConstantRateEntryNeighbourGraphView
                            constantRateTest={constantRateTest}
                        />
                    }
                    {
                        viewAsTable &&
                        <BoreholeConstantRateEntryNeighbourTableView
                            constantRateTest={constantRateTest}
                        />
                    }
                </div>
            </div>
        );
    };
}

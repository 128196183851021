import { createAction } from 'typesafe-actions';
import { INotification } from '../../@types/redux';

export const generalSetCompany = createAction('SET_COMPANY', resolve => (company : string) => resolve(company));

export const enqueueSnackbar = createAction('ENQUEUE_SNACKBAR', resolve => (notification : INotification) => resolve({
    key: new Date().getTime() + Math.random(),
    ...notification,
}));
export const removeSnackbar = createAction('REMOVE_SNACKBAR', resolve => (key : number) => resolve(key));

import * as Yup from 'yup';
import { firestore } from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import { IBasicBaseClass, BasicBaseHelper } from '../base';
import TransformerHelper, { ITransformer, ITransformerCompany } from './transformer';
import { YupSchema } from '../../../services/helper/yupHelper';
import moment from 'moment';
import lodash from 'lodash';
import { HIGH_SEASON_MONTHS } from '../../../appConstants';
import GeneralFunctions from '../../../store/general/functions';

export type BudgetType = 'lpu' | 'spu' | 'municipality';

export interface ITransformerBudget<T extends ISPUBudget | ILPUBudget | IMunicipalityBudget | IBudget = IBudget> extends IBasicBaseClass {
    type : BudgetType;
    year : number;

    startDate : number;
    endDate : number;

    companyId : string;
    companyName : string;
    area : string;

    values : Array<T>;

    prevBudget : string | null;

    isActive : boolean;
}

interface IBudget {
    month : number;
    days : number;

    transformer : ITransformer;
    transformerRef : string;

    highSeason : boolean;
    
    comment : string;
}

export interface ISPUBudget extends IBudget {
    budget : number | null;
}


export interface ISPUBudgetFormValues extends Omit<ITransformerBudget, keyof IBasicBaseClass | 'budget' | 'values' | 'isActive'> {
    id : string;
    type : 'spu';

    values : Array<ISPUBudgetValueFormValue>;
}


export interface ISPUBudgetValueFormValue extends ISPUBudget {
    index : number;
}

type YupSPUBudgetFormShape = Record<keyof ISPUBudgetFormValues, YupSchema>;
type YupSPUBudgetValueFormShape = Record<keyof ISPUBudgetValueFormValue, YupSchema>;

export interface ILPUBudget extends IBudget {
    standardBudget : number | null;
    peakBudget : number | null;
    offpeakBudget : number | null;
    totalBudget : number | null;
}

export interface ILPUBudgetFormValues extends Omit<ITransformerBudget, keyof IBasicBaseClass | 'budget' | 'values' | 'isActive'> {
    id : string;
    type : 'lpu';

    values : Array<ILPUBudgetValueFormValue>;
}


export interface ILPUBudgetValueFormValue extends ILPUBudget {
    index : number;
}

type YupLPUBudgetFormShape = Record<keyof ILPUBudgetFormValues, YupSchema>;
type YupLPUBudgetValueFormShape = Record<keyof ILPUBudgetValueFormValue, YupSchema>;

export interface IMunicipalityBudget extends IBudget {
    budget : number | null;
}

export interface IMunicipalityBudgetFormValues extends Omit<ITransformerBudget, keyof IBasicBaseClass | 'budget' | 'values' | 'isActive'> {
    id : string;
    type : 'municipality';

    values : Array<IMunicipalityBudgetValueFormValue>;
}

export interface IMunicipalityBudgetValueFormValue extends IMunicipalityBudget {
    index : number;
}

type YupMunicipalityBudgetFormShape = Record<keyof IMunicipalityBudgetFormValues, YupSchema>;
type YupMunicipalityBudgetValueFormShape = Record<keyof IMunicipalityBudgetValueFormValue, YupSchema>;

export default class TransformerBudgetHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_budget';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerBudget> = {
        fromFirestore: (snapshot) => {
            return TransformerBudgetHelper.fromFirestore(snapshot);
        },
        toFirestore: (data : ITransformerBudget) : firebase.firestore.DocumentData => {
            return TransformerBudgetHelper.toFirestoreDocument(data);
        },
    };

    protected static fromFirestore(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerBudget {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!data || !result) throw new Error('Budget not found!');

        return {
            ...data as ITransformerBudget,
            ...result,
            startDate: data.startDate?.toMillis() ?? 0,
            endDate: data.endDate?.toMillis() ?? null,
            prevBudget: data.prevBudget?.id,
            values: data.values.map((n : any) => ({
                ...n,
                month: n.month.toMillis() ?? 0,
                days: moment.utc(n.month.toMillis()).daysInMonth(),
                transformer: TransformerHelper.fromDocument(n.transformer, n.transformerRef),
                transformerRef: n.transformerRef.id,
                highSeason: HIGH_SEASON_MONTHS.includes(moment.utc(n.month.toMillis()).month() + 1),
            })),
        };
    }

    public static toFirestoreDocument(data : ITransformerBudget) {
        const result = super.toFirestore(data);

        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
            startDate: firestore.Timestamp.fromMillis(data.startDate),
            endDate: !data.endDate ? null : firestore.Timestamp.fromMillis(data.endDate),
            prevBudget: !data.prevBudget ? null : TransformerBudgetHelper.doc(data.type, data.prevBudget),
            values: data.values.map((n) => ({
                ...n,
                month: firestore.Timestamp.fromMillis(n.month),
                transformer: TransformerHelper.toFirestore(n.transformer, true),
                transformerRef: TransformerHelper.doc(n.transformerRef),
            })),
        };
    }

    private static lpuConverter : firebase.firestore.FirestoreDataConverter<ITransformerBudget<ILPUBudget>> = {
        fromFirestore: (snapshot) => {
            return TransformerBudgetHelper.lpuFromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerBudget<ILPUBudget>) : firebase.firestore.DocumentData => {
            const firestoreObject = {
                ...TransformerBudgetHelper.toFirestoreDocument(data),
            };
            
            return firestoreObject;
        },
    };

    private static spuConverter : firebase.firestore.FirestoreDataConverter<ITransformerBudget<ISPUBudget>> = {
        fromFirestore: (snapshot) => {
            return TransformerBudgetHelper.spuFromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerBudget<ISPUBudget>) : firebase.firestore.DocumentData => {
            const firestoreObject = {
                ...TransformerBudgetHelper.toFirestoreDocument(data),
            };
            
            return firestoreObject;
        },
    };

    private static municipalityConverter : firebase.firestore.FirestoreDataConverter<ITransformerBudget<IMunicipalityBudget>> = {
        fromFirestore: (snapshot) => {
            return TransformerBudgetHelper.municipalityFromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerBudget<IMunicipalityBudget>) : firebase.firestore.DocumentData => {
            const firestoreObject = {
                ...TransformerBudgetHelper.toFirestoreDocument(data),
            };
            
            return firestoreObject;
        },
    };

    public static lpuFromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
        const result = this.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as ITransformerBudget<ILPUBudget>,
            ...result,
        } as ITransformerBudget<ILPUBudget>;
    }

    public static spuFromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
        const result = this.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !data) throw new Error(`Document does not exist! ${snapshot.id}`);

        return {
            ...data as ITransformerBudget<ISPUBudget>,
            ...result,
        } as ITransformerBudget<ISPUBudget>;
    }

    public static municipalityFromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) {
        const result = this.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        
        return {
            ...data as ITransformerBudget<IMunicipalityBudget>,
            ...result,
        } as ITransformerBudget<IMunicipalityBudget>;
    }

    public static doc(type : BudgetType, id ?: string) {
        if (!id) {
            return this.collection(type).doc();
        }

        return this.collection(type).doc(id);
    }

    public static async save(save : ITransformerBudget) {
        await firebaseApp.firestore().runTransaction(async (transaction) => {
            const doc = await transaction.get(this.doc(save.type, save.id));

            transaction.set(doc.ref, save);
        });
    }

    public static async batchSave(saves : Array<ITransformerBudget>) {
        const data = saves.slice();

        while (data.length > 0) {
            GeneralFunctions.generalShowInfoSnackbar(`Busy, ${data.length}/${saves.length}`);
            const batch = firebaseApp.firestore().batch();
            const current = data.splice(0, 5);

            for (const save of current) {
                batch.set(this.doc(save.type, save.id), save);
            }
    
            await batch.commit();
        }
    }

    public static collection(type : BudgetType) : firestore.CollectionReference<ITransformerBudget> {
        let converter;

        switch (type) {
            case 'lpu':
                converter = this.lpuConverter;
                break;
            case 'municipality':
                converter = this.municipalityConverter;
                break;
            case 'spu':
                converter = this.spuConverter;
                break;
            default:
                converter = this.converter;
        }

        return firebaseApp
            .firestore()
            .collection(TransformerBudgetHelper.COLLECTION_NAME)
            .withConverter(converter);
    }

    public static initSPUForm(
        startDate : number,
        transformers : Array<ITransformer>,
        company : ITransformerCompany | null,
        area : string,
        entry ?: ITransformerBudget<ISPUBudget> | null,
    ) : ISPUBudgetFormValues {        
        return {
            id: entry?.id ?? '',
            startDate: entry?.startDate ?? startDate,
            endDate: entry?.endDate ?? moment.utc(startDate).add(1, 'year').subtract(1, 'day').valueOf(),
            prevBudget: null,
            type: 'spu',
            companyId: entry?.companyId ?? company?.id ?? '',
            companyName: entry?.companyName ?? company?.name ?? '',
            area: entry?.area ?? area,
            year: entry?.year ?? moment.utc(startDate).add(1, 'year').subtract(1, 'day').year(),
            values: (entry?.values ?? lodash.chain(transformers)
                .filter(x => x.MeterType === 'SPU')
                .filter(x => x.Company?.id === company?.id)
                .filter(x => x.Division === area)
                .flatMap((transfromer) => (
                    new Array(12).fill(0).map((n, i) => ({
                        budget: null,
                        comment: '',
                        month: moment.utc(startDate).add(i, 'month').valueOf(),
                        transformer: transfromer,
                        transformerRef: transfromer.ref.id,
                        days: moment.utc(startDate).add(i, 'month').daysInMonth(),
                        highSeason: HIGH_SEASON_MONTHS.includes(moment.utc(startDate).add(i, 'month').month() + 1),
                    } as ISPUBudgetValueFormValue))
                )).value())
                .map((n, i) => ({
                    ...n,
                    index: i,
                })),
        };
    }

    public static formSPUSchema = () => Yup.object<YupSPUBudgetFormShape>({
        id: Yup.string().optional(),
        endDate: Yup.number().required('Required'),
        prevBudget: Yup.string().nullable().optional(),
        startDate: Yup.number().required('Required'),
        type: Yup.string().required().oneOf(['spu'], 'Invalid'),
        companyId: Yup.string().required('Required'),
        companyName: Yup.string().required('Required'),
        area: Yup.string().required('Required'),
        year: Yup.number().required(),
        values: Yup.array<YupSPUBudgetValueFormShape>(Yup.object<YupSPUBudgetValueFormShape>({
            index: Yup.number().required(),
            budget: Yup.number().nullable(),
            comment: Yup.string().optional(),
            month: Yup.number().required('Required'),
            days: Yup.number().required('Required'),
            transformer: Yup.object().required(),
            transformerRef: Yup.string().required('Required'),
            highSeason: Yup.boolean().required('Required'),
        }))
            .required(),
    });

    public static initLPUForm(
        startDate : number,
        transformers : Array<ITransformer>,
        company : ITransformerCompany | null,
        area : string,
        entry ?: ITransformerBudget<ILPUBudget> | null,
    ) : ILPUBudgetFormValues {        
        return {
            id: entry?.id ?? '',
            startDate: entry?.startDate ?? startDate,
            endDate: entry?.endDate ?? moment.utc(startDate).add(1, 'year').subtract(1, 'day').valueOf(),
            prevBudget: null,
            type: 'lpu',
            companyId: entry?.companyId ?? company?.id ?? '',
            companyName: entry?.companyName ?? company?.name ?? '',
            area: entry?.area ?? area,
            year: entry?.year ?? moment.utc(startDate).add(1, 'year').subtract(1, 'day').year(),
            values: (entry?.values ?? lodash.chain(transformers)
                .filter(x => x.MeterType === 'LPU')
                .filter(x => x.Company?.id === company?.id)
                .filter(x => x.Division === area)
                .flatMap((transfromer) => (
                    new Array(12).fill(0).map((n, i) => ({
                        offpeakBudget: null,
                        peakBudget: null,
                        standardBudget: null,
                        totalBudget: null,
                        comment: '',
                        month: moment.utc(startDate).add(i, 'month').valueOf(),
                        transformer: transfromer,
                        transformerRef: transfromer.ref.id,
                        days: moment.utc(startDate).add(i, 'month').daysInMonth(),
                        highSeason: HIGH_SEASON_MONTHS.includes(moment.utc(startDate).add(i, 'month').month() + 1),
                    } as ILPUBudgetValueFormValue))
                )).value())
                .map((n, i) => ({
                    ...n,
                    index: i,
                })),
        };
    }

    public static formLPUSchema = () => Yup.object<YupLPUBudgetFormShape>({
        id: Yup.string().optional(),
        endDate: Yup.number().required('Required'),
        prevBudget: Yup.string().nullable().optional(),
        startDate: Yup.number().required('Required'),
        type: Yup.string().required().oneOf(['lpu'], 'Invalid'),
        companyId: Yup.string().required('Required'),
        companyName: Yup.string().required('Required'),
        area: Yup.string().required('Required'),
        year: Yup.number().required(),
        values: Yup.array<YupLPUBudgetValueFormShape>(Yup.object<YupLPUBudgetValueFormShape>({
            index: Yup.number().required(),
            offpeakBudget: Yup.number().nullable(),
            peakBudget: Yup.number().nullable(),
            standardBudget: Yup.number().nullable(),
            totalBudget: Yup.number().nullable(),
            comment: Yup.string().optional(),
            month: Yup.number().required('Required'),
            days: Yup.number().required('Required'),
            transformer: Yup.object().required(),
            transformerRef: Yup.string().required('Required'),
            highSeason: Yup.boolean().required('Required'),
        }))
            .required(),
    });

    public static initMunicipalityForm(
        startDate : number,
        transformers : Array<ITransformer>,
        company : ITransformerCompany | null,
        area : string,
        entry ?: ITransformerBudget<IMunicipalityBudget> | null,
    ) : IMunicipalityBudgetFormValues {        
        return {
            id: entry?.id ?? '',
            startDate: entry?.startDate ?? startDate,
            endDate: entry?.endDate ?? moment.utc(startDate).add(1, 'year').subtract(1, 'day').valueOf(),
            prevBudget: null,
            type: 'municipality',
            companyId: entry?.companyId ?? company?.id ?? '',
            companyName: entry?.companyName ?? company?.name ?? '',
            area: entry?.area ?? area,
            year: entry?.year ?? moment.utc(startDate).add(1, 'year').subtract(1, 'day').year(),
            values: (entry?.values ?? lodash.chain(transformers)
                .filter(x => x.MeterType === 'Municipality')
                .filter(x => x.Company?.id === company?.id)
                .filter(x => x.Division === area)
                .flatMap((transfromer) => (
                    new Array(12).fill(0).map((n, i) => ({
                        budget: null,
                        comment: '',
                        month: moment.utc(startDate).add(i, 'month').valueOf(),
                        transformer: transfromer,
                        transformerRef: transfromer.ref.id,
                        days: moment.utc(startDate).add(i, 'month').daysInMonth(),
                        highSeason: HIGH_SEASON_MONTHS.includes(moment.utc(startDate).add(i, 'month').month() + 1),
                    } as IMunicipalityBudgetValueFormValue))
                )).value())
                .map((n, i) => ({
                    ...n,
                    index: i,
                })),
        };
    }

    public static formMunicipalitySchema = () => Yup.object<YupMunicipalityBudgetFormShape>({
        id: Yup.string().optional(),
        endDate: Yup.number().required('Required'),
        prevBudget: Yup.string().nullable().optional(),
        startDate: Yup.number().required('Required'),
        type: Yup.string().required().oneOf(['municipality'], 'Invalid'),
        companyId: Yup.string().required('Required'),
        companyName: Yup.string().required('Required'),
        area: Yup.string().required('Required'),
        year: Yup.number().required(),
        values: Yup.array<YupMunicipalityBudgetValueFormShape>(Yup.object<YupMunicipalityBudgetValueFormShape>({
            index: Yup.number().required(),
            budget: Yup.number().nullable(),
            comment: Yup.string().optional(),
            month: Yup.number().required('Required'),
            days: Yup.number().required('Required'),
            transformer: Yup.object().required(),
            transformerRef: Yup.string().required('Required'),
            highSeason: Yup.boolean().required('Required'),
        }))
            .required(),
    });
}

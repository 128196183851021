import { IBaseClass, BaseClass } from '../base';
import firebaseApp from '../../../services/firebaseService';

export interface IBoreholeMotor extends IBaseClass {
    name : string;
}

export default class BoreholeMotor extends BaseClass implements IBoreholeMotor {
    public static COLLECTION = 'borehole_motors';
    public name : string;

    constructor(snapshot? : firebase.firestore.DocumentSnapshot | firebase.firestore.QueryDocumentSnapshot) {
        super(snapshot);
        if (snapshot && snapshot.exists) {
            const data = snapshot.data();

            if (data) {
                this.name = data['name'];
            }
        }
    }

    protected toObject() {
        const obj = {
            ...this,
        };
        const { ref, ...rest } = obj;

        return rest;
    }

    public save() {
        if (this.ref) {
            return firebaseApp.firestore().doc(this.ref.path).set(this.toObject(), {
                merge: true,
            });
        } else {
            return firebaseApp.firestore().collection(BoreholeMotor.COLLECTION).add(this.toObject());
        }
    }
}

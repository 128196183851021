import { FormControl, TextField } from '@material-ui/core';
import React from 'react';

interface IIndexTextInputProps {
    id : string;
    label ?: string;
    value : string;
    onChange : (index : number, value : string) => void;
    index : number | null;
}

interface IIndexTextInputState {
}

export default class IndexTextInput extends React.Component<IIndexTextInputProps, IIndexTextInputState> {
    constructor(props : IIndexTextInputProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange(this.props.index ?? -1, event.target.value);
    };

    public readonly render = () => {
        const { id, label, value, index} = this.props;
        return (
            <FormControl fullWidth>
                <TextField
                    autoComplete='off'
                    id={`${id}_${index}`}
                    label={label}
                    value={value}
                    onChange={this.onChange}
                    margin='normal'
                    className={'TextField'}
                    type={'number'}
                />
            </FormControl>
        );
    };
}   
import { Field, FieldProps } from 'formik';
import React from 'react';
import TransformerAccountDropdown, { ITransformerAccountDropdownProps } from '../../selector/transformer/TransformerAccountSelector';
import { ITransformer } from '../../../../@types/model/transformer/transformer';

interface IAccountAutocompleteFormFieldProps extends Omit<ITransformerAccountDropdownProps, 'name' | 'value' | 'error' | 'variant' | 'onChange' | 'transformers' | 'isLoading'> {
    name : string;

    onChange ?: (transformer : ITransformer | null, params : FieldProps<string | null>) => void;
}

class AccountAutocompleteFormField extends React.PureComponent<IAccountAutocompleteFormFieldProps> {
    constructor(props : IAccountAutocompleteFormFieldProps) {
        super(props);
    }

    private readonly onChange = (transformer : ITransformer | null, params : FieldProps<string | null>) => {
        params.form.setFieldValue(params.field.name, transformer?.AccountNumber ?? null);

        setTimeout(() => {
            if (!this.props.onChange) return;
            this.props.onChange(transformer, params);
        });
        
    };

    public readonly render = () => {
        const { ...rest } = this.props;
        return (
            <Field
                name={rest.name}
            >
                {
                    (params : FieldProps<string | null>) => (
                        
                        <TransformerAccountDropdown
                            {...rest}
                            onChange={(value) => this.onChange(value ?? null, params)}
                            value={params.field.value ?? ''}
                            required
                        />
                    )
                }
            </Field>
        );
    };
}

export default AccountAutocompleteFormField;
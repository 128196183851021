import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { ITransformerJournal } from '../../../../@types/model/transformer/journal';
import Typography from '@material-ui/core/Typography';
import PrimaryTab from '../../../customComponents/tab/PrimaryTab';
import PrimaryTabs from '../../../customComponents/tab/PrimaryTabs';
import TabPanel from '../../../customComponents/tab/TabPanel';
import TransformerJournalInvoicesView from '../view/Invoices';
import TransformerJournalPaymentView from '../view/Payment';
import TransformerJournalSplit from '../view/Split';

interface ITransformerJournalInfoDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    value : ITransformerJournal;
}

interface ITransformerJournalInfoDialogState {
    open : boolean;

    tabIndex : number;
}

export default class TransformerJournalInfoDialog extends React.PureComponent<ITransformerJournalInfoDialogProps, ITransformerJournalInfoDialogState> {
    constructor(props : ITransformerJournalInfoDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
            tabIndex: 0,
        });
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
        });
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, value } = this.props;
        const { open, tabIndex } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Info'>
                    <div>
                        <IconButton color='primary' onClick={this.onClick} aria-label='Info'>
                            <Icon>info</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Info-dialog-title'
                    aria-describedby='Info-description'>
                    <AppBar className='posr' position='static' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography variant='h5' color='inherit'>
                                Journal {value.journalNumber}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                        <PrimaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                            <PrimaryTab label='SPLITS' value={0} />
                            <PrimaryTab label='INVOICES' value={1} />
                            <PrimaryTab label='PAYMENT' value={2} />
                        </PrimaryTabs>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill bcg0'>
                        <TabPanel value={tabIndex} index={0}>
                            <div className='fdc flx1 ais hfill bcg0'>
                                <TransformerJournalSplit value={value} />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <div className='fdc flx1 bcg0'>
                                <TransformerJournalInvoicesView value={value} />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <div className='fdc flx1 bcg0'>
                                <TransformerJournalPaymentView value={value} />
                            </div>
                        </TabPanel>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    };
}
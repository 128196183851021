import React from 'react';

interface IStadiumDivProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export default class StadiumDiv extends React.PureComponent<IStadiumDivProps> {
    constructor(props : IStadiumDivProps) {
        super(props);
    }

    public readonly render = () => {
        const { className, ...rest } = this.props;
        return (<div
            className={`fdc jcc aic pl30 pr30 h40 br50 bcp cw fs15 fw700 ftu ${className ?? ''}`}
            {...rest}
        />);
    };
}

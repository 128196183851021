import { Typography, Card } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IDam } from '../../../@types/model/dam/dam';
import { IRootState } from '../../../@types/redux';
import { Transitions } from '../../customComponents/animations/Transitions';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import EditDamEP from '../dialog/EditEP';
import { IUserSession } from '../../../@types/employee';
import { createSelector } from 'reselect';

interface IDamTransformerProps {
    dam : IDam;

    isLoading : boolean;

    isDamAdmin : boolean;
    session ?: IUserSession | null;
}

interface IDamTransformerState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

class DamTransformerComponent extends React.PureComponent<IDamTransformerProps, IDamTransformerState> {
    constructor(props : IDamTransformerProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    public readonly getImages = (props : IDamTransformerProps) => props.dam.electricalBoxFiles;
    public readonly getDamImage = createSelector(
        [this.getImages],
        (images) => {
            return images.length ? images[images.length - 1] : null;
        }
    );

    public readonly getImageUrls = createSelector(
        [this.getDamImage],
        (damImage) => {
            const result : Array<string> = [
                damImage?.downloadURL ?? '',
            ];

            return result;
        }
    );

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    private readonly onThumbClick = (event : React.MouseEvent<HTMLImageElement>) => {
        this.setState({
            isCarouselOpen: true,
            imageIndex: Number(event.currentTarget.id),
        });
    };

    public readonly render = () => {
        const { imageIndex, isCarouselOpen } = this.state;
        const { dam, isDamAdmin, session } = this.props;

        const image = this.getDamImage(this.props);
        const imageSrcs = this.getImageUrls(this.props);
        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            ELECTRICAL POINT
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isDamAdmin &&
                                    <EditDamEP
                                        dam={dam}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                        session={session}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdr ml17 mr17 mb13'}>
                        <div className={'fdc mb13'}>
                            <Typography className={'fs11 bbw1 bocGrey fw500 cp pl15 pr15 pb15 aic jcc'}>
                                TRANSFORMER
                            </Typography>
                            <div className={'bcg0 fdc aic jcfs w200 p10 mt10'}>
                                <Typography className={'fs11 cdg'}>
                                    EP POINT
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.transformerNumber ?? '-'}
                                </Typography>
                            </div>
                        </div>
                        <div className='brw1 ml17 mr17 bocg3'>
                        </div>
                        <div className={'fdc mb13'}>
                            <Typography className={'fs11 bbw1 bocGrey fw500 cp pl15 pr15 pb15 aic jcc'}>
                                ELECTRICAL BOX
                            </Typography>
                            <div className={'fdc flx1 aic jcc p10 mt10'}>
                                <div className='w200'>
                                    {
                                        !!image &&
                                        <div className='fdc jcc aic flx1'>
                                            <img
                                                id={'0'}
                                                className='curp'
                                                width={200}
                                                src={image.thumbnailUrl ?? image.downloadURL ?? ''}
                                                onClick={this.onThumbClick}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        isDamAdmin: !!state.auth.session?.employee.IsDamAdmin,
        isLoading: state.dam.loading,
    };
};

const DamTransformerView = connect(
    mapStateToProps,
)(DamTransformerComponent);

export default DamTransformerView;


import { IconButton, Dialog, AppBar, Toolbar, DialogContent, DialogActions, Button, FormControl, LinearProgress } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { IDamWeeklyReading } from '../../../@types/model/dam/weeklyReading';
import DamFunctions from '../../../store/dam/functions';
import * as firebase from 'firebase/app';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import DamMeasurementSelector from '../../customComponents/selector/dam/DamMeasurementSelector';
import { IDam, IDamVolume } from '../../../@types/model/dam/dam';
import CapacityButton from '../button/CapacityButton';

interface IEditDamReadingDialogProps {
    reading : IDamWeeklyReading | null;
    dam ?: IDam;
    showEdit : boolean;
    onClose : () => void;
    isLoading : boolean;
}

interface IEditDamReadingDialogState {
    measurement : number;
    capacity : number;
    volume : number;
    date : moment.Moment;
    damVolume ?: number;
    selectedVolume ?: IDamVolume;
}

class EditDamReadingDialog extends React.PureComponent<IEditDamReadingDialogProps, IEditDamReadingDialogState> {
    constructor(props : IEditDamReadingDialogProps) {
        super(props);
        this.state = {
            measurement: 0,
            capacity: 0,
            volume: 0,
            date: moment.utc(),
            damVolume: 0,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditDamReadingDialogProps) => {
        if (prevProps.showEdit && !this.props.showEdit) {
            this.setState({
                measurement: 0,
                capacity: 0,
                volume: 0,
                date: moment.utc(),
                damVolume: 0,
            });
        }
        if (!prevProps.showEdit && this.props.showEdit) {
            this.setState({
                measurement: !this.props.reading?.measurement ? 0 : this.props.reading.measurement,
                capacity: !this.props.reading?.currentCapacity ? 0 : this.props.reading.currentCapacity,
                volume: !this.props.reading?.currentVolume ? 0 : this.props.reading.currentVolume,
                date: moment.utc(this.props.reading?.date.toMillis()),
            });
        }
    };

    public readonly onClick = () => {
        this.props.onClose();
    };

    private readonly onSubmit = async () => {
        const { measurement, volume, capacity, date } = this.state;

        const reading = {
            ...Object.assign({}, this.props.reading),
        };

        if (this.props.dam?.measurementStick) {
            reading.measurement = measurement;
            reading.currentVolume = volume;
        }

        reading.currentCapacity = capacity;
        reading.date = firebase.firestore.Timestamp.fromMillis(moment.utc(date).valueOf());

        const result = await DamFunctions.saveWeeklyReading(reading);

        if (result) {
            this.props.onClose();
        }
    };
   
    private readonly onMeasurementChange = (value ?: number) => {

        const selectedVolume = this.props.dam?.damVolumes.find((e) => e.plate == value);
        if (!selectedVolume) return;

        this.setState({
            measurement: value ?? 0,
            capacity: Number(selectedVolume.percentage.toFixed(2)),
            volume: selectedVolume.volume,
        });
    };
    
    private readonly onDateChange = (momentDate : moment.Moment | null) => {
        if (!momentDate) return;

        this.setState({
            date: momentDate,
        });
    };

    private readonly onCapacityClick = (value : number) => {
        this.setState({
            capacity: value,
        });
    };

    public readonly render = () => {
        const { isLoading } = this.props;
        const { capacity, volume, date, measurement } = this.state;
        const capacities = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

        return (
            <Dialog
                open={this.props.showEdit}
                transitionDuration={500}
                onClose={this.onClick}
                maxWidth={'lg'}
                fullScreen={false}
                fullWidth={true}
                aria-labelledby='dam-info-info-title'
                aria-describedby='dam-info-info-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                                Edit Dam Plate Info
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={this.onClick} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    { isLoading &&
                        <LinearProgress />
                    }
                    <DialogContent className='fdc flx1 hfill'>
                        <div className={'fdc flx1 '}>
                            <div className={'fdr flx1 aifs'}>
                                {
                                    this.props.dam?.measurementStick
                                        ? <>
                                            <div className={'flx1 ais p5 mb10 pr20 jcc aic pt30'}>
                                                <DamMeasurementSelector damVolumes={this.props.dam.damVolumes} value={measurement} onChange={this.onMeasurementChange} />
                                            </div>
                                            <div className={'flx1 ais p5 mb10 pr20 fdc jcc aic pt30'}>
                                                <Typography className={'fs16'}>
                                                        Current Capacity
                                                </Typography>
                                                <Typography className={'fs16'}>
                                                    { Number((capacity * 100).toFixed(2))}%
                                                </Typography>
                                            </div>
                                            <div className={'flx1 ais p5 mb10 pr20 fdc jcc aic pt30'}>
                                                <Typography className={'fs16'}>
                                                        Volume
                                                </Typography>
                                                <Typography className={'fs16'}>
                                                    {volume} m<sup>3</sup>
                                                </Typography>
                                            </div>
                                        </>
                                        :
                                        <div className={'fdc flx1'}>
                                            <Typography className={'fs18 cpd pb15'}>
                                                Please select current capacity
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'grid',
                                                    gridTemplateColumns: '100px 100px 100px 100px 100px',
                                                    gridTemplateRows: '35px 35px 35px',
                                                    gridRowGap: 7,
                                                    gridColumnGap: 15,
                                                }}>
                                                {
                                                    capacities.map((e) => <CapacityButton value={e} selectedValue={capacity} onClick={this.onCapacityClick} key={e} />)
                                                }
                                            </div>
                                        </div>
                                }
                                <div className={'flx1 ais p5 mb10 '}>
                                    <FormControl fullWidth>
                                        <DatePicker
                                            autoComplete='off'
                                            id='date'
                                            label='Date'
                                            value={date}
                                            onChange={this.onDateChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClick} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };
}
const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.dam.loadingReadings,
        dam: state.dam.selectedDam,
    };
};

export default connect(
    mapStateToProps,
)(EditDamReadingDialog);
import { TextField } from '@material-ui/core';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface IControlledTextFieldProps {
    name : string;
    label : string;
    control : Control<any, any>;
    defaultValue ?: string;
    className ?: string;
    disabled ?: boolean;
    variant ?: 'filled' | 'outlined' | 'standard';
    onChange ?: (value : any) => void;
}

const CustomTextField = (props: IControlledTextFieldProps): React.ReactElement => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValue || ''}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    label={props.label}
                    className={props.className}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : ''}
                    disabled={props.disabled}
                    variant={props.variant}
                    onChange={(event : any) : void => {
                        field.onChange(event);

                        if (props.onChange) {
                            props.onChange(event);
                        }
                    }}
                />
            )}
        />
    );
};

export default CustomTextField;
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

interface IConfirmInternalDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    onClose : (result : boolean) => void;

    acceptText ?: string;
    denyText ?: string;
    title : string;
    message : string;

    disabled ?: boolean;

    buttonText : string;
    tooltipText : string;
}

interface IConfirmInternalDialogState {
    open : boolean;

}

export default class ConfirmInternalDialog extends React.PureComponent<IConfirmInternalDialogProps, IConfirmInternalDialogState> {
    constructor(props : IConfirmInternalDialogProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
        });

        this.props.onClose(false);
    };

    public readonly onAccept = () => {
        this.setState({
            open: false,
        });

        this.props.onClose(true);
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            acceptText,
            denyText,
            title,
            message,
            disabled,
            tooltipText,
            buttonText,
        } = this.props;
        
        const { open } = this.state;
        return (
            <React.Fragment>
                <Tooltip title={tooltipText}>
                    <div>
                        <Button disabled={disabled} variant='contained' color='primary' onClick={this.onClick}>
                            {buttonText}
                        </Button>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='confirm-dialog-title'
                    aria-describedby='confirm-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit' className='flx1'>
                                {title}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill'>
                        <Typography variant='subtitle1'>
                            {message}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' onClick={this.onAccept} color='primary'>
                            {acceptText ? acceptText : 'YES'}
                        </Button>
                        <Button variant='outlined' onClick={this.onClose} color='primary'>
                            {denyText ? denyText : 'NO'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    };
}

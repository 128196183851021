import React from 'react';
import { IMottech, IMottechIO } from '../../../@types/mottech';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import mottechFunctions from '../../../store/mottech/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MottechIOInput from '../../customComponents/input/MottechIOInput';
import { MOTTECH_TYPES } from '../../../appConstants';

interface IEditMottechIOProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    mottech ?: IMottech;
    input ?: boolean;
}

interface IEditMottechIOState {
    open : boolean;
    io : Array<IMottechIO>;
}

export default class EditMottechIO extends React.Component<IEditMottechIOProps, IEditMottechIOState> {
    constructor(props : IEditMottechIOProps) {
        super(props);
        this.state = {
            open: false,
            io: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditMottechIOProps, prevState : IEditMottechIOState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                io: [],
            });
        }
        if (!prevState.open && this.state.open && this.props.mottech) {
            const { input } = this.props;

            this.setState({
                io: input ? this.props.mottech.Inputs : this.props.mottech.Outputs,
            });
        }

    };

    private readonly save = async () => {
        const { input } = this.props;
        const { io } = this.state;

        if (!this.props.mottech) return;
        if (io.some(n => !n.Name || (n.Name === 'Other' && n.Additional))) return;

        const mottech = {
            ...this.props.mottech,
        };

        if (input) {
            mottech.Inputs = io;
        } else {
            mottech.Outputs = io;
        }

        const result = await mottechFunctions.saveMottech(mottech);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onAddIOClick = () => {
        const { input, mottech } = this.props;

        if (!mottech?.Type) return;

        const io = this.state.io.slice();

        const type = MOTTECH_TYPES[mottech.Type];

        if (!type ||
        (type.inputs <= io.length && input) ||
        (type.outputs <= io.length && !input)) return;

        io.push({
            InputNumber: io.length + 1,
            Name: '',
            Additional: null,
        });

        this.setState({
            io,
        });
    };

    private readonly onIOChange = (runTimer : IMottechIO) => {
        const io = this.state.io.slice();
        io.splice(runTimer.InputNumber - 1, 1, runTimer);
        this.setState({
            io,
        });
    };

    private readonly onIODelete = (inputNumber : number) => {
        const io = this.state.io.slice();
        io.splice(inputNumber - 1, 1);

        io.forEach((n, i) => {
            n.InputNumber = i + 1;
        });

        this.setState({
            io,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, input, mottech } = this.props;
        const { open, io } = this.state;

        const type = MOTTECH_TYPES[mottech?.Type ?? ''];

        const addDisabled = !type || (type.inputs <= io.length && input) || (type.outputs <= io.length && !input);
        return (
            <div>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='mottech-io-edit-dialog-title'
                    aria-describedby='mottech-io-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Mottech IO
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc flx1 hfill oys'>
                        <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                            <DialogContent className='fdc flx1 hfill'>
                                <div className={'fdc flx1'}>
                                    <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                        {input ? 'INPUTS' : 'OUTPUTS'}
                                        <span className='flx1' />
                                        <Tooltip title='Add'>
                                            <div>
                                                <IconButton size='small' disabled={addDisabled} color='inherit' onClick={this.onAddIOClick} aria-label='Add'>
                                                    <Icon>add</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10'} variant='subtitle1'>
                                            NUMBER
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10'} variant='subtitle1'>
                                            TYPE
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10'} variant='subtitle1'>
                                            OTHER
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10'} variant='subtitle1'>
                                        </Typography>
                                    </div>
                                    <div className={'fdc flx1'}>
                                        {
                                            io.map(m => (
                                                <MottechIOInput input={input} key={`${m.InputNumber}_meter`} value={m} onChange={this.onIOChange} onDelete={this.onIODelete} />
                                            ))
                                        }
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                    OK
                                </Button>
                                <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                    CANCEL
                                </Button>
                            </DialogActions>
                        </Form>
                    </div>
                </Dialog>
            </div>);
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import boreholeFunctions from '../../../../store/borehole/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import appFunctionsService from '../../../../services/appFunctionsService';
import RunTimerInput from '../../../customComponents/input/RunTimerInput';
import moment from 'moment';
import firebase from 'firebase/app';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IBoreholeTest, IBoreholeRuntimer } from '../../../../@types/model/borehole/boreholeTest';

interface IEditBoreholeTestRunTimersProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    boreholeTest ?: IBoreholeTest;
}

interface IEditBoreholeTestRunTimersState {
    open : boolean;
    runTimers : Array<IBoreholeRuntimer>;
}

export default class EditBoreholeTestRunTimers extends React.Component<IEditBoreholeTestRunTimersProps, IEditBoreholeTestRunTimersState> {
    constructor(props : IEditBoreholeTestRunTimersProps) {
        super(props);
        this.state = {
            open: false,
            runTimers: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeTestRunTimersProps, prevState : IEditBoreholeTestRunTimersState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                runTimers: [],
            });
        }
        if (!prevState.open && this.state.open && this.props.boreholeTest) {
            this.setState({
                runTimers: this.props.boreholeTest.Runtimers,
            });
        }

    };

    private readonly onRuntimerChange = (runTimer : IBoreholeRuntimer, index : number) => {
        const runTimers = this.state.runTimers.slice();
        runTimers.splice(index, 1, runTimer);
        this.setState({
            runTimers,
        });
    };

    private readonly onTimerDelete = (index : number) => {
        const runTimers = this.state.runTimers.slice();
        runTimers.splice(index, 1);
        this.setState({
            runTimers,
        });
    };

    private readonly onAddClick = () => {
        const runTimers = this.state.runTimers.slice();

        runTimers.push({
            StartTime: firebase.firestore.Timestamp.fromMillis(moment().startOf('day').valueOf()),
            EndTime: firebase.firestore.Timestamp.fromMillis(moment().startOf('day').valueOf()),
            Duration: 0,
        });

        this.setState({
            runTimers,
        });
    };

    private readonly save = async () => {
        const { runTimers } = this.state;
        if (!this.props.boreholeTest) return;

        const boreholeTest = {
            ...this.props.boreholeTest,
        };

        boreholeTest.Runtimers = runTimers;
        const result = await boreholeFunctions.saveBoreholeTest(boreholeTest);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly getData = (state : IEditBoreholeTestRunTimersState) => state.runTimers;
    public readonly getTotalRuntime = createSelector(
        [this.getData],
        (runTimers) => {

            return lodash.sum(runTimers.map(n => n.Duration)) / 60;
        },
    );

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            isLoading,
            boreholeTest,
        } = this.props;
        
        const { open, runTimers } = this.state;

        const totalRuntime = this.getTotalRuntime(this.state);
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-info-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Borehole Test ({!boreholeTest ? '' : appFunctionsService.formatDateTimeToDateOnly(boreholeTest.CreatedOn)})
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill oya'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdc flx1 m10'}>
                                <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    TIMERS
                                    <span className='flx1' />
                                    <Tooltip title='Add'>
                                        <div>
                                            <IconButton size='small' color='inherit' onClick={this.onAddClick} aria-label='Add'>
                                                <Icon>add</Icon>
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </Typography>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        START
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        END
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        DURATION
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                    </Typography>
                                </div>
                                {
                                    runTimers.map((t, i) => (
                                        <RunTimerInput index={i} key={`${i}_timer`} value={t} onChange={this.onRuntimerChange} onDelete={this.onTimerDelete} />
                                    ))
                                }
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48 jcfe'} variant='subtitle1' component='b'>
                                        Total:
                                    </Typography>
                                    <Typography className={'flx1 fw600 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {totalRuntime} h
                                    </Typography>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

import React from 'react';
import { ITransformerUsage } from '../../../../@types/model/transformer/transformerUsage';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DispatchCall, IAuthState, IRootState, RootAction } from '../../../../@types/redux';
import { enqueueSnackbar } from '../../../../store/general/actions';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../../../customComponents/materialTable/Table';
import Tooltip from '@material-ui/core/Tooltip';
import TransformerUsageEditDialog from './Edit';
import { Transitions } from '../../../customComponents/animations/Transitions';
import DataFunctions from '../../../../store/data/functions';
import { createSelector } from 'reselect';
import Icon from '@material-ui/core/Icon';

interface ITransformerUsageListProps {
    auth : IAuthState;
    generalShowErrorSnackbar : DispatchCall<string>;
    usages : Array<ITransformerUsage>;
    isLoading : boolean;
}

interface ITransformerUsageListState {
}

class TransformerUsageList extends React.PureComponent<ITransformerUsageListProps, ITransformerUsageListState> {
    constructor(props : ITransformerUsageListProps) {
        super(props);
        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        DataFunctions.getTransformerUsages();
    };

    private readonly getUsages = (props : ITransformerUsageListProps) => props.usages;

    private readonly getDefaultCode = createSelector(
        [this.getUsages],
        (usages) => {
            return `US${(usages.length + 1).toString().padStart(3, '0')}`;
        },
    );

    public readonly render = () => {
        const { auth, isLoading, usages } = this.props;

        const defaultCode = this.getDefaultCode(this.props);
        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<ITransformerUsage>
                            id='transformerUsagesTable'
                            data={usages}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            externalSort
                            columns={[{
                                header: '',
                                field: 'id',
                                width: 50,
                                renderCell: row => (
                                    <div className='aic'>
                                        <TransformerUsageEditDialog auth={auth} usage={row}
                                            isLoading={isLoading}
                                            fullWidth
                                            maxWidth='sm'
                                            transition={Transitions.Down} />
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Is Active?',
                                field: 'isActive',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.isActive ? (<Icon className='cr'>close</Icon>) : (<Icon className='cdg'>check</Icon>),
                            }, {
                                header: 'Created By',
                                field: 'createdByName',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={row.createdByEmployee}>
                                        <div>
                                            {
                                                row.createdByName
                                            }
                                        </div>
                                    </Tooltip>
                                ),
                            }, {
                                header: 'Updated By',
                                field: 'updatedByName',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={row.updatedByEmployee}>
                                        <div>
                                            {
                                                row.updatedByName
                                            }
                                        </div>
                                    </Tooltip>
                                ),
                            }]}
                        />
                    </Card>
                    <div className='fdr jcfe'>
                        <TransformerUsageEditDialog defaultCode={defaultCode} auth={auth} isLoading={isLoading} fullWidth maxWidth='sm' transition={Transitions.Down} />
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        isLoading: state.data.isLoadingTransformerUsages,
        usages: state.data.transformerUsages,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators({
    generalShowErrorSnackbar: (message : string) => dispatch(enqueueSnackbar({
        message,
        options: {
            variant: 'error',
        },
    })),
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TransformerUsageList);

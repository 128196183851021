import { CircularProgress, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { createSelector } from 'reselect';
import TransformerHelper, { ITransformer } from '../../@types/model/transformer/transformer';
import CarouselImageViewer from '../customComponents/image/CarouselViewer';
import RoundedLabelImage, { IRoundedLabelImageView } from '../customComponents/image/RoundedLabelImage';

interface ITransformerMapSideViewProps {
    transformer : ITransformer;
}

interface ITransformerMapSideViewState {
    images : Array<IRoundedLabelImageView>;
    isCarouselOpen : boolean; 
    imageIndex : number;

    isLoading : boolean;
}

export default class TransformerMapSideView extends React.PureComponent<ITransformerMapSideViewProps, ITransformerMapSideViewState> {
    constructor(props : ITransformerMapSideViewProps) {
        super(props);
        this.state = {
            images: [],
            isCarouselOpen: false,
            imageIndex: -1,
            isLoading: false,
        };
    }

    public componentDidMount() {
        this.loadImageSources();
    }

    public componentDidUpdate(prevProps : Readonly<ITransformerMapSideViewProps>) : void {
        if (prevProps.transformer.ref.id !== this.props.transformer.ref.id) {
            this.loadImageSources();
        }
        
    }

    private async loadImageSources() {
        const { transformer } = this.props;

        const images : Array<IRoundedLabelImageView> = [];
        const promises : Array<Promise<unknown>> = [];

        this.setState({
            isLoading: true,
        });

        // Load Transformer Image
        if (transformer.TransformerFileName) {
            if (transformer.TransformerFileUrl) {
                images.push({
                    name: 'TRANSFORMER',
                    url: transformer.TransformerFileUrl,
                    thumb: transformer.TransformerFileThumbnailUrl ?? transformer.TransformerFileUrl,
                });
            } else {
                promises.push(
                    TransformerHelper.loadImage(transformer, transformer.TransformerFileName)
                        .then((result) => {
                            images.push({
                                name: 'TRANSFORMER',
                                url: result[0],
                                thumb: result[1],
                            });
                        }),
                );
            }
        }

        // Load Pole Image
        if (transformer.PoleFileName) {
            if (transformer.PoleFileUrl) {
                images.push({
                    name: 'POLE',
                    url: transformer.PoleFileUrl,
                    thumb: transformer.PoleFileThumbnailUrl ?? transformer.PoleFileUrl,
                });
            } else {
                promises.push(
                    TransformerHelper.loadImage(transformer, transformer.PoleFileName)
                        .then((result) => {
                            images.push({
                                name: 'POLE',
                                url: result[0],
                                thumb: result[1],
                            });
                        }),
                );
            }
        }

        // Load Pole Image
        if (transformer.CircuitFileName) {
            if (transformer.CircuitFileUrl) {
                images.push({
                    name: 'CIRCUIT',
                    url: transformer.CircuitFileUrl,
                    thumb: transformer.CircuitFileThumbnailUrl ?? transformer.CircuitFileUrl,
                });
            } else {
                promises.push(
                    TransformerHelper.loadImage(transformer, transformer.CircuitFileName)
                        .then((result) => {
                            images.push({
                                name: 'CIRCUIT',
                                url: result[0],
                                thumb: result[1],
                            });
                        }),
                );
            }
        }

        // Load Yellow Image
        if (transformer.YellowNumberFileName) {
            if (transformer.YellowNumberFileUrl) {
                images.push({
                    name: 'YELLOW NUMBER',
                    url: transformer.YellowNumberFileUrl,
                    thumb: transformer.YellowNumberFileThumbnailUrl ?? transformer.YellowNumberFileUrl,
                });
            } else {
                promises.push(
                    TransformerHelper.loadImage(transformer, transformer.YellowNumberFileName)
                        .then((result) => {
                            images.push({
                                name: 'YELLOW NUMBER',
                                url: result[0],
                                thumb: result[1],
                            });
                        }),
                );
            }
        }

        await Promise.all(promises);

        this.setState({
            images,
            isLoading: false,
        });
    }

    private readonly onCarouselClose = () => {
        this.setState({ 
            isCarouselOpen: false,
        });
    };

    public readonly getImages = (state : ITransformerMapSideViewState) => state.images;
    public readonly getImageSrcs = createSelector(
        [this.getImages],
        (images) => {
            return images.map(x => x.url);
        },
    );

    private readonly onThumbClick = (event : React.MouseEvent<HTMLImageElement>) => {
        const imageSrcs = this.getImageSrcs(this.state);
        
        const index = imageSrcs.indexOf(event.currentTarget.alt);

        this.setState({ 
            isCarouselOpen: true, 
            imageIndex: index,
        });
    };

    public readonly render = () => {
        const { transformer } = this.props;
        const { images, imageIndex, isCarouselOpen, isLoading } = this.state;

        const imageSrcs = this.getImageSrcs(this.state);
        return (
            <div className={'fdc flx1 p20'}>
                <Typography className={'fdr fw700 cp'} variant='h6'>
                    SUMMARY:
                </Typography>
                <Divider className={'h2 bcp'}/>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        GROUP:
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.EPGroup}
                    </Typography>
                </div>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        AREA:
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.Division} 
                    </Typography>
                </div>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        DESCRIPTION
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.SubArea} 
                    </Typography>
                </div>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        ACCOUNT NUMBER:
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.AccountNumber} 
                    </Typography>
                </div>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        POLE NUMBER:
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.PoleNumber}
                    </Typography>
                </div>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        METER TYPE
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.MeterType}
                    </Typography>
                </div>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        LINE:
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.Line}
                    </Typography>
                </div>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        CIRCUIT BREAKER:
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.CircuitBreaker}
                    </Typography>
                </div>
                <div className={'fdr pt10'}>
                    <Typography className={'flx1'} variant='body2'>
                        TRANSFORMER RATING:
                    </Typography>
                    <Typography className={'flx1 fw500'} variant='body2'>
                        {transformer.TransformerRating}
                    </Typography>
                </div>
                <Typography className={'fdr fw700 cp pt20'} variant='h6'>
                    IMAGES:
                </Typography>
                <Divider className={'h2 bcp'}/>
                <div className={'fdc pt10'}>
                    {
                        isLoading &&
                        <div className='fdc aic jcc'>
                            <CircularProgress />
                        </div>
                    }
                    {
                        !isLoading &&
                        <div className={'fdr fww jcsb'}>
                            {
                                images.map((image, i) => (
                                    <RoundedLabelImage
                                        key={i}
                                        thumbnailUrl={image.thumb}
                                        fileUrl={image.url}
                                        label={image.name} 
                                        className={'mr20 mb10'}
                                        onClick={this.onThumbClick}
                                    />
                                ))
                            }
                        </div>
                    }
                </div>
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

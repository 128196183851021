import React from 'react';
import PumpingStationHelper, { EnumPumpingStationFileType, IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FileDropZone from '../../customComponents/FileDropZone';
import GeneralFunctions from '../../../store/general/functions';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import TransformerHelper, { ITransformer } from '../../../@types/model/transformer/transformer';
import TransformerCustomMap from '../../customComponents/map/TransformerMap';
import { createSelector } from 'reselect';
import { DEFAULT_MAP_CENTER, PUMPING_STATION_MAP_IMAGES } from '../../../appConstants';
import { IMapMarker } from '../../customComponents/map/CustomMapBox';
import firebaseApp from '../../../services/firebaseService';
import { IUserSession } from '../../../@types/employee';
import firebase from 'firebase/app';
import { EnumFileUploadQueueType } from '../../../@types/model/files/enum';

interface IEditPumpingStationEPProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    pumpingStation : IPumpingStation;
    session ?: IUserSession | null;
}

interface IEditPumpingStationEPState {
    open : boolean;
    epNumber : string | null;

    image ?: File | null;

    isLoading : boolean;
}

export default class EditPumpingStationEP extends React.Component<IEditPumpingStationEPProps, IEditPumpingStationEPState> {
    constructor(props : IEditPumpingStationEPProps) {
        super(props);
        this.state = {
            open: false,
            epNumber: '',
            isLoading: false,
            image: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditPumpingStationEPProps, prevState : IEditPumpingStationEPState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                epNumber: this.props.pumpingStation.transformer?.id ?? null,
                image: null,
            });
        }

    };

    public readonly getPumpingStationCode = (state : IEditPumpingStationEPState, props : IEditPumpingStationEPProps) => props.pumpingStation.code;
    public readonly getPumpingStationGeom = (state : IEditPumpingStationEPState, props : IEditPumpingStationEPProps) => props.pumpingStation.geo;

    public readonly getMapCenter = createSelector(
        [this.getPumpingStationGeom],
        (geom) => {
            return !geom ? DEFAULT_MAP_CENTER : [geom.longitude, geom.latitude] as [number, number];
        }
    );
    
    public readonly getMarkers = createSelector(
        [
            this.getPumpingStationCode,
            this.getMapCenter,
        ],
        (
            code,
            center,
        ) => {
            return [{
                coordinates: center,
                iconSize: 0.35,
                icon: 'pumpingStation',
                id: code,
            } as IMapMarker];
        }
    );

    private readonly onTransformer = (value ?: ITransformer) => {
        this.setState({
            epNumber: value?.EPNumber ?? null,
        });
    };

    private readonly onMarkerClick = (value ?: string) => {
        this.setState({
            epNumber: value ?? null,
        });
    };

    private readonly save = async () => {
        try {
            this.setState({
                isLoading: true,
            });
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { epNumber, image } = this.state;
                const { session, pumpingStation } = this.props;
        
                if (!session) throw new Error('Session Required');

                const ref = PumpingStationHelper.doc(pumpingStation.id);

                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);

                if (!doc.exists) return;
    
                transaction.set(ref, {
                    ...pumpingStation,
                    transformer: !epNumber ? null : TransformerHelper.doc(epNumber),
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                    isSent: false,
                }, {
                    merge: true,
                });

                if (image) {
                    await PumpingStationHelper.uploadFile(
                        pumpingStation.id,
                        image,
                        {
                            collection: PumpingStationHelper.COLLECTION_NAME,
                            fieldName: 'documentURL',
                            fileType: EnumFileUploadQueueType.PumpingStation.toString(),
                            refGuid: pumpingStation.id,
                            thumbnailFieldName: 'documentThumbnailUrl',
                            type: EnumPumpingStationFileType.ElectricalBox.toString(),
                        }
                    );
                }
            });

            if (this.state.image) {
                GeneralFunctions.generalShowSuccessSnackbar('PumpingStation EP image uploaded successfuly');
            }

            this.onClose();
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'PumpingStation EP image upload failed');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
        
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        this.setState({
            open: false,
        });
    };

    private readonly onFileSelected = (image : File) => {
        this.setState({
            image,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, pumpingStation } = this.props;
        const { open, epNumber, isLoading } = this.state;

        const mapCenter = this.getMapCenter(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='pumpingStation-info-edit-dialog-title'
                    aria-describedby='pumpingStation-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit PumpingStation EP Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 jcfs hfill'>
                            <div className={'fdr flx1 ais'}>
                                <div className={'fdc flx1 ais mr15'}>
                                    <TransformerDropdown value={epNumber} fullWidth onChange={this.onTransformer} />
                                    <div className='fdc flx1 ais'>
                                        <TransformerCustomMap
                                            selected={epNumber}
                                            mapCenter={mapCenter}
                                            onMarkerClick={this.onMarkerClick}
                                            extraMarkers={markers}
                                            extraImages={{
                                                'pumpingStation': PUMPING_STATION_MAP_IMAGES['1'],
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={'fdc flx1 aic ml15'}>
                                    <FileDropZone
                                        path={`pumpingStation/${pumpingStation.code}`}
                                        onFileSelected={this.onFileSelected}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

import React from 'react'; 
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import riverFunctions from '../../../store/river/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FileDropZone from '../../customComponents/FileDropZone';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRiver } from '../../../@types/model/river/river';
import GeneralFunctions from '../../../store/general/functions';

interface IEditRiverImagesProps {
    river ?: IRiver;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading ?: boolean;
}

interface IEditRiverImagesState {
    open : boolean;
    riverImage ?: File;

    riverImageProgress : number;
    isUploadingLoading : boolean;
}

export default class EditRiverImages extends React.Component<IEditRiverImagesProps, IEditRiverImagesState> {
    constructor(props : IEditRiverImagesProps) {
        super(props);
        this.state = {
            open: false,
            riverImageProgress: 0,
            isUploadingLoading: false,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditRiverImagesProps, prevState : IEditRiverImagesState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                riverImage: undefined,
                riverImageProgress: 0,
                isUploadingLoading: false,
            });
        }
    };

    private readonly save = async () => {
        const { riverImage } = this.state;
        
        this.setState({
            isUploadingLoading: true,
        });

        if (riverImage) {
            try {
                await riverFunctions.uploadSelectedRiverImage(riverImage, (progress : number) => {
                    this.setState({
                        riverImageProgress: progress,
                    });
                });
                GeneralFunctions.generalShowSuccessSnackbar('River Image uploaded.');
            } catch (ex) {
                GeneralFunctions.generalShowError(ex, 'River Image upload error.');
            }
        }

        this.setState({
            isUploadingLoading: false,
        });

        this.onClose();
    };

    private readonly onSubmit = (event : React.FormEvent) => {
        event.preventDefault();
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading || this.state.isUploadingLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onRiverFileSelected = (riverImage : File) => {
        this.setState({
            riverImage,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading, river } = this.props;
        const { open, isUploadingLoading, riverImageProgress } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading || isUploadingLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='river-image-edit-dialog-title'
                    aria-describedby='river-image-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit River Image
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading || isUploadingLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdr flx1 hfill'>
                            <div className={'fdc flx1 ais jcc m5'}>
                                <FileDropZone fileName={!river?.RiverImageName ? '' : river.RiverImageName}
                                    path={!river?.RiverImageName ? '' : `river/${river.WRNumber}`}
                                    onFileSelected={this.onRiverFileSelected} />
                                <div className='wfill mnh4'>
                                    {
                                        !!riverImageProgress &&
                                        <LinearProgress variant='determinate' value={riverImageProgress} />
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading || isUploadingLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading || isUploadingLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>);
    };
}

import { TablePagination } from '@material-ui/core';
import { FieldProps } from 'formik';
import React from 'react';
import { createSelector } from 'reselect';
import { ILPUBudgetValueFormValue } from '../../../../@types/model/transformer/budget';
import { ITransformerCompany } from '../../../../@types/model/transformer/transformer';

interface IBudgetTablePaginationFieldProps extends FieldProps {
    hideDetail ?: boolean;

    company : ITransformerCompany | null;
    area : string | null;
    transformer : string | null;
    accountNumber : string | null;
    period : number | null;

    rowsPerPageOptions : Array<number>;
    currentPage : number;
    rowsPerPage : number;

    onPageChange : (event : React.MouseEvent<HTMLButtonElement> | null, page : number) => void;
    onRowsPerPageChange : (event ?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface IBudgetTablePaginationFieldState {}

export default class BudgetTablePaginationField extends React.PureComponent<IBudgetTablePaginationFieldProps, IBudgetTablePaginationFieldState> {
    constructor(props : IBudgetTablePaginationFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly getForm = (props : IBudgetTablePaginationFieldProps) => props.form;
    public readonly getCompany = (props : IBudgetTablePaginationFieldProps) => props.company;
    public readonly getArea = (props : IBudgetTablePaginationFieldProps) => props.area;
    public readonly getTransformer = (props : IBudgetTablePaginationFieldProps) => props.transformer;
    public readonly getAccountNumber = (props : IBudgetTablePaginationFieldProps) => props.accountNumber;
    public readonly getPeriod = (props : IBudgetTablePaginationFieldProps) => props.period;

    public readonly getValues = createSelector(
        [this.getForm],
        (form) => {
            return form.values.values as Array<ILPUBudgetValueFormValue>;
        },
    );

    public readonly getCount = createSelector(
        [
            this.getValues,
            this.getCompany,
            this.getArea,
            this.getTransformer,
            this.getAccountNumber,
            this.getPeriod,
        ],
        (
            values,
            company,
            area,
            transformer,
            accountNumber,
            period,
        ) => {
            return values
                .filter(x => !company || x.transformer.Company?.id === company.id)
                .filter(x => !area || x.transformer.Division === area)
                .filter(x => !transformer || x.transformer.EPNumber === transformer)
                .filter(x => !accountNumber || x.transformer.AccountNumber === accountNumber)
                .filter(x => !period || x.month === period)
                .length;
        },
    );

    public readonly render = () => {
        const { rowsPerPageOptions, rowsPerPage, currentPage, onPageChange, onRowsPerPageChange } = this.props;

        const count = this.getCount(this.props);
        return (
            <TablePagination
                component='div'
                rowsPerPageOptions={rowsPerPageOptions}
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        );
    };
}

import { Button, Card, Typography } from '@material-ui/core';
import { Field, FieldArray, FieldProps } from 'formik';
import React from 'react';
import { BoreholeCalibrationStages, BoreholeCalibrationStagesType, IBoreholeCalibrationStageFormValues } from '../../../../../@types/model/borehole/boreholeCalibration';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import BoreholeCalibrationAchieveStepEntryFormField from '../../../../customComponents/form/boreholeCalibration/BoreholeCalibrationAchieveStepEntryFormField';
import BoreholeCalibrationStepEntryFormField from '../../../../customComponents/form/boreholeCalibration/BoreholeCalibrationStepEntryFormField';
import BoreholeCalibrationStepFormField from '../../../../customComponents/form/boreholeCalibration/BoreholeCalibrationStepFormField';
import TextFormField from '../../../../customComponents/form/TextFormField';

interface IBoreholeCalibrationEditDialogStepsTabProps {
    isLoading : boolean;
    isValid ?: boolean;

    onSubmitClick ?: () => void;
    onBackClick ?: () => void;
}

interface IBoreholeCalibrationEditDialogStepsTabState {
    currentStep : BoreholeCalibrationStagesType | 5 | 6 | 7;
}

export default class BoreholeCalibrationEditDialogStepsTab extends React.PureComponent<IBoreholeCalibrationEditDialogStepsTabProps, IBoreholeCalibrationEditDialogStepsTabState> {
    constructor(props : IBoreholeCalibrationEditDialogStepsTabProps) {
        super(props);
        this.state = {
            currentStep: 0,
        };
    }

    private readonly onStepClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            currentStep: Number(event.currentTarget.value) as BoreholeCalibrationStagesType,
        });
    };

    public readonly render = () => {
        const { currentStep } = this.state;
        const { onSubmitClick, onBackClick, isValid, isLoading } = this.props;
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <Card className='fdr aic jcc p15 dbco'>
                    <Typography className='flx1 aic jcc fs18 fwb cw'>
                        CALIBRATION TEST
                    </Typography>
                </Card>
                <div className='fdr mt15'>
                    {
                        BoreholeCalibrationStages.map((step) => (
                            <div key={step} className={'mr15'}>
                                <Button
                                    value={step}
                                    variant='contained'
                                    onClick={this.onStepClick}
                                    color={currentStep === step ? 'primary' : 'secondary'}
                                >
                                    Step {step + 1}
                                </Button>
                            </div>
                        ))
                    }
                    <div className={'mr15'}>
                        <Button
                            value={5}
                            variant='contained'
                            onClick={this.onStepClick}
                            color={currentStep === 5 ? 'primary' : 'secondary'}
                        >
                            RECOVERY
                        </Button>
                    </div>
                    <div className={'mr15'}>
                        <Button
                            value={6}
                            variant='contained'
                            onClick={this.onStepClick}
                            color={currentStep === 6 ? 'primary' : 'secondary'}
                        >
                            Achieved
                        </Button>
                    </div>
                    <div className={''}>
                        <Button
                            value={7}
                            variant='contained'
                            onClick={this.onStepClick}
                            color={currentStep === 7 ? 'primary' : 'secondary'}
                        >
                            Notes
                        </Button>
                    </div>
                </div>
                {
                    currentStep < 5 &&
                    <div className='fdc'>
                        <Field 
                            name={`stages.${currentStep}`}
                        >
                            {
                                (params : FieldProps<IBoreholeCalibrationStageFormValues>) => (
                                    <BoreholeCalibrationStepFormField {...params}
                                    />
                                )
                            }
                        </Field>
                    </div>
                }
                {
                    currentStep === 5 &&
                    <div className='fdc'>
                        <FieldArray
                            name={'recoveryEntries'}
                            render={(props) => (
                                <BoreholeCalibrationStepEntryFormField {...props}
                                    recovery
                                />)}
                        />
                    </div>
                }
                {
                    currentStep === 6 &&
                    <div className='fdc'>
                        <FieldArray
                            name={'achieved'}
                            render={(props) => (
                                <BoreholeCalibrationAchieveStepEntryFormField {...props} />)}
                        />
                    </div>
                }
                {
                    currentStep === 7 &&
                    <div className='fdc mt12'>
                        <TextFormField
                            autoFocus
                            id={'note'}
                            name={'note'}
                            fullWidth
                            required
                            multiline
                            disabled={isLoading}
                            inputProps={{
                                className: 'cp fwb',
                            }}
                        />
                    </div>
                }
                {
                    currentStep === 7 &&
                    <div className='fdr jcfe mt15'>
                        <div className='mr15'>
                            <OutlinedStadiumButton disabled={isLoading} onClick={onBackClick}>
                                Back
                            </OutlinedStadiumButton>
                        </div>
                        <div>
                            <StadiumButton disabled={!isValid || isLoading} onClick={onSubmitClick}>
                                Submit
                            </StadiumButton>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

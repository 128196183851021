import { Icon, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import UsageToggleButton from '../../button/UsageToggleButton';

export interface ISPUMeterReading {
    itemNumber : number;
    meterNumber : string;
    meterReading : string;
    usage : number;
    numberOfDigits : number;
    image : File | null;
    rollover : boolean;
}

interface ISPUMeterReadingInputProps {
    value : ISPUMeterReading;
    readingGuid : string;

    onChange ?: (value : ISPUMeterReading) => void;

    exists ?: boolean;
}

interface ISPUMeterReadingInputState {}

export default class SPUMeterReadingInput extends React.PureComponent<ISPUMeterReadingInputProps, ISPUMeterReadingInputState> {
    constructor(props : ISPUMeterReadingInputProps) {
        super(props);
        this.state = {};
    }

    private readonly onMeterReadingChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        if (event.currentTarget.value.length > this.props.value.numberOfDigits) return;

        const reading = { ...this.props.value };

        reading.meterReading = event.currentTarget.value;

        if (this.props.onChange) this.props.onChange(reading);
    };

    private readonly onFileChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files?.length) return;
        const file = event.currentTarget.files[0];
        const reading = { ...this.props.value };
        reading.image = new File([file.slice()], `${this.props.readingGuid}_${file.name}`, {
            type: file.type,
        });

        if (!this.props.onChange) return;
        
        this.props.onChange({
            ...reading,
        });
    };

    private readonly onRolloverToggle = () => {
        const reading = { ...this.props.value };

        reading.rollover = !reading.rollover;

        if (this.props.onChange) this.props.onChange(reading);
    };

    public readonly render = () => {
        const { value, exists } = this.props;
        return (
            <div className='fdc'>
                <div className='fdr jcc'>
                    <div className='fdc flx1 aifs pr5'>
                        <FormControl fullWidth required>
                            <TextField
                                autoComplete='off'
                                id='meter'
                                label='Meter'
                                value={value.meterNumber}
                                margin='normal'
                                required
                                disabled
                                className={'TextField'}
                            />
                        </FormControl>
                    </div>
                    <div className='fdc flx1 aifs pl5'>
                        <FormControl fullWidth required error={value.meterReading.length > value.numberOfDigits}>
                            <TextField
                                autoComplete='off'
                                required
                                id='meterReading'
                                type='number'
                                label='Meter Reading'
                                value={value.meterReading}
                                onChange={this.onMeterReadingChange}
                                margin='normal'
                                helperText={`${value.meterReading.length}/${value.numberOfDigits}`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            kWh
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                    {
                        !!value.usage &&
                        <div className='fdc flx1 aifs pl5'>
                            <FormControl fullWidth required>
                                <TextField
                                    autoComplete='off'
                                    id='usage'
                                    label='Usage'
                                    value={value.usage}
                                    margin='normal'
                                    disabled
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                kWh
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </div>
                    }
                    <div className='fdc flx1 aic jcc pl5'>
                        <UsageToggleButton text='Rollover?' value='Rollover?' selected={value.rollover}
                            onClick={this.onRolloverToggle}
                        />
                    </div>
                    <div className='fdc flx1 aic jcc pl5'>
                        <input multiple={false} onChange={this.onFileChange} type='file' />
                    </div>
                </div>
                {
                    !!exists &&
                    <div className='fdr mb5 aic'>
                        <Icon className='cy mr10'>
                            warning
                        </Icon>
                        <Typography className='fs12 fwb'>
                            Reading already captured for selected month. 
                        </Typography>
                    </div>
                }
            </div>
        );
    };
}

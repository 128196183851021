import { AppBar, Toolbar, Typography,Paper } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditPumpingStationDivision from '../dialog/EditDivisionDialog';

interface IPumpingStationDivisionProps {
    pumpingStation : IPumpingStation;
    isLoading : boolean;
    session : IUserSession;
}

interface IPumpingStationDivisionState {
}

export default class PumpingStationDivision extends React.PureComponent<IPumpingStationDivisionProps, IPumpingStationDivisionState> {
    constructor(props : IPumpingStationDivisionProps) {
        super(props);
        this.state = {
        };
    }

    public render = () => {
        const { pumpingStation, session } = this.props;
        return (
            <div className='fdr  jcc bcg0 flx1 aifs'>
                <span className={'flx1'}></span>
                <Paper className='mt20 fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography variant='h6' className='flx1 cw'>
                                DIVISION INFO
                            </Typography>
                            <span className={'flx1'} />
                            {
                                session.employee.IsPumpingStationAdmin &&
                                <EditPumpingStationDivision pumpingStation={pumpingStation} transition={Transitions.Up} maxWidth='md' fullWidth />
                            }
                        </Toolbar>
                    </AppBar>
                    <div className={'fdr pt30 pb30 jcsb'}>
                        <div className={'fdc jcc aic flx1'}>
                            <Typography className={'fs12 cg3'}>
                                Division
                            </Typography>
                            <Typography className={'fs16 fw500 cg3'}>
                                { pumpingStation.division ?? 'None'}
                            </Typography>
                        </div>
                        <div className={'fdr jcc aic flx1'}>
                            <div className={'fdc flx1 aic jcc'}>
                                <Typography className={'fs12 cg3'}>
                                    Management Area
                                </Typography>
                                <Typography className={'fs16 fw500 cg3 '}>
                                    { pumpingStation.managementArea ?? 'None' }
                                </Typography>
                            </div>
                        </div>
                        <div className={'fdc jcc aic flx1'}>
                            <Typography className={'fs12 cg3'}>
                                Division Number
                            </Typography>
                            <Typography className={'fs16 fw500 cg3'}>
                                { pumpingStation.divisionNo ?? 'None'}
                            </Typography>
                        </div>
                        <div className={'fdr jcc aic flx1'}>
                            <div className={'fdc flx1 aic jcc'}>
                                <Typography className={'fs12 cg3'}>
                                    Accpac Description
                                </Typography>
                                <Typography className={'fs16 fw500 cg3'}>
                                    { pumpingStation.accpacDesc ?? 'None' }
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Paper>
                <span className={'flx1'}></span>
            </div>
        );
    }
}

import firebase from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';

export interface IBoreholeSensorAudit {
    Id : string;
    Ref : firebase.firestore.DocumentReference;

    Guid : string;
    BoreholeCode : string;
    Borehole : firebase.firestore.DocumentReference;

    HasLevelSensor : boolean;
    HasFlowMeter : boolean;

    LevelSensorType : string;
    LevelSensorBar : string;
    LevelSensorOut : string;
    LevelSensorSupply : string;
    LevelSensorCableLength : number;
    LevelSensorSerialNr : string;
    LevelSensorFileName : string;

    FlowMeterModel : string;
    FlowMeterType : string;
    FlowMeterSerialNr : string;
    FlowMeterFileName : string;
    FlowMeterPipeDiameter : number;
    FlowMeterEV : string;
    FlowMeterOutput : string;

    CreatedBy : firebase.firestore.DocumentReference;
    CreatedByEmployee : string;
    CreatedByName : string;
    CreatedOn : firebase.firestore.Timestamp;

    UpdatedBy : firebase.firestore.DocumentReference;
    UpdatedByEmployee : string;
    UpdatedByName : string;
    UpdatedOn : firebase.firestore.Timestamp;

    IsSent : boolean;
}

export default class BoreholeSensorAuditHelper {
    public static readonly COLLECTION_NAME = 'borehole_sensor_audit';

    private static converter : firebase.firestore.FirestoreDataConverter<IBoreholeSensorAudit> = {
        fromFirestore: (snapshot) => {
            return BoreholeSensorAuditHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBoreholeSensorAudit) : firebase.firestore.DocumentData => {
            const { Ref: _ref, Id: _id, ...firestoreObject } = data;
            return firestoreObject;
        },
    };

    public static fromFirestoreDocument(doc : firebase.firestore.DocumentSnapshot) {
        const data = doc.data() as IBoreholeSensorAudit | undefined;

        if (!data) {
            throw new Error(`Document does not exist! ${doc.id}`);
        }

        const result : IBoreholeSensorAudit = {
            ...data,
            Ref: doc.ref.withConverter(BoreholeSensorAuditHelper.converter),
            Id: doc.id,
        };

        return result;
    }

    public static update(boreholeTest : IBoreholeSensorAudit) {
        return boreholeTest.Ref.set(boreholeTest, {
            merge: true,
        });
    }

    public static delete(id : string) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .delete();
    }

    public static listen(boreholeCode : string) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .where('BoreholeCode', '==', boreholeCode)
            .withConverter(this.converter);
    }
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import { IRootState } from '../../../../@types/redux';
import { BOREHOLE_MAP_IMAGES, BOREHOLE_STATUS } from '../../../../appConstants';
import BoreholeFunctions from '../../../../store/borehole/functions';
import { Transitions } from '../../../customComponents/animations/Transitions';
import EditLocationDialog from '../../../customComponents/dialog/LocationEditDialog';
import CustomMapBox, { IMapMarker } from '../../../customComponents/map/CustomMapBox';

interface IBoreholeLocationInfoViewProps {
    borehole : IBorehole;

    isLoading : boolean;

    isBoreholeAdmin : boolean;
}

interface IBoreholeLocationInfoViewState {}

class BoreholeLocationInfoViewComponent extends React.PureComponent<IBoreholeLocationInfoViewProps, IBoreholeLocationInfoViewState> {
    constructor(props : IBoreholeLocationInfoViewProps) {
        super(props);
        this.state = {};
    }
    private readonly mapZoom = 9;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];

    public readonly getData = (state : IBoreholeLocationInfoViewState, props : IBoreholeLocationInfoViewProps) => props.borehole;

    public readonly getMarker = createSelector(
        [this.getData],
        (borehole) => {
            return {
                coordinates: [borehole.Geom?.longitude ?? 0, borehole.Geom?.latitude ?? 0],
                iconSize: 1,
                icon: BOREHOLE_STATUS[borehole.Status ?? '1'],
                id: borehole.Code,
            } as IMapMarker;
        }
    );
    
    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number) => {
        const borehole = Object.assign({}, this.props.borehole);

        borehole.Geom = geom;
        borehole.Elevation = elevation;

        await BoreholeFunctions.saveBorehole(borehole);
    };

    public readonly render = () => {
        const { isBoreholeAdmin, borehole, isLoading } = this.props;
        const marker = this.getMarker(this.state, this.props);

        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc mxh400 hfill'}>
                    <div className={'fdr dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            LOCATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isBoreholeAdmin &&
                                    <EditLocationDialog
                                        markerImage={BOREHOLE_MAP_IMAGES[BOREHOLE_STATUS[borehole.Status ?? '1']]}
                                        markerImageSize={1}
                                        elevation={borehole.Elevation}
                                        geom={borehole.Geom}
                                        transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                        onSave={this.onLocationSave}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdc ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    LATITUDE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.Geom?.latitude ?? '-'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    LONGITUDE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.Geom?.longitude ?? '-'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    ELEVATION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.Elevation}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {
                        !isLoading &&
                        <div className={'flx1 ais'}>
                            <div className='hfill wfill '>
                                <CustomMapBox
                                    id='borehole_map'                                                                                  
                                    center={this.mapCenter}
                                    zoom={this.mapZoom}
                                    images={BOREHOLE_MAP_IMAGES}
                                    markers={[marker]}
                                    selectedMarker={borehole.Code}
                                />
                            </div>
                        </div>
                    }
                </Card>
            </div>
        );   
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
        isLoading: state.borehole.loading,
    };
};

const BoreholeLocationInfoView = connect(
    mapStateToProps,
)(BoreholeLocationInfoViewComponent);

export default BoreholeLocationInfoView;

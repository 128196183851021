import { Field, FieldProps } from 'formik';
import React from 'react';
import BoreholeCodeSelector, { IBoreholeCodeSelectorProps } from '../../selector/borehole/BoreholeCodeSelector';

interface IBoreholeFormFieldProps extends Omit<IBoreholeCodeSelectorProps, 'onChange' | 'value' | 'isLoading' | 'boreholes'> {
    name : string;

    onChange ?: (value : string, fieldName : string) => void;
}

interface IBoreholeFormFieldState {}

export default class BoreholeFormField extends React.PureComponent<IBoreholeFormFieldProps, IBoreholeFormFieldState> {
    constructor(props : IBoreholeFormFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { name, onChange, ...rest } = this.props;
        return (
            <Field
                name={name}
            >
                {
                    (params : FieldProps<string | null | undefined>) => (
                        <BoreholeCodeSelector
                            {...rest}
                            onChange={(value) => onChange ? onChange(value, params.field.name) : params.form.setFieldValue(params.field.name, value)}
                            value={params.field.value ?? ''}
                            error={params.meta.error}
                        />
                    )
                }
            </Field>
        );
    };
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { VictoryPie } from 'victory';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import { IRootState } from '../../../../@types/redux';
import { Transitions } from '../../../customComponents/animations/Transitions';
import EditBoreholeYield from '../../dialog/EditYield';

interface IBoreholeYieldInfoViewProps {
    borehole : IBorehole;

    isLoading : boolean;

    isBoreholeAdmin : boolean;
}

interface IBoreholeYieldInfoViewState {}

class BoreholeYieldInfoViewComponent extends React.PureComponent<IBoreholeYieldInfoViewProps, IBoreholeYieldInfoViewState> {
    constructor(props : IBoreholeYieldInfoViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { isBoreholeAdmin, borehole, isLoading } = this.props;

        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc mxh400'}>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            YIELD INFORMATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isBoreholeAdmin &&
                                    <EditBoreholeYield
                                        borehole={borehole}
                                        isLoading={isLoading}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdr flx1 ml17 mr17 mb13'}>
                        <div className={'mt9 fdr mr40'}>
                            <Card className={'w350 bcw fdc mr30'}>
                                <div className={'jcc aic bcg wfill dvbg h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        ABSTRACTION RECOMMENDATION
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 bcg0 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        RECOMMENDED ABSTRACTION RATE (m<sup>3</sup>/hr)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.DesignedYield ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        MAXIMUM ABSTRACTION RATE (m<sup>3</sup>/hr)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.PerfomanceYield ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 bcg0 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        PUMPING CYCLE (hr/day)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.PumpingCycle ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        RECOMMENDED MAXIMUM ABSTRACTION (m<sup>3</sup>/day)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.MaximumDailyAbstraction ?? '-'}
                                    </Typography>
                                </div>
                            </Card>
                            <Card className={'w350 bcw fdc '}>
                                <div className={'jcc aic bcg wfill bcb h50'}>
                                    <Typography className={'fs13 fw500 cw vcp'}>
                                        INSTALLED ABSTRACTION
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 bcg0 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        INSTALLED YIELD (m<sup>3</sup>/hr)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.InstalledYield ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        ADJUSTED YIELD (m<sup>3</sup>/hr)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.AdjustedYield ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 bcg0 jcc aic'}>
                                </div>
                                <div className={'fdc flx1 jcc aic'}>
                                </div>
                            </Card>
                            
                        </div>
                        <div className={'fdc mt9 fdr'}>
                            <Typography className={'bbw1 bocGrey fw500'} color='inherit' variant='subtitle1'>
                                LATEST YIELD
                            </Typography>
                            <div className={'posr mxh200 aic jcc mt5'} >
                                <VictoryPie
                                    height={200}
                                    width={200}
                                    standalone={true}
                                    padding={0}
                                    data={[{
                                        x: 1,
                                        y: (borehole.LatestYield ?? 0) / (borehole.InstalledYield ?? 1),
                                    },
                                    {
                                        x: 1,
                                        y: 1 - ((borehole.LatestYield ?? 0) / (borehole.InstalledYield ?? 1)),
                                    }]}
                                    colorScale={['#61B2B5', '#EAEAEA']}
                                    innerRadius={95}
                                    labelRadius={10}
                                    style={{ 
                                        labels: { fontSize: 12, fill: 'transparent' },
                                        
                                    }}
                                />
                                <Typography className={'posa cpo fs10 fw700'}>
                                    {borehole.LatestYield ? borehole.LatestYield.toFixed(2) : '0.00'} m<sup>3</sup>/hr
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
        isLoading: state.borehole.loading,
    };
};

const BoreholeYieldInfoView = connect(
    mapStateToProps,
)(BoreholeYieldInfoViewComponent);

export default BoreholeYieldInfoView;

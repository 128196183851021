import { AccordionDetails, AppBar, CircularProgress, Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import DamHelper, { IDam } from '../../../@types/model/dam/dam';
import DamRegistrationHelper, { IDamRegistration } from '../../../@types/model/dam/damRegistration';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditDamRegistration from '../dialog/registration/EditDialog';
import { Transitions } from '../../customComponents/animations/Transitions';

interface IDamRegistrationProps {
    dam : IDam;
    isLoading : boolean;
    session : IUserSession;
}

interface IDamRegistrationState {
    damRegistrations : Array<IDamRegistration>;
    isLoading : boolean;
}

export default class DamRegistration extends React.PureComponent<IDamRegistrationProps, IDamRegistrationState> {
    private listener ?: () => void;

    constructor(props : IDamRegistrationProps) {
        super(props);
        this.state = {
            isLoading: false,
            damRegistrations: [],
        };
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public componentDidMount() : void {
        this.load();
    }

    public readonly load = () => {
        if (this.listener) this.listener();

        this.setState({
            damRegistrations: [],
            isLoading: true,
        });

        this.listener = DamRegistrationHelper.collection()
            .where('damRef', '==', DamHelper.doc(this.props.dam.id))
            .orderBy('date', 'desc')
            .onSnapshot((snapshot) => {
                if (!this.listener) return;
                this.setState({
                    damRegistrations: snapshot.docs.map(x => x.data()).filter(x => !!x.documentName),
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Registration s');
            }, () => {
                this.listener = undefined;
            });
    };

    private readonly onViewClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const constantRate = this.state.damRegistrations.find(x => x.id === event.currentTarget.value);

        if (!constantRate?.documentURL) return;
        window.open(constantRate.documentURL, '_blank');
    };

    private readonly renderRow = (
        title1 : string,
        title2 : string,
        value1 : string,
        value2 : string,
        odd ?: boolean,
    ) => {
        return (
            <div className='fdr'>
                <div className={`fdc aic jcc flx1 mr10 p10 ${odd ? '' : 'bcg0'}`}>
                    <Typography className='fs11 cdg fwb'>{title1.toLocaleUpperCase()}</Typography>
                    <Typography className={'fs13 cg3 fw700 pt10'}>
                        {value1}
                    </Typography>
                </div>
                <div className={`fdc aic jcc flx1 ml10 p10 ${odd ? '' : 'bcg0'}`}>
                    {
                        title2 &&
                        <>
                            <Typography className='fs11 cdg fwb'>{title2.toLocaleUpperCase()}</Typography>
                            <Typography className={'fs13 cg3 fw700 pt10'}>
                                {value2}
                            </Typography>
                        </>
                    }
                </div>
            </div>
        );
    };

    public readonly render = () => {
        const { damRegistrations, isLoading } = this.state;
        const { dam, session } = this.props;

        return (
            <div className={'fdc flx1 pt20 pb20 pl20 pr20 bcg0 oya'}>
                <div className='fdc aife jcfe'>
                    <EditDamRegistration
                        transition={Transitions.Down}
                        dam={dam}
                    />
                </div>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    damRegistrations.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Files uploaded
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!damRegistrations.length &&
                    <div className='fdc flx1 ais jcfs oya oxh mt12'>
                        {
                            damRegistrations.map(damRegistration => (
                                <SeparatedAccordion key={damRegistration.id}>
                                    <PrimaryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className='fdr jcc aic flx1'>
                                            {
                                                AppFunctionsService.formatDateTimeToDateOnly(damRegistration.date) 
                                            }
                                            <span className='flx1' />
                                            <div className='mr15'>
                                                <Tooltip title='View'>
                                                    <IconButton
                                                        color='inherit'
                                                        value={damRegistration.id}
                                                        onClick={this.onViewClick}
                                                        size='small'
                                                    >
                                                        <Icon>visibility</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            {
                                                session.employee.IsDamAdmin &&
                                                <div>
                                                    <EditDamRegistration
                                                        transition={Transitions.Down}
                                                        dam={dam}
                                                        damRegistration={damRegistration}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </PrimaryAccordionSummary>
                                    <AccordionDetails className={'fdc flx1'}>
                                        <div className='fdr'>
                                            <div className='fdc w804'>
                                                <div className='fdc h1132 wfill'>
                                                    {
                                                        damRegistration.documentURL &&
                                                        <section className='fdc flx1'>
                                                            <div className='fdc flx1'>
                                                                <embed height={'100%'} src={`${damRegistration.documentURL}#toolbar=0&navpanes=0&scrollbar=0`} />
                                                            </div>
                                                        </section>
                                                    }
                                                </div>
                                            </div>
                                            <div className='fdc flx1 ml15'>
                                                <div className={'fdc flx1 mb15'}>
                                                    <div className='fdr jcfs'>
                                                        <AppBar position='static' className='dvblg' elevation={0}>
                                                            <Toolbar variant='dense'>
                                                                <Typography variant='h6' className='flx1 cw'>
                                                                    PDF DOCUMENT - DETAILS
                                                                </Typography>
                                                            </Toolbar>
                                                        </AppBar>
                                                    </div>
                                                    <div className='fdc jcfs pl20 pr20'>
                                                        {
                                                            this.renderRow(
                                                                'NO OF DAM',
                                                                'WARMS DAM ID',
                                                                `${damRegistration.damNumber ?? '-'}`,
                                                                `${damRegistration.warmsDamId ?? '-'}`,
                                                                true
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'NAME OF DAM',
                                                                'WATER MANAGEMENTAREA',
                                                                `${damRegistration.nameOfDam ?? '-'}`,
                                                                `${damRegistration.waterManagementArea ?? '-'}`
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Quaternary Drainage Area',
                                                                '',
                                                                `${damRegistration.quaternaryDrainageArea ?? '-'}`,
                                                                '',
                                                                true,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Latitude',
                                                                'Longitude',
                                                                `${damRegistration.latitude?.toFixed(4) ?? '-'}`,
                                                                `${damRegistration.longitude?.toFixed(4) ?? '-'}`,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'nearest Town',
                                                                'Distance from Town',
                                                                `${damRegistration.nearestTown ?? '-'}`,
                                                                `${damRegistration.distanceFromTown ?? '-'}`,
                                                                true,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Name of farm',
                                                                'District',
                                                                `${damRegistration.nameOfFarm ?? '-'}`,
                                                                `${damRegistration.district ?? '-'}`
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Province',
                                                                'DWS Provincial Office / Region',
                                                                `${damRegistration.province ?? '-'}`,
                                                                `${damRegistration.provinceOffice ?? '-'}`,
                                                                true,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Completion date',
                                                                'Completion date raised',
                                                                `${damRegistration.completionDate?.toMoment().asDateOnly() ?? '-'}`,
                                                                `${damRegistration.completionDateRaised?.toMoment().asDateOnly() ?? '-'}`,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'River or Watercourse',
                                                                'Wall type',
                                                                `${damRegistration.damNumber}`,
                                                                damRegistration.wallType === null ?
                                                                    '-' :
                                                                    DamHelper.DamWallType[damRegistration.wallType],
                                                                true,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Wall height',
                                                                'Crest Length (m)',
                                                                `${damRegistration.wallHeight ?? '-'}`,
                                                                `${damRegistration.crestLength ?? '-'}`,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Spillway Type',
                                                                'Capacity (1000m³)',
                                                                `${damRegistration.spillwayType ?? '-'}`,
                                                                `${damRegistration.capacity ?? '-'}`,
                                                                true,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Surface area (ha)',
                                                                'Catchment area (km²)',
                                                                `${damRegistration.surfaceArea ?? '-'}`,
                                                                `${damRegistration.catchmentArea ?? '-'}`,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Purpose',
                                                                'Owner Name',
                                                                `${damRegistration.purpose ?? '-'}`,
                                                                `${damRegistration.owner ?? '-'}`,
                                                                true,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Designer',
                                                                'Contractor',
                                                                `${damRegistration.designer ?? '-'}`,
                                                                `${damRegistration.contractor ?? '-'}`,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Registration date',
                                                                'Size',
                                                                `${damRegistration.registrationDate?.toMoment().asDateOnly() ?? '-'}`,
                                                                damRegistration.size === null ?
                                                                    '-' :
                                                                    DamHelper.DamSize[damRegistration.size],
                                                                true,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Hazard Potential',
                                                                'Category',
                                                                damRegistration.hazardPotential === null ?
                                                                    '-' :
                                                                    DamHelper.HazardPotential[damRegistration.hazardPotential],
                                                                `${damRegistration.category}`,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Classification date',
                                                                'Sector',
                                                                `${damRegistration.classificationDate?.toMoment().asDateOnly() ?? '-'}`,
                                                                `${damRegistration.sector ?? '-'}`,
                                                                true,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Date Last DSE',
                                                                'Number Last DSE',
                                                                `${damRegistration.lastDSEDate?.toMoment().asDateOnly() ?? '-'}`,
                                                                `${damRegistration.lastDSENumber ?? '-'}`,
                                                            )
                                                        }
                                                        {
                                                            this.renderRow(
                                                                'Target date next DSE',
                                                                '',
                                                                `${damRegistration.nextDSEDate?.toMoment().asDateOnly() ?? '-'}`,
                                                                '',
                                                                true,
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </SeparatedAccordion>
                            ))
                        }
                    </div>
                }
            </div>
        );
    };
}

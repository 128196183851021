import firebase, { firestore } from 'firebase';
import firebaseApp from '../../../../services/firebaseService';
import { IUserSession } from '../../../employee';
import { IRainMeterMeasurement } from './rainMeterMeasurement';
import { IRainMeterMeasurementFormValues } from './rainMeterMeasurementFormValues';
import * as Yup from 'yup';
import uuid from 'uuid';

export default class RainMeterMeasurementModelHelper {
    public static readonly  COLLECTION_NAME = 'rain_meter_measurement';
    /*================================================================================================================
     *                                                  Converters
     * ==============================================================================================================*/
    private static converter : firebase.firestore.FirestoreDataConverter<IRainMeterMeasurement> = {
        fromFirestore: (snapshot) => {
            return RainMeterMeasurementModelHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IRainMeterMeasurement) : firebase.firestore.DocumentData => {
            return RainMeterMeasurementModelHelper.toFirestoreDocument(data);
        },
    };
    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot | firebase.firestore.QueryDocumentSnapshot) : IRainMeterMeasurement {
        const data = snapshot.data() as IRainMeterMeasurement;
        if (!snapshot.exists) throw new Error(`Document does not exist! ${snapshot.id}`);
        
        return {
            ...data,
            ref: snapshot.ref,
        };
    }
    public static toFirestoreDocument(data : IRainMeterMeasurement) {
        return RainMeterMeasurementModelHelper.convertToObject(data);
    }
    public static convertToObject(model : IRainMeterMeasurement) {
        const obj = {
            ...model,
        };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { ref, ...rest } = obj;
        return rest;
    }
    /*================================================================================================================
     *                                                Handler Methods
     * ==============================================================================================================*/
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(RainMeterMeasurementModelHelper.COLLECTION_NAME)
            .withConverter(RainMeterMeasurementModelHelper.converter);
    }
    public static async save(entry : IRainMeterMeasurement) : Promise<void> {
        if (entry.ref) {
            RainMeterMeasurementModelHelper
                .collection()
                .doc(entry.ref.id)
                .set(entry, { merge: true });
        } else {
            RainMeterMeasurementModelHelper
                .collection()
                .add(entry);
        }
    }

    /*================================================================================================================
     *                                                  Form Values
     * ==============================================================================================================*/

    public static createFormValues = (rainMeterRef : firestore.DocumentReference, existing ?: IRainMeterMeasurement | null) : IRainMeterMeasurementFormValues => {
        if (existing) {
            return {
                guid: existing.guid,
                rainMeterRef: existing.rainMeterRef,
                value: existing.value,
                capturedDate: existing.capturedDate,
                isActive: existing.isActive,
            };
        } else {
            return {
                guid: uuid.v4(),
                rainMeterRef: rainMeterRef,
                value: 0,
                capturedDate: firestore.Timestamp.now(),
                isActive: true,
            };
        }
    };

    public static createInactiveSave = (user : IUserSession, entry : IRainMeterMeasurement) : IRainMeterMeasurement => {        
        return {
            ...entry,
            updatedByEmployee: user.employee.EmployeeNumber ?? '',
            updatedByName: user.employee.Name,
            updatedBy: firebaseApp
                .firestore()
                .collection('employee')
                .doc(user.firebaseUser.uid),

            updatedOn: firebase.firestore.Timestamp.now(),
            isActive: false,
        }
    };

    public static createSave = (user : IUserSession, formValues : IRainMeterMeasurementFormValues, existingEntry ?: IRainMeterMeasurement | null) : IRainMeterMeasurement => {         
        if (existingEntry) {
            return {
                ...existingEntry,
                value: formValues.value,
                rainMeterRef: formValues.rainMeterRef,
                updatedByEmployee: user.employee.EmployeeNumber ?? '',
                updatedByName: user.employee.Name,
                updatedBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                updatedOn: firebase.firestore.Timestamp.now(),
                isActive: formValues.isActive
            };

        } else {
            return {
                guid: formValues.guid,
                rainMeterRef: formValues.rainMeterRef,
                value: formValues.value,
                capturedDate: formValues.capturedDate,
                createdByEmployee: user.employee.EmployeeNumber ?? '',
                createdByName: user.employee.Name,
                createdOn: firebase.firestore.Timestamp.now(),
                createdBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                updatedByEmployee: user.employee.EmployeeNumber ?? '',
                updatedByName: user.employee.Name,
                updatedBy: firebaseApp.firestore().collection('employee').doc(user.firebaseUser.uid),
                updatedOn: firebase.firestore.Timestamp.now(),
                isActive: true,
            };
        }
    };

    /*================================================================================================================
     *                                                  Schemas
     * ==============================================================================================================*/

    public static formSchema = () : Yup.ObjectSchema<IRainMeterMeasurementFormValues> => Yup.object({
        guid: Yup.string().required('Required'), 
        rainMeterRef: Yup.mixed<firestore.DocumentReference>().required('Required'),
        capturedDate: Yup.mixed<firestore.Timestamp>().required('Required'),
        value: Yup.number().typeError('Enter a positive number').positive().required('Required'),
        isActive: Yup.boolean().required('Required'), 
    });
}
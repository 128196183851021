import { Icon, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IUserSession } from '../../../@types/employee';
import { IBoreholeAgesRecommendation } from '../../../@types/model/borehole/boreholeAgesRecommendation';
import { IRootState } from '../../../@types/redux';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditBoreholeAgesRecommendation from '../dialog/agesRecommendation/EditDialog';

interface IAgesRecommendationEntryViewProps {
    session ?: IUserSession | null;
    recommendation : IBoreholeAgesRecommendation;
}

interface IAgesRecommendationEntryViewState {}

class AgesRecommendationEntryViewComponent extends React.PureComponent<IAgesRecommendationEntryViewProps, IAgesRecommendationEntryViewState> {
    constructor(props : IAgesRecommendationEntryViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { recommendation, session } = this.props;
        return (
            <div className='fdr p5'>
                <div className='fdc w804'>
                    <div className={'jcfs aic bcg wfill dvbg h50'}>
                        <Typography className={'ml15 fs14 fw500 cw '}>
                            PDF DOCUMENT
                        </Typography>
                        <span className='flx1' />
                        {
                            recommendation.url &&
                            <IconButton href={recommendation.url} download target={'_blank'} rel='noreferrer'>
                                <Icon className='cw'>download</Icon>
                            </IconButton>
                        }
                    </div>
                    <div className='fdc h1132 wfill'>
                        {
                            recommendation.url &&
                            <embed height={'100%'} src={`${recommendation.url}#toolbar=0&navpanes=0&scrollbar=0`} />
                        }
                    </div>
                </div>
                <span className='w10' />
                <div className='fdc flx1'>
                    <div className={'jcfs aic bcg wfill dvblg h50'}>
                        <Typography className={'ml15 fs14 fw500 cw '}>
                            PDF DOCUMENT - DETAILS
                        </Typography>
                        <span className='flx1' />
                        {
                            session?.employee.IsBoreholeAdmin &&
                            <EditBoreholeAgesRecommendation
                                boreholeAgesRecommendation={recommendation}
                                fullWidth
                                maxWidth='md'
                                transition={Transitions.Down}
                            />
                        }
                        <span className='ml15' />
                    </div>
                    <div className='fdr flx1'>
                        <div className='fdc flx1'>
                            <div className={'jcc aic bcg wfill bclg h50'}>
                                <Typography className={'fs14 fw500 cp'}>
                                    ABSTRACTION RECOMMENDATION
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    RECOMMENDED ABSTRACTION RATE (m<sup>3</sup>/hr)
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.designedYield ?? '-'}
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 bcg0 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    MAXIMUM ABSTRACTION RATE (m<sup>3</sup>/hr)
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.perfomanceYield ?? '-'}
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    PUMPING CYCLE (hr/day)
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.pumpingCycle ?? '-'}
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 bcg0 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    RECOMMENDED MAXIMUM ABSTRACTION (m<sup>3</sup>/day)
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.maximumDailyAbstraction ?? '-'}
                                </Typography>
                            </div>
                        </div>
                        <span className='w10' />
                        <div className='fdc flx1'>
                            <div className={'jcc aic bcg wfill bclg h50'}>
                                <Typography className={'fs14 fw500 cp'}>
                                    RECOMMENDED INSTALLED DEPTH (m)
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    RECOMMENDED INSTALLED DEPTH
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.installDepthRecommendation ?? '-'}
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 bcg0 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    BOREHOLE DEPTH (m)
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.holeDepth ?? '-'}
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    DYNAMIC WATER LEVEL (m)
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.dynamicWaterLevelLimit ?? '-'}
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 bcg0 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    STATIC WATER LEVEL (m)
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.staticWaterLevel ?? '-'}
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    CRITICAL WATER LEVEL (m)
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.criticalWaterLevel ?? '-'}
                                </Typography>
                            </div>
                            <div className={'fdc pb20 pt20 bcg0 jcc aic'}>
                                <Typography className={'fs13 fw500 cp mb12'}>
                                    GROUNDWATER ZONE
                                </Typography>
                                <Typography className={'fs15 fw600 cb'}>
                                    {recommendation.groundwaterZone?.Name ?? '-'}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const AgesRecommendationEntryView = connect(
    mapStateToProps,
)(AgesRecommendationEntryViewComponent);

export default AgesRecommendationEntryView;
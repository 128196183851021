import React from 'react';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import MaterialTable from '../../customComponents/materialTable/Table';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import TransformerReadingHelper, { IMeterReadingsView, ITransformerReading } from '../../../@types/model/transformer/transformerReading';
import generalFunctions from '../../../store/general/functions';
import lodash from 'lodash';
import appFunctionsService from '../../../services/appFunctionsService';
import TransformerSPUReadingInfoDialog from './dialog/SPUMeterReadingInfoDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import TransformerMeterReadingProblem from '../../customComponents/TransformerMeterReadingProblem';
import Tooltip from '@material-ui/core/Tooltip';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { EmployeeHelper, IUserSession } from '../../../@types/employee';
import { firestore } from 'firebase/app';
import SPUMeterCreateDialog from './dialog/SPUMeterReadingCreate';
import TransformerSPUReadingDeleteDialog from './dialog/SPUMeterReadingDeleteDialog';
import { Warning } from '@material-ui/icons';
import moment from 'moment';
import { Fab, Icon, IconButton } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { CSVLinkProps } from '../../../@types/csv';
import DateRangePicker from '../../customComponents/input/DateRangePicker';
import TransformerSPUMeterReportDialog from '../dialog/SPUMeterReport';
import SPUApproveDialog from './dialog/SPUApproveDialog';

interface ITransformerSPUMeterListProps {
    isTransformerAdmin : boolean;
    isTransformerSpuTester : boolean;

    session ?: IUserSession | null;

    transformers : Array<ITransformer>;
}

interface ITransformerSPUMeterListState {
    transformerNumber ?: string;
    readings : Array<ITransformerReading>;
    isLoading : boolean;
    dateFrom : moment.Moment | null;
    dateTo : moment.Moment | null;
    onlyErrors : boolean;

    selectedRows : Array<IMeterReadingsView>;
    showApproveDialog : boolean;
    showDisapproveDialog : boolean;
}

class TransformerSPUMeterListClass extends React.Component<ITransformerSPUMeterListProps, ITransformerSPUMeterListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    private readingListener ?: () => void;
    constructor(props : ITransformerSPUMeterListProps) {
        super(props);

        this.state = {
            readings: [],
            isLoading: false,
            dateFrom: moment.utc().startOf('month').subtract(1, 'months'),
            dateTo: moment.utc().startOf('day'),
            onlyErrors: false,
            selectedRows: [],
            showApproveDialog: false,
            showDisapproveDialog: false,
        };
        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    private readonly loadData = async () => {
        if (this.readingListener) this.readingListener();

        this.setState({
            isLoading: true,
        });

        const session = this.props.session;

        if (!session) return;

        this.readingListener = TransformerReadingHelper.listen().onSnapshot((snap) => {
            const readings = this.state.readings.slice();

            snap.docChanges().forEach((f) => {
                const item = f.doc.data();
                if (
                    !session.employee.IsTransformerAdmin &&
                    item.Division &&
                    session.employee.Areas.length &&
                    !session.employee.Areas.includes(item.Division)) return;

                const index = lodash.findIndex(readings, n => n.ref.id === item.ref.id);

                switch (f.type) {
                    case 'added':
                        readings.push(item);
                        break;
                    case 'modified':
                        readings.splice(index, 1, item);
                        break;
                    case 'removed':
                        readings.splice(index, 1);
                        break;
                }
            });

            this.setState({
                readings,
                isLoading: false,
            });
        }, (err) => {
            generalFunctions.generalShowErrorSnackbar(err.message);
        });
    };

    private readonly getTransformers = (props : ITransformerSPUMeterListProps) => props.transformers;
    private readonly getData = (props : ITransformerSPUMeterListProps, state : ITransformerSPUMeterListState) => state.readings;
    private readonly getTransformerNumber = (props : ITransformerSPUMeterListProps, state : ITransformerSPUMeterListState) => state.transformerNumber;
    private readonly getFromDate = (props : ITransformerSPUMeterListProps, state : ITransformerSPUMeterListState) => state.dateFrom;
    private readonly getToDate = (props : ITransformerSPUMeterListProps, state : ITransformerSPUMeterListState) => state.dateTo;
    private readonly getOnlyErrors = (props : ITransformerSPUMeterListProps, state : ITransformerSPUMeterListState) => state.onlyErrors;

    public readonly getMeterReadingView = createSelector(
        [
            this.getTransformers,
            this.getData,
            this.getTransformerNumber,
        ],
        (transformers, readings, transformerNumber) => {
            const result : Array<IMeterReadingsView> = lodash.chain(readings)
                .filter(n => !transformerNumber || n.Electricalpoint.id === transformerNumber)
                .flatMap(n => n.TransformerMeterNumberReadings.map(reading => ({
                    ...reading,
                    Guid: n.GUID,
                    ItemNumber: reading.ItemNumber,
                    Date: n.Date?.toMillis() ?? n.CreatedOn.toMillis(),
                    Conditions: n.Conditions,
                    EPNumber: n.Electricalpoint.id,
                    CreatedByName: n.CreatedByName,
                    UpdatedByName: n.UpdatedByName,
                    CreatedByEmployee: n.CreatedByEmployee,
                    UpdatedByEmployee: n.UpdatedByEmployee,
                    Division: n.Division,
                    Group: n.Group,
                    Rollover: reading.Rollover,
                    CreatedBy: n.CreatedBy.id,
                    UpdatedBy: n.UpdatedBy.id,
                    isWeb: n.isWeb,
                    SubArea: transformers.find(x => x.EPNumber === n.Electricalpoint.id)?.SubArea,
                    PoleNumber: transformers.find(x => x.EPNumber === n.Electricalpoint.id)?.PoleNumber ?? undefined,
                    AccountNumber: transformers.find(x => x.EPNumber === n.Electricalpoint.id)?.AccountNumber,
                    Approved: reading.Approved,
                    ApprovedOn: reading.ApprovedOn,
                    ApprovedBy: reading.ApprovedBy,
                    ApprovedByName: reading.ApprovedByName,
                    ApprovedByEmployee: reading.ApprovedByEmployee,
                } as IMeterReadingsView)))
                .value();

            return result;
        },
    );

    public readonly getMappedData = createSelector(
        [
            this.getMeterReadingView,
            this.getFromDate,
            this.getToDate,
        ],
        (readings, fromDate, toDate) => {
            const result : Array<IMeterReadingsView> = lodash.chain(readings)
                .filter(n => !fromDate || n.Date >= fromDate.valueOf())
                .filter(n => !toDate || n.Date <= toDate.valueOf())
                .map((reading, i, all) => ({
                    ...reading,
                    Error: this.hasError(reading, lodash.find(all, x =>
                        x.EPNumber === reading.EPNumber &&
                        x.Guid !== reading.Guid &&
                        moment.utc(x.Date).format('MM-YYYY') === moment.utc(reading.Date).format('MM-YYYY')
                    )),
                }))
                .sort((a, b) => b.Date - a.Date)
                .value();

            return result;
        },
    );

    public readonly getFilteredData = createSelector(
        [
            this.getMappedData,
            this.getOnlyErrors,
        ],
        (readings, onlyErrors) => {
            const result : Array<IMeterReadingsView> = lodash.chain(readings)
                .filter(n => !onlyErrors || !!n.Error)
                .sort((a, b) => b.Date - a.Date)
                .value();

            return result;
        },
    );

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            transformerNumber: value?.EPNumber,
        });
    };

    private readonly hasError = (reading : IMeterReadingsView, other ?: IMeterReadingsView | null) => {
        const errors : Array<string> = [];

        if (reading.Usage < 0) {
            errors.push('Transformer reading has a negative usage');
        }

        if (other) {
            errors.push('Reading already captured for selected month');
        }

        return errors.join(', ');
    };

    private readonly onSave = (meterReading : IMeterReadingsView) => {
        const session = this.props.session;

        if (!session) return;
        
        const readingIndex = this.state.readings.findIndex(x => x.GUID === meterReading.Guid);

        if (readingIndex < 0) return;

        const reading = this.state.readings[readingIndex];
        const meterIndex = reading.TransformerMeterNumberReadings.findIndex(x => x.ItemNumber === meterReading.ItemNumber);

        if (meterIndex < 0) return;

        const update = {
            ...reading,
            TransformerMeterNumberReadings: [
                ...reading.TransformerMeterNumberReadings,
            ],
        };

        update.TransformerMeterNumberReadings[meterIndex].Reading = meterReading.Reading;
        update.TransformerMeterNumberReadings[meterIndex].Rollover = meterReading.Rollover;
        update.TransformerMeterNumberReadings[meterIndex].ImageFileName = meterReading.ImageFileName;
        update.Conditions = [ ...meterReading.Conditions ];
        update.Date = firestore.Timestamp.fromMillis(meterReading.Date.valueOf());
        update.UpdatedBy = EmployeeHelper.doc(session.firebaseUser.uid);
        update.UpdatedByName = session.firebaseUser.displayName ?? '';
        update.UpdatedByEmployee = session.employee.EmployeeNumber ?? '';
        update.UpdatedOn = firestore.Timestamp.now();

        TransformerReadingHelper.update(update);
    };

    private readonly onDelete = async (meterReading : IMeterReadingsView) => {
        const reading = lodash
            .chain(this.state.readings)
            .filter(x => x.Electricalpoint.id === meterReading.EPNumber)
            .sortBy(x => x.Date?.toMillis() ?? x.CreatedOn.toMillis())
            .find(x => x.GUID === meterReading.Guid)
            .value();

        await TransformerReadingHelper.delete(reading);
    };
    
    private readonly onDateSelectionChange = (start : moment.Moment | null, end : moment.Moment | null) => {
        this.setState({
            dateFrom: !start ? null : moment(start),
            dateTo: !end ? null : moment(end),
        });
    };

    private readonly onShowOnlyErrorClick = () => {
        this.setState({
            onlyErrors: !this.state.onlyErrors,
        });
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (transformers) => {
            return transformers.map(n => ({
                EPNumber: n.EPNumber,
                Date: appFunctionsService.formatDateTime(n.Date),
                Division: n.Division,
                Group: n.Group,
                Meter: n.MeterNumber,
                Reading: n.Reading,
                LastReading: n.Rollover ?
                    n.Reading - n.Usage + Number(new Array(n.NumberOfDigits).fill('9').join('')) :
                    n.Reading - n.Usage,
                Usage: n.Usage,
                CreatedByName: n.CreatedByName,
                CreatedByEmployee: n.CreatedByEmployee,
                Problems: n.Conditions.join('; '),
                SubArea: n.SubArea,
                AccountNumber: n.AccountNumber,
                Approved: n.Approved,
                ApprovedOn: !n.ApprovedOn ? '' : appFunctionsService.formatDateTime(n.ApprovedOn),
                ApprovedBy: n.ApprovedBy,
                ApprovedByName: n.ApprovedByName,
                ApprovedByEmployee: n.ApprovedByEmployee,
            }));
        },
    );

    public readonly onSelectionChanged = (selectedRows : Array<IMeterReadingsView>) => {
        this.setState({
            selectedRows,
        });
    };

    private readonly onApproveSelectedClick = () => {
        this.setState({
            showApproveDialog: true,
        });
    };

    private readonly onApproveClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const readings = this.getFilteredData(this.props, this.state);
        const reading = readings.find(x => x.Guid === event.currentTarget.value);

        if (reading) {
            this.setState({
                selectedRows: [reading],
                showApproveDialog: true,
            });
        }
    };

    private readonly onDisapproveSelectedClick = () => {
        this.setState({
            showDisapproveDialog: true,
        });
    };

    private readonly onDisapproveClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const readings = this.getFilteredData(this.props, this.state);
        const reading = readings.find(x => x.Guid === event.currentTarget.value);
        
        if (reading) {
            this.setState({
                selectedRows: [reading],
                showDisapproveDialog: true,
            });
        }
    };

    private readonly onApproveClose = (result : boolean) => {
        if (result) {
            this.setState({
                selectedRows: [],
            });
        }
        this.setState({
            showApproveDialog: false,
        });
    };

    private readonly onDisapproveClose = (result : boolean) => {
        if (result) {
            this.setState({
                selectedRows: [],
            });
        }
        this.setState({
            showDisapproveDialog: false,
        });
    };

    public readonly render = () => {
        const {
            transformerNumber,
            isLoading,
            dateFrom,
            dateTo,
            onlyErrors,
            selectedRows,
            showApproveDialog,
            showDisapproveDialog,
        } = this.state;
        const { isTransformerAdmin, isTransformerSpuTester, session } = this.props;
        const meterView = this.getMeterReadingView(this.props, this.state);
        const readings = this.getFilteredData(this.props, this.state);

        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <Toolbar>
                    <span className={'flx1'} />
                    <div style={{ paddingRight: '15px' }}>
                        {
                            !!selectedRows.length &&
                            !!selectedRows.some(n => !n.Approved) &&
                            isTransformerSpuTester &&
                            <Tooltip title='Approve Selected'>
                                <Fab color='primary' size='small' onClick={this.onApproveSelectedClick}>
                                    <Icon>
                                        check
                                    </Icon>
                                </Fab>
                            </Tooltip>
                        }
                    </div>
                    <div style={{ paddingRight: '15px' }}>
                        {
                            !!selectedRows.length &&
                            !!selectedRows.some(n => n.Approved) &&
                            isTransformerSpuTester &&
                            <Tooltip title='Disapprove Selected'>
                                <Fab color='primary' size='small' onClick={this.onDisapproveSelectedClick}>
                                    <Icon>
                                        close
                                    </Icon>
                                </Fab>
                            </Tooltip>
                        }
                    </div>
                    <div style={{ paddingRight: '15px' }}>
                        <TransformerSPUMeterReportDialog fullScreen readings={meterView} />
                    </div>
                    <div className='pr20'>
                        <Tooltip title='Show only Errors'>
                            <Fab color={onlyErrors ? 'primary' : 'default'} size='small' onClick={this.onShowOnlyErrorClick}>
                                <Icon className='fs20'>
                                    warning
                                </Icon>
                            </Fab>
                        </Tooltip>
                    </div>
                    <div className={'aic p5 mb10 w350'}>
                        <DateRangePicker onChange={this.onDateSelectionChange} start={dateFrom} end={dateTo} />
                    </div>
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className='fdc flx1 w240'>
                            <TransformerDropdown
                                type='spu'
                                value={transformerNumber}
                                label='Select Transformer'
                                fullWidth
                                onChange={this.onEPNumberChange}
                            />
                        </div>
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<IMeterReadingsView>
                        id='transformerReadingsTable'
                        data={readings}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        onSelectionChanged={this.onSelectionChanged}
                        onExportCsvClick={this.onCSVClick}
                        selectedRows={selectedRows}
                        selectable={isTransformerSpuTester}
                        columns={[{
                            header: '',
                            field: 'EPNumber',
                            renderCell: row => (
                                <div className=' fdr aic'>
                                    <TransformerSPUReadingInfoDialog key={`${row.Guid}_${row.ItemNumber}_edit`} fullWidth
                                        maxWidth='md' reading={row} transition={Transitions.Down}
                                        enabled={(isTransformerAdmin || !row.Approved)}
                                        onSave={this.onSave}
                                    />
                                    {
                                        (!row.Approved) &&
                                        <TransformerSPUReadingDeleteDialog key={`${row.Guid}_${row.ItemNumber}_delete`} fullWidth
                                            maxWidth='md' reading={row} transition={Transitions.Down}
                                            onDelete={this.onDelete}
                                        />

                                    }
                                    {
                                        !!row.isWeb &&
                                        <Icon className='cp ml10'>
                                            laptop
                                        </Icon>                                            
                                    }
                                    {
                                        !row.isWeb &&
                                        <Icon className='cp ml10'>
                                            smartphone
                                        </Icon>                                            
                                    }
                                    {
                                        !!row.Error &&
                                        <Tooltip title={row.Error}>
                                            <Warning className={'cy pl10'}/>
                                        </Tooltip>
                                    }
                                </div>
                            ),
                        }, {
                            header: 'Date',
                            field: 'Date',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (<span>{appFunctionsService.formatDateTimeToDateOnly(row.Date)}</span>),
                        }, {
                            header: 'EP Point',
                            field: 'EPNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Pole Nr',
                            field: 'PoleNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Area',
                            field: 'Division',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Group',
                            field: 'Group',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Meter',
                            field: 'MeterNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Meter Reading (kWh)',
                            field: 'Reading',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Last Reading (kWh)',
                            field: 'Usage',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <span>
                                    {
                                        row.Rollover ?
                                            row.Reading - row.Usage + Number(new Array(row.NumberOfDigits).fill('9').join('')) :
                                            row.Reading - row.Usage
                                    }
                                </span>),
                        }, {
                            header: 'Usage',
                            field: 'Usage',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Created By',
                            field: 'CreatedByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.CreatedByEmployee ?? ''}>
                                    <div>
                                        {
                                            row.CreatedByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }, {
                            header: 'Problems',
                            field: 'Conditions',
                            renderCell: row => (<span className='fdr cw'>{row.Conditions.map((n, i) => (<TransformerMeterReadingProblem key={`${i}_${row.EPNumber}_problem`} text={n} />))}</span>),
                        }, {
                            header: 'APPROVE',
                            width: '175px',
                            renderCell: row => (
                                <div>
                                    {
                                        !row.Approved &&
                                        <Tooltip title='Approve'>
                                            <IconButton value={row.Guid} onClick={this.onApproveClick}>
                                                <Icon className={'cpd'}>
                                                    check_circle
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        !!row.Approved && !!row.ApprovedOn &&
                                        <Tooltip title={`${appFunctionsService.formatDateTime(row.ApprovedOn)}`}>
                                            <span>{row.ApprovedByName}</span>
                                        </Tooltip>
                                    }
                                    {
                                        !!row.Approved && !!row.ApprovedOn &&
                                        <Tooltip title='Disapprove'>
                                            <IconButton value={row.Guid} onClick={this.onDisapproveClick}>
                                                <Icon className={'cr'}>
                                                    close
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            ),
                        }]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <SPUMeterCreateDialog aria-label='add' maxWidth='md' fullWidth session={session} readings={readings} />
                </div>
                <CSVLink
                    data={csvData}
                    headers={[
                        {
                            key: 'EPNumber',
                            label: 'EP Number',
                        },
                        {
                            key: 'Date',
                            label: 'Date',
                        },
                        {
                            key: 'Division',
                            label: 'Area',
                        },
                        {
                            key: 'SubArea',
                            label: 'Sub Area',
                        },
                        {
                            key: 'Group',
                            label: 'Group',
                        },
                        {
                            key: 'AccountNumber',
                            label: 'Account Nr',
                        },
                        {
                            key: 'Meter',
                            label: 'Meter',
                        },
                        {
                            key: 'Reading',
                            label: 'Reading',
                        },
                        {
                            key: 'LastReading',
                            label: 'LastReading',
                        },
                        {
                            key: 'Usage',
                            label: 'Usage',
                        },
                        {
                            key: 'CreatedByName',
                            label: 'CreatedByName',
                        },
                        {
                            key: 'CreatedByEmployee',
                            label: 'CreatedByEmployee',
                        },
                        {
                            key: 'Problems',
                            label: 'Problems',
                        },
                        {
                            key: 'Approved',
                            label: 'Approved',
                        },
                        {
                            key: 'ApprovedByName',
                            label: 'Approved By Name',
                        },
                        {
                            key: 'ApprovedByEmployee',
                            label: 'Approved By Employee',
                        },
                        {
                            key: 'ApprovedOn',
                            label: 'Approved On',
                        },
                    ]}
                    filename={`${csvName}_spu_tansformers.csv`}
                    className='dn'
                    ref={this.csvLink}
                    target='_blank'/>

                {
                    session &&
                    <SPUApproveDialog
                        maxWidth='md'
                        fullWidth
                        open={showApproveDialog}
                        onClose={this.onApproveClose}
                        values={selectedRows}
                        title='Approve Readings'
                        readings={this.state.readings}
                        session={session}
                    />
                }
                {
                    session &&
                    <SPUApproveDialog
                        maxWidth='md'
                        fullWidth
                        disapprove
                        open={showDisapproveDialog}
                        onClose={this.onDisapproveClose}
                        values={selectedRows}
                        title='Disapprove Readings'
                        readings={this.state.readings}
                        session={session}
                    />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isTransformerAdmin: !!state.auth.session?.employee.IsTransformerAdmin,
        isTransformerSpuTester: !!state.auth.session?.employee.IsTransformerSpuTester,
        session: state.auth.session,
        transformers: state.transformer.transformers,
    };
};

const TransformerSPUMeterList = connect(
    mapStateToProps,
)(TransformerSPUMeterListClass);

export default TransformerSPUMeterList;
import { firestore } from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';

export enum EnumFlowmeterWaterUsage {
    irrigation,
    waterSupply,
    irrigationAndWaterSupply,
    externalStakeholders,
    other,
    fertigation,
    'in-fieldIrrigation',
    'non production',
}

export enum EnumFlowmeterMonitor {
    agriotec,
    manual,
    mottech,
    other,
}

export interface IFlowmeter extends IBasicBaseClass {
    guid : string;
    code : string;
    name : string;

    division : string | null;
    managementArea : string | null;
    divisionNo : string | null;
    accpacDesc : string | null;

    elevation : number | null;
    geo : firebase.firestore.GeoPoint | null;

    connected : boolean;

    currentMeter : string | null;
    monitor : string | null;

    lastReading : number | null;
    lastReadingDate : number | null;

    cumulativeFlowMonth : number | null;
    cumulativeFlowWeek : number | null;
    lastFlowWeek : number | null;

    isActive : boolean;
    isSent : boolean;

    waterUsageType : EnumFlowmeterWaterUsage | null;
    waterUsageTypeDescription : string | null;

    monitorType : EnumFlowmeterMonitor | null;
    monitorTypeDescription : string | null;
}

export default class FlowmeterHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'flowmeter';

    private static readonly converter : firebase.firestore.FirestoreDataConverter<IFlowmeter> = {
        fromFirestore: (snapshot) => {
            return FlowmeterHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IFlowmeter) : firebase.firestore.DocumentData => {
            return FlowmeterHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IFlowmeter {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IFlowmeter,
            ...result,
            lastReadingDate: (data['lastReadingDate'] as firebase.firestore.Timestamp | null)?.toMillis() ?? null,
        };
    }

    public static toFirestoreDocument(data : IFlowmeter) {
        const result = super.toFirestore(data);
        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
            lastReadingDate: !rest.lastReadingDate ? null : firestore.Timestamp.fromMillis(rest.lastReadingDate),
        };
    }

    public static readonly EnumFlowMeterWaterUsageToString : Record<EnumFlowmeterWaterUsage, string> = {
        [EnumFlowmeterWaterUsage.irrigation]: 'Irrigation',
        [EnumFlowmeterWaterUsage.waterSupply]: 'Water Supply',
        [EnumFlowmeterWaterUsage.irrigationAndWaterSupply]: 'Irrigation and Water Supply',
        [EnumFlowmeterWaterUsage.externalStakeholders]: 'External Stakeholders',
        [EnumFlowmeterWaterUsage.other]: 'Other',
        [EnumFlowmeterWaterUsage.fertigation]: 'Fertigation',
        [EnumFlowmeterWaterUsage['in-fieldIrrigation']]: 'In-field Irrigation',
        [EnumFlowmeterWaterUsage['non production']]: 'Non production',
    };

    public static readonly EnumFlowMeterMonitorToString : Record<EnumFlowmeterMonitor, string> = {
        [EnumFlowmeterMonitor.agriotec]: 'Agriotec',
        [EnumFlowmeterMonitor.manual]: 'Manual',
        [EnumFlowmeterMonitor.mottech]: 'Mottech',
        [EnumFlowmeterMonitor.other]: 'Other',
    };

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(FlowmeterHelper.COLLECTION_NAME).withConverter(FlowmeterHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(FlowmeterHelper.COLLECTION_NAME).withConverter(FlowmeterHelper.converter).doc(id);
    }

    public static save(flowmeter : IFlowmeter) {
        return FlowmeterHelper.doc(flowmeter.id).set(flowmeter, {
            merge: true,
        });
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(FlowmeterHelper.COLLECTION_NAME)
            .withConverter(FlowmeterHelper.converter);
    }

    public static mapToCsv(stations : Array<IFlowmeter>) {
        return stations.map(station => ({
            code: station.code,
            name: station.name,
            division: station.division,
            managementArea: station.managementArea,
        }));
    }
}
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import StandardFab from '../../../customComponents/button/StandardFab';
import { DialogActions, Divider, Typography } from '@material-ui/core';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { ITransformer } from '../../../../@types/model/transformer/transformer';
import TransformerDropdown from '../../../customComponents/selector/transformer/TransformerSelector';
import MultiFileUploadProgress from '../../../customComponents/file/MultiFileUploadProgress';
import { createSelector } from 'reselect';
import TransformerFunctions from '../../../../store/transformer/functions';
import uuid from 'uuid';
import { IUserSession } from '../../../../@types/employee';
import { firestore } from 'firebase/app';
import moment from 'moment';
import generalFunctions from '../../../../store/general/functions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../../../appConstants';
import TransformerSPUMeterProblemSelector from '../../../customComponents/selector/transformer/SPUMeterProblemSelector';
import SPUMeterReadingInput, { ISPUMeterReading } from '../../../customComponents/input/transformer/SPUMeterReadingInput';
import TransformerMunicipalityReadingHelper, { ITransformerMunicipalityReading } from '../../../../@types/model/transformer/transformerMunicipalityReading';

interface IMunicipalityMeterCreateDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;


    session ?: IUserSession | null;
}

interface IMunicipalityMeterCreateDialogState {
    open : boolean;

    transformer : ITransformer | null;

    date : moment.Moment;
    readings : Array<ISPUMeterReading>;
    guid : string;
    conditions : Array<string>;

    isLoading : boolean;
    isUploading : boolean;
}

export default class MunicipalityMeterCreateDialog extends React.PureComponent<IMunicipalityMeterCreateDialogProps, IMunicipalityMeterCreateDialogState> {
    constructor(props : IMunicipalityMeterCreateDialogProps) {
        super(props);
        this.state = {
            open: false,
            transformer: null,
            date: moment.utc().startOf('day'),
            guid: '',
            readings: [],
            conditions: [],
            isLoading: false,
            isUploading: false,
        };
    }

    private readonly onClick = () => {
        this.setState({
            open: true,
            date: moment.utc().startOf('day'),
            transformer: null,
            guid: uuid.v4(),
            readings: [],
            conditions: [],
            isUploading: false,
        });
    };

    private readonly onClose = () => {
        this.setState({
            open: false,
            isUploading: false,
            isLoading: false,
        });
    };

    private readonly onSubmit = (event : React.FormEvent) => {
        event.preventDefault();
        this.save();
    };

    private readonly save = async () => {
        const { readings, transformer, guid, conditions, date } = this.state;
        const { session } = this.props;

        if (!session || !transformer) return;
        if (readings.some(n => !Number(n.meterReading))) return;

        const reading : ITransformerMunicipalityReading = {
            id: '',
            guid: guid,
            conditions: conditions,
            transformerRef: transformer.ref.id,
            geom: new firestore.GeoPoint(0, 0),
            isWeb: true,
            transformerMeterNumberReadings: readings.map(n => ({
                imageFileName: n.image ? n.image.name : null,
                imageFileUrl: null,
                itemNumber: n.itemNumber,
                meterNumber: n.meterNumber,
                numberOfDigits: n.numberOfDigits,
                reading: Number(n.meterReading),
                recordingFileName: '',
                usage: n.usage,
                division: transformer.Division,
                group: transformer.EPGroup ?? '',
                rollover: n.rollover,
            })),
            date: date.valueOf(),
            division: transformer.Division,
            group: transformer.EPGroup ?? '',
            subArea: transformer.SubArea,
            createdBy: session.firebaseUser.uid,
            createdByName: session.firebaseUser.displayName ?? '',
            createdByEmployee: session.employee.EmployeeNumber ?? '',
            createdOn: firestore.Timestamp.now().toMillis(),
            updatedBy: session.firebaseUser.uid,
            updatedByName: session.firebaseUser.displayName ?? '',
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedOn: firestore.Timestamp.now().toMillis(),
        };

        try {
    
            this.setState({
                isLoading: true,
            });

            await TransformerMunicipalityReadingHelper.save(reading);
    
            this.setState({
                isUploading: true,
            });

        } catch (ex) {
            if (ex instanceof Error) generalFunctions.generalShowErrorSnackbar(ex.message ? ex.message : 'Error Creating Reading');
    
            this.setState({
                isLoading: false,
            });
        }
    };

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            transformer: value ?? null,
            readings: value?.TransformerMeterNumbers?.map((meterNumber) => ({
                image: null,
                itemNumber: meterNumber.ItemNumber,
                meterNumber: meterNumber.Number,
                numberOfDigits: meterNumber.NumberOfDigits,
                meterReading: '',
                usage: 0,
                rollover: false,
            })) ?? [],
        });
    };

    private readonly onConditionChange = (values : Array<string>) => {
        this.setState({
            conditions: values.slice(),
        });
    };

    private readonly onMeterReadingChange = (reading : ISPUMeterReading) => {
        const readings = this.state.readings.slice();

        const index = readings.findIndex(x => x.itemNumber === reading.itemNumber);

        readings.splice(index, 1, {
            ...reading,
        });

        this.setState({
            readings,
        });
    };

    private readonly onFileUpload = (file : File) => {
        const { transformer, guid, readings } = this.state;
        if (!transformer) return null;

        const reading = readings.find(x => x.image?.name === file.name);
        if (!reading) return null;
        
        return TransformerFunctions.uploadMunicipalityReadingFile(transformer.ref.id, file, guid, reading.itemNumber);
    };

    private readonly onDateChange = (date : moment.Moment | null) => {
        if (!date) return;
        this.setState({
            date: date.startOf('day').asUTC(),
        });
    };

    private readonly onDone = () => {
        this.onClose();
    };

    private readonly getData = (props : IMunicipalityMeterCreateDialogProps, state : IMunicipalityMeterCreateDialogState) => state.readings;
    public readonly getFiles = createSelector(
        [this.getData],
        (readings,) => {
            const result : Array<File> = [];
            
            readings.forEach(n => {
                if (n.image) result.push(n.image);
            });

            return result;
        },
    );

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen } = this.props;
        const { open, transformer, conditions, readings, isUploading, isLoading, guid } = this.state;

        const files = this.getFiles(this.props, this.state);

        return (
            <>
                <Tooltip title='Create'>
                    <div>
                        <StandardFab color='primary' onClick={this.onClick} aria-label='tooltip'>
                            <Icon>add</Icon>
                        </StandardFab>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='municipality-create-dialog-title'
                    aria-describedby='municipality-create-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Create Municipality Reading
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading || isUploading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form onSubmit={this.onSubmit}>
                        <DialogContent className='fdc flx1 hfill bcg0'>
                            {
                                !isUploading &&
                                <div className='fdr'>
                                    <div className='fdc flx1 pr5 w240'>
                                        <TransformerDropdown
                                            value={transformer?.EPNumber}
                                            label='Select Transformer'
                                            type='municipality'
                                            fullWidth
                                            onChange={this.onEPNumberChange}
                                            required
                                            activeOnly
                                        />
                                    </div>
                                    <div className='fdc flx1 pr5 w240'>
                                        <KeyboardDatePicker
                                            value={this.state.date}
                                            label='Date'
                                            type='municipality'
                                            format={DATE_FORMAT_DEFAULT_NO_TIME}
                                            fullWidth
                                            onChange={this.onDateChange}
                                            required
                                        />
                                    </div>
                                </div>
                            }
                            {
                                !isUploading &&
                                <div className='fdr ais mt15'>
                                    <div className='fdc flx1 ais pr5'>
                                        <div className='fdc mb10'>
                                            <Typography className='fs16 lh37 cg3'>
                                                Readings
                                            </Typography>
                                        </div>
                                        <div className='fdc'>
                                            {
                                                readings.map((n) => (
                                                    <SPUMeterReadingInput
                                                        readingGuid={guid}
                                                        key={n.itemNumber}
                                                        value={n}
                                                        onChange={this.onMeterReadingChange}
                                                    />
                                                ))
                                            }
                                        </div>
                                        <Divider />
                                    </div>
                                </div>
                            }
                            {
                                !isUploading &&
                                <div className='fdr ais mt15'>
                                    <div className='fdc flx1 ais pr5'>
                                        <div className='fdc mb10'>
                                            <Typography className='fs16 lh37 cg3'>
                                                Problems
                                            </Typography>
                                        </div>
                                        <div className='fdr ais mb10'>
                                            <TransformerSPUMeterProblemSelector values={conditions} onChange={this.onConditionChange} />
                                        </div>
                                        <Divider />
                                    </div>
                                </div>
                            }
                            {
                                isUploading &&
                                <MultiFileUploadProgress files={files} onFileUpload={this.onFileUpload} onDone={this.onDone} />
                            }
                        </DialogContent>
                        <DialogActions className='bcg0'>
                            <OutlinedStadiumButton disabled={isLoading || isUploading} className='mr16' variant='text' type='reset' onClick={this.onClose}>
                                CANCEL
                            </OutlinedStadiumButton>
                            <StadiumButton variant='contained' disabled={isLoading || isUploading} type='submit'>
                                CONFIRM
                            </StadiumButton>
                        </DialogActions>
                    </form>
                </Dialog>
            </>
        );
    };
}

import { Tabs, withStyles } from '@material-ui/core';

const PrimaryTabs = withStyles({
    root: {
    },
    indicator: {
        backgroundColor: '#FEDD31',
    },
})(Tabs);

export default PrimaryTabs;

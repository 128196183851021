import { Star, StarHalf, StarOutline } from '@material-ui/icons';
import React from 'react';
import { createSelector } from 'reselect';

interface IStarRatingProps {
    rating : number;

    size : number;

    onClick ?: (rating : number) => void;
}


export default class StarRating extends React.PureComponent<IStarRatingProps> {
    constructor(props : IStarRatingProps) {
        super(props);
    }

    public readonly getSize = (props : IStarRatingProps) => props.size;
    public readonly getRatings = createSelector(
        [this.getSize],
        (size) => {
            return Array.from({length: size}, (_, i) => i + 1);
        },
    );

    public readonly onClick = (event : React.MouseEvent<HTMLDivElement>) => {
        if (!this.props.onClick) return;
        
        this.props.onClick(Number(event.currentTarget.id));
    };
    
    public readonly render = () => {
        const { rating, onClick } = this.props;
        const ratings = this.getRatings(this.props);
        return (
            <div className='fdr aic jcc'>
                {
                    ratings.map(x => (
                        <div
                            id={x.toFixed()}
                            onClick={this.onClick}
                            key={x.toFixed()}
                            className={`fdc aic jcc ${!onClick ? '' : 'curp'}`}
                        >
                            {
                                x > rating &&
                                (x - 0.5) !== rating &&
                                <StarOutline className='cdg' />
                            }
                            {
                                x <= rating &&
                                <Star className='cy'/>
                            }
                            {
                                (x - 0.5) === rating &&
                                <StarHalf className='cy'/>
                            }
                        </div>
                    ))
                }
            </div>
        );
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { IGroundWaterZone } from '../../../../@types/model';
import dataFunctions from '../../../../store/data/functions';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';

interface IBoreholeGroundwaterZoneSelectorProps {
    value : IGroundWaterZone | null;

    onChange : (value : IGroundWaterZone | null) => void;

    zones : Array<IGroundWaterZone>;
    isLoading : boolean;

    disabled ?: boolean;
    required ?: boolean;
}

interface IBoreholeGroundwaterZoneSelectorState {}

class BoreholeGroundwaterZoneSelectorComponent extends React.Component<IBoreholeGroundwaterZoneSelectorProps, IBoreholeGroundwaterZoneSelectorState> {
    constructor(props : IBoreholeGroundwaterZoneSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        dataFunctions.getGroundwaterZones();
    };

    private readonly onChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        const zone = this.props.zones.slice().find(n => n.Code === event.target.value);
        this.props.onChange(zone ?? null);
    };

    public readonly render = () => {
        const { value, isLoading, required, disabled } = this.props;

        return (
            <FormControl margin='normal' required={required} fullWidth>
                <InputLabel shrink={!!value?.Code} htmlFor='value'>Groundwater Zone</InputLabel>
                <Select
                    fullWidth
                    required={required}
                    disabled={isLoading || disabled}
                    id='value'
                    value={value?.Code ?? ''}
                    onChange={this.onChange}
                >
                    {
                        !required &&
                        <MenuItem key={'None'} value={'None'}>
                            None
                        </MenuItem>
                    }
                    {
                        lodash.map(this.props.zones, n => (
                            <MenuItem key={`${n.Code}_zone`} value={`${n.Code}`}>
                                {n.Name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        zones: state.data.groundwaterZones,
        isLoading: state.data.loadingGroundwaterZones,
    };
};

const BoreholeGroundwaterZoneSelector = connect(
    mapStateToProps,
)(BoreholeGroundwaterZoneSelectorComponent);

export default BoreholeGroundwaterZoneSelector;

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { BOREHOLE_INSTALLATION_UPVC_TYPES } from '../../../appConstants';
import { createSelector } from 'reselect';

interface IUVPCSelectorProps {
    value : string;
    column : string;
    columnValue ?: number;

    onChange : (value : string) => void;
    onColumnChange : (value : string) => void;
    onColumnValueChange ?: (value : number) => void;

    disabled ?: boolean;
    noColumnValue ?: boolean;
}

interface IUVPCSelectorState {}

export default class UVPCSelector extends React.Component<IUVPCSelectorProps, IUVPCSelectorState> {
    constructor(props : IUVPCSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidUpdate = (prevProps : Readonly<IUVPCSelectorProps>) => {
        if (prevProps.value !== this.props.value) {
            this.props.onColumnChange('');
        }
        if (prevProps.column !== this.props.column) {
            if (this.props.onColumnValueChange) this.props.onColumnValueChange(0);
        }
    };

    private readonly onChange = (event : React.ChangeEvent<{ name ?: string | undefined; value : unknown }>) => {
        if (typeof event.target.value !== 'string') return;
        this.props.onChange(event.target.value);
    };

    private readonly onColumnChange = (event : React.ChangeEvent<{ name ?: string | undefined; value : unknown }>) => {
        if (typeof event.target.value !== 'string') return;
        this.props.onColumnChange(event.target.value);
    };

    private readonly onColumnValueChange = (event : React.ChangeEvent<{ name ?: string | undefined; value : unknown }>) => {
        if (this.props.onColumnValueChange) this.props.onColumnValueChange(Number(event.target.value));
    };

    public readonly getData = (state : IUVPCSelectorState, props : IUVPCSelectorProps) => props.value;
    public readonly getColumn = (state : IUVPCSelectorState, props : IUVPCSelectorProps) => props.column;
    public readonly getColumns = createSelector(
        [this.getData],
        (value) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            return value && BOREHOLE_INSTALLATION_UPVC_TYPES[value] ? Object.assign({}, BOREHOLE_INSTALLATION_UPVC_TYPES[value]) : {};
        },
    );
    public readonly getColumnValues = createSelector(
        [this.getData, this.getColumn],
        (value, column) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            return value && column && BOREHOLE_INSTALLATION_UPVC_TYPES[value]?.[column] ? BOREHOLE_INSTALLATION_UPVC_TYPES[value][column].slice() : [];
        },
    );

    public readonly render = () => {
        const { value, column, columnValue, disabled, noColumnValue } = this.props;

        const columns = this.getColumns(this.state, this.props);
        const columnValues = this.getColumnValues(this.state, this.props);

        return (<React.Fragment>
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!value} htmlFor='uPVC'>uPVC Type</InputLabel>
                    <Select
                        disabled={disabled}
                        fullWidth
                        id='value'
                        value={!value ? '' : value}
                        onChange={this.onChange}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(BOREHOLE_INSTALLATION_UPVC_TYPES, (n, i) => (
                                <MenuItem key={`${i}_uPVC`} value={i}>
                                    {i}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!column} htmlFor='column'>uPVC Column Type</InputLabel>
                    <Select
                        disabled={disabled}
                        fullWidth
                        id='column'
                        value={!column ? '' : column}
                        onChange={this.onColumnChange}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(columns, (n, i) => (
                                <MenuItem key={`${i}_uPVC`} value={i}>
                                    {i}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
            {
                !noColumnValue &&
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!columnValue} htmlFor='columnValue'>uPVC Column</InputLabel>
                    <Select
                        disabled={disabled}
                        fullWidth
                        id='columnValue'
                        value={!columnValue ? '' : columnValue}
                        onChange={this.onColumnValueChange}>
                        <MenuItem value={0}>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(columnValues, n => (
                                <MenuItem key={`${n}_uPVC`} value={n}>
                                    {n}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
            }
        </React.Fragment>);
    };
}

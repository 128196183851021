import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { BOREHOLE_INSTALLATION_TEST_VALVES } from '../../../appConstants';
import lodash from 'lodash';
import InputAdornment from '@material-ui/core/InputAdornment';

interface IEditBoreholeInstallationInfoProps {
    borehole : IBorehole;

    onChange : (borehole : IBorehole) => void;
}

interface IEditBoreholeInstallationInfoState {
}

export default class EditBoreholeInstallationInfo extends React.Component<IEditBoreholeInstallationInfoProps, IEditBoreholeInstallationInfoState> {
    constructor(props : IEditBoreholeInstallationInfoProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onTestValveChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.props.onChange({
            ...this.props.borehole,
            TestValve: Number(event.target.value),
        });
    };

    private readonly onCasingDiameterChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.props.onChange({
            ...this.props.borehole,
            CasingDiameter: Number(event.currentTarget.value),
        });
    };

    private readonly onCasingHeightChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.props.onChange({
            ...this.props.borehole,
            CasingHeight: Number(event.currentTarget.value),
        });
    };

    private readonly onConcreteBlockHeightChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.props.onChange({
            ...this.props.borehole,
            ConcreteBlockHeight: Number(event.currentTarget.value),
        });
    };

    private readonly onPiezometerHeightChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.props.onChange({
            ...this.props.borehole,
            PiezometerTubeHeight: Number(event.currentTarget.value),
        });
    };

    private readonly onHoleDepthChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.props.onChange({
            ...this.props.borehole,
            HoleDepth: Number(event.currentTarget.value),
        });
    };

    private readonly onInstallationDepthChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.props.onChange({
            ...this.props.borehole,
            InstallationDepth: Number(event.currentTarget.value),
        });
    };

    public readonly render = () => {
        const { borehole } = this.props;
        return (
            <div className='fdc hfill'>
                <div className={'fdr flx1 aifs'}>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl margin='normal' fullWidth>
                            <InputLabel shrink={!!borehole.TestValve} htmlFor='testValve'>Test Valve</InputLabel>
                            <Select
                                fullWidth
                                id='testValve'
                                value={!borehole.TestValve ? '' : borehole.TestValve}
                                onChange={this.onTestValveChange}>
                                <MenuItem value={0}>
                                    <em>None</em>
                                </MenuItem>
                                {
                                    lodash.map(BOREHOLE_INSTALLATION_TEST_VALVES, (n) => (
                                        <MenuItem key={`${n}_testValve`} value={n}>
                                            {n} mm
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='casingDiameter'
                                label='Casing Diameter'
                                value={borehole.CasingDiameter ?? ''}
                                onChange={this.onCasingDiameterChange}
                                margin='normal'
                                className={'TextField'}
                                error={!!borehole.CasingDiameter && !Number(borehole.CasingDiameter)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            mm
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                borehole.CasingDiameter && !Number(borehole.CasingDiameter) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='casingHeight'
                                label='Casing Height'
                                value={borehole.CasingHeight ?? ''}
                                onChange={this.onCasingHeightChange}
                                margin='normal'
                                className={'TextField'}
                                error={!!borehole.CasingHeight && !Number(borehole.CasingHeight)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            cm
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                borehole.CasingHeight && !Number(borehole.CasingHeight) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </div>
                <div className={'fdr flx1 aifs'}>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='concreteBlockHeight'
                                label='Concrete Block Height'
                                value={borehole.ConcreteBlockHeight ?? ''}
                                onChange={this.onConcreteBlockHeightChange}
                                margin='normal'
                                className={'TextField'}
                                error={!!borehole.ConcreteBlockHeight && !Number(borehole.ConcreteBlockHeight)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            mm
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                borehole.ConcreteBlockHeight && !Number(borehole.ConcreteBlockHeight) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='piezometerHeight'
                                label='Piezometer Tube Height'
                                value={borehole.PiezometerTubeHeight ?? ''}
                                onChange={this.onPiezometerHeightChange}
                                margin='normal'
                                className={'TextField'}
                                error={!!borehole.PiezometerTubeHeight && !Number(borehole.PiezometerTubeHeight)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            mm
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                borehole.PiezometerTubeHeight && !Number(borehole.PiezometerTubeHeight) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='holeDepth'
                                label='Hole Depth'
                                value={borehole.HoleDepth ?? ''}
                                onChange={this.onHoleDepthChange}
                                margin='normal'
                                className={'TextField'}
                                error={!!borehole.HoleDepth && !Number(borehole.HoleDepth)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            m
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                borehole.HoleDepth && !Number(borehole.HoleDepth) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </div>
                <div className={'fdr flx1 aifs'}>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='installationDepth'
                                label='Installation Depth'
                                value={borehole.InstallationDepth ?? ''}
                                onChange={this.onInstallationDepthChange}
                                margin='normal'
                                className={'TextField'}
                                error={!!borehole.InstallationDepth && !Number(borehole.InstallationDepth)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            m
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                borehole.InstallationDepth && !Number(borehole.InstallationDepth) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                    </div>
                    <div className={'flx1 ais p5 mb10 pr20'}>
                    </div>
                </div>
            </div>
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import FlowmeterHelper, { EnumFlowmeterMonitor, EnumFlowmeterWaterUsage, IFlowmeter } from '../../../@types/model/flowmeter/flowmeter';
import FlowmeterFunctions from '../../../store/flowmeter/functions';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import FlowmeterWaterUsageSelector from '../../customComponents/selector/flowmeter/FlowmeterWaterUsageTypeSelector';
import FlowmeterMonitorSelector from '../../customComponents/selector/flowmeter/FlowmeterMonitorTypeSelector';

interface IEditFlowmeterSummaryProps {
    flowmeter : IFlowmeter;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IEditFlowmeterSummaryState {
    open : boolean;
    name : string;
    currentMeter : string;
    connected : boolean;
    monitor : string;

    waterUsageType : EnumFlowmeterWaterUsage | null;
    waterUsageTypeDescription : string | null;

    monitorType : EnumFlowmeterMonitor | null;
    monitorTypeDescription : string | null;
}

export default class EditFlowmeterSummary extends React.Component<IEditFlowmeterSummaryProps, IEditFlowmeterSummaryState> {
    constructor(props : IEditFlowmeterSummaryProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            currentMeter: '',
            connected: false,
            monitor: '',
            waterUsageType: null,
            waterUsageTypeDescription: null,
            monitorType: null,
            monitorTypeDescription: null,
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditFlowmeterSummaryProps, prevState : IEditFlowmeterSummaryState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: '',
                currentMeter: '',
                connected: false,
                monitor: '',
                waterUsageType: null,
                waterUsageTypeDescription: null,
                monitorType: null,
                monitorTypeDescription: null,
            });
        }

        if (!prevState.open && this.state.open) {
            const { flowmeter } = this.props;
            this.setState({
                name: !flowmeter.name ? '' : flowmeter.name,
                currentMeter: !flowmeter.currentMeter ? '' : flowmeter.currentMeter,
                connected: !flowmeter.connected ? false : flowmeter.connected,
                monitor: !flowmeter.monitor ? '' : flowmeter.monitor,
                waterUsageType: flowmeter.waterUsageType ?? null,
                waterUsageTypeDescription: flowmeter.waterUsageTypeDescription ?? null,
                monitorType: flowmeter.monitorType ?? null,
                monitorTypeDescription: flowmeter.monitorTypeDescription ?? null,
            });
        }
    };

    private readonly save = async () => {
        const {
            name,
            currentMeter,
            connected,
            monitor,
            monitorType,
            monitorTypeDescription,
            waterUsageType,
            waterUsageTypeDescription,
        } = this.state;

        const flowmeter = {
            ...this.props.flowmeter,
            name,
            currentMeter,
            connected,
            monitor,
            monitorType,
            monitorTypeDescription,
            waterUsageType,
            waterUsageTypeDescription,
        };

        const result = await FlowmeterFunctions.saveFlowmeter(flowmeter);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            name: event.target.value,
        });
    };

    private readonly onMonitorChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            monitor: event.target.value,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onConnectedChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            connected: checked,
            monitor: '',
        });
    };

    private readonly onWaterUsageTypeChange = (waterUsageType : EnumFlowmeterWaterUsage | null) => {
        this.setState({
            waterUsageType,
            waterUsageTypeDescription: waterUsageType === null ? null : FlowmeterHelper.EnumFlowMeterWaterUsageToString[waterUsageType],
        });

        if (waterUsageType !== null && waterUsageType === EnumFlowmeterWaterUsage.other) {
            this.setState({
                waterUsageTypeDescription: '',
            });
        }
    };

    private readonly onWaterUsageTypeDescriptionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            waterUsageTypeDescription: event.currentTarget.value,
        });
    };

    private readonly onMonitorTypeChange = (monitorType : EnumFlowmeterMonitor | null) => {
        this.setState({
            monitorType,
            monitorTypeDescription: monitorType === null ? null : FlowmeterHelper.EnumFlowMeterMonitorToString[monitorType],
        });

        if (monitorType !== null && monitorType === EnumFlowmeterMonitor.other) {
            this.setState({
                monitorTypeDescription: '',
            });
        }
    };

    private readonly onMonitorTypeDescriptionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            monitorTypeDescription: event.currentTarget.value,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const {
            open,
            name,
            connected,
            monitor,
            monitorType,
            monitorTypeDescription,
            waterUsageType,
            waterUsageTypeDescription,
        } = this.state;

        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='flowmeter-info-info-title'
                    aria-describedby='flowmeter-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Flowmeter Summary Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 ais'}>
                                <div className={'flx1 ais pr10'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='name'
                                            label='Name'
                                            value={name}
                                            onChange={this.onNameChange}
                                            required
                                            error={!name}
                                        />
                                        {
                                            !name &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 aifs pl10 pr10'}>
                                    <FormControl fullWidth>
                                        <FlowmeterWaterUsageSelector
                                            value={waterUsageType}
                                            onChange={this.onWaterUsageTypeChange}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aifs pl10'}>
                                    {
                                        waterUsageType === EnumFlowmeterWaterUsage.other &&
                                        <FormControl fullWidth>
                                            <TextField
                                                autoFocus
                                                autoComplete='off'
                                                id='waterUsageTypeDescription'
                                                label='Water Usage Type Description'
                                                value={waterUsageTypeDescription ?? ''}
                                                onChange={this.onWaterUsageTypeDescriptionChange}
                                                required
                                                error={!waterUsageTypeDescription}
                                                disabled={isLoading}
                                            />
                                            {
                                                !waterUsageTypeDescription &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    }
                                </div>
                            </div>
                            <div className={'fdr flx1 ais mt30'}>
                                <div className={'flx1 aifs pr10'}>
                                    <FormControl fullWidth>
                                        <FlowmeterMonitorSelector
                                            value={monitorType}
                                            onChange={this.onMonitorTypeChange}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aifs pl10 pr10'}>
                                    {
                                        monitorType === EnumFlowmeterMonitor.other &&
                                        <FormControl fullWidth>
                                            <TextField
                                                autoFocus
                                                autoComplete='off'
                                                id='monitorTypeDescription'
                                                label='Monitor Type Description'
                                                value={monitorTypeDescription ?? ''}
                                                onChange={this.onMonitorTypeDescriptionChange}
                                                required
                                                error={!monitorTypeDescription}
                                                disabled={isLoading}
                                            />
                                            {
                                                !monitorTypeDescription &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    }
                                </div>
                                <div className={'flx1 aifs pr10'}>
                                </div>
                            </div>
                            <div className={'fdr flx1 ais mt30'}>
                                <div className='fdc flx1 aic jcc pr10'>
                                    <FormControl>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    value={connected}
                                                    color='primary'
                                                    checked={connected}
                                                    onChange={this.onConnectedChange}
                                                />
                                            }
                                            labelPlacement='start'
                                            label={'Connected to monitoring system?'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aifs pl10'}>
                                    {
                                        connected &&
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete='off'
                                                id='monitor'
                                                label='Monitoring'
                                                value={monitor}
                                                onChange={this.onMonitorChange}
                                                required
                                                error={!monitor}
                                            />
                                            {
                                                !monitor &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    }
                                </div>
                                <div className={'flx1 aifs'}>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

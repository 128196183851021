import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core';
import Card from '@material-ui/core/Card/Card';
import { Formik } from 'formik';
import React from 'react';
import { createSelector } from 'reselect';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import '../../../style/RuntimesOverrideTable.scss';
import OutlinedStadiumButton from '../button/OutlinedStadiumButton';
import StadiumButton from '../button/StadiumButton';
import AbsoluteLinearProgress from '../progress/AbsoluteLinearProgress';

interface IRuntimesOverrideTableProps {
    isLoading : boolean;
    boreholes : Array<IBorehole>;
    save : (borehole : Array<IBorehole>) => Promise<boolean | undefined>;
}

interface IRuntimesOverrideTableState {
    currentPage : number;
    rowsPerPage : number;
    rowsPerPageOptions : Array<number>;
}

export default class RuntimesOverrideTable extends React.PureComponent<IRuntimesOverrideTableProps, IRuntimesOverrideTableState> {
    constructor(props : IRuntimesOverrideTableProps) {
        super(props);
        this.state = {
            currentPage: 0,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 20, 30, 50, 100],
        };
    }

    private readonly onPageChange = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>, page : number) => {
        this.setState({ currentPage: page });
    };

    private readonly onRowsPerPageChange = (event : React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    private save = async (values : Array<IBorehole>) => {
        const { save, boreholes } = this.props;
        const boreholesToUpdate = values
            .filter(x => boreholes.some(y => y.ref.id === x.ref.id && y.RuntimeOverride !== x.RuntimeOverride))
            .map(x => ({ ...x, RuntimeOverride: !x.RuntimeOverride ? undefined : x.RuntimeOverride * 60 }));

        await save(boreholesToUpdate);
    };

    public getBoreholes = (props : IRuntimesOverrideTableProps) => props.boreholes;
    public getRowsPerPage = (props : IRuntimesOverrideTableProps, state : IRuntimesOverrideTableState) => state.rowsPerPage;
    public getCurrentPage = (props : IRuntimesOverrideTableProps, state : IRuntimesOverrideTableState) => state.currentPage;
    public getValues = createSelector(
        [this.getRowsPerPage, this.getCurrentPage, this.getBoreholes],
        (rowsPerPage, currentPage, boreholes) => {
            return boreholes.slice((currentPage * rowsPerPage), (currentPage * rowsPerPage + rowsPerPage));
        },
    );

    public render = () => {
        const { currentPage, rowsPerPage, rowsPerPageOptions } = this.state;
        const { isLoading, boreholes } = this.props;

        return (
            <div className='flx1 fdc mh0 mw0'>
                <Formik
                    enableReinitialize
                    onSubmit={this.save}
                    initialValues={boreholes}
                >
                    {({
                        values,
                        handleChange,
                        handleSubmit,
                        handleReset,
                        isSubmitting,
                        dirty,
                    }) => (
                        <form id='runtimes-override-form' onSubmit={handleSubmit} onReset={handleReset} className='fdc flx1'>
                            {
                                <div className={'hfill wfill fdc mh0 mw0'}>
                                    {
                                        (isSubmitting || isLoading) &&
                                            <div className='fdc ais wfillMinusScroll' style={{
                                                position: 'relative',
                                                top: 56,
                                            }}>
                                                <AbsoluteLinearProgress />
                                            </div>
                                    }
                                    <Card className='fdc flx1'>
                                        <div className='flx1 fdc oxs oys'>
                                            <Table stickyHeader>
                                                <TableHead className='runtimes-override-table-head'>
                                                    <TableRow>
                                                        <TableCell component='th' scope='row' align='left' className='bwn'>
                                                                Code
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' className='bwn'>
                                                                Name
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' className='bwn'>
                                                                Installed Yield
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' className='bwn'>
                                                                Adjusted Yield
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' className='bwn'>
                                                                Latest Yield
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' className='bwn'>
                                                                Total Runtime
                                                        </TableCell>
                                                        <TableCell component='th' scope='row' align='left' className='bwn'>
                                                                Total Runtime Override
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className='runtimes-override-table-body'>
                                                    {this.getValues(this.props, this.state).map((row, index) => (
                                                        <TableRow key={row.ref.id}>
                                                            <TableCell component='td' scope='row' align='left'>
                                                                {row.Code}
                                                            </TableCell>
                                                            <TableCell component='td' className='w150' scope='row' align='left'>
                                                                {row.Name}
                                                            </TableCell>
                                                            <TableCell component='td' scope='row' align='left'>
                                                                {row.InstalledYield}
                                                            </TableCell>
                                                            <TableCell component='td' scope='row' align='left'>
                                                                {row.AdjustedYield}
                                                            </TableCell>
                                                            <TableCell component='td' scope='row' align='left'>
                                                                {row.LatestYield}
                                                            </TableCell>
                                                            <TableCell component='td' scope='row' align='left'>
                                                                {!row.LatestRuntime ? '' : (row.LatestRuntime / 60).toFixed(2)}
                                                            </TableCell>
                                                            <TableCell component='td' scope='row' className='p0' align='left'>
                                                                <input
                                                                    className='h30 bbw1 bct runtimes-override-edit'
                                                                    disabled={isSubmitting}
                                                                    value={values[index + (currentPage * rowsPerPage)]?.RuntimeOverride ?? ''}
                                                                    name={`${index + (currentPage * rowsPerPage)}.RuntimeOverride`}
                                                                    onChange={handleChange}
                                                                    type='number'
                                                                    min='0'
                                                                    step='any'
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                        <div className='fdr aic'>
                                            <span className='flx1'></span>
                                            <TablePagination
                                                component='div'
                                                rowsPerPageOptions={rowsPerPageOptions}
                                                rowsPerPage={rowsPerPage}
                                                count={boreholes.length}
                                                page={currentPage}
                                                onPageChange={this.onPageChange}
                                                onRowsPerPageChange={this.onRowsPerPageChange}
                                            />
                                        </div>
                                    </Card>
                                    <div className='mnh55'>
                                        {
                                            dirty &&
                                            <div className='fdr jcfe aife mt5 mb10 mr20'>
                                                <OutlinedStadiumButton disabled={isSubmitting} className='mr16' variant='text' type='reset' onClick={handleReset}>
                                                    CANCEL
                                                </OutlinedStadiumButton>
                                                <StadiumButton disabled={isSubmitting} type='submit'>
                                                    SAVE
                                                </StadiumButton>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </form>
                    )}
                </Formik>
            </div>
        );
    };
}

import { firestore } from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import DamHelper, { EnumDamSlopeSurfaceProtection, EnumDamWallSurfaceProtection } from './dam';


export interface IDamInspection extends IBasicBaseClass {
    dam : string; // Dam Id
    damCode : string;
    date : number;

    guid : string;
    employeeNumber : string;
    employeeName : string;

    crestSurfaceProtection : EnumDamWallSurfaceProtection | null;
    crestSettlement : string | null;
    crestSettlementLocation : [number, number] | null;
    crestSettlementPhotoFileName : string | null;
    crestSettlementPhotoUrl : string | null;
    crestSettlementPhotoThumbUrl : string | null;
    crestDisturbance : string | null;
    crestDisturbancePhotoFileName : string | null;
    crestDisturbancePhotoUrl : string | null;
    crestDisturbancePhotoThumbUrl : string | null;
    crestErosion : string | null;
    crestErosionLocation : [number, number] | null;
    crestErosionPhotoFileName : string | null;
    crestErosionPhotoUrl : string | null;
    crestErosionPhotoThumbUrl : string | null;
    crestUnwantedVegetation : string | null;
    crestUnwantedVegetationPhotoFileName : string | null;
    crestUnwantedVegetationPhotoUrl : string | null;
    crestUnwantedVegetationPhotoThumbUrl : string | null;
    crestCracks : string | null;
    crestCracksLocation : [number, number] | null;
    crestCracksPhotoFileName : string | null;
    crestCracksPhotoUrl : string | null;
    crestCracksPhotoThumbUrl : string | null;
    crestOther : string | null;
    crestOtherLocation : [number, number] | null;
    crestOtherPhotoFileName : string | null;
    crestOtherPhotoUrl : string | null;
    crestOtherPhotoThumbUrl : string | null;

    upstreamSlopeDisturbance : string | null;
    upstreamSlopeDisturbanceLocation : [number, number] | null;
    upstreamSlopeDisturbancePhotoFileName : string | null;
    upstreamSlopeDisturbancePhotoUrl : string | null;
    upstreamSlopeDisturbancePhotoThumbUrl : string | null;
    upstreamSlopeErosion : string | null;
    upstreamSlopeErosionLocation : [number, number] | null;
    upstreamSlopeErosionPhotoFileName : string | null;
    upstreamSlopeErosionPhotoUrl : string | null;
    upstreamSlopeErosionPhotoThumbUrl : string | null;
    upstreamSlopeSurfaceProtection : EnumDamSlopeSurfaceProtection | null;
    upstreamSlopeSurfaceProtectionLocation : [number, number] | null;
    upstreamSlopeSurfaceProtectionPhotoFileName : string | null;
    upstreamSlopeSurfaceProtectionPhotoUrl : string | null;
    upstreamSlopeSurfaceProtectionPhotoThumbUrl : string | null;
    upstreamSlopeSurfaceProtectionOther : string | null;
    upstreamSlopeUnwantedVegetation : string | null;
    upstreamSlopeUnwantedVegetationPhotoFileName : string | null;
    upstreamSlopeUnwantedVegetationPhotoUrl : string | null;
    upstreamSlopeUnwantedVegetationPhotoThumbUrl : string | null;
    downstreamSlopeBulging : string | null;
    downstreamSlopeBulgingLocation : [number, number] | null;
    downstreamSlopeBulgingPhotoFileName : string | null;
    downstreamSlopeBulgingPhotoUrl : string | null;
    downstreamSlopeBulgingPhotoThumbUrl : string | null;
    downstreamSlopeSettlement : string | null;
    downstreamSlopeSettlementLocation : [number, number] | null;
    downstreamSlopeSettlementPhotoFileName : string | null;
    downstreamSlopeSettlementPhotoUrl : string | null;
    downstreamSlopeSettlementPhotoThumbUrl : string | null;
    downstreamSlopeDisturbance : string | null;
    downstreamSlopeDisturbanceLocation : [number, number] | null;
    downstreamSlopeDisturbancePhotoFileName : string | null;
    downstreamSlopeDisturbancePhotoUrl : string | null;
    downstreamSlopeDisturbancePhotoThumbUrl : string | null;
    downstreamSlopeErosion : string | null;
    downstreamSlopeErosionLocation : [number, number] | null;
    downstreamSlopeErosionPhotoFileName : string | null;
    downstreamSlopeErosionPhotoUrl : string | null;
    downstreamSlopeErosionPhotoThumbUrl : string | null;
    downstreamSlopeSeepage : string | null;
    downstreamSlopeSeepageType : string | null;
    downstreamSlopeSeepageColour : string | null;
    downstreamSlopeSeepageLocation : [number, number] | null;
    downstreamSlopeSeepagePhotoFileName : string | null;
    downstreamSlopeSeepagePhotoUrl : string | null;
    downstreamSlopeSeepagePhotoThumbUrl : string | null;
    downstreamSlopeUnwantedVegetation : string | null;
    downstreamSlopeUnwantedVegetationLocation : [number, number] | null;
    downstreamSlopeUnwantedVegetationPhotoFileName : string | null;
    downstreamSlopeUnwantedVegetationPhotoUrl : string | null;
    downstreamSlopeUnwantedVegetationPhotoThumbUrl : string | null;

    downstreamWallErosion : string | null;
    downstreamWallErosionComment : string | null;
    downstreamWallErosionLocation : [number, number] | null;
    downstreamWallErosionPhotoFileName : string | null;
    downstreamWallErosionPhotoUrl : string | null;
    downstreamWallErosionPhotoThumbUrl : string | null;
    downstreamWallSeepage : string | null;
    downstreamWallSeepageType : string | null;
    downstreamWallSeepageColour : string | null;
    downstreamWallSeepageLocation : [number, number] | null;
    downstreamWallSeepagePhotoFileName : string | null;
    downstreamWallSeepagePhotoUrl : string | null;
    downstreamWallSeepagePhotoThumbUrl : string | null;
    downstreamWallUnwantedVegetation : string | null;
    downstreamWallUnwantedVegetationLocation : [number, number] | null;
    downstreamWallUnwantedVegetationPhotoFileName : string | null;
    downstreamWallUnwantedVegetationPhotoUrl : string | null;
    downstreamWallUnwantedVegetationPhotoThumbUrl : string | null;

    spillwayDisturbance : string | null;
    spillwayErosion : string | null;
    spillwayErosionLocation : [number, number] | null;
    spillwayErosionPhotoFileName : string | null;
    spillwayErosionPhotoUrl : string | null;
    spillwayErosionPhotoThumbUrl : string | null;
    spillwayUnwantedVegetation : string | null;
    spillwayUnwantedVegetationLocation : [number, number] | null;
    spillwayUnwantedVegetationPhotoFileName : string | null;
    spillwayUnwantedVegetationPhotoUrl : string | null;
    spillwayUnwantedVegetationPhotoThumbUrl : string | null;

    outletWorksCorrosion : boolean | null;
    outletWorksCorrosionPhotoFileName : string | null;
    outletWorksCorrosionPhotoUrl : string | null;
    outletWorksCorrosionPhotoThumbUrl : string | null;
    outletWorksValves : boolean | null;
    outletWorksValvesPhotoFileName : string | null;
    outletWorksValvesPhotoUrl : string | null;
    outletWorksValvesPhotoThumbUrl : string | null;
    outletWorksValvesPhotoFileName1 : string | null;
    outletWorksValvesPhotoUrl1 : string | null;
    outletWorksValvesPhotoThumbUrl1 : string | null;
    outletWorksValvesPhotoFileName2 : string | null;
    outletWorksValvesPhotoUrl2 : string | null;
    outletWorksValvesPhotoThumbUrl2 : string | null;

    publicSafetyAccessToSpillway : boolean | null;
    publicSafetyAccessToSpillwayFenced : boolean | null;
    publicSafetyAccessToWall : boolean | null;
    publicSafetyAccessToWallFenced : boolean | null;
    publicSafetyOther : string | null;
    publicSafetyOtherPhotoFileName : string | null;
    publicSafetyOtherPhotoUrl : string | null;
    publicSafetyOtherPhotoThumbUrl : string | null;

    additionalComment : string | null;

    isSent : boolean;
    isActive : boolean;
}

export default class DamInspectionHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'dam_dsi_inspection';

    private static converter : firebase.firestore.FirestoreDataConverter<IDamInspection> = {
        fromFirestore: (snapshot) => {
            return DamInspectionHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDamInspection) : firebase.firestore.DocumentData => {
            return DamInspectionHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDamInspection {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IDamInspection,
            ...result,
            dam: (data['dam'] as firestore.DocumentReference).id, // We convert the ref to an id
            date: (data['date'] as firestore.Timestamp).toMillis(),
            crestCracks: (data['crestCracks'] as string | boolean | null)?.toString() ?? null,
            crestSettlementLocation: !data['crestSettlementLocation'] ? null :
                [data['crestSettlementLocation'].longitude, data['crestSettlementLocation'].latitude],
            crestErosionLocation: !data['crestErosionLocation'] ? null :
                [data['crestErosionLocation'].longitude, data['crestErosionLocation'].latitude],
            crestCracksLocation: !data['crestCracksLocation'] ? null :
                [data['crestCracksLocation'].longitude, data['crestCracksLocation'].latitude],
            crestOtherLocation: !data['crestOtherLocation'] ? null :
                [data['crestOtherLocation'].longitude, data['crestOtherLocation'].latitude],
            upstreamSlopeDisturbanceLocation: !data['upstreamSlopeDisturbanceLocation'] ? null :
                [data['upstreamSlopeDisturbanceLocation'].longitude, data['upstreamSlopeDisturbanceLocation'].latitude],
            upstreamSlopeErosionLocation: !data['upstreamSlopeErosionLocation'] ? null :
                [data['upstreamSlopeErosionLocation'].longitude, data['upstreamSlopeErosionLocation'].latitude],
            upstreamSlopeSurfaceProtectionLocation: !data['upstreamSlopeSurfaceProtectionLocation'] ? null :
                [data['upstreamSlopeSurfaceProtectionLocation'].longitude, data['upstreamSlopeSurfaceProtectionLocation'].latitude],
            downstreamSlopeBulgingLocation: !data['downstreamSlopeBulgingLocation'] ? null :
                [data['downstreamSlopeBulgingLocation'].longitude, data['downstreamSlopeBulgingLocation'].latitude],
            downstreamSlopeSettlementLocation: !data['downstreamSlopeSettlementLocation'] ? null :
                [data['downstreamSlopeSettlementLocation'].longitude, data['downstreamSlopeSettlementLocation'].latitude],
            downstreamSlopeDisturbanceLocation: !data['downstreamSlopeDisturbanceLocation'] ? null :
                [data['downstreamSlopeDisturbanceLocation'].longitude, data['downstreamSlopeDisturbanceLocation'].latitude],
            downstreamSlopeErosionLocation: !data['downstreamSlopeErosionLocation'] ? null :
                [data['downstreamSlopeErosionLocation'].longitude, data['downstreamSlopeErosionLocation'].latitude],
            downstreamSlopeSeepageLocation: !data['downstreamSlopeSeepageLocation'] ? null :
                [data['downstreamSlopeSeepageLocation'].longitude, data['downstreamSlopeSeepageLocation'].latitude],
            downstreamSlopeUnwantedVegetationLocation: !data['downstreamSlopeUnwantedVegetationLocation'] ? null :
                [data['downstreamSlopeUnwantedVegetationLocation'].longitude, data['downstreamSlopeUnwantedVegetationLocation'].latitude],
            downstreamWallErosionLocation: !data['downstreamWallErosionLocation'] ? null :
                [data['downstreamWallErosionLocation'].longitude, data['downstreamWallErosionLocation'].latitude],
            downstreamWallSeepageLocation: !data['downstreamWallSeepageLocation'] ? null :
                [data['downstreamWallSeepageLocation'].longitude, data['downstreamWallSeepageLocation'].latitude],
            downstreamWallUnwantedVegetationLocation: !data['downstreamWallUnwantedVegetationLocation'] ? null :
                [data['downstreamWallUnwantedVegetationLocation'].longitude, data['downstreamWallUnwantedVegetationLocation'].latitude],
            spillwayErosionLocation: !data['spillwayErosionLocation'] ? null :
                [data['spillwayErosionLocation'].longitude, data['spillwayErosionLocation'].latitude],
            spillwayUnwantedVegetationLocation: !data['spillwayUnwantedVegetationLocation'] ? null :
                [data['spillwayUnwantedVegetationLocation'].longitude, data['spillwayUnwantedVegetationLocation'].latitude],
        };
    }

    public static toFirestoreDocument(data : IDamInspection) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            dam: DamHelper.doc(data.dam), // We convert the id back to a ref here
            date: firestore.Timestamp.fromMillis(data.date),
            crestSettlementLocation: !data.crestSettlementLocation ? null : new firestore.GeoPoint(data.crestSettlementLocation[0], data.crestSettlementLocation[1]),
            crestErosionLocation: !data.crestErosionLocation ? null : new firestore.GeoPoint(data.crestErosionLocation[0], data.crestErosionLocation[1]),
            crestCracksLocation: !data.crestCracksLocation ? null : new firestore.GeoPoint(data.crestCracksLocation[0], data.crestCracksLocation[1]),
            crestOtherLocation: !data.crestOtherLocation ? null : new firestore.GeoPoint(data.crestOtherLocation[0], data.crestOtherLocation[1]),
            upstreamSlopeDisturbanceLocation: !data.upstreamSlopeDisturbanceLocation ? null : new firestore.GeoPoint(data.upstreamSlopeDisturbanceLocation[0], data.upstreamSlopeDisturbanceLocation[1]),
            upstreamSlopeErosionLocation: !data.upstreamSlopeErosionLocation ? null : new firestore.GeoPoint(data.upstreamSlopeErosionLocation[0], data.upstreamSlopeErosionLocation[1]),
            upstreamSlopeSurfaceProtectionLocation: !data.upstreamSlopeSurfaceProtectionLocation ? null : new firestore.GeoPoint(data.upstreamSlopeSurfaceProtectionLocation[0], data.upstreamSlopeSurfaceProtectionLocation[1]),
            downstreamSlopeBulgingLocation: !data.downstreamSlopeBulgingLocation ? null : new firestore.GeoPoint(data.downstreamSlopeBulgingLocation[0], data.downstreamSlopeBulgingLocation[1]),
            downstreamSlopeSettlementLocation: !data.downstreamSlopeSettlementLocation ? null : new firestore.GeoPoint(data.downstreamSlopeSettlementLocation[0], data.downstreamSlopeSettlementLocation[1]),
            downstreamSlopeDisturbanceLocation: !data.downstreamSlopeDisturbanceLocation ? null : new firestore.GeoPoint(data.downstreamSlopeDisturbanceLocation[0], data.downstreamSlopeDisturbanceLocation[1]),
            downstreamSlopeErosionLocation: !data.downstreamSlopeErosionLocation ? null : new firestore.GeoPoint(data.downstreamSlopeErosionLocation[0], data.downstreamSlopeErosionLocation[1]),
            downstreamSlopeSeepageLocation: !data.downstreamSlopeSeepageLocation ? null : new firestore.GeoPoint(data.downstreamSlopeSeepageLocation[0], data.downstreamSlopeSeepageLocation[1]),
            downstreamSlopeUnwantedVegetationLocation: !data.downstreamSlopeUnwantedVegetationLocation ? null : new firestore.GeoPoint(data.downstreamSlopeUnwantedVegetationLocation[0], data.downstreamSlopeUnwantedVegetationLocation[1]),
            downstreamWallErosionLocation: !data.downstreamWallErosionLocation ? null : new firestore.GeoPoint(data.downstreamWallErosionLocation[0], data.downstreamWallErosionLocation[1]),
            downstreamWallSeepageLocation: !data.downstreamWallSeepageLocation ? null : new firestore.GeoPoint(data.downstreamWallSeepageLocation[0], data.downstreamWallSeepageLocation[1]),
            downstreamWallUnwantedVegetationLocation: !data.downstreamWallUnwantedVegetationLocation ? null : new firestore.GeoPoint(data.downstreamWallUnwantedVegetationLocation[0], data.downstreamWallUnwantedVegetationLocation[1]),
            spillwayErosionLocation: !data.spillwayErosionLocation ? null : new firestore.GeoPoint(data.spillwayErosionLocation[0], data.spillwayErosionLocation[1]),
            spillwayUnwantedVegetationLocation: !data.spillwayUnwantedVegetationLocation ? null : new firestore.GeoPoint(data.spillwayUnwantedVegetationLocation[0], data.spillwayUnwantedVegetationLocation[1]),
        };
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .withConverter(this.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return this.collection().withConverter(DamInspectionHelper.converter).doc();
        }

        return this.collection().withConverter(DamInspectionHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return this.doc(id)
            .delete();
    }
}
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import PumpingStationInspectionAuditHelper, { IPumpingStationInspectionAudit } from '../../../../@types/model/pumpingStation/pumpingStationInspectionAudit';
import { IPumpingStationInspectionGroupMotor } from '../../../../@types/model/pumpingStation/pumpingStationInspectionGroup';
import { IUserSession } from '../../../../@types/employee';
import { FormControl, FormControlLabel, InputAdornment, Switch, TextField } from '@material-ui/core';
import moment from 'moment';
import GeneralFunctions from '../../../../store/general/functions';

interface IEditPumpingStationInspectionMotorDialogProps {
    audit : IPumpingStationInspectionAudit;
    reference : string;

    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
    
    session : IUserSession;
}

interface IEditPumpingStationInspectionMotorDialogState {
    open : boolean;
    motor : IPumpingStationInspectionGroupMotor;
}

export default class EditPumpingStationInspectionMotorDialog extends React.Component<IEditPumpingStationInspectionMotorDialogProps, IEditPumpingStationInspectionMotorDialogState> {
    constructor(props : IEditPumpingStationInspectionMotorDialogProps) {
        super(props);
        this.state = {
            open: false,
            motor: {
                axialDirectionZ: 0,
                bearingMotorDriveX: 0,
                bearingMotorDriveY: 0,
                bearingMotorNonDriveX: 0,
                serviceRequired: false,
                visualDamage: false,
                visualDamageComment: '',
            },
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditPumpingStationInspectionMotorDialogProps, prevState : IEditPumpingStationInspectionMotorDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                motor: {
                    axialDirectionZ: 0,
                    bearingMotorDriveX: 0,
                    bearingMotorDriveY: 0,
                    bearingMotorNonDriveX: 0,
                    serviceRequired: false,
                    visualDamage: false,
                    visualDamageComment: '',
                },
            });
        }
        if (!prevState.open && this.state.open) {
            const group = this.props.audit.inspectionGroups.find(x => x.reference === this.props.reference);

            if (!group) return;

            this.setState({
                motor: {
                    ...group.motor,
                },
            });
        }
    };

    private readonly save = async () => {
                
        const { session } = this.props;

        const { visualDamage, visualDamageComment } = this.state.motor;

        if (visualDamage && !visualDamageComment) return;

        const audit = {
            ...this.props.audit,
            inspectionGroups: [
                ...this.props.audit.inspectionGroups,
            ],
        };

        const index = audit.inspectionGroups.findIndex(x => x.reference === this.props.reference);
        audit.inspectionGroups[index] = {
            ...audit.inspectionGroups[index],
            motor: {
                ...this.state.motor,
            },
        };

        audit.updatedBy = session.firebaseUser.uid;
        audit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        audit.updatedByName = session.employee.Name;
        audit.updatedOn = moment.utc().valueOf();

        this.onClose();

        try {
            await PumpingStationInspectionAuditHelper.save(audit);

            GeneralFunctions.generalShowSuccessSnackbar('Saved changes!');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Something went wrong while saving the your changes.');
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onBearingMotorDriveXChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isNaN(Number(event.currentTarget.value))) return;
        this.setState({
            motor: {
                ...this.state.motor,
                bearingMotorDriveX: Number(event.currentTarget.value),
            },
        });
    };

    private readonly onBearingMotorDriveYChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isNaN(Number(event.currentTarget.value))) return;
        this.setState({
            motor: {
                ...this.state.motor,
                bearingMotorDriveY: Number(event.currentTarget.value),
            },
        });
    };

    private readonly onBearingMotorNonDriveXChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isNaN(Number(event.currentTarget.value))) return;
        this.setState({
            motor: {
                ...this.state.motor,
                bearingMotorNonDriveX: Number(event.currentTarget.value),
            },
        });
    };

    private readonly onAxialDirectionZChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (isNaN(Number(event.currentTarget.value))) return;
        this.setState({
            motor: {
                ...this.state.motor,
                axialDirectionZ: Number(event.currentTarget.value),
            },
        });
    };

    private readonly onServiceRequiredChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            motor: {
                ...this.state.motor,
                serviceRequired: checked,
            },
        });
    };

    private readonly onVisualDamageChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            motor: {
                ...this.state.motor,
                visualDamage: checked,
                visualDamageComment: '',
            },
        });
    };

    private readonly onVisualDamageCommentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            motor: {
                ...this.state.motor,
                visualDamageComment: event.currentTarget.value,
            },
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open } = this.state;
        const { serviceRequired, visualDamage, visualDamageComment,
            axialDirectionZ, bearingMotorDriveX, bearingMotorDriveY, bearingMotorNonDriveX } = this.state.motor;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton className='cdg' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='pumpingStation-info-info-title'
                    aria-describedby='pumpingStation-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Pumping Station Inspection Audit Motor Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 ais jcc'}>
                                <div className={'fdr flx1 mr15 aifs'}>
                                    <FormControl fullWidth={true} >
                                        <TextField 
                                            type={'number'}
                                            autoComplete={'off'}
                                            id={'bearingMotorDriveX'}
                                            label={'Bearings Motor Drive-end X'}
                                            value={bearingMotorDriveX}
                                            margin={'normal'}
                                            error={!bearingMotorDriveX}
                                            onChange={this.onBearingMotorDriveXChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m/s<sup>2</sup>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'fdr flx1 mr15 aifs'}>
                                    <FormControl fullWidth={true} >
                                        <TextField 
                                            type={'number'}
                                            autoComplete={'off'}
                                            id={'bearingMotorDriveY'}
                                            label={'Bearings Motor Drive-end Y'}
                                            value={bearingMotorDriveY}
                                            margin={'normal'}
                                            error={!bearingMotorDriveY}
                                            onChange={this.onBearingMotorDriveYChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m/s<sup>2</sup>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'fdr flx1'}>
                                    <FormControl fullWidth={true} >
                                        <TextField 
                                            type={'number'}
                                            autoComplete={'off'}
                                            id={'bearingMotorNonDriveX'}
                                            label={'Bearings Motor Non Drive - end X'}
                                            value={bearingMotorNonDriveX}
                                            margin={'normal'}
                                            error={!bearingMotorNonDriveX}
                                            onChange={this.onBearingMotorNonDriveXChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m/s<sup>2</sup>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 ais jcc'}>
                                <div className={'fdr flx1 mr15 aifs'}>
                                    <FormControl fullWidth={true} >
                                        <TextField 
                                            type={'number'}
                                            autoComplete={'off'}
                                            id={'axialDirectionZ'}
                                            label={'Axial Direction (Z)'}
                                            value={axialDirectionZ}
                                            margin={'normal'}
                                            error={!axialDirectionZ}
                                            onChange={this.onAxialDirectionZChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m/s<sup>2</sup>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'fdr flx1 mr15 aic'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={serviceRequired}
                                                color='primary'
                                                checked={serviceRequired}
                                                onChange={this.onServiceRequiredChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Service Require?'}
                                    />
                                </div>
                                <div className={'fdr flx1 aic'}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                value={visualDamage}
                                                color='primary'
                                                checked={visualDamage}
                                                onChange={this.onVisualDamageChange}
                                            />
                                        }
                                        labelPlacement='start'
                                        label={'Visual Damage?'}
                                    />
                                </div>
                            </div>
                            {
                                visualDamage &&
                                <div className={'fdr flx1 aifs jcc'}>
                                    <div className={'fdr flx1'}>
                                        <FormControl fullWidth={true} >
                                            <TextField 
                                                type={'text'}
                                                autoComplete={'off'}
                                                id={'visualDamageComment'}
                                                label={'Comment'}
                                                value={visualDamageComment}
                                                margin={'normal'}
                                                error={!visualDamageComment}
                                                onChange={this.onVisualDamageCommentChange}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

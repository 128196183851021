import { FormControl, TextField, TextFieldProps, withStyles } from '@material-ui/core';
import { FastField, FieldProps } from 'formik';
import React from 'react';

interface ITextFormFastFieldProps extends Omit<TextFieldProps, 'name' | 'value' | 'error' | 'variant' | 'color' | 'onChange'> {
    name : string;

    color ?: 'white';

    onChange ?: (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, params ?: FieldProps<unknown>) => void;
}

interface ITextFormFastFieldState {}

const WhiteTextField = withStyles({
    root: {
        '& input': {
            color: 'white',
        },
        '& label.Mui-focused,label': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'white',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);
  
export default class TextFormFastField extends React.PureComponent<ITextFormFastFieldProps, ITextFormFastFieldState> {
    constructor(props : ITextFormFastFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { id, name, onChange, color, ...rest } = this.props;
        return (
            <FastField
                id={id}
                name={name}
            >
                {
                    (params : FieldProps<unknown>) => (
                        <FormControl fullWidth={rest.fullWidth} required={rest.required}>
                            {
                                color !== 'white' &&
                                <TextField
                                    {...rest}
                                    {...params.field}
                                    value={params.field.value ?? ''}
                                    error={!!params.meta.error}
                                    variant='standard'onChange={onChange ? onChange : params.field.onChange}
                                />

                            }
                            {
                                color === 'white' &&
                                <WhiteTextField
                                    {...rest}
                                    {...params.field}
                                    value={params.field.value ?? ''}
                                    error={!!params.meta.error}
                                    variant='standard'
                                    onChange={onChange ? onChange : params.field.onChange}
                                />

                            }
                        </FormControl>
                    )
                }
            </FastField>
        );
    };
}

import React from 'react';
import { IMottech } from '../../../@types/mottech';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import mottechFunctions from '../../../store/mottech/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MottechRepeaterInput from '../../customComponents/input/mottech/RepeaterInput';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface IEditMottechRepeaterProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    mottech ?: IMottech;
}

interface IEditMottechRepeaterState {
    open : boolean;
    repeater : boolean;
    repeaters : Array<string>;
}

export default class EditMottechRepeater extends React.Component<IEditMottechRepeaterProps, IEditMottechRepeaterState> {
    constructor(props : IEditMottechRepeaterProps) {
        super(props);
        this.state = {
            open: false,
            repeater: false,
            repeaters: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditMottechRepeaterProps, prevState : IEditMottechRepeaterState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                repeater: false,
                repeaters: [],
            });
        }
        if (!prevState.open && this.state.open && this.props.mottech) {
            this.setState({
                repeater: !!this.props.mottech.Repeater,
                repeaters: !this.props.mottech.Repeaters ? [] : this.props.mottech.Repeaters,
            });
        }

    };

    private readonly save = async () => {
        const { repeaters, repeater } = this.state;

        if (!this.props.mottech) return;
        if (repeaters.some(n => !n)) return;

        const mottech = {
            ...this.props.mottech,
        };
        mottech.Repeater = repeater;
        mottech.Repeaters = repeaters;
        const result = await mottechFunctions.saveMottech(mottech);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onAddRepeaterClick = () => {
        const repeaters = this.state.repeaters.slice();

        if (!this.state.repeater) return;
        repeaters.push('');

        this.setState({
            repeaters,
        });
    };

    private readonly onRepeaterChange = (value : string, index : number) => {
        const repeaters = this.state.repeaters.slice();
        repeaters.splice(index, 1, value);
        this.setState({
            repeaters,
        });
    };

    private readonly onRepeaterDelete = (index : number) => {
        const repeaters = this.state.repeaters.slice();
        repeaters.splice(index - 1, 1);

        this.setState({
            repeaters,
        });
    };

    private readonly onRepeaterChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            repeater: checked,
            repeaters: [],
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, repeater, repeaters } = this.state;

        return (
            <div>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='mottech-io-edit-dialog-title'
                    aria-describedby='mottech-io-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Mottech Repeater
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <div className='fdc flx1 hfill oys'>
                        <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                            <DialogContent className='fdc flx1 hfill'>
                                <div className={'fdr flx1 aifs'}>
                                    <div className={'ais p5 mb10 pr20'}>
                                        <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color='primary' checked={repeater} onChange={this.onRepeaterChanged} />
                                                }
                                                label='Repeater?'
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                {
                                    repeater &&
                                    <div className={'fdc flx1'}>
                                        <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                                REPEATERS
                                            <span className='flx1' />
                                            <Tooltip title='Add'>
                                                <div>
                                                    <IconButton size='small' color='inherit' onClick={this.onAddRepeaterClick} aria-label='Add'>
                                                        <Icon>add</Icon>
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Typography>
                                        <div className={'fdc flx1'}>
                                            {
                                                repeaters.map((r, i) => (
                                                    <MottechRepeaterInput id={`${i}_rep`} key={`${i}_rep`} value={r} index={i} onChange={this.onRepeaterChange} onDelete={this.onRepeaterDelete} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                    OK
                                </Button>
                                <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                    CANCEL
                                </Button>
                            </DialogActions>
                        </Form>
                    </div>
                </Dialog>
            </div>);
    };
}

import { TextField, TextFieldProps, withStyles } from '@material-ui/core';
import React from 'react';

type OutlinedProps = Omit<TextFieldProps, 'variant'>;

interface IStadiumOutlinedTextFieldProps extends OutlinedProps {}

interface IStadiumOutlinedTextFieldState {}


export default class StadiumOutlinedTextField extends React.PureComponent<IStadiumOutlinedTextFieldProps, IStadiumOutlinedTextFieldState> {
    constructor(props : IStadiumOutlinedTextFieldProps) {
        super(props);
        this.state = {};
    }

    private readonly OutlinedTextField = withStyles({
        root: {
            '& input + fieldset': {
                borderColor: '#707070',
                borderWidth: 1,
                borderRadius: 32,
            },
        },
    })(TextField);

    public readonly render = () => {
        const { ...rest } = this.props;
        return (
            <this.OutlinedTextField
                {...rest}
                variant='outlined'
            />
        );
    };
}


import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IRootState } from '../../../../@types/redux';
import { IPumpingStationMotorPole } from '../../../../@types/model/masterData/pumpingStation/motorPole';
import { connect } from 'react-redux';

interface IPumpingStationMotorPoleSelectorProps {
    value : string;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : string) => void;

    isLoading : boolean;
    motorPoles : Array<IPumpingStationMotorPole>;
}

interface IPumpingStationMotorPoleSelectorState { }

class PumpingStationMotorPoleSelectorComponent extends React.Component<IPumpingStationMotorPoleSelectorProps, IPumpingStationMotorPoleSelectorState> {
    constructor(props : IPumpingStationMotorPoleSelectorProps) {
        super(props);

        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? '');
    };

    private readonly getData = (state : IPumpingStationMotorPoleSelectorState, props : IPumpingStationMotorPoleSelectorProps) => props.motorPoles;
    private readonly getValue = (state : IPumpingStationMotorPoleSelectorState, props : IPumpingStationMotorPoleSelectorProps) => props.value;
    private readonly getRequired = (state : IPumpingStationMotorPoleSelectorState, props : IPumpingStationMotorPoleSelectorProps) => props.required;

    private readonly getMotorPoles = createSelector([
        this.getData,
    ], (motorPoles) => motorPoles.filter(x => x.isActive).map(x => x.description).sort());

    private readonly getMotorPolesForDropdown = createSelector([ 
        this.getRequired, this.getMotorPoles,
    ], (required, motorPoles) => {
        
        const dropdownData = lodash.map(motorPoles, (element) => ({ label: element, value: element }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([ 
        this.getValue, this.getRequired, this.getMotorPoles,
    ], (value, required, motorPoles) => {
        if (value) {
            const motorPole = motorPoles.find(element => element === value);

            if (motorPole) {
                return { label: motorPole, value: motorPole };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, className, disabled } = this.props;

        const dropdownData = this.getMotorPolesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'motorPole'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Motor Pole'} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return { 
        isLoading: state.masterData.pumpingStation.motorPole.isLoading,
        motorPoles: state.masterData.pumpingStation.motorPole.motorPoles,
    };
};

const PumpingStationMotorPoleSelector = connect(
    mapStateToProps,
)(PumpingStationMotorPoleSelectorComponent);

export default PumpingStationMotorPoleSelector;
import React from 'react';
import { LinePath } from '@visx/shape';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { timeFormat } from 'd3-time-format';
import { curveMonotoneX } from '@visx/curve';
import { IRainMeterMeasurement } from '../../../../@types/model/rainMeter/rainMeterMeasurement/rainMeterMeasurement';
import { extent, max } from 'd3-array';

const margin = { top: 20, right: 20, bottom: 40, left: 50 };

export interface IRainMeterMeasurementChartProps {
    data: Array<IRainMeterMeasurement>;
    height: number;
    width: number;
}

const RainMeterMeasurementChart = (props: IRainMeterMeasurementChartProps): React.ReactElement => {
    if (!props.data.length) {
        return (
            <svg width={props.width} height={props.height}>
                <text x={props.width / 2} y={props.height / 2} textAnchor="middle">
                    No data available
                </text>
            </svg>
        );
    }

    const chartData = React.useMemo(() => {
        const groupedData = props.data.filter(x => x.isActive).reduce((acc, curr) => {
            const date = curr.capturedDate.toDate();
            date.setHours(0, 0, 0, 0);
            const dateStr = date.toISOString();
            
            if (!acc[dateStr]) {
                acc[dateStr] = {
                    x: date,
                    y: 0
                };
            }
            acc[dateStr].y += curr.value;
            return acc;
        }, {} as Record<string, {x: Date, y: number}>);

        return Object.values(groupedData);
    }, [props.data]);

    // Create scales using d3 helpers
    const xScale = scaleTime<number>({
        domain: extent(chartData, d => d.x) as [Date, Date],
        range: [margin.left, props.width - margin.right],
    });

    const yScale = scaleLinear<number>({
        domain: [0, max(chartData, d => d.y) || 0],
        range: [props.height - margin.bottom, margin.top],
        nice: true,
    });

    const dateFormatter = (date: Date) => timeFormat('%b %d')(date);

    return (
        <svg width={props.width} height={props.height}>
            <Group>
                <AxisBottom
                    scale={xScale}
                    top={props.height - margin.bottom}
                    label="Captured Date"
                    tickFormat={(value: Date | { valueOf(): number }) => {
                        if (value instanceof Date) {
                            return dateFormatter(value);
                        }
                        return '';
                    }}
                    numTicks={5}
                />
                <AxisLeft 
                    scale={yScale}
                    left={margin.left}
                    label="Value"
                    numTicks={5}
                />
                <LinePath
                    data={chartData}
                    x={d => xScale(d.x) ?? 0}
                    y={d => yScale(d.y) ?? 0}
                    stroke="blue"
                    strokeWidth={2}
                    curve={curveMonotoneX}
                />
            </Group>
        </svg>
    );
};

export default RainMeterMeasurementChart;

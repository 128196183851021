import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import AppFunctionsService from '../../../services/appFunctionsService';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditTransformerLine from '../dialog/EditLine';

interface ITransformerLineInfoViewProps {
    transformer : ITransformer;
    isLoading : boolean;
    session : IUserSession;
}

interface ITransformerLineInfoViewState {}

export default class TransformerLineInfoView extends React.PureComponent<ITransformerLineInfoViewProps, ITransformerLineInfoViewState> {
    constructor(props : ITransformerLineInfoViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { transformer, isLoading, session } = this.props;

        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg0'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                LINE INFO
                            </Typography>
                            <span className='flx1' />
                            {
                                session.employee.IsTransformerAdmin &&
                                <EditTransformerLine transformer={transformer} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc'>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                FEEDER LINE
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                LINE VOLTAGE
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {!transformer.Line ? '-' : transformer.Line}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.LineVoltage ? AppFunctionsService.getLineVoltageRangeLabel(transformer.LineVoltage) : '-'}
                            </Typography>
                        </div>
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    };
}

import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';

export interface IRoundedLabelImageView {
    name : string;
    url : string;
    thumb : string;
}

interface IRoundedLabelImageProps {
    thumbnailUrl ?: string | null;
    fileUrl ?: string | null;
    className ?: string;
    label : string;
    onClick : (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IRoundedLabelImageState {
    isLoading : boolean;
}

export default class RoundedLabelImage extends React.Component<IRoundedLabelImageProps, IRoundedLabelImageState> {
    public constructor(props : IRoundedLabelImageProps) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }
    
    public readonly onLoadStart = () => {

        this.setState({
            isLoading: true,
        });
    };
    
    public readonly onLoad = () => {

        this.setState({
            isLoading: false,
        });
    };

    public render () {

        const { fileUrl, thumbnailUrl, label, className, onClick } = this.props;
        const { isLoading } = this.state;

        return (
            <div className={`fdc br20 oh ${className ?? ''}`}>
                <div className={'fdc jcc aic bcg0 mw200 h200'}>
                    {
                        thumbnailUrl &&
                        <img 
                            className={'curp mw200'} 
                            src={thumbnailUrl} 
                            alt={fileUrl ?? ''}
                            // eslint-disable-next-line react/no-unknown-property
                            onLoadStart={this.onLoadStart}
                            onLoad={this.onLoad}
                            onClick={onClick} 
                        />
                    }
                    {
                        !thumbnailUrl &&
                        <div className={'fdc jcc aic bcg0 mw200 h200'}>
                            <Typography className={'cp fw500'}>No Image</Typography> 
                        </div>
                    }
                    {
                        isLoading &&
                        <div className={'posa fdc jcc aic'}>
                            <CircularProgress />  
                        </div>
                    }
                </div>
                <div className={'bcp p10'}>
                    <Typography className={'cw fs14'} align={'center'}>
                        {label}
                    </Typography>
                </div>
            </div> 
        );
    }
} 
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../../@types/employee';
import GeneralFunctions from '../../../../store/general/functions';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { CircularProgress } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import FileEmbedView from '../../../customComponents/embed/FileEmbedView';
import FormikForm from '../../../customComponents/form/FormikForm';
import DamRegistrationHelper, { IDamRegistration, IDamRegistrationFormValues } from '../../../../@types/model/dam/damRegistration';
import { IDam } from '../../../../@types/model/dam/dam';
import firebaseApp from '../../../../services/firebaseService';
import { EnumFileUploadQueueType } from '../../../../@types/model/files/enum';
import TextFormField from '../../../customComponents/form/TextFormField';
import DateFormField from '../../../customComponents/form/DateFormField';
import DamWallTypeFormField from '../../../customComponents/form/dam/DamWallTypeFormField';
import DamSizeFormField from '../../../customComponents/form/dam/DamSizeFormField';
import DamHazardPotentialFormField from '../../../customComponents/form/dam/DamHazardPotentialFormField';
import firebase from 'firebase/app';
import { Field, FieldProps } from 'formik';
import { Moment } from 'moment';
import FormTabDateSelector from '../../../customComponents/form/FormTabDateSelector';

interface IEditDamRegistrationProps {
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;


    damRegistration ?: IDamRegistration;

    session ?: IUserSession | null;
    dam ?: IDam;
}

interface IEditDamRegistrationState {
    isLoading : boolean;
    open : boolean;

    file : File | null;

    tabIndex : number;

    initialValues : IDamRegistrationFormValues | null;
}

class EditDamRegistrationComponent extends React.Component<IEditDamRegistrationProps, IEditDamRegistrationState> {
    constructor(props : IEditDamRegistrationProps) {
        super(props);
        this.state = {
            isLoading: false,
            open: false,
            file: null,
            tabIndex: 0,
            initialValues: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditDamRegistrationProps, prevState : IEditDamRegistrationState) => {
        if (!prevState.open && this.state.open) {
            const { session } = this.props;
            this.setState({
                isLoading: false,
                file: null,
                tabIndex: 0,
                initialValues: {
                    ...DamRegistrationHelper.initialFormValues(this.props.damRegistration, this.props.dam),
                    employeeName: session?.employee.Name ?? null,
                    employeeNumber: session?.employee.EmployeeNumber ?? null,
                },
            });
        }

    };

    private readonly save = async (formValues : IDamRegistrationFormValues) => {
        const { damRegistration } = this.props;
        const { 
            file,
        } = this.state;

        const ref = DamRegistrationHelper.doc(damRegistration?.id);

        try {
            this.setState({
                isLoading: true,
            });
            
            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { session, dam } = this.props;
        
                if (!session) throw new Error('Session Required');
                if (!dam) throw new Error('Dam Required');
                if (!file && !damRegistration) throw new Error('Photo Required');
    
                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);
    
                const data = doc.data();
    
                transaction.set(ref, {
                    ...formValues,
                    guid: data?.guid ?? formValues.guid,
                    createdBy: data?.createdBy ?? session.firebaseUser.uid,
                    createdByName: data?.createdByName ?? session.employee.Name,
                    createdByEmployee: data?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
                    createdOn: data?.createdOn ?? firebase.firestore.Timestamp.now().toMillis(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                    isSent: false,
                    isActive: data?.isActive ?? true,
                    isWeb: data?.isWeb ?? true,

                    damRef: dam.id,
                    damCode: dam.code,
                    
                    documentName: file?.name ?? data?.documentName ?? null,
                    documentURL: !file ? (data?.documentURL ?? null) : '',

                    employeeName: formValues.employeeName ?? '',
                    employeeNumber: formValues.employeeNumber ?? '',

                    nextDSEDate: formValues.nextDSEDate?.valueOf() ?? null,
                    date: formValues.date.valueOf(),
                    completionDate: formValues.completionDate?.valueOf() ?? null,
                    lastDSEDate: formValues.lastDSEDate?.valueOf() ?? null,
                    completionDateRaised: formValues.completionDateRaised?.valueOf() ?? null,
                    registrationDate: formValues.registrationDate?.valueOf() ?? null,
                    classificationDate: formValues.classificationDate?.valueOf() ?? null,
                }, {
                    merge: true,
                });
    
                if (file) {
                    await DamRegistrationHelper.uploadFile(
                        data?.damCode ?? dam.code,
                        file,
                        {
                            collection: DamRegistrationHelper.COLLECTION_NAME,
                            fieldName: 'documentURL',
                            fileType: EnumFileUploadQueueType.DamRegistration.toString(),
                            refGuid: formValues.guid,
                            thumbnailFieldName: '',
                        }
                    );
                }
            });

            this.setState({
                open: false,
            });
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving registration.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
        
    };

    private readonly onSubmit = (formValues : IDamRegistrationFormValues) => {
        this.save(formValues);
    };

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.dam) return;
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onFileDrop = (acceptedFiles : Array<File>) => {
        if (!acceptedFiles.length) return;

        this.setState({
            file: new File([acceptedFiles[0].slice()], `${acceptedFiles[0].name}`, {
                type: acceptedFiles[0].type,
            }),
        });
    };

    public readonly render = () => {
        const { transition, damRegistration } = this.props;
        const { open, isLoading, file, initialValues } = this.state;
        return (
            <React.Fragment>
                <Tooltip title={damRegistration ? 'Edit' : 'Upload'}>
                    <div>
                        {
                            !!damRegistration &&
                            <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Edit' disabled={isLoading}>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                        {
                            !damRegistration &&
                            <StadiumButton style={{
                                height: 42,
                                borderRadius: 40,
                            }} className='jcc aic p0' color='primary' onClick={this.onClick} aria-label='Upload Video'>
                                <Icon className='cp ml10'>upload</Icon>
                                <Typography className='fw600 fs16 cp ml20 mr10'>DOCUMENT UPLOADS</Typography>
                            </StadiumButton>
                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    fullScreen
                    fullWidth
                    aria-labelledby='dam-registration-edit-dialog-title'
                    aria-describedby='dam-registration-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            {
                                !!damRegistration &&
                                <Typography variant='h5' color='inherit'>
                                    Edit Dam Registration - {AppFunctionsService.formatDateTimeToDatePicker(damRegistration.date)}
                                </Typography>
                            }
                            {
                                !damRegistration &&
                                <Typography variant='h5' color='inherit'>
                                    Add Dam Registration
                                </Typography>
                            }
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    {
                        initialValues &&
                        <FormikForm
                            onSubmit={this.onSubmit}
                            className='fdc hfill bcg0'
                            validateOnMount
                            initialValues={initialValues}
                            validationSchema={DamRegistrationHelper.formSchema()}
                            enableReinitialize
                        >
                            {
                                (props) => (
                                    <>
                                        <DialogContent className='fdc hfill p0'>
                                            {
                                                isLoading &&
                                                <div className='fdc flx1 aic jcc'>
                                                    <CircularProgress />
                                                </div>
                                            }
                                            {
                                                !isLoading &&
                                                <div className='fdr'>
                                                    <div className='fdc w804 mr10'>
                                                        <div className={'jcfs aic wfill dvbg h50'}>
                                                            <Typography className={'ml15 fs14 fw500 cw '}>
                                                                PDF DOCUMENT
                                                            </Typography>
                                                            <span className='flx1' />
                                                        </div>
                                                        <div className='fdc h1132 wfill'>
                                                            <Dropzone onDrop={this.onFileDrop} accept={'application/pdf,image/*'}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className='fdc flx1'>
                                                                        <div className='fdc flx1' {...getRootProps()}>
                                                                            <input disabled={isLoading} multiple={false} {...getInputProps()} />
                                                                            
                                                                            {
                                                                                !file &&
                                                                                <div className='fdr flx1 jcc aic'>
                                                                                    <Typography className='cg0' variant='body2'>
                                                                                        DRAG &amp; DROP FILE
                                                                                    </Typography>
                                                                                </div>
                                                                            }
                                                                            
                                                                            {
                                                                                file &&
                                                                                <FileEmbedView file={file} />
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                    <div className='fdc flx1 ml10 mr10'>
                                                        <div className={'jcfs aic wfill dvbg h50'}>
                                                            <Typography className={'ml15 fs14 fw500 cw '}>
                                                                PDF DOCUMENT DETAILS
                                                            </Typography>
                                                            <span className='flx1' />
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdc flx1'>
                                                                <Field 
                                                                    id='date'
                                                                    name='date'
                                                                >
                                                                    {
                                                                        (params : FieldProps<Moment>) => <FormTabDateSelector {...params} />
                                                                    }
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'employeeName'}
                                                                    name={'employeeName'}
                                                                    fullWidth
                                                                    required
                                                                    autoComplete='off'
                                                                    label={'DATA RECORDER\u2019S PERSONEL NAME'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'employeeNumber'}
                                                                    name={'employeeNumber'}
                                                                    fullWidth
                                                                    required
                                                                    autoComplete='off'
                                                                    label={'DATA RECORDER\u2019S PERSONEL NUMBER'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1'>
                                                                <TextFormField
                                                                    id={'nameOfDam'}
                                                                    name={'nameOfDam'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'NAME OF DAM'}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'damNumber'}
                                                                    name={'damNumber'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'NO OF DAM'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'warmsDamId'}
                                                                    name={'warmsDamId'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'WARMS DAM ID'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'waterManagementArea'}
                                                                    name={'waterManagementArea'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'WATER MANAGEMENT AREA'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'quaternaryDrainageArea'}
                                                                    name={'quaternaryDrainageArea'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'QUATERNARY DRAINAGE AREA'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'latitude'}
                                                                    name={'latitude'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'LATITUDE'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'longitude'}
                                                                    name={'longitude'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'LONGITUDE'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'nearestTown'}
                                                                    name={'nearestTown'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'TOWN NEAREST'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'distanceFromTown'}
                                                                    name={'distanceFromTown'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'DISTANCE FROM TOWN'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'nameOfFarm'}
                                                                    name={'nameOfFarm'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'NAME OF FARM'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'district'}
                                                                    name={'district'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'DISTRICT'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'province'}
                                                                    name={'province'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'PROVINCE'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'provinceOffice'}
                                                                    name={'provinceOffice'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'DWS PROVINCIAL OFFICE / REGION'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <DateFormField
                                                                    name={'completionDate'}
                                                                    fullWidth
                                                                    format='DD/MM/YYYY'
                                                                    label={'COMPLETION DATE'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <DateFormField
                                                                    name={'completionDateRaised'}
                                                                    fullWidth
                                                                    format='DD/MM/YYYY'
                                                                    label={'COMPLETION DATE RAISED'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'riverOrWaterCourse'}
                                                                    name={'riverOrWaterCourse'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'RIVER OR WATERCOURSE'}
                                                                    className='mt16'
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <DamWallTypeFormField
                                                                    name={'wallType'}
                                                                    label='WALL TYPE'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'wallHeight'}
                                                                    name={'wallHeight'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'WALL HEIGHT'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'crestLength'}
                                                                    name={'crestLength'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'CREST LENGTH (M)'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'spillwayType'}
                                                                    name={'spillwayType'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'SPILLWAY TYPE'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'capacity'}
                                                                    name={'capacity'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'CAPACITY (1000m³)'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'surfaceArea'}
                                                                    name={'surfaceArea'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'SURFACE AREA (HA)'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'catchmentArea'}
                                                                    name={'catchmentArea'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'CATCHMENT AREA (km²)'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'purpose'}
                                                                    name={'purpose'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'PURPOSE'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'owner'}
                                                                    name={'owner'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'OWNER'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <TextFormField
                                                                    id={'designer'}
                                                                    name={'designer'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'DESIGNER'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'contractor'}
                                                                    name={'contractor'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'CONTRACTOR'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10 mt16'>
                                                                <DateFormField
                                                                    name={'registrationDate'}
                                                                    fullWidth
                                                                    format='DD/MM/YYYY'
                                                                    label={'REGISTRATION DATE'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <DamSizeFormField
                                                                    name={'size'}
                                                                    label='DAM SIZE'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <DamHazardPotentialFormField
                                                                    name={'hazardPotential'}
                                                                    label='HAZARD POTENTIAL'
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10 mt16'>
                                                                <TextFormField
                                                                    id={'category'}
                                                                    name={'category'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'CATEGORY'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <DateFormField
                                                                    name={'classificationDate'}
                                                                    fullWidth
                                                                    format='DD/MM/YYYY'
                                                                    label={'CLASSIFICATION DATE'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'sector'}
                                                                    name={'sector'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'SECTOR'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15 bcw'>
                                                            <div className='fdr flx1 mr10'>
                                                                <DateFormField
                                                                    name={'lastDSEDate'}
                                                                    fullWidth
                                                                    format='DD/MM/YYYY'
                                                                    label={'LAST DSE DATE'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                                <TextFormField
                                                                    id={'lastDSENumber'}
                                                                    name={'lastDSENumber'}
                                                                    fullWidth
                                                                    autoComplete='off'
                                                                    label={'LAST DSE NUMBER'}
                                                                    type='number'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='fdr pt15 pb15 pl15 pr15'>
                                                            <div className='fdr flx1 mr10'>
                                                                <DateFormField
                                                                    name={'nextDSEDate'}
                                                                    fullWidth
                                                                    format='DD/MM/YYYY'
                                                                    label={'NEXT DSE DATE'}
                                                                />
                                                            </div>
                                                            <div className='fdr flx1 ml10'>
                                                            </div>
                                                        </div>
                                                        <div className='fdr jcfe mt15 mb15'>
                                                            <div>
                                                                <StadiumButton disabled={!props.isValid} onClick={props.submitForm}>
                                                                    Save
                                                                </StadiumButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </DialogContent>
                                    </>
                                )
                            }
                        </FormikForm>
                    }
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};

const EditDamRegistration = connect(
    mapStateToProps,
)(EditDamRegistrationComponent);

export default EditDamRegistration;
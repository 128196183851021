
import { createAction } from 'typesafe-actions';
import { ITransformer } from '../../@types/model/transformer/transformer';
import { ITransformerLpuReading } from '../../@types/model/transformer/transformerLpuReading';
import { ITransformerReading } from '../../@types/model/transformer/transformerReading';

export const transformerSetList = createAction('TRANSFORMER_SET_LIST', resolve => (transformers : Array<ITransformer>) => resolve(transformers));
export const transformerSetSelected = createAction('TRANSFORMER_SET_SELECTED', resolve => (transformer ?: ITransformer) => resolve(transformer));
export const transformerSetLoading = createAction('TRANSFORMER_SET_LOADING', resolve => (loading : boolean) => resolve(loading));
export const transformerSetReadings = createAction('TRANSFORMER_SET_READINGS', resolve => (readings : Array<ITransformerReading>) => resolve(readings));
export const transformerSetReadingLoading = createAction('TRANSFORMER_SET_LOADING_READINGS', resolve => (loading : boolean) => resolve(loading));
export const transformerSetLpuReadings = createAction('TRANSFORMER_SET_LPUREADINGS', resolve => (readings : Array<ITransformerLpuReading>) => resolve(readings));
export const transformerSetLpuReadingLoading = createAction('TRANSFORMER_SET_LOADING_LPUREADINGS', resolve => (loading : boolean) => resolve(loading));

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createSelector } from 'reselect';
import CustomMapBox, { IMapMarker } from '../map/CustomMapBox';
import { MapBoxRef } from '../mapBox/mapbox';
import { MapMouseEvent } from 'react-map-gl';
import { EventData } from 'mapbox-gl';

interface IEditLocationDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    markerImage : string;
    markerImageSize ?: number;

    isLoading ?: boolean;

    geom ?: firebase.firestore.GeoPoint | null;
    elevation ?: number;
    accuracy ?: number;

    onSave ?: (geom : firebase.firestore.GeoPoint, elevation : number, accuracy ?: number) => Promise<void>;
}

interface IEditLocationDialogState {
    open : boolean;
    geom : firebase.firestore.GeoPoint;
    elevation : string;
    accuracy ?: string;
}

export default class EditLocationDialog extends React.Component<IEditLocationDialogProps, IEditLocationDialogState> {
    private readonly mapZoom = 16;
    private mapRef : MapBoxRef | null = null;
    
    constructor(props : IEditLocationDialogProps) {
        super(props);
        this.state = {
            open: false,
            geom: new firebase.firestore.GeoPoint(-23.5520414, 30.1148622),
            elevation: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditLocationDialogProps, prevState : IEditLocationDialogState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                geom: this.props.geom ?? new firebase.firestore.GeoPoint(-23.5520414, 30.1148622),
                accuracy: this.props.accuracy?.toString(),
                elevation: this.props.elevation?.toString() ?? '',
            });
        }
    };

    private readonly save = async () => {
        const { geom, elevation, accuracy } = this.state;
        
        if (this.props.onSave) await this.props.onSave(geom, parseFloat(elevation), !accuracy ? undefined : parseFloat(accuracy));

        this.onClose();
    };

    private readonly onOkClick = () => {
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onMapClick = (event : MapMouseEvent & EventData) => {
        this.setState({
            geom: new firebase.firestore.GeoPoint(event.lngLat.lat, event.lngLat.lng),
        });

        event.target.flyTo({
            center: event.lngLat,
            zoom: this.mapZoom,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });

        this.mapRef?.off('click', this.onMapClick);
    };

    private readonly onAccuracyChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            accuracy: event.currentTarget.value,
        });
    };

    private readonly onElevationChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            elevation: event.currentTarget.value,
        });
    };

    private readonly onLatChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;

        const lat = parseFloat(event.currentTarget.value);
        this.setState({
            geom: new firebase.firestore.GeoPoint(!lat ? 0 : lat, this.state.geom.longitude),
        });
    };

    private readonly onLngChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;

        const lng = parseFloat(event.currentTarget.value);
        this.setState({
            geom: new firebase.firestore.GeoPoint(this.state.geom.latitude, !lng ? 0 : lng),
        });
    };

    public readonly getGeom = (state : IEditLocationDialogState) => state.geom;
    public readonly getPropGeom = (state : IEditLocationDialogState, props : IEditLocationDialogProps) => props.geom;
    public readonly getMarkerImageSize = (state : IEditLocationDialogState, props : IEditLocationDialogProps) => props.markerImageSize;
    public readonly getMarkerImage = (state : IEditLocationDialogState, props : IEditLocationDialogProps) => props.markerImage;

    public readonly getCenter = createSelector(
        [this.getPropGeom],
        (geom) : [number, number] => {
            return !geom ? [30.1148622, -23.5520414] : [geom.longitude, geom.latitude];
        },
    );

    public readonly getMapImage = createSelector(
        [this.getMarkerImage],
        (markerImage) => {
            return {
                'location_marker': markerImage,
            };
        },
    );

    public readonly getMarkers = createSelector(
        [this.getGeom, this.getMarkerImageSize],
        (geom, markerImageSize) => {
            return [{
                id: 'location',
                coordinates: [geom.longitude, geom.latitude],
                iconSize: markerImageSize ?? 0.35,
                icon: 'location_marker',
            } as IMapMarker];
        },
    );

    private readonly setMapRef = (mapRef : MapBoxRef) => {
        this.mapRef = mapRef;

        this.mapRef.on('click', this.onMapClick);
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, geom, elevation, accuracy } = this.state;

        const mapImage = this.getMapImage(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);
        
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-geom-edit-dialog-title'
                    aria-describedby='transformer-geom-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Location
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 hfill'>
                        {
                            open &&
                            <Paper className={'fdc flx1 bcw wfill'}>
                                <div className={'fdr'}>
                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                type='number'
                                                autoComplete='off'
                                                id='geom.latitude'
                                                label='Latitude'
                                                value={geom.latitude}
                                                onChange={this.onLatChange}
                                                margin='normal'
                                                className={'TextField'}
                                                error={!!geom.latitude && !Number(geom.latitude)}
                                            />
                                            {
                                                geom.latitude && !Number(geom.latitude) &&
                                                <FormHelperText error>Invalid</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                type='number'
                                                autoComplete='off'
                                                id='geom.longitude'
                                                label='Longitude'
                                                value={geom.longitude}
                                                onChange={this.onLngChange}
                                                margin='normal'
                                                className={'TextField'}
                                                error={!!geom.longitude && !Number(geom.longitude)}
                                            />
                                            {
                                                geom.longitude && !Number(geom.longitude) &&
                                                <FormHelperText error>Invalid</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                </div>
                                <div className={'fdr'}>
                                    <div className={'flx1 ais p5 mb10 pr20'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                type='number'
                                                autoComplete='off'
                                                id='elevation'
                                                label='Elevation'
                                                value={elevation}
                                                onChange={this.onElevationChange}
                                                margin='normal'
                                                className={'TextField'}
                                                error={!!elevation && !Number(elevation)}
                                            />
                                            {
                                                elevation && !Number(elevation) &&
                                                <FormHelperText error>Invalid</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    {
                                        this.props.accuracy !== undefined &&
                                        <div className={'flx1 ais p5 mb10 pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    type='number'
                                                    autoComplete='off'
                                                    id='accuracy'
                                                    label='Accuracy'
                                                    value={accuracy}
                                                    onChange={this.onAccuracyChange}
                                                    margin='normal'
                                                    className={'TextField'}
                                                    error={!!accuracy && !Number(accuracy)}
                                                />
                                                {
                                                    accuracy && !Number(accuracy) &&
                                                    <FormHelperText error>Invalid</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    }
                                </div>
                            
                                <div className='h250 wfill mt5'>
                                    <CustomMapBox
                                        id='location_map'
                                        images={mapImage}
                                        markers={markers}
                                        onMap={this.setMapRef}
                                    />
                                </div>
                            </Paper>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} variant='contained' color='primary' onClick={this.onOkClick}>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>);
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { BOREHOLE_INSTALLATION_HDPE_TYPES } from '../../../appConstants';

interface IRisingMainHDPESelectorProps {
    value : string;

    onChange : (value : string) => void;

    disabled ?: boolean;
}

interface IRisingMainHDPESelectorState {}

export default class RisingMainHDPESelector extends React.Component<IRisingMainHDPESelectorProps, IRisingMainHDPESelectorState> {
    constructor(props : IRisingMainHDPESelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<{ name ?: string | undefined; value : unknown }>) => {
        if (typeof event.target.value !== 'string') return;
        this.props.onChange(event.target.value);
    };

    public readonly render = () => {
        const { value, disabled } = this.props;

        return (<React.Fragment>
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!value} htmlFor='RisingMainHDPE'>Rising Main Type</InputLabel>
                    <Select
                        disabled={disabled}
                        fullWidth
                        id='value'
                        value={!value ? '' : value}
                        onChange={this.onChange}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(BOREHOLE_INSTALLATION_HDPE_TYPES, n => (
                                <MenuItem key={`${n}_RisingMainHDPE`} value={`${n}`}>
                                    {n}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        </React.Fragment>);
    };
}

import { firestore } from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { IGroundWaterZone } from '../../model';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';
import BoreholeHelper from './borehole';

export interface IBoreholeAgesRecommendation extends IBasicBaseClass {
    guid : string;

    // Date in utc milliseconds
    date : number;

    boreholeCode : string;
    // Borehole Ref Id
    borehole : string;

    designedYield : number | null;
    perfomanceYield : number | null;
    pumpingCycle : number | null;
    maximumDailyAbstraction : number | null;
    installDepthRecommendation : number | null;
    holeDepth : number | null;
    dynamicWaterLevelLimit : number | null;
    staticWaterLevel : number | null;
    criticalWaterLevel : number | null;
    groundwaterZone : IGroundWaterZone | null;

    yieldTestAnalysis : string;
    comment : string;

    url : string;
    fileName : string;

    isActive : boolean;
    isSent : boolean;
}

export default class BoreholeAgesRecommendationHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'borehole_ages_recommendation';

    private static converter : firebase.firestore.FirestoreDataConverter<IBoreholeAgesRecommendation> = {
        fromFirestore: (snapshot) => {
            return BoreholeAgesRecommendationHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBoreholeAgesRecommendation) : firebase.firestore.DocumentData => {
            return BoreholeAgesRecommendationHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IBoreholeAgesRecommendation {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IBoreholeAgesRecommendation,
            ...result,
            borehole: (data['borehole'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : IBoreholeAgesRecommendation) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            borehole: BoreholeHelper.doc(rest.borehole),
            date:firestore.Timestamp.fromMillis(rest.date),
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(BoreholeAgesRecommendationHelper.COLLECTION_NAME).withConverter(BoreholeAgesRecommendationHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(BoreholeAgesRecommendationHelper.COLLECTION_NAME).withConverter(BoreholeAgesRecommendationHelper.converter).doc(id);
    }

    public static save(boreholeAgesRecommendation : IBoreholeAgesRecommendation) {
        return BoreholeAgesRecommendationHelper.doc(boreholeAgesRecommendation.id).set(boreholeAgesRecommendation, {
            merge: true,
        });
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(BoreholeAgesRecommendationHelper.COLLECTION_NAME)
            .withConverter(BoreholeAgesRecommendationHelper.converter);
    }

    public static uploadFile(boreholeCode : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `borehole/${boreholeCode}/borehole_ages_recommendation/${new Date().valueOf()}_${file.name}`, metadata);
    }
}
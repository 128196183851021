import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import { IAuthState, IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { ITransformerInvoice, TransformerInvoiceHelper } from '../../../../@types/model/transformer/transformerInvoice';
import LPUCapturingTable from '../table/LPUCaptureTable';
import { ILPULine, ISPULine } from '../../../../@types/model/transformer/tariffs';
import TariffConfirmTable from '../table/ConfirmTable';
import GeneralFunctions from '../../../../store/general/functions';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import SPUCapturingTable from '../table/SPUCaptureTable';

interface ITransformerLPUEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    invoice : ITransformerInvoice;
    
    auth : IAuthState;
}

interface ITransformerLPUEditDialogState {
    open : boolean;
    isLoading : boolean;

    invoices : Array<ITransformerInvoice>;

    showCapture : boolean;
}

class TransformerEditDialogClass extends React.PureComponent<ITransformerLPUEditDialogProps, ITransformerLPUEditDialogState> {
    constructor(props : ITransformerLPUEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            isLoading: false,
            invoices: [],
            showCapture: true,
        };
    }
    public readonly onClick = () => {
        if (this.props.invoice.journaled) return;
        this.setState({
            open: true,
            isLoading: false,
            invoices: [{
                ...this.props.invoice,
                lines: [
                    ...this.props.invoice.lines,
                ],
                transformer: {
                    ...this.props.invoice.transformer,
                },
            }],
        });
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
            showCapture: true,
            isLoading: false,
        });
    };
    
    private readonly onCaptureSubmit = (invoices : Array<ITransformerInvoice>) => {
        this.setState({
            invoices,
            showCapture: false,
        });
    };
    
    private readonly onCancelClick = () => {
        this.setState({
            showCapture: true,
        });
    };

    private readonly onConfirmClick = () => {
        this.save();
    };

    private readonly save = async () => {
        const { auth } = this.props;

        if (!auth.session) return;

        this.setState({
            isLoading: true,
        });

        try {
            const invoices = this.state.invoices
                .map(invoice => ({
                    ...invoice,
                    lines: [ ...invoice.lines ],
                    updatedBy: auth.session?.firebaseUser.uid ?? invoice.updatedBy,
                    updatedByEmployee: auth.session?.employee.EmployeeNumber ?? invoice.updatedByEmployee,
                    updatedByName: auth.session?.employee.Name ?? invoice.updatedByName,
                    updatedOn: moment.utc().valueOf(),
                }));

            await TransformerInvoiceHelper.saveMany(invoices);
            this.onClose();
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, `${ex}`);
            this.setState({
                isLoading: false,
            });
        }
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, invoice } = this.props;
        const { open, invoices, showCapture, isLoading } = this.state;
        return (
            <div>
                <Tooltip title='Info'>
                    <div>
                        <IconButton className={'cp'} onClick={invoice.journaled ? undefined : this.onClick}
                            aria-label='Edit'>
                            <EditIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen
                    fullWidth={fullWidth}
                    onClose={this.onClose}
                    aria-labelledby='LPU-Meter-Reading-Info-dialog-title'
                    aria-describedby='LPU-Meter-Reading-Info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                EDIT INVOICE | {AppFunctionsService.formatDateTimeToDateOnly(invoice.monthDate)} | {invoice.epNumber}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 ais hfill bcg0'>
                        {
                            isLoading &&
                            <div className='fdc flx1 aic jcc'>
                                <CircularProgress />
                            </div>
                        }
                        {
                            !isLoading &&
                            showCapture &&
                            invoice.type === 'lpu' &&
                            <div className='fdc hfill mh0 mw0'>
                                <LPUCapturingTable
                                    invoices={invoices as Array<ITransformerInvoice<ILPULine>>}
                                    disabledTransformers={[]}
                                    multi={invoice.lines.length > 1}
                                    onSubmit={this.onCaptureSubmit}
                                    onCancelClick={this.onClose}
                                />

                            </div>
                        }
                        {
                            !isLoading &&
                            showCapture &&
                            invoice.type === 'spu' &&
                            <div className='fdc hfill mh0 mw0'>
                                <SPUCapturingTable
                                    invoices={invoices as Array<ITransformerInvoice<ISPULine>>}
                                    disabledTransformers={[]}
                                    multi={invoice.lines.length > 1}
                                    onSubmit={this.onCaptureSubmit}
                                    onCancelClick={this.onClose}
                                />

                            </div>
                        }
                        {
                            !isLoading &&
                            !showCapture &&
                            !!invoices.length &&
                            <div className='fdc flx1 mh0 mw0'>
                                <TariffConfirmTable
                                    invoices={invoices}
                                    group={invoice.group}
                                    disabledTransformers={[]}
                                    onCancelClick={this.onCancelClick}
                                    onConfirmClick={this.onConfirmClick}
                                />
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

const TransformerEditDialog = connect(
    mapStateToProps,
)(TransformerEditDialogClass);

export default TransformerEditDialog;

import { Card, TextField, Typography } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { Moment } from 'moment';
import React from 'react';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import BoreholeFormField from '../../../../customComponents/form/borehole/BoreholeAutocompleteFormField';
import BoreholeCalibrationDateSelector from '../../../../customComponents/form/boreholeCalibration/BoreholeCalibrationDateSelector';
import DateFormField from '../../../../customComponents/form/DateFormField';
import TextFormField from '../../../../customComponents/form/TextFormField';
import TimeFormField from '../../../../customComponents/form/TimeFormField';

interface IBoreholeConstantRateEditDialogLogisticsTabProps {
    isLoading : boolean;

    onNextClick ?: () => void;
    onCancelClick ?: () => void;
}

interface IBoreholeConstantRateEditDialogLogisticsTabState {}

export default class BoreholeConstantRateEditDialogLogisticsTab extends React.PureComponent<IBoreholeConstantRateEditDialogLogisticsTabProps, IBoreholeConstantRateEditDialogLogisticsTabState> {
    constructor(props : IBoreholeConstantRateEditDialogLogisticsTabProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { onNextClick, onCancelClick } = this.props;
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <Field 
                    id='date'
                    name='date'
                >
                    {
                        (params : FieldProps<Moment>) => <BoreholeCalibrationDateSelector {...params} />
                    }
                </Field>
                <div className='fdc ais jcc mt12'>
                    <Card className='fdc'>
                        <div className={'jcc aic wfill dvbg h50'}>
                            <Typography className={'fs16 fw400 cw'}>
                                COORDINATES
                            </Typography>
                        </div>
                        <div className='fdr'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    LATITUDE
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='latitude'
                                        name='latitude'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    LONGITUDE
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='longitude'
                                        name='longitude'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='fdr bcg2'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    ELEVATION
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id='elevation'
                                        name='elevation'
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                            </div>
                        </div>
                        <div className={'jcc aic wfill dvblg h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                BOREHOLE LOGISTICS
                            </Typography>
                        </div>
                        <div className='fdr'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    DATA RECORDER’S PERSONEL NUMBER
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'employeeNumber'}
                                        name={'employeeNumber'}
                                        fullWidth
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    DATA RECORDER’S PERSONEL NAME
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'employeeName'}
                                        name={'employeeName'}
                                        fullWidth
                                        required
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'jcc aic wfill dvbg h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                TEST PUMP
                            </Typography>
                        </div>
                        <div className='fdr'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    PUMP DEPTH (m)
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'pumpDepth'}
                                        name={'pumpDepth'}
                                        fullWidth
                                        required
                                        type={'number'}
                                    />
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    STATIC GROUNDWATER LEVEL (m)
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'staticWaterLevel'}
                                        name={'staticWaterLevel'}
                                        fullWidth
                                        required
                                        type={'number'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='fdr bcg2'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    INSTALLED PUMP (m)
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'installedPump'}
                                        name={'installedPump'}
                                        fullWidth
                                        required
                                        type={'number'}
                                    />
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    RECOMMENDED CONSTANT RATE TEST (m<sup>3</sup>/hr)
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'recommendedRate'}
                                        name={'recommendedRate'}
                                        fullWidth
                                        required
                                        type={'number'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='fdr'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    DEWATERING YIELD (m<sup>3</sup>/hr)
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'dewateringYield'}
                                        name={'dewateringYield'}
                                        fullWidth
                                        type={'number'}
                                    />
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    NEXT POSSIBLE BOREHOLE TO BE TESTED
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <BoreholeFormField
                                        name={'nextBorehole'}
                                        label={null}
                                        optional
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'jcc aic wfill bcp h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                LEVEL LOGGER
                            </Typography>
                        </div>
                        <div className='fdr bcg2'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    LEVEL LOGGER DEPTH (m)
                                </Typography>
                                <div className='fdr mr30 ml30'>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={'levelLoggerDepth'}
                                        name={'levelLoggerDepth'}
                                        fullWidth
                                        type={'number'}
                                    />
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd'}>
                                    LEVEL LOGGER
                                </Typography>
                                <div className='fdc mr30 ml30'>
                                    <div className='fdr bbw1'>
                                        <div className='fdc jcc aic flx1'>
                                            <Typography className='cp fwb pr36'>
                                                TIME
                                            </Typography>
                                        </div>
                                        <div className='fdc jcc aic flx1'>
                                            <Typography className='cp fwb pr36'>
                                                DATE
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='fdr mt10'>
                                        <div className='fdr flx1'>
                                            <TimeFormField
                                                name={'levelLoggerTime'}
                                                fullWidth
                                                required
                                                TextFieldComponent={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            className: params.inputProps + ' cp fwb',
                                                            style: {
                                                                ...params.inputProps?.style,
                                                                textAlign: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='fdr flx1'>
                                            <DateFormField
                                                name={'levelLoggerDate'}
                                                fullWidth
                                                required
                                                format='DD/MM/YYYY'
                                                TextFieldComponent={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            className: params.inputProps + ' cp fwb',
                                                            style: {
                                                                ...params.inputProps?.style,
                                                                textAlign: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='fdr'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    PUMP SWITCHED ON
                                </Typography>
                                <div className='fdc mr30 ml30'>
                                    <div className='fdr bbw1'>
                                        <div className='fdc jcc aic flx1'>
                                            <Typography className='cp fwb pr36'>
                                                TIME
                                            </Typography>
                                        </div>
                                        <div className='fdc jcc aic flx1'>
                                            <Typography className='cp fwb pr36'>
                                                DATE
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='fdr mt10'>
                                        <div className='fdr flx1'>
                                            <TimeFormField
                                                name={'levelLoggerPumpOnTime'}
                                                fullWidth
                                                required
                                                TextFieldComponent={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            className: params.inputProps + ' cp fwb',
                                                            style: {
                                                                ...params.inputProps?.style,
                                                                textAlign: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='fdr flx1'>
                                            <DateFormField
                                                name={'levelLoggerPumpOnDate'}
                                                fullWidth
                                                required
                                                format='DD/MM/YYYY'
                                                TextFieldComponent={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            className: params.inputProps + ' cp fwb',
                                                            style: {
                                                                ...params.inputProps?.style,
                                                                textAlign: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    PUMP SWITCHED OFF
                                </Typography>
                                <div className='fdc mr30 ml30'>
                                    <div className='fdr bbw1'>
                                        <div className='fdc jcc aic flx1'>
                                            <Typography className='cp fwb pr36'>
                                                TIME
                                            </Typography>
                                        </div>
                                        <div className='fdc jcc aic flx1'>
                                            <Typography className='cp fwb pr36'>
                                                DATE
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='fdr mt10'>
                                        <div className='fdr flx1'>
                                            <TimeFormField
                                                name={'levelLoggerPumpOffTime'}
                                                fullWidth
                                                required
                                                TextFieldComponent={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            className: params.inputProps + ' cp fwb',
                                                            style: {
                                                                ...params.inputProps?.style,
                                                                textAlign: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='fdr flx1'>
                                            <DateFormField
                                                name={'levelLoggerPumpOffDate'}
                                                fullWidth
                                                required
                                                format='DD/MM/YYYY'
                                                TextFieldComponent={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            className: params.inputProps + ' cp fwb',
                                                            style: {
                                                                ...params.inputProps?.style,
                                                                textAlign: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='fdr bcg2'>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                <Typography className={'aic jcc fs13 fw500 cpd mb12'}>
                                    LEVEL LOGGER OUT TIME
                                </Typography>
                                <div className='fdc mr30 ml30'>
                                    <div className='fdr bbw1'>
                                        <div className='fdc jcc aic flx1'>
                                            <Typography className='cp fwb pr36'>
                                                TIME
                                            </Typography>
                                        </div>
                                        <div className='fdc jcc aic flx1'>
                                            <Typography className='cp fwb pr36'>
                                                DATE
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='fdr mt10'>
                                        <div className='fdr flx1'>
                                            <TimeFormField
                                                name={'levelLoggerOutTime'}
                                                fullWidth
                                                required
                                                TextFieldComponent={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            className: params.inputProps + ' cp fwb',
                                                            style: {
                                                                ...params.inputProps?.style,
                                                                textAlign: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className='fdr flx1'>
                                            <DateFormField
                                                name={'levelLoggerOutDate'}
                                                fullWidth
                                                required
                                                format='DD/MM/YYYY'
                                                TextFieldComponent={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            className: params.inputProps + ' cp fwb',
                                                            style: {
                                                                ...params.inputProps?.style,
                                                                textAlign: 'center',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'fdc flx1 pb20 pt20 jcc ais'}>
                                
                            </div>
                        </div>
                    </Card>
                    <div className='fdr jcfe mt15'>
                        <div className='mr15'>
                            <OutlinedStadiumButton onClick={onCancelClick}>
                                Cancel
                            </OutlinedStadiumButton>
                        </div>
                        <div>
                            <StadiumButton onClick={onNextClick}>
                                Next
                            </StadiumButton>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

import { Typography } from '@material-ui/core';
import React from 'react';

interface ICapacityButtonProps {
    value : number;
    selectedValue : number;
    onClick : (value : number) => void; 
}

interface ICapacityButtonState {}

export default class CapacityButton extends React.PureComponent<ICapacityButtonProps, ICapacityButtonState> {
    constructor(props : ICapacityButtonProps) {
        super(props);
        this.state = {};
    }

    public readonly onClick = () => {
        this.props.onClick(this.props.value);
    };

    public readonly render = () => {
        const {value, selectedValue } = this.props;
        return (
            <div 
                color={'primary'}
                onClick={this.onClick}
                className={` ${value === selectedValue ? 'bcp' : 'bcw' }  br15 bocp aic jcc tac pt5 pb5`}
            >
                <Typography className={` ${value === selectedValue ? 'cw' : 'cp' }  fs16 fw500 `}>
                    {value}%
                </Typography>
            </div>
        );
    };
}

import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationPumpMake } from '../../../../@types/model/masterData/pumpingStation/pumpMake';

type MasterDataPumpingStationPumpMakeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationPumpMakeState {
    pumpMakes : Array<IPumpingStationPumpMake>;
    isLoading : boolean;
}
const initialState = {
    pumpMakes: [],
    isLoading: false,
};

export default function pumpMakeReducer(state : IMasterDataPumpingStationPumpMakeState = initialState, action : MasterDataPumpingStationPumpMakeActionType) : IMasterDataPumpingStationPumpMakeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, pumpMakes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

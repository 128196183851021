import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IRainMeterFormValues } from '../../../@types/model/rainMeter/rainMeter/rainMeterFormValues';
import RainMeterModelHelper from '../../../@types/model/rainMeter/rainMeter/rainMeterModelHelper';
import CustomTextField from '../../customComponentV2/CustomTextField';
import CustomLabelledCheckBox from '../../customComponentV2/CustomCheckBox';
import { Button } from '@material-ui/core';

interface IRainMeterEditFormProps {
    initialValues : IRainMeterFormValues;
    onSubmit : (values : IRainMeterFormValues) => void;
    onCancel : () => void;
}

const RainMeterEditForm = (props : IRainMeterEditFormProps) : React.ReactElement => {
    const schema = RainMeterModelHelper.formSchema();

    const { handleSubmit, control, formState: { isValid } } = useForm<IRainMeterFormValues>({
        defaultValues: props.initialValues,
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <div className={'fdc p30 pt15 pb0'}>
                    <div className={'fdc wfill pt10 jcc aic mb20'}>
                        <div className={'wfill mb15'}>
                            <CustomTextField 
                                className={'wfill'}
                                label={'Code'}
                                name={'code'}
                                control={control}
                            />
                        </div>
                        <div className={'wfill mb15'}>
                            <CustomTextField 
                                className={'wfill'}
                                label={'Latitude'}
                                name={'latitude'}
                                control={control}
                            />
                        </div>
                        <div className={'wfill mb15'}>
                            <CustomTextField 
                                className={'wfill'}
                                label={'Longitude'}
                                name={'longitude'}
                                control={control}
                            />
                        </div>
                    </div>
                    <div className='flx1 fdr jcfs'>
                        <CustomLabelledCheckBox 
                            name={'isActive'}
                            label={'Is Active'}
                            control={control}
                            defaultValue={props.initialValues.isActive}
                            disabled={props.initialValues.isActive}
                        />
                    </div>
                    <div className={'fdr flx1 aic jcfe mb20'}>
                        <div className={'jcfe'}>
                            <Button className='m10' disabled={!isValid} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button className='m10' variant='outlined' onClick={props.onCancel} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default RainMeterEditForm;
import React from 'react';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import { IAuthState, IRootState } from '../../../@types/redux';
import { IDivision } from '../../../@types/model/masterData/division';
import MaterialTable from '../../customComponents/materialTable/Table';
import DataFunctions from '../../../store/data/functions';
import DivisionEditDialog from './dialog/EditDialog';
import { Transitions } from '../../customComponents/animations/Transitions';

interface IDivisionListProps {
    auth : IAuthState;
    divisions : Array<IDivision>;
    isLoading : boolean;
}

interface IDivisionListState {
}

class DivisionList extends React.PureComponent<IDivisionListProps, IDivisionListState> {
    constructor(props : IDivisionListProps) {
        super(props);
        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        DataFunctions.getDivisions();
    };

    public readonly render = () => {
        const { isLoading, divisions } = this.props;
        
        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                    </Toolbar>
                    <Card className={'flx1 fdc mb70'}>
                        <MaterialTable<IDivision>
                            id='divisionsTable'
                            data={divisions}
                            isLoading={isLoading}
                            rowsPerPage={50}
                            externalSort
                            columns={[{
                                header: '',
                                field: 'id',
                                width: 50,
                                renderCell: row => (
                                    <div className='aic'>
                                        <DivisionEditDialog
                                            division={row}
                                            isLoading={isLoading}
                                            fullWidth
                                            maxWidth='md'
                                            transition={Transitions.Down}
                                        />
                                    </div>
                                ),
                            }, {
                                header: 'Name',
                                field: 'Name',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Division No.',
                                field: 'DivisionNo',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Accpac Desc.',
                                field: 'AccpacDesc',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Lines',
                                field: 'Line.length',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'ManagementArea',
                                field: 'ManagementArea.length',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Created By',
                                field: 'CreatedByName',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={!row.CreatedByEmployee ? '' : row.CreatedByEmployee}>
                                        <div>
                                            {
                                                row.CreatedByName
                                            }
                                        </div>
                                    </Tooltip>
                                ),
                            }, {
                                header: 'Updated By',
                                field: 'UpdatedByName',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: row => (
                                    <Tooltip title={!row.UpdatedByEmployee ? '' : row.UpdatedByEmployee}>
                                        <div>
                                            {
                                                row.UpdatedByName
                                            }
                                        </div>
                                    </Tooltip>
                                ),
                            }]}
                        />
                    </Card>
                    <div className='fdr jcfe'>
                        <DivisionEditDialog
                            isLoading={isLoading}
                            fullWidth
                            maxWidth='md'
                            transition={Transitions.Down}
                        />
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        isLoading: state.data.loadingDivisions,
        divisions: state.data.divisions,
    };
};

export default connect(
    mapStateToProps,
)(DivisionList);

import { Typography } from '@material-ui/core';
import React from 'react';
import { IDamWeeklyReading } from '../../@types/model/dam/weeklyReading';
import CarouselImageViewer from '../customComponents/image/CarouselViewer';

interface IDamDamReadingEntryViewProps {
    reading : IDamWeeklyReading;
}

interface IDDamReadingEntryViewState {
    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class DamReadingEntryView extends React.Component<IDamDamReadingEntryViewProps, IDDamReadingEntryViewState> {
    constructor(props : IDamDamReadingEntryViewProps) {
        super(props);
        this.state = {
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private readonly onThumbClick = () => {
        this.setState({
            isCarouselOpen: true,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly getImages = () => {
        const result : Array<string> = [];
        
        if (this.props.reading.imageDam) result.push(this.props.reading.imageDam.downloadURL);
        if (this.props.reading.imageMeasurestick) result.push(this.props.reading.imageMeasurestick.downloadURL);
        return result;
    };

    public readonly render = () => {
        const {reading} = this.props;
        const { isCarouselOpen, imageIndex } = this.state;
        
        return <div className={' mb20 jcfs fdc ml10'}>
            <div className={'fdr'}>
                <div className={'mt20 mb20 fdc'}>
                    <Typography className={'fs12 dcgrey'}>
                        Meter Measurement
                    </Typography>
                    <Typography className={'fs16 '}>
                        {reading.measurement ?? '0' + ' '}m
                    </Typography>
                </div>
                <div className={'mt20 mb20 fdc pl50'}>
                    <Typography className={'fs12 dcgrey'}>
                        Current Capacity
                    </Typography>
                    <Typography className={'fs16 '}>
                        {(reading.currentCapacity ?? 0) * 100 }%
                    </Typography>
                </div>                
                <div className={'mt20 mb20 fdc pl50'}>
                    <Typography className={'fs12 dcgrey'}>
                        Volume
                    </Typography>
                    <Typography className={'fs16 '}>
                        {reading.currentVolume ?? '0' + ' '}m<sup>3</sup>
                    </Typography>
                </div>
            </div>
            <div className={'mt20 mb20 fdr'}>
                <div className={'flx1 bcg0'}>
                </div>
                <img height='225' src={reading.imageDam?.thumbnailUrl ?? reading.imageDam?.downloadURL} onClick={this.onThumbClick}/>
                <div className={'flx1 bcg0'}>
                </div>
            </div>
            <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={this.getImages()} onClose={this.onCarouselClose} />
        </div>;
    };
}

import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationFilterType } from '../../../../@types/model/masterData/pumpingStation/filterType';

type MasterDataPumpingStationFilterTypeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationFilterTypeState {
    filterTypes : Array<IPumpingStationFilterType>;
    isLoading : boolean;
}
const initialState = {
    filterTypes: [],
    isLoading: false,
};

export default function filterTypeReducer(state : IMasterDataPumpingStationFilterTypeState = initialState, action : MasterDataPumpingStationFilterTypeActionType) : IMasterDataPumpingStationFilterTypeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, filterTypes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

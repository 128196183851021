import React from 'react';
import { IUserSession } from '../../../@types/employee';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { connect } from 'react-redux';
import BoreholeCalibrationHelper, { IBoreholeCalibration } from '../../../@types/model/borehole/boreholeCalibration';
import AppFunctionsService from '../../../services/appFunctionsService';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { CircularProgress } from '@material-ui/core';
import GeneralFunctions from '../../../store/general/functions';
import BoreholeCalibrationEntryView from './calibrationView/CalibrationEntryView';

interface IBoreholeCalibrationPDFViewProps {
    borehole : IBorehole;

    session : IUserSession;
}

interface IBoreholeCalibrationPDFViewState {
    selectedCalibration ?: IBoreholeCalibration;

    boreholeCalibrations : Array<IBoreholeCalibration>;
    isLoading : boolean;
}

class BoreholeCalibrationPDFViewComponent extends React.Component<IBoreholeCalibrationPDFViewProps, IBoreholeCalibrationPDFViewState> {
    private listener ?: () => void;
    constructor(props : IBoreholeCalibrationPDFViewProps) {
        super(props);
        this.state = {
            isLoading: false,
            boreholeCalibrations: [],
        };
    }

    public componentDidMount() : void {
        this.load();
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public readonly load = () => {
        if (this.listener) this.listener();

        this.setState({
            boreholeCalibrations: [],
            isLoading: true,
        });

        this.listener = BoreholeCalibrationHelper.collection()
            .where('Borehole', '==', this.props.borehole.ref)
            .onSnapshot((snapshot) => {
                this.setState({
                    boreholeCalibrations: snapshot.docs
                        .map(x => x.data())
                        .sort((a, b) => (b.Date?.toMillis() ?? 0) - (a.Date?.toMillis() ?? 0)),
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Calibrations');
            });
    };

    public readonly render = () => {
        const { session } = this.props;
        const { boreholeCalibrations, isLoading } = this.state;
        return (
            <div className={'fdc flx1'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    boreholeCalibrations.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Test Data Exists!
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!boreholeCalibrations.length &&
                    <div className='fdc flx1 ais jcfs oya oxh mt12 p10'>
                        {
                            boreholeCalibrations.map(boreholeCalibration => (
                                <SeparatedAccordion key={boreholeCalibration.ref.id}>
                                    <PrimaryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className='fdr jcc aic flx1'>
                                            {
                                                AppFunctionsService.formatDateTimeToDateOnly(boreholeCalibration.Date ?? boreholeCalibration.CreatedOn) 
                                            }
                                            <span className='flx1' />
                                        </div>
                                    </PrimaryAccordionSummary>
                                    <AccordionDetails className={'fdc flx1'}>
                                        <BoreholeCalibrationEntryView
                                            calibration={boreholeCalibration}
                                            session={session}
                                            isLoading={isLoading}
                                        />
                                    </AccordionDetails>
                                </SeparatedAccordion>
                            ))
                        }
                    </div>
                }
            </div>
        );
    };
}

const mapStateToProps = () => {
    return {};
};

const BoreholeCalibrationPDFView = connect(
    mapStateToProps,
)(BoreholeCalibrationPDFViewComponent);

export default BoreholeCalibrationPDFView;
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import lodash from 'lodash';
import DamHelper, { EnumDamSize } from '../../../../@types/model/dam/dam';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField } from '@material-ui/core';
import { createSelector } from 'reselect';

export interface IDamSizeDropDownOption {
    label : string;
    value : EnumDamSize | null;
}

export interface IDamSizeSelectorProps {
    value : EnumDamSize | null;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : EnumDamSize | null) => void;
    label ?: string;
}

interface IDamSizeSelectorState { }

class DamSizeSelector extends React.Component<IDamSizeSelectorProps, IDamSizeSelectorState> {
    constructor(props : IDamSizeSelectorProps) {
        super(props);

        this.state = {};
    }   

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? null);
    };

    private readonly getValue = (state : IDamSizeSelectorState, props : IDamSizeSelectorProps) => props.value;
    private readonly getRequired = (state : IDamSizeSelectorState, props : IDamSizeSelectorProps) => props.required;

    private readonly getSizes = () => DamHelper.DamSize;

    private readonly getSizesForDropdown = createSelector([
        this.getRequired, this.getSizes,
    ], (required, sizes) => {
        const dropdownData = lodash.map(sizes, (element, index) => ({ label: element, value: lodash.parseInt(index) } as IDamSizeDropDownOption));

        if (!required) {
            dropdownData.unshift({
                label: 'NONE',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getSizes,
    ], (value, required, sizes) => {
        if (value != null) {
            const size = sizes[value];

            if (size) {
                return { label: size, value: value } as IDamSizeDropDownOption;
            }
        }
        return null;
    });

    public readonly render = () => {

        const { required, className, disabled, label } = this.props;

        const dropdownData = this.getSizesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'size'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={ label ?? 'Wall Types' } />}  
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };

}

export default DamSizeSelector;
import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import moment from 'moment';
import AppFunctionsService from '../../../services/appFunctionsService';
import OutlinedTextField from '../textField/OutlinedTextField';

interface IPeriodDropdownProps {
    id ?: string;
    value : number | null;

    onChange : (value : number | null) => void;

    required ?: boolean;
    disabled ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    label ?: string;
    
    startMonth : number;

    variant ?: 'outlined';
}

interface IPeriodDropdownState {
}

export default class PeriodDropdown extends React.Component<IPeriodDropdownProps, IPeriodDropdownState> {
    constructor(props : IPeriodDropdownProps) {
        super(props);

        this.state = {
        };
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string | null;
        value : number | null;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? null);
    };

    private readonly getStartMonth = (state : IPeriodDropdownState, props : IPeriodDropdownProps) => props.startMonth;
    private readonly getValue = (state : IPeriodDropdownState, props : IPeriodDropdownProps) => props.value;
    private readonly getRequired = (state : IPeriodDropdownState, props : IPeriodDropdownProps) => props.required;

    private readonly getPeriods = createSelector([
        this.getStartMonth,
    ], (startMonth) => {
        return new Array(12).fill(0).map((n, i) => moment.utc(startMonth).add(i, 'month').valueOf());
    });

    private readonly getPeriodDropdown = createSelector([
        this.getRequired,
        this.getPeriods,
    ], (required, periods) => {
        const drop : Array<{
            label : string;
            value : number | null;
        }> = lodash.map(periods, (n) => ({ label: AppFunctionsService.formatDateTimeToMonthYearOnly(n), value: n }));

        if (!required) {
            drop.unshift({
                label: 'ALL',
                value: null,
            });
        }

        return drop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue,
        this.getRequired,
        this.getPeriods,
    ], (value, required, periods) => {
        if (value) {
            const period = periods.find(x => x === value);

            if (period) {
                return { label: AppFunctionsService.formatDateTimeToMonthYearOnly(period), value: period };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: null,
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, fullWidth, id, label = 'Period',
            disabled, variant } = this.props;

        const transformersDrop = this.getPeriodDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        const Component = variant === 'outlined' ? OutlinedTextField : TextField;
        
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    disabled={disabled}
                    options={transformersDrop}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <Component
                        error={required && !value} {...params} fullWidth={fullWidth}
                        disabled={disabled} label={label} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}
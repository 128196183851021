import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import { ITransformerTariff, TariffType } from '../../../@types/model/transformer/tariffs';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import '../../../style/tariffTable.scss';
import TableCell from '@material-ui/core/TableCell';
import { createSelector } from 'reselect';
import appFunctionsService from '../../../services/appFunctionsService';
import TariffEditDialog from '../../transformers/tariff/dialog/TariffEditDialog';
import Tooltip from '@material-ui/core/Tooltip';

interface ITariffTableProps {
    isLoading ?: boolean;

    tariffs : Array<ITransformerTariff>;
    type : TariffType;
}

interface ITariffTableState {
    currentPage : number;
    rowsPerPage : number;
    rowsPerPageOptions : Array<number>;
}

export default class TariffTable extends React.PureComponent<ITariffTableProps, ITariffTableState> {
    constructor(props : ITariffTableProps) {
        super(props);
        this.state = {
            currentPage: 0,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 20, 30, 50, 100],
        };
    }

    private readonly onPageChange = (event : React.MouseEvent<HTMLButtonElement> | null, page : number) => {
        this.setState({ currentPage: page });
    };

    private readonly onRowsPerPageChange = (event ?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ rowsPerPage: Number(event?.target.value) });
    };

    public readonly getTariffs = (props : ITariffTableProps) => props.tariffs;
    public readonly getCurrentPage = (props : ITariffTableProps, state : ITariffTableState) => state.currentPage;
    public readonly getRowsPerPage = (props : ITariffTableProps, state : ITariffTableState) => state.rowsPerPage;
    public readonly getList = createSelector(
        [this.getTariffs, this.getCurrentPage, this.getRowsPerPage],
        (tariffs, currentPage, rowsPerPage) => {
            return tariffs.slice((currentPage * rowsPerPage), (currentPage * rowsPerPage + rowsPerPage)).sort((a, b) => a.startDate.valueOf() > b.startDate.valueOf() ? -1 : 1);
        },
    );

    public readonly render = () => {
        const { currentPage, rowsPerPage, rowsPerPageOptions } = this.state;
        const { isLoading, tariffs, type } = this.props;

        const list = this.getList(this.props, this.state);
        return (
            <div className={'hfill fdc mh0 mw0'}>
                <div style={{ height: '5px' }}>
                    {isLoading && <LinearProgress className={'wfill'} style={{ borderRadius: '2.5px' }} />}
                </div>
                <div className={'flx1 fdc oxs oys wfill hfill'}>
                    <Table stickyHeader>
                        <TableHead className='tariff-table-header'>
                            <TableRow>
                                <TableCell className='bcp' component='th' scope='row' align='left'>
                                    Start Date
                                </TableCell>
                                <TableCell component='th' scope='row' align='left'>
                                    End Date
                                </TableCell>
                                <TableCell component='th' scope='row' align='left'>
                                    Created By
                                </TableCell>
                                <TableCell component='th' scope='row' align='left'>
                                    Created On
                                </TableCell>
                                <TableCell component='th' scope='row' align='left'>
                                    Update By
                                </TableCell>
                                <TableCell component='th' scope='row' align='left'>
                                    Updated On
                                </TableCell>
                                <TableCell component='th' scope='row' align='left'>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='tariff-table-body'>
                            {
                                list.map(row => (
                                    <TableRow key={row.ref.id}>
                                        <TableCell component='td' scope='row' align='left'>
                                            {appFunctionsService.formatDateTimeToDateOnly(row.startDate)}
                                        </TableCell>
                                        <TableCell component='td' scope='row' align='left'>
                                            {
                                                !!row.endDate &&
                                            appFunctionsService.formatDateTimeToDateOnly(row.endDate)
                                            }
                                        </TableCell>
                                        <TableCell component='td' scope='row' align='left'>
                                            <Tooltip title={row.createdByEmployee}>
                                                <div>
                                                    {
                                                        row.createdByName
                                                    }
                                                </div>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell component='td' scope='row' align='left'>
                                            {
                                                appFunctionsService.formatDateTime(row.createdOn)
                                            }
                                        </TableCell>
                                        <TableCell component='td' scope='row' align='left'>
                                            <Tooltip title={row.updatedByEmployee}>
                                                <div>
                                                    {
                                                        row.updatedByName
                                                    }
                                                </div>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell component='td' scope='row' align='left'>
                                            {
                                                appFunctionsService.formatDateTime(row.updatedOn)
                                            }
                                        </TableCell>
                                        <TableCell component='td' scope='row' align='left'>
                                            <TariffEditDialog type={type} value={row.ref.id} fullScreen enableEdit={!row.endDate} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
                <div className={'fdr aic'}>
                    <span className='flx1'></span>
                    <TablePagination
                        component='div'
                        rowsPerPageOptions={rowsPerPageOptions}
                        count={tariffs.length}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        onPageChange={this.onPageChange}
                        onRowsPerPageChange={this.onRowsPerPageChange}
                    />
                </div>
            </div>
        );
    };
}

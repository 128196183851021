import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IDam } from '../../../../@types/model/dam/dam';

interface IDamCodeSelectorProps {
    dams : Array<IDam>;
    isLoading : boolean;

    value : string;

    onChange : (value : string) => void;

    required ?: boolean;
}

interface IDamCodeSelectorState {}

class DamCodeSelector extends React.Component<IDamCodeSelectorProps, IDamCodeSelectorState> {
    constructor(props : IDamCodeSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly getDams = (props : IDamCodeSelectorProps) => props.dams;

    public readonly getDamCodes = createSelector(
        [this.getDams],
        (dams) => {
            return dams.map((e : IDam) => {
                return e.code;
            });
        },
    );

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: string | undefined | null) => {
        this.props.onChange(value ?? '');
    };

    public readonly render = () => {
        const { value, required, isLoading} = this.props;

        const codes = this.getDamCodes(this.props);

        return (
            <FormControl fullWidth={true} required={false} disabled={isLoading}>
                <Autocomplete
                    id={'code'}
                    options={codes}
                    value={value}
                    getOptionSelected={(option, val) => option === val}
                    getOptionLabel={option => option}
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={true} label={'Dam Code'} />}
                />
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        dams: state.dam.dams,
        isLoading: state.dam.loading,
    };
};

export default connect(
    mapStateToProps,
)(DamCodeSelector);

import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IChemicalFillingPointInspection } from '../../../../@types/model/fillingPoint/chemicalInspection';
import CarouselImageViewer from '../../../customComponents/image/CarouselViewer';
import { Card, Typography } from '@material-ui/core';

interface IChemicalFillingPointInspectionEntryViewProps {
    inspection : IChemicalFillingPointInspection;
}

interface IChemicalFillingPointInspectionEntryViewState {
    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class ChemicalFillingPointInspectionEntryView extends React.PureComponent<IChemicalFillingPointInspectionEntryViewProps, IChemicalFillingPointInspectionEntryViewState> {
    constructor(props : IChemicalFillingPointInspectionEntryViewProps) {
        super(props);
        this.state = {
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    public readonly getInspection = (props : IChemicalFillingPointInspectionEntryViewProps) => props.inspection;
    public readonly getImageUrls = createSelector(
        [
            this.getInspection,
        ],
        (inspection) => {
            const result : Array<string> = [];

            if (inspection.photoUrl) {
                result.push(inspection.photoUrl);
            }

            if (inspection.observationPhotoUrl) {
                result.push(inspection.observationPhotoUrl);
            }

            return result;
        }
    );

    private readonly onImageClick = (event : React.MouseEvent<HTMLImageElement>) => {
        const { inspection } = this.props;

        const url = lodash.find(inspection, (_, key) => key === event.currentTarget.id) as string;
        this.onThumbClick(url);
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.getImageUrls(this.props);
        const index = imageSrcs.indexOf(url);

        if (index < 0) return;

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { inspection } = this.props;
        const { imageIndex, isCarouselOpen } = this.state;

        const imageSrcs = this.getImageUrls(this.props);

        return (
            <div className='fdc'>
                <div className='fdr aifs jcc pt28 pb28'>
                    <Card className={'bcg0 fdc mrnlc15'}>
                        <div className={'jcc aic wfill bcpl h50'}>
                            <Typography className={'fs14 fw500 cw '}>
                                IMAGE
                            </Typography>
                        </div>
                        <div className='fdc p15 ais jcc w200 flx1'>
                            <div className='fdc mt12'>
                                <Card className='flx1 h150 curp'>
                                    {
                                        inspection.photoUrl &&
                                        <img
                                            width={'100%'}
                                            src={inspection.photoUrl}
                                            alt={inspection.photoUrl}
                                            onClick={this.onImageClick}
                                        />
                                    }
                                </Card>
                            </div>
                        </div>
                    </Card>
                    {
                        inspection.observation !== 'none' &&
                        <Card className={'bcg0 fdc mrnlc15'}>
                            <div className={'jcc aic wfill bcpl h50'}>
                                <Typography className={'fs14 fw500 cw '}>
                                    OBSERVATION
                                </Typography>
                            </div>
                            <div className='fdc p15 ais jcc w200 flx1'>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.observation}
                                </Typography>
                                {
                                    inspection.observationPhotoUrl &&
                                    <div className='fdc mt12'>
                                        <Card className='flx1 h150 curp'>
                                            {
                                                inspection.observationPhotoUrl &&
                                                <img
                                                    width={'100%'}
                                                    src={inspection.observationPhotoUrl}
                                                    alt={inspection.observationPhotoUrl}
                                                    onClick={this.onImageClick}
                                                />
                                            }
                                        </Card>
                                    </div>
                                }
                            </div>
                        </Card>
                    }
                    
                </div>
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IPumpingStation } from '../../../../@types/model/pumpingStation/pumpingStation';

interface IPumpingStationCodeSelectorProps {
    pumpingStations : Array<IPumpingStation>;
    isLoading : boolean;

    value : string;

    onChange : (value : string) => void;

    required ?: boolean;
}

interface IPumpingStationCodeSelectorState {}

class PumpingStationCodeSelector extends React.Component<IPumpingStationCodeSelectorProps, IPumpingStationCodeSelectorState> {
    constructor(props : IPumpingStationCodeSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly getPumpingStations = (props : IPumpingStationCodeSelectorProps) => props.pumpingStations;

    public readonly getPumpingStationCodes = createSelector(
        [this.getPumpingStations],
        (pumpingStations) => {
            return pumpingStations.map((e : IPumpingStation) => {
                return e.code;
            });
        },
    );

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: string | undefined | null) => {
        this.props.onChange(value ?? '');
    };

    public readonly render = () => {
        const { value, required, isLoading} = this.props;

        const codes = this.getPumpingStationCodes(this.props);

        return (
            <FormControl fullWidth={true} required={false} disabled={isLoading}>
                <Autocomplete
                    id={'code'}
                    options={codes}
                    value={value}
                    getOptionSelected={(option, val) => option === val}
                    getOptionLabel={option => option}
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={true} label={'PumpingStation Code'} />}
                />
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        pumpingStations: state.pumpingStation.pumpingStations,
        isLoading: state.pumpingStation.loading,
    };
};

export default connect(
    mapStateToProps,
)(PumpingStationCodeSelector);

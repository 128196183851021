import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IDamInspection } from '../../../../@types/model/dam/damInspection';
import DamInspectionLocationView from './LocationView';
import DamInspectionPhotoView from './PhotoView';

interface IDamInspectionWallDownstreamViewProps {
    inspection : IDamInspection;

    onImageClick ?: (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IDamInspectionWallDownstreamViewState {}

export default class DamInspectionWallDownstreamView extends React.PureComponent<IDamInspectionWallDownstreamViewProps, IDamInspectionWallDownstreamViewState> {
    constructor(props : IDamInspectionWallDownstreamViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { inspection, onImageClick } = this.props;
        
        return (
            <div className='fdr aifs jcc pt28 pb28'>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            EROSION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamWallErosion &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.downstreamWallErosion &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.downstreamWallErosion}
                                </Typography>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.downstreamWallErosionComment ?? '-'}
                                </Typography>
                                {
                                    inspection.downstreamWallErosionPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'downstreamWallErosionPhotoUrl'}
                                            photoThumbUrl={inspection.downstreamWallErosionPhotoThumbUrl}
                                            photoFileName={inspection.downstreamWallErosionPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamWallErosionLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamWallErosionLocation'
                                            lat={inspection.downstreamWallErosionLocation[0]}
                                            lng={inspection.downstreamWallErosionLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            SEEPAGE
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamWallSeepage &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            inspection.downstreamWallSeepage != null &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {
                                        `${inspection.downstreamWallSeepage}, ${inspection.downstreamWallSeepageType}, ${inspection.downstreamWallSeepageColour}`
                                    }
                                </Typography>
                                {
                                    inspection.downstreamWallSeepagePhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'ddownstreamWallSeepagePhotoUrl'}
                                            photoThumbUrl={inspection.downstreamWallSeepagePhotoThumbUrl}
                                            photoFileName={inspection.downstreamWallSeepagePhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamWallSeepageLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamWallSeepageLocation'
                                            lat={inspection.downstreamWallSeepageLocation[0]}
                                            lng={inspection.downstreamWallSeepageLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            UNWANTED VEGETATION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.downstreamWallUnwantedVegetation &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.downstreamWallUnwantedVegetation &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.downstreamWallUnwantedVegetation}
                                </Typography>
                                {
                                    inspection.downstreamWallUnwantedVegetationPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'downstreamWallUnwantedVegetationPhotoUrl'}
                                            photoThumbUrl={inspection.downstreamWallUnwantedVegetationPhotoThumbUrl}
                                            photoFileName={inspection.downstreamWallUnwantedVegetationPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.downstreamWallUnwantedVegetationLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='downstreamWallUnwantedVegetationLocation'
                                            lat={inspection.downstreamWallUnwantedVegetationLocation[0]}
                                            lng={inspection.downstreamWallUnwantedVegetationLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
            </div>
        );
    };
}

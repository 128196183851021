import { AppBar, Toolbar, Typography,Paper } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';

interface IPumpingStationConnectionsProps {
    pumpingStation : IPumpingStation;
    isLoading : boolean;
    session : IUserSession;
}

interface IPumpingStationConnectionsState {
}

export default class PumpingStationConnections extends React.PureComponent<IPumpingStationConnectionsProps, IPumpingStationConnectionsState> {
    constructor(props : IPumpingStationConnectionsProps) {
        super(props);
        this.state = {
        };
    }

    public render = () => {
        return (
            <div className='fdr  jcc bcg0 flx1 aifs'>
                <span className={'flx1'}></span>
                <Paper className='mt20 fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar variant='dense'>
                            <Typography variant='h6' className='flx1 cw'>
                                        DIVISION INFO
                            </Typography>
                            <span className={'flx1'} />
                        </Toolbar>
                    </AppBar>
                    <div className={'fdr pt30 pb30'}>
                    </div>
                </Paper>
                <span className={'flx1'}></span>
            </div>
        );
    }
}

import { Button, Card, Tooltip } from '@material-ui/core';
import React from 'react';
import { createSelector } from 'reselect';
import { ITransformerJournal, ITransformerJournalSplit } from '../../../../@types/model/transformer/journal';
import MaterialTable from '../../../customComponents/materialTable/Table';
import { CURRENCY_FORMAT } from '../../../../appConstants';
import GeneralFunctions from '../../../../store/general/functions';
import moment from 'moment';

interface ITransformerJournalSplitProps {
    value : ITransformerJournal;
}

interface ITransformerJournalSplitState {}

export default class TransformerJournalSplit extends React.PureComponent<ITransformerJournalSplitProps, ITransformerJournalSplitState> {
    constructor(props : ITransformerJournalSplitProps) {
        super(props);
        this.state = {};
    }
    
    public readonly getValue = (state : ITransformerJournalSplitState, props : ITransformerJournalSplitProps) => props.value;
    public readonly getData = createSelector(
        [this.getValue],
        (journal) : Array<ITransformerJournalSplit> => {
            return journal.lines.map((invoice) => ({
                ...invoice,
                description: `${invoice.accountNumber}_${journal.journalNumber}`,
                // This is a temporary fix... Date was set incorrectly on some
                date: !invoice.date ? ('dueDate' in journal.invoices[0] ? (moment.utc(journal.invoices[0].dueDate).format('YYYY/MM/DD')) : ('')) : invoice.date,
            }));
        },
    );

    public readonly onCSVClick = () => {
        const { value } = this.props;

        this.setState({
            isLoadingPayment: true,
        });

        try {

        
            const csvData : Array<[
                string,
                string,
                string,
                string,
                string,
                string,
                string,
                string,
                string,
            ]> = [];
    
            csvData.push([
                'ACCNUMBER',
                'Trans Amt',
                'TRANSDATE',
                'ACCTID',
                'TRANSDESC',
                'SUBAREADESCR',
                'ALLOCATORDESCR',
                'AREADESCR',
                'WAYPOINT',
            ]);
    
            const data = this.getData(this.state, this.props);
    
            data.forEach(n => {
                csvData.push([
                    n.accountNumber,
                    n.amount.toFixed(2),
                    n.date,
                    n.accpacAccountNumber,
                    n.description,
                    n.subArea,
                    n.group,
                    n.area,
                    n.waypoint,
                ]);
            });
    
            let csv = '';
            
            csvData.forEach(n => {
                csv += n.join(',');
                csv += '\n';
            });
    
    
            const url = window.URL.createObjectURL(new Blob([csv]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${value.journalNumber}_payment.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error generating file.');
        }        
    };

    public readonly render = () => {
        const data = this.getData(this.state, this.props);
        return (
            <div className='fdc flx1'>
                <div className='fdr'>
                    <div className='fdc flx1'>
                    </div>
                    <div className='fdr'>
                        <Tooltip title='Download'>
                            <div>
                                <Button variant='contained' color='primary' onClick={this.onCSVClick}>
                                    Download
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <Card className={'flx1 fdc mt15'}>
                    <MaterialTable<ITransformerJournalSplit>
                        id='transformerJournalSplitTable'
                        data={data}
                        rowsPerPage={50}
                        externalSort
                        columns={[{
                            header: 'ACCNUMBER',
                            field: 'accountNumber',
                            width: 145,
                        }, {
                            header: 'Trans Amt',
                            field: 'amount',
                            width: 145,
                            renderCell: row => (
                                <div className=''>
                                    {CURRENCY_FORMAT.format(row.amount)}
                                </div>
                            ),
                        }, {
                            header: 'TRANSDATE',
                            field: 'date',
                            width: 145,
                        }, {
                            header: 'ACCTID',
                            field: 'accpacAccountNumber',
                            width: 145,
                        }, {
                            header: 'TRANSDESC',
                            field: 'description',
                            width: 145,
                        }, {
                            header: 'SUBAREADESCR',
                            field: 'subArea',
                            width: 145,
                        }, {
                            header: 'ALLOCATORDESCR',
                            field: 'group',
                            width: 145,
                        }, {
                            header: 'AREADESCR',
                            field: 'area',
                            width: 145,
                        }, {
                            header: 'WAYPOINT',
                            field: 'waypoint',
                            width: 145,
                        }]}
                    />
                </Card>
            </div>
        );
    };
}

import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { firestore } from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import TransformerHelper, { ITransformer } from './transformer';
import { IFileMetaData } from '../files/fileMetaData';
import Decimal from 'decimal.js';

export interface ITransformerPrepaidReading extends IBasicBaseClass {
    geom : firebase.firestore.GeoPoint;
    transformerRef : string; // Transformer Id
    guid : string;

    electricityTotal : number;
    electricityUnits : number | null;
    waterTotal : number;

    electricityVat : number;
    electricityExlVat : number;
    waterVat : number;
    waterExlVat : number;

    vatTotal : number;
    amount : number;

    division : string;
    subArea : string;
    group : string;
    date : number;
    comment : string;

    imageFileName : string | null;
    imageFileUrl : string | null;
    imageFileThumbnailUrl : string | null;

    isActive ?: boolean;
}

export default class TransformerPrepaidReadingHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_prepaid_reading';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerPrepaidReading> = {
        fromFirestore: (snapshot) => {
            return TransformerPrepaidReadingHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerPrepaidReading) : firebase.firestore.DocumentData => {
            return TransformerPrepaidReadingHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerPrepaidReading {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as ITransformerPrepaidReading,
            ...result,
            date: (data['date'] as firestore.Timestamp).toMillis(),
            transformerRef: (data['transformerRef'] as firestore.DocumentReference).id,
        };
    }

    public static toFirestoreDocument(data : ITransformerPrepaidReading) {
        const result = super.toFirestore(data);
        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
            date: firestore.Timestamp.fromMillis(data.date),
            transformerRef: TransformerHelper.doc(data.transformerRef),
        };
    }
    
    public static listen(ref ?: firebase.firestore.DocumentReference<ITransformer>) {
        if (!ref) {
            return firebaseApp
                .firestore()
                .collection(TransformerPrepaidReadingHelper.COLLECTION_NAME)
                .withConverter(TransformerPrepaidReadingHelper.converter);
        }

        return firebaseApp
            .firestore()
            .collection(TransformerPrepaidReadingHelper.COLLECTION_NAME)
            .where('transformerRef', '==', ref)
            .withConverter(TransformerPrepaidReadingHelper.converter);
    }

    public static save(transaction : firestore.Transaction, reading : ITransformerPrepaidReading) {

        const save = {
            ...reading,
        };

        const ref = !reading.id ? TransformerPrepaidReadingHelper.doc() : TransformerPrepaidReadingHelper.doc(reading.id);

        save.electricityTotal = new Decimal(!save.electricityTotal ? 0 : save.electricityTotal).toNumber();
        save.electricityVat = new Decimal(save.electricityTotal)
            .mul(new Decimal(15 / 115))
            .toNumber();

        save.electricityExlVat = new Decimal(save.electricityTotal)
            .sub(new Decimal(save.electricityVat))
            .toNumber();

        save.waterTotal = new Decimal(!save.waterTotal ? 0 : save.waterTotal).toNumber();
        save.waterVat = new Decimal(0)
            .add(new Decimal(save.waterTotal))
            .mul(new Decimal(15 / 115))
            .toNumber();

        save.waterExlVat = new Decimal(0)
            .add(new Decimal(save.waterTotal))
            .sub(new Decimal(save.waterVat))
            .toNumber();

        save.vatTotal = new Decimal(save.electricityVat)
            .add(new Decimal(save.waterVat))
            .toNumber();

        save.amount = new Decimal(save.electricityTotal)
            .add(new Decimal(save.waterTotal))
            .toNumber();

        transaction.set(ref, save, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(TransformerPrepaidReadingHelper.COLLECTION_NAME).withConverter(TransformerPrepaidReadingHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(TransformerPrepaidReadingHelper.COLLECTION_NAME).withConverter(TransformerPrepaidReadingHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return firebaseApp
            .firestore()
            .collection(TransformerPrepaidReadingHelper.COLLECTION_NAME)
            .doc(id)
            .delete();
    }

    public static uploadFile(code : string, file : File, metadata : IFileMetaData) {
        return FirebaseService.fileUpload(file, `electricalpoint/${code}/prepaid_readings/${new Date().valueOf()}_${file.name}`, metadata);
    }
}
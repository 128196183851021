import { Card } from '@material-ui/core';
import React from 'react';
import CustomMapBox, { IMapMarker } from '../map/CustomMapBox';
import { createSelector } from 'reselect';

interface ILocationCardProps {
    id : string;
    lat : number;
    lng : number;

    height ?: number;
    className ?: string;
}

interface ILocationCardState {}

export default class LocationCard extends React.PureComponent<ILocationCardProps, ILocationCardState> {
    constructor(props : ILocationCardProps) {
        super(props);
        this.state = {};
    }

    public readonly getLat = (props : ILocationCardProps) => props.lat;
    public readonly getLng = (props : ILocationCardProps) => props.lng;
    
    public readonly getMarkers = createSelector(
        [
            this.getLat,
            this.getLng,
        ],
        (
            lat,
            lng,
        ) => {
            return [{
                coordinates: [lat, lng],
                iconSize: 0.7,
                icon: '1',
                id: '',
            } as IMapMarker];
        }
    );

    public readonly render = () => {
        const {
            height = 150,
            className,
            lat,
            lng,
            id,
        } = this.props;

        const markers = this.getMarkers(this.props);

        return (
            <Card className={className}
                style={{
                    height,
                    cursor: 'pointer',
                }}
            >
                <CustomMapBox
                    id={id}
                    enableAreas={false}
                    showScale={false}
                    layerControls={false}
                    mapStyle='Streets'
                    center={[
                        lat,
                        lng,
                    ]}
                    zoom={12}
                    markers={markers}
                />
            </Card>
        );
    };
}

import firebase from 'firebase/app';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { firestore } from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import TransformerHelper, { ITransformer } from './transformer';
import { EmployeeHelper, IUserSession } from '../../employee';

export interface ITransformerMunicipalityMeterReading {
    itemNumber : number;
    meterNumber : string;
    imageFileName : string | null;
    imageFileUrl : string | null;
    numberOfDigits : number;
    reading : number;
    usage : number;
    rollover : boolean;

    approved ?: boolean | null;
    approvedOn ?: firebase.firestore.Timestamp | null;
    approvedBy ?: firebase.firestore.DocumentReference | null;
    approvedByName ?: string | null;
    approvedByEmployee ?: string | null;
}

export interface ITransformerMunicipalityReading extends IBasicBaseClass {
    geom : firebase.firestore.GeoPoint;
    transformerRef : string; // Transformer Id
    guid : string;
    transformerMeterNumberReadings : Array<ITransformerMunicipalityMeterReading>;
    conditions : Array<string>;
    division : string;
    subArea : string;
    group : string;
    date : number;

    approved ?: boolean | null;
    approvedOn ?: firebase.firestore.Timestamp | null;
    approvedBy ?: firebase.firestore.DocumentReference | null;
    approvedByName ?: string | null;
    approvedByEmployee ?: string | null;
}

export default class TransformerMunicipalityReadingHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_municipality_reading';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerMunicipalityReading> = {
        fromFirestore: (snapshot) => {
            return TransformerMunicipalityReadingHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerMunicipalityReading) : firebase.firestore.DocumentData => {
            return TransformerMunicipalityReadingHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerMunicipalityReading {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as ITransformerMunicipalityReading,
            ...result,
            date: (data['date'] as firestore.Timestamp).toMillis(),
            transformerRef: (data['transformerRef'] as firestore.DocumentReference).id,
        };
    }

    public static toFirestoreDocument(data : ITransformerMunicipalityReading) {
        const result = super.toFirestore(data);
        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
            date: firestore.Timestamp.fromMillis(data.date),
            transformerRef: TransformerHelper.doc(data.transformerRef),
        };
    }
    
    public static listen(ref ?: firebase.firestore.DocumentReference<ITransformer>) {
        if (!ref) {
            return firebaseApp
                .firestore()
                .collection(TransformerMunicipalityReadingHelper.COLLECTION_NAME)
                .withConverter(TransformerMunicipalityReadingHelper.converter);
        }

        return firebaseApp
            .firestore()
            .collection(TransformerMunicipalityReadingHelper.COLLECTION_NAME)
            .where('transformerRef', '==', ref)
            .withConverter(TransformerMunicipalityReadingHelper.converter);
    }

    public static save(reading : ITransformerMunicipalityReading) {
        if (!reading.id) {
            return TransformerMunicipalityReadingHelper.doc().set(reading, {
                merge: true,
            });
        }

        return TransformerMunicipalityReadingHelper.doc(reading.id).set(reading, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(TransformerMunicipalityReadingHelper.COLLECTION_NAME).withConverter(TransformerMunicipalityReadingHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(TransformerMunicipalityReadingHelper.COLLECTION_NAME).withConverter(TransformerMunicipalityReadingHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return firebaseApp
            .firestore()
            .collection(TransformerMunicipalityReadingHelper.COLLECTION_NAME)
            .doc(id)
            .delete();
    }

    public static batchReadingSave(
        readings : Array<ITransformerMunicipalityReading>,
        session : IUserSession,
    ) {
        const batch = firebaseApp.firestore().batch();

        readings.forEach((reading) => {
            batch.update(
                this.doc(reading.id),
                {
                    isSent: false,
                    transformerMeterNumberReadings: reading.transformerMeterNumberReadings,
                    updatedBy: EmployeeHelper.doc(session.firebaseUser.uid),
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: firebase.firestore.Timestamp.now(),
                },
            );
        });

        return batch.commit();
    }
}
import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ITransformer, TransformerMeterType } from '../../../../@types/model/transformer/transformer';
import TransformerFunctions from '../../../../store/transformer/functions';
import { IRootState } from '../../../../@types/redux';
import OutlinedTextField from '../../textField/OutlinedTextField';

export interface ITransformerDropdownProps {
    id ?: string;
    value ?: string | null;

    onChange : (value ?: ITransformer) => void;

    required ?: boolean;
    disabled ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    transformers : Array<ITransformer>;
    isLoading : boolean;

    exclusionList ?: Array<ITransformer>;

    label ?: string;

    type ?: TransformerMeterType | string;

    variant ?: 'outlined';

    activeOnly ?: boolean;
}

interface ITransformerDropdownState {
}

class TransformerDropdownComponent extends React.Component<ITransformerDropdownProps, ITransformerDropdownState> {
    constructor(props : ITransformerDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        TransformerFunctions.getList();
    };

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        if (typeof value === 'string') {
            const transformer = this.props.transformers.find(x => x.EPNumber === value);
            this.props.onChange(transformer);
        } else {
            const transformer = this.props.transformers.find(x => x.EPNumber === value?.value);
            this.props.onChange(transformer);
        }
    };

    private readonly getData = (state : ITransformerDropdownState, props : ITransformerDropdownProps) => props.transformers;
    private readonly getExclusionList = (state : ITransformerDropdownState, props : ITransformerDropdownProps) => props.exclusionList;
    private readonly getValue = (state : ITransformerDropdownState, props : ITransformerDropdownProps) => props.value;
    private readonly getRequired = (state : ITransformerDropdownState, props : ITransformerDropdownProps) => props.required;
    private readonly getType = (state : ITransformerDropdownState, props : ITransformerDropdownProps) => props.type;
    private readonly getActiveOnly = (state : ITransformerDropdownState, props : ITransformerDropdownProps) => props.activeOnly;

    private readonly getTransformers = createSelector([
        this.getData, this.getExclusionList, this.getValue, this.getType, this.getActiveOnly,
    ], (transformers, exclusionList, value, type, activeOnly) => {
        return lodash
            .chain(transformers)
            .filter(n => !activeOnly || typeof n.IsActive === 'undefined' || n.IsActive)
            .filter(n => !exclusionList || n.EPNumber === value || !exclusionList.some(x => n.EPNumber === x.EPNumber))
            .filter(n => !type || n.MeterType?.toLocaleLowerCase() === type)
            .map(n => n.EPNumber)
            .sort()
            .value();
    });

    private readonly getTransformerDropdown = createSelector([
        this.getRequired,
        this.getTransformers,
        this.getValue,
    ], (
        required,
        transformers,
        value,
    ) => {
        const transformersDrop = lodash.map(transformers, (n) => ({ label: n, value: n }));

        if (value && !transformersDrop.some(x => x.value === value)) {
            transformersDrop.unshift({
                label: value,
                value: value,
            });
        }
        if (!required) {
            transformersDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return transformersDrop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getTransformers,
    ], (value, required, transformers) => {
        if (value) {
            const transformer = transformers.find(x => x === value);

            if (transformer) {
                return { label: transformer, value: transformer };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: '',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, fullWidth, isLoading, id, label = 'EP Number',
            disabled, variant } = this.props;

        const transformersDrop = this.getTransformerDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        const Component = variant === 'outlined' ? OutlinedTextField : TextField;
        
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required}>
                <Autocomplete
                    id={id}
                    disabled={disabled}
                    options={transformersDrop}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <Component
                        error={required && !value} {...params} fullWidth={fullWidth}
                        disabled={disabled} label={label} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                        <LinearProgress />
                    }
                </div>
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoading: state.transformer.loading,
    };
};

const TransformerDropdown = connect(
    mapStateToProps,
)(TransformerDropdownComponent);

export default TransformerDropdown;
import { FieldProps } from 'formik';
import React from 'react';
import { IMunicipalityOtherInvoiceFormValues, MunicipalityInvoiceHelper } from '../../../@types/model/transformer/municipalityInvoice';
import { themeVariables } from '../../../material';
import { Divider, Switch, Typography } from '@material-ui/core';
import TextFormFastField from '../../customComponents/form/TextFormFastField';
import StadiumDiv from '../../customComponents/div/Stadium';

interface IMunicipilityInvoiceOtherFormProps extends FieldProps<IMunicipalityOtherInvoiceFormValues | null> {}

interface IMunicipilityInvoiceOtherFormState {}

export default class MunicipilityInvoiceOtherForm extends React.PureComponent<IMunicipilityInvoiceOtherFormProps, IMunicipilityInvoiceOtherFormState> {
    constructor(props : IMunicipilityInvoiceOtherFormProps) {
        super(props);
        this.state = {};
    }

    private readonly onDivClick = (event : React.SyntheticEvent) => {
        this.onOtherChange(event as React.ChangeEvent<HTMLInputElement>, !this.props.field.value);
    };

    private readonly onOtherChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {

        let other : IMunicipalityOtherInvoiceFormValues | null = null;
        if (checked) {
            other = {
                propertyRates: null,
                propertyRatesRebate: null,
                yearlyPropertyTax: null,
                wasteDisposal: null,
                wasteDisposalInterest: null,
                sanitationSewerage: null,
                sanitationSewerageInterest: null,
                propertyInterest: null,
                propertyRatesTotal: 0,
                totalOtherCosts: 0,
            };
        }

        this.props.form.setFieldValue('other', other);
    };

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.field.onChange(event);

        setTimeout(() => {
            MunicipalityInvoiceHelper.calculateTotals(this.props.form);
        });
    };

    public readonly render = () => {
        const {
            field,
        } = this.props;
        return (
            <div className='fdc'>
                <div className='fdr'>
                    <div
                        className='fdr flx1 aic h50 pl25 pr25 curp'
                        onClick={this.onDivClick}
                        style={{
                            boxShadow: '0px 3px 6px #00000029',
                            backgroundColor: field.value ?
                                '#0D703F' :
                                themeVariables.colors.table.capturing.spu.totalUsage,
                        }}
                    >
                        <Typography className='cw fwm fs20'>OTHER</Typography>
                        <span className='flx1' />
                        <Switch
                            color={!field.value ? 'primary' : 'secondary'}
                            checked={!!field.value}
                            onChange={this.onOtherChange}
                        />
                    </div>
                </div>
                {
                    field.value &&
                    <div className='fdc mt15'>
                        <div className='fdr'>
                            <div className='flx1 mr10 aifs'>
                                <TextFormFastField
                                    name={'other.propertyRates'}
                                    label='Property Rates'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'other.propertyRatesRebate'}
                                    label='Property Rates Rebates'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'other.propertyInterest'}
                                    label='Property Rates Interest'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='flx1 ml10 aifs'>
                                <TextFormFastField
                                    name={'other.yearlyPropertyTax'}
                                    label='Yearly Property Tax'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <Divider className='mt15 mb15' />
                        <div className='fdr'>
                            <div className='flx1 mr10 aifs'>
                                <TextFormFastField
                                    name={'other.wasteDisposal'}
                                    label='Waste Disposal'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'other.sanitationSewerage'}
                                    label='Sanitation'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'other.wasteDisposalInterest'}
                                    label='Disposal Interest'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='flx1 ml10 aifs'>
                                <TextFormFastField
                                    name={'other.sanitationSewerageInterest'}
                                    label='Sanitation Interest'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <Divider className='mt15 mb15' />
                        <div className='fdr aic'>
                            <div className='fdr flx1 mr10'>
                                <Typography component={'span'} className='flx2 aic jcfe cpd fwm fs14'>TOTAL PROPERTY RATES:</Typography>
                                <StadiumDiv className='fdr flx1 bcg1 cpd ml15'>
                                    <div className='fdr aic jcc'>
                                        <Typography component={'span'} className='fdr jcfs flx1 fs16 fwm pr'>
                                            {
                                                field.value.propertyRatesTotal.toLocaleCurrency()
                                            }
                                        </Typography>
                                    </div>
                                </StadiumDiv>
                            </div>
                            <div className='fdr flx1 ml10'>
                                <Typography component={'span'} className='flx2 aic jcfe cpd fwm fs14'>TOTAL OTHER COSTS:</Typography>
                                <StadiumDiv className='fdr flx1 bcg1 cpd ml15'>
                                    <div className='fdr aic jcc'>
                                        <Typography component={'span'} className='fdr jcfs flx1 fs16 fwm pr'>
                                            {
                                                field.value.totalOtherCosts.toLocaleCurrency()
                                            }
                                        </Typography>
                                    </div>
                                </StadiumDiv>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

import ReactPDF, { Document, Font, Text, Page, View, Canvas } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import pdfStyles from '../../../style/pdfStyleSheet';
import QrCodeService from '../../../services/qrCodeService';
import PumpingStationHelper, { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import { Ecc } from '../../../@types/qrcodegen';

interface IPumpingStationQrCodeDocumentProps {
    onRender ?: (props : ReactPDF.OnRenderProps) => void;

    pumpingStations : Array<IPumpingStation>;
}

interface IPumpingStationQrCodeDocumentState {}

export default class PumpingStationQrCodeDocument extends React.PureComponent<IPumpingStationQrCodeDocumentProps, IPumpingStationQrCodeDocumentState> {
    private readonly documentSize = {
        width: 120,
        height: 135,
    };

    constructor(props : IPumpingStationQrCodeDocumentProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        Font.register({
            family: 'Montserrat',
            src: '/assets/fonts/Montserrat-Regular.ttf',
            format: 'truetype',
            fonts: [
                { src: '/assets/fonts/Montserrat-Regular.ttf' },
                { src: '/assets/fonts/Montserrat-Medium.ttf', fontWeight: 500 },
                { src: '/assets/fonts/Montserrat-Bold.ttf', fontWeight: 700 },
                { src: '/assets/fonts/Montserrat-SemiBold.ttf', fontWeight: 800 },
                { src: '/assets/fonts/Montserrat-ExtraBold.ttf', fontWeight: 900 },
            ],
        });
    }

    public getPumpingStations = (state : IPumpingStationQrCodeDocumentState, props : IPumpingStationQrCodeDocumentProps) => props.pumpingStations;

    public readonly getFileName = createSelector([],
        () => {
            return `pumpingStations-${moment.utc().format('YYYY-MM-DD')}.pdf`;
        },
    );

    public getPages = createSelector([
        this.getPumpingStations,
    ],
    (pumpingStations) => {
        return pumpingStations.map(pumpingStation => (
            <Page
                key={pumpingStation.id}
                orientation='portrait'
                break
                size={this.documentSize}
                style={[
                    pdfStyles.body,
                    this.documentSize,
                ]}
            >
                <View style={[
                    pdfStyles.fdc,
                    pdfStyles.flx1,
                    pdfStyles.aic,
                    pdfStyles.jcc,
                ]}>
                    <Canvas paint={(
                        painter,
                    ) => {
                        QrCodeService.paintOnPdfCanvas(
                            painter,
                            {
                                text: PumpingStationHelper.getQrCodeText(pumpingStation),
                                ecc: Ecc.HIGH,
                                scale: 1.3,
                                border: 1,
                            }
                        );

                        return null;
                    }} />
                    <View style={[
                        pdfStyles.fdc,
                        pdfStyles.aic,
                        pdfStyles.jcc,
                        pdfStyles.mt15,
                    ]}>
                        <Text
                            style={[
                                pdfStyles.fs8,
                                pdfStyles.cdg,
                                pdfStyles.fwm,
                            ]}
                        >
                            {pumpingStation.code}
                        </Text>
                        <Text
                            style={[
                                pdfStyles.fs8,
                                pdfStyles.cdg,
                                pdfStyles.fwm,
                            ]}
                        >
                            {pumpingStation.name}
                        </Text>
                    </View>
                </View>
            </Page>
        ));
    },);

    public render = () => {
        const {
            onRender,
        } = this.props;

        const pages = this.getPages(this.state, this.props);
        return (
            <Document
                title={this.getFileName(this.props)}
                subject={this.getFileName(this.props)}
                onRender={onRender}
            >
                {
                    pages
                }
            </Document>
        );
    };
}

import React from 'react';
import LocationCard from '../../../customComponents/card/LocationCard';
import { IconButton, Typography } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';

interface IDamInspectionLocationViewProps {
    id : string;
    lat : number;
    lng : number;
}

interface IDamInspectionLocationViewState {}

export default class DamInspectionLocationView extends React.PureComponent<IDamInspectionLocationViewProps, IDamInspectionLocationViewState> {
    constructor(props : IDamInspectionLocationViewProps) {
        super(props);
        this.state = {};
    }

    private readonly onClick = () => {
        const {
            lat,
            lng,
        } = this.props;

        window.open(`https://www.google.com/maps/dir/?api=1&destination=${lng},${lat}`);
    };

    public readonly render = () => {
        const {
            lat,
            lng,
            id,
        } = this.props;

        return (
            <>
                <div className='fdr'>
                    <Typography className={'flx1 fs11 fwr cpd aic jcc'}>
                        LOCATION
                    </Typography>
                    <div className='posr'>
                        <div className='fdc aic jcc posa' style={{
                            top: 0,
                            bottom: 0,
                            left: -15,
                            right: 25,
                        }}>
                            <IconButton onClick={this.onClick}>
                                <LocationOn />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className='fdr mt8'>
                    <LocationCard
                        id={id}
                        lat={lat}
                        lng={lng}
                        height={150}
                        className='flx1'
                    />
                </div>
            </>
        );
    };
}

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import firebase from '../../../services/firebaseService';
import generalFunctions from '../../../store/general/functions';

interface IFirebaseAudioButtonProps {
    path : string;
    fileName : string;
}

interface IFirebaseAudioButtonState {
    isLoading : boolean;

    downloadUrl : string;
}

export default class FirebaseAudioButton extends React.Component<IFirebaseAudioButtonProps, IFirebaseAudioButtonState> {
    constructor(props : IFirebaseAudioButtonProps) {
        super(props);
        this.state = {
            isLoading: false,
            downloadUrl: '',
        };
    }

    public readonly componentDidMount = () => {
        this.loadAudioFile();
    };

    private readonly loadAudioFile = async () => {
        try {
            const fullPath = `${this.props.path}/${this.props.fileName}`;
            const downloadUrl = await firebase.storage().ref(fullPath).getDownloadURL();

            this.setState({
                isLoading: false,
                downloadUrl,
            });
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`Error loading audio. ${ex}`);
        }
    };

    public readonly render = () => {
        const { fileName } = this.props;
        const { isLoading, downloadUrl } = this.state;
        return (
            <div className='fdr aic jcc'>
                {
                    isLoading &&
                    <CircularProgress />
                }
                {
                    downloadUrl &&
                    <audio src={downloadUrl} className='p15' title={fileName} controls preload='auto'>
                    </audio>
                }
                {
                    downloadUrl &&
                    <Tooltip title='Download'>
                        <div>
                            <IconButton color='inherit' href={downloadUrl} target='_blank' download>
                                <Icon>get_app</Icon>
                            </IconButton>
                        </div>
                    </Tooltip>
                }
            </div>
        );
    };
}

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';
import generalFunctions from '../store/general/functions';
import IDepartment from '../@types/model/department';
import { IFileMetaData } from '../@types/model/files/fileMetaData';

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
export default firebaseApp;

export const firebaseGoogleProvider = new firebase.auth.GoogleAuthProvider();

export enum EnumFileType {
    Dam = 0,
    Transformer = 1,
}

export class FirebaseService {
    private static getDepartmentsCloud = firebaseApp.functions('us-central1').httpsCallable('getDepartments');
    private static createPumpingStationCloud = firebaseApp.functions('us-central1').httpsCallable('createPumpingStation');

    /**
     * @deprecated
     * Uploads file to Firebase and then updates appropriate entity
     * field with new file name.
     * @param id ID of entity to update
     * @param fieldName field of entity to update
     * @param collectionName collection name of entity
     * @param file file to upload
     * @param path path of file to upload
     * @static
     * @memberof FirebaseService
     */
    public static uploadFileMetaData = (id : string, fieldName : string, collectionName : string, file : File, path : string) => {
        return firebase.storage().ref(path).child(file.name).put(file, {
            customMetadata: {
                fieldName,
                collectionName,
                id,
            },
        });
    };

    /**
     * @deprecated
     * Uploads a file to firebase storage.
     * @param file File to upload
     * @param path Remote Path
     * @param name Name of file
     * @param customMetadata Custom data
     * @returns Upload Task
     */
    public static uploadFile = (file : File, path : string, name : string, customMetadata : Record<string, string>) => {
        return firebase.storage().ref(path).child(name).put(file, {
            customMetadata,
        });
    };

    public static fileUpload = (file : File, remotePath : string, metadata : IFileMetaData) => {
        return firebase.storage().ref(remotePath).put(file, {
            customMetadata: metadata,
        });
    };

    public static initRemoteConfig = async () => {
        const remoteConfig = firebaseApp.remoteConfig();

        remoteConfig.settings = {
            minimumFetchIntervalMillis: 1800000,
            fetchTimeoutMillis: 30000,
        };

        remoteConfig.defaultConfig = ({
            vat: 0,
            bankAccount: '',
        });

        await remoteConfig.ensureInitialized();
        await remoteConfig.fetchAndActivate();
    };

    /**
     * Returns remote config value for vat.
     * Always returns a value, value of 0 is returned if an error occured.
     * Shows error message if there is an error.
     */
    public static getVat = () => {
        let vat = 0;

        try {
            vat = firebaseApp.remoteConfig().getNumber('vat');
        } catch (ex) {
            const error = ex as Error | null;
            generalFunctions.generalShowErrorSnackbar(error?.message ?? 'Error');
        }

        return vat;
    };

    /**
     * Returns remote config value for bank account number.
     * Always returns a value, value of 0 is returned if an error occured.
     * Shows error message if there is an error.
     */
    public static getBankAccount = () => {
        let bankAccount = '';

        try {
            bankAccount = firebaseApp.remoteConfig().getString('bankAccount');
        } catch (ex) {
            const error = ex as Error | null;
            generalFunctions.generalShowErrorSnackbar(error?.message ?? 'Error');
        }

        return bankAccount;
    };

    public static getDepartments = async () => {
        try {
            const result = await FirebaseService.getDepartmentsCloud();

            return result.data as Array<IDepartment>;
        } catch (ex) {
            const error = ex as Error;
            throw Error(error.message);
        }
    };

    public static createPumpingStation = async (name : string) => {
        try {
            await FirebaseService.createPumpingStationCloud({
                name,
            });
        } catch (ex) {
            const error = ex as Error;
            throw Error(error.message);
        }
    };
}

import firebaseApp from '../../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../../base';

export interface IPumpingStationMotorPole extends IBasicBaseClass {
    description : string;

    isActive : boolean;
}

export default class PumpingStationMotorPoleHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station_motor_pole';

    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStationMotorPole> = {
        fromFirestore: (snapshot) => {
            return PumpingStationMotorPoleHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStationMotorPole) : firebase.firestore.DocumentData => {
            return PumpingStationMotorPoleHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStationMotorPole {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStationMotorPole,
            ...result,
        };
    }

    public static toFirestoreDocument(data : IPumpingStationMotorPole) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationMotorPoleHelper.COLLECTION_NAME).withConverter(PumpingStationMotorPoleHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationMotorPoleHelper.COLLECTION_NAME).withConverter(PumpingStationMotorPoleHelper.converter).doc(id);
    }

    public static save(company : IPumpingStationMotorPole) {
        return PumpingStationMotorPoleHelper.doc(company.id).set(company, {
            merge: true,
        });
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(PumpingStationMotorPoleHelper.COLLECTION_NAME)
            .withConverter(PumpingStationMotorPoleHelper.converter);
    }
}
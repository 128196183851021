import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MotorSizeSelector from '../../customComponents/selector/borehole/MotorSizeSelector';
import MotorTypeSelector from '../../customComponents/selector/borehole/MotorTypeSelector';
import MotorPhaseSelector from '../../customComponents/selector/borehole/MotorPhaseSelector';

interface IEditBoreholeMotorSizeInfoProps {
    borehole : IBorehole;

    onChange : (borehole : IBorehole) => void;
}

interface IEditBoreholeMotorSizeInfoState {
}

export default class EditBoreholeMotorSizeInfo extends React.Component<IEditBoreholeMotorSizeInfoProps, IEditBoreholeMotorSizeInfoState> {
    constructor(props : IEditBoreholeMotorSizeInfoProps) {
        super(props);
        this.state = {
            open: false,
            motorType: '',
            motorPhase: '',
            motorDiameter: 0,
            motorSize: 0,
            motorSerial: '',
        };
    }

    private readonly onMotorDiameterChange = (motorDiameter : number) => {
        this.props.onChange({
            ...this.props.borehole,
            MotorDiameter: motorDiameter,
        });
    };

    private readonly onMotorSizeChange = (motorSize : number) => {
        this.props.onChange({
            ...this.props.borehole,
            MotorSize: motorSize,
        });
    };

    private readonly onMotorTypeChange = (motorType : string) => {
        this.props.onChange({
            ...this.props.borehole,
            MotorType: motorType,
        });
    };

    private readonly onMotorPhaseChange = (motorPhase : string) => {
        this.props.onChange({
            ...this.props.borehole,
            MotorPhase: motorPhase,
        });
    };

    private readonly onSerialChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange({
            ...this.props.borehole,
            MotorSerial: event.currentTarget.value,
        });
    };

    public readonly render = () => {
        const { borehole } = this.props;
        return (
            <>
                <div className={'fdr flx1 aifs'}>
                    <MotorTypeSelector motorType={borehole.MotorType ?? ''} onMotorTypeChange={this.onMotorTypeChange} />
                    <MotorPhaseSelector phase={borehole.MotorPhase ?? ''} onPhaseChange={this.onMotorPhaseChange} />
                    <div className={'flx1 ais p5 mb10 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='motorSerial'
                                label='Motor Serial'
                                value={borehole.MotorSerial ?? ''}
                                onChange={this.onSerialChange}
                                margin='normal'
                                className={'TextField'}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={'fdr flx1 aifs'}>
                    <MotorSizeSelector
                        diameter={borehole.MotorDiameter ?? 0}
                        size={borehole.MotorSize ?? 0}
                        onDiameterChange={this.onMotorDiameterChange}
                        onSizeChange={this.onMotorSizeChange}
                    />
                </div>
            </>
        );
    };
}

import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationFlowmeterSize } from '../../../../@types/model/masterData/pumpingStation/flowmeterSize';

type MasterDataPumpingStationFlowmeterSizeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationFlowmeterSizeState {
    flowmeterSizes : Array<IPumpingStationFlowmeterSize>;
    isLoading : boolean;
}
const initialState = {
    flowmeterSizes: [],
    isLoading: false,
};

export default function flowmeterSizeReducer(state : IMasterDataPumpingStationFlowmeterSizeState = initialState, action : MasterDataPumpingStationFlowmeterSizeActionType) : IMasterDataPumpingStationFlowmeterSizeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, flowmeterSizes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

import React from 'react';
import { IMottechInstallationAudit } from '../../../@types/mottech';
import Typography from '@material-ui/core/Typography';
import FirebaseImageButton from '../../customComponents/button/FirebaseImageButton';
import appFunctionsService from '../../../services/appFunctionsService';
import Icon from '@material-ui/core/Icon';
import MottechConnectedView from './ConnectedView';

interface IMottechInstallationAuditEntryViewProps {
    insAudit : IMottechInstallationAudit;
}

interface IMottechInstallationAuditEntryViewState {}

export default class MottechInstallationAuditEntryView extends React.Component<IMottechInstallationAuditEntryViewProps, IMottechInstallationAuditEntryViewState> {
    constructor(props : IMottechInstallationAuditEntryViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { insAudit } = this.props;
        return (
            <div className='fdc'>
                {
                    insAudit.EndOfLife &&
                    <div className='fdr flx1'>
                        <div className={'fdc flx1 m10'}>
                            <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                END OF LIFE ISSUES
                            </Typography>
                            <div className={'fdr mb10 pl10 pr10'}>
                                <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                    {
                                        (!insAudit.EndOfLifeIssues.length) &&
                                        <span>None</span>
                                    }
                                    {
                                        !!insAudit.EndOfLifeIssues.length &&
                                        <span>{insAudit.EndOfLifeIssues.join(', ')}</span>
                                    }
                                </Typography>
                            </div>
                        </div>
                    </div>
                }
                {
                    insAudit.Moved &&
                    <div className='fdc'>
                        <div className='fdr flx1'>
                            <div className={'fdc flx1 m10'}>
                                <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    MOTTECH INFO
                                </Typography>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        CODE
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        NAME
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        TYPE
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Mottech.id}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Name}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Type}
                                    </Typography>
                                </div>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        PICCOLO/PIU ID
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        SERIAL NUMBER
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        INSTALLATION DATE
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Division}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.SerialNumber}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.InstallationDate ? appFunctionsService.formatDateTimeToDateOnly(insAudit.InstallationDate) : ''}
                                    </Typography>
                                </div>
                            </div>
                            <div className={'fdc flx1 m10'}>
                                <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    DIVISION INFO
                                </Typography>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        DIVISION
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        MANAGEMENT AREA
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Division}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.ManagementArea}
                                    </Typography>
                                </div>
                                <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    LOCATION
                                </Typography>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        LATITUDE
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        LONGITUDE
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        ELEVATION
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                    ACCURACY
                                    </Typography>
                                </div>
                                <div className={'fdr mb10 pl10 pr10'}>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Geom ? insAudit.Geom.latitude : 'None'}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Geom ? insAudit.Geom.longitude : 'None'}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Geom && insAudit.Elevation ? insAudit.Elevation.toFixed(2) : 'None'}
                                    </Typography>
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        {insAudit.Geom && insAudit.Accuracy ? insAudit.Accuracy.toFixed(2) : 'None'}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className='fdr flx1'>
                            <div className={'fdc flx1 m10'}>
                                <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    INPUTS
                                </Typography>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        NUMBER
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        TYPE
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        OTHER
                                    </Typography>
                                </div>
                                {
                                    insAudit.Inputs.map(n => (
                                        <div key={`${n.InputNumber}_in`} className={'fdr mb10 pl10 pr10'}>
                                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                                {n.InputNumber}
                                            </Typography>
                                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                                {n.Name}
                                            </Typography>
                                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                                {n.Additional}
                                            </Typography>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className={'fdc flx1 m10'}>
                                <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    OUTPUTS
                                </Typography>
                                <div className={'fdr pl10 pr10 pt10 mb10'}>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        NUMBER
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        TYPE
                                    </Typography>
                                    <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                        OTHER
                                    </Typography>
                                </div>
                                {
                                    insAudit.Outputs.map(n => (
                                        <div key={`${n.InputNumber}_out`} className={'fdr mb10 pl10 pr10'}>
                                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                                {n.InputNumber}
                                            </Typography>
                                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                                {n.Name}
                                            </Typography>
                                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                                {n.Additional}
                                            </Typography>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='fdr flx1'>
                            <div className={'fdc flx1 m10'}>
                                <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    REPEATERS
                                </Typography>
                                {
                                    !insAudit.Repeater &&
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            <Icon>close</Icon>
                                        </Typography>
                                    </div>
                                }
                                {
                                    insAudit.Repeaters?.map(n => (
                                        <MottechConnectedView key={`${n}_rep`} mottechCode={n} />
                                    ))
                                }
                            </div>
                            <div className={'fdc flx1 m10'}>
                                <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                    CONNECTED TO
                                </Typography>
                                {
                                    insAudit.ConnectedTo &&
                                    <MottechConnectedView mottechCode={insAudit.ConnectedTo} />
                                }
                                {
                                    !insAudit.ConnectedTo &&
                                    <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                        <Icon>close</Icon>
                                    </Typography>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            IMAGES
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            {
                                insAudit.ImageName &&
                                <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                    Mottech ({insAudit.Mottech.id})
                                </Typography>
                            }
                        </div>
                        <div className={'fdr mb10 pl10 pr10 jcc'}>
                            <Typography className={'flx1 pl10 pr10 mnh48 jcc'} variant='subtitle1'>
                                {
                                    insAudit.ImageName &&
                                    <FirebaseImageButton fileName={insAudit.ImageName} path={`mottech/${insAudit.Mottech.id}`} />
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import BoreholeAgesRecommendationHelper, { IBoreholeAgesRecommendation } from '../../../../@types/model/borehole/boreholeAgesRecommendation';
import AppFunctionsService from '../../../../services/appFunctionsService';
import firebaseApp from '../../../../services/firebaseService';
import GeneralFunctions from '../../../../store/general/functions';
import { IUserSession } from '../../../../@types/employee';
import moment from 'moment';

interface IDeleteBoreholeAgesRecommendationProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;


    boreholeAgesRecommendation : IBoreholeAgesRecommendation;

    session : IUserSession;
}

interface IDeleteBoreholeAgesRecommendationState {
    isLoading : boolean;
    open : boolean;
}

class DeleteBoreholeAgesRecommendationComponent extends React.Component<IDeleteBoreholeAgesRecommendationProps, IDeleteBoreholeAgesRecommendationState> {
    constructor(props : IDeleteBoreholeAgesRecommendationProps) {
        super(props);
        this.state = {
            isLoading: false,
            open: false,
        };
    }

    private readonly save = async () => {
        const { boreholeAgesRecommendation } = this.props;

        const ref = BoreholeAgesRecommendationHelper.doc(boreholeAgesRecommendation.id);

        try {
            this.setState({
                isLoading: true,
            });

            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { session } = this.props;
    
                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);
    
                const data = doc.data();
    
                transaction.update(ref, {
                    isActive: !data?.isActive,
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: moment.utc().valueOf(),
                    isSent: false,
                });
            });

            this.setState({
                open: false,
            });
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving recommendation.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
        
    };

    private readonly onSubmit = (event : React.FormEvent) => {
        event.preventDefault();
        this.save();
    };

    private readonly onClick = (event : React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            boreholeAgesRecommendation,
        } = this.props;

        const {
            open,
            isLoading,
        } = this.state;

        return (
            <React.Fragment>
                <Tooltip title={boreholeAgesRecommendation.isActive ? 'Delete' : 'Restore'}>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Delete' disabled={isLoading}>
                            <Icon>{boreholeAgesRecommendation.isActive ? 'delete' : 'restore'}</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-ages-delete-dialog-title'
                    aria-describedby='borehole-ages-delete-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Delete Recommendation - {AppFunctionsService.formatDateTimeToDatePicker(boreholeAgesRecommendation.date)}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill oya'>
                        <DialogContent className='fdc flx1 hfill'>
                            <Typography variant='subtitle1'>
                                Mark AGES Recommendation as {boreholeAgesRecommendation.isActive ? 'inactive' : 'active'}?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary'>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}
const DeleteBoreholeAgesRecommendation = DeleteBoreholeAgesRecommendationComponent;

export default DeleteBoreholeAgesRecommendation;
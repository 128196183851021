import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationFlowmeterMake } from '../../../../@types/model/masterData/pumpingStation/flowMeterMake';

type MasterDataPumpingStationFlowmeterMakeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationFlowmeterMakeState {
    flowmeterMakes : Array<IPumpingStationFlowmeterMake>;
    isLoading : boolean;
}
const initialState = {
    flowmeterMakes: [],
    isLoading: false,
};

export default function flowmeterMakeReducer(state : IMasterDataPumpingStationFlowmeterMakeState = initialState, action : MasterDataPumpingStationFlowmeterMakeActionType) : IMasterDataPumpingStationFlowmeterMakeState {
    switch (action.type) {
    case getType(actions.set):
        return { ...state, flowmeterMakes: action.payload };
    case getType(actions.setLoading):
        return { ...state, isLoading: action.payload };
    default:
        return state;
    }
}

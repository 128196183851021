import { Card, Checkbox, Toolbar, Tooltip } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import TransformerJournalHelper, { ITransformerJournal } from '../../../../@types/model/transformer/journal';
import { ITransformerInvoice } from '../../../../@types/model/transformer/transformerInvoice';
import { IAuthState, IRootState } from '../../../../@types/redux';
import { CURRENCY_FORMAT } from '../../../../appConstants';
import AppFunctionsService from '../../../../services/appFunctionsService';
import GeneralFunctions from '../../../../store/general/functions';
import ConfirmInternalDialog from '../../../customComponents/dialog/ConfirmInternalDialog';
import MaterialTable from '../../../customComponents/materialTable/Table';
import { IMunicipalityInvoice } from '../../../../@types/model/transformer/municipalityInvoice';
import { ISPSInvoice } from '../../../../@types/model/transformer/spsInvoice';

interface ITransformerJournalInvoicesViewProps {
    value : ITransformerJournal;

    auth : IAuthState;
}

interface ITransformerJournalInvoicesViewState {
    isLoading : boolean;
    
    selectedInvoices : Array<ITransformerInvoice | IMunicipalityInvoice | ISPSInvoice>;
}

class TransformerJournalInvoicesViewComponent extends React.PureComponent<ITransformerJournalInvoicesViewProps, ITransformerJournalInvoicesViewState> {
    constructor(props : ITransformerJournalInvoicesViewProps) {
        super(props);
        this.state = {
            selectedInvoices: [],
            isLoading: false,
        };
    }

    private readonly onCheckboxClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const selectedInvoices = this.state.selectedInvoices.slice();
        const invoice = this.props.value.invoices.slice().find(x => x.id === (event.target as HTMLButtonElement).value);

        if (invoice) selectedInvoices.upsert(invoice);

        this.setState({
            selectedInvoices,
        });
    };

    private readonly onReverseClose = (result : boolean) => {
        if (!result || this.state.isLoading) return;
        this.setState({
            isLoading: true,
        });

        if (this.props.value.type !== 'lpu' && this.props.value.type !== 'spu') return;

        TransformerJournalHelper.reverseInvoices(this.props.value, this.state.selectedInvoices as Array<ITransformerInvoice>, this.props.auth)
            .then(() => {
                GeneralFunctions.generalShowSuccessSnackbar('Success');
                this.setState({
                    selectedInvoices: [],
                });
            })
            .catch((ex) => {
                GeneralFunctions.generalShowErrorSnackbar(`${ex?.message ?? ex ?? 'Error reversing invoices'}`);
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    public readonly render = () => {
        const { value } = this.props;
        const { selectedInvoices, isLoading } = this.state;
        return (
            <>

                <Toolbar disableGutters variant='dense'>
                    <span className='flx1' />
                    {
                        !!selectedInvoices.length &&
                        <ConfirmInternalDialog
                            buttonText='REVERSE'
                            tooltipText='Reverse'
                            disabled={isLoading}
                            message='Reverse selected invocies?'
                            title='Reverse Invoices'
                            onClose={this.onReverseClose}
                            fullWidth
                            maxWidth='sm'
                        />
                    }
                </Toolbar>
                <Card className={'flx1 fdc'}>
                    <MaterialTable<ITransformerInvoice | IMunicipalityInvoice | ISPSInvoice>
                        id='transformerInvoicesTable'
                        data={value.invoices}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        columns={[{
                            header: '',
                            field: 'id',
                            paddingLeft: 15,
                            width: 25,
                            renderCell: row => (
                                <div className='fdr aic jcc'>
                                    {
                                        !row.reverted &&
                                        <Checkbox
                                            value={row.id}
                                            checked={selectedInvoices.some(invoice => invoice.id === row.id)}
                                            color='primary'
                                            onClick={this.onCheckboxClick}
                                        />
                                    }
                                </div>
                            ),
                        }, {
                            header: 'Month',
                            field: 'monthDate',
                            width: 75,
                            paddingLeft: 15,
                            renderCell: row => (
                                <div className='aic'>
                                    {AppFunctionsService.formatDateTimeToMonthYearOnly(row.monthDate)}
                                </div>
                            ),
                        }, {
                            header: 'Way Point',
                            field: 'epNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Pole No',
                            field: 'poleNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Account No',
                            field: 'accountNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Total Usage',
                            field: 'totalUsage',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Days',
                            field: 'days',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Total Charge',
                            field: 'totalCharge',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {CURRENCY_FORMAT.format('totalCharge' in row ? row.totalCharge : row.totalExVat)}
                                </div>
                            ),
                        }, {
                            header: 'VAT',
                            field: 'vatCharge',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {CURRENCY_FORMAT.format('vatCharge' in row ? row.vatCharge : row.totalVat)}
                                </div>
                            ),
                        }, {
                            header: 'Total Due',
                            field: 'totalDue',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {CURRENCY_FORMAT.format('totalDue' in row ? row.totalDue : row.totalIncVat)}
                                </div>
                            ),
                        }, {
                            header: 'Total Billed',
                            field: 'totalBilled',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {CURRENCY_FORMAT.format('totalBilled' in row ? row.totalBilled : (row.rebillTotal ? row.rebillTotal : row.totalIncVat))}
                                </div>
                            ),
                        }, {
                            header: 'Created By',
                            field: 'createdByName',
                            width: 145,
                            renderCell: row => (
                                <Tooltip title={row.createdByEmployee}>
                                    <div>
                                        {
                                            row.createdByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }]}
                    />
                </Card>
            </>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

const TransformerJournalInvoicesView = connect(
    mapStateToProps,
)(TransformerJournalInvoicesViewComponent);

export default TransformerJournalInvoicesView;

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import DamHelper from '../../../../@types/model/dam/dam';

interface IDamTypeSelectorProps {
    value : string;

    onChange : (value : string) => void;

    required ?: boolean;
}

interface IDamTypeSelectorState {}

export default class DamTypeSelector extends React.Component<IDamTypeSelectorProps, IDamTypeSelectorState> {
    constructor(props : IDamTypeSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onDamTypeChange = (event : React.ChangeEvent<{ name ?: string | undefined; value : unknown }>) => {

        if (typeof event.target.value !== 'string') return;

        this.props.onChange(event.target.value);
    };

    public readonly render = () => {
        const { value, required } = this.props;

        return (
            <FormControl margin='normal' fullWidth>
                <InputLabel shrink={!!value} required={required} htmlFor='damType'>Dam Type</InputLabel>
                <Select
                    fullWidth
                    id='damType'
                    value={!value ? '' : value}
                    required={required}
                    error={required && !value}
                    onChange={this.onDamTypeChange}>
                    <MenuItem value=''>
                        <em>None</em>
                    </MenuItem>
                    {
                        lodash.map(DamHelper.DamType, n => (
                            <MenuItem key={`${n}_DamType`} value={`${n}`}>
                                {n}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    };
}

import * as actions from './actions';
import { getType } from 'typesafe-actions';
import { IFlowmeterState, FlowmeterAction } from '../../@types/redux';

const initialState = {
    flowmeters: [],
    selectedFlowmeter: null,
    loading: false,
};

export default function reducer(state : IFlowmeterState = initialState, action : FlowmeterAction) : IFlowmeterState {
    switch (action.type) {
        case getType(actions.flowmeterSetList):
            return { ...state, flowmeters: action.payload };
        case getType(actions.flowmeterSetSelected):
            return { ...state, selectedFlowmeter: action.payload };
        case getType(actions.flowmeterSetLoading):
            return { ...state, loading: action.payload };
        default:
            return state;
    }
}

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

interface IDropdownButtonProps {}

interface IDropdownButtonState {}

export default class DropdownButton extends React.PureComponent<IDropdownButtonProps, IDropdownButtonState> {
    constructor(props : IDropdownButtonProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        return (
            <Tooltip title='Open'>
                <div>
                    <IconButton size='small'>
                        <Icon>arrow_drop_down</Icon>
                    </IconButton>
                </div>
            </Tooltip>
        );
    };
}

import firebaseApp from '../../../services/firebaseService';

export interface IDivision {
    Id : string;
    Name : string;
    Line : Array<string>;
    ManagementArea : Array<string>;

    DivisionNo : string | null;
    AccpacDesc : string | null;

    CreatedBy : firebase.firestore.DocumentReference;
    CreatedByEmployee : string;
    CreatedByName : string;
    CreatedOn : firebase.firestore.Timestamp;
    UpdatedBy : firebase.firestore.DocumentReference;
    UpdatedByName : string;
    UpdatedByEmployee : string;
    UpdatedOn : firebase.firestore.Timestamp;
    IsActive : boolean;
}

export default class DivisionHelper {
    public static readonly COLLECTION_NAME = 'division';

    private static converter : firebase.firestore.FirestoreDataConverter<IDivision> = {
        fromFirestore: (snapshot) => {
            return DivisionHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IDivision) : firebase.firestore.DocumentData => {
            return DivisionHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IDivision {
        const data = snapshot.data();

        if (!snapshot.exists || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IDivision,
            IsActive: data.IsActive ?? true,
            Name: !data.Name ? snapshot.id : data.Name,
            Id: snapshot.id,
            Line: data.Line ?? [],
            ManagementArea: data.ManagementArea ?? [],
            DivisionNo: data.DivisionNo ?? null,
            AccpacDesc: data.AccpacDesc ?? null,
        };
    }

    public static toFirestoreDocument(data : IDivision) {
        const { Id:_id, ...rest } = data;

        return {
            ...rest,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(DivisionHelper.COLLECTION_NAME).withConverter(DivisionHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(DivisionHelper.COLLECTION_NAME).withConverter(DivisionHelper.converter).doc(id);
    }

    public static save(division : IDivision) {
        return DivisionHelper.doc(division.Id).set(division, {
            merge: true,
        });
    }
    
    public static listen() {
        return firebaseApp
            .firestore()
            .collection(DivisionHelper.COLLECTION_NAME)
            .withConverter(DivisionHelper.converter);
    }
}
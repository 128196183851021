import { AccordionDetails, CircularProgress, Icon, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IUserSession } from '../../../@types/employee';
import { IDam } from '../../../@types/model/dam/dam';
import DamVNotchReadingHelper, { IDamVNotchReading } from '../../../@types/model/dam/vNotchReading';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import GeneralFunctions from '../../../store/general/functions';
import moment, { Moment } from 'moment';
import { DatePicker } from '@material-ui/pickers';
import SecondaryTabs from '../../customComponents/tab/SecondaryTabs';
import SecondaryTab from '../../customComponents/tab/SecondaryTab';
import TabViews from '../../customComponents/tab/TabViews';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppFunctionsService from '../../../services/appFunctionsService';
import { IRootState } from '../../../@types/redux';
import { IDamWeeklyReading } from '../../../@types/model/dam/weeklyReading';

interface IDamSeepageProps {
    dam : IDam;
    session : IUserSession;

    monthlyReadings : Array<IDamWeeklyReading>;
}

interface IDamSeepageState {
    isCarouselOpen : boolean;
    isLoading : boolean;
    imageIndex : number;

    fromDate : Moment;
    toDate : Moment;

    readings : Array<IDamVNotchReading>;

    tabIndex : number;
}

class DamSeepage extends React.PureComponent<IDamSeepageProps, IDamSeepageState> {
    private listener ?: () => void;

    constructor(props : IDamSeepageProps) {
        super(props);
        this.state = {
            isCarouselOpen: false,
            imageIndex: 0,
            readings: [],
            isLoading: true,
            fromDate: moment.utc().startOf('day').subtract(1, 'month'),
            toDate: moment.utc().startOf('day'),
            tabIndex: 0,
        };
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public componentDidMount() : void {
        this.load();
    }

    public readonly load = () => {
        if (this.listener) this.listener();

        this.setState({
            readings: [],
            isLoading: true,
        });

        this.listener = DamVNotchReadingHelper.listen(this.props.dam.id)
            .onSnapshot((snapshot) => {
                if (!this.listener) return;
                this.setState({
                    readings: snapshot.docs.map(x => x.data()).sort((a, b) => b.date - a.date),
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Seepage Readings');
            }, () => {
                this.listener = undefined;
            });
    };

    private readonly onThumbClick = (event : React.MouseEvent<HTMLImageElement>) => {
        this.setState({
            isCarouselOpen: true,
            imageIndex: Number(event.currentTarget.id),
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly getReadings = (props : IDamSeepageProps, state : IDamSeepageState) => state.readings;
    public readonly getMonthlyReadings = (props : IDamSeepageProps) => props.monthlyReadings;
    public readonly getFromDate = (props : IDamSeepageProps, state : IDamSeepageState) => state.fromDate;
    public readonly getToDate = (props : IDamSeepageProps, state : IDamSeepageState) => state.toDate;

    public readonly getFilteredData = createSelector(
        [
            this.getReadings,
            this.getFromDate,
            this.getToDate,
        ],
        (
            readings,
            fromDate,
            toDate,
        ) => {
            return readings
                .filter(x => x.date >= fromDate.valueOf())
                .filter(x => x.date <= toDate.valueOf());
        }
    );

    public readonly getMonthlyFilterData = createSelector(
        [
            this.getMonthlyReadings,
            this.getFromDate,
            this.getToDate,
        ],
        (
            readings,
            fromDate,
            toDate,
        ) => {
            return readings
                .filter(x => x.date.toMillis() >= fromDate.valueOf())
                .filter(x => x.date.toMillis() <= toDate.valueOf());
        }
    );

    public readonly getImages = createSelector(
        [this.getMonthlyFilterData],
        (readings) => {
            return readings
                .filter(x => !!x.imageDam)
                .map(n => n.imageDam?.downloadURL ?? '');
        }
    );
    
    public readonly getHeights = createSelector(
        [this.getFilteredData],
        (readings) => {
            return readings
                .map(x => ({
                    time: x.date,
                    value: x.measuredHeight, 
                }))
                .sort((a, b) => a.time - b.time);
        }
    );
    
    public readonly getFlow = createSelector(
        [this.getFilteredData],
        (readings) => {
            return readings
                .map(x => ({
                    time: x.date,
                    value: x.measuredFlow, 
                }))
                .sort((a, b) => a.time - b.time);
        }
    );

    private readonly onFromChanged = (momentDate : moment.Moment | null) => {
        if (!momentDate) return;
        this.setState({
            fromDate: moment.utc(momentDate),
        });
    };

    private readonly onToChanged = (momentDate : moment.Moment | null) => {
        if (!momentDate) return;
        this.setState({
            toDate: moment.utc(momentDate),
        });
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly render = () => {
        const { 
            isCarouselOpen,
            imageIndex,
            fromDate,
            toDate,
            tabIndex,
            isLoading,
        } = this.state;

        const monthlyReadings = this.getMonthlyFilterData(this.props, this.state);
        const imageUrls = this.getImages(this.props, this.state);

        const heights = this.getHeights(this.props, this.state);
        const flows = this.getFlow(this.props, this.state);

        return (
            <div className={'fdc flx1 pt20 pb20 pl20 pr20 bcg0'}>
                <div className={'fdr mt15 mb15'}>
                    <div className='fdr flx1 mr15'>
                        <DatePicker
                            value={fromDate}
                            onChange={this.onFromChanged}
                            format='YYYY-MM-DD'
                            label='Select from Date'
                            id='fromDate'
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Icon>date_range</Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className='fdr flx1 ml15'>
                        <DatePicker
                            value={toDate}
                            onChange={this.onToChanged}
                            format='YYYY-MM-DD'
                            label='Select to Date'
                            id='toDate'
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Icon>date_range</Icon>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <span className='fdr flx3' />
                </div>
                {
                    !isLoading &&
                    !monthlyReadings.length &&
                    !heights.length &&
                    !flows.length &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No readings
                        </Typography>
                    </div>
                }
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !!heights.length &&
                    !!flows.length &&
                    <div className='fdc ais jcfs mt12 mnh350'>
                        <div className='fdc oxh'>
                            <Paper>
                                <SecondaryTabs
                                    value={tabIndex}
                                    onChange={this.onTabChange}
                                    variant='fullWidth'
                                >
                                    <SecondaryTab label='MEASURE HEIGHT' value={0} />
                                    <SecondaryTab label='MEASURE FLOW' value={1} />
                                </SecondaryTabs>
                                <div className={'fdc'}>
                                    <TabViews index={tabIndex}>
                                        <div className={'fdc p10 aic jcc bcDarkBlue h250'}>
                                            <ResponsiveContainer
                                                width={'80%'}
                                                height={'80%'}
                                            >
                                                <LineChart
                                                    className={''} data={heights}>
                                                    <XAxis
                                                        tick={{
                                                            fill: 'white',
                                                        }}
                                                        dataKey='time'
                                                        tickFormatter={(label : number) => `${label.toMoment().asDateOnly()}`}
                                                    />
                                                    <YAxis tick={{
                                                        fill: 'white',
                                                    }} domain={[0, 'dataMax + 5']} reversed/>
                                                    <CartesianGrid />
                                                    <Tooltip
                                                        itemStyle={{
                                                            color: 'black',
                                                        }}
                                                        labelStyle={{ color: 'black' }}
                                                        labelFormatter={(label : number) => `${label.toMoment().asDateOnly()}`}
                                                        formatter={(value) => `${value}m`}
                                                    />
                                                    <Line strokeWidth={3} type='monotone' dataKey='value' stroke='white' />
                                                </LineChart>
                                            </ResponsiveContainer>
                                            <Typography className={'fs14 fwb pb10 aic jcc cw'} color='inherit' variant='subtitle1'>
                                                Date
                                            </Typography>
                                        </div>
                                        <div className={'flx1 fdc aic jcc p10 h250'}>
                                            <ResponsiveContainer
                                                width={'80%'}
                                                height={'80%'}
                                            >
                                                <LineChart className={''} data={flows}>
                                                    <XAxis
                                                        dataKey='time'
                                                        tickFormatter={(label : number) => `${label.toMoment().asDateOnly()}`}
                                                    />
                                                    <YAxis domain={[0, 'dataMax + 5']}/>
                                                    <CartesianGrid />
                                                    <Tooltip
                                                        itemStyle={{
                                                            color: 'black',
                                                        }}
                                                        labelStyle={{ 
                                                            color: 'black',
                                                        }}
                                                        labelFormatter={(label : number) => `${label.toMoment().asDateOnly()}`}
                                                        formatter={(value) => `${value}m³/h`}
                                                    />
                                                    <Line strokeWidth={3} type='monotone' dataKey='value' stroke='#2294FC' />
                                                </LineChart>
                                            </ResponsiveContainer>
                                            <Typography className={'fs14 fwb aic jcc cg3'} color='inherit' variant='subtitle1'>
                                                Time (min)
                                            </Typography>
                                        </div>
                                        <div />
                                    </TabViews>
                                </div>
                            </Paper>
                        </div>
                    </div>
                }
                {
                    !isLoading &&
                    !!monthlyReadings.length &&
                    <div className='fdc oya pl5 pr5 drawer'>
                        {
                            monthlyReadings.map((reading, i) => (
                                <SeparatedAccordion key={reading.id}>
                                    <PrimaryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className='fdr jcc aic flx1'>
                                            {
                                                AppFunctionsService.formatDateTimeToDateOnly(reading.date) 
                                            }
                                            <span className='flx1' />
                                        </div>
                                    </PrimaryAccordionSummary>
                                    <AccordionDetails className={'fdc flx1'}>
                                        <div className='fdr'>
                                            <div className='fdc flx1 aifs jcfs mr15'>
                                                <TextField
                                                    id='measuredHeight'
                                                    name='measuredHeight'
                                                    fullWidth
                                                    type='number'
                                                    label='Meter Measurement'
                                                    value={reading.measurement ?? '-'}
                                                />
                                            </div>
                                            <div className='fdc flx1 aifs jcfs mr15 ml15'>
                                                <TextField
                                                    id='currentCapacity'
                                                    name='currentCapacity'
                                                    fullWidth
                                                    type='number'
                                                    label='Current Capacity'
                                                    value={reading.currentCapacity ?? '-'}
                                                />
                                            </div>
                                            <div className='fdc flx1 aifs jcfs mr15 ml15'>
                                                <TextField
                                                    id='currentVolume'
                                                    name='currentVolume'
                                                    fullWidth
                                                    type='number'
                                                    label='Volume m³'
                                                    value={reading.currentVolume ?? '-'}
                                                />

                                            </div>
                                            {
                                                reading.imageDam &&
                                                <>
                                                    <div className='fdc w150 aifs jcfs mr15 ml15'>
                                                        <Typography className='fs15 fwm cpd'>
                                                            PHOTO:
                                                        </Typography>
                                                    </div>
                                                    <div className='fdc flx1 aic jcfs ml15'>
                                                        <img
                                                            id={`${i}`}
                                                            className='curp'
                                                            height='225'
                                                            src={reading.imageDam.thumbnailUrl ?? ''}
                                                            onClick={this.onThumbClick}
                                                        />
                                                    </div>
                                                </>

                                            }
                                        </div>
                                    </AccordionDetails>
                                </SeparatedAccordion>
                            ))
                        }
                        <span className='h20' />
                    </div>
                }
                {
                    !!imageUrls.length &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageUrls} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}
const mapStateToProps = (state : IRootState) => {
    return {
        monthlyReadings: state.dam.readings,
    };
};

export default connect(
    mapStateToProps,
)(DamSeepage);
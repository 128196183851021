import { AccordionDetails, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChemicalFillingPointHelper, { IChemicalFillingPoint } from '../../../../@types/model/fillingPoint/chemical';
import ChemicalFillingPointInspectionHelper, { IChemicalFillingPointInspection } from '../../../../@types/model/fillingPoint/chemicalInspection';
import GeneralFunctions from '../../../../store/general/functions';
import SeparatedAccordion from '../../../customComponents/expansion/SeparatedAccordion';
import PrimaryAccordionSummary from '../../../customComponents/expansion/PrimaryAccordionSummary';
import AppFunctionsService from '../../../../services/appFunctionsService';
import ChemicalFillingPointInspectionEntryView from './ChemicalFillingPointInspectionEntryView';

interface IChemicalFillingPointInspectionViewProps {
    point : IChemicalFillingPoint;
}

interface IChemicalFillingPointInspectionViewState {
    inspections : Array<IChemicalFillingPointInspection>;
    isLoading : boolean;
}

export default class ChemicalFillingPointInspectionView extends React.PureComponent<IChemicalFillingPointInspectionViewProps, IChemicalFillingPointInspectionViewState> {
    private listener ?: () => void;

    constructor(props : IChemicalFillingPointInspectionViewProps) {
        super(props);
        this.state = {
            isLoading: false,
            inspections: [],
        };
    }

    public componentWillUnmount() : void {
        if (this.listener) this.listener();
    }

    public componentDidMount() : void {
        this.load();
    }

    public readonly load = () => {
        if (this.listener) this.listener();

        this.setState({
            inspections: [],
            isLoading: true,
        });

        this.listener = ChemicalFillingPointInspectionHelper.collection()
            .where('pointRef', '==', ChemicalFillingPointHelper.doc(this.props.point.id))
            .onSnapshot((snapshot) => {
                if (!this.listener) return;
                this.setState({
                    inspections: snapshot.docs.map(x => x.data()).sort((a, b) => b.date - a.date),
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Inspections');
            }, () => {
                this.listener = undefined;
            });
    };

    public readonly render = () => {
        const { inspections, isLoading } = this.state;

        return (
            <div className={'fdc flx1 bcg0 oya'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    inspections.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Records
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!inspections.length &&
                    <div className='fdc flx1 ais jcfs oya oxh pt20 pb20 pl20 pr20 drawer'>
                        {
                            inspections.map(inspection => (
                                <SeparatedAccordion key={inspection.id}>
                                    <PrimaryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className='fdr jcc aic flx1'>
                                            {
                                                AppFunctionsService.formatDateTimeToDateOnly(inspection.date) 
                                            }
                                            <span className='flx1' />
                                        </div>
                                    </PrimaryAccordionSummary>
                                    <AccordionDetails className={'fdc p0'}>
                                        <ChemicalFillingPointInspectionEntryView inspection={inspection}/>
                                    </AccordionDetails>
                                </SeparatedAccordion>
                            ))
                        }
                    </div>
                }
            </div>
        );
    };
}

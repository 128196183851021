import { AccordionDetails, CircularProgress, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import BoreholeAgesRecommendationHelper, { IBoreholeAgesRecommendation } from '../../../@types/model/borehole/boreholeAgesRecommendation';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import DeleteBoreholeAgesRecommendation from '../dialog/agesRecommendation/DeleteDialog';
import EditBoreholeAgesRecommendation from '../dialog/agesRecommendation/EditDialog';
import AgesRecommendationEntryView from './AgesRecommendationEntryView';

interface IBoreholeAgesRecommendationViewProps {
    borehole : IBorehole;

    session : IUserSession;
}

interface IBoreholeAgesRecommendationViewState {
    isLoading : boolean;
    recommendations : Array<IBoreholeAgesRecommendation>;
}

export default class BoreholeAgesRecommendationView extends React.PureComponent<IBoreholeAgesRecommendationViewProps, IBoreholeAgesRecommendationViewState> {
    private listener ?: () => void;
    constructor(props : IBoreholeAgesRecommendationViewProps) {
        super(props);
        this.state = {
            isLoading: false,
            recommendations: [],
        };
    }

    public componentDidMount() : void {
        this.load();
    }

    public readonly load = () => {
        if (this.listener) this.listener();

        this.setState({
            recommendations: [],
            isLoading: true,
        });

        this.listener = BoreholeAgesRecommendationHelper.collection()
            .where('boreholeCode', '==', this.props.borehole.Code)
            .orderBy('date', 'desc').onSnapshot((snapshot) => {
                this.setState({
                    recommendations: snapshot.docs.map(x => x.data()),
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Recommendations');
            });
    };

    public readonly render = () => {
        const { session } = this.props;
        const { isLoading, recommendations } = this.state;
        return (
            <div className={'fdc flx1 m10'}>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    recommendations.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Test Data Exists!
                        </Typography>
                    </div>
                }
                {
                    !isLoading &&
                    !!recommendations.length &&
                    <div className='fdc flx1 ais jcfs oya oxh'>
                        {
                            recommendations.map(recommendation => (
                                <SeparatedAccordion key={recommendation.id}>
                                    <PrimaryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className='fdr jcc aic flx1'>
                                            {
                                                AppFunctionsService.formatDateTimeToDateOnly(recommendation.date) 
                                            }
                                            <span className='flx1' />
                                            {
                                                session.employee.IsBoreholeAdmin &&
                                                <DeleteBoreholeAgesRecommendation
                                                    boreholeAgesRecommendation={recommendation}
                                                    session={session}
                                                    fullWidth
                                                    maxWidth='md'
                                                    transition={Transitions.Down}
                                                />
                                            }
                                        </div>
                                    </PrimaryAccordionSummary>
                                    <AccordionDetails className={'fdc flx1'}>
                                        <AgesRecommendationEntryView
                                            recommendation={recommendation}
                                        />
                                    </AccordionDetails>
                                </SeparatedAccordion>
                            ))
                        }
                    </div>
                }
                <div className='fdc aife jcfe'>
                    <EditBoreholeAgesRecommendation
                        fullWidth
                        maxWidth='md'
                        transition={Transitions.Down}
                    />
                </div>
            </div>
        );
    };
}

import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { firestore } from 'firebase/app';
import firebaseApp from '../../../services/firebaseService';
import TransformerHelper, { ITransformer } from './transformer';

export interface ITransformerComment extends IBasicBaseClass {
    transformerRef : string; // Transformer Id

    comment : string;

    type : 'comment' | 'website' | 'reading' | 'update' | 'guarantee' | string;

    date : number;
}

export default class TransformerCommentHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'transformer_comment';

    private static converter : firebase.firestore.FirestoreDataConverter<ITransformerComment> = {
        fromFirestore: (snapshot) => {
            return TransformerCommentHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ITransformerComment) : firebase.firestore.DocumentData => {
            return TransformerCommentHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ITransformerComment {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as ITransformerComment,
            ...result,
            transformerRef: (data['transformerRef'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : ITransformerComment) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            transformerRef: TransformerHelper.doc(data.transformerRef),
            date: firestore.Timestamp.fromMillis(data.date),
        };
    }
    
    public static collection(ref : firebase.firestore.DocumentReference<ITransformer>) {
        return ref
            .collection(TransformerCommentHelper.COLLECTION_NAME)
            .withConverter(TransformerCommentHelper.converter);
    }

    public static save(data : ITransformerComment) {
        if (!data.id) {
            return TransformerCommentHelper.doc(TransformerHelper.doc(data.transformerRef)).set(data, {
                merge: true,
            });
        }

        return TransformerCommentHelper.doc(TransformerHelper.doc(data.transformerRef), data.id).set(data, {
            merge: true,
        });
    }

    public static doc(ref : firebase.firestore.DocumentReference<ITransformer>, id ?: string) {
        if (!id) {
            return this.collection(ref).withConverter(TransformerCommentHelper.converter).doc();
        }

        return this.collection(ref).withConverter(TransformerCommentHelper.converter).doc(id);
    }

    public static delete(id : string) {
        return firebaseApp
            .firestore()
            .collection(TransformerCommentHelper.COLLECTION_NAME)
            .doc(id)
            .delete();
    }
}
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import Typography from '@material-ui/core/Typography';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import EditWeatherStationInfo from '../dialog/EditInfo';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditWeatherStationArea from '../dialog/EditArea';
import EditWeatherStationImages from '../dialog/EditImages';
import weatherFunctions from '../../../store/weather/functions';
import EditLocationDialog from '../../customComponents/dialog/LocationEditDialog';
import { WEATHER_MAP_IMAGES } from '../../../appConstants';
import { IWeatherStation } from '../../../@types/model/weather/weatherStation';

interface IWeatherStationDetailViewProps {
    weatherStation : IWeatherStation;
    isLoading : boolean;
    session : IUserSession;
}

interface IWeatherStationDetailViewState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class WeatherStationDetailView extends React.Component<IWeatherStationDetailViewProps, IWeatherStationDetailViewState> {
    constructor(props : IWeatherStationDetailViewProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private readonly onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number) => {
        const weatherStation = Object.assign({}, this.props.weatherStation);

        weatherStation.Geom = geom;
        weatherStation.Elevation = elevation;

        const result = await weatherFunctions.saveWeatherStation(weatherStation);
        
        if (!result) throw new Error('');
    };

    public readonly render = () => {
        const { weatherStation, isLoading, session } = this.props;
        const { imageSrcs, isCarouselOpen, imageIndex } = this.state;
        return (
            <div className='fdc flx1'>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            SUMMARY
                            <span className={'flx1'} />
                            {
                                session.employee.IsWeatherStationAdmin &&
                                <EditWeatherStationInfo weatherStation={weatherStation} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                WS NUMBER
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                STATION
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                NAME
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                TYPE
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.WSNumber}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.Station ?? '-'}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.WSName}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.WSType}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                DIVISION
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                INSTALLATION DATE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                SERIAL NUMBER
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.Division}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.InstallationDate}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.SerialNumber}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            SURROUNDING AREA
                            <span className={'flx1'} />
                            {
                                session.employee.IsWeatherStationAdmin &&
                                <EditWeatherStationArea weatherStation={weatherStation} transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                OBSTRUCTION DISTANCE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                SURROUNDINGS
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                FENCED
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.ObstructionDistance} m
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.Surroundings.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    weatherStation.Fenced ? 'YES' : 'NO'
                                }
                            </Typography>
                        </div>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            LOCATION
                            <span className={'flx1'} />
                            {
                                session.employee.IsWeatherStationAdmin &&
                                <EditLocationDialog
                                    markerImage = {WEATHER_MAP_IMAGES['1']}
                                    markerImageSize = {0.35}
                                    elevation={weatherStation.Elevation}
                                    geom={weatherStation.Geom}
                                    transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                    onSave={this.onLocationSave}
                                />
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                LATITUDE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                LONGITUDE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                ELEVATION
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.Geom ? weatherStation.Geom.latitude : 'None'}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.Geom ? weatherStation.Geom.longitude : 'None'}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {weatherStation.Geom && weatherStation.Elevation ? weatherStation.Elevation.toFixed(2) : 'None'}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            IMAGES
                            <span className='flx1' />
                            {
                                session.employee.IsWeatherStationAdmin &&
                                <EditWeatherStationImages transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}/>
                            }
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            {
                                weatherStation.StationImageURL &&
                                <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10 jcc'} variant='subtitle1'>
                                    Station ({weatherStation.WSNumber})
                                </Typography>
                            }
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            {
                                weatherStation.StationImageURL &&
                                <div className='flx1 p10 jcc fdc'>
                                    <FirebaseStorageImage fileName={weatherStation.StationImageURL} path={`weather_station/${weatherStation.WSNumber}`} onClick={this.onThumbClick} onLoaded={this.onImageUrlLoaded} />
                                </div>
                            }
                            {
                                imageSrcs.length > 0 &&
                                <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                            }
                        </div>
                    </div>
                </div>
            </div>);
    };
}

import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import lodash from 'lodash';
import React from 'react';
import { createSelector } from 'reselect';
import { BoreholeConstantRateTimesType, IBoreholeConstantRateRecoveryTimeEntry, IBoreholeConstantRateTimeEntry } from '../../../../@types/model/borehole/boreholeConstantRateTest';

interface IBoreholeConstantRateEntryTableViewProps {
    entries : Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntry | IBoreholeConstantRateRecoveryTimeEntry>;
}

interface IBoreholeConstantRateEntryTableViewState {
}

export default class BoreholeConstantRateEntryTableView extends React.Component<IBoreholeConstantRateEntryTableViewProps, IBoreholeConstantRateEntryTableViewState> {
    constructor(props : IBoreholeConstantRateEntryTableViewProps) {
        super(props);
        this.state = {
        };
    }


    public readonly getData = (props : IBoreholeConstantRateEntryTableViewProps) => props.entries;
    public readonly getEntries = createSelector(
        [this.getData],
        (entries) => {
            return Object.keys(entries).reduce((current, key, i) => {
                const time = Number(key) as BoreholeConstantRateTimesType;

                if (entries[time].waterLevel) {
                    const idx = Math.floor(i / 9);
                    const page = current[idx] || (current[idx] = {});
                    page[time] = {
                        ...entries[time],
                    };
                }
                

                return current;
            }, [] as Array<Record<number, IBoreholeConstantRateTimeEntry | IBoreholeConstantRateRecoveryTimeEntry>>);
        }
    );

    public readonly render = () => {
        const entryPages = this.getEntries(this.props);

        return (
            <div className='fdr fww'>
                {
                    entryPages.map((entries, i) => (
                        <Card key={`p_${i}`} className={'fdc bcg0 mr10 mt15'}>
                            <Table className={'bcg0'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='p12'>Time</TableCell>
                                        <TableCell className='p12'>Ground Level (m){/* crappy alignment fix*/ }<sup></sup></TableCell>
                                        {
                                            lodash.some(entries, n => 'rate' in n) &&
                                            <TableCell className='p12'>Abstraction Rate (m<sup>3</sup>/h)</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        lodash.map(entries, (entry, time) => (
                                            <TableRow key={`tr_${time}`}>
                                                <TableCell className='p12'>{time}</TableCell>
                                                <TableCell className='p12'>{!entry.waterLevel ? '-' : entry.waterLevel.toFixed(2)}</TableCell>
                                                {
                                                    'rate' in entry &&
                                                    <TableCell className='p12'>{!entry.rate ? '-' : entry.rate.toFixed(2)}</TableCell>
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                            <div className='h10 bcDarkBlue' />
                        </Card>
                    ))
                }
            </div>
        );
    };
}

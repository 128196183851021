import { BankAccountType } from './@types/model/bankAccount';
import { TransformerMeterTypeUpperCase, TransformerMeterTypesUpperCase } from './@types/model/transformer/transformer';

export const DEFAULT_MAP_CENTER : [number, number] = [30.1148622, -23.5520414];
export const DEFAULT_MAP_ZOOM = 8;
export const DIVISION_BOUNDRIES : [number, number, number, number] = [19.324796118269436, -33.805521942862825, 30.558539648045727, -22.185720865840814];

export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_DEFAULT_NO_TIME = 'DD/MM/YYYY';
export const DATE_FORMAT_MONTH_YEAR = 'MMM YYYY';
export const DATEPICKER_FORMAT_DEFAULT = 'YYYY-MM-DD';
export const TIME_FORMAT_DEFAULT = 'HH:mm';
export const DATE_INPUT_FORMAT_DEFAULT = 'YYYY/M/D';
export const DATE_FILE_UPLOAD_FORMAT = 'DD MMMM YYYY';
export const DATE_FORMAT_DATE_PICKER = ' MMMM, YYYY';
export const DATE_FORMAT_MMM_DD_HH_MM = ' MMM DD, HH:mm';

/* Borehole constants */
export const BOREHOLE_STATUS : Record<string, string> = {
    1: 'Dynamic',
    2: 'Static',
    3: 'Influenced',
};
export const BOREHOLE_STATUS_DYNAMIC = '1';
export const BOREHOLE_STATUS_STATIC = '2';

export const BOREHOLE_OVERALL_STATUS : { [key : string] : string } = {
    1: 'In use',
    2: 'Not in use',
    3: 'Monitoring',
    4: 'Destroyed',
    5: 'Abandoned',
};

export const BOREHOLE_PROBLEMS = [
    'Toetskraan',
    'Bye',
    'Wortels',
    'Installasie Foutief',
    'Casing',
    'Pomp',
    'Motor',
    'Terrein',
    'Droog',
    'Elektriese Probleme',
    'Diefstal',
    'Toerusting Foutief',
    'Toegeval',
    'Cement Block',
    'Non Return Valve',
];

export const BOREHOLE_USERS = ['Housing', 'Production', 'Packhouse', 'Livestock', 'Production & Housing'];
export const BOREHOLE_CONTAINERS = [10, 110, 210, 230];

export const TRANSFORMER_METER_TYPE : Array<TransformerMeterTypeUpperCase> = [...TransformerMeterTypesUpperCase];
export const TRANSFORMER_RATINGS = [16, 25, 50, 100, 150, 200, 315, 500, 1000];

export const TRANSFORMER_BREAKERS : { [key : number] : Array<number> } = {
    1: [40, 60, 80],
    3: [40, 80, 100, 125, 150, 160, 175, 200, 250, 300, 325, 350, 450, 600, 850],
};
export const TRANSFORMER_METER_NUMBER_OF_DIGITS = [
    4,
    5,
    6,
    7,
    'Other',
];

export const BOREHOLE_INSTALLATION_CABLE_CORES = [
    3,
    4,
];

export const BOREHOLE_INSTALLATION_CABLE_SIZE = [
    1.5,
    2.5,
    4.0,
    6.0,
    10.0,
    16.0,
    25.0,
];

export const BOREHOLE_INSTALLATION_MOTOR_PHASES = [
    'Single Phase',
    'Three Phase',
];

export const BOREHOLE_INSTALLATION_STARTERS = [
    'Single Phase',
    'Star Delta',
    'Direct Online',
    'VSD',
];

export const BOREHOLE_INSTALLATION_TEST_VALVES = [
    32,
    40,
    50,
    65,
    80,
    100,
];

export const BOREHOLE_INSTALLATION_RISING_MAINS = [
    'HDPE',
    'Steel exact',
    'Boreline',
    'uPVC Column',
];

export const BOREHOLE_INSTALLATION_HDPE_TYPES = [
    '32/10',
    '32/12',
    '32/16',
    '40/10',
    '40/12',
    '40/16',
    '50/10',
    '50/12',
    '50/16',
    '63/10',
    '63/12',
    '63/16',
    '65/10',
    '65/12',
    '65/16',
    '75/10',
    '75/12',
    '75/16',
];

export const BOREHOLE_INSTALLATION_STEEL_EXACT_TYPES = [40, 50, 65, 80, 100];
export const BOREHOLE_INSTALLATION_BORELINE_TYPES = [
    24,
    40,
    50,
    65,
    76,
    102,
    125,
    154,
    202,
];

export const BOREHOLE_INSTALLATION_UPVC_TYPES : { [key : string] : { [key : string] : Array<number> } } = {
    CRI: {
        Medium: [25, 32, 40, 50, 65, 80, 100],
        'Medium Plus': [25, 32, 40, 50, 65, 80, 100],
        Heavy: [25, 32, 40, 50, 65, 80, 100],
    },
    ASHIRVAD: {
        Crystal: [25, 32, 40, 50],
        Medium: [50, 65, 80, 100, 125],
        Standard: [25, 32, 40, 50, 65, 80, 100, 125],
        Heavy: [32, 40, 50, 65, 80, 100],
        'Super Heavy': [50, 65, 65, 80, 100],
    },
    SABAR: {
        Medium: [25, 32, 40, 50, 65, 80, 100, 125],
        Standard: [25, 32, 40, 50, 65, 80, 100, 125],
        Heavy: [25, 32, 40, 50, 65, 80, 100, 125],
        'Super Heavy': [25, 32, 40, 50, 65, 80, 100, 125],
    },
};

/* Weather Station Section */

export const WEATHER_STATION_TYPES = ['Davis', 'Netafim', 'ARC', 'Hortec'];

export const WEATHER_STATION_REG_EXP_YEAR = /^[1-9][0-9][0-9][0-9]$/;

export const WEATHER_STATION_SURROUNDINGS = [
    'Natural grassland',
    'Irrigated grassland',
    'Vygies',
    'Soil',
    'Paving',
];

export const weatherStationAnemometerInstallationIssues = ['Less than 2m'];

export const weatherStationAnemometerDirectionIssues = [
    'Not south facing',
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationAnemometerSensorIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationRainGuageFunnelIssues = [
    'Bird nest',
    'No protection',
    'Not cleaned',
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationRainGuageBucketIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationLightMeterIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationSolarDiskIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationTempHumMeterIssues = [
    'Insects',
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationControlBoxInstallationIssues = [
    'Not north facing',
];

export const weatherStationControlBoxSunPanelIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationControlBoxCoverIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationControlBoxAntennaIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationControlBoxCableIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const weatherStationControlBoxBatteryIssues = [
    'Broken',
    'Weathered',
    'Not present',
];

export const RIVER_FLOW_TYPES = ['Dry', 'Low Flow', 'Medium Flow', 'High Flow', 'Flood Flow'];

/* Mottech Section */

export const MOTTECH_TYPES : {
    [key : string] : {
        inputs : number;
        outputs : number;
    } | null;
} = {
    'IRRInet M': { inputs: 7, outputs: 12 },
    'IRRInet ACE': { inputs: 16, outputs: 16 },
    'Piccolo 1x1': { inputs: 1, outputs: 1 },
    'Piccolo 2x2': { inputs: 2, outputs: 2 },
    'Piccolo 4x4': { inputs: 4, outputs: 4 },
    'Piccolo 7x1': { inputs: 7, outputs: 1 },
    'Piccolo 8x1': { inputs: 8, outputs: 1 },
    PIU: { inputs: 0, outputs: 0 },
};

export const MOTTECH_INPUTS = [
    'Water meter',
    'Pressure sensor',
    'Level sensor',
    'Temperature sensor',
    'Relative humidity sensor',
    'Phase failure sensor',
    'Pump run feedback',
    'EC sensor',
    'pH sensor',
    'Fence monitor',
    'Fault',
    'Other',
];

export const MOTTECH_OUTPUTS = [
    'Valve',
    'Irrigation Pump Start Stop',
    'Fertigation Pump Start Stop',
    'Blower Start Stop',
    'Pump Start Stop',
    'Other',
];

export const BOREHOLE_MAP_IMAGES : Record<string, string> = {
    'Static': `${ASSET_BASE}/assets/images/markers/borehole_static.png`,
    'Dynamic': `${ASSET_BASE}/assets/images/markers/borehole_dynamic.png`,
    'Influenced': `${ASSET_BASE}/assets/images/markers/borehole_influenced.png`,
};

export const TRANSFORMER_MAP_IMAGES : Record<string, string> = {
    '1': `${ASSET_BASE}/assets/images/markers/transformer.png`,
};

export const WEATHER_MAP_IMAGES : Record<string, string> = {
    '1': `${ASSET_BASE}/assets/images/markers/weatherstation.png`,
};

export const MOTTECH_MAP_IMAGES : Record<string, string> = {
    '1': `${ASSET_BASE}/assets/images/markers/mottech.png`,
};

export const RIVER_MAP_IMAGES : Record<string, string> = {
    '1': `${ASSET_BASE}/assets/images/markers/river.png`,
};

export const DAM_MAP_IMAGES : Record<string, string> = {
    '1': `${ASSET_BASE}/assets/images/markers/dam.png`,
};

export const CHEMICAL_FILLING_POINT_MAP_IMAGES : Record<string, string> = {
    '1': `${ASSET_BASE}/assets/images/markers/chemical_filling_point.png`,
};

export const PUMPING_STATION_MAP_IMAGES : Record<string, string> = {
    '1': `${ASSET_BASE}/assets/images/markers/pumpingStationMarker.png`,
};

export const FLOWMETER_MAP_IMAGES : Record<string, string> = {
    '1': `${ASSET_BASE}/assets/images/markers/flowmeter.png`,
};

export const LANDRATES : Array<number> = [
    1,
    2,
    3,
    4,
    0,
];

export const TRANSMISSION_ZONES : Array<[number, number]> = [
    [0, 300],
    [300, 600],
    [600, 900],
    [900, 0],
];

export const LPU_CATEGORIES : Array<[number, number]> = [
    [0, 100],
    [100, 500],
    [500, 1000],
    [1000, 0],
    [-1, -1],
];

export const LPU_VOLTAGES : Array<[number, number]> = [
    [0, 500],
    [500, 22000],
];

export const LINE_VOLTAGES : Array<[number, number]> = [
    [0, 500],
    [500, 22000],
];

// High Months, 7 - July, 8 - August, 9 - September. Moment uses month 0-11
export const HIGH_SEASON_MONTHS = [7, 8, 9];

export const DATE_REGEX = /^[0-9]{1,4}[\/]{0,1}[0-9]{0,2}[\/]{0,1}[0-9]{0,2}/;

export const CURRENCY_FORMAT = new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', maximumFractionDigits: 2 });

export const DEPT_PREFIX = '2940';

export const ACCOUNT_TYPE : Record<BankAccountType, string> = {
    'Current': '1',
    'Saving': '1',
    'Recurring Deposit': '1',
    'Fixed Deposit': '1',
    'Other': '1',
};

export const EN_ZA_NUMBER_FORMAT = new Intl.NumberFormat('en-ZA');

export const EP_REGEX = /\b^EP(\d+)[A-Z]?\b/;

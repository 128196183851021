import React from 'react';
import { IPermission, EMPLOYEE_ROLES } from '../../../@types/employee';
import Typography from '@material-ui/core/Typography';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeItem from '@material-ui/lab/TreeItem';
import RoleTreeItem from './RoleTreeItem';

interface IRoleTreeViewProps {
    permissions : IPermission;
    onPermissionChange : (permissions : IPermission) => void;
}

interface IRoleTreeViewState {}

export default class RoleTreeView extends React.PureComponent<IRoleTreeViewProps, IRoleTreeViewState> {
    constructor(props : IRoleTreeViewProps) {
        super(props);
        this.state = {};
    }
    
    public readonly render = () => {
        return (
            <TreeView
                defaultExpanded={[]}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}>
                {
                    Object.keys(EMPLOYEE_ROLES).sort().map(role => (
                        <TreeItem
                            nodeId={`${role}_node`}
                            key={`${role}_node`}
                            label={
                                <Typography variant='body1' className='pt5 pb5'>
                                    {role}
                                </Typography>}>
                            <RoleTreeItem
                                permissions={this.props.permissions}
                                parentNode={`${role}_node`}
                                role={EMPLOYEE_ROLES[role]}
                                onPermissionChange={this.props.onPermissionChange}
                            />
                        </TreeItem>
                    ))
                }
            </TreeView>
        );
    };
}

import { AppBar, Dialog, DialogActions, Button, DialogContent, Icon, IconButton, Toolbar, Tooltip, Typography, FormControl, TextField } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Form } from 'informed';
import moment from 'moment';
import React from 'react';
import PumpingStationAuditHelper, { IPumpingStationAudit } from '../../../@types/model/pumpingStation/pumpingStationAudit';
import { IPumpingStationGroup } from '../../../@types/model/pumpingStation/pumpingStationGroup';
import GeneralFunctions from '../../../store/general/functions';
import PumpingStationPurposeSelector from '../../customComponents/selector/pumpingStation/PumpingStationPurposeSelector';
import PumpingStationFilterSizeSelector from '../../customComponents/selector/pumpingStation/PumpingStationFilterSizeSelector';
import PumpingStationFilterTypeSelector from '../../customComponents/selector/pumpingStation/PumpingStationFilterTypeSelector';
import { IUserSession } from '../../../@types/employee';

interface IEditPumpingStationGeneralProps {
    session : IUserSession;
    pumpingStationAudit : IPumpingStationAudit;
    pumpingGroup : IPumpingStationGroup;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    fullWidth ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IEditPumpingStationGeneralState {
    open : boolean;
    purpose : string;
    filterType : string | null;
    fliterSize : number | null;
    installationImage : string;

    description : string;
}

export default class EditPumpingStationGeneral extends React.Component<IEditPumpingStationGeneralProps, IEditPumpingStationGeneralState> {
    constructor(props : IEditPumpingStationGeneralProps) {
        super(props);
        this.state = {
            open: false,
            purpose: this.props.pumpingGroup.purpose,
            filterType: this.props.pumpingGroup.filterBankType,
            fliterSize: this.props.pumpingGroup.filterBankSize,
            installationImage: this.props.pumpingGroup.installationPhotoThumbnailUrl,
            description: this.props.pumpingGroup.description ?? '',
        };
    }

    private readonly onPurposeChange = (value : string) => {
        
        if (value === 'Irrigation') {

            this.setState({ 
                purpose: value, 
                filterType: null, 
                fliterSize: null, 
            });

        } else {
            this.setState({ purpose: value });
        }
    };

    private readonly onFilterSizeChange = (value : number | null) => {
        this.setState({ fliterSize: value});
    };

    private readonly onFilterTypeChange = (value : string | null) => {
        this.setState({ filterType: value });
    };
    
    private readonly onEditClick = () => {
        this.setState({ open: true });
    };

    private readonly onSubmit = async () => {
        
        const { session } = this.props;

        const { purpose, filterType, fliterSize, description } = this.state;

        if (!purpose || (purpose === 'Irrigation' && (!filterType || !fliterSize))) {
            GeneralFunctions.generalShowErrorSnackbar('You have left out some information.'); 
            return;
        } 

        const pumpingStationAudit = { ...this.props.pumpingStationAudit, pumpingGroups: [ ...this.props.pumpingStationAudit.pumpingGroups ]};
        
        const pumpingGroupIndex = pumpingStationAudit.pumpingGroups.findIndex((element) => element.reference == this.props.pumpingGroup.reference);

        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].purpose = purpose;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].description = description;

        pumpingStationAudit.updatedBy = session.firebaseUser.uid;
        pumpingStationAudit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStationAudit.updatedByName = session.employee.Name;
        pumpingStationAudit.updatedOn = moment.utc().valueOf();

        if (purpose === 'Transfer') {
            pumpingStationAudit.pumpingGroups[pumpingGroupIndex].filterBankSize = null;
            pumpingStationAudit.pumpingGroups[pumpingGroupIndex].filterBankType = null;
        }

        if (purpose === 'Irrigation') {
            pumpingStationAudit.pumpingGroups[pumpingGroupIndex].filterBankSize = fliterSize;
            pumpingStationAudit.pumpingGroups[pumpingGroupIndex].filterBankType = filterType;
        }

        try {
            await PumpingStationAuditHelper.save(pumpingStationAudit);

            this.onClose();

            GeneralFunctions.generalShowSuccessSnackbar('Saved changes!');
        } catch {
            GeneralFunctions.generalShowErrorSnackbar('Something went wrong while saving the your changes.');
        }

    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({ open: false });
    };

    private readonly onDescriptionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            description: event.currentTarget.value,
        });
    };

    public render = () => {

        const { transition, maxWidth, fullScreen, fullWidth, isLoading, pumpingStationAudit, pumpingGroup } = this.props;
        const { open, purpose, filterType, fliterSize, description } = this.state;

        const showFilterBank = purpose === 'Irrigation';

        return (
            <>
                <Tooltip title={'Edit'}>
                    <IconButton 
                        color={'primary'} 
                        size={'small'} 
                        onClick={this.onEditClick} 
                        aria-label={'Edit'} 
                        disabled={isLoading}
                    >
                        <Icon>edit</Icon>
                    </IconButton> 
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby={'pumpingStation-info-info-title'}
                    aria-describedby={'pumpingStation-info-info-description'}
                >
                    <AppBar position='static' >
                        <Toolbar className={'fdr aic jcsb'}>
                            <Typography variant={'h5'} color={'inherit'}>
                                {`Edit ${pumpingStationAudit.pumpCode} - ${moment.utc(pumpingStationAudit.createdOn).format('DD-MM-YYYY')}: PG ${pumpingGroup.number}`}
                            </Typography>
                            <Tooltip title={'Close'}>
                                <IconButton color={'inherit'} disabled={isLoading} onClick={this.onClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete={'off'} onSubmit={this.onSubmit} className={'fdc flx1 hfill oya'}>
                        <DialogContent className={'fdc hfill'}>
                            <div className={'fdr'}>
                                <PumpingStationPurposeSelector 
                                    disabled={isLoading} 
                                    value={purpose} 
                                    required={true}
                                    onChange={this.onPurposeChange}
                                />
                            </div>
                            {
                                showFilterBank &&
                                <div className={'fdr mt15'}>
                                    <PumpingStationFilterSizeSelector 
                                        disabled={isLoading} 
                                        value={fliterSize} 
                                        required={true}
                                        onChange={this.onFilterSizeChange}
                                    />
                                    <PumpingStationFilterTypeSelector 
                                        disabled={isLoading} 
                                        value={filterType} 
                                        className={'ml20'}
                                        required={true}
                                        onChange={this.onFilterTypeChange}
                                    />
                                </div>
                            }
                            <div className={'fdr mt15'}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={isLoading}
                                        autoComplete='off'
                                        id='description'
                                        label='Description'
                                        value={description}
                                        onChange={this.onDescriptionChange}
                                        margin='normal'
                                        multiline
                                    />
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button type={'submit'} disabled={isLoading} variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </>
        );
    };
}
import React from 'react';
import { IWeatherStationAudit } from '../../../@types/weatherStation';
import Typography from '@material-ui/core/Typography';
import FirebaseImageButton from '../../customComponents/button/FirebaseImageButton';

interface IWeatherStationAuditEntryViewProps {
    audit : IWeatherStationAudit;
}

interface IWeatherStationAuditEntryViewState {}

export default class WeatherStationAuditEntryView extends React.Component<IWeatherStationAuditEntryViewProps, IWeatherStationAuditEntryViewState> {
    constructor(props : IWeatherStationAuditEntryViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { audit } = this.props;
        return (
            <div className='fdc'>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            SUMMARY
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                TYPE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                IMAGE
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {audit.WSType}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    audit.StationImageURL &&
                                    <FirebaseImageButton fileName={audit.StationImageURL} path={`weather_station/${audit.WeatherStation.id}`} />
                                }
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                SURROUNDING AREA
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                OBSTRUCTION DISTANCE
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                SURROUNDINGS
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                FENCED
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {audit.ObstructionDistance} m
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {audit.Surroundings.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {
                                    audit.Fenced ? 'YES' : 'NO'
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            ANEMOMETER
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                INSTALLATION ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                WIND D. SENSOR ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                WIND S. SENSOR ISSUES
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.AnemometerInstallationIssues.length ? 'None' : audit.AnemometerInstallationIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.AnemometerDirectionIssues.length ? 'None' : audit.AnemometerDirectionIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.AnemometerSpeedSensorIssues.length ? 'None' : audit.AnemometerSpeedSensorIssues.join(', ')}
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            RAIN GUAGE
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                FUNNEL ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                BUCKET ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.RainGuageFunnelIssues.length ? 'None' : audit.RainGuageFunnelIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.RainGuageFunnelIssues.length ? 'None' : audit.RainGuageFunnelIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            LIGHT INTENSITY
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                LIGHT METER ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.LightMeterIssues.length ? 'None' : audit.LightMeterIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            TEMP &amp; HUMIDITY
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                SOLAR RAD. DISK ISSUES
                            </Typography>
                            <Typography className={'flx2 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                TEMPERATURE/HUMIDITY METER ISSUES
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.SolarDiskIssues.length ? 'None' : audit.SolarDiskIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx2 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.TempHumMeterIssues.length ? 'None' : audit.TempHumMeterIssues.join(', ')}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className='fdr flx1'>
                    <div className={'fdc flx1 m10'}>
                        <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                            CONTROL BOX
                        </Typography>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                INSTALLATION ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                SUN PANEL ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                COVER ISSUES
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.ControlBoxInstallationIssues.length ? 'None' : audit.ControlBoxInstallationIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.ControlBoxSunPanelIssues.length ? 'None' : audit.ControlBoxSunPanelIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.ControlBoxCoverIssues.length ? 'None' : audit.ControlBoxCoverIssues.join(', ')}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                ANTENNA ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                CABLE ISSUES
                            </Typography>
                            <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                BATTERY ISSUES
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.ControlBoxAntennaIssues.length ? 'None' : audit.ControlBoxAntennaIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.ControlBoxCableIssues.length ? 'None' : audit.ControlBoxCableIssues.join(', ')}
                            </Typography>
                            <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                {!audit.ControlBoxBatteryIssues.length ? 'None' : audit.ControlBoxBatteryIssues.join(', ')}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

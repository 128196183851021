import { Field, FieldProps } from 'formik';
import React from 'react';
import { EnumDamSize } from '../../../../@types/model/dam/dam';
import DamSizeSelector, { IDamSizeSelectorProps } from '../../selector/dam/DamSizeSelector';

type DamSizeFormFieldPropsType = FieldProps<EnumDamSize | null> & Omit<IDamSizeSelectorProps, 'value' | 'onChange'>;

interface IDamSizeFormFieldProps extends DamSizeFormFieldPropsType {
    name : string;
}

class DamSizeFormFieldComponent extends React.PureComponent<IDamSizeFormFieldProps> {
    constructor(props : IDamSizeFormFieldProps) {
        super(props);
    }

    private readonly onDamSizeChange = (value : EnumDamSize | null) => {
        const { field, form } = this.props;

        form.setFieldValue(field.name, value);
    };

    public readonly render = () => {
        const { field, form: _form, meta: _meta, name: _name, ...rest } = this.props;
        return (
            <DamSizeSelector
                {...rest}
                value={field.value} 
                onChange={this.onDamSizeChange}                                        
            />
        );
    };
}

export default class DamSizeFormField extends React.PureComponent<{name : string; label : string}> {
    constructor(props : {name : string; label : string}) {
        super(props);
    }

    public render = () => {
        const { name, label } = this.props;
        return (
            <Field
                name={name}
                label={label}
                component={DamSizeFormFieldComponent}                                  
            >
            </Field>
        );
    };
}

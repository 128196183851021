import { LinearProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import TransformerBudgetHelper, { BudgetType, ITransformerBudget } from '../../../@types/model/transformer/budget';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import '../../../style/transformer/budget/budgetTable.scss';
import BudgetEditDialog from './dialog/EditDialog';
import DataFunctions from '../../../store/data/functions';

interface ITransformerBudgetListProps extends RouteComponentProps<never, never, {
    type : BudgetType;
}> {

}

interface ITransformerBudgetListState {
    type ?: BudgetType;
    budgets : Array<ITransformerBudget>;
    isLoading : boolean;

    currentPage : number;
    rowsPerPage : number;
    rowsPerPageOptions : Array<number>;
}

export default class TransformerBudgetList extends React.PureComponent<ITransformerBudgetListProps, ITransformerBudgetListState> {
    private listen ?: () => void;
    
    constructor(props : ITransformerBudgetListProps) {
        super(props);
        this.state = {
            isLoading: false,
            currentPage: 0,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 20, 30, 50, 100],
            budgets: [],
        };
    }
    
    public readonly componentDidMount = () => {
        DataFunctions.getCompanies();
        this.setType();
    };

    public readonly componentWillUnmount = () => {
        if (this.listen) this.listen();
    };

    public readonly componentDidUpdate = (prevProps : ITransformerBudgetListProps, prevState : ITransformerBudgetListState) => {
        if (prevProps.location.search !== this.props.location.search) this.setType();

        if (prevState.type !== this.state.type) this.loadList();
    };

    private readonly setType = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const type = urlParams.get('type') as BudgetType;

        this.setState({
            type,
        });
    };

    private readonly loadList = () => {
        if (!this.state.type) return;
        if (this.listen) {
            this.listen();
            this.setState({
                budgets: [],
            });
        }

        this.setState({
            isLoading: true,
        });

        this.listen = TransformerBudgetHelper.collection(this.state.type)
            .where('type', '==', this.state.type)
            .orderBy('startDate', 'asc')
            .onSnapshot((snapshot) => {
                const budgets = this.state.budgets.slice();
                
                snapshot.docChanges().forEach((docChange) => {
                    const data = docChange.doc.data();        
                    switch (docChange.type) {
                        case 'added':
                            budgets.splice(docChange.newIndex, 0, data);
                            break;
                        case 'modified':
                            budgets.splice(docChange.newIndex, 1, data);
                            break;
                        case 'removed':
                            budgets.splice(docChange.newIndex, 1);
                            break;
                    }
                });

                this.setState({
                    isLoading: false,
                    budgets,
                });
            }, (err) => {
                GeneralFunctions.generalShowError(err.message, 'An error occured while loading budgets.');
                this.setState({
                    isLoading: false,
                });
            });
    };

    private readonly onPageChange = (event : React.MouseEvent<HTMLButtonElement> | null, page : number) => {
        this.setState({ currentPage: page });
    };

    private readonly onRowsPerPageChange = (event ?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!event) return;
        this.setState({ rowsPerPage: Number(event.target.value) });
    };

    public readonly getBudgets = (props : ITransformerBudgetListProps, state : ITransformerBudgetListState) => state.budgets;
    public readonly getRowsPerPage = (props : ITransformerBudgetListProps, state : ITransformerBudgetListState) => state.rowsPerPage;
    public readonly getCurrentPage = (props : ITransformerBudgetListProps, state : ITransformerBudgetListState) => state.currentPage;

    public readonly getPrevBudget = createSelector(
        [this.getBudgets],
        (budgets) => {
            return budgets.find(x => !x.endDate);
        },
    );

    public readonly getFilterBudgets = createSelector(
        [
            this.getBudgets,
            this.getRowsPerPage,
            this.getCurrentPage,
        ],
        (budgets, rowsPerPage, currentPage) => {
            return budgets.slice((currentPage * rowsPerPage), currentPage * rowsPerPage + rowsPerPage);
        },
    );

    public readonly render = () => {
        const {
            type,
            isLoading,
            currentPage,
            rowsPerPage,
            rowsPerPageOptions,
            budgets,
        } = this.state;

        const filterBudgets = this.getFilterBudgets(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography color='inherit' className='mr20 fs25 fwm cpd'>
                            {type?.toLocaleUpperCase() ?? ''} BUDGETED UNITS
                        </Typography>
                        {
                            type &&
                            <BudgetEditDialog type={type} budgets={budgets} fullScreen />
                        }
                        <span className={'flx1'} />
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{budgets.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <div className={'hfill fdc mh0 mw0'}>
                        <div style={{ height: '5px' }}>
                            {isLoading && <LinearProgress className={'wfill'} style={{ borderRadius: '2.5px' }} />}
                        </div>
                        <div className={'flx1 fdc oxs oys wfill hfill'}>
                            <Table stickyHeader>
                                <TableHead className='budget-table-header'>
                                    <TableRow>
                                        <TableCell className='bcp' component='th' scope='row' align='left'>
                                            Company
                                        </TableCell>
                                        <TableCell className='bcp' component='th' scope='row' align='left'>
                                            Area
                                        </TableCell>
                                        <TableCell className='bcp' component='th' scope='row' align='left'>
                                            Start Date
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='left'>
                                            End Date
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='left'>
                                            Created By
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='left'>
                                            Created On
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='left'>
                                            Update By
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='left'>
                                            Updated On
                                        </TableCell>
                                        <TableCell component='th' scope='row' align='left'>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className='budget-table-body'>
                                    {
                                        filterBudgets.map(row => (
                                            <TableRow key={row.id} className={'data-row'}>
                                                <TableCell>
                                                    {
                                                        row.companyName
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        row.area
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnly(row.startDate)
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        AppFunctionsService.formatDateTimeToDateOnly(row.endDate)
                                                    }
                                                </TableCell>
                                                <TableCell component='td' scope='row' align='left'>
                                                    <Tooltip title={row.createdByEmployee}>
                                                        <div>
                                                            {
                                                                row.createdByName
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell component='td' scope='row' align='left'>
                                                    {
                                                        AppFunctionsService.formatDateTime(row.createdOn)
                                                    }
                                                </TableCell>
                                                <TableCell component='td' scope='row' align='left'>
                                                    <Tooltip title={row.updatedByEmployee}>
                                                        <div>
                                                            {
                                                                row.updatedByName
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell component='td' scope='row' align='left'>
                                                    {
                                                        AppFunctionsService.formatDateTime(row.updatedOn)
                                                    }
                                                </TableCell>
                                                <TableCell component='td' scope='row' align='left'>
                                                    <BudgetEditDialog type={row.type} value={row} fullScreen budgets={budgets} />
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </div>
                        <div className={'fdr aic'}>
                            <span className='flx1'></span>
                            <TablePagination
                                component='div'
                                rowsPerPageOptions={rowsPerPageOptions}
                                count={budgets.length}
                                rowsPerPage={rowsPerPage}
                                page={currentPage}
                                onPageChange={this.onPageChange}
                                onRowsPerPageChange={this.onRowsPerPageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

import { IBaseClass, BaseClass } from '../base';
import firebaseApp from '../../../services/firebaseService';

export interface IBoreholeMotorSize extends IBaseClass {
    diameter : number;
    sizes : Array<number>;
}

export default class BoreholeMotorSize extends BaseClass implements IBoreholeMotorSize {
    public static COLLECTION = 'borehole_motor_sizes';
    public ref : firebase.firestore.DocumentReference;
    public diameter : number;
    public sizes : Array<number>;

    constructor(snapshot? : firebase.firestore.DocumentSnapshot | firebase.firestore.QueryDocumentSnapshot) {
        super(snapshot);
        if (snapshot && snapshot.exists) {
            const data = snapshot.data();

            if (data) {
                this.diameter = data['diameter'];
                this.sizes = data['sizes'];
            }
        }
    }

    protected toObject() {
        const obj = {
            ...this,
        };

        const { ref, ...rest } = obj;

        return rest;
    }

    public save() {
        if (this.ref) {
            return firebaseApp.firestore().doc(this.ref.path).set(this.toObject(), {
                merge: true,
            });
        } else {
            return firebaseApp.firestore().collection(BoreholeMotorSize.COLLECTION).add(this.toObject());
        }
    }
}

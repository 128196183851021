import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { connect } from 'react-redux';
import { IAuthState, IGeneralState, IRootState } from '../../@types/redux';
import { VERSION } from '../../version';
import RouteBreadCrumbs from '../customComponents/breadCrumb/RouteBreadCrumb';
import { withRouter, RouteComponentProps } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import randomColor from 'randomcolor';

interface INavBarProps extends RouteComponentProps {
    toggleLeftDrawer : () => void;
    toggleRightDrawer : () => void;
    auth : IAuthState;

    general : IGeneralState;
}

interface INavBarStates {
}

class NavBar extends React.Component<INavBarProps, INavBarStates> {
    constructor(props : INavBarProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        const auth = this.props.auth;
        return (
            <AppBar position='static'>
                <Toolbar variant='dense'>
                    {auth.isLoggedIn && auth.session && <IconButton color={'inherit'} className={'mr20'} onClick={this.props.toggleLeftDrawer}>
                        <Icon>menu</Icon>
                    </IconButton>}
                    <img height={60} src={`${ASSET_BASE}/assets/images/logo.png`} className={'mr10'} />
                    <Typography color='inherit' variant='h6'>
                        Technical
                    </Typography>
                    <sub className={'ml10 pt5'}>v{VERSION.version}</sub>
                    <div className='pl15 aic jcc'>
                        <RouteBreadCrumbs />
                    </div>
                    <span className={'flx1'} />
                    {
                        auth.isLoggedIn && auth.session &&
                        <div className={'fdr aic'} onClick={this.props.toggleRightDrawer}>
                            <Typography variant='h6' color='inherit'>{auth.session.firebaseUser.displayName}</Typography>
                            <div className={'h40 br20 oh aic ml20'}>
                                <Avatar
                                    className='hfill'
                                    alt={auth.session.firebaseUser.displayName ?? ''}
                                    src={auth.session.firebaseUser.photoURL ? auth.session.firebaseUser.photoURL : '/broken-image.jpg'}
                                    style={{
                                        backgroundColor: randomColor({ seed: auth.session.firebaseUser.displayName ?? '' }),
                                    }}
                                />
                            </div>
                        </div>
                    }
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        general: state.general,
    };
};

export default withRouter(connect(
    mapStateToProps,
)(NavBar));

import { Card, Typography } from '@material-ui/core';
import { FieldArrayRenderProps, FormikProps } from 'formik';
import React from 'react';
import { createSelector } from 'reselect';
import { BoreholeCalibrationStagesType, IBoreholeCalibrationFormValues } from '../../../../@types/model/borehole/boreholeCalibration';
import TextFormField from '../TextFormField';

interface IBoreholeCalibrationStepEntryFormFieldProps extends Omit<FieldArrayRenderProps, 'form'> {
    form : FormikProps<IBoreholeCalibrationFormValues>;

    recovery ?: boolean;
}

export default class BoreholeCalibrationStepEntryFormField extends React.PureComponent<IBoreholeCalibrationStepEntryFormFieldProps> {
    constructor(props : IBoreholeCalibrationStepEntryFormFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly getValues = (props : IBoreholeCalibrationStepEntryFormFieldProps) => props.form.values;
    public readonly getName = (props : IBoreholeCalibrationStepEntryFormFieldProps) => props.name;
    public readonly getStage = createSelector(
        [this.getName],
        (name) => {
            return Number(name.split('.')[1]);
        },
    );
    public readonly getList = createSelector(
        [this.getValues, this.getStage],
        (values, stage : number) => {
            if (stage < 5) {
                return values.stages[stage as BoreholeCalibrationStagesType].stageEntries;
            }

            return values.recoveryEntries;
        },
    );

    public readonly render = () => {
        const { name, recovery } = this.props;
        const stageEntries = this.getList(this.props);
        return (
            <div className='fdr mt12'>
                <Card className='fdc flx1'>
                    <div className='fdr bcw h50'>
                        <div className={'fdc flx1 jcc aic bcp h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                TIME
                            </Typography>
                        </div>
                        <span className='w3' />
                        <div className={'fdc flx1 jcc aic dvbg h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                GROUNDWATER LEVEL (m)
                            </Typography>
                        </div>
                        {
                            !recovery &&
                            <>
                                <span className='w3' />
                                <div className={'fdc flx1 jcc aic dvblg h50'}>
                                    <Typography className={'fs14 fw400 cw'}>
                                        ABSTRACTION RATE (m<sup>3</sup>/h)
                                    </Typography>
                                </div>
                            </>
                        }
                    </div>
                    {
                        stageEntries.map((n, index) => (
                            <div key={index} className={`fdr ais jcc ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                <div className={'fdr flx1 aic jcc pb16 pt16 pr30 pl30'}>
                                    <Typography className={'fs20 fwb'}>
                                        {
                                            n.time
                                        }
                                    </Typography>
                                </div>
                                <span className='w3 bcw' />
                                <div className={'fdr flx1 ais jcc pb16 pt16 pr30 pl30'}>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={`${name}.${index}.groundLevel`}
                                        name={`${name}.${index}.groundLevel`}
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                                {
                                    !recovery &&
                                    <>
                                        <span className='w3 bcw' />
                                        <div className={'fdr flx1 ais jcc pb16 pt16 pr30 pl30'}>
                                            <TextFormField
                                                inputProps={{
                                                    className: 'cp fwb',
                                                    style: {
                                                        textAlign: 'center',
                                                    },
                                                }}
                                                id={`${name}.${index}.abstractionRate`}
                                                name={`${name}.${index}.abstractionRate`}
                                                fullWidth
                                                required
                                                type='number'
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        ))
                    }
                </Card>
            </div>
        );
    };
}

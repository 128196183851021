import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import DamHelper from '../../../../@types/model/dam/dam';
import CheckTreeItem from '../../../customComponents/tree/CheckTreeItem';

interface IEmployeeDamConstructionTypesViewProps {
    value : Array<number>;

    onChange ?: (types : Array<number>) => void;
}

interface IEmployeeDamConstructionTypesViewState {
}

class EmployeeDamConstructionTypesViewComponent extends React.PureComponent<IEmployeeDamConstructionTypesViewProps, IEmployeeDamConstructionTypesViewState> {
    constructor(props : IEmployeeDamConstructionTypesViewProps) {
        super(props);
        this.state = {
            otherDamConstructionType: '',
        };
    }

    private readonly getDamConstructionTypes = createSelector([], () => {
        return lodash
            .chain(DamHelper.DamConstructionType)
            .map((damConstructionType, i) => ({
                label: damConstructionType,
                value: Number(i),
            }))
            .sortBy((a) => a.label)
            .value();
    });

    private readonly updateDamConstructionTypes = (damConstructionType : number, add : boolean) => {
        const damConstructionTypes = this.props.value.slice();

        if (add) {
            damConstructionTypes.push(damConstructionType);
        } else {
            const index = damConstructionTypes.findIndex((x) => x === damConstructionType);
            damConstructionTypes.splice(index, 1);
        }

        if (this.props.onChange) this.props.onChange(damConstructionTypes);

    };

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.updateDamConstructionTypes(Number(event.currentTarget.value), checked);
    };

    private readonly onItemClick = (event : React.MouseEvent<HTMLLIElement>) => {
        if (!event.currentTarget.textContent) return;

        const damConstructionTypes = this.getDamConstructionTypes({} as never);


        const damConstructionType = damConstructionTypes.find(x => x.label === event.currentTarget.textContent);
        if (!damConstructionType) return;
        this.updateDamConstructionTypes(damConstructionType.value, !this.props.value.includes(damConstructionType.value));
    };

    public readonly render = () => {
        const { value } = this.props;
        const damConstructionTypes = this.getDamConstructionTypes({} as never);
        return (
            <TreeView
                className='mt10'
                defaultExpanded={[]}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}>
                {
                    damConstructionTypes.map(n => (
                        <TreeItem
                            nodeId={`${n.label}_node`}
                            key={`${n.label}_node`}
                            onClick={this.onItemClick}
                            label={
                                <CheckTreeItem
                                    value={n.value}
                                    labelText={n.label}
                                    checked={value.includes(n.value)}
                                    onChange={this.onChange}
                                />
                            }>
                        </TreeItem>
                    ))
                }
            </TreeView>
        );
    };
}

const mapStateToProps = () => {
    return {
    };
};

const EmployeeDamConstructionTypesView = connect(
    mapStateToProps,
)(EmployeeDamConstructionTypesViewComponent);

export default EmployeeDamConstructionTypesView;

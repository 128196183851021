import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IDamVolume } from '../../../../@types/model/dam/dam';

interface IDamMeasurementelectorProps {
    damVolumes : Array<IDamVolume>;
    isLoading : boolean;

    value : number;

    onChange : (value ?: number) => void;

    required ?: boolean;
}

interface IDamMeasurementelectorState {}

class DamMeasurementelector extends React.Component<IDamMeasurementelectorProps, IDamMeasurementelectorState> {
    constructor(props : IDamMeasurementelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly getDamMeasurements = (props : IDamMeasurementelectorProps) => props.damVolumes;

    public readonly getDamMeasurementCodes = createSelector(
        [this.getDamMeasurements],
        (damVolumes) => {
            return damVolumes.map((e : IDamVolume) => {
                return e.plate;
            });
        },
    );

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: number | undefined | null) => {
        this.props.onChange(value ?? undefined);
    };
    
    public readonly render = () => {
        const { value, required, isLoading} = this.props;

        const codes = this.getDamMeasurementCodes(this.props);

        return (
            <FormControl fullWidth={true} required={false} disabled={isLoading}>
                <Autocomplete
                    id={'code'}
                    options={codes}
                    value={value}
                    getOptionSelected={(option, val) => option === val}
                    getOptionLabel={option => option.toString()}
                    onChange={this.onChange}
                    disableClearable={false}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={true} label={'Meter Measurement'} />}
                />
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.dam.loading,
    };
};

export default connect(
    mapStateToProps,
)(DamMeasurementelector);

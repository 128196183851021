import React from 'react';
import '../../../style/transformerTariffs.scss';
import { IAuthState, IRootState } from '../../../@types/redux';
import TariffHelper, { ISPUTariff } from '../../../@types/model/transformer/tariffs';
import firebaseApp from '../../../services/firebaseService';
import generalFunctions from '../../../store/general/functions';
import firebase from 'firebase/app';
import { Formik, FieldArray } from 'formik';
import OutlinedStadiumButton from '../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../customComponents/button/StadiumButton';
import AbsoluteLinearProgress from '../../customComponents/progress/AbsoluteLinearProgress';
import Paper from '@material-ui/core/Paper';
import NumberHelper from '../../../services/helper/numberHelper';
import { connect } from 'react-redux';
import DateMillisInput from '../../customComponents/form/DateMillisInput';

interface ISPUEditProps {
    auth : IAuthState;
    vat : number;
    spu : ISPUTariff;

    onSaved ?: () => void;

    enabled ?: boolean;
    disabledStartDate ?: boolean;
}

interface ISPUEditState {
}

class SPUEdit extends React.PureComponent<ISPUEditProps, ISPUEditState> {
    constructor(props : ISPUEditProps) {
        super(props);
        this.state = {
        };
    }

    private readonly save = async (values : ISPUTariff) => {
        if (!this.props.auth.session) return;
        const spu = { ...values };

        spu.vat = this.props.vat;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!spu.ref) {
            spu.ref = TariffHelper.spuDoc();
            spu.createdBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session.firebaseUser.uid);
            spu.createdByEmployee = this.props.auth.session.employee.EmployeeNumber ?? '';
            spu.createdByName = this.props.auth.session.employee.Name;
            spu.createdOn = firebase.firestore.Timestamp.now();
        }

        spu.updatedBy = firebaseApp.firestore().collection('employee').doc(this.props.auth.session.firebaseUser.uid);
        spu.updatedByEmployee = this.props.auth.session.employee.EmployeeNumber ?? '';
        spu.updatedByName = this.props.auth.session.employee.Name;
        spu.updatedOn = firebase.firestore.Timestamp.now();

        try {
            await TariffHelper.saveSPU(spu);
            if (this.props.onSaved) this.props.onSaved();
        } catch (ex) {
            generalFunctions.generalShowError(ex, 'Error saving.');
            throw ex;
        }

    };

    public readonly render = () => {
        const { vat, spu, enabled, disabledStartDate } = this.props;
        return (
            <div className='fdc flx1 ais p30 transformer-tariffs spu'>
                <Formik
                    enableReinitialize
                    initialValues={spu}
                    onSubmit={this.save}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        isSubmitting,
                    }) => (
                        <form id='spu-form' className='' onSubmit={handleSubmit} onReset={handleReset}>
                            <div className='fdr pb20'>
                                <DateMillisInput name='startDate' label='Start Date' className='' disabled={isSubmitting || !enabled || disabledStartDate} />
                                {
                                    !!spu.endDate &&
                                    <DateMillisInput name='endDate' label='End Date' className='ml20' disabled />
                                }
                            </div>
                            <Paper className='fdc ais' elevation={3}>
                                <div className='fdr ais'>
                                    <div className='fdc ais jcc flx1'>
                                        <div className='fdr aic jcfs vat-header ml10 mr10'>
                                            CURRENT VAT
                                        </div>
                                        {
                                            !!vat &&
                                            <div className='fdr aic jcc vat-value ml10 mr10 mt10'>
                                                <span className='p10'>{vat * 100} %</span>
                                            </div>
                                        }
                                        {
                                            !vat &&
                                            <div className='fdr aic jcc vat-value error ml10 mr10'>
                                                <span className='p10'>ERROR</span>
                                            </div>
                                        }
                                    </div>
                                    <div className='fdc flx1 ais pt10 energy-charge'>
                                        <span className='fdr flx1 jcc aic tac'>
                                            Energy Charge [c/kWh]
                                        </span>
                                        <div className='fdc aife jcfe vat'>
                                            VAT incl
                                        </div>
                                    </div>
                                    <div className='fdc flx1 ais pt10 ancillary-service'>
                                        <span className='fdr flx1 jcc aic tac'>
                                            Ancillary Service [c/kWh]
                                        </span>
                                        <div className='fdc aife jcfe vat'>
                                            VAT incl
                                        </div>
                                    </div>
                                    <div className='fdc flx1 ais pt10 network-demand'>
                                        <span className='fdr flx1 jcc aic tac'>
                                            Network Demand Charge [c/kWh]
                                        </span>
                                        <div className='fdc aife jcfe vat'>
                                            VAT incl
                                        </div>
                                    </div>
                                    <div className='fdc flx1 ais pt10 network-capacity'>
                                        <span className='fdr flx1 aic jcc tac'>
                                            Network Capacity Charge [R/POD/day]
                                        </span>
                                        <div className='fdc aife jcfe vat'>
                                            VAT incl
                                        </div>
                                    </div>
                                    <div className='fdc flx1 ais pt10 service-charge'>
                                        <span className='fdr flx1 aic jcc tac'>
                                            Service Charge [R/POD/day]
                                        </span>
                                        <div className='fdc aife jcfe vat'>
                                            VAT incl
                                        </div>
                                    </div>
                                </div>
                                {
                                    isSubmitting &&
                                    <div className='fdc ais wfill submitting'>
                                        <AbsoluteLinearProgress />
                                    </div>
                                }
                                <div className='fdc ais flx2'>
                                    <FieldArray
                                        name='landrates'
                                        render={
                                            () => (
                                                <div className='fdc ais flx1'>
                                                    {
                                                        Object.keys(values.landrates).map(key => (
                                                            <div className='fdr tariff-row mnh65' key={`landrate_${key}`}>
                                                                <div className='fdc aic jcc flx1 landrate'>
                                                                    Landrate {key}{key === 'DX' ? '*' : ''}
                                                                </div>
                                                                <div className='fdc ais jcc flx1 br'>
                                                                    {
                                                                        typeof values.landrates[key].energyCharge !== 'undefined' &&
                                                                        <div className='fdr'>
                                                                            <input value={values.landrates[key].energyCharge}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`landrates.${key}.energyCharge`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(values.landrates[key].energyCharge, vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        typeof values.landrates[key].energyCharge === 'undefined' &&
                                                                        <div className='fdr flx1 empty'></div>
                                                                    }
                                                                </div>
                                                                <div className='fdc ais jcc flx1 br'>
                                                                    {
                                                                        typeof values.landrates[key].ancillaryService !== 'undefined' &&
                                                                        <div className='fdr'>
                                                                            <input value={values.landrates[key].ancillaryService}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`landrates.${key}.ancillaryService`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(values.landrates[key].ancillaryService, vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        typeof values.landrates[key].ancillaryService === 'undefined' &&
                                                                        <div className='fdr flx1 empty'></div>
                                                                    }
                                                                </div>
                                                                <div className='fdc ais jcc flx1 br'>
                                                                    {
                                                                        typeof values.landrates[key].networkDemand !== 'undefined' &&
                                                                        <div className='fdr'>
                                                                            <input value={values.landrates[key].networkDemand}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`landrates.${key}.networkDemand`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                {NumberHelper.addPercentage(values.landrates[key].networkDemand, vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        typeof values.landrates[key].networkDemand === 'undefined' &&
                                                                        <div className='fdr flx1 empty'></div>
                                                                    }
                                                                </div>
                                                                <div className='fdc ais jcc flx1 br'>
                                                                    {
                                                                        typeof values.landrates[key].networkCapacity !== 'undefined' &&
                                                                        <div className='fdr'>
                                                                            <span className='currency'>R</span>
                                                                            <input value={values.landrates[key].networkCapacity}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`landrates.${key}.networkCapacity`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                R {NumberHelper.addPercentage(values.landrates[key].networkCapacity, vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        typeof values.landrates[key].networkCapacity === 'undefined' &&
                                                                        <div className='fdr flx1 empty'></div>
                                                                    }
                                                                </div>
                                                                <div className='fdc ais jcc flx1'>
                                                                    {
                                                                        typeof values.landrates[key].serviceCharge !== 'undefined' &&
                                                                        <div className='fdr'>
                                                                            <span className='currency'>R</span>
                                                                            <input value={values.landrates[key].serviceCharge}
                                                                                disabled={isSubmitting || !enabled}
                                                                                autoComplete='off'
                                                                                name={`landrates.${key}.serviceCharge`}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type='number'
                                                                                min='0'
                                                                                step='any' />
                                                                            <span className='flx1' />
                                                                            <span className='vat'>
                                                                                R {NumberHelper.addPercentage(values.landrates[key].serviceCharge, vat).toFixed(2)}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        typeof values.landrates[key].serviceCharge === 'undefined' &&
                                                                        <div className='fdr flx1 empty'></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    />
                                    <div className='fdr aic pl10 mnh65 disclaimer'>
                                        <span>* R/day fixed charge inclusive of the following charges; energy, ancillary service, network demand, network capacity and service charge.</span>
                                    </div>
                                </div>
                            </Paper>
                            <div className='fdr flx1 jcfe aife pt10'>
                                <OutlinedStadiumButton className='mr16' variant='text' type='reset' disabled={isSubmitting || !enabled}>
                                        CANCEL
                                </OutlinedStadiumButton>
                                <StadiumButton type='submit' disabled={isSubmitting || !enabled}>
                                        SAVE
                                </StadiumButton>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(SPUEdit);

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { Card } from '@material-ui/core';
import BoreholeYieldGraph from '../graphs/YieldGraph';
import BoreholeWaterLevelGraph from '../graphs/WaterLevelGraph';
import { VictoryPie } from 'victory';
import TabViews from '../../customComponents/tab/TabViews';
import SecondaryTabs from '../../customComponents/tab/SecondaryTabs';
import SecondaryTab from '../../customComponents/tab/SecondaryTab';

interface IBoreholeSummaryProps {
    borehole : IBorehole;
}

interface IBoreholeSummaryState { 
    tabIndex : number;
}

export default class BoreholeSummary extends React.Component<IBoreholeSummaryProps, IBoreholeSummaryState> {
    constructor(props : IBoreholeSummaryProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly render = () => {
        const { borehole } = this.props;
        const { tabIndex } = this.state;

        return (
            <div className='fdc flx1 pt20 bcg0'>
                <div className={'fdr  bcg0 hfill oyh'}>
                    <div className={'flx1 fdc p30'}>
                        <div className={'flx1 fdr pb30'}>
                            <Card className={'flx1 bcw fdc mr30'}>
                                <div className={'jcc aic bcg wfill dvbg h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        ABSTRACTION RECOMMENDATION
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 bcg0 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        RECOMMENDED ABSTRACTION RATE (m<sup>3</sup>/hr)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.DesignedYield ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        MAXIMUM ABSTRACTION RATE (m<sup>3</sup>/hr)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.PerfomanceYield ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 bcg0 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        PUMPING CYCLE (hr/day)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.PumpingCycle ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        RECOMMENDED MAXIMUM ABSTRACTION (m<sup>3</sup>/day)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.MaximumDailyAbstraction ?? '-'}
                                    </Typography>
                                </div>
                            </Card>
                            <Card className={'flx1 bcw fdc '}>
                                <div className={'jcc aic bcg wfill bcb h50'}>
                                    <Typography className={'fs13 fw500 cw vcp'}>
                                        INSTALLED ABSTRACTION
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 bcg0 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        INSTALLED YIELD (m<sup>3</sup>/hr)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.InstalledYield ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 jcc aic'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        ADJUSTED YIELD (m<sup>3</sup>/hr)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.AdjustedYield ?? '-'}
                                    </Typography>
                                </div>
                                <div className={'fdc flx1 bcg0 jcc aic'}>
                                </div>
                                <div className={'fdc flx1 jcc aic'}>
                                </div>
                            </Card>
                        </div>
                        <div className={'flx1 fdc '}>
                            <div className={'flx1 fdc '}>
                                <Card className={'flx1 bcw fdc '}>
                                    <div className={'jcc aic bcg wfill dvbdg h50'}>
                                        <Typography className={'fs14 fw500 cw vcp'}>
                                            INSTALLED RECOMMENDATION
                                        </Typography>
                                    </div>
                                    <div className={'fdc flx1 bclg jcc aic'}>
                                        <Typography className={'fs13 fw500 cp'}>
                                            RECOMMENDED INSTALLED DEPTH (mbdl)
                                        </Typography>
                                        <Typography className={'fs15 fw700 cb'}>
                                            {borehole.InstallDepthRecommendation ?? '-'}
                                        </Typography>
                                    </div>
                                    <div className={'fdc flx1 jcc aic'}>
                                        <Typography className={'fs13 fw500 cp'}>
                                            BOREHOLE DEPTH (m)
                                        </Typography>
                                        <Typography className={'fs14 fw700 cb'}>
                                            {borehole.HoleDepth ?? '-'}
                                        </Typography>
                                    </div>
                                    <div className={'flx1 fdr bclg jcc aic jcsb'}>
                                        <div className={'fdc flx1 jcc aic'}>
                                            <Typography className={'fs13 fw500 cp'}>
                                                DYNAMIC WATER LEVEL (m) 
                                            </Typography>
                                            <Typography className={'fs14 fw700 cb'}>
                                                {borehole.DynamicWaterLevelLimit ?? '-'}
                                            </Typography>
                                        </div>
                                        <div className={'fdc flx1 jcc aic'}>
                                            <Typography className={'fs13 fw500 cp'}>
                                                STATIC WATER LEVEL (m)
                                            </Typography>
                                            <Typography className={'fs14 fw700 cb'}>
                                                {borehole.StaticWaterLevel ?? '-'}
                                            </Typography>
                                        </div>
                                        <div className={'fdc flx1 jcc aic'}>
                                            <Typography className={'fs13 fw500 cp'}>
                                                CRITICAL WATER LEVEL (m)
                                            </Typography>
                                            <Typography className={'fs14 fw700 cb'}>
                                                {borehole.CriticalWaterLevel ?? '-'}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={'fdc flx1 jcc aic'}>
                                        <Typography className={'fs13 fw500 cp'}>
                                            GROUND WATER ZONE
                                        </Typography>
                                        <Typography className={'fs14 fw700 cb'}>
                                            {borehole.GroundwaterZone?.Name ?? ''}
                                        </Typography>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>
                    <div className={'flx1 fdc p30'}>
                        <div className={'flx1 fdr pb15'}>
                            <div className={'flx2 fdc '}>
                                <Card className={'fdc flx5 mnh100'}>
                                    <div className={'jcc aic fdr flx1 dvblg jcsb h50'}>
                                        <Typography className={'fs14 fw500 cw pl15'}>
                                            LATITUDE
                                        </Typography>
                                        <Typography className={'fs14 fw500 cw'}>
                                            LONGITUDE
                                        </Typography>
                                        <Typography className={'fs14 fw500 cw pr15'}>
                                            ELEVATION
                                        </Typography>
                                    </div>
                                    <div className={'aic fdr flx1 jcsb h50'}>
                                        <Typography className={'fs14 fw500 cb pl15'}>
                                            {borehole.Geom?.latitude ?? '-'}
                                        </Typography>
                                        <Typography className={'fs14 fw500 cb'}>
                                            {borehole.Geom?.longitude ?? '-'}
                                        </Typography>
                                        <Typography className={'fs14 fw500 cb pr15'}>
                                            {borehole.Elevation}
                                        </Typography>
                                    </div>
                                </Card>
                                <div className={'flx2 fdr'}>
                                    <div className={'fdc flx1 m10'}>
                                        <Typography className={'p10 pl20 bbw1 bocGrey fw500'} color='inherit' variant='subtitle1'>
                                            LATEST YIELD
                                        </Typography>
                                        <div className={'posr flx1 aic jcc'} >
                                            <VictoryPie
                                                standalone={true}
                                                radius={140}
                                                padding={0}
                                                data={[{
                                                    x: 1,
                                                    y: (borehole.LatestYield ?? 0) / (borehole.InstalledYield ?? 1),
                                                }, {
                                                    x: 1,
                                                    y: 1 - ((borehole.LatestYield ?? 0) / (borehole.InstalledYield ?? 1)),
                                                }]}
                                                colorScale={['#61B2B5', '#EAEAEA']}
                                                innerRadius={150} labelRadius={10}
                                                style={{ labels: { fontSize: 12, fill: 'transparent' } }}
                                            />
                                            <Typography className={'posa cpo'} variant='subtitle1'>
                                                {borehole.LatestYield ? borehole.LatestYield.toFixed(2) : '0.00'} m<sup>3</sup>/hr
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={'fdc flx1 m10'}>
                                        <Typography className={'p10 pl20 bbw1 bocGrey fw500'} color='inherit' variant='subtitle1'>
                                            WATER LEVEL (m)
                                        </Typography>
                                        <div className={'posr flx1 aic jcc'} >
                                            <VictoryPie
                                                standalone={true}
                                                radius={140}
                                                padding={0}
                                                data={[
                                                    { x: 1, y: 1 },
                                                ]}
                                                colorScale={['#61B2B5', '#CCCCCC']}
                                                innerRadius={0} labelRadius={10}
                                                style={{ labels: { fontSize: 12, fill: 'transparent' } }}
                                            />
                                            <Typography className={'posa cw'} color='inherit' variant='subtitle1'>
                                                {borehole.StaticWaterLevel ? borehole.StaticWaterLevel.toFixed(2) : '0.00'} m
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'flx1 fdc pl15 jcfs'}>
                                <Card className={'h100 fdc mb15'}>
                                    <div className={'dvbdg h50 jcc aic'}>
                                        <Typography className={'cw fs14 fw500 jcc aic '}>
                                            BOREHOLE TEST
                                        </Typography >
                                    </div>
                                    <Typography className={'h50 fs16 fw600 cb jcc aic '}>
                                        {!borehole.PerfomanceTest ? '-' : borehole.PerfomanceTest}
                                    </Typography >
                                </Card>
                                <Card className={'h100 fdc mb15'}>
                                    <div className={'bcp h50 jcc aic'}>
                                        <Typography className={'cw fs14 fw500 jcc aic '}>
                                            EQUIPPED
                                        </Typography >
                                    </div>
                                    <Typography className={'fs16 fw600 cb jcc aic  h50'}>
                                        {borehole.Equipped ? 'YES' : 'NO'}
                                    </Typography >
                                </Card>
                                <Card className={'h100 fdc mb15'}>
                                    <div className={'dbcsdg h50 jcc aic'}>
                                        <Typography className={'cw fs14 fw500 jcc aic'}>
                                            WATER LEVEL STATUS
                                        </Typography>
                                    </div>
                                    <Typography className={'fs16 fw600 cb jcc aic h50'}>
                                        {borehole.StaticWaterLevel ? 'STATIC' : 'DYNAMIC'}
                                    </Typography >
                                </Card>
                            </div>
                        </div>
                        <Card className={'flx1 fdc'}>
                            <SecondaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs' className={'wfill'} variant={'fullWidth'} indicatorColor={'primary'}>
                                <SecondaryTab className='enableBackground' label='YIELDS' value={0} fullWidth/>
                                <SecondaryTab className='enableBackground' label='WATER LEVEL' value={1} fullWidth/>
                            </SecondaryTabs>
                            <div className={'fdc flx1 hfill'}>
                                <TabViews index={tabIndex}>
                                    <div className='jcc aic flx1'>
                                        {
                                            <BoreholeYieldGraph />
                                        }
                                    </div>
                                    <div className='jcc aic flx1'>
                                        {
                                            <BoreholeWaterLevelGraph />
                                        }
                                    </div>
                                </TabViews>
                            </div>
                        </Card>
                    </div>                    
                </div>
            </div>
        );
    };
}

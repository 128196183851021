import axios, { AxiosPromise, AxiosRequestConfig, CancelTokenStatic, Canceler, AxiosResponse } from 'axios';
import generalFunctions from '../store/general/functions';
import { IARCGISQueryResult } from '../@types/model';

export default class HttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;

    private static requestInterceptorNum = 0;
    private static responseInterceptorNum = 0;

    /**
     * Allows you to cancel current area request
     */
    public static cancelGeoJSON ?: Canceler;

    /**
     * Allows you to cancel current area request
     */
    public static cancelArea ?: Canceler;

    public static readonly initializeInterceptor = (token : string) => {
        axios.interceptors.request.eject(HttpService.requestInterceptorNum);
        axios.interceptors.response.eject(HttpService.responseInterceptorNum);

        if (token) {
            HttpService.requestInterceptorNum = axios.interceptors.request.use((config : AxiosRequestConfig) => {
                config.headers['Authorization'] = 'Bearer ' + token;
                return config;
            });

            HttpService.responseInterceptorNum = axios.interceptors.response.use((response) => {
                return response;
            }, (error) => {
                if (axios.isCancel(error)) {
                    return Promise.reject();
                }
                const response = error.response as AxiosResponse | null;

                if (!!response && response.status === 401) {
                    HttpService.initializeInterceptor('');
                    generalFunctions.generalShowError(response, 'Unauthorised');
                    return Promise.reject(response);
                } else if (!!response && response.status === 403) {
                    generalFunctions.generalShowError(response, 'Insufficient rights');
                    return Promise.reject(response);
                }

                if (!error || !response) {
                    generalFunctions.generalShowErrorSnackbar('Connection Error.');
                    return Promise.reject();
                }

                return Promise.reject(error.response);
            });
        }
    };
    
    private static getDownloadFile(data : Blob, fileName : string) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }

    private static getContentDispositionFileName(headers : Record<string, string>) {
        if (!headers['content-disposition']) return '';
    
        const result = headers['content-disposition'].split(';')[1].trim().split('=')[1];
    
        return result.replace(/"/g, '');

    }

    private static getAxiosFileDownload(response : AxiosResponse<Blob>) {
        const fileName = this.getContentDispositionFileName(response.headers);
    
        this.getDownloadFile(response.data, fileName);

    }

    public static readonly registerEmployee = (employeeNumber : string, name : string) : AxiosPromise<unknown> => {
        return axios.post(CLOUD_FUNCTIONS_URL + 'registerEmployeeWithNumber', {
            EmployeeNumber: employeeNumber,
            Name: name,
            IsBoreholeAdmin: false,
            IsBoreholeCreateAdmin: false,
            IsBoreholeUser: false,
            IsBoreholeTester: false,
            IsBoreholeYieldAdjuster: false,
            IsTransformerAdmin: false,
            IsTransformerUser: false,
            IsTransformerLpuTester: false,
            IsTransformerSpuTester: false,
            IsWeatherStationUser: false,
            IsWeatherStationAdmin: false,
            IsWeatherStationTester: false,
            IsRiverUser: false,
            IsRiverAdmin: false,
            IsRiverTester: false,
            IsMottechUser: false,
            IsMottechAdmin: false,
            IsMottechTester: false,
            IsEmployeeAdmin: false,
            IsPumpingStationAdmin: false,
            IsPumpingStationCreateAdmin: false,
            IsPumpingStationTester: false,
            IsPumpingStationUser: false,

            IsActive: true,
        });
    };

    public static readonly linkEmployee = (employeeNumber : string, email : string) => {
        return axios.post<string>(CLOUD_FUNCTIONS_URL + 'linkEmployeeNumber', {
            employeeNumber,
            email,
        }, {
            params: {},
        });
    };

    public static readonly unlinkEmployee = (email : string) => {
        return axios.post<string>(CLOUD_FUNCTIONS_URL + 'unlinkEmployeeNumber', {
            email,
        }, {
            params: {},
        });
    };

    public static readonly getAreaGeoJSON = (token : string) => {
        if (HttpService.cancelGeoJSON) {
            HttpService.cancelGeoJSON();
        }

        return axios.create().get<GeoJSON.FeatureCollection<GeoJSON.Geometry>>('https://services.arcgis.com/yNX9XhIRRfSnSyUQ/arcgis/rest/services/Boundaries/FeatureServer/0/query', {
            cancelToken: new HttpService.CancelToken((c) => {
                HttpService.cancelGeoJSON = c;
            }),
            params: {
                returnGeometry: true,
                outFields: '*',
                f: 'pgeojson',
                where: 'FID > 0',
                token,
            },
        });
    };

    public static readonly getAreas = (token : string) => {
        if (HttpService.cancelArea) {
            HttpService.cancelArea();
        }

        return axios.create().get<IARCGISQueryResult>('https://services.arcgis.com/yNX9XhIRRfSnSyUQ/arcgis/rest/services/Boundaries/FeatureServer/0/query', {
            cancelToken: new HttpService.CancelToken((c) => {
                HttpService.cancelArea = c;
            }),
            params: {
                returnGeometry: false,
                outFields: '*',
                f: 'pjson',
                where: 'FID > 0',
                token,
            },
        });
    };
}

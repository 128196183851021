import { AppBar, Dialog, DialogActions, Button, DialogContent, Icon, IconButton, Toolbar, Tooltip, Typography, FormControl, TextField } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Form } from 'informed';
import moment from 'moment';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import PumpingStationAuditHelper, { IPumpingStationAudit } from '../../../@types/model/pumpingStation/pumpingStationAudit';
import { IPumpingStationGroup } from '../../../@types/model/pumpingStation/pumpingStationGroup';
import GeneralFunctions from '../../../store/general/functions';
import PumpingStationPumpMakeSelector from '../../customComponents/selector/pumpingStation/PumpingStationPumpMakeSelector';
import PumpingStationPumpTypeSelector from '../../customComponents/selector/pumpingStation/PumpingStationPumpTypeSelector';

interface IEditPumpingStationPumpProps {
    session : IUserSession;
    pumpingStationAudit : IPumpingStationAudit;
    pumpingGroup : IPumpingStationGroup;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    fullWidth ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IEditPumpingStationPumpState {
    open : boolean;
    make : string;
    model : string;
    type : string;
    serialNumber : string;
    pumpImage : string;
}

export default class EditPumpingStationPump extends React.Component<IEditPumpingStationPumpProps, IEditPumpingStationPumpState> {
    constructor(props : IEditPumpingStationPumpProps) {
        super(props);
        this.state = {
            open: false,
            make: this.props.pumpingGroup.pumpMake,
            model: this.props.pumpingGroup.pumpModel,
            type: this.props.pumpingGroup.pumpType,
            serialNumber: this.props.pumpingGroup.pumpSerial,
            pumpImage: this.props.pumpingGroup.pumpPhotoThumbnailUrl,
        };
    }

    private readonly onMakeChange = (value : string) => {
        this.setState({ make: value, model: '' });
    };

    private readonly onModelChange = (value : string) => {
        this.setState({ model: value });
    };

    private readonly onTypeChange = (value : string) => {
        this.setState({ type: value });
    };

    private readonly onSerialChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ serialNumber: event.target.value });
    };

    private readonly onEditClick = () => {
        this.setState({ open: true });
    };

    private readonly onSubmit = async () => {
                
        const { session } = this.props;

        const { make, model, type, serialNumber } = this.state;

        if (!make || !model || !type || !serialNumber) {
            GeneralFunctions.generalShowErrorSnackbar('You have left out some information.'); 
            return;
        } 

        const pumpingStationAudit = { ...this.props.pumpingStationAudit, pumpingGroups: [ ...this.props.pumpingStationAudit.pumpingGroups ]};
        
        const pumpingGroupIndex = pumpingStationAudit.pumpingGroups.findIndex((element) => element.reference == this.props.pumpingGroup.reference);

        pumpingStationAudit.updatedBy = session.firebaseUser.uid;
        pumpingStationAudit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStationAudit.updatedByName = session.employee.Name;
        pumpingStationAudit.updatedOn = moment.utc().valueOf();

        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].pumpMake = make;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].pumpModel = model;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].pumpType = type;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].pumpSerial = serialNumber;
        
        try {
            await PumpingStationAuditHelper.save(pumpingStationAudit);

            this.onClose();

            GeneralFunctions.generalShowSuccessSnackbar('Saved changes!');
        } catch {
            GeneralFunctions.generalShowErrorSnackbar('Something went wrong while saving the your changes.');
        }

    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({ open: false });
    };

    public render = () => {

        const { transition, maxWidth, fullScreen, fullWidth, isLoading, pumpingStationAudit, pumpingGroup } = this.props;
        const { open, make, model, type, serialNumber } = this.state;

        return (
            <>
                <Tooltip title={'Edit'}>
                    <IconButton 
                        color={'primary'} 
                        size={'small'} 
                        onClick={this.onEditClick} 
                        aria-label={'Edit'} 
                        disabled={isLoading}
                    >
                        <Icon>edit</Icon>
                    </IconButton> 
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby={'pumpingStation-info-info-title'}
                    aria-describedby={'pumpingStation-info-info-description'}
                >
                    <AppBar position='static' >
                        <Toolbar className={'fdr aic jcsb'}>
                            <Typography variant={'h5'} color={'inherit'}>
                                {`Edit ${pumpingStationAudit.pumpCode} - ${moment.utc(pumpingStationAudit.createdOn).format('DD-MM-YYYY')}: PG ${pumpingGroup.number}`}
                            </Typography>
                            <Tooltip title={'Close'}>
                                <IconButton color={'inherit'} disabled={isLoading} onClick={this.onClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete={'off'} onSubmit={this.onSubmit} className={'fdc flx1 hfill oya'}>
                        <DialogContent className={'fdc hfill'}>
                            <div className={'fdr flx1'}>
                                <PumpingStationPumpMakeSelector 
                                    makeValue={make}
                                    modelValue={model}
                                    disabled={isLoading}
                                    required={true}
                                    onMakeChange={this.onMakeChange}
                                    onModelChange={this.onModelChange}
                                />
                            </div>
                            <div className='fdr flx1'>
                                <PumpingStationPumpTypeSelector 
                                    value={type}
                                    disabled={isLoading}
                                    required={true}
                                    onChange={this.onTypeChange}
                                />
                                <FormControl fullWidth={true} className={'ml20'}>
                                    <TextField 
                                        type={'text'}
                                        autoComplete={'off'}
                                        id={'serialNumber'}
                                        label={'Serial Number'}
                                        value={serialNumber}
                                        margin={'normal'}
                                        className={'TextField'}
                                        error={!serialNumber}
                                        onChange={this.onSerialChange}
                                    />
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button type={'submit'} disabled={isLoading} variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </>
        );
    };
}
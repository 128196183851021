import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IDamInspection } from '../../../../@types/model/dam/damInspection';
import DamInspectionPhotoView from './PhotoView';

interface IDamInspectionOutletWorksViewProps {
    inspection : IDamInspection;

    onImageClick ?: (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IDamInspectionOutletWorksViewState {}

export default class DamInspectionOutletWorksView extends React.PureComponent<IDamInspectionOutletWorksViewProps, IDamInspectionOutletWorksViewState> {
    constructor(props : IDamInspectionOutletWorksViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { inspection, onImageClick } = this.props;
        
        return (
            <div className='fdr aifs jcc pt28 pb28'>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            CORROSION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.outletWorksCorrosion &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.outletWorksCorrosion &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    YES
                                </Typography>
                                {
                                    inspection.outletWorksCorrosionPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'outletWorksCorrosionPhotoUrl'}
                                            photoThumbUrl={inspection.outletWorksCorrosionPhotoThumbUrl}
                                            photoFileName={inspection.outletWorksCorrosionPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            VALVES
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.outletWorksValves &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.outletWorksValves &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    YES
                                </Typography>
                                {
                                    inspection.outletWorksValvesPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'outletWorksValvesPhotoUrl'}
                                            photoThumbUrl={inspection.outletWorksValvesPhotoThumbUrl}
                                            photoFileName={inspection.outletWorksValvesPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    inspection.outletWorksValvesPhotoThumbUrl1 &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'outletWorksValvesPhotoUrl1'}
                                            photoThumbUrl={inspection.outletWorksValvesPhotoThumbUrl1}
                                            photoFileName={inspection.outletWorksValvesPhotoFileName1 ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    inspection.outletWorksValvesPhotoThumbUrl2 &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'outletWorksValvesPhotoUrl2'}
                                            photoThumbUrl={inspection.outletWorksValvesPhotoThumbUrl2}
                                            photoFileName={inspection.outletWorksValvesPhotoFileName2 ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
            </div>
        );
    };
}

import React, { ChangeEvent } from 'react';
import { IRootState, IAuthState } from '../../../../@types/redux';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PumpingStationVoluteCasingMakeHelper, { IPumpingStationVoluteCasingMake } from '../../../../@types/model/masterData/pumpingStation/voluteCasingMake';
import GeneralFunctions from '../../../../store/general/functions';
import firebase from 'firebase/app';
import MasterDataPumpingStationVoluteCasingMakeFunctions from '../../../../store/masterData/pumpingStation/voluteCasingMake/functions';

interface IPumpingStationVoluteCasingMakeListProps {
    auth : IAuthState;

    isLoading : boolean;
    voluteCasingMakes : Array<IPumpingStationVoluteCasingMake>;
}

interface IPumpingStationVoluteCasingMakeListState {
    searchText : string;
    typeText : string;

    selectedId : string | null;
}

class PumpingStationVoluteCasingMakeListComponent extends React.PureComponent<IPumpingStationVoluteCasingMakeListProps, IPumpingStationVoluteCasingMakeListState> {
    constructor(props : IPumpingStationVoluteCasingMakeListProps) {
        super(props);
        this.state = {
            searchText: '',
            typeText: '',
            selectedId: null,
        };
    }

    public readonly componentDidMount = () => {
        this.load();
    };

    private readonly load = () => {
        MasterDataPumpingStationVoluteCasingMakeFunctions.load();
    };

    private readonly onMakeClick = (event : React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({
            selectedId: event.currentTarget.id,
        });
    };

    private readonly onSearchChanged = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            searchText: event.currentTarget.value,
        });
    };

    private readonly onTypeChanged = (event : ChangeEvent<HTMLInputElement>) => {
        this.setState({
            typeText: event.currentTarget.value,
        });
    };

    private readonly onClearClick = () => {
        this.setState({
            searchText: '',
        });
    };

    private readonly onAddClick = () => {
        if (!this.state.searchText) return;

        if (this.props.voluteCasingMakes.some(n => n.name.toLocaleLowerCase() === this.state.searchText.toLocaleLowerCase())) {
            GeneralFunctions.generalShowErrorSnackbar(`${this.state.searchText} already added.`);
            return;
        }

        const pump : IPumpingStationVoluteCasingMake = {
            id: '',
            name: this.state.searchText,
            types: [],
            createdBy: this.props.auth.session?.firebaseUser.uid ?? '',
            createdByEmployee: this.props.auth.session?.employee.EmployeeNumber ?? '',
            createdByName: this.props.auth.session?.employee.Name ?? '',
            createdOn: firebase.firestore.Timestamp.now().toMillis(),
            updatedBy: this.props.auth.session?.firebaseUser.uid ?? '',
            updatedByEmployee: this.props.auth.session?.employee.EmployeeNumber ?? '',
            updatedByName: this.props.auth.session?.employee.Name ?? '',
            updatedOn: firebase.firestore.Timestamp.now().toMillis(),
            isActive: true,
        };

        PumpingStationVoluteCasingMakeHelper.save(pump);
    };

    private readonly onAddTypeClick = () => {

        if (!this.state.typeText) return;

        const selected = this.getSelected(this.state, this.props);

        if (!selected) return;

        if (selected.types.some(n => n.toLocaleLowerCase() === this.state.typeText.toLocaleLowerCase())) {
            GeneralFunctions.generalShowErrorSnackbar(`${this.state.typeText} already added.`);
            return;
        }

        const types = selected.types.slice();
        types.push(this.state.typeText);

        PumpingStationVoluteCasingMakeHelper.save({
            ...selected,
            types,
        });

        this.setState({
            typeText: '',
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        const selected = this.props.voluteCasingMakes.find(x => x.id === event.currentTarget.value);
        if (!selected) return;

        PumpingStationVoluteCasingMakeHelper.save({
            ...selected,
            isActive: !selected.isActive,
        });
    };

    private readonly onDeleteSizeClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        const selected = this.getSelected(this.state, this.props);

        if (!selected) return;

        const types = selected.types.slice();
        const index = types.indexOf(event.currentTarget.value);
        types.splice(index, 1);

        PumpingStationVoluteCasingMakeHelper.save({
            ...selected,
            types,
        });

        this.setState({
            typeText: '',
        });
    };

    private readonly getVoluteCasingMakes = (state : IPumpingStationVoluteCasingMakeListState, props : IPumpingStationVoluteCasingMakeListProps) => props.voluteCasingMakes;
    private readonly getSearchText = (state : IPumpingStationVoluteCasingMakeListState) => state.searchText;
    private readonly getSelectedId = (state : IPumpingStationVoluteCasingMakeListState) => state.selectedId;

    private readonly getFilteredMakes = createSelector(
        [this.getVoluteCasingMakes, this.getSearchText],
        (voluteCasingMakes, searchText) => {
            return voluteCasingMakes
                .filter(n => n.name.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        },
    );

    private readonly getTotal = createSelector(
        [this.getVoluteCasingMakes],
        (voluteCasingMakes) => {
            return voluteCasingMakes.length;
        },
    );

    private readonly getSelected = createSelector(
        [this.getVoluteCasingMakes, this.getSelectedId],
        (voluteCasingMakes, selectedId) => {
            if (!selectedId) return null;
            return voluteCasingMakes.find(x => x.id === selectedId);
        },
    );

    public readonly render = () => {
        const { searchText, selectedId, typeText } = this.state;
        const { isLoading } = this.props;

        const voluteCasingMakes = this.getFilteredMakes(this.state, this.props);
        const total = this.getTotal(this.state, this.props);
        const selected = this.getSelected(this.state, this.props);

        return (
            <div className={'fdc ais flx1 p10'}>
                <div className='fdr flx1 hfill'>
                    <div className='fdc flx1 hfill'>
                        <Paper className='fdc flx1 hfill m10'>
                            <Toolbar className={'fdr aic'}>
                                <TextField
                                    value={searchText}
                                    onChange={this.onSearchChanged}
                                    placeholder='Search'
                                    margin='dense'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Icon>search</Icon>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <Tooltip title='Clear'>
                                                <IconButton onClick={this.onClearClick}>
                                                    <Icon className='cr'>close</Icon>
                                                </IconButton>
                                            </Tooltip>
                                        ),
                                    }}
                                />
                                <Tooltip title='Add'>
                                    <div>
                                        <IconButton color='primary' onClick={this.onAddClick} aria-label='Add'>
                                            <Icon>add</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Toolbar>
                            <Divider />
                            <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                                Total {total}
                            </Typography>
                            <Divider />
                            <List className={'fdc flx1 oya'}>
                                <div className='mnh4'>
                                    { isLoading && <LinearProgress />}
                                </div>
                                {
                                    voluteCasingMakes.map(n => (
                                        <ListItem button key={n.id} id={n.id} onClick={this.onMakeClick} selected={selectedId === n.id} >
                                            <ListItemText
                                                className={n.isActive ? '' : 'cr'}
                                                primary={`${n.name}`}
                                            />
                                            {
                                                (n.isActive) &&
                                                <ListItemSecondaryAction>
                                                    <Tooltip title='Disable'>
                                                        <IconButton className='cr' value={n.id} onClick={this.onDeleteClick} aria-label='Delete'>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            }
                                            {
                                                (!n.isActive) &&
                                                <ListItemSecondaryAction>
                                                    <Tooltip title='Enable'>
                                                        <IconButton color='primary' value={n.id} onClick={this.onDeleteClick} aria-label='Delete'>
                                                            <Icon>check</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            }
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Paper>
                    </div>
                    <div className='fdc flx4 hfill'>
                        {
                            !!selected &&
                            <Paper className='fdc flx1 hfill m10'>
                                <Toolbar className={'fdr aic'}>
                                    <Typography variant='h5' color='inherit'>
                                        {selected.name}
                                    </Typography>
                                    <span className='flx1' />
                                    <TextField
                                        value={typeText}
                                        onChange={this.onTypeChanged}
                                        placeholder='Type'
                                        margin='dense'
                                        InputProps={{
                                            endAdornment: (
                                                <Tooltip title='Add'>
                                                    <IconButton color='primary' onClick={this.onAddTypeClick}>
                                                        <Icon>add</Icon>
                                                    </IconButton>
                                                </Tooltip>
                                            ),
                                        }}
                                    />
                                    <span className='flx1' />
                                </Toolbar>
                                <Divider />
                                <div className='fdc flx1 hfill'>
                                    <List className='fdc flx1 oya'>
                                        {
                                            selected.types.map((type, index) => (
                                                <ListItem button id={`${type}`} key={index}>
                                                    <ListItemText
                                                        primary={`${type}`}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton className='cr'
                                                            value={type} onClick={this.onDeleteSizeClick} aria-label='Delete'>
                                                            <Icon>delete</Icon>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
        isLoading: state.masterData.pumpingStation.voluteCasingMake.isLoading,
        voluteCasingMakes: state.masterData.pumpingStation.voluteCasingMake.voluteCasingMakes,
    };
};

const PumpingStationVoluteCasingMakeList = connect(
    mapStateToProps,
)(PumpingStationVoluteCasingMakeListComponent);

export default PumpingStationVoluteCasingMakeList;

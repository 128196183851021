import * as React from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IAuthState, IRootState, RootAction } from '../@types/redux';
import { Dispatch, bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import authFunctions from '../store/auth/functions';

interface ILoginProps {
    auth : IAuthState;
}

class Login extends React.Component<ILoginProps> {
    constructor(props : ILoginProps) {
        super(props);
    }

    private readonly onSignInClick = () => {
        authFunctions.firebaseSignIn();
    };

    public readonly render = () => {
        return (
            <div className={'flx1 fdr'}>
                {
                    this.props.auth.isLoggingIn ? (
                        <div className={'flx1 fdc aic jcc'}>
                            <img height={60} src={`${ASSET_BASE}/assets/images/logo.png`} className={'posr post70'} />
                            <CircularProgress size={80} />
                        </div>
                    ) : (
                        <div className={'flx1 fdc aic pt30'}>
                            <Paper className={'fdc p30 w400'}>
                                <Typography color='inherit' variant='h5'>
                                    To continue, please sign in with Google:
                                </Typography>
                                <span className={'mt30'} />
                                <Button variant='contained' color='primary' onClick={this.onSignInClick}>
                                    Continue
                                </Button>
                            </Paper>
                        </div>
                    )
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch : Dispatch<RootAction>) => bindActionCreators(
    { }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { ITransformerSplit } from '../../../../@types/model/transformer/transformer';
import { ITransformerUsage } from '../../../../@types/model/transformer/transformerUsage';
import { IRootState } from '../../../../@types/redux';
import DataFunctions from '../../../../store/data/functions';
import UsageToggleButton from '../../button/UsageToggleButton';
import lodash from 'lodash';

interface ITransformerUsageSelectorProps {
    onUsageClick ?: (usage : string) => void;
    selectedUsages : Array<ITransformerSplit>;

    usages : Array<ITransformerUsage>;
    isLoading : boolean;
}

interface ITransformerUsageSelectorState {}

class TransformerUsageSelector extends React.PureComponent<ITransformerUsageSelectorProps, ITransformerUsageSelectorState> {
    constructor(props : ITransformerUsageSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        DataFunctions.getTransformerUsages();
    };

    private readonly getUsages = (props : ITransformerUsageSelectorProps) => props.usages;

    private readonly getData = createSelector(
        [this.getUsages],
        (usages) => {
            return lodash.chain(usages).filter(n => n.isActive).sortBy(n => n.name).value();
        },
    );

    public readonly render = () => {
        const { onUsageClick, selectedUsages,
            isLoading } = this.props;

        const usages = this.getData(this.props);
        return (
            <React.Fragment>
                <Typography className='fw700 fs18 pb15'>
                    PLEASE SELECT AN USAGE
                </Typography>
                {
                    isLoading &&
                    <LinearProgress />
                }
                <div className='fdr fww hfill'>
                    {
                        !isLoading &&
                        usages.map(usage => (
                            <div key={usage.code} className='aic jcc pr15 pb15'>
                                <UsageToggleButton text={usage.name} value={usage.name} selected={selectedUsages.some(x => x.Usage === usage.name)}
                                    onClick={onUsageClick}
                                />
                            </div>
                        ))
                    }
                </div>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.data.isLoadingTransformerUsages,
        usages: state.data.transformerUsages,
    };
};

export default connect(
    mapStateToProps,
)(TransformerUsageSelector);

import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { createSelector } from 'reselect';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { Transitions } from '../../customComponents/animations/Transitions';
import lodash from 'lodash';
import EditTransformerUsage from '../dialog/EditUsage';

interface ITransformerUsageViewProps {
    transformer : ITransformer;
    isLoading : boolean;
    session : IUserSession;
}

interface ITransformerUsageViewState {}

export default class TransformerUsageView extends React.PureComponent<ITransformerUsageViewProps, ITransformerUsageViewState> {
    constructor(props : ITransformerUsageViewProps) {
        super(props);
        this.state = {};
    }

    public readonly getData = (props : ITransformerUsageViewProps) => props.transformer.UsageSplits;
    public readonly getSplits = createSelector(
        [this.getData],
        (usageSplits) => {
            if (!usageSplits) return [];

            return lodash
                .chain(usageSplits)
                .groupBy(x => x.Dept)
                .map((splits, key) => ({
                    title: `${key} - ${splits[0].DeptName}`,
                    usages: splits.map(split => ({
                        name: split.Usage,
                        value: split.Percentage ?? split.FixedAmount,
                        prefix: !split.FixedAmount ? '' : 'R',
                        sufix: !split.Percentage ? '' : ' %',
                        fleetNumber: split.FleetNumber ?? '',
                    })),
                }))
                .value();
        },
    );

    public readonly render = () => {
        const { transformer, isLoading, session } = this.props;

        const splits = this.getSplits(this.props,);
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg0'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                USAGE &amp; SPLITS
                            </Typography>
                            <span className='flx1' />
                            {
                                session.employee.IsTransformerAdmin &&
                                <EditTransformerUsage transformer={transformer} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc p15'>
                        {
                            splits.map((split, i) => (
                                <div className={`fdc flx1 ${i > 0 ? 'mt15' : ''}`} key={`split_${split.title}_${i}`}>
                                    <Typography className='fwm fs16'>
                                        {split.title}
                                    </Typography>
                                    <div className='fdr fww mt10'>
                                        {
                                            split.usages.map(usage => (
                                                <Typography key={`split_usage_${split.title}_${i}_${usage.name}`} component='div'
                                                    style={{ backgroundColor: '#188944' }}
                                                    className='aic jcc br16 h31 cw fwm fs12 mr10 pl10 pr10'
                                                >
                                                    {usage.name}{usage.name === 'Housing' && usage.fleetNumber ? ` - ${usage.fleetNumber}` : ''} - {usage.prefix}{usage.value?.toFixed(2) ?? ''}{usage.sufix}
                                                </Typography>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    };
}

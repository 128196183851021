import { AppBar, Toolbar, Typography, Paper } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IUserSession } from '../../../@types/employee';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditPumpingStationSummaryDialog from '../dialog/EditSummaryDialog';
import PumpingStationDownloadQrCodeButton from '../dialog/DownloadQrCodes';

interface IPumpingStationSummaryProps {
    pumpingStation : IPumpingStation;
    isLoading : boolean;
    session : IUserSession;
}

interface IPumpingStationSummaryState {
}

class PumpingStationSummary extends React.PureComponent<IPumpingStationSummaryProps, IPumpingStationSummaryState> {
    constructor(props : IPumpingStationSummaryProps) {
        super(props);
        this.state = {
        };
    }

    public render = () => {
        const { pumpingStation, session } = this.props;

        return (
            <div className='fdc flx1 pt20 bcg0'>
                <div className='fdr aic jcc'>
                    <span className='flx1' />
                    <Paper className='mt20 fdc flx2' elevation={3}>
                        <AppBar position='static' className='pbr' elevation={0}>
                            <Toolbar variant='dense'>
                                <Typography variant='h6' className='flx1 cw'>
                                    PUMPING STATION INFO
                                </Typography>
                                <span className={'flx1'} />
                                {
                                    session.employee.IsPumpingStationAdmin &&
                                    <EditPumpingStationSummaryDialog pumpingStation={pumpingStation} transition={Transitions.Up} maxWidth='md' fullWidth />
                                }
                            </Toolbar>
                        </AppBar>
                        <div className={'fdr pt30 pb30 ais'}>
                            <div className={'fdc jcc aic flx1'}>
                                <Typography className={'fs12 cg3'}>
                                    Pumping Station Code
                                </Typography>
                                <Typography className={'fs16 fw500 cg3'}>
                                    { pumpingStation.code }
                                </Typography>
                            </div>
                            <span className={'cp h70 w2 bcGreen br25'}/>
                            <div className={'fdc jcc aic flx1'}>
                                <Typography className={'fs12 cg3'}>
                                    Pumping Station Name
                                </Typography>
                                <Typography className={'fs16 fw500 cg3'}>
                                    { pumpingStation.name }
                                </Typography>
                            </div>
                        </div>
                    </Paper>
                    <span className='flx1' />
                </div>
                <span className='flx1' />
                <div className='fdr aic mb15 mr15'>
                    <span className='flx1' />
                    <PumpingStationDownloadQrCodeButton value={pumpingStation} />
                </div>
            </div>
        );
    };
}

const mapStateToProps = () => {
    return {
    };
};

export default connect(
    mapStateToProps,
)(PumpingStationSummary);

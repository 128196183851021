import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppBar, Drawer, Icon, IconButton, LinearProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import { createSelector } from 'reselect';
import { IChemicalFillingPoint } from '../../../@types/model/fillingPoint/chemical';
import { IAuthState, IRootState } from '../../../@types/redux';
import ChemicalFillingPointInfoDialog from '../dialog/Info';
import { Transitions } from '../../customComponents/animations/Transitions';
import FillingPointChemicalFunctions from '../../../store/fillingPoint/chemical/functions';
import CustomMapBox, { IMapMarker } from '../../customComponents/map/CustomMapBox';
import { CHEMICAL_FILLING_POINT_MAP_IMAGES } from '../../../appConstants';

interface IChemicalFillingPointMapProps extends RouteComponentProps<{
    Code : string;
}, Record<string, unknown>, Record<string, unknown>> {
    auth : IAuthState;
    points : Array<IChemicalFillingPoint>;
    isLoading : boolean;
}

interface IChemicalFillingPointMapState {
    selectedStation ?: IChemicalFillingPoint;
}

class ChemicalFillingPointMapComponent extends React.Component<IChemicalFillingPointMapProps, IChemicalFillingPointMapState> {
    private readonly mapZoom = 8;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];

    constructor(props : IChemicalFillingPointMapProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        FillingPointChemicalFunctions.load(this.setSelectedChemicalFillingPoint);
    };

    private setSelectedChemicalFillingPoint : undefined | (() => void) = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const pointId = urlParams.get('Code') as string;
        this.onMarkerClick(pointId);

        this.setSelectedChemicalFillingPoint = undefined;
    };

    private readonly selectMarker = (point : IChemicalFillingPoint) => {
        this.setState({
            selectedStation: point,
        });
    };
    
    private readonly onMarkerClick = (id : string) => {
        const point = this.props.points.slice().find(n => n.id === id);

        if (point) {        
            this.selectMarker(point);
        }
    };

    private onMoreInfo = () => {
        FillingPointChemicalFunctions.setSelected(this.state.selectedStation);
    };

    private onCloseDrawer = () => {
        this.setState({
            selectedStation: undefined,
        });
    };

    public getData = (state : IChemicalFillingPointMapState, props : IChemicalFillingPointMapProps) => props.points;

    public getFilteredData = createSelector(
        [this.getData],
        (points) => {
            return points.filter(n => n.geo);
        },
    );

    public readonly getMarkers = createSelector(
        [this.getFilteredData],
        (points) => {
            return points.map(n => ({
                coordinates: [n.geo?.longitude ?? 0, n.geo?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: n.code,
            } as IMapMarker));
        }
    );

    public render = () => {
        const { isLoading } = this.props;
        const { selectedStation } = this.state;
        const points = this.getFilteredData(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (<div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Toolbar>
                    <Typography variant='h5' color='inherit'>
                        ChemicalFillingPoints
                    </Typography>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className={'fdr pt5'}>
                            <span style={{ paddingRight: '15px' }}><b>Total: </b>{points.length}</span>
                        </div>
                    </div>
                </Toolbar>
                <div className='mnh4'>
                    { isLoading && <LinearProgress />}
                </div>
                <Paper className={'flx1 fdr oxh'}>
                    {
                                            
                        points.length > 0 &&
                        <CustomMapBox
                            id='point_map'
                            enableAreas
                            geoLocationControl
                            navigationControl
                            showScale
                            layerControls
                            images={CHEMICAL_FILLING_POINT_MAP_IMAGES}
                            onMarkerClick={this.onMarkerClick}
                            markers={markers}
                            selectedMarker={selectedStation?.code}
                        />
                    }
                </Paper>
            </div>
            <Drawer open={!!selectedStation} anchor='right' variant='persistent'>
                <div className={'w300 flx1 fdc'}>
                    <AppBar className='posr' position='static'>
                        <Toolbar variant='dense'>
                            <div className='h60' />
                            <IconButton color={'inherit'} onClick={this.onMoreInfo}><Icon color={'inherit'}>more_vert</Icon></IconButton>
                            <Typography color='inherit' variant='h6' className={'flx1 ml10'} noWrap={true}>
                                {!!selectedStation && selectedStation.code}
                            </Typography>
                            <IconButton color={'inherit'} onClick={this.onCloseDrawer}><Icon color={'inherit'}>close</Icon></IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        selectedStation &&
                        <div className='fdc flx1'>
                            <div className='fdr flx1'>
                                <div className={'fdc flx1'}>
                                    <Typography className={'fdr bcDarkBlue fw700 cw p10'} variant='h6'>
                                        SUMMARY
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            Code
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            Name
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedStation.code}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedStation.name}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <ChemicalFillingPointInfoDialog fullScreen transition={Transitions.Up} />
            </Drawer>
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        points: state.fillingPoints.chemical.points,
        selectedGuid: state.fillingPoints.chemical.selectedGuid,
        isLoading: state.fillingPoints.chemical.isLoading,
        auth: state.auth,
    };
};

const ChemicalFillingPointMap = connect(
    mapStateToProps,
)(withRouter(ChemicalFillingPointMapComponent));

export default ChemicalFillingPointMap;

import React from 'react';
import { IMottechAudit } from '../../../@types/mottech';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import appFunctionsService from '../../../services/appFunctionsService';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { IUserSession } from '../../../@types/employee';
import MottechAuditEntryView from './AuditEntryView';

interface IMottechAuditViewProps {
    audits : Array<IMottechAudit>;
    isLoading : boolean;
    session ?: IUserSession | null;
}

interface IMottechAuditViewState {}

class MottechAuditView extends React.Component<IMottechAuditViewProps, IMottechAuditViewState> {
    constructor(props : IMottechAuditViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { audits, isLoading } = this.props;
        return (
            <div className={'fdc flx1 m10'}>
                { isLoading && <LinearProgress className={'wfill mt5'} />}
                {
                    !isLoading && audits.length === 0 &&
                <div>
                    <Typography className={'mb10'} color='inherit' variant='h5'>
                        No Audit Data Exists!
                    </Typography>
                </div>
                }
                {
                    audits.map(n => (
                        <ExpansionPanel key={n.Id} className={'wfill'}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                { appFunctionsService.formatDateTimeToDateOnly(n.CreatedOn) }
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={'fdc flx1'}>
                                <MottechAuditEntryView audit={n} />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        audits: state.mottech.audits,
        isLoading: state.mottech.loadingAudits,
    };
};

export default connect(
    mapStateToProps,
)(MottechAuditView);

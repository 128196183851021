import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationMotorType } from '../../../../@types/model/masterData/pumpingStation/motorType';

type MasterDataPumpingStationMotorTypeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationMotorTypeState {
    motorTypes : Array<IPumpingStationMotorType>;
    isLoading : boolean;
}
const initialState = {
    motorTypes: [],
    isLoading: false,
};

export default function motorTypeReducer(state : IMasterDataPumpingStationMotorTypeState = initialState, action : MasterDataPumpingStationMotorTypeActionType) : IMasterDataPumpingStationMotorTypeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, motorTypes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IBoreholeConstantRateTest } from '../../../../@types/model/borehole/boreholeConstantRateTest';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import BoreholeConstantRateEntryGraphView from './ConstantRateEntryGraphView';
import BoreholeConstantRateEntryTableView from './ConstantRateEntryTableView';

interface IBoreholeConstantRateRecoveryEntryLogViewProps {
    constantRateTest : IBoreholeConstantRateTest;
}

interface IBoreholeConstantRateRecoveryEntryLogViewState {
    viewAsTable : boolean;
}

export default class BoreholeConstantRateRecoveryEntryLogView extends React.Component<IBoreholeConstantRateRecoveryEntryLogViewProps, IBoreholeConstantRateRecoveryEntryLogViewState> {
    constructor(props : IBoreholeConstantRateRecoveryEntryLogViewProps) {
        super(props);
        this.state = {
            viewAsTable: false,
        };
    }

    private readonly onViewAsClick = () => {
        this.setState({
            viewAsTable: !this.state.viewAsTable,
        });
    };

    public readonly render = () => {
        const { constantRateTest } = this.props;
        const { viewAsTable } = this.state;
        return (
            <div className='fdc mt8'>
                <Card className='fdr aic jcc p15 bcp'>
                    <Typography className='flx1 aic jcc fs18 fwb cw'>
                        RECOVERY TEST
                    </Typography>
                </Card>
                <div className='fdc mt16 pl20 pr20'>
                    <Card className='fdr aic jcfs p15 dvbg'>
                        <Typography className='flx1 aic jcfs fs14 fw500 cw'>
                            LOG {!viewAsTable ? 'GRAPH' : 'TABLE'}
                        </Typography>
                        <div className='posr'>
                            <div className='posa posr0 postn17 w150'>
                                <OutlinedStadiumButton
                                    variant={'outlined'}
                                    onClick={this.onViewAsClick}
                                    className={'cw bocw'}
                                >
                                    VIEW AS {viewAsTable ? 'GRAPH' : 'TABLE'}
                                </OutlinedStadiumButton>
                            </div>
                        </div>
                    </Card>
                    {
                        viewAsTable &&
                        <BoreholeConstantRateEntryTableView
                            entries={constantRateTest.recoveryEntries}
                        />
                    }
                    {
                        !viewAsTable &&
                        <BoreholeConstantRateEntryGraphView
                            entries={constantRateTest.recoveryEntries}
                        />
                    }
                </div>
            </div>
        );
    };
}

import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationVoluteCasingMake } from '../../../../@types/model/masterData/pumpingStation/voluteCasingMake';

type MasterDataPumpingStationVoluteCasingMakeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationVoluteCasingMakeState {
    voluteCasingMakes : Array<IPumpingStationVoluteCasingMake>;
    isLoading : boolean;
}
const initialState = {
    voluteCasingMakes: [],
    isLoading: false,
};

export default function voluteCasingMakeReducer(state : IMasterDataPumpingStationVoluteCasingMakeState = initialState, action : MasterDataPumpingStationVoluteCasingMakeActionType) : IMasterDataPumpingStationVoluteCasingMakeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, voluteCasingMakes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

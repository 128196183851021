import { Tooltip } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IBorehole } from '../../@types/model/borehole/borehole';
import { IRootState } from '../../@types/redux';
import boreholeFunctions from '../../store/borehole/functions';
import RuntimesOverrideTable from '../customComponents/runtimesOverrideTable/RuntimesOverrideTable';
import DivisionSelector from '../customComponents/selector/DivisionSelector';
import ToggleStadiumButton from '../customComponents/button/ToggleStadiumButton';

interface IRuntimesOverrideProps {
    boreholes : Array<IBorehole>;
    isLoadingBoreholes : boolean;
}

interface IRuntimesOverrideState {
    isLoading : boolean;
    onlyEquipped : boolean;
    division : string;
}

class RuntimesOverride extends React.PureComponent<IRuntimesOverrideProps, IRuntimesOverrideState> {
    constructor(props : IRuntimesOverrideProps) {
        super(props);
        this.state = {
            isLoading: false,
            division: '',
            onlyEquipped: true,
        };
    }

    public readonly componentDidMount = () => {
        boreholeFunctions.getBoreholes();
    };

    private readonly save = async (boreholes : Array<IBorehole>) => {
        return boreholeFunctions.saveBoreholes(boreholes);
    };

    private readonly onDivisionChanged = (division : string) => {
        this.setState({
            division,
        });
    };
    private readonly onShowOnlyEquipped = () => {
        this.setState({
            onlyEquipped: !this.state.onlyEquipped,
        });
    };

    public readonly getBoreholes = (props : IRuntimesOverrideProps) => props.boreholes;
    public readonly getDivision = (props : IRuntimesOverrideProps, state : IRuntimesOverrideState) => state.division;
    public readonly getOnlyEquipped = (props : IRuntimesOverrideProps, state : IRuntimesOverrideState) => state.onlyEquipped;
    public readonly getList = createSelector(
        [this.getBoreholes, this.getDivision, this.getOnlyEquipped],
        (boreholes, division, onlyEquipped) => {
            return division ? boreholes
                .filter(x => x.Division === division && (!onlyEquipped || x.Equipped))
                .map(x => ({ ...x, RuntimeOverride: !x.RuntimeOverride ? undefined : (x.RuntimeOverride / 60) })) : [];
        },
    );

    public readonly render = () => {
        const { isLoadingBoreholes } = this.props;
        const { division, onlyEquipped } = this.state;
        const boreholes = this.getList(this.props, this.state);

        return (
            <div className='fdc flx1 pr20 pl20 pt10 pb20 mh0 mw0 bcg2'>
                <div className='fdc flx1 mh0 mw0'>
                    <div className='fdr'>
                        <DivisionSelector division={division} onDivisionChanged={this.onDivisionChanged} />
                        <div className={'fdr pt5 jcfe aic pr15'}>
                            <Tooltip title='Show only Equipped'>
                                <ToggleStadiumButton isSelected={onlyEquipped} onClick={this.onShowOnlyEquipped}
                                    toggledIcon={`${ASSET_BASE}/assets/images/icons/tap.svg`}
                                    untoggledIcon={`${ASSET_BASE}/assets/images/icons/tap2.svg`}
                                >
                                    EQUIPPED BOREHOLES
                                </ToggleStadiumButton>
                            </Tooltip>
                        </div>
                    </div>
                    <RuntimesOverrideTable save={this.save} isLoading={isLoadingBoreholes} boreholes={boreholes} />
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        boreholes: state.borehole.boreholes,
        isLoadingBoreholes: state.borehole.loading,
    };
};

export default connect(
    mapStateToProps,
)(RuntimesOverride);

import { Card, CircularProgress, Divider, Tooltip, Typography } from '@material-ui/core';
import ModeCommentIcon from '@material-ui/icons/AddComment';
import React from 'react';
import { connect } from 'react-redux';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import TransformerCommentHelper, { ITransformerComment } from '../../../@types/model/transformer/transformerComment';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import StandardFab from '../../customComponents/button/StandardFab';
import StadiumDiv from '../../customComponents/div/Stadium';
import AddTransformerCommentDialog from '../dialog/AddComment';

interface ITransformerCommentsViewProps {
    transformer ?: ITransformer;
    session ?: IUserSession | null;
}

interface ITransformerCommentsViewState {
    isLoading : boolean;

    comments : Array<ITransformerComment>;

    openAdd : boolean;
}

class TransformerCommentsViewComponent extends React.Component<ITransformerCommentsViewProps, ITransformerCommentsViewState> {
    private listener ?: () => void;
    constructor(props : ITransformerCommentsViewProps) {
        super(props);
        this.state = {
            isLoading: false,
            comments: [],
            openAdd: false,
        };
    }

    public componentDidMount() : void {
        this.load();
    }

    public componentWillUnmount() : void {
        if (this.listener) {
            this.listener();
        }
    }

    public readonly onAddClick = () => {
        this.setState({
            openAdd: true,
        });
    };

    public readonly onAddClose = () => {
        this.setState({
            openAdd: false,
        });
    };

    private readonly load = () => {
        if (this.listener) {
            this.listener();
        }

        const { transformer } = this.props;

        if (!transformer) return;

        this.setState({
            isLoading: true,
        });

        this.listener = TransformerCommentHelper.collection(transformer.ref).onSnapshot((snapshot) => {
            const comments = this.state.comments.slice();
            snapshot.docChanges().forEach((f) => {
                const data = f.doc.data();
                const index = comments.findIndex(n => n.id === data.id);

                switch (f.type) {
                    case 'added':
                        comments.push(data);
                        break;
                    case 'modified':
                        comments.splice(index, 1, data);
                        break;
                    case 'removed':
                        comments.splice(index, 1);
                        break;
                }
            });

            this.setState({
                isLoading: false,
                comments: comments.sort((a, b) => b.date - a.date),
            });
        }, (error) => {
            this.setState({
                isLoading: false,
            });

            GeneralFunctions.generalShowError(error, 'Error loading comments');
        });
    };

    public readonly render = () => {
        const { isLoading, comments, openAdd } = this.state;
        const { transformer, session } = this.props;
        return (
            <div className='fdc flx1 pt20 pb20 bcg0'>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !comments.length &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography>No Data</Typography>
                    </div>
                }
                {
                    !isLoading &&
                    comments.length &&
                    <div className='fdr flx1 ml15 mr15 pb15'>
                        <div className={'fdc flx1'}>
                            <Card className={'bcw mt30 fdc'}>
                                <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                                    <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                                        UPDATES
                                    </Typography>
                                </div>
                                <div className={'fdc ml7 mr7'}>
                                    {
                                        comments.map(x => (
                                            <div key={x.id} className='fdc'>
                                                <div className='fdr bcg0 pl10 mb10'>
                                                    <div className='fdc flx1'>
                                                        <Typography className='fw700 cdg fs18 mt5 mb5'>
                                                            {
                                                                x.createdByName
                                                            }
                                                        </Typography>
                                                        <Typography className='cpd fs12 mb10'>
                                                            {
                                                                AppFunctionsService.formatDateTimeToMMMDDHHmm(x.date)
                                                            }
                                                        </Typography>
                                                    </div>
                                                    <div className='fdc jcc aic mr10'>
                                                        
                                                        <StadiumDiv className=''>
                                                            {
                                                                x.type
                                                            }
                                                        </StadiumDiv>
                                                    </div>
                                                </div>
                                                <div className='fdr ml10 mr10 mb10'>
                                                    <Divider className='bcp flx1 h2' />
                                                </div>
                                                <div className='fdc ml10 mr10 mb10'>
                                                    {
                                                        /** This is ugly but needed for displaying new lines... */
                                                        x.comment.split('\n').map(n => (
                                                            <Typography key={n} className='fs15 cg3' component='p'>
                                                                {
                                                                    n
                                                                }
                                                            </Typography>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Card>
                        </div>
                    </div>
                }
                {
                    !isLoading &&
                    session &&
                    session.employee.IsTransformerAdmin &&
                    <div className='fdr aife jcfe'>
                        <Tooltip title='Add'>
                            <div>
                                <StandardFab onClick={this.onAddClick}>
                                    <ModeCommentIcon />
                                </StandardFab>
                            </div>
                        </Tooltip>
                    </div>
                }
                {
                    transformer &&
                    session &&
                    <AddTransformerCommentDialog
                        open={openAdd}
                        maxWidth='md'
                        onClose={this.onAddClose}
                        fullWidth
                        session={session}
                        transformer={transformer}
                    />
                }
                
            </div>
        );
    };
}

const mapStateToProps = () => ({});

const TransformerCommentsView = connect(
    mapStateToProps,
)(TransformerCommentsViewComponent);

export default TransformerCommentsView;
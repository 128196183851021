import { Typography } from '@material-ui/core';
import React from 'react';

interface IBoreholeCalibrationEntryLogisticsCardViewProps {
    value : string;
    title : string | React.ReactNode;

    titleHeight ?: number;

    alt ?: boolean;
}

export default class BoreholeCalibrationEntryLogisticsCardView extends React.Component<IBoreholeCalibrationEntryLogisticsCardViewProps> {
    constructor(props : IBoreholeCalibrationEntryLogisticsCardViewProps) {
        super(props);
    }

    public readonly render = () => {
        const { value, title, titleHeight = 32, alt } = this.props;
        return (
            <div className='fdc mw160 maw180'>
                <div className={`fdr aic jcc ${alt ? 'bcp cw fwb' : 'dvblg fw400'} pl10 pr10`}
                    style={{
                        height: titleHeight,
                        minHeight: titleHeight,
                    }}
                >
                    <Typography className='tac cw fs11'>
                        {
                            title
                        }
                    </Typography>
                </div>
                <span className='h2' />
                <div className={`fdr aic jcc ${alt ? 'dvbg cw' : 'bcg0'} mnh32`}>
                    <Typography className='fs13 fwb'>
                        {
                            value
                        }
                    </Typography>
                </div>
            </div>
        );
    };
}

import { Card, Icon, IconButton, TextField, Typography } from '@material-ui/core';
import { FieldArrayRenderProps } from 'formik';
import React from 'react';
import { createSelector } from 'reselect';
import { IBoreholeCalibrationNeighbourFormValues } from '../../../../@types/model/borehole/boreholeCalibration';
import BoreholeFormField from '../borehole/BoreholeAutocompleteFormField';
import TextFormField from '../TextFormField';
import TimeFormField from '../TimeFormField';

interface IBoreholeCalibrationNeighbouringFormFieldProps extends FieldArrayRenderProps {
}

export default class BoreholeCalibrationNeighbouringFormField extends React.PureComponent<IBoreholeCalibrationNeighbouringFormFieldProps> {
    constructor(props : IBoreholeCalibrationNeighbouringFormFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly getValues = (props : IBoreholeCalibrationNeighbouringFormFieldProps) => props.form.values;
    public readonly getName = (props : IBoreholeCalibrationNeighbouringFormFieldProps) => props.name;
    public readonly getList = createSelector(
        [this.getValues, this.getName],
        (values, name : string) => {
            return values[name] as Array<IBoreholeCalibrationNeighbourFormValues>;
        },
    );

    public readonly onAddClick = () => {
        this.props.push({});
    };

    public readonly render = () => {
        const neighbouringBoreholes = this.getList(this.props);
        return (
            <div className='fdc'>
                <Card className='fdr aic jcc p15 dbco'>
                    <Typography className='flx1 aic jcc fs18 fwb cw'>
                        IDENTIFICATION AND GROUNDWATER LEVEL OF NEIGHBOURING BOREHOLES
                    </Typography>
                    <div className='fdc jcc aic posr'>
                        <div className='posa'>
                            <IconButton className='cw mr30' onClick={this.onAddClick}>
                                <Icon>
                                    add
                                </Icon>
                            </IconButton>
                        </div>
                    </div>
                </Card>
                <div className='fdr mt12'>
                    <Card className='fdc flx1'>
                        <div className={'jcc aic wfill bcp h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                WB NUMBER
                            </Typography>
                        </div>
                        {
                            neighbouringBoreholes.map((n, index) => (
                                <div key={index} className={`fdr aic jcc pr30 pl30 pb20 pt20 ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                    <BoreholeFormField
                                        name={`neighbouringBoreholes.${index}.boreholeCode`}
                                        required
                                    />
                                </div>
                            ))
                        }
                    </Card>
                    <span className='w30' />
                    <Card className='fdc flx1'>
                        <div className={'jcc aic wfill bcp h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                TIME
                            </Typography>
                        </div>
                        {
                            neighbouringBoreholes.map((n, index) => (
                                <div key={index} className={`fdr aic jcc pr30 pl30 pb28 pt28 ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                    <TimeFormField
                                        name={`neighbouringBoreholes.${index}.time`}
                                        fullWidth
                                        required
                                        TextFieldComponent={(params) => (
                                            <TextField
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    className: params.inputProps + ' cp fwb',
                                                    style: {
                                                        ...params.inputProps?.style,
                                                        textAlign: 'center',
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            ))
                        }
                    </Card>
                    <span className='w30' />
                    <Card className='fdc flx1'>
                        <div className={'jcc aic wfill bcp h50'}>
                            <Typography className={'fs14 fw400 cw'}>
                                GROUNDWATER LEVEL (m)
                            </Typography>
                        </div>
                        {
                            neighbouringBoreholes.map((n, index) => (
                                <div key={index} className={`fdr aic jcc pr30 pl30 pb28 pt28 ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                    <TextFormField
                                        inputProps={{
                                            className: 'cp fwb',
                                            style: {
                                                textAlign: 'center',
                                            },
                                        }}
                                        id={`neighbouringBoreholes.${index}.waterLevel`}
                                        name={`neighbouringBoreholes.${index}.waterLevel`}
                                        fullWidth
                                        required
                                        type='number'
                                    />
                                </div>
                            ))
                        }
                    </Card>
                </div>
            </div>
        );
    };
}

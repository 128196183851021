import React from 'react';
import { ITransformer, ITransformerSplit } from '../../../@types/model/transformer/transformer';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import transformerFunctions from '../../../store/transformer/functions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import TransformerUsageSplitInput from '../input/UsageSplitInput';
import firebase from 'firebase/app';
import generalFunctions from '../../../store/general/functions';
import TransformerUsageSelector from '../../customComponents/selector/transformer/TransformerUsageSelector';

interface IEditTransformerUsageProps {
    transformer : ITransformer;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditTransformerUsageState {
    open : boolean;
    usages : Array<ITransformerSplit>;

}

export default class EditTransformerUsage extends React.Component<IEditTransformerUsageProps, IEditTransformerUsageState> {
    constructor(props : IEditTransformerUsageProps) {
        super(props);
        this.state = {
            open: false,
            usages: [],
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTransformerUsageProps, prevState : IEditTransformerUsageState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                usages: [],
            });
        }
        if (!prevState.open && this.state.open) {
            
            if (this.props.transformer.Usage instanceof firebase.firestore.FieldValue) return;

            const usages = this.props.transformer.UsageSplits ?? (
                !this.props.transformer.Usage ? [] : this.props.transformer.Usage.map(n => ({
                    FixedAmount: null,
                    IncludeVat: false,
                    Percentage: null,
                    Usage: n,
                    Dept: '',
                    DeptName: '',
                }))
            );
            this.setState({
                usages,
            });
        }
    };

    private readonly save = async () => {
        const { usages } = this.state;

        if (usages.some(n => (!n.Dept) || (!n.FixedAmount && !n.Percentage))) return;

        if (usages.map(n => n.Percentage ?? 0).reduce((prev, current) => prev + current) > 100) {
            generalFunctions.generalShowErrorSnackbar('Total percentages more than 100%');
            return;
        }

        const transformer = Object.assign({}, this.props.transformer);

        transformer.UsageSplits = usages;

        const result = await transformerFunctions.saveTransformer(transformer);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onUsageClick = (usage : string) => {
        const usages = this.state.usages.slice();

        usages.push({
            FixedAmount: null,
            IncludeVat: false,
            Percentage: null,
            Usage: usage,
            Dept: '',
            DeptName: '',
        });

        this.setState({
            usages,
        });
    };

    private readonly onRemoveClick = (index : number) => {
        const usages = this.state.usages.slice();

        usages.splice(index, 1);

        this.setState({
            usages,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onUsageChange = (index : number, usage : ITransformerSplit) => {
        const usages = this.state.usages.slice();

        usages.splice(index, 1, usage);

        this.setState({
            usages,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, usages } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-usage-info-title'
                    aria-describedby='transformer-usage-info-description'
                >
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                EDIT USAGE &amp; SPLITS
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className='fdc oya'>
                                <TransformerUsageSelector onUsageClick={this.onUsageClick} selectedUsages={usages} />
                                <Divider />
                                {
                                    usages.map((usage, index) => (
                                        <TransformerUsageSplitInput key={`${usage.Usage}_${index}`}
                                            index={index} usage={usage}
                                            onRemoveClick={this.onRemoveClick} isLoading={isLoading}
                                            onChange={this.onUsageChange}
                                        />
                                    ))
                                }
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

import { Typography, Card } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Transitions } from '../../../customComponents/animations/Transitions';
import { IRootState } from '../../../../@types/redux';
import { IChemicalFillingPoint } from '../../../../@types/model/fillingPoint/chemical';
import EditChemicalFillingPointDivisionInfo from '../../dialog/EditDivisionInfoDialog';

interface IChemicalFillingPointDivisionViewProps {
    point : IChemicalFillingPoint;

    isLoading : boolean;

    isFillingPointAdmin : boolean;
}

interface IChemicalFillingPointDivisionViewState {
}

class ChemicalFillingPointDivisionViewComponent extends React.PureComponent<IChemicalFillingPointDivisionViewProps, IChemicalFillingPointDivisionViewState> {
    constructor(props : IChemicalFillingPointDivisionViewProps) {
        super(props);
        this.state = {
        };
    }

    public readonly render = () => {
        const { point, isFillingPointAdmin, isLoading } = this.props;
        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            DIVISION INFORMATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isFillingPointAdmin &&
                                    <EditChemicalFillingPointDivisionInfo
                                        point={point}
                                        isLoading={isLoading}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdc flx1 ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DIVISION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {point.division ? point.division : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    MANAGMENT AREA
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {point.managementArea ? point.managementArea : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    DIVISION NUMBER
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {point.divisionNo ? point.divisionNo : 'None'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    ACCPAC DESCRIPTION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {point.accpacDesc ? point.accpacDesc : 'None'}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isFillingPointAdmin: !!state.auth.session?.employee.IsFillingPointAdmin,
        isLoading: state.fillingPoints.chemical.isLoading,
    };
};

const ChemicalFillingPointDivisionView = connect(
    mapStateToProps,
)(ChemicalFillingPointDivisionViewComponent);

export default ChemicalFillingPointDivisionView;


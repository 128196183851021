import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationPumpType } from '../../../../@types/model/masterData/pumpingStation/pumpType';

type MasterDataPumpingStationPumpTypeActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationPumpTypeState {
    pumpTypes : Array<IPumpingStationPumpType>;
    isLoading : boolean;
}
const initialState = {
    pumpTypes: [],
    isLoading: false,
};

export default function pumpTypeReducer(state : IMasterDataPumpingStationPumpTypeState = initialState, action : MasterDataPumpingStationPumpTypeActionType) : IMasterDataPumpingStationPumpTypeState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, pumpTypes: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

import { BasicBaseHelper, IBasicBaseClass } from '../base';
import { firestore } from 'firebase/app';
import BoreholeHelper, { IBorehole } from './borehole';

export interface IBoreholeComment extends IBasicBaseClass {
    boreholeRef : string; // Borehole Id

    comment : string;

    type : 'comment' | 'website' | 'calibration' | 'constant rate' | 'mobile' | string;

    date : number;
}

export default class BoreholeCommentHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'borehole_comment';

    private static converter : firebase.firestore.FirestoreDataConverter<IBoreholeComment> = {
        fromFirestore: (snapshot) => {
            return BoreholeCommentHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IBoreholeComment) : firebase.firestore.DocumentData => {
            return BoreholeCommentHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IBoreholeComment {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IBoreholeComment,
            ...result,
            boreholeRef: (data['boreholeRef'] as firestore.DocumentReference).id,
            date: (data['date'] as firestore.Timestamp).toMillis(),
        };
    }

    public static toFirestoreDocument(data : IBoreholeComment) {
        const result = super.toFirestore(data);
        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
            boreholeRef: BoreholeHelper.doc(data.boreholeRef),
            date: firestore.Timestamp.fromMillis(data.date),
        };
    }
    
    public static collection(ref : firebase.firestore.DocumentReference<IBorehole>) {
        return ref
            .collection(BoreholeCommentHelper.COLLECTION_NAME)
            .withConverter(BoreholeCommentHelper.converter);
    }

    public static save(data : IBoreholeComment) {
        if (!data.id) {
            return BoreholeCommentHelper.doc(BoreholeHelper.doc(data.boreholeRef)).set(data, {
                merge: true,
            });
        }

        return BoreholeCommentHelper.doc(BoreholeHelper.doc(data.boreholeRef), data.id).set(data, {
            merge: true,
        });
    }

    public static doc(ref : firebase.firestore.DocumentReference<IBorehole>, id ?: string) {
        if (!id) {
            return this.collection(ref).withConverter(BoreholeCommentHelper.converter).doc();
        }

        return this.collection(ref).withConverter(BoreholeCommentHelper.converter).doc(id);
    }

    public static delete(ref : firebase.firestore.DocumentReference<IBorehole>, id : string) {
        return this.doc(ref, id)
            .delete();
    }
}
import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';

export interface IPump extends IBasicBaseClass {
    name : string;
    types : Array<string>;

    isActive : boolean;
}

export default class PumpHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumps';

    private static converter : firebase.firestore.FirestoreDataConverter<IPump> = {
        fromFirestore: (snapshot) => {
            return PumpHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPump) : firebase.firestore.DocumentData => {
            return PumpHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPump {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data() as IPump;

        if (!snapshot.exists || !result) throw new Error(`Document does not exist! ${snapshot.id}`);

        return {
            ...data,
            ...result,
            isActive: data.isActive ?? true,
        };
    }

    public static toFirestoreDocument(data : IPump) {
        const result = super.toFirestore(data);
        const { id: _id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static collection() {
        return firebaseApp
            .firestore()
            .collection(PumpHelper.COLLECTION_NAME)
            .withConverter(PumpHelper.converter);
    }

    public static doc(id ?: string) {
        if (!id) {
            return PumpHelper.collection().doc();
        }

        return PumpHelper.collection().doc(id);
    }

    public static save(pump : IPump) {
        return PumpHelper.doc(pump.id).set(pump, {
            merge: true,
        });
    }
}

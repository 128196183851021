import React from 'react';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import navFunctions from '../../store/nav/functions';
import MapIcon from '@material-ui/icons/Map';
import PumpingStationHelper, { IPumpingStation } from '../../@types/model/pumpingStation/pumpingStation';
import PumpingStationFunctions from '../../store/pumpingStation/functions';
import PumpingStationNewButton from './button/NewButton';
import { Transitions } from '../customComponents/animations/Transitions';
import PumpingStationInfo from './Info';
import { Fab, Icon } from '@material-ui/core';
import PumpingStationFilterSelector from '../customComponents/selector/pumpingStation/FilterSelector';
import { IUserSession } from '../../@types/employee';
import { CSVLinkProps } from '../../@types/csv';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import PumpingStationPrintQrCodeButton from './dialog/PrintQrCode';

interface IPumpingStationListProps {
    pumpingStations : Array<IPumpingStation>;
    isLoadingPumpingStations : boolean;
    session ?: IUserSession | null;
}

interface IPumpingStationListState {
    sortList : Array<{ field : string; direction : 'asc' | 'desc' }>;
    showAll : boolean;

    name : string;
    code : string;
    division : string;
    manArea : string;

    selectedStations : Array<IPumpingStation>;
}

class PumpingStationList extends React.Component<IPumpingStationListProps, IPumpingStationListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : IPumpingStationListProps) {
        super(props);

        this.state = {
            sortList: [],
            selectedStations: [],
            showAll: false, 
            name: '',
            code: '',
            division: '',
            manArea: '',
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public componentDidMount = () => {
        PumpingStationFunctions.getList();
    };

    public onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const pumpingStation = this.props.pumpingStations.slice().find(x => x.code === event.currentTarget.value);

        PumpingStationFunctions.setSelected(pumpingStation);
    };

    public onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const pumpingStation = this.props.pumpingStations.slice().find(x => x.code === event.currentTarget.value);

        if (pumpingStation) {
            navFunctions.navPumpingStationMap(pumpingStation.code);
        }
    }; 

    private getSortList = (props : IPumpingStationListProps, state : IPumpingStationListState) => state.sortList;
    private getName = (props : IPumpingStationListProps, state : IPumpingStationListState) => state.name;
    private getCode = (props : IPumpingStationListProps, state : IPumpingStationListState) => state.code;
    private getDivision = (props : IPumpingStationListProps, state : IPumpingStationListState) => state.division;
    private getmanArea = (props : IPumpingStationListProps, state : IPumpingStationListState) => state.manArea;
    private getData = (props : IPumpingStationListProps) => props.pumpingStations;
    private getShowAll = (props : IPumpingStationListProps, state : IPumpingStationListState) => state.showAll;

    public getFilteredData = createSelector(
        [this.getData, this.getSortList, this.getShowAll,
            this.getName, this.getCode, this.getDivision,
            this.getmanArea],
        (pumpingStations : Array<IPumpingStation>, 
            sortList : Array<{ field : string; direction : 'asc' | 'desc' }>,
            showAll : boolean, 
            name : string,
            code : string,
            division : string,
            manArea : string,    
        ) => {
            let sortedList = pumpingStations;
            sortedList = lodash
                .chain(sortedList)
                .filter(n => showAll || typeof n.isActive === 'undefined' || n.isActive)
                .filter(n => n.code.includes(code))
                .filter(n => n.name.toLowerCase().includes(name.toLowerCase()))
                .filter(n => !division || !!n.division?.toLowerCase().includes(division.toLowerCase()))
                .filter(n => !manArea || !!n.managementArea?.toLowerCase().includes(manArea.toLowerCase()))
                .value();
            if (sortList.length === 0) {
                return sortedList.sort((a : IPumpingStation, b : IPumpingStation) => parseInt(a.code.substring(2)) - parseInt(b.code.substring(2)));
            }

            sortList
                .forEach((n) => {
                    if (n.field === 'code') {
                        sortedList = sortedList.sort((a, b) => parseInt(b.code.substring(2)) - parseInt(a.code.substring(2)) * (n.direction === 'desc' ? 1 : -1));
                    } else {
                        sortedList = lodash.orderBy(sortedList, n.field, n.direction);
                    }
                });
            return sortedList;
        },
    );

    public onChange = (name : string | null, code : string | null, division : string | null, manArea : string | null) => {
        this.setState({
            name: name ?? '',
            code: code ?? '',
            division: division ?? '',
            manArea: manArea ?? '',
        });
    };

    public onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    private onShowOnlyActiveClick = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    };

    public getCSVData = createSelector(
        [this.getFilteredData],
        (pumpingStations) => {
            return PumpingStationHelper.mapToCsv(pumpingStations);
        },
    );

    private readonly onSelectedClose = () => {
        this.setState({
            selectedStations: [],
        });
    };

    private readonly onSelectionChange = (selectedStations : Array<IPumpingStation>) => {
        this.setState({
            selectedStations: selectedStations.slice(),
        });
    };

    public render = () => {
        const { isLoadingPumpingStations, session } = this.props;
        const { showAll, name, code, division, manArea, selectedStations } = this.state;
        const pumpingStations = this.getFilteredData(this.props, this.state);
        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0 pt30 pl30 pr30'}>
                    <div className={'fdr wfill jcfe mr15 pb30 aic'}>
                        <PumpingStationFilterSelector
                            id='dam_filter'
                            onChange={this.onChange}
                            name={name}
                            code={code}
                            division={division}
                            manArea={manArea}
                            label='Filter By...'
                            disabled={isLoadingPumpingStations}
                        />
                        <div className={'fdr pt5 aic pl10'}>
                            <Tooltip title={'Show only Active'}>
                                <Fab color={!showAll ? 'primary' : 'default'} size={'small'} onClick={this.onShowOnlyActiveClick}>
                                    <Icon>
                                        <img src={`${ASSET_BASE}/assets/images/icons/active.svg`} />
                                    </Icon>
                                </Fab>
                            </Tooltip>    
                            <span className={'pl10 pt5 pr20'}><b>Total: </b>{pumpingStations.length}</span>
                        </div>
                    </div>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IPumpingStation>
                            id='PumpingStationTable'
                            data={pumpingStations}
                            isLoading={isLoadingPumpingStations}
                            selectable
                            selectedRows={selectedStations}
                            onSelectionChanged={this.onSelectionChange}
                            onExportCsvClick={!session?.employee.IsPumpingStationExport ? undefined : this.onCSVClick}
                            rowsPerPage={100}
                            columns={[{
                                header: '',
                                field: '',
                                width: 100,
                                enableSort: true,
                                enableFilter: true,
                                paddingRight: 4,
                                fixed: true,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton color='primary' value={row.code} onClick={this.onInfoClick}>
                                                    <Icon>
                                                        list
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton color='primary' value={row.code} onClick={this.onMapClick} disabled={!row.geo}>
                                                    <MapIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 140,
                                enableSort: true,
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 140,
                                enableSort: true,
                            }, {
                                header: 'Division',
                                field: 'division',
                                width: 250,
                                enableSort: true,
                            }, {
                                header: 'Management Area',
                                field: 'managementArea',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Active?',
                                field: 'isActive',
                                width: 215,
                                enableSort: true,
                                renderCell: n => !n.isActive ? (<Icon className='cr'>close</Icon>) : (<Icon className='cdg'>check</Icon>),
                            }]}
                        />
                    </Card>
                    <div className='fdr aic'>
                        <div className='mr15'>
                            <PumpingStationPrintQrCodeButton
                                selected={selectedStations}
                                onClose={this.onSelectedClose}
                            />
                        </div>
                        <span className='flx1' />
                        {
                            session?.employee.IsPumpingStationCreateAdmin &&
                            <PumpingStationNewButton transition={Transitions.Up}/>
                        }

                    </div>
                    <PumpingStationInfo fullScreen transition={Transitions.Up} />
                    {
                        session?.employee.IsPumpingStationExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'code',
                                    label: 'Code',
                                },
                                {
                                    key: 'name',
                                    label: 'Name',
                                },
                                {
                                    key: 'division',
                                    label: 'Division',
                                },
                                {
                                    key: 'managementArea',
                                    label: 'Management Area',
                                },
                            ]}
                            filename={`${csvName}_PumpingStation.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        pumpingStations: state.pumpingStation.pumpingStations,
        isLoadingPumpingStations: state.pumpingStation.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(PumpingStationList);
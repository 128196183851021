import React from 'react';
import { ITransformer, ITransformerCompany, TransformerMeterTypeUpperCase } from '../../../@types/model/transformer/transformer';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import transformerFunctions from '../../../store/transformer/functions';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import MeterTypeSelector from '../../customComponents/selector/transformer/MeterTypeSelector';
import PhaseSizeSelector from '../../customComponents/selector/transformer/PhaseSizeSelector';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TransformerGroupDropdown from '../../customComponents/selector/transformer/GroupSelector';
import { ITransformerGroup } from '../../../@types/model/transformer/transformerGroup';
import TransmissionZoneDropdown from '../../customComponents/selector/transformer/TransmissionZoneSelector';
import LandrateDropdown from '../../customComponents/selector/transformer/LandrateSelector';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircuitBreakerSelector from '../../customComponents/selector/transformer/CircuitBreakerSelector';
import TransformerRatingSelector from '../../customComponents/selector/transformer/TransformerRatingSelector';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';

interface IEditTransformerDetailProps {
    transformer : ITransformer;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditTransformerDetailState {
    open : boolean;
    epType : string;
    epGroup ?: string;
    company : ITransformerCompany | null;
    transformerReaderEmployeeNr : string;
    transformerRating : number | null;
    notifiedMaxDemand : string;
    meterType : TransformerMeterTypeUpperCase | '';
    phaseSize : number | null;
    poleNumber : string;
    circuitBreaker : number | null;
    manualReading : boolean;
    accountNumber : string;
    transmissionZone : [number, number] | null;
    landrate : number | null;
    premiseId ?: string;
    currentResident : string;

    securityGuarantee : number | null;
    securityDeposit : number | null;

    linkedEp : string | null;
}

export default class EditTransformerDetail extends React.Component<IEditTransformerDetailProps, IEditTransformerDetailState> {
    constructor(props : IEditTransformerDetailProps) {
        super(props);
        this.state = {
            open: false,
            epType: '',
            company: null,
            transformerReaderEmployeeNr: '',
            transformerRating: null,
            notifiedMaxDemand: '',
            meterType: '',
            poleNumber: '',
            phaseSize: null,
            circuitBreaker: null,
            manualReading: false,
            accountNumber: '',
            transmissionZone: null,
            landrate: null,
            currentResident: '',
            securityGuarantee: null,
            securityDeposit: null,
            linkedEp: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTransformerDetailProps, prevState : IEditTransformerDetailState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                epType: '',
                epGroup: undefined,
                company: null,
                transformerReaderEmployeeNr: '',
                transformerRating: null,
                notifiedMaxDemand: '',
                meterType: '',
                poleNumber: '',
                phaseSize: null,
                circuitBreaker: null,
                manualReading: false,
                accountNumber: '',
                transmissionZone: null,
                landrate: null,
                premiseId: undefined,
                currentResident: '',
                securityGuarantee: null,
                securityDeposit: null,
                linkedEp: null,
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                epType: !this.props.transformer.EPType ? '' : this.props.transformer.EPType,
                epGroup: !this.props.transformer.EPGroup ? undefined : this.props.transformer.EPGroup,
                company: this.props.transformer.Company ?? null,
                transformerRating: !this.props.transformer.TransformerRating ? null : this.props.transformer.TransformerRating,
                notifiedMaxDemand: this.props.transformer.NotifiedMaxDemand?.toFixed(2) ?? '',
                meterType: !this.props.transformer.MeterType ? '' : this.props.transformer.MeterType,
                phaseSize: !this.props.transformer.PhaseSize ? null : this.props.transformer.PhaseSize,
                poleNumber: !this.props.transformer.PoleNumber ? '' : this.props.transformer.PoleNumber,
                circuitBreaker: !this.props.transformer.CircuitBreaker ? null : this.props.transformer.CircuitBreaker,
                manualReading: !!this.props.transformer.ManualReading,
                accountNumber: !this.props.transformer.AccountNumber ? '' : this.props.transformer.AccountNumber,
                transmissionZone: this.props.transformer.TransmissionZone,
                landrate: this.props.transformer.Landrate,
                transformerReaderEmployeeNr: !this.props.transformer.TransformerReaderEmployeeNr ? '' : this.props.transformer.TransformerReaderEmployeeNr,
                premiseId: !this.props.transformer.PremiseId ? undefined : this.props.transformer.PremiseId,
                currentResident: this.props.transformer.CurrentResident ?? '',
                securityGuarantee: this.props.transformer.SecurityGuarantee ?? null,
                securityDeposit: this.props.transformer.SecurityDeposit ?? null,
                linkedEp: this.props.transformer.LinkedEp ?? null,
            });
        }
    };

    private readonly save = async () => {
        const {
            epType,
            transformerRating,
            meterType,
            phaseSize,
            poleNumber,
            circuitBreaker,
            manualReading,
            accountNumber,
            epGroup,
            transmissionZone,
            landrate,
            transformerReaderEmployeeNr,
            notifiedMaxDemand,
            premiseId,
            company,
            currentResident,
            securityDeposit,
            securityGuarantee,
            linkedEp,
        } = this.state;

        const transformer = {
            ...this.props.transformer,
        };

        transformer.EPType = epType;
        transformer.EPGroup = epGroup ?? null;
        transformer.Company = company ?? null;
        transformer.PremiseId = premiseId ?? null;
        transformer.TransformerRating = !transformerRating ? null : Number(transformerRating);
        transformer.MeterType = meterType;
        transformer.PhaseSize = phaseSize;
        transformer.PoleNumber = poleNumber;
        transformer.CircuitBreaker = !circuitBreaker ? null : Number(circuitBreaker);
        transformer.ManualReading = manualReading;
        transformer.AccountNumber = accountNumber;
        transformer.TransmissionZone = transmissionZone;
        transformer.Landrate = landrate;
        transformer.TransformerReaderEmployeeNr = transformerReaderEmployeeNr;
        transformer.NotifiedMaxDemand = !notifiedMaxDemand ? null : Number(notifiedMaxDemand);
        transformer.CurrentResident = currentResident;
        transformer.SecurityGuarantee = securityGuarantee;
        transformer.SecurityDeposit = securityDeposit;
        transformer.LinkedEp = linkedEp;

        const result = await transformerFunctions.saveTransformer(transformer);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onEPTypeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            epType: event.target.value,
        });
    };

    private readonly onPremiseIdChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value && isNaN(Number(event.target.value))) return;
        if (event.target.value.length > 10) return;

        this.setState({
            premiseId: event.target.value,
        });
    };

    private readonly onEPGroupChange = (value ?: ITransformerGroup) => {
        this.setState({
            epGroup: value?.name,
            company: value?.company ?? null,
        });
    };

    private readonly onPoleNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            poleNumber: event.target.value,
        });
    };

    private readonly onTransformerReaderEmployeeNrChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            transformerReaderEmployeeNr: event.target.value,
        });
    };

    private readonly onAccountNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            accountNumber: event.target.value,
        });
    };

    private readonly onCurrentResidentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            currentResident: event.target.value,
        });
    };

    private readonly onMeterTypeChange = (meterType : TransformerMeterTypeUpperCase | '') => {
        this.setState({
            meterType,
        });
    };

    private readonly onPhaseSizeChange = (phaseSize : number | null) => {
        this.setState({
            phaseSize,
        });
    };

    private readonly onCircuitBreakerChange = (circuitBreaker : number | null) => {
        this.setState({
            circuitBreaker,
        });
    };

    private readonly onTransformerRatingChange = (transformerRating : number | null) => {
        this.setState({
            transformerRating,
        });
    };

    private readonly onManualReadingChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            manualReading: checked,
        });
    };

    private readonly onNotifiedMaxDemandChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value && !parseInt(event.currentTarget.value)) return;
        this.setState({
            notifiedMaxDemand: event.currentTarget.value,
        });
    };

    private readonly onTransmissionZoneChange = (value : [number, number] | null) => {
        this.setState({
            transmissionZone: value,
        });
    };

    private readonly onLandrateChange = (value : number | null) => {
        this.setState({
            landrate: value,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onSecurityGuaranteeChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            securityGuarantee: Number(event.currentTarget.value),
        });
    };

    private readonly onSecurityDepositChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            securityDeposit: Number(event.currentTarget.value),
        });
    };

    private readonly onLinkedEpChange = (value ?: ITransformer) => {
        this.setState({
            linkedEp: value?.EPNumber ?? null,
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            isLoading,
            transformer,
        } = this.props;

        const {
            open,
            transformerRating,
            meterType,
            phaseSize,
            poleNumber,
            circuitBreaker,
            manualReading,
            accountNumber,
            transmissionZone,
            landrate,
            notifiedMaxDemand,
            premiseId,
            currentResident,
            securityDeposit,
            securityGuarantee,
            epType,
            epGroup,
            transformerReaderEmployeeNr,
            linkedEp,
        } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-info-info-title'
                    aria-describedby='transformer-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Transformer Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='epType'
                                            label='EP Type'
                                            value={epType}
                                            onChange={this.onEPTypeChange}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais pr20'}>
                                    <TransformerGroupDropdown
                                        fullWidth value={epGroup}
                                        onChange={this.onEPGroupChange}
                                        type={meterType}
                                        required
                                    />
                                </div>
                                <div className={'flx1 ais'}>
                                    <MeterTypeSelector value={meterType} onChange={this.onMeterTypeChange} />
                                </div>
                            </div>

                            {
                                meterType === 'SPS' &&
                                <>
                                    <div className={'fdr flx1 aifs mt20'}>
                                        <div className={'flx1 ais pr20'}>
                                            <TransformerDropdown
                                                value={linkedEp}
                                                label='Linked Eskom Waypoint'
                                                fullWidth
                                                onChange={this.onLinkedEpChange}
                                                exclusionList={[transformer]}
                                            />
                                        </div>
                                        <div className={'flx1 ais pr20'}>
                                        </div>
                                        <div className={'flx1 ais'}>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                meterType !== 'SPS' &&
                                <>
                                    <div className={'fdr flx1 aifs mt20'}>
                                        <div className={'flx1 ais pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='transformerReaderEmployeeNr'
                                                    label='Reader Employee Nr'
                                                    value={transformerReaderEmployeeNr}
                                                    onChange={this.onTransformerReaderEmployeeNrChange}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 ais pr20'}>
                                            <TransformerRatingSelector fullWidth value={transformerRating} onChange={this.onTransformerRatingChange} />
                                        </div>
                                        <div className={'flx1 ais pr20'}>
                                            <PhaseSizeSelector phaseSize={phaseSize} onPhaseSizeChange={this.onPhaseSizeChange} />
                                        </div>
                                        <div className={'flx1 ais'}>
                                            <CircuitBreakerSelector fullWidth phase={phaseSize} value={circuitBreaker} onChange={this.onCircuitBreakerChange} />
                                        </div>
                                    </div>
                                    <div className={'fdr flx1 aifs mt20'}>
                                        <div className={'flx1 ais pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='poleNumber'
                                                    label='Pole Number'
                                                    value={poleNumber}
                                                    onChange={this.onPoleNumberChange}
                                                />
                                            </FormControl>
                                        </div>
                                        {
                                            !!meterType &&
                                            <div className={'flx1 ais pr20'}>
                                                {
                                                    meterType === 'LPU' &&
                                                    <TransmissionZoneDropdown clearable value={transmissionZone} fullWidth onChange={this.onTransmissionZoneChange} />
                                                }
                                                {
                                                    meterType === 'SPU' &&
                                                    <LandrateDropdown clearable value={landrate} fullWidth onChange={this.onLandrateChange} />
                                                }
                                                {
                                                    meterType === 'Prepaid' &&
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            autoComplete='off'
                                                            id='currentResident'
                                                            label='Current Resident'
                                                            value={currentResident}
                                                            onChange={this.onCurrentResidentChange}
                                                        />
                                                    </FormControl>
                                                }
                                            </div>
                                        }
                                        <div className={'flx1 ais pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='accountNumber'
                                                    label='Account Number'
                                                    value={accountNumber}
                                                    onChange={this.onAccountNumberChange}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 ais'}>
                                            <FormControl>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color='primary' checked={manualReading} onChange={this.onManualReadingChange} />
                                                    }
                                                    label='Manual Reading?'
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className={'fdr flx1 aifs mt20'}>
                                        <div className={'flx1 ais pr20'}>
                                            <FormControl fullWidth
                                                error={!!notifiedMaxDemand && !Number(notifiedMaxDemand)}
                                            >
                                                <TextField
                                                    autoComplete='off'
                                                    id='notifiedMaxDemand'
                                                    label='Notified Max Demand'
                                                    value={notifiedMaxDemand}
                                                    onChange={this.onNotifiedMaxDemandChange}
                                                    error={!!notifiedMaxDemand && !Number(notifiedMaxDemand)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                kV
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {
                                                    notifiedMaxDemand && !Number(notifiedMaxDemand) &&
                                                    <FormHelperText error>Invalid</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 ais pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='premiseId'
                                                    label='Premise Id'
                                                    value={premiseId ?? ''}
                                                    onChange={this.onPremiseIdChange}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 ais pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='securityGuarantee'
                                                    label='Security Guarantee'
                                                    value={securityGuarantee ?? ''}
                                                    onChange={this.onSecurityGuaranteeChange}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='end'>
                                                                R
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 ais pr20'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='securityDeposit'
                                                    label='Security Deposit'
                                                    value={securityDeposit ?? ''}
                                                    onChange={this.onSecurityDepositChange}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='end'>
                                                                R
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

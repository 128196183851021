import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IDam } from '../../../@types/model/dam/dam';
import { IRootState } from '../../../@types/redux';
import { DAM_MAP_IMAGES } from '../../../appConstants';
import DamFunctions from '../../../store/dam/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditLocationDialog from '../../customComponents/dialog/LocationEditDialog';
import CustomMapBox, { IMapMarker } from '../../customComponents/map/CustomMapBox';

interface IDamLocationInfoViewProps {
    dam : IDam;

    isLoading : boolean;

    isDamAdmin : boolean;
}

interface IDamLocationInfoViewState {}

class DamLocationInfoViewComponent extends React.PureComponent<IDamLocationInfoViewProps, IDamLocationInfoViewState> {
    constructor(props : IDamLocationInfoViewProps) {
        super(props);
        this.state = {};
    }
    private readonly mapZoom = 9;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];

    public readonly getData = (state : IDamLocationInfoViewState, props : IDamLocationInfoViewProps) => props.dam;

    public readonly getMarker = createSelector(
        [this.getData],
        (dam) => {
            return {
                coordinates: [dam.geo?.longitude ?? 0, dam.geo?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: dam.code,
            } as IMapMarker;
        }
    );
    
    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number) => {
        const dam = Object.assign({}, this.props.dam);

        dam.geo = geom;
        dam.elevation = elevation;

        await DamFunctions.saveDam(dam);
    };

    public readonly render = () => {
        const { isDamAdmin, dam, isLoading } = this.props;
        const marker = this.getMarker(this.state, this.props);

        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc mxh400 hfill'}>
                    <div className={'fdr dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            LOCATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isDamAdmin &&
                                    <EditLocationDialog
                                        markerImage={DAM_MAP_IMAGES['1']}
                                        markerImageSize = {0.35}
                                        elevation={dam.elevation ?? 0}
                                        geom={dam.geo}
                                        transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                        onSave={this.onLocationSave}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdc ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    LATITUDE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.geo?.latitude ?? '-'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    LONGITUDE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.geo?.longitude ?? '-'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    ELEVATION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {dam.elevation}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {
                        !isLoading &&
                        <div className={'flx1 ais'}>
                            <div className='hfill wfill '>
                                <CustomMapBox
                                    id='dam_map'
                                    showScale
                                    center={this.mapCenter}
                                    zoom={this.mapZoom}
                                    images={DAM_MAP_IMAGES}
                                    markers={[marker]}
                                    selectedMarker={dam.code}
                                />
                            </div>
                        </div>
                    }
                </Card>
            </div>
        );   
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isDamAdmin: !!state.auth.session?.employee.IsDamAdmin,
        isLoading: state.dam.loading,
    };
};

const DamLocationInfoView = connect(
    mapStateToProps,
)(DamLocationInfoViewComponent);

export default DamLocationInfoView;

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import PumpingStationFunctions from '../../../store/pumpingStation/functions';
import { connect } from 'react-redux';

interface IEditPumpingStationSummaryDialogProps {
    pumpingStation : IPumpingStation;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IEditPumpingStationSummaryDialogState {
    open : boolean;
    name : string;
}

class EditPumpingStationSummaryDialog extends React.Component<IEditPumpingStationSummaryDialogProps, IEditPumpingStationSummaryDialogState> {
    constructor(props : IEditPumpingStationSummaryDialogProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditPumpingStationSummaryDialogProps, prevState : IEditPumpingStationSummaryDialogState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                name: '',
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                name: !this.props.pumpingStation.name ? '' : this.props.pumpingStation.name,
            });
        }
    };

    private readonly save = async () => {
        const { name } = this.state;

        const pumpingStation = {
            ...this.props.pumpingStation,
        };

        pumpingStation.name = name;

        const result = await PumpingStationFunctions.savePumpingStation(pumpingStation);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private onNameChanged = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.target.value,
        });
    };

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, name } = this.state;
        
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='pumpingStation-info-info-title'
                    aria-describedby='pumpingStation-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit PumpingStation Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs jcse '}>
                                <TextField 
                                    onChange={this.onNameChanged}
                                    value={name}
                                    label={'Name'}
                                    className={'pr10 flx1'}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = () => {
    return {
    };
};

export default connect(
    mapStateToProps,
)(EditPumpingStationSummaryDialog);

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import { FormControl, FormHelperText, Typography, TextField, DialogActions, Button } from '@material-ui/core';
import TransformerCommentHelper, { ITransformerComment } from '../../../@types/model/transformer/transformerComment';
import GeneralFunctions from '../../../store/general/functions';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import moment from 'moment';

interface IAddTransformerCommentProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    open : boolean;
    onClose ?: () => void;
    session : IUserSession;
    transformer : ITransformer;
}

interface IAddTransformerCommentState {
    comment : string;

    isLoading : boolean;
}

export default class AddTransformerCommentDialog extends React.PureComponent<IAddTransformerCommentProps, IAddTransformerCommentState> {
    constructor(props : IAddTransformerCommentProps) {
        super(props);
        this.state = {
            comment: '',
            isLoading: false,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IAddTransformerCommentProps>) : void {
        if (!prevProps.open && this.props.open) {
            this.setState({
                comment: '',
                isLoading: false,
            });
        }
    }

    private readonly onCommentChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            comment: event.currentTarget.value,
        });
    };

    public readonly onClose = () => {
        if (!this.props.onClose) return;
        this.props.onClose();
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly save = async () => {
        const { comment } = this.state;
        const { transformer, session } = this.props;

        const result : ITransformerComment = {
            id: '',
            isWeb: true,
            createdBy: session.firebaseUser.uid,
            createdByEmployee: session.employee.EmployeeNumber ?? '',
            createdByName: session.firebaseUser.displayName ?? '',
            createdOn: moment.utc().valueOf(),
            updatedBy: session.firebaseUser.uid,
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedByName: session.firebaseUser.displayName ?? '',
            updatedOn: moment.utc().valueOf(),
            comment,
            transformerRef: transformer.ref.id,
            date: moment.utc().valueOf(),
            type: 'website',
        };

        try {
            this.setState({
                isLoading: true,
            });

            await TransformerCommentHelper.save(result);

            this.onClose();
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving comment.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, open } = this.props;
        const { comment, isLoading } = this.state;
        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                aria-labelledby='tooltip-dialog-title'
                aria-describedby='tooltip-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Add Comment
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                    <DialogContent className='fdc'>
                        <div className={'flx2 aife p5 mb10 pr20'} style={{ marginTop: '16px' }}>
                            <FormControl fullWidth>
                                <TextField
                                    value={comment}
                                    onChange={this.onCommentChange}
                                    aria-describedby='comment'
                                    required
                                    multiline
                                    error={!comment}
                                    autoFocus
                                />
                                {
                                    !comment &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                            OK
                        </Button>
                        <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                            CANCEL
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };
}

import React from 'react';
import { IMunicipalityInvoice, IMunicipalityInvoiceFormValues, MunicipalityInvoiceHelper, waterBlock, waterBlocksTypes } from '../../../@types/model/transformer/municipalityInvoice';
import { AppBar, CircularProgress, Dialog, DialogContent, Divider, FormControl, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close } from '@material-ui/icons';
import FormikForm from '../../customComponents/form/FormikForm';
import KeyboardDateFormField from '../../customComponents/form/KeyboardDateFormField';
import moment, { Moment } from 'moment';
import AccountAutocompleteFormField from '../../customComponents/form/transformer/AccountAutocompleteFormField';
import { FastField, Field, FieldProps } from 'formik';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../../appConstants';
import MunicipilityInvoiceElectricityForm from '../form/ElectricityField';
import MunicipilityInvoiceWaterForm from '../form/WaterField';
import MunicipilityInvoiceOtherForm from '../form/OtherField';
import OutlinedStadiumButton from '../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../customComponents/button/StadiumButton';
import StadiumDiv from '../../customComponents/div/Stadium';
import WhiteTextField from '../../customComponents/textField/WhiteTextField';
import GeneralFunctions from '../../../store/general/functions';
import firebaseApp from '../../../services/firebaseService';
import MunicipilityInvoiceCreditFormField from '../form/CreditField';
import MunicipilityInvoiceVatFormField from '../form/VatField';

interface IMunicipalityInvoiceEditDialogProps {
    value : IMunicipalityInvoice | null;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    monthDate ?: Moment | null;
    group ?: string | null;
    accountNumbers ?: Array<string> | null;

    isLoading : boolean;

    onClose ?: () => void;
}

interface IMunicipalityInvoiceEditDialogState {
    open : boolean;

    initialValues : IMunicipalityInvoiceFormValues | null;
}

export default class MunicipalityInvoiceEditDialog extends React.PureComponent<IMunicipalityInvoiceEditDialogProps, IMunicipalityInvoiceEditDialogState> {
    private readonly maxDate = moment.utc().startOf('month');
    constructor(props : IMunicipalityInvoiceEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            initialValues: null,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IMunicipalityInvoiceEditDialogProps>) : void {
        if (!prevProps.value && this.props.value) {
            this.setState({
                open: true,
            });

            this.setInitialValue(this.props.value);
        }
        
        if (prevProps.value && !this.props.value) {
            this.setState({
                open: false,
            });
        }
    }

    private readonly setInitialValue = (value : IMunicipalityInvoice) => {
        this.setState({
            initialValues: {
                ...value,
                monthDate: !value.monthDate ? (this.props.monthDate ?? null) : moment.utc(value.monthDate),
                accountNumber: !value.accountNumber ? null : value.accountNumber,
                group: !value.group ? null : value.group,
                dueDate: !value.dueDate ? null : moment.utc(value.dueDate),
                credit: !value.credit ? null : value.credit,
                rebillTotal: !value.rebillTotal ? null : value.rebillTotal,
                calculatedVat: !value.calculatedVat ? value.totalVat : value.calculatedVat,
                electricity: !value.electricity ? null : {
                    ...value.electricity,
                },
                water: !value.water ? null : {
                    ...value.water,
                },
                other: !value.other ? null : {
                    ...value.other,
                },
            } as IMunicipalityInvoiceFormValues,
        });
    };

    private readonly onAccountNumberChange = (transformer : ITransformer | null, params : FieldProps<string | null, IMunicipalityInvoiceFormValues>) => {
        params.form.setFieldValue('transformer', transformer ?? null);
        params.form.setFieldValue('group', transformer?.EPGroup ?? null);
        params.form.setFieldValue('transformerRef', transformer?.ref.id ?? null);

        if (params.form.values.electricity && transformer) {
            const electricity = {
                basicInterest: null,
                meteredInterest: null,
                fixedBasicCharge: null,
                demandCost: null,
                days: null,
                meters: transformer.TransformerMeterNumbers?.map(n => ({
                    meterNumber: n.Number,
                    unitOfMeasure: n.UnitOfMeasure,
                    offPeakConsumptionKwh: null,
                    offPeakCostReading: null,
                    peakConsumptionKwh: null,
                    peakCostReading: null,
                    standardConsumptionKwh: null,
                    standardCostReading: null,
                })) ?? [],
                usageZar: 0,
                totalConsumption: 0,
                total: 0,
            };

            params.form.setFieldValue('electricity', electricity);
        }

        setTimeout(() => {
            MunicipalityInvoiceHelper.calculateTotals(params.form);
        });
    };

    private readonly onSubmit = async (formValues : IMunicipalityInvoiceFormValues) => {
        try {

            const ref = MunicipalityInvoiceHelper.doc(this.props.value?.id);

            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const doc = await transaction.get(ref);
                
                if (!this.props.value) return;
                if (!formValues.transformer) return;

                transaction.set(doc.ref, {
                    ...this.props.value,
                    accountNumber: formValues.accountNumber,
                    group: formValues.group,
                    credit: Number(formValues.credit ?? 0),
                    dueDate: formValues.dueDate.valueOf(),
                    monthDate: formValues.monthDate.valueOf(),
                    reasonForRebill: formValues.reasonForRebill ?? null,
                    rebillTotal: Number(formValues.rebillTotal ?? 0),
                    totalExVat: Number(formValues.totalExVat ?? 0),
                    vat: Number(formValues.vat ?? 0),
                    totalVat: Number(formValues.totalVat ?? 0),
                    calculatedVat: Number(formValues.calculatedVat ?? formValues.totalVat ?? 0),
                    totalIncVat: Number(formValues.totalIncVat ?? 0),
                    transformer: formValues.transformer,
                    transformerRef: formValues.transformerRef,
                    electricity: !formValues.electricity ? null : {
                        basicInterest: Number(formValues.electricity.basicInterest ?? 0),
                        demandCost: Number(formValues.electricity.demandCost ?? 0),
                        fixedBasicCharge: Number(formValues.electricity.fixedBasicCharge ?? 0),
                        meteredInterest: Number(formValues.electricity.meteredInterest ?? 0),
                        days: Number(formValues.electricity.days ?? 0),
                        usageZar: formValues.electricity.usageZar,
                        total: formValues.electricity.total,
                        totalConsumption: formValues.electricity.totalConsumption,
                        offPeakConsumptionKwh: formValues.electricity.meters.reduce((value, meter) => (value + Number(meter.offPeakConsumptionKwh ?? 0)), 0),
                        peakConsumptionKwh: formValues.electricity.meters.reduce((value, meter) => (value + Number(meter.peakConsumptionKwh ?? 0)), 0),
                        standardConsumptionKwh: formValues.electricity.meters.reduce((value, meter) => (value + Number(meter.standardConsumptionKwh ?? 0)), 0),
                        offPeakCostReading: formValues.electricity.meters.reduce((value, meter) => (value + Number(meter.offPeakCostReading ?? 0)), 0),
                        peakCostReading: formValues.electricity.meters.reduce((value, meter) => (value + Number(meter.peakCostReading ?? 0)), 0),
                        standardCostReading: formValues.electricity.meters.reduce((value, meter) => (value + Number(meter.standardCostReading ?? 0)), 0),
                        meters: formValues.electricity.meters.map((meter) => ({
                            meterNumber: meter.meterNumber,
                            unitOfMeasure: meter.unitOfMeasure,
                            offPeakConsumptionKwh: meter.offPeakConsumptionKwh,
                            standardConsumptionKwh: Number(meter.standardConsumptionKwh ?? 0),
                            peakConsumptionKwh: meter.peakConsumptionKwh,
                            offPeakCostReading: meter.offPeakCostReading,
                            standardCostReading: Number(meter.standardCostReading ?? 0),
                            peakCostReading: meter.peakCostReading,
                        })),
                    },
                    water: !formValues.water ? null : {
                        basicInterest: Number(formValues.water.basicInterest ?? 0),
                        days: formValues.water.days,
                        meteredInterest: Number(formValues.water.meteredInterest ?? 0),
                        totalConsumption: formValues.water.totalConsumption,
                        totalConsumptionCost: formValues.water.totalConsumptionCost,
                        waterAvailabilityCost: Number(formValues.water.waterAvailabilityCost ?? 0),
                        total: formValues.water.total,
                        blocks: waterBlocksTypes.reduce((n, a) => ({
                            ...n,
                            [a]: {
                                consumption: Number(formValues.water?.blocks[a].consumption ?? 0),
                                cost: Number(formValues.water?.blocks[a].cost ?? 0),
                            },
                        }), {} as Record<waterBlock, {
                            consumption : number;
                            cost : number;
                        }>),
                    },
                    other: !formValues.other ? null : {
                        propertyRates: Number(formValues.other.propertyRates ?? 0),
                        propertyRatesRebate: Number(formValues.other.propertyRatesRebate ?? 0),
                        propertyInterest: Number(formValues.other.propertyInterest ?? 0),
                        sanitationSewerage: Number(formValues.other.sanitationSewerage ?? 0),
                        sanitationSewerageInterest: Number(formValues.other.sanitationSewerageInterest ?? 0),
                        yearlyPropertyTax: Number(formValues.other.yearlyPropertyTax ?? 0),
                        wasteDisposal: Number(formValues.other.wasteDisposal ?? 0),
                        wasteDisposalInterest: Number(formValues.other.wasteDisposalInterest ?? 0),
                        totalOtherCosts: formValues.other.totalOtherCosts,
                        propertyRatesTotal: formValues.other.propertyRatesTotal,
                        
                    },
                });
            });
            if (!this.props.onClose) return;
            this.props.onClose();
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving invoice.');
        }
    };

    public readonly render = () => {
        const {
            transition,
            isLoading,
            onClose,
            value,
            group,
            accountNumbers,
        } = this.props;

        const {
            open,
            initialValues,
        } = this.state;

        return (
            <Dialog
                open={open}
                TransitionComponent={transition}
                transitionDuration={500}
                maxWidth={'md'}
                fullWidth
                aria-labelledby='municipality-invoice-dialog-title'
                aria-describedby='municipality-invoice-description'
            
            >
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            {!value?.id ? 'ADD' : 'EDIT'} MUNICIPAL READING
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={onClose} aria-label='Close'>
                                    <Close />
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                {
                    initialValues &&
                    <FormikForm
                        onSubmit={this.onSubmit}
                        className='fdc hfill'
                        validateOnMount
                        initialValues={initialValues}
                        validationSchema={MunicipalityInvoiceHelper.formSchema()}
                        enableReinitialize
                    >
                        {
                            (props) => (
                                <>
                                    <DialogContent className='p15'>
                                        {
                                            isLoading &&
                                            <div className='fdc flx1 aic jcc'>
                                                <CircularProgress />
                                            </div>
                                        }
                                        {
                                            !isLoading &&
                                            <div className='fdc'>
                                                <div className='fdr'>
                                                    <div className={'flx1 aifs pr10'}>
                                                        <KeyboardDateFormField
                                                            fullWidth
                                                            disabled={!!this.props.monthDate}
                                                            views={['month']}
                                                            maxDate={this.maxDate}
                                                            format='MMM YYYY'
                                                            label='Select Account Month...'
                                                            id='monthDate'
                                                            name={'monthDate'}
                                                            required
                                                        />
                                                    </div>
                                                    <div className={'flx1 aifs ml10 pr10'}>
                                                        <AccountAutocompleteFormField
                                                            fullWidth
                                                            disabled={isLoading}
                                                            id='accountNumber'
                                                            name={'accountNumber'}
                                                            onChange={this.onAccountNumberChange}
                                                            group={group}
                                                            type={['municipality', 'prepaid']}
                                                            exclude={accountNumbers}
                                                        />
                                                    </div>
                                                    <div className={'flx1 aifs ml10'}>
                                                        <KeyboardDateFormField
                                                            fullWidth
                                                            disabled={isLoading}
                                                            format={DATE_FORMAT_DEFAULT_NO_TIME}
                                                            label='Due Date'
                                                            id='dueDate'
                                                            name={'dueDate'}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='fdc mt10'>
                                                    <Field
                                                        id='electricity'
                                                        name='electricity'
                                                        component={MunicipilityInvoiceElectricityForm}
                                                    />
                                                </div>
                                                <div className='fdc mt10'>
                                                    <FastField
                                                        id='water'
                                                        name='water'
                                                        component={MunicipilityInvoiceWaterForm}
                                                    />
                                                </div>
                                                <div className='fdc mt10'>
                                                    <FastField
                                                        id='other'
                                                        name='other'
                                                        component={MunicipilityInvoiceOtherForm}
                                                    />
                                                </div>
                                                <div className='fdc mt10'>
                                                    <div className='fdc bcpd p20'>
                                                        <Typography className='cw fs22 fwm'>INVOICE SUMMARY</Typography>
                                                        <Divider className='mt15 mb15 bcw' />
                                                        <div className='fdr'>
                                                            <div className='fdc mr15'>
                                                                <div className='fdr aic'>
                                                                    <div className='w250'>
                                                                        <Typography className='cw fs16 fwm'>TOTAL AMOUNT DUE:</Typography>
                                                                    </div>
                                                                    <div className='w200'>
                                                                        <StadiumDiv className='bcw cp'>
                                                                            <Typography component={'span'} className='fdr fs16 fwm cp'>
                                                                                {
                                                                                    props.values.totalExVat?.toLocaleCurrency()
                                                                                }
                                                                            </Typography>
                                                                        </StadiumDiv>
                                                                    </div>
                                                                </div>
                                                                <div className='fdr aic mt15'>
                                                                    <div className='w250'>
                                                                        <Typography className='cw fs16 fwm'>TOTAL VAT:</Typography>
                                                                    </div>
                                                                    <div className='w200'>
                                                                        <StadiumDiv className='bcw cp'>
                                                                            <Typography component={'span'} className='fdr fs16 fwm cp'>
                                                                                {
                                                                                    props.values.calculatedVat?.toLocaleCurrency()
                                                                                }
                                                                            </Typography>
                                                                        </StadiumDiv>
                                                                    </div>
                                                                </div>
                                                                <div className='fdr aic mt15'>
                                                                    <div className='w250'>
                                                                        <Typography className='cw fs16 fwm'>TOTAL INVOICE COST:</Typography>
                                                                    </div>
                                                                    <div className='w200'>
                                                                        <StadiumDiv className='bcw cp'>
                                                                            <Typography component={'span'} className='fdr fs16 fwm cp'>
                                                                                {
                                                                                    ((props.values.totalIncVat ?? 0)).toLocaleCurrency()
                                                                                }
                                                                            </Typography>
                                                                        </StadiumDiv>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='fdc flx1'>
                                                                <div className='fdr flx1 aifs'>
                                                                    <div className=''>
                                                                        <FastField
                                                                            id='credit'
                                                                            name='credit'
                                                                            component={MunicipilityInvoiceCreditFormField}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='fdr flx1 aife'>
                                                                    <div className=''>
                                                                        <FastField
                                                                            id='totalVat'
                                                                            name='totalVat'
                                                                            component={MunicipilityInvoiceVatFormField}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='fdr flx1 aife'>
                                                                    <div className='mr15'>
                                                                        <FastField
                                                                            name={'rebillTotal'}
                                                                        >
                                                                            {
                                                                                (params : FieldProps<number | null>) => (
                                                                                    <FormControl fullWidth required>
                                                                                        <WhiteTextField
                                                                                            {...params.field}
                                                                                            value={params.field.value ?? ''}
                                                                                            error={!!params.meta.error}
                                                                                            variant='standard'
                                                                                            label='Rebill'
                                                                                            fullWidth
                                                                                            type='number'
                                                                                            onChange={params.field.onChange}
                                                                                        />
                                                                                    </FormControl>
                                                                                )
                                                                            }
                                                                        </FastField>
                                                                    </div>
                                                                    <div className='flx1'>
                                                                        <FastField
                                                                            name={'reasonForRebill'}
                                                                        >
                                                                            {
                                                                                (params : FieldProps<string | null>) => (
                                                                                    <FormControl fullWidth required>
                                                                                        <WhiteTextField
                                                                                            {...params.field}
                                                                                            value={params.field.value ?? ''}
                                                                                            error={!!params.meta.error}
                                                                                            variant='standard'
                                                                                            label='Reason for Rebill'
                                                                                            fullWidth
                                                                                            onChange={params.field.onChange}
                                                                                        />
                                                                                    </FormControl>
                                                                                )
                                                                            }
                                                                        </FastField>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </DialogContent>
                                    <div className='fdr flx1 jcfe aife p20'>
                                        <OutlinedStadiumButton className='mr16' variant='text' type='button' onClick={onClose} disabled={props.isSubmitting}>
                                            CANCEL
                                        </OutlinedStadiumButton>
                                        <StadiumButton type='submit' disabled={!props.isValid || !props.dirty || props.isSubmitting}>
                                            CONFIRM
                                        </StadiumButton>
                                    </div>
                                </>
                            )
                        }
                    </FormikForm>
                }
            </Dialog>
        );
    };
}

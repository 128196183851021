import React from 'react';
import riverFunctions from '../../store/river/functions';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { IAuthState, IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { Transitions } from '../customComponents/animations/Transitions';
import RiverInfo from './Info';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import { withRouter, RouteComponentProps } from 'react-router';
import CustomMapBox, { IMapMarker } from '../customComponents/map/CustomMapBox';
import { RIVER_MAP_IMAGES } from '../../appConstants';
import { IRiver } from '../../@types/model/river/river';

interface IRiverMapProps extends RouteComponentProps<{
    WRNumber : string;
}, Record<string, unknown>, Record<string, unknown>> {
    auth : IAuthState;
    rivers : Array<IRiver>;
    isLoadingRivers : boolean;
}

interface IRiverMapState {
    selectedRiver ?: IRiver;
}

class RiverMap extends React.Component<IRiverMapProps, IRiverMapState> {

    private readonly mapZoom = 8;
    private readonly mapCenter : [number, number] = [30.1148622, -23.5520414];
    constructor(props : IRiverMapProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        riverFunctions.getList(this.setSelectedRiver);
    };

    private setSelectedRiver : undefined | (() => void) = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const riverID = urlParams.get('Code') as string;
        this.onMarkerClick(riverID);

        this.setSelectedRiver = undefined;
    };

    private readonly selectMarker = (river : IRiver) => {
        this.setState({
            selectedRiver: river,
        });
    };

    private readonly onMoreInfo = () => {
        riverFunctions.setSelected(Object.assign({}, this.state.selectedRiver));
    };

    private onCloseDrawer = () => {
        this.setState({
            selectedRiver: undefined,
        });
    };

    private readonly onMarkerClick = (code : string) => {
        const river = this.props.rivers.slice().find(n => n.WRNumber === code);

        if (river) {        
            this.selectMarker(river);
        }
    };

    public readonly getData = (state : IRiverMapState, props : IRiverMapProps) => props.rivers;
    public readonly getFilteredData = createSelector(
        [this.getData],
        (rivers) => {
            return rivers.slice().filter(n => !!n.Geom && (n.IsActive === undefined || n.IsActive));
        },
    );

    public readonly getMarkers = createSelector(
        [this.getFilteredData],
        (rivers) => {
            return rivers.map(n => ({
                coordinates: [n.Geom?.longitude ?? 0, n.Geom?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: n.WRNumber,
            } as IMapMarker));
        }
    );
    public readonly render = () => {
        const { isLoadingRivers } = this.props;
        const { selectedRiver } = this.state;
        const rivers = this.getFilteredData(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (<div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Toolbar>
                    <Typography variant='h5' color='inherit'>
                        RIVERS
                    </Typography>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className={'fdr pt5'}>
                            <span style={{ paddingRight: '15px' }}><b>Total: </b>{rivers.length}</span>
                        </div>
                    </div>
                </Toolbar>
                <div className='mnh4'>
                    { isLoadingRivers && <LinearProgress />}
                </div>
                <Paper className={'flx1 fdr oxh'}>
                    {
                        rivers.length > 0 &&
                        <CustomMapBox
                            id='river_map'
                            enableAreas
                            geoLocationControl
                            navigationControl
                            showScale
                            layerControls
                            center={this.mapCenter}
                            zoom={this.mapZoom}
                            images={RIVER_MAP_IMAGES}
                            onMarkerClick={this.onMarkerClick}
                            markers={markers}
                            selectedMarker={selectedRiver?.WRNumber}
                        />
                    }
                </Paper>
            </div>
            <Drawer open={!!selectedRiver} anchor='right' variant='persistent'>
                <div className={'w300 flx1 fdc'}>
                    <AppBar className='posr' position='static'>
                        <Toolbar variant='dense'>
                            <div className='h60' />
                            <IconButton color={'inherit'} onClick={this.onMoreInfo}><Icon color={'inherit'}>more_vert</Icon></IconButton>
                            <Typography color='inherit' variant='h6' className={'flx1 ml10'} noWrap={true}>
                                {!!selectedRiver && selectedRiver.WRNumber}
                            </Typography>
                            <IconButton color={'inherit'} onClick={this.onCloseDrawer}><Icon color={'inherit'}>close</Icon></IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        selectedRiver &&
                        <div className='fdc flx1'>
                            <div className='fdr flx1'>
                                <div className={'fdc flx1'}>
                                    <Typography className={'fdr bcDarkBlue fw700 cw p10'} variant='h6'>
                                        SUMMARY
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            CODE
                                        </Typography>
                                        <Typography className={'flx2 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            NAME
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedRiver.WRNumber}
                                        </Typography>
                                        <Typography className={'flx2 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedRiver.RiverName}
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            LATEST FLOW
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            LATEST FLOW TYPE
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedRiver.LatestFlow}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedRiver.LatestFlowType}
                                        </Typography>
                                    </div>
                                    <Typography className={'fdr aic bcDarkBlue fw700 cw mb10 p10 mt10'} variant='h6'>
                                        DIVISION INFO
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle1'>
                                            DIVISION
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle1'>
                                            {selectedRiver.Division}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Drawer>
            <RiverInfo fullScreen transition={Transitions.Up} />
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        rivers: state.river.rivers,
        river: state.river.river,
        isLoadingRivers: state.river.loading,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(RiverMap));

import React from 'react';
import { IPermission } from '../../../../@types/employee';
import RoleTreeView from '../../../customComponents/tree/RoleTreeView';

interface IEmployeeRightsViewProps {
    permissions : IPermission;

    onChange : (permissions : IPermission) => void;
}

interface IEmployeeRightsViewState {}

export default class EmployeeRightsView extends React.PureComponent<IEmployeeRightsViewProps, IEmployeeRightsViewState> {
    constructor(props : IEmployeeRightsViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        return (<RoleTreeView permissions={this.props.permissions} onPermissionChange={this.props.onChange} />);
    };
}

import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Map from '@material-ui/icons/Map';
import ChemicalFillingPointHelper, { IChemicalFillingPoint } from '../../../@types/model/fillingPoint/chemical';
import { IUserSession } from '../../../@types/employee';
import { CSVLinkProps } from '../../../@types/csv';
import FillingPointChemicalFunctions from '../../../store/fillingPoint/chemical/functions';
import NavFunctions from '../../../store/nav/functions';
import MaterialTable from '../../customComponents/materialTable/Table';
import { IRootState } from '../../../@types/redux';
import ChemicalFillingPointNewButton from '../button/NewButton';
import { Transitions } from '../../customComponents/animations/Transitions';
import ChemicalFillingPointInfoDialog from '../dialog/Info';
import ChemicalFillingPointFilterSelector from '../../customComponents/selector/fillingStation/ChemicalFillingStationFilterSelector';

interface IChemicalFillingPointListProps {
    points : Array<IChemicalFillingPoint>;
    isLoading : boolean;
    session ?: IUserSession | null;
}

interface IChemicalFillingPointListState {
    name : string;
    code : string;
    division : string;
    managementArea : string;
}

class ChemicalFillingPointListComponent extends React.PureComponent<IChemicalFillingPointListProps, IChemicalFillingPointListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : IChemicalFillingPointListProps) {
        super(props);

        this.state = {
            name: '',
            code: '',
            division: '',
            managementArea: '',
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        FillingPointChemicalFunctions.load();
    };

    private readonly getData = (props : IChemicalFillingPointListProps) => props.points;
    private readonly getCode = (props : IChemicalFillingPointListProps, state : IChemicalFillingPointListState) => state.code;
    private readonly getName = (props : IChemicalFillingPointListProps, state : IChemicalFillingPointListState) => state.name;
    private readonly getDivision = (props : IChemicalFillingPointListProps, state : IChemicalFillingPointListState) => state.division;
    private readonly getManagementArea = (props : IChemicalFillingPointListProps, state : IChemicalFillingPointListState) => state.managementArea;

    public readonly getFilteredData = createSelector(
        [
            this.getData,
            this.getCode,
            this.getName,
            this.getDivision,
            this.getManagementArea,
        ],
        (
            points,
            code,
            name,
            division,
            managementArea,
        ) => {

            return lodash
                .chain(points)
                .filter(n => n.code.toLowerCase().includes(code.toLowerCase()))
                .filter(n => n.name.toLowerCase().includes(name.toLowerCase()))
                .filter(n => (n.division ?? '').toLowerCase().includes(division.toLowerCase()))
                .filter(n => (n.managementArea ?? '').toLowerCase().includes(managementArea.toLowerCase()))
                .value();
        },
    );

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (points) => {
            return ChemicalFillingPointHelper.mapToCsv(points);
        },
    );

    public readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const point = this.props.points.slice().find(x => x.code === event.currentTarget.value);

        FillingPointChemicalFunctions.setSelected(point);
    };

    public readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const point = this.props.points.slice().find(x => x.code === event.currentTarget.value);

        if (point) {
            NavFunctions.navChemicalFillingPointMap(point.code);
        }
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    private readonly onFilterChange = (
        code : string | null,
        name : string | null,
        division : string | null,
        managementArea : string | null,
    ) => {
        this.setState({
            name: name ?? '',
            code: code ?? '',
            division: division ?? '',
            managementArea: managementArea ?? '',
        });
    };

    public readonly render = () => {
        const { isLoading, session } = this.props;
        const { code, name, division, managementArea } = this.state;
        const points = this.getFilteredData(this.props, this.state);
        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            CHEMICAL FILLING POINTS
                        </Typography>
                        <span className={'flx4'} />
                        <div className={'flx1'}>
                            <ChemicalFillingPointFilterSelector
                                id='chemicalFillingStation_filter'
                                onChange={this.onFilterChange}
                                name={name}
                                code={code}
                                division={division}
                                managementArea={managementArea}
                                label='Filter By...'
                                disabled={isLoading}
                            />    
                        </div>
                        <div className={'fdr pt5 jcfe aic'}>
                            <div className={'fdr '}>
                                <span className='pr15'><b>Total: </b>{points.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IChemicalFillingPoint>
                            id='ChemicalFillingPointTable'
                            data={points}
                            isLoading={isLoading}
                            rowsPerPage={100}
                            onExportCsvClick={!session?.employee.IsChemicalFillingPointExport ? undefined : this.onCSVClick}
                            columns={[{
                                header: '',
                                width: 100,
                                paddingRight: 4,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton color='primary' value={row.code} onClick={this.onInfoClick}>
                                                    <InfoOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton color='primary' value={row.code} onClick={this.onMapClick} disabled={!row.geo}>
                                                    <Map />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Division',
                                field: 'division',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                paddingRight: 4,
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                                paddingRight: 4,
                            },
                            {
                                header: 'Name',
                                field: 'name',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }]}
                        />
                    </Card>
                    {
                        session?.employee.IsFillingPointAdmin &&
                        <ChemicalFillingPointNewButton />
                    }
                    <ChemicalFillingPointInfoDialog fullScreen transition={Transitions.Up} />
                    {
                        session?.employee.IsChemicalFillingPointExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'id',
                                    label: 'Id',
                                },
                                {
                                    key: 'division',
                                    label: 'Division',
                                },
                                {
                                    key: 'code',
                                    label: 'Code',
                                },
                                {
                                    key: 'createdOn',
                                    label: 'Created On',
                                },
                                {
                                    key: 'updatedOn',
                                    label: 'Updated On',
                                },
                                
                            ]}
                            filename={`${csvName}_ChemicalFillingPoints.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        points: state.fillingPoints.chemical.points,
        isLoading: state.fillingPoints.chemical.isLoading,
        session: state.auth.session,
    };
};

const ChemicalFillingPointList = connect(
    mapStateToProps,
)(ChemicalFillingPointListComponent);

export default ChemicalFillingPointList;
import { dispatch, getState } from '../../..';
import PumpingStationFilterSizeHelper, { IPumpingStationFilterSize } from '../../../../@types/model/masterData/pumpingStation/filterSize';
import GeneralFunctions from '../../../general/functions';
import MasterDataPumpingStationFilterSizeActions from './actions';

export default class MasterDataPumpingStationFilterSizeFunctions {
    private static listener : undefined | (() => void);


    public static readonly load = async () => {
        if (MasterDataPumpingStationFilterSizeFunctions.listener) return;
        
        dispatch(MasterDataPumpingStationFilterSizeActions.setLoading(true));
        dispatch(MasterDataPumpingStationFilterSizeActions.set([]));

        try {
            MasterDataPumpingStationFilterSizeFunctions.listener = PumpingStationFilterSizeHelper
                .collection()
                .onSnapshot((snapshot : firebase.firestore.QuerySnapshot<IPumpingStationFilterSize>) => {
                    const state = getState().masterData.pumpingStation.filterSize;

                    const list = state.filterSizes.slice();
                
                    const session = getState().auth.session;
                    if (!session) return;

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const item = f.doc.data();
                        const index = list.findIndex(n => n.id === item.id);


                        switch (f.type) {
                            case 'added':
                                list.push(item);
                                break;
                            case 'modified':
                                list.splice(index, 1, item);
                                break;
                            case 'removed':
                                list.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(MasterDataPumpingStationFilterSizeActions.set(list));
                    dispatch(MasterDataPumpingStationFilterSizeActions.setLoading(false));
                }, (ex) => {
                    GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
                    dispatch(MasterDataPumpingStationFilterSizeActions.setLoading(false));
                }, () => {
                    dispatch(MasterDataPumpingStationFilterSizeActions.setLoading(false));
                    MasterDataPumpingStationFilterSizeFunctions.listener = undefined;
                });

        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error loading pumps.');
            dispatch(MasterDataPumpingStationFilterSizeActions.setLoading(false));
        }
    };
}
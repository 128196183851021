import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import Fab from '@material-ui/core/Fab';
import { PDFViewer } from '@react-pdf/renderer';
import SPUMeterReportPdf from '../pdf/SPUMeterReportPdf';
import StandardFab from '../../customComponents/button/StandardFab';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { Typography } from '@material-ui/core';
import TransformerAreaDropdown from '../../customComponents/selector/transformer/AreaSelector';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { IMeterReadingsView } from '../../../@types/model/transformer/transformerReading';

interface ITransformerMunicipalityMeterReportDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    session ?: IUserSession | null;
    transformers : Array<ITransformer>;

    readings ?: Array<IMeterReadingsView>;
}

interface ITransformerMunicipalityMeterReportDialogState {
    open : boolean;

    area : string | null;
}

class TransformerMunicipalityMeterReportDialogComponent extends React.PureComponent<ITransformerMunicipalityMeterReportDialogProps, ITransformerMunicipalityMeterReportDialogState> {
    constructor(props : ITransformerMunicipalityMeterReportDialogProps) {
        super(props);
        this.state = {
            open: false,
            area: null,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
            area: null,
        });
    };

    private readonly onAreaChange = (area ?: string | null) => {
        this.setState({
            area: area ?? null,
        });
    };
    
    private readonly getData = (state : ITransformerMunicipalityMeterReportDialogState, props : ITransformerMunicipalityMeterReportDialogProps) => props.transformers;
    private readonly getPropsReadings = (state : ITransformerMunicipalityMeterReportDialogState, props : ITransformerMunicipalityMeterReportDialogProps) => props.readings;
    private readonly getArea = (state : ITransformerMunicipalityMeterReportDialogState) => state.area;
    private readonly getTransformers = createSelector([
        this.getData, this.getArea,
    ], (transformers, area) => {
        return lodash
            .chain(transformers)
            .filter(n => !area || n.Division.toLocaleLowerCase() === area.toLocaleLowerCase())
            .filter(n => !area || n.MeterType?.toLocaleLowerCase() === 'municipality')
            .filter(n => !!n.IsActive)
            .sortBy(n => n.EPNumber)
            .value();
    });

    private readonly getReadings = createSelector([
        this.getPropsReadings, this.getArea,
    ], (readings, area) => {
        return lodash
            .chain(readings)
            .filter(n => !area || n.Division?.toLocaleLowerCase() === area.toLocaleLowerCase())
            .value();
    });

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, session } = this.props;
        const { open, area } = this.state;

        const transformers = this.getTransformers(this.state, this.props);
        const readings = this.getReadings(this.state, this.props);
        
        return (
            <>
                <Tooltip title='Municipality Meter Report'>
                    <div>
                        <Fab color={'primary'} size='small' onClick={this.onClick}>
                            <PrintIcon />
                        </Fab>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='spu-meter-report-dialog-title'
                    aria-describedby='spu-meter-report-description'
                >
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Area
                            </Typography>
                            <div className='fdc flx1 jcc ml20'>
                                <TransformerAreaDropdown
                                    type='municipality'
                                    fullWidth
                                    value={area}
                                    onChange={this.onAreaChange}
                                    className='cw'
                                    color='secondary'
                                />
                            </div>
                            <div className='fdc flx4 aic jcc'>
                            </div>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    {
                        area &&
                        <div className='fdc flx1'>
                            <PDFViewer style={{
                                flexGrow: 1,
                                borderWidth: 0,
                                flexDirection: 'column',
                                position: 'absolute',
                                height: '93%',
                                width: '100%',
                            }}>
                                <SPUMeterReportPdf area={area} session={session} transformers={transformers} readings={readings} />
                            </PDFViewer>
                        </div>
                    }
                    {
                        !area &&
                        <div className='fdc flx1 aic jcc'>
                            <Typography variant='h5' color='inherit'>
                                Please select area
                            </Typography>
                        </div>
                    }
                    <StandardFab style={{
                        bottom: 15,
                        left: 15,
                    }} aria-label='Back' onClick={this.onClose}>
                        <ArrowBackIcon />
                    </StandardFab>
                </Dialog>
            </>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        transformers: state.transformer.transformers,
    };
};

const TransformerMunicipalityMeterReportDialog = connect(
    mapStateToProps,
)(TransformerMunicipalityMeterReportDialogComponent);

export default TransformerMunicipalityMeterReportDialog;

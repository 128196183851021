import React from 'react';
import CustomMapBox, { IMapMarker } from './CustomMapBox';
import { TRANSFORMER_MAP_IMAGES } from '../../../appConstants';
import TransformerFunctions from '../../../store/transformer/functions';
import { IRootState } from '../../../@types/redux';
import { createSelector } from 'reselect';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { connect } from 'react-redux';

interface ITransformerCustomMapProps {
    id ?: string;

    selected ?: string | null;
    onMarkerClick ?: (id : string) => void;

    transformers : Array<ITransformer>;
    isLoading : boolean;

    extraMarkers ?: Array<IMapMarker>;
    extraImages ?: Record<string, string>;
}

interface ITransformerCustomMapState {}

class TransformerCustomMapComponent extends React.PureComponent<ITransformerCustomMapProps, ITransformerCustomMapState> {
    private mapZoom = 8;

    constructor(props : ITransformerCustomMapProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount = () => {
        TransformerFunctions.getList();
    };

    public readonly getData = (state : ITransformerCustomMapState, props : ITransformerCustomMapProps) => props.transformers;
    public readonly getExtraMarkers = (state : ITransformerCustomMapState, props : ITransformerCustomMapProps) => props.extraMarkers;
    public readonly getExtraImages = (state : ITransformerCustomMapState, props : ITransformerCustomMapProps) => props.extraImages;
    public readonly getMarkers = createSelector(
        [
            this.getData,
            this.getExtraMarkers,
        ],
        (
            transformers,
            extraMarkers,
        ) => {
            return [
                ...transformers.map(n => ({
                    coordinates: [n.Geom?.longitude ?? 0, n.Geom?.latitude ?? 0],
                    iconSize: 0.35,
                    icon: '1',
                    id: n.EPNumber,
                } as IMapMarker)),
                ...(extraMarkers ?? []),
            ];
        }
    );

    public readonly getImages = createSelector(
        [
            this.getExtraImages,
        ],
        (
            extraImages,
        ) => {
            return {
                ...TRANSFORMER_MAP_IMAGES,
                ...extraImages,
            };
        }
    );

    private readonly onMarkerClick = (id : string) => {
        if (!this.props.onMarkerClick) return;
        this.props.onMarkerClick(id);
    };

    public readonly render = () => {
        const {
            id = 'transformer_map',
            selected,
        } = this.props;

        const markers = this.getMarkers(this.state, this.props);
        const images = this.getImages(this.state, this.props);
        return (
            <CustomMapBox
                id={id}
                enableAreas
                geoLocationControl
                navigationControl
                showScale
                layerControls
                images={images}
                onMarkerClick={this.onMarkerClick}
                markers={markers}
                selectedMarker={selected}
            />
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoading: state.transformer.loading,
    };
};

const TransformerCustomMap = connect(
    mapStateToProps,
)(TransformerCustomMapComponent);

export default TransformerCustomMap;
import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import { Form } from 'informed';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import moment, { Moment } from 'moment';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { DatePicker } from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { BOREHOLE_STATUS } from '../../../appConstants';
import lodash from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import boreholeFunctions from '../../../store/borehole/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import { IGroundWaterZone } from '../../../@types/model';
import BoreholeGroundwaterZoneSelector from '../../customComponents/selector/borehole/GroundWaterZoneSelector';

interface IEditBoreholeInfoProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;

    borehole ?: IBorehole;
}

interface IEditBoreholeInfoState {
    open : boolean;
    name : string;
    performanceTest : string;
    equipped : boolean;
    levelCheck : boolean;
    boreholStatus : string;
    designDate ?: Moment;
    comment : string;
    groundwaterZone : IGroundWaterZone | null;
}

export default class EditBoreholeInfo extends React.Component<IEditBoreholeInfoProps, IEditBoreholeInfoState> {
    constructor(props : IEditBoreholeInfoProps) {
        super(props);
        this.state = {
            open: false,
            name: '',
            equipped: false,
            levelCheck: false,
            boreholStatus: '',
            performanceTest: '',
            comment: '',
            groundwaterZone: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeInfoProps, prevState : IEditBoreholeInfoState) => {
        if (!prevState.open && this.state.open && this.props.borehole) {
            this.setState({
                name: this.props.borehole.Name,
                equipped: !!this.props.borehole.Equipped,
                levelCheck: !!this.props.borehole.LevelCheck,
                boreholStatus: this.props.borehole.Status ? this.props.borehole.Status.toString() : '',
                designDate: this.props.borehole.DesignDate ? moment(this.props.borehole.DesignDate.toMillis()) : undefined,
                performanceTest: this.props.borehole.PerfomanceTest ? this.props.borehole.PerfomanceTest : '',
                comment: this.props.borehole.Comment ? this.props.borehole.Comment : '',
                groundwaterZone: this.props.borehole.GroundwaterZone ?? null,
            });
        }

    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onCommentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            comment: event.currentTarget.value,
        });
    };

    private readonly onPerformanceTestChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            performanceTest: event.currentTarget.value,
        });
    };

    private readonly onDesignDateChanged = (momentDate : moment.Moment | null) => {
        if (!momentDate) return;
        this.setState({
            designDate: moment(momentDate),
        });
    };

    private readonly onStatusChange = (event : React.ChangeEvent<{name ?: string | undefined; value : unknown}>) => {
        if (typeof event.target.value !== 'string') return;
        this.setState({
            boreholStatus: event.target.value,
        });
    };

    private readonly onEquippedChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            equipped: checked,
        });
    };

    private readonly onLevelCheckChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            levelCheck: checked,
        });
    };

    private readonly onGroundwaterZoneChange = (groundwaterZone : IGroundWaterZone | null) => {
        this.setState({
            groundwaterZone,
        });
    };

    private readonly save = async () => {
        const { name, designDate, performanceTest, equipped, boreholStatus,
            levelCheck, comment, groundwaterZone } = this.state;
        if (!name) return;

        const borehole = Object.assign({}, this.props.borehole);

        borehole.Name = name;
        borehole.PerfomanceTest = performanceTest;
        borehole.Equipped = equipped;
        borehole.Status = boreholStatus;
        borehole.LevelCheck = levelCheck;
        borehole.DesignDate = !designDate ? null : firebase.firestore.Timestamp.fromMillis(designDate.valueOf());
        borehole.Comment = comment;
        borehole.GroundwaterZone = !groundwaterZone ? null : {
            ...groundwaterZone,
        };

        await boreholeFunctions.saveBorehole(borehole);

        this.onClose();
    };

    private onSubmit = () => {
        this.save();
    };

    private onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, name, designDate, performanceTest, equipped, boreholStatus, levelCheck, comment, groundwaterZone } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-info-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Borehole Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='name'
                                            label='Name'
                                            value={name}
                                            onChange={this.onNameChange}
                                            margin='normal'
                                            className={'TextField'}
                                            required
                                            error={!name}
                                        />
                                        {
                                            !name &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <DatePicker value={designDate} onChange={this.onDesignDateChanged} format='YYYY-MM-DD' label='Design Date'
                                            id='designDate'
                                            className={'TextField'}
                                            margin={'normal'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='performanceTest'
                                            label='Borehole Test'
                                            value={performanceTest ? performanceTest : ''}
                                            onChange={this.onPerformanceTestChange}
                                            margin='normal'
                                            className={'TextField'}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl margin='normal' fullWidth>
                                        <InputLabel shrink={!!boreholStatus} htmlFor='boreholStatus'>Status</InputLabel>
                                        <Select
                                            fullWidth
                                            id='boreholStatus'
                                            value={!boreholStatus ? '' : boreholStatus}
                                            onChange={this.onStatusChange}>
                                            <MenuItem value=''>
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                lodash.map(BOREHOLE_STATUS, (n, i) => (
                                                    <MenuItem key={`${n}_status`} value={`${i}`}>
                                                        {n}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 aic p5 mb10 pr20'}>
                                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={equipped} onChange={this.onEquippedChanged} />
                                            }
                                            label='Equipped?'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 aic p5 mb10 pr20'}>
                                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox color='primary' checked={levelCheck} onChange={this.onLevelCheckChanged} />
                                            }
                                            label='Level Check?'
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <BoreholeGroundwaterZoneSelector value={groundwaterZone} onChange={this.onGroundwaterZoneChange} />
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'} />
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='comment'
                                            label='Comment'
                                            value={comment}
                                            onChange={this.onCommentChange}
                                            margin='normal'
                                            className={'TextField'}
                                            multiline
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </React.Fragment>);
    };
}

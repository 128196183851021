import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import appFunctionsService from '../../../services/appFunctionsService';
import Typography from '@material-ui/core/Typography';
import { IUserSession } from '../../../@types/employee';
import TransformerLpuReadingEntryView from './LpuReadingEntryView';
import { ITransformerLpuReading } from '../../../@types/model/transformer/transformerLpuReading';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { StyledExpansionPanel } from '../../customComponents/StyledExpansionPanel';
import TransformerLpuReadingDelete from '../dialog/DeleteLpuReading';
import EditTransformerLpuReadingEdit from '../dialog/EditLpuReading';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

interface ITransformerLpuReadingViewProps {
    transformerLpuReadings : Array<ITransformerLpuReading>;
    transformer ?: ITransformer;
    isLoading : boolean;
    session ?: IUserSession | null;
}

interface ITransformerLpuReadingViewState {
    selectedReading ?: ITransformerLpuReading;
    showEdit : boolean;
    showDelete : boolean;
}

class TransformerLpuReadingView extends React.Component<ITransformerLpuReadingViewProps, ITransformerLpuReadingViewState> {
    constructor(props : ITransformerLpuReadingViewProps) {
        super(props);
        this.state = {
            showEdit: false,
            showDelete: false,
            selectedReading: undefined,
        };
    }

    private readonly onDelete = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const reading = this.props.transformerLpuReadings.find((e) => e.id == event.currentTarget.value);
        this.setState({
            showDelete: true,
            selectedReading: reading,
        });
    };

    private readonly onEdit = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const reading = this.props.transformerLpuReadings.find((e) => e.id == event.currentTarget.value);
        this.setState({
            showEdit: true,
            selectedReading: reading,
        });
    };

    private readonly onDeleteClose = () => {
        this.setState({
            showDelete: false,
            selectedReading: undefined,
        });
    };

    private readonly onEditClose = (open : boolean) => {
        this.setState({
            showEdit: !!open,
            selectedReading: undefined,
        });
    };

    public readonly render = () => {
        const { transformerLpuReadings, isLoading, transformer, session } = this.props;
        const { showEdit, showDelete, selectedReading } = this.state;

        return (
            <div className='hfill fdc aifs pt20 pb20 pl20 pr20 bcg0 oya'>
                { isLoading && <LinearProgress className={'wfill mt5'} />}
                {
                    !isLoading && transformerLpuReadings.length === 0 &&
                <div>
                    <Typography className={'mb10'} color='inherit' variant='h5'>
                        No Reading Data Exists!
                    </Typography>
                </div>
                }
                {
                    transformerLpuReadings.map(n => (
                        <StyledExpansionPanel                             
                            key={n.id}
                            heading={appFunctionsService.formatDateTimeToDateOnly(n.date)}
                            actions={(expanded) => (
                                <div className={'fdr'}>
                                    {  
                                        session?.employee.IsTransformerAdmin && 
                                        <IconButton value={n.id} onClick={this.onEdit} className={expanded ? 'cw p0' : 'cp p0'}>
                                            <Icon>edit</Icon> 
                                        </IconButton>
                                    }
                                    {  
                                        session?.employee.IsTransformerAdmin && 
                                        <IconButton value={n.id} onClick={this.onDelete} className={`${expanded ? 'cw p0' : 'cp p0'} pl15`}>
                                            <Icon>delete</Icon> 
                                        </IconButton>
                                    }
                                </div>
                            )}
                        >
                            <TransformerLpuReadingEntryView reading={n} transformer={transformer}/>
                        </StyledExpansionPanel>
                    ))
                }
                <EditTransformerLpuReadingEdit
                    transformerRef={transformer?.ref.id}
                    fullWidth
                    maxWidth={'lg'}
                    reading={selectedReading}
                    open={showEdit}
                    onClose={this.onEditClose}
                />
                <TransformerLpuReadingDelete isLoading={isLoading} readingId={selectedReading?.id ?? ''} open={showDelete} onClose={this.onDeleteClose}/>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformer: state.transformer.transformer,
        transformerLpuReadings: state.transformer.lpuReadings,
        isLoading: state.transformer.loadingLpuReadings,
    };
};

export default connect(
    mapStateToProps,
)(TransformerLpuReadingView);

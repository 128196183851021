import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { TransformerMeterUoM, TransformerMeterUoMType } from '../../../../@types/model/transformer/transformer';

interface ITransformerMeterUoMSelectorProps {
    value : string | null;

    onChange : (value : TransformerMeterUoMType | null) => void;

    required ?: boolean;
}

interface ITransformerMeterUoMSelectorState {}

export default class TransformerMeterUoMSelector extends React.Component<ITransformerMeterUoMSelectorProps, ITransformerMeterUoMSelectorState> {
    constructor(props : ITransformerMeterUoMSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onTransformerMeterUoMChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {

        if (event.target.value !== null && typeof event.target.value !== 'string') {
            return;
        }

        this.props.onChange(event.target.value as TransformerMeterUoMType | null);
    };

    public readonly render = () => {
        const { value, required } = this.props;

        return (
            <div className={'flx1 ais p5 mb10'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!value} required={required} htmlFor='meterType'>Meter UoM</InputLabel>
                    <Select
                        fullWidth
                        id='meterUom'
                        value={!value ? '' : value}
                        required={required}
                        error={required && !value}
                        onChange={this.onTransformerMeterUoMChange}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(TransformerMeterUoM, n => (
                                <MenuItem key={`${n}_meter_uom`} value={`${n}`}>
                                    {n}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        );
    };
}

import moment, { Moment } from 'moment';
import React from 'react';
import { ISPSInvoice, SPSInvoiceHelper } from '../../@types/model/transformer/spsInvoice';
import GeneralFunctions from '../../store/general/functions';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import { Card, FormControl, IconButton, InputAdornment, Toolbar, Tooltip } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DropdownButton from '../customComponents/button/DropdownButton';
import MaterialTable from '../customComponents/materialTable/Table';
import { Add, Close, Edit } from '@material-ui/icons';
import AppFunctionsService from '../../services/appFunctionsService';
import StandardFab from '../customComponents/button/StandardFab';
import TransformerDropdown from '../customComponents/selector/transformer/TransformerSelector';
import { ITransformer } from '../../@types/model/transformer/transformer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SPSInvoiceEditDialog from './dialog/Edit';
import { Transitions } from '../customComponents/animations/Transitions';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../@types/employee';
import { FirebaseService } from '../../services/firebaseService';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../appConstants';
import TransformerInvoiceJournalDialog from '../transformers/capturing/dialog/JournalDialog';

interface ISPSInvoiceListProps {
    session ?: IUserSession | null;
}

interface ISPSInvoiceListState {
    monthDate : Moment | null;
    epNumber : string | null;

    isLoading : boolean;

    invoices : Array<ISPSInvoice>;

    selectedInvoice : ISPSInvoice | null;
    selectedInvoices : Array<ISPSInvoice>;
}

class SPSInvoiceListComponent extends React.PureComponent<ISPSInvoiceListProps, ISPSInvoiceListState> {
    private readonly maxDate = moment.utc().startOf('month').add(1, 'day');
    private listener : null | (() => void);
    
    constructor(props : ISPSInvoiceListProps) {
        super(props);
        this.state = {
            isLoading: false,
            monthDate: null,
            epNumber: null,
            selectedInvoice: null,
            invoices: [],
            selectedInvoices: [],
        };

        this.listener = null;
    }

    public componentDidMount() : void {
        this.refresh();
    }

    private readonly refresh = () => {
        if (this.listener) this.listener();
        this.setState({
            isLoading: true,
            invoices: [],
            selectedInvoices: [],
        });

        this.listener = SPSInvoiceHelper.collection()
            .orderBy('monthDate', 'desc')
            .onSnapshot((snapshot) => {
                const invoices = this.state.invoices.slice();

                snapshot.docChanges().forEach((f) => {
                    const data = f.doc.data();
    
                    switch (f.type) {
                        case 'added':
                            invoices.splice(f.newIndex, 0, data);
                            break;
                        case 'modified':
                            invoices.splice(f.oldIndex, 1);
                            invoices.splice(f.newIndex, 0, data);
                            break;
                        case 'removed':
                            invoices.splice(f.oldIndex, 1);
                            break;
                    }
                });

                this.setState({
                    invoices,
                    isLoading: false,
                });
            }, (error) => {
                GeneralFunctions.generalShowError(error, 'Error Loading Invoices');
            });
    };

    private readonly onMonthDateChanged = (momentDate : moment.Moment | null) => {
        this.setState({
            monthDate: !momentDate?.isValid() ? null : moment.utc(momentDate.valueOf()).startOf('month'),
            selectedInvoices: [],
        });
    };

    private readonly onClearDateClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        this.setState({
            monthDate: null,
            selectedInvoices: [],
        });
    };

    private readonly getInvoices = (props : ISPSInvoiceListProps, state : ISPSInvoiceListState) => state.invoices;
    private readonly getMonthDate = (props : ISPSInvoiceListProps, state : ISPSInvoiceListState) => state.monthDate;
    private readonly getEpNumber = (props : ISPSInvoiceListProps, state : ISPSInvoiceListState) => state.epNumber;
    public readonly getList = createSelector(
        [
            this.getInvoices,
            this.getMonthDate,
            this.getEpNumber,
        ],
        (
            invoices,
            monthDate,
            epNumber,
        ) => {
            return lodash
                .chain(invoices)
                .filter(n => !epNumber || n.transformerRef.toLowerCase().includes(epNumber.toLowerCase()))
                .filter(x => !monthDate || x.monthDate === monthDate.valueOf())
                .uniq()
                .value();
        },
    );

    private readonly onEditClose = () => {
        this.setState({
            selectedInvoice: null,
        });
    };

    private readonly onAddClick = () => {
        const { session } = this.props;

        if (!session) return;
        const selectedInvoice : ISPSInvoice = {
            id: '',
            totalExVat: 0,
            totalGenerationKwh: 0,
            totalVat: 0,
            totalIncVat: 0,
            vat: FirebaseService.getVat(),
            createdBy: session.firebaseUser.uid,
            createdByEmployee: session.employee.EmployeeNumber ?? '',
            createdByName: session.firebaseUser.displayName ?? '',
            createdOn: moment.utc().valueOf(),
            updatedBy: session.firebaseUser.uid,
            updatedByName: session.firebaseUser.displayName ?? '',
            updatedByEmployee: session.employee.EmployeeNumber ?? '',
            updatedOn: moment.utc().valueOf(),
        } as ISPSInvoice;
        
        this.setState({
            selectedInvoice,
        });
    };

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const line = this.state.invoices.find(x => x.id === event.currentTarget.value);
        this.setState({
            selectedInvoice: line ?? null,
        });
        
    };

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            epNumber: value?.EPNumber ?? null,
        });
    };

    private readonly onJournalDialogClose = () => {
        this.setState({
            selectedInvoices: [],
        });
    };

    private readonly onSelectionChange = (selectedRows : Array<ISPSInvoice>) => {
        this.setState({
            selectedInvoices: selectedRows,
        });
    };

    public readonly render = () => {
        const {
            isLoading,
            monthDate,
            epNumber,
            selectedInvoice,
            selectedInvoices,
        } = this.state;

        const invoices = this.getList(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <Toolbar className='aifs'>
                    <div className='flx1 ais mb10'>
                        {
                            !!selectedInvoices.length &&
                            <TransformerInvoiceJournalDialog
                                onClose={this.onJournalDialogClose}
                                invoices={selectedInvoices}
                                fullScreen
                                transition={Transitions.Down}
                                type={'sps'}
                            />
                        }
                    </div>
                    <span className={'flx1'} />
                    <div className={'flx1 ais mb10 pl10 pr10'}>
                    </div>
                    <div className={'flx1 ais mb10 pl10 pr10'}>
                    </div>
                    <div className={'flx1 ais mb10 pl10 pr10'}>
                        <FormControl fullWidth>
                            <KeyboardDatePicker
                                value={monthDate}
                                views={['month']}
                                maxDate={this.maxDate}
                                onChange={this.onMonthDateChanged}
                                format='MMM YYYY'
                                label='Select Account Month...'
                                autoComplete='off'
                                id='monthDate'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            {
                                                monthDate &&
                                                <IconButton size='small' onClick={this.onClearDateClick}>
                                                    <Close />
                                                </IconButton>
                                            }
                                            <DropdownButton />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className={'flx1 ais mb10 pl10 pr10'}>
                        <FormControl fullWidth>
                            <TransformerDropdown
                                value={epNumber}
                                label='Waypoint No'
                                fullWidth
                                onChange={this.onEPNumberChange}
                                type={'sps'}
                            />
                        </FormControl>
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<ISPSInvoice>
                        id='ISPSInvoiceTable'
                        data={invoices}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        selectable
                        selectedRows={selectedInvoices}
                        onSelectionChanged={this.onSelectionChange}
                        isSelectable={(row) => 
                            !row.paid &&
                            !row.journaled &&
                            !!row.transformer.Company &&
                            !!row.transformer.BankAccount
                        }
                        columns={[{
                            header: '',
                            field: 'monthDate',
                            width: 75,
                            paddingLeft: 15,
                            renderCell: row => (
                                <div className='aic'>
                                    {
                                        !row.paid &&
                                        !row.journaled &&
                                        <Tooltip title='Edit'>
                                            <IconButton value={row.id} onClick={this.onEditClick}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            ),
                        }, {
                            header: 'Month',
                            field: 'monthDate',
                            width: 75,
                            paddingLeft: 15,
                            renderCell: row => (
                                <div className='aic'>
                                    {AppFunctionsService.formatDateTimeToMonthYearOnly(row.monthDate)}
                                </div>
                            ),
                        }, {
                            header: 'Way Point',
                            field: 'transformerRef',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Group',
                            field: 'transformer.EPGroup',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Pole No',
                            field: 'transformer.PoleNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Account No',
                            field: 'transformer.AccountNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Peak Units (kWh)',
                            field: 'peakGenerationKwh',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {!row.peakGenerationKwh ? '-' : row.peakGenerationKwh.toLocaleString()}
                                </div>
                            ),
                        }, {
                            header: 'Standard Units (kWh)',
                            field: 'standardGenerationKwh',
                            width: 205,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {!row.standardGenerationKwh ? '-' : row.standardGenerationKwh.toLocaleString()}
                                </div>
                            ),
                        }, {
                            header: 'Off-Peak Units (kWh)',
                            field: 'offPeakGenerationKwh',
                            width: 205,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {!row.offPeakGenerationKwh ? '-' : row.offPeakGenerationKwh.toLocaleString()}
                                </div>
                            ),
                        }, {
                            header: 'Total Units (kWh)',
                            field: 'totalGenerationKwh',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {row.totalGenerationKwh.toLocaleString()}
                                </div>
                            ),
                        }, {
                            header: 'Total Charge',
                            field: 'totalExVat',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(row.totalExVat).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'VAT',
                            field: 'totalVat',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(row.totalVat).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Total Due',
                            field: 'totalIncVat',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {(row.totalIncVat).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Total Billed',
                            field: 'rebillTotal',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <div className=''>
                                    {!row.rebillTotal ? '-' : (row.rebillTotal).toLocaleCurrency()}
                                </div>
                            ),
                        }, {
                            header: 'Reason',
                            field: 'reasonForRebill',
                            width: 165,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Due Date',
                            field: 'dueDate',
                            width: 165,
                            renderCell: row => (
                                <div className=''>
                                    {!row.dueDate ? '-' : moment.utc(row.dueDate).format(DATE_FORMAT_DEFAULT_NO_TIME)}
                                </div>
                            ),
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Journaled',
                            field: 'journaled',
                            width: 145,
                            renderCell: row => (
                                <div className='fdc jcc'>
                                    <Tooltip title={!row.journaled ? '' : `${row.journaledByName} - ${row.journaledByEmployeeNumber}`}>
                                        <CheckCircleIcon className={!row.journaled ? 'cg2' : 'cp'} />
                                    </Tooltip>
                                </div>
                            ),
                        }, {
                            header: 'Paid',
                            field: 'paid',
                            width: 145,
                            renderCell: row => (
                                <div className='fdc jcc'>
                                    <Tooltip title={!row.paid ? '' : `${row.paidByName} - ${row.paidByEmployeeNumber}`}>
                                        <CheckCircleIcon className={!row.paid ? 'cg2' : 'cp'} />
                                    </Tooltip>
                                </div>
                            ),
                        }, {
                            header: 'Created By',
                            field: 'createdByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.createdByEmployee}>
                                    <div>
                                        {
                                            row.createdByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }, {
                            header: 'Updated By',
                            field: 'updatedByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.updatedByEmployee}>
                                    <div>
                                        {
                                            row.updatedByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <StandardFab aria-label='add' disabled={isLoading} onClick={this.onAddClick}>
                        <Add />
                    </StandardFab>
                </div>
                <SPSInvoiceEditDialog
                    value={selectedInvoice}
                    transition={Transitions.Up}
                    isLoading={isLoading}
                    onClose={this.onEditClose}
                    monthDate={monthDate}
                />
            </div>
        );
    };

}
const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
    };
};
    
const SPSInvoiceList = connect(
    mapStateToProps,
)(SPSInvoiceListComponent);

export default SPSInvoiceList;
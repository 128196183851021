import { CircularProgress, Typography } from '@material-ui/core';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { IFlowmeter } from '../../../@types/model/flowmeter/flowmeter';
import FlowmeterInstallationAuditHelper, { IFlowmeterInstallationAudit } from '../../../@types/model/flowmeter/flowmeterAudit';
import { IRootState } from '../../../@types/redux';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';
import { StyledExpansionPanel } from '../../customComponents/StyledExpansionPanel';

interface IFlowmeterInstallationAuditProps {
    flowmeter : IFlowmeter;
    isLoading : boolean;
    
    isFlowmeterAdmin : boolean;
}

interface IFlowmeterInstallationAuditState {
    isLoading : boolean;
    entries : Array<IFlowmeterInstallationAudit>;
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

class FlowmeterInstallationAudits extends React.PureComponent<IFlowmeterInstallationAuditProps, IFlowmeterInstallationAuditState> {
    private listener ?: () => void;
    constructor(props : IFlowmeterInstallationAuditProps) {
        super(props);
        this.state = {
            isLoading: false,
            entries: [],
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }
    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly componentWillUnmount = () => {
        if (this.listener) this.listener();
    };

    public readonly loadData = () => {
        if (this.listener) this.listener();

        this.setState({
            isLoading: true,
        });

        this.listener = FlowmeterInstallationAuditHelper.collection(this.props.flowmeter.id).onSnapshot((snapshot) => {
            const list = this.state.entries.slice();
    
            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                const item = {
                    ...f.doc.data(),
                };

                const index = lodash.findIndex(list, n => n.id === item.id);

                switch (f.type) {
                    case 'added':
                        list.push(item);
                        break;
                    case 'modified':
                        list.splice(index, 1, item);
                        break;
                    case 'removed':
                        list.splice(index, 1);
                        break;
                }
            });

            const entries = list.sort((a, b) => b.date - a.date);

            this.setState({
                isLoading: false,
                entries,
                imageSrcs: entries.filter(x => !!x.serialNumberPhotoUrl).map(x => x.serialNumberPhotoUrl),
            });
        }, (ex) => {
            GeneralFunctions.generalShowError(ex, 'Error loading readings');
        }, () => {
            if (this.listener) this.listener();

            this.setState({
                isLoading: false,
                entries: [],
            });
        });
    };

    private onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const {
            entries,
            isLoading,
            imageIndex,
            isCarouselOpen,
            imageSrcs,
        } = this.state;
        
        return (
            <div className={'fdc flx1 m10'}>
                { 
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    entries.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Data
                        </Typography>
                    </div>
                }
                {
                    entries.map(flowmeterInstallationAudit => (
                        <StyledExpansionPanel
                            key={flowmeterInstallationAudit.id}
                            heading={AppFunctionsService.formatDateTimeToDateOnly(flowmeterInstallationAudit.date)}
                        >
                            <div className='fdc'>
                                <div className={'fdr'}>
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            CURRENT METER
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {flowmeterInstallationAudit.currentMeter}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            MAKE
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {flowmeterInstallationAudit.make}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            SIZE
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {flowmeterInstallationAudit.size}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            REED SWITCH
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {flowmeterInstallationAudit.reedSwitch ? 'YES' : 'NO'}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            PULSE
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {flowmeterInstallationAudit.pulse}
                                        </Typography>
                                    </div>
                                    <div className={'bcg0 fdc flx1 aic jcc p10'}>
                                        <Typography className={'fs11 cdg'}>
                                            SERIAL NUMBER
                                        </Typography>
                                        <Typography className={'fs13 cg3 fw700 pt10'}>
                                            {flowmeterInstallationAudit.serialNumber}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={'fdr mt8'}>
                                    <div className={'fdr flx1 aifs mr8'}>
                                        <div className={'bcg0 fdc flx1 aic jcc p10'}>
                                            <Typography className={'fs11 cdg'}>
                                                CONNECTED TO MONITORNING SYSTEM
                                            </Typography>
                                            <Typography className={'fs13 cg3 fw700 pt10'}>
                                                {flowmeterInstallationAudit.connected ? 'YES' : 'NO'}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={'fdr flx1 aifs mr8'}>
                                        <div className={'bcg0 fdc flx1 aic jcc p10'}>
                                            <Typography className={'fs11 cdg'}>
                                                MONITORING
                                            </Typography>
                                            <Typography className={'fs13 cg3 fw700 pt10'}>
                                                {!flowmeterInstallationAudit.monitor ? '-' : flowmeterInstallationAudit.monitor}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={'fdc flx4 pr24 h275 bcg0'}>
                                        <div className='fdc bcg0 pt8 pb8'>
                                            <div className='fdc aifs jcfs pl50'>
                                                <Typography className='fs11 fwr cp'>SERIAL NUMBER PHOTO</Typography>
                                            </div>
                                            <div className='fdr aic jcc flx1'>
                                                {
                                                    flowmeterInstallationAudit.serialNumberPhotoUrl &&
                                                    <FirebaseStorageImage
                                                        url={flowmeterInstallationAudit.serialNumberPhotoUrl}
                                                        thumbnailUrl={flowmeterInstallationAudit.serialNumberThumbnailUrl}
                                                        fileName={''}
                                                        path={`flowmeter/${flowmeterInstallationAudit.flowmeterCode}`}
                                                        onClick={this.onThumbClick}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </StyledExpansionPanel>
                    ))
                }
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.flowmeter.loading,
        isFlowmeterAdmin: !!state.auth.session?.employee.IsFlowmeterAdmin,
    };
};

export default connect(
    mapStateToProps,
)(FlowmeterInstallationAudits);

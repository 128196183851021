import React from 'react';
import WeatherStationAuditHelper, { IWeatherStationAudit } from '../../../@types/weatherStation';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import appFunctionsService from '../../../services/appFunctionsService';
import Typography from '@material-ui/core/Typography';
import { IUserSession } from '../../../@types/employee';
import WeatherStationAuditEntryView from './AuditEntryView';
import { StyledExpansionPanel } from '../../customComponents/StyledExpansionPanel';
import { Transitions } from '../../customComponents/animations/Transitions';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

interface IWeatherStationAuditViewProps {
    audits : Array<IWeatherStationAudit>;
    isLoading : boolean;
    session : IUserSession | null;
}

interface IWeatherStationAuditViewState {
    showDelete : boolean;
    selectedAudit : string;
}

class WeatherStationAuditView extends React.Component<IWeatherStationAuditViewProps, IWeatherStationAuditViewState> {
    constructor(props : IWeatherStationAuditViewProps) {
        super(props);
        this.state = {
            showDelete: false,
            selectedAudit: '',
        };
    }

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        this.setState({
            selectedAudit: event.currentTarget.value,
            showDelete: true,
        });
    };

    private readonly onDelete = (result : boolean) => {
        const selectedAudit = this.props.audits.slice().find((n : IWeatherStationAudit) => n.Id === this.state.selectedAudit);
        if (selectedAudit && result) {
            WeatherStationAuditHelper.delete(selectedAudit.Id);
        }
        this.setState({
            showDelete: false,
        });
    };

    public readonly render = () => {
        const { audits, isLoading, session } = this.props;
        const { showDelete } = this.state;
        return (
            <div className={'fdc flx1 m10'}>
                { isLoading && <LinearProgress className={'wfill mt5'} />}
                {
                    !isLoading && audits.length === 0 &&
                <div>
                    <Typography className={'mb10'} color='inherit' variant='h5'>
                        No Audit Data Exists!
                    </Typography>
                </div>
                }
                {
                    audits.map(n => (
                        <StyledExpansionPanel                             
                            key={n.GUID}
                            heading={appFunctionsService.formatDateTimeToDateOnly(n.CreatedOn)}
                            actions={(expanded) => (
                                <div className={'fdr'}>
                                    {  
                                        session?.employee.IsWeatherStationAdmin && 
                                        <IconButton value={n.Id} onClick={this.onDeleteClick} className={`${expanded ? 'cw p0' : 'cp p0'} pl15`}>
                                            <Icon>delete</Icon> 
                                        </IconButton>
                                    }
                                </div>
                            )}
                        >
                            <WeatherStationAuditEntryView audit={n} />
                        </StyledExpansionPanel>
                    ))
                }
                <ConfirmDialog open={showDelete} title={'Confirm'}
                    message={'Delete Weather Station Audit?'}
                    onClose={this.onDelete} transition={Transitions.Up} />
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        audits: state.weather.audits,
        isLoading: state.weather.loadingAudits,
    };
};

export default connect(
    mapStateToProps,
)(WeatherStationAuditView);

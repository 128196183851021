import { Card, TextField, Typography } from '@material-ui/core';
import { FieldProps } from 'formik';
import lodash from 'lodash';
import React from 'react';
import { createSelector } from 'reselect';
import { BoreholeConstantRateDrumStage, BoreholeConstantRateDrumStageType, IBoreholeConstantRateTestDrumFormValues, IBoreholeConstantRateTestFormValues } from '../../../../@types/model/borehole/boreholeConstantRateTest';
import TextFormField from '../TextFormField';
import TimeFormField from '../TimeFormField';

interface IBoreholeConstantRateDrumFormFieldProps extends FieldProps<Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateTestDrumFormValues>, IBoreholeConstantRateTestFormValues> {
}

export default class BoreholeConstantRateDrumFormField extends React.PureComponent<IBoreholeConstantRateDrumFormFieldProps> {
    constructor(props : IBoreholeConstantRateDrumFormFieldProps) {
        super(props);
        this.state = {};
    }

    public readonly getValues = (props : IBoreholeConstantRateDrumFormFieldProps) => props.field.value;
    public readonly getValue = createSelector(
        [this.getValues],
        (value) => {
            return value;
        },
    );

    private readonly onCapacityChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        BoreholeConstantRateDrumStage.forEach(step => {
            this.props.form.setFieldValue(`drumEntries.${step}.capacity`, event.currentTarget.value);
        });
    };

    public readonly render = () => {
        const drumEntries = this.getValue(this.props);
        return (
            <div className='fdc'>
                <Card className='fdr aic jcc p15 bcdlg3'>
                    <Typography className='flx1 aic jcc fs18 fwb cw'>
                        DRUM YIELD TEST
                    </Typography>
                </Card>
                <div className='fdc'>
                    <Card className='fdc flx1 mt12'>
                        <div className='fdr bcw h50'>
                            <div className={'fdc flx1 jcc aic bcp h50'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    STAGE
                                </Typography>
                            </div>
                            <span className='w3' />
                            <div className={'fdc flx1 jcc aic bcp h50'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    CAPACITY (L)
                                </Typography>
                            </div>
                            <span className='w3' />
                            <div className={'fdc flx1 jcc aic dvbg h50'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    TIME (HH:mm)
                                </Typography>
                            </div>
                            <span className='w3' />
                            <div className={'fdc flx1 jcc aic dvblg h50'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    FILL TIME (s)
                                </Typography>
                            </div>
                            <span className='w3' />
                            <div className={'fdc flx1 jcc aic bcdlg1 h50'}>
                                <Typography className={'fs14 fw400 cw'}>
                                    YIELD (m<sup>3</sup>/h)
                                </Typography>
                            </div>
                        </div>
                        {
                            lodash.map(drumEntries, (x, step) => (
                                <div key={step} className={`fdr ais jcc ${Number(step) % 2 === 1 ? 'bcg2' : ''}`}>
                                    <div className={'fdr flx1 aic jcc pb16 pt16 pr30 pl30'}>
                                        <Typography className={'fs20 fwb'}>
                                            {
                                                step
                                            }
                                        </Typography>
                                    </div>
                                    <span className='w3 bcw' />
                                    <div className={'fdr flx1 aic jcc pb16 pt16 pr30 pl30'}>
                                        <TextFormField
                                            inputProps={{
                                                className: 'cp fwb',
                                                style: {
                                                    textAlign: 'center',
                                                },
                                            }}
                                            id={`drumEntries.${step}.capacity`}
                                            name={`drumEntries.${step}.capacity`}
                                            fullWidth
                                            type='number'
                                            onChange={this.onCapacityChange}
                                        />
                                    </div>
                                    <span className='w3 bcw' />
                                    <div className={'fdr flx1 ais jcc pb16 pt16 pr30 pl30'}>
                                        <TimeFormField
                                            name={`drumEntries.${step}.time`}
                                            fullWidth
                                            TextFieldComponent={(params) => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        className: params.inputProps + ' cp fwb',
                                                        style: {
                                                            ...params.inputProps?.style,
                                                            textAlign: 'center',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <span className='w3 bcw' />
                                    <div className={'fdr flx1 aic jcc pb16 pt16 pr30 pl30'}>
                                        <TextFormField
                                            inputProps={{
                                                className: 'cp fwb',
                                                style: {
                                                    textAlign: 'center',
                                                },
                                            }}
                                            id={`drumEntries.${step}.fillTime`}
                                            name={`drumEntries.${step}.fillTime`}
                                            fullWidth
                                            type='number'
                                        />
                                    </div>
                                    <span className='w3 bcw' />
                                    <div className={'fdr flx1 ais jcc pb16 pt16 pr30 pl30'}>
                                        <TextFormField
                                            inputProps={{
                                                className: 'cp fwb',
                                                style: {
                                                    textAlign: 'center',
                                                },
                                            }}
                                            id={`drumEntries.${step}.rate`}
                                            name={`drumEntries.${step}.rate`}
                                            fullWidth
                                            type='number'
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </Card>
                </div>
            </div>
        );
    };
}

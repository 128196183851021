import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import { IRootState } from '../../../../@types/redux';
import { Transitions } from '../../../customComponents/animations/Transitions';
import EditBoreholeInstallationInfoDialog from '../../dialog/EditInstallationDialog';

interface IBoreholeInstallationViewProps {
    borehole : IBorehole;

    isLoading : boolean;

    isBoreholeAdmin : boolean;
}

interface IBoreholeInstallationViewState {}

class BoreholeInstallationViewComponent extends React.PureComponent<IBoreholeInstallationViewProps, IBoreholeInstallationViewState> {
    constructor(props : IBoreholeInstallationViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { isBoreholeAdmin, borehole, isLoading } = this.props;
        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            BOREHOLE INSTALLATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isBoreholeAdmin &&
                                    <EditBoreholeInstallationInfoDialog
                                        borehole={borehole}
                                        isLoading={isLoading}
                                        fullWidth
                                        maxWidth='lg'
                                        transition={Transitions.Down}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdr ml17 mr17 mb13'}>
                        <div className={'mt9 fdr mr40'}>
                            <Card className={'w200 bcg0 fdc'}>
                                <div className={'jcc aic wfill bcp h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        BOREHOLE INSTALLATION
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        TEST VALVE
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.TestValve ? `${borehole.TestValve} mm` : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc bcw aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        CASING DIAMETER
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.CasingDiameter ? borehole.CasingDiameter.toFixed(2) : 0} mm
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        CASTING HEIGHT
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.CasingHeight ? borehole.CasingHeight : 0} cm
                                    </Typography>
                                </div>
                                <div className={'fdc jcc bcw aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        CONCRETE BLOCK HEIGHT
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.ConcreteBlockHeight ? borehole.ConcreteBlockHeight : 0} mm
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        PIEZOMETER TUBE HEIGHT
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.PiezometerTubeHeight ? borehole.PiezometerTubeHeight : 0} mm
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                        <div className={'mt9 fdr mr30'}>
                            <Card className={'w200 bcg0 fdc'}>
                                <div className={'jcc aic wfill bcp h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        PUMP
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        PUMP
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.Pump ? borehole.Pump : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc bcw aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        PUMP TYPE
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.PumpType ? borehole.PumpType : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        PUMP SERIAL
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.PumpSerial ? borehole.PumpSerial : 'No Data'}
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                        <div className={'mt9 fdr mr30'}>
                            <Card className={'w200 bcg0 fdc'}>
                                <div className={'jcc aic wfill bcp h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        MOTOR
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        MOTOR TYPE
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.MotorType ? borehole.MotorType : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc bcw aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        MOTOR PHASE
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.MotorPhase ? borehole.MotorPhase : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        MOTOR DIAMETER
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.MotorDiameter ? `${borehole.MotorDiameter} inches` : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic bcw p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        MOTOR SERIAL
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.MotorSerial ? borehole.MotorSerial : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        MOTOR SIZE
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.MotorSize ? borehole.MotorSize : 0} kW
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                        <div className={'mt9 fdr mr30'}>
                            <Card className={'w200 bcg0 fdc'}>
                                <div className={'jcc aic wfill bcp h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        RISING MAIN
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        RISING MAN
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.RisingMain ? borehole.RisingMain : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc bcw aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        { borehole.RisingMain === 'uPVC Column' ? 'uPVC COLUMN' : 'RISING MAIN TYPE'}
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        { borehole.RisingMain === 'uPVC Column' ? (borehole.uPVCColumn ? borehole.uPVCColumn : 'No Data') : (borehole.RisingMainType ? borehole.RisingMainType : 'No Data')}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        { (borehole.RisingMain === 'Steel exact' || borehole.RisingMain === 'uPVC Column') ? 'NO. OF SECTIONS' : 'PIPE LENGTH'}
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        { (borehole.RisingMain === 'Steel exact' || borehole.RisingMain === 'uPVC Column') ? (borehole.PipeLength ? borehole.PipeLength / 3 : 0) : (borehole.PipeLength ? `${borehole.PipeLength} m` : 0)}
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                        <div className={'mt9 fdr mr30'}>
                            <Card className={'w200 bcg0 fdc'}>
                                <div className={'jcc aic wfill bcp h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        CABLE
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        CABLE CORES
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.CableCores ? borehole.CableCores : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc bcw aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        CABLE SIZE
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.CableSize ? borehole.CableSize : 'None'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        CABLE LENGTH (M)
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.CableLength ? borehole.CableLength : 0} m
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                        <div className={'mt9 fdr mr30'}>
                            <Card className={'w200 bcg0 fdc'}>
                                <div className={'jcc aic wfill bcp h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        STARTER
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        STARTER
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.Starter ? 'YES' : 'NO'}
                                    </Typography>
                                </div>
                                <div className={'fdc jcc bcw aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        STARTER TYPE
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.StarterType ? borehole.StarterType : 'None'}
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                        <div className={'mt9 fdr'}>
                            <Card className={'w200 bcg0 fdc'}>
                                <div className={'jcc aic wfill bcp h50'}>
                                    <Typography className={'fs14 fw500 cw '}>
                                        BOREHOLE LOGISTICS
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        HOLE DEPTH
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.HoleDepth ? borehole.HoleDepth : 0} m
                                    </Typography>
                                </div>
                                <div className={'fdc jcc bcw aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        INSTALLATION DEPTH
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.InstallationDepth ? borehole.InstallationDepth : 0} m
                                    </Typography>
                                </div>
                                <div className={'fdc jcc aic p10'}>
                                    <Typography className={'fs13 fw500 cp'}>
                                        INSTALLATION YIELD
                                    </Typography>
                                    <Typography className={'fs15 fw600 cb'}>
                                        {borehole.InstalledYield ?? '-'} m<sup>3</sup>/hr
                                    </Typography>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
        isLoading: state.borehole.loading,
    };
};

const BoreholeInstallationView = connect(
    mapStateToProps,
)(BoreholeInstallationViewComponent);

export default BoreholeInstallationView;

import React from 'react';
import transformerFunctions from '../../store/transformer/functions';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import TransformerInfo from './Info';
import { Transitions } from '../customComponents/animations/Transitions';
import navFunctions from '../../store/nav/functions';
import TransformerNewButton from './button/NewButton';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import appFunctionsService from '../../services/appFunctionsService';
import { ITransformer, TransformerMeterTypeUpperCase } from '../../@types/model/transformer/transformer';
import Fab from '@material-ui/core/Fab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MapIcon from '@material-ui/icons/Map';
import TransformerFilterSelector from '../customComponents/selector/transformer/FilterSelector';
import { IUserSession } from '../../@types/employee';
import { CSVLinkProps } from '../../@types/csv';

interface ITransformerListProps {
    transformers : Array<ITransformer>;
    isLoadingTransformers : boolean;
    session ?: IUserSession | null;
}

interface ITransformerListState {
    sortList : Array<{ field : string; direction : 'asc' | 'desc' }>;
    showAll : boolean;

    epNumber : string;
    epGroup : string;
    epArea : string;
    type : string;
    meterType : TransformerMeterTypeUpperCase | null;
}

class TransformerList extends React.Component<ITransformerListProps, ITransformerListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : ITransformerListProps) {
        super(props);

        this.state = {
            sortList: [],
            showAll: false,
            epNumber: '',
            epGroup: '',
            epArea: '',
            type: '',
            meterType: null,
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        transformerFunctions.getList();
    };

    private readonly onSortChanged = (sortList : Array<{ field : string; direction : 'asc' | 'desc' }>) => {
        this.setState({
            sortList,
        });
    };


    private readonly getEPNumber = (props : ITransformerListProps, state : ITransformerListState) => state.epNumber;
    private readonly getEPArea = (props : ITransformerListProps, state : ITransformerListState) => state.epArea;
    private readonly getEPGroup = (props : ITransformerListProps, state : ITransformerListState) => state.epGroup;
    private readonly getType = (props : ITransformerListProps, state : ITransformerListState) => state.type;
    private readonly getMeterType = (props : ITransformerListProps, state : ITransformerListState) => state.meterType;
    private readonly getData = (props : ITransformerListProps) => props.transformers;
    private readonly getSortList = (props : ITransformerListProps, state : ITransformerListState) => state.sortList;
    private readonly getShowAll = (props : ITransformerListProps, state : ITransformerListState) => state.showAll;

    public readonly getFilteredData = createSelector(
        [
            this.getData,
            this.getSortList,
            this.getShowAll,
            this.getEPNumber,
            this.getEPArea,
            this.getEPGroup,
            this.getType,
            this.getMeterType,
        ],
        (
            transformers, 
            sortList, 
            showAll,
            number,
            area,
            group,
            type,
            meterType,
        ) => {
            let sortedList = transformers
                .filter(n => showAll || typeof n.IsActive === 'undefined' || n.IsActive);
            sortedList = lodash
                .chain(sortedList)
                .filter(n => n.EPNumber.includes(number))
                .filter(n => n.Division.toLowerCase().includes(area.toLowerCase()))
                .filter(n => !group || !!n.EPGroup?.includes(group))
                .filter(n => !type || !!n.EPType?.toLowerCase().includes(type.toLowerCase()))
                .filter(n => !meterType || n.MeterType === meterType)
                .value();
            if (sortList.length === 0) {
                return sortedList.sort((a, b) => parseInt(a.EPNumber.substr(2)) - parseInt(b.EPNumber.substr(2)));
            }

            sortList
                .forEach((n) => {
                    if (n.field === 'EPNumber') {
                        sortedList = sortedList.sort((a, b) => parseInt(b.EPNumber.substr(2)) - parseInt(a.EPNumber.substr(2)) * (n.direction === 'desc' ? 1 : -1));
                    } else {
                        sortedList = lodash.orderBy(sortedList, n.field, n.direction);
                    }
                });
            return sortedList;
        },
    );

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (transformers) => {
            return transformers.filter(n => n.IsActive || typeof n.IsActive === 'undefined').map(n => ({
                EPNumber: n.EPNumber,
                EPGroup: n.EPGroup,
                PoleNumber: n.PoleNumber,
                Division: n.Division,
                SubArea: n.SubArea,
                Latitude: !n.Geom ? 0 : n.Geom.latitude,
                Longitude: !n.Geom ? 0 : n.Geom.longitude,
                CNC: n.CNC,
                EPType: n.EPType,
                AccountNumber: n.AccountNumber,
                Line: n.Line,
                LineVoltage: n.LineVoltage,
                MeterType: n.MeterType,
                CircuitBreaker: n.CircuitBreaker,
                TransformerRating: n.TransformerRating,
                MeterNumbers: !n.TransformerMeterNumbers ? '' : n.TransformerMeterNumbers.map(x => x.Number).join(', '),
                CreatedOn: appFunctionsService.formatDateTime(n.CreatedOn),
                UpdatedOn: appFunctionsService.formatDateTime(n.UpdatedOn),
                CurrentResident: n.CurrentResident,
                SecurityGuarantee: n.SecurityGuarantee ?? '',
                SecurityDeposit: n.SecurityDeposit ?? '',
                DefaultReader: !n.TransformerReaderEmployeeNr || !n.TransformerReaderEmployeeName ?
                    '' :
                    `${n.TransformerReaderEmployeeNr} - ${n.TransformerReaderEmployeeName}`,
            }));
        },
    );

    public readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const transformer = this.props.transformers.slice().find(x => x.EPNumber === event.currentTarget.value);

        transformerFunctions.setSelected(transformer);
    };

    public readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const transformer = this.props.transformers.slice().find(x => x.EPNumber === event.currentTarget.value);

        if (transformer) {
            navFunctions.navTransformerMap(transformer.EPNumber);
        }
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    private readonly onShowOnlyActiveClick = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    };

    public readonly onChange = (epNumber : string | null, epGroup : string | null, epArea : string | null, type : string | null, meterType : TransformerMeterTypeUpperCase | null) => {
        this.setState({
            epNumber: epNumber ?? '',
            epGroup: epGroup ?? '',
            epArea: epArea ?? '',
            type: type ?? '',
            meterType: meterType,
        });
    };
    
    public readonly render = () => {
        const { isLoadingTransformers, session } = this.props;
        const { showAll, epNumber, epGroup, epArea, type, meterType } = this.state;
        const transformers = this.getFilteredData(this.props, this.state);
        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0'}>
                <div className={'flx1 fdc mh0 mw0'}>
                    <Toolbar>
                        <Typography variant='h5' color='inherit'>
                            TRANSFORMERS
                        </Typography>
                        {
                            session?.employee.IsTransformerAdmin &&
                            <TransformerNewButton />
                        }
                        <span className={'flx4'} />
                        <div className='flx1'>
                            <TransformerFilterSelector
                                id='transformer_filter'
                                onChange={this.onChange}
                                epNumber={epNumber}
                                epArea={epArea}
                                epGroup={epGroup}
                                type={type}
                                meterType={meterType}
                                label='Filter By...'
                                disabled={isLoadingTransformers}
                            />
                        </div>
                        <div className={'fdr pt5 jcfe aic'}>
                            <div style={{ paddingRight: '15px' }}>
                                <Tooltip title='Show only Active'>
                                    <Fab color={!showAll ? 'primary' : 'default'} size='small' onClick={this.onShowOnlyActiveClick}>
                                        <Icon>
                                            <img src={`${ASSET_BASE}/assets/images/icons/active.svg`} />
                                        </Icon>
                                    </Fab>
                                </Tooltip>
                            </div>
                            <div className={'fdr pt5'}>
                                <span style={{ paddingRight: '15px' }}><b>Total: </b>{transformers.length}</span>
                            </div>
                        </div>
                    </Toolbar>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<ITransformer>
                            id='transformersTable'
                            data={transformers}
                            isLoading={isLoadingTransformers}
                            rowsPerPage={100}
                            externalSort
                            onSortChange={this.onSortChanged}
                            onExportCsvClick={!session?.employee.IsTransformerExport ? undefined : this.onCSVClick}
                            columns={[{
                                header: 'EP Nr',
                                field: 'EPNumber',
                                width: 150,
                                enableSort: true,
                                enableFilter: false,
                                paddingRight: 4,
                                fixed: true,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton color='primary' value={row.EPNumber} onClick={this.onInfoClick}>
                                                    <InfoOutlinedIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton color='primary' value={row.EPNumber} onClick={this.onMapClick} disabled={!row.Geom}>
                                                    <MapIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <span>{row.EPNumber}</span>
                                    </div>
                                ),
                            }, {
                                header: 'Company',
                                field: 'Company.name',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Group',
                                field: 'EPGroup',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Area',
                                field: 'Division',
                                width: 145,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Sub Area',
                                field: 'SubArea',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Pole Number',
                                field: 'PoleNumber',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Type',
                                field: 'EPType',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'CNC',
                                field: 'CNC',
                                width: 125,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Meter Type',
                                field: 'MeterType',
                                width: 150,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Account Nr',
                                field: 'AccountNumber',
                                width: 200,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Line',
                                field: 'Line',
                                width: 125,
                                enableSort: true,
                                enableFilter: true,
                            }, {
                                header: 'Line Voltage',
                                field: 'LineVoltage',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => (<span>{!n.LineVoltage ? '' : appFunctionsService.getLineVoltageRangeLabel(n.LineVoltage)}</span>),
                            }, {
                                header: 'Circuit Breaker',
                                field: 'CircuitBreaker',
                                width: 175,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.CircuitBreaker ? (<span></span>) : (<span>{n.CircuitBreaker} A</span>),
                            }, {
                                header: 'Transformer Rating',
                                field: 'TransformerRating',
                                width: 215,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.TransformerRating ? (<span></span>) : (<span>{n.TransformerRating} kVA</span>),
                            }, {
                                header: 'Active?',
                                field: 'IsActive',
                                width: 215,
                                enableSort: true,
                                enableFilter: true,
                                renderCell: n => !n.IsActive ? (<Icon className='cr'>close</Icon>) : (<Icon className='cdg'>check</Icon>),
                            }]}
                        />
                    </Card>
                    <TransformerInfo fullScreen transition={Transitions.Up} />
                    {
                        session?.employee.IsTransformerExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'EPNumber',
                                    label: 'EP Number',
                                },
                                {
                                    key: 'EPGroup',
                                    label: 'Group',
                                },
                                {
                                    key: 'Division',
                                    label: 'Area',
                                },
                                {
                                    key: 'SubArea',
                                    label: 'Sub Area',
                                },
                                {
                                    key: 'PoleNumber',
                                    label: 'Pole Number',
                                },
                                {
                                    key: 'EPType',
                                    label: 'EP Type',
                                },
                                {
                                    key: 'CNC',
                                    label: 'CNC',
                                },
                                {
                                    key: 'MeterType',
                                    label: 'Meter Type',
                                },
                                {
                                    key: 'AccountNumber',
                                    label: 'Account Number',
                                },
                                {
                                    key: 'Line',
                                    label: 'Line',
                                },
                                {
                                    key: 'LineVoltage',
                                    label: 'Line Voltage',
                                },
                                {
                                    key: 'CircuitBreaker',
                                    label: 'Circuit Breaker',
                                },
                                {
                                    key: 'TransformerRating',
                                    label: 'Transformer Rating',
                                },
                                {
                                    key: 'MeterNumbers',
                                    label: 'Meter Numbers',
                                },
                                {
                                    key: 'Latitude',
                                    label: 'Latitude',
                                },
                                {
                                    key: 'Longitude',
                                    label: 'Longitude',
                                },
                                {
                                    key: 'SecurityGuarantee',
                                    label: 'Security Guarantee',
                                },
                                {
                                    key: 'SecurityDeposit',
                                    label: 'Security Deposit',
                                },
                                {
                                    key: 'CurrentResident',
                                    label: 'Current Resident',
                                },
                                {
                                    key: 'DefaultReader',
                                    label: 'Default Reader',
                                },
                                {
                                    key: 'CreatedOn',
                                    label: 'Created On',
                                },
                                {
                                    key: 'UpdatedOn',
                                    label: 'Updated On',
                                },
                            ]}
                            filename={`${csvName}_tansformers.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoadingTransformers: state.transformer.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(TransformerList);

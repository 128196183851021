import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import '../../../../style/transformer/ConfirmCapturing.scss';
import { ITransformerInvoice } from '../../../../@types/model/transformer/transformerInvoice';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { createSelector } from 'reselect';

interface ITariffConfirmTableProps {
    invoices : Array<ITransformerInvoice>;
    group : string;

    onConfirmClick ?: () => void;
    onCancelClick ?: () => void;
    onRowClick ?: (epNumber : string) => void;

    disabledTransformers : Array<string>;
}

interface ITariffConfirmTableState {
    isLoading : boolean;
    confirm : boolean;
}

export default class TariffConfirmTable extends React.PureComponent<ITariffConfirmTableProps, ITariffConfirmTableState> {
    constructor(props : ITariffConfirmTableProps) {
        super(props);
        this.state = {
            isLoading: false,
            confirm: false,
        };
    }

    public readonly onConfirmChange = () => {
        this.setState({
            confirm: !this.state.confirm,
        });
    };

    public readonly onConfirmClick = () => {
        if (!this.state.confirm) return;
        if (!this.props.onConfirmClick) return;
        this.props.onConfirmClick();
    };

    public readonly onRowClick = (event : React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (this.props.onRowClick) this.props.onRowClick(event.currentTarget.id);
    };

    public readonly getInvoices = (state : ITariffConfirmTableState, props : ITariffConfirmTableProps) => props.invoices;
    public readonly getFiltredInvoices = createSelector(
        [this.getInvoices],
        (invoices) => {
            return invoices
                .filter(n => !!n.paymentDueDate);
        },
    );

    public readonly render = () => {
        const { group, onCancelClick, disabledTransformers } = this.props;
        const { isLoading, confirm } = this.state;

        const invoices = this.getFiltredInvoices(this.state, this.props);
        return (
            <div className='fdc flx1'>
                <div className='fdc flx1'>
                    <Paper className='oya'>
                        <Toolbar>
                            <Typography className='fs18 fwm cp'>
                                Process Amounts Payable
                            </Typography>
                            <span className='flx1' />
                            <Typography className='fs18 fwm cp'>
                                Group: {group}
                            </Typography>
                        </Toolbar>
                        <Table className='tariff-confirm-table'>
                            <TableHead>
                                <TableRow className='header-row'>
                                    <TableCell className='waypoint'>
                                        Way Point
                                    </TableCell>
                                    <TableCell className='pole'>
                                        Pole No.
                                    </TableCell>
                                    <TableCell className='account'>
                                        Acc. No
                                    </TableCell>
                                    <TableCell align='right' className='total-usage'>
                                        Total Unit Usage
                                    </TableCell>
                                    <TableCell align='right' className=''>
                                        Days
                                    </TableCell>
                                    <TableCell align='right' className=''>
                                        Due Date
                                    </TableCell>
                                    <TableCell align='right' className=''>
                                        Credit
                                    </TableCell>
                                    <TableCell align='right' className=''>
                                        Total Charge
                                    </TableCell>
                                    <TableCell align='right'className=''>
                                        VAT
                                    </TableCell>
                                    <TableCell align='right' className='total-due'>
                                        Total Due
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    invoices
                                        .filter(x => !disabledTransformers.includes(x.epNumber))
                                        .map((invoice, index) => (
                                            <TableRow id={invoice.epNumber} key={`value_${invoice.epNumber}_${index}`} className='data-row curp' onClick={this.onRowClick}>
                                                <TableCell className='waypoint'>
                                                    {invoice.epNumber}
                                                </TableCell>
                                                <TableCell className='pole'>
                                                    {invoice.poleNumber}
                                                </TableCell>
                                                <TableCell className='account'>
                                                    {invoice.accountNumber}
                                                </TableCell>
                                                <TableCell className='total-usage' align='right'>
                                                    {invoice.totalUsage}
                                                </TableCell>
                                                <TableCell className='days' align='right'>
                                                    {invoice.days}
                                                </TableCell>
                                                <TableCell className='days' align='right'>
                                                    {!invoice.paymentDueDate ? '' : moment.utc(invoice.paymentDueDate, 'YYYY/MM/DD').format('YYYY/MM/DD')}
                                                </TableCell>
                                                <TableCell className='days' align='right'>
                                                    {invoice.credit.toFixed(2)}
                                                </TableCell>
                                                <TableCell className='totalCharge' align='right'>
                                                    {invoice.totalCharge.toFixed(2)}
                                                </TableCell>
                                                <TableCell className='vatCharge' align='right'>
                                                    {invoice.vatCharge.toFixed(2)}
                                                </TableCell>
                                                <TableCell className='total-due' align='right'>
                                                    {invoice.totalBilled.toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                }
                            </TableBody>
                        </Table>
                    </Paper>
                </div>
                <div className='fdr aic pl15'>
                    <Checkbox 
                        style={{
                            transform: 'scale(1.5)',
                        }}
                        onChange={this.onConfirmChange}
                        checked={confirm} size='medium' color='primary' />
                    <span
                        style={{
                            marginLeft: '10px',
                            color: '#424242',
                            fontFamily: 'Roboto',
                            fontSize: '15px',
                            fontWeight: 500,
                            lineHeight: '15px',
                            letterSpacing: '0.3px',
                        }}
                    >I, hereby declare that all above information is correct and all amounts can be processed</span>
                </div>
                <div className='fdr jcfe aife mt15'>
                    <OutlinedStadiumButton className='mr16' variant='text' disabled={isLoading} onClick={onCancelClick}>
                            CANCEL
                    </OutlinedStadiumButton>
                    <StadiumButton disabled={isLoading || !confirm} onClick={this.onConfirmClick}>
                            CAPTURE
                    </StadiumButton>
                </div>
            </div>
        );
    };
}

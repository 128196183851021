import React from 'react';
import { createSelector } from 'reselect';
import { ITransformerInvoice } from '../../../@types/model/transformer/transformerInvoice';
import { CURRENCY_FORMAT } from '../../../appConstants';
import TransformerJournalHelper, { ITransformerJournalSplit } from '../../../@types/model/transformer/journal';
import MaterialTable from '../materialTable/Table';
import { IMunicipalityInvoice } from '../../../@types/model/transformer/municipalityInvoice';
import { ISPSInvoice } from '../../../@types/model/transformer/spsInvoice';

interface ITransformerTariffSplitTableProps {
    invoices : Array<ITransformerInvoice | IMunicipalityInvoice | ISPSInvoice>;
}

interface ITransformerTariffSplitTableState {}

export default class TransformerTariffSplitTable extends React.PureComponent<ITransformerTariffSplitTableProps, ITransformerTariffSplitTableState> {
    

    constructor(props : ITransformerTariffSplitTableProps) {
        super(props);
        this.state = {};
    }

    private readonly getInvoices = (props : ITransformerTariffSplitTableProps) => props.invoices;

    private readonly getCalculatedLines = createSelector(
        [this.getInvoices],
        (invoices) => {
            return TransformerJournalHelper.getSplitLines(invoices);
        },
    );

    public readonly render = () => {
        const lines = this.getCalculatedLines(this.props);

        return (
            <MaterialTable<ITransformerJournalSplit>
                id='transformerJournalSplitTable'
                data={lines}
                rowsPerPage={50}
                externalSort
                columns={[{
                    header: 'ACCNUMBER',
                    field: 'accountNumber',
                    width: 145,
                }, {
                    header: 'Trans Amt',
                    field: 'amount',
                    width: 145,
                    renderCell: row => (
                        <div className=''>
                            {CURRENCY_FORMAT.format(row.amount)}
                        </div>
                    ),
                }, {
                    header: 'TRANSDATE',
                    field: 'date',
                    width: 145,
                }, {
                    header: 'ACCTID',
                    field: 'accpacAccountNumber',
                    width: 145,
                }, {
                    header: 'TRANSDESC',
                    field: 'description',
                    width: 145,
                }, {
                    header: 'SUBAREADESCR',
                    field: 'subArea',
                    width: 145,
                }, {
                    header: 'ALLOCATORDESCR',
                    field: 'group',
                    width: 145,
                }, {
                    header: 'AREADESCR',
                    field: 'area',
                    width: 145,
                }, {
                    header: 'WAYPOINT',
                    field: 'waypoint',
                    width: 145,
                }]}
            />
        );
    };
}

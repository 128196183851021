import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import BoreholeConstantRateTestHelper, { BoreholeConstantRateDrumStage, BoreholeConstantRateDrumStageType, BoreholeConstantRateNeighbouringStage, BoreholeConstantRateNeighbouringStageType, BoreholeConstantRateTime, BoreholeConstantRateTimesType, IBoreholeConstantRateDrumEntry, IBoreholeConstantRateRecoveryTimeEntry, IBoreholeConstantRateTest, IBoreholeConstantRateTestFormValues, IBoreholeConstantRateTestNeighbour, IBoreholeConstantRateTimeEntry } from '../../../../@types/model/borehole/boreholeConstantRateTest';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../../@types/employee';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import firebaseApp from '../../../../services/firebaseService';
import GeneralFunctions from '../../../../store/general/functions';
import { EnumFileUploadQueueType } from '../../../../@types/model/files/enum';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { CircularProgress, Tab, Tabs } from '@material-ui/core';
import TabViews from '../../../customComponents/tab/TabViews';
import Dropzone from 'react-dropzone';
import FileEmbedView from '../../../customComponents/embed/FileEmbedView';
import FormikForm from '../../../customComponents/form/FormikForm';
import BoreholeConstantRateEditDialogLogisticsTab from './tabs/CharacteristicsTab';
import BoreholeConstantRateEditDialogNeighbouringTab from './tabs/NeighbouringTab';
import BoreholeConstantRateEditDialogDrumTab from './tabs/DrumTab';
import BoreholeConstantRateEditDialogTestTab from './tabs/TestTab';
import firebase from 'firebase/app';
import moment from 'moment';

interface IEditBoreholeConstantRateTestProps {
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;


    boreholeConstantRateTest ?: IBoreholeConstantRateTest;

    session ?: IUserSession | null;
    borehole ?: IBorehole;
}

interface IEditBoreholeConstantRateTestState {
    isLoading : boolean;
    open : boolean;

    file : File | null;

    tabIndex : number;

    initialValues : IBoreholeConstantRateTestFormValues | null;
}

class EditBoreholeConstantRateTestComponent extends React.Component<IEditBoreholeConstantRateTestProps, IEditBoreholeConstantRateTestState> {
    constructor(props : IEditBoreholeConstantRateTestProps) {
        super(props);
        this.state = {
            isLoading: false,
            open: false,
            file: null,
            tabIndex: 0,
            initialValues: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeConstantRateTestProps, prevState : IEditBoreholeConstantRateTestState) => {
        if (!prevState.open && this.state.open) {
            this.setState({
                isLoading: false,
                file: null,
                tabIndex: 0,
                initialValues: BoreholeConstantRateTestHelper.initialFormValues(this.props.boreholeConstantRateTest),
            });
        }

    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly onSubmit = async (formValues : IBoreholeConstantRateTestFormValues) => {
        const { boreholeConstantRateTest } = this.props;
        const { 
            file,
        } = this.state;

        const ref = BoreholeConstantRateTestHelper.doc(boreholeConstantRateTest?.id);

        try {
            this.setState({
                isLoading: true,
            });

            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { session, borehole } = this.props;
        
                if (!session) return;
                if (!borehole) return;
                if (!file && !boreholeConstantRateTest) return;
    
                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);
    
                const data = doc.data();
    
                transaction.set(ref, {
                    date: formValues.date.valueOf(),
                    documentName: file?.name ?? data?.documentName ?? null,
                    documentURL: !file ? (data?.documentURL ?? null) : '',
                    elevation: formValues.elevation ?? 0,
                    geo: [
                        formValues.latitude ?? 0,
                        formValues.longitude ?? 0,
                    ],
                    employeeName: formValues.employeeName ?? '',
                    employeeNumber: formValues.employeeNumber ?? '',
                    guid: data?.guid ?? formValues.guid,
                    comment: formValues.comment,
                    createdBy: data?.createdBy ?? session.firebaseUser.uid,
                    createdByName: data?.createdByName ?? session.employee.Name,
                    createdByEmployee: data?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
                    createdOn: data?.createdOn ?? firebase.firestore.Timestamp.now().toMillis(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: firebase.firestore.Timestamp.now().toMillis(),
                    isSent: false,
                    isActive: data?.isActive ?? true,
                    isWeb: data?.isWeb ?? true,
                    boreholeRef: borehole.ref.id,
                    dewateringYield: formValues.dewateringYield ?? null,
                    installedPump: formValues.installedPump ?? 0,
                    pumpDepth: formValues.pumpDepth ?? 0,
                    levelLoggerDate: formValues.levelLoggerDate?.valueOf() ?? null,
                    levelLoggerDepth: formValues.levelLoggerDepth,
                    levelLoggerTime: !formValues.levelLoggerTime ? null : moment.utc(formValues.levelLoggerTime).format('HH:mm'),
                    levelLoggerOutDate: formValues.levelLoggerOutDate?.valueOf() ?? null,
                    levelLoggerOutTime: !formValues.levelLoggerOutTime ? null : moment.utc(formValues.levelLoggerOutTime).format('HH:mm'),
                    levelLoggerPumpOffDate: formValues.levelLoggerPumpOffDate?.valueOf() ?? null,
                    levelLoggerPumpOffTime: !formValues.levelLoggerPumpOffTime ? null : moment.utc(formValues.levelLoggerPumpOffTime).format('HH:mm'),
                    levelLoggerPumpOnDate: formValues.levelLoggerPumpOnDate?.valueOf() ?? null,
                    levelLoggerPumpOnTime: !formValues.levelLoggerPumpOnTime ? null : moment.utc(formValues.levelLoggerPumpOnTime).format('HH:mm'),
                    recommendedRate: formValues.recommendedRate ?? 0,
                    nextBorehole: formValues.nextBorehole ?? null,
                    staticWaterLevel: formValues.staticWaterLevel ?? 0,
                    neighbouringBoreholes: formValues.neighbouringBoreholes.map(value => BoreholeConstantRateNeighbouringStage.reduce((current, stage) => {

                        current[stage] = {
                            borehole: value[stage].borehole ?? '',
                            time: !value[stage].time ? null : moment.utc(value[stage].time).format('HH:mm'),
                            waterLevel: value[stage].waterLevel ?? null,
                        };
            
                        return current;
                    }, {} as Record<BoreholeConstantRateNeighbouringStageType, IBoreholeConstantRateTestNeighbour>)),
                    drumEntries: BoreholeConstantRateDrumStage.reduce((current, stage) => {
                        const value = formValues.drumEntries[stage];
                        current[stage] = {
                            time: !value.time ? null : moment.utc(value.time).format('HH:mm'),
                            rate: value.rate ?? null,
                            fillTime: value.fillTime ?? null,
                            capacity: value.capacity ?? null,
                        };
            
                        return current;
                    }, {} as Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateDrumEntry>),
                    recoveryEntries: BoreholeConstantRateTime.reduce((current, time) => {
                        const value = formValues.recoveryEntries[time];
                        current[time] = {
                            time,
                            waterLevel: value.waterLevel ?? null,
                        };
            
                        return current;
                    }, {} as Record<BoreholeConstantRateTimesType, IBoreholeConstantRateRecoveryTimeEntry>),
                    entries: BoreholeConstantRateTime.reduce((current, time) => {
                        const value = formValues.entries[time];
                        current[time] = {
                            time,
                            waterLevel: value.waterLevel ?? null,
                            rate: value.rate ?? null,
                        };
            
                        return current;
                    }, {} as Record<BoreholeConstantRateTimesType, IBoreholeConstantRateTimeEntry>),
                }, {
                    merge: true,
                });

                if (file) {
                    await BoreholeConstantRateTestHelper.uploadFile(
                        data?.boreholeRef ?? borehole.Code,
                        file,
                        {
                            collection: 'borehole_constant_rate',
                            fieldName: 'documentURL',
                            fileType: EnumFileUploadQueueType.BoreholeConstantRateTest.toString(),
                            refGuid: formValues.guid,
                            thumbnailFieldName: '',
                        }
                    );
                }
            });

            this.setState({
                open: false,
            });
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving constant rate.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
        
    };

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.borehole) return;
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onFileDrop = (acceptedFiles : Array<File>) => {
        if (!acceptedFiles.length) return;

        this.setState({
            file: new File([acceptedFiles[0].slice()], `${acceptedFiles[0].name}`, {
                type: acceptedFiles[0].type,
            }),
        });
    };

    private readonly onNextClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex + 1,
        });
    };

    private readonly onBackClick = () => {
        this.setState({
            tabIndex: this.state.tabIndex - 1,
        });
    };
    

    public readonly render = () => {
        const {
            transition,
            boreholeConstantRateTest,
        } = this.props;

        const {
            open,
            isLoading,
            tabIndex,
            file,
            initialValues,
        } = this.state;
        
        return (
            <React.Fragment>
                <Tooltip title={boreholeConstantRateTest ? 'Edit' : 'Upload'}>
                    <div>
                        {
                            !!boreholeConstantRateTest &&
                            <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Edit' disabled={isLoading}>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                        {
                            !boreholeConstantRateTest &&
                            <StadiumButton style={{
                                height: 42,
                                borderRadius: 40,
                            }} className='jcc aic p0' color='primary' onClick={this.onClick} aria-label='Upload Video'>
                                <Icon className='cp ml10'>upload</Icon>
                                <Typography className='fw600 fs16 cp ml20 mr10'>DOCUMENT UPLOADS</Typography>
                            </StadiumButton>
                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    fullScreen
                    fullWidth
                    aria-labelledby='borehole-calibration-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            {
                                !!boreholeConstantRateTest &&
                                <Typography variant='h5' color='inherit'>
                                    Edit ConstantRateTest - {AppFunctionsService.formatDateTimeToDatePicker(boreholeConstantRateTest.date)}
                                </Typography>
                            }
                            {
                                !boreholeConstantRateTest &&
                                <Typography variant='h5' color='inherit'>
                                    Add ConstantRateTest
                                </Typography>
                            }
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    {
                        initialValues &&
                        <FormikForm
                            onSubmit={this.onSubmit}
                            className='fdc hfill bcg0'
                            validateOnMount
                            initialValues={initialValues}
                            validationSchema={BoreholeConstantRateTestHelper.formSchema()}
                            enableReinitialize
                        >
                            {
                                (props) => (
                                    <>
                                        <DialogContent className='fdc hfill p0'>
                                            {
                                                isLoading &&
                                                <div className='fdc flx1 aic jcc'>
                                                    <CircularProgress />
                                                </div>
                                            }
                                            {
                                                !isLoading &&
                                                <div className='fdr'>
                                                    <div className='fdc w804'>
                                                        <div className={'jcfs aic wfill dvbg h50'}>
                                                            <Typography className={'ml15 fs14 fw500 cw '}>
                                                                PDF DOCUMENT
                                                            </Typography>
                                                            <span className='flx1' />
                                                        </div>
                                                        <div className='fdc h1132 wfill'>
                                                            <Dropzone onDrop={this.onFileDrop} accept={'application/pdf,image/*'}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <section className='fdc flx1'>
                                                                        <div className='fdc flx1' {...getRootProps()}>
                                                                            <input disabled={isLoading} multiple={false} {...getInputProps()} />
                                                                            
                                                                            {
                                                                                !file &&
                                                                                <div className='fdr flx1 jcc aic'>
                                                                                    <Typography className='cg0' variant='body2'>
                                                                                        DRAG &amp; DROP FILE
                                                                                    </Typography>
                                                                                </div>
                                                                            }
                                                                            
                                                                            {
                                                                                file &&
                                                                                <FileEmbedView file={file} />
                                                                            }
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                    <div className='fdc flx1'>
                                                        <Tabs
                                                            value={tabIndex}
                                                            onChange={this.onTabChange}
                                                            aria-label='Info Tabs'
                                                            className={'h50'}
                                                            variant={'fullWidth'}
                                                            indicatorColor={'primary'}
                                                        >
                                                            <Tab label='TEST CHARACTERISTICS' value={0} className={'cdg'} fullWidth/>
                                                            <Tab label='DRUM YIELD TEST' value={1} className={'cdg'} fullWidth/>
                                                            <Tab label='NEIGHBOURHOOD BOREHOLES' value={2} className={'cdg'} fullWidth/>
                                                            <Tab label='CONSTANT TEST' value={3} className={'cdg'} fullWidth/>
                                                        </Tabs>
                                                        <div className={'fdc flx1 hfill mb15'}>
                                                            <TabViews index={tabIndex}>
                                                                <BoreholeConstantRateEditDialogLogisticsTab
                                                                    isLoading={isLoading}
                                                                    onNextClick={this.onNextClick}
                                                                    onCancelClick={this.onClose}
                                                                />
                                                                <BoreholeConstantRateEditDialogDrumTab
                                                                    isLoading={isLoading}
                                                                    onNextClick={this.onNextClick}
                                                                    onBackClick={this.onBackClick}
                                                                />
                                                                <BoreholeConstantRateEditDialogNeighbouringTab
                                                                    isLoading={isLoading}
                                                                    onNextClick={this.onNextClick}
                                                                    onBackClick={this.onBackClick}
                                                                />
                                                                <BoreholeConstantRateEditDialogTestTab
                                                                    isLoading={isLoading}
                                                                    onSubmitClick={props.submitForm}
                                                                    onBackClick={this.onBackClick}
                                                                    isValid={props.isValid}
                                                                />
                                                            </TabViews>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </DialogContent>
                                    </>
                                )
                            }
                        </FormikForm>
                    }
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        borehole: state.borehole.borehole,
    };
};

const EditBoreholeConstantRateTest = connect(
    mapStateToProps,
)(EditBoreholeConstantRateTestComponent);

export default EditBoreholeConstantRateTest;
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import BoreholeAgesRecommendationHelper, { IBoreholeAgesRecommendation } from '../../../../@types/model/borehole/boreholeAgesRecommendation';
import { IGroundWaterZone } from '../../../../@types/model';
import moment, { Moment } from 'moment';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { Fab, FormControl, FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { IRootState } from '../../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../../@types/employee';
import BoreholeGroundwaterZoneSelector from '../../../customComponents/selector/borehole/GroundWaterZoneSelector';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DATE_FORMAT_DEFAULT_NO_TIME } from '../../../../appConstants';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import firebaseApp from '../../../../services/firebaseService';
import uuid from 'uuid';
import GeneralFunctions from '../../../../store/general/functions';
import { EnumFileUploadQueueType } from '../../../../@types/model/files/enum';

interface IEditBoreholeAgesRecommendationProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;


    boreholeAgesRecommendation ?: IBoreholeAgesRecommendation;

    session ?: IUserSession | null;
    borehole ?: IBorehole;
}

interface IEditBoreholeAgesRecommendationState {
    isLoading : boolean;
    open : boolean;

    date : Moment;
    designedYield : number | null;
    perfomanceYield : number | null;
    pumpingCycle : number | null;
    maximumDailyAbstraction : number | null;
    installDepthRecommendation : number | null;
    holeDepth : number | null;
    dynamicWaterLevelLimit : number | null;
    staticWaterLevel : number | null;
    criticalWaterLevel : number | null;
    groundwaterZone : IGroundWaterZone | null;

    yieldTestAnalysis : string;
    comment : string;
    guid : string;

    file : File | null;
}

class EditBoreholeAgesRecommendationComponent extends React.Component<IEditBoreholeAgesRecommendationProps, IEditBoreholeAgesRecommendationState> {
    constructor(props : IEditBoreholeAgesRecommendationProps) {
        super(props);
        this.state = {
            isLoading: false,
            open: false,
            designedYield: null,
            perfomanceYield: null,
            pumpingCycle: null,
            maximumDailyAbstraction: null,
            installDepthRecommendation: null,
            holeDepth: null,
            dynamicWaterLevelLimit: null,
            staticWaterLevel: null,
            criticalWaterLevel: null,
            groundwaterZone: null,
            yieldTestAnalysis: '',
            comment: '',
            file: null,
            date: moment.utc().startOf('day'),
            guid: '',
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditBoreholeAgesRecommendationProps, prevState : IEditBoreholeAgesRecommendationState) => {
        if (!prevState.open && this.state.open) {
            const { boreholeAgesRecommendation } = this.props;
            this.setState({
                isLoading: false,
                designedYield: boreholeAgesRecommendation?.designedYield ?? null,
                perfomanceYield: boreholeAgesRecommendation?.perfomanceYield ?? null,
                pumpingCycle: boreholeAgesRecommendation?.pumpingCycle ?? null,
                maximumDailyAbstraction: boreholeAgesRecommendation?.maximumDailyAbstraction ?? null,
                installDepthRecommendation: boreholeAgesRecommendation?.installDepthRecommendation ?? null,
                holeDepth: boreholeAgesRecommendation?.holeDepth ?? null,
                dynamicWaterLevelLimit: boreholeAgesRecommendation?.dynamicWaterLevelLimit ?? null,
                staticWaterLevel: boreholeAgesRecommendation?.staticWaterLevel ?? null,
                criticalWaterLevel: boreholeAgesRecommendation?.criticalWaterLevel ?? null,
                groundwaterZone: boreholeAgesRecommendation?.groundwaterZone ?? null,
                comment: boreholeAgesRecommendation?.comment ?? '',
                yieldTestAnalysis: boreholeAgesRecommendation?.yieldTestAnalysis ?? '',
                guid: boreholeAgesRecommendation?.guid ?? uuid.v4(),
                file: null,
                date: !boreholeAgesRecommendation?.date ? moment.utc().startOf('day') : moment.utc(boreholeAgesRecommendation.date),
            });
        }

    };

    private readonly save = async () => {
        const { boreholeAgesRecommendation } = this.props;
        const { 
            designedYield,
            perfomanceYield,
            pumpingCycle,
            maximumDailyAbstraction,
            holeDepth,
            installDepthRecommendation,
            dynamicWaterLevelLimit,
            staticWaterLevel,
            criticalWaterLevel,
            groundwaterZone,
            date,
            comment,
            yieldTestAnalysis,
            guid,
            file,
        } = this.state;

        const ref = BoreholeAgesRecommendationHelper.doc(boreholeAgesRecommendation?.id);

        if (typeof designedYield === 'object') return;
        if (typeof perfomanceYield === 'object') return;
        if (typeof pumpingCycle === 'object') return;
        if (typeof maximumDailyAbstraction === 'object') return;
        if (typeof holeDepth === 'object') return;
        if (typeof installDepthRecommendation === 'object') return;
        if (typeof dynamicWaterLevelLimit === 'object') return;
        if (typeof staticWaterLevel === 'object') return;
        if (typeof criticalWaterLevel === 'object') return;

        try {
            this.setState({
                isLoading: true,
            });

            await firebaseApp.firestore().runTransaction(async (transaction) => {
                const { session, borehole } = this.props;
        
                if (!session) return;
                if (!borehole) return;
                if (!file && !boreholeAgesRecommendation) return;
    
                /**
                 * Have to get docs first.
                 */
                const doc = await transaction.get(ref);
    
                const data = doc.data();
    
                transaction.set(ref, {
                    borehole: data?.borehole ?? borehole.ref.id,
                    boreholeCode: data?.boreholeCode ?? borehole.Code,
                    date: date.valueOf(),
                    criticalWaterLevel: criticalWaterLevel,
                    designedYield: designedYield,
                    dynamicWaterLevelLimit: dynamicWaterLevelLimit,
                    groundwaterZone: groundwaterZone,
                    holeDepth: holeDepth,
                    installDepthRecommendation: installDepthRecommendation,
                    maximumDailyAbstraction: maximumDailyAbstraction,
                    perfomanceYield: perfomanceYield,
                    pumpingCycle: pumpingCycle,
                    staticWaterLevel: staticWaterLevel,
                    fileName: data?.fileName ?? file?.name ?? '',
                    guid,
                    yieldTestAnalysis,
                    comment,
                    id: boreholeAgesRecommendation?.id ?? '',
                    createdBy: data?.createdBy ?? session.firebaseUser.uid,
                    createdByName: data?.createdByName ?? session.employee.Name,
                    createdByEmployee: data?.createdByEmployee ?? session.employee.EmployeeNumber ?? '',
                    createdOn: data?.createdOn ?? moment.utc().valueOf(),
                    updatedBy: session.firebaseUser.uid,
                    updatedByName: session.employee.Name,
                    updatedByEmployee: session.employee.EmployeeNumber ?? '',
                    updatedOn: moment.utc().valueOf(),
                    isSent: false,
                    isActive: data?.isActive ?? true,
                    isWeb: data?.isWeb ?? true,
                }, {
                    merge: true,
                });
    
                if (file) {
                    await BoreholeAgesRecommendationHelper.uploadFile(
                        data?.boreholeCode ?? borehole.Code,
                        file,
                        {
                            collection: 'borehole_ages_recommendation',
                            fieldName: 'url',
                            fileType: EnumFileUploadQueueType.BoreholeAgesRecommendation.toString(),
                            refGuid: guid,
                            thumbnailFieldName: '',
                        }
                    );
                }
            });

            this.setState({
                open: false,
            });
            GeneralFunctions.generalShowSuccessSnackbar('Success');
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'Error saving recommendation.');
        } finally {
            this.setState({
                isLoading: false,
            });
        }
        
    };

    private readonly onSubmit = (event : React.FormEvent) => {
        event.preventDefault();
        this.save();
    };

    private readonly onClick = () => {
        if (!this.props.borehole) return;
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onDesignedYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            designedYield: Number(event.currentTarget.value),
        });
    };

    private readonly onPerfomanceYieldChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            perfomanceYield: Number(event.currentTarget.value),
        });
    };

    private readonly onPumpingCycleChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            pumpingCycle: Number(event.currentTarget.value),
        });
    };

    private readonly onMaximumDailyAbstractionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            maximumDailyAbstraction: Number(event.currentTarget.value),
        });
    };

    private readonly onInstallDepthRecommendationChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            installDepthRecommendation: Number(event.currentTarget.value),
        });
    };

    private readonly onHoleDepthChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            holeDepth: Number(event.currentTarget.value),
        });
    };

    private readonly onDynamicWaterLevelLimitChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            dynamicWaterLevelLimit: Number(event.currentTarget.value),
        });
    };

    private readonly onStaticWaterLevelChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            staticWaterLevel: Number(event.currentTarget.value),
        });
    };

    private readonly onCriticalWaterLevelChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;
        this.setState({
            criticalWaterLevel: Number(event.currentTarget.value),
        });
    };

    private readonly onYieldTestAnalysisChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            yieldTestAnalysis: event.currentTarget.value,
        });
    };

    private readonly onCommentChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            comment: event.currentTarget.value,
        });
    };

    private readonly onGroundwaterZoneChange = (groundwaterZone : IGroundWaterZone | null) => {
        this.setState({
            groundwaterZone,
        });
    };

    private readonly onDateChange = (date : moment.Moment | null) => {
        if (!date) return;
        this.setState({
            date: date.startOf('day').asUTC(),
        });
    };

    private onFileChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files?.length) return;
        const file = event.currentTarget.files[0];
        
        this.setState({
            file: new File([file.slice()], `${file.name}`, {
                type: file.type,
            }),
        });
    };

    public readonly render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            boreholeAgesRecommendation,
        } = this.props;
        
        const { open, isLoading,
            designedYield,
            perfomanceYield,
            pumpingCycle,
            maximumDailyAbstraction,
            holeDepth,
            installDepthRecommendation,
            dynamicWaterLevelLimit,
            staticWaterLevel,
            criticalWaterLevel,
            groundwaterZone,
            date,
            comment,
            yieldTestAnalysis,
        } = this.state;
        return (
            <React.Fragment>
                <Tooltip title={boreholeAgesRecommendation ? 'Edit' : 'Add'}>
                    <div>
                        {
                            !!boreholeAgesRecommendation &&
                            <IconButton className='cw' size='small' onClick={this.onClick} aria-label='Edit' disabled={isLoading}>
                                <Icon>edit</Icon>
                            </IconButton>
                        }
                        {
                            !boreholeAgesRecommendation &&
                            <Fab aria-label='Add' onClick={this.onClick} className='fab add cp bcy '>
                                <Icon>add</Icon>
                            </Fab>
                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='borehole-info-edit-dialog-title'
                    aria-describedby='borehole-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            {
                                !!boreholeAgesRecommendation &&
                                <Typography variant='h5' color='inherit'>
                                    Edit Recommendation - {AppFunctionsService.formatDateTimeToDatePicker(boreholeAgesRecommendation.date)}
                                </Typography>
                            }
                            {
                                !boreholeAgesRecommendation &&
                                <Typography variant='h5' color='inherit'>
                                    Add Recommendation
                                </Typography>
                            }
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill oya'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 mt15 pr20'}>
                                    <KeyboardDatePicker
                                        value={date}
                                        label='Date'
                                        format={DATE_FORMAT_DEFAULT_NO_TIME}
                                        fullWidth
                                        onChange={this.onDateChange}
                                        required
                                        disabled={isLoading}
                                    />
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <BoreholeGroundwaterZoneSelector
                                        disabled={isLoading}
                                        required
                                        value={groundwaterZone}
                                        onChange={this.onGroundwaterZoneChange}
                                    />
                                </div>
                                <div className={'flx1 ais p5 mb10 mt15 pr20'}>
                                    {
                                        !boreholeAgesRecommendation?.url &&
                                        <input 
                                            disabled={isLoading}
                                            multiple={false}
                                            onChange={this.onFileChange}
                                            type='file'
                                            accept='application/pdf,image/*'
                                        />
                                    }
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl required fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            type='number'
                                            autoFocus
                                            autoComplete='off'
                                            id='designedYield'
                                            label='Rec. Abstraction Rate'
                                            value={designedYield ?? ''}
                                            onChange={this.onDesignedYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!designedYield}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/hr
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !designedYield &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl required fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            type='number'
                                            autoComplete='off'
                                            id='perfomanceYield'
                                            label='Rec. Max. Abtraction Rate'
                                            value={perfomanceYield ?? ''}
                                            onChange={this.onPerfomanceYieldChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!perfomanceYield}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/hr
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !perfomanceYield &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                    
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            type='number'
                                            disabled={isLoading}
                                            autoComplete='off'
                                            id='pumpingCycle'
                                            label='Pumping Cycle'
                                            value={pumpingCycle ?? ''}
                                            onChange={this.onPumpingCycleChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!pumpingCycle}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        hr/day
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !pumpingCycle &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl required fullWidth>
                                        <TextField
                                            type='number'
                                            disabled={isLoading}
                                            autoComplete='off'
                                            id='maximumDailyAbstraction'
                                            label='Rec. D. Max. Abtraction Rate'
                                            value={maximumDailyAbstraction ?? ''}
                                            onChange={this.onMaximumDailyAbstractionChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!maximumDailyAbstraction}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        m<sup>3</sup>/day
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !maximumDailyAbstraction &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl required fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            type='number'
                                            autoComplete='off'
                                            id='holeDepth'
                                            label='Hole Depth'
                                            value={holeDepth ?? ''}
                                            onChange={this.onHoleDepthChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!holeDepth}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        mdbl
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !holeDepth &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl required fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            type='number'
                                            autoComplete='off'
                                            id='installDepthRecommendation'
                                            label='Install Depth Recommendation'
                                            value={installDepthRecommendation ?? ''}
                                            onChange={this.onInstallDepthRecommendationChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!installDepthRecommendation}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        mdbl
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !installDepthRecommendation &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl required fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            type='number'
                                            autoComplete='off'
                                            id='dynamicWaterLevelLimit'
                                            label='Dynamic Water Level'
                                            value={dynamicWaterLevelLimit ?? ''}
                                            onChange={this.onDynamicWaterLevelLimitChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!dynamicWaterLevelLimit}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        mdbl
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !dynamicWaterLevelLimit &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl required fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            type='number'
                                            autoComplete='off'
                                            id='staticWaterLevel'
                                            label='Static Water Level'
                                            value={staticWaterLevel ?? ''}
                                            onChange={this.onStaticWaterLevelChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!staticWaterLevel}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        mdbl
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !staticWaterLevel &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl required fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            type='number'
                                            autoComplete='off'
                                            id='criticalWaterLevel'
                                            label='Critical Water Level'
                                            value={criticalWaterLevel ?? ''}
                                            onChange={this.onCriticalWaterLevelChange}
                                            margin='normal'
                                            className={'TextField'}
                                            error={!criticalWaterLevel}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        mdbl
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {
                                            !criticalWaterLevel &&
                                            <FormHelperText error>Required</FormHelperText>
                                        }
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            autoComplete='off'
                                            id='yieldTestAnalysis'
                                            label='Yield Test Analysis'
                                            value={yieldTestAnalysis}
                                            onChange={this.onYieldTestAnalysisChange}
                                            margin='normal'
                                            className={'TextField'}
                                            multiline
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={isLoading}
                                            autoComplete='off'
                                            id='comment'
                                            label='Comment'
                                            value={comment}
                                            onChange={this.onCommentChange}
                                            margin='normal'
                                            className={'TextField'}
                                            multiline
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary'>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        session: state.auth.session,
        borehole: state.borehole.borehole,
    };
};

const EditBoreholeAgesRecommendation = connect(
    mapStateToProps,
)(EditBoreholeAgesRecommendationComponent);

export default EditBoreholeAgesRecommendation;
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import MottechFilterPopper from '../popper/MottechfilterPoper';

interface IMottechFilterButtonProps {
    id ?: string;
    open : boolean;

    onClick ?: () => void;

    code : string | null;
    name : string | null;
    division : string | null;
    managementArea : string | null;
    onChange ?: (code : string | null, name : string | null, division : string | null, managementArea : string | null) => void;

    disabled ?: boolean;
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
}

interface IMottechFilterButtonState {
}

export default class MottechFilterButton extends React.PureComponent<IMottechFilterButtonProps, IMottechFilterButtonState> {
    private filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : IMottechFilterButtonProps) {
        super(props);
        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    public readonly render = () => {
        const {
            id,
            onChange,
            code,
            name,
            division,
            managementArea,
            disabled,
            onClick,
            open,
            color,
        } = this.props;
        
        return (
            <>
                <Tooltip title='Filter'>
                    <div>
                        <IconButton
                            ref={this.filterRef}
                            aria-label='toggle filter menu'
                            onClick={onClick}
                            disabled={disabled}
                            color={color}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <MottechFilterPopper
                    id={id}
                    anchorEl={this.filterRef.current}
                    open={open}
                    onCancelClick={onClick}
                    onChange={onChange}
                    code={code}
                    name={name}
                    division={division}
                    managementArea={managementArea}
                />
            </>
        );
    };
}

import React from 'react';
import { createSelector } from 'reselect';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import lodash from 'lodash';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IRootState } from '../../../../@types/redux';
import { ITransformerGroup } from '../../../../@types/model/transformer/transformerGroup';
import DataFunctions from '../../../../store/data/functions';

interface ITransformerGroupGroupDropdownProps {
    id ?: string;
    value ?: string | null;

    onChange : (value ?: ITransformerGroup) => void;

    required ?: boolean;
    autoFocus ?: boolean;

    fullWidth ?: boolean;

    groups : Array<ITransformerGroup>;
    isLoading : boolean;

    label ?: string;
    type ?: 'ALL' | string;

    disabled ?: boolean;
    paymentOnly ?: boolean;
}

interface ITransformerGroupGroupDropdownState {
}

class TransformerGroupDropdownComponent extends React.Component<ITransformerGroupGroupDropdownProps, ITransformerGroupGroupDropdownState> {
    constructor(props : ITransformerGroupGroupDropdownProps) {
        super(props);

        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        DataFunctions.getTransformerGroups();
    };

    private readonly onChange = (event : React.ChangeEvent<unknown>, value ?: {
        label : string;
        value : string;
    } | null) => {
        if (typeof value === 'string') {
            const transformer = this.props.groups.find(x => x.name === value);
            this.props.onChange(transformer);
        } else {
            const transformer = this.props.groups.find(x => x.name === value?.value);
            this.props.onChange(transformer);
        }
    };

    private readonly getData = (state : ITransformerGroupGroupDropdownState, props : ITransformerGroupGroupDropdownProps) => props.groups;
    private readonly getValue = (state : ITransformerGroupGroupDropdownState, props : ITransformerGroupGroupDropdownProps) => props.value;
    private readonly getType = (state : ITransformerGroupGroupDropdownState, props : ITransformerGroupGroupDropdownProps) => props.type;
    private readonly getPaymentOnly = (state : ITransformerGroupGroupDropdownState, props : ITransformerGroupGroupDropdownProps) => props.paymentOnly;
    private readonly getRequired = (state : ITransformerGroupGroupDropdownState, props : ITransformerGroupGroupDropdownProps) => props.required;

    private readonly getGroups = createSelector([
        this.getData, this.getType, this.getPaymentOnly,
    ], (groups, type, paymentOnly) => {
        if (type == 'ALL') return lodash
            .chain(groups)
            .filter(n => n.isActive)
            .map(n => n.name)
            .sort()
            .value();

        return lodash
            .chain(groups)
            .filter(n => n.isActive)
            .filter(n => !type || n.type === type)
            .filter(n => !paymentOnly || !!n.paymentEnabled)
            .map(n => n.name)
            .sort()
            .value();
    });

    private readonly getTransformerGroupDropdown = createSelector([
        this.getRequired,
        this.getGroups,
    ], (required, groups) => {
        const groupsDrop = lodash.map(groups, (n) => ({ label: n, value: n }));

        if (!required) {
            groupsDrop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return groupsDrop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getGroups,
    ], (value, required, groups) => {
        if (value) {
            const group = groups.find(x => x === value);

            if (group) {
                return { label: group, value: group };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, fullWidth, isLoading, id, label = 'Group', disabled } = this.props;

        const groupsDrop = this.getTransformerGroupDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);
        return (
            <FormControl fullWidth={fullWidth} error={required && !value} required={required} disabled={disabled}>
                <Autocomplete
                    id={id}
                    disabled={disabled}
                    options={groupsDrop}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} disabled={disabled} label={label} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                <div className='wfill' style={{
                    minHeight: 8,
                }}>
                    {
                        isLoading &&
                    <LinearProgress />
                    }
                </div>
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        groups: state.data.transformerGroups,
        isLoading: state.data.isLoadingTransformerGroups,
    };
};

const TransformerGroupDropdown = connect(
    mapStateToProps,
)(TransformerGroupDropdownComponent);

export default TransformerGroupDropdown;

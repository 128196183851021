import React from 'react';
import style from '../../../style/pdfStyleSheet';
import { Document, Font, Page, View, Text } from '@react-pdf/renderer';
import lodash from 'lodash';
import moment from 'moment';
import TransformerSPUMeterReportHeaderPdf from './SPUMeterReportHeaderPdf';
import { IUserSession } from '../../../@types/employee';
import { ITransformer, ITransformerMeter } from '../../../@types/model/transformer/transformer';
import TransformerSPUMeterReportTableHeaderPdf from './SPUMeterReportTableHeaderPdf';
import { createSelector } from 'reselect';
import { IMeterReadingsView } from '../../../@types/model/transformer/transformerReading';

interface ITransformerSPUMeterReportPdfProps {
    session ?: IUserSession | null;
    transformers : Array<ITransformer>;
    area : string;
    readings ?: Array<IMeterReadingsView>;
}

interface ITransformerSPUMeterReportPdfState {}

export default class TransformerSPUMeterReportPdfComponent extends React.PureComponent<ITransformerSPUMeterReportPdfProps, ITransformerSPUMeterReportPdfState> {
    constructor(props : ITransformerSPUMeterReportPdfProps) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {
        Font.register({
            family: 'Montserrat',
            src: '/assets/fonts/Montserrat-Regular.ttf',
            format: 'truetype',
            fonts: [
                { src: '/assets/fonts/Montserrat-Regular.ttf' },
                { src: '/assets/fonts/Montserrat-Medium.ttf', fontWeight: 500 },
                { src: '/assets/fonts/Montserrat-SemiBold.ttf', fontWeight: 600 },
                { src: '/assets/fonts/Montserrat-Bold.ttf', fontWeight: 700 },
                { src: '/assets/fonts/Montserrat-ExtraBold.ttf', fontWeight: 800 },
            ],
        });
    }

    private getLastReading(n : ITransformer & ITransformerMeter, readings ?: Array<IMeterReadingsView>) {
        if (!readings?.length) return '-';

        const sortedReadings = lodash.chain(readings)
            .filter(x => x.EPNumber === n.EPNumber)
            .sort((a, b) => (b.Date) - (a.Date))
            .value();

        if (!sortedReadings.length) return '-';

        const reading = sortedReadings[0];

        return reading.Rollover ?
            reading.Reading - reading.Usage + Number(new Array(reading.NumberOfDigits).fill('9').join('')) :
            reading.Reading - reading.Usage;
    }

    private readonly getTransformers = (props : ITransformerSPUMeterReportPdfProps) => props.transformers;
    private readonly getReadings = (props : ITransformerSPUMeterReportPdfProps) => props.readings;

    public readonly getLines = createSelector(
        [this.getTransformers, this.getReadings],
        (transformers, readings) => {
            return lodash.chain(transformers)
                .flatMap(transformer => transformer.TransformerMeterNumbers?.map(meter => ({
                    ...transformer,
                    ...meter,
                })) ?? [])
                .map(n => ({
                    number: n.EPNumber,
                    description: n.SubArea,
                    poleNumber: n.PoleNumber ?? '',
                    meterType: n.MeterType ?? '',
                    kva: n.TransformerRating ? `${n.TransformerRating} kVA` : '-',
                    meterNumber: n.Number,
                    meterNumberOfDigits: n.NumberOfDigits,
                    lastReading: this.getLastReading(n, readings),
                    transformerReaderEmployeeNr: `${n.TransformerReaderEmployeeNr ?? '-'}`,
                    transformerReaderEmployeeName: `${n.TransformerReaderEmployeeName ?? '-'}`,
                }))
                .value();
        },
    );

    public readonly getGroupedLines = createSelector(
        [this.getLines],
        (lines) => {
            return lodash.groupBy(lines, n => `${n.transformerReaderEmployeeNr} - ${n.transformerReaderEmployeeName}`);
        },
    );

    public readonly render = () => {
        const { session, area } = this.props;

        const groupedLines = this.getGroupedLines(this.props);
        return (
            <Document title={`Meter_Readings_${moment().format('YYYY-MM-DD')}.pdf`}>
                {
                    lodash.map(groupedLines, (lines, employee) => 
                        <Page
                            key={employee}
                            orientation='landscape'
                            size='A4'
                            style={[
                                style.fdc,
                                style.jcfs,
                                style.wfill,
                                style.body,
                            ]}
                            wrap>
                            <TransformerSPUMeterReportHeaderPdf area={area} session={session} employee={employee} />
                            <TransformerSPUMeterReportTableHeaderPdf />
                            {
                                lines.map((line, i) => (
                                    <View key={`${line.number}_${line.meterNumber}`}
                                        style={[style.fdr, style.ais, style.h28, style.page, i % 2 === 0 ? style.bcw : style.bcg1, style.bbw0]}>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.h28, style.pl9, style.w50]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}>{line.number}</Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.fww, style.pl9, style.h28, style.w80, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}>{line.description}</Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.fww, style.pl9, style.h28, style.w90, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}>{line.poleNumber}</Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.h28, style.pl9, style.w50, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}>{line.meterType}</Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.h28, style.pl9, style.w50, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}>{line.kva}</Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.pl9, style.h28, style.w90, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}>{line.meterNumber}</Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.h28, style.pl9, style.w50, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}>{line.meterNumberOfDigits}</Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.h28, style.pl9, style.w50, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}>{line.lastReading}</Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.h28, style.pl9, style.w150, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}></Text>
                                        </View>
                                        <View style={[style.fdc, style.aifs, style.jcc, style.h28, style.pl9, style.flx1, style.blw1, style.bbw0, style.bocg2]}>
                                            <Text style={[style.fs6, style.fwm, style.cg3]}></Text>
                                        </View>
                                    </View>
                                ))
                            }
                        </Page>
                    )
                }
                
            </Document>
        );
    };
}
import { dispatch, getState } from '..';
import { boreholeSetLoading, boreholeSetBoreholes, boreholeSetBorehole, boreholeSetTestLoading, boreholeSetBoreholeTests, boreholeSetAuditLoading, boreholeSetBoreholeAudits } from './actions';
import generalFunctions from '../general/functions';
import firebase from 'firebase/app';
import firebaseApp from '../../services/firebaseService';
import lodash from 'lodash';
import uuid from 'uuid';
import BoreholeHelper, { IBorehole } from '../../@types/model/borehole/borehole';
import BoreholeTestHelper, { IBoreholeTest } from '../../@types/model/borehole/boreholeTest';
import BoreholeInstallationAuditHelper from '../../@types/model/borehole/boreholeInstallationAudit';
import BoreholeCalibrationHelper, { IBoreholeCalibration } from '../../@types/model/borehole/boreholeCalibration';
import { EnumFileUploadQueueType } from '../../@types/model/files/enum';
import BoreholeVideoHelper from '../../@types/model/borehole/boreholeVideo';

export default class BoreholeFunctions {
    private static boreholeListener : undefined | (() => void);
    private static boreholeTestListener : undefined | (() => void);
    private static boreholeAuditListener : undefined | (() => void);

    private static readonly setBoreholes = (boreholes : Array<IBorehole>) => {
        dispatch(boreholeSetBoreholes(boreholes));
    };

    public static readonly getBoreholes = async (onLoaded ?: () => void) => {
        if (BoreholeFunctions.boreholeListener) {
            if (onLoaded) onLoaded();
            return;
        }

        BoreholeFunctions.setLoading(true);
        BoreholeFunctions.setBoreholes([]);
        try {
            BoreholeFunctions.boreholeListener = BoreholeHelper.listen().onSnapshot((snapshot) => {
                const boreholes = getState().borehole.boreholes.slice();
                const selectedBorehole = getState().borehole.borehole;
                
                const session = getState().auth.session;
                if (!session) return;

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const borehole = f.doc.data();
                    if (
                        !session.employee.IsBoreholeAdmin &&
                        borehole.Division &&
                        session.employee.Areas.length &&
                        (!session.employee.Areas.includes(borehole.Division) && !session.employee.Areas.includes(borehole.ManagementArea ?? ''))) return;

                    const index = lodash.findIndex(boreholes, n => n.Code === borehole.Code);

                    switch (f.type) {
                        case 'added':
                            boreholes.push(borehole);
                            break;
                        case 'modified':
                            boreholes.splice(index, 1, borehole);
                            break;
                        case 'removed':
                            boreholes.splice(index, 1);
                            break;
                    }
                });

                BoreholeFunctions.setBoreholes(boreholes);

                if (selectedBorehole) {
                    BoreholeFunctions.setBorehole(boreholes.find(n => n.Code === selectedBorehole.Code), false);
                }

                BoreholeFunctions.setLoading(false);
                if (onLoaded) onLoaded();
            });
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading boreholes.');
            BoreholeFunctions.setLoading(false);
        }
    };

    public static readonly setLoading = (loading : boolean) => {
        dispatch(boreholeSetLoading(loading));
    };

    public static readonly setBorehole = (borehole ?: IBorehole, loadChildren = true) => {
        dispatch(boreholeSetBorehole(borehole));

        if (loadChildren) {
            BoreholeFunctions.getBoreholeTests(borehole);
            BoreholeFunctions.getBoreholeAudits(borehole);
        }
    };

    public static readonly deactivateBorehole = async (id : string) => {
        const auth = getState().auth;

        if (!auth.session || !auth.session.employee.IsBoreholeAdmin) return;
        BoreholeFunctions.setLoading(true);

        try {
            await BoreholeHelper.delete(id, auth.session);

            BoreholeFunctions.setBorehole();
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while deactivating borehole.');
        } finally {
            BoreholeFunctions.setLoading(false);
        }

    };

    /** Save's borehole.
     * Sets Borehole Loading on Borehole State. Sets IsSent, UpdatedOn and UpdatedBy.
     * Returns true if success false if failure.
     */
    public static readonly saveBorehole = async (save : IBorehole) => {

        const session = getState().auth.session;

        if (!session) return;

        BoreholeFunctions.setLoading(true);

        try {
            const borehole = {
                ...save,
            };

            borehole.IsSent = false;
            borehole.UpdatedOn = firebase.firestore.Timestamp.now();
            borehole.UpdatedBy = firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid);
            borehole.UpdatedByEmployee = session.employee.EmployeeNumber ?? session.employee.Email ?? '';
            borehole.UpdatedByName = session.firebaseUser.displayName ?? session.employee.Name;
            
            await BoreholeHelper.update(borehole);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving borehole. ${ex}`);
            throw ex;
        } finally {
            BoreholeFunctions.setLoading(false);
        }

    };

    /** Batch save's boreholes.
     * Sets Borehole Loading on Borehole State. Sets IsSent, UpdatedOn and UpdatedBy.
     * Returns true if success false if failure.
     */
    public static readonly saveBoreholes = async (boreholes : Array<IBorehole>) => {

        const session = getState().auth.session;

        if (!session) return;

        BoreholeFunctions.setLoading(true);

        const final = boreholes.map((borehole) => {
            borehole.IsSent = false;
            borehole.UpdatedOn = firebase.firestore.Timestamp.now();
            borehole.UpdatedBy = firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid);
            borehole.UpdatedByEmployee = session.employee.EmployeeNumber ?? session.employee.Email ?? '';
            borehole.UpdatedByName = session.firebaseUser.displayName ?? session.employee.Name;
            return borehole;
        });

        let result = false;
        try {
            await BoreholeHelper.batchUpdate(final);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving boreholes. ${ex}`);
        } finally {
            BoreholeFunctions.setLoading(false);
        }
        return result;

    };

    public static readonly createBorehole = async (name : string, code : string) => {
        dispatch(boreholeSetLoading(true));

        const session = getState().auth.session;

        if (!session) return;
        const borehole : IBorehole = {
            ref: BoreholeHelper.doc(code),
            GroundwaterZone: null,
            Status: '2',
            GUID: uuid.v4(),
            Code: code,
            Name: name,
            EPNumber: null,
            AdjustedYield: null,
            Geom: new firebase.firestore.GeoPoint(-1, -1),
            Elevation: 0,
            BoreholeImageFileName: null,
            BoreholeImageUrl: null,
            BoreholeImageThumbnailUrl: null,
            ElectricalBoxImageFileName: null,
            ElectricalBoxImageUrl: null,
            ElectricalBoxImageThumbnailUrl: null,
            PipeLength: null,
            Pump: null,
            PumpType: null,
            PumpSerial: null,
            PumpImageUrl: null,
            PumpImageFileName: null,
            PumpImageThumbnailUrl: null,
            Starter: false,
            StarterType: null,
            RisingMain: null,
            RisingMainType: null,
            uPVCColumn: null,
            uPVCColumnType: null,
            LevelCheck: false,
            User: null,
            CableCores: null,
            CableLength: null,
            CableSize: null,
            CasingDiameter: null,
            CasingHeight: null,
            Comment: '',
            ConcreteBlockHeight: null,
            DesignDate: null,
            DesignedYield: null,
            Division: null,
            DivisionDescriptionAccpac: null,
            DivisionNumber: null,
            ManagementArea: null,
            HoleDepth: null,
            InstallationDepth: null,
            PerfomanceTest: null,
            InstalledYield: null,
            PiezometerTubeHeight: null,
            MotorSize: null,
            MotorType: null,
            MotorPhase: null,
            MotorDiameter: null,
            MotorSerial: null,
            MotorImageFileName: null,
            MotorImageThumbnailUrl: null,
            MotorImageUrl: null,
            IsSent: false,
            CreatedOn: firebase.firestore.Timestamp.now(),
            CreatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
            CreatedByEmployee: session.employee.EmployeeNumber ?? session.employee.Email ?? '',
            CreatedByName: session.firebaseUser.displayName ?? session.employee.Name,
            UpdatedOn: firebase.firestore.Timestamp.now(),
            UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
            UpdatedByEmployee: session.employee.EmployeeNumber ?? session.employee.Email ?? '',
            UpdatedByName: session.firebaseUser.displayName ?? session.employee.Name,
            IsActive: true,
        };

        let result = false;
        try {
            await BoreholeHelper.create(borehole);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while creating borehole. ${ex}`);
        }
        dispatch(boreholeSetLoading(false));

        if (result) BoreholeFunctions.setBorehole(borehole);
        return result;
    };

    public static readonly getBoreholeTests = async (borehole ?: IBorehole) => {
        if (!borehole) {
            dispatch(boreholeSetTestLoading(false));
            if (BoreholeFunctions.boreholeTestListener) {
                BoreholeFunctions.boreholeTestListener();
            }
            dispatch(boreholeSetBoreholeTests([]));
            return;
        }

        dispatch(boreholeSetTestLoading(true));

        try {
            BoreholeFunctions.boreholeTestListener = BoreholeTestHelper
                .listen(borehole.Code)
                .orderBy('CreatedOn', 'desc')
                .onSnapshot((snapshot) => {
                    const tests = getState().borehole.boreholeTests.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const test = f.doc.data();

                        const index = lodash.findIndex(tests, n => n.ref.id === test.ref.id);

                        switch (f.type) {
                            case 'added':
                                tests.push(test);
                                break;
                            case 'modified':
                                tests.splice(index, 1, test);
                                break;
                            case 'removed':
                                tests.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(boreholeSetBoreholeTests(tests));

                    dispatch(boreholeSetTestLoading(false));
                });
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading borehole tests.');
            dispatch(boreholeSetTestLoading(false));
        }
    };

    public static readonly deleteBoreholeTest = async (id : string) => {
        const session = getState().auth.session;

        if (!session || !session.employee.IsBoreholeAdmin) return;

        dispatch(boreholeSetTestLoading(true));

        try {
            await BoreholeTestHelper
                .delete(id);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while delete borehole test.');
        } finally {
            dispatch(boreholeSetTestLoading(false));
        }

    };

    /** Save's borehole.
     * Sets Borehole Test Loading on Borehole State. Sets IsSent, UpdatedOn and UpdatedBy.
     * Returns true if success, false if failure.
     */
    public static readonly saveBoreholeTest = async (test : IBoreholeTest) => {

        const session = getState().auth.session;

        if (!session) return;
        dispatch(boreholeSetTestLoading(true));

        const { ...boreholeTest } = test;
        boreholeTest.Sent = false;
        boreholeTest.UpdatedOn = firebase.firestore.Timestamp.now();
        boreholeTest.UpdatedBy = firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid);
        boreholeTest.UpdatedByEmployee = session.employee.EmployeeNumber ?? session.employee.Email ?? '';
        boreholeTest.UpdatedByName = session.firebaseUser.displayName ?? session.employee.Name;

        let result = false;
        try {
            await BoreholeTestHelper.update(boreholeTest);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving borehole test. ${ex}`);
        } finally {
            dispatch(boreholeSetTestLoading(false));
        }
        return result;

    };

    public static readonly getBoreholeAudits = async (borehole ?: IBorehole) => {
        if (!borehole) {
            dispatch(boreholeSetAuditLoading(false));
            if (BoreholeFunctions.boreholeAuditListener) {
                BoreholeFunctions.boreholeAuditListener();
            }
            dispatch(boreholeSetBoreholeAudits([]));
            return;
        }
        dispatch(boreholeSetAuditLoading(true));

        try {
            BoreholeFunctions.boreholeAuditListener = BoreholeInstallationAuditHelper
                .listen(borehole.Code)
                .orderBy('CreatedOn', 'desc')
                .onSnapshot((snapshot) => {
                    const audits = getState().borehole.boreholeAudits.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const audit = f.doc.data();

                        const index = lodash.findIndex(audits, n => n.ref.id === audit.ref.id);

                        switch (f.type) {
                            case 'added':
                                audits.push(audit);
                                break;
                            case 'modified':
                                audits.splice(index, 1, audit);
                                break;
                            case 'removed':
                                audits.splice(index, 1);
                                break;
                        }
                    });

                    dispatch(boreholeSetBoreholeAudits(audits));

                    dispatch(boreholeSetAuditLoading(false));
                });
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while loading borehole audits. ${ex}`);
            dispatch(boreholeSetAuditLoading(false));
        }
    };

    public static readonly deleteBoreholeAudit = async (id : string) => {
        const session = getState().auth.session;

        if (!session || !session.employee.IsBoreholeAdmin) return;

        dispatch(boreholeSetAuditLoading(true));

        try {
            await BoreholeInstallationAuditHelper
                .delete(id);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while delete borehole audit.');
        } finally {
            dispatch(boreholeSetAuditLoading(false));
        }

    };

    /** Save's borehole calibration.
     * Sets Borehole calibration Loading on Borehole State. Sets IsSent, UpdatedOn and UpdatedBy.
     * Returns true if success, false if failure.
     */
    public static readonly saveBoreholeCalibration = async (calabaration : IBoreholeCalibration) => {

        const session = getState().auth.session;

        if (!session || !session.employee.IsBoreholeAdmin) return;

        const { ...boreholeCalibration } = calabaration;
        boreholeCalibration.IsSent = false;
        boreholeCalibration.UpdatedOn = firebase.firestore.Timestamp.now();
        boreholeCalibration.UpdatedBy = firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid);
        boreholeCalibration.UpdatedByEmployee = session.employee.EmployeeNumber ?? session.employee.Email ?? '';
        boreholeCalibration.UpdatedByName = session.firebaseUser.displayName ?? session.employee.Name;

        let result = false;
        try {
            await BoreholeCalibrationHelper
                .update(boreholeCalibration);
            result = true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving borehole calibration. ${ex}`);
        }

        return result;

    };

    public static readonly deleteBoreholeCalibration = async (id : string) => {
        const session = getState().auth.session;
        if (!session || !session.employee.IsBoreholeAdmin) return;

        try {
            await BoreholeCalibrationHelper
                .delete(id);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while delete borehole calibration.');
        }
    };

    /**
     * Uploads file to Firebase Selected Borehole's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     * @param errorCallback callback on error
     */
    public static readonly uploadSelectedBoreholeBoreholeImage = (file : File, progressCallback : undefined | ((progress : number) => void)) => {
        return new Promise<void>((res, rej) => {
            const boreholeState = getState().borehole;

            if (!boreholeState.borehole) {
                rej('No selected borehole');
                return;
            }

            BoreholeHelper.uploadImage(boreholeState.borehole, file, {
                collection: BoreholeHelper.COLLECTION_NAME,
                fieldName: 'BoreholeImageUrl',
                thumbnailFieldName: 'BoreholeImageThumbnailUrl',
                refGuid: boreholeState.borehole.GUID,
                fileType: EnumFileUploadQueueType.Borehole.toString(),
            }, (progress) => {
                if (progressCallback) progressCallback(progress);

                if (progress >= 100) {
                    res();
                }

            }, (err) => {
                rej(err);
            });
        });
    };

    /**
     * Uploads file to Firebase Selected Borehole's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     */
    public static readonly uploadSelectedBoreholeEPImage = async (file : File, progressCallback ?: (progress : number) => void) => {
        return new Promise<void>((res, rej) => {
            const boreholeState = getState().borehole;

            if (!boreholeState.borehole) {
                rej('No selected borehole');
                return;
            }

            BoreholeHelper.uploadImage(boreholeState.borehole, file, {
                collection: BoreholeHelper.COLLECTION_NAME,
                fieldName: 'ElectricalBoxImageUrl',
                thumbnailFieldName: 'ElectricalBoxImageThumbnailUrl',
                refGuid: boreholeState.borehole.GUID,
                fileType: EnumFileUploadQueueType.Borehole.toString(),
            }, (progress) => {
                if (progressCallback) progressCallback(progress);

                if (progress >= 100) {
                    res();
                }

            }, (err) => {
                rej(err);
            });
        });
    };

    /**
     * Uploads file to Firebase Selected Borehole's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     */
    public static readonly uploadSelectedBoreholePumpImage = async (file : File, progressCallback ?: (progress : number) => void) => {
        return new Promise<void>((res, rej) => {
            const boreholeState = getState().borehole;

            if (!boreholeState.borehole) {
                rej('No selected borehole');
                return;
            }

            BoreholeHelper.uploadImage(boreholeState.borehole, file, {
                collection: BoreholeHelper.COLLECTION_NAME,
                fieldName: 'PumpImageUrl',
                thumbnailFieldName: 'PumpImageThumbnailUrl',
                refGuid: boreholeState.borehole.GUID,
                fileType: EnumFileUploadQueueType.Borehole.toString(),
            }, (progress) => {
                if (progressCallback) progressCallback(progress);

                if (progress >= 100) {
                    res();
                }

            }, (err) => {
                rej(err);
            });
        });
    };

    /**
     * Uploads file to Firebase Selected Borehole's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     */
    public static readonly uploadSelectedBoreholeMotorImage = async (file : File, progressCallback ?: (progress : number) => void) => {
        return new Promise<void>((res, rej) => {
            const boreholeState = getState().borehole;

            if (!boreholeState.borehole) {
                rej('No selected borehole');
                return;
            }

            BoreholeHelper.uploadImage(boreholeState.borehole, file, {
                collection: BoreholeHelper.COLLECTION_NAME,
                fieldName: 'MotorImageUrl',
                thumbnailFieldName: 'MotorImageThumbnailUrl',
                refGuid: boreholeState.borehole.GUID,
                fileType: EnumFileUploadQueueType.Borehole.toString(),
            }, (progress) => {
                if (progressCallback) progressCallback(progress);

                if (progress >= 100) {
                    res();
                }

            }, (err) => {
                rej(err);
            });
        });
    };

    /**
     * Uploads file to Firebase Selected Borehole's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     */
    public static readonly uploadSelectedBoreholeVideo = (file : File) => {
        const boreholeState = getState().borehole;
        const uid = getState().auth.session?.employee.Id;

        if (!boreholeState.borehole) {
            throw new Error('No selected borehole');
        }

        if (!uid) {
            throw new Error('Auth unknown');
        }

        return BoreholeVideoHelper.uploadVideo(boreholeState.borehole.Code, file, {
            collection: BoreholeVideoHelper.COLLECTION_NAME,
            fieldName: '',
            thumbnailFieldName: '',
            refGuid: boreholeState.borehole.GUID,
            fileType: EnumFileUploadQueueType.BoreholeVideo.toString(),
            uid,
        });
    };
}

import { Typography, Card } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IFlowmeter } from '../../../@types/model/flowmeter/flowmeter';
import { IRootState } from '../../../@types/redux';
import TransformerFunctions from '../../../store/transformer/functions';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditFlowmeterSummary from '../dialog/EditSummaryInfo';

interface IFlowmeterSummaryProps {
    flowmeter : IFlowmeter;
    isLoading : boolean;
    
    isFlowmeterAdmin : boolean;
}

interface IFlowmeterSummaryState {
}

class FlowmeterSummary extends React.PureComponent<IFlowmeterSummaryProps, IFlowmeterSummaryState> {
    constructor(props : IFlowmeterSummaryProps) {
        super(props);
        this.state = {
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        TransformerFunctions.getList();
    };

    public readonly render = () => {
        const { flowmeter, isFlowmeterAdmin, isLoading } = this.props;
        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr flx1 bcp cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            FLOWMETER SUMMARY
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isFlowmeterAdmin &&
                                    <EditFlowmeterSummary
                                        flowmeter={flowmeter}
                                        isLoading={isLoading}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                    />
                                }
                                
                            </div>
                        </div>
                    </div>
                    <div className={'fdc flx1 ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc flx1 aic jcc mr4 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    CODE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.code}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc ml4 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    NAME
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {flowmeter.name}
                                </Typography>
                            </div>
                        </div>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'fdr flx2 mr4'}>
                                <div className={'bcg0 fdc aic jcc mr4 w200 p10'}>
                                    <Typography className={'fs11 cdg aic jcc pl25 pr25 tac'}>
                                        CONNECTED TO A MONITORING SYSTEM?
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {flowmeter.connected ? 'YES' : 'NO'}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc aic jcc ml4 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        MONITORING
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {!flowmeter.monitor ? '-' : flowmeter.monitor}
                                    </Typography>
                                </div>
                            </div>
                            <div className={'fdr flx2 ml4'}>
                                <div className={'bcg0 fdc aic jcc mr4 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        WATER USE TYPE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {!flowmeter.waterUsageTypeDescription ? '-' : flowmeter.waterUsageTypeDescription}
                                    </Typography>
                                </div>
                                <div className={'bcg0 fdc aic jcc ml4 w200 p10'}>
                                    <Typography className={'fs11 cdg'}>
                                        MONITORING TYPE
                                    </Typography>
                                    <Typography className={'fs13 cg3 fw700 pt10'}>
                                        {!flowmeter.monitorTypeDescription ? '-' : flowmeter.monitorTypeDescription}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'fdr flx1 dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            FLOWMETER SUMMARY
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>                                
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.flowmeter.loading,
        isFlowmeterAdmin: !!state.auth.session?.employee.IsFlowmeterAdmin,
    };
};

export default connect(
    mapStateToProps,
)(FlowmeterSummary);

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import StandardFab from '../../../customComponents/button/StandardFab';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import { IDivision } from '../../../../@types/model/masterData/division';
import PrimaryTabs from '../../../customComponents/tab/PrimaryTabs';
import PrimaryTab from '../../../customComponents/tab/PrimaryTab';
import TabViews from '../../../customComponents/tab/TabViews';
import { Divider, FormControl, FormHelperText, InputAdornment, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core';
import DataFunctions from '../../../../store/data/functions';

interface IDivisionEditDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    division ?: IDivision;

    isLoading : boolean;
}

interface IDivisionEditDialogState {
    open : boolean;
    tabIndex : number;

    name : string;
    divisionNo : string | null;
    accpacDesc : string | null;
    isActive : boolean;

    lineAdd : string;
    lines : Array<string>;

    managementAreaAdd : string;
    managementAreas : Array<string>;
}

export default class DivisionEditDialog extends React.PureComponent<IDivisionEditDialogProps, IDivisionEditDialogState> {
    constructor(props : IDivisionEditDialogProps) {
        super(props);
        this.state = {
            open: false,
            tabIndex: 0,
            name: '',
            isActive: true,
            divisionNo: null,
            accpacDesc: null,
            lineAdd: '',
            lines: [],
            managementAreaAdd: '',
            managementAreas: [],
        };
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly onClick = () => {
        this.setState({
            open: true,
            tabIndex: 0,
        });

        if (this.props.division) {
            this.setState({
                name: this.props.division.Name,
                isActive: this.props.division.IsActive,
                divisionNo: this.props.division.DivisionNo,
                accpacDesc: this.props.division.AccpacDesc,
                lines: this.props.division.Line,
                managementAreas: this.props.division.ManagementArea,
            });
        }
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
            name: '',
            isActive: true,
            divisionNo: null,
            accpacDesc: null,
            lines: [],
            managementAreas: [],
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.save();
    };

    private readonly save = async () => {
        const { division } = this.props;

        const {
            name,
            accpacDesc,
            divisionNo,
            lines,
            managementAreas,
        } = this.state;

        await DataFunctions.saveDivision({
            ...division,
            Name: name,
            AccpacDesc: accpacDesc,
            DivisionNo: divisionNo,
            Line: lines,
            ManagementArea: managementAreas,
            Id: name,
        } as IDivision);

        this.onClose();
    };


    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onDivisionNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            divisionNo: event.currentTarget.value,
        });
    };

    private readonly onAccPacDescChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            accpacDesc: event.currentTarget.value,
        });
    };

    private readonly onLineAddChanged = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            lineAdd: event.currentTarget.value,
        });
    };

    private readonly onLineAddClick = () => {
        const { lineAdd, lines } = this.state;

        if (!lineAdd) return;
        if (lines.some(x => x.toLocaleLowerCase() === lineAdd.toLocaleLowerCase())) return;

        const newLines = lines.slice();
        newLines.splice(0, 0, lineAdd);

        this.setState({
            lines: newLines,
            lineAdd: '',
        });
    };

    private readonly onLineAddClearClick = () => {
        this.setState({
            lineAdd: '',
        });
    };

    private readonly onLineDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const lines = this.state.lines.slice();
        const index = Number(event.currentTarget.value);

        lines.splice(index, 1);

        this.setState({
            lines,
        });
    };

    private readonly onManagementAreaAddChanged = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            managementAreaAdd: event.currentTarget.value,
        });
    };

    private readonly onManagementAreaAddClick = () => {
        const { managementAreaAdd, managementAreas } = this.state;

        if (!managementAreaAdd) return;
        if (managementAreas.some(x => x.toLocaleLowerCase() === managementAreaAdd.toLocaleLowerCase())) return;

        const newManagementAreas = managementAreas.slice();
        newManagementAreas.splice(0, 0, managementAreaAdd);

        this.setState({
            managementAreas: newManagementAreas,
            managementAreaAdd: '',
        });
    };

    private readonly onManagementAreaAddClearClick = () => {
        this.setState({
            managementAreaAdd: '',
        });
    };

    private readonly onManagementAreaDeleteClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const managementAreas = this.state.managementAreas.slice();
        const index = Number(event.currentTarget.value);

        managementAreas.splice(index, 1);

        this.setState({
            managementAreas,
        });
    };

    public render = () => {
        const {
            transition,
            maxWidth,
            fullWidth,
            fullScreen,
            isLoading,
            division,
        } = this.props;
        const {
            open,
            tabIndex,
            name,
            accpacDesc,
            divisionNo,
            lineAdd,
            lines,
            managementAreas,
            managementAreaAdd,
        } = this.state;

        return (
            <>
                <Tooltip title={!division ? 'Add' : 'Edit'}>
                    <div>
                        {
                            !division &&
                            <StandardFab aria-label='add' disabled={isLoading} onClick={this.onClick}>
                                <AddIcon />
                            </StandardFab>

                        }
                        {
                            division &&
                            <IconButton color='primary' onClick={this.onClick}
                                aria-label='Edit' disabled={isLoading }>
                                <Icon>edit</Icon>
                            </IconButton>

                        }
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='Edit--Bank-dialog-title'
                    aria-describedby='Edit--Bank-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                {!division ? 'Create Division' : `Edit ${division.Name}`}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc'>
                        <AppBar className='posr' position='static' elevation={0}>
                            <PrimaryTabs
                                value={tabIndex}
                                onChange={this.onTabChange}
                                aria-label='Info Tabs'
                            >
                                <PrimaryTab label='INFO' />
                                <PrimaryTab label='LINES' />
                                <PrimaryTab label='MANAGEMENT AREA' />
                            </PrimaryTabs>
                        </AppBar>
                        <DialogContent className='fdc'>
                            <TabViews index={tabIndex}>
                                <div className={'fdc flx1'}>
                                    <div className={'fdr flx1 aifs mt30'}>
                                        <div className={'flx1 aifs pr10'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='name'
                                                    label='Name'
                                                    value={name}
                                                    onChange={this.onNameChange}
                                                    required
                                                    error={!name}
                                                />
                                                {
                                                    !name &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 aifs pr10 pl10'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='accPacDesc'
                                                    label='AccPac Description'
                                                    value={accpacDesc ?? ''}
                                                    onChange={this.onAccPacDescChange}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className={'flx1 aifs pl10'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete='off'
                                                    id='divisionNo'
                                                    label='Division Number'
                                                    type='number'
                                                    value={divisionNo ?? ''}
                                                    onChange={this.onDivisionNumberChange}
                                                />
                                                {
                                                    divisionNo && !Number(divisionNo) &&
                                                    <FormHelperText error>Invalid</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className={'fdc h400'}>
                                    <Toolbar className={'fdr aic jcc ais'}>
                                        <TextField
                                            fullWidth
                                            value={lineAdd}
                                            onChange={this.onLineAddChanged}
                                            placeholder='Add Line'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <Icon>search</Icon>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: lineAdd ? (
                                                    <Tooltip title='Clear'>
                                                        <IconButton size='small' onClick={this.onLineAddClearClick}>
                                                            <Icon className='cr'>close</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : null,
                                            }}
                                        />
                                        <Tooltip title='Add'>
                                            <div>
                                                <IconButton color='primary' onClick={this.onLineAddClick} aria-label='Add'>
                                                    <Icon>add</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Toolbar>
                                    <List className={'fdc flx1 drawer oys'}>
                                        {
                                            lines.map((n, i) => (
                                                <ListItem button key={i} id={n} >
                                                    <ListItemText
                                                        primary={n}
                                                    />
                                                    {
                                                        <ListItemSecondaryAction>
                                                            <IconButton className='cr' value={i} onClick={this.onLineDeleteClick} aria-label='Delete'>
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    }
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                    <Divider />
                                    <div className='fdr jcfe'>
                                        <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                                            Total: {lines.length}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={'fdc h400'}>
                                    <Toolbar className={'fdr aic jcc ais'}>
                                        <TextField
                                            fullWidth
                                            value={managementAreaAdd}
                                            onChange={this.onManagementAreaAddChanged}
                                            placeholder='Add Management Area'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <Icon>search</Icon>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: managementAreaAdd ? (
                                                    <Tooltip title='Clear'>
                                                        <IconButton size='small' onClick={this.onManagementAreaAddClearClick}>
                                                            <Icon className='cr'>close</Icon>
                                                        </IconButton>
                                                    </Tooltip>
                                                ) : null,
                                            }}
                                        />
                                        <Tooltip title='Add'>
                                            <div>
                                                <IconButton color='primary' onClick={this.onManagementAreaAddClick} aria-label='Add'>
                                                    <Icon>add</Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Toolbar>
                                    <List className={'fdc flx1 drawer oys'}>
                                        {
                                            managementAreas.map((n, i) => (
                                                <ListItem button key={i} id={n} >
                                                    <ListItemText
                                                        primary={n}
                                                    />
                                                    {
                                                        <ListItemSecondaryAction>
                                                            <IconButton className='cr' value={i} onClick={this.onManagementAreaDeleteClick} aria-label='Delete'>
                                                                <Icon>delete</Icon>
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    }
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                    <Divider />
                                    <div className='fdr jcfe'>
                                        <Typography variant={'subtitle2'} className={'fdr pl20 pr30 pb5 pt5'}>
                                            Total: {managementAreas.length}
                                        </Typography>
                                    </div>
                                    
                                </div>
                            </TabViews>
                        </DialogContent>
                        <DialogActions>
                            <OutlinedStadiumButton disabled={isLoading} className='mr16' variant='text' type='reset' onClick={this.onClose}>
                                CANCEL
                            </OutlinedStadiumButton>
                            <StadiumButton variant='contained' disabled={isLoading} type='submit'>
                                SAVE
                            </StadiumButton>
                        </DialogActions>
                    </form>
                </Dialog>
            </>
        );
    };
}

import React from 'react';
import { ITransformer, ITransformerCompany } from '../../../@types/model/transformer/transformer';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import transformerFunctions from '../../../store/transformer/functions';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DivisionAreaDropdown from '../../customComponents/selector/DivisionAreaSelector';
import TextField from '@material-ui/core/TextField';
import CompanySelector from '../../customComponents/selector/CompanySelector';

interface IEditTransformerDivisionInfoProps {
    transformer : ITransformer;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    isLoading : boolean;
}

interface IEditTransformerDivisionInfoState {
    open : boolean;
    division : string;
    subArea : string;
    cnc : string;
    company : ITransformerCompany | null;
}

export default class EditTransformerDivisionInfo extends React.Component<IEditTransformerDivisionInfoProps, IEditTransformerDivisionInfoState> {
    constructor(props : IEditTransformerDivisionInfoProps) {
        super(props);
        this.state = {
            open: false,
            division: '',
            subArea: '',
            cnc: '',
            company: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IEditTransformerDivisionInfoProps, prevState : IEditTransformerDivisionInfoState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                division: '',
                subArea: '',
                cnc: '',
                company: null,
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                division: !this.props.transformer.Division || this.props.transformer.Division === 'No Data' ? '' : this.props.transformer.Division,
                subArea: !this.props.transformer.SubArea ? '' : this.props.transformer.SubArea,
                cnc: !this.props.transformer.CNC ? '' : this.props.transformer.CNC,
                company: this.props.transformer.Company ?? null,
            });
        }
    };

    private readonly onDivisionChanged = (division ?: string) => {
        this.setState({
            division: division ?? '',
        });
    };

    private readonly onSubAreaChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            subArea: event.currentTarget.value,
        });
    };

    private readonly onCNCChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            cnc: event.currentTarget.value,
        });
    };

    private readonly save = async () => {
        const { division, subArea, cnc, company } = this.state;

        const transformer = Object.assign({}, this.props.transformer);

        transformer.Division = division;
        transformer.SubArea = subArea;
        transformer.CNC = cnc;
        transformer.Company = company;
        const result = await transformerFunctions.saveTransformer(transformer);

        if (result) {
            this.onClose();
        }
    };

    private readonly onSubmit = (event ?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        this.save();
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, division, cnc, subArea, company } = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='transformer-division-info-title'
                    aria-describedby='transformer-division-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Transformer Area Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Add'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Add'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 fdc ais pr20 mt21'}>
                                    <DivisionAreaDropdown
                                        value={division}
                                        label='Area'
                                        fullWidth
                                        required
                                        onChange={this.onDivisionChanged} />
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='subAra'
                                            label='Sub Area'
                                            value={subArea}
                                            onChange={this.onSubAreaChange}
                                            margin='normal'
                                            className={'TextField'}
                                            disabled={isLoading}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div className={'fdr flx1 aifs'}>
                                <div className={'flx1 ais p5 mb10 pr20'}>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete='off'
                                            id='cnc'
                                            label='CNC'
                                            value={cnc}
                                            onChange={this.onCNCChange}
                                            margin='normal'
                                            className={'TextField'}
                                            disabled={isLoading}
                                        />
                                    </FormControl>
                                </div>
                                <div className={'flx1 ais p5 mb10 pr20 mt16'}>
                                    <FormControl fullWidth>
                                        <CompanySelector
                                            value={company?.id}
                                            required
                                            disabled
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>);
    };
}

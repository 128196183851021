import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

interface IRisingMainNoOfSectionsInputProps {
    value : number;

    onChange : (value : string) => void;

    disabled ?: boolean;
}

interface IRisingMainNoOfSectionsInputState { }

export default class RisingMainNoOfSectionsInput extends React.Component<IRisingMainNoOfSectionsInputProps, IRisingMainNoOfSectionsInputState> {
    constructor(props : IRisingMainNoOfSectionsInputProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(event.target.value);
    };

    public readonly render = () => {
        const { value, disabled } = this.props;

        return (
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!value} htmlFor='RisingMainNoOfSections'>No. Of Sections</InputLabel>
                    <Input
                        onChange={this.onChange}
                        disabled={disabled}
                        value={!value ? '' : value}
                        fullWidth
                    />
                </FormControl>
            </div>
        );
    };
}

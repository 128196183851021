import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { IDamInspection } from '../../../../@types/model/dam/damInspection';
import DamInspectionLocationView from './LocationView';
import DamInspectionPhotoView from './PhotoView';
import DamHelper, { EnumDamSlopeSurfaceProtection } from '../../../../@types/model/dam/dam';

interface IDamInspectionUpstreamSlopeViewProps {
    inspection : IDamInspection;

    onImageClick ?: (event : React.MouseEvent<HTMLImageElement>) => void;
}

interface IDamInspectionUpstreamSlopeViewState {}

export default class DamInspectionUpstreamSlopeView extends React.PureComponent<IDamInspectionUpstreamSlopeViewProps, IDamInspectionUpstreamSlopeViewState> {
    constructor(props : IDamInspectionUpstreamSlopeViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { inspection, onImageClick } = this.props;
        
        return (
            <div className='fdr aifs jcc pt28 pb28'>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            DISTURBANCE
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.upstreamSlopeDisturbance &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.upstreamSlopeDisturbance &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.upstreamSlopeDisturbance}
                                </Typography>
                                {
                                    inspection.upstreamSlopeDisturbancePhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'upstreamSlopeDisturbancePhotoUrl'}
                                            photoThumbUrl={inspection.upstreamSlopeDisturbancePhotoThumbUrl}
                                            photoFileName={inspection.upstreamSlopeDisturbancePhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.upstreamSlopeDisturbanceLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='upstreamSlopeDisturbanceLocation'
                                            lat={inspection.upstreamSlopeDisturbanceLocation[0]}
                                            lng={inspection.upstreamSlopeDisturbanceLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            EROSION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.upstreamSlopeErosion &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.upstreamSlopeErosion &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.upstreamSlopeErosion}
                                </Typography>
                                {
                                    inspection.upstreamSlopeErosionPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'upstreamSlopeErosionPhotoUrl'}
                                            photoThumbUrl={inspection.upstreamSlopeErosionPhotoThumbUrl}
                                            photoFileName={inspection.upstreamSlopeErosionPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.upstreamSlopeErosionLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='upstreamSlopeErosionLocation'
                                            lat={inspection.upstreamSlopeErosionLocation[0]}
                                            lng={inspection.upstreamSlopeErosionLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            UNWANTED VEGETATION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            !inspection.upstreamSlopeUnwantedVegetation &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            !!inspection.upstreamSlopeUnwantedVegetation &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {inspection.upstreamSlopeUnwantedVegetation}
                                </Typography>
                                {
                                    inspection.upstreamSlopeUnwantedVegetationPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'upstreamSlopeUnwantedVegetationPhotoUrl'}
                                            photoThumbUrl={inspection.upstreamSlopeUnwantedVegetationPhotoThumbUrl}
                                            photoFileName={inspection.upstreamSlopeUnwantedVegetationPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
                <Card className={'bcg0 fdc mrnlc15'}>
                    <div className={'jcc aic wfill bcpl h50'}>
                        <Typography className={'fs14 fw500 cw '}>
                            SURFACE PROTECTION
                        </Typography>
                    </div>
                    <div className='fdc p15 ais jcc w200 flx1'>
                        {
                            inspection.upstreamSlopeSurfaceProtection == null &&
                            <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                NO
                            </Typography>
                        }
                        {
                            inspection.upstreamSlopeSurfaceProtection != null &&
                            <>
                                <Typography className={'fs14 fw500 cg3 aic jcc'}>
                                    {
                                        inspection.upstreamSlopeSurfaceProtection === EnumDamSlopeSurfaceProtection.Other &&
                                        inspection.upstreamSlopeSurfaceProtectionOther
                                    }
                                    {
                                        inspection.upstreamSlopeSurfaceProtection !== EnumDamSlopeSurfaceProtection.Other &&
                                        DamHelper.DamSlopeSurfaceProtection[inspection.upstreamSlopeSurfaceProtection]
                                    }
                                </Typography>
                                {
                                    inspection.upstreamSlopeSurfaceProtectionPhotoThumbUrl &&
                                    <div className='fdc mt12'>
                                        <DamInspectionPhotoView
                                            id={'upstreamSlopeSurfaceProtectionPhotoUrl'}
                                            photoThumbUrl={inspection.upstreamSlopeSurfaceProtectionPhotoThumbUrl}
                                            photoFileName={inspection.upstreamSlopeSurfaceProtectionPhotoFileName ?? ''}
                                            onImageClick={onImageClick}
                                        />
                                    </div>
                                }
                                {
                                    !!inspection.upstreamSlopeSurfaceProtectionLocation &&
                                    <div className='fdc mt12'>
                                        <DamInspectionLocationView
                                            id='upstreamSlopeSurfaceProtectionLocation'
                                            lat={inspection.upstreamSlopeSurfaceProtectionLocation[0]}
                                            lng={inspection.upstreamSlopeSurfaceProtectionLocation[1]}
                                        />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </Card>
            </div>
        );
    };
}

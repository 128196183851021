import React from 'react';
import boreholeFunctions from '../../store/borehole/functions';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { IAuthState, IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BOREHOLE_MAP_IMAGES, BOREHOLE_STATUS } from '../../appConstants';
import { createSelector } from 'reselect';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { Transitions } from '../customComponents/animations/Transitions';
import BoreholeInfo from './Info';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import { withRouter, RouteComponentProps } from 'react-router';
import { IBorehole } from '../../@types/model/borehole/borehole';
import CustomMapBox, { IMapMarker } from '../customComponents/map/CustomMapBox';

interface IBoreholeMapProps extends RouteComponentProps<{
    Code : string;
}, Record<string, unknown>, Record<string, unknown>> {
    auth : IAuthState;
    boreholes : Array<IBorehole>;
    borehole ?: IBorehole;
    isLoadingBoreholes : boolean;
}

interface IBoreholeMapState {
    selectedBorehole ?: IBorehole;
}

class BoreholeMap extends React.Component<IBoreholeMapProps, IBoreholeMapState> {
    constructor(props : IBoreholeMapProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        boreholeFunctions.getBoreholes(this.setSelectedBorehole);
    };

    private setSelectedBorehole : undefined | (() => void) = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        const boreholeId = urlParams.get('Code') as string;
        this.onMarkerClick(boreholeId);

        this.setSelectedBorehole = undefined;
    };

    private readonly selectMarker = (borehole : IBorehole) => {
        this.setState({
            selectedBorehole: borehole,
        });
    };

    private readonly onMoreInfo = () => {
        boreholeFunctions.setBorehole(Object.assign({}, this.state.selectedBorehole));
    };

    private readonly onCloseDrawer = () => {
        this.setState({
            selectedBorehole: undefined,
        });
    };

    private readonly onMarkerClick = (code : string) => {
        const borehole = this.props.boreholes.slice().find(n => n.Code === code);

        if (borehole) {        
            this.selectMarker(borehole);
        }
    };

    public readonly getData = (state : IBoreholeMapState, props : IBoreholeMapProps) => props.boreholes;
    public readonly getFilteredData = createSelector(
        [this.getData],
        (boreholes) => {
            return boreholes.slice().filter(n => !!n.Geom && (n.IsActive === undefined || n.IsActive));
        },
    );

    public readonly getMarkers = createSelector(
        [this.getFilteredData],
        (mottechs) => {
            return mottechs.map(n => ({
                coordinates: [n.Geom?.longitude ?? 0, n.Geom?.latitude ?? 0],
                iconSize: 1,
                icon: BOREHOLE_STATUS[n.Status ?? '1'],
                id: n.Code,
            } as IMapMarker));
        }
    );

    public readonly render = () => {
        const { isLoadingBoreholes } = this.props;
        const { selectedBorehole } = this.state;
        const boreholes = this.getFilteredData(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (<div className={'fdc flx1 p10 mh0 mw0'}>
            <div className={'flx1 fdc mh0 mw0'}>
                <Toolbar>
                    <Typography variant='h5' color='inherit'>
                        BOREHOLES
                    </Typography>
                    <span className={'flx1'} />
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className={'fdr pt5'}>
                            <span className={'pr15'}><b>Total: </b>{boreholes.length}</span>
                        </div>
                    </div>
                </Toolbar>
                <div className='mnh4'>
                    { isLoadingBoreholes && <LinearProgress />}
                </div>
                <Paper className={'flx1 fdr oxh'}>
                    {
                        boreholes.length > 0 &&

                        <CustomMapBox
                            id='borehole_map'
                            enableAreas
                            geoLocationControl
                            navigationControl
                            showScale
                            layerControls
                            images={BOREHOLE_MAP_IMAGES}
                            onMarkerClick={this.onMarkerClick}
                            markers={markers}
                            selectedMarker={selectedBorehole?.Code}
                        />
                    }
                </Paper>
            </div>
            <Drawer open={!!selectedBorehole} anchor='right' variant='persistent'>
                <div className={'w300 flx1 fdc'}>
                    <AppBar className='posr' position='static'>
                        <Toolbar variant='dense'>
                            <div className='h60' />
                            <IconButton color={'inherit'} onClick={this.onMoreInfo}><Icon color={'inherit'}>more_vert</Icon></IconButton>
                            <Typography color='inherit' variant='h6' className={'flx1 ml10'} noWrap={true}>
                                {!!selectedBorehole && selectedBorehole.Code}
                            </Typography>
                            <IconButton color={'inherit'} onClick={this.onCloseDrawer}><Icon color={'inherit'}>close</Icon></IconButton>
                        </Toolbar>
                    </AppBar>
                    {
                        selectedBorehole &&
                        <div className='fdc flx1'>
                            <div className='fdr flx1'>
                                <div className={'fdc flx1'}>
                                    <Typography className={'fdr bcDarkBlue fw700 cw p10'} variant='h6'>
                                        SUMMARY
                                    </Typography>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle2'>
                                            DIVISION
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle2'>
                                            Man. Area
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle2'>
                                            {selectedBorehole.Division}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle2'>
                                            {selectedBorehole.ManagementArea}
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle2'>
                                            NAME
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle2'>
                                            STATUS
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle2'>
                                            {selectedBorehole.Name}
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle2'>
                                            {selectedBorehole.Status ? BOREHOLE_STATUS[selectedBorehole.Status.toString()] : 'None'}
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle2'>
                                            INSTALLED YIELD
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle2'>
                                            ADJUSTED YIELD
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle2'>
                                            {selectedBorehole.InstalledYield ? selectedBorehole.InstalledYield.toFixed(2) : '0.00'} m<sup>3</sup>/hr
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle2'>
                                            {selectedBorehole.AdjustedYield ? selectedBorehole.AdjustedYield.toFixed(2) : '0.00'} m<sup>3</sup>/hr
                                        </Typography>
                                    </div>
                                    <div className={'fdr pl10 pr10 pt10 mb10'}>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle2'>
                                            LATEST YIELD
                                        </Typography>
                                        <Typography className={'flx1 fw600 bbw1 bocGrey pb10 pl10 pr10'} variant='subtitle2'>
                                            LATEST RUNTIME
                                        </Typography>
                                    </div>
                                    <div className={'fdr mb10 pl10 pr10'}>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle2'>
                                            {selectedBorehole.LatestYield ? selectedBorehole.LatestYield.toFixed(2) : '0.00'} m<sup>3</sup>/hr
                                        </Typography>
                                        <Typography className={'flx1 pl10 pr10 mnh48'} variant='subtitle2'>
                                            {selectedBorehole.LatestRuntime ? selectedBorehole.LatestRuntime.toFixed(0) : '0'} h
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Drawer>
            <BoreholeInfo fullScreen transition={Transitions.Up} />
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        boreholes: state.borehole.boreholes,
        borehole: state.borehole.borehole,
        isLoadingBoreholes: state.borehole.loading,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
)(withRouter(BoreholeMap));

import React from 'react';
import { IRootState } from '../../@types/redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Card from '@material-ui/core/Card';
import MaterialTable from '../customComponents/materialTable/Table';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import lodash from 'lodash';
import NavFunctions from '../../store/nav/functions';
import MapIcon from '@material-ui/icons/Map';
import FlowmeterHelper, { IFlowmeter } from '../../@types/model/flowmeter/flowmeter';
import FlowmeterFunctions from '../../store/flowmeter/functions';
import FlowmeterNewButton from './button/NewButton';
import { Transitions } from '../customComponents/animations/Transitions';
import FlowmeterInfo from './Info';
import { Fab, Icon } from '@material-ui/core';
import FlowmeterFilterSelector from '../customComponents/selector/flowmeter/FilterSelector';
import { IUserSession } from '../../@types/employee';
import { CSVLinkProps } from '../../@types/csv';
import moment from 'moment';
import { CSVLink } from 'react-csv';

interface IFlowmeterListProps {
    flowmeters : Array<IFlowmeter>;
    isLoadingFlowmeters : boolean;
    session ?: IUserSession | null;
}

interface IFlowmeterListState {
    sortList : Array<{ field : string; direction : 'asc' | 'desc' }>;
    showAll : boolean;

    name : string;
    code : string;
    division : string;
    manArea : string;
}

class FlowmeterListComponent extends React.Component<IFlowmeterListProps, IFlowmeterListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    constructor(props : IFlowmeterListProps) {
        super(props);

        this.state = {
            sortList: [],
            showAll: false, 
            name: '',
            code: '',
            division: '',
            manArea: '',
        };

        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        FlowmeterFunctions.getList();
    };

    public readonly onInfoClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const flowmeter = this.props.flowmeters.slice().find(x => x.code === event.currentTarget.value);

        FlowmeterFunctions.setSelected(flowmeter);
    };

    public readonly onMapClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const flowmeter = this.props.flowmeters.slice().find(x => x.code === event.currentTarget.value);

        if (flowmeter) {
            NavFunctions.navFlowmeterMap(flowmeter.code);
        }
    };
    
    private readonly getSortList = (props : IFlowmeterListProps, state : IFlowmeterListState) => state.sortList;
    private readonly getName = (props : IFlowmeterListProps, state : IFlowmeterListState) => state.name;
    private readonly getCode = (props : IFlowmeterListProps, state : IFlowmeterListState) => state.code;
    private readonly getDivision = (props : IFlowmeterListProps, state : IFlowmeterListState) => state.division;
    private readonly getmanArea = (props : IFlowmeterListProps, state : IFlowmeterListState) => state.manArea;
    private readonly getData = (props : IFlowmeterListProps) => props.flowmeters;
    private readonly getShowAll = (props : IFlowmeterListProps, state : IFlowmeterListState) => state.showAll;

    public readonly getFilteredData = createSelector(
        [this.getData, this.getSortList, this.getShowAll,
            this.getName, this.getCode, this.getDivision,
            this.getmanArea],
        (flowmeters : Array<IFlowmeter>, 
            sortList : Array<{ field : string; direction : 'asc' | 'desc' }>,
            showAll : boolean, 
            name : string,
            code : string,
            division : string,
            manArea : string,    
        ) => {
            let sortedList = flowmeters;
            sortedList = lodash
                .chain(sortedList)
                .filter(n => showAll || typeof n.isActive === 'undefined' || n.isActive)
                .filter(n => n.code.includes(code))
                .filter(n => n.name.toLowerCase().includes(name.toLowerCase()))
                .filter(n => !division || !!n.division?.toLowerCase().includes(division.toLowerCase()))
                .filter(n => !manArea || !!n.managementArea?.toLowerCase().includes(manArea.toLowerCase()))
                .value();
            if (sortList.length === 0) {
                return sortedList.sort((a : IFlowmeter, b : IFlowmeter) => parseInt(a.code.substr(2)) - parseInt(b.code.substr(2)));
            }

            sortList
                .forEach((n) => {
                    if (n.field === 'code') {
                        sortedList = sortedList.sort((a, b) => parseInt(b.code.substr(2)) - parseInt(a.code.substr(2)) * (n.direction === 'desc' ? 1 : -1));
                    } else {
                        sortedList = lodash.orderBy(sortedList, n.field, n.direction);
                    }
                });
            return sortedList;
        },
    );

    public readonly onChange = (name : string | null, code : string | null, division : string | null, manArea : string | null) => {
        this.setState({
            name: name ?? '',
            code: code ?? '',
            division: division ?? '',
            manArea: manArea ?? '',
        });
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    private readonly onShowOnlyActiveClick = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    };

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (flowmeters) => {
            return FlowmeterHelper.mapToCsv(flowmeters);
        },
    );

    public readonly render = () => {
        const { isLoadingFlowmeters, session } = this.props;
        const { showAll, name, code, division, manArea } = this.state;
        const flowmeters = this.getFilteredData(this.props, this.state);
        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <div className={'flx1 fdc mh0 mw0 pt30 pl30 pr30'}>
                    <div className={'fdr wfill jcfe mr15 pb30 aic'}>
                        <FlowmeterFilterSelector
                            id='dam_filter'
                            onChange={this.onChange}
                            name={name}
                            code={code}
                            division={division}
                            manArea={manArea}
                            label='Filter By...'
                            disabled={isLoadingFlowmeters}
                        />
                        <div className={'fdr pt5 aic pl10'}>
                            <Tooltip title={'Show only Active'}>
                                <Fab color={!showAll ? 'primary' : 'default'} size={'small'} onClick={this.onShowOnlyActiveClick}>
                                    <Icon>
                                        <img src={`${ASSET_BASE}/assets/images/icons/active.svg`} />
                                    </Icon>
                                </Fab>
                            </Tooltip>    
                            <span className={'pl10 pt5 pr20'}><b>Total: </b>{flowmeters.length}</span>
                        </div>
                    </div>
                    <Card className={'flx1 fdc'}>
                        <MaterialTable<IFlowmeter>
                            id='FlowmeterTable'
                            data={flowmeters}
                            isLoading={isLoadingFlowmeters}
                            onExportCsvClick={!session?.employee.IsFlowmeterExport ? undefined : this.onCSVClick}
                            rowsPerPage={100}
                            columns={[{
                                header: '',
                                field: '',
                                width: 100,
                                enableSort: true,
                                enableFilter: true,
                                paddingRight: 4,
                                fixed: true,
                                renderCell: row => (
                                    <div className='aic'>
                                        <Tooltip title='Info'>
                                            <div>
                                                <IconButton color='primary' value={row.code} onClick={this.onInfoClick}>
                                                    <Icon>
                                                        info_outline
                                                    </Icon>
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title='Map'>
                                            <div>
                                                <IconButton color='primary' value={row.code} onClick={this.onMapClick} disabled={!row.geo}>
                                                    <MapIcon />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </div>
                                ),
                            }, {
                                header: 'Code',
                                field: 'code',
                                width: 140,
                                enableSort: true,
                            }, {
                                header: 'Name',
                                field: 'name',
                                width: 200,
                                enableSort: true,
                            }, {
                                header: 'Division',
                                field: 'division',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Management Area',
                                field: 'managementArea',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Current Meter',
                                field: 'currentMeter',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Monitoring',
                                field: 'monitor',
                                width: 100,
                                enableSort: true,
                            }, {
                                header: 'Latest Reading',
                                field: 'lastReading',
                                width: 150,
                                enableSort: true,
                            }, {
                                header: 'Cumulative (m³/month)',
                                field: 'cumulativeFlowMonth',
                                width: 170,
                                enableSort: true,
                            }, {
                                header: 'Cumulative (m³/week)',
                                field: 'cumulativeFlowWeek',
                                width: 170,
                                enableSort: true,
                            }, {
                                header: 'Latest Flow (m³/week)',
                                field: 'lastFlowWeek',
                                width: 170,
                                enableSort: true,
                            }, {
                                header: 'Active?',
                                field: 'isActive',
                                enableSort: true,
                                renderCell: n => <span className='fdc jcc'>
                                    {
                                        !n.isActive ? 
                                            (<Icon className='cr'>close</Icon>) :
                                            (<Icon className='cdg'>check</Icon>)
                                    }
                                </span>,
                            }]}
                        />
                    </Card>
                    {
                        session?.employee.IsFlowmeterCreateAdmin &&
                        <FlowmeterNewButton maxWidth='md' fullWidth transition={Transitions.Up}/>
                    }
                    <FlowmeterInfo fullScreen transition={Transitions.Up} />
                    {
                        session?.employee.IsFlowmeterExport &&
                        <CSVLink
                            data={csvData}
                            headers={[
                                {
                                    key: 'code',
                                    label: 'Code',
                                },
                                {
                                    key: 'name',
                                    label: 'Name',
                                },
                                {
                                    key: 'division',
                                    label: 'Division',
                                },
                                {
                                    key: 'managementArea',
                                    label: 'Management Area',
                                },
                            ]}
                            filename={`${csvName}_Flowmeter.csv`}
                            className='dn'
                            ref={this.csvLink}
                            target='_blank'/>
                    }
                </div>
            </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        flowmeters: state.flowmeter.flowmeters,
        isLoadingFlowmeters: state.flowmeter.loading,
        session: state.auth.session,
    };
};

const FlowmeterList = connect(
    mapStateToProps,
)(FlowmeterListComponent);

export default FlowmeterList;

import Fade from '@material-ui/core/Fade';
import Popper from '@material-ui/core/Popper';
import React, { ChangeEvent } from 'react';
import Fab from '@material-ui/core/Fab';
import OutsideClicker from '../outsideClicker';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DamCodeSelector from '../selector/dam/DamCodeSelector';
import DamTypeSelector from '../selector/dam/DamTypeSelector';
import DivisionSelector from '../selector/DivisionSelector';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface IDamPopperProps {
    id ?: string;
    open : boolean;
    anchorEl : HTMLButtonElement | null;

    onChange ?: (
        code : string | null,
        name : string | null,
        type : string | null,
        division : string | null,
        managementArea : string | null,
        inspectionDue : boolean | null,
    ) => void;

    onCancelClick ?: () => void;
    code : string | null;
    name : string | null;
    type : string | null;
    division : string | null;
    managementArea : string | null;
    inspectionDue : boolean | null;
}

interface IDamFilterPopperState {
    code : string;
    name : string;
    type : string;
    division : string | null;
    managementArea : string | null;
    inspectionDue : boolean | null;
}

export default class DamFilterPopper extends React.PureComponent<IDamPopperProps, IDamFilterPopperState> {
    constructor(props : IDamPopperProps) {
        super(props);
        this.state = {
            code: '',
            name: '',
            type: '',
            division: '',
            managementArea: '',
            inspectionDue: null,
        };
    }

    public readonly componentDidUpdate = (prevProps : IDamPopperProps) => {
        if (!prevProps.open && this.props.open) {
            const {code, name, type, division, managementArea} = this.props;
            this.setState({
                code: code ?? '',
                name: name ?? '',
                type: type ?? '',
                division: division ?? '',
                managementArea: managementArea ?? '',
            });
        }
    };

    private readonly codeChange = (value : string) => {
        this.setState({
            code: value,
        });
    };

    private readonly nameChange = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        this.setState({
            name: event.target.value,
        });
    };

    private readonly typeChange = (value : string) => {
        this.setState({
            type: value,
        });
    };

    private readonly onDivisionChanged = (value : string) => {
        this.setState({
            division: value,
        });
    };

    private readonly onManagementAreaChanged = (value : string) => {
        this.setState({
            managementArea: value,
        });
    };

    private readonly onSubmit = (event ?: React.FormEvent<HTMLFormElement>) => {
        
        if (event) {
            event.preventDefault();
        }

        if (!this.props.onChange) return;
        const { code, name, type, division, managementArea, inspectionDue } = this.state;

        this.props.onChange(code, name, type, division, managementArea, inspectionDue);
    };

    private readonly onInspectionDueChanged = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.setState({
            inspectionDue: this.state.inspectionDue === false ? null : checked,
        });
    };

    public readonly render = () => {
        const { open, anchorEl, id, onCancelClick } = this.props;
        const { code, name, type, division, managementArea, inspectionDue } = this.state;

        return (
            <Popper
                id={`${id}_dam_filter_menu`} open={open} anchorEl={anchorEl} transition
                style={{
                    zIndex: 5,
                }}
                placement='bottom'>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <div style={{
                            width: 362,
                            background: '#FFF',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: 2,
                            marginRight: 20,
                            marginTop: -5,
                            paddingLeft: 15,
                            paddingRight: 15,
                        }}>
                            {
                                open &&
                                <OutsideClicker onClickedOutside={onCancelClick}>
                                    <form onSubmit={this.onSubmit} className='fdc ais'>
                                        <div className={'fdr flx1 aifs'}>
                                            <DivisionSelector division={division ?? ''} manArea={managementArea ?? ''} onDivisionChanged={this.onDivisionChanged} onManAreaChanged={this.onManagementAreaChanged} />
                                        </div>  
                                        <div className='fdr aic jcc'>
                                            <div className='flx1 aifs jcc pt5 ais mb10 pr25'>
                                                <DamCodeSelector value={code} onChange={this.codeChange} />
                                            </div>
                                            <div className='flx1 aifs jcc ais pt5 mb10 pr25'>
                                                <DamTypeSelector value={type} onChange={this.typeChange} />
                                            </div>
                                        </div>
                                        <div className='fdr'>
                                            <div className={'flx1 aifs pr5'}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        autoComplete='off'
                                                        id='name'
                                                        label='Name'
                                                        value={name}
                                                        onChange={this.nameChange}
                                                        margin='normal'
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className='fdr flx1 aic jcc pl5'>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color='primary'
                                                            indeterminate={inspectionDue === null}
                                                            checked={!!inspectionDue}
                                                            onChange={this.onInspectionDueChanged}
                                                        />
                                                    }
                                                    label='Inspection Due?'
                                                />
                                            </div>
                                        </div>
                                        <div className='fdr aic pb16'>
                                            <span className='flx1' />
                                            <Fab variant='extended' color='secondary' className='fab cancel' size='small' onClick={onCancelClick} type='button'>
                                                CANCEL
                                            </Fab>
                                            <Fab variant='extended' color='secondary' className='fab save fw600' size='small' type='submit'>
                                                APPLY
                                            </Fab>
                                        </div>
                                    </form>
                                </OutsideClicker>
                            }
                        </div>
                    </Fade>
                )}
            </Popper>
        );
    };
}

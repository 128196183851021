import IMask from 'imask';
import moment from 'moment';
import React from 'react';

interface IDateTextInputProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref' | 'onChange'> {
    setFieldValue : (field : string, value : unknown, shouldValidate ?: boolean) => void;
}

interface IDateTextInputState {}

export default class DateTextInput extends React.PureComponent<IDateTextInputProps, IDateTextInputState> {
    private readonly ref : React.RefObject<HTMLInputElement>;
    private mask : IMask.InputMask<IMask.AnyMaskedOptions> | undefined;
    private readonly momentFormat = 'YYYY/MM/DD';
    constructor(props : IDateTextInputProps) {
        super(props);
        this.state = {};

        this.ref = React.createRef<HTMLInputElement>();
    }

    public readonly componentDidUpdate = (prevProps : IDateTextInputProps) => {
        if (!this.ref.current) return;
        if (!this.mask) return;

        if (!prevProps.value && prevProps.value) {
            this.mask.updateValue();
        }
    };

    public readonly componentWillUnmount = () => {
        if (!this.mask) return;
        this.mask.destroy();
        this.mask.off('accept');
    };

    public readonly componentDidMount = () => {
        if (!this.ref.current) return;
        this.mask = IMask(this.ref.current, {
            mask: IMask.MaskedDate,
            lazy: false,
            placeholderChar: '_',
            pattern: this.momentFormat,
            format: (date) => {
                return moment.utc(date).format(this.momentFormat);
            },
            parse: (str) => {
                return moment.utc(str, this.momentFormat);
            },
            
            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    from: 1970,
                    to: 2030,
                },
                MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                },
                DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31,
                },
            },
        });

        if (typeof this.props.value === 'string') {
            this.mask.value = this.props.value;
            this.mask.updateValue();
        }

        this.mask.on('accept', () => {
            if (!this.props.name) return;
            this.props.setFieldValue(this.props.name, this.mask?.value === '____/__/__' ? null : this.mask?.value);
        });
    };

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!this.mask) return;
        this.mask.value = event.currentTarget.value;
        this.mask.updateValue();
    };

    public readonly render = () => {
        const { setFieldValue: _, value: _ivalue, ...rest } = this.props;
        return (
            <input {...rest} ref={this.ref} onChange={this.onChange} />
        );
    };
}

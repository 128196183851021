import { FieldProps } from 'formik';
import React from 'react';
import { IMunicipalityWaterInvoiceFormValues, MunicipalityInvoiceHelper, waterBlock, waterBlocksTypes } from '../../../@types/model/transformer/municipalityInvoice';
import { themeVariables } from '../../../material';
import { Divider, Switch, Typography } from '@material-ui/core';
import TextFormFastField from '../../customComponents/form/TextFormFastField';
import StadiumDiv from '../../customComponents/div/Stadium';

interface IMunicipilityInvoiceWaterFormProps extends FieldProps<IMunicipalityWaterInvoiceFormValues | null> {}

interface IMunicipilityInvoiceWaterFormState {}

export default class MunicipilityInvoiceWaterForm extends React.PureComponent<IMunicipilityInvoiceWaterFormProps, IMunicipilityInvoiceWaterFormState> {
    constructor(props : IMunicipilityInvoiceWaterFormProps) {
        super(props);
        this.state = {};
    }

    private readonly onDivClick = (event : React.SyntheticEvent) => {
        this.onWaterChange(event as React.ChangeEvent<HTMLInputElement>, !this.props.field.value);
    };

    private readonly onWaterChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {        
        let water : IMunicipalityWaterInvoiceFormValues | null = null;
        if (checked) {
            water = {
                waterAvailabilityCost: null,
                days: null,
                basicInterest: null,
                meteredInterest: null,
                blocks: waterBlocksTypes.reduce((n, a) => ({
                    ...n,
                    [a]: {
                        consumption: null,
                        cost: null,
                    },
                }), {} as Record<waterBlock, {
                    consumption : number | null;
                    cost : number | null;
                }>),
                totalConsumption: 0,
                totalConsumptionCost: 0,
                total: 0,
            };
        }

        this.props.form.setFieldValue('water', water);
    };

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.field.onChange(event);

        setTimeout(() => {
            MunicipalityInvoiceHelper.calculateTotals(this.props.form);
        });
    };

    public readonly render = () => {
        const {
            field,
            form,
        } = this.props;
        return (
            <div className='fdc'>
                <div className='fdr'>
                    <div
                        className='fdr flx1 aic h50 pl25 pr25 curp'
                        onClick={this.onDivClick}
                        style={{
                            boxShadow: '0px 3px 6px #00000029',
                            backgroundColor: field.value ?
                                '#0D703F' :
                                themeVariables.colors.table.capturing.spu.totalUsage,
                        }}
                    >
                        <Typography className='cw fwm fs20'>WATER</Typography>
                        <span className='flx1' />
                        <Switch
                            color={!field.value ? 'primary' : 'secondary'}
                            checked={!!field.value}
                            onChange={this.onWaterChange}
                        />
                    </div>
                </div>
                {
                    field.value &&
                    <div className='fdc mt10'>
                        <div className='fdr'>
                            <div className='flx1 mr10 aifs'>
                                <TextFormFastField
                                    name={'water.waterAvailabilityCost'}
                                    label='Availability Cost:'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'water.days'}
                                    label='Days:'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                />
                            </div>
                            <div className='flx1 ml10 mr10 aifs'>
                                <TextFormFastField
                                    name={'water.basicInterest'}
                                    label='Basic Interest:'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                />
                            </div>
                            <div className='flx1 ml10 aifs'>
                                <TextFormFastField
                                    name={'water.meteredInterest'}
                                    label='Metered Interest:'
                                    fullWidth
                                    type='number'
                                    onChange={this.onChange}
                                    onBlur={form.handleBlur}
                                />
                            </div>
                        </div>
                        <div className='mt20 grid2'>
                            {
                                waterBlocksTypes.map((a) => (
                                    <div key={a} className={`fdc ${a % 2 === 0 ? 'ml5' : 'mr5'}`}>
                                        <div className='fdr bclg'>
                                            <Typography className='cpd fs14 fwm pt5 pb5 pl25'>BLOCK {a}</Typography>
                                        </div>
                                        <div className='fdr flx1 aic jcc p15 pl25 pr25'>
                                            <div className='flx1 mr10'>
                                                <TextFormFastField
                                                    name={`water.blocks.${a}.consumption`}
                                                    label='Consumption'
                                                    fullWidth
                                                    type='number'
                                                    onChange={this.onChange}
                                                    onBlur={form.handleBlur}
                                                />
                                            </div>
                                            <div className='flx1 ml10'>
                                                <TextFormFastField
                                                    name={`water.blocks.${a}.cost`}
                                                    label='Cost'
                                                    fullWidth
                                                    type='number'
                                                    onChange={this.onChange}
                                                    onBlur={form.handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <Divider className='mt15 mb15' />
                        <div className='fdr aic'>
                            <div className='fdr flx1 mr10'>
                                <Typography component={'span'} className='flx2 aic jcfe cpd fwm fs14'>TOTAL WATER CONSUMPTION:</Typography>
                                <StadiumDiv className='fdr flx1 bcg1 cpd ml15'>
                                    <div className='fdr aic jcc'>
                                        <Typography component={'span'} className='fdr jcfs flx1 fs16 fwm pr'>
                                            {
                                                field.value.totalConsumption.toLocaleString()
                                            }
                                        </Typography>
                                        <Typography component={'span'} className='cg3 fs16 fwm ml15'>KL</Typography>
                                    </div>
                                </StadiumDiv>
                            </div>
                            <div className='fdr flx1 ml10'>
                                <Typography component={'span'} className='flx2 aic jcfe cpd fwm fs14'>TOTAL WATER COST:</Typography>
                                <StadiumDiv className='fdr flx1 bcg1 cpd ml15'>
                                    <div className='fdr aic jcc'>
                                        <Typography component={'span'} className='fdr jcfs flx1 fs16 fwm pr'>
                                            {
                                                field.value.totalConsumptionCost.toLocaleCurrency()
                                            }
                                        </Typography>
                                    </div>
                                </StadiumDiv>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

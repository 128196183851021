import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FlowmeterHelper, { EnumFlowmeterMonitor, EnumFlowmeterWaterUsage, IFlowmeter } from '../../../@types/model/flowmeter/flowmeter';
import { CircularProgress, Fab } from '@material-ui/core';
import FlowmeterFunctions from '../../../store/flowmeter/functions';
import uuid from 'uuid';
import DivisionSelector from '../../customComponents/selector/DivisionSelector';
import FlowmeterWaterUsageSelector from '../../customComponents/selector/flowmeter/FlowmeterWaterUsageTypeSelector';
import FlowmeterMonitorSelector from '../../customComponents/selector/flowmeter/FlowmeterMonitorTypeSelector';
import LocationInput from '../../customComponents/input/LocationInput';
import { FLOWMETER_MAP_IMAGES } from '../../../appConstants';
import StadiumButton from '../../customComponents/button/StadiumButton';
import OutlinedStadiumButton from '../../customComponents/button/OutlinedStadiumButton';

interface IFlowmeterNewButtonProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    flowmeters : Array<IFlowmeter>;
}

interface IFlowmeterNewButtonState {
    open : boolean;
    guid : string;
    name : string;
    division : string;
    managementArea : string;

    waterUsageType : EnumFlowmeterWaterUsage | null;
    waterUsageTypeDescription : string | null;

    monitorType : EnumFlowmeterMonitor | null;
    monitorTypeDescription : string | null;

    geom : firebase.firestore.GeoPoint | null;
    elevation : number | null;

    isLoading : boolean;
}


class FlowmeterNewButton extends React.Component<IFlowmeterNewButtonProps, IFlowmeterNewButtonState> {
    constructor(props : IFlowmeterNewButtonProps) {
        super(props);
        this.state = {
            open: false,
            guid: '',
            name: '',
            division: '',
            managementArea: '',
            waterUsageType: null,
            waterUsageTypeDescription: null,
            monitorType: null,
            monitorTypeDescription: null,
            geom: null,
            elevation: null,
            isLoading: false,
        };
    }

    private readonly onAddClick = () => {
        this.setState({
            open: true,
            guid: uuid.v4(),
            name: '',
            division: '',
            managementArea: '',
            isLoading: false,
            waterUsageType: null,
            waterUsageTypeDescription: null,
            monitorType: null,
            monitorTypeDescription: null,
            geom: null,
            elevation: null,
        });
    };

    private readonly onClose = () => {
        if (this.state.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onNameChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            name: event.currentTarget.value,
        });
    };

    private readonly onWaterUsageTypeChange = (waterUsageType : EnumFlowmeterWaterUsage | null) => {
        this.setState({
            waterUsageType,
            waterUsageTypeDescription: waterUsageType === null ? null : FlowmeterHelper.EnumFlowMeterWaterUsageToString[waterUsageType],
        });

        if (waterUsageType !== null && waterUsageType === EnumFlowmeterWaterUsage.other) {
            this.setState({
                waterUsageTypeDescription: '',
            });
        }
    };

    private readonly onWaterUsageTypeDescriptionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            waterUsageTypeDescription: event.currentTarget.value,
        });
    };

    private readonly onMonitorTypeChange = (monitorType : EnumFlowmeterMonitor | null) => {
        this.setState({
            monitorType,
            monitorTypeDescription: monitorType === null ? null : FlowmeterHelper.EnumFlowMeterMonitorToString[monitorType],
        });

        if (monitorType !== null && monitorType === EnumFlowmeterMonitor.other) {
            this.setState({
                monitorTypeDescription: '',
            });
        }
    };

    private readonly onMonitorTypeDescriptionChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            monitorTypeDescription: event.currentTarget.value,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly save = async () => {
        const {
            guid,
            name,
            division,
            managementArea,
            monitorType,
            monitorTypeDescription,
            waterUsageType,
            waterUsageTypeDescription,
            elevation,
            geom,
        } = this.state;

        if (!name) return;

        try {
            this.setState({
                isLoading: true,
            });

            await FlowmeterFunctions.create(
                guid,
                name,
                division,
                managementArea,
                waterUsageType,
                monitorType,
                waterUsageTypeDescription,
                monitorTypeDescription,
                geom,
                elevation,
            );

            this.setState({
                open: false,
            });
        } finally {
            this.setState({
                isLoading: false,
            });
        }

    };

    private readonly onDivisionChanged = (value : string) => {
        this.setState({
            division: value,
        });
    };

    private readonly onManAreaChanged = (value : string) => {
        this.setState({
            managementArea: value,
        });
    };

    private readonly onGeoChange = (geom : firebase.firestore.GeoPoint | null) => {
        this.setState({
            geom,
        });
    };

    private readonly onElevationChange = (elevation : number) => {
        this.setState({
            elevation,
        });
    };

    public readonly render = () => {
        const { transition, fullWidth, maxWidth, fullScreen } = this.props;
        const {
            open,
            name,
            division,
            managementArea,
            isLoading,
            monitorType,
            monitorTypeDescription,
            waterUsageType,
            waterUsageTypeDescription,
            elevation,
            geom,
        } = this.state;

        return (
            <div className={'jcfe mt10 mb30 mr20'}>
                <Tooltip title='Create'>
                    <div className={' '}>
                        <Fab aria-label='Add' onClick={this.onAddClick} className='fab add cp bcy '>
                            <Icon>add</Icon>
                        </Fab>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    aria-labelledby='new-flowmeter-dialog-title'
                    aria-describedby='new-flowmeter-dialog-description'>
                    <AppBar className='flx1 fdr posr p10 aic' position='static'>
                        <Typography variant='h5' color='inherit'>
                            NEW FLOWMETER
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </AppBar>
                    {
                        isLoading &&
                        <div className={'fdc aic jcc h250'}>
                            <CircularProgress />
                        </div>
                    }
                    {
                        !isLoading &&
                        <form autoComplete='off' onSubmit={this.onSubmit} className='fdc'>
                            <DialogContent className='fdc'>
                                <div className={'fdr flx1 aifs'}>
                                    <div className={'flx1 aifs pr10'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoFocus
                                                autoComplete='off'
                                                id='name'
                                                label='Name'
                                                value={name}
                                                onChange={this.onNameChange}
                                                required
                                                error={!name}
                                                disabled={isLoading}
                                            />
                                            {
                                                !name &&
                                                <FormHelperText error>Required</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <div className={'flx1 aifs pl10 pr10'}>
                                        <FormControl fullWidth>
                                            <FlowmeterWaterUsageSelector
                                                value={waterUsageType}
                                                onChange={this.onWaterUsageTypeChange}
                                            />
                                        </FormControl>
                                    </div>
                                    {
                                        waterUsageType === EnumFlowmeterWaterUsage.other &&
                                        <div className={'flx1 aifs pl10 pr10'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoFocus
                                                    autoComplete='off'
                                                    id='waterUsageTypeDescription'
                                                    label='Water Usage Type Description'
                                                    value={waterUsageTypeDescription ?? ''}
                                                    onChange={this.onWaterUsageTypeDescriptionChange}
                                                    required
                                                    error={!waterUsageTypeDescription}
                                                    disabled={isLoading}
                                                />
                                                {
                                                    !waterUsageTypeDescription &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    }
                                </div>
                                <div className={'fdr flx1 aifs mt10'}>
                                    <div className={'flx1 aifs pr10'}>
                                        <FormControl fullWidth>
                                            <FlowmeterMonitorSelector
                                                value={monitorType}
                                                onChange={this.onMonitorTypeChange}
                                            />
                                        </FormControl>
                                    </div>
                                    {
                                        monitorType !== EnumFlowmeterMonitor.other &&
                                        <div className={'pr10'}>
                                        </div>
                                    }
                                    {
                                        monitorType === EnumFlowmeterMonitor.other &&
                                        <div className={'flx1 aifs pl10 pr10'}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoFocus
                                                    autoComplete='off'
                                                    id='monitorTypeDescription'
                                                    label='Monitor Type Description'
                                                    value={monitorTypeDescription ?? ''}
                                                    onChange={this.onMonitorTypeDescriptionChange}
                                                    required
                                                    error={!monitorTypeDescription}
                                                    disabled={isLoading}
                                                />
                                                {
                                                    !monitorTypeDescription &&
                                                    <FormHelperText error>Required</FormHelperText>
                                                }
                                            </FormControl>
                                        </div>
                                    }
                                    <div className={'fdr flx2 aifs'}>
                                        <DivisionSelector
                                            division={division}
                                            manArea={managementArea}
                                            onDivisionChanged={this.onDivisionChanged}
                                            onManAreaChanged={this.onManAreaChanged}
                                        />
                                    </div>
                                </div>
                                <div className={'fdr mt10'}>
                                    <LocationInput
                                        elevation={elevation}
                                        geom={geom}
                                        markerImage={FLOWMETER_MAP_IMAGES['1']}
                                        onGeoChange={this.onGeoChange}
                                        onElevationChange={this.onElevationChange}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <OutlinedStadiumButton onClick={this.onClose}>
                                    Cancel
                                </OutlinedStadiumButton>
                                <StadiumButton type='submit'>
                                    Ok
                                </StadiumButton>
                            </DialogActions>
                        </form>
                    }
                </Dialog>

            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        flowmeters: state.flowmeter.flowmeters,
        isLoading: state.flowmeter.loading,
    };
};

export default connect(
    mapStateToProps,
)(FlowmeterNewButton);
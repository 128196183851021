import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import { ISPULine } from '../../../../@types/model/transformer/tariffs';
import { ITransformerInvoice, TransformerInvoiceHelper } from '../../../../@types/model/transformer/transformerInvoice';
import { DATE_INPUT_FORMAT_DEFAULT, DATE_REGEX } from '../../../../appConstants';
import DateTextInput from '../../../customComponents/input/DateTextInput';

interface ISPUCapturingFormTableRowProps {
    invoice : ITransformerInvoice<ISPULine>;
    index : number;
    lineIndex : number;
    line : ISPULine;

    isSubmitting ?: boolean;
    handleChange(e : React.ChangeEvent<unknown>) : void;
    handleBlur(e : React.FocusEvent<unknown>) : void;
    setFieldValue : (field : string, value : unknown, shouldValidate ?: boolean) => void;

    multi ?: boolean;
    selected ?: boolean;

    disabled ?: boolean;
}

interface ISPUCapturingFormTableRowState {}

export default class SPUCapturingFormTableRow extends React.PureComponent<ISPUCapturingFormTableRowProps, ISPUCapturingFormTableRowState> {
    constructor(props : ISPUCapturingFormTableRowProps) {
        super(props);
        this.state = {};
    }

    private readonly getInvoice = (props : ISPUCapturingFormTableRowProps) => props.invoice;

    private readonly calculateTotal = (invoice : ITransformerInvoice<ISPULine>) => {
        const value = TransformerInvoiceHelper.calculateSPUTotal(invoice);

        this.props.setFieldValue(`${this.props.index}`, value, true);
    };

    private readonly onFieldChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.value) return;
        
        this.props.handleChange(event);


        const invoice : ITransformerInvoice<ISPULine> = { 
            ...this.props.invoice,
            lines: [...this.props.invoice.lines],
        };

        const field = event.currentTarget.name.split('.')[2];

        const line = invoice.lines[this.props.lineIndex];
        (line[field as keyof ISPULine] as number) = Number(event.currentTarget.value);

        this.calculateTotal(invoice as ITransformerInvoice<ISPULine>);

    };

    private readonly onInvoiceFieldChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const name = event.currentTarget.name.split('.')[1];
        const value = Number(event.currentTarget.value);
        
        this.props.handleChange(event);
        
        if (!event.currentTarget.value) return;

        setTimeout(() => {
            const invoice : ITransformerInvoice<ISPULine> = { ...this.props.invoice, lines: [...this.props.invoice.lines] };
            (invoice[name as keyof ITransformerInvoice<ISPULine>] as number) = value;
    
            this.calculateTotal(invoice as ITransformerInvoice<ISPULine>);
        });
    };

    public readonly getDueDateValid = createSelector(
        [this.getInvoice],
        (invoice) => {
            return DATE_REGEX.test(invoice.paymentDueDate ?? '') && moment.utc(invoice.paymentDueDate ?? '', DATE_INPUT_FORMAT_DEFAULT, true).isValid();
        },
    );

    public readonly render = () => {
        const {
            line: value,
            index,
            isSubmitting,
            handleBlur,
            multi,
            handleChange,
            selected,
            lineIndex,
            invoice,
            setFieldValue,
        } = this.props;

        const isValidDate = this.getDueDateValid(this.props);
        const disabled = this.props.disabled || isSubmitting;

        return (
            <TableRow selected={selected} className={`data-row ${multi ? 'multi' : ''}`}>
                <TableCell align='center' className='waypoint'>
                    {value.transformer.EPNumber}
                </TableCell>
                <TableCell align='center' className='account'>
                    {value.transformer.AccountNumber}
                </TableCell>
                <TableCell className='total-usage' align='center'>
                    <input value={value.totalUsage}
                        disabled={disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].totalUsage`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any'
                        className='tac'
                    />
                </TableCell>
                <TableCell className='' align='center'>
                    <input value={value.days}
                        disabled={disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].days`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any'
                        className='tac'
                    />
                </TableCell>
                {
                    lineIndex === 0 &&
                    <TableCell className='' align='center' rowSpan={2}>
                        <DateTextInput value={invoice.paymentDueDate ?? ''}
                            disabled={disabled}
                            autoComplete='off'
                            id={`${index}.paymentDueDate`}
                            name={`${index}.paymentDueDate`}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            className={`${isValidDate ? '' : 'cr'} tac`} />
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className='' align='center' rowSpan={2}>
                        <input value={invoice.credit}
                            disabled={disabled}
                            autoComplete='off'
                            name={`${index}.credit`}
                            onChange={this.onInvoiceFieldChange}
                            onBlur={handleBlur}
                            type='number'
                            step='any'
                            className='tac'
                        />
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className={`invoice-total ${disabled ? 'disabled' : ''}`} rowSpan={2}>
                        {invoice.totalCharge}
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className={`invoice-total ${disabled ? 'disabled' : ''}`} rowSpan={2}>
                        {invoice.vatCharge}
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className={`invoice-total ${disabled ? 'disabled' : ''}`} rowSpan={2}>
                        {invoice.totalDue}
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className='invoice-total' rowSpan={2}>
                        <input value={invoice.totalBilled}
                            disabled={disabled}
                            autoComplete='off'
                            id={`${index}.totalBilled`}
                            name={`${index}.totalBilled`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type='number'
                            min='0'
                            step='0.01' />
                    </TableCell>
                }
            </TableRow>
        );
    };
}

import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import FormControl from '@material-ui/core/FormControl';
import StarterTypeSelector from '../../customComponents/selector/StarterTypeSelector';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface IEditBoreholeStarterInfoProps {
    borehole : IBorehole;

    onChange : (borehole : IBorehole) => void;
}

interface IEditBoreholeStarterInfoState {
}

export default class EditBoreholeStarterInfo extends React.Component<IEditBoreholeStarterInfoProps, IEditBoreholeStarterInfoState> {
    constructor(props : IEditBoreholeStarterInfoProps) {
        super(props);
        this.state = {
        };
    }

    private readonly onStarterTypeChange = (starterType : string) => {
        this.props.onChange({
            ...this.props.borehole,
            StarterType: starterType,
        });
    };

    private readonly onStarterChanged = (event : React.ChangeEvent<HTMLInputElement>, starter : boolean) => {
        this.props.onChange({
            ...this.props.borehole,
            Starter: starter,
            StarterType: '',
        });
    };

    public readonly render = () => {
        const { borehole } = this.props;
        return (
            <div className={'fdr flx1 aifs'}>
                <div className={'aife p5 mt10 pr20'}>
                    <FormControl margin='normal' style={{ marginTop: '16px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox color='primary' checked={borehole.Starter ?? false} onChange={this.onStarterChanged} />
                            }
                            label='Starter?'
                        />
                    </FormControl>
                </div>
                <StarterTypeSelector value={borehole.StarterType ?? ''} onChange={this.onStarterTypeChange} disabled={!borehole.Starter} />
            </div>
        );
    };
}

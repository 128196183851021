import { FormikContextType, connect } from 'formik';
import React from 'react';
import { ISPSInvoiceFormValues, SPSInvoiceHelper } from '../../../@types/model/transformer/spsInvoice';
import TextFormFastField from '../../customComponents/form/TextFormFastField';
import { InputAdornment } from '@material-ui/core';

interface ISPSInvoiceCalculatedFieldsProps {
    formik : FormikContextType<ISPSInvoiceFormValues>;
}

interface ISPSInvoiceCalculatedFieldsState {}

class SPSInvoiceCalculatedFieldsComponent extends React.PureComponent<ISPSInvoiceCalculatedFieldsProps, ISPSInvoiceCalculatedFieldsState> {
    constructor(props : ISPSInvoiceCalculatedFieldsProps) {
        super(props);
        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.formik.handleChange(event);

        setTimeout(() => {
            SPSInvoiceHelper.calculateTotals(this.props.formik);
        });
    };

    public readonly render = () => {
        const {
            isSubmitting,
            handleBlur,
        } = this.props.formik;

        return (
            <div className='fdr mt10'>
                <div className='flx1 mr10 aifs'>
                    <TextFormFastField
                        name={'peakGenerationKwh'}
                        label='Peak Units Generated'
                        fullWidth
                        type='number'
                        onChange={this.onChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    kWh
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className='flx1 ml10 mr10 aifs'>
                    <TextFormFastField
                        name={'standardGenerationKwh'}
                        label='Standard Units Generated'
                        fullWidth
                        type='number'
                        onChange={this.onChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    kWh
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className='flx1 ml10 mr10 aifs'>
                    <TextFormFastField
                        name={'offPeakGenerationKwh'}
                        label='Off-Peak Units Generated'
                        fullWidth
                        type='number'
                        onChange={this.onChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    kWh
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className='flx1 ml10 aifs'>
                    <TextFormFastField
                        name={'rates'}
                        label='Rate'
                        fullWidth
                        type='number'
                        onChange={this.onChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    R
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
        );
    };
}

const SPSInvoiceCalculatedFields = connect(SPSInvoiceCalculatedFieldsComponent);

export default SPSInvoiceCalculatedFields;

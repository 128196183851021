import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import Dialog from '@material-ui/core/Dialog';
import transformerFunctions from '../../store/transformer/functions';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import TabPanel from '../customComponents/tab/TabPanel';
import { IUserSession } from '../../@types/employee';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import Tooltip from '@material-ui/core/Tooltip';
import TransformerDetail from './view/DetailView';
import TransformerReadingView from './view/ReadingView';
import TransformerLpuReadingView from './view/LpuReadingView';
import { ITransformer } from '../../@types/model/transformer/transformer';
import PrimaryTabs from '../customComponents/tab/PrimaryTabs';
import PrimaryTab from '../customComponents/tab/PrimaryTab';
import TransformerGuaranteeListView from './guarantee/List';

interface ITransformerInfoProps {
    onClose ?: () => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    transformer ?: ITransformer;
    isLoadingTransformers : boolean;

    session ?: IUserSession | null;
}

interface ITransformerInfoState {
    tabIndex : number;
    showDelete : boolean;
}

class TransformerInfo extends React.Component<ITransformerInfoProps, ITransformerInfoState> {
    constructor(props : ITransformerInfoProps) {
        super(props);

        this.state = {
            tabIndex: 0,
            showDelete: false,
        };
    }

    private readonly onClose = () => {
        transformerFunctions.setSelected();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly onDeleteClick = () => {
        this.setState({
            showDelete: true,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.props.transformer && (typeof this.props.transformer.IsActive === 'undefined' || this.props.transformer.IsActive)) {
            transformerFunctions.deactivate(this.props.transformer.EPNumber);
        } else if (result && this.props.transformer && !this.props.transformer.IsActive) {
            transformerFunctions.activate(this.props.transformer.EPNumber);
        }
        this.setState({
            showDelete: false,
        });
    };

    public readonly render = () => {
        const { transformer, fullWidth, transition, maxWidth, fullScreen, session, isLoadingTransformers } = this.props;
        const { tabIndex, showDelete } = this.state;
        return (
            <Dialog
                open={!!transformer}
                onClose={this.onClose}
                aria-labelledby='transformer-info-dialog-title'
                aria-describedby='transformer-info-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}>
                <AppBar className='posr' position='static' elevation={0}>
                    <Toolbar variant='dense'>
                        {
                            transformer &&
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {transformer.EPNumber} - {transformer.PoleNumber}
                            </Typography>
                        }
                        <span className={'flx1'} />
                        {
                            session?.employee.IsTransformerAdmin &&
                            <Tooltip title={typeof transformer?.IsActive === 'undefined' || transformer.IsActive ? 'Deactivate' : 'Activate' }>
                                <div>
                                    <IconButton color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoadingTransformers}>
                                        <Icon>{typeof transformer?.IsActive === 'undefined' || transformer.IsActive ? 'delete' : 'check' }</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        }
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <PrimaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                        <PrimaryTab label='DETAILS' value={0} />
                        <PrimaryTab label='READINGS' value={1} />
                        <PrimaryTab label='GUARANTEES' value={2} />
                    </PrimaryTabs>
                </AppBar>
                <div className={'fdc flx1 hfill'}>
                    <TabPanel value={tabIndex} index={0}>
                        <div className='fdc flx1'>
                            {
                                transformer && session &&
                                <TransformerDetail session={session} transformer={transformer} isLoading={isLoadingTransformers} />
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <div className='fdc flx1'>
                            {
                                transformer && session && transformer.MeterType == 'SPU' &&
                                    <TransformerReadingView session={session} />
                            }
                            {
                                transformer && session && transformer.MeterType == 'LPU' &&
                                    <TransformerLpuReadingView session={session} />
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <div className='fdc flx1'>
                            {
                                transformer &&
                                <TransformerGuaranteeListView
                                    session={session}
                                    transformer={transformer}
                                />
                            }
                        </div>
                    </TabPanel>
                </div>
                {
                    transformer &&
                    <ConfirmDialog open={showDelete} title={'Confirm'}
                        message={(typeof transformer.IsActive === 'undefined' || transformer.IsActive) ? `Deactivate Transformer ${transformer.EPNumber}?` : `Activate Transformer ${transformer.EPNumber}?`}
                        onClose={this.onDelete} transition={Transitions.Up} />
                }
            </Dialog>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformer: state.transformer.transformer,
        isLoadingTransformers: state.transformer.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(TransformerInfo);

import firebaseApp from '../../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../../base';

export interface IPumpingStationFilterType extends IBasicBaseClass {
    description : string;

    isActive : boolean;
}

export default class PumpingStationFilterTypeHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station_filter_type';

    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStationFilterType> = {
        fromFirestore: (snapshot) => {
            return PumpingStationFilterTypeHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStationFilterType) : firebase.firestore.DocumentData => {
            return PumpingStationFilterTypeHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStationFilterType {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStationFilterType,
            ...result,
        };
    }

    public static toFirestoreDocument(data : IPumpingStationFilterType) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationFilterTypeHelper.COLLECTION_NAME).withConverter(PumpingStationFilterTypeHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationFilterTypeHelper.COLLECTION_NAME).withConverter(PumpingStationFilterTypeHelper.converter).doc(id);
    }

    public static save(company : IPumpingStationFilterType) {
        return PumpingStationFilterTypeHelper.doc(company.id).set(company, {
            merge: true,
        });
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(PumpingStationFilterTypeHelper.COLLECTION_NAME)
            .withConverter(PumpingStationFilterTypeHelper.converter);
    }
}
import { Typography } from '@material-ui/core';
import React from 'react';
import { IDamInspection } from '../../../../@types/model/dam/damInspection';

interface IDamInspectionOutletWorksViewProps {
    inspection : IDamInspection;
}

interface IDamInspectionOutletWorksViewState {}

export default class DamInspectionAdditionalView extends React.PureComponent<IDamInspectionOutletWorksViewProps, IDamInspectionOutletWorksViewState> {
    constructor(props : IDamInspectionOutletWorksViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { inspection } = this.props;
        
        return (
            <div className='fdr aifs jcc pt28 pb28'>
                <Typography className={'fs14 fw500 cg3'}>
                    {
                        inspection.additionalComment
                    }
                </Typography>
            </div>
        );
    };
}

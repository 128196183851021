import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';

export interface ICompany extends IBasicBaseClass {
    code : string;
    name : string;
    vatAccountNumber : string;
    electricityAccountNumber : string;
    interestAccountNumber : string | null;

    paymentEnabled : boolean | null;
}

export default class CompanyHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'company';

    private static converter : firebase.firestore.FirestoreDataConverter<ICompany> = {
        fromFirestore: (snapshot) => {
            return CompanyHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : ICompany) : firebase.firestore.DocumentData => {
            return CompanyHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : ICompany {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as ICompany,
            ...result,
            interestAccountNumber: data.interestAccountNumber ?? null,
            paymentEnabled: data.paymentEnabled ?? true,
        };
    }

    public static toFirestoreDocument(data : ICompany) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(CompanyHelper.COLLECTION_NAME).withConverter(CompanyHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(CompanyHelper.COLLECTION_NAME).withConverter(CompanyHelper.converter).doc(id);
    }

    public static save(company : ICompany) {
        return CompanyHelper.doc(company.id).set(company, {
            merge: true,
        });
    }
    
    public static listen() {
        return firebaseApp
            .firestore()
            .collection(CompanyHelper.COLLECTION_NAME)
            .withConverter(CompanyHelper.converter);
    }
}

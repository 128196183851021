import React from 'react';
import BoreholeConstantRateEntryLogisticsCardView from './ConstantRateEntryLogistcsCardView';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import AppFunctionsService from '../../../../services/appFunctionsService';
import { IBoreholeConstantRateTest } from '../../../../@types/model/borehole/boreholeConstantRateTest';

interface IBoreholeConstantRateEntryLogisticsViewProps {
    borehole : IBorehole;
    constantRateTest : IBoreholeConstantRateTest;
}

interface IBoreholeConstantRateEntryLogisticsViewState {
    
}

export default class BoreholeConstantRateEntryLogisticsView extends React.Component<IBoreholeConstantRateEntryLogisticsViewProps, IBoreholeConstantRateEntryLogisticsViewState> {
    constructor(props : IBoreholeConstantRateEntryLogisticsViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { constantRateTest, borehole } = this.props;

        return (
            <div className='fdc aifs p5'>
                <div className='fdr aic jcc'>
                    <BoreholeConstantRateEntryLogisticsCardView
                        title={'DIVISION'}
                        value={borehole.Division ?? '-'}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        title={'BOREHOLE NAME'}
                        value={borehole.Name}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        title={'WB NUMBER'}
                        value={borehole.Code}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        title={'DATE'}
                        value={AppFunctionsService.formatDateTimeToDateOnly(constantRateTest.date)}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        title={'LATITUDE'}
                        value={constantRateTest.geo[0].toFixed(9)}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        title={'LONGITUDE'}
                        value={constantRateTest.geo[1].toFixed(9)}
                    />
                </div>
                <div className='fdr aic jcc mt8'>
                    <BoreholeConstantRateEntryLogisticsCardView
                        titleHeight={50}
                        title={'DATA RECORDER PERSONNEL NUMBER'}
                        value={constantRateTest.employeeNumber}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        titleHeight={50}
                        title={'DATA RECORDER PERSONNEL NAME'}
                        value={constantRateTest.employeeName}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        titleHeight={50}
                        title={'PUMP DEPTH (m)'}
                        value={constantRateTest.pumpDepth.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        titleHeight={50}
                        title={'STATIC GROUNDWATER LEVEL (m)'}
                        value={constantRateTest.staticWaterLevel.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        titleHeight={50}
                        title={'INSTALLED PUMP'}
                        value={constantRateTest.installedPump.toFixed(2)}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        titleHeight={50}
                        title={<span>RECOMMENDED CONSTANT TEST RATE (m<sup>3</sup>/h)</span>}
                        value={constantRateTest.recommendedRate.toFixed(2)}
                    />
                </div>
                <div className='fdr aic jcc mt8'>
                    <BoreholeConstantRateEntryLogisticsCardView
                        titleHeight={50}
                        title={<span>DEWATERING YIELD (m<sup>3</sup>/h)</span>}
                        value={constantRateTest.dewateringYield?.toFixed(2) ?? '-'}
                    />
                    <span className='w5' />
                    <BoreholeConstantRateEntryLogisticsCardView
                        titleHeight={50}
                        title={'NEXT POSSIBLE BOREHOLE TO BE TESTED'}
                        value={constantRateTest.nextBorehole ?? '-'}
                    />
                </div>
            </div>
        );
    };
}

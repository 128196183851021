import { getState, dispatch } from '..';
import firebase from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../services/firebaseService';
import { transformerSetList, transformerSetLoading, transformerSetSelected, transformerSetReadingLoading, transformerSetReadings, transformerSetLpuReadings, transformerSetLpuReadingLoading } from './actions';
import lodash from 'lodash';
import generalFunctions from '../general/functions';
import uuid from 'uuid';
import TransformerHelper, { ITransformer } from '../../@types/model/transformer/transformer';
import TransformerReadingHelper from '../../@types/model/transformer/transformerReading';
import { BankAccountType } from '../../@types/model/bankAccount';
import TransformerLpuReadingHelper, { ITransformerLpuReading } from '../../@types/model/transformer/transformerLpuReading';
import moment from 'moment';
import { EnumFileUploadQueueType } from '../../@types/model/files/enum';
import TransformerMunicipalityReadingHelper from '../../@types/model/transformer/transformerMunicipalityReading';

export default class TransformerFunctions {
    private static listListener : undefined | (() => void);
    private static readingListener : undefined | (() => void);
    private static lpuReadingListener : undefined | (() => void);

    public static readonly getList = async (onLoaded ?: () => void) => {
        if (TransformerFunctions.listListener) {
            if (onLoaded) onLoaded();
            return;
        }
        dispatch(transformerSetLoading(true));
        dispatch(transformerSetList([]));

        try {
            TransformerFunctions.listListener = TransformerHelper.listen().onSnapshot((snapshot : firebase.firestore.QuerySnapshot<ITransformer>) => {
                const state = getState().transformer;

                const list = state.transformers.slice();
                const session = getState().auth.session;

                if (!session) return;

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();
                    if (
                        !session.employee.IsTransformerAdmin &&
                        session.employee.Areas.length &&
                        !session.employee.Areas.includes(item.Division)) return;

                    const index = lodash.findIndex(list, n => n.EPNumber === item.EPNumber);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(transformerSetList(list));

                if (state.transformer) {
                    TransformerFunctions.setSelected(list.find(n => n.EPNumber === state.transformer?.EPNumber), false);
                }

                dispatch(transformerSetLoading(false));
                if (onLoaded) onLoaded(); 
            });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading transformers.');
            dispatch(transformerSetLoading(false));
        }
    };

    public static readonly setSelected = (item ?: ITransformer, loadChildren = true) => {
        dispatch(transformerSetSelected(item));

        if (loadChildren || !item) {
            if (item?.MeterType == 'SPU') TransformerFunctions.getReadings(item);
            if (item?.MeterType == 'LPU') TransformerFunctions.getLpuReadings(item);
        }
    };

    public static readonly deactivate = async (id : string) => {
        const auth = getState().auth;

        if (!auth.session) return;

        dispatch(transformerSetLoading(true));

        try {
            if (!auth.session.employee.IsTransformerAdmin) {
                throw new Error('Admin rights required.');
            }

            await TransformerHelper.delete(id, auth.session);

            TransformerFunctions.setSelected();
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while deactivating transformer.');
        } finally {
            dispatch(transformerSetLoading(false));
        }

    };

    public static readonly activate = async (id : string) => {
        const auth = getState().auth;

        if (!auth.session) return;

        dispatch(transformerSetLoading(true));

        try {
            if (!auth.session.employee.IsTransformerAdmin) {
                throw new Error('Admin rights required.');
            }

            await TransformerHelper.activate(id, auth.session);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while activating transformer.');
        } finally {
            dispatch(transformerSetLoading(false));
        }

    };

    public static readonly getReadings = (transformer ?: ITransformer) => {
        if (TransformerFunctions.readingListener) TransformerFunctions.readingListener();

        dispatch(transformerSetReadings([]));

        if (!transformer) return;

        dispatch(transformerSetReadingLoading(true));
        try {
            TransformerFunctions.readingListener = TransformerReadingHelper.listen(transformer.ref).orderBy('CreatedOn', 'desc').onSnapshot((snapshot) => {
                const state = getState().transformer;

                const list = state.readings.slice();

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();

                    const index = lodash.findIndex(list, n => n.ref.id === item.ref.id);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(transformerSetReadings(list));

                dispatch(transformerSetReadingLoading(false));
            });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading transformer readings.');
            dispatch(transformerSetReadingLoading(false));
        }
    };

    public static readonly getLpuReadings = (transformer ?: ITransformer) => {
        if (TransformerFunctions.lpuReadingListener) TransformerFunctions.lpuReadingListener();

        dispatch(transformerSetLpuReadings([]));

        if (!transformer) return;

        dispatch(transformerSetLpuReadingLoading(true));
        try {
            TransformerFunctions.lpuReadingListener = TransformerLpuReadingHelper.listen(transformer.ref).onSnapshot((snapshot) => {
                const state = getState().transformer;

                const list = state.lpuReadings.slice();

                // "added" | "removed" | "modified"
                snapshot.docChanges().forEach((f) => {
                    const item = f.doc.data();

                    const index = lodash.findIndex(list, n => n.id === item.id);

                    switch (f.type) {
                        case 'added':
                            list.push(item);
                            break;
                        case 'modified':
                            list.splice(index, 1, item);
                            break;
                        case 'removed':
                            list.splice(index, 1);
                            break;
                    }
                });

                dispatch(transformerSetLpuReadings(list.sort((a, b) => b.date - a.date)));

                dispatch(transformerSetLpuReadingLoading(false));
            }, (ex) => {
                generalFunctions.generalShowErrorSnackbar('An error while loading transformer lpu readings. ' + ex.message);
                dispatch(transformerSetLpuReadingLoading(false));
            });

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while loading transformer lpu readings.');
            dispatch(transformerSetLpuReadingLoading(false));
        }
    };

    public static readonly createTransformer = async (poleNumber : string, code : string) => {
        dispatch(transformerSetLoading(true));

        const session = getState().auth.session;

        if (!session) return;
        const transformer : ITransformer = {
            GUID: uuid.v4(),
            Geom: new firebase.firestore.GeoPoint(-1, -1),
            EPNumber: code,
            PoleNumber: poleNumber,
            EPGroup: '',
            Division: '',
            SubArea: '',
            CNC: '',
            Line: '',
            PremiseId: null,
            LineVoltage: null,
            ManualReading: false,
            MeterType: null,
            EPType: null,
            PhaseSize: null,
            PoleFileName: null,
            CircuitFileName: null,
            TransformerNumber: null,
            CircuitBreaker: null,
            TransformerFileName: null,
            TransformerRating: null,
            YellowNumber: null,
            YellowNumberFileName: null,
            UsageSplits: [],
            TransformerMeterNumbers: [],
            AccountNumber: null,
            RoadNumber: null,
            IsSent: false,
            CreatedOn: firebase.firestore.Timestamp.now(),
            CreatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
            CreatedByName: session.employee.Name,
            CreatedByEmployee: session.employee.EmployeeNumber ?? '',
            UpdatedOn: firebase.firestore.Timestamp.now(),
            UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
            UpdatedByName: session.employee.Name,
            UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
            IsActive: true,
            ref: TransformerHelper.newDoc(code),
            TransmissionZone: null,
            Landrate: null,
            TransformerReaderEmployeeName: null,
            NotifiedMaxDemand: null,
            TransformerReaderEmployeeNr: null,
            BankAccount: null,
            CircuitFileThumbnailUrl: null,
            CircuitFileUrl: null,
            PoleFileThumbnailUrl: null,
            PoleFileUrl: null,
            TransformerFileThumbnailUrl: null,
            TransformerFileUrl: null,
            YellowNumberFileThumbnailUrl: null,
            YellowNumberFileUrl: null,
            Company: null,
            CurrentResident: null,
            SecurityDeposit: null,
            SecurityGuarantee: null,
            Consolidated: null,
            LinkedEp: null,
        };

        try {
            await TransformerHelper.create(transformer);
            TransformerFunctions.setSelected(transformer);
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while creating transformer. ${ex}`);
            throw ex;
        } finally {
            dispatch(transformerSetLoading(false));
        }
    };

    /** Save's transformer.
     * Sets Transformer Loading on Transformer State. Sets IsSent, UpdatedOn and UpdatedBy.
     * Returns true if success false if failure.
     */
    public static readonly saveTransformer = async (transformer : ITransformer) => {

        const session = getState().auth.session;

        if (!session) return;

        dispatch(transformerSetLoading(true));

        const value = {
            ...transformer,
            IsSent: false,
            UpdatedOn: firebase.firestore.Timestamp.now(),
            UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
            UpdatedByName: session.employee.Name,
            UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
        };

        try {
            await TransformerHelper.update(value);

            return true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving transformer. ${ex}`);
        } finally {
            dispatch(transformerSetLoading(false));
        }

        return false;

    };

    /** Save's transformer.
     * Sets Transformer Loading on Transformer State. Sets IsSent, UpdatedOn and UpdatedBy.
     * Returns true if success false if failure.
     */
    public static readonly saveLpuReading = async (reading : ITransformerLpuReading) => {

        const session = getState().auth.session;

        if (!session) return;

        dispatch(transformerSetLpuReadingLoading(true));

        if (!reading.createdBy) {
            reading.createdBy = session.firebaseUser.uid;
            reading.createdByEmployee = session.employee.EmployeeNumber ?? '';
            reading.createdByName = session.employee.Name;
            reading.createdOn = moment.utc().valueOf();
            reading.isWeb = true;
        }

        reading.updatedBy = session.firebaseUser.uid;
        reading.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        reading.updatedByName = session.employee.Name;
        reading.updatedOn = moment.utc().valueOf();
        
        try {
            await TransformerLpuReadingHelper.save(reading);

            return true;
        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar(`An error while saving reading. ${ex}`);
        } finally {
            dispatch(transformerSetLpuReadingLoading(false));
        }

        return false;

    };

    public static readonly deleteLpuReading = async (id : string) => {
        const auth = getState().auth;

        if (!auth.session) return;

        dispatch(transformerSetLpuReadingLoading(true));

        try {
            if (!auth.session.employee.IsTransformerAdmin) {
                throw new Error('Admin rights required.');
            }

            await TransformerLpuReadingHelper.delete(id);

        } catch (ex) {
            generalFunctions.generalShowErrorSnackbar('An error while deleteing LPU reading.');
        } finally {
            dispatch(transformerSetLpuReadingLoading(false));
        }

    };

    public static readonly updateBankDetails = async (
        epNumber : string, bank : string, accountNumber : string, accountType : BankAccountType, branch : string,
        beneficiaryDescription : string, recipientReference : string, ownReference : string) => {

        const transformer = getState().transformer.transformers.find(x => x.EPNumber === epNumber);

        if (!transformer) return;

        dispatch(transformerSetLoading(true));

        transformer.BankAccount = {
            accountNumber,
            bank,
            accountType,
            branch,
            beneficiaryDescription,
            recipientReference,
            ownReference,
        };

        return TransformerFunctions.saveTransformer(transformer);

    };

    /**
     * Uploads file to Firebase Selected Transformer's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     * @param errorCallback callback on error
     */
    public static readonly uploadSelectedTransformerPoleImage = async (file : File, progressCallback : (progress : number) => void, errorCallback ?: (e : Error) => void) => {
        const transformerState = getState().transformer;

        if (!transformerState.transformer) throw new Error('No selected transformer');

        const unsub = FirebaseService.uploadFileMetaData(transformerState.transformer.EPNumber, 'PoleFileName', TransformerHelper.COLLECTION_NAME, file, `electricalpoint/${transformerState.transformer.EPNumber}`)
            .on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                if (progress >= 100) {
                    unsub();
                }

                progressCallback(progress);
            }, errorCallback);
    };

    /**
     * Uploads file to Firebase Selected Transformer's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     * @param errorCallback callback on error
     */
    public static readonly uploadSelectedTransformerImage = async (file : File, progressCallback : (progress : number) => void, errorCallback ?: (e : Error) => void) => {
        const transformerState = getState().transformer;

        if (!transformerState.transformer) throw new Error('No selected transformer');

        const unsub = FirebaseService.uploadFileMetaData(transformerState.transformer.EPNumber, 'TransformerFileName', TransformerHelper.COLLECTION_NAME, file, `electricalpoint/${transformerState.transformer.EPNumber}`)
            .on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                if (progress >= 100) {
                    unsub();
                }

                progressCallback(progress);
            }, errorCallback);
    };

    /**
     * Uploads file to Firebase Selected Transformer's storage location.
     * Firebase function generateThumbnail updates appropriate field
     * once thumbnail is generated.
     * @param file File to upload
     * @param progressCallback callback for updating upload progress
     * @param errorCallback callback on error
     */
    public static readonly uploadSelectedCircuitImage = async (file : File, progressCallback : (progress : number) => void, errorCallback ?: (e : Error) => void) => {
        const transformerState = getState().transformer;

        if (!transformerState.transformer) throw new Error('No selected transformer');

        const unsub = FirebaseService.uploadFileMetaData(transformerState.transformer.EPNumber, 'CircuitFileName', TransformerHelper.COLLECTION_NAME, file, `electricalpoint/${transformerState.transformer.EPNumber}`)
            .on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                if (progress >= 100) {
                    unsub();
                }

                progressCallback(progress);
            }, errorCallback);
    };

    public static uploadReadingFile(
        transformerId : string,
        file : File,
        guid : string,
        itemNumber : number,
    ) {
        return FirebaseService.fileUpload(file, `electricalpoint/${transformerId}/readings/${file.name}`, {
            collection: TransformerReadingHelper.COLLECTION_NAME,
            fieldName: 'ImageFileUrl',
            thumbnailFieldName: 'ImageFileThumbnailUrl',
            refGuid: `${guid},${itemNumber}`,
            fileType: EnumFileUploadQueueType.TransformerSpuReading.toString(),
        });
    }

    public static uploadMunicipalityReadingFile(
        transformerId : string,
        file : File,
        guid : string,
        itemNumber : number,
    ) {
        return FirebaseService.fileUpload(file, `electricalpoint/${transformerId}/municipality_readings/${file.name}`, {
            collection: TransformerMunicipalityReadingHelper.COLLECTION_NAME,
            fieldName: 'imageFileUrl',
            thumbnailFieldName: 'imageFileThumbnailUrl',
            refGuid: `${guid},${itemNumber}`,
            fileType: EnumFileUploadQueueType.TransformerMunicipalityReading.toString(),
        });
    }
}

import { AppBar, Dialog, DialogActions, Button, DialogContent, Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Form } from 'informed';
import moment from 'moment';
import React from 'react';
import PumpingStationAuditHelper, { IPumpingStationAudit } from '../../../@types/model/pumpingStation/pumpingStationAudit';
import { IPumpingStationGroup } from '../../../@types/model/pumpingStation/pumpingStationGroup';
import GeneralFunctions from '../../../store/general/functions';
import PumpingStationFilterSizeSelector from '../../customComponents/selector/pumpingStation/PumpingStationFilterSizeSelector';
import PumpingStationFilterTypeSelector from '../../customComponents/selector/pumpingStation/PumpingStationFilterTypeSelector';
import { IUserSession } from '../../../@types/employee';
import AppFunctionsService from '../../../services/appFunctionsService';

interface IEditPumpingStationFilterProps {
    session : IUserSession;
    pumpingStationAudit : IPumpingStationAudit;
    pumpingGroup : IPumpingStationGroup;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    fullWidth ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IEditPumpingStationFilterState {
    open : boolean;
    type : string | null;
    size : number | null;
}

export default class EditPumpingStationFilter extends React.Component<IEditPumpingStationFilterProps, IEditPumpingStationFilterState> {
    constructor(props : IEditPumpingStationFilterProps) {
        super(props);
        this.state = {
            open: false,
            type: null,
            size: null,
        };
    }

    public componentDidUpdate(prevProps : Readonly<IEditPumpingStationFilterProps>, prevState : Readonly<IEditPumpingStationFilterState>) : void {
        if (!prevState.open && this.state.open) {
            this.setState({
                type: !this.props.pumpingGroup.filterBankType ? null :
                    AppFunctionsService.toTitleCase(this.props.pumpingGroup.filterBankType),
                size: this.props.pumpingGroup.filterBankSize,
            });
        }
    }

    private readonly onSizeChange = (value : number | null) => {
        this.setState({ size: value });
    };
   
    private readonly onTypeChange = (value : string | null) => {
        this.setState({ type: value });
    };

    private readonly onEditClick = () => {
        this.setState({ open: true });
    };

    private readonly onSubmit = async () => {
        
        const { session } = this.props;

        const { type, size } = this.state;

        if (!type || !size) {
            GeneralFunctions.generalShowErrorSnackbar('You have left out some information.'); 
            return;
        } 

        const pumpingStationAudit = { ...this.props.pumpingStationAudit, pumpingGroups: [ ...this.props.pumpingStationAudit.pumpingGroups ]};
        
        const pumpingGroupIndex = pumpingStationAudit.pumpingGroups.findIndex((element) => element.reference == this.props.pumpingGroup.reference);

        pumpingStationAudit.updatedBy = session.firebaseUser.uid;
        pumpingStationAudit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStationAudit.updatedByName = session.employee.Name;
        pumpingStationAudit.updatedOn = moment.utc().valueOf();

        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].filterBankType = type;
        pumpingStationAudit.pumpingGroups[pumpingGroupIndex].filterBankSize = size;

        try {
            await PumpingStationAuditHelper.save(pumpingStationAudit);

            this.onClose();
            
            GeneralFunctions.generalShowSuccessSnackbar('Saved changes!');
        } catch {
            GeneralFunctions.generalShowErrorSnackbar('Something went wrong while saving the your changes.');
        }

    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({ open: false });
    };

    public readonly render = () => {

        const { transition, maxWidth, fullScreen, fullWidth, isLoading, pumpingStationAudit, pumpingGroup } = this.props;
        const { open, type, size } = this.state;

        return (
            <>
                <Tooltip title={'Edit'}>
                    <IconButton 
                        color={'primary'} 
                        size={'small'} 
                        onClick={this.onEditClick} 
                        aria-label={'Edit'} 
                        disabled={isLoading}
                    >
                        <Icon>edit</Icon>
                    </IconButton> 
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby={'pumpingStation-info-info-title'}
                    aria-describedby={'pumpingStation-info-info-description'}
                >
                    <AppBar position='static' >
                        <Toolbar className={'fdr aic jcsb'}>
                            <Typography variant={'h5'} color={'inherit'}>
                                {`Edit ${pumpingStationAudit.pumpCode} - ${moment.utc(pumpingStationAudit.createdOn).format('DD-MM-YYYY')}: PG ${pumpingGroup.number}`}
                            </Typography>
                            <Tooltip title={'Close'}>
                                <IconButton color={'inherit'} disabled={isLoading} onClick={this.onClose}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <Form autoComplete={'off'} onSubmit={this.onSubmit} className={'fdc flx1 hfill oya'}>
                        <DialogContent className={'hfill'}>
                            <div className={'fdr flx1'}>
                                <PumpingStationFilterTypeSelector 
                                    disabled={isLoading} 
                                    value={type} 
                                    required={true}
                                    onChange={this.onTypeChange}
                                />
                                <PumpingStationFilterSizeSelector 
                                    disabled={isLoading} 
                                    value={size} 
                                    required={true}
                                    className={'ml20'}
                                    onChange={this.onSizeChange}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button type={'submit'} disabled={isLoading} variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            </>
        );
    };
}
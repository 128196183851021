import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { IUserSession } from '../../@types/employee';
import Tooltip from '@material-ui/core/Tooltip';
import PrimaryTabs from '../customComponents/tab/PrimaryTabs';
import PrimaryTab from '../customComponents/tab/PrimaryTab';
import FlowmeterFunctions from '../../store/flowmeter/functions';
import { IFlowmeter } from '../../@types/model/flowmeter/flowmeter';
import TabViews from '../customComponents/tab/TabViews';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import FlowmeterSummary from './view/FlowmeterSummary';
import FlowmeterDivision from './view/FlowmeterDivisionView';
import FlowmeterLocation from './view/FlowmeterLocationView';
import FlowmeterManualReading from './view/FlowmeterManualReadingView';
import FlowmeterInstallationAuditView from './view/FlowmeterInstallationAuditView';

interface IFlowmeterInfoProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    flowmeter ?: IFlowmeter | null;
    isLoadingFlowmeters : boolean;

    session ?: IUserSession | null;
}

interface IFlowmeterInfoState {
    tabIndex : number;
    showDelete : boolean;
}

class FlowmeterInfo extends React.PureComponent<IFlowmeterInfoProps, IFlowmeterInfoState> {
    constructor(props : IFlowmeterInfoProps) {
        super(props);

        this.state = {
            tabIndex: 0,
            showDelete: false,
        };
    }

    private readonly onClose = () => {
        FlowmeterFunctions.setSelected();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly onDeleteClick = () => {
        this.setState({
            showDelete: true,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.props.flowmeter && (typeof this.props.flowmeter.isActive === 'undefined' || this.props.flowmeter.isActive)) {
            FlowmeterFunctions.deactivate(this.props.flowmeter);
        } else if (result && this.props.flowmeter && !this.props.flowmeter.isActive) {
            FlowmeterFunctions.activate(this.props.flowmeter);
        }

        this.setState({
            showDelete: false,
        });
    }; 

    public readonly render = () => {
        const { flowmeter, fullWidth, transition, maxWidth, fullScreen, session, isLoadingFlowmeters } = this.props;
        const { tabIndex, showDelete } = this.state;

        return (
            <Dialog
                open={!!flowmeter}
                onClose={this.onClose}
                aria-labelledby='flowmeter-info-dialog-title'
                aria-describedby='flowmeter-info-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}
                PaperProps={{
                    className: 'bcg0',
                }}>
                <AppBar className='posr' position='static' elevation={0}>
                    <Toolbar variant='dense'>
                        {
                            flowmeter &&
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {flowmeter.code} - {flowmeter.name}
                            </Typography>
                        }
                        <span className={'flx1'} />
                        {
                            session?.employee.IsFlowmeterAdmin && (
                                <Tooltip title={typeof flowmeter?.isActive === 'undefined' || flowmeter.isActive ? 'Deactivate' : 'Activate'}>
                                    <div>
                                        <IconButton color={'inherit'} onClick={this.onDeleteClick} aria-label={'Delete'} disabled={isLoadingFlowmeters}>
                                            <Icon>{typeof flowmeter?.isActive === 'undefined' || flowmeter.isActive ? 'delete' : 'check'}</Icon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )
                        }
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <PrimaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                        <PrimaryTab label='SUMMARY' />
                        <PrimaryTab label='DIVISION INFO' />
                        <PrimaryTab label='LOCATION' />
                        <PrimaryTab label='INSTALLATION AUDIT' />
                        <PrimaryTab label='MANUAL READING' />
                    </PrimaryTabs>
                </AppBar>
                <div className={'fdc flx1 hfill bcg0'}>
                    {
                        flowmeter && session &&
                        <TabViews index={tabIndex}>
                            <FlowmeterSummary flowmeter={flowmeter} />
                            <FlowmeterDivision flowmeter={flowmeter} />
                            <FlowmeterLocation flowmeter={flowmeter} />
                            <FlowmeterInstallationAuditView flowmeter={flowmeter} />
                            <FlowmeterManualReading flowmeter={flowmeter} />
                        </TabViews>
                    }
                </div>
                {
                    !!flowmeter && (
                        <ConfirmDialog open={showDelete} title={'Confirm'}
                            message={`${(typeof flowmeter.isActive === 'undefined' || flowmeter.isActive) ? 'Deactivate' : 'Activate'} Pumping Station ${flowmeter.code}`}
                            onClose={this.onDelete} transition={Transitions.Up} />
                    )
                }
            </Dialog>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        flowmeter: state.flowmeter.selectedFlowmeter,
        isLoadingFlowmeters: state.flowmeter.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(FlowmeterInfo);

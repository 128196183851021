import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import { IRootState } from '../../../../@types/redux';
import { Transitions } from '../../../customComponents/animations/Transitions';
import CarouselImageViewer from '../../../customComponents/image/CarouselViewer';
import FirebaseStorageImage from '../../../customComponents/image/FirebaseStorageImage';
import EditBoreholeEP from '../../dialog/EditEP';

interface IBoreholeElectricalPointViewProps {
    borehole : IBorehole;

    isLoading : boolean;

    isBoreholeAdmin : boolean;
}

interface IBoreholeElectricalPointViewState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

class BoreholeElectricalPointViewComponent extends React.PureComponent<IBoreholeElectricalPointViewProps, IBoreholeElectricalPointViewState> {
    constructor(props : IBoreholeElectricalPointViewProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private readonly onImageUrlLoaded = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        if (index > 0) {
            imageSrcs.splice(index, 1, url);
        } else {
            imageSrcs.push(url);
        }

        this.setState({
            imageSrcs,
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { imageSrcs, imageIndex, isCarouselOpen } = this.state;
        const { isBoreholeAdmin, borehole, isLoading } = this.props;
        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc'}>
                    <div className={'fdr dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc fw600 fs16 flx1'}>
                            ELECTRICAL POINT
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isBoreholeAdmin &&
                                    <EditBoreholeEP
                                        borehole={borehole}
                                        isLoading={isLoading}
                                        fullWidth
                                        maxWidth='md'
                                        transition={Transitions.Down}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdr ml17 mr17 mb13'}>
                        <div className={'fdc mb13'}>
                            <Typography className={'fs11 bbw1 bocGrey fw500 cp pl15 pr15 pb15 aic jcc'}>
                                TRANSFORMER
                            </Typography>
                            <div className={'bcg0 fdc aic jcfs w200 p10 mt10'}>
                                <Typography className={'fs11 cdg'}>
                                    EP POINT
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {borehole.EPNumber ?? '-'}
                                </Typography>
                            </div>
                        </div>
                        <div className='brw1 ml17 mr17 bocg3'>
                        </div>
                        <div className={'fdc mb13'}>
                            <Typography className={'fs11 bbw1 bocGrey fw500 cp pl15 pr15 pb15 aic jcc'}>
                                ELECTRICAL BOX
                            </Typography>
                            <div className={'fdc flx1 aic jcc w200 p10 mt10'}>
                                {
                                    borehole.ElectricalBoxImageFileName &&
                                    <div className='fdc p15 aic jcc mw200 flx1'>
                                        <div className='fdc jcc aic flx1'>
                                            <FirebaseStorageImage
                                                url={borehole.ElectricalBoxImageUrl}
                                                thumbnailUrl={borehole.ElectricalBoxImageThumbnailUrl}
                                                fileName={borehole.ElectricalBoxImageFileName}
                                                path={`borehole/${borehole.Code}`}
                                                onClick={this.onThumbClick}
                                                onLoaded={this.onImageUrlLoaded}
                                            />
                                        </div>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </Card>
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
        isLoading: state.borehole.loading,
    };
};

const BoreholeElectricalPointView = connect(
    mapStateToProps,
)(BoreholeElectricalPointViewComponent);

export default BoreholeElectricalPointView;

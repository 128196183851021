import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { createSelector } from 'reselect';
import lodash from 'lodash';
import PumpingStationHelper from '../../../../@types/model/pumpingStation/pumpingStation';

interface IPumpingStationMotorPhaseSelectorProps {
    value : string;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : string) => void;
}

interface IPumpingStationMotorPhaseSelectorState { }

class PumpingStationMotorPhaseSelector extends React.Component<IPumpingStationMotorPhaseSelectorProps, IPumpingStationMotorPhaseSelectorState> {
    constructor(props : IPumpingStationMotorPhaseSelectorProps) {
        super(props);

        this.state = {};
    }

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? '');
    };

    private readonly getValue = (state : IPumpingStationMotorPhaseSelectorState, props : IPumpingStationMotorPhaseSelectorProps) => props.value;
    private readonly getRequired = (state : IPumpingStationMotorPhaseSelectorState, props : IPumpingStationMotorPhaseSelectorProps) => props.required;

    private readonly getMotorPhases = () => PumpingStationHelper.MOTOR_PHASES;

    private readonly getMotorPhasesForDropdown = createSelector([ 
        this.getRequired, this.getMotorPhases,
    ], (required, motorPhases) => {
        
        const dropdownData = lodash.map(motorPhases, (element) => ({ label: element, value: element }));
        
        if (!required) {
            dropdownData.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([ 
        this.getValue, this.getRequired, this.getMotorPhases,
    ], (value, required, motorPhases) => {
        if (value) {
            const motorPhase = motorPhases.find(element => element === value);

            if (motorPhase) {
                return { label: motorPhase, value: motorPhase };
            } else {
                return { label: value, value };
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { required, className, disabled } = this.props;

        const dropdownData = this.getMotorPhasesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'motorPhase'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Motor Phase'} />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };
}

export default PumpingStationMotorPhaseSelector;
import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../base';
import PumpingStationHelper from './pumpingStation';
import { IPumpingStationInspectionGroup } from './pumpingStationInspectionGroup';

export interface IPumpingStationInspectionAudit extends IBasicBaseClass {
    guid : string;
    pumpRef : string;
    pumpCode : string;
    isActive : boolean;

    jobCardNumber : string;
    date : number;

    inspectionGroups : Array<IPumpingStationInspectionGroup>;
}

export default class PumpingStationInspectionAuditHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station_inspection';

    public static switchGears = ['VSD', 'STAR-DELTA', 'Soft Starter'];
    
    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStationInspectionAudit> = {
        fromFirestore: (snapshot) => {
            return PumpingStationInspectionAuditHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStationInspectionAudit) : firebase.firestore.DocumentData => {
            return PumpingStationInspectionAuditHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStationInspectionAudit {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStationInspectionAudit,
            ...result,
            pumpRef: data.pumpRef.id,
        };
    }

    public static toFirestoreDocument(data : IPumpingStationInspectionAudit) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
            pumpRef: PumpingStationHelper.doc(data.pumpRef),
        };
    }

    public static save(pumpingStation : IPumpingStationInspectionAudit) {
        return PumpingStationInspectionAuditHelper.doc(pumpingStation.id).set(pumpingStation, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationInspectionAuditHelper.COLLECTION_NAME).withConverter(PumpingStationInspectionAuditHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationInspectionAuditHelper.COLLECTION_NAME).withConverter(PumpingStationInspectionAuditHelper.converter).doc(id);
    }
    
    public static listen(pumpingStationCode ?: string) {
        return firebaseApp
            .firestore()
            .collection(PumpingStationInspectionAuditHelper.COLLECTION_NAME)
            .where('pumpCode', '==', pumpingStationCode)
            .withConverter(PumpingStationInspectionAuditHelper.converter);
    }

    public static mapToCsv(stations : Array<IPumpingStationInspectionAudit>) {
        return stations.map(() => ({
        }));
    }
}
import { Icon, IconButton, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import moment from 'moment';
import DateRange from '../../customComponents/dateRangeComponent';
import { connect } from 'react-redux';
import { IRootState } from '../../../@types/redux';
import DamWeeklyReadingHelper, { IDamWeeklyReading } from '../../../@types/model/dam/weeklyReading';
import { StyledExpansionPanel } from '../../customComponents/StyledExpansionPanel';
import DamReadingEntryView from '../DamReadingEntryView';
import appFunctionsService from '../../../services/appFunctionsService';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import { IDam } from '../../../@types/model/dam/dam';
import { createSelector } from 'reselect';
import EditDamReadingDialog from '../dialog/EditReadingDialog';

interface IDamReadingProps {
    readings : Array<IDamWeeklyReading>;
    isLoading : boolean;
    session : IUserSession;
    dam : IDam;
}

interface IDamReadingState {
    dateFrom : moment.Moment;
    dateTo : moment.Moment;
    showDelete : boolean;
    showEdit : boolean;
    selectedReading : IDamWeeklyReading | null;
}

class DamReadingView extends React.PureComponent<IDamReadingProps, IDamReadingState> {
    constructor(props : IDamReadingProps) {
        super(props);
        this.state = {
            dateFrom: moment.utc().startOf('month'),
            dateTo: moment.utc().endOf('month').subtract(1, 'days'),
            showDelete: false,
            showEdit: false,
            selectedReading: null,
        };
    }

    private readonly onDateSelectionChange = (start : moment.Moment | null, end : moment.Moment | null) => {
        this.setState({
            dateFrom: !start ? moment.utc().startOf('month') : moment(start),
            dateTo: !end ? moment.utc().endOf('month').subtract(1, 'days') : moment(end),
        });
    };

    private readonly onDeleteClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const selectedReading = this.props.readings.slice().find(n => n.id === event.currentTarget.value) ?? null;

        event.stopPropagation();
        this.setState({
            selectedReading: selectedReading,
            showDelete: true,
        });
    };
    

    private readonly onEditClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        const selectedReading = this.props.readings.slice().find(n => n.id === event.currentTarget.value) ?? null;

        event.stopPropagation();
        this.setState({
            selectedReading: selectedReading,
            showEdit: true,
        });
    };

    private readonly onEditClose = () => {
        this.setState({
            showEdit: false,
        });
    };

    private readonly onDelete = (result : boolean) => {
        const selectedReading = this.state.selectedReading;

        if (selectedReading && result) {
            DamWeeklyReadingHelper.delete(selectedReading.id);
        }
        this.setState({
            showDelete: false,
        });
    };

    public readonly getReadings = (state : IDamReadingState, props : IDamReadingProps) => props.readings;
    public readonly getFromDate = (state : IDamReadingState) => state.dateFrom;
    public readonly getToDate = (state : IDamReadingState) => state.dateTo;

    public readonly filterReadings = createSelector(
        [this.getReadings, this.getFromDate, this.getToDate], (readings, dateFrom, dateTo) => {
            return readings.filter(n => n.date.toMillis() >= dateFrom.valueOf() && n.date.toMillis() <= dateTo.valueOf());
        }
    );
    public readonly render = () => {
        const { isLoading, session } = this.props;
        const { dateTo, dateFrom, showDelete, showEdit } = this.state;
        const filteredReadings = this.filterReadings(this.state, this.props);

        return (
            <div className='hfill fdc pt20 pb20 pl20 pr20 bcg0 oya'>
                <div className={'aic p5 pr20 mb10'}>
                    <DateRange onChange={this.onDateSelectionChange} start={dateFrom} end={dateTo} />
                </div>
                <Typography className={'fs28 cp fw5s00'}>Readings</Typography >
                <div className={'ml50 mr50'} >
                    { isLoading && <LinearProgress className={'wfill mt5'} />}
                    {
                        !isLoading && filteredReadings.length === 0 &&
                            <div>
                                <Typography className={'mb10'} color='inherit' variant='h5'>
                                    No Reading Data Exists!
                                </Typography>
                            </div>
                    }
                    {
                        filteredReadings.map((n) => (
                            <StyledExpansionPanel                             
                                key={n.guid}
                                heading={appFunctionsService.formatDateTimeToDateOnly(n.createdOn)}
                                actions={(expanded) => (
                                    <div className={'fdr'}>
                                        {  
                                            session.employee.IsDamAdmin && 
                                            <IconButton value={n.id} onClick={this.onDeleteClick} className={`${expanded ? 'cw p0' : 'cp p0'} pl15`}>
                                                <Icon>delete</Icon> 
                                            </IconButton>
                                        }
                                        {  
                                            session.employee.IsDamAdmin && 
                                            <IconButton value={n.id} className={`${expanded ? 'cw p0' : 'cp p0'} pl15`} onClick={this.onEditClick}>
                                                <Icon>
                                                    edit
                                                </Icon>
                                            </IconButton>
                                        }
                                    </div>
                                )}
                            >
                                <DamReadingEntryView reading={n}/>
                            </StyledExpansionPanel>
                        ))  
                    }
                </div>
                <ConfirmDialog open={showDelete} title={'Confirm'}
                    message={'Delete Weekly Reading?'}
                    onClose={this.onDelete} transition={Transitions.Up} />
                <EditDamReadingDialog showEdit={showEdit}
                    onClose={this.onEditClose} reading={this.state.selectedReading}/>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        readings: state.dam.readings,
        isLoading: state.dam.loadingReadings,
    };
};

export default connect(
    mapStateToProps,
)(DamReadingView);
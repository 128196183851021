import React from 'react';

interface IInputMaskProps {
    for : string;
    mask : string;
    className ?: string;
}

interface IInputMaskState {}

export default class InputMask extends React.PureComponent<IInputMaskProps, IInputMaskState> {
    constructor(props : IInputMaskProps) {
        super(props);
        this.state = {};
    }

    private readonly onFocus = () => {
        document.getElementById(this.props.for)?.focus();
    };

    public readonly render = () => {
        const { className, mask } = this.props;

        return (
            <span onFocus={this.onFocus} onClick={this.onFocus} className={`${className} posa`} style={{
                opacity: 0.8,
            }}>
                {mask}
            </span>
        );
    };
}

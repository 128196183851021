import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import lodash from 'lodash';
import DamHelper, { EnumDamSlopeSurfaceProtection } from '../../../../@types/model/dam/dam';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField } from '@material-ui/core';
import { createSelector } from 'reselect';

export interface IDamSlopeProtectionDropDownOption {
    label : string;
    value : EnumDamSlopeSurfaceProtection | null;
}

interface IDamSlopeProtectionSelectorProps {
    value : EnumDamSlopeSurfaceProtection | null;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : EnumDamSlopeSurfaceProtection | null) => void;
}

interface IDamSlopeProtectionSelectorState { }

class DamSlopeProtectionSelector extends React.Component<IDamSlopeProtectionSelectorProps, IDamSlopeProtectionSelectorState> {
    constructor(props : IDamSlopeProtectionSelectorProps) {
        super(props);

        this.state = {};
    }   

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? null);
    };

    private readonly getValue = (state : IDamSlopeProtectionSelectorState, props : IDamSlopeProtectionSelectorProps) => props.value;
    private readonly getRequired = (state : IDamSlopeProtectionSelectorState, props : IDamSlopeProtectionSelectorProps) => props.required;

    private readonly getSlopeProtections = () => DamHelper.DamSlopeSurfaceProtection;

    private readonly getSlopeProtectionsForDropdown = createSelector([
        this.getRequired, this.getSlopeProtections,
    ], (required, wallProtections) => {
        const dropdownData = lodash.map(wallProtections, (element, index) => ({ label: element, value: lodash.parseInt(index) } as IDamSlopeProtectionDropDownOption));

        if (!required) {
            dropdownData.unshift({
                label: 'NONE',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getSlopeProtections,
    ], (value, required, wallProtections) => {
        if (value != null) {
            const wallProtection = wallProtections[value];

            if (wallProtection) {
                return { label: wallProtection, value: value } as IDamSlopeProtectionDropDownOption;
            }
        }
        return null;
    });

    public readonly render = () => {

        const { required, className, disabled } = this.props;

        const dropdownData = this.getSlopeProtectionsForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <FormControl margin={'normal'} fullWidth className={className} disabled={disabled} error={required && !value}>
                <Autocomplete 
                    id={'wallProtection'}
                    options={dropdownData}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth label={'Slope Surface Protections'} />}  
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
            </FormControl>
        );
    };

}

export default DamSlopeProtectionSelector;
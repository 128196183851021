import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { IUserSession } from '../../../@types/employee';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { Transitions } from '../../customComponents/animations/Transitions';
import EditTransformerDivisionInfo from '../dialog/EditDivision';

interface ITransformerDivisionViewProps {
    transformer : ITransformer;
    isLoading : boolean;
    session : IUserSession;
}

interface ITransformerDivisionViewState {}

export default class TransformerDivisionView extends React.PureComponent<ITransformerDivisionViewProps, ITransformerDivisionViewState> {
    constructor(props : ITransformerDivisionViewProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { transformer, isLoading, session } = this.props;
        return (
            <div className='flx1 fdr aifs pt20 pb20 bcg0'>
                <span className='flx1' />
                <Paper className='fdc flx2' elevation={3}>
                    <AppBar position='static' className='pbr' elevation={0}>
                        <Toolbar>
                            <Typography className='fwm fs18'>
                                AREA INFO
                            </Typography>
                            <span className='flx1' />
                            {
                                session.employee.IsTransformerAdmin &&
                                <EditTransformerDivisionInfo transformer={transformer} transition={Transitions.Up} maxWidth='md' fullWidth isLoading={isLoading}/>
                            }
                        </Toolbar>
                    </AppBar>
                    <div className='fdc'>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                AREA
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                SUB AREA
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.Division}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bbw1 bocg0'} variant='subtitle1'>
                                {transformer.SubArea}
                            </Typography>
                        </div>
                        <div className={'fdr pl10 pr10 pt10 mb10'}>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                CNC
                            </Typography>
                            <Typography className={'flx1 fs13 lh37 ls065 cg3'} variant='subtitle1'>
                                COMPANY
                            </Typography>
                        </div>
                        <div className={'fdr mb10 pl10 pr10'}>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {transformer.CNC}
                            </Typography>
                            <Typography className={'flx1 fs16 lh37 fw700 bocg0'} variant='subtitle1'>
                                {transformer.Company?.name ?? '-'}
                            </Typography>
                        </div>
                    </div>
                </Paper>
                <span className='flx1' />
            </div>
        );
    };
}

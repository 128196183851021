import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IRootState } from '../../../@types/redux';
import DataFunctions from '../../../store/data/functions';
import lodash from 'lodash';
import TextField from '@material-ui/core/TextField';
import IDepartment from '../../../@types/model/department';

interface IDepartmentSelectorProps {
    id ?: string;
    fullWidth ?: boolean;
    required ?: boolean;
    isLoading : boolean;
    disabled ?: boolean;

    value ?: string | IDepartment;
    onChange ?: (value ?: IDepartment) => void;

    departments : Array<IDepartment>;
}

interface IDepartmentSelectorState {}

class DepartmentSelector extends React.PureComponent<IDepartmentSelectorProps, IDepartmentSelectorState> {
    constructor(props : IDepartmentSelectorProps) {
        super(props);
        this.state = {};
    }

    public readonly componentDidMount = () => {
        DataFunctions.getDepartments();
    };
    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : string;
    } | null) => {

        if (this.props.onChange) this.props.onChange(this.props.departments.find(x => x.GL === value?.value));
    };

    private readonly getData = (props : IDepartmentSelectorProps) => props.departments;
    private readonly getValue = (props : IDepartmentSelectorProps) => props.value;
    private readonly getRequired = (props : IDepartmentSelectorProps) => props.required;

    private readonly getDepartments = createSelector([
        this.getData,
    ], (departments) => {
        return lodash
            .chain(departments)
            .sort((a, b) => a.GL.localeCompare(b.GL))
            .value();
    });

    private readonly getDepartmentSelector = createSelector([
        this.getRequired,
        this.getDepartments,
    ], (required, departments) => {
        const drop = lodash.map(departments, (n) => ({ label: `${n.GL} - ${n.CUSTDESC}`, value: n.GL }));

        if (!required) {
            drop.unshift({
                label: 'ALL',
                value: '',
            });
        }

        return drop;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue, this.getRequired, this.getDepartmentSelector,
    ], (value, required, dropdown) => {
        if (value) {
            if (typeof value === 'string') {
                const department = dropdown.find(x => x.value === value);

                if (department) {
                    return department;
                } else {
                    return { label: value, value };
                }
            } else {
                const department = dropdown.find(x => x.value === value.GL);

                if (department) {
                    return department;
                } else {
                    return { label: `${value.GL} - ${value.CUSTDESC}`, value: value.GL };
                }
            }
        }

        if (!value && !required) {
            return {
                label: 'ALL',
                value: '',
            };
        }

        return null;
    });

    public readonly render = () => {
        const { isLoading, required, fullWidth, disabled, id } = this.props;

        const departments = this.getDepartmentSelector(this.props);

        const value = this.getSelectedValue(this.props);
        return (
            <FormControl fullWidth={fullWidth}>
                <Autocomplete
                    disabled={disabled || isLoading}
                    id={id}
                    options={departments}
                    value={value}
                    getOptionSelected={(option, val) => option.value === val.value}
                    getOptionLabel={option => option.label}
                    onChange={this.onChange}
                    disableClearable={required}
                    openOnFocus
                    renderInput={params => <TextField error={required && !value} {...params} fullWidth={fullWidth} label='Department' />}
                />
                {
                    required && !value &&
                    <FormHelperText error>Required</FormHelperText>
                }
                {
                    isLoading &&
                    <LinearProgress />
                }
            </FormControl>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.data.isLoadingDepartments,
        departments: state.data.departments,
    };
};

export default connect(
    mapStateToProps,
)(DepartmentSelector);

import React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../@types/redux';
import { IMottech } from '../../@types/mottech';
import Dialog from '@material-ui/core/Dialog';
import mottechFunctions from '../../store/mottech/functions';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../customComponents/tab/TabPanel';
import { IUserSession } from '../../@types/employee';
import ConfirmDialog from '../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../customComponents/animations/Transitions';
import Tooltip from '@material-ui/core/Tooltip';
import MottechDetailView from './view/DetailView';
import MottechAuditView from './view/AuditView';
import MottechInstallationAuditView from './view/InstallationAuditView';

interface IMottechInfoProps {
    onClose ?: () => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    mottech ?: IMottech;
    isLoadingMottechs : boolean;

    session ?: IUserSession | null;
}

interface IMottechInfoState {
    tabIndex : number;
    showDelete : boolean;
}

class MottechInfo extends React.Component<IMottechInfoProps, IMottechInfoState> {
    constructor(props : IMottechInfoProps) {
        super(props);

        this.state = {
            tabIndex: 0,
            showDelete: false,
        };
    }

    private readonly onClose = () => {
        mottechFunctions.setSelected();
    };

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    private readonly onDeleteClick = () => {
        this.setState({
            showDelete: true,
        });
    };

    private readonly onDelete = (result : boolean) => {
        if (result && this.props.mottech) {
            mottechFunctions.deactivate(this.props.mottech.Code);
        }
        this.setState({
            showDelete: false,
        });
    };

    public readonly render = () => {
        const { mottech, fullWidth, transition, maxWidth, fullScreen, session, isLoadingMottechs } = this.props;
        const { tabIndex, showDelete } = this.state;
        return (
            <Dialog
                open={!!mottech}
                onClose={this.onClose}
                aria-labelledby='mottech-info-dialog-title'
                aria-describedby='mottech-info-description'
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                fullScreen={fullScreen}>
                <AppBar className='posr' position='static'>
                    <Toolbar variant='dense'>
                        {
                            mottech &&
                            <Typography variant='h6' color='inherit' className='flx1'>
                                {mottech.Code}
                            </Typography>
                        }
                        <span className={'flx1'} />
                        {
                            session && session.employee.IsMottechAdmin &&
                            <Tooltip title='Delete'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onDeleteClick} aria-label='Delete' disabled={isLoadingMottechs}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        }
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                    <Tabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                        <Tab label='Details' value={0} />
                        <Tab label='Audits' value={1} />
                        <Tab label='Installation Audits' value={2} />
                    </Tabs>
                </AppBar>
                <div className={'fdc flx1 hfill oys'}>
                    <TabPanel value={tabIndex} index={0}>
                        <div>
                            {
                                session && mottech &&
                                <MottechDetailView session={session} isLoading={isLoadingMottechs} mottech={mottech} />
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <div>
                            {
                                session && mottech &&
                                <MottechAuditView session={session} />
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <div>
                            {
                                session && mottech &&
                                <MottechInstallationAuditView session={session} />
                            }
                        </div>
                    </TabPanel>
                </div>
                <ConfirmDialog open={showDelete} title={!mottech ? '' : `Confirm Deactivating ${mottech.Code}`}
                    message={!mottech ? '' : `Deactivate Mottech ${mottech.Code}?`}
                    onClose={this.onDelete} transition={Transitions.Up}/>
            </Dialog>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        mottech: state.mottech.mottech,
        isLoadingMottechs: state.mottech.loading,
        session: state.auth.session,
    };
};

export default connect(
    mapStateToProps,
)(MottechInfo);

import React, { useEffect, useState } from 'react';
import { AppBar, Dialog, DialogContent, Icon, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { IUserSession } from '../../../../@types/employee';
import { IRainMeterMeasurement } from '../../../../@types/model/rainMeter/rainMeterMeasurement/rainMeterMeasurement';
import { IRainMeterMeasurementFormValues } from '../../../../@types/model/rainMeter/rainMeterMeasurement/rainMeterMeasurementFormValues';
import RainMeterMeasurementModelHelper from '../../../../@types/model/rainMeter/rainMeterMeasurement/rainMeterMeasurementModelHelper';
import GeneralFunctions from '../../../../store/general/functions';
import { IRainMeter } from '../../../../@types/model/rainMeter/rainMeter/rainMeter';
import RainMeterMeasurementEditForm from './RainMeterMeasurementEditForm';
import { Transitions } from '../../../customComponents/animations/Transitions';

interface IRainMeterEditDialogProps {
    currentUser ?: IUserSession | null;
    selectedRainMeterMeasurement ?: IRainMeterMeasurement | null;
    selectedRainMeter ?: IRainMeter | null;
    isOpen : boolean;
    onDismiss : () => void;
}

const RainMeterEditDialog = (props : IRainMeterEditDialogProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [entrySaved, setEntrySaved] = useState<boolean>(false);

    /*================================================================================================================
     *                                                      Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (entrySaved) {
            setEntrySaved(false);
            props.onDismiss();   
        } 

    }, [entrySaved]);

    /*================================================================================================================
    *                                                  Handler Methods
    * ==============================================================================================================*/

    const onSubmit = async (formValues : IRainMeterMeasurementFormValues) : Promise<void> => {
        setIsLoading(true);

        if (!props.currentUser) {
            setIsLoading(false);
            return;
        }
        
        const RainMeter = RainMeterMeasurementModelHelper.createSave(props.currentUser, formValues, props.selectedRainMeterMeasurement);

        try {
            await RainMeterMeasurementModelHelper.save(RainMeter);
            setIsLoading(false);
            setEntrySaved(true);
            GeneralFunctions.generalShowSuccessSnackbar('Rain Meter Measurement updated successfully.');

        } catch (e) {
            GeneralFunctions.generalShowErrorSnackbar('An error occurred while saving Rain Meter Measurement.');
            setIsLoading(false);
        }
    };

    const getInitialFormValues = () : IRainMeterMeasurementFormValues => {
        return RainMeterMeasurementModelHelper.createFormValues(props.selectedRainMeter?.ref!, props.selectedRainMeterMeasurement);
    };

    /*================================================================================================================
    *                                                  Memos
    * ==============================================================================================================*/

    /*================================================================================================================
    *                                                  Render
    * ==============================================================================================================*/


    return (
        <>
            <Dialog
                open={props.isOpen}
                TransitionComponent={Transitions.Up}
                transitionDuration={500}
                onClose={props.onDismiss}
                maxWidth={'xs'}
                aria-labelledby='rain-meter-edit-dialog-title'
                aria-describedby='rain-meter-edit-description'>
                <AppBar className='fdr posr aic jcc' position='static'>
                    <Toolbar className={'fdr flx1 aic jcc'}>
                        <Typography variant='h5' color='inherit'>
                            Edit Rain Meter Measurement
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' disabled={isLoading} onClick={props.onDismiss} aria-label='Close'>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent className='fdc flx1 jcfs hfill'>
                    <RainMeterMeasurementEditForm 
                        initialValues={getInitialFormValues()} 
                        onSubmit={onSubmit} 
                        onCancel={props.onDismiss} 
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default RainMeterEditDialog;
import React from 'react';
import lodash, { isNumber } from 'lodash';
import FlowmeterHelper, { EnumFlowmeterWaterUsage } from '../../../../@types/model/flowmeter/flowmeter';
import { Autocomplete } from '@material-ui/lab';
import { createSelector } from 'reselect';
import { TextField } from '@material-ui/core';

export interface IFlowmeterWaterUsageDropDownOption {
    label : string;
    value : EnumFlowmeterWaterUsage | null;
}

interface IFlowmeterWaterUsageSelectorProps {
    value : EnumFlowmeterWaterUsage | null;
    disabled ?: boolean;
    className ?: string;
    required ?: boolean;
    onChange : (value : EnumFlowmeterWaterUsage | null) => void;
}

interface IFlowmeterWaterUsageSelectorState { }

class FlowmeterWaterUsageSelector extends React.Component<IFlowmeterWaterUsageSelectorProps, IFlowmeterWaterUsageSelectorState> {
    constructor(props : IFlowmeterWaterUsageSelectorProps) {
        super(props);

        this.state = {};
    }   

    private readonly onChange = (event : React.ChangeEvent<unknown>, value : {
        label : string;
        value : number | null;
    } | null | undefined) => {
        this.props.onChange(value?.value ?? null);
    };

    private readonly getValue = (state : IFlowmeterWaterUsageSelectorState, props : IFlowmeterWaterUsageSelectorProps) => props.value;
    private readonly getRequired = (state : IFlowmeterWaterUsageSelectorState, props : IFlowmeterWaterUsageSelectorProps) => props.required;

    private readonly getFlowmeterWaterUsagesForDropdown = createSelector([
        this.getRequired,
    ], (required,) => {
        const dropdownData : Array<IFlowmeterWaterUsageDropDownOption> = lodash
            .chain(EnumFlowmeterWaterUsage)
            .filter(x => isNumber(x))
            .map((element) => ({
                label: FlowmeterHelper.EnumFlowMeterWaterUsageToString[element],
                value: Number(element),
            }))
            .value();

        if (!required) {
            dropdownData.unshift({
                label: 'None',
                value: null,
            });
        }
        
        return dropdownData;
    });

    private readonly getSelectedValue = createSelector([
        this.getValue,
    ], (
        value,
    ) => {
        if (value != null) {
            const result = FlowmeterHelper.EnumFlowMeterWaterUsageToString[value];

            if (result) {
                return {
                    label: result,
                    value: value,
                } as IFlowmeterWaterUsageDropDownOption;
            }
        }

        return null;
    });

    public readonly render = () => {

        const {
            required,
            disabled,
        } = this.props;

        const dropdownData = this.getFlowmeterWaterUsagesForDropdown(this.state, this.props);

        const value = this.getSelectedValue(this.state, this.props);

        return (
            <Autocomplete
                options={dropdownData}
                value={value}
                getOptionSelected={(option, val) => option.value === val.value}
                getOptionLabel={option => option.label}
                onChange={this.onChange}
                disableClearable={required}
                openOnFocus
                disabled={disabled}
                renderInput={params => (
                    <TextField
                        error={required && !value}
                        {...params}
                        required={required}
                        fullWidth
                        label={'Water Usage Type'}
                    />
                )}  
            />
        );
    };

}

export default FlowmeterWaterUsageSelector;
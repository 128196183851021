import firebaseApp from '../../../../services/firebaseService';
import { BasicBaseHelper, IBasicBaseClass } from '../../base';

export interface IPumpingStationMotorSize extends IBasicBaseClass {
    size : number;

    isActive : boolean;
}

export default class PumpingStationMotorSizeHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'pumping_station_motor_size';

    private static converter : firebase.firestore.FirestoreDataConverter<IPumpingStationMotorSize> = {
        fromFirestore: (snapshot) => {
            return PumpingStationMotorSizeHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IPumpingStationMotorSize) : firebase.firestore.DocumentData => {
            return PumpingStationMotorSizeHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IPumpingStationMotorSize {
        const result = super.fromFirestore(snapshot);
        const data = snapshot.data();

        if (!snapshot.exists || !result || !data) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data as IPumpingStationMotorSize,
            ...result,
        };
    }

    public static toFirestoreDocument(data : IPumpingStationMotorSize) {
        const result = super.toFirestore(data);
        const { id:_id, ...rest } = data;

        return {
            ...rest,
            ...result,
        };
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(PumpingStationMotorSizeHelper.COLLECTION_NAME).withConverter(PumpingStationMotorSizeHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(PumpingStationMotorSizeHelper.COLLECTION_NAME).withConverter(PumpingStationMotorSizeHelper.converter).doc(id);
    }

    public static save(company : IPumpingStationMotorSize) {
        return PumpingStationMotorSizeHelper.doc(company.id).set(company, {
            merge: true,
        });
    }
    
    public static collection() {
        return firebaseApp
            .firestore()
            .collection(PumpingStationMotorSizeHelper.COLLECTION_NAME)
            .withConverter(PumpingStationMotorSizeHelper.converter);
    }
}
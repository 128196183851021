import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { IPumpingStation } from '../../../@types/model/pumpingStation/pumpingStation';
import PumpingStationFunctions from '../../../store/pumpingStation/functions';
import TextField from '@material-ui/core/TextField';
import DivisionSelector from '../../customComponents/selector/DivisionSelector';

interface IEditPumpingStationDivisionProps {
    pumpingStation : IPumpingStation;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    isLoading ?: boolean;
}

interface IEditPumpingStationDivisionState {
    open : boolean;
    division : string;
    manArea : string;
    divisionNo : string;
    accPaccDesc : string;
}

export default class EditPumpingStationDivision extends React.Component<IEditPumpingStationDivisionProps, IEditPumpingStationDivisionState> {
    constructor(props : IEditPumpingStationDivisionProps) {
        super(props);
        this.state = {
            open: false,
            division: '',
            manArea: '',
            divisionNo: '',
            accPaccDesc: '',
        };
    }   

    public readonly componentDidUpdate = (prevProps : IEditPumpingStationDivisionProps, prevState : IEditPumpingStationDivisionState) => {
        if (prevState.open && !this.state.open) {
            this.setState({
                division: '',
                manArea: '',
                divisionNo: '',
                accPaccDesc: '',
            });
        }
        if (!prevState.open && this.state.open) {
            this.setState({
                division: !this.props.pumpingStation.division ? '' : this.props.pumpingStation.division,
                manArea: !this.props.pumpingStation.managementArea ? '' : this.props.pumpingStation.managementArea,
                divisionNo: !this.props.pumpingStation.divisionNo ? '' : this.props.pumpingStation.divisionNo,
                accPaccDesc: !this.props.pumpingStation.accpacDesc ? '' : this.props.pumpingStation.accpacDesc,
            });
        }
    };

    private readonly save = async () => {
        const { division, manArea, divisionNo, accPaccDesc} = this.state;

        const pumpingStation = Object.assign({}, this.props.pumpingStation);

        pumpingStation.division = division;
        pumpingStation.managementArea = manArea;
        pumpingStation.divisionNo = divisionNo;
        pumpingStation.accpacDesc = accPaccDesc;
        const result = await PumpingStationFunctions.savePumpingStation(pumpingStation);

        if (result) {
            this.setState({
                open: false,
            });
        }
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly onDivisionChanged = (value : string) => {
        this.setState({
            division: value,
        });
    };

    private readonly onManAreaChanged = (value : string) => {
        this.setState({
            manArea: value,
        });
    };

    private readonly onDivisionNumberChanged = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            divisionNo: event.target.value,
        });
    };

    private readonly onAccPaccDescChanged = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            accPaccDesc: event.target.value,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onEditClick = () => {
        this.setState({
            open: true,
        });
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, isLoading } = this.props;
        const { open, manArea, division, divisionNo, accPaccDesc} = this.state;
        return (
            <React.Fragment>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onEditClick} aria-label='Edit' disabled={isLoading}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    aria-labelledby='pumpingStation-info-info-title'
                    aria-describedby='pumpingStation-info-info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit Pumping Station Info
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' disabled={isLoading} onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill'>
                        <DialogContent className='fdc flx1 hfill'>
                            <div className={'fdr flx1 aifs jcc aic'}>
                                <DivisionSelector division={division} manArea={manArea} onDivisionChanged={this.onDivisionChanged} onManAreaChanged={this.onManAreaChanged}/>
                                <TextField 
                                    onChange={this.onDivisionNumberChanged}
                                    value={divisionNo}
                                    label={'Division Number'}
                                    className={'pl10 pr10'}
                                />
                                <TextField 
                                    onChange={this.onAccPaccDescChanged}
                                    value={accPaccDesc}
                                    label={'AccPac Description'}
                                    className={'pl10 pr10 pb3'}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={isLoading} type='submit' variant='contained' color='primary'>
                                OK
                            </Button>
                            <Button disabled={isLoading} variant='outlined' onClick={this.onClose} color='primary' autoFocus>
                                CANCEL
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </React.Fragment>
        );
    };
}

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { IDivision } from '../../../@types/model/masterData/division';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import dataFunctions from '../../../store/data/functions';

interface IDivisionSelectorProps {
    division : string;
    manArea ?: string;
    divisions : Array<IDivision>;
    isLoading : boolean;

    onDivisionChanged : (divisionName : string, division ?: IDivision) => void;
    onManAreaChanged ?: (manArea : string) => void;
}

interface IDivisionSelectorState {
    manAreas : Array<string>;
}

class DivisionSelector extends React.Component<IDivisionSelectorProps, IDivisionSelectorState> {
    constructor(props : IDivisionSelectorProps) {
        super(props);
        this.state = {
            manAreas: [],
        };
    }

    public readonly componentDidMount = () => {
        dataFunctions.getDivisions();

        if (this.props.division) {
            this.setManAreas(this.props.division);
        }
    };

    public readonly componentDidUpdate = (prevProps : IDivisionSelectorProps) => {
        if (prevProps.division !== this.props.division && this.props.division) {
            this.setManAreas(this.props.division);
        }

        if (prevProps.divisions.length !== this.props.divisions.length && this.props.division) {
            this.setManAreas(this.props.division);
        }
    };

    private readonly onDivisionChanged = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {

        const value = event.target.value;

        if (typeof value !== 'string') return;

        this.props.onDivisionChanged(value, this.props.divisions.find(x => x.Id === value));
        if (this.props.onManAreaChanged) this.props.onManAreaChanged('');
        this.setManAreas(value);
    };

    private readonly setManAreas = (value : string) => {
        const division = this.props.divisions.find(n => n.Name === value);

        if (division) {
            this.setState({
                manAreas: division.ManagementArea.slice(),
            });
        }
    };

    private readonly onManAreaChanged = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (this.props.onManAreaChanged) this.props.onManAreaChanged(event.target.value as string);
    };

    public readonly render = () => {
        const { division, manArea, divisions, isLoading } = this.props;
        const { manAreas } = this.state;
        return (<div className='fdr flx1 aifs'>
            <div className={'flx1 aifs pr10'}>
                <FormControl fullWidth>
                    <InputLabel shrink={!!division} htmlFor='division'>Division</InputLabel>
                    <Select
                        disabled={isLoading}
                        fullWidth
                        id='division'
                        value={!division || !divisions.length ? '' : division}
                        onChange={this.onDivisionChanged}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(divisions, (n, i) => (
                                <MenuItem key={`${i}_division`} value={`${n.Name}`}>
                                    {n.Name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
            {
                !!this.props.onManAreaChanged &&
                <div className={'flx1 aifs pl10'}>
                    <FormControl fullWidth>
                        <InputLabel shrink={!!manArea} htmlFor='manArea'>Management Area</InputLabel>
                        <Select
                            disabled={isLoading}
                            fullWidth
                            id='manArea'
                            value={!manArea || !manAreas.length ? '' : manArea}
                            onChange={this.onManAreaChanged}>
                            <MenuItem value=''>
                                <em>None</em>
                            </MenuItem>
                            {
                                lodash.map(manAreas, (n, i) => (
                                    <MenuItem key={`${i}_manArea`} value={`${n}`}>
                                        {n}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>
            }
        </div>);
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        divisions: state.data.divisions,
        isLoading: state.data.loadingDivisions,
    };
};

export default connect(
    mapStateToProps,
)(DivisionSelector);

import React from 'react';
import { IDamInspection } from '../../../@types/model/dam/damInspection';
import SecondaryTab from '../../customComponents/tab/SecondaryTab';
import SecondaryTabs from '../../customComponents/tab/SecondaryTabs';
import TabViews from '../../customComponents/tab/TabViews';
import DamInspectionCrestView from './inspection/DamInspectionCrestView';
import { createSelector } from 'reselect';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import lodash from 'lodash';
import DamInspectionUpstreamSlopeView from './inspection/DamInspectionUpstreamSlopeView';
import DamInspectionDownstreamSlopeView from './inspection/DamInspectionDownstreamSlopeView';
import DamInspectionWallDownstreamView from './inspection/DamInspectionWallDownstreamView';
import DamInspectionSpillwayView from './inspection/DamInspectionSpillwayView';
import DamInspectionOutletWorksView from './inspection/DamInspectionOutlet';
import DamInspectionPublicSafetyView from './inspection/DamInspectionPublicSafetyView';
import DamInspectionAdditionalView from './inspection/DamInspectionAdditional';

interface IDamInspectionEntryViewProps {
    inspection : IDamInspection;
}

interface IDamInspectionEntryViewState {
    tabIndex : number;

    isCarouselOpen : boolean;
    imageIndex : number;
}

export default class DamInspectionEntryView extends React.PureComponent<IDamInspectionEntryViewProps, IDamInspectionEntryViewState> {
    constructor(props : IDamInspectionEntryViewProps) {
        super(props);
        this.state = {
            tabIndex: 0,
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly getInspection = (props : IDamInspectionEntryViewProps) => props.inspection;
    public readonly getImageUrls = createSelector(
        [
            this.getInspection,
        ],
        (inspection) => {
            const result : Array<string> = [];

            if (inspection.crestSettlementPhotoUrl) {
                result.push(inspection.crestSettlementPhotoUrl);
            }
            if (inspection.crestErosionPhotoUrl) {
                result.push(inspection.crestErosionPhotoUrl);
            }
            if (inspection.crestCracksPhotoUrl) {
                result.push(inspection.crestCracksPhotoUrl);
            }
            if (inspection.crestOtherPhotoUrl) {
                result.push(inspection.crestOtherPhotoUrl);
            }

            if (inspection.upstreamSlopeDisturbancePhotoUrl) {
                result.push(inspection.upstreamSlopeDisturbancePhotoUrl);
            }
            if (inspection.upstreamSlopeErosionPhotoUrl) {
                result.push(inspection.upstreamSlopeErosionPhotoUrl);
            }
            if (inspection.upstreamSlopeSurfaceProtectionPhotoUrl) {
                result.push(inspection.upstreamSlopeSurfaceProtectionPhotoUrl);
            }

            if (inspection.downstreamSlopeBulgingPhotoUrl) {
                result.push(inspection.downstreamSlopeBulgingPhotoUrl);
            }
            if (inspection.downstreamSlopeSettlementPhotoUrl) {
                result.push(inspection.downstreamSlopeSettlementPhotoUrl);
            }
            if (inspection.downstreamSlopeDisturbancePhotoUrl) {
                result.push(inspection.downstreamSlopeDisturbancePhotoUrl);
            }
            if (inspection.downstreamSlopeErosionPhotoUrl) {
                result.push(inspection.downstreamSlopeErosionPhotoUrl);
            }
            if (inspection.downstreamSlopeSeepagePhotoUrl) {
                result.push(inspection.downstreamSlopeSeepagePhotoUrl);
            }
            if (inspection.downstreamSlopeUnwantedVegetationPhotoUrl) {
                result.push(inspection.downstreamSlopeUnwantedVegetationPhotoUrl);
            }

            if (inspection.downstreamWallErosionPhotoUrl) {
                result.push(inspection.downstreamWallErosionPhotoUrl);
            }
            if (inspection.downstreamWallSeepagePhotoUrl) {
                result.push(inspection.downstreamWallSeepagePhotoUrl);
            }
            if (inspection.downstreamWallUnwantedVegetationPhotoUrl) {
                result.push(inspection.downstreamWallUnwantedVegetationPhotoUrl);
            }

            if (inspection.spillwayErosionPhotoUrl) {
                result.push(inspection.spillwayErosionPhotoUrl);
            }
            if (inspection.spillwayUnwantedVegetationPhotoUrl) {
                result.push(inspection.spillwayUnwantedVegetationPhotoUrl);
            }

            if (inspection.outletWorksCorrosionPhotoUrl) {
                result.push(inspection.outletWorksCorrosionPhotoUrl);
            }
            if (inspection.outletWorksValvesPhotoUrl) {
                result.push(inspection.outletWorksValvesPhotoUrl);
            }

            return result;
        }
    );

    private readonly onImageClick = (event : React.MouseEvent<HTMLImageElement>) => {
        const { inspection } = this.props;

        const url = lodash.find(inspection, (value, key) => key === event.currentTarget.id) as string;
        this.onThumbClick(url);
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.getImageUrls(this.props);
        const index = imageSrcs.indexOf(url);

        if (index < 0) return;

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private readonly onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const { inspection } = this.props;
        const { tabIndex, imageIndex, isCarouselOpen } = this.state;

        const imageSrcs = this.getImageUrls(this.props);

        return (
            <div className='fdc'>
                <SecondaryTabs
                    value={tabIndex}
                    onChange={this.onTabChange}
                    aria-label='Info Tabs'
                    variant='fullWidth'
                >
                    <SecondaryTab label='CREST' />
                    <SecondaryTab label='UPSTREAM SLOPE' />
                    <SecondaryTab label='DOWNSTREAM SLOPE' />
                    <SecondaryTab label='AREA DOWNSTREAM OF WALL' />
                    <SecondaryTab label='SPILLWAY' />
                    <SecondaryTab label='OUTLET' />
                    <SecondaryTab label='PUBLIC SAFETY' />
                    {
                        inspection.additionalComment &&
                        <SecondaryTab label='ADDITIONAL INSPECTION OBSERVATION' />
                    }
                </SecondaryTabs>
                <TabViews index={tabIndex}>
                    <DamInspectionCrestView
                        inspection={inspection}
                        onImageClick={this.onImageClick}
                    />
                    <DamInspectionUpstreamSlopeView
                        inspection={inspection}
                        onImageClick={this.onImageClick}
                    />
                    <DamInspectionDownstreamSlopeView
                        inspection={inspection}
                        onImageClick={this.onImageClick}
                    />
                    <DamInspectionWallDownstreamView
                        inspection={inspection}
                        onImageClick={this.onImageClick}
                    />
                    <DamInspectionSpillwayView
                        inspection={inspection}
                        onImageClick={this.onImageClick}
                    />
                    <DamInspectionOutletWorksView
                        inspection={inspection}
                        onImageClick={this.onImageClick}
                    />
                    <DamInspectionPublicSafetyView inspection={inspection} />
                    {
                        inspection.additionalComment &&
                        <DamInspectionAdditionalView inspection={inspection} />
                    }
                </TabViews>
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { BoreholeConstantRateNeighbouringStage, IBoreholeConstantRateTest } from '../../../../@types/model/borehole/boreholeConstantRateTest';

interface IBoreholeConstantRateEntryNeighbourTableViewProps {
    constantRateTest : IBoreholeConstantRateTest;
}

interface IBoreholeConstantRateEntryNeighbourTableViewState {
}

export default class BoreholeConstantRateEntryNeighbourTableView extends React.Component<IBoreholeConstantRateEntryNeighbourTableViewProps, IBoreholeConstantRateEntryNeighbourTableViewState> {
    constructor(props : IBoreholeConstantRateEntryNeighbourTableViewProps) {
        super(props);
        this.state = {
        };
    }

    public readonly render = () => {
        const { constantRateTest } = this.props;

        return (
            <div className='fdr mt15 fww'>
                {
                    constantRateTest.neighbouringBoreholes.map((x, i) => (
                        <Card key={i} className={`fdc ${i === constantRateTest.neighbouringBoreholes.length - 1 ? '' : 'mr15'}`}>
                            <div className='fdr bcw h50'>
                                <div className={'fdc w120 jcc aic dvblg h50'}>
                                    <Typography className={'fs11 fw400 cw'}>
                                        STAGE
                                    </Typography>
                                </div>
                                <span className='w3' />
                                <div className={'fdc w120 jcc aic dvblg h50'}>
                                    <Typography className={'tac fs11 fw400 cw'}>
                                        WB NUMBER
                                    </Typography>
                                </div>
                                <span className='w3' />
                                <div className={'fdc w120 jcc aic dvblg h50'}>
                                    <Typography className={'fs11 fw400 cw'}>
                                        TIME
                                    </Typography>
                                </div>
                                <span className='w3' />
                                <div className={'fdc w120 jcc aic dvblg h50'}>
                                    <Typography className={'tac fs11 fw400 cw pl10 pr10'}>
                                        GROUNDWATER LEVEL (m)
                                    </Typography>
                                </div>
                            </div>
                            {
                                BoreholeConstantRateNeighbouringStage
                                    .map((stage, index) => (
                                        <div key={index} className={`fdr ais jcc ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                            <div className={'fdr w120 aic jcc pb16 pt16'}>
                                                <Typography className={'fs13'}>
                                                    {
                                                        stage
                                                    }
                                                </Typography>
                                            </div>
                                            <span className='w3 bcw' />
                                            <div className={'fdr w120 aic jcc pb16 pt16'}>
                                                <Typography className={'fs13'}>
                                                    {
                                                        x[stage].borehole
                                                    }
                                                </Typography>
                                            </div>
                                            <span className='w3 bcw' />
                                            <div className={'fdr w120 aic jcc pb16 pt16'}>
                                                <Typography className={'fs13'}>
                                                    {
                                                        x[stage].time ?? '-'
                                                    }
                                                </Typography>
                                            </div>
                                            <span className='w3 bcw' />
                                            <div className={'fdr w120 aic jcc pb16 pt16'}>
                                                <Typography className={'fs13'}>
                                                    {
                                                        x[stage].waterLevel?.toFixed(2) ?? '-'
                                                    }
                                                </Typography>
                                            </div>
                                        </div>
                                    ))
                            }
                        </Card>
                    ))
                }
            </div>
        );
    };
}

import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import RisingMainTypeSelector from '../../customComponents/selector/RisingMainTypeSelector';
import RisingMainHDPESelector from '../../customComponents/selector/RisingMainHDPESelector';
import RisingMainSteelSelector from '../../customComponents/selector/RisingSteelExactSelector';
import RisingMainBorelineSelector from '../../customComponents/selector/RisingMainBorlineSelector';
import UVPCSelector from '../../customComponents/selector/UPVCSelector';
import RisingMainNoOfSectionsInput from '../../customComponents/input/RisingMainNoOfSectionsInput';
import RisingMainPipeLengthInput from '../../customComponents/input/RisingMainPipeLengthInput';

interface IEditBoreholeRisingMainInfoProps {
    borehole : IBorehole;

    onChange : (borehole : IBorehole) => void;
}

interface IEditBoreholeRisingMainInfoState {
    numberOfSection : string;
}

export default class EditBoreholeRisingMainInfo extends React.Component<IEditBoreholeRisingMainInfoProps, IEditBoreholeRisingMainInfoState> {
    constructor(props : IEditBoreholeRisingMainInfoProps) {
        super(props);
        this.state = {
            numberOfSection: '',
        };
    }

    public readonly componentDidMount = () => {
        this.setState({
            numberOfSection: !this.props.borehole.PipeLength ? '' : (this.props.borehole.PipeLength / 3).toFixed(2),
        });
    };

    private readonly onRisingMainChange = (risingMain : string) => {
        this.props.onChange({
            ...this.props.borehole,
            RisingMain: risingMain,
        });
    };

    private readonly onRisingMainTypeChange = (risingMainType : string | number) => {
        this.props.onChange({
            ...this.props.borehole,
            RisingMainType: risingMainType.toString(),
        });
    };

    private readonly onuPVCColumnChange = (uPVCColumn : string) => {
        this.props.onChange({
            ...this.props.borehole,
            uPVCColumn,
        });
    };

    private readonly onuPVCColumnTypeChange = (uPVCColumnType : string) => {
        this.props.onChange({
            ...this.props.borehole,
            uPVCColumnType,
        });
    };

    private readonly onNumberOfSectionChange = (numberOfSection : string) => {
        const parseCheck = Number(numberOfSection);
        if (!isNaN(parseCheck)) {
            this.setState({
                numberOfSection,
            });
            this.props.onChange({
                ...this.props.borehole,
                PipeLength: parseCheck * 3,
            });
        }
    };

    private readonly onPipeLengthChange = (pipeLength : string) => {
        if (!isNaN(Number(pipeLength))) {
            this.props.onChange({
                ...this.props.borehole,
                PipeLength: Number(pipeLength),
            });
        }
    };

    public readonly render = () => {
        const { borehole } = this.props;
        const { numberOfSection } = this.state;
        return (
            <>
                <div className={'fdr flx1 aifs'}>
                    <RisingMainTypeSelector value={borehole.RisingMain ?? ''} onChange={this.onRisingMainChange} />
                    {
                        borehole.RisingMain === 'HDPE' &&
                        <RisingMainHDPESelector value={borehole.RisingMainType ?? ''} onChange={this.onRisingMainTypeChange} />
                    }
                    {
                        borehole.RisingMain === 'Steel exact' &&
                        <RisingMainSteelSelector value={Number(borehole.RisingMainType)} onChange={this.onRisingMainTypeChange} />
                    }
                    {
                        borehole.RisingMain === 'Boreline' &&
                        <RisingMainBorelineSelector value={Number(borehole.RisingMainType)} onChange={this.onRisingMainTypeChange} />
                    }
                    {
                        borehole.RisingMain === 'uPVC Column' &&
                        <UVPCSelector
                            value={borehole.uPVCColumn ?? ''}
                            column={borehole.uPVCColumnType ?? ''}
                            onChange={this.onuPVCColumnChange}
                            onColumnChange={this.onuPVCColumnTypeChange}
                            noColumnValue
                        />
                    }
                </div>
                <div className={'fdr flx1 aifs'}>
                    {
                        (borehole.RisingMain === 'uPVC Column' || borehole.RisingMain === 'Steel exact') &&
                        <RisingMainNoOfSectionsInput value={Number(numberOfSection)} onChange={this.onNumberOfSectionChange}/>
                    }
                    <RisingMainPipeLengthInput value={Number(borehole.PipeLength)} onChange={this.onPipeLengthChange} disabled={borehole.RisingMain === 'uPVC Column' || borehole.RisingMain === 'Steel exact'}/>
                </div>
            </>
            
        );
    };
}

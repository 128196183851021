import { AppBar, Checkbox, Dialog, FormControlLabel, Icon, IconButton, InputAdornment, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Field, FieldArrayRenderProps } from 'formik';
import React from 'react';
import { createSelector } from 'reselect';
import { IDamSafetyInspectionDetailFormValues } from '../../../../@types/model/dam/damSafetyInspection';
import { Transitions } from '../../animations/Transitions';
import OutlinedStadiumButton from '../../button/OutlinedStadiumButton';
import StadiumButton from '../../button/StadiumButton';
import DateFormField from '../DateFormField';
import TextFormField from '../TextFormField';

interface IDamSafetyInspectionDetailFormFieldProps extends FieldArrayRenderProps {
}

interface IDamSafetyInspectionDetailFormFieldState {
    open : boolean;

    prevValues : Array<IDamSafetyInspectionDetailFormValues>;
}

export default class DamSafetyInspectionDetailFormField extends React.PureComponent<IDamSafetyInspectionDetailFormFieldProps, IDamSafetyInspectionDetailFormFieldState> {
    constructor(props : IDamSafetyInspectionDetailFormFieldProps) {
        super(props);
        this.state = {
            open: false,
            prevValues: [],
        };
    }

    public readonly getValues = (props : IDamSafetyInspectionDetailFormFieldProps) => props.form.values;
    public readonly getName = (props : IDamSafetyInspectionDetailFormFieldProps) => props.name;
    public readonly getList = createSelector(
        [this.getValues, this.getName],
        (values, name : string) => {
            return values[name] as Array<IDamSafetyInspectionDetailFormValues>;
        },
    );

    private readonly onClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const values = this.getList(this.props);

        this.setState({
            open: true,
            prevValues: values.map(x => ({
                ...x,
            })),
        });
    };

    private readonly onClose = () => {
        this.props.form.setFieldValue(this.props.name, this.state.prevValues);
        this.setState({
            open: false,
        });
    };

    private readonly onOkClick = () => {
        this.setState({
            open: false,
        });
    };

    private readonly onAddClick = () => {
        this.props.push({
            app: false,
            commenceDate: null,
            completionDate: null,
            description: '',
            no: '',
            estimateCost: null,
            priority: null,
        } as IDamSafetyInspectionDetailFormValues);
    };

    public readonly render = () => {
        const { isSubmitting } = this.props.form;
        const { open } = this.state;

        const details = this.getList(this.props);
        return (
            <>
                <Tooltip title='Edit'>
                    <div>
                        <IconButton color='inherit' size='small' onClick={this.onClick} aria-label='Edit' disabled={isSubmitting}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={Transitions.Down}
                    transitionDuration={500}
                    onClose={this.onClose}
                    maxWidth='lg'
                    fullWidth
                    aria-labelledby='dam-calibration-edit-dialog-title'
                    aria-describedby='dam-info-edit-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                Edit 
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <div className='fdr'>
                        <div className={'fdc flx1'}>
                            <div className='fdr jcfe mt15 mr15 mb15'>
                                <Tooltip title='Add'>
                                    <div>
                                        <IconButton onClick={this.onAddClick}>
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </div>
                            {
                                details.map((n, index) => (
                                    <div key={index} className={`fdr jcfs mnh65 pt15 pb15 pl5 pr5 ${index % 2 === 0 ? 'bcg2' : ''}`}>
                                        <div className='fdc w50 ml15 mr15'>
                                            <Typography className={'aic jcfs fs13 fw500 cpd mb12'}>
                                                No.
                                            </Typography>
                                            <div className='fdr'>
                                                <TextFormField
                                                    inputProps={{
                                                        className: 'cp fwb',
                                                        style: {
                                                            textAlign: 'left',
                                                        },
                                                    }}
                                                    id={`details.${index}.no`}
                                                    name={`details.${index}.no`}
                                                    fullWidth
                                                    required
                                                    type='number'
                                                />
                                            </div>
                                        </div>
                                        <div className='fdc flx1'>
                                            <Typography className={'aic jcfs fs13 fw500 cpd mb12'}>
                                                Description
                                            </Typography>
                                            <div className='fdr'>
                                                <TextFormField
                                                    inputProps={{
                                                        className: 'cp fwb',
                                                        style: {
                                                            textAlign: 'left',
                                                        },
                                                    }}
                                                    id={`details.${index}.description`}
                                                    name={`details.${index}.description`}
                                                    fullWidth
                                                    required
                                                    type='text'
                                                    multiline
                                                />
                                            </div>
                                        </div>
                                        <div className='fdc w50 ml15'>
                                            <Typography className={'aic jcfs fs13 fw500 cpd mb12'}>
                                                APP
                                            </Typography>
                                            <div className='fdr'>
                                                <Field
                                                    type='checkbox'
                                                    name={`details.${index}.app`}
                                                    as={FormControlLabel}
                                                    control={<Checkbox color='primary' />}
                                                    label=''
                                                />
                                            </div>
                                        </div>
                                        <div className='fdc w50 ml15 mr15'>
                                            <Typography className={'aic jcfs fs13 fw500 cpd mb12'}>
                                                Priority
                                            </Typography>
                                            <div className='fdr'>
                                                <TextFormField
                                                    inputProps={{
                                                        className: 'cp fwb',
                                                        style: {
                                                            textAlign: 'center',
                                                        },
                                                    }}
                                                    id={`details.${index}.priority`}
                                                    name={`details.${index}.priority`}
                                                    fullWidth
                                                    required
                                                    type='number'
                                                />
                                            </div>
                                        </div>
                                        <div className='fdr flx1'>
                                            <div className='fdc flx1 mr15'>
                                                <Typography className={'aic jcfs fs13 fw500 cpd mb12'}>
                                                    Commence Date
                                                </Typography>
                                                <div className='fdr'>
                                                    <DateFormField
                                                        name={`details.${index}.commenceDate`}
                                                        fullWidth
                                                        required
                                                        format='DD/MM/YYYY'
                                                        TextFieldComponent={(params) => (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    className: params.inputProps + ' cp fwb',
                                                                    style: {
                                                                        ...params.inputProps?.style,
                                                                        textAlign: 'center',
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className='fdc flx1 ml15'>
                                                <Typography className={'aic jcfs fs13 fw500 cpd mb12'}>
                                                    Completion Date
                                                </Typography>
                                                <div className='fdr'>
                                                    <DateFormField
                                                        name={`details.${index}.completionDate`}
                                                        fullWidth
                                                        required
                                                        format='DD/MM/YYYY'
                                                        TextFieldComponent={(params) => (
                                                            <TextField
                                                                {...params}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    className: params.inputProps + ' cp fwb',
                                                                    style: {
                                                                        ...params.inputProps?.style,
                                                                        textAlign: 'center',
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='fdc flx1 ml15 mr15'>
                                            <Typography className={'aic jcfs fs13 fw500 cpd mb12'}>
                                                Estimate Cost
                                            </Typography>
                                            <div className='fdr'>
                                                <TextFormField
                                                    inputProps={{
                                                        className: 'cp fwb',
                                                    }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position='end'>
                                                                R
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    id={`details.${index}.estimateCost`}
                                                    name={`details.${index}.estimateCost`}
                                                    fullWidth
                                                    required
                                                    type='number'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='fdr jcfe mt15 mr15 mb15'>
                        <div className='mr15'>
                            <OutlinedStadiumButton onClick={this.onClose}>
                                Cancel
                            </OutlinedStadiumButton>
                        </div>
                        <div>
                            <StadiumButton onClick={this.onOkClick}>
                                Ok
                            </StadiumButton>
                        </div>
                    </div>
                </Dialog>
            </>
            
        );
    };
}

import firebase from 'firebase/app';
import firebaseApp, { FirebaseService } from '../../../services/firebaseService';
import { IUserSession } from '../../employee';
import { IDepricatedBaseClass } from '../base';
import { IFileMetaData } from '../files/fileMetaData';

export interface IRiverMonitorTest extends IDepricatedBaseClass<IRiverMonitorTest> {
    Date : firebase.firestore.Timestamp;

    River : firebase.firestore.DocumentReference;
    RiverImageName : string;
    RiverImageUrl : string;
    RiverImageThumbnailUrl : string;
    FlowType : string;
    Flow : number | null;
    Container : number;
    Geom : firebase.firestore.GeoPoint;
    Elevation : number;
    UpdatedByName : string;
    IsSent : boolean;
    IsActive : boolean;

    IsFlowmeterReading : boolean;
    IsVnotchReading : boolean;
    YieldTested : boolean;

    ImageName : string | null;
    ImageUrl : string | null;
    ImageThumbnailUrl : string | null;
}

export default class RiverMonitorHelper {
    public static readonly COLLECTION_NAME = 'river_monitor_test';

    private static converter : firebase.firestore.FirestoreDataConverter<IRiverMonitorTest> = {
        fromFirestore: (snapshot) => {
            return RiverMonitorHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IRiverMonitorTest) : firebase.firestore.DocumentData => {
            return RiverMonitorHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IRiverMonitorTest {
        const data = snapshot.data();

        if (!data) {
            throw new Error(`Document does not exist! ${snapshot.id}`);
        }

        const result = { 
            ...data,
            ref: snapshot.ref.withConverter(RiverMonitorHelper.converter),
            IsActive: !!(typeof data.IsActive === 'undefined' || data.IsActive),
            Date: data['Date'] ?? data['CreatedOn'],
            ImageUrl: data['FlowmeterImageUrl'] ?? data['ImageUrl'] ?? null,
            ImageThumbnailUrl: data['FlowmeterImageThumbnailUrl'] ?? data['ImageThumbnailUrl'] ?? null,
            ImageName: data['FlowmeterImageName'] ?? data['ImageName'] ?? null,
            Flow: data['Flow'] ?? data['InstalledYield'] ?? null,
        } as IRiverMonitorTest;

        return result;
    }

    public static toFirestoreDocument(data : IRiverMonitorTest) {
        const { ref:_ref, ...firestoreObject } = data;
        return firestoreObject;
    }

    public static doc(id : string) {
        return firebaseApp.firestore().collection(this.COLLECTION_NAME).withConverter(this.converter).doc(id);
    }

    public static listen(riverRef : firebase.firestore.DocumentReference) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .withConverter(this.converter)
            .where('River', '==', riverRef);
    }

    public static update(borehole : IRiverMonitorTest) {
        return borehole.ref.set(borehole, {
            merge: true,
        });
    }

    public static delete(id : string, session : IUserSession) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                UpdatedByName: session.employee.Name,
                IsActive: false,
                IsSent: false,
            } as IRiverMonitorTest);
    }

    public static activate(id : string, session : IUserSession) {
        return firebaseApp
            .firestore()
            .collection(this.COLLECTION_NAME)
            .doc(id)
            .update({
                UpdatedOn: firebase.firestore.Timestamp.now(),
                UpdatedBy: firebaseApp.firestore().collection('employee').doc(session.firebaseUser.uid),
                UpdatedByEmployee: session.employee.EmployeeNumber ?? '',
                UpdatedByName: session.employee.Name,
                IsActive: true,
                IsSent: false,
            } as IRiverMonitorTest);
    }

    public static uploadImage(monitorTest : IRiverMonitorTest, file : File, metadata : IFileMetaData, progressCallback : (progress : number) => void, errorCallback ?: (e : Error) => void) {
        const unsub = FirebaseService.fileUpload(file, `river/${monitorTest.River.id}/${new Date().valueOf()}_${file.name}`, metadata)
            .on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;

                if (progress >= 100) {
                    unsub();
                }

                progressCallback(progress);
            }, errorCallback);
    }
}
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import { createSelector } from 'reselect';

interface IEmbedFileDialogProps {
    onClose : () => void;
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    src ?: string;
}

interface IEmbedFileDialogState {
}

export default class EmbedFileDialog extends React.Component<IEmbedFileDialogProps, IEmbedFileDialogState> {
    constructor(props : IEmbedFileDialogProps) {
        super(props);
        this.state = {};
    }

    private readonly onClose = () => {
        this.props.onClose();
    };

    private readonly getSrc = (props : IEmbedFileDialogProps) => props.src;

    public readonly getFileName = createSelector(
        [
            this.getSrc,
        ],
        (src) => {
            if (!src) return '';

            let filename = src.substring(src.lastIndexOf('/') + 1, src.lastIndexOf('?'));

            if (filename.includes('%2F')) {
                filename = filename.substring(filename.lastIndexOf('%2F') + 3);
            }

            return filename;
        },
    );

    public readonly render = () => {
        const {
            transition,
            fullWidth,
            maxWidth,
            fullScreen,
            src,
        } = this.props;

        const fileName = this.getFileName(this.props);
        return (
            <Dialog
                open={!!src}
                fullScreen={fullScreen}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                TransitionComponent={transition}
                transitionDuration={500}
                onClose={this.onClose}
                aria-labelledby='embedFile-dialog-title'
                aria-describedby='embedFile-dialog-description'
            >
                <AppBar className='posr p10' position='static'>
                    <Typography variant='h5' color='inherit' className='aic flx1'>
                        View - {fileName}
                    </Typography>
                </AppBar>
                <DialogContent>
                    <object height={500} width={'100%'} data={`${src}#navpanes=0&scrollbar=0`} title={fileName}>
                        <p>Error Loading PDF</p>
                    </object>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={this.onClose} color='primary'>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
}

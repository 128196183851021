import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { IMeterReadingsView } from '../../../../@types/model/transformer/transformerReading';
import StadiumButton from '../../../customComponents/button/StadiumButton';
import DialogActions from '@material-ui/core/DialogActions';
import OutlinedStadiumButton from '../../../customComponents/button/OutlinedStadiumButton';
import AppFunctionsService from '../../../../services/appFunctionsService';

interface ITransformerMunicipalityReadingDeleteDialogProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;

    reading : IMeterReadingsView;

    onDelete ?: (reading : IMeterReadingsView) => void;
}

interface ITransformerMunicipalityReadingDeleteDialogState {
    open : boolean;
}

class TransformerMunicipalityReadingDeleteDialog extends React.PureComponent<ITransformerMunicipalityReadingDeleteDialogProps, ITransformerMunicipalityReadingDeleteDialogState> {
    constructor(props : ITransformerMunicipalityReadingDeleteDialogProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    public readonly onClick = () => {
        this.setState({
            open: true,
        });
    };

    public readonly onClose = () => {
        this.setState({
            open: false,
        });
    };

    private readonly onDeleteClick = async () => {  
        if (this.props.onDelete) this.props.onDelete(this.props.reading);      
        this.onClose();
    };

    public readonly render = () => {
        const { transition, maxWidth, fullWidth, fullScreen, reading } = this.props;
        const { open } = this.state;
        return (
            <div>
                <Tooltip title='Delete'>
                    <div>
                        <IconButton className={'cr'} onClick={this.onClick}
                            aria-label='Delete'>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    maxWidth={maxWidth}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    onClose={this.onClose}
                    aria-labelledby='Municipality-Meter-Reading-Info-dialog-title'
                    aria-describedby='Municipality-Meter-Reading-Info-description'>
                    <AppBar className='fdr posr aic jcc' position='static'>
                        <Toolbar className={'fdr flx1 aic jcc'}>
                            <Typography variant='h5' color='inherit'>
                                REMOVE READING | {AppFunctionsService.formatDateTimeToDateOnly(reading.Date)} | {reading.EPNumber}
                            </Typography>
                            <span className='flx1' />
                            <Tooltip title='Close'>
                                <div>
                                    <IconButton color='inherit' onClick={this.onClose} aria-label='Close'>
                                        <Icon>close</Icon>
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className='fdc flx1 ais hfill bcg0'>
                        <div className='fdc flx1 ais jcc'>
                            <p>
                                Remove Reading?
                            </p>
                            <p>
                                This will remove all associated readings too.
                            </p>
                        </div>
                    </DialogContent>
                    <DialogActions className='bcg0'>
                        <OutlinedStadiumButton className='mr16' variant='text' onClick={this.onClose}>
                            CANCEL
                        </OutlinedStadiumButton>
                        <StadiumButton variant='contained' onClick={this.onDeleteClick}>
                            REMOVE
                        </StadiumButton>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
}

export default TransformerMunicipalityReadingDeleteDialog;

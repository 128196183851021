import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { WEATHER_STATION_TYPES } from '../../../../appConstants';

interface IWeatherStationTypeSelectorProps {
    value : string;

    onChange : (value : string) => void;
}

interface IWeatherStationTypeSelectorState {}

export default class WeatherStationTypeSelector extends React.Component<IWeatherStationTypeSelectorProps, IWeatherStationTypeSelectorState> {
    constructor(props : IWeatherStationTypeSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onWeatherStationTypeChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (typeof event.target.value === 'string') {
            this.props.onChange(event.target.value);
        }
    };

    public readonly render = () => {
        const { value } = this.props;

        return (
            <div className={'flx1 ais p5 mb10 pr20'}>
                <FormControl margin='normal' fullWidth>
                    <InputLabel shrink={!!value} htmlFor='weatherStationType'>Weather Station Type</InputLabel>
                    <Select
                        fullWidth
                        id='weatherStationType'
                        value={!value ? '' : value}
                        onChange={this.onWeatherStationTypeChange}>
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                        {
                            lodash.map(WEATHER_STATION_TYPES, n => (
                                <MenuItem key={`${n}_weatherStationType`} value={`${n}`}>
                                    {n}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </div>
        );
    };
}

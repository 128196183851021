import { IWeatherState, WeatherAction } from '../../@types/redux';
import * as actions from './actions';
import { getType } from 'typesafe-actions';

const initialState = {
    weatherStations: [],
    loading: false,
    audits: [],
    loadingAudits: false,
};

export default function generalReducer(state : IWeatherState = initialState, action : WeatherAction) : IWeatherState {
    switch (action.type) {
        case getType(actions.weatherSetList):
            return { ...state, weatherStations: action.payload };
        case getType(actions.weatherSetSelected):
            return { ...state, weatherStation: action.payload };
        case getType(actions.weatherSetLoading):
            return { ...state, loading: action.payload };
        case getType(actions.weatherSetAudits):
            return { ...state, audits: action.payload };
        case getType(actions.weatherSetAuditLoading):
            return { ...state, loadingAudits: action.payload };
        default:
            return state;
    }
}

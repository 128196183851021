import { Icon, IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Area } from '../../../../@types/model/area';
import { IRootState } from '../../../../@types/redux';
import DataFunctions from '../../../../store/data/functions';
import CheckTreeItem from '../../../customComponents/tree/CheckTreeItem';

interface IEmployeeAreasViewProps {
    value : Array<string>;

    onChange ?: (areas : Array<string>) => void;

    areas : Array<Area>;
    isLoading : boolean;
}

interface IEmployeeAreasViewState {
    otherArea : string;
}

class EmployeeAreasView extends React.PureComponent<IEmployeeAreasViewProps, IEmployeeAreasViewState> {
    constructor(props : IEmployeeAreasViewProps) {
        super(props);
        this.state = {
            otherArea: '',
        };
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    public readonly loadData = async () => {
        DataFunctions.getAreas();
    };

    private readonly getValue = (props : IEmployeeAreasViewProps) => props.value;
    private readonly getData = (props : IEmployeeAreasViewProps) => props.areas;

    private readonly getDivisions = createSelector([
        this.getData,
    ], (areas) => {
        return lodash
            .chain(areas)
            .groupBy(x => x.division)
            .map((a, division) => ({
                name: division,
                subDivisions: lodash
                    .chain(a)
                    .sortBy(x => x.subDivisionName)
                    .groupBy(x => x.subDivisionName)
                    .map((departments, subDivision) => ({
                        name: subDivision,
                        departments: lodash.sortBy(departments, (department) => department.name),
                    }))
                    .value(),
            }))
            .sortBy((a) => a.name)
            .value();
    });

    private readonly getOtherAreas = createSelector([
        this.getData, this.getValue,
    ], (areas, value) => {
        return lodash
            .chain(value)
            .filter(x => !areas.find(n => n.name === x))
            .value();
    });

    private readonly updateAreas = (area : string, add : boolean) => {
        const areas = this.props.value.slice();

        if (add) {
            areas.push(area);
        } else {
            const index = areas.findIndex((x) => x === area);
            areas.splice(index, 1);
        }

        if (this.props.onChange) this.props.onChange(areas);

    };

    private readonly onRemoveClick = (event : React.MouseEvent<HTMLButtonElement>) => {
        this.updateAreas(event.currentTarget.value, false);
    };

    private readonly onDepartmentChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        this.updateAreas(event.currentTarget.value, checked);
    };

    private readonly onDivisionChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const division = event.currentTarget.value;
        const selectedDivision = this.getDivisions(this.props).find(x => x.name === division);

        if (!selectedDivision) return;

        const areas = this.props.value.slice();

        selectedDivision.subDivisions.forEach(subDivision => {
            if (checked) {
                subDivision.departments.forEach(departments => {
                    if (areas.includes(departments.name)) return;
    
                    areas.push(departments.name);
                });
            } else {
                subDivision.departments.forEach(departments => {
                    if (!areas.includes(departments.name)) return;
    
                    const index = areas.findIndex((x) => x === departments.name);
                    areas.splice(index, 1);
                });
            }
        });
        
        if (this.props.onChange) this.props.onChange(areas);
    };

    private readonly onSubDivisionChange = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
        const splits = event.currentTarget.value.split(':');
        const division = splits[0];
        const subDivision = splits[1];
        const selectedDivision = this.getDivisions(this.props).find(x => x.name === division);

        if (!selectedDivision) return;

        const selectedSubDivision = selectedDivision.subDivisions.find(x => x.name === subDivision);

        if (!selectedSubDivision) return;

        const areas = this.props.value.slice();

        if (checked) {
            selectedSubDivision.departments.forEach(departments => {
                if (areas.includes(departments.name)) return;

                areas.push(departments.name);
            });
        } else {
            selectedSubDivision.departments.forEach(departments => {
                if (!areas.includes(departments.name)) return;

                const index = areas.findIndex((x) => x === departments.name);
                areas.splice(index, 1);
            });
        }
        if (this.props.onChange) this.props.onChange(areas);
    };

    private readonly onItemClick = (event : React.MouseEvent<HTMLLIElement>) => {
        if (!event.currentTarget.textContent) return;

        this.updateAreas(event.currentTarget.textContent, !this.props.value.includes(event.currentTarget.textContent));
    };

    private readonly onOtherAreaChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            otherArea: event.currentTarget.value,
        });
    };

    private readonly onOtherSubmit = (event : React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') return;

        event.preventDefault();
        event.stopPropagation();
        const { otherArea } = this.state;
        const { value } = this.props;

        if (!otherArea) return;
        if (value.includes(otherArea)) return;

        this.updateAreas(otherArea.slice(), true);
        this.setState({
            otherArea: '',
        });
    };

    public readonly render = () => {
        const { otherArea } = this.state;
        const { value } = this.props;
        const divisions = this.getDivisions(this.props);
        const otherAreas = this.getOtherAreas(this.props);
        return (
            <>
                <div className='fdr aic p5 mb10'>
                    <div className={'aic flx1 p5 pr20'}>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete='off'
                                id='other'
                                label='Other'
                                value={otherArea}
                                onChange={this.onOtherAreaChange}
                                margin='normal'
                                onKeyDown={this.onOtherSubmit}
                                type='text'
                            />
                        </FormControl>
                    </div>
                </div>
                {
                    !!otherAreas.length &&
                    <TreeView
                        defaultExpanded={['other_node']}
                        defaultCollapseIcon={<ArrowDropDownIcon />}
                        defaultExpandIcon={<ArrowRightIcon />}
                        defaultEndIcon={<div style={{ width: 24 }} />}>
                        <TreeItem
                            nodeId='other_node'
                            key='other_node'
                            label={
                                <Typography variant='body1' className='pt5 pb5'>
                                    Other
                                </Typography>
                            }>
                            {
                                otherAreas.map(area => (
                                    <TreeItem
                                        nodeId={`other_${area}_node`}
                                        key={`other_${area}_node`}
                                        label={
                                            <div className='fdr aic'>
                                                <IconButton value={area} type='button' className='cr' size='small' onClick={this.onRemoveClick}>
                                                    <Icon>close</Icon>
                                                </IconButton>
                                                <Typography variant='body1' className='ml7'>
                                                    {area}
                                                </Typography>
                                            </div>
                                        }>
                                    </TreeItem>
                                ))
                            }
                        </TreeItem>
                    </TreeView>

                }
                <TreeView
                    defaultExpanded={[]}
                    defaultCollapseIcon={<ArrowDropDownIcon />}
                    defaultExpandIcon={<ArrowRightIcon />}
                    defaultEndIcon={<div style={{ width: 24 }} />}>
                    {
                        divisions.map(division => (
                            <TreeItem
                                nodeId={`${division.name}_node`}
                                key={`${division.name}_node`}
                                label={
                                    <CheckTreeItem
                                        value={division.name}
                                        labelText={division.name}
                                        checked={!division.subDivisions.some(subDivision => subDivision.departments.some(department => !value.includes(department.name)))}
                                        indeterminate={
                                            division.subDivisions.some(subDivision => subDivision.departments.some(department => value.includes(department.name))
                                            && division.subDivisions.some(sub => sub.departments.some(department => !value.includes(department.name))))}
                                        onChange={this.onDivisionChange}
                                    />
                                }>
                                {
                                    division.subDivisions.map((subDivision) => (
                                        <TreeItem
                                            nodeId={`${division.name}_${subDivision.name}_node`}
                                            key={`${division.name}_${subDivision.name}_node`}
                                            onClick={this.onItemClick}
                                            label={
                                                <CheckTreeItem
                                                    value={`${division.name}:${subDivision.name}`}
                                                    labelText={subDivision.name}
                                                    checked={!subDivision.departments.some(department => !value.includes(department.name))}
                                                    onChange={this.onSubDivisionChange}
                                                    indeterminate={subDivision.departments.some(department => value.includes(department.name))
                                                        && subDivision.departments.some(department => !value.includes(department.name))}
                                                />
                                            }>
                                            {
                                                subDivision.departments.map((department) => (
                                                    <TreeItem
                                                        nodeId={`${division.name}_${subDivision.name}_${department.name}_node`}
                                                        key={`${division.name}_${subDivision.name}_${department.name}_node`}
                                                        onClick={this.onItemClick}
                                                        label={
                                                            <CheckTreeItem
                                                                value={department.name}
                                                                labelText={department.name}
                                                                checked={value.includes(department.name)}
                                                                onChange={this.onDepartmentChange}
                                                            />
                                                        } />
                                                ))
                                            }
                                        </TreeItem>
                                    ))
                                }
                            </TreeItem>
                        ))
                    }
                </TreeView>
            </>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        areas: state.data.areas,
        isLoading: state.data.isLoadingAreas,
    };
};

export default connect(
    mapStateToProps,
)(EmployeeAreasView);

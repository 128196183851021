import React from 'react';
import { createSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import MaterialTable from '../../customComponents/materialTable/Table';
import TransformerDropdown from '../../customComponents/selector/transformer/TransformerSelector';
import TransformerMunicipalityReadingHelper, { ITransformerMunicipalityReading } from '../../../@types/model/transformer/transformerMunicipalityReading';
import generalFunctions from '../../../store/general/functions';
import lodash from 'lodash';
import appFunctionsService from '../../../services/appFunctionsService';
import { Transitions } from '../../customComponents/animations/Transitions';
import TransformerMeterReadingProblem from '../../customComponents/TransformerMeterReadingProblem';
import Tooltip from '@material-ui/core/Tooltip';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import { IUserSession } from '../../../@types/employee';
import { firestore } from 'firebase/app';
import { Warning } from '@material-ui/icons';
import moment from 'moment';
import { Fab, Icon, IconButton } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { CSVLinkProps } from '../../../@types/csv';
import DateRangePicker from '../../customComponents/input/DateRangePicker';
import TransformerMunicipalityReadingInfoDialog from './dialog/MunicipalityMeterReadingInfoDialog';
import MunicipalityMeterCreateDialog from './dialog/MunicipalityMeterReadingCreate';
import TransformerMunicipalityReadingDeleteDialog from './dialog/MunicipalityMeterReadingDeleteDialog';
import { IMeterReadingsView } from '../../../@types/model/transformer/transformerReading';
import TransformerMunicipalityMeterReportDialog from '../dialog/MunicipalityMeterReport';
import MunicipalityApproveDialog from './dialog/MunicipalityApproveDialog';

interface ITransformerMunicipalityMeterListProps {
    isTransformerAdmin : boolean;
    isTransformerMunicipalityTester : boolean;

    session ?: IUserSession | null;
}

interface ITransformerMunicipalityMeterListState {
    transformerNumber ?: string;
    readings : Array<ITransformerMunicipalityReading>;
    isLoading : boolean;
    dateFrom : moment.Moment | null;
    dateTo : moment.Moment | null;
    onlyErrors : boolean;

    selectedRows : Array<IMeterReadingsView>;
    showApproveDialog : boolean;
    showDisapproveDialog : boolean;
}

class TransformerMunicipalityMeterListClass extends React.Component<ITransformerMunicipalityMeterListProps, ITransformerMunicipalityMeterListState> {
    private readonly csvLink : React.RefObject<CSVLinkProps & HTMLAnchorElement>;
    private readingListener ?: () => void;
    constructor(props : ITransformerMunicipalityMeterListProps) {
        super(props);

        this.state = {
            readings: [],
            isLoading: false,
            dateFrom: moment.utc().startOf('month').subtract(1, 'months'),
            dateTo: moment.utc().startOf('day'),
            onlyErrors: false,
            selectedRows: [],
            showApproveDialog: false,
            showDisapproveDialog: false,
        };
        this.csvLink = React.createRef<CSVLinkProps & HTMLAnchorElement>();
    }

    public readonly componentDidMount = () => {
        this.loadData();
    };

    private readonly loadData = async () => {
        if (this.readingListener) this.readingListener();

        this.setState({
            isLoading: true,
        });

        const session = this.props.session;

        if (!session) return;

        this.readingListener = TransformerMunicipalityReadingHelper.listen().onSnapshot((snap) => {
            const readings = this.state.readings.slice();

            snap.docChanges().forEach((f) => {
                const item = f.doc.data();
                if (
                    !session.employee.IsTransformerAdmin &&
                    item.division &&
                    session.employee.Areas.length &&
                    !session.employee.Areas.includes(item.division)) return;

                const index = lodash.findIndex(readings, n => n.id === item.id);

                switch (f.type) {
                    case 'added':
                        readings.push(item);
                        break;
                    case 'modified':
                        readings.splice(index, 1, item);
                        break;
                    case 'removed':
                        readings.splice(index, 1);
                        break;
                }
            });

            this.setState({
                readings,
                isLoading: false,
            });
        }, (err) => {
            generalFunctions.generalShowErrorSnackbar(err.message);
        });
    };

    private readonly getData = (props : ITransformerMunicipalityMeterListProps, state : ITransformerMunicipalityMeterListState) => state.readings;
    private readonly getTransformerNumber = (props : ITransformerMunicipalityMeterListProps, state : ITransformerMunicipalityMeterListState) => state.transformerNumber;
    private readonly getFromDate = (props : ITransformerMunicipalityMeterListProps, state : ITransformerMunicipalityMeterListState) => state.dateFrom;
    private readonly getToDate = (props : ITransformerMunicipalityMeterListProps, state : ITransformerMunicipalityMeterListState) => state.dateTo;
    private readonly getOnlyErrors = (props : ITransformerMunicipalityMeterListProps, state : ITransformerMunicipalityMeterListState) => state.onlyErrors;

    public readonly getMappedData = createSelector(
        [
            this.getData,
            this.getTransformerNumber,
            this.getFromDate,
            this.getToDate,
        ],
        (readings, transformerNumber, fromDate, toDate) => {
            const result : Array<IMeterReadingsView> = lodash.chain(readings)
                .filter(n => !transformerNumber || n.transformerRef === transformerNumber)
                .flatMap(n => n.transformerMeterNumberReadings.map(reading => ({
                    ImageFileName: reading.imageFileName,
                    ImageFileUrl: reading.imageFileUrl,
                    MeterNumber: reading.meterNumber,
                    NumberOfDigits: reading.numberOfDigits,
                    Reading: reading.reading,
                    Usage: reading.usage,
                    Guid: n.guid,
                    ItemNumber: reading.itemNumber,
                    Date: n.date,
                    Conditions: n.conditions,
                    EPNumber: n.transformerRef,
                    CreatedByName: n.createdByName,
                    UpdatedByName: n.updatedByName,
                    CreatedByEmployee: n.createdByEmployee,
                    UpdatedByEmployee: n.updatedByEmployee,
                    Division: n.division,
                    Group: n.group,
                    Rollover: reading.rollover,
                    CreatedBy: n.createdBy,
                    UpdatedBy: n.updatedBy,
                    isWeb: n.isWeb,
                    RecordingFileName: '',
                    Approved: reading.approved,
                    ApprovedBy: reading.approvedBy,
                    ApprovedByEmployee: reading.approvedByEmployee,
                    ApprovedByName: reading.approvedByName,
                    ApprovedOn: reading.approvedOn,
                } as IMeterReadingsView)))
                .filter(n => !fromDate || n.Date >= fromDate.valueOf())
                .filter(n => !toDate || n.Date <= toDate.valueOf())
                .map((reading, i, all) => ({
                    ...reading,
                    Error: this.hasError(reading, lodash.find(all, x =>
                        x.EPNumber === reading.EPNumber &&
                        x.Guid !== reading.Guid &&
                        moment.utc(x.Date).format('MM-YYYY') === moment.utc(reading.Date).format('MM-YYYY')
                    )),
                }))
                .sort((a, b) => b.Date - a.Date)
                .value();

            return result;
        },
    );
    
    public readonly getFilteredData = createSelector(
        [
            this.getMappedData,
            this.getOnlyErrors,
        ],
        (readings, onlyErrors) => {
            const result : Array<IMeterReadingsView> = lodash.chain(readings)
                .filter(n => !onlyErrors || !!n.Error)
                .sort((a, b) => b.Date - a.Date)
                .value();

            return result;
        },
    );

    private readonly onEPNumberChange = (value ?: ITransformer) => {
        this.setState({
            transformerNumber: value?.EPNumber,
        });
    };

    private readonly hasError = (reading : IMeterReadingsView, other ?: IMeterReadingsView | null) => {
        const errors : Array<string> = [];

        if (reading.Usage < 0) {
            errors.push('Transformer reading has a negative usage');
        }

        if (other) {
            errors.push('Reading already captured for selected month');
        }

        return errors.join(', ');
    };

    private readonly onSave = (meterReading : IMeterReadingsView) => {
        const session = this.props.session;

        if (!session) return;
        
        const readingIndex = this.state.readings.findIndex(x => x.guid === meterReading.Guid);

        if (readingIndex < 0) return;

        const reading = this.state.readings[readingIndex];
        const meterIndex = reading.transformerMeterNumberReadings.findIndex(x => x.itemNumber === meterReading.ItemNumber);

        if (meterIndex < 0) return;

        const update = {
            ...reading,
            transformerMeterNumberReadings: [
                ...reading.transformerMeterNumberReadings,
            ],
        };

        update.transformerMeterNumberReadings[meterIndex].reading = meterReading.Reading;
        update.transformerMeterNumberReadings[meterIndex].rollover = meterReading.Rollover ?? false;
        update.transformerMeterNumberReadings[meterIndex].imageFileName = meterReading.ImageFileName;
        update.conditions = [ ...meterReading.Conditions ];
        update.date = meterReading.Date.valueOf();
        update.updatedBy = session.firebaseUser.uid;
        update.updatedByName = session.firebaseUser.displayName ?? '';
        update.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        update.updatedOn = firestore.Timestamp.now().toMillis();

        TransformerMunicipalityReadingHelper.save(update);
    };

    private readonly onDelete = async (meterReading : IMeterReadingsView) => {
        const session = this.props.session;

        if (!session?.employee.IsTransformerAdmin) return;

        const reading = lodash
            .chain(this.state.readings)
            .filter(x => x.transformerRef === meterReading.EPNumber)
            .sortBy(x => x.date)
            .find(x => x.guid === meterReading.Guid)
            .value();

        await TransformerMunicipalityReadingHelper.delete(reading.id);
    };
    
    private readonly onDateSelectionChange = (start : moment.Moment | null, end : moment.Moment | null) => {
        this.setState({
            dateFrom: !start ? null : moment(start),
            dateTo: !end ? null : moment(end),
        });
    };

    private readonly onShowOnlyErrorClick = () => {
        this.setState({
            onlyErrors: !this.state.onlyErrors,
        });
    };

    public readonly onCSVClick = () => {
        if (this.csvLink.current) {
            this.csvLink.current.link.click();
        }
    };

    public readonly getCSVData = createSelector(
        [this.getFilteredData],
        (transformers) => {
            return transformers.map(n => ({
                EPNumber: n.EPNumber,
                Date: appFunctionsService.formatDateTime(n.Date),
                Division: n.Division,
                Group: n.Group,
                Meter: n.MeterNumber,
                Reading: n.Reading,
                LastReading: n.Rollover ?
                    n.Reading - n.Usage + Number(new Array(n.NumberOfDigits).fill('9').join('')) :
                    n.Reading - n.Usage,
                Usage: n.Usage,
                CreatedByName: n.CreatedByName,
                CreatedByEmployee: n.CreatedByEmployee,
                Problems: n.Conditions.join('; '),
                Approved: n.Approved,
                ApprovedOn: !n.ApprovedOn ? '' : appFunctionsService.formatDateTime(n.ApprovedOn),
                ApprovedBy: n.ApprovedBy,
                ApprovedByName: n.ApprovedByName,
                ApprovedByEmployee: n.ApprovedByEmployee,
            }));
        },
    );

    public readonly onSelectionChanged = (selectedRows : Array<IMeterReadingsView>) => {
        this.setState({
            selectedRows,
        });
    };

    private readonly onApproveSelectedClick = () => {
        this.setState({
            showApproveDialog: true,
        });
    };

    private readonly onApproveClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const readings = this.getFilteredData(this.props, this.state);
        const reading = readings.find(x => x.Guid === event.currentTarget.value);

        if (reading) {
            this.setState({
                selectedRows: [reading],
                showApproveDialog: true,
            });
        }
    };

    private readonly onDisapproveSelectedClick = () => {
        this.setState({
            showDisapproveDialog: true,
        });
    };

    private readonly onDisapproveClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const readings = this.getFilteredData(this.props, this.state);
        const reading = readings.find(x => x.Guid === event.currentTarget.value);
        
        if (reading) {
            this.setState({
                selectedRows: [reading],
                showDisapproveDialog: true,
            });
        }
    };

    private readonly onApproveClose = (result : boolean) => {
        if (result) {
            this.setState({
                selectedRows: [],
            });
        }
        this.setState({
            showApproveDialog: false,
        });
    };

    private readonly onDisapproveClose = (result : boolean) => {
        if (result) {
            this.setState({
                selectedRows: [],
            });
        }
        this.setState({
            showDisapproveDialog: false,
        });
    };

    public readonly render = () => {
        const {
            transformerNumber,
            isLoading,
            dateFrom,
            dateTo,
            onlyErrors,
            selectedRows,
            showApproveDialog,
            showDisapproveDialog,
        } = this.state;

        const { isTransformerAdmin, session, isTransformerMunicipalityTester } = this.props;
        const readings = this.getFilteredData(this.props, this.state);

        const csvName = moment().valueOf();
        const csvData = this.getCSVData(this.props, this.state);

        return (
            <div className={'fdc flx1 p10 mh0 mw0 bcg0'}>
                <Toolbar>
                    <span className={'flx1'} />
                    <div style={{ paddingRight: '15px' }}>
                        {
                            !!selectedRows.length &&
                            !!selectedRows.some(n => !n.Approved) &&
                            isTransformerMunicipalityTester &&
                            <Tooltip title='Approve Selected'>
                                <Fab color='primary' size='small' onClick={this.onApproveSelectedClick}>
                                    <Icon>
                                        check
                                    </Icon>
                                </Fab>
                            </Tooltip>
                        }
                    </div>
                    <div style={{ paddingRight: '15px' }}>
                        {
                            !!selectedRows.length &&
                            !!selectedRows.some(n => n.Approved) &&
                            isTransformerMunicipalityTester &&
                            <Tooltip title='Disapprove Selected'>
                                <Fab color='primary' size='small' onClick={this.onDisapproveSelectedClick}>
                                    <Icon>
                                        close
                                    </Icon>
                                </Fab>
                            </Tooltip>
                        }
                    </div>
                    <div style={{ paddingRight: '15px' }}>
                        <TransformerMunicipalityMeterReportDialog fullScreen readings={readings} />
                    </div>
                    <div className='pr20'>
                        <Tooltip title='Show only Errors'>
                            <Fab color={onlyErrors ? 'primary' : 'default'} size='small' onClick={this.onShowOnlyErrorClick}>
                                <Icon className='fs20'>
                                    warning
                                </Icon>
                            </Fab>
                        </Tooltip>
                    </div>
                    <div className={'aic p5 mb10 w350'}>
                        <DateRangePicker onChange={this.onDateSelectionChange} start={dateFrom} end={dateTo} />
                    </div>
                    <div className={'fdr pt5 jcfe aic'}>
                        <div className='fdc flx1 w240'>
                            <TransformerDropdown
                                type='municipality'
                                value={transformerNumber}
                                label='Select Transformer'
                                fullWidth
                                onChange={this.onEPNumberChange}
                            />
                        </div>
                    </div>
                </Toolbar>
                <Card className={'flx1 fdc mb70'}>
                    <MaterialTable<IMeterReadingsView>
                        id='transformerReadingsTable'
                        data={readings}
                        isLoading={isLoading}
                        rowsPerPage={50}
                        externalSort
                        onExportCsvClick={this.onCSVClick}
                        selectable
                        onSelectionChanged={this.onSelectionChanged}
                        selectedRows={selectedRows}
                        columns={[{
                            header: '',
                            field: 'EPNumber',
                            renderCell: row => (
                                <div className=' fdr aic'>
                                    <TransformerMunicipalityReadingInfoDialog key={`${row.Guid}_${row.ItemNumber}_edit`} fullWidth
                                        maxWidth='md' reading={row} transition={Transitions.Down}
                                        enabled={(isTransformerAdmin || (row.CreatedBy === session?.firebaseUser.uid))}
                                        onSave={this.onSave}
                                    />
                                    {
                                        (isTransformerAdmin || (row.CreatedBy === session?.firebaseUser.uid))
                                        && !row.Approved &&
                                        <TransformerMunicipalityReadingDeleteDialog
                                            key={`${row.Guid}_${row.ItemNumber}_delete`}
                                            fullWidth
                                            maxWidth='md' reading={row} transition={Transitions.Down}
                                            onDelete={this.onDelete}
                                        />

                                    }
                                    {
                                        !!row.isWeb &&
                                        <Icon className='cp ml10'>
                                            laptop
                                        </Icon>                                            
                                    }
                                    {
                                        !row.isWeb &&
                                        <Icon className='cp ml10'>
                                            smartphone
                                        </Icon>                                            
                                    }
                                    {
                                        !!row.Error &&
                                        <Tooltip title={row.Error}>
                                            <Warning className={'cy pl10'}/>
                                        </Tooltip>
                                    }
                                </div>
                            ),
                        }, {
                            header: 'Date',
                            field: 'Date',
                            width: 175,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (<span>{appFunctionsService.formatDateTimeToDateOnly(row.Date)}</span>),
                        }, {
                            header: 'EP Point',
                            field: 'EPNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Area',
                            field: 'Division',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Group',
                            field: 'Group',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Meter',
                            field: 'MeterNumber',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Meter Reading (kWh)',
                            field: 'Reading',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Last Reading (kWh)',
                            field: 'Usage',
                            width: 200,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <span>
                                    {
                                        row.Rollover ?
                                            row.Reading - row.Usage + Number(new Array(row.NumberOfDigits).fill('9').join('')) :
                                            row.Reading - row.Usage
                                    }
                                </span>),
                        }, {
                            header: 'Usage',
                            field: 'Usage',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                        }, {
                            header: 'Created By',
                            field: 'CreatedByName',
                            width: 145,
                            enableSort: true,
                            enableFilter: true,
                            renderCell: row => (
                                <Tooltip title={row.CreatedByEmployee ?? ''}>
                                    <div>
                                        {
                                            row.CreatedByName
                                        }
                                    </div>
                                </Tooltip>
                            ),
                        }, {
                            header: 'Problems',
                            field: 'Conditions',
                            renderCell: row => (<span className='fdr cw'>{row.Conditions.map((n, i) => (<TransformerMeterReadingProblem key={`${i}_${row.EPNumber}_problem`} text={n} />))}</span>),
                        }, {
                            header: 'APPROVE',
                            width: '175px',
                            renderCell: row => (
                                <div>
                                    {
                                        !row.Approved &&
                                        <Tooltip title='Approve'>
                                            <IconButton value={row.Guid} onClick={this.onApproveClick}>
                                                <Icon className={'cpd'}>
                                                    check_circle
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {
                                        !!row.Approved && !!row.ApprovedOn &&
                                        <Tooltip title={`${appFunctionsService.formatDateTime(row.ApprovedOn)}`}>
                                            <span>{row.ApprovedByName}</span>
                                        </Tooltip>
                                    }
                                    {
                                        !!row.Approved && !!row.ApprovedOn &&
                                        <Tooltip title='Disapprove'>
                                            <IconButton value={row.Guid} onClick={this.onDisapproveClick}>
                                                <Icon className={'cr'}>
                                                    close
                                                </Icon>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            ),
                        }]}
                    />
                </Card>
                <div className='fdr jcfe'>
                    <MunicipalityMeterCreateDialog aria-label='add' maxWidth='md' fullWidth session={session} />
                </div>
                <CSVLink
                    data={csvData}
                    headers={[
                        {
                            key: 'EPNumber',
                            label: 'EP Number',
                        },
                        {
                            key: 'Date',
                            label: 'Date',
                        },
                        {
                            key: 'Division',
                            label: 'Area',
                        },
                        {
                            key: 'Group',
                            label: 'Group',
                        },
                        {
                            key: 'Meter',
                            label: 'Meter',
                        },
                        {
                            key: 'Reading',
                            label: 'Reading',
                        },
                        {
                            key: 'LastReading',
                            label: 'Last Reading',
                        },
                        {
                            key: 'Usage',
                            label: 'Usage',
                        },
                        {
                            key: 'CreatedByName',
                            label: 'Created By Name',
                        },
                        {
                            key: 'CreatedByEmployee',
                            label: 'Created By Employee',
                        },
                        {
                            key: 'Problems',
                            label: 'Problems',
                        },
                        {
                            key: 'Approved',
                            label: 'Approved',
                        },
                        {
                            key: 'ApprovedByName',
                            label: 'Approved By Name',
                        },
                        {
                            key: 'ApprovedByEmployee',
                            label: 'Approved By Employee',
                        },
                        {
                            key: 'ApprovedOn',
                            label: 'Approved On',
                        },
                    ]}
                    filename={`${csvName}_municipality_tansformers.csv`}
                    className='dn'
                    ref={this.csvLink}
                    target='_blank'/>
                {
                    session &&
                    <MunicipalityApproveDialog
                        maxWidth='md'
                        fullWidth
                        open={showApproveDialog}
                        onClose={this.onApproveClose}
                        values={selectedRows}
                        title='Approve Readings'
                        readings={this.state.readings}
                        session={session}
                    />
                }
                {
                    session &&
                    <MunicipalityApproveDialog
                        maxWidth='md'
                        fullWidth
                        disapprove
                        open={showDisapproveDialog}
                        onClose={this.onDisapproveClose}
                        values={selectedRows}
                        title='Disapprove Readings'
                        readings={this.state.readings}
                        session={session}
                    />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isTransformerAdmin: !!state.auth.session?.employee.IsTransformerAdmin,
        isTransformerMunicipalityTester: !!state.auth.session?.employee.IsTransformerMunicipalityTester,
        session: state.auth.session,
    };
};

const TransformerMunicipalityMeterList = connect(
    mapStateToProps,
)(TransformerMunicipalityMeterListClass);

export default TransformerMunicipalityMeterList;
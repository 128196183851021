import React from 'react';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { IUserSession } from '../../../@types/employee';
import SecondaryTabs from '../../customComponents/tab/SecondaryTabs';
import SecondaryTab from '../../customComponents/tab/SecondaryTab';
import TabViews from '../../customComponents/tab/TabViews';
import TransformerSummaryView from './SummaryView';
import TransformerDivisionView from './DivisionView';
import TransformerLineInfoView from './LineInfoView';
import LocationInfoView from './LocationView';
import TransformerMetersView from './MetersView';
import TransformerImagesView from './ImagesView';
import TransformerUsageView from './UsageView';
import TransformerCommentsView from './CommentsView';

interface ITransformerDetailProps {
    transformer : ITransformer;
    isLoading : boolean;
    session : IUserSession;
}

interface ITransformerDetailState {
    tabIndex : number;
}

export default class TransformerDetail extends React.Component<ITransformerDetailProps, ITransformerDetailState> {
    constructor(props : ITransformerDetailProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly render = () => {
        const { transformer, isLoading, session } = this.props;
        const { tabIndex } = this.state;
        return (
            <div className='fdc flx1'>
                <SecondaryTabs value={tabIndex} onChange={this.onTabChange} aria-label='Info Tabs'>
                    <SecondaryTab label='SUMMARY' value={0} />
                    <SecondaryTab label='AREA INFO' value={1} />
                    <SecondaryTab label='LINE INFO' value={2} />
                    <SecondaryTab label='LOCATION' value={3} />
                    <SecondaryTab label='USAGE & SPLITS' value={4} />
                    <SecondaryTab label='METERS' value={5} />
                    <SecondaryTab label='IMAGES' value={6} />
                    <SecondaryTab label='UPDATES' value={7} />
                </SecondaryTabs>
                <div className={'fdc hfill oys'}>
                    <TabViews index={tabIndex}>
                        <TransformerSummaryView transformer={transformer} isLoading={isLoading} session={session} />
                        <TransformerDivisionView transformer={transformer} isLoading={isLoading} session={session} />
                        <TransformerLineInfoView transformer={transformer} isLoading={isLoading} session={session} />
                        <LocationInfoView transformer={transformer} isLoading={isLoading} session={session} />
                        <TransformerUsageView transformer={transformer} isLoading={isLoading} session={session} />
                        <TransformerMetersView transformer={transformer} isLoading={isLoading} session={session} />
                        <TransformerImagesView transformer={transformer} isLoading={isLoading} session={session} />
                        <TransformerCommentsView transformer={transformer} session={session} />
                    </TabViews>
                </div>
            </div>
        );
    };
}

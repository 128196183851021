import { Card, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IChemicalFillingPoint } from '../../../../@types/model/fillingPoint/chemical';
import { IRootState } from '../../../../@types/redux';
import EditLocationDialog from '../../../customComponents/dialog/LocationEditDialog';
import CustomMapBox, { IMapMarker } from '../../../customComponents/map/CustomMapBox';
import FillingPointChemicalFunctions from '../../../../store/fillingPoint/chemical/functions';
import { Transitions } from '../../../customComponents/animations/Transitions';
import { CHEMICAL_FILLING_POINT_MAP_IMAGES } from '../../../../appConstants';

interface IChemicalFillingPointLocationInfoViewProps {
    point : IChemicalFillingPoint;

    isLoading : boolean;

    isFillingPointAdmin : boolean;
}

interface IChemicalFillingPointLocationInfoViewState {}

class ChemicalFillingPointLocationInfoViewComponent extends React.PureComponent<IChemicalFillingPointLocationInfoViewProps, IChemicalFillingPointLocationInfoViewState> {
    constructor(props : IChemicalFillingPointLocationInfoViewProps) {
        super(props);
        this.state = {};
    }

    public readonly getData = (state : IChemicalFillingPointLocationInfoViewState, props : IChemicalFillingPointLocationInfoViewProps) => props.point;

    public readonly getMarker = createSelector(
        [this.getData],
        (point) => {
            return {
                coordinates: [point.geo?.longitude ?? 0, point.geo?.latitude ?? 0],
                iconSize: 0.35,
                icon: '1',
                id: point.code,
            } as IMapMarker;
        }
    );
    
    private readonly onLocationSave = async (geom : firebase.firestore.GeoPoint, elevation : number) => {
        const point = {
            ...this.props.point,
        };

        point.geo = geom;
        point.elevation = elevation;

        await FillingPointChemicalFunctions.save(point);
    };

    public readonly render = () => {
        const { isFillingPointAdmin, point, isLoading } = this.props;
        const marker = this.getMarker(this.state, this.props);

        return (
            <div className={'flx1 fdr aifs jcc mt15'}>
                <Card className={'bcw mt30 fdc mxh400 hfill'}>
                    <div className={'fdr dvbdg cw mb10 p10'}>
                        <Typography className={'fdr aic jcc flx1 fw600 fs16'}>
                            LOCATION
                        </Typography>
                        <div className='posr posr aic jcc'>
                            <div className='posa posr0'>
                                {
                                    isFillingPointAdmin &&
                                    <EditLocationDialog
                                        markerImage={CHEMICAL_FILLING_POINT_MAP_IMAGES['1']}
                                        markerImageSize = {0.35}
                                        elevation={point.elevation ?? 0}
                                        geom={point.geo}
                                        transition={Transitions.Up} maxWidth='sm' fullWidth isLoading={isLoading}
                                        onSave={this.onLocationSave}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'fdc ml17 mr17 mb13'}>
                        <div className={'flx1 mt9 fdr'}>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    LATITUDE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {point.geo?.latitude ?? '-'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    LONGITUDE
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {point.geo?.longitude ?? '-'}
                                </Typography>
                            </div>
                            <div className={'bcg0 fdc flx1 aic jcc mr8 w200 p10'}>
                                <Typography className={'fs11 cdg'}>
                                    ELEVATION
                                </Typography>
                                <Typography className={'fs13 cg3 fw700 pt10'}>
                                    {point.elevation}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {
                        !isLoading &&
                        <div className={'flx1 ais'}>
                            <div className='hfill wfill '>
                                <CustomMapBox
                                    id='point_map'
                                    showScale
                                    images={CHEMICAL_FILLING_POINT_MAP_IMAGES}
                                    markers={[marker]}
                                    selectedMarker={point.code}
                                />
                            </div>
                        </div>
                    }
                </Card>
            </div>
        );   
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isFillingPointAdmin: !!state.auth.session?.employee.IsFillingPointAdmin,
        isLoading: state.fillingPoints.chemical.isLoading,
    };
};

const ChemicalFillingPointLocationInfoView = connect(
    mapStateToProps,
)(ChemicalFillingPointLocationInfoViewComponent);

export default ChemicalFillingPointLocationInfoView;

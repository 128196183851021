import actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { IPumpingStationMotorPole } from '../../../../@types/model/masterData/pumpingStation/motorPole';

type MasterDataPumpingStationMotorPoleActionType = ActionType<typeof actions>;

export interface IMasterDataPumpingStationMotorPoleState {
    motorPoles : Array<IPumpingStationMotorPole>;
    isLoading : boolean;
}
const initialState = {
    motorPoles: [],
    isLoading: false,
};

export default function motorPoleReducer(state : IMasterDataPumpingStationMotorPoleState = initialState, action : MasterDataPumpingStationMotorPoleActionType) : IMasterDataPumpingStationMotorPoleState {
    switch (action.type) {
        case getType(actions.set):
            return { ...state, motorPoles: action.payload };
        case getType(actions.setLoading):
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
}

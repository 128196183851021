import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import React from 'react';
import TransformerFilterPopper from '../popper/TransformerFilterPopper';
import { TransformerMeterTypeUpperCase } from '../../../@types/model/transformer/transformer';

interface ITransformerFilterButtonProps {
    id ?: string;
    open : boolean;

    onClick ?: () => void;

    epNumber : string | null;
    epGroup : string | null;
    epArea : string | null;
    type : string | null;
    meterType : TransformerMeterTypeUpperCase | null;
    onChange ?: (epNumber : string | null, epGroup : string | null, epArea : string | null, type : string | null, meterType : TransformerMeterTypeUpperCase | null) => void;

    disabled ?: boolean;
    color ?: 'inherit' | 'primary' | 'secondary' | 'default';
}

interface ITransformerFilterButtonState {
}

export default class TransformerFilterButton extends React.PureComponent<ITransformerFilterButtonProps, ITransformerFilterButtonState> {
    private readonly filterRef : React.RefObject<HTMLButtonElement>;
    constructor(props : ITransformerFilterButtonProps) {
        super(props);
        this.filterRef = React.createRef<HTMLButtonElement>();
    }

    public readonly render = () => {
        const { id, onChange, epNumber, epGroup, epArea,
            type, meterType, disabled, onClick, open, color } = this.props;
        return (
            <>
                <Tooltip title='Filter'>
                    <div>
                        <IconButton
                            ref={this.filterRef}
                            aria-label='toggle filter menu'
                            onClick={onClick}
                            disabled={disabled}
                            color={color}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </div>
                </Tooltip>
                <TransformerFilterPopper
                    id={id}
                    anchorEl={this.filterRef.current}
                    open={open}
                    onCancelClick={onClick}
                    onChange={onChange}
                    epNumber={epNumber}
                    epGroup={epGroup}
                    epArea={epArea}
                    type={type}
                    meterType={meterType}
                />
            </>
        );
    };
}

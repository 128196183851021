import React from 'react';
import CustomMapBox, { IMapMarker } from '../map/CustomMapBox';
import { createSelector } from 'reselect';
import firebase from 'firebase/app';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';

interface ILocationInputProps {
    markerImage : string;
    markerImageSize ?: number;


    geom : firebase.firestore.GeoPoint | null;
    elevation : number | null;

    onGeoChange ?: (geom : firebase.firestore.GeoPoint | null) => void;
    onElevationChange ?: (elevation : number) => void;
}

interface ILocationInputState {}

export default class LocationInput extends React.PureComponent<ILocationInputProps> {
    private readonly zoom = 16;
    
    constructor(props : ILocationInputProps) {
        super(props);
    }

    private readonly onElevationChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { onElevationChange } = this.props;
        if (!onElevationChange) return;
        if (event.currentTarget.value && isNaN(Number(event.currentTarget.value))) return;

        onElevationChange(Number(event.currentTarget.value));
    };

    private readonly onLatChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { onGeoChange, geom } = this.props;
        if (!onGeoChange) return;
        if (event.currentTarget.value && isNaN(Number(event.currentTarget.value))) return;

        const lat = Number(event.currentTarget.value);

        if (lat < -90 || lat > 90) return;
        onGeoChange(new firebase.firestore.GeoPoint(lat, geom?.longitude ?? 0));
    };

    private readonly onLngChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { onGeoChange, geom } = this.props;
        if (!onGeoChange) return;
        if (event.currentTarget.value && isNaN(Number(event.currentTarget.value))) return;

        const lng = Number(event.currentTarget.value);

        if (lng < -180 || lng > 180) return;
        onGeoChange(new firebase.firestore.GeoPoint(geom?.latitude ?? 0, lng));
    };

    private readonly onMapClick = (lng : number, lat : number) => {
        const { onGeoChange } = this.props;
        if (!onGeoChange) return;
        onGeoChange(new firebase.firestore.GeoPoint(lat, lng));
    };

    public readonly getGeom = (state : ILocationInputState, props : ILocationInputProps) => props.geom;
    public readonly getPropGeom = (state : ILocationInputState, props : ILocationInputProps) => props.geom;
    public readonly getMarkerImageSize = (state : ILocationInputState, props : ILocationInputProps) => props.markerImageSize;
    public readonly getMarkerImage = (state : ILocationInputState, props : ILocationInputProps) => props.markerImage;

    public readonly getCenter = createSelector(
        [this.getPropGeom],
        (geom) : [number, number] => {
            return !geom ? [30.1148622, -23.5520414] : [geom.longitude, geom.latitude];
        },
    );

    public readonly getMapImage = createSelector(
        [this.getMarkerImage],
        (markerImage) => {
            return {
                'location_marker': markerImage,
            };
        },
    );

    public readonly getMarkers = createSelector(
        [this.getGeom, this.getMarkerImageSize],
        (geom, markerImageSize) => {
            if (!geom) return [];
            
            return [{
                id: 'location',
                coordinates: [geom.longitude, geom.latitude],
                iconSize: markerImageSize ?? 0.35,
                icon: 'location_marker',
            } as IMapMarker];
        },
    );

    public readonly render = () => {
        const { geom, elevation } = this.props;

        const center = this.getCenter(this.state, this.props);
        const mapImage = this.getMapImage(this.state, this.props);
        const markers = this.getMarkers(this.state, this.props);

        return (
            <div className={'fdc flx1'}>
                <div className={'fdr'}>
                    <div className={'flx1 aifs pr10'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='geom.latitude'
                                label='Latitude'
                                value={geom?.latitude ?? ''}
                                onChange={this.onLatChange}
                                error={!!geom?.latitude && !Number(geom.latitude)}
                            />
                            {
                                geom?.latitude && !Number(geom.latitude) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className={'flx1 aifs pl10'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='geom.longitude'
                                label='Longitude'
                                value={geom?.longitude ?? ''}
                                onChange={this.onLngChange}
                                error={!!geom?.longitude && !Number(geom.longitude)}
                            />
                            {
                                geom?.longitude && !Number(geom.longitude) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </div>
                <div className={'fdr'}>
                    <div className={'flx1 aifs'}>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id='elevation'
                                label='Elevation'
                                value={elevation ?? ''}
                                onChange={this.onElevationChange}
                                error={!!elevation && !Number(elevation)}
                            />
                            {
                                elevation && !Number(elevation) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </div>
                <div className='h250 wfill mt5'>
                    <CustomMapBox
                        id='location_map'
                        images={mapImage}
                        zoom={this.zoom}
                        center={center}
                        markers={markers}
                        onMapClick={this.onMapClick}
                    />
                </div>
            </div>
        );
    };
}

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React from 'react';
import { createSelector } from 'reselect';
import { ILPULine } from '../../../../@types/model/transformer/tariffs';
import { ITransformerInvoice, TransformerInvoiceHelper } from '../../../../@types/model/transformer/transformerInvoice';
import { DATE_INPUT_FORMAT_DEFAULT, DATE_REGEX } from '../../../../appConstants';
import DateTextInput from '../../../customComponents/input/DateTextInput';
import InputMask from '../../../customComponents/InputMask';

interface ILPUCapturingFormTableRowProps {
    invoice : ITransformerInvoice<ILPULine>;
    index : number;
    lineIndex : number;
    line : ILPULine;

    isSubmitting ?: boolean;
    handleChange(e : React.ChangeEvent<unknown>) : void;
    handleBlur(e : React.FocusEvent<unknown>) : void;
    setFieldValue : (field : string, value : unknown, shouldValidate ?: boolean) => void;

    multi ?: boolean;
    selected ?: boolean;

    adminCharge : number;
    serviceCharge : number;
    standardConnectionCharge : number;

    disabled : boolean;
}

interface ILPUCapturingFormTableRowState {}

export default class LPUCapturingFormTableRow extends React.PureComponent<ILPUCapturingFormTableRowProps, ILPUCapturingFormTableRowState> {
    constructor(props : ILPUCapturingFormTableRowProps) {
        super(props);
        this.state = {};
    }

    private readonly getInvoice = (props : ILPUCapturingFormTableRowProps) => props.invoice;

    private readonly calculateTotal = (invoice : ITransformerInvoice<ILPULine>) => {
        const value = TransformerInvoiceHelper.calculateLPUTotal(invoice);

        this.props.setFieldValue(`${this.props.index}`, value, true);
    };

    private readonly onFieldChange = (event : React.ChangeEvent<HTMLInputElement>) => {

        if (!event.currentTarget.value) return;
        
        this.props.handleChange(event);

        const invoice : ITransformerInvoice<ILPULine> = { 
            ...this.props.invoice,
            lines: [...this.props.invoice.lines],
        };

        const field = event.currentTarget.name.split('.')[2];
        
        const line = invoice.lines[this.props.lineIndex];
        (line[field as keyof ILPULine] as number) = Number(event.currentTarget.value);

        this.calculateTotal(invoice as ITransformerInvoice<ILPULine>);
    };

    private readonly onInvoiceFieldChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.value) return;
        
        this.props.handleChange(event);
        
        const invoice : ITransformerInvoice<ILPULine> = { 
            ...this.props.invoice,
            lines: [...this.props.invoice.lines],
        };
        const field = event.currentTarget.name.split('.')[1];
        (invoice[field as keyof ITransformerInvoice<ILPULine>] as number) = Number(event.currentTarget.value);
        
        this.calculateTotal(invoice as ITransformerInvoice<ILPULine>);
    };

    public readonly getDueDateValid = createSelector(
        [this.getInvoice],
        (invoice) => {
            return DATE_REGEX.test(invoice.paymentDueDate ?? '') && moment.utc(invoice.paymentDueDate ?? '', DATE_INPUT_FORMAT_DEFAULT, true).isValid();
        },
    );

    public readonly render = () => {
        const {
            line: value,
            index,
            isSubmitting,
            handleBlur,
            multi,
            handleChange,
            selected,
            lineIndex,
            adminCharge,
            serviceCharge,
            standardConnectionCharge,
            invoice,
            setFieldValue,
            disabled,
        } = this.props;

        const isValidDate = this.getDueDateValid(this.props);
        
        return (
            <TableRow selected={selected} className={`data-row ${multi ? 'multi' : ''}`}>
                {
                    lineIndex === 0 &&
                    <TableCell className='waypoint fixed' rowSpan={2}>
                        {invoice.epNumber}
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className='account' rowSpan={2}>
                        {invoice.accountNumber}
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className='' rowSpan={2}>
                        <input value={invoice.utilizedCapacity}
                            disabled={isSubmitting || disabled}
                            autoComplete='off'
                            name={`${index}.utilizedCapacity`}
                            onChange={this.onInvoiceFieldChange}
                            onBlur={handleBlur}
                            type='number'
                            min='0'
                            step='any' />
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className='' rowSpan={2}>
                        <input value={invoice.demandReading}
                            disabled={isSubmitting || disabled}
                            autoComplete='off'
                            name={`${index}.demandReading`}
                            onChange={this.onInvoiceFieldChange}
                            onBlur={handleBlur}
                            type='number'
                            min='0'
                            step='any' />
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className='' rowSpan={2}>
                        <input value={invoice.reactiveEnergy}
                            disabled={isSubmitting || disabled}
                            autoComplete='off'
                            name={`${index}.reactiveEnergy`}
                            onChange={this.onInvoiceFieldChange}
                            onBlur={handleBlur}
                            type='number'
                            min='0'
                            step='any' />
                    </TableCell>
                }
                <TableCell className='' align='center'>
                    <input value={value.days}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].days`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                {
                    lineIndex === 0 &&
                    <TableCell className='admin-charge' rowSpan={2}>
                        <input value={adminCharge}
                            disabled={isSubmitting || disabled}
                            autoComplete='off'
                            name={`${index}.adminCharge`}
                            onChange={this.onInvoiceFieldChange}
                            onBlur={handleBlur}
                            type='number'
                            min='0'
                            step='any' />
                    </TableCell>
                }
                <TableCell className=''>
                    <input value={value.networkDemandUnits}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].networkDemandUnits`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                <TableCell className='disabled'>
                    {value.ancillaryServiceCharge}
                </TableCell>
                <TableCell className=''>
                    <input value={value.unitsSTDLow}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].unitsSTDLow`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                <TableCell className=''>
                    <input value={value.unitsOffPeakLow}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].unitsOffPeakLow`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                <TableCell className=''>
                    <input value={value.unitsPeakLow}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].unitsPeakLow`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                <TableCell className=''>
                    <input value={value.unitsSTDHigh}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].unitsSTDHigh`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                <TableCell className=''>
                    <input value={value.unitsPeakHigh}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].unitsPeakHigh`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                <TableCell className=''>
                    <input value={value.unitsOffPeakHigh}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].unitsOffPeakHigh`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                {
                    lineIndex === 0 &&
                    <TableCell className='' rowSpan={2}>
                        <input value={serviceCharge}
                            disabled={isSubmitting || disabled}
                            autoComplete='off'
                            name={`${index}.serviceCharge`}
                            onChange={this.onInvoiceFieldChange}
                            onBlur={handleBlur}
                            type='number'
                            min='0'
                            step='any' />
                    </TableCell>
                }
                <TableCell className=''>
                    <input value={value.excessNetworkAccessCharge}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].excessNetworkAccessCharge`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                <TableCell className=''>
                    <input value={value.excessReactiveEnergy}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].excessReactiveEnergy`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                {
                    lineIndex === 0 &&
                    <TableCell className='' rowSpan={2}>
                        <input value={standardConnectionCharge}
                            disabled={isSubmitting || disabled}
                            autoComplete='off'
                            name={`${index}.standardConnectionCharge`}
                            onChange={this.onInvoiceFieldChange}
                            onBlur={handleBlur}
                            type='number'
                            min='0'
                            step='any' />
                    </TableCell>
                }
                <TableCell className=''>
                    <input value={value.interestCharge}
                        disabled={isSubmitting || disabled}
                        autoComplete='off'
                        name={`${index}.lines[${lineIndex}].interestCharge`}
                        onChange={this.onFieldChange}
                        onBlur={handleBlur}
                        type='number'
                        min='0'
                        step='any' />
                </TableCell>
                {
                    lineIndex === 0 &&
                    <TableCell className='' rowSpan={2}>
                        <div className='posr'>
                            {
                                !invoice.paymentDueDate &&
                                <InputMask for={`${index}.paymentDueDate`} mask='YYYY/MM/DD' className={`${isValidDate || !invoice.totalBilled ? '' : 'cr'}`} />
                            }
                        </div>
                        <DateTextInput value={invoice.paymentDueDate ?? ''}
                            disabled={isSubmitting || disabled}
                            autoComplete='off'
                            id={`${index}.paymentDueDate`}
                            name={`${index}.paymentDueDate`}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            className={`${isValidDate ? '' : 'cr'}`} />
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className={`invoice-total ${disabled ? 'disabled' : ''}`} rowSpan={2}>
                        {invoice.totalDue.toFixed(2)}
                    </TableCell>
                }
                {
                    lineIndex === 0 &&
                    <TableCell className='invoice-total' rowSpan={2}>
                        <input value={invoice.totalBilled}
                            disabled={isSubmitting || disabled}
                            autoComplete='off'
                            id={`${index}.totalBilled`}
                            name={`${index}.totalBilled`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type='number'
                            min='0'
                            step='0.01' />
                    </TableCell>
                }
            </TableRow>
        );
    };
}

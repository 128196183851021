import React from 'react';
import { IRootState } from '../../../@types/redux';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import transformerFunctions from '../../../store/transformer/functions';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import { EP_REGEX } from '../../../appConstants';
import { createSelector } from 'reselect';

interface ITransformerNewButtonProps {
    fullWidth ?: boolean;
    maxWidth ?: 'xs' | 'sm' | 'md' | 'lg' | false;
    fullScreen ?: boolean;
    transition ?: React.ForwardRefExoticComponent<TransitionProps & React.RefAttributes<unknown>>;
    transformers : Array<ITransformer>;
    isLoading : boolean;
}

interface ITransformerNewButtonState {
    open : boolean;

    poleNumber : string;
    epNumber : string;
}

class TransformerNewButtonComponent extends React.Component<ITransformerNewButtonProps, ITransformerNewButtonState> {
    constructor(props : ITransformerNewButtonProps) {
        super(props);
        this.state = {
            open: false,
            poleNumber: '',
            epNumber: '',
        };
    }

    private readonly onCloseClick = () => {
        if (this.props.isLoading) return;
        this.setState({
            open: false,
        });
    };

    private readonly onClose = () => {
        if (this.props.isLoading) return;

        this.setState({
            open: false,
        });
    };

    private readonly onAddClick = () => {
        const latestBorehole = this.props.transformers.slice().sort((a, b) => parseInt(b.EPNumber.substr(2)) - parseInt(a.EPNumber.substr(2)))[0];
        const maxNumber = parseInt(latestBorehole.EPNumber.substring(2)) + 1;

        this.setState({
            open: true,
            poleNumber: '',
            epNumber: `EP${maxNumber.toString().padStart(3, '0')}`,
        });
    };

    private readonly onEpNumberChangeChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value.length < 2 || event.currentTarget.value.substr(0, 2) !== 'EP') return;
        this.setState({
            epNumber: event.currentTarget.value,
        });
    };

    private readonly onPoleNumberChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            poleNumber: event.currentTarget.value,
        });
    };

    private readonly onSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.save();
    };

    private readonly save = async () => {
        const { poleNumber, epNumber } = this.state;

        if (!EP_REGEX.test(epNumber)) return;

        await transformerFunctions.createTransformer(poleNumber, epNumber);

        this.setState({
            open: false,
        });
    };

    private readonly getEPNumber = (props : ITransformerNewButtonProps, state : ITransformerNewButtonState) => state.epNumber;
    public readonly getIsValid = createSelector(
        [
            this.getEPNumber,
        ],
        (
            number,
        ) => {
            return EP_REGEX.test(number);
        },
    );

    public readonly render = () => {
        const { transition, fullWidth, maxWidth, fullScreen, isLoading } = this.props;
        const { open, poleNumber, epNumber } = this.state;

        const isValid = this.getIsValid(this.props, this.state);

        return (
            <div>
                <Tooltip title='Create'>
                    <div>
                        <IconButton onClick={this.onAddClick}>
                            <Icon>add</Icon>
                        </IconButton>
                    </div>
                </Tooltip>
                <Dialog
                    open={open}
                    fullScreen={fullScreen}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    TransitionComponent={transition}
                    transitionDuration={500}
                    onClose={this.onClose}
                    aria-labelledby='new-transformer-dialog-title'
                    aria-describedby='new-transformer-dialog-description'
                >
                    <AppBar className='flx1 fdr posr p10 aic' position='static'>
                        <Typography variant='h5' color='inherit'>
                            NEW TRANSFORMER - {epNumber}
                        </Typography>
                        <span className='flx1' />
                        <Tooltip title='Close'>
                            <div>
                                <IconButton color='inherit' onClick={this.onCloseClick}>
                                    <Icon>close</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    </AppBar>
                    <form autoComplete='off' onSubmit={this.onSubmit} className='fdc flx1 hfill ais'>
                        <div style={{ height: 5 }}>
                            {
                                isLoading &&
                                <LinearProgress />
                            }
                        </div>
                        <DialogContent className='fdr jcfs aifs flx1'>
                            <div className={'flx1 ais p5 mb10 pr20'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoFocus
                                        autoComplete='off'
                                        id='epNumber'
                                        label='Number'
                                        value={epNumber}
                                        onChange={this.onEpNumberChangeChange}
                                        required
                                        error={!epNumber || epNumber.length < 2 || !parseInt(epNumber.substr(2))}
                                    />
                                    {
                                        !epNumber &&
                                        <FormHelperText error>Required</FormHelperText>
                                    }
                                    {
                                        (epNumber.length < 2 || !parseInt(epNumber.substr(2))) &&
                                        <FormHelperText error>Invalid</FormHelperText>
                                    }
                                </FormControl>
                            </div>
                            <div className={'flx1 ais p5 mb10 pr20'}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoFocus
                                        autoComplete='off'
                                        id='poleNumber'
                                        label='Pole Number'
                                        value={poleNumber}
                                        onChange={this.onPoleNumberChange}
                                        className={'TextField'}
                                    />
                                </FormControl>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' type='submit' color='primary' disabled={isLoading || !isValid}>
                                CREATE
                            </Button>
                            <Button variant='outlined' onClick={this.onCloseClick} color='primary' disabled={isLoading}>
                                CLOSE
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        transformers: state.transformer.transformers,
        isLoading: state.transformer.loading,
    };
};

const TransformerNewButton = connect(
    mapStateToProps,
)(TransformerNewButtonComponent);

export default TransformerNewButton;

import AppFunctionsService from '../../../services/appFunctionsService';
import firebaseApp from '../../../services/firebaseService';
import { BasicBaseHelper, IDepricatedBaseClass } from '../base';

export interface IWeatherStation extends IDepricatedBaseClass<IWeatherStation> {
    Geom ?: firebase.firestore.GeoPoint;
    Elevation : number;
    WSNumber : string;
    WSName : string;
    WSType : string;
    Division : string | null;
    InstallationDate : string | null;
    SerialNumber : string | null;
    StationImageURL : string | null;
    ObstructionDistance : number | null;
    Surroundings : Array<string>;
    Fenced : boolean;
    IsSent : boolean;
    IsActive ?: boolean;

    Station ?: string;
}

export default class WeatherStationHelper extends BasicBaseHelper {
    public static readonly COLLECTION_NAME = 'weather_station';

    private static converter : firebase.firestore.FirestoreDataConverter<IWeatherStation> = {
        fromFirestore: (snapshot) => {
            return WeatherStationHelper.fromFirestoreDocument(snapshot);
        },
        toFirestore: (data : IWeatherStation) : firebase.firestore.DocumentData => {
            return WeatherStationHelper.toFirestoreDocument(data);
        },
    };

    public static fromFirestoreDocument(snapshot : firebase.firestore.DocumentSnapshot) : IWeatherStation {
        const data = snapshot.data();

        if (!data || !snapshot.exists) throw new Error(`Document does not exist! ${snapshot.id}`);
        return {
            ...data,
            Station: data['Station'] ?? '',
            Surroundings: data['Surroundings'] ?? [],
        } as IWeatherStation;
    }

    public static toFirestoreDocument(data : IWeatherStation) {
        return {
            ...data,
        };
    }

    public static save(weatherStation : IWeatherStation) {
        return WeatherStationHelper.doc(weatherStation.ref.id).set(weatherStation, {
            merge: true,
        });
    }

    public static doc(id ?: string) {
        if (!id) {
            return firebaseApp.firestore().collection(WeatherStationHelper.COLLECTION_NAME).withConverter(WeatherStationHelper.converter).doc();
        }

        return firebaseApp.firestore().collection(WeatherStationHelper.COLLECTION_NAME).withConverter(WeatherStationHelper.converter).doc(id);
    }
    
    public static listen() {
        return firebaseApp
            .firestore()
            .collection(WeatherStationHelper.COLLECTION_NAME)
            .withConverter(WeatherStationHelper.converter);
    }

    public static mapToCsv(stations : Array<IWeatherStation>) {
        return stations.map(n => ({
            WSNumber: n.WSNumber,
            WSName: n.WSName,
            Division: n.Division,
            WSType: n.WSType,
            Latitude: !n.Geom ? 0 : n.Geom.latitude,
            Longitude: !n.Geom ? 0 : n.Geom.longitude,
            InstallationDate: n.InstallationDate,
            SerialNumber: n.SerialNumber,
            ObstructionDistance: n.ObstructionDistance,
            Fenced: n.Fenced ? 'YES' : 'NO',
            CreatedOn: AppFunctionsService.formatDateTime(n.CreatedOn),
            UpdatedOn: AppFunctionsService.formatDateTime(n.UpdatedOn),
        }));
    }
}
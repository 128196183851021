import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ITransformerLpuReading } from '../../../@types/model/transformer/transformerLpuReading';
import { ITransformer } from '../../../@types/model/transformer/transformer';
import appFunctionsService from '../../../services/appFunctionsService';

interface ITransformerLpuReadingEntryViewProps {
    reading : ITransformerLpuReading;
    transformer ?: ITransformer;
}

interface ITransformerLpuReadingEntryViewState {}

export default class TransformerLpuReadingEntryView extends React.Component<ITransformerLpuReadingEntryViewProps, ITransformerLpuReadingEntryViewState> {
    constructor(props : ITransformerLpuReadingEntryViewProps) {
        super(props);
        this.state = {};
    }

    public render = () => {
        const { reading, transformer } = this.props;
        return (
            <div className='fdc'>
                <div className={'fdc flx1 pl15 pr15'}>
                    <div className={'fdr flx1 jcsb bbw1 bocGrey jcse'}>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Date Reading:
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {appFunctionsService.formatDateTimeToDateOnly(reading.date)}
                            </Typography>
                        </div>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                EP Number:
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {transformer?.EPNumber}
                            </Typography>
                        </div>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Area:
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {transformer?.Division}
                            </Typography>
                        </div>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Group:
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {transformer?.EPGroup}
                            </Typography>
                        </div>
                    </div>
                    <div className={'fdr flx1 jcsb bbw1 bocGrey'}>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13  p10'}>
                                Peak Reading (On.1):
                            </Typography>
                            <Typography className={'fw700 fs16  p10'}>
                                {reading.peakReading}
                            </Typography>
                        </div>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Standard Reading (Std1):
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {reading.standardReading}
                            </Typography>
                        </div>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Off Peak Reading (Off):
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {reading.offPeakReading}
                            </Typography>
                        </div>
                        <div className={'flx1'}>

                        </div>
                    </div>
                    <div className={'fdr flx1 jcsb bbw1 bocGrey'}>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Maximum Demand:
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {reading.maxDemand}
                            </Typography>
                        </div>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Date of maximum demand:
                            </Typography> 
                            <Typography className={'fw700 fs16 p10'}>
                                {appFunctionsService.formatDateTimeToDateOnly(reading.dateOfMaxDemand)}
                            </Typography> 
                        </div>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Time of maximum demand:
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {reading.timeOfMaxDemand}
                            </Typography>
                        </div>
                        <span className={'flx1 '}/>
                    </div>
                    <div className={'fdr flx1 jcsb bbw1 bocGrey'}>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Comment:
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {reading.comment}
                            </Typography>
                        </div>
                        <div className={'flx1 fdc'}>
                            <Typography className={'fs13 p10'}>
                                Captured By:
                            </Typography>
                            <Typography className={'fw700 fs16 p10'}>
                                {reading.createdByName}
                            </Typography>
                        </div>
                        <span className={'flx1'}/>
                        <span className={'flx1'}/>
                    </div>
                </div>
            </div>
        );
    };
}

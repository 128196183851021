import { FieldArray, FieldProps } from 'formik';
import React from 'react';
import { IBoreholeCalibrationStageFormValues } from '../../../../@types/model/borehole/boreholeCalibration';
import BoreholeCalibrationStepEntryFormField from './BoreholeCalibrationStepEntryFormField';

interface IBoreholeCalibrationStepFormFieldProps<T> extends FieldProps<IBoreholeCalibrationStageFormValues, T> {
}

export default class BoreholeCalibrationStepFormField<T> extends React.PureComponent<IBoreholeCalibrationStepFormFieldProps<T>> {
    constructor(props : IBoreholeCalibrationStepFormFieldProps<T>) {
        super(props);
    }

    public readonly render = () => {
        return (
            <div className='fdc'>
                <FieldArray
                    name={`${this.props.field.name}.stageEntries`}
                    render={(props) => <BoreholeCalibrationStepEntryFormField {...props} />}
                />
            </div>
        );
    };
}

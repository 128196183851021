import React from 'react';
import { IBorehole } from '../../../@types/model/borehole/borehole';
import { Tabs, Tab } from '@material-ui/core';
import BoreholeInfoView from './detaiViews/InfoView';
import BoreholeDivisionInfoView from './detaiViews/DivisionInfoView';
import BoreholeElectricalPointView from './detaiViews/ElectricalPointView';
import BoreholeImagesView from './detaiViews/ImagesView';
import BoreholeInstallationView from './detaiViews/InstallationView';
import BoreholeLocationInfoView from './detaiViews/LocationInfoView';
import BoreholeSensorInstallationView from './detaiViews/SensorInstallationView';
import BoreholeVideosView from './detaiViews/VideosView';
import BoreholeYieldInfoView from './detaiViews/YieldInfoView';
import TabViews from '../../customComponents/tab/TabViews';

interface IBoreholeDetailProps {
    borehole : IBorehole;
}

interface IBoreholeDetailState {
    tabIndex : number;
}

export default class BoreholeDetail extends React.Component<IBoreholeDetailProps, IBoreholeDetailState> {
    constructor(props : IBoreholeDetailProps) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    private readonly onTabChange = (event : React.ChangeEvent<unknown>, value : number) => {
        this.setState({
            tabIndex: value,
        });
    };

    public readonly render = () => {
        const { borehole } = this.props;
        const { tabIndex } = this.state;
        return (
            <div className='fdc flx1 hfill'>
                <Tabs
                    value={tabIndex}
                    onChange={this.onTabChange}
                    aria-label='Info Tabs'
                    className={'bcg0'}
                    variant={'fullWidth'}
                    indicatorColor={'primary'}
                >
                    <Tab label='BOREHOLE INFO' value={0} className={'cdg'} fullWidth/>
                    <Tab label='DIVISION INFO' value={1} className={'cdg'} fullWidth/>
                    <Tab label='YIELD INFO' value={2} className={'cdg'} fullWidth/>
                    <Tab label='LOCATION INFO' value={3} className={'cdg'} fullWidth/>
                    <Tab label='BOREHOLE INSTALLATION' value={4} className={'cdg'} fullWidth/>
                    <Tab label='SENSOR INSTALLATION' value={5} className={'cdg'} fullWidth/>
                    <Tab label='ELECTRICAL POINT' value={6} className={'cdg'} fullWidth/>
                    <Tab label='IMAGES' value={7} className={'cdg'} fullWidth/>
                    <Tab label='VIDEOS' value={8} className={'cdg'} fullWidth/>
                </Tabs>
                <div className={'fdc flx1 hfill'}>
                    <TabViews index={tabIndex}>
                        <BoreholeInfoView borehole={borehole} />
                        <BoreholeDivisionInfoView borehole={borehole} />
                        <BoreholeYieldInfoView borehole={borehole} />
                        <BoreholeLocationInfoView borehole={borehole} />
                        <BoreholeInstallationView borehole={borehole} />
                        <BoreholeSensorInstallationView borehole={borehole} />
                        <BoreholeElectricalPointView borehole={borehole} />
                        <BoreholeImagesView borehole={borehole} />
                        <BoreholeVideosView borehole={borehole} />
                    </TabViews>
                </div>
            </div>
        );
    };
}


import { createAction } from 'typesafe-actions';
import { IWeatherStation } from '../../@types/model/weather/weatherStation';
import { IWeatherStationAudit } from '../../@types/weatherStation';

export const weatherSetList = createAction('WEATHER_SET_LIST', resolve => (weathers : Array<IWeatherStation>) => resolve(weathers));
export const weatherSetSelected = createAction('WEATHER_SET_SELECTED', resolve => (weather ?: IWeatherStation) => resolve(weather));
export const weatherSetLoading = createAction('WEATHER_SET_LOADING', resolve => (loading : boolean) => resolve(loading));
export const weatherSetAudits = createAction('WEATHER_SET_AUDITS', resolve => (audits : Array<IWeatherStationAudit>) => resolve(audits));
export const weatherSetAuditLoading = createAction('WEATHER_SET_LOADING_AUDITS', resolve => (loading : boolean) => resolve(loading));

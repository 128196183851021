import { CircularProgress, Typography } from '@material-ui/core';
import lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { IFlowmeter } from '../../../@types/model/flowmeter/flowmeter';
import FlowmeterReadingHelper, { IFlowmeterReading } from '../../../@types/model/flowmeter/flowmeterReading';
import { IRootState } from '../../../@types/redux';
import AppFunctionsService from '../../../services/appFunctionsService';
import GeneralFunctions from '../../../store/general/functions';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import FirebaseStorageImage from '../../customComponents/image/FirebaseStorageImage';
import { StyledExpansionPanel } from '../../customComponents/StyledExpansionPanel';

interface IFlowmeterManualReadingProps {
    flowmeter : IFlowmeter;
    isLoading : boolean;
    
    isFlowmeterAdmin : boolean;
}

interface IFlowmeterManualReadingState {
    isLoading : boolean;
    entries : Array<IFlowmeterReading>;
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
}

class FlowmeterManualReadings extends React.PureComponent<IFlowmeterManualReadingProps, IFlowmeterManualReadingState> {
    private listener ?: () => void;
    constructor(props : IFlowmeterManualReadingProps) {
        super(props);
        this.state = {
            isLoading: false,
            entries: [],
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
        };
    }
    public readonly componentDidMount = () => {
        this.loadData();
    };
    
    public readonly componentWillUnmount = () => {
        if (this.listener) this.listener();
    };

    public readonly loadData = () => {
        if (this.listener) this.listener();

        this.setState({
            isLoading: true,
        });

        this.listener = FlowmeterReadingHelper.collection(this.props.flowmeter.id).onSnapshot((snapshot) => {
            const list = this.state.entries.slice();
    
            // "added" | "removed" | "modified"
            snapshot.docChanges().forEach((f) => {
                const item = {
                    ...f.doc.data(),
                };

                const index = lodash.findIndex(list, n => n.id === item.id);

                switch (f.type) {
                    case 'added':
                        list.push(item);
                        break;
                    case 'modified':
                        list.splice(index, 1, item);
                        break;
                    case 'removed':
                        list.splice(index, 1);
                        break;
                }
            });

            const entries = list.sort((a, b) => b.date - a.date);

            this.setState({
                isLoading: false,
                entries: entries,
                imageSrcs: entries.filter(x => !!x.photoUrl).map(x => x.photoUrl),
            });
        }, (ex) => {
            GeneralFunctions.generalShowError(ex, 'Error loading readings');
        }, () => {
            if (this.listener) this.listener();

            this.setState({
                isLoading: false,
                entries: [],
            });
        });
    };

    private readonly onThumbClick = (url : string) => {
        const imageSrcs = this.state.imageSrcs.slice();
        const index = imageSrcs.indexOf(url);

        this.setState({
            isCarouselOpen: true,
            imageIndex: index,
        });
    };

    private onCarouselClose = () => {
        this.setState({
            isCarouselOpen: false,
        });
    };

    public readonly render = () => {
        const {
            entries,
            isLoading,
            imageIndex,
            isCarouselOpen,
            imageSrcs,
        } = this.state;
        
        return (
            <div className={'fdc flx1 m10'}>
                { 
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    entries.length === 0 &&
                    <div className='fdc flx1 aic jcc'>
                        <Typography className={'mb10'} color='inherit' variant='h5'>
                            No Data
                        </Typography>
                    </div>
                }
                {
                    entries.map(flowmeterReading => (
                        <StyledExpansionPanel
                            key={flowmeterReading.id}
                            heading={AppFunctionsService.formatDateTimeToDateOnly(flowmeterReading.date)}
                        >
                            <div className='fdr'>
                                <div className='fdc flx1'>
                                    <div className={'fdr'}>
                                        <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                            <Typography className={'fs11 cdg'}>
                                                CUMULATIVE READING
                                            </Typography>
                                            <Typography className={'fs13 cg3 fw700 pt10'}>
                                                {flowmeterReading.reading}
                                            </Typography>
                                        </div>
                                        <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                            <Typography className={'fs11 cdg'}>
                                                LATEST FLOW (m<sup>3</sup>/h)
                                            </Typography>
                                            <Typography className={'fs13 cg3 fw700 pt10'}>
                                                {flowmeterReading.flow.toFixed(2)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={'fdr mt8'}>
                                        <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                            <Typography className={'fs11 cdg'}>
                                                DIAL READING
                                            </Typography>
                                            <Typography className={'fs13 cg3 fw700 pt10'}>
                                                {flowmeterReading.dialMeter}
                                            </Typography>
                                        </div>
                                        <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                            <Typography className={'fs11 cdg'}>
                                                REVS
                                            </Typography>
                                            <Typography className={'fs13 cg3 fw700 pt10'}>
                                                {flowmeterReading.revs}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className={'fdr mt8'}>
                                        <div className={'bcg0 fdc flx1 aic jcc mr8 p10'}>
                                            <Typography className={'fs11 cdg'}>
                                                SECONDS
                                            </Typography>
                                            <Typography className={'fs13 cg3 fw700 pt10'}>
                                                {(flowmeterReading.milliSeconds / 1000).toFixed(0)}
                                            </Typography>
                                        </div>
                                        <div className={'fdc flx1 aic jcc mr8 p10'}>
                                        </div>
                                    </div>
                                </div>
                                <div className='fdc ais flx3 h275 bcg0 pt8 pb8 pl50 pr50'>
                                    <div className='fdc aifs jcfs'>
                                        <Typography className='fs11 fwr cp'>PHOTO</Typography>
                                    </div>
                                    <div className='fdr aic jcc flx1'>
                                        {
                                            flowmeterReading.photoUrl &&
                                            <FirebaseStorageImage
                                                url={flowmeterReading.photoUrl}
                                                thumbnailUrl={flowmeterReading.thumbnailUrl}
                                                fileName={''}
                                                path={`flowmeter/${flowmeterReading.flowmeterCode}`}
                                                onClick={this.onThumbClick}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            
                        </StyledExpansionPanel>
                    ))
                }
                {
                    imageSrcs.length > 0 &&
                    <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                }
            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isLoading: state.flowmeter.loading,
        isFlowmeterAdmin: !!state.auth.session?.employee.IsFlowmeterAdmin,
    };
};

export default connect(
    mapStateToProps,
)(FlowmeterManualReadings);

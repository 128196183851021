import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import lodash from 'lodash';
import { MOTTECH_INPUTS, MOTTECH_OUTPUTS } from '../../../../appConstants';
import FormHelperText from '@material-ui/core/FormHelperText';

interface IMottechIOSelectorProps {
    input ?: boolean;
    value : string;

    onChange : (value : string) => void;
}

interface IMottechIOSelectorState {}

export default class MottechIOSelector extends React.Component<IMottechIOSelectorProps, IMottechIOSelectorState> {
    constructor(props : IMottechIOSelectorProps) {
        super(props);
        this.state = {};
    }

    private readonly onMottechIOChange = (event : React.ChangeEvent<{
        name ?: string | undefined;
        value : unknown;
    }>) => {
        if (typeof event.target.value !== 'string') return;

        this.props.onChange(event.target.value);
    };

    public readonly render = () => {
        const { value, input } = this.props;

        return (
            <FormControl margin='normal' fullWidth>
                <InputLabel shrink={!!value} htmlFor='mottechIO'>Mottech IO</InputLabel>
                <Select
                    fullWidth
                    id='mottechIO'
                    value={!value ? '' : value}
                    onChange={this.onMottechIOChange}
                    required
                    error={!value}>
                    <MenuItem value=''>
                        <em>None</em>
                    </MenuItem>
                    {
                        input &&
                        lodash.map(MOTTECH_INPUTS, n => (
                            <MenuItem key={`${n}_mottechIn`} value={`${n}`}>
                                {n}
                            </MenuItem>
                        ))
                    }
                    {
                        !input &&
                        lodash.map(MOTTECH_OUTPUTS, n => (
                            <MenuItem key={`${n}_mottechOut`} value={`${n}`}>
                                {n}
                            </MenuItem>
                        ))
                    }
                    {
                        !value &&
                        <FormHelperText error>Required</FormHelperText>
                    }
                </Select>
            </FormControl>
        );
    };
}

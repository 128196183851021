import moment, { Moment } from 'moment';
import { DATE_FORMAT_DEFAULT_NO_TIME, EN_ZA_NUMBER_FORMAT } from '../appConstants';

declare module 'moment' {
    // tslint:disable-next-line: interface-name
    export interface Moment {
        asUTC() : moment.Moment;
        asDateOnly() : string;
    }
}

moment.prototype.asUTC = function () : moment.Moment {
    const self = this as moment.Moment;
    return moment.utc(self.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
};

moment.prototype.asDateOnly = function () : string {
    const self = this as moment.Moment;
    return self.format(DATE_FORMAT_DEFAULT_NO_TIME);
};

declare global {
    // tslint:disable-next-line: interface-name
    interface Array<T> {
        /**
         * Insert or Removes element from array depending
         * if it is already in array.
         * @param elem Element to insert or remove.
         */
        upsert(elem : T) : Array<T>;
        /**
         * Replaces an element in an array.
         * Throws exception if element is not in array.
         * @param elem Element to replace
         * @param idField Field to compare
         */
        /**
         * Replaces an element in an array.
         * Throws exception if element is not in array and
         * insert is false.
         * @param elem Element to replace
         * @param idField Field to compare
         * @param insert Insert element if not found.
         */
        replace(elem : T, idField : string, insert ?: boolean) : Array<T>;
        /**
         * Removes an element in an array.
         * Throws exception if element is not in array.
         * @param elem Element to replace
         * @param idField Field to compare
         */
        remove(elem : T, idField : string) : Array<T>;
    }
    
    interface Number {
        toSAFormat(fraction ?: number) : string;
        toMoment(local ?: boolean) : Moment;
        toLocaleCurrency(
            currency ?: string,
            locale ?: string,
            options ?: Intl.NumberFormatOptions,
        ) : string;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.upsert) {
    Object.defineProperty(Array.prototype, 'upsert', {
        value: function upsert(value : never) {
            const index = this.indexOf(value);

            if (index > -1) this.splice(index, 1);
            else this.push(value);

            return this;
        },
        writable: true,
        configurable: true,
    });
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.replace) {
    Object.defineProperty(Array.prototype, 'replace', {
        value: function replace(value : never, idField : string, insert ?: boolean) {
            const index = this.findIndex((x : never) => value[idField] === x[idField]);

            if (index > -1) this.splice(index, 1, value);
            else if (insert) this.push(value);
            else throw new Error('Element not found!');

            return this;
        },
        writable: true,
        configurable: true,
    });
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Array.prototype.remove) {
    Object.defineProperty(Array.prototype, 'remove', {
        value: function remove(value : never, idField : string) {
            const index = this.findIndex((x : never) => value[idField] === x[idField]);

            if (index > -1) this.splice(index, 1);
            else throw new Error('Element not found!');

            return this;
        },
        writable: true,
        configurable: true,
    });
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Number.prototype.toSAFormat) {
    Number.prototype.toSAFormat = function (fraction ?: number) : string {
        return EN_ZA_NUMBER_FORMAT.format(Number(this.toFixed(fraction)));
    };
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Number.prototype.toMoment) {
    Number.prototype.toMoment = function (local ?: boolean) : Moment {
        return local ? moment(Number(this)) : moment.utc(Number(this));
    };
}
Number.prototype.toLocaleCurrency = function (
    currency = 'ZAR',
    locale = 'en-ZA',
    options ?: Intl.NumberFormatOptions,
) : string {
    try {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            ...options,
        }).format(Number(this));
    } catch (ex) {
        console.error(ex);
    }

    return '';    
};
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { ITransformerSplit } from '../../../@types/model/transformer/transformer';
import DepartmentSelector from '../../customComponents/selector/DepartmentSelector';
import DeleteIcon from '@material-ui/icons/Delete';
import IDepartment from '../../../@types/model/department';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import UsageToggleButton from '../../customComponents/button/UsageToggleButton';

interface ITransformerUsageSplitInputProps {
    index : number;
    usage : ITransformerSplit;

    isLoading : boolean;

    onRemoveClick ?: (index : number) => void;
    onChange ?: (index : number, value : ITransformerSplit) => void;
}

interface ITransformerUsageSplitInputState {}

export default class TransformerUsageSplitInput extends React.PureComponent<ITransformerUsageSplitInputProps, ITransformerUsageSplitInputState> {
    constructor(props : ITransformerUsageSplitInputProps) {
        super(props);
        this.state = {};
    }

    private readonly onDepartmentSelected = (value ?: IDepartment) => {
        if (!value) return;

        const usage = { ...this.props.usage };

        usage.Dept = value.GL;
        usage.DeptName = value.CUSTDESC;

        if (this.props.onChange) this.props.onChange(this.props.index, usage);
    };

    private readonly onPercentageChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;

        const usage = { ...this.props.usage };
        const percentage = parseInt(event.currentTarget.value);

        if (!isNaN(percentage) && (percentage < 0 || percentage > 100)) return;

        usage.Percentage = isNaN(percentage) ? null : percentage;
        usage.FixedAmount = null;

        if (this.props.onChange) this.props.onChange(this.props.index, usage);
    };

    private readonly onFixedAmountChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.currentTarget.value && !Number(event.currentTarget.value)) return;

        const usage = { ...this.props.usage };

        usage.FixedAmount = parseInt(event.currentTarget.value);
        usage.Percentage = null;

        if (this.props.onChange) this.props.onChange(this.props.index, usage);
    };

    private readonly onFleetChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const usage = { ...this.props.usage };

        usage.FleetNumber = event.currentTarget.value;

        if (this.props.onChange) this.props.onChange(this.props.index, usage);
    };

    private readonly onIncludeVatToggle = () => {
        const usage = { ...this.props.usage };

        usage.IncludeVat = !usage.IncludeVat;

        if (this.props.onChange) this.props.onChange(this.props.index, usage);
    };

    private readonly onRemoveClick = () => {
        if (this.props.onRemoveClick) this.props.onRemoveClick(this.props.index);
    };

    public render = () => {
        const { usage, index, isLoading, onRemoveClick } = this.props;
        return (
            <div className='mt10'>
                <div className='fdr aic pr5'>
                    <div style={{ backgroundColor: '#188944' }} className='fdr aic jcc h37 w37 br20 cw fwm fs16 mr10'>
                        {index + 1}
                    </div>
                    <Typography className='fwm fs16'>
                        Usage {index + 1} - {usage.Usage}
                    </Typography>
                    <span className='flx1' />
                    {
                        !!onRemoveClick &&
                        <Tooltip title='Remove'>
                            <div>
                                <IconButton color='primary' disabled={isLoading && !!onRemoveClick} onClick={this.onRemoveClick}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                    }
                </div>
                <div className='fdr aifs jcfs pr5'>
                    <div className='fdc flx1 pr5'>
                        <DepartmentSelector id={`usage_dep_${usage.Usage}`} required value={usage.Dept} onChange={this.onDepartmentSelected} fullWidth />
                    </div>
                    <div className='fdc flx1 pl5'>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id={`usage_perc_${usage.Usage}`}
                                label='Percentage'
                                value={usage.Percentage ?? ''}
                                onChange={this.onPercentageChange}
                                error={(!usage.FixedAmount && !usage.Percentage) || (!!usage.Percentage && !Number(usage.Percentage))}
                                InputProps={{
                                    inputProps: {
                                        max: 100,
                                        min: 0,
                                    },
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                !usage.FixedAmount && !usage.Percentage &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                            {
                                !!usage.Percentage && !Number(usage.Percentage) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </div>
                <div className='fdr aifs jcfs pr5 mt5'>
                    <div className='fdc flx1 pr5'>
                        <FormControl fullWidth>
                            <TextField
                                type='number'
                                autoComplete='off'
                                id={`usage_fixed_${usage.Usage}`}
                                label='Nominal Amount'
                                value={usage.FixedAmount ?? ''}
                                onChange={this.onFixedAmountChange}
                                error={(!usage.FixedAmount && !usage.Percentage) || (!!usage.FixedAmount && !Number(usage.FixedAmount))}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            R
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {
                                !usage.FixedAmount && !usage.Percentage &&
                                <FormHelperText error>Required</FormHelperText>
                            }
                            {
                                usage.FixedAmount && !Number(usage.FixedAmount) &&
                                <FormHelperText error>Invalid</FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <div className='fdc flx1 pl5 h70'>
                        <div className='flx1 aic'>
                            <UsageToggleButton text='Claimable VAT' value='Claimable VAT' selected={usage.IncludeVat}
                                onClick={this.onIncludeVatToggle}
                            />
                        </div>
                    </div>
                </div>
                {
                    usage.Usage === 'Housing' &&
                    <div className='fdr aifs jcfs pr5 mt5'>
                        <div className='fdc flx1 pr5'>
                            <FormControl required fullWidth>
                                <TextField
                                    type='text'
                                    autoComplete='off'
                                    id={`usage_fleet_${usage.Usage}`}
                                    label='Fleet Number'
                                    value={usage.FleetNumber ?? ''}
                                    onChange={this.onFleetChange}
                                    error={!usage.FleetNumber}
                                    required
                                />
                                {
                                    !usage.FleetNumber &&
                                    <FormHelperText error>Required</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className='fdc flx1 pl5 h70'>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

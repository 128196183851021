import { AppBar, AccordionDetails, IconButton, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import PumpingStationAuditHelper, { IPumpingStationAudit } from '../../../@types/model/pumpingStation/pumpingStationAudit';
import { IPumpingStationGroup } from '../../../@types/model/pumpingStation/pumpingStationGroup';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles';
import '../../../style/pumpingstation/expandableAudit.scss';
import { IUserSession } from '../../../@types/employee';
import EditPumpingStationGeneral from '../../pumpingStation/dialog/EditGeneralAuditDialog';
import EditPumpingStationPump from '../../pumpingStation/dialog/EditPumpAuditDialog';
import EditPumpingStationMotor from '../../pumpingStation/dialog/EditMotorAuditDialog';
import EditPumpingStationFilter from '../../pumpingStation/dialog/EditFilterAuditDialog';
import EditPumpingStationFlowmeter from '../../pumpingStation/dialog/EditFlowmeterAuditDialog';
import GeneralFunctions from '../../../store/general/functions';
import EditPumpingStationBoosterPump from '../../pumpingStation/dialog/EditBoosterPumpAuditDialog';
import EditPumpingStationVoluteCasing from '../../pumpingStation/dialog/EditVoluteCasingAuditDialog';
import CarouselImageViewer from '../../customComponents/image/CarouselViewer';
import ConfirmDialog from '../../customComponents/dialog/ConfirmDialog';
import { Transitions } from '../../customComponents/animations/Transitions';
import SeparatedAccordion from '../../customComponents/expansion/SeparatedAccordion';
import PrimaryAccordionSummary from '../../customComponents/expansion/PrimaryAccordionSummary';

const WidePanelDetials = withStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
    },
})(AccordionDetails);

interface IPumpingStationExpandableAuditProps {
    pumpingStationAudit : IPumpingStationAudit;
    session : IUserSession;
}

interface IPumpingStationExpandableAuditState {
    imageSrcs : Array<string>;
    isCarouselOpen : boolean;
    imageIndex : number;
    showConfirm : boolean;
}

class PumpingStationExpandableAudit extends React.Component<IPumpingStationExpandableAuditProps, IPumpingStationExpandableAuditState> {

    constructor(props : IPumpingStationExpandableAuditProps) {
        super(props);
        this.state = {
            imageSrcs: [],
            isCarouselOpen: false,
            imageIndex: 0,
            showConfirm: false,
        };
    }

    public componentDidMount() {
        this.buildImageSources();
    }

    public componentDidUpdate(prevProps : IPumpingStationExpandableAuditProps) {
        if (prevProps.pumpingStationAudit !== this.props.pumpingStationAudit)
            this.buildImageSources();
    }

    private buildImageSources() {
        const imageSrcs : Array<string> = [];
        
        this.props.pumpingStationAudit.pumpingGroups.forEach(pumpingGroup => {
            
            if (pumpingGroup.pumpPhotoUrl) imageSrcs.push(pumpingGroup.pumpPhotoUrl);
            if (pumpingGroup.motorPhotoUrl) imageSrcs.push(pumpingGroup.motorPhotoUrl);
            if (pumpingGroup.flowMeterPhotoUrl) imageSrcs.push(pumpingGroup.flowMeterPhotoUrl);
            if (pumpingGroup.installationPhotoUrl) imageSrcs.push(pumpingGroup.installationPhotoUrl);
            if (pumpingGroup.filterBankPhotoUrl) imageSrcs.push(pumpingGroup.filterBankPhotoUrl);

        });
        
        this.setState({ imageSrcs });
    }


    private onThumbClick = (event : React.MouseEvent<HTMLImageElement>) => {
        
        const index = this.state.imageSrcs.indexOf(event.currentTarget.alt);

        this.setState({ 
            isCarouselOpen: true, 
            imageIndex: index,
        });
    };

    private onCarouselClose = () => {
        this.setState({ isCarouselOpen: false });
    };

    private onDeleteClick = () => {
        this.setState({ showConfirm: true });
    };

    private onCloseConfirm = () => {
        this.setState({ showConfirm: false });
    };

    private deleteAudit = async (result : boolean) => {

        if (!result) {
            this.onCloseConfirm();
            return;
        }

        const { session } = this.props;

        const pumpingStationAudit : IPumpingStationAudit = { ...this.props.pumpingStationAudit, pumpingGroups: [...this.props.pumpingStationAudit.pumpingGroups]}; 

        pumpingStationAudit.updatedBy = session.firebaseUser.uid;
        pumpingStationAudit.updatedByEmployee = session.employee.EmployeeNumber ?? '';
        pumpingStationAudit.updatedByName = session.employee.Name;
        pumpingStationAudit.updatedOn = moment().utc().valueOf();
        pumpingStationAudit.isActive = false;
 
        try {
            await PumpingStationAuditHelper.save(pumpingStationAudit);

            this.onCloseConfirm();
            GeneralFunctions.generalShowSuccessSnackbar('Audit has been deleted!');
        } catch {
            GeneralFunctions.generalShowErrorSnackbar('Something went wrong while deleting the audit.');
        }


    };

    public render() {

        const { isCarouselOpen, imageIndex, imageSrcs, showConfirm } = this.state;
        const { pumpingStationAudit, session } = this.props;

        const auditCode = `${pumpingStationAudit.pumpCode} - ${moment.utc(pumpingStationAudit.createdOn).format('DD/MM/YYYY')}`;

        return (
            <SeparatedAccordion>
                <PrimaryAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className={'fs19 fw500'}> 
                        {auditCode}
                    </Typography>
                </PrimaryAccordionSummary>
                <WidePanelDetials>
                    <div className='fdc wfill'>
                        {
                            pumpingStationAudit.pumpingGroups.map((pumpingGroup : IPumpingStationGroup, index : number) => (
                                <div key={pumpingGroup.reference} className={'wfill'}>
                                    <AppBar className='inner-header' position='static' elevation={1}>
                                        <Toolbar variant='dense'>
                                            <div className={'fdr jcsb aic wfill'}>
                                                <Typography variant='h6' className='flx1 cp fw600'>
                                                    General Info PG - {pumpingGroup.number}
                                                    {!pumpingGroup.description ? '' : ` (${pumpingGroup.description})`}
                                                </Typography>
                                                {
                                                    (session.employee.IsPumpingStationAdmin) && (
                                                        <EditPumpingStationGeneral 
                                                            session={session}
                                                            pumpingGroup={pumpingGroup} 
                                                            pumpingStationAudit={pumpingStationAudit} 
                                                            maxWidth={'md'} 
                                                            fullWidth={true} 
                                                        />
                                                    )
                                                }
                                            </div>
                                        </Toolbar>
                                    </AppBar>
                                    <div className={'fdr info-padding'}>
                                        <div className={'fdr jcsb flx1 aic'}>
                                            <div className={'fdr fww info-spacing'}>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>PC Number</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingStationAudit.pumpCode}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Surveyor</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingStationAudit.createdByName}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Purpose</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.purpose}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Description</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.description ?? '-'}</Typography>
                                                </div>
                                            </div>
                                            <div className={'fdr'}>
                                                {
                                                    pumpingGroup.installationPhotoThumbnailUrl ? 
                                                        <img className={'curp'} src={pumpingGroup.installationPhotoThumbnailUrl} alt={pumpingGroup.installationPhotoUrl} onClick={this.onThumbClick} /> :
                                                        <div className='flx1 fdc aic jcc p30'>No Image</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        pumpingGroup.voluteCasingMake &&
                                        <>
                                            <AppBar className='inner-header' position='static' elevation={1}>
                                                <Toolbar variant='dense'>
                                                    <div className={'fdr jcsb aic wfill'}>
                                                        <Typography variant='h6' className='flx1 cp fw600'>
                                                            Volute Casing
                                                        </Typography>
                                                        {
                                                            (session.employee.IsPumpingStationAdmin) && (
                                                                <EditPumpingStationVoluteCasing
                                                                    session={session}
                                                                    pumpingGroup={pumpingGroup} 
                                                                    pumpingStationAudit={pumpingStationAudit} 
                                                                    maxWidth={'md'} 
                                                                    fullWidth={true} 
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </Toolbar>
                                            </AppBar>
                                            <div className={'fdr info-padding'}>
                                                <div className={'fdr jcsb flx1 aic'}>
                                                    <div className={'fdr fww info-spacing'}>
                                                        <div className={'fdc'}>
                                                            <Typography className={'fs12 cg3'}>Make</Typography>
                                                            <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.voluteCasingMake}</Typography>
                                                        </div>
                                                        <div className={'fdc'}>
                                                            <Typography className={'fs12 cg3'}>Model</Typography>
                                                            <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.voluteCasingModel}</Typography>
                                                        </div>
                                                        <div className={'fdc'}>
                                                            <Typography className={'fs12 cg3'}>Serial Number</Typography>
                                                            <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.voluteCasingSerial}</Typography>
                                                        </div>
                                                    </div>
                                                    <div className={'fdr'}>
                                                        {
                                                            pumpingGroup.voluteCasingPhotoThumbnailUrl ? 
                                                                <img className={'curp'} src={pumpingGroup.voluteCasingPhotoThumbnailUrl} alt={pumpingGroup.voluteCasingPhotoUrl} onClick={this.onThumbClick} /> :
                                                                <div className='flx1 fdc aic jcc p30'>No Image</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <AppBar className='inner-header' position='static' elevation={1}>
                                        <Toolbar variant='dense'>
                                            <div className={'fdr jcsb aic wfill'}>
                                                <Typography variant='h6' className='flx1 cp fw600'>
                                                    Pump 
                                                </Typography>
                                                {
                                                    (session.employee.IsPumpingStationAdmin) && (
                                                        <EditPumpingStationPump 
                                                            session={session}
                                                            pumpingGroup={pumpingGroup} 
                                                            pumpingStationAudit={pumpingStationAudit} 
                                                            maxWidth={'md'} 
                                                            fullWidth={true} 
                                                        />
                                                    )
                                                }
                                            </div>
                                        </Toolbar>
                                    </AppBar>
                                    <div className={'fdr info-padding'}>
                                        <div className={'fdr jcsb flx1 aic'}>
                                            <div className={'fdr fww info-spacing'}>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Make</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.pumpMake}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Model</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.pumpModel}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Type</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.pumpType}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Serial Number</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.pumpSerial}</Typography>
                                                </div>
                                            </div>
                                            <div className={'fdr'}>
                                                {
                                                    pumpingGroup.pumpPhotoThumbnailUrl ? 
                                                        <img className={'curp'} src={pumpingGroup.pumpPhotoThumbnailUrl} alt={pumpingGroup.pumpPhotoUrl} onClick={this.onThumbClick} /> :
                                                        <div className='flx1 fdc aic jcc p30'>No Image</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        pumpingGroup.boosterPumpType &&
                                        <>
                                            <AppBar className='inner-header' position='static' elevation={1}>
                                                <Toolbar variant='dense'>
                                                    <div className={'fdr jcsb aic wfill'}>
                                                        <Typography variant='h6' className='flx1 cp fw600'>
                                                            Booster Pump 
                                                        </Typography>
                                                        {
                                                            (session.employee.IsPumpingStationAdmin) && (
                                                                <EditPumpingStationBoosterPump 
                                                                    session={session}
                                                                    pumpingGroup={pumpingGroup} 
                                                                    pumpingStationAudit={pumpingStationAudit} 
                                                                    maxWidth={'md'} 
                                                                    fullWidth={true} 
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </Toolbar>
                                            </AppBar>
                                            <div className={'fdr info-padding'}>
                                                <div className={'fdr jcsb flx1 aic'}>
                                                    <div className={'fdr fww info-spacing'}>
                                                        <div className={'fdc'}>
                                                            <Typography className={'fs12 cg3'}>Make</Typography>
                                                            <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.boosterPumpMake}</Typography>
                                                        </div>
                                                        <div className={'fdc'}>
                                                            <Typography className={'fs12 cg3'}>Model</Typography>
                                                            <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.boosterPumpModel}</Typography>
                                                        </div>
                                                        <div className={'fdc'}>
                                                            <Typography className={'fs12 cg3'}>Type</Typography>
                                                            <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.boosterPumpType}</Typography>
                                                        </div>
                                                        <div className={'fdc'}>
                                                            <Typography className={'fs12 cg3'}>Serial Number</Typography>
                                                            <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.boosterPumpSerial}</Typography>
                                                        </div>
                                                    </div>
                                                    <div className={'fdr'}>
                                                        {
                                                            pumpingGroup.boosterPumpPhotoThumbnailUrl ? 
                                                                <img className={'curp'} src={pumpingGroup.boosterPumpPhotoThumbnailUrl} alt={pumpingGroup.boosterPumpPhotoUrl} onClick={this.onThumbClick} /> :
                                                                <div className='flx1 fdc aic jcc p30'>No Image</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <AppBar className='inner-header' position='static' elevation={1}>
                                        <Toolbar variant='dense'>
                                            <div className={'fdr jcsb aic wfill'}>
                                                <Typography variant='h6' className='flx1 cp fw600'>
                                                   Motor
                                                </Typography>
                                                {
                                                    (session.employee.IsPumpingStationAdmin) && (
                                                        <EditPumpingStationMotor 
                                                            session={session}
                                                            pumpingGroup={pumpingGroup} 
                                                            pumpingStationAudit={pumpingStationAudit} 
                                                            maxWidth={'md'} 
                                                            fullWidth={true} 
                                                        />
                                                    )
                                                }
                                            </div>
                                        </Toolbar>
                                    </AppBar>
                                    <div className={'fdr info-padding'}>
                                        <div className={'fdr jcsb flx1 aic'}>
                                            <div className={'fdr fww info-spacing'}>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Make</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.motorMake}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Size (kW)</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.motorSize}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Poles</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.motorPoles}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Phases</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.motorPhase}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Efficiency</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.motorEfficiency}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Type</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.motorType}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Mounting</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.motorMountType}</Typography>
                                                </div>
                                                <div className={'fdc'}>
                                                    <Typography className={'fs12 cg3'}>Serial Number</Typography>
                                                    <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.motorSerial}</Typography>
                                                </div>
                                            </div>
                                            <div className={'fdr'}>
                                                {
                                                    pumpingGroup.motorPhotoThumbnailUrl ? 
                                                        <img className={'curp'} src={pumpingGroup.motorPhotoThumbnailUrl} alt={pumpingGroup.motorPhotoUrl} onClick={this.onThumbClick} /> : 
                                                        <div className='flx1 fdc aic jcc p30'>No Image</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (pumpingGroup.purpose === 'Irrigation') && (
                                            <>
                                                <AppBar className='inner-header' position='static' elevation={1}>
                                                    <Toolbar variant='dense'>
                                                        <div className={'fdr jcsb aic wfill'}>
                                                            <Typography variant='h6' className='flx1 cp fw600'>
                                                                Fliter Bank
                                                            </Typography>
                                                            {
                                                                (session.employee.IsPumpingStationAdmin) && (
                                                                    <EditPumpingStationFilter 
                                                                        session={session}
                                                                        pumpingGroup={pumpingGroup} 
                                                                        pumpingStationAudit={pumpingStationAudit} 
                                                                        maxWidth={'md'} 
                                                                        fullWidth={true} 
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </Toolbar>
                                                </AppBar>
                                                <div className={'fdr info-padding'}>
                                                    <div className={'fdr jcsb flx1 aic'}>
                                                        <div className={'fdr fww info-spacing'}>
                                                            <div className={'fdc'}>
                                                                <Typography className={'fs12 cg3'}>Type</Typography>
                                                                <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.filterBankType}</Typography>
                                                            </div>
                                                            <div className={'fdc'}>
                                                                <Typography className={'fs12 cg3'}>Size</Typography>
                                                                <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.filterBankSize}</Typography>
                                                            </div>
                                                        </div>
                                                        <div className={'fdr'} >
                                                            {
                                                                pumpingGroup.filterBankPhotoThumbnailUrl ? 
                                                                    <img className={'curp'} src={pumpingGroup.filterBankPhotoThumbnailUrl} alt={pumpingGroup.filterBankPhotoUrl} onClick={this.onThumbClick} /> : 
                                                                    <div className='flx1 fdc aic jcc p30'>No Image</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        pumpingGroup.flowMeterMake && (
                                            <>
                                                <AppBar className='inner-header' position='static' elevation={1}>
                                                    <Toolbar variant='dense'>
                                                        <div className={'fdr jcsb aic wfill'}>
                                                            <Typography variant='h6' className='flx1 cp fw600'>
                                                                Flowmeter
                                                            </Typography>
                                                            {
                                                                (session.employee.IsPumpingStationAdmin) && (
                                                                    <EditPumpingStationFlowmeter
                                                                        session={session}
                                                                        pumpingGroup={pumpingGroup} 
                                                                        pumpingStationAudit={pumpingStationAudit} 
                                                                        maxWidth={'md'} 
                                                                        fullWidth={true} 
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </Toolbar>
                                                </AppBar>
                                                <div className={'fdr info-padding'}>
                                                    <div className={'fdr jcsb flx1 aic'}>
                                                        <div className={'fdr fww info-spacing'}>
                                                            <div className={'fdc'}>
                                                                <Typography className={'fs12 cg3'}>Make</Typography>
                                                                <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.flowMeterMake}</Typography>
                                                            </div>
                                                            <div className={'fdc'}>
                                                                <Typography className={'fs12 cg3'}>Size</Typography>
                                                                <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.flowMeterSize}</Typography>
                                                            </div>
                                                            <div className={'fdc'}>
                                                                <Typography className={'fs12 cg3'}>Reed Switch</Typography>
                                                                <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.flowMeterReedSwitch ? 'YES' : 'NO'}</Typography>
                                                            </div>
                                                            <div className={'fdc'}>
                                                                <Typography className={'fs12 cg3'}>Pulse</Typography>
                                                                <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.flowMeterPulse}</Typography>
                                                            </div>
                                                            <div className={'fdc'}>
                                                                <Typography className={'fs12 cg3'}>Serial Number</Typography>
                                                                <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.flowMeterSerial}</Typography>
                                                            </div>
                                                            <div className={'fdc'}>
                                                                <Typography className={'fs12 cg3'}>Connected to a monitoring system?</Typography>
                                                                <Typography className={'fs16 fw500 cg3'}>{pumpingGroup.monitorSystemConnected ? 'YES' : 'NO'}</Typography>
                                                            </div>
                                                        </div>
                                                        <div className={'fdr'} >
                                                            {
                                                                pumpingGroup.flowMeterPhotoThumbnailUrl ? 
                                                                    <img className={'curp'} src={pumpingGroup.flowMeterPhotoThumbnailUrl} alt={pumpingGroup.flowMeterPhotoUrl} onClick={this.onThumbClick} /> : 
                                                                    <div className='flx1 fdc aic jcc p30'>No Image</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        imageSrcs.length > 0 &&
                                        <CarouselImageViewer index={imageIndex} isOpen={isCarouselOpen} imageSrcs={imageSrcs} onClose={this.onCarouselClose} />
                                    }
                                    {(index !== (pumpingStationAudit.pumpingGroups.length - 1)) && <div className={'wfill mb10 bcp h3'} />} 
                                </div>
                            ))
                        }
                    </div>
                    <div className='fdr jcfe'>
                        <IconButton onClick={this.onDeleteClick}>
                            <DeleteIcon style={{ fontSize: 30 }} color='primary'/>
                        </IconButton>
                    </div>
                    <ConfirmDialog 
                        open={showConfirm}
                        title={'Delete Pumping Station Audit'}
                        message={`Are you sure that you want to delete the following audit - ${auditCode}`}
                        onClose={this.deleteAudit}
                        transition={Transitions.Up}
                    />
                </WidePanelDetials>
            </SeparatedAccordion>
        );
    }
}

export default PumpingStationExpandableAudit;
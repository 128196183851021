import { Card, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { IBorehole } from '../../../../@types/model/borehole/borehole';
import BoreholeVideoHelper, { IBoreholeVideo } from '../../../../@types/model/borehole/boreholeVideo';
import { IRootState } from '../../../../@types/redux';
import AppFunctionsService from '../../../../services/appFunctionsService';
import GeneralFunctions from '../../../../store/general/functions';
import { Transitions } from '../../../customComponents/animations/Transitions';
import VideoFileView from '../../../customComponents/file/VideoFileView';
import UploadBoreholeVideos from '../../dialog/UploadVideoDialog';

interface IBoreholeVideosViewProps {
    borehole : IBorehole;

    isBoreholeAdmin : boolean;
}

interface IBoreholeVideosViewState {
    videos : Array<IBoreholeVideo>;
    isLoading : boolean;
}

class BoreholeVideosViewComponent extends React.PureComponent<IBoreholeVideosViewProps, IBoreholeVideosViewState> {
    private stop ?: () => void;
    constructor(props : IBoreholeVideosViewProps) {
        super(props);
        this.state = {
            videos: [],
            isLoading: false,
        };
    }

    public readonly componentWillUnmount = () => {
        if (!this.stop) return;

        this.stop();
    };

    public readonly componentDidMount = () => {
        const { borehole } = this.props;

        try {
            this.setLoading(true);
            this.stop = BoreholeVideoHelper
                .collection(borehole.Code)
                .orderBy('createdOn', 'desc')
                .onSnapshot((snapshot) => {
                    this.setLoading(false);

                    const videos = this.state.videos.slice();

                    // "added" | "removed" | "modified"
                    snapshot.docChanges().forEach((f) => {
                        const video = f.doc.data();

                        const index = videos.findIndex(n => n.id === video.id);

                        switch (f.type) {
                            case 'added':
                                videos.push(video);
                                break;
                            case 'modified':
                                videos.splice(index, 1, video);
                                break;
                            case 'removed':
                                videos.splice(index, 1);
                                break;
                        }
                    });

                    this.setState({
                        videos,
                    });
                }, (ex) => {
                    GeneralFunctions.generalShowError(ex, 'An error while loading borehole videos.');
                    this.setLoading(false);
                });
        } catch (ex) {
            GeneralFunctions.generalShowError(ex, 'An error while loading borehole videos.');
            this.setLoading(false);
        }
    };

    private readonly setLoading = (isLoading : boolean) => {
        this.setState({
            isLoading,
        });
    };

    public readonly render = () => {
        const { isLoading, videos } = this.state;
        const { borehole } = this.props;
        return (
            <div className={'fdc flx1 mt15'}>
                <div className='fdr mr18'>
                    <span className='flx1' />
                    <UploadBoreholeVideos
                        borehole={borehole}
                        isLoading={isLoading}
                        fullWidth
                        maxWidth='md'
                        transition={Transitions.Down}

                    />
                </div>
                {
                    isLoading &&
                    <div className='fdc flx1 aic jcc'>
                        <CircularProgress />
                    </div>
                }
                {
                    !isLoading &&
                    !videos.length &&
                    <div className='fdc flx1 aic jcc'>
                        No Videos
                    </div>
                }
                {
                    !isLoading &&
                    !!videos.length &&
                    <div className='fdr fww flx1 aifs jcc mr15'>
                        {
                            videos.map(n => (
                                <div key={n.id} className={'mt9 fdr h400 ml15'}>
                                    <Card className={'bcw fdc'}>
                                        <div className={'jcc aic wfill bcp h50'}>
                                            <Typography className={'fs16 fw600 cw '}>
                                                VIDEO {AppFunctionsService.formatDateTimeToDateOnly(n.createdOn)}
                                            </Typography>
                                        </div>
                                        <div className='fdc aic w500 mt15 mb15 ml15 mr15 hfill'>
                                            <VideoFileView url={n.url} thumbnailUrl={n.thumbnailUrl} />
                                        </div>
                                    </Card>
                                </div>
                            ))
                        }
                    </div>
                }

            </div>
        );
    };
}

const mapStateToProps = (state : IRootState) => {
    return {
        isBoreholeAdmin: !!state.auth.session?.employee.IsBoreholeAdmin,
    };
};

const BoreholeVideosView = connect(
    mapStateToProps,
)(BoreholeVideosViewComponent);

export default BoreholeVideosView;

import { Field, FieldProps } from 'formik';
import React from 'react';
import { BoreholeConstantRateDrumStageType, IBoreholeConstantRateTestDrumFormValues } from '../../../../../@types/model/borehole/boreholeConstantRateTest';
import OutlinedStadiumButton from '../../../../customComponents/button/OutlinedStadiumButton';
import StadiumButton from '../../../../customComponents/button/StadiumButton';
import BoreholeConstantRateDrumFormField from '../../../../customComponents/form/boreholeConstantRate/BoreholeConstantRateDrumFormField';

interface IBoreholeConstantRateEditDialogDrumTabProps {
    isLoading : boolean;

    onNextClick ?: () => void;
    onBackClick ?: () => void;
}

interface IBoreholeConstantRateEditDialogDrumTabState {}

export default class BoreholeConstantRateEditDialogDrumTab extends React.PureComponent<IBoreholeConstantRateEditDialogDrumTabProps, IBoreholeConstantRateEditDialogDrumTabState> {
    constructor(props : IBoreholeConstantRateEditDialogDrumTabProps) {
        super(props);
        this.state = {};
    }

    public readonly render = () => {
        const { onNextClick, onBackClick } = this.props;
        return (
            <div className='fdc flx1 ml15 mr15 mt15'>
                <Field
                    name='drumEntries'
                >
                    {
                        (params : FieldProps<Record<BoreholeConstantRateDrumStageType, IBoreholeConstantRateTestDrumFormValues>>) =>
                            <BoreholeConstantRateDrumFormField {...params}/>
                    }
                </Field>
                <div className='fdr jcfe mt15'>
                    <div className='mr15'>
                        <OutlinedStadiumButton onClick={onBackClick}>
                            Back
                        </OutlinedStadiumButton>
                    </div>
                    <div>
                        <StadiumButton onClick={onNextClick}>
                            Next
                        </StadiumButton>
                    </div>
                </div>
            </div>
        );
    };
}
